import {
  Config,
  ConfigAction,
  DeleteConfigRequest,
  DeleteConfigSuccess,
  EKSConfig,
  FetchEksConfig,
  FetchEksConfigCompleted,
  FetchResourceGroupCompleted,
  FetchResourceGroupStarted,
  MemberValidatingSuccess,
  RequestConfig,
  RequestConfigSuccess,
  SaveConfigRequest,
  SaveConfigSuccess,
  ValidateConfigRequest,
  ValidateConfigSuccess,
} from './types';
import api from '../../../api';
import { notify } from '../../../components/common/toaster';

export const SendConfigRequest = function(): ConfigAction {
  return {
    type: RequestConfig,
  };
};

export const ConfigResult = function(data: any): ConfigAction {
  return {
    type: RequestConfigSuccess,
    data: data as Config[],
  };
};

export const SendConfigSaveRequest = function(): ConfigAction {
  return {
    type: SaveConfigRequest,
  };
};

export const SendConfigValidateRequest = function(): ConfigAction {
  return {
    type: ValidateConfigRequest,
  };
};

export const SendSavingRequest = function(): ConfigAction {
  return {
    type: SaveConfigRequest,
  };
};

export const ConfigSaved = function(cfg: Config | null): ConfigAction {
  return {
    type: SaveConfigSuccess,
    data: cfg,
  };
};

export const SendValidateConfigSuccess = function(): ConfigAction {
  return {
    type: ValidateConfigSuccess,
  };
};

export const SendMemberValidatingSuccess = function(): ConfigAction {
  return {
    type: MemberValidatingSuccess,
  };
};

export const SendConfigDeleteRequest = function(): ConfigAction {
  return {
    type: DeleteConfigRequest,
  };
};

export const ConfigDeleted = function(id: string | null): ConfigAction {
  return {
    type: DeleteConfigSuccess,
    data: id,
  };
};

export const fetchResourceGroupStarted = function(id: string): ConfigAction {
  return {
    type: FetchResourceGroupStarted,
    data: { configId: id },
  };
};

export const fetchResourceGroupCompleted = function(
  id: string,
  resourceGroup: string[],
): ConfigAction {
  return {
    type: FetchResourceGroupCompleted,
    data: { configId: id, resourceGroup },
  };
};

const fetchEksConfig = function(
  configId: string,
  region: string,
): ConfigAction {
  return {
    type: FetchEksConfig,
    data: {
      configId,
      region,
    },
  };
};

const fetchEksConfigCompleted = function(
  configId: string,
  region: string,
  eksConfigs: EKSConfig[],
): ConfigAction {
  return {
    type: FetchEksConfigCompleted,
    data: {
      configId,
      region,
      data: eksConfigs,
    },
  };
};

export function fetchConfig(props: { dispatch: any }) {
  props.dispatch(SendConfigRequest());
  api
    .getConfigs()
    .then((config) => {
      return props.dispatch(ConfigResult(config || []));
    })
    .catch((e) => {
      console.error(e);
    });
}

export async function validateCred(props: { dispatch: any; config: any }) {
  props.dispatch(SendConfigValidateRequest());
  return api
    .validate(props.config)
    .then(() => api.fetchAwsOrgMembers(props.config))
    .then((data) => {
      props.dispatch(SendValidateConfigSuccess());
      return data;
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      props.dispatch(SendValidateConfigSuccess());
      return null;
    });
}

export async function validateAndEditOrganisation(props: {
  dispatch: any;
  config: any;
}) {
  let configWithTags = JSON.parse(JSON.stringify(props.config));
  delete props.config.value.allowedTags;
  return api
    .validate(props.config)
    .then(() => api.editConfig(configWithTags))
    .then(() => api.editGroup(props.config))
    .then((data) => {
      notify({ type: 'success', message: 'Details Saved' });
      props.dispatch(ConfigSaved(data));
      return { isSaved: true };
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      props.dispatch(ConfigSaved(null));
      return { isSaved: false };
    });
}

export async function saveOrganisation(props: { dispatch: any; config: any }) {
  if (props.config.id) {
    //editing organisation
    props.dispatch(SendSavingRequest());
    if (props.config.removeConfig) {
      // remove config from organisation, validate and edit organisation
      return api.removeConfigFromGroup(props.config.removeConfig).then(() => {
        return validateAndEditOrganisation({
          dispatch: props.dispatch,
          config: props.config,
        });
      });
    }
    //validate and edit organisation
    return validateAndEditOrganisation({
      dispatch: props.dispatch,
      config: props.config,
    });
  } else {
    //save organisation
    return saveOrg({ dispatch: props.dispatch, config: props.config });
  }
}

export async function saveGroup(props: { dispatch: any; config: any }) {
  props.dispatch(SendSavingRequest());
  if (props.config.id) {
    if (props.config.removeConfig) {
      return api.removeConfigFromGroup(props.config.removeConfig).then(() => {
        delete props.config.removeConfig;
        return editGrp({ dispatch: props.dispatch, config: props.config });
      });
    } else {
      return editGrp({ dispatch: props.dispatch, config: props.config });
    }
  }
  return saveGrp({ dispatch: props.dispatch, config: props.config });
}

export function editGrp(props: { dispatch: any; config: any }) {
  return api
    .editConfig(props.config)
    .then(() => api.editGroup(props.config))
    .then((data) => {
      props.dispatch(ConfigSaved(null));
      return { isSaved: true };
    })
    .catch((e) => {
      notify({ type: 'error', message: e.message || e });
      props.dispatch(ConfigSaved(null));
      return { isSaved: false };
    });
}

export function saveOrg(props: { dispatch: any; config: any }) {
  return api
    .saveGroup(props.config)
    .then((data) => {
      props.dispatch(ConfigSaved(null));
      return { isSaved: true };
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      props.dispatch(ConfigSaved(null));
      return { isSaved: false };
    });
}

export function saveGrp(props: { dispatch: any; config: any }) {
  let childInfo = props.config.childInfo;
  props.config.childInfo = [];
  return api
    .saveGroup(props.config)
    .then((data) =>
      api.editGroup({ id: data.id, childInfo }).then((res) => {
        props.dispatch(ConfigSaved(null));
        return { isSaved: true };
      }),
    )
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      props.dispatch(ConfigSaved(null));
      return { isSaved: false };
    });
}

export function validateMemberConfig(props: { dispatch: any; config: any }) {
  return api
    .validate(props.config)
    .then((data) => {
      return { isValid: true };
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return { isValid: false };
    });
}

export function saveAwsConfig(props: { dispatch: any; config: any }) {
  props.dispatch(SendConfigSaveRequest());
  let configWithTags = JSON.parse(JSON.stringify(props.config));
  delete props.config.value.allowedTags;
  let method: (payload: any) => Promise<any> = props.config.id
    ? api.editConfig
    : api.saveConfig;
  return api
    .validate(props.config)
    .then(() => method.call(api, configWithTags))
    .then((data) => {
      notify({ type: 'success', message: 'Details Saved' });
      props.dispatch(ConfigSaved(data));
      return { isSaved: true };
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      props.dispatch(ConfigSaved(null));
      return { isSaved: false };
    });
}

export function deleteConfig(props: { dispatch: any; id: any }) {
  props.dispatch(SendConfigDeleteRequest());
  api
    .deleteConfig(props.id)
    .then(() => {
      return props.dispatch(ConfigDeleted(props.id));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return props.dispatch(ConfigDeleted(null));
    });
}

export function saveSlackConfig(props: {
  dispatch: any;
  code: any;
  config: any;
}) {
  props.dispatch(SendConfigSaveRequest());
  let method: (payload: any) => Promise<any> = props.config.id
    ? api.editConfig
    : api.saveConfig;
  return api
    .validateSlack(props.code, props.config)
    .then(() => {
      notify({
        type: 'info',
        message: `Please verify that you have received the following code ${props.code} as a message in the configured slack channel or user.`,
      });
      return method.call(api, props.config);
    })
    .then((data) => {
      props.dispatch(ConfigSaved(data));
      return { isSaved: true };
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      props.dispatch(ConfigSaved(null));
      return { isSaved: false };
    });
}

export function createSNSConfig(x: { dispatch: any }) {
  x.dispatch(SendConfigSaveRequest());
  return api
    .createEndpoint()
    .then((data) => {
      return x.dispatch(ConfigSaved(data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return x.dispatch(ConfigSaved(null));
    });
}

export function saveConfig(x: { dispatch: any; config: Config }) {
  x.dispatch(SendConfigSaveRequest());
  const method = x.config.id
    ? api.editConfig(x.config)
    : api.saveConfig(x.config);
  return method
    .then((data) => {
      x.dispatch(ConfigSaved(data));
      return { isSaved: true };
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      x.dispatch(ConfigSaved(null));
      return { isSaved: false };
    });
}

export function fetchResourceGroup(dispatch: any, configId: string) {
  dispatch(fetchResourceGroupStarted(configId));
  api
    .listResourceGroups({ configId })
    .then((data) => dispatch(fetchResourceGroupCompleted(configId, data)))
    .catch((error) => {
      console.error(error);
      dispatch(fetchResourceGroupCompleted(configId, []));
    });
}

export function fetchEksConfigs(
  dispatch: any,
  configId: string,
  region: string,
) {
  dispatch(fetchEksConfig(configId, region));
  api
    .listEKSCluster({ configId, region })
    .then((data) => dispatch(fetchEksConfigCompleted(configId, region, data)))
    .catch((error) => {
      console.error(error);
      notify({ type: 'error', message: error.message || error });
      dispatch(fetchEksConfigCompleted(configId, region, []));
    });
}
