import * as React from 'react';
import {
  WorkflowButtonsWrapper,
  WorkflowItemsWrapper,
  InnerSec,
  ListWorkflows,
} from '../styledEntities';
import { isLengthyArray } from '../../../util/commonUtils';
import {
  DashboardCreateWorkFlowBtn,
  DashboardViewWorkFlowBtn,
} from '../../../design/commonStyledComponents';
import { Link } from 'react-router-dom';
// import { WORKFLOW_STATUS } from "../../../config/constants";
import colors from '../../../design/colors';

import { Row, Col, ListGroup, ListGroupItem, Button } from 'reactstrap';
import { Workflow } from '../../../lib/store/workflows/types';
import { track } from '../../../analytics/analytics';
import { events } from '../../../analytics/events';

interface WorkflowProps {
  workflowData: Array<Workflow> | null;
}

export default class WorkflowsList extends React.Component<WorkflowProps> {
  getRelevantWorkFlows = (workFlows: Array<any> | null) => {
    //For now we pick the first three. Eventually this should pick the relevant ones.
    if (!workFlows) return null;

    const activeWorkflows = workFlows.filter((item) => {
      return item.properties && item.properties.active === true;
    });
    // return workFlows.slice(0, 3);
    return activeWorkflows.slice(0, 10);
  };

  renderList = () => {
    const { workflowData } = this.props;
    const relevantWorkFlows = this.getRelevantWorkFlows(workflowData);

    if (!workflowData || !isLengthyArray(workflowData)) {
      return null;
    }
    return (
      <div className="test-active-wf-list">
        {relevantWorkFlows && (
          <ListGroup flush={true} as="ul">
            {relevantWorkFlows.map((data, idx) => {
              return (
                <ListGroupItem
                  key={idx}
                  as="li"
                  className={'py-3'}
                  style={{
                    backgroundColor: 'transparent',
                    color: colors.text.secondary,
                    fontSize: '0.9em',
                  }}
                >
                  {data.name}
                </ListGroupItem>
              );
            })}
          </ListGroup>
        )}
      </div>
    );
  };

  renderButtons = () => {
    return (
      <>
        <Link className="test-new-wf mr-3" to="/editor">
          <Button color={'primary'} className="">
            <small>Create New Workflow</small>
          </Button>
        </Link>
        <Link className="test-view-wf" to="/workflows">
          <Button color={'primary'} outline className="">
            <small>VIEW WORKFLOWS</small>
          </Button>
        </Link>
      </>
    );
  };
  render() {
    const { workflowData } = this.props;
    if (!workflowData || !isLengthyArray(workflowData)) {
      return null;
    }
    return (
      <Row className="test-active-wf">
        <Col>
          <div className="test-active-wf-header border-bottom">
            <h5>
              Active Workflows
              <small className="text-small font-weight-light font-italic mx-1">
                (Top 10 workflows)
              </small>
            </h5>
          </div>
        </Col>
        <Col md="12">
          <ListWorkflows>
            {isLengthyArray(workflowData) ? this.renderList() : null}
          </ListWorkflows>
        </Col>
        <Col lg="12" className={'mt-4'}>
          {this.renderButtons()}
        </Col>
      </Row>
      // <WorkflowListWrapper className="workflow-list-wrapper">
      //   <WorkflowListHeader className="workflow-list-header">
      //     <span className="title-text">Active Workflows</span>
      //   </WorkflowListHeader>
      //   {isLengthyArray(workflowData) ? (
      //     <div className="absolute-content">{this.renderList()}</div>
      //   ) : null}
      //   {this.renderButtons()}
      // </WorkflowListWrapper>
    );
  }
}
