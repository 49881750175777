export const Colors = {
  palette: {
    white: '#fff',
    yellow: '#FFF386',
    bgPrimary: '#5E72E4',
    bgSecondary: '#F5F6FA',
    bgThird: '#F0F0F0',
    bgFourth: '#0E325E',
    bgFifth: '#031022',
    bgSixth: '#F4F4F4',
    // success: 'rgb(61, 255, 0)',
    success: 'rgb(51, 204, 0)',
    error: '#9F1F16',
    errorMessageColor: '#d04d4d',
    connectorBlue: '#0094FF',
    transparent: 'rgb(0,0,0,0)',
  },
  text: {
    primary: '#5E72E4',
    secondary: '#0D233F',
    tertiary: '#525F7F',
    quarternary: 'rgba(255,255,255,0.3)',
    quinary: 'rgba(255,255,255,0.7)',
    senary: 'rgba(134, 176, 232, 0.7)',
    linkButton: '#00A3FF',
  },
  button: {
    primary: {
      color: '#fff',
      background: '#5E72E4',
    },

    secondary: {
      color: '#5E72E4',
    },
  },
  input: {
    placeholder: 'rgba(255,255,255, 0.4)',
    placeholderDark: 'rgba(0, 0, 0, 0.4)',
  },
  link: {
    inactive: '#87BBFF',
    active: '#3B4ADE',
    alt: 'cyan',
  },
  border: {
    normal: '#CAD1D7',
  },
};
