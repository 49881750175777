import * as React from 'react';
import { TemplatesTable } from '../components/templatesTable';
import TemplateModalContent from '../components/TemplateModalContent';
import { CATEGORIES, SUB_CATEGORIES } from '../../../config/Categories';

import { TableItem, TemplateColNames } from '../components/cols';
import { Template } from '../../../lib/store/templates/types';
import { Category } from '../../common/categoryUtils';
import { workflowFiltersOptions } from '../components/workflowFilters';

interface IProps {
  categories: Category[];
  fetchingWorkflows: boolean;
  tableData: TableItem[];
  filterOptions: React.ReactNode;
}

interface IState {}

export class TesterWorkflow extends React.Component<IProps, IState> {
  getWorkflowsTabContent = () => {
    return (
      <TemplatesTable
        options={this.props.filterOptions}
        title={'Tester Workflows'}
        categories={this.props.categories}
        fields={[
          TemplateColNames.WORKFLOW_NAME,
          TemplateColNames.WORKFLOW_OPT,
          TemplateColNames.LAST_UPDATED,
          TemplateColNames.PAST_EXECUTIONS,
        ]}
        tableData={this.props.tableData}
        fetching={this.props.fetchingWorkflows}
      />
    );
  };

  render() {
    return (
      <div>
        {this.getWorkflowsTabContent()}
      </div>
    );
  }
}
