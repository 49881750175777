import React from 'react';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { WorkflowExecutionStatus } from '../style';
import { Link } from 'react-router-dom';
import { TAB_NAME } from 'webapp-genric/dist/constants';

export enum ExecutionStatusEnum {
  custom = 'custom',
  Loading = 'Loading latest Result',
  success = 'Successfully Executed',
  report = 'Successfully Executed',
  error = 'Execution Error',
  permission = 'Insufficient Permissions',
  notification = 'Successfully Executed', //'Notification Sent',
  running = 'In Progress',
  NA = 'Never Executed',
  queued = 'Queued',
}

export interface IExecutionStatusProps {
  tabName?: TAB_NAME;
  color?: string;
  status: ExecutionStatusEnum;
  url: string;
  executionUrl: string;
}

export const SymbolMap: {
  [s in ExecutionStatusEnum]: { color: string };
} = {
  'Never Executed': { color: '#808080' },
  'Loading latest Result': { color: '#4CADFF' },
  'Execution Error': { color: '#ff8c4e' },
  'Insufficient Permissions': {
    color: '#ff8c4e',
  },
  'In Progress': { color: '#4CADFF' },
  // 'Report Generated': { icon: 'fa-newspaper-o' },
  'Successfully Executed': { color: '#118009' }, //{ icon: 'fa-check-circle-o' },
  custom: { color: '#118009' },
  Queued: { color: '#4CADFF' },
  // 'Notification Sent': { icon: 'fa-send-o' },
};

export function parseExecutionStatus(
  status,
): { status: string; isSuccess: boolean; isFailure: boolean } {
  status = (status || '').replace('found', '');
  const isSuccess = status.startsWith('0 ');
  let digit = status.substring(0, status.indexOf(' '));
  const isFailure = digit !== '' && !isNaN(Number(digit));
  return { status, isSuccess, isFailure };
}

export function ExecutionStatus(props: IExecutionStatusProps) {
  const { status } = props;
  let { color } = SymbolMap[status] || { color: '#118009' };
  let className = 'test-workflows-result';
  if (props.color) {
    color = props.color;
  }
  let { url, executionUrl } = props;

  if (url && url.startsWith('http')) {
    url = new URL(url).pathname;
  }
  if (executionUrl && executionUrl.startsWith('http')) {
    executionUrl = new URL(executionUrl).pathname;
  }

  if (props.tabName === TAB_NAME.INSIGHTS) {
    let color;
    let status = props.status as string;
    const __ret = parseExecutionStatus(status);
    status = __ret.status;
    const isSuccess = __ret.isSuccess;
    const isFailure = __ret.isFailure;
    if (isSuccess) {
      return (
        <Row style={{ backgroundColor: '#228b22' }}>
          <Col className={'d-flex p-2 text-white'}>
            <span>Success</span>
          </Col>
        </Row>
      );
    }
    if (isFailure) status = 'Failed: ' + status;
    return (
      <Row
        style={{
          backgroundColor: SymbolMap[status]
            ? SymbolMap[status].color
            : '#FF0004',
        }}
      >
        <Col className={'d-flex p-2 text-white'}>
          <div title={status} className={'text-truncate'}>
            {status}
          </div>
          <div className={'align-items-right'}>
            {url && (
              <>
                <Link to={url} target={'_blank'} id={'report-link'}>
                  <i
                    className={'fa fa fa-newspaper-o ml-1'}
                    aria-hidden="true"
                  />
                </Link>
                <UncontrolledTooltip
                  target={'report-link'}
                  placement={'bottom'}
                >
                  Report link
                </UncontrolledTooltip>
              </>
            )}
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <Row className="d-flex py-2">
      <Col md={12}>
        <WorkflowExecutionStatus>
          <span
            style={{ color }}
            className={className}
            data-test="execution-status"
          >
            {status}
          </span>
        </WorkflowExecutionStatus>
        {url && (
          <>
            <Link to={url} target={'_blank'} id={'report'}>
              <i className={'fa fa fa-newspaper-o ml-1'} aria-hidden="true" />
            </Link>
            <UncontrolledTooltip target={'report'} placement={'bottom'}>
              Report link
            </UncontrolledTooltip>
          </>
        )}
        <Link to={executionUrl} target={'_blank'} id={'execution-detail-url'}>
          <i className={'fa fa fa-external-link ml-1'} aria-hidden="true" />
        </Link>
        {/*<Tooltip isOpen={tooltipOpenExec} toggle={toggleExec} target={'execution-detail-url'} placement={'bottom'}>*/}
        {/*  Execution details*/}
        {/*</Tooltip>*/}
      </Col>
    </Row>
  );
}
