import { GroupWorkflowListResult } from './../../lib/store/parking/type';
import moment from 'moment';
import { CustomScheduleType } from '../schedules/components/CustomScheduleForm';
import cronParser from 'cron-parser';
import {
  IGroupWorkflowModel,
  WORKFLOW_ACTION,
} from 'workflow-model/dist/types/IGroupWorkflowModel';
import { TriggerNode } from 'workflow-model/dist/nodes';
import { NODE_TYPES, SerializedTriggerNodeData } from 'workflow-model/dist';
import momentTime from 'moment-timezone';

export const days = [
  {
    label: 'Monday',
    value: 'MON',
  },
  {
    label: 'Tuesday',
    value: 'TUE',
  },
  {
    label: 'Wednesday',
    value: 'WED',
  },
  {
    label: 'Thursday',
    value: 'THU',
  },
  {
    label: 'Friday',
    value: 'FRI',
  },
  {
    label: 'Saturday',
    value: 'SAT',
  },
  {
    label: 'Sunday',
    value: 'SUN',
  },
];

export const DaysOptions = [
  {
    label: 'Monday - Friday',
    value: 'MON-FRI',
  },
  {
    label: 'Saturday - Sunday',
    value: 'SUN,SAT',
  },
  {
    label: 'Everyday',
    value: 'SUN-SAT',
  },
  {
    label: 'Custom',
    value: '0',
  },
];
export const timeZoneOptions = () => {
  let options = momentTime.tz.names().map((timezone) => {
    const abbr = momentTime.tz(timezone).zoneAbbr();
    const isNumber = Number(abbr);
    return {
      label: `${timezone}${
        isNaN(isNumber) ? `(${abbr})` : ''
      } : UTC ${momentTime.tz(timezone).format('Z')}`,
      value: timezone,
      timeZone: momentTime.tz(timezone).format('Z'),
      offset: momentTime.tz(moment.utc(), timezone).utcOffset(),
    };
  });
  options = options.sort((a, b) => a.offset - b.offset);
  return options;
};

export const extractTimeZoneOption = (value) => {
  let options = timeZoneOptions();
  return options.filter((option) => option.value === value);
};

export const getBrowserTimezone = () => {
  return momentTime.tz.guess();
};

export enum ActionName {
  stop = 'Parked',
  start = 'Unparked',
  smart_schedule = 'Parked By Smart Schedule',
}

export function convertPairedSchedule(
  schedules: CustomScheduleType[],
): { startSchedules: string[]; endSchedules: string[] } {
  let startSchedules = [];
  let endSchedules = [];
  for (let schedule of schedules) {
    let { startTime, endTime, customDays, days } = schedule;
    startSchedules.push(getCron(startTime, days, customDays));
    endSchedules.push(getCron(endTime, days, customDays));
  }
  return { startSchedules, endSchedules };
}

function isScheduleValid(schedule: CustomScheduleType): boolean {
  if (!schedule.startTime || !schedule.endTime) return false;
  return !(
    schedule.days === '0' &&
    (schedule.customDays.length === 0 || schedule.customDays[0] === '0')
  );
}

function extractScheduleExpression(e) {
  let exp = e.replace('cron(', '').replace(')', '');
  // since only 5 values are supported by lib so replace last
  return exp
    .split(' ')
    .slice(0, 5)
    .join(' ');
}

function numPad(n: string) {
  if (Number(n) <= 9) return 0 + n;
  return n;
}

export function getTime(milisecond) {
  if (!milisecond) {
    return '';
  }
  return moment.utc(milisecond).format('hh:mm');
}

export function shortName(name: string, length) {
  return name.length > length
    ? name.substring(0, length + 1).concat('...')
    : name;
}
export function modifyActionName(status: string) {
  return ActionName[status];
}

export const numberOfDaysScheduleRuns = (group: GroupWorkflowListResult) => {
  let totalDays = 0;
  let daysAlreadyCounted = [];
  const paired = pairSchedules({
    startSchedules: group.schedules.start,
    endSchedules: group.schedules.stop,
  });
  //loop for number of schedules
  paired.forEach((pair) => {
    const parser = expressionParser(pair.start, pair.end);
    //schedule days for Mon to Fri
    if (parser.days === 'MON-FRI') {
      days.forEach((day, index) => {
        if (daysAlreadyCounted.includes(day)) {
          return;
        }
        daysAlreadyCounted.push(day);
        if (index > 4) {
          return;
        }
        totalDays += getDaysBetweenDates(
          getDateBeforeXDays(30),
          getCurrentDate(),
          day.value,
        );
      });
      // schedules days for sat-sun
    } else if (parser.days === 'SAT-SUN') {
      days.forEach((day, index) => {
        if (daysAlreadyCounted.includes(day)) {
          return;
        }
        daysAlreadyCounted.push(day);
        if (index < 4) {
          return;
        }
        totalDays += getDaysBetweenDates(
          getDateBeforeXDays(30),
          getCurrentDate(),
          day.value,
        );
      });
      //schedules days for every days
    } else if (parser.days === 'EVERYDAY') {
      days.forEach((day) => {
        if (daysAlreadyCounted.includes(day)) {
          return;
        }
        daysAlreadyCounted.push(day);
      });
      totalDays += 30;
      //schedules for custom days
    } else if (parser.days === '0' && parser.customDays[0] !== '0') {
      parser.customDays.forEach((day) => {
        if (daysAlreadyCounted.includes(day)) {
          return;
        }
        daysAlreadyCounted.push(day);
        totalDays += getDaysBetweenDates(
          getDateBeforeXDays(30),
          getCurrentDate(),
          day,
        );
      });
    }
  });
  return totalDays;
};

export const getDaysBetweenDates = (start, end, dayName) => {
  var result = [];
  var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
  var day = days[dayName.toLowerCase().substr(0, 3)];
  // Copy start date
  var current = new Date(start);
  // Shift to next of required days
  current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
  // While less than end date, add dates to result array
  var endValue = new Date(end);
  while (current < endValue) {
    result.push(new Date(+current));
    current.setDate(current.getDate() + 7);
  }
  return result.length;
};

export const getDateBeforeXDays = (x) => {
  return moment(moment().subtract(x, 'days')).format('YYYY-MM-DD');
};

export const getCurrentDate = (format = 'YYYY-MM-DD') => {
  return moment().format(format);
};

export const getUtcTimeFromLocalTime = (time, format) => {
  const hour = `0${moment(time, format)
    .utc()
    .hours()}`.slice(-2);
  const minute = `0${moment(time, format)
    .utc()
    .minutes()}`.slice(-2);
  return `${hour}:${minute}`;
};

function getCron(time: string, days: string, customDays: string[]) {
  let stArr = time.split(':');
  const hour = Number(stArr[0]).toString();
  const minute = Number(stArr[1]).toString();
  if (days !== '0') return `cron(${minute} ${hour} ? * ${days} *)`;
  return `cron(${minute} ${hour} ? * ${customDays.join(',')} *)`;
}

export function getCronWithDate(time: string, date?: string) {
  let stArr = time.split(':');
  const hour = `0${Number(stArr[0]).toString()}`.slice(-2);
  const minute = `0${Number(stArr[1]).toString()}`.slice(-2);
  if (!date) {
    return `cron(${minute} ${hour} ? * * *)`;
  }
  let tempDate = moment(date, 'MM-DD-YYYY');
  let month = tempDate.format('MM');
  let day = tempDate.format('DD');
  let year = tempDate.format('YYYY');
  return `cron(${minute} ${hour} ${day} ${month} ? ${year})`;
}

export function getTimeAfterXFormat(x, format) {
  return moment()
    .add(x, format)
    .format('HH:mm');
}

export function getDateAfterXFormat(date, x, format) {
  return moment(date, 'MM-DD-YYYY HH:mm')
    .add(x, format)
    .format('MM-DD-YYYY');
}

export function isSelectedDateBeforeToday(date, format) {
  return moment(date, format).isBefore(moment().format(format));
}

export function isSelectedDateSameAsToday(date, format) {
  return moment(date, format).isSame(moment().format(format));
}

export function isSelectedTimeLessCurrentTime(time, format) {
  return getMinute(time, format) < getMinute(moment().format(format), format);
}

export function isSelectedEndDateBeforeStartDate(endDate, startDate, format) {
  return moment(endDate, format).isBefore(moment(startDate, format));
}
export function isSelectedEndDateSameOrBeforeStartDate(
  endDate,
  startDate,
  format,
) {
  return moment(endDate, format).isSameOrBefore(moment(startDate, format));
}

export function isSelectedEndDateSameAsStartDate(endDate, startDate, format) {
  return moment(endDate, format).isSame(moment(startDate, format));
}

export function isSelectedEndTimeLessStartTime(endTime, startTime, format) {
  return getMinute(endTime, format) <= getMinute(startTime, format);
}

export function isHour(hour) {
  if (!hour.includes('h')) {
    return false;
  }
  return !isNaN(Number(hour.slice(0, -1)));
}

export function convertUTCTimetoCustomTimeZoneTime(time, timezone) {
  return moment
    .utc(time, 'HH:mm')
    .tz(timezone)
    .format('HH:mm');
}

export function convertTimeFromCustomTimezoneToUTCTime(time, timezone) {
  return moment
    .tz(time, 'HH:mm', timezone)
    .utc()
    .format('HH:mm');
}

export const expressionParser = (
  start: string,
  end: string,
): CustomScheduleType => {
  const split = start.split(' ');
  const split2 = end.split(' ');
  const startTime = `${numPad(split[1])}:${numPad(split[0])}`;
  const endTime = `${numPad(split2[1])}:${numPad(split2[0])}`;
  let dayOption = DaysOptions.find((x) => x.value === split[4]);
  const days = dayOption ? split[4] : '0';
  const customDays = !dayOption ? split[4].split(',') : ['0'];
  let schedule: CustomScheduleType = {
    startTime,
    endTime,
    days,
    customDays,
    isValid: false,
  };
  schedule.isValid = isScheduleValid(schedule);
  return schedule;
};

export const getTimeZoneWithAbbr = (timezone) => {
  return momentTime.tz(timezone).format('Z');
};

export const pairSchedules = (params: {
  startSchedules: string[];
  endSchedules: string[];
}): { start: string; end: string }[] => {
  // put into same array
  // to normalise effect of timezone
  const referenceTime = new Date();
  referenceTime.setHours(0);
  referenceTime.setMinutes(0);
  referenceTime.setSeconds(0);
  referenceTime.setMilliseconds(0);

  function parseSchedule(s: string) {
    const expression = extractScheduleExpression(s);
    const cp = cronParser.parseExpression(expression, {
      currentDate: referenceTime,
    });
    return {
      sch: s,
      parsed: expression,
      next: cp.next().getTime(),
      __debug: cp.next(),
    };
  }

  const sortedStart = params.startSchedules
    .map((s) => parseSchedule(s))
    .sort((a, b) => a.next - b.next);
  const sortedStop = params.endSchedules
    .map((s) => parseSchedule(s))
    .sort((a, b) => a.next - b.next);

  let pairs = [];

  for (let i = 0; i < sortedStart.length; i++) {
    const start = sortedStart[i];
    const end = sortedStop[i];

    pairs.push({
      start: start.parsed,
      end: end.parsed,
    });
  }

  return pairs;
};

export function getMinute(m, format) {
  return moment(m, format).minutes() + moment(m, format).hours() * 60;
}

export function areAllSchedulesValid(schedules: CustomScheduleType[]): boolean {
  return schedules.filter((x) => !x.isValid).length === 0;
}

export function getParkedUnParkedTriggerData(
  groupWorkflow: IGroupWorkflowModel,
) {
  let parkedWorkflow = groupWorkflow.getChildWorkflowByAction(
    WORKFLOW_ACTION.STOP,
  ).workflow;
  let unParkedWorkflow = groupWorkflow.getChildWorkflowByAction(
    WORKFLOW_ACTION.START,
  ).workflow;

  let parkedTrigger: TriggerNode = parkedWorkflow.getNodesByType(
    NODE_TYPES.TRIGGER,
  )[0] as TriggerNode;
  let unParkedTrigger: TriggerNode = unParkedWorkflow.getNodesByType(
    NODE_TYPES.TRIGGER,
  )[0] as TriggerNode;

  let parkedTriggerData: SerializedTriggerNodeData = parkedTrigger.getNodeData();
  let unParkedTriggerData: SerializedTriggerNodeData = unParkedTrigger.getNodeData();

  return { parkedTriggerData, unParkedTriggerData };
}
