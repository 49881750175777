import { Reducer } from 'redux';
import {
  GetGroupWorkflowRequest,
  GetGroupWorkflowResponse,
  GroupWorkflowAction,
  ListGroupWorkflowRequest,
  ListGroupWorkflowResponse,
  ParkingState,
  SaveGroupWorkflowRequest,
  SaveGroupWorkflowResponse,
  TestGroupWorkflowRequest,
  TestGroupWorkflowResponse,
  FetchBatchHistoryRequest,
  SaveBatchHistoryResponse,
  FetchBatchHistoryResponse,
  SaveFetchingGroupsId,
  SetGroupWorkflowDeletedResponse,
} from './type';

const initialState: ParkingState = {
  isFetchingList: false,
  isTesting: false,
  isSaving: false,
  data: [],
  resources: [],
  fetchingGroupsHistory: [],
  childWfMap: {},
  isFetchingHistory: false,
  history: {},
  isFetchGroupPolicy: false,
  isFetchingCombinedPolicy: false,
  groupPolicy: {},
  multiGroupPolicy: {},
};

export const parkingReducer: Reducer<ParkingState, GroupWorkflowAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case TestGroupWorkflowRequest:
      return { ...state, isTesting: true };
    case TestGroupWorkflowResponse:
      return { ...state, isTesting: false, resources: action.resources };

    case ListGroupWorkflowRequest:
      return { ...state, isFetchingList: true };
    case ListGroupWorkflowResponse:
      return { ...state, isFetchingList: false, data: action.data };

    case SaveGroupWorkflowRequest:
      return { ...state, isSaving: true };
    case SaveGroupWorkflowResponse:
      return { ...state, isSaving: false };

    case GetGroupWorkflowRequest: {
      let { childWfMap = {} } = state;
      childWfMap = {
        ...childWfMap,
        [action.group]: { isFetchingWf: true, workflow: null },
      };
      return { ...state, childWfMap };
    }

    case SetGroupWorkflowDeletedResponse: {
      let childWfMap = JSON.parse(JSON.stringify(state.childWfMap));
      delete childWfMap[action.group];
      return { ...state, childWfMap };
    }

    case GetGroupWorkflowResponse: {
      let { childWfMap = {} } = state;
      childWfMap = {
        ...childWfMap,
        [action.group]: { isFetchingWf: false, workflow: action.data },
      };
      return { ...state, childWfMap };
    }

    case FetchBatchHistoryRequest: {
      return { ...state, batchFetching: true };
    }
    case SaveFetchingGroupsId: {
      return {
        ...state,
        fetchingGroupsHistory: [...state.fetchingGroupsHistory, ...action.data],
      };
    }
    case SaveBatchHistoryResponse: {
      const newHistory: {} = Object.assign({}, ...action.data);
      const history = { ...state.history, ...newHistory };
      const fetchingGroupsHistory = state.fetchingGroupsHistory.filter(
        (group) => !history[group],
      );
      return {
        ...state,
        history: {
          ...history,
        },
        fetchingGroupsHistory,
      };
    }
    case FetchBatchHistoryResponse: {
      return { ...state, batchFetching: false };
    }

    case 'GetHistory':
      return { ...state, isFetchingHistory: true };
    case 'GetHistorySuccess':
      return {
        ...state,
        isFetchingHistory: false,
        history: {
          ...state.history,
          [action.group]: action.data
            ? action.data
            : state.history[action.group],
        },
      };
    case 'GroupPolicyRequest':
      return { ...state, isFetchGroupPolicy: true };
    case 'GroupPolicyResponse':
      return {
        ...state,
        isFetchGroupPolicy: false,
        groupPolicy: {
          ...state.groupPolicy,
          [action.group]: action.data,
        },
      };
    case 'MultiGroupPolicyRequest':
      return { ...state, isFetchingCombinedPolicy: true };
    case 'MultiGroupPolicyResponse':
      return {
        ...state,
        isFetchingCombinedPolicy: false,
        multiGroupPolicy: {
          ...state.multiGroupPolicy,
          [action.group]: action.data,
        },
      };
    default:
      return state;
  }
};
