import * as React from 'react';
import { FormItem, StyledInput } from '../../../lib/editor/StyledEntities';

import { Col, Label, Modal, Row } from 'reactstrap';
import InputText from '../../../lib/formInputs/input-text.component';
import { TextArea } from '../../../lib/formInputs/textarea.component';
import { ModalWrapper } from './ModalWrapper';
import Categories from './TemplateModalContentCategoriesUi';
import { SectionLoader } from '../../common/SectionLoader';
import InputSelect from '../../../lib/formInputs/select-option.component';
import { SEVERITY } from 'workflow-model/dist';
import { Template } from '../../../lib/store/templates/types';
import MultiSelect from '../../../lib/formInputs/multi-select.component';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const awsAccountTypeOptions = [
  { label: CONFIG_TYPES.AWS_KEY, value: CONFIG_TYPES.AWS_KEY },
  { label: CONFIG_TYPES.AWS_ROLE, value: CONFIG_TYPES.AWS_ROLE },
  { label: CONFIG_TYPES.AWS_ORG, value: CONFIG_TYPES.AWS_ORG },
  { label: CONFIG_TYPES.AWS_ORG_MEMBER, value: CONFIG_TYPES.AWS_ORG_MEMBER },
  { label: CONFIG_TYPES.AWS_PROXY, value: CONFIG_TYPES.AWS_PROXY },
];

const allowedAccountTypeOptions = [
  ...awsAccountTypeOptions,
  { label: CONFIG_TYPES.AZURE, value: CONFIG_TYPES.AZURE },
  { label: CONFIG_TYPES.VMWARE, value: CONFIG_TYPES.VMWARE },
  { label: CONFIG_TYPES.Kubernetes, value: CONFIG_TYPES.Kubernetes },
];
interface TemplatePopupProps {
  templateDef: Template;
  isFetching?: boolean;
  closePopup: (data) => void;
  onSave: (data?: any) => void;
  onSaveAndPublish: Function;
  categories: Array<string>;
  subCategories: Array<string>;
}
interface TemplatePopupState {
  id: string | null;
  name: string;
  description: string;
  benefits: string;
  category: Array<string>;
  subCategory: Array<string>;
  properties: any;
}

export default class TemplateModalContent extends React.Component<
  TemplatePopupProps,
  TemplatePopupState
> {
  state = {
    multiSelect: [],
    id: null,
    name: '',
    description: '',
    benefits: '',
    category: [] as Array<string>,
    subCategory: [] as Array<string>,
    properties: {},
  };

  componentDidMount(): void {
    this.populateStateFromProps();
  }

  //todo refactor this file
  populateStateFromProps() {
    if (this.props.templateDef) {
      let params: any = this.props.templateDef;
      for (let key in params) {
        if (params[key] === null || params[key] == undefined) {
          delete params[key];
        }
      }
      params.properties = Object.assign(
        {},
        { active: false },
        params.properties,
      );

      params.category = params.category || [];
      params.subCategory = params.subCategory || [];

      this.setState({ ...params });
    }
  }

  onNameChange(event) {
    const { value } = event.target;
    this.setState({ name: value });
  }

  onNodesDescriptionChange(variableName, event) {
    const { value } = event.target;
    let properties = this.state.properties || {};
    properties['nodeDescription'] = Object.assign(
      {},
      properties['nodeDescription'] || {},
      { [variableName]: value },
    );
    this.setState({ properties });
  }
  onSampleOutputChange(event) {
    const { value } = event.target;
    let properties = this.state.properties || {};
    properties['sampleOutput'] = value;
    this.setState({ properties });
  }

  onAllowedAccountTypeChange = (values) => {
    if (!values) values = [];
    let properties = this.state.properties || {};
    properties['allowedAccountTypes'] = values.map((x) => x.value);
    this.setState({ properties });
  };
  onPriorityChange = (event) => {
    const { value } = event.target;
    let properties = this.state.properties || {};
    properties['priority'] = Number.parseInt(value) || 0;
    this.setState({ properties });
  };
  onSeverityChange = (event) => {
    const { value } = event.target;
    let properties = this.state.properties || {};
    properties['severity'] = value;
    this.setState({ properties });
  };
  onPropertiesChange(propKey, event) {
    const { value } = event.target;
    let properties = this.state.properties || {};
    properties[propKey] = value;
    this.setState({ properties });
  }
  onDescriptionChange(event) {
    const { value } = event.target;
    this.setState({ description: value });
  }

  onCategoryChange(option) {
    this.setState({ category: option ? option : [] });
  }

  onSubCategoryChange(option) {
    this.setState({ subCategory: option ? option : [] });
  }

  renderCmsItem = () => {
    const { properties } = this.state;
    return (
      <Row>
        <Col md={5}>
          <TextArea
            name={'webflow'}
            label={'Webflow details'}
            rows={6}
            placeHolder="Enter template's sample output"
            onChange={(event) => {
              let { value } = event.target;
              try {
                let { slug, cmsItemId, publishedOn } = JSON.parse(value);
                properties['webflow'] = { slug, cmsItemId, publishedOn };
                this.setState({ properties });
              } catch (error) {
                console.error(error);
              }
            }}
            value={JSON.stringify(properties['webflow'] || {}, null, 4)}
          />
        </Col>
      </Row>
    );
  };

  renderNodeDescription() {
    const { properties } = this.state;
    let nodeDescription = properties['nodeDescription'];
    return (
      <Row>
        <Col md={10}>
          <div className="label " style={{ fontSize: '30px' }}>
            Nodes:
          </div>
          {nodeDescription &&
            Object.keys(nodeDescription).map((x) => {
              return (
                <Row key={'node-' + x}>
                  <Col md={12}>
                    <TextArea
                      rows={2}
                      label={x}
                      name={'snode-' + x}
                      key={'snode-' + x}
                      value={nodeDescription[x]}
                      placeHolder="Enter Node's Description"
                      onChange={this.onNodesDescriptionChange.bind(this, x)}
                    />
                  </Col>
                </Row>
              );
            })}
        </Col>
      </Row>
    );
  }
  renderSampleOutput() {
    const { properties } = this.state;
    let sampleOutput = properties['sampleOutput'];
    if (!sampleOutput) sampleOutput = '';
    return (
      <Row>
        <Col md={10}>
          <TextArea
            name={'sample_output'}
            label={'Sample Output'}
            rows={2}
            placeHolder="Enter template's sample output"
            onChange={this.onSampleOutputChange.bind(this)}
            value={sampleOutput}
          />
        </Col>
      </Row>
    );
  }
  renderPriorityAndSeverity() {
    const { properties } = this.state;
    let { severity, priority } = properties as any;
    if (!severity) severity = SEVERITY.LOW;
    if (!priority) priority = 1;
    return (
      <>
        <Row>
          <Col md={5}>
            <InputSelect
              name={'severity'}
              label={'Severity'}
              onChange={this.onSeverityChange}
              value={severity}
              options={[
                { label: 'Low', value: SEVERITY.LOW },
                { label: 'MEDIUM', value: SEVERITY.MEDIUM },
                { label: 'HIGH', value: SEVERITY.HIGH },
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <InputText
              value={priority}
              name={'priority'}
              label={'Priority'}
              onChange={this.onPriorityChange}
            />
          </Col>
        </Row>
      </>
    );
  }

  renderProperties() {
    const { properties } = this.state;
    return (
      <div>
        <FormItem>
          <div className="label">Properties</div>
          {properties &&
            Object.keys(properties).map((x) => {
              if (
                x === 'nodeDescription' ||
                x === 'webflow' ||
                x === 'sampleOutput' ||
                x === 'allowedAccountTypes'
              )
                return;
              let isReadonlyValue = false;
              if (x === 'originUser') isReadonlyValue = true;

              return (
                <div key={'div-' + x}>
                  <StyledInput
                    readOnly={true}
                    key={'key-' + x}
                    placeholder="Enter Key"
                    value={x}
                    style={{ width: '200px' }}
                  />
                  <StyledInput
                    readOnly={isReadonlyValue}
                    key={'value-' + x}
                    placeholder="Enter Value"
                    value={properties[x]}
                    onChange={this.onPropertiesChange.bind(this, x)}
                    style={{ width: '250px' }}
                  />
                </div>
              );
            })}
        </FormItem>
      </div>
    );
  }

  renderAllowedAccountType = () => {
    const { properties } = this.state;
    let { allowedAccountTypes } = properties as any;
    if (!allowedAccountTypes) allowedAccountTypes = []; //awsAccountTypeOptions.map((x) => x.value);
    return (
      <>
        <Row>
          <Col md={5}>
            <MultiSelect
              name={'allowedAccountTypes'}
              label={'Allowed Account Types'}
              options={allowedAccountTypeOptions}
              value={allowedAccountTypes}
              onChange={this.onAllowedAccountTypeChange}
            />
          </Col>
        </Row>
      </>
    );
  };

  renderContent = () => {
    let { name, description, benefits, category, subCategory } = this.state;
    return (
      <div>
        <Row>
          <Col md={9}>
            <div className="label" />
            <InputText
              name={'template_name'}
              label={'Template Name'}
              placeholder="Enter Template Name"
              value={name}
              onChange={this.onNameChange.bind(this)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <Label> Description</Label>
            <ReactQuill
              value={description}
              onChange={(content) => {
                this.setState({ description: content });
              }}
            />
          </Col>
        </Row>
        {this.renderPriorityAndSeverity()}
        <Categories
          categories={category}
          subCategories={subCategory}
          onSubCategoryChange={this.onSubCategoryChange.bind(this)}
          onCategoryChange={this.onCategoryChange.bind(this)}
        />

        {this.renderNodeDescription()}
        {this.renderAllowedAccountType()}
        {this.renderSampleOutput()}
        {this.renderCmsItem()}
        {this.renderProperties()}
      </div>
    );
  };

  render() {
    return (
      <Modal
        size={'xl'}
        isOpen={true}
        toggle={this.props.closePopup}
        backdrop={'static'}
      >
        <ModalWrapper
          title={'Convert To Template'}
          onCancel={() => this.props.closePopup(null)}
          onSubmit={
            this.props.isFetching
              ? () => {}
              : this.props.onSave.bind(null, this.state)
          }
          onPublishAndSubmit={
            this.props.isFetching
              ? () => {}
              : this.props.onSaveAndPublish.bind(null, this.state)
          }
        >
          {this.props.isFetching ? <SectionLoader /> : this.renderContent()}
        </ModalWrapper>
      </Modal>
    );
  }
}
