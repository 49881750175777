import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../../Colors';

const AbsoluteDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;
const FixedDiv = styled(AbsoluteDiv)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
`;
const Spin = keyframes`
0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
const Loader = styled.div`
  border: 4px solid ${Colors.palette.bgPrimary};
  border-radius: 50%;
  border-top: 4px solid transparent;
  width: 40px;
  height: 40px;
  animation: ${Spin} 1s linear infinite;
`;
export const ComponentOverlayLoader = () => (
  <AbsoluteDiv>
    <Loader />
  </AbsoluteDiv>
);

export const PageLoader = () => (
  <FixedDiv>
    <Loader className="test-page-loader" />
  </FixedDiv>
);
