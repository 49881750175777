import api from '../../../api';

import {
  ApiOperation,
  GET_SUPER_TABLE_DATA,
  GET_SUPER_TABLE_DATA_FAILED,
  GET_SUPER_TABLE_DATA_SUCCESS,
  SUPER_TABLE_ACTION_FAILED,
  SUPER_TABLE_ACTION_REQUEST,
  SUPER_TABLE_ACTION_SUCCESS,
  SuperApiProps,
  SuperApiProvider,
} from './types';

export const getSuperTableData = (payload: {
  category: string;
  subCategory: string;
}): GET_SUPER_TABLE_DATA => {
  return {
    type: GET_SUPER_TABLE_DATA,
    payload,
  };
};

export const getSuperTableDataSuccess = (payload: {
  category: string;
  subCategory: string;
  data: any;
}): GET_SUPER_TABLE_DATA_SUCCESS => ({
  type: GET_SUPER_TABLE_DATA_SUCCESS,
  payload,
});

export const getSuperTableDataFailed = (payload: {
  category: string;
  subCategory;
  data: string;
}): GET_SUPER_TABLE_DATA_FAILED => ({
  type: GET_SUPER_TABLE_DATA_FAILED,
  payload,
});

export const superTableActionRequest = (payload: {
  category: string;
  subCategory: string;
  data: any;
}): SUPER_TABLE_ACTION_REQUEST => {
  return {
    type: SUPER_TABLE_ACTION_REQUEST,
    payload,
  };
};

export const superTableActionSuccess = (payload: {
  category: string;
  subCategory: string;
  data: any;
}): SUPER_TABLE_ACTION_SUCCESS => ({
  type: SUPER_TABLE_ACTION_SUCCESS,
  payload,
});

export const superTableActionFailed = (payload: {
  category: string;
  subCategory;
  data: string;
}): SUPER_TABLE_ACTION_FAILED => ({
  type: SUPER_TABLE_ACTION_FAILED,
  payload,
});

export const superApiCall = async (
  props: SuperApiProps,
  apiProvider: SuperApiProvider = api,
) => {
  if (props.operation === ApiOperation.ACTION) {
    await apiProvider.doSuperTableAction(props);
  }
  if (props.operation === ApiOperation.GET) {
    await get(props, apiProvider);
  }
};

const get = async (props: SuperApiProps, apiProvider: SuperApiProvider) => {
  const { dispatch, category, options, subCategory } = props;
  dispatch(getSuperTableData({ category, subCategory }));
  apiProvider
    .getSuperTableData({ category, subCategory, params: options })
    .then((data) => {
      dispatch(getSuperTableDataSuccess({ category, subCategory, data }));
    })
    .catch((err) => {
      dispatch(
        getSuperTableDataFailed({ category, subCategory, data: err.message }),
      );
    });
};

const getDataFromExt = async (data: any) => {
  const res = await fetch(
    'https://zmrxxmrk60.execute-api.us-east-1.amazonaws.com/dev/super-api',
    {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'x-api-key': 'cChU2mUAMmY8xJ6XYaCKe3bG',
      },
    },
  );

  return await res.json();
};
