import { FormikProps } from 'formik';
import { TriggerSchema } from '../schema';
import { ToggleButton } from './ToggleActiveState';
import React, { Component } from 'react';

import { IWorkflow } from 'workflow-model/dist';

interface IProps extends FormikProps<TriggerSchema> {
  workflowModel: IWorkflow;
}

export class ManualForm extends Component<IProps> {
  render() {
    return (
      <>
        <ToggleButton isActive={true} toggleActive={() => {}} disabled={true} />
      </>
    );
  }
}
