import React from 'react';
import { AppState } from '../../lib/store';
import { Dispatch } from 'redux';
import { fetchResourceGroup } from '../../lib/store/config/action';
import { connect } from 'react-redux';

interface StateProps {
  isFetchingResourceGroup: boolean;
  resourceGroup: string[];
}

type OwnProps = React.PropsWithChildren<{
  configId: string;
}>;

interface DispatchProps {
  fetchResourceGroup: () => void;
}

type IProps = StateProps & DispatchProps & OwnProps;

export class ResourceGroupsFactoryInner extends React.Component<IProps> {
  fetchResourceGroups = () => {
    if (!this.props.configId) return;
    this.props.fetchResourceGroup();
  };

  componentDidMount(): void {
    this.fetchResourceGroups();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.configId !== this.props.configId) {
      this.fetchResourceGroups();
    }
  }

  render() {
    let { isFetchingResourceGroup, resourceGroup = [] } = this.props;
    if (isFetchingResourceGroup) {
      resourceGroup = ['Loading ....'];
    }
    // console.log('availableResourceGroups', resourceGroup);
    // @ts-ignore
    return this.props.children({ availableResourceGroups: resourceGroup });
  }
}

const mapStateToProps = (state: AppState, props: OwnProps): StateProps => {
  let { resourceGroupData } = state.config;
  return {
    isFetchingResourceGroup:
      resourceGroupData[props.configId] &&
      resourceGroupData[props.configId].isFetchingResourceGroup,
    resourceGroup: resourceGroupData[props.configId]
      ? resourceGroupData[props.configId].data
      : [],
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: OwnProps,
): DispatchProps => {
  return {
    fetchResourceGroup: () => fetchResourceGroup(dispatch, ownProps.configId),
  };
};

export const ResourceGroupsFactory = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ResourceGroupsFactoryInner);
