import React from 'react';
import { Button, Col, Modal, Row } from 'reactstrap';

import Api from '../../../api';
import { getAutoCompleteList } from '../../../lib/editor/components/ui/nodeUI/helpers/Utils';
import {
  SchemaValidationError,
  TcJsonEditor,
} from '../../../lib/formInputs/json-editor';
import { getErrorObj } from '../../../lib/editor/components/ui/nodeUI/action/ActionForm';
import { notify } from '../../common/toaster';
import InputText from '../../../lib/formInputs/input-text.component';
import { Config } from '../../../lib/store/config/types';
import { getDisplayName } from 'workflow-model/dist/helper/utils';
import { NODE_TYPES, NODE_CONFIG } from 'workflow-model/dist';
import classnames from 'classnames';
import SplitPane from 'react-split-pane';
import HelpSection from '../../../lib/editor/components/ui/common/HelpSection';
import LineLoader from '../../common/LineLoader';
import pluralize from 'pluralize';

interface IProps {
  service: string;
  action: string;
  inputJson: any;
  map: any;
  data: any;
  onClose: () => void;
  configs: Config[];
  workflowAccount: string;
  workflowRegion: string;
  selectedResource: string;
  workflowId?: string;
  onActionPerformed?: (actionResponseData: any) => void;
}

interface IState {
  mappedParams: any;
  mappedData: any;
  isFetching: boolean;
  isMapping: boolean;
  outputKeys: string[];
  error: boolean;
  executionUrl: string;
  typingTimeout: any;
}

export class ActionsModal extends React.Component<IProps, IState> {
  state = {
    isFetching: false,
    isMapping: true,
    mappedParams: this.props.inputJson,
    mappedData: [],
    outputKeys: [],
    error: false,
    executionUrl: '',
    typingTimeout: 0,
  };

  getMappedParam = async (inputJson) => {
    let { service, action, data, map = {} } = this.props;
    let mapperResponse = await Api.mapParamsForAliasData({
      service,
      method: action,
      data,
      inputJson,
      map,
    }).catch((x) => {
      notify({ message: x.message, type: 'error' });
    });
    if (!mapperResponse) {
      this.setState({ isMapping: false });
      return;
    }
    let { mappedParams, mappedData, outputKeys = [] } = mapperResponse;
    this.setState({ isMapping: false, mappedParams, mappedData, outputKeys });
  };

  componentDidMount(): void {
    this.setState({ isMapping: true });

    this.getMappedParam(this.props.inputJson);
  }

  onSchemaValidation = async (params): Promise<SchemaValidationError[]> => {
    try {
      let { service, action } = this.props;
      if (params.error) return [];
      let { isValid, message } = await Api.validateParams({
        service,
        method: action,
        params,
        variables: {},
      });
      if (isValid) return [];
      let errors = message
        .split('\n')
        .map(getErrorObj)
        .filter((x) => !!x);
      return errors;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  performAction = async () => {
    this.setState({ isFetching: true, executionUrl: '' });
    let res = await Api.customWorkflowAction({
      inputData: this.props.data,
      nodeType: NODE_TYPES.ACTION,
      target: NODE_CONFIG[NODE_TYPES.ACTION].target,
      account: this.props.workflowAccount,
      region: this.props.workflowRegion,
      workflowId: this.props.workflowId,
      inputs: {
        service: this.props.service,
        method: this.props.action,
        params: this.state.mappedParams,
      },
    }).catch((x) => {
      notify({ message: x.message, type: 'error' });
    });
    if (res && res.workflow) {
      this.props.onActionPerformed && this.props.onActionPerformed(res);
      this.setState({
        isFetching: false,
        executionUrl: `${window.location.origin}/workflow/${res.workflow}/details`,
      });
    }
  };

  onUserInput = ({ data, error }): void => {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      mappedParams: data,
      error,
      typingTimeout: setTimeout(function() {
        self.getMappedParam(data);
      }, 1000),
    });
  };

  render() {
    let autoCompletionList = getAutoCompleteList({
      lastNodeOutputKeys: this.state.outputKeys.map((x) => ({
        name: x,
        value: x,
      })),
    });
    let paramsMappingLabel = 'Params Mapping ';
    if (this.props.selectedResource)
      paramsMappingLabel +=
        '(Each ' +
        pluralize.singular(this.props.selectedResource) +
        ' is referred as obj )';
    return (
      <div>
        <Modal
          className={'tc-editor-modal'}
          size={'xl'}
          isOpen={true}
          toggle={this.props.onClose}
          backdrop={'static'}
        >
          <Row noGutters={true}>
            <Col md={12}>
              <SplitPane
                split="vertical"
                defaultSize={'30%'}
                style={{ position: 'relative' }}
                primary="second"
              >
                <div className={'p-4'}>
                  {/*header*/}
                  <Row>
                    <Col>
                      <h4>{`Action will be performed on each of selected ${pluralize.singular(
                        this.props.selectedResource,
                      )}`}</h4>
                    </Col>
                  </Row>

                  {/*body*/}
                  <Row className={classnames('border-top', 'border-bottom')}>
                    <Col className={classnames('node-ui-modal-body')}>
                      <>
                        <Row className={'pl-3 my-2'}>
                          <Col md={3}>
                            <InputText
                              label={'Service'}
                              name={'service'}
                              value={this.props.service}
                              readOnly={true}
                            />
                          </Col>
                          <Col md={3}>
                            <InputText
                              label={'Action'}
                              name={'action'}
                              value={getDisplayName(this.props.action)}
                              readOnly={true}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.isMapping || this.state.isFetching ? (
                              <>
                                <LineLoader />
                                <TcJsonEditor
                                  label={paramsMappingLabel}
                                  autoCompletionList={autoCompletionList}
                                  onChange={this.onUserInput}
                                  name="Params"
                                  error={this.state.error ? 'Invalid Json' : ''}
                                  onSchemaValidation={this.onSchemaValidation}
                                  value={{
                                    data: this.state.mappedParams,
                                    error: this.state.error,
                                  }}
                                />
                              </>
                            ) : (
                              <TcJsonEditor
                                label={paramsMappingLabel}
                                autoCompletionList={autoCompletionList}
                                onChange={this.onUserInput}
                                name="Params"
                                error={this.state.error ? 'Invalid Json' : ''}
                                onSchemaValidation={this.onSchemaValidation}
                                value={{
                                  data: this.state.mappedParams,
                                  error: this.state.error,
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.executionUrl && (
                              <>
                                <span>
                                  {' '}
                                  Check the status of last action performed:
                                </span>
                                <Button
                                  color={'link'}
                                  onClick={() =>
                                    window.open(this.state.executionUrl)
                                  }
                                >
                                  {this.state.executionUrl}
                                </Button>
                              </>
                            )}
                          </Col>
                        </Row>
                      </>
                    </Col>
                  </Row>

                  {/*footer*/}
                  <Row
                    className={classnames(
                      'd-flex',
                      'justify-content-start pt-2',
                    )}
                  >
                    <Col md={4}>
                      <Button
                        color="primary"
                        block={true}
                        disabled={this.state.isFetching || this.state.isMapping}
                        onClick={this.performAction}
                      >
                        Run Action
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Button
                        color="primary"
                        outline={true}
                        block={true}
                        onClick={this.props.onClose}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row>
                </div>
                {/*helpBar*/}
                <div
                  className={classnames(
                    'px-4',
                    'pt-4',
                    'bg-help',
                    'text-dark',
                    'help-section',
                  )}
                >
                  {/*help header*/}
                  <HelpSection
                    helpText={actionHelpText()}
                    rawJsonData={JSON.stringify(this.state.mappedData, null, 3)}
                  />
                </div>
              </SplitPane>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const actionHelpText = () => {
  return (
    <>
      <ul>
        <li>
          This action will affect the resources selected in the previous step
        </li>
        <li>
          A link with the execution details will be generated once the action is
          completed
        </li>
        <li>Check the actual mapping for resources in JSON section</li>
        <li>Note: Once the action is completed, it cannot be reversed</li>
      </ul>
    </>
  );
};
