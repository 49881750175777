import * as React from 'react';
import { getHelpComponent } from '../../common/commonHelp';

export const actionHelpText = (
  <>
    {getHelpComponent({
      heading: 'Action Node',
      para1:
        'The Action Node performs an action on the AWS cloud. This node can access every actionable AWS API that’s available. For example, startInstance, deleteBucket, etc..',
      para2: '',
      para3: '',
      params: [
        {
          key: 'Select resources to perform action on',
          description:
            "Dropdown list of input resources that you need to perform the action. You could also choose 'NONE' if there's no input to the action node.",
        },
        {
          key: 'Params Mapping',
          description: 'The paramters to the action.',
        },
      ],
      examples: [
        'Use this node to ask for approval before stopping an EC2 instance or a set of EC2 instances.',
      ],
      notes: [
        "Access attributes of each of the input resources in the params mapping by using 'obj.<param>'. For example, obj.InstanceId if you need to access the instance id of EC2 instances passed into this node.",
        'Some actions give you an option to wait for the action to finish before continuing to the next node in the workflow.',
        'Action Node is relevant to AWS only, other clouds have their corresponding action nodes.',
      ],
      moreLink: 'https://docs.totalcloud.io/workflows/nodes/action-node',
    })}
  </>
);
