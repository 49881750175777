import * as React from 'react';
import { StyledDropdownItem } from './styledElements';
import { exportAsPng, pdfConvert } from './pdf';
import classNames from 'classnames';
import {
  Button,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { downloadAsCSV } from './csv';

export const rootContainerId = 'reportRootContainer';

type IProps = { data?: any };
type IState = { isOpen: boolean; downloadActionIsPending: boolean };

export default class DownloadMenu extends React.Component<IProps, IState> {
  state: IState = {
    isOpen: false,
    downloadActionIsPending: false,
  };

  toggle() {
    let last = this.state && this.state.isOpen;
    this.setState({ isOpen: !last });
  }

  async wrap(action: (args?) => {}, args?: any) {
    this.setState({ downloadActionIsPending: true });
    await action(args);
    this.setState({ downloadActionIsPending: false });
  }

  render(): React.ReactNode {
    // if (this.state.downloadActionIsPending) return null;
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle.bind(this)}>
        <DropdownToggle
          color="primary"
          size="sm"
          caret
          disabled={this.state.downloadActionIsPending}
        >
          <i
            className={classNames({
              'fa mr-2': true,
              'fa-download': !this.state.downloadActionIsPending,
              'fa-spinner fa-spin': this.state.downloadActionIsPending,
            })}
          />
          Save
        </DropdownToggle>
        <DropdownMenu>
          <StyledDropdownItem
            onClick={() => this.wrap(pdfConvert, rootContainerId)}
          >
            PDF
          </StyledDropdownItem>
          <StyledDropdownItem
            onClick={() => this.wrap(exportAsPng, rootContainerId)}
          >
            PNG
          </StyledDropdownItem>
          <StyledDropdownItem
            onClick={() => this.wrap(downloadAsCSV, this.props.data)}
          >
            CSV
          </StyledDropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
