import * as React from 'react';
import { PageLoader } from '../../common/loaders';
import { Workflow } from '../../../lib/store/workflows/types';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { TextArea } from '../../../lib/formInputs/textarea.component';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { fetchPolicy } from '../../../lib/store/workflows/action';
import { connect } from 'react-redux';
import { SectionLoader } from '../../common/SectionLoader';

interface OwnProps {
  workflow: string;
  onClose: () => void;
}

interface DispatchProps {
  fetchPolicy: () => void;
}

interface StateProps {
  isFetching: boolean;
  data: any;
}
interface OwnState {
  isOpen: boolean;
}

type IProps = OwnProps & DispatchProps & StateProps;

export default class WorkflowPolicyInner extends React.Component<
  IProps,
  OwnState
> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount(): void {
    if (!document.body.classList.contains('modal-open'))
      document.body.classList.add('modal-open');

    document.body.classList.remove('pr-0');
    document.body.style.paddingRight = '15px';
    this.props.fetchPolicy();
  }

  toggle() {
    this.setState(
      (prevState) => ({
        isOpen: !prevState.isOpen,
      }),
      () => {
        document.body.classList.remove('modal-open');
        document.body.classList.add('pr-0');
        this.props.onClose();
      },
    );
  }

  render() {
    const loading = !this.props.data && this.props.isFetching;

    let permission = JSON.stringify(this.props.data, null, 4);

    return (
      <Modal
        className="test-policy-popup"
        isOpen={this.state.isOpen}
        toggle={this.toggle}
        size={'lg'}
      >
        <ModalHeader toggle={this.toggle}>Required Policies</ModalHeader>
        <ModalBody>
          {loading && <SectionLoader />}
          {!loading && (
            <Row>
              <Col md={12}>
                <TextArea
                  className={'test-policy-textarea'}
                  label={'Policy required to run this workflow'}
                  rows={10}
                  name={'policy_div'}
                  readOnly={true}
                  defaultValue={permission}
                  value={permission}
                />
              </Col>
            </Row>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetching:
      state.workflows.policies[ownProps.workflow] &&
      state.workflows.policies[ownProps.workflow].fetching,
    data:
      state.workflows.policies[ownProps.workflow] &&
      state.workflows.policies[ownProps.workflow].data &&
      state.workflows.policies[ownProps.workflow].data.recommended,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchPolicy: () => fetchPolicy({ dispatch, workflow: ownProps.workflow }),
  };
}

export const WorkflowPolicy = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(WorkflowPolicyInner);
