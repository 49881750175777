import { Workflow } from '../../../lib/store/workflows/types';
import * as React from 'react';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { fetchWorkflow } from '../../../lib/store/workflows/action';
import WorkflowsContainer from './workflowsList';
import { SectionLoader } from '../../common/SectionLoader';

interface StateProps {
  workflows: Workflow[];
  isFetching: boolean;
}

interface DispatchProps {
  getWorkflows: () => void;
}

interface OwnProps {}

type IProps = StateProps & DispatchProps & OwnProps;

export class WorkflowInterceptInner extends React.Component<IProps> {
  componentDidMount(): void {
    this.props.getWorkflows();
  }

  render(): React.ReactNode {
    let loading =
      this.props.isFetching &&
      !(this.props.workflows && this.props.workflows.length);
    if (loading) {
      return <SectionLoader />;
    }

    return <WorkflowsContainer workflowData={this.props.workflows} />;
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetching: state.workflows.fetching,
    workflows: state.workflows.data || [],
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    getWorkflows: () => fetchWorkflow({ dispatch }),
  };
}

export const WorkflowIntercept = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(WorkflowInterceptInner);
