import { Workflow } from '../../../../lib/store/workflows/types';
import { Config } from '../../../../lib/store/config/types';
import {
  getAllAccountIdsFromWorkflow,
  getWorkflowAccount,
} from '../../../../util/commonUtils';
import { CONFIG_TYPES } from 'webapp-genric/dist';
import {
  getResultCount,
  InsightSummaryResult,
} from '../summaryFormatter/insightSummary';
import { getServiceFromCategory } from '../formatter/ServiceColFormatter';

type GroupedWorkflowsObject = {
  label: string;
  isLeaf?: boolean;
  values: GroupedWorkflowsObject[] | Workflow[];
};

const getServiceLevelData = (workflows: Workflow[]) => {
  let serviceMap = {};
  for (let wf of workflows) {
    let service = getServiceFromCategory(
      wf.properties && wf.properties.categories,
    );
    serviceMap[service] = serviceMap[service] || [];
    serviceMap[service].push(wf);
  }

  return Object.keys(serviceMap)
    .map((x) => ({ label: x, values: serviceMap[x] }))
    .sort((a, b) => {
      return b.values.length - a.values.length;
    })
    .slice(0, 3);
};

export const getServiceTableData = (
  workflows: Workflow[],
  configs: Config[],
  result: (InsightSummaryResult & {
    serviceLabel: string;
    accountLevelData: InsightsSummaryTableData[];
  })[] = [],
): (InsightSummaryResult & {
  serviceLabel: string;
  accountLevelData: InsightsSummaryTableData[];
})[] => {
  let serviceLevelData = getServiceLevelData(workflows);
  for (let obj of serviceLevelData) {
    let data = getResultCount(obj.values);
    let groupedObj = getGroupedWorkflows({ workflows: obj.values, configs });
    let accountLevelData = getSummaryTableData(groupedObj);
    result = result.concat({
      serviceLabel: obj.label,
      ...data,
      accountLevelData,
    });
  }
  return result;
};

export const getGroupedWorkflows = (params: {
  workflows: Workflow[];
  configs: Config[];
}): GroupedWorkflowsObject[] => {
  let { workflows, configs } = params;
  let awsOrgsMap = {};
  let awsAccounts = {};
  let azureAccounts = {};
  let otherAccountGroupMap = {};
  let other = [];
  for (let wf of workflows) {
    let account = getWorkflowAccount(wf);
    let configObj = account && configs.find((x) => x.id === account);
    if (!configObj) {
      other.push(wf);
      continue;
    }
    switch (configObj.type) {
      case CONFIG_TYPES.AWS_ORG_MEMBER: {
        let parentName = configObj.parentName;
        if (!parentName) break;
        awsOrgsMap[parentName] = awsOrgsMap[parentName] || {};
        awsOrgsMap[parentName][configObj.id] =
          awsOrgsMap[parentName][configObj.id] || [];
        awsOrgsMap[parentName][configObj.id].push(wf);
        break;
      }
      case CONFIG_TYPES.AWS_PROXY:
      case CONFIG_TYPES.AWS_KEY:
      case CONFIG_TYPES.AWS_ROLE: {
        if (configObj.parentName) {
          otherAccountGroupMap[configObj.parentName] =
            otherAccountGroupMap[configObj.parentName] || {};
          otherAccountGroupMap[configObj.parentName][configObj.id] =
            otherAccountGroupMap[configObj.parentName][configObj.id] || [];
          otherAccountGroupMap[configObj.parentName][configObj.id].push(wf);
        } else {
          awsAccounts[configObj.id] = awsAccounts[configObj.id] || [];
          awsAccounts[configObj.id].push(wf);
        }
        break;
      }
      case CONFIG_TYPES.AZURE: {
        if (configObj.parentName) {
          otherAccountGroupMap[configObj.parentName] =
            otherAccountGroupMap[configObj.parentName] || {};
          otherAccountGroupMap[configObj.parentName][configObj.id] =
            otherAccountGroupMap[configObj.parentName][configObj.id] || [];
          otherAccountGroupMap[configObj.parentName][configObj.id].push(wf);
        } else {
          azureAccounts[configObj.id] = azureAccounts[configObj.id] || [];
          azureAccounts[configObj.id].push(wf);
        }
      }
    }
  }
  let result = [];
  if (Object.keys(awsAccounts).length !== 0)
    result.push({
      label: 'AWS Accounts',
      values: Object.keys(awsAccounts).map((x) => {
        return {
          label: configs.find((c) => c.id === x).name,
          values: awsAccounts[x],
          isLeaf: true,
        };
      }),
    });
  if (Object.keys(awsOrgsMap).length !== 0) {
    result.push({
      label: 'AWS Organisation',
      values: Object.keys(awsOrgsMap).map((x) => {
        return {
          label: x,
          values: Object.keys(awsOrgsMap[x]).map((a) => {
            return {
              label: configs.find((c) => c.id === a).name,
              values: awsOrgsMap[x][a],
              isLeaf: true,
            };
          }),
        };
      }),
    });
  }
  if (Object.keys(azureAccounts).length !== 0)
    result.push({
      label: 'Azure Accounts',
      values: Object.keys(azureAccounts).map((x) => {
        return {
          label: configs.find((c) => c.id === x).name,
          values: azureAccounts[x],
          isLeaf: true,
        };
      }),
    });
  if (Object.keys(otherAccountGroupMap).length !== 0)
    result.push({
      label: 'Groups',
      values: Object.keys(otherAccountGroupMap).map((x) => {
        return {
          label: x,
          values: Object.keys(otherAccountGroupMap[x]).map((a) => {
            return {
              label: configs.find((c) => c.id === a).name,
              values: otherAccountGroupMap[x][a],
              isLeaf: true,
            };
          }),
        };
      }),
    });
  if (other.length !== 0)
    result.push({
      isLeaf: true,
      label: 'Other',
      values: other,
    });

  return result;
};

const getWorkflowsFromGroupObj = (groupedWfObj, workflows = []) => {
  if (groupedWfObj.isLeaf) return groupedWfObj.values;
  else {
    let arr: any[] = groupedWfObj.values;
    for (let a of arr) {
      let wfs = getWorkflowsFromGroupObj(a, []);
      workflows = workflows.concat(wfs);
    }
  }
  return workflows;
};

const getCrunchedOptions = (configOptions, result = []) => {
  for (let opt of configOptions) {
    if (!opt.options) result = result.concat(opt.value);
    else {
      return getCrunchedOptions(opt.options, result);
    }
  }
  return result;
};

const getAccountsIdsForParent = (configOptions = [], parentName) => {
  let index = configOptions.findIndex((x) => x.label === parentName);
  // console.log(configOptions[index], parentName)
  if (index !== -1) {
    return getCrunchedOptions(configOptions[index].options);
  }
  for (let opt of configOptions) {
    if (!opt.options) continue;
    let accounts = getAccountsIdsForParent(opt.options, parentName);
    if (accounts) return accounts;
  }
};

export const getSummaryBreadCrumbs = (
  groupedWfObj,
  configOptions,
  updateConfigAndRegion,
  changeToTableView,
  breadCrumbs = [],
) => {
  if (groupedWfObj.length == 1 && !groupedWfObj[0].isLeaf) {
    let label = groupedWfObj[0].label;
    let accountIds = getAccountsIdsForParent(configOptions, label);
    breadCrumbs.push({
      name: groupedWfObj[0].label,
      route: '/insights',
      onClick: () => {
        changeToTableView();
        updateConfigAndRegion('account', accountIds);
      },
    });
    return getSummaryBreadCrumbs(
      groupedWfObj[0].values,
      configOptions,
      updateConfigAndRegion,
      changeToTableView,
      breadCrumbs,
    );
  } else {
    return breadCrumbs;
  }
};

const getAccountIdsFromGroupObj = (
  groupedWfObj: GroupedWorkflowsObject,
  accountIds = [],
) => {
  if (groupedWfObj.isLeaf)
    return getAllAccountIdsFromWorkflow(groupedWfObj.values);
  else {
    let arr: any[] = groupedWfObj.values;
    for (let a of arr) {
      let accounts = getAccountIdsFromGroupObj(a, []);
      accountIds = accountIds.concat(accounts);
    }
  }
  return accountIds;
};

type InsightsSummaryTableData = InsightSummaryResult & {
  isLeaf: boolean;
  accountIds: string[];
  accountLabel: string;
};

export const getSummaryTableData = (
  groupedWfObj = [],
  result: InsightsSummaryTableData[] = [],
): InsightsSummaryTableData[] => {
  if (groupedWfObj.length === 1 && !groupedWfObj[0].isLeaf) {
    return getSummaryTableData(groupedWfObj[0].values);
  } else {
    for (let obj of groupedWfObj) {
      let wfs = getWorkflowsFromGroupObj(obj);
      let data = getResultCount(wfs);
      result = result.concat({
        isLeaf: obj.isLeaf,
        accountIds: getAccountIdsFromGroupObj(obj),
        accountLabel: obj.label,
        ...data,
      });
    }
  }
  return result;
};
