import * as yup from 'yup';
import { OrgGroup, OrgUser } from '../../lib/store/tenants/types';
import React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import InputText from '../../lib/formInputs/input-text.component';
import MultiSelect from '../../lib/formInputs/multi-select.component';
import { Config } from '../../lib/store/config/types';

type IProps = {
  addGroup: (name: string, users: OrgUser[], accounts: string[]) => void;
  users: OrgUser[];
  existingGroups: OrgGroup[];
  config: Config[];
};

type OrgGroupForm = {
  name: string;
  users: string[];
  accounts: string[];
};

export function AddGroup(props: IProps) {
  const schema = yup.object().shape<OrgGroupForm>({
    name: yup
      .string()
      .notOneOf(
        props.existingGroups.map((u) => u.name),
        'A Group already exists with same name',
      )
      .required(),
    users: yup.array().of(yup.string()),
    accounts: yup
      .array()
      .of(yup.string())
      .min(1, 'Select an account'),
  });

  const [isModelVisible, setModalVisible] = React.useState(false);
  const toggleModal = () => setModalVisible(!isModelVisible);
  const getInitialValue = (): OrgGroupForm => {
    return {
      name: '',
      users: [],
      accounts: [],
    };
  };

  const resetForm = (
    value: OrgGroupForm,
    action: FormikActions<OrgGroupForm>,
  ) => {
    action.resetForm(getInitialValue());
    toggleModal();
  };

  const submit = (value: OrgGroupForm, action: FormikActions<OrgGroupForm>) => {
    action.setSubmitting(true);
    const users = props.users.filter((u) => value.users.includes(u.id));
    props.addGroup(value.name, users, value.accounts);
    resetForm(value, action);
  };

  const userOption = props.users.map((u) => ({ label: u.email, value: u.id }));
  const accountOption = [{ label: 'All Accounts', value: '*' }].concat(
    props.config.map((u) => ({
      label: u.name,
      value: u.id,
    })),
  );

  return (
    <>
      <Button color={'primary'} outline={true} onClick={toggleModal}>
        Add Group <i className="fa fa-plus ml-3" />
      </Button>
      <Formik<OrgGroupForm>
        initialValues={getInitialValue()}
        onSubmit={submit}
        onReset={resetForm}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps: FormikProps<OrgGroupForm>) => {
          const handleUserOptionUpdate = (values: { value: string }[]) => {
            formikProps.handleChange({
              target: { name: 'users', value: values.map((u) => u.value) },
            });
          };
          const handleAccountOptionUpdate = (values: { value: string }[]) => {
            formikProps.handleChange({
              target: { name: 'accounts', value: values.map((u) => u.value) },
            });
          };
          return (
            <Modal size={'lg'} toggle={toggleModal} isOpen={isModelVisible}>
              <ModalHeader toggle={toggleModal}>Create a new Group</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={8} className={'mt-2'}>
                    <InputText
                      name={'name'}
                      value={formikProps.values.name}
                      error={formikProps.errors.name}
                      onChange={formikProps.handleChange}
                      label={'Group Name'}
                    />
                  </Col>
                  <Col md={8} className={'mt-3'}>
                    <MultiSelect
                      label={'Select Accounts to allow'}
                      value={[]}
                      options={accountOption}
                      name={'accounts'}
                      onChange={handleAccountOptionUpdate}
                      error={
                        formikProps.errors.accounts &&
                        formikProps.errors.accounts.join('   ')
                      }
                    />
                  </Col>
                  <Col md={8} className={'mt-3 mb-2'}>
                    <MultiSelect
                      label={'Select Users to add to this group'}
                      value={[]}
                      options={userOption}
                      name={'users'}
                      onChange={handleUserOptionUpdate}
                      error={
                        formikProps.errors.users &&
                        formikProps.errors.users.join('   ')
                      }
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <Button
                  color={'primary'}
                  onClick={() => formikProps.handleSubmit()}
                >
                  Submit
                </Button>
                <Button
                  color={'primary'}
                  className="ml-3"
                  outline={true}
                  onClick={formikProps.handleReset}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}
