import * as React from 'react';
import { Redirect } from 'react-router-dom';
import WorkflowEditor from './workflowEditor';
import { SecureRouteProps } from '../api/tcApiHelper';

export default function EditWorkflow(props: SecureRouteProps) {
  const workflow = props.match.params && props.match.params['workflow'];
  if (!workflow) {
    return <Redirect to="/workflows" />;
  } else {
    return <WorkflowEditor workflowId={workflow} {...props} />;
  }
}
