import * as React from 'react';

import { ExecutionsMapType } from '../../../lib/store/executions/types';
import CustomTable, { ICustomTableCol } from '../../common/table';
import {
  Workflow,
  WorkflowProperties,
} from '../../../lib/store/workflows/types';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { getCols, getSummaryColsForTabs } from './workflowTableHelper';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  fetchWorkflowExecution,
  fetchWorkflowExecutionInBatch,
  runWorkflow,
} from '../../../lib/store/executions/action';
import { connect } from 'react-redux';
import {
  adoptAndRunFix,
  destroyWorkflow,
  runLinkedWorkflowAction,
  toggleWorkflowActive,
  updateAutoRunOfLinkAction,
  updateLinkedWorkflowAction,
} from '../../../lib/store/workflows/action';
import Swal from 'sweetalert2';
import { track } from '../../../analytics/analytics';
import { events } from '../../../analytics/events';
import { Template } from '../../../lib/store/templates/types';
import { StyledOptionCol } from '../style';
import { Filters } from './filters';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { EmptyListText } from '../../../config/WorkflowCategories';

import { EventLookupToolbar } from './eventLookupToolbar';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { ItemUpdateStateMap } from '../../../lib/store/templates-manage/types';
import {
  getEventsExecution,
  getReportId,
  getTableData,
  getTableInfoText,
  isEventSearchTable,
} from './dataFormatter/FormatWorkflowsData';
import { Config } from '../../../lib/store/config/types';
import { CustomBreadCrumb } from './CustomBreadCrumb';
import { RenderSummaryChart } from './summaryFormatter';
import { ControlTagsOption } from './controlInventoryOptions';
import { getBreadCrumbs } from './breadCrumbs';
import InventoryResourceView from './resource-level-views/InventoryResourceView';
import { ResourceUtilisation } from './resource-level-views/ResourceUtilisation';
import ReportLevelView from './report-level-view/ReportLevelView';
import InputSelect from '../../../lib/formInputs/select-option.component';
import { textFilter } from 'react-bootstrap-table2-filter';
import InventoryActionProgressIndicator from './InventoryActionProgressIndicator';

export enum VIEW {
  'TABLE' = 'table',
  'RESOURCE' = 'resource',
  'REPORT' = 'report',
  'SERVICE' = 'service',
  'PURE_SERVICE' = 'pure_service',
}

export enum ORDER {
  'ASCENDING' = 'asc',
  'DESCENDING' = 'desc',
}
type StateProps = {
  updatingColMap: ItemUpdateStateMap;
  isFetchingWorkflow: boolean;
  isFetchingExecutions: boolean;
  isFetchingAnyExecutions: boolean; // used to detect run now workflow
  executionsMap: ExecutionsMapType;
  config: Config[];
  callbackFun?(data: any): void;
};

type DispatchProps = {
  runLinkedWorkflow: (props: {
    parentWorkflowId: string;
    workflow: string;
  }) => void;
  updateAutoRunOfLink: (props: {
    parentWorkflowId: string;
    status: boolean;
  }) => void;
  updateLinkedWorkflow: (props: {
    parentWorkflowId: string;
    workflow: string;
    runFix?: boolean;
  }) => void;
  adoptTemplateAndRunFix: (props: {
    parentWorkflowId: string;
    templateId: string;
    regions: string[];
    accounts: string[];
    runFix?: boolean;
  }) => void;
  fetchExecutionsInBatch: () => void;
  toggleWorkflowActive: (workflow: Workflow) => void;
  runWorkflow: (workflow: string) => void;
  destroyWorkflow: (workflow: string) => void;
  fetchExecution: (workflow: string) => void;
};

export interface OwnProps {
  user: string;
  allWorkflows: {
    workflow: string;
    name: string;
    properties: WorkflowProperties;
  }[];
  allTemplates: {
    id: string;
    name: string;
  }[];
  workflows: Workflow[];
  templates?: Template[];
  onViewPolicyRequest: (workflow: string) => void;
  onCloneRequest?: (workflow: string) => void;
  onAdoptRequest: (templateId: string, option: string) => void;
  isLoading?: boolean;
  fields: string[];
  fetchWorkflows: () => void;
  tabName?: TAB_NAME;
  credentials: string[];
  updateConfigAndRegion: (key: string, value: string[]) => void;
  match: any;
}

type IProps = RouteComponentProps & OwnProps & StateProps & DispatchProps;

interface IState {
  selectedFilter: string;
  selectedSort: string;
  extraCols: ICustomTableCol[];
  view: VIEW;
  viewData: any;
}

class WorkflowTableInner extends React.Component<IProps, IState> {
  setSeverityFilter = (value) => {};
  setResultFilter = (value) => {};

  setServiceFilter = (value) => {};

  setAccountFilter = (value) => {};

  state: IState = {
    selectedFilter: 'all',
    selectedSort: 'default',
    extraCols: [],
    view: VIEW.TABLE,
    viewData: null,
  };

  onChangeSeverityFilter = (value) => {
    this.setSeverityFilter(value);
  };
  onChangeServiceFilter = (value) => {
    this.setServiceFilter(value);
  };

  onChangeResultFilter = (value) => {
    this.setResultFilter(value);
  };

  onChangeAccountFilter = (value) => {
    this.setAccountFilter(value);
  };

  onClearAllFilters = () => {
    this.setSeverityFilter('');
    this.setServiceFilter('');
    this.setResultFilter('');
    this.setAccountFilter('');
  };

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    let workflowIds = this.props.workflows
      ? this.props.workflows.map((x) => x.workflow)
      : [];
    let executionsToFetch = workflowIds.filter(
      (x) => Object.keys(this.props.executionsMap).indexOf(x) === -1,
    );
    if (executionsToFetch.length > 0 && !this.props.isFetchingExecutions) {
      this.props.fetchExecutionsInBatch();
    }
    if (
      this.props.credentials.length !== prevProps.credentials.length &&
      this.props.credentials.length > 1
    ) {
      if (
        this.state.view === VIEW.RESOURCE ||
        this.state.view === VIEW.REPORT
      ) {
        this.changeToTableView();
      }
    }
  }

  changeView = (view: VIEW) => {
    this.setState({ view });
    this.onClearAllFilters();
  };

  setResourceView = (viewData) => {
    let categories = viewData.categories.find((x) => x.category === 'Account');
    let accountId = categories.subCategories[0];
    this.props.updateConfigAndRegion('account', [accountId]);
    this.setState({ viewData, view: VIEW.RESOURCE });
    this.onClearAllFilters();
  };

  handleFilter = (value: any) => {
    this.setState({ selectedFilter: value });
  };

  handleSort = (value: any) => {
    this.setState({ selectedSort: value });
  };

  onChangeOption = (data: any, option: string) => {
    switch (option) {
      case 'resource-details': {
        return this.setResourceView(data);
      }
      case 'open-report': {
        let reportId = getReportId(data.url);
        return this.props.history.push(
          `${window.location.pathname}/report/${reportId}`,
        );
      }
      case 'edit': {
        window.open(`${window.location.origin}/editor/${data.workflow}`);
        return;
      }
      case 'execution-details':
        window.open(
          `${window.location.origin}/workflow/${data.workflow}/details`,
        );
        return;
      case 'view-policy':
        this.props.onViewPolicyRequest(data.workflow);
        return;
      case 'run-now':
        this.props.runWorkflow(data.workflow);
        return;
      case 'delete':
        this.deleteWorkflow(data.workflow);
        return;
      case 'clone':
        this.props.onCloneRequest(data.workflow);
        return;

      default:
        return;
    }

    // this.setState({ selectedOption: value });
    // console.log('selectedOption', this.state.selectedOption);
  };

  toggleActive = (workflow: Workflow, active) => {
    if (active) {
      track(events.WORKFLOW_ACTIVATED, null);
    } else {
      track(events.WORKFLOW_DEACTIVATED, null);
    }
    this.props.toggleWorkflowActive(workflow);
  };

  deleteWorkflow = (workflow: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      type: 'warning',
      width: '30em',
      showCancelButton: true,
      confirmButtonColor: '#ad1321',
      cancelButtonColor: '#646464',
      confirmButtonText: 'Proceed',
      confirmButtonClass: 'test-delete-confirm',
      cancelButtonClass: 'test-delete-cancel',
    })
      .then((result) => {
        if (result.value) {
          this.props.destroyWorkflow(workflow);
        }
      })
      .catch(() => {
        //  Cancel delete
      });
  };

  getOptions = (data, tableInfoText, isSummaryTable = false) => {
    return (
      <Col className="d-flex   justify-content-between" md={8}>
        {tableInfoText ? (
          <div className="d-flex align-self-center font-italic table-info-text">
            {tableInfoText}
          </div>
        ) : null}

        {!isEventSearchTable(this.props.tabName) ? (
          <StyledOptionCol
            className={
              'px-3 d-flex align-self-center justify-content-end text-primary'
            }
          >
            {this.props.tabName === TAB_NAME.INSIGHTS && !isSummaryTable && (
              <Button color={'link'} onClick={this.onClearAllFilters}>
                Clear Search
              </Button>
            )}
            {this.renderControlButton(data)}
            {this.renderRefreshButton()}
            {this.renderFilterButton()}
          </StyledOptionCol>
        ) : (
          <>{this.renderEventToolbar()}</>
        )}
      </Col>
    );
  };

  onSaveExtraCols = (extraCols: ICustomTableCol[]) => {
    this.setState({ extraCols });
  };

  getAccount = () => {
    return (
      this.props.credentials &&
      this.props.credentials.length > 0 &&
      this.props.config.filter((conf) =>
        this.props.credentials.includes(conf.id),
      )
    );
  };

  getSortingFields = (tabName) => {
    if (tabName === TAB_NAME.UTILISATION) {
      return { sortField: 'lowUtilisation', order: ORDER.DESCENDING };
    } else if (tabName === TAB_NAME.INVENTORY) {
      return { sortField: 'totalResources', order: ORDER.DESCENDING };
    } else if (tabName !== TAB_NAME.INSIGHTS) {
      return { sortField: 'priority', order: ORDER.ASCENDING };
    } else return {};
  };

  renderSummaryTable = (data, tableInfoText) => {
    let { isLoading, tabName } = this.props;
    let account = this.getAccount();
    let breadCrum =
      this.state.view !== VIEW.PURE_SERVICE &&
      getBreadCrumbs({
        view: this.state.view,
        configs: this.props.config,
        workflows: this.props.workflows,
        tabName,
        account,
        changeToTableView: this.changeToTableView,
        updateConfigAndRegion: this.props.updateConfigAndRegion,
      });
    let summaryData = getTableData(
      {
        ...this.props,
        ...this.state,
        changeView: this.changeView,
        onChangeSeverityFilter: this.onChangeSeverityFilter,
        onChangeAccountFilter: this.onChangeAccountFilter,
        onChangeResultFilter: this.onChangeResultFilter,
        onChangeServiceFilter: this.onChangeServiceFilter,
        adoptTemplateAndRunFixForInsight: this.props.adoptTemplateAndRunFix,
        onChangeOption: this.onChangeOption,
        toggleActive: this.toggleActive,
        setResourceView: this.setResourceView,
      },
      true,
    );
    return (
      <RenderSummaryChart
        viewSelector={this.renderSummaryViewSelector()}
        options={this.getOptions(data, tableInfoText, true)}
        summaryData={summaryData}
        {...this.props}
        columns={this.getSummaryColumns()}
        breadCrum={breadCrum}
        isLoading={isLoading}
        updateConfigAndRegion={this.props.updateConfigAndRegion}
      />
    );
  };

  renderSummaryViewSelector = () => {
    return (
      <>
        <Col md={3} className={'text-right mt-3 pr-0'}>
          <span className={'text-dark'}>View:</span>
        </Col>
        <Col md={{ size: 9 }}>
          <InputSelect
            onChange={(e) => {
              this.onClearAllFilters();
              this.setState({ view: e.target.value });
            }}
            value={
              this.state.view === VIEW.PURE_SERVICE
                ? VIEW.PURE_SERVICE
                : VIEW.TABLE
            }
            options={[
              { label: 'Accounts', value: VIEW.TABLE },
              { label: 'Service', value: VIEW.PURE_SERVICE },
            ]}
            name={'viewSelector'}
          />
        </Col>
      </>
    );
  };

  renderTableView = () => {
    let { isLoading, tabName } = this.props;
    let data = getTableData({
      ...this.props,
      ...this.state,
      adoptTemplateAndRunFixForInsight: this.props.adoptTemplateAndRunFix,
      onChangeOption: this.onChangeOption,
      toggleActive: this.toggleActive,
      setResourceView: this.setResourceView,
    });

    let tableInfoText = getTableInfoText(this.props.tabName);

    let loadingText = isLoading
      ? 'Loading...'
      : EmptyListText[this.props.tabName];
    let sort = this.getSortingFields(tabName);
    return (
      <>
        {this.renderSummaryTable(data, tableInfoText)}

        <Col className={'py-4 px-4 bg-white custom-container'} md={12}>
          <CustomTable
            options={this.getOptions(data, tableInfoText)}
            emptyText={loadingText}
            data={data}
            columns={this.getColumns()}
            sortField={sort.sortField}
            order={sort.order}
          />
        </Col>
      </>
    );
  };

  changeToTableView = () => {
    this.setState({ view: VIEW.TABLE });
  };

  renderReportView = () => {
    let { tabName } = this.props;
    let account = this.getAccount();
    let reportId = getReportId(window.location.pathname);
    let breadCrum = getBreadCrumbs({
      tabName,
      account,
      updateConfigAndRegion: this.props.updateConfigAndRegion,
      changeToTableView: this.changeToTableView,
      reportId,
    });

    return (
      <>
        <Col className={'py-4 px-4 bg-white custom-container'} md={12}>
          {breadCrum && <CustomBreadCrumb data={breadCrum} />}
          <ReportLevelView data={reportId} {...this.props} />
        </Col>
      </>
    );
  };

  onActionPerformed = (actionResponseData: any) => {
    let { workflow } = actionResponseData;
    workflow && this.props.fetchWorkflows();
  };

  renderResourceView = () => {
    let { tabName } = this.props;
    let { viewData } = this.state;

    let account = this.getAccount();
    let breadCrum = getBreadCrumbs({
      tabName,
      account,
      updateConfigAndRegion: this.props.updateConfigAndRegion,
      resourceName: viewData.resource,
      changeToTableView: this.changeToTableView,
    });

    if (this.props.tabName === TAB_NAME.INVENTORY) {
      return (
        <>
          <Col className={'py-4 px-4 bg-white custom-container'} md={12}>
            <Row>
              <Col> {breadCrum && <CustomBreadCrumb data={breadCrum} />}</Col>
              <Col className={'d-flex justify-content-end'}>
                <InventoryActionProgressIndicator
                  allWorkflows={this.props.allWorkflows}
                  viewData={this.state.viewData}
                />
              </Col>
            </Row>
            <InventoryResourceView
              onActionPerformed={this.onActionPerformed}
              viewData={viewData}
              {...this.props}
              configs={this.props.config}
            />
          </Col>
        </>
      );
    } else if (this.props.tabName === TAB_NAME.UTILISATION) {
      return (
        <>
          <Col className={'py-4 px-4 bg-white custom-container'} md={12}>
            {breadCrum && <CustomBreadCrumb data={breadCrum} />}
            <ResourceUtilisation data={viewData} {...this.props} />
          </Col>
        </>
      );
    }
    return 'View not defined';
  };

  render() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.path}`}
          exact={true}
          render={() => {
            if (this.state.view === VIEW.RESOURCE)
              return this.renderResourceView();
            return this.renderTableView();
          }}
        />
        <Route
          path={`${this.props.match.path}/report/:reportId`}
          exact={true}
          render={() => {
            return this.renderReportView();
          }}
        />
      </Switch>
    );
  }

  private renderFilterButton() {
    if (isEventSearchTable(this.props.tabName)) return null;
    return (
      <Filters
        selectedFilter={this.state.selectedFilter}
        selectedSort={this.state.selectedSort}
        handleFilter={this.handleFilter}
        handleSort={this.handleSort}
      />
    );
  }

  private renderControlButton(data) {
    if (
      this.props.tabName === TAB_NAME.INVENTORY ||
      this.props.tabName === TAB_NAME.INVENTORY_GLOBAL
    )
      return (
        <ControlTagsOption
          extraCols={this.state.extraCols}
          data={data}
          onSaveExtraCols={this.onSaveExtraCols}
        />
      );
  }

  private renderRefreshButton() {
    if (isEventSearchTable(this.props.tabName)) return null;
    return (
      <Button color={'link'} onClick={() => this.props.fetchWorkflows()}>
        {' '}
        <i className={'fa master-tc-continuous text-primary'}> </i>{' '}
      </Button>
    );
  }

  private renderEventToolbar() {
    if (!isEventSearchTable(this.props.tabName)) return null;
    return (
      <EventLookupToolbar
        {...getEventsExecution(this.props)}
        triggerExecutionReFetch={this.props.fetchExecutionsInBatch}
      />
    );
  }

  private getSummaryColumns() {
    if (this.props.tabName === TAB_NAME.INVENTORY) {
      return getCols(
        getSummaryColsForTabs(TAB_NAME.INVENTORY),
        false,
        this.state.extraCols,
      );
    }
    if (this.props.tabName === TAB_NAME.UTILISATION) {
      return getCols(getSummaryColsForTabs(TAB_NAME.UTILISATION), false);
    }
    if (this.props.tabName === TAB_NAME.INSIGHTS) {
      let cols = getCols(getSummaryColsForTabs(TAB_NAME.INSIGHTS), false);
      if (
        this.state.view === VIEW.SERVICE ||
        this.state.view === VIEW.PURE_SERVICE
      ) {
        return cols.map((x) => {
          if (x.colName === 'AccountLabel') x.hidden = true;
          if (x.colName === 'ServiceLabel') x.hidden = false;
          return x;
        });
      } else
        return cols.map((x) => {
          if (x.colName === 'AccountLabel') x.hidden = false;
          if (x.colName === 'ServiceLabel') x.hidden = true;
          return x;
        });
    }
  }

  private getColumns() {
    if (this.props.tabName === TAB_NAME.INVENTORY) {
      return getCols(this.props.fields, true, this.state.extraCols);
    }

    let cols = getCols(this.props.fields);

    if (this.props.tabName === TAB_NAME.INSIGHTS) {
      cols = cols.map((x) => {
        switch (x.colName) {
          case 'Severity': {
            x.filter = textFilter({
              getFilter: (filter) => {
                this.setSeverityFilter = filter;
              },
            });
            break;
          }
          case 'Result': {
            x.filter = textFilter({
              getFilter: (filter) => {
                this.setResultFilter = filter;
              },
            });
            break;
          }
          case 'Account_Label': {
            x.filter = textFilter({
              getFilter: (filter) => {
                // nameFilter was assigned once the component has been mounted.
                this.setAccountFilter = filter;
              },
            });
            break;
          }
          case 'Service': {
            x.filter = textFilter({
              getFilter: (filter) => {
                this.setServiceFilter = filter;
              },
            });
            break;
          }
        }

        return x;
      });
    }
    return cols;
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    updatingColMap: state.workflows.itemUpdateStateMap,
    isFetchingWorkflow:
      state.workflows.fetching && !state.workflows.data.length,
    isFetchingExecutions: state.execution.batchFetching,
    isFetchingAnyExecutions: state.execution.fetching,
    executionsMap: state.execution.workflowExecutions,
    config: state.config.data,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    adoptTemplateAndRunFix: (props) => adoptAndRunFix({ dispatch, ...props }),
    fetchExecutionsInBatch: () =>
      fetchWorkflowExecutionInBatch({
        dispatch,
        workflows: ownProps.workflows,
      }),
    runWorkflow: (workflow: string) => {
      runWorkflow({ dispatch, workflow });
    },
    runLinkedWorkflow: (props) =>
      runLinkedWorkflowAction({ dispatch, ...props }),
    updateLinkedWorkflow: async (props) => {
      await updateLinkedWorkflowAction({ dispatch, ...props });
      if (props.runFix) {
        await runLinkedWorkflowAction({ dispatch, ...props });
      }
    },

    updateAutoRunOfLink: (props) =>
      updateAutoRunOfLinkAction({ dispatch, ...props }),
    toggleWorkflowActive: (workflow: Workflow) =>
      toggleWorkflowActive({ dispatch, workflow }),
    destroyWorkflow: (workflow: string) =>
      destroyWorkflow({ dispatch, workflow }),
    fetchExecution: (workflow: string) =>
      fetchWorkflowExecution({ dispatch, workflow }),
  };
}

export const WorkflowTable = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(
  withRouter<OwnProps & RouteComponentProps, typeof WorkflowTableInner>(
    WorkflowTableInner,
  ),
);
