import * as yup from 'yup';
import { OrgGroup, OrgUser } from '../../lib/store/tenants/types';
import React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import {
  Button,
  Col,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import InputText from '../../lib/formInputs/input-text.component';
import MultiSelect from '../../lib/formInputs/multi-select.component';
import { Config } from '../../lib/store/config/types';

type IProps = {
  modify: (name: string, groupId, accounts: string[]) => void;
  group: OrgGroup;
  existingGroups: OrgGroup[];
  config: Config[];
  children: (props: any) => JSX.Element;
};

type OrgGroupForm = {
  name: string;
  accounts: string[];
};

export function EditGroup(props: IProps) {
  const existing = props.existingGroups
    .filter((g) => g.id !== props.group.id)
    .map((g) => g.name);
  const schema = yup.object().shape<OrgGroupForm>({
    name: yup
      .string()
      .notOneOf(existing, 'A Group already exists with same name')
      .required(),
    accounts: yup
      .array()
      .of(yup.string())
      .min(1, 'Select an account'),
  });

  const [isModelVisible, setModalVisible] = React.useState(false);
  const toggleModal = () => setModalVisible(!isModelVisible);
  const getInitialValue = (): OrgGroupForm => {
    return {
      name: props.group.name,
      accounts: props.group.policies.map((u) => u.id),
    };
  };

  const resetForm = (
    value: OrgGroupForm,
    action: FormikActions<OrgGroupForm>,
  ) => {
    action.resetForm(getInitialValue());
    toggleModal();
  };

  const submit = (value: OrgGroupForm, action: FormikActions<OrgGroupForm>) => {
    action.setSubmitting(true);
    props.modify(value.name, props.group.id, value.accounts);
    resetForm(value, action);
  };

  const accountOption = props.config.map((u) => ({
    label: u.name,
    value: u.id,
  }));

  return (
    <>
      {props.children({ toggleModal })}
      <Formik<OrgGroupForm>
        initialValues={getInitialValue()}
        onSubmit={submit}
        onReset={resetForm}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps: FormikProps<OrgGroupForm>) => {
          const handleAccountOptionUpdate = (values: { value: string }[]) => {
            formikProps.handleChange({
              target: { name: 'accounts', value: values.map((u) => u.value) },
            });
          };
          return (
            <Modal size={'lg'} toggle={toggleModal} isOpen={isModelVisible}>
              <ModalHeader toggle={toggleModal}>Create a new Group</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={8} className={'mt-2'}>
                    <InputText
                      name={'name'}
                      value={formikProps.values.name}
                      error={formikProps.errors.name}
                      onChange={formikProps.handleChange}
                      label={'Group Name'}
                    />
                  </Col>
                  <Col md={8} className={'mt-3'}>
                    <MultiSelect
                      label={'Select Accounts to allow'}
                      value={accountOption.filter((u) =>
                        formikProps.values.accounts.includes(u.value),
                      )}
                      options={accountOption}
                      name={'accounts'}
                      onChange={handleAccountOptionUpdate}
                      error={
                        formikProps.errors.accounts &&
                        formikProps.errors.accounts.join('   ')
                      }
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <Button
                  color={'primary'}
                  onClick={() => formikProps.handleSubmit()}
                >
                  Submit
                </Button>
                <Button
                  color={'primary'}
                  className="ml-3"
                  outline={true}
                  onClick={formikProps.handleReset}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}
