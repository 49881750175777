import { Category } from 'workflow-model/dist';
import { TAB_NAME } from 'webapp-genric/dist/constants';

export const EmptyListText: { [t in TAB_NAME]: string } = {
  [TAB_NAME.EVENTS_SEARCH]: 'No Events Found',
  [TAB_NAME.INSIGHTS]: 'No workflows Found',
  [TAB_NAME.AUTO_REMEDIATION]: 'No workflows Found',
  [TAB_NAME.UTILISATION]: 'No workflows Found',
  [TAB_NAME.UTILISATION_SUMMARY]: 'No summary Found',
  [TAB_NAME.SCHEDULES]: 'No workflows Found',
  [TAB_NAME.EVENTS]: 'No workflows Found',
  [TAB_NAME.WORKFLOWS]: 'No workflows Found',
  [TAB_NAME.INVENTORY]: 'No workflows Found',
  [TAB_NAME.INVENTORY_GLOBAL]: 'No workflows Found',
};

export type Workflow_Category_Type = {
  name: string;
  category: string;
  subCategory?: string;
  subCategoriesToShow: string[];
  selectedSubCategories?: string[];
};

export const CATEGORIES: Workflow_Category_Type[] = [
  {
    name: 'Insights',
    category: 'Insights',
    subCategoriesToShow: [
      'Other',
      'CIS-AWS',
      'Security',
      'Cost Saving',
      'AWS Best Practices',
    ],
  },
  {
    name: 'Auto Remediation',
    category: 'By Solution',
    subCategory: 'Remediation',
    subCategoriesToShow: [],
  },
  {
    name: 'Utilisation',
    category: 'By Solution',
    subCategory: 'Reporting',
    subCategoriesToShow: [],
  },
  {
    name: 'Events',
    category: 'Events',
    subCategory: 'Reporting',
    subCategoriesToShow: [],
  },
  {
    name: 'Custom',
    category: 'Custom',
    subCategory: '',
    subCategoriesToShow: [],
  },
  {
    name: 'Inventory',
    category: 'Inventory',
    subCategory: '',
    subCategoriesToShow: [],
  },
];

export const getSubCategoriesOption = (categoryName: string): string[] => {
  let cat = CATEGORIES.find((x) => x.name === categoryName);
  return cat ? cat.subCategoriesToShow : [];
};

export const getCategoryForWorkflow = (
  categories: Category[] = [],
): Workflow_Category_Type => {
  for (let x of CATEGORIES) {
    let existing = categories.find((c) => {
      if (x.subCategory)
        return (
          c.category === x.category &&
          c.subCategories.indexOf(x.subCategory) !== -1
        );
      return c.category === x.category;
    });
    if (!!existing) {
      return {
        ...x,
        selectedSubCategories: !x.subCategory ? existing.subCategories : [],
      };
    }
  }
  return {
    name: 'Custom',
    category: 'Custom',
    subCategory: '',
    subCategoriesToShow: [],
    selectedSubCategories: [],
  };
};

export const getSortProperty = (tabName: TAB_NAME): string => {
  if (tabName === TAB_NAME.INSIGHTS) return 'severity';
  return 'priority';
};

export const shouldShowTimeStamp = (tabName: TAB_NAME) => {
  return true;
};

export const shouldShowTriggerTimeStamp = (tabName: TAB_NAME) => {
  return (
    tabName === TAB_NAME.UTILISATION ||
    tabName === TAB_NAME.INSIGHTS ||
    tabName == TAB_NAME.INVENTORY
  );
};
