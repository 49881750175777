import * as yup from 'yup';

export enum viewType {
  SUGGESTED = 'suggested',
  CUSTOM_WORKFLOW = 'custom_workflow',
}

export const changeFixFormSchema = yup.object({
  runFix: yup.boolean(),
  type: yup.string().required('Select at least on option'),
  selectedWorkflow: yup.string().when('type', {
    is: viewType.CUSTOM_WORKFLOW,
    then: yup.string().required('Workflow is required'),
  }),
  templateId: yup.string().when('type', {
    is: viewType.SUGGESTED,
    then: yup.string().required('Workflow is required'),
  }),
  regions: yup
    .array()
    .required()
    .of(yup.string())
    .min(1, 'Select one or more regions'),
  accounts: yup
    .array()
    .required()
    .of(yup.string())
    .min(1, 'Select one or more accounts'),
});

export type ChangeFixFormSchema = yup.InferType<typeof changeFixFormSchema>;
