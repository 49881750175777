import { Reducer } from 'redux';
import {
  ListUserCreatedNodeCompleted,
  ListUserCreatedNodeStarted,
  UserCreatedNodeAction,
  UserCreatedNodeState,
} from './types';

const initialState: UserCreatedNodeState = {
  fetching: false,
  data: [],
  initialised: false,
};

export const userCreatedNodeReducer: Reducer<
  UserCreatedNodeState,
  UserCreatedNodeAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ListUserCreatedNodeStarted:
      return { ...state, fetching: true };
    case ListUserCreatedNodeCompleted:
      return {
        ...state,
        fetching: false,
        data: action.data || state.data,
        initialised: true,
      };
    case 'ModifyUserCreatedNodeStarted':
      return { ...state, fetching: true };
    case 'ModifyUserCreatedNodeCompleted':
      return { ...state, fetching: false };
    default:
      return state;
  }
};
