import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { JSONReducer } from './jsonsection/reducer';
import { subscriptionReducer } from './billing/reducer';
import { configReducer } from './config/reducer';
import { templatesReducer } from './templates/reducer';
import { workflowReducer } from './workflows/reducer';
import { executionReducer } from './executions/reducer';
import { overviewReducer } from './overview/reducer';
import { onBoardingReducer } from './onboarding/reducer';
import { sidebarReducer } from './sidebar/reducer';
import LogRocket from 'logrocket';
import { parkingReducer } from './parking/reducer';
import { alarmReducer } from './alarms/reducer';
import { recommendationReducer } from './recommendation/reducer';
import { policyReducer } from './policy/reducer';
import { templatesManageReducer } from './templates-manage/reducer';
import { manageReducer } from './manage/reducer';
import { SGCTypeReducer } from './sgc/reducer';
import { tenantReducer } from './tenants/reducer';
import superTableReducer from './super-table/reducer';
import { tfcTypeReducer } from './tfcTypes/reducer';
import { userCreatedNodeReducer } from './user-nodes/reducer';
import { userCreatedScriptReducer } from './user-scripts/reducer';

const rootReducer = combineReducers({
  JSONReducer: JSONReducer,
  subscription: subscriptionReducer,
  config: configReducer,
  templates: templatesReducer,
  workflows: workflowReducer,
  execution: executionReducer,
  overview: overviewReducer,
  onboarding: onBoardingReducer,
  sidebar: sidebarReducer,
  parking: parkingReducer,
  alarms: alarmReducer,
  recommendations: recommendationReducer,
  policy: policyReducer,
  templatesManage: templatesManageReducer,
  manage: manageReducer,
  sgcTypes: SGCTypeReducer,
  tenantConfig: tenantReducer,
  superTable: superTableReducer,
  tfcTypes: tfcTypeReducer,
  userCreatedNode: userCreatedNodeReducer,
  userCreatedScript: userCreatedScriptReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export function createRootStore() {
  return createStore(
    rootReducer,
    applyMiddleware(thunk, LogRocket.reduxMiddleware()),
  );
}

export const store = createRootStore();
