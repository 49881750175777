import { ExecutionStatus, ExecutionStatusEnum } from '../ExecutionStatus';
import { Link } from 'react-router-dom';
import { setPropertyInStore } from '../../../common/withRegionAndCredentials';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { TAB_NAME } from 'webapp-genric/dist';
import { getReportId } from '../dataFormatter/FormatWorkflowsData';
import React from 'react';

/*
Seconds | Microseconds | Milliseconds | Bytes | Kilobytes | Megabytes | Gigabytes | Terabytes |
Bits | Kilobits | Megabits | Gigabits | Terabits | Percent | Count | Bytes/Second |
Kilobytes/Second | Megabytes/Second | Gigabytes/Second | Terabytes/Second | Bits/Second
| Kilobits/Second | Megabits/Second | Gigabits/Second | Terabits/Second | Count/Second | None
 */
export let unitMap = {
  Seconds: 'sec',
  Milliseconds: 'ms',

  Bytes: 'B',
  Kilobytes: 'KB',
  Megabytes: 'MB',
  Gigabytes: 'GB',
  Terabytes: 'TB',

  bits: 'b',
  Kilobits: 'Kb',
  Megabits: 'Mb',
  Gigabits: 'Gb',
  Terabits: 'Tb',

  Percent: '%',

  'Bytes/Second': 'B/s',
  'Kilobytes/Second': 'KB/s',
  'Megabytes/Second': 'MB/s',
  'Gigabytes/Second': 'GB/s',
  'Terabytes/Second': 'TB/s',
  'Bits/Second': 'b/s',
  'Kilobits/Second': 'Kb/s',
  'Megabits/Second': 'Mb/s',
  'Gigabits/Second': 'Gb/s',
  'Terabits/Second': 'Tb/s',
  'Count/Second': 'Count/s',
};
export const loader = () => {
  return <i className={'fa fa-spin fa-spinner'} />;
};

export const isFetching = (row) => {
  if (!row || !row.exe) return false;
  return row.exe.fetching || row.isFetching;
};

const isUtilisationReport = (row) => {
  if (row && row.exe && row.exe.output && row.exe.output.utilisationReport)
    return true;
  return false;
};

export const getStatus = (row) => {
  let status = ExecutionStatusEnum.NA;
  if (row && row.exe && row.exe.output && row.exe.output.state)
    status = ExecutionStatusEnum[row.exe.output.state];

  if (status === 'custom') return 'Not available';
  return (
    <ExecutionStatus
      tabName={row.tabName}
      data-test="execution-status-comp"
      status={status}
      executionUrl={row.executionUrl}
      url={''}
    />
  );
};

export const formatTotalResourceCol = (col, row) => {
  if (isFetching(row)) return loader();
  if (row.isTemplateItem) return '';
  if (
    (!isUtilisationReport(row) && row.tabName === TAB_NAME.UTILISATION) ||
    col === undefined
  ) {
    return getStatus(row);
  }
  let reportId = getReportId(row.url);
  let url;
  if (reportId) {
    url = `${window.location.pathname}/report/${reportId}`;
  }
  return getValue(url, col);
};

export const fetchingMetricCol = (col, row) => {
  if (isFetching(row)) return loader();
  if (row.isTemplateItem) return '';
  if (!col) return '';

  if (!row.Unit) return col;
  let unit = unitMap[row.Unit] || row.Unit;
  return `${col}(${unit})`;
};

export const formatOverallUtilisationCol = (col, row) => {
  if (isFetching(row)) return loader();
  if (row.isTemplateItem) return '';
  let value = col;
  if (!isNaN(value)) value = Number(value).toFixed(1);
  let reportId = getReportId(row.url);
  let url;
  if (reportId) {
    url = `${window.location.pathname}/report/${reportId}`;
  }
  return getValue(url, value);
};

const getValue = (url, value, id?, hoverContent?, colName?, className = '') => {
  if (!url) return value;
  return (
    <>
      <Link className={className} to={url} id={`${colName}-utilisation-${id}`}>
        {value}
      </Link>
      {hoverContent}
    </>
  );
};

/*
todo prepare filter for report and send as query params with irl
 */
export const formatLowUtilisationCol = (col, row) => {
  if (isFetching(row)) return loader();
  if (row.isTemplateItem) return '';
  if (isNaN(col)) col = '';
  let value = col;
  if (
    row.metricUtilisationMap &&
    row.metricName &&
    row.metricUtilisationMap[row.metricName]
  ) {
    value = `${value} (${row.metricUtilisationMap[row.metricName].low.label})`;
  }
  let hoverContent = hoverUtilisation(row, col, 'low');
  let reportId = getReportId(row.url);
  let url;
  if (reportId) {
    url = `${window.location.pathname}/report/${reportId}`;
  }
  return getValue(
    url,
    value,
    row.workflow,
    hoverContent,
    'low',
    col === 0 ? '' : 'text-red',
  );
};

export const hoverUtilisation = (row, noOfInstance, colName) => {
  return noOfInstance > 0 ? (
    <UncontrolledTooltip
      placement="right"
      target={`${colName}-utilisation-${row.workflow}`}
    >
      <div>Resources with {colName} utilisation:</div>
      <div className="px-1 d-flex justify-content-start">
        {` ${noOfInstance} ${row.resourceName}`}
      </div>
    </UncontrolledTooltip>
  ) : null;
};

export const formatAccountLevelLowUtilisationCol = (col, row) => {
  return (
    <>
      <span id={`low-utilisation-${row.id}`} className="text-red">
        {row.lowUtilisation}
      </span>
      {row.lowUtilisation ? (
        <UncontrolledTooltip
          placement="right"
          target={`low-utilisation-${row.id}`}
        >
          <div>Resources with low utilisation:</div>
          <div className="px-1 d-flex justify-content-start">
            {row.lowUtilisationResource.map((rs, index) => {
              if (rs.value) {
                return <div key={index}>{` ${rs.value} ${rs.name}`}</div>;
              }
              return null;
            })}
          </div>
        </UncontrolledTooltip>
      ) : null}
    </>
  );
};

export const formatAccountLevelTotalResourcesUtilisationCol = (col, row) => {
  return <span>{row.totalResources}</span>;
};

export const formatAccountLevelHighUtilisationCol = (col, row) => {
  return (
    <>
      <span id={`high-utilisation-${row.id}`}>{row.highUtilisation}</span>
      {row.highUtilisation ? (
        <UncontrolledTooltip
          placement="right"
          target={`high-utilisation-${row.id}`}
        >
          <div>Resources with high utilisation:</div>
          <div className="px-1  d-flex justify-content-start">
            {row.highUtilisationResource.map((rs, index) => {
              if (rs.value) {
                return <div key={index}>{`${rs.value} ${rs.name}`}</div>;
              }
              return null;
            })}
          </div>
        </UncontrolledTooltip>
      ) : null}
    </>
  );
};

export const formatAccountLevelInRangeUtilisationCol = (col, row) => {
  return (
    <>
      <span id={`inrange-utilisation-${row.id}`}>{row.inRangeUtilisation}</span>
      {row.inRangeUtilisation ? (
        <UncontrolledTooltip
          placement="right"
          target={`inrange-utilisation-${row.id}`}
        >
          <div>Resources with in-range utilisation:</div>
          <div className="px-1 d-flex justify-content-start">
            {row.inRangeUtilisationResource.map((rs, index) => {
              if (rs.value) {
                return <div key={index}>{`${rs.value} ${rs.name}`}</div>;
              }
              return null;
            })}
          </div>
        </UncontrolledTooltip>
      ) : null}
    </>
  );
};

/*
todo prepare filter for report and send as query params with irl
 */
export const formatHighUtilisationCol = (col, row) => {
  if (isFetching(row)) return loader();
  if (row.isTemplateItem) return '';
  if (isNaN(col)) col = '';
  let value = col;
  if (
    row.metricUtilisationMap &&
    row.metricName &&
    row.metricUtilisationMap[row.metricName]
  ) {
    value = `${value} (${row.metricUtilisationMap[row.metricName].high.label})`;
  }
  let hoverContent = hoverUtilisation(row, col, 'high');
  let reportId = getReportId(row.url);
  let url;
  if (reportId) {
    url = `${window.location.pathname}/report/${reportId}`;
  }
  return getValue(
    url,
    value,
    row.workflow,
    hoverContent,
    'high',
    col === 0 ? '' : 'text-danger',
  );
};
