import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';

export interface Config {
  name: string;
  type: CONFIG_TYPES;
  value: { [key: string]: any };
  parentId?: string;
  parentName?: string;
  id: string;
  resourceGroup?: {
    [configId: string]: Array<string>;
  };
  accountId?: string;
}

export type AWS_ORG_MEM = {
  value: {
    memberArn: string;
    accountId: string;
  };
  isValid: boolean;
  name: string;
};
//
// export type EventConfig = Config & {
//   value : {
//     enabled: boolean,
//     regions: string[],
//     pattern: any,
//   }
// }

// events
export const RequestConfig = 'RequestConfig';
export const RequestConfigSuccess = 'RequestConfigSuccess';
export const SaveConfigRequest = 'SaveConfigRequest';
export const MemberValidatingRequest = 'MemberValidatingRequest';
export const ValidateConfigRequest = 'ValidateConfigRequest';
export const ValidateConfigSuccess = 'ValidateConfigSuccess';
export const MemberValidatingSuccess = 'MemberValidatingSuccess';
export const SaveConfigSuccess = 'SaveConfigSuccess';
export const DeleteConfigRequest = 'DeleteConfigRequest';
export const DeleteConfigSuccess = 'DeleteConfigSuccess';
export const FetchResourceGroupStarted = 'FetchResourceGroupStarted';
export const FetchResourceGroupCompleted = 'FetchResourceGroupCompleted';
export const FetchEksConfig = 'FetchEksConfig';
export const FetchEksConfigCompleted = 'FetchEksConfigCompleted';

interface RequestConfig {
  type: typeof RequestConfig;
}

interface MemberValidatingSuccess {
  type: typeof MemberValidatingSuccess;
}

interface RequestConfigSuccess {
  type: typeof RequestConfigSuccess;
  data: Config[];
}

interface SaveConfigRequest {
  type: typeof SaveConfigRequest;
}

interface ValidateConfigRequest {
  type: typeof ValidateConfigRequest;
}

interface MemberValidatingRequest {
  type: typeof MemberValidatingRequest;
  data: string;
}

interface ValidateConfigSuccess {
  type: typeof ValidateConfigSuccess;
}

interface SaveConfigSuccess {
  type: typeof SaveConfigSuccess;
  data: Config | null;
}

interface DeleteConfigRequest {
  type: typeof DeleteConfigRequest;
}

interface DeleteConfigSuccess {
  type: typeof DeleteConfigSuccess;
  data: string | null;
}

// emits {configId}
interface FetchResourceGroupStarted {
  type: typeof FetchResourceGroupStarted;
  data: { configId: string };
}

// FetchResourceGroupCompleted , emits {configId, resourceGroupData} after completion
interface FetchResourceGroupCompleted {
  type: typeof FetchResourceGroupCompleted;
  data: { configId: string; resourceGroup: string[] };
}

interface FetchEksConfig {
  type: typeof FetchEksConfig;
  data: { configId: string; region: string };
}

interface FetchEksConfigCompleted {
  type: typeof FetchEksConfigCompleted;
  data: { configId: string; region: string; data: EKSConfig[] };
}

export type ConfigAction =
  | RequestConfig
  | RequestConfigSuccess
  | SaveConfigRequest
  | ValidateConfigRequest
  | ValidateConfigSuccess
  | MemberValidatingSuccess
  | MemberValidatingRequest
  | SaveConfigSuccess
  | DeleteConfigRequest
  | DeleteConfigSuccess
  | FetchResourceGroupCompleted
  | FetchResourceGroupStarted
  | FetchEksConfig
  | FetchEksConfigCompleted;

export interface EKSConfig {
  configId: string;
  region: string;
  name: string;
  endpoint: string;
  certificateAuthority: string;
}

export interface EKSConfigState {
  // path like configId/region
  [path: string]: {
    fetching: boolean;
    data?: EKSConfig[];
  };
}

export interface ConfigState {
  fetching: boolean;
  data: Config[] | null;
  //Corresponds to FetchResourceGroupStarted / FetchResourceGroupCompleted
  resourceGroupData: {
    [configId: string]: { isFetchingResourceGroup: boolean; data?: string[] };
  };
  saving: boolean;
  deleting: boolean;
  eksConfigState: EKSConfigState;
}
