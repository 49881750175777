import React from 'react';
import moment, { Moment } from 'moment';
import { default as TimePicker, TimePickerProps } from 'antd/lib/time-picker';
import InputCommonProps from './index.component';

export class TimePickerWrapper extends React.Component<
  InputCommonProps & TimePickerProps
> {
  handleChange = (v: Moment) => {
    let e = {
      target: {
        value: v ? v.format(this.props.format) : '09:00',
        name: this.props.name,
      },
    };
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    let o = this.props.value;
    let value;
    if (o) {
      let split = o.split(':');
      let hour = Number[split[0]] <= 9 ? '0' + split[0] : split[0];
      let min = Number[split[1]] <= 9 ? '0' + split[1] : split[1];
      value = moment(hour + ':' + min, this.props.format);
    } else {
      value = undefined;
    }

    return (
      <div>
        <TimePicker
          {...this.props}
          onChange={this.handleChange}
          value={value}
        />
        {this.props.error && (
          <small className="text-danger">{this.props.error}</small>
        )}
      </div>
    );
  }
}
