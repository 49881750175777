import * as React from 'react';

interface TooltipProps {
  isRefreshing: boolean;

  onRefresh(data?: any): void;
}

const RefreshHandler = (props: TooltipProps) => {
  const { isRefreshing, onRefresh } = props;

  return (
    <button
      type="button"
      onClick={(e: any) => onRefresh(e)}
      className="input-group-text refresh-handler border-0 outline-0"
    >
      {isRefreshing && <i className="fa fa-spinner fa-pulse fa-fw" />}
      {!isRefreshing && <i className="fa fa-repeat" />}
    </button>
  );
};

export default RefreshHandler;
