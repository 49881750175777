import React from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../../design/colors';

const slide = keyframes`

0%{
left: -300px;
}
100%{
left: calc(100% + 300px)}

`;
const LoaderContainer = styled.div`
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 1000;

  & div {
    height: 100%;
    background-color: ${colors.palette.bgPrimary};
    width: 300px;
    position: absolute;
    left: -300px;
    animation: ${slide} 1s linear infinite;
  }
`;

const LineLoader = () => {
  return (
    <LoaderContainer>
      <div> </div>
    </LoaderContainer>
  );
};
export default LineLoader;
