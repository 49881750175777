import { Config } from '../../../../lib/store/config/types';
import { Workflow } from '../../../../lib/store/workflows/types';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import {
  FormatUtilisationData,
  UtilisationSummaryDataFormatter,
} from './utilisationTab';
import { eventsTableFormatter } from '../formatter/eventsTableFormatter';
import { getLatestExecutionStatus } from '../LastExecutionsStatus';
import { Template } from '../../../../lib/store/templates/types';
import { ExecutionsMapType } from '../../../../lib/store/executions/types';
import { ItemUpdateStateMap } from '../../../../lib/store/templates-manage/types';
import { FilterWorkflows } from '../FilterWorkflows';
import { SortWorkflows } from '../SortWorkflows';
import {
  nameColDataFormatter,
  NameColDataType,
} from '../formatter/NameColFormatter';
import {
  resultColDataFormatter,
  ResultColDataType,
} from '../formatter/ResultColFormatter';
import {
  serviceColDataFormatter,
  ServiceColDataType,
} from '../formatter/ServiceColFormatter';
import {
  severityColDataFormatter,
  SeverityColDataType,
} from '../formatter/SeverityColFormatter';
import {
  fixColDataFormatter,
  FixColDataType,
  getAccountFromWorkflow,
  getAllAccountFromWorkflow,
} from '../formatter/FixColFormatter';
import { OptionsColDataType } from '../formatter/OptionsColFormatter';
import classNames from 'classnames';
import { REGIONS } from '../../../../lib/editor/components/model/config/Regions';
import {
  getRegionsFromWorkflow,
  getResourceGroupsFromWorkflow,
} from '../utils';
import {
  FormatGlobalInventoryTabData,
  FormatInventoryTabData,
} from './inventoryTabData';
import { InsightSummaryDataFormatter } from '../summaryFormatter/insightSummary';
import { VIEW } from '../workflowTable';
import { Badge } from 'reactstrap';
import * as React from 'react';

interface ParentProps {
  workflows: Workflow[];
  templates?: Template[];
  executionsMap: ExecutionsMapType;
  isFetchingWorkflow: boolean;
  tabName?: TAB_NAME;
  config?: Config[];
  onAdoptRequest: (templateId: string, option: string) => void;
  isLoading?: boolean;
  isFetchingExecutions: boolean;
  isFetchingAnyExecutions: boolean;
  allWorkflows: { workflow: string; name: string }[];
  allTemplates: {
    id: string;
    name: string;
  }[];
  updatingColMap: ItemUpdateStateMap;

  updateConfigAndRegion?: (key: string, value: string[]) => void;
  changeView?: (view: VIEW) => void;
  onChangeSeverityFilter?: (value) => void;
  onChangeAccountFilter?: (value) => void;
  onChangeResultFilter?: (value) => void;
  onChangeServiceFilter?: (value) => void;
  fetchWorkflows: () => void;
  runLinkedWorkflow: (props: {
    parentWorkflowId: string;
    workflow: string;
  }) => void;
  updateAutoRunOfLink: (props: {
    parentWorkflowId: string;
    status: boolean;
  }) => void;
  updateLinkedWorkflow: (props: {
    parentWorkflowId: string;
    workflow: string;
  }) => void;
  setResourceView?: (viewData: any) => void;
  isWorkflowReport?: boolean;
  credentials?: string[];
}

interface FormatDataProps {
  onChangeOption: Function;
  toggleActive: Function;
  adoptTemplateAndRunFixForInsight: (props: {
    parentWorkflowId: string;
    templateId: string;
    regions: string[];
    accounts: string[];
    runFix?: boolean;
  }) => void;
}

export const getTableInfoText = (tabName) => {
  if (tabName === TAB_NAME.UTILISATION) {
    return 'Based on usage data of past 14 days';
  }
  return null;
};

export const getTableData = (
  props: ParentProps &
    FormatDataProps & {
      selectedFilter: string;
      selectedSort: string;
      view: VIEW;
    },
  isSummaryData?: boolean,
) => {
  let workflows = FilterWorkflows({
    tabName: props.tabName,
    workflows: props.workflows,
    executionMap: props.executionsMap,
    selectedFilter: props.selectedFilter,
  });

  workflows = SortWorkflows({
    tabName: props.tabName,
    workflows,
    executionMap: props.executionsMap,
    selectedSort: props.selectedSort,
  });

  if (isSummaryData) {
    return dataFormatter(
      getSummaryFormattedData({ ...props, workflows }),
      props.config,
    );
  }
  // combine both formatter with clean code.
  return dataFormatter(getFormattedData({ ...props, workflows }), props.config);
};

export const getSummaryFormattedData = (
  props: FormatDataProps & ParentProps & { view: VIEW },
): { [s: string]: any }[] => {
  let { tabName } = props;

  if (tabName === TAB_NAME.INVENTORY) {
    return FormatGlobalInventoryTabData(props);
  }
  if (tabName === TAB_NAME.UTILISATION) {
    return UtilisationSummaryDataFormatter(props);
  }
  if (tabName === TAB_NAME.INSIGHTS) {
    return InsightSummaryDataFormatter({
      ...props,
      data: getFormattedData(props),
    });
  }
  return [];
};

export const getFormattedData = (
  props: FormatDataProps & ParentProps,
): { [s: string]: any }[] => {
  let { workflows, tabName, executionsMap, config } = props;

  if (tabName === TAB_NAME.INVENTORY) return FormatInventoryTabData(props);
  if (tabName === TAB_NAME.UTILISATION) return FormatUtilisationData(props);

  if (isEventSearchTable(tabName)) {
    const {
      workflow,
      execution,
      executionResult,
      fetching,
    } = getEventsExecution(props);
    return eventsTableFormatter(workflow, execution, executionResult, fetching);
  }

  let data = workflows.map((x) => {
    let { result, executions, isFetchingExecution } = getLatestExecutionStatus({
      workflow: x.workflow,
      executionMap: executionsMap,
      isFetchingWorkflow: props.isFetchingWorkflow,
    });
    // console.log({executions})

    let data = {
      ...x,
      name: x.name && x.name.trim(),
      executions,
      isFetchingExecution,
      result,
      tabName,
      isFetchingWorkflow: props.isFetchingWorkflow,
      isFetching: props.isLoading,
      adoptTemplateAndRunFix: props.adoptTemplateAndRunFixForInsight,
      runLinkedWorkflow: props.runLinkedWorkflow,
      updateAutoRunOfLink: props.updateAutoRunOfLink,
      updateLinkedWorkflow: props.updateLinkedWorkflow,
      updating:
        props.updatingColMap && props.updatingColMap[x.workflow]
          ? props.updatingColMap[x.workflow].updating
          : false,
      allWorkflows: props.allWorkflows,
      allTemplates: props.allTemplates,
      linkedWorkflowOutput: getLinkedWorkflowExecution(x, executionsMap),
      isWorkflowReport: props.isWorkflowReport,
      fetchWorkflows: props.fetchWorkflows,
    };
    data['toggleActive'] = props.toggleActive.bind(this, data);
    data['onChangeOption'] = props.onChangeOption.bind(this, data);
    return data;
  });

  let data2 = [];
  if (isEventSearchTable(tabName)) {
    tabName = TAB_NAME.EVENTS; // overriding virtual tab name
  }

  if (
    tabName !== TAB_NAME.WORKFLOWS &&
    tabName !== TAB_NAME.INSIGHTS &&
    props.templates &&
    props.templates.length
  ) {
    data2 = props.templates.map((x) => {
      return {
        ...x,
        tabName: tabName,
        isTemplateItem: true,
        onAdoptRequest: props.onAdoptRequest,
      };
    });
  }
  return data.concat(data2);
};

const getLinkedWorkflowExecution = (
  workflow: Workflow,
  executionsMap: ExecutionsMapType,
) => {
  if (
    !workflow.properties ||
    !workflow.properties.linkedWorkflows ||
    !workflow.properties.linkedWorkflows.length
  ) {
    return '';
  }
  let linkedWfExecution = executionsMap[workflow.properties.linkedWorkflows[0]];
  return linkedWfExecution && linkedWfExecution.output;
};
export const getEventsExecution = (props: ParentProps) => {
  const eventsLookupWorkflow = props.workflows[0];
  const execution =
    props.executionsMap[eventsLookupWorkflow && eventsLookupWorkflow.workflow];
  const latestExecution = execution && execution.exes && execution.exes[0];
  const isFetching = execution ? execution.fetching : true;
  const output =
    execution && execution.output && execution.output.eventLookupResult;
  const triggerOptions = output && output.initialOptions;
  const executionResult = output && output.result;
  return {
    workflow: eventsLookupWorkflow,
    execution: latestExecution,
    fetching:
      isFetching || props.isFetchingExecutions || props.isFetchingAnyExecutions, /// isFetchingExecutions = any batch execution fetch
    triggerOptions,
    executionResult,
  };
};

export const isEventSearchTable = (tabName: TAB_NAME) => {
  return tabName === TAB_NAME.EVENTS_SEARCH;
};

export type RowDataType = {
  _id: number;
  isTemplateItem: boolean;
  Name: NameColDataType;
  Result: ResultColDataType;
  service: ServiceColDataType;
  severity: SeverityColDataType;
  Fix: FixColDataType;
  options: OptionsColDataType;
  regionName: string;
  accountName: string[];
};
// todo type
export const dataFormatter = (data, config) => {
  let rows = data.map((x, i) => {
    let region = REGIONS.find(
      (r) => r.value === getRegionsFromWorkflow([x])[0] || '',
    );
    const resourceGroup = getResourceGroupsFromWorkflow([x])[0] || '';
    let accountIds = getAllAccountFromWorkflow(x);
    let workflowConfigs = config
      ? config.filter((x) => accountIds.includes(x.id))
      : [];
    let row: RowDataType = {
      _id: i,
      isTemplateItem: x.isTemplateItem,
      Name: nameColDataFormatter(x),
      Result: resultColDataFormatter(x),
      service: serviceColDataFormatter(x),
      severity: severityColDataFormatter(x),
      Fix: fixColDataFormatter(x, config),
      regionName: region ? region.label : resourceGroup || '',
      accountName: workflowConfigs.map((u) => u.name),
      options: {
        templateId: x.id,
        toggleActive: x.toggleActive,
        onAdoptRequest: x.onAdoptRequest,
        onChangeOption: x.onChangeOption,
        active: x.properties ? x.properties.active : false,
        classNames: classNames({
          'fa px-3': true,
          'fa-ellipsis-v fa': !x.isFetchingWorkflow,
          'fa-spin fa-spinner': x.isFetchingWorkflow,
        }),
      },
    };
    return { ...x, ...row };
  });
  return rows;
};

export const getReportId = (url) => {
  if (!url) {
    return undefined;
  }
  let tempArray = url.split('/');
  let index = tempArray.indexOf('report');
  let reportId = tempArray[index + 1];
  return reportId;
};
