import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';

interface DummyFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  height?: string;
  width?: string;
  circle?: boolean;
  rounded?: boolean;
}

const StyledDiv = styled.div<DummyFieldProps>`
  height: ${(props) => {
    return props.height || '20px';
  }};
  width: ${(props) => props.width || '100px'};
  border-radius: ${(props) =>
    props.circle ? '50%' : props.rounded ? '4px' : '3px'};
  margin: 0 5px;
`;

export const DummyField = (props: DummyFieldProps) => {
  const { className, ...rest } = props;
  return <StyledDiv className={`shimmer ${className}`} {...rest} />;
};
