import { Workflow } from '../../../../lib/store/workflows/types';
import { Execution } from '../../../../lib/store/executions/types';

export function eventsTableFormatter(
  workflow: Workflow,
  executions: Execution,
  executionResult: any[],
  fetching: boolean,
) {
  if (fetching || !executionResult) return [];
  const results = [];
  // console.log(executionResult);
  for (const result of executionResult) {
    if (result.CloudTrailEvent) {
      let parsed;
      try {
        parsed = JSON.parse(result.CloudTrailEvent);
      } catch (e) {
        parsed = result.CloudTrailEvent;
      }
      result.CloudTrailEvent = parsed;
    }
    results.push({
      ...result,
      _raw: result,
      isEventHistory: true,
    });
  }
  return results;
}
