import styled from 'styled-components';
import Container from 'reactstrap/lib/Container';
import colors from '../design/colors';

export const AppContainer = styled(Container).attrs({
  id: 'totalcloud-webapp',
})`
  ${(props) => props.useColor && `background: ${colors.palette.bgSecondary};`}
  min-height: 100vh;
  line-height: 1;
  border: 0;
  color: black;
`;
