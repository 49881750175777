export enum TableSubCategories {
  TABLE_DATA = 'tableData',
  CATEGORY_DATA = 'categoryData',
  SUMMARY_DATA = 'summaryData',
  TRIGGER_SETTINGS_DATA = 'triggerSettingsData',
  SHARE_SETTINGS_DATA = 'shareSettingsData',
  ADOPTION_SETTINGS_DATA = 'adoptionData',
  EXECUTIONS = 'executions',
  WORKFLOWS = 'workflows',
  USERS_LIST = 'userList',
}

export * from './SummaryAndDataTableWrapper';
export * from './TableWrapper';
