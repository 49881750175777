import React from 'react';
import { withExternalData } from '../../../common/externalDependency';
import { fixStructure } from '../../../report/pipeline';
import { rootContainerId } from '../../../report/downloadMenu';
import { DataTable } from '../../../../lib/data-table';
import { Row, Col } from 'reactstrap';
import { ReportBucket } from '../../../../config/resources.json';
import { ReportNotFoundEmbed } from '../../../common/fallback/ReportNotFound';

interface IProps {
  data: any;
}

interface IState {}

class ReportLevelView extends React.Component<IProps, IState> {
  render() {
    let { data } = this.props;

    if (data.error)
      return (
        <ReportNotFoundEmbed
          buttonText={'Inventory'}
          buttonLink={'/inventory'}
        />
      );

    data = fixStructure(data.data);
    return (
      <>
        <Row>
          <Col className="test-workflow-name font-20 mb-2" lg={12}>
            {this.props.data.workflow}
          </Col>
        </Row>
        <DataTable data={data} divId={rootContainerId} />
      </>
    );
  }
}

const fetchReportData = async (props) => {
  let { data } = props;
  let url = `https://s3.amazonaws.com/${ReportBucket}/${data}.json`;
  try {
    let response = await fetch(url);
    let data = await response.json();
    if (response.status !== 200) {
      data.error = true;
    }
    data.url = url;
    return data;
  } catch (e) {
    return {
      url,
      error: true,
    };
  }
};

export default withExternalData(ReportLevelView, fetchReportData);
