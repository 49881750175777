import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

interface DeleteAccountMessageProps {
  message: string;
}

/**
 *
 * DeleteAccountMessage Component to show after deletion is triggered
 */

export const DeleteAccountError = ({ message }: DeleteAccountMessageProps) => {
  return (
    <div className={'dashboard-content custom-container py-4 px-4'}>
      <Row>
        <Col md={12} className={'text-center py-5'}>
          <h3 className={'font-weight-bold text-dark'}>
            Cannot process your request.
          </h3>
          <p className={`font-18 text-danger`}>{message}</p>
          <div className={'mt-3'}>
            <Link to={'/'} className={'btn btn-primary'}>
              Go to homepage
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};
