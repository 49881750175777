// workflow or template_account id : { action : true| false }
import { TAB_NAME } from 'webapp-genric/dist/constants';

export interface MultiPolicyMap {
  details: {
    [s: string]: { [s: string]: boolean };
  };
  error: string;
}

export interface IPolicyDoc {
  minPolicy: any;
  recommendedPolicy: any;
}

export const MultiPolicyRequest = 'MultiPolicyRequest';
export const MultiPolicyResponse = 'MultiPolicyResponse';
export const PolicyDocRequest = 'PolicyDocRequest';
export const PolicyDocResponse = 'PolicyDocResponse';
export const GlobalPolicyRequest = 'GlobalPolicyRequest';
export const GlobalPolicyResponse = 'GlobalPolicyResponse';

interface GlobalPolicyRequest {
  type: typeof GlobalPolicyRequest;
}

interface GlobalPolicyResponse {
  type: typeof GlobalPolicyResponse;
  data: GlobalPolicyType;
}

interface PolicyDocRequest {
  type: typeof PolicyDocRequest;
}

interface PolicyDocResponse {
  type: typeof PolicyDocResponse;
  data: IPolicyDoc;
}

interface MultiPolicyRequest {
  type: typeof MultiPolicyRequest;
}

interface MultiPolicyResponse {
  type: typeof MultiPolicyResponse;
  data: MultiPolicyMap;
  account: string;
}

export type PolicyAction =
  | MultiPolicyRequest
  | MultiPolicyResponse
  | PolicyDocRequest
  | PolicyDocResponse
  | GlobalPolicyRequest
  | GlobalPolicyResponse;

export type GlobalPolicyDetailsType = {
  details: {
    [workflow: string]: {
      [action: string]: boolean;
    };
  };
  isAuthError: boolean;
};

export type GlobalPolicyType = {
  workflows: {
    [account: string]: GlobalPolicyDetailsType;
  };
  tabSummary: {
    [account: string]: {
      [tabName: string]: GlobalPolicyDetailsType;
    };
  };
};

export interface PolicyState {
  fetching: boolean;
  fetchingGlobalPolicy: boolean;
  data: null | { [account: string]: MultiPolicyMap };
  fetchingPolicyDoc: boolean;
  policyDoc: IPolicyDoc;
  globalPolicy: GlobalPolicyType;
}
