import { REGIONS } from '../../../../lib/editor/components/model/config/Regions';
import { Badge } from 'reactstrap';
import * as React from 'react';

export const regionFilterValue = (col) => {
  if (!col) return '';
  let region = REGIONS.find((x) => x.label === col);
  if (!region) return col;
  return col + ' ' + region.value;
};

export const regionDisplayFormatter = (col) => {
  if (!col) return '';
  return (
    <Badge color="light" className={''}>
      {col}
    </Badge>
  );
};
