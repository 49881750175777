import { Col, Row } from 'reactstrap';
import React from 'react';
import { Heading } from './Heading';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { Options } from './Options';
import { CreateNewButton } from './CreateNewButton';
import { ShareOptions } from './ShareOptions';
import { TableSubCategories } from '../tables';
import { ExecutionStatus } from './ExecutionStatus';

interface PageHeadingProps {
  showHeading: boolean;
  showExecutionTimeStamp: boolean;
  showCreateButton: boolean;
  showOptions: boolean;
  showShareOptions: boolean;

  /**
   * A Category to differentiate type of page.
   */
  category: string;
  /**
   * A subCategory to differentiate between different Components of the page.
   */
  subCategories: string[];
  tabName: TAB_NAME;
  itemCount?: string;
  user: string;
  workflowCategory?: string;
  workflowSubCategory?: string;
  regions: string[];
  credentials: string[];
}

export const PageHeaderComponent = (props: PageHeadingProps) => {
  const {
    showHeading,
    showOptions,
    tabName,
    showShareOptions,
    category,
    subCategories,
    showCreateButton,
    showExecutionTimeStamp,
    workflowCategory,
    workflowSubCategory,
    user,
    itemCount,
    credentials,
    regions,
  } = props;

  const renderShareOptions = (): JSX.Element => {
    if (
      showShareOptions &&
      subCategories.includes(TableSubCategories.SHARE_SETTINGS_DATA)
    )
      return (
        <ShareOptions
          user={user}
          workflowCategory={workflowCategory}
          workflowSubCategory={workflowSubCategory}
          tabName={tabName}
          category={category}
          subCategory={TableSubCategories.SHARE_SETTINGS_DATA}
        />
      );

    return null;
  };

  const renderOptions = (): JSX.Element => {
    if (
      showOptions &&
      (subCategories.includes(TableSubCategories.TRIGGER_SETTINGS_DATA) ||
        subCategories.includes(TableSubCategories.ADOPTION_SETTINGS_DATA))
    )
      return (
        <Options
          credentials={credentials}
          regions={regions}
          user={user}
          category={category}
          subCategories={subCategories}
          tabName={tabName}
        />
      );

    return null;
  };

  const renderCount = () => {
    if (itemCount)
      return (
        <small
          data-test="list-layout-count"
          className="px-2 small text-muted font-italic font-weight-light test-workflows-count"
        >
          {itemCount}
        </small>
      );

    return null;
  };

  const buttonText = tabName === TAB_NAME.INSIGHTS ? 'Insight' : undefined;

  return (
    <>
      <Row className={'mb-3'}>
        <Col md={4} className={'d-flex align-items-center'}>
          {showHeading && <Heading tabName={tabName} />}
          {renderCount()}
        </Col>
        <Col md={8} className={'d-flex justify-content-end align-items-center'}>
          {showExecutionTimeStamp && (
            <ExecutionStatus
              category={category}
              subCategory={TableSubCategories.TRIGGER_SETTINGS_DATA}
            />
          )}
          {renderShareOptions()}
          {renderOptions()}
          {showCreateButton && (
            <CreateNewButton buttonText={buttonText} tabName={tabName} />
          )}
        </Col>
      </Row>
    </>
  );
};
