import * as React from 'react';
import { withAuth } from '@okta/okta-react';
import { SecureRouteProps } from './tcApiHelper';
import { SectionLoader } from '../components/common/SectionLoader';

/**
 * embed user:string property to a type
 */
export type WithUser<T = any> = T & { user: string };

/**
 * Add a user prop to the component after getting it from okta
 * @param Component
 */
export function withUser<T extends { user: string } = any>(
  Component: React.ComponentType<T>,
): React.ComponentType<Omit<T, 'user'>> {
  class Intermediate extends React.Component<
    Omit<T, 'user'> & SecureRouteProps,
    { user: string }
  > {
    state = {
      user: '',
    };

    componentDidMount(): void {
      this.props.auth
        .getUser()
        .then((auth) => {
          auth && this.setState({ user: auth.email });
        })
        .catch((err) => console.error(err));
    }

    render() {
      if (!this.state.user) return <SectionLoader />;
      // @ts-ignore
      return <Component {...this.props} user={this.state.user} />;
    }
  }

  return withAuth(Intermediate);
}
