import React, { Component } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-plugin-stacked100';
import * as chartjs from 'chart.js';

interface OwnProps {
  chartTitle?: string;
  height?: number;
  data: {
    label: string[];
    value: {
      [Key: string]: number[];
    };
  } | null;
  xUnit: string;
  yUnit: string;
  isReady: boolean;
}

type IProps = OwnProps;

type IState = {
  legendVisible: number[];
  chartData: { labels: string[]; datasets: any[] };
};

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  // var x = Math.floor(Math.random() * 256);
  // var y = Math.floor(Math.random() * 256);
  // var z = Math.floor(Math.random() * 256);
  // var bgColor = `rgb(${x},${y},${z},0.1)`;
  return color;
}

function generateRandomNumber(min, max, noOfTimes, isString) {
  let arr = [];
  for (let i = 1; i < noOfTimes; i++) {
    if (isString) {
      arr.push(String(Math.floor(Math.random() * (max - min) + min)));
    } else {
      arr.push(Math.floor(Math.random() * (max - min) + min));
    }
  }
  return arr;
}

// function createMockData(noOfData, min, max, noOfTimes) {
//   let data: chartjs.ChartData = {
//     labels: [],
//     datasets: [],
//   };
//   data.labels = generateRandomNumber(min, max, noOfTimes, true);
//   for (let i = 1; i <= noOfData; i++) {
//     let randomColor = getRandomColor();
//     data.datasets.push({
//       label: `instance${i}`,
//       data: generateRandomNumber(min, max, noOfTimes, false),
//       borderColor: 'transparent',
//       pointBackgroundColor: 'transparent',
//       pointBorderColor: 'transparent',
//       pointHoverBorderColor: randomColor,
//       pointHoverBackgroundColor: randomColor,
//       backgroundColor: randomColor,
//     });
//   }
//   return data;
// }

const formatChartData = (data) => {
  if (data) {
    let xCoordinates = data.label;
    let yCoordinates = Object.keys(data.value).map((key, index) => {
      let randomColor = getRandomColor();
      return {
        label: key,
        data: data.value[key],
        fill: false,
        borderColor: randomColor,
        pointBackgroundColor: randomColor,
      };
    });
    return {
      labels: xCoordinates,
      datasets: yCoordinates,
    };
  }
  return null;
};

export class CustomChart extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.chart = React.createRef();
    this.state = {
      legendVisible: [],
      chartData: null,
    };
  }

  componentDidMount() {
    let chartData = formatChartData(this.props.data);
    this.setState({
      chartData,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      let chartData = formatChartData(this.props.data);
      this.setState({
        chartData,
      });
    }
  }

  hideAllOtherInstance = (e, legendItem) => {
    var index = legendItem.datasetIndex;
    let newLegendVisible = this.state.legendVisible;
    if (newLegendVisible.findIndex((v) => v === index) !== -1) {
      newLegendVisible = newLegendVisible.filter(function(value) {
        return value !== index;
      });
      this.setState({
        legendVisible: newLegendVisible,
      });
    } else {
      newLegendVisible.push(index);
      this.setState({
        legendVisible: newLegendVisible,
      });
    }

    var ci = this.chart.current.chartInstance;
    ci.data.datasets.forEach(function(e, i) {
      var meta = ci.getDatasetMeta(i);

      if (newLegendVisible.findIndex((v) => v === i) === -1) {
        if (newLegendVisible.length === 0) {
          meta.hidden = null;
          return;
        }
        meta.hidden = true;
      } else {
        meta.hidden = null;
      }
    });

    ci.update();
  };
  chart: any;

  render() {
    const options: chartjs.ChartOptions = {
      title: {
        display: this.props.chartTitle ? true : false,
        text: this.props.chartTitle,
      },
      responsive: true,
      tooltips: {
        enabled: true,
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.props.xUnit,
            },
            ticks: {},
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.props.yUnit,
            },
            ticks: {
              callback: (label, index, labels) => {
                if (label > 1000 && this.props.yUnit !== 'Percent') {
                  return (label / 1000).toFixed(3) + 'k';
                } else {
                  return label;
                }
              },
              maxTicksLimit: 5,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
    // let mockData = createMockData(50, 1, 100, 20);

    let { chartData, legendVisible } = this.state;
    if (!this.props.isReady) {
      return (
        <div className="w-100 p-5 my-3 text-center">
          Loading summary
          <i className="fa fa-spin fa-spinner ml-1" />
        </div>
      );
    }
    if (
      this.props.isReady &&
      this.props.data.label &&
      !this.props.data.label.length
    ) {
      return (
        <div className="w-100 p-5 my-3 text-center font-italic">
          {' '}
          No Data Points Available
        </div>
      );
    }

    const { chartInstance } = this.chart.current || {};

    return (
      <>
        <Line
          data={chartData}
          ref={this.chart}
          height={this.props.height || 100}
          options={options}
        />
        <div className="utilisation-legend-container">
          <div className="row no-gutters">
            {chartInstance && (
              <UtilisationLegends
                chart={chartInstance}
                hideAllOtherInstance={this.hideAllOtherInstance}
                legendVisible={legendVisible}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const UtilisationLegends = ({
  chart,
  hideAllOtherInstance,
  legendVisible,
}: {
  chart: any;
  legendVisible: number[];
  hideAllOtherInstance: (e, index) => void;
}) => {
  return (
    <>
      {chart.config.data.datasets.map((chartItem, index) => (
        <div
          className="col-md-3 pl-2 cursor-pointer list-style-none"
          onClick={(e) =>
            hideAllOtherInstance(e, chart.legend.legendItems[index])
          }
        >
          <div
            className="d-inline-block square-box"
            style={{
              backgroundColor: `
                ${chartItem.borderColor}
                `,
            }}
          />
          <span
            className={
              legendVisible.length > 0 &&
              legendVisible.findIndex((legend) => legend === index) === -1
                ? 'pl-1 text-strikethrough'
                : 'pl-1'
            }
          >
            {chartItem.label}
          </span>
        </div>
      ))}
    </>
  );
};
