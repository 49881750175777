export interface Tenant {
  tenantId: string;
  name: string;
  domain: string;
  contact: string;
  owner: string;
  client_id: string;
  okta_domain: string;
  registration_token: string;
  orgAdmins: string[];
  isSubjectAdmin: boolean;
}

export interface OrgUser {
  id: string;
  status: string;
  email: string;
  firstName: string;
  lastName: string;
  groups: OrgGroup[];
}

export interface OrgGroup {
  id: string;
  name: string;
  users: OrgUser[];
  policies: OrgPolicy[];
}

export interface OrgPolicy {
  id: string;
  type: string;
  resources: [];
}

// events
export const FetchUserStarted = 'FetchUserStarted';
export const FetchUserEnded = 'FetchUserEnded';
export const FetchGroupsStarted = 'FetchGroupsStarted';
export const FetchGroupsEnded = 'FetchGroupsEnded';
export const AddUserStarted = 'AddUserStarted';
export const AddUserEnded = 'AddUserEnded';
export const ToggleUserStarted = 'ToggleUserStarted';
export const ToggleUserEnded = 'ToggleUserEnded';
export const AddGroupStarted = 'AddGroupStarted';
export const AddGroupEnded = 'AddGroupEnded';
export const DeleteGroupStarted = 'DeleteGroupStarted';
export const DeleteGroupEnded = 'DeleteGroupEnded';
export const AssignUserToGroupStarted = 'AssignUserToGroupStarted';
export const AssignUserToGroupEnded = 'AssignUserToGroupEnded';
export const UpdateGroupStarted = 'UpdateGroupStarted';
export const UpdateGroupEnded = 'UpdateGroupEnded';
export const FetchTenantStarted = 'FetchTenantStarted';
export const FetchTenantEnded = 'FetchTenantEnded';

export interface FetchUserStarted {
  type: typeof FetchUserStarted;
}
export interface FetchUserEnded {
  type: typeof FetchUserEnded;
  users: OrgUser[] | null;
}
export interface FetchGroupsStarted {
  type: typeof FetchGroupsStarted;
}
export interface FetchGroupsEnded {
  type: typeof FetchGroupsEnded;
  groups: OrgGroup[] | null;
}
export interface AddUserStarted {
  type: typeof AddUserStarted;
}
export interface AddUserEnded {
  type: typeof AddUserEnded;
}
export interface DeleteUserStarted {
  type: typeof ToggleUserStarted;
}
export interface DeleteUserEnded {
  type: typeof ToggleUserEnded;
}
export interface AddGroupStarted {
  type: typeof AddGroupStarted;
}
export interface AddGroupEnded {
  type: typeof AddGroupEnded;
}
export interface DeleteGroupStarted {
  type: typeof DeleteGroupStarted;
}
export interface DeleteGroupEnded {
  type: typeof DeleteGroupEnded;
}
export interface AssignUserToGroupStarted {
  type: typeof AssignUserToGroupStarted;
}
export interface AssignUserToGroupEnded {
  type: typeof AssignUserToGroupEnded;
}
export interface UpdateGroupStarted {
  type: typeof UpdateGroupStarted;
}
export interface UpdateGroupEnded {
  type: typeof UpdateGroupEnded;
}
export interface FetchTenantStarted {
  type: typeof FetchTenantStarted;
}
export interface FetchTenantEnded {
  type: typeof FetchTenantEnded;
  tenant: Tenant;
}

export type TenantAction =
  | FetchUserStarted
  | FetchUserEnded
  | FetchGroupsStarted
  | FetchGroupsEnded
  | AddUserStarted
  | AddUserEnded
  | DeleteUserStarted
  | DeleteUserEnded
  | AddGroupStarted
  | AddGroupEnded
  | DeleteGroupStarted
  | DeleteGroupEnded
  | AssignUserToGroupStarted
  | AssignUserToGroupEnded
  | UpdateGroupStarted
  | UpdateGroupEnded
  | FetchTenantStarted
  | FetchTenantEnded;

export type TenantStore = {
  isFetchingUsers: boolean;
  isFetchingGroups: boolean;
  isAddingUser: boolean;
  isTogglingUserActive: boolean;
  isAddingGroup: boolean;
  isDeletingGroup: boolean;
  isModifyingUserGroups: boolean;
  isUpdatingGroup: boolean;
  isFetchingTenantDetails: boolean;
  tenant: Tenant | null;
  orgUsers: OrgUser[];
  orgGroups: OrgGroup[];
};
