import * as React from 'react';
import {
  Button,
  UncontrolledPopover,
  PopoverHeader,
  Row,
  Col,
  PopoverBody,
} from 'reactstrap';
import { Template } from '../../../lib/store/templates/types';
import Categories from './TemplateModalContentCategoriesUi';
interface IProps {
  template: Template;
  updateCategories: (params: {
    category: string[];
    subCategory: string[];
    template: Template;
  }) => void;
}

interface IState {
  isOpen: boolean;
  category: Array<string>;
  subCategory: Array<string>;
}

export default class InlineCategory extends React.Component<IProps, IState> {
  state = {
    isOpen: false,
    category: [],
    subCategory: [],
  };

  componentDidMount(): void {
    this.init();
  }

  init() {
    if (!this.props.template) return;
    this.setState({
      category: this.props.template.category || [],
      subCategory: this.props.template.subCategory || [],
    });
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (this.props.template !== prevProps.template) {
      this.init();
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onCategoryChange = (option) => {
    this.setState({ category: option ? option : [] });
  };

  onSubCategoryChange = (option) => {
    this.setState({ subCategory: option ? option : [] });
  };

  render() {
    let { category, subCategory } = this.state;
    let id = 'popover_' + this.props.template.id;
    return (
      <div>
        <a
          title={category.join(',')}
          className={'text-decoration-none'}
          id={id}
        >
          Edit
        </a>
        <UncontrolledPopover
          style={{ width: '610px', backgroundColor: 'white' }}
          toggle={this.toggle}
          isOpen={this.state.isOpen}
          trigger="legacy"
          placement="left"
          target={id}
        >
          <PopoverHeader>
            <Row>
              <Col md={9} className={'text-truncate'}>
                {this.props.template.name}
              </Col>
              <Col md={2}>
                <Button
                  color="primary"
                  onClick={() =>
                    this.props.updateCategories({
                      ...this.state,
                      template: this.props.template,
                    })
                  }
                >
                  Save
                </Button>
              </Col>
            </Row>
          </PopoverHeader>
          <PopoverBody
            style={{
              maxHeight: '700px',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          >
            <Categories
              categories={category}
              subCategories={subCategory}
              onSubCategoryChange={this.onSubCategoryChange}
              onCategoryChange={this.onCategoryChange}
            />
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    );
  }
}
