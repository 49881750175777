import * as React from 'react';
import { shouldShowTimeStamp } from '../../../../config/WorkflowCategories';
import { Col, UncontrolledTooltip } from 'reactstrap';
import { RowDataType } from '../dataFormatter/FormatWorkflowsData';

export const tagColDisplayFormatter = (
  col: { [s: string]: number },
  matchVal: string,
) => {
  if (!col) return '';
  let tagKey = Object.keys(col).filter((k) => k.startsWith(matchVal));
  return tagKey.reduce((A, e) => A + col[e], 0);
};

export const tagHeaderDisplayFormatter = (
  column,
  colIndex,
  { sortElement, filterElement },
): any => {
  return (
    <div className={'d-flex'}>
      {filterElement}
      <span className={'align-self-center text-truncate'} title={column.text}>
        {column.text}
      </span>
      {sortElement}
    </div>
  );
};
