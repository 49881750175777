import * as React from 'react';

import {
  SchemaValidationError,
  TcJsonEditor,
} from '../../../../formInputs/json-editor';

export interface AdvancedFiltersProps {
  name: string;
  error: string;
  touched?: boolean | object;
  value: { paramsValue: { [key: string]: any }; error: boolean };
  h2: string;
  setError?: Function;
  onChange: Function;
  onBlur?: Function;
  autoCompletionList?: string[];
  onSchemaValidation?: (json: any) => Promise<SchemaValidationError[]>;
}

interface IState {}

export default class AdvancedFiltersView extends React.Component<
  AdvancedFiltersProps,
  IState
> {
  onUserInput = ({ data, error }): void => {
    this.props.onChange({
      target: { name: this.props.name, value: { paramsValue: data, error } },
    });
  };

  renderContent() {
    let { autoCompletionList, value, error, h2 } = this.props;
    return (
      <div>
        <TcJsonEditor
          label={h2}
          autoCompletionList={autoCompletionList}
          onChange={this.onUserInput}
          name="Params"
          error={error}
          onSchemaValidation={this.props.onSchemaValidation}
          value={{
            data: value.paramsValue,
            error: value.error,
          }}
        />
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}
