import * as React from 'react';
import { Col, FormFeedback, Input, InputGroup, Label, Row } from 'reactstrap';

import InputCommonProps from './index.component';
import Tooltip from './shared/tooltip.component';
import ExternalLink from './shared/external-link.component';
import RefreshHandler from './shared/refresh-handler.component';
import './forminputs.style.css';
import Select, { createFilter } from 'react-select';

type options = {
  disabled?: boolean;
  label: string;
  value: string;
};

interface InputTextProps extends InputCommonProps {
  value: any;
  options: {
    disabled?: boolean;
    label: string;
    value: any | string;
  }[];
  defaultOption?: {
    disabled?: boolean;
    label: string;
    value: string;
  };
  useAlt?: boolean;
  isRefreshing?: boolean;

  onRefresh?(data: any): void;

  className?: any;
  clear?: true;
}

const InputSelect = (props: InputTextProps) => {
  const {
    id,
    label,
    error,
    tooltip,
    externalLink,
    onChange,
    value,
    name,
    useAlt,
    isRefreshing,
    onRefresh,
    className,
    transparent,
    clear,
    readOnly,
    onBlur,
    onFocus,
  } = props;
  let { options, defaultOption } = props;

  const invalid = !!(error && error.length);
  const infoWithError = !invalid ? 'link-without-error' : '';
  const useAltInput = useAlt ? 'form-control-alternative' : '';
  const showRefreshBtn = !!(isRefreshing === true || isRefreshing === false);
  const inputWithoutInfo = !tooltip ? 'brtb' : '';
  const setBorderInvalid = invalid ? 'multi-select-invalid' : '';
  const infoAvailable =
    tooltip && tooltip.length ? 'input-with-info-refersh' : '';

  if (defaultOption && defaultOption.label) {
    let array: options[] = [];
    options = array.concat(defaultOption).concat(options);
  }

  if (clear) {
    options = [{ label: 'None', value: '' }].concat(options);
  }

  const transparentClass = transparent ? 'bg-transparent' : '';
  const returnWithtarget = (data: { label: string; value: string }) => {
    if (!(data && data.hasOwnProperty('value')))
      if (onChange) {
        return onChange(data);
      }

    const returnData = {
      target: {
        value: data.value,
        label: data.label,
        name: name,
      },
    };
    if (onChange) {
      return onChange(returnData);
    }
  };

  const valueFromValue = (opts: any, value: string) =>
    opts.find((o: any) => o.value === value) || '';

  return (
    <Row className={className} noGutters>
      <Col sm={12}>
        {label && (
          <Label className={'text-dark'} for={id}>
            {label}
          </Label>
        )}
      </Col>
      <Col sm={showRefreshBtn ? 11 : 12}>
        <Row noGutters>
          <Col sm={12}>
            <InputGroup className="multi-select-flex text-dark">
              <Select
                isDisabled={readOnly}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                id={`select-${id}`}
                inputId={id}
                // defaultValue={value}
                value={valueFromValue(options, value)}
                filterOption={createFilter({ ignoreAccents: false })}
                name={name}
                options={options}
                onChange={(e: any) => returnWithtarget(e)}
                className={`basic-multi-select ${setBorderInvalid} ${infoAvailable} ${transparentClass}`}
                classNamePrefix="select"
                invalid={invalid}
                onBlur={onBlur}
                onFocus={onFocus}
                isOptionDisabled={(option) => option.disabled === true}
              />
              {tooltip && <Tooltip info={tooltip} invalid={invalid} />}
            </InputGroup>
            {invalid && (
              <small data-test="react-select-error" className="text-red">
                {error}
              </small>
            )}
          </Col>
          <Col md={12}>
            {externalLink && (
              <ExternalLink
                links={externalLink}
                className={`right-0-9 ${infoWithError}`}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col sm="1" className="refresh-col">
        {showRefreshBtn && (
          <RefreshHandler
            isRefreshing={!!isRefreshing}
            onRefresh={(e: any) => {
              onRefresh && onRefresh(e);
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default InputSelect;
