// src/Login.js

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { withAuth } from '@okta/okta-react';
// import { page, track } from '../../../analytics';

interface LoginProps {
  auth?: any;
}

interface LoginState {
  authenticated?: any;
}

export default withAuth(
  class Login extends Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
      super(props);
      this.state = { authenticated: null };
      this.checkAuthentication = this.checkAuthentication.bind(this);
      this.checkAuthentication().catch(console.error);
    }

    async checkAuthentication() {
      const authenticated = await this.props.auth.isAuthenticated();
      if (authenticated !== this.state.authenticated) {
        this.setState({ authenticated });
      }
    }

    // componentDidMount() {
    //   page('login');
    // }

    componentDidUpdate() {
      this.checkAuthentication().catch(console.error);
    }

    render() {
      if (this.state.authenticated === null) return null;

      return this.state.authenticated ? (
        // <Redirect to={{ pathname: "/protected" }} /> :
        <Redirect to={{ pathname: '/dashboard' }} />
      ) : (
        <LoginForm />
      );
    }
  },
);
