const colors = {
  palette: {
    white: '#fff',
    yellow: '#FFF386',
    bgPrimary: '#5E72E4',
    bgSecondary: '#F5F6FA',
    bgThird: '#F0F0F0',
    bgFourth: '#0E325E',
    bgFifth: '#031022',
    bgSixth: '#F4F4F4',
    bgSeven: '#C1C1C1',
    // success: 'rgb(61, 255, 0)',
    success: '#00FF21',
    green: 'rgb(50, 150, 0)',
    error: '#F00',
    red: '#FF0E08',
    connectorBlue: '#0094ff',
    transparent: 'rgb(0,0,0,0)',
  },
  text: {
    primary: '#5e72e4',
    secondary: '#0A2140',
    tertiary: '#7AA7E3',
    quarternary: 'rgba(255,255,255,0.3)',
    quinary: 'rgba(255,255,255,0.7)',
    senary: 'rgba(134, 176, 232, 0.7)',
    linkButton: '#00a3ff',
    secPrimary: '#3b4ade',
  },
  button: {
    primary: {
      color: '#fff',
      background: '#5e72e4',
    },

    secondary: {
      color: '#5e72e4',
      border: '1px solid #5E72E4',
      background: 'transparent',
    },
  },
  input: {
    placeholder: 'rgba(255,255,255, 0.4)',
  },
  link: {
    inactive: '#87BBFF',
    active: '#fff',
    alt: 'cyan',
  },
  border: {
    normal: '#CAD1D7',
  },
};

export default colors;
