import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  UncontrolledDropdown,
} from 'reactstrap';
import './forminputs.style.css';
import InputCommonProps from './index.component';
import classNames from 'classnames';

interface CustomDropDownProps extends InputCommonProps {
  value: any;
  options: {
    label: string | JSX.Element;
    value: any;
    disabled?: boolean;
  }[];
  title?: string | JSX.Element;
  icon?: string;
  className?: any;
  right?: boolean;
  isCaretIcon?: boolean;
  isAlternateText?: any;
  customDropDownFooter?: any;
  disabled?: boolean;
}

function CustomDropDown(props: CustomDropDownProps) {
  const {
    id,
    name,
    title,
    options,
    onChange,
    value,
    icon,
    className,
    right,
    isAlternateText,
    isCaretIcon,
    customDropDownFooter,
    disabled,
  } = props;

  const [isOpen, setOpen] = useState(false);

  const onOptionClick = (value: any) => {
    setOpen(!isOpen);
    onChange && onChange(value);
  };

  return (
    <UncontrolledDropdown
      className={className}
      isOpen={isOpen}
      id={id}
      name={name}
      value={value}
      toggle={() => setOpen(!isOpen)}
      direction={'down'}
      disabled={disabled}
    >
      <DropdownToggle
        tag="a"
        className={classNames({
          'text-info': !disabled,
          'text-muted': disabled,
          'cursor-pointer test-options-button d-flex align-items-center text-decoration-none text-nowrap': true,
        })}
        caret={true}
        aria-expanded={isOpen}
      >
        {icon && <i className={icon} aria-hidden="true" />}
        {title && title}
        {isCaretIcon && (
          <i className="fa fa-caret-down pl-2" aria-hidden="true" />
        )}
      </DropdownToggle>
      <DropdownMenu
        className="custom-dropdown-menu test-options-menu"
        right={right}
      >
        {options &&
          options.map((option, index) => (
            <DropdownItem
              data-test={`${name}-item-${index}`}
              className="cursor-pointer d-flex align-items-center test-options-item"
              key={index}
              active={option.value === value}
              onClick={() => onOptionClick(option.value)}
              disabled={option.disabled || false}
            >
              {option.label}
            </DropdownItem>
          ))}
        {!options.length && isAlternateText && isAlternateText}
        {customDropDownFooter && (
          <>
            <DropdownItem className="test-divider" divider />
            <DropdownItem disabled>{customDropDownFooter}</DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default CustomDropDown;
