import * as React from 'react';
import MultiSelect from '../../../lib/formInputs/multi-select.component';
import { Col, Row } from 'reactstrap';
import InputSelect from '../../../lib/formInputs/select-option.component';
import { CATEGORIES, SUB_CATEGORIES } from '../../../config/Categories';

interface CategoriesProps {
  categories: Array<string>;
  subCategories: Array<string>;
  onSubCategoryChange: Function;
  onCategoryChange: Function;
}
interface CategoriesState {}
export default class Categories extends React.Component<
  CategoriesProps,
  CategoriesState
> {
  onCategoryChange(oldIndex, event) {
    // console.log('cate', event);
    let { value } = event.target;
    let { categories } = this.props;
    for (let i = 0; i < categories.length; i++) {
      if (i === oldIndex) {
        categories[i] = value;
        break;
      }
    }
    this.props.onCategoryChange(categories);
  }

  onSubCategoryChange(category, option) {
    // console.log('sub', { category, option });
    if (!category) return;
    option = option || [];
    let selectedSub = option.map((x) => category + ':' + x.value);
    let existingSub = this.props.subCategories.filter(
      (x) => !x.startsWith(category + ':'),
    );
    let res = existingSub.concat(selectedSub);
    this.props.onSubCategoryChange(res);
  }

  addNewCategory = () => {
    this.props.onCategoryChange(this.props.categories.concat(CATEGORIES[0]));
  };

  removeCategory(index, category) {
    if (this.props.categories.length === 1) return;
    this.props.onCategoryChange(
      this.props.categories.filter((x, i) => i !== index),
    );
    this.props.onSubCategoryChange(
      this.props.subCategories.filter((x) => !x.startsWith(category + ':')),
    );
  }

  render() {
    let { categories, subCategories } = this.props;
    // console.log({ categories, subCategories });
    return (
      <Row>
        <Col>
          <div className="label mb-2">
            Categories
            <i
              title={'Add Category'}
              className={'fa fa-plus ml-2 text-success'}
              onClick={this.addNewCategory}
            />
          </div>
          {categories.length === 0 && (
            <Row className={'text-center pt-2'}>
              <Col onClick={this.addNewCategory}>Add Category</Col>
            </Row>
          )}
          {categories.map((x, i) => {
            let subCategory = subCategories
              .filter((s) => s.startsWith(x + ':'))
              .map((s) => s.substring(s.indexOf(':') + 1))
              .map((s) => ({ label: s, value: s }));
            // console.log({ subCategory });
            return (
              <Row key={i} className={'border ml-2'}>
                <Col md={12}>
                  <Row>
                    <Col md={12}>
                      <i
                        className={
                          'float-right cursor-pointer fa fa-trash text-danger'
                        }
                        onClick={this.removeCategory.bind(this, i, x)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <InputSelect
                        label={'Category'}
                        value={x}
                        options={CATEGORIES.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        name={x + i}
                        onChange={this.onCategoryChange.bind(this, i)}
                      />
                    </Col>
                    <Col md={6}>
                      <MultiSelect
                        name={'subCategory' + i}
                        label={'Sub Categories'}
                        options={SUB_CATEGORIES.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        value={subCategory.map((x) => x.value)}
                        onChange={this.onSubCategoryChange.bind(this, x)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }
}
