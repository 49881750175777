import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';

import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  NavLink,
} from 'reactstrap';
import { ButtonText } from '../onboarding/common/buttonText';
import { AppState } from '../../lib/store';
import {
  fetchSubscriptionInfo,
  openUserPortal,
} from '../../lib/store/billing/action';
import { SubscriptionInfo, TrialStatus } from '../../lib/store/billing/types';
import { Link } from 'react-router-dom';
import {
  withRegionAndCredentials,
  RegionChildProps,
} from '../common/withRegionAndCredentials';
import { PlanInfo } from '../common/Planinfo';
import { UserManagement } from './UserManagement';

interface StateProps {
  subscription: SubscriptionInfo;
  isFetching: boolean;
  portalUrl: string;
}

interface DispatchProps {
  fetchSubscription: () => any;
  openUserPortal: () => any;
}

interface OwnProps {
  auth: {
    getAccessToken: () => string;
    getUser: () => any;
    logout: (s: string) => void;
  };
}

type IProps = RegionChildProps & OwnProps & DispatchProps & StateProps;

interface IState {
  user: {
    email: string;
  };
}

/** to use state vars in test case exported SettingPage without connect */
export class SettingPage extends Component<IProps, IState> {
  async componentDidMount() {
    this.props.fetchSubscription();
    const user = await this.props.auth.getUser();
    this.setState({ user: user });
  }

  openBillingPortal = () => {
    this.props.openUserPortal();
    window.open(this.props.portalUrl, '_blank');
    return;
  };

  render() {
    const { subscription, isFetching } = this.props;
    let loading = isFetching || !(subscription && subscription.planId);

    let planAmount: string = '$0';
    if (subscription.planAmount) {
      planAmount = '$' + subscription.planAmount / 100;
    }
    return (
      <>
        <Row className={'pr-5 test-billing-header dashboard-content'}>
          <Col md={12}>
            <h3>Subscription</h3>
          </Col>
        </Row>
        <Row className={'test-current-plan align-items-stretch'}>
          <Col md={3}>
            <Card className="border-0 shadow-sm">
              <CardBody>
                <CardTitle>Email</CardTitle>
                <CardTitle
                  className="font-weight-bold test-settings-email text-wrap"
                  data-test="user-email"
                >
                  {loading ? (
                    <i
                      data-test="workflow-loader"
                      className={'fa fa-2x fa-spin fa-spinner'}
                    />
                  ) : (
                    <>
                      {this.state && this.state.user && this.state.user.email}
                    </>
                  )}
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="border-0 shadow-sm">
              <CardBody>
                <CardTitle>Current Plan</CardTitle>
                <CardTitle data-test="current-plan-id">
                  {!loading ? (
                    subscription.planId ? (
                      <>
                        <div className="text-capitalize font-weight-bold">
                          {subscription.planId}
                        </div>
                        {subscription.status === TrialStatus.Trial ? (
                          <span className="highlight-trial">
                            Free Trial End in :{' '}
                            <PlanInfo subscription={this.props.subscription} />
                            days
                          </span>
                        ) : null}
                      </>
                    ) : (
                      'No Plan Selected'
                    )
                  ) : (
                    <i
                      data-test="workflow-loader"
                      className={'fa fa-2x fa-spin fa-spinner'}
                    />
                  )}
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="border-0 shadow-sm">
              <CardBody>
                <CardTitle>Amount</CardTitle>
                <CardTitle
                  data-test="current-plan-amount"
                  className="font-weight-bold"
                >
                  {!loading ? (
                    planAmount
                  ) : (
                    <i
                      data-test="workflow-loader"
                      className={'fa fa-2x fa-spin fa-spinner'}
                    />
                  )}
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3 test-subscription-buttons">
          <Col md={2}>
            <Button
              onClick={this.openBillingPortal}
              className="test-upgrade-plan"
              color={'primary'}
              block={true}
            >
              Upgrade Plan
            </Button>
          </Col>
          <Col md={2} className={'ml-2'}>
            <a href={'https://totalcloud.io/pricing.html'} target={'_blank'}>
              <Button
                className="test-pricing-button"
                outline
                color={'primary'}
                block
              >
                Go to Pricing
              </Button>
            </a>
          </Col>
        </Row>
        <Row className={'pr-5 mt-4 test-billing-header dashboard-content'}>
          <Col md={12}>
            <h3>Settings</h3>
          </Col>
        </Row>
        <Row className="test-settings-logout">
          <Col md="2" className="">
            <Link to={'/logout'}>
              <Button
                className="test-signout-button"
                color={'primary'}
                outline
                block
              >
                Sign Out
              </Button>
            </Link>
          </Col>
          <Col md={2} className={'ml-2'}>
            <Link to={'/settings/delete-account'}>
              <Button
                className="test-delete-account-button"
                color={'danger'}
                block
              >
                <span>Delete my account</span>
              </Button>
            </Link>
          </Col>
        </Row>
        <Row className={'pr-5 mt-4 test-billing-header dashboard-content'}>
          <Col md={12}>
            <h3>Organisation</h3>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <UserManagement
              user={this.state && this.state.user && this.state.user.email}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

function mapStateToProps(state: AppState): StateProps {
  return {
    isFetching: state.subscription.fetching,
    subscription: state.subscription.subscription,
    portalUrl: state.subscription.portalUrl,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchSubscription: () => fetchSubscriptionInfo({ ...ownProps, dispatch }),
    openUserPortal: () => openUserPortal({ dispatch }),
  };
}

const WithRegion = withRegionAndCredentials(SettingPage);

/** For test case exported without withAuth */
export const SettingIntermediate = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(WithRegion);

export const SettingIndex = withAuth(SettingIntermediate);
