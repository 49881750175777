type TcRoute = {
  path: string;
  label: string;
  icon: string;
  external?: boolean;
};

export type TcRoutes = {
  name?: string;
  paths: TcRoute[];
};

export const mainRoutes: TcRoutes[] = [
  {
    paths: [
      {
        path: '/dashboard',
        label: 'Dashboard',
        icon: 'master-tc-dashboard',
      },
    ],
  },
  {
    name: 'Solutions',
    paths: [
      {
        path: '/schedules',
        label: 'Schedules',
        icon: 'master-tc-Scheduling',
      },
      {
        path: '/insights',
        label: 'Insights',
        icon: 'master-tc-insights',
      },
      {
        path: '/inventory',
        label: 'Inventory',
        icon: 'master-tc-workflows',
      },
      {
        path: '/utilisation',
        label: 'Utilisation',
        icon: 'master-tc-Utilization',
      },
      {
        path: '/auto-remediation',
        label: 'Auto Remediation',
        icon: 'master-tc-auto-rem',
      },
      {
        path: '/events',
        label: 'Events',
        icon: 'fa-calendar fa',
      },
    ],
  },
  {
    name: 'Build your own',
    paths: [
      {
        path: '/workflows',
        label: 'Workflows',
        icon: 'master-tc-workflows',
      },
      {
        path: '/templates',
        label: 'Templates',
        icon: 'master-tc-templates',
      },
      {
        path: '/editor',
        label: 'Editor',
        icon: 'master-tc-editor',
      },
    ],
  },
  {
    name: 'Profile',
    paths: [
      {
        path: '/accounts',
        label: 'Accounts',
        icon: 'master-tc-accounts',
      },
      {
        path: '/request-feature',
        label: 'Request Feature',
        external: false,
        icon: 'master-tc-request-feature',
      },
      {
        path: '/support',
        label: 'Support',
        icon: 'navIcon-support',
        external: false,
      },
      {
        path: '/settings',
        label: 'Settings',
        external: false,
        icon: 'fa fa-gear',
      },
    ],
  },
];

export const templateManageUiRoutes: TcRoutes[] = [
  {
    name: 'Summary',
    paths: [
      {
        path: '/wf-templates-ui/summary',
        label: 'Summary',
        icon: 'master-tc-dashboard',
      },
    ],
  },
  {
    name: 'Workflows',
    paths: [
      {
        path: '/wf-templates-ui/convert-wf-template',
        label: 'All Workflows',
        icon: 'master-tc-workflows',
      },
      {
        path: '/wf-templates-ui/cf-stack-wfs',
        label: 'Stack Workflows',
        icon: 'master-tc-workflows',
      },
      {
        path: '/wf-templates-ui/template-tester-wfs',
        label: 'Tester Workflows',
        icon: 'master-tc-workflows',
      },
    ],
  },
  {
    name: 'Options',
    paths: [
      {
        path: '/wf-templates-ui/edit-template',
        label: 'Templates',
        icon: 'master-tc-templates',
      },
      {
        path: '/wf-templates-ui/test-template',
        label: 'Template Test',
        icon: 'master-tc-templates',
      },
      {
        path: '/wf-templates-ui/insights-fixes',
        label: 'Insights Fixes',
        icon: 'master-tc-insights',
      },
    ],
  },
  {
    name: 'Webflow',
    paths: [
      {
        path: '/wf-templates-ui/webflow',
        label: 'Missing Webflow',
        icon: 'master-tc-templates',
      },
    ],
  },
];

export const manageUiRoutes: TcRoutes[] = [
  {
    name: 'Workflows',
    paths: [
      {
        path: '/manage/workflows',
        label: 'All Manage Workflows',
        icon: 'master-tc-workflows',
      },
    ],
  },

  {
    name: 'Manage Board',
    paths: [
      {
        path: '/manage/boards/summary',
        label: 'Summary',
        icon: 'master-tc-dashboard',
      },
      {
        path: '/manage/boards/executions',
        label: 'Execution Board',
        icon: 'fa fa-file',
      },
      {
        path: '/manage/boards/workflows',
        label: 'Workflow Board',
        icon: 'master-tc-workflows',
      },
    ],
  },
  {
    name: 'Options',
    paths: [
      {
        path: '/manage/clone',
        label: 'Clone workflows',
        icon: 'master-tc-templates',
      },
    ],
  },
  {
    name: 'Dynamic Nodes',
    paths: [
      {
        path: '/manage/user-created-scripts',
        label: 'Manage Scripts',
        icon: 'master-tc-templates',
      },
      {
        path: '/manage/user-created-nodes',
        label: 'Manage Nodes',
        icon: 'master-tc-templates',
      },
    ],
  },
];
