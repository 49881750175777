import * as React from 'react';
import { WorkflowTemplateEditor } from '../components/templates-editor';
// import { page } from '../analytics';
import { AppContainer } from './style';
import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { store } from '../lib/store';
import { SidebarWrapper } from '../components/common/sideBarWrapper';
import { Provider } from 'react-redux';
import { templateManageUiRoutes } from '../config/sidePannel';

class WfTemplates extends React.Component<SecureRouteProps, {}> {
  render() {
    return (
      <Provider store={store}>
        <AppContainer fluid={true}>
          <SidebarWrapper routes={templateManageUiRoutes} {...this.props}>
            <WorkflowTemplateEditor {...this.props} />
          </SidebarWrapper>
        </AppContainer>
      </Provider>
    );
  }
}

export default withTcApi(WfTemplates);
