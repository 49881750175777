import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { CustomReactSelect } from '../../lib/formInputs/react-multi-select/custom-react-multi-select';
import {
  REGIONS,
  REGIONS_GROUPS,
} from '../../lib/editor/components/model/config/Regions';
import React from 'react';
import { Config } from '../../lib/store/config/types';
import { CONFIG_TYPES } from 'webapp-genric/dist';
import { getAccountConfig } from './withRegionAndCredentials';
import { Direction } from 'reactstrap/lib/Dropdown';
import GetCloudProviderFromConfig from 'webapp-genric/dist/cloudProviderFromConfig';
import CloudProvider from 'workflow-model/dist/types/cloudProvider';
import useRestrictions from '../../lib/user-restriction';

type OptionType = {
  label: string;
  options?: OptionType[];
  value?: string;
};

interface IProps {
  configs: Config[];
  selectedAccount: string | string[];
  selectedRegion: string | string[];
  selectedResourceGroup?: string | string[];
  onAccountChange: (value: any) => void;
  onRegionChange: (region: string | string[]) => void;
  onResourceGroupChange?: (azResource: string | string[]) => void;
  isMultiSelect?: boolean;
  allowSelectAll?: boolean;
  direction?: Direction;
  readOnly?: boolean;
  showResourceGroup?: boolean;
  availableResourceGroups?: string[];
  isSingleSelectCredentialGroup?: boolean;
  singleSelectCredentialGroup?: string[];
  restrictMaxSelection?: boolean;
  maxAccountSelection?: number;
  maxRegionSelection?: number;
  maxResourceGroupSelection?: number;
}

export const groupAccountConfigsOptions = (configs: Config[]) => {
  let accountConfigs = getAccountConfig(configs);
  if (accountConfigs.length === 0) return [];

  let awsOrgsMap = {};
  let awsAccounts = [];
  let azureAccounts = [];
  let vmwareAccounts = [];
  let k8Accounts = [];
  let tfcAccounts = [];
  let otherAccountGroupMap = {};

  const typeMap: { [s: string]: [CloudProvider, any[]] } = {
    [CONFIG_TYPES.AWS_PROXY]: [CloudProvider.AWS, awsAccounts],
    [CONFIG_TYPES.AWS_KEY]: [CloudProvider.AWS, awsAccounts],
    [CONFIG_TYPES.AWS_ROLE]: [CloudProvider.AWS, awsAccounts],
    [CONFIG_TYPES.AZURE]: [CloudProvider.AZURE, azureAccounts],
    [CONFIG_TYPES.VMWARE]: [CloudProvider.VMWARE, vmwareAccounts],
    [CONFIG_TYPES.Kubernetes]: [CloudProvider.Kubernetes, k8Accounts],
    [CONFIG_TYPES.TerraformCloud]: [CloudProvider.TerraformCloud, tfcAccounts],
  };

  for (let cfg of accountConfigs) {
    const { type, id, name, parentName } = cfg;
    if (parentName && type === CONFIG_TYPES.AWS_ORG_MEMBER) {
      awsOrgsMap[parentName] = (awsOrgsMap[parentName] || []).concat({
        label: name,
        value: id,
        type: CloudProvider.AWS,
      });
    } else if (parentName) {
      otherAccountGroupMap[parentName] = (
        otherAccountGroupMap[parentName] || []
      ).concat({
        label: cfg.name,
        value: cfg.id,
        type: typeMap[type][0],
      });
    } else if (typeMap[type]) {
      const [cloudProvider, configGroup] = typeMap[type];
      configGroup.push({
        label: cfg.name,
        value: cfg.id,
        type: cloudProvider,
      });
    }
  }

  return [
    {
      label: 'AWS Accounts',
      options: awsAccounts,
    },
    {
      label: 'AWS Organisation',
      options: Object.keys(awsOrgsMap).map((x) => {
        return { label: x, value: x, options: awsOrgsMap[x] };
      }),
    },
    {
      label: 'Azure Accounts',
      options: azureAccounts,
    },
    {
      label: 'Groups',

      options: Object.keys(otherAccountGroupMap).map((x) => {
        return { label: x, value: x, options: otherAccountGroupMap[x] };
      }),
    },
    {
      label: 'VMware Accounts',
      options: vmwareAccounts,
    },
    {
      label: 'Kubernetes Accounts',
      options: k8Accounts,
    },
    {
      label: 'Terraform Accounts',
      options: tfcAccounts,
    },
  ];
};

const getFooter = (configs: Config[]) => {
  return !configs || configs.length === 0 ? (
    <Button
      onClick={() => {
        window.location.href = '/accounts/aws/user';
      }}
      size={'sm'}
      block={true}
      color="primary"
      className="text-center"
    >
      Add New Account
    </Button>
  ) : null;
};
export const RegionAndCredsComponent = (props: IProps) => {
  const {
    selectedAccount,
    selectedRegion,
    onAccountChange,
    onRegionChange,
    onResourceGroupChange,
    selectedResourceGroup = [],
    isMultiSelect,
    allowSelectAll,
    direction,
    readOnly,
    singleSelectCredentialGroup,
    isSingleSelectCredentialGroup,
    showResourceGroup,
    restrictMaxSelection,
    maxAccountSelection,
    maxRegionSelection,
    maxResourceGroupSelection,
  } = props;

  const getRegionLabel = () => {
    if (!isMultiSelect)
      return selectedRegion
        ? REGIONS.find((x) => x.value === selectedRegion)?.label
        : 'Select Region';
    if (selectedRegion.length === REGIONS.length) return `All Regions`;
    return selectedRegion.length === 1 && selectedRegion[0] !== '*'
      ? REGIONS.find((x) => x.value === selectedRegion[0]).label
      : `${selectedRegion.length} Regions`;
  };

  const resourceGroupOptions = () => {
    return props.availableResourceGroups.map((config) => ({
      label: config,
      value: config,
    }));
  };

  const getAzResourceLabel = () => {
    let resourceGroupConfigs = resourceGroupOptions();
    let found;
    if (!isMultiSelect) {
      found =
        selectedResourceGroup &&
        resourceGroupConfigs.find((x) => x.value === selectedResourceGroup);
      return found ? found.label : 'Select Resource Group';
    }
    if (selectedResourceGroup.length === resourceGroupConfigs.length)
      return `All Groups`;
    return selectedResourceGroup.length === 1
      ? resourceGroupConfigs.find((x) => x.value === selectedResourceGroup[0])
          ?.label
      : `${selectedResourceGroup.length} Resource groups`;
  };

  const getAccountLabel = () => {
    let configs = getAccountConfig(props.configs);

    if (isSingleSelectCredentialGroup) {
      if (!configs || configs.length === 0) return '0 Account';
      if (configs.length === selectedAccount.length) return `All Accounts`;
      if (selectedAccount.length === 1) {
        let selectedConfigObj = configs.find(
          (x) => x.id === selectedAccount[0],
        );
        return selectedConfigObj ? selectedConfigObj.name : '0 Account';
      }
      return `${selectedAccount.length} Accounts`;
    }
    if (!isMultiSelect) {
      let selectedConfigObj = configs.find((x) => x.id === selectedAccount);
      return selectedConfigObj ? selectedConfigObj.name : 'Select Account';
    }

    if (!configs || configs.length === 0) return '0 Account';
    if (configs.length === selectedAccount.length) return `All Accounts`;
    if (selectedAccount.length === 1) {
      let selectedConfigObj = configs.find((x) => x.id === selectedAccount[0]);
      return selectedConfigObj ? selectedConfigObj.name : '0 Account';
    }
    return `${selectedAccount.length} Accounts`;
  };

  const isAccountSelected = (type: CloudProvider) => {
    const configs = props.configs;

    if (typeof selectedAccount === 'object') {
      return !!selectedAccount.find((x) => {
        let value = configs.find((config) => config.id === x);
        return value && GetCloudProviderFromConfig(value.type) == type;
      });
    } else {
      let value = configs.find((config) => config.id === selectedAccount);
      return value ? GetCloudProviderFromConfig(value.type) == type : false;
    }
  };

  const restrictAccount = useRestrictions('allowedAccounts');
  const restrictResourceGroup = useRestrictions('allowedResourceGroups');
  const restrictRegions = useRestrictions('allowedRegions');

  const maxAccount = restrictMaxSelection
    ? typeof maxAccountSelection !== 'undefined'
      ? maxAccountSelection
      : parseInt(restrictAccount.value)
    : undefined;
  const maxRegion = restrictMaxSelection
    ? typeof maxRegionSelection !== 'undefined'
      ? maxRegionSelection
      : parseInt(restrictRegions.value)
    : undefined;
  const maxResourceGroup = restrictMaxSelection
    ? typeof maxResourceGroupSelection !== 'undefined'
      ? maxResourceGroupSelection
      : parseInt(restrictResourceGroup.value)
    : undefined;

  return (
    <>
      <CustomReactSelect
        maxSelection={maxAccount}
        config={props.configs}
        readOnly={readOnly}
        direction={direction}
        isMulti={isMultiSelect || isSingleSelectCredentialGroup}
        isSingleSelectWithinGroup={isSingleSelectCredentialGroup}
        singleSelectGroup={singleSelectCredentialGroup}
        allowSelectAll={allowSelectAll && !restrictMaxSelection}
        data-test={'update-credential'}
        label={renderTitle(getAccountLabel())}
        name={'config-select'}
        options={groupAccountConfigsOptions(props.configs)}
        emptyText={
          <div className="text-center text-light no-account-text">
            You have not added any Cloud account yet. Please add an account to
            create a workflow.
          </div>
        }
        footer={getFooter(getAccountConfig(props.configs))}
        icon={'fa TC-icon-1Cloud-service fa-2x mr-2'}
        onChange={onAccountChange}
        value={selectedAccount}
      />

      <CustomReactSelect
        readOnly={readOnly}
        direction={direction}
        isMulti={isMultiSelect}
        maxSelection={maxRegion}
        allowSelectAll={allowSelectAll && !restrictMaxSelection}
        data-test={'region-dropdown'}
        label={getRegionLabel()}
        name={'regions'}
        options={REGIONS_GROUPS}
        onChange={onRegionChange}
        className="d-flex align-items-center justify-content-between"
        icon={'fa master-tc-region fa-2x mr-2'}
        value={selectedRegion}
        disabled={!isAccountSelected(CloudProvider.AWS)}
      />

      {showResourceGroup && (
        <CustomReactSelect
          readOnly={readOnly}
          direction={direction}
          isMulti={isMultiSelect}
          maxSelection={maxResourceGroup}
          allowSelectAll={allowSelectAll && !restrictMaxSelection}
          data-test={'azure-resource'}
          label={getAzResourceLabel()}
          name={'azureResources'}
          options={resourceGroupOptions()}
          onChange={onResourceGroupChange}
          className="d-flex align-items-center justify-content-between"
          icon={'fa master-tc-region fa-2x mr-2'}
          disabled={!isAccountSelected(CloudProvider.AZURE)}
          value={selectedResourceGroup}
        />
      )}
    </>
  );
};

const renderTitle = (title) => {
  return (
    <>
      <span
        id="account-title"
        data-test="account-title"
        className="text-truncate"
      >
        {title}
      </span>
      <UncontrolledTooltip target="account-title">{title}</UncontrolledTooltip>
    </>
  );
};
