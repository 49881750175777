export const Transformers = [
  {
    name: 'jira-description-parser',
    variables: [],
    description: 'Parses description if http triger is jira ticket ',
  },
  {
    name: 'custom',
    variables: [],
  },
];
