import { Transform } from '.';

export default class DotKeysHandler extends Transform {
  apply(block: any): any {
    if (!block) return block;
    let keysWithDot = Object.keys(block).filter((x) => x.indexOf('.') !== -1);
    let newKeysForDotKeys = keysWithDot.map((x) => x.replace(/\./g, '_'));
    for (let i = 0; i < newKeysForDotKeys.length; i++) {
      let k = newKeysForDotKeys[i];
      block[k] = block[keysWithDot[i]];
      delete block[keysWithDot[i]];
    }
    return block;
  }
}
