import React, { useEffect } from 'react';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  getGroupWorkflowDetails,
  runReportingWorkflow,
  runTriggerWorkflow,
  saveGroupWorkflow,
  saveReportingWorkflow,
  toggleWorkflowActive,
} from '../../../lib/store/workflows/action';
import {
  IWorkflow,
  NODE_TYPES,
  TriggerNodeData,
  Workflow,
} from 'workflow-model/dist';
import { connect } from 'react-redux';
import * as WorkflowType from '../../../lib/store/workflows/types';
import { viewType } from '../../workflows/common/workflowReportForm';
import { Formik, FormikActions, FormikProps } from 'formik';
import { TriggerWorkflowForm } from '../../workflows/common/WorkflowsTriggerForm';
import { TriggerNode } from 'workflow-model/dist/nodes';
import {
  TriggerSchema,
  triggerSchema,
} from '../../../lib/editor/components/ui/nodeUI/trigger/schema';
import { getTriggerWorkflowConfigByTabName } from 'webapp-genric/dist/genricWorkflowsConfig';
import _ from 'lodash';
import {
  convertCombinedToParsed,
  convertParsedToCombinedCon,
} from '../../workflows/common/cornTypeConverter';
import { superApiCall } from '../../../lib/store/super-table/action';
import { ApiOperation } from '../../../lib/store/super-table/types';
import { defaultTriggerNodeData } from '../../../lib/editor/components/ui/nodeUI/trigger/TriggerForm';

interface StateProps {
  isFetchingWorkflow: boolean;
  triggerWorkflow: WorkflowType.Workflow;
  workflowModel: Workflow;
  reportingAction: boolean;
}

interface DispatchProps {
  getWorkflow: (params: { group: string; workflow: string }) => void;
  getTriggerWorkflow: (options: any) => void;
  sendReportNow: (workflow: IWorkflow) => void;
  saveTriggerWorkflow: (params: { workflow: IWorkflow; group: string }) => void;
  saveReportingWorkflow: (workflow: IWorkflow) => void;
  toggleTriggerWorkflowState: (workflowId: WorkflowType.Workflow) => void;
  runTriggerWorkflow: (workflowId: string) => void;
}

interface OwnProps {
  category: string;
  subCategory: string;
  user: string;
  tabName: TAB_NAME;
  show: boolean;
  closeModal: () => void;
}

type IProps = OwnProps & DispatchProps & StateProps;

interface IState {
  view: viewType;
  workflowModel: Workflow;
}

class TriggerSettingV2 extends React.Component<IProps, IState> {
  state = {
    view: viewType.LATER,
    workflowModel: null,
  };

  fetchWorkflow = () => {
    let triggerConfig = getTriggerWorkflowConfigByTabName(this.props.tabName);

    if (
      this.props.triggerWorkflow &&
      !this.props.isFetchingWorkflow &&
      !this.props.triggerWorkflow.definition &&
      triggerConfig
    ) {
      this.props.getWorkflow({
        group: triggerConfig.group,
        workflow: this.props.triggerWorkflow.workflow,
      });
    }

    if (this.props.workflowModel && !this.state.workflowModel) {
      this.setState({ workflowModel: this.props.workflowModel });
    }
  };

  componentDidMount(): void {
    if (!this.props.triggerWorkflow && !this.props.isFetchingWorkflow) {
      this.props.getTriggerWorkflow({});
    }
    this.fetchWorkflow();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    this.fetchWorkflow();
  }

  toggleRadio = (event) => {
    this.setState({ view: event.target.value });
  };
  handleSubmit = (
    values: TriggerSchema,
    actions: FormikActions<TriggerSchema>,
  ) => {
    let usedValues = _.cloneDeep(values);
    let { view } = this.state;
    if (view === viewType.NOW) {
      this.props.runTriggerWorkflow(this.state.workflowModel.getId());
    } else {
      let triggerNode: TriggerNode = this.state.workflowModel.getNodesByType(
        NODE_TYPES.TRIGGER,
      )[0] as TriggerNode;
      //This will prevent updating  @param values as it is passed as a reference here.
      usedValues.schedule.active = true;
      triggerNode.setNodeData(
        triggerNode.serialize(
          convertCombinedToParsed(usedValues) as TriggerNodeData,
        ),
      );

      let triggerConfig = getTriggerWorkflowConfigByTabName(this.props.tabName);
      if (!triggerConfig) return;
      this.props.saveTriggerWorkflow({
        workflow: this.state.workflowModel,
        group: triggerConfig.group,
      });
    }
    actions.setSubmitting(false);
  };

  getDefaultValues = (): TriggerSchema => {
    if (this.state.workflowModel) {
      let triggerNode: TriggerNode = this.state.workflowModel.getNodesByType(
        NODE_TYPES.TRIGGER,
      )[0] as TriggerNode;
      let data = triggerNode.deserialize();
      return convertParsedToCombinedCon(data) as TriggerSchema;
    }

    const triggerNode = new TriggerNode();
    triggerNode.setNodeData(defaultTriggerNodeData);
    return convertParsedToCombinedCon(
      triggerNode.deserialize(),
    ) as TriggerSchema;
  };

  toggleActiveHandler = (value: boolean) => {
    this.props.toggleTriggerWorkflowState(this.props.triggerWorkflow);
  };

  render() {
    let initialValues: TriggerSchema = this.getDefaultValues();
    return (
      this.props.show && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={(formProps) => (
            <RenderTriggerForm
              formProps={formProps}
              parentProps={this.props}
              toggleActiveHandler={this.toggleActiveHandler}
              toggleRadio={this.toggleRadio}
              view={this.state.view}
            />
          )}
          validateOnChange={false}
          validateOnBlur={true}
          validationSchema={triggerSchema}
        />
      )
    );
  }
}

const RenderTriggerForm = (props: {
  formProps: FormikProps<TriggerSchema>;
  parentProps: IProps;
  view: viewType;
  toggleActiveHandler: (value: any) => void;
  toggleRadio: (event: any) => void;
}) => {
  const {
    formProps,
    parentProps,
    view,
    toggleActiveHandler,
    toggleRadio,
  } = props;
  const { triggerWorkflow, closeModal, isFetchingWorkflow } = parentProps;
  const { setFieldValue } = formProps;

  useEffect(() => {
    if (view === viewType.LATER) {
      setFieldValue('schedule.active', true);
    } else {
      setFieldValue('schedule.active', false);
    }
  }, [view]);

  if (!triggerWorkflow) return null;
  return (
    <TriggerWorkflowForm
      renderActiveToggle={true}
      toggleActiveValue={triggerWorkflow.properties.active}
      toggleActiveHandler={toggleActiveHandler}
      {...formProps}
      {...parentProps}
      onClose={closeModal}
      toggleRadio={toggleRadio}
      view={view}
      isFetching={isFetchingWorkflow}
    />
  );
};

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const { category, subCategory } = ownProps;
  let workflowModel = null;
  let triggerConfig = getTriggerWorkflowConfigByTabName(ownProps.tabName);
  let triggerWorkflow: WorkflowType.Workflow, isFetchingWorkflow;
  let triggerData =
    state.superTable[category] && state.superTable[category][subCategory];

  triggerWorkflow = triggerData?.data?.workflow;
  isFetchingWorkflow = triggerData?.loading;

  if (triggerWorkflow && triggerWorkflow.definition) {
    workflowModel = new Workflow({
      isModelDirty: true,
      ...triggerWorkflow,
      user: ownProps.user,
    });
  }
  return {
    isFetchingWorkflow,
    triggerWorkflow,
    workflowModel,
    reportingAction: state.workflows.reportingAction,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  const { category, subCategory } = ownProps;

  const getTriggerWorkflow = (options: any = {}) =>
    superApiCall({
      category,
      subCategory,
      operation: ApiOperation.GET,
      options,
      dispatch,
    });
  return {
    getWorkflow: ({ group, workflow }) =>
      getGroupWorkflowDetails({ dispatch, group, workflow }),
    getTriggerWorkflow,
    saveReportingWorkflow: (workflow: IWorkflow) =>
      saveReportingWorkflow({
        dispatch,
        workflow,
        reFetch: getTriggerWorkflow,
      }),
    sendReportNow: (workflow: IWorkflow) =>
      runReportingWorkflow({ dispatch, workflow }),
    runTriggerWorkflow: (workflow) =>
      runTriggerWorkflow({ dispatch, workflow }),
    saveTriggerWorkflow: ({ workflow, group }) =>
      saveGroupWorkflow({
        dispatch,
        workflow,
        group,
        skipCredsAndRegionValidation: true,
        reFetch: getTriggerWorkflow,
      }),

    toggleTriggerWorkflowState: (workflow) =>
      toggleWorkflowActive({ dispatch, workflow }),
  };
}

export const TriggerWorkflowOptionsV2 = connect<
  StateProps,
  DispatchProps,
  OwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(TriggerSettingV2);
