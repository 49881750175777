import * as React from 'react';
import { DashboardWrapper } from './styledEntities';

import { Row, Col } from 'reactstrap';
import { WorkflowIntercept } from './components/WorkflowIntercept';
import { ExecutionIntercept } from './components/ExecutionIntercept';
import { OverviewDuration } from '../../lib/store/overview/types';
import { OverviewIntercept } from './components/OverviewIntercept';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { fetchConfig } from '../../lib/store/config/action';
import { connect } from 'react-redux';
import { PageLoader } from '../common/loaders';
import { Welcome } from '../common/Welcome';

export const OVERVIEW_INTERVALS = [
  {
    value: '7d',
    label: '7d',
  },
  {
    value: '3d',
    label: '3d',
  },
  {
    value: '1d',
    label: '1d',
  },
  {
    value: '12hrs',
    label: '12hrs',
  },
  {
    value: '6hrs',
    label: '6hrs',
  },
];

type StateProps = {
  isFetching: boolean;
  notHasConfig: boolean;
  initialDataIsAbsent: boolean;
};

type DispatchProps = {
  fetchConfig: () => void;
};

type OwnProps = {};

type IProps = StateProps & DispatchProps & OwnProps;

interface State {
  timeperiod: string;
}

class DashboardIndex extends React.Component<IProps, State> {
  state = {
    timeperiod: '7d',
  };

  updateTimeperiod = (timeperiod: string) => {
    this.setState({ timeperiod });
  };

  componentDidMount(): void {
    if (this.props.initialDataIsAbsent) {
      this.props.fetchConfig();
    }
  }

  render() {
    const { timeperiod } = this.state;

    if (!this.props.isFetching && this.props.notHasConfig) {
      // show welcome page
      return <Welcome />;
    }

    if (this.props.initialDataIsAbsent) {
      return <PageLoader />;
    }

    return (
      <DashboardWrapper>
        {/*{isFetching ? <PageLoader /> : null}*/}
        <Row>
          <Col md={12} className={''}>
            <h5> Dashboard </h5>
          </Col>
        </Row>
        <Row>
          <Col md="7" className="border-right">
            <Row>
              <Col lg="12" id="ta_fp_wf_info" className="test-wf-summary">
                <OverviewIntercept
                  intervals={OVERVIEW_INTERVALS}
                  duration={timeperiod as OverviewDuration}
                  updateTimePeriod={this.updateTimeperiod}
                />
              </Col>
              <Col lg="12">
                <ExecutionIntercept duration={timeperiod as OverviewDuration} />
              </Col>
            </Row>
          </Col>
          <Col xs="5" sm="5" md="5" lg="5">
            {/* <WorkflowSection> */}
            <WorkflowIntercept />
            {/* </WorkflowSection> */}
          </Col>
        </Row>

        {/*{error ? <SnackbarComponent message={error} type="error" /> : null}*/}
      </DashboardWrapper>
    );
  }
}

function mapStateToProps(state: AppState): StateProps {
  return {
    isFetching: state.config.fetching,
    notHasConfig: state.config.data && state.config.data.length === 0,
    initialDataIsAbsent: state.config.data === null,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
): DispatchProps {
  return {
    fetchConfig: () => fetchConfig({ dispatch }),
  };
}

const Dashboard = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardIndex);

export default Dashboard;
