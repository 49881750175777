import * as React from 'react';
import { Workflow } from '../../lib/store/workflows/types';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { shouldAdoptTemplates } from '../../config/policyStatusConfig';
import { Alert } from 'reactstrap';
import { adoptTemplates } from '../../lib/store/templates/action';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import {
  getRegionsFromWorkflow,
  getResourceGroupsFromWorkflow,
  getUnadoptedTemplates,
} from '../workflows/common/utils';
import { runWorkflow } from '../../lib/store/executions/action';
import { AdoptionSelectorModal } from '../workflows/common/adoptionSelector';
import { Config, ConfigState } from '../../lib/store/config/types';
import { AdoptionSelectorSchema } from '../workflows/common/adoptionSelectorSchema';
import { Template } from '../../lib/store/templates/types';
import { fetchResourceGroup } from '../../lib/store/config/action';
import { AlreadyAdopted } from '../page-header/AdoptionSetting';

interface OwnProps {
  configs: Config[];
  tabName: TAB_NAME;
  workflows: Workflow[];
  validatedTemplates: string[];
  isFetching: boolean;
  account: string[];
  region: string[];
  shouldAdopt: boolean;
  showNotification: boolean;
  showRegionSelector?: boolean;
  hideRegionSelector?: () => void;
  triggerWorkflow?: Workflow;
  alreadyAdopted: AlreadyAdopted;
  fetchAdoptionData: (options: any) => void;
}

interface StateProps {
  isAdopting: boolean;
  resourceGroupData: ConfigState['resourceGroupData'];
}

interface DispatchProps {
  adoptTemplatesRequest: (params: {
    templateIds: string[];
    account: string[];
    region: string[];
    resourceGroups: string[];
  }) => void;
  fetchResourceGroups: (configIds: string[]) => void;
}

type IProps = OwnProps & StateProps & DispatchProps;

type IState = {
  showRegionSelector: boolean;
  regionsToAdopt: string[];
  alreadyAdoptedRegions: string[];
  alreadyAdoptedResourceGroups: string[];
};

class AutoAdoptionInner extends React.Component<IProps, IState> {
  state: IState = {
    showRegionSelector: this.props.showRegionSelector,
    regionsToAdopt: [],
    alreadyAdoptedRegions: getRegionsFromWorkflow(this.props.workflows),
    alreadyAdoptedResourceGroups: getResourceGroupsFromWorkflow(
      this.props.workflows,
    ),
  };

  // auto adoption if workflows are not 0 and template available which need to be adopted
  processAdoption = () => {
    if (!shouldAdoptTemplates(this.props.tabName)) return;
    if (!this.props.shouldAdopt) return;
    if (this.props.isAdopting) return;
    if (!this.props.account || this.props.account.length !== 1) return;
    if (this.props.workflows && this.props.workflows.length !== 0) {
      let unAdoptedTemplateIds = getUnadoptedTemplates({
        configs: this.props.configs,
        workflows: this.props.workflows,
        accounts: this.props.account,
        regions: this.state.alreadyAdoptedRegions,
        templates: [],
      });

      return (
        unAdoptedTemplateIds &&
        unAdoptedTemplateIds.length &&
        this.adopt(
          this.state.alreadyAdoptedRegions,
          [],
          this.props.account,
          this.state.alreadyAdoptedResourceGroups,
        )
      );
    }
  };

  showRegionSelector = () => {
    this.setState({ showRegionSelector: true });
  };

  hideRegionSelector = () => {
    this.setState({ showRegionSelector: false });
    this.props.hideRegionSelector && this.props.hideRegionSelector();
  };
  adopt = (
    regions: string[],
    templatesIds: string[],
    accounts: string[],
    resourceGroups: string[],
  ) => {
    this.hideRegionSelector();
    if (this.props.isAdopting) return;
    this.props.adoptTemplatesRequest({
      account: accounts,
      templateIds: templatesIds,
      region: regions,
      resourceGroups: resourceGroups,
    });
  };

  onSubmitAdoptionForm = (values: AdoptionSelectorSchema) => {
    const { validatedTemplates } = this.props;
    let { regions, accounts, resourceGroups } = values;
    return this.adopt(regions, validatedTemplates, accounts, resourceGroups);
  };

  componentDidMount(): void {
    this.processAdoption();
  }

  onRegionChange = (regionsToAdopt = []) => {
    // if (!values) values = [];
    this.setState({ regionsToAdopt });
  };

  renderRegionSelector = () => {
    return (
      <AdoptionSelectorModal
        alreadyAdopted={this.props.alreadyAdopted}
        configs={this.props.configs}
        tabName={this.props.tabName}
        selectedAccounts={this.props.account}
        selectedRegions={this.props.region}
        onSubmit={this.onSubmitAdoptionForm}
        toggle={this.hideRegionSelector}
        fetchResourceGroups={this.props.fetchResourceGroups}
        resourceGroupData={this.props.resourceGroupData}
      />
    );
  };

  render = () => {
    if (this.props.isAdopting) {
      return (
        <NotificationBar
          message={'Adopting templates... '}
          show={this.props.isAdopting}
        />
      );
    } else
      return (
        <>
          {this.props.showNotification && (
            <NotificationBar
              message={
                'No ' +
                this.props.tabName +
                ' available for this account and region.You want to adopt? '
              }
              show={
                (!this.props.workflows || !this.props.workflows.length) &&
                !this.props.isFetching
              }
              onConfirm={() => this.showRegionSelector()}
              onCancel={() => {}}
            />
          )}

          {(this.state.showRegionSelector || this.props.showRegionSelector) &&
            this.renderRegionSelector()}
        </>
      );
  };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isAdopting: state.templates.adopting,
    resourceGroupData: state.config.resourceGroupData,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    adoptTemplatesRequest: async (data) => {
      await adoptTemplates({
        dispatch,
        ...data,
        resourceGroup: data.resourceGroups,
      });
      if (ownProps.triggerWorkflow && ownProps.triggerWorkflow.workflow) {
        await runWorkflow({
          dispatch,
          workflow: ownProps.triggerWorkflow.workflow,
        });
      }
    },
    fetchResourceGroups: (configIds) =>
      configIds.forEach((id) => fetchResourceGroup(dispatch, id)),
  };
}

interface INProps {
  show: boolean;
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface INState {
  showNotificationBar: boolean;
}

class NotificationBar extends React.Component<INProps, INState> {
  state = {
    showNotificationBar: this.props.show,
  };

  componentDidUpdate(
    prevProps: Readonly<INProps>,
    prevState: Readonly<INState>,
    snapshot?: any,
  ): void {
    if (this.props !== prevProps)
      this.setState({ showNotificationBar: this.props.show });
  }

  render = () => {
    if (this.state.showNotificationBar)
      return (
        <div className={'notification-bar'}>
          <Alert color="primary">
            {this.props.message}
            {this.props.onConfirm && (
              <>
                <a
                  onClick={() => {
                    this.props.onConfirm();
                    this.setState({ showNotificationBar: false });
                  }}
                  className="alert-link ml-3"
                >
                  Yes{' '}
                </a>
                <a
                  onClick={() => this.setState({ showNotificationBar: false })}
                  className="alert-link ml-2"
                >
                  No{' '}
                </a>
              </>
            )}
          </Alert>
        </div>
      );
    return <div />;
  };
}

export const AutoAdoption = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AutoAdoptionInner);
