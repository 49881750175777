import * as React from 'react';
import { Button, Col, InputGroup, Label, Row } from 'reactstrap';
import AceEditor, { IEditorProps } from 'react-ace';
import 'brace/mode/text';
import 'brace/mode/javascript';
import 'brace/theme/textmate';
import InputCommonProps from './index.component';
import ace, { Annotation, Editor, IEditSession } from 'brace';
import 'brace/ext/language_tools';
import './forminputs.style.css';
import { copyToClipboard } from '../../util/commonUtils';
import 'brace/ext/searchbox';
if (ace) {
  const langTools = ace.acequire('ace/ext/language_tools');
}

interface CodeEditorProps extends InputCommonProps {
  style?: object;
  fontSize?: number;
  defaultValue?: string;
  showPrintMargin?: boolean;
  highlightActiveLine?: boolean;
  readOnly?: boolean;
  disableFirstLastLine?: boolean;
  autoCompletionList?: string[];
  gotToLineNumber?: number;
  gotToColNumber?: number;
  onInput?(event: any): void;

  onFocus?(event: any): void;

  onCopy?(text: string): void;

  onPaste?(text: string): void;

  onScroll?(): void;

  showCopyControl?: boolean;
}

export const filterListWithPrefix = (list: Array<string>, prefix = '') => {
  prefix = prefix.replace(/\[\d*\]\"/g, '').replace('"', '');
  if (!list || list.length === 0) return [];

  // filter list for prefix before .
  let filtered = list.filter((x: string) =>
    x
      .toLowerCase()
      .includes(prefix.toLowerCase().substring(0, prefix.lastIndexOf('.'))),
  );

  // filter for last typed value
  let keyAfterDot = prefix.substring(prefix.toLowerCase().lastIndexOf('.') + 1);
  filtered = filtered.filter((x: string) =>
    x.toLowerCase().includes(keyAfterDot.toLowerCase()),
  );
  let indexOfLastDot = prefix.lastIndexOf('.') + 1;
  return filtered.map((x) => x.replace(x.substring(0, indexOfLastDot), ''));
};
export const getWordCompleter = (autoCompletionList: string[]) => {
  return {
    getCompletions: function(
      editor: Editor,
      session: IEditSession,
      pos: any,
      prefix: string,
      callback: Function,
    ) {
      // pos = editor.selection.getCursor();
      // session = editor.session;

      let curLine = session.getDocument().getLine(pos.row);
      let curTokens = curLine.slice(0, pos.column).split(/\s+/);
      if (curTokens.length === 0) return;
      let lastToken = curTokens[curTokens.length - 1];
      let listToShow = filterListWithPrefix(autoCompletionList, lastToken);
      callback(
        null,
        listToShow!.map(function(word: string) {
          return {
            caption: word,
            value: word,
            meta: 'static',
          };
        }),
      );
    },
  };
};

const CodeEditor = (props: CodeEditorProps) => {
  const onValidate = (annotations: Annotation[]) => {
    let { value } = props;
    if (annotations) {
      let errors = annotations.filter((x) => x.type === 'error');
      value.error = errors.length !== 0;
      props && props.onChange && props.onChange(value);
    }
  };

  const handleChange = (data: any) => {
    let { value } = props;
    value.data = data;
    props && props.onChange && props.onChange(value);
  };

  const setConfig = (editor: IEditorProps) => {
    if (editor) {
      editor.getSession().foldAll(1);
      goToLine(editor);
    }
  };

  const goToLine = (editor) => {
    if (!props.gotToLineNumber || props.gotToLineNumber < 5) return;
    editor.resize(true);
    editor.scrollToLine(props.gotToLineNumber, true, true, function() {});
    editor.gotoLine(props.gotToLineNumber, props.gotToColNumber, true);
  };
  const setThis = (aceEditor: AceEditor) => {
    if (!aceEditor) return;
    // console.log({aceEditor});
    if (aceEditor) {
      // aceEditor && aceEditor.editor.getSession().setMode(customMode);
      goToLine(aceEditor.editor);
      let { disableFirstLastLine, autoCompletionList } = props;
      if (disableFirstLastLine) {
        aceEditor.editor.commands.on('exec', function(e: any) {
          let rowCol = aceEditor && aceEditor.editor.selection.getCursor();
          if (
            rowCol.row == 0 ||
            rowCol.row + 1 == aceEditor.editor.session.getLength()
          ) {
            e.preventDefault();
            e.stopPropagation();
          }
        });
      }
      if (
        autoCompletionList &&
        autoCompletionList.length > 0 &&
        (!aceEditor.editor.completers || aceEditor.editor.completers !== 0)
      ) {
        aceEditor.editor.commands.on('afterExec', function(e: any) {
          if (e.command.name == 'insertstring' && /^[\w.]$/.test(e.args)) {
            aceEditor.editor.execCommand('startAutocomplete');
          }
        });
        let staticWordCompleter = getWordCompleter(autoCompletionList);
        aceEditor.editor.completers = [staticWordCompleter];
      }
    }
  };

  const {
    id,
    name,
    label,
    value,
    style,
    onCopy,
    onPaste,
    onScroll,
    onBlur,
    onInput,
    error,
    fontSize,
    showPrintMargin,
    defaultValue,
    highlightActiveLine,
    readOnly,
    showCopyControl = true,
  } = props;
  const invalid = !!(error && error.length);
  return (
    <>
      <Row>
        <Col sm={12}>
          <InputGroup>
            <Row className="mb-2 no-gutters w-100">
              <Col md={8} className="">
                {label && (
                  <Label for={id} className="text-dark">
                    {label}
                  </Label>
                )}
              </Col>
              {showCopyControl && (
                <Col md={4} className={'text-right'}>
                  <Button
                    className="test-copy-button p-0"
                    size={'sm'}
                    color={'link'}
                    onClick={() => copyToClipboard(value.data)}
                  >
                    Copy to clipboard
                  </Button>
                </Col>
              )}
            </Row>

            <AceEditor
              ref={setThis}
              onLoad={setConfig}
              name={name}
              className="border"
              mode={'javascript'}
              theme="textmate"
              setOptions={{
                enableBasicAutocompletion: true,
                showLineNumbers: false,
                tabSize: 2,
              }}
              onCopy={onCopy}
              onPaste={onPaste}
              onScroll={onScroll}
              onBlur={onBlur}
              onInput={onInput}
              fontSize={fontSize}
              style={style}
              onValidate={onValidate}
              onChange={handleChange}
              editorProps={{ $blockScrolling: true }}
              value={value.data}
              defaultValue={defaultValue}
              showPrintMargin={showPrintMargin}
              highlightActiveLine={highlightActiveLine}
              readOnly={readOnly}
            />
            {invalid && <small style={{ color: '#fb6340' }}>{error}</small>}
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};

const defaultProps: CodeEditorProps = {
  name: 'codeEditor',
  fontSize: 14,
  style: { resize: 'contain', height: '400px', width: '100%' },
  showPrintMargin: false,
  highlightActiveLine: true,
  readOnly: false,
};

CodeEditor.defaultProps = defaultProps;

export default CodeEditor;
