import { TableItem } from '../cols';
import * as React from 'react';
import { Execution } from '../../../../lib/store/executions/types';
import { getPastExecutionsFormatter } from '../../../workflows/common/workflowTableHelper';
import { Link } from 'react-router-dom';

export const StackWfsCreatedStatusDisplay = (
  cell: Execution[],
  row: TableItem,
) => {
  if (row.isFetchingExecution) return getPastExecutionsFormatter(cell, row);
  if (!cell || !cell.length) return '';
  return (
    <div className={'d-flex'}>
      <Link to={`/workflow/${row.id}/details`} target={'_blank'}>
        {cell[0].timestamp}
      </Link>
      {getPastExecutionsFormatter(cell, row)}
    </div>
  );
};

export const StackWfsDestroyedStatusDisplay = (
  cell: Execution[],
  row: TableItem,
) => {
  if (row.isFetchingExecution) return getPastExecutionsFormatter(cell, row);
  if (!cell || !cell.length || !row.destroyStackWf) return '';
  return (
    <div className={'d-flex'}>
      <Link
        to={`/workflow/${row.destroyStackWf.workflow}/details`}
        target={'_blank'}
      >
        {cell[0].timestamp}
      </Link>
      {getPastExecutionsFormatter(cell, row)}
    </div>
  );
};
