import { store } from '../';
import {
  AdoptionAccepted,
  AdoptionCompleted,
  AdoptionRejected,
  AdoptionSkipped,
  AWSAccountAdded,
  AWSAccountSkipped,
  EndWithDashBoard,
  EndWithEditor,
  ExpertHelpGiven,
  ExpertHelpRejected,
  GoalsSelected,
  LandingSelected,
  OnBoardingAction,
  RequestAdoption,
  WantsExpertHelp,
  DefineSuccessSelected,
  PreviousBoardingState,
  IntendToGainSelected,
} from './types';

export const recordLandingSelected = function(landing) {
  store.dispatch<OnBoardingAction>({
    type: LandingSelected,
    payload: landing,
  });
};

export const onPressingBack = function() {
  store.dispatch<OnBoardingAction>({
    type: PreviousBoardingState,
  });
};

export const recordUserGoals = function(goal) {
  store.dispatch<OnBoardingAction>({
    type: GoalsSelected,
    payload: goal,
  });
};

export const recordUserSuccess = function() {
  store.dispatch<OnBoardingAction>({
    type: DefineSuccessSelected,
  });
};

export const requestExpertHelp = function() {
  store.dispatch<OnBoardingAction>({
    type: WantsExpertHelp,
  });
};

export const expertHelpGiven = function() {
  store.dispatch<OnBoardingAction>({
    type: ExpertHelpGiven,
  });
};

export const expertHelpRejected = function() {
  store.dispatch<OnBoardingAction>({
    type: ExpertHelpRejected,
  });
};

export const awsAccountSkipped = function() {
  store.dispatch<OnBoardingAction>({
    type: AWSAccountSkipped,
  });
};

export const awsAccountAdded = function() {
  store.dispatch<OnBoardingAction>({
    type: AWSAccountAdded,
  });
};

export const adoptionRejected = function() {
  store.dispatch<OnBoardingAction>({
    type: AdoptionRejected,
  });
};

export const adoptionCompleted = function() {
  store.dispatch<OnBoardingAction>({
    type: AdoptionCompleted,
  });
};

export const adoptionSkipped = function() {
  store.dispatch<OnBoardingAction>({
    type: AdoptionSkipped,
  });
};

export const endWithEditor = function() {
  store.dispatch<OnBoardingAction>({
    type: EndWithEditor,
  });
};

export const endWithDashBoard = function() {
  store.dispatch<OnBoardingAction>({
    type: EndWithDashBoard,
  });
};

export const recordHearFromSelected = function(landing) {
  store.dispatch<OnBoardingAction>({
    type: LandingSelected,
    payload: landing,
  });
};

export const recordAdoptionFinishSelected = function() {
  store.dispatch<OnBoardingAction>({
    type: IntendToGainSelected,
  });
};
