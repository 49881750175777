export const LookupAttributes_AttributeKey: {
  label: string;
  value: string;
}[] = [
  { label: 'EventId', value: 'EventId' },
  { label: 'EventName', value: 'EventName' },
  { label: 'ReadOnly', value: 'ReadOnly' },
  { label: 'Username', value: 'Username' },
  { label: 'ResourceType', value: 'ResourceType' },
  { label: 'ResourceName', value: 'ResourceName' },
  { label: 'EventSource', value: 'EventSource' },
  { label: 'AccessKeyId', value: 'AccessKeyId' },
];
