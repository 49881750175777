import { IWorkflowOperation } from '../components/ops/components/IWorkflowOperation';
import { WorkflowValidator } from '../components/ops/components/validation/WorkflowValidator';
import { INode, IResolver, IWorkflow, NODE_TYPES } from 'workflow-model/dist';
import React from 'react';
import { OverlayOperation } from './OverlayOperation';
import { UserDefinedNode } from 'workflow-model/dist/nodes';

export enum PolicyStatus {
  NOACCOUNT = 'NOACCOUNT',
  WARNING = 'WARNING',
  INVALID = 'INVALID',
  VALID = 'VALID',
}

export interface IPolicyDoc {
  recommendedPolicy: any;
  minPolicy: any;
  missingPolicy?: any;
}

type IPolicyChildProps = {
  checkPolicy: () => void;
  isFetchingPolicy: boolean;
  policyDocument: IPolicyDoc | null;
  policyStatus: PolicyStatus;
};

type IProps = {
  workflowModel: IWorkflow;
  render: (props: IPolicyChildProps) => React.ComponentType | JSX.Element;
  dataResolver: IResolver;
  operation: OverlayOperation;
  selected: INode | null;
};

type IState = {
  isFetchingPolicy: boolean;
  policyDocument: IPolicyDoc | null;
  policyStatus: PolicyStatus;
};

export class PolicyValidator extends React.Component<IProps, IState> {
  state: IState = {
    isFetchingPolicy: false,
    policyDocument: null,
    policyStatus: PolicyStatus.INVALID,
  };

  checkPolicy = () => {
    let { workflowModel } = this.props;
    if (!workflowModel.getCredentials()) {
      this.setState({ policyStatus: PolicyStatus.NOACCOUNT });
      return;
    }
    const workflowValidator: IWorkflowOperation = new WorkflowValidator({
      resolver: this.props.dataResolver,
    });

    this.setState({ isFetchingPolicy: true });
    try {
      workflowValidator.execute(workflowModel, ({ error, value }) => {
        if (error) this.onErrorPolicy();
        else this.onSuccessPolicy(value);
        this.setState({ isFetchingPolicy: false });
      });
    } catch (error) {
      this.setState({ isFetchingPolicy: false });
      this.onErrorPolicy();
    }
  };

  shouldValidate(node: INode) {
    let nodeType = node.getNodeType();
    if (
      nodeType === NODE_TYPES.RESOURCE ||
      nodeType === NODE_TYPES.ACTION ||
      nodeType === NODE_TYPES.MONITORING ||
      (node.getNodeType() === 'user-defined-node' &&
        Boolean((node as UserDefinedNode).getNodeDefinition().templateLocation))
    ) {
      return node.validate();
    }
    return false;
  }

  componentDidMount(): void {
    this.checkPolicy();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (
      this.props.operation === OverlayOperation.none &&
      prevProps.operation === OverlayOperation.edit &&
      prevProps.selected &&
      this.shouldValidate(prevProps.selected)
    ) {
      this.checkPolicy();
    }

    if (this.props.workflowModel !== prevProps.workflowModel) {
      this.checkPolicy();
    }
  }

  onSuccessPolicy = (data: {
    valid: any;
    isAuthError: any;
    recommended: any;
    minimum: any;
    // minIamToVerify: any;
  }) => {
    if (!data) return;
    if (data.valid) {
      this.setState({
        policyStatus: PolicyStatus.VALID,
        policyDocument: {
          recommendedPolicy: data.recommended,
          minPolicy: data.minimum,
        },
      });
    } else if (data.isAuthError) {
      //display recommended policies and minimum policy
      this.setState({
        policyStatus: PolicyStatus.INVALID,
        policyDocument: {
          recommendedPolicy: data.recommended,
          minPolicy: data.minimum,
        },
      });
    } else {
      //display minimum policies always
      this.setState({
        policyStatus: PolicyStatus.INVALID,
        policyDocument: {
          recommendedPolicy: data.recommended,
          minPolicy: data.minimum,
        },
      });
    }
  };

  onErrorPolicy() {
    this.setState({ policyStatus: PolicyStatus.WARNING });
  }

  render() {
    return this.props.render({
      checkPolicy: this.checkPolicy,
      isFetchingPolicy: this.state.isFetchingPolicy,
      policyDocument: this.state.policyDocument,
      policyStatus: this.state.policyStatus,
    });
  }
}
