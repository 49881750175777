import { Workflow } from '../../../../lib/store/workflows/types';
import { Template } from '../../../../lib/store/templates/types';
import {
  Execution,
  ExecutionsMapType,
  WorkflowExecutionOutput,
} from '../../../../lib/store/executions/types';
import { REGIONS } from '../../../../lib/editor/components/model/config/Regions';
import { getRegionsFromWorkflow } from '../utils';
import {
  getAccountFromWorkflow,
  addSimilarKeyValueInArrayOfObject,
} from '../../../../util/commonUtils';
import { replaceConfigIds } from '../summaryFormatter/insightSummary';
import { Config } from '../../../../lib/store/config/types';
import { TAB_NAME } from 'webapp-genric/dist/constants';

interface OwnProps {
  workflows: Workflow[];
  templates?: Template[];
  executionsMap: ExecutionsMapType;
  isFetchingWorkflow: boolean;
  tabName?: TAB_NAME;
  onAdoptRequest: (templateId: string, option: string) => void;
  onChangeOption: Function;
  toggleActive: Function;
  config?: Config[];
  updateConfigAndRegion?: (key: string, value: string[]) => void;
  setResourceView?: Function;
}

export type UtilisationSummaryType = {
  properties: any;
  name: string;
  id: string;
  totalResources: number;
  inRangeUtilisation: number;
  lowUtilisation: number;
  highUtilisation: number;
  resource: string;
  lowUtilizationResource?: { name: string; value: number }[];
  highUtilizationResource?: { name: string; value: number }[];
  inRangeUtilizationResource?: { name: string; value: number }[];
  tabName: TAB_NAME;
  updateConfigAndRegion?: (key: string, value: string[]) => void;
};

export function getUtilisationReport(
  utilisation,
): {
  metricName: string;
  highUtilisation: number;
  lowUtilisation: number;
  overAllUtilisation: number;
}[] {
  let metrics = Object.keys(utilisation);

  // no metric data avaialble
  if (metrics.length === 0)
    return [
      {
        metricName: '',
        overAllUtilisation: 0,
        highUtilisation: 0,
        lowUtilisation: 0,
      },
    ];

  return metrics.map((m) => {
    return {
      metricName: m,
      ...utilisation[m],
    };
  });
}

function extractUtilisationFromExecution(props: {
  isFetchingWorkflow: boolean;
  onChangeOption: Function;
  toggleActive: Function;
  executionsMap: ExecutionsMapType;
  workflow: Workflow;
  tabName: string;
  setResourceView: Function;
}) {
  let result = [];
  let { executionsMap, workflow, isFetchingWorkflow, setResourceView } = props;

  let executionUrl = `/workflow/${workflow.workflow}/details`;

  let exe = executionsMap[workflow.workflow];
  let output: WorkflowExecutionOutput = exe ? exe.output : null;
  let region = REGIONS.find(
    (r) => r.value === getRegionsFromWorkflow([workflow])[0] || '',
  );
  let regionName = region ? region.label : '';
  if (output && output.utilisationReport) {
    let url = output.url;
    let {
      totalResources,
      utilisation,
      metricUtilisationMap,
      Unit,
      resource: resourceName,
    } = output.utilisationReport;
    if (totalResources === 0) {
      setResourceView = null;
    }
    let report = getUtilisationReport(utilisation);

    let re = report.map((r) => {
      let data = {
        ...workflow,
        regionName,
        tabName: props.tabName,
        resource: workflow.name,
        resourceName,
        Unit,
        url,
        executionUrl,
        exe,
        metricUtilisationMap,
        totalResources,
        setResourceView,
        ...r,
        isFetching: isFetchingWorkflow,
      };
      data['toggleActive'] = props.toggleActive.bind(this, data);
      data['onChangeOption'] = props.onChangeOption.bind(this, data);
      return data;
    });
    result = result.concat(re);
  } else {
    let data = {
      ...workflow,
      regionName,
      tabName: props.tabName,
      exe,
      executionUrl,
      isFetching: isFetchingWorkflow,
      isProcessing: true,
      resource: workflow.name,
    };
    data['toggleActive'] = props.toggleActive.bind(this, data);
    data['onChangeOption'] = props.onChangeOption.bind(this, data);
    return [data];
  }
  return result;
}

export const FormatUtilisationSummaryData = (
  utilisationSummary,
  selectedAccount?,
) => {
  let lowUtilisation = 0;
  let highUtilisation = 0;
  let inRangeUtilisation = 0;
  let label = '';
  if (!selectedAccount) {
    label = 'All Account';
    utilisationSummary.forEach((wf) => {
      lowUtilisation += wf.lowUtilisation;
      highUtilisation += wf.highUtilisation;
      inRangeUtilisation += wf.inRangeUtilisation;
    });
  } else {
    let account = utilisationSummary.find(
      (utl) => utl.id === selectedAccount[0],
    );
    if (account) {
      label = account.name;
      lowUtilisation = account.lowUtilisation;
      highUtilisation = account.highUtilisation;
      inRangeUtilisation = account.inRangeUtilisation;
    }
  }
  if (lowUtilisation + highUtilisation + inRangeUtilisation === 0) {
    return null;
  }
  return {
    label,
    value: {
      lowUtilisation,
      highUtilisation,
      inRangeUtilisation,
    },
  };
};

export const UtilisationSummaryDataFormatter = (props: OwnProps) => {
  let { tabName, executionsMap, workflows, config } = props;
  let summaryData = Object.keys(executionsMap).map((wf) => {
    let {
      lowUtilisation,
      highUtilisation,
      totalResources,
      inRangeUtilisation,
      resource,
    } = getUtilisationSummary(executionsMap[wf].output);
    const account = getAccountFromWorkflow(workflows, wf);
    return {
      name: account,
      id: account,
      properties: {
        categories: [{ category: 'Account', subCategories: [account] }],
      },
      totalResources,
      inRangeUtilisation,
      lowUtilisation,
      highUtilisation,
      resource,
      tabName,
      updateConfigAndRegion: props.updateConfigAndRegion,
    };
  });
  let modifiedSummary = getAccountWiseAssociateUtilisationSummary(summaryData);
  modifiedSummary = modifiedSummary.filter((summary) => summary.name);
  modifiedSummary = replaceConfigIds(config, modifiedSummary);
  return modifiedSummary;
};

export const FormatUtilisationData = (props: OwnProps) => {
  let result: {
    name: string;
    isFetching?: boolean;
    resource: string;
    resourceName: string;
    totalResources: number | string;
    highUtilisation: number | string;
    lowUtilisation: number | string;
    overAllUtilisation: number | string;
    metricName: string;
    onChangeOption: Function;
    toggleActive: Function;
    setResourceView: Function | null;
  }[] = props.workflows
    .map((x) =>
      extractUtilisationFromExecution({
        isFetchingWorkflow: props.isFetchingWorkflow,
        executionsMap: props.executionsMap,
        workflow: x,
        onChangeOption: props.onChangeOption,
        toggleActive: props.toggleActive,
        setResourceView: props.setResourceView,
        tabName: props.tabName,
      }),
    )
    .reduce((A, e) => A.concat(e), []);

  let tempaltes = [];
  if (props.tabName && props.templates && props.templates.length) {
    tempaltes = props.templates.map((x) => {
      return {
        ...x,
        resource: x.name,
        tabName: props.tabName,
        isTemplateItem: true,
        onAdoptRequest: props.onAdoptRequest,
      };
    });
  }
  return result.concat(tempaltes);
};

export const getUtilisationSummary = (output) => {
  let lowUtilisation = 0;
  let highUtilisation = 0;
  let totalResources = 0;
  let inRangeUtilisation = 0;
  let resource = '';
  if (output && output.utilisationReport) {
    totalResources = output.utilisationReport.totalResources;
    resource = output.utilisationReport.resource;
  }
  if (
    output &&
    output.utilisationReport &&
    output.utilisationReport.utilisation
  ) {
    Object.keys(output.utilisationReport.utilisation).forEach((utl) => {
      lowUtilisation +=
        output.utilisationReport.utilisation[utl].lowUtilisation;
      highUtilisation +=
        output.utilisationReport.utilisation[utl].highUtilisation;
    });
  }
  if (totalResources > 0) {
    inRangeUtilisation = totalResources - lowUtilisation - highUtilisation;
  }
  return {
    lowUtilisation,
    highUtilisation,
    totalResources,
    inRangeUtilisation,
    resource,
  };
};

export const getAccountWiseAssociateUtilisationSummary = (
  newSummary: UtilisationSummaryType[],
): UtilisationSummaryType[] => {
  return Object.values(
    newSummary.reduce((a, c: UtilisationSummaryType) => {
      if (!a[c.id]) {
        a[c.id] = {
          name: c.name,
          id: c.id,
          properties: c.properties,
          tabName: c.tabName,
          totalResources: c.totalResources,
          inRangeUtilisation: c.inRangeUtilisation,
          lowUtilisation: c.lowUtilisation,
          highUtilisation: c.highUtilisation,
          updateConfigAndRegion: c.updateConfigAndRegion,
          lowUtilisationResource: [],
          highUtilisationResource: [],
          inRangeUtilisationResource: [],
        };
        if (c.resource) {
          a[c.id] = {
            ...a[c.id],
            lowUtilisationResource: [
              { name: c.resource, value: c.lowUtilisation },
            ],
            highUtilisationResource: [
              { name: c.resource, value: c.highUtilisation },
            ],
            inRangeUtilisationResource: [
              { name: c.resource, value: c.inRangeUtilisation },
            ],
          };
        }
      } else {
        a[c.id].totalResources += c.totalResources;
        a[c.id].inRangeUtilisation += c.inRangeUtilisation;
        a[c.id].lowUtilisation += c.lowUtilisation;
        a[c.id].highUtilisation += c.highUtilisation;
        if (c.resource) {
          a[c.id].lowUtilisationResource = addSimilarKeyValueInArrayOfObject([
            ...a[c.id].lowUtilisationResource,
            {
              name: c.resource,
              value: c.lowUtilisation,
            },
          ]);
          a[c.id].highUtilisationResource = addSimilarKeyValueInArrayOfObject([
            ...a[c.id].highUtilisationResource,
            {
              name: c.resource,
              value: c.highUtilisation,
            },
          ]);
          a[
            c.id
          ].inRangeUtilisationResource = addSimilarKeyValueInArrayOfObject([
            ...a[c.id].inRangeUtilisationResource,
            {
              name: c.resource,
              value: c.inRangeUtilisation,
            },
          ]);
        }
      }
      return a;
    }, {}),
  );
};
