const deleteReasons = [
  {
    label: 'Wasn’t too easy to use',
    value: 'Wasn’t too easy to use',
    key: 0,
  },
  {
    label: 'It didn’t address my use cases',
    value: 'It didn’t address my use cases',
    key: 1,
  },
  {
    label: 'I don’t have any cloud management needs',
    value: 'I don’t have any cloud management needs',
    key: 2,
  },
  {
    label: 'I decided to use another service/product',
    value: 'I decided to use another service/product',
    key: 3,
  },
  {
    label: 'I’d much rather script',
    value: 'I’d much rather script',
    key: 4,
  },
  {
    label: 'Other',
    value: '',
    key: 5,
  },
];

export default deleteReasons;
