import React from 'react';
import { Col, CustomInput, InputGroup, Label } from 'reactstrap';
import './forminputs.style.css';
import InputCommonProps from './index.component';

export enum ORIENTATION {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

interface RadioButtonsProps extends InputCommonProps {
  options: {
    label: string | React.ReactNode;
    value: any;
    disabled?: boolean;
  }[];
  defaultChecked?: any;
  orientation?: ORIENTATION;
}

function CustomRadioButtons(props: RadioButtonsProps) {
  const {
    name,
    label,
    id,
    options,
    defaultChecked,
    onChange,
    onBlur,
    value,
    orientation,
    error,
  } = props;
  const invalid = !!(error && error.length);
  const defaultCheckedOption = value ? value : defaultChecked || '';
  return (
    <InputGroup>
      {invalid && <small style={{ color: '#fb6340' }}>{error}</small>}
      {label && <Label for={id}>{label}</Label>}

      <Col md={12} className={`test-active-toggle radio-${orientation}`}>
        {options &&
          options.map((option, index) => (
            <CustomInput
              key={index}
              id={`${index + name}`}
              type="radio"
              name={name}
              value={option.value}
              disabled={option && option.disabled}
              onChange={(event) => {
                onChange(event);
              }}
              onBlur={() => onBlur && onBlur(option.value)}
              defaultChecked={defaultCheckedOption === option.value}
              checked={option.value === value}
              label={option.label}
              className="my-2 mr-4"
            />
          ))}
      </Col>
    </InputGroup>
  );
}

export default CustomRadioButtons;
