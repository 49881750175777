import { withAuth } from '@okta/okta-react';
import React, { Component } from 'react';

class OktaAuth extends Component<any> {
  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (!authenticated) {
      this.props.auth.login('/dashboard');
    }
  }

  async componentDidMount() {
    this.checkAuthentication().catch(console.error);
  }

  render() {
    return null;
  }
}

export default withAuth(OktaAuth);
