import * as React from 'react';

import { ListHeading } from '../common/list/ListHeading';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Template } from '../../lib/store/templates/types';
import { fetchTemplates } from '../../lib/store/templates/action';

import { extractCategories } from '../common/categoryUtils';
import { SectionLoader } from '../common/SectionLoader';
import {
  CategoryChildProps,
  CategoryParentProps,
  CategoryStateProps,
  withCategories,
} from '../workflows/common/withCategories';
import CustomTable from '../common/table';
import { getCols } from './common/templateTableHelper';
import { filterItemsByMultipleCategories } from 'webapp-genric/dist/filters';

interface StateProps extends CategoryStateProps {
  isFetching: boolean;
  templates: Template[];
}

interface DispatchProps {
  fetchTemplates: () => any;
}

interface OwnProps extends CategoryParentProps {
  adoptTemplates: (template: string, option) => void;
  renderAdoptButton?: (
    selected: any[],
    adoptTemplates: () => void,
  ) => JSX.Element; // for landing
  classNames?: string;
}

type IProps = CategoryChildProps & OwnProps & DispatchProps & StateProps;

interface IState {
  selected: string[];
  searchValue: string;
}

export class TemplatePageInner extends React.Component<IProps, IState> {
  state: IState = {
    selected: [],
    searchValue: '',
  };

  componentDidMount(): void {
    this.props.fetchTemplates();
  }

  getFormattedData = () => {
    let templates = this.filterTemplates();

    templates = templates.map((x) => {
      return {
        ...x,
        description: extractHTMLContent(x.description),
        options: {
          templateId: x.id,
          onAdoptRequest: this.props.adoptTemplates,
        },
        isTemplateItem: true,
      };
    });

    return templates;
  };

  render(): React.ReactNode {
    const isLoading = this.props.isFetching && !this.props.templates.length;
    if (isLoading) {
      return <SectionLoader />;
    }

    let templates = this.getFormattedData();

    return (
      <ListHeading
        count={`(${templates.length} templates)`}
        heading={'Available Templates'}
        topLevelFilter={null}
      >
        <CustomTable
          options={null}
          emptyText={'No Templates available'}
          data={templates}
          columns={getCols()}
        />
      </ListHeading>
    );
  }

  private filterTemplates() {
    //sss
    let templates = filterItemsByMultipleCategories(this.props.templates, [
      {
        category: this.props.selectedCategory,
        subCategories: [].concat(this.props.selectedSubCategory),
      },
    ]) as Template[];
    if (this.state.searchValue) {
      const search = this.state.searchValue.toLowerCase();
      templates = templates.filter(
        (e) =>
          e.name
            .toLowerCase()
            .search(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')) > -1,
      );
    }

    return templates;
  }
}

const CategoriesWrappedComponent = withCategories(TemplatePageInner);

function mapStateToProps(state: AppState): StateProps {
  const templates =
    state.templates.data &&
    state.templates.data.filter((t) => t.properties.active);
  return {
    isFetching: state.templates.fetching,
    templates: templates,
    categories: extractCategories(templates),
  };
}

export const extractHTMLContent = (s: string): string => {
  let span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchTemplates: () => fetchTemplates({ dispatch }),
  };
}

export const TemplatePage = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriesWrappedComponent);
