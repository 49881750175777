import styled from 'styled-components';
import { InputGroupAddon, Dropdown, Button } from 'reactstrap';

export const CustomInputGroupAddon = styled(InputGroupAddon)`
  border-left: 0.0625em solid #cad1d7;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  background-color: '#cad1d7';
  .input-group-text {
    background-color: #cad1d7;
  }
`;

export const CustomDropdown = styled(Dropdown)`
  border-top: 0.0625em solid #cad1d7;
  border-top-right-radius: 0;
  border-top-left-radius: 5px;
  border-bottom: 0.0625em solid #cad1d7;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 5px;
  background-color: #d6dae2;
  padding: 0 0.5em;

  .dropdown-toggle {
    box-shadow: none;
    background-color: transparent;
    border: 0;
    padding: 0;
    height: 101%;

    &:hover,
    &:focus {
      box-shadow: none;
      background-color: transparent;
    }

    &::after {
      border-top: 0.3em solid #808080;
      vertical-align: 0.1em;
    }

    img {
      width: 50%;
      height: 1.25em;
      width: 1.8em;
    }
  }

  .dropdown-menu.show {
    width: 100%;
    padding: 0;
    margin: 0;
    transform: translate3d(0, 2.8125em, 0px) !important;
  }

  .dropdown-menu {
    min-width: 3em;

    button {
      padding: 0.4em;
      text-align: center;

      img {
        text-align: center;
        height: 1.25em;
        width: 1.8em;
      }
    }
  }
`;

export const CustomBoardButton = styled(Button)`
  border: 1px solid #1d8cf8 !important;
  border-radius: 0 !important;
  box-shadow: 0px 0px #ffffff !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  // font-size: 14px !important;
  overflow: hidden;
  &.active {
    background: #3359f4 0% 0% no-repeat padding-box !important;
    color: white !important;
  }
`;

export const SubmitBoardButton = styled(Button)`
  color: white;
  background: transparent linear-gradient(260deg, #1d8cf8 0%, #3358f4 97%) 0% 0%
    no-repeat padding-box;
`;
