import * as React from 'react';
// import { page } from '../analytics';
import api from '../api';
import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { AppContainer } from './style';
import { store } from '../lib/store';
import { Provider } from 'react-redux';
import { OnBoardingIntercept } from '../components/common/onBoardingIntercept';
import { SectionLoader } from '../components/common/SectionLoader';
import { parse } from 'query-string';
import { WithUser, withUser } from '../api/withUser';

const preloader = import('../lib/editor/Editor');
const Editor = React.lazy(() => preloader);

interface WorkflowEditorViewProps extends SecureRouteProps {
  workflowId?: string;
}

type IProps = WithUser<WorkflowEditorViewProps>;

class WorkflowEditor extends React.Component<IProps> {
  render(): React.ReactNode {
    return (
      <Provider store={store}>
        <OnBoardingIntercept render={this.renderInner} />
      </Provider>
    );
  }

  renderInner = () => {
    let template;
    if (this.props.location.search) {
      ({ template } = parse(this.props.location.search));
    }
    return (
      <AppContainer fluid={true} className="bg-white">
        <React.Suspense fallback={<SectionLoader />}>
          <Provider store={store}>
            <Editor
              templateId={template}
              user={this.props.user}
              workflowId={this.props.workflowId}
              apiInterface={api}
              hideAllSelector
            />
          </Provider>
        </React.Suspense>
      </AppContainer>
    );
  };
}

export default withUser<IProps>(withTcApi(WorkflowEditor));
