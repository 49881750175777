import { LandingSelected } from './types';
import { store } from '../index';

function persist(key: string, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
}

export const setOnBoardingState = (state) => {
  try {
    window.localStorage.setItem('OnBoardingState', JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

function getItem(key: string) {
  try {
    const item = window.localStorage.getItem(key);
    return item && JSON.parse(item);
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export function getLastOnBoardingState() {
  let item = getItem('OnBoardingState');
  if (item && item.landingSelected && item.goalSelected) {
    return item;
  }
  return undefined;
}

function persistOnBoardingState() {
  store.subscribe(() => {
    const state = store.getState();
    persist('OnBoardingState', state.onboarding);
  });
}

// invoke once store is created
setTimeout(() => persistOnBoardingState(), 0);
