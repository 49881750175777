import React from 'react';
import InputCommonProps from './index.component';
import MultiSelect from './multi-select.component';

const daysOption = [
  {
    label: 'Monday',
    value: 'Mon',
  },
  {
    label: 'Tuesday',
    value: 'Tue',
  },
  {
    label: 'Wednesday',
    value: 'Wed',
  },
  {
    label: 'Thursday',
    value: 'Thu',
  },
  {
    label: 'Friday',
    value: 'Fri',
  },
  {
    label: 'Saturday',
    value: 'Sat',
  },
  {
    label: 'Sunday',
    value: 'Sun',
  },
];

export function WeekPickerWrapper(props: InputCommonProps) {
  const { value = [] } = props;
  return (
    <>
      <MultiSelect
        {...props}
        options={daysOption}
        value={value}
        onChange={(value) => {
          return props.onChange({
            target: {
              name: props.name,
              value: value ? value.map((u) => u.value) : [],
            },
          });
        }}
      />
    </>
  );
}
