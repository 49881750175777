import { IApi } from 'workflow-model';
import { IResolver } from 'workflow-model';

export const DATA_RESOLUTION_TYPES: { [s: string]: keyof IApi } = {
  GET_AVAILABLE_ALARMS: 'getAvailableAlarms',
  GET_CONFIGS: 'getConfigs',
  GET_WORKFLOW_DETAILS: 'getWorkflowDetails',
  CREATE_WORKFLOW: 'createWorkflow',
  EDIT_WORKFLOW: 'editWorkflow',
  VERIFY_WORKFLOW_POLICY: 'verifyWorkflowPolicy',
  RUN_NOW: 'executeWorkflowInstantRun',
  DRY_RUN: 'executeWorkflowDryRun',
  LIST_TEMPLATES: 'listTemplates',
  GET_TEMPLATE: 'getTemplate',
  GET_KEYS_FROM_SCHEMA: 'getKeysFromSchema',
};

interface IProps {
  apiInterface: IApi;
  onError: Function;
}

export class DataResolver implements IResolver {
  private props: IProps;
  // private resolutions: Object;

  constructor(props: IProps) {
    this.props = props;
    // this.resolutions = {
    //   getAvailableAlarms: this.props.apiInterface.getAvailableAlarms,
    //   getConfigs: this.props.apiInterface.getConfigs,
    //   getWorkflowDetails: this.props.apiInterface.getWorkflowDetails,
    //   createWorkflow: this.props.apiInterface.createWorkflow,
    //   editWorkflow: this.props.apiInterface.editWorkflow,
    //   verifyWorkflowPolicy: this.props.apiInterface.verifyWorkflowPolicy,
    //   executeWorkflowInstantRun: this.props.apiInterface
    //     .executeWorkflowInstantRun,
    //   executeWorkflowDryRun: this.props.apiInterface.executeWorkflowDryRun,
    //   listTemplates: this.props.apiInterface.listTemplates,
    //   getTemplate: this.props.apiInterface.getTemplate,
    // };
  }

  public async resolve(
    type: keyof IApi,
    param: any,
    onError?: (error: any) => void,
  ) {
    try {
      return await this.props.apiInterface[type](param);
    } catch (error) {
      const handlerError = (error: string) => {
        //console.trace("here", onError);
        if (onError) onError(error);
        this.props.onError(error);
      };
      if (error && error.response && error.response.error) {
        handlerError(error.response.error.message);
      } else if (error && error.message) {
        handlerError(error.message);
      } else handlerError('Something went wrong');
    }
  }
}
