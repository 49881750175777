import { events } from '../../../analytics/events';
import { observe } from '../../../analytics/eventCapturer';
import { track } from '../../../analytics/analytics';
import { OktaConfig } from '../../../config/okta';
export const SignUp = async (email, firstName, lastName, password) => {
  const headers: HeadersInit = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  headers.append('q', '0.01');
  headers.append('User-Agent', 'request');
  try {
    let res = await fetch(`${OktaConfig.REACT_APP_SIGNUP_URL}`, {
      method: 'post',
      headers,
      body: JSON.stringify({
        userProfile: {
          email,
          firstName,
          lastName,
          password,
        },
      }),
    });
    let response = await res.json();
    if (response.errorCauses) {
      throw response;
    } else {
      return response;
    }
  } catch (err) {
    throw err;
  }
};

export const ResetPassword = async (newPassword, stateToken) => {
  try {
    let res = await fetch(`${OktaConfig.REACT_APP_RESET_PASSWORD_URL}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // stateToken: cookies.oktaStateToken,
        stateToken,
        newPassword,
      }),
    });
    let response = await res.json();
    if (response.errorCauses) {
      throw response;
    } else {
      return response;
    }
  } catch (err) {
    throw err;
  }
};
