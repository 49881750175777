export const RequestJSON = 'RequestJSON';
export const RequestJSONSuccess = 'RequestJSONSuccess';

interface RequestJSON {
  type: typeof RequestJSON;
}

interface RequestJSONSuccess {
  type: typeof RequestJSONSuccess;
  data: string;
}

export type JSONAction = RequestJSON | RequestJSONSuccess;

export type JsonValueState = {
  fetching: boolean;
  data: string;
};
