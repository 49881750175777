/*
take data and figure out rows required
*/

import { ICustomTableCol } from '../../components/common/table';
import { Button, Col, UncontrolledTooltip } from 'reactstrap';
import React from 'react';

type ValueType =
  | 'null'
  | 'undefined'
  | 'object'
  | 'boolean'
  | 'number'
  | 'string'
  | 'array';

export const arrayFormatter = (cellContent, row, rowIndex) => {
  return cellContent;
};

export const rawFormatter = (cellContent, row, rowIndex) => {
  return row;
};

export const showMoreWithExternalModal = (modalCallback, dataCallback) => {
  return (cellContent, row, rowIndex) => {
    const data = dataCallback(cellContent, row, rowIndex);
    if (!data || Object.keys(data).length === 0) {
      return '--';
    }
    return (
      <div className={'d-flex'} style={{ maxWidth: '200px' }}>
        <span className={'text-truncate'}>{JSON.stringify(data)}</span>
        <i
          className={'fa fa fa-external-link ml-1 cursor-pointer '}
          onClick={() => modalCallback(data)}
        />
      </div>
    );
  };
};

export const openExternalModal = (modalCallback, dataCallback) => {
  return (cellContent, row, rowIndex) => {
    const data = dataCallback(cellContent, row, rowIndex);
    if (!data) {
      return '--';
    }
    return (
      <Button color={'link'} onClick={() => modalCallback(data)}>
        {' '}
        Details{' '}
      </Button>
    );
  };
};

function summary_column() {
  return {
    colName: 'JSON',
    dataField: 'JSON',
    text: 'JSON',
    sort: false,
    formatter: rawFormatter,
    isDummyField: true,
    headerClasses: 'text-right',
    classes: 'text-right',
  };
}

function column_string(name: string): ICustomTableCol {
  return {
    colName: name,
    dataField: name,
    text: name,
    sort: true,
    headerClasses: 'text-uppercase',
    classes: 'text-truncate',
  };
}

function column_mixed(name: string): ICustomTableCol {
  return {
    colName: name,
    dataField: name,
    text: name,
    sort: false,
    headerClasses: 'text-uppercase',
    formatter: arrayFormatter,
  };
}

function identify(key: string, value: any, prefix?: string): ICustomTableCol {
  let name: string;
  if (prefix) {
    name = `${prefix}.${key}`;
  } else {
    name = key;
  }
  let type: ValueType = typeof value as ValueType;
  if (type === 'object') {
    if (Array.isArray(value)) type = 'array';
    if (value === null) type = 'null';
  }
  switch (type) {
    case 'undefined':
    case 'null':
      return null;
    case 'boolean':
    case 'number':
    case 'string':
      return column_string(name);
    case 'array':
    case 'object':
      return column_mixed(name);
    default:
      return column_mixed(name);
  }
}

export function discoverRows(
  data: any[],
  detailsCB?: (data: any) => void,
): ICustomTableCol[] {
  const cols: ICustomTableCol[] = [];
  for (const datum of data) {
    for (const key of Object.keys(datum)) {
      const value = datum[key];
      const _col = identify(key, value);
      if (!_col) continue;
      if (!cols.find((c) => c.colName === _col.colName)) {
        cols.push(_col);
      }
    }
  }

  let summaryCol = summary_column();
  cols.push(summaryCol);

  for (const col of cols) {
    if (col.formatter && detailsCB && col.colName !== summaryCol.colName) {
      col.formatter = showMoreWithExternalModal(detailsCB, col.formatter);
    } else if (col.formatter && detailsCB) {
      col.formatter = openExternalModal(detailsCB, col.formatter);
    }
  }

  return cols;
}
