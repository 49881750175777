import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Period } from '../constants';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

const durations: { label: string; value: Period }[] = [
  { label: '1 day', value: Period.DAY },
  { label: '1 week', value: Period.WEEK },
  { label: '15 days', value: Period.WEEK * 2 + Period.DAY },
  { label: '1 month', value: Period.MONTH },
  { label: '3 months', value: Period.QUARTER },
  { label: '6 months', value: Period.MONTH * 6 },
  { label: '1 Year', value: Period.YEAR },
  { label: 'All', value: 0 },
];

interface DurationSelectorComponentProps {
  onChangeDuration: (duration: { fromTime: number; toTime: number }) => void;
  selectedDuration?: { fromTime: number; toTime: number };
}
export const DurationSelectorComponent = ({
  onChangeDuration,
  selectedDuration,
}: DurationSelectorComponentProps) => {
  const updateDuration = (opt: { label: string; value: Period }) => {
    onChangeDuration({
      fromTime: opt.value,
      toTime: selectedDuration.toTime,
    });
  };

  return (
    <div>
      <UncontrolledDropdown>
        <DropdownToggle
          data-test={'durationSelector'}
          color={'link'}
          size={'sm'}
        >
          Duration{' '}
          {durations.find((x) => x.value === selectedDuration.fromTime)?.label}
        </DropdownToggle>
        <DropdownMenu>
          {durations.map((opt, index) => (
            <DropdownItem
              data-testid={`chart-duration-selector-${index}`}
              key={JSON.stringify(opt)}
              active={opt.value === selectedDuration.fromTime}
              onClick={() => updateDuration(opt)}
            >
              {opt.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
