export const CustomStyles = {
  menu: () => ({ boxShadow: 'inset 0 0px 0 rgba(0, 0, 0, 0.1)' }),
  menuList: (styles) => ({ ...styles, overflowX: 'hidden', maxHeight: 200 }),
  control: (styles) => ({
    ...styles,
    minWidth: 240,
    minHeight: 20,
    maxWidth: 240,
    margin: 8,
    backgroundColor: 'white',
  }),
  groupHeading: (styles) => {
    return {
      ...styles,
      paddingLeft: 0,
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,

      maxWidth: 200,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: null,
      color: isDisabled
        ? 'grey'
        : isSelected
        ? '#1d8cf8'
        : isFocused
        ? '#F88205'
        : null,
      // fontWeight: isSelected ? 600 : "normal",
      cursor: isDisabled ? 'default' : 'pointer',
      padding: '0px 0px 0px 40px',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? null : null),
      },
    };
  },
};
