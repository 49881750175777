import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { TriggerWorkflowOptionsV2 } from '../TriggerSetting';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { TableSubCategories } from '../../tables';
import { AdoptionSetting } from '../AdoptionSetting';
interface OptionsProps {
  category: string;
  subCategories: string[];
  user: string;
  tabName: TAB_NAME;
  credentials: string[];
  regions: string[];
}

export enum ReportModals {
  ADOPTION = 'adoption',
  TRIGGER = 'trigger',
}

export const Options = (props: OptionsProps) => {
  const {
    category,
    subCategories,
    tabName,
    user,
    credentials,
    regions,
  } = props;

  const [modal, setModal] = useState<ReportModals | null>(null);
  const closeModal = () => setModal(null);
  const showAdoptionOption = subCategories.includes(
    TableSubCategories.ADOPTION_SETTINGS_DATA,
  );
  const showTriggerOption = subCategories.includes(
    TableSubCategories.TRIGGER_SETTINGS_DATA,
  );

  const hideRegionSelector = () => setModal(null);

  return (
    <React.Fragment>
      {showTriggerOption && modal === ReportModals.TRIGGER && (
        <TriggerWorkflowOptionsV2
          category={category}
          subCategory={TableSubCategories.TRIGGER_SETTINGS_DATA}
          user={user}
          tabName={tabName}
          show={modal === ReportModals.TRIGGER}
          closeModal={closeModal}
        />
      )}

      {showAdoptionOption && (
        <AdoptionSetting
          subCategory={TableSubCategories.ADOPTION_SETTINGS_DATA}
          category={category}
          tabName={tabName}
          credentials={credentials}
          regions={regions}
          hideRegionSelector={hideRegionSelector}
          renderRegionSelector={modal === ReportModals.ADOPTION}
        />
      )}
      <UncontrolledDropdown>
        <DropdownToggle color={'primary'} outline className={'ml-2'}>
          Options
        </DropdownToggle>
        <DropdownMenu>
          {showTriggerOption && (
            <DropdownItem onClick={() => setModal(ReportModals.TRIGGER)}>
              Trigger Setting
              <div className="float-right">
                <i className="fa fa-info" id={'trigger-save'} />
              </div>
              <UncontrolledTooltip target={'trigger-save'}>
                {`Configure when ${tabName} will run`}
              </UncontrolledTooltip>
            </DropdownItem>
          )}

          {showAdoptionOption && (
            <DropdownItem onClick={() => setModal(ReportModals.ADOPTION)}>
              Adoption Setting
              <div className="float-right">
                <i className="fa fa-info" id={'region-setting'} />
              </div>
              <UncontrolledTooltip target={'region-setting'}>
                {`Configure regions in which ${tabName} should be adopted`}
              </UncontrolledTooltip>
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
};
