import React, { Component } from 'react';
import Search from '../../common/search.component';
import { StyledCustomDropDown } from '../style';

const filterOptions = [
  {
    label: <span className="test-filter-all">All</span>,
    value: 'all',
  },
  {
    label: <span className="test-filter-deployed">Deployed</span>,
    value: 'active',
  },
  {
    label: <span className="test-filter-staged">Staged</span>,
    value: 'inactive',
  },
  {
    label: <span className="test-filter-failing">Failing</span>,
    value: 'failing',
  },
  {
    label: <span className="test-filter-succeeded">Succeeded</span>,
    value: 'succeeded',
  },
];

const sortingOptions = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: <span className="test-sort-createdAt">Created At</span>,
    value: 'createdAt',
  },
  {
    label: <span className="test-sort-lastRun">Last Run</span>,
    value: 'lastRun',
  },
];

interface OwnProps {
  selectedFilter: string;
  selectedSort: string;
  handleFilter(data: any): void;
  handleSort(data: any): void;
}

export const Filters = (props: OwnProps) => {
  const { selectedFilter, handleFilter, selectedSort, handleSort } = props;
  return (
    <>
      <StyledCustomDropDown
        name="filter-workflow"
        className={'p-2 m-1'}
        value={selectedFilter}
        options={filterOptions}
        icon={
          selectedFilter === 'all'
            ? 'fa master-tc-filter test-filter-button'
            : 'navIcon-filter-applied test-filter-selected'
        }
        onChange={handleFilter}
        right
      />
      <StyledCustomDropDown
        className={'p-2 m-1'}
        name="sort-workflow"
        value={selectedSort}
        options={sortingOptions}
        icon="fa master-tc-sort test-sort-button"
        onChange={handleSort}
        right
      />
    </>
  );
};
