import React from 'react';
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from 'reactstrap';
import { FormikProps } from 'formik';
import { TriggerSchema } from '../schema';
import classnames from 'classnames';
import { ViewType } from 'workflow-model/dist/types/NodeDataTypes';

function getIcon(active: boolean, error: boolean) {
  if (error) return <i className="fa fa-exclamation-circle text-danger ml-2" />;
  if (active) return <i className="fa fa-check-circle text-primary ml-2" />;
  return <i className="fa fa-pause-circle-o ml-2" />;
}

export function TypeSelector(
  props: React.PropsWithChildren<FormikProps<TriggerSchema>>,
) {
  const toggle = (s: 'schedule' | 'http' | 'alarm' | 'manual' | 'events') =>
    props.setFieldValue('view', s);
  const activeTab: ViewType = props.values.view;
  return (
    <div>
      <Nav tabs className="mb-0 pb-0 mt-0">
        <NavItem>
          <NavLink
            className={classnames({
              'active border border-dark': activeTab === 'schedule',
            })}
            onClick={() => toggle('schedule')}
          >
            <span
              className={classnames({
                'text-muted': !props.values.schedule.active,
                'text-uppercase font-weight-bold': true,
              })}
            >
              Schedule
            </span>
            {getIcon(props.values.schedule.active, !!props.errors.schedule)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              'active border border-dark': activeTab === 'http',
            })}
            onClick={() => toggle('http')}
          >
            <span
              className={classnames({
                'text-muted': !props.values.http.active,
                'text-uppercase font-weight-bold': true,
              })}
            >
              Http
            </span>
            {getIcon(props.values.http.active, !!props.errors.http)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              'active border border-dark': activeTab === 'alarm',
            })}
            onClick={() => toggle('alarm')}
          >
            <span
              className={classnames({
                'text-muted': !props.values.alarm.active,
                'text-uppercase font-weight-bold': true,
              })}
            >
              Alarm
            </span>
            {getIcon(props.values.alarm.active, !!props.errors.alarm)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              'active border border-dark': activeTab === 'events',
            })}
            onClick={() => toggle('events')}
          >
            <span
              className={classnames({
                'text-muted': !props.values.event.active,
                'text-uppercase font-weight-bold': true,
              })}
            >
              Events
            </span>
            {getIcon(props.values.event.active, !!props.errors.event)}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              'active border border-dark': activeTab === 'manual',
            })}
            onClick={() => toggle('manual')}
          >
            <span
              className={classnames({
                'text-muted': !props.values.alarm.active,
                'text-uppercase font-weight-bold': true,
              })}
            >
              Manual
            </span>
            {getIcon(true, false)}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>{props.children}</TabContent>
    </div>
  );
}
