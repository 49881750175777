import * as React from 'react';
import { Col, CustomInput, InputGroup, Label, Row } from 'reactstrap';
import './forminputs.style.css';
import InputCommonProps from './index.component';
import ExternalLink from './shared/external-link.component';

interface CheckBoxProps extends InputCommonProps {
  options: {
    label: string;
    value: any;
  }[];
  CheckedValues: any[];
  disabled?: string[];
  hideSelectAll?: boolean;
  onCheckedAll?(status: boolean): void;
  border?: boolean;
}

const CheckBox = (props: CheckBoxProps) => {
  const {
    id,
    name,
    label,
    onChange,
    onBlur,
    error,
    tooltip,
    options,
    CheckedValues,
    onCheckedAll,
    disabled,
    externalLink,
    hideSelectAll,
    border = true,
  } = props;
  const optionClass = border ? 'custom-input-border' : '';
  const invalid = !!(error && error.length);
  const isInfoAvailable =
    tooltip && tooltip.length ? 'input-with-info  pb-1 pt-1' : ' pb-1 pt-1';
  return (
    <Row>
      <Col md={11}>
        <div className={'d-flex justify-content-between pt-2'}>
          {label && <Label for={id}>{label}</Label>}
          {!hideSelectAll && (
            <span
              onClick={() => {
                onCheckedAll &&
                  onCheckedAll(CheckedValues.length !== options.length);
              }}
              className={'cursor-pointer'}
              style={{ color: '#1d8cf8' }}
            >
              {CheckedValues.length === options.length
                ? 'Unselect All'
                : 'Select all'}
            </span>
          )}
        </div>
        <Col md={12} className={optionClass}>
          {options &&
            options.map((option, index) => (
              <InputGroup key={index} className={isInfoAvailable}>
                <CustomInput
                  id={name + index}
                  name={option.label}
                  type="checkbox"
                  disabled={disabled && disabled.indexOf(option.value) !== -1}
                  checked={
                    CheckedValues && CheckedValues.indexOf(option.value) !== -1
                  }
                  className={'text-truncate'}
                  label={option.label}
                  onChange={(event) => onChange && onChange(option.value)}
                  onBlur={onBlur}
                  invalid={invalid}
                />
                {externalLink &&
                  CheckedValues &&
                  CheckedValues.indexOf(option.value) !== -1 && (
                    <ExternalLink
                      links={externalLink.map(
                        ({ label, onClick, disabled }) => {
                          let internalOnClick = (e: Event) =>
                            onClick(option.value);
                          return { label, onClick: internalOnClick, disabled };
                        },
                      )}
                      className={'checkbox-external-links'}
                    />
                  )}
              </InputGroup>
            ))}
        </Col>
      </Col>
    </Row>
  );
};

export default CheckBox;
