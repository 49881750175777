export const REGIONS = [
  { value: 'us-east-1', label: 'N. Virginia' },
  { value: 'us-east-2', label: 'Ohio' },
  { value: 'us-west-1', label: 'N. California' },
  { value: 'us-west-2', label: 'Oregon' },

  { value: 'eu-west-1', label: 'Ireland' },
  { value: 'eu-west-2', label: 'London' },
  { value: 'eu-west-3', label: 'Paris' },
  { value: 'eu-north-1', label: 'Stockholm' },
  { value: 'eu-central-1', label: 'Frankfurt' },

  { value: 'ap-southeast-1', label: 'Singapore' },
  { value: 'ap-southeast-2', label: 'Sydney' },
  { value: 'ap-northeast-1', label: 'Tokyo' },
  { value: 'ap-northeast-2', label: 'Seoul' },
  { value: 'ap-east-1', label: 'Hong Kong' },
  { value: 'ap-south-1', label: 'Mumbai' },

  { value: 'sa-east-1', label: 'Sao Paulo' },

  { value: 'ca-central-1', label: 'Canada' },
];

export const REGIONS_GROUPS = [
  {
    label: 'US',
    options: [
      { value: 'us-east-1', label: 'N. Virginia' },
      { value: 'us-east-2', label: 'Ohio' },
      { value: 'us-west-1', label: 'N. California' },
      { value: 'us-west-2', label: 'Oregon' },
    ],
  },
  {
    label: 'Europe',
    options: [
      { value: 'eu-west-1', label: 'Ireland' },
      { value: 'eu-west-2', label: 'London' },
      { value: 'eu-west-3', label: 'Paris' },
      { value: 'eu-north-1', label: 'Stockholm' },
      { value: 'eu-central-1', label: 'Frankfurt' },
    ],
  },
  {
    label: 'Asia Pacific',
    options: [
      { value: 'ap-southeast-1', label: 'Singapore' },
      { value: 'ap-southeast-2', label: 'Sydney' },
      { value: 'ap-northeast-1', label: 'Tokyo' },
      { value: 'ap-northeast-2', label: 'Seoul' },
      { value: 'ap-east-1', label: 'Hong Kong' },
      { value: 'ap-south-1', label: 'Mumbai' },
    ],
  },
  {
    label: 'Canada',
    options: [{ value: 'ca-central-1', label: 'Canada' }],
  },
  {
    label: 'South America',
    options: [{ value: 'sa-east-1', label: 'Sao Paulo' }],
  },
];
