import React, { HTMLAttributes } from 'react';
import {
  Col,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import './forminputs.style.css';
import InputCommonProps from './index.component';
import Tooltip from './shared/tooltip.component';
import ExternalLink from './shared/external-link.component';

interface InputDataListProps extends InputCommonProps {
  options: {
    label: string;
    value: string;
  }[];
  placeHolder?: string;
  avoidErrorText?: boolean;
  showCalendar?: (data: any) => void;
}

const InputWrapper = ({
  showCalendar,
  ...htmlAttributes
}: HTMLAttributes<HTMLDivElement> & { showCalendar?: (data: any) => void }) =>
  showCalendar ? (
    <InputGroup {...htmlAttributes} />
  ) : (
    <div {...htmlAttributes} />
  );

function InputDataList(props: InputDataListProps) {
  const {
    name,
    onChange,
    id,
    label,
    options,
    value,
    error,
    onBlur,
    tooltip,
    externalLink,
    onFocus,
    onKeyDown,
    showCalendar,
    readOnly,
    avoidErrorText,
    placeHolder,
  } = props;

  const invalid = !!(error && error.length);
  const infoWithError = !invalid ? 'link-without-error' : '';

  return (
    <Row>
      <Col sm={12}>
        {label && (
          <Label className={'text-dark'} for={id}>
            {label}
          </Label>
        )}
      </Col>
      <Col sm={12}>
        <Row>
          <Col sm={12}>
            <InputWrapper showCalendar={showCalendar}>
              <Input
                placeholder={placeHolder}
                autoComplete={'off'}
                list={name}
                name={name}
                onChange={onChange}
                value={value}
                invalid={invalid}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                readOnly={readOnly}
              />
              {typeof showCalendar !== 'undefined' && (
                <InputGroupAddon
                  addonType="append"
                  className={invalid ? `tooltip-invalid` : ''}
                  onClick={showCalendar}
                >
                  <span className="input-group-text date-select">
                    <i
                      className="fa fa-calendar calendar-icon"
                      aria-hidden="true"
                    />
                  </span>
                </InputGroupAddon>
              )}
              {tooltip && <Tooltip info={tooltip || ''} invalid={invalid} />}
              {invalid && !avoidErrorText && (
                <FormFeedback>{error}</FormFeedback>
              )}
              <datalist id={name}>
                {options &&
                  options.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                      label={option.label}
                    />
                  ))}
              </datalist>
            </InputWrapper>
          </Col>
          <Col>
            {externalLink && (
              <ExternalLink
                links={externalLink}
                className={`right-0-9 ${infoWithError}`}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default InputDataList;
