import * as React from 'react';
import { Col, Row } from 'reactstrap';
import CodeEditor from '../../../../formInputs/codeEditor.component';
import { AppState } from '../../../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { fetchJSONValue } from '../../../../store/jsonsection/actions';
import { JsonSectionDataType } from './ModalWrapper';
import LineLoader from '../../../../../components/common/LineLoader';

interface StateProps {
  isFetching: boolean;
  jsonValue: string;
}

interface DispatchProps {
  fetchJson: () => any;
}

interface OwnProps {
  jsonData: JsonSectionDataType;
}

type IProps = OwnProps & StateProps & DispatchProps;

class JsonSectionInner extends React.Component<IProps> {
  componentDidMount(): void {
    if (this.props.jsonData && this.props.jsonData.selectedNode)
      this.props.fetchJson();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    if (
      this.props.jsonData &&
      this.props.jsonData.selectedNode &&
      JSON.stringify(prevProps.jsonData) !== JSON.stringify(this.props.jsonData)
    ) {
      this.props.fetchJson();
    }
  }
  render(): React.ReactNode {
    let { jsonValue, isFetching } = this.props;
    let lineNumber,
      colNumber = 0;
    if (jsonValue && this.props.jsonData && this.props.jsonData.selectedNode) {
      let selectedNodeId = this.props.jsonData.selectedNode.getNodeId();
      let preText = jsonValue.substring(
        0,
        jsonValue.indexOf(`"id": "${selectedNodeId}"`),
      );
      lineNumber = preText.split('\n').length;
      colNumber = preText.substring(preText.lastIndexOf('\n') + 1).length;
    }
    return (
      <div>
        {isFetching && <LineLoader />}
        <Row>
          <Col>
            <CodeEditor
              gotToLineNumber={lineNumber}
              gotToColNumber={colNumber}
              value={{ data: jsonValue || '{}' }}
              name={'node-json'}
              readOnly={true}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state: AppState): StateProps {
  return {
    isFetching: state.JSONReducer.fetching,
    jsonValue: state.JSONReducer.data || '{}',
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchJson: () => fetchJSONValue({ dispatch, uiData: ownProps.jsonData }),
  };
}

export const JSONSection = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(JsonSectionInner);
