import { FormikProps } from 'formik';
import { TriggerSchema } from '../schema';
import { Alert, Button, Col, Row } from 'reactstrap';
import { ToggleActiveState } from './ToggleActiveState';
import React from 'react';
import { IWorkflow } from 'workflow-model/dist';
import { nsMap, stat } from 'workflow-model/dist/config/CloudwatchOptions';
import { KeyValuePairEditor } from '../../../../../../formInputs/keyValuePairEditor';
import { MultiSelectV2 } from '../../../../../../formInputs/multi-select.component';
import InputSelect from '../../../../../../formInputs/select-option.component';
import { SectionLoader } from '../../../../../../../components/common/SectionLoader';
import { AppState } from '../../../../../../store';
import { CONFIG_TYPES } from 'webapp-genric/dist';
import { Dispatch } from 'redux';
import { fetchConfig } from '../../../../../../store/config/action';
import { connect } from 'react-redux';

type StateProps = {
  isConfigSelected: boolean;
  isConfigAvailable: boolean;
  isFetching: boolean;
};

type DispatchProps = {
  fetchConfig: () => void;
};

interface OwnProps extends FormikProps<TriggerSchema> {
  workflowModel: IWorkflow;
}

type IProps = StateProps & DispatchProps & OwnProps;

function AlarmFormInner(props: IProps) {
  const nsOptions = Object.keys(nsMap).map((v) => ({ label: v, value: v }));
  const metricOptions = nsMap[props.values.alarm.value.namespace]
    ? nsMap[props.values.alarm.value.namespace].MetricNames.map((v) => ({
        label: v,
        value: v,
      }))
    : [];
  const dimensionOptions = nsMap[props.values.alarm.value.namespace]
    ? nsMap[props.values.alarm.value.namespace].Dimensions.map((v) => ({
        label: v,
        value: v,
      }))
    : [];
  const statisticOptions = stat.map((v) => ({ label: v, value: v }));
  if (props.isFetching) {
    return <SectionLoader />;
  }
  if (!props.isConfigSelected) {
    return (
      <Row className={'mt-4'}>
        <Col md={8}>
          <Alert color={'danger'}>
            <i className="fa fa-warning mr-2" /> Please select an aws credential
            and region in editor.
          </Alert>
        </Col>
      </Row>
    );
  }
  if (!props.isConfigAvailable) {
    return (
      <Row className={'mt-4'}>
        <Col md={8}>
          <Alert color={'danger'}>
            <i className="fa fa-warning mr-2" /> Please enable Events for
            selected region.
          </Alert>
        </Col>
        <Col md={6}>
          <a href={'/accounts/events'} target="_blank">
            <Button color={'primary'}>
              <i className="fa fa-external-link-square mr-1" /> Accounts{' '}
            </Button>
          </a>
          <Button color={'primary'} outline={true} onClick={props.fetchConfig}>
            <i className="fa fa-refresh mr-1" /> Refresh{' '}
          </Button>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Row>
        <Col md={12}>
          <ToggleActiveState {...props} targetType={'alarm'} />
        </Col>
      </Row>
      <Row className={'px-3'}>
        <h4 className="text-muted">
          Select Alarm conditions for which this workflow should run
        </h4>
      </Row>
      <Row className={'pb-2'}>
        <Col md={6}>
          <InputSelect
            label={'Select Metric Namespace'}
            name={'alarm.value.namespace'}
            value={props.values.alarm.value.namespace}
            onChange={props.handleChange}
            error={
              props.errors.alarm &&
              props.errors.alarm.value &&
              props.errors.alarm.value.namespace
            }
            options={nsOptions}
          />
        </Col>
      </Row>
      <Row className={'pb-2'}>
        <Col md={6}>
          <MultiSelectV2
            label={'Select Metric Names'}
            name={'alarm.value.metricNames'}
            value={props.values.alarm.value.metricNames}
            onChange={props.handleChange}
            error={
              props.errors.alarm &&
              props.errors.alarm.value &&
              props.errors.alarm.value.metricNames &&
              JSON.stringify(props.errors.alarm.value.metricNames)
            }
            options={metricOptions}
          />
        </Col>
      </Row>
      <Row className={'pb-2'}>
        <Col md={6}>
          <MultiSelectV2
            label={'Select Statistics (optional)'}
            name={'alarm.value.statistics'}
            value={props.values.alarm.value.statistics}
            onChange={props.handleChange}
            error={
              props.errors.alarm &&
              props.errors.alarm.value &&
              props.errors.alarm.value.statistics &&
              JSON.stringify(props.errors.alarm.value.statistics)
            }
            options={statisticOptions}
          />
        </Col>
      </Row>
      <Row className={'pb-2'}>
        <Col md={6}>
          <KeyValuePairEditor
            value={props.values.alarm.value.dimensions}
            label={'Select Dimensions (optional)'}
            name={'alarm.value.dimensions'}
            error={
              props.errors.alarm &&
              props.errors.alarm.value &&
              props.errors.alarm.value.dimensions
            }
            onChange={props.handleChange}
            keyOptions={dimensionOptions}
          />
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const currentConfigId = ownProps.workflowModel.getCredentials();
  const region = ownProps.workflowModel.getRegion();
  let isConfigAvailable = false;
  const configs = state.config.data;
  if (currentConfigId && configs) {
    const currentConfig = configs.find((u) => u.id === currentConfigId);
    for (const config of configs) {
      if (config.type === CONFIG_TYPES.Events) {
        const parent = configs.find((u) => u.id === config.parentId);
        if (
          parent &&
          currentConfig &&
          parent.accountId === currentConfig.accountId &&
          config.value.regions &&
          config.value.regions.includes(region)
        ) {
          isConfigAvailable = true;
          break;
        }
      }
    }
  }
  return {
    isConfigSelected: !!(currentConfigId && region),
    isConfigAvailable: isConfigAvailable,
    isFetching: state.config.fetching,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    fetchConfig: () => fetchConfig({ dispatch }),
  };
}

export const AlarmForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlarmFormInner);
