import styled from 'styled-components';
import colors from '../../design/colors';
import fonts from '../../design/fonts';
import { SCREEN_BREAKPOINTS } from '../../context/deviceContext';
import { Col, ListGroup } from 'reactstrap';
/**Height is set to screen height - header height + top padding + other internal paddings */
export const DashboardWrapper = styled.div`
  position: relative;
  height: calc(100vh - 10vh);
`;

export const OverviewCard = styled.div`
  // position: relative;
  // display: flex;
  // width: 100%;
  // justify-content: space-between;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  // @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
  //   height: 220px;
  // }
  // height: 270px;
  // position: relative;
`;

export const OverviewCardLeft = styled.div`
  background-color: ${colors.palette.bgThird};
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.25em;
  // box-shadow: 0 0.125em 0.125em 0.06px #000;
  position: relative;
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
    // height: 13.75em;
  }
`;

export const OverviewCardRight = styled.div`
  background-color: ${colors.palette.bgThird};
  width: 49%;
  border-radius: 4px;
  box-shadow: 0 2px 2px 1px #000;
  position: relative;
  display: flex;
`;

export const OverViewContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8rem;
  height: 20rem;
  align-items: center;
`;

export const OverviewInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 1.5rem;
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.small}px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

export const InnerSec = styled(Col)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.small}px) {
    &:first-child {
      margin-bottom: 2em;
    }
  }
`;
export const ListWorkflow = styled(ListGroup)`
  width: 100%;
  padding-inline-start: 0;
`;
export const ListWorkflows = styled(ListGroup)`
  width: 100%;
  padding-inline-start: 0;
  li {
    border: 0 !important;
  }
`;

export const IntervalLabel = styled.div`
  cursor: pointer;
  color: ${colors.text.secondary};
  opacity: 0.5;
  &.active {
    opacity: 1;
  }
`;

export const OverviewHeader = styled(IntervalLabel)`
  color: ${colors.text.secondary};
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
    margin-bottom: 1rem;
  }
`;

export const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem 5rem 1rem 0;
  min-width: 150px;
  margin: 0 0 1rem 0;
  color: ${colors.text.secondary};
  .data-value {
    font-size: ${fonts.size.heading.large};
    margin-bottom: 1em;
  }
  .data-label {
    font-size: ${fonts.size.content.medium};
  }
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.large}px) {
    padding: 0.5rem;
    min-width: 6.875em;
    .data-value {
      font-size: ${fonts.size.heading.regular};
    }
  }
`;

export const WorkflowSection = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  // bottom: 10px;
  height: calc(100% - 320px);
  padding: ${(props: any) => (props.fluid ? '0rem' : '0rem 3rem')};
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
    height: calc(100% - 290px);
  }
`;

export const ExecutionListWrapper = styled.div`
  padding-top: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  // position: absolute;
  width: 100%;
  bottom: 0;
  height: calc(100% - 60px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
    margin-bottom: 1rem;
  }
`;

export const ExecutionListHeader = styled.div`

  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: ${(props: any) => (props.insideWorkflowDetails ? 0 : '4rem')};
  .title-text {
    color: ${colors.text.secondary};
    display: flex;
    font-size: ${(props: any) =>
      props.insideWorkflowDetails
        ? fonts.size.content.large
        : fonts.size.heading.regular};
    flex: 1;
  }
  .status-selector {
    font-size: ${(props: any) =>
      props.insideWorkflowDetails
        ? fonts.size.content.regular
        : fonts.size.heading.small};
    display: flex;
    
  }
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
    margin-bottom: 1rem;
    .title-text {
      font-size: ${(props: any) =>
        props.insideWorkflowDetails
          ? fonts.size.content.regular
          : fonts.size.content.large};
    }
`;

export const ExecutionListContainer = styled.div`
  position: relative;
  height: 100%;
  width: ${(props: any) => (props.insideWorkflowDetails ? '100%' : '50%')};
  border-right: ${(props: any) =>
    props.insideWorkflowDetails
      ? 'none'
      : ` solid 1px ${colors.border.normal}`};
`;

export const Selectorlabel = styled.div`
  color: ${colors.text.secondary};
  // margin-left: 1.5rem;
  cursor: pointer;
  opacity: 0.3;
  &.active {
    opacity: 1;
  }
`;

export const WorkflowListWrapper = styled(ExecutionListContainer)`
  height: 70%;
  width: 45%;
  padding: 0 0 0 2rem;

  .absolute-content {
    top: 40px;
    max-height: calc(100% - 40px);
    width: 100%;
    overflow-y: hidden;
    margin-top: 1rem;
  }
`;

export const WorkflowListHeader = styled.div`
  height: 3rem;
  padding-top: 40px;
  .title-text {
    font-size: ${fonts.size.content.large};
    color: ${colors.text.secondary};
  }
  .count-value {
    font-size: ${fonts.size.content.medium};
    color: #000;
    margin-left: 0.5rem;
  }
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
    margin-bottom: 0.5rem;
    .title-text {
      font-size: ${fonts.size.content.large};
    }
    .count-value {
      font-size: ${fonts.size.content.regular};
    }
  }
`;

export const ExecutionListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props: any) =>
    props.insideWorkflowDetails ? '12px 0' : '12px 4rem 12px 4px'};
  min-height: 24px;
  border-bottom: solid 1px ${colors.border.normal};
  cursor: ${(props: any) =>
    props.insideWorkflowDetails ? 'pointer' : 'default'};
  &:last-child {
    border-bottom: none;
  }
  &.active {
    background: #ddd;
  }
  &:hover {
    background: ${(props: any) =>
      props.insideWorkflowDetails ? '#ddd' : 'transparent'};
  }
  .wfname {
    font-size: ${(props: any) =>
      props.insideWorkflowDetails
        ? fonts.size.content.regular
        : fonts.size.content.large};
    color: ${colors.text.secondary};
  }
  .days-count {
    font-size: ${(props: any) =>
      props.insideWorkflowDetails
        ? fonts.size.content.regular
        : fonts.size.content.medium};
    color: ${colors.text.secondary};
    margin-left: 0.5rem;
    font-style: italic;
    opacity: 0.6;
  }
  .status {
    font-size: ${fonts.size.content.medium};
    color: ${colors.text.secondary};
  }
  .success {
    color: ${colors.palette.success};
  }
  .error {
    color: ${colors.palette.error};
  }
  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
    .wfname {
      font-size: ${fonts.size.content.regular};
    }
    .days-count {
      font-size: ${fonts.size.content.regular};
    }
  }
`;

export const WorkflowItemsWrapper = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media only screen and (max-width: ${SCREEN_BREAKPOINTS.medium}px) {
    margin-bottom: 1rem;
  }
`;

export const WorkflowListItem = styled.div`
  margin-bottom: 1rem;
  .wfname {
    font-size: ${fonts.size.content.medium};
    color: ${colors.text.secondary};
    margin-bottom: 0.5rem;
  }
  .description-sec {
    display: flex;
    align-items: center;
  }
  .desc {
    width: 90%;
    color: ${colors.text.secondary};
  }
  .options-icon {
    width: 10%;
    text-align: center;
  }
`;

export const WorkflowButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 0.95vw;
  button {
    font-size: 0.8em !important;
  }
`;

export const SuccessRateWrapper = styled.div`
  color: ${colors.text.secondary};
  font-size: ${fonts.size.heading.xLarge};
  justify-content: center;
  margin-right: 5rem;
  margin-bottom: 5rem;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
`;

export const ProgressContainer = styled.div`
  // align-items: center;
  width: 10%;
`;
