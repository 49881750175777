import { TableItem, TemplateColNames } from '../cols';
import * as React from 'react';
import TestSettingModal from '../TestSettingModal';
import { TemplateTestSettings } from '../../../../lib/store/templates/types';
import { loader } from './loader';

export const TemplateTestSettingsDisplay = (
  cell: TemplateTestSettings,
  item: TableItem,
) => {
  if (!item.templateItem || !item.templateItem.id) return '';
  if (item.updating === TemplateColNames.TEST_SETTING) return loader();
  return (
    <TestSettingModal
      testSettings={cell}
      template={item.templateItem}
      onSave={item.functions.updateTemplateTestSettings}
      getCustomWorkflows={item.functions.getCustomWorkflows}
      getTemplateTesterWorkflows={item.functions.getTemplateTesterWorkflows}
    />
  );
};
