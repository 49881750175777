import * as React from 'react';
import { Col, Input, Row } from 'reactstrap';
import { CustomBoardButton, SubmitBoardButton } from '../styles';
import { ButtonText } from '../../common/buttonText';
import { OnBoardingQuestionsType } from '../../common/questions';
import { ChangeEvent, useEffect, useState } from 'react';

export interface OnBoardingQuestionViewProps {
  currentQuestion: OnBoardingQuestionsType;
  onSelectAnswer: (answer: string[]) => void;
  skip?: boolean;
  answers?: string[];
  total: number;
  now: number;
}

const OptionalValueInput = ({
  onChange,
  show,
  option,
  value,
}: {
  show: boolean;
  option: { name: string; value: string; optional?: boolean };
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}) => {
  return show ? (
    <Input
      onChange={onChange}
      data-testid={'on-boarding-optional-input'}
      name={`on-boarding-option-${option.name}`}
      value={value}
      placeholder={'Type your answer'}
      className={'rounded-0 mt-2'}
      autoFocus={true}
    />
  ) : null;
};

export const OnBoardingQuestionView = ({
  answers = [],
  currentQuestion,
  onSelectAnswer,
  now,
  skip,
  total,
}: OnBoardingQuestionViewProps) => {
  const [value, setValue] = useState<string[]>([]);
  const [optionalValue, setOptionalValue] = useState<string>('');

  useEffect(() => {
    const filterAnswers = currentQuestion.options
      .filter((opt) => answers.includes(opt.value))
      .map((opt) => opt.value);
    setValue(
      filterAnswers.length !== answers.length
        ? [...filterAnswers, 'Other']
        : filterAnswers,
    );

    const otherOption = answers.filter((x) => !filterAnswers.includes(x));
    setOptionalValue(otherOption[0] || '');
  }, [currentQuestion]);

  const selectOption = (option: {
    name: string;
    value: string;
    optional?: boolean;
  }) => {
    const valueIndex = value.findIndex((p) => p == option.value);
    if (valueIndex !== -1) {
      setValue(value.slice(0, valueIndex).concat(value.slice(valueIndex + 1)));
    } else {
      setValue(
        currentQuestion.multi ? [...value, option.value] : [option.value],
      );
    }
  };

  const onChangeOptionalInput = (e: ChangeEvent<HTMLInputElement>) => {
    setOptionalValue(e.target.value);
  };

  const proceed = () => {
    onSelectAnswer(
      value.map((x) => (x === 'Other' ? optionalValue || 'Other' : x)),
    );
  };

  return (
    <div className="board-content">
      <div className="text-center font-64 custom-light-grey opacity-2">
        {now}
        <span className="font-24">/{total}</span>
      </div>
      <h6 className="text-center font-18">
        <strong>{currentQuestion.question}</strong>
      </h6>
      <Row className={'text-center'}>
        <Col
          md={currentQuestion.options.length > 3 ? 4 : 6}
          className={'mx-auto'}
        >
          {currentQuestion.options.slice(0, 3).map((option) => (
            <div className={'mb-2'}>
              <div>
                <CustomBoardButton
                  block={true}
                  active={value.includes(option.value)}
                  name={option.name}
                  id={option.value}
                  onClick={(e) => selectOption(option)}
                >
                  <ButtonText className="board-button-text">
                    {option.name}
                  </ButtonText>
                </CustomBoardButton>
              </div>
              <div>
                <OptionalValueInput
                  show={option.optional && value.includes(option.value)}
                  option={option}
                  onChange={onChangeOptionalInput}
                  value={optionalValue}
                />
              </div>
            </div>
          ))}
        </Col>

        {currentQuestion.options.length > 3 && (
          <Col md={4} className={'mx-auto'}>
            {currentQuestion.options.slice(3).map((option) => (
              <div key={option.name} className={'mb-2'}>
                <div>
                  <CustomBoardButton
                    block={true}
                    active={value.includes(option.value)}
                    name={option.name}
                    id={option.value}
                    onClick={(e) => selectOption(option)}
                  >
                    <ButtonText className="board-button-text">
                      {option.name}
                    </ButtonText>
                  </CustomBoardButton>
                </div>
                <div>
                  <OptionalValueInput
                    show={option.optional && value.includes(option.value)}
                    option={option}
                    onChange={onChangeOptionalInput}
                    value={optionalValue}
                  />
                </div>
              </div>
            ))}
          </Col>
        )}
      </Row>
      <div>
        <div className={'d-flex justify-content-center w-100 mt-5'}>
          <SubmitBoardButton
            data-testid={'select-answer-button'}
            color={'primary'}
            className={'custom-button-width'}
            outline={!value.length}
            disabled={!value.length}
            onClick={proceed}
          >
            {now === total ? 'Finish' : 'Next'}
          </SubmitBoardButton>
        </div>
      </div>
    </div>
  );
};
