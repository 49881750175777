import { Transform } from './index';

export default class MetricHandler extends Transform {
  mapTS(data, tKey, vKey) {
    let timestamps = data[tKey];
    let values = data[vKey];
    delete data['MetricData.Timestamps'];
    delete data['MetricData.Values'];
    let modified: { timestamp; value }[] = [];
    for (let i = 0; i < timestamps.length; i++) {
      modified.push({
        timestamp: timestamps[i],
        value: values[i],
      });
    }
    return modified;
  }

  apply(block: any): any {
    if (block && block['MetricData.Timestamps'] && block['MetricData.Values']) {
      let modified = this.mapTS(
        block,
        'MetricData.Timestamps',
        'MetricData.Values',
      );
      if (!modified.length) {
        return block;
      }
      return modified.map((m) => Object.assign({}, m, block));
    }
    return block;
  }
}
