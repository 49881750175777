import styled, { keyframes } from 'styled-components';
import fonts from '../../design/fonts';

export const MailSupport = styled.p`
  font-size: 1.2em;
  a {
    color: inherit;
    font-weight: 500;
  }
`;

export const CardWrapper = styled.a<{ width?: number; height?: number }>`
  width: 20em;

  .card {
    width: 100%;
    padding: 18% 0 !important;
  }
  .card-title {
    font-size: 1.25em;
  }
  img {
    width: ${(props) => props.width || '7.5'}em;
    height: ${(props) => props.height || '7.5'}em;
  }
`;
