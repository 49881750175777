import * as yup from 'yup';
import { OrgGroup, OrgUser } from '../../lib/store/tenants/types';
import React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import CheckBox from '../../lib/formInputs/checkbox.component';
import { Config } from '../../lib/store/config/types';

type IProps = {
  reassignGroups: (user: OrgUser, groups: OrgGroup[]) => void;
  user: OrgUser;
  groups: OrgGroup[];
  config: Config[];
};

const schema = yup.object().shape({
  groups: yup.array().of(yup.string()),
});

type GroupsForm = yup.InferType<typeof schema>;

export function ModifyGroups(props: IProps) {
  const [isModelVisible, setModalVisible] = React.useState(false);
  const toggleModal = () => setModalVisible(!isModelVisible);
  const getInitialValue = (): GroupsForm => {
    return {
      groups: props.user.groups.map((u) => u.id),
    };
  };

  const resetForm = (value: GroupsForm, action: FormikActions<GroupsForm>) => {
    action.resetForm(getInitialValue());
    toggleModal();
  };

  const submit = (value: GroupsForm, action: FormikActions<GroupsForm>) => {
    action.setSubmitting(true);
    const groups = props.groups.filter((g) => value.groups.includes(g.id));
    props.reassignGroups(props.user, groups);
    resetForm(value, action);
    action.setSubmitting(false);
  };

  const groupOptions = props.groups.map((u) => {
    return { label: u.name, value: u.id };
  });
  return (
    <>
      <Button color={'link'} onClick={toggleModal}>
        <i className="fa fa-edit ml-1" />
      </Button>
      <Formik<GroupsForm>
        initialValues={getInitialValue()}
        onSubmit={submit}
        onReset={resetForm}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps: FormikProps<GroupsForm>) => {
          const handleChange = (value: string) => {
            // console.log('hcv', value);
            const checked = formikProps.values.groups;
            if (checked.includes(value)) {
              checked.splice(checked.indexOf(value), 1);
            } else {
              checked.push(value);
            }
            formikProps.handleChange({
              target: { name: 'groups', value: checked },
            });
          };
          return (
            <Modal size={'lg'} toggle={toggleModal} isOpen={isModelVisible}>
              <ModalHeader toggle={toggleModal}>
                Assign Users To Groups
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={8}>
                    <CheckBox
                      name={'group'}
                      options={groupOptions}
                      error={
                        formikProps.errors.groups &&
                        formikProps.errors.groups.join(' ')
                      }
                      CheckedValues={formikProps.values.groups}
                      onChange={handleChange}
                      hideSelectAll={true}
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <Button
                  color={'primary'}
                  onClick={() => formikProps.handleSubmit()}
                >
                  Submit
                </Button>
                <Button
                  color={'primary'}
                  className="ml-3"
                  outline={true}
                  onClick={formikProps.handleReset}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}
