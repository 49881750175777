import React, { useMemo } from 'react';
import Select, {
  ActionMeta,
  createFilter,
  InputActionMeta,
} from 'react-select';
import { Col, InputGroup, Label, Row } from 'reactstrap';
import './forminputs.style.css';
import InputCommonProps from './index.component';
import Tooltip from './shared/tooltip.component';
import ExternalLink from './shared/external-link.component';
import RefreshHandler from './shared/refresh-handler.component';

interface InputTextProps extends InputCommonProps {
  options: {
    label: string;
    value?: any;
    options?: { label: string; value: string }[];
  }[];
  isRefreshing?: boolean;
  onInputChange?: (value: string, actionMeta: InputActionMeta) => void;

  onRefresh?(data: any): void;
}

const MultiSelect = (props: InputTextProps) => {
  const {
    id,
    label,
    error,
    tooltip,
    externalLink,
    onChange,
    value = [],
    name,
    // If options is undefined treat as empty
    options = [],
    isRefreshing,
    onRefresh,
    readOnly,
    onInputChange,
  } = props;

  const invalid = !!(error && error.length);
  const infoWithError = !invalid ? 'link-without-error' : '';
  const showRefreshBtn = !!(isRefreshing === true || isRefreshing === false);
  const infoAvailable =
    tooltip && tooltip.length ? 'input-with-info-refersh' : '';
  const setBorderInvalid = invalid ? 'multi-select-invalid' : '';

  const valueOptions = useMemo(
    () =>
      options
        .reduce((acc, item) => {
          if (item.options) {
            return acc.concat(item.options);
          } else {
            return acc.concat(item);
          }
        }, [])
        .filter((v) => value.includes(v.value)),
    [value, options],
  );

  return (
    <Row noGutters>
      <Col sm={12}>{label && <Label for={id}>{label}</Label>}</Col>
      <Col sm={showRefreshBtn ? 10 : 12}>
        <Row noGutters>
          <Col sm={12}>
            <InputGroup className="multi-select-flex">
              <Select
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
                id={id}
                value={valueOptions}
                isMulti
                name={name}
                options={options}
                onChange={onChange}
                filterOption={createFilter({ ignoreAccents: false })}
                className={`basic-multi-select ${setBorderInvalid +
                  ' ' +
                  infoAvailable}`}
                classNamePrefix="select"
                invalid={invalid}
                isDisabled={readOnly}
                onInputChange={onInputChange}
              />
              {tooltip && <Tooltip info={tooltip} invalid={invalid} />}
            </InputGroup>
            {invalid && <small className="text-red">{error}</small>}
          </Col>
          <Col>
            {externalLink && (
              <ExternalLink
                links={externalLink}
                className={`right-0-9 ${infoWithError}`}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col sm="1" className="refresh-col ml-1">
        {showRefreshBtn && (
          <RefreshHandler
            isRefreshing={!!isRefreshing}
            onRefresh={(e: any) => {
              onRefresh && onRefresh(e);
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default MultiSelect;

export function MultiSelectV2(props: InputTextProps) {
  const handleChange = (value: any[]) => {
    const strings = value ? value.map((u) => u.value) : [];
    props.onChange({ target: { name: props.name, value: strings } });
  };

  return <MultiSelect {...props} onChange={handleChange} />;
}
