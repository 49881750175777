import { IUserNode } from 'workflow-model/dist/types/IUserNode';

export interface UserCreatedNode extends IUserNode {}

// events
export const ListUserCreatedNodeStarted = 'ListUserCreatedNodeStarted';
export const ListUserCreatedNodeCompleted = 'ListUserCreatedNodeCompleted';
export const ModifyUserCreatedNodeStarted = 'ModifyUserCreatedNodeStarted';
export const ModifyUserCreatedNodeCompleted = 'ModifyUserCreatedNodeCompleted';

interface ListUserCreatedNodeStarted {
  type: typeof ListUserCreatedNodeStarted;
}

interface ListUserCreatedNodeCompleted {
  type: typeof ListUserCreatedNodeCompleted;
  data: UserCreatedNode[] | null;
}

interface ModifyUserCreatedNodeStarted {
  type: typeof ModifyUserCreatedNodeStarted;
}

interface ModifyUserCreatedNodeCompleted {
  type: typeof ModifyUserCreatedNodeCompleted;
}

export type UserCreatedNodeAction =
  | ListUserCreatedNodeStarted
  | ListUserCreatedNodeCompleted
  | ModifyUserCreatedNodeStarted
  | ModifyUserCreatedNodeCompleted;

export interface UserCreatedNodeState {
  fetching: boolean;
  initialised: boolean;
  data: UserCreatedNode[];
}
