import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../../design/colors';
import fonts from '../../design/fonts';

const SlideUp = keyframes`
  100% {
    bottom: 0px;
  }
`;
export const StyledSnackbar = styled.div<{
  open: boolean;
  backgroundColor: boolean | string;
}>`
  display: ${(props: any) => (props.open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor : '#000'};
  color: ${colors.palette.white};
  font-size: ${fonts.size.content.regular};
  font-family: ${fonts.family.content};
  font-weight: ${fonts.weight.heading.bold};
  padding: 1rem;
  position: fixed;
  bottom: -50px;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 400px;
  border-radius: 4px;
  animation: ${SlideUp} 1s forwards;
  z-index: 999999;
`;
export const SNACKBAR_TIMEOUT_DURATION = 5000;
export default class SnackbarComponent extends React.Component<
  {
    message: string;
    type: 'success' | 'error' | 'default';
    onHide?: Function;
  },
  { showSnackbar: boolean }
> {
  state = { showSnackbar: true };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showSnackbar: false });
      this.props.onHide && this.props.onHide();
    }, SNACKBAR_TIMEOUT_DURATION);
  }

  render() {
    const { message, type } = this.props;
    const { showSnackbar } = this.state;
    if (!message || !message.trim()) return null;
    return (
      <StyledSnackbar
        open={showSnackbar}
        backgroundColor={
          type === 'success'
            ? colors.palette.success
            : type === 'error'
            ? colors.palette.error
            : colors.palette.bgPrimary
        }
      >
        {message}
      </StyledSnackbar>
    );
  }
}
