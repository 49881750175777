import { Reducer } from 'redux';
import { SGCTypeAction, SGCTypeState } from './types';

const initialState: SGCTypeState = {
  sgcType: {},
};

export const RequestJSON = 'RequestJSON';
export const RequestJSONSuccess = 'RequestJSONSuccess';

export const SGCTypeReducer: Reducer<SGCTypeState, SGCTypeAction> = (
  state = initialState,
  action,
): SGCTypeState => {
  switch (action.type) {
    case 'RequestType':
      return {
        ...state,
        sgcType: {
          ...state.sgcType,
          [action.path]: {
            fetching: true,
            data: state.sgcType[action.path]
              ? state.sgcType[action.path].data
              : null,
          },
        },
      };
    case 'RequestTypeSuccess':
      return {
        ...state,
        sgcType: {
          ...state.sgcType,
          [action.path]: {
            fetching: false,
            data: action.data
              ? action.data
              : state.sgcType[action.path]
              ? state.sgcType[action.path].data
              : null,
          },
        },
      };
    default:
      return state;
  }
};
