import * as React from 'react';
import { InputGroupAddon, UncontrolledTooltip } from 'reactstrap';

interface TooltipProps {
  info?: string;
  place?: string;
  invalid?: boolean;
  raw?: JSX.Element;
}

const Tooltip = (props: TooltipProps) => {
  const { info, place, invalid } = props;

  const placement: any = place || 'top';
  const isInvalid = invalid ? 'tooltip-invalid' : '';
  const tooltipId = `tooltip-${Math.floor(
    Math.random() * Math.floor(Date.now()),
  )}`;

  if (props.raw) {
    return <InputGroupAddon addonType="append">{props.raw}</InputGroupAddon>;
  }

  return (
    <InputGroupAddon addonType="prepend" className={isInvalid}>
      <span className="input-group-text">
        <i
          data-placement={placement}
          id={tooltipId}
          className="fa fa-info-circle"
          aria-hidden="true"
        />
      </span>
      <UncontrolledTooltip delay={0} placement={placement} target={tooltipId}>
        {info}
      </UncontrolledTooltip>
    </InputGroupAddon>
  );
};

export default Tooltip;
