import {
  FilterType,
  IResolver,
  IWorkflow,
  NODE_TYPES,
  ScheduleType,
  SerializedActionNodeData,
  SerializedMonitoringNodeData,
  Workflow,
} from 'workflow-model/dist';
import {
  ActionNode,
  FilterNode,
  MonitoringNode,
  ResourceNode,
  TriggerNode,
} from 'workflow-model/dist/nodes';
import { WORKFLOW_ACTION } from 'workflow-model/dist/types/IGroupWorkflowModel';

export const functionFilterNodeId = 'node-a55bfaa1-filter';

let defaultFunctionFilterValue =
  '' +
  'let threshold = THRESHOLD;\n' +
  '  if($.addOns && $.addOns.MetricData && $.addOns.MetricData.data[0] && $.addOns.MetricData.data[0].Values){\n' +
  '    let values = $.addOns.MetricData.data[0].Values;\n' +
  '    return values.filter(x => x < threshold).length == values.length;\n' +
  '  }\n' +
  '\treturn false;';

export function getThresholdFromFunctionValue(value?: string): string {
  if (!value) return '0.5';
  let match = value.match(/let threshold = ([0-9]*\.?[0-9]*);\n/);
  return match && match[1];
}

export function getFunctionValueFromThreshold(threshold: string = '0.5') {
  return defaultFunctionFilterValue.replace('THRESHOLD', threshold);
}

export const getDefaultActionNode = (action: WORKFLOW_ACTION): ActionNode => {
  let actionNode: ActionNode = new ActionNode();
  let actionNodeData: SerializedActionNodeData = {
    service: 'EC2',
    method:
      action === WORKFLOW_ACTION.START ? 'startInstances' : 'stopInstances',
    params: {
      InstanceIds: ['obj.InstanceId'],
      Hibernate: '<optional>',
      Force: '<optional>',
    },
    waitForAction: '',
  };
  actionNode.setNodeData(actionNodeData);
  return actionNode;
};

const getDefaultTriggerNode = (): TriggerNode => {
  let triggerNode: TriggerNode = new TriggerNode();
  triggerNode.setNodeData({
    alarm: {
      active: false,
    },
    event: {
      active: false,
      value: {
        pattern: {},
        eventIds: [],
      },
    },
    http: {
      active: false,
      value: {},
    },
    schedule: {
      active: true,
      value: [
        {
          type: ScheduleType.RATE,
          value: 'rate(15 minutes)',
        },
      ],
    },
  });
  return triggerNode;
};

/*
  set id of this node to a const id so that we can find it easily
 */
const getDefaultFunctionFilterNode = (): FilterNode => {
  let functionFilterNode = new FilterNode({
    id: functionFilterNodeId,
    type: NODE_TYPES.FILTER,
  });
  functionFilterNode.setNodeData({
    conditions: [
      {
        id: 'c1',
        type: FilterType.FUNCTION,
        key: '',
        value: getFunctionValueFromThreshold(),
        operator: '',
      },
    ],
    expression: '( (c1) )',
  });
  return functionFilterNode;
};

const getDefaultMonitoringNode = (): MonitoringNode => {
  let monitoringNode: MonitoringNode = new MonitoringNode();
  let data: SerializedMonitoringNodeData = {
    StartTime: '-1h',
    EndTime: 'now',
    Queries: [
      {
        Namespace: 'AWS/EC2',
        MetricName: 'CPUUtilization',
        Dimensions: [
          {
            Name: 'InstanceId',
          },
        ],
        Period: '900',
        Stat: 'Average',
      },
    ],
  };
  monitoringNode.setNodeData(data);
  return monitoringNode;
};

/**
 * trigger (rate 15 mins) -> resource (ec2:instances) -> filterNode (empty)
 * -> monitoring node (duration 1 hour, period 15 min) -> filterNode (function filter)
 * -> action (ec2: stopInstances)
 * @param params
 * @param defaultNodes
 */
export const getDefaultSmartScheduleWorkflow = async (
  params: { user: string; dataResolver: IResolver },
  defaultNodes: {
    resourceNode: ResourceNode;
    filterNode: FilterNode;
    actionNode: ActionNode;
  },
): Promise<IWorkflow> => {
  let { user, dataResolver } = params;

  let { resourceNode, filterNode, actionNode } = defaultNodes;
  let smartScheduleWf = new Workflow({
    group: 'new',
    name: WORKFLOW_ACTION.SMART_SCHEDULE,
    isModelDirty: true,
    user,
    dataResolver,
  });

  let triggerNode: TriggerNode = getDefaultTriggerNode();
  let monitoringNode: MonitoringNode = getDefaultMonitoringNode();
  let functionFilterNode: FilterNode = getDefaultFunctionFilterNode();
  let parkedActionNode: ActionNode = new ActionNode({
    id: actionNode.getNodeId(),
    type: NODE_TYPES.ACTION,
  });
  parkedActionNode.setNodeData(actionNode.getNodeData());

  smartScheduleWf.addNodeAtLast(triggerNode);
  smartScheduleWf.addNodeAtLast(resourceNode);
  smartScheduleWf.addNodeAtLast(filterNode);
  smartScheduleWf.addNodeAtLast(monitoringNode);
  smartScheduleWf.addNodeAtLast(functionFilterNode);
  smartScheduleWf.addNodeAtLast(parkedActionNode);

  let avResFil = await smartScheduleWf.getAllAvailableResources(filterNode);
  filterNode.setSelectedResource(
    avResFil.find(
      (x) => x.selectedResourceId === resourceNode.getNodeId() && !x.path,
    ),
  ); // selected node as resource node

  let avResMon = await smartScheduleWf.getAllAvailableResources(monitoringNode);
  monitoringNode.setSelectedResource(
    avResMon.find(
      (x) => x.selectedResourceId === filterNode.getNodeId() && !x.path,
    ),
  ); //selected node as filter node

  let avResFun = await smartScheduleWf.getAllAvailableResources(
    functionFilterNode,
  );
  functionFilterNode.setSelectedResource(
    avResFun.find(
      (x) => x.selectedResourceId === monitoringNode.getNodeId() && !x.path,
    ),
  ); // selected node as monitoring node

  let avResAct = await smartScheduleWf.getAllAvailableResources(
    parkedActionNode,
  );
  parkedActionNode.setSelectedResource(
    avResAct.find(
      (x) => x.selectedResourceId === functionFilterNode.getNodeId() && !x.path,
    ),
  ); // selected node as function filter node

  return smartScheduleWf;
};
