import {
  DeleteWorkflowsRequest,
  DeleteWorkflowsResponse,
  FetchTemplatesRequest,
  FetchTemplatesResponse,
  MissingWebflowItem,
  RequestWebflowItemWithoutTemplate,
  ResponseWebflowItemWithoutTemplate,
  SaveTemplateRequest,
  SaveTemplateResponse,
  SyncTemplatesRequest,
  SyncTemplatesResponse,
  TemplateMap,
  TemplatesManageAction,
  ToggleTemplateModal,
  UpdateItemRequest,
  UpdateItemResponse,
} from './types';
import api from '../../../api';
import { notify } from '../../../components/common/toaster';
import { Template } from '../templates/types';

import { store } from '../index';
import { Workflow } from '../workflows/types';
import { fetchWorkflow, WorkflowResponse } from '../workflows/action';

const SendTemplateSyncRequest = function(): TemplatesManageAction {
  return {
    type: SyncTemplatesRequest,
  };
};

const SendTemplateSyncResponse = function(): TemplatesManageAction {
  return {
    type: SyncTemplatesResponse,
  };
};
const SendDeleteWorkflowsRequest = function(): TemplatesManageAction {
  return {
    type: DeleteWorkflowsRequest,
  };
};
const SendDeleteWorkflowsResponse = function(): TemplatesManageAction {
  return {
    type: DeleteWorkflowsResponse,
  };
};

const SendToggleTemplateModalRequest = function(
  template: Template,
): TemplatesManageAction {
  return {
    type: ToggleTemplateModal,
    template,
  };
};

const SendSaveTemplateRequest = function(): TemplatesManageAction {
  return {
    type: SaveTemplateRequest,
  };
};

const SendSaveTemplateResponse = function(): TemplatesManageAction {
  return {
    type: SaveTemplateResponse,
  };
};

const SendItemUpdateRequest = function(params: {
  id: string;
  col: string;
}): TemplatesManageAction {
  return {
    type: UpdateItemRequest,
    id: params.id,
    col: params.col,
  };
};

const SendItemUpdateResponse = function(params: {
  id: string;
}): TemplatesManageAction {
  return {
    type: UpdateItemResponse,
    id: params.id,
  };
};

const SendTemplateRequest = function(): TemplatesManageAction {
  return {
    type: FetchTemplatesRequest,
  };
};

const SendTemplateResponse = function(
  data: TemplateMap | null,
): TemplatesManageAction {
  return {
    type: FetchTemplatesResponse,
    data: data,
  };
};

const SendMissingWebflowRequest = function(): TemplatesManageAction {
  return {
    type: RequestWebflowItemWithoutTemplate,
  };
};

const SendMissingWebflowResponse = function(
  data: MissingWebflowItem[],
): TemplatesManageAction {
  return {
    type: ResponseWebflowItemWithoutTemplate,
    data,
  };
};

export const fetchWebflowItemsWithoutTemplates = (props: {
  dispatch: (e: any) => void;
}) => {
  let { dispatch } = props;
  dispatch(SendMissingWebflowRequest());
  api
    .getCmsWithMissingTemplates()
    .then((data) => {
      return dispatch(SendMissingWebflowResponse(data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendMissingWebflowResponse(null));
    });
};

export const fetchAllTemplates = (props: { dispatch: (e: any) => void }) => {
  let { dispatch } = props;
  dispatch(SendTemplateRequest());
  api
    .listTemplates({})
    .then((data) => {
      return dispatch(
        SendTemplateResponse(
          data.reduce((A, e) => {
            A[e.id] = e;
            return A;
          }, {}),
        ),
      );
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendTemplateResponse(null));
    });
};

export const updateTemplate = (props: {
  dispatch: (e: any) => void;
  template: Template;
  col: string;
}) => {
  let { dispatch, template, col } = props;
  dispatch(SendItemUpdateRequest({ id: template.id, col }));
  api
    .updateTemplate(template)
    .then(() => {
      let templateMap = store.getState().templatesManage.templateMap;
      templateMap[template.id] = template;
      dispatch(SendTemplateResponse(templateMap));
      dispatch(SendItemUpdateResponse({ id: template.id }));
      notify({ type: 'success', message: 'Updated Successfully' });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendItemUpdateResponse({ id: template.id }));
    });
};

const saveTemplateInner = async (props: {
  dispatch: (e: any) => void;
  template: Template;
  publish: boolean;
}) => {
  let { dispatch, template, publish } = props;
  let message;
  let templateMap = store.getState().templatesManage.templateMap;

  if (template.id) {
    template.properties = template.properties || { active: false };
    template.properties.webflow = template.properties.webflow || {};
    template.properties.webflow.isLatestPublished = false;
    await api.updateTemplate(template);
    message = 'Template saved successfully';
  } else {
    template = await api.createTemplate(template);
    message = 'Template saved successfully';
  }

  templateMap[template.id] = template;
  dispatch(SendTemplateResponse(templateMap));

  if (publish) {
    template = await api.publishTemplate(template);
    message = 'Template saved and publish successfully';
  }

  notify({ type: 'success', message });

  templateMap[template.id] = template;
  dispatch(SendTemplateResponse(templateMap));
  dispatch(SendSaveTemplateResponse());
  toggleTemplateModal({ dispatch, template: null });
};

export const saveTemplate = (props: {
  dispatch: (e: any) => void;
  template: Template;
  publish: boolean;
}) => {
  let { dispatch } = props;
  dispatch(SendSaveTemplateRequest());
  let templateMap = store.getState().templatesManage.templateMap;
  saveTemplateInner(props).catch((e) => {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
    return dispatch(SendSaveTemplateResponse());
  });
};

export const deleteTemplate = (props: {
  dispatch: (e: any) => void;
  template: Template;
  col: string;
}) => {
  let { dispatch, template, col } = props;
  dispatch(SendItemUpdateRequest({ id: template.id, col }));
  api
    .deleteTemplate(template)
    .then(() => {
      let templateMap = store.getState().templatesManage.templateMap;
      delete templateMap[template.id];
      dispatch(SendTemplateResponse(templateMap));
      dispatch(SendItemUpdateResponse({ id: template.id }));
      notify({ type: 'success', message: 'Template Deleted Successfully' });
      fetchWebflowItemsWithoutTemplates({ dispatch });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendItemUpdateResponse({ id: template.id }));
    });
};

export const toggleWorkflowStatus = (props: {
  dispatch: (e: any) => void;
  workflow: Workflow;
  col: string;
}) => {
  let { dispatch, workflow, col } = props;
  dispatch(SendItemUpdateRequest({ id: workflow.workflow, col }));

  let workflows = store.getState().workflows.data;

  let target = {
    workflow: workflow.workflow,
    properties: { active: !workflow.properties.active },
  };
  api
    .editWorkflow(target)
    .then(() => {
      // find index and update that in store as well.
      let index = workflows.findIndex((x) => x.workflow === workflow.workflow);
      workflows[index].properties.active = !workflows[index].properties.active;
      dispatch(WorkflowResponse(workflows));
      dispatch(SendItemUpdateResponse({ id: workflow.workflow }));
      notify({ type: 'success', message: 'Workflow updated Successfully' });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendItemUpdateResponse({ id: workflow.workflow }));
    });
};

export const convertWfToTemplate = (props: {
  dispatch: (e: any) => void;
  workflow: Workflow;
  template: Template;
  col: string;
}) => {
  let { dispatch, workflow, template, col } = props;
  dispatch(SendItemUpdateRequest({ id: workflow.workflow, col }));

  api
    .convertToTemplate({
      workflow: workflow.workflow,
      template,
    })
    .then((convertedTemplate) => {
      // find index and update that in store as well.
      let templateMap = store.getState().templatesManage.templateMap;
      if (convertedTemplate.id) {
        templateMap[convertedTemplate.id] = convertedTemplate;
      } else {
        templateMap[workflow.workflow] = convertedTemplate; // if newly convertd template just store using its workflow id.
      }
      dispatch(SendTemplateResponse(templateMap));
      dispatch(SendItemUpdateResponse({ id: workflow.workflow }));
      toggleTemplateModal({ dispatch, template: convertedTemplate });
      notify({
        type: 'success',
        message:
          'Workflow successfully converted to template. Edit and Save with required fields',
      });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendItemUpdateResponse({ id: workflow.workflow }));
    });
  // convertedTemplate['isModified'] = true;
  // this.setState({ isFetching: false, template: convertedTemplate });
};

export const toggleTemplateModal = (props: {
  dispatch: (e: any) => void;
  template: Template;
}) => {
  let { dispatch, template } = props;
  dispatch(SendToggleTemplateModalRequest(template));
};

const deleteAllWorkflowsInBatch = async (props: {
  dispatch: (e: any) => void;
  workflows: string[];
}) => {
  const batchLength = 25;
  let { workflows } = props;
  for (let i = 0; i < workflows.length; i += batchLength) {
    const batch = workflows.slice(i, i + batchLength);
    await Promise.all(
      batch.map((w) => {
        return api.destroyWorkflow(w);
      }),
    );
  }
};

export const deleteAllWorkflows = (props: {
  dispatch: (e: any) => void;
  workflows: string[];
}) => {
  let { dispatch } = props;
  dispatch(SendDeleteWorkflowsRequest());
  deleteAllWorkflowsInBatch(props)
    .then(() => {
      dispatch(SendDeleteWorkflowsResponse());
      notify({ type: 'success', message: 'Workflows deleted Successfully' });
      fetchAllTemplates({ dispatch });
      fetchWorkflow({ dispatch });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendDeleteWorkflowsResponse());
    });
};

export const syncTemplatesFromProdAction = (props: {
  dispatch: (e: any) => void;
}) => {
  let { dispatch } = props;
  dispatch(SendTemplateSyncRequest());
  api
    .syncDevTemplatesFromProd()
    .then(() => {
      dispatch(SendTemplateSyncResponse());
      notify({
        type: 'success',
        message: 'Successfully updated dev templates',
      });
      fetchAllTemplates({ dispatch });
      fetchWorkflow({ dispatch });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendTemplateSyncResponse());
    });
};
