import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import React from 'react';
import InputSelect from '../../../lib/formInputs/select-option.component';
import CustomRadioButtons, {
  ORIENTATION,
} from '../../../lib/formInputs/radio-button.component';
import { Formik, FormikActions, FormikProps } from 'formik';
import { RegionAndCredsComponent } from '../../common/RegionAndCredsComponent';
import { SwitchStatus } from '../style';
import SwitchContainer from '../../../lib/switch/SwitchContainer';
import {
  changeFixFormSchema,
  ChangeFixFormSchema,
  viewType,
} from './changeFixFormSchema';
import { Config } from '../../../lib/store/config/types';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  adoptAndRunFix,
  runLinkedWorkflowAction,
  updateAutoRunOfLinkAction,
  updateLinkedWorkflowAction,
} from '../../../lib/store/workflows/action';
import { connect } from 'react-redux';
import { superApiCall } from '../../../lib/store/super-table/action';
import { ApiOperation } from '../../../lib/store/super-table/types';
import api from '../../../api';
import { Template } from 'workflow-model';

interface OwnProps {
  parentWorkflowId: string;
  toggleModal: (t: boolean) => void;
  templateId: string;
  templateName: string;
  linkedWorkflowId?: string;
  autoRunLinkedWorkflows: boolean;
  parentAccount: string;
  parentRegion: string;
}

interface DispatchProps {
  refetchWorkflows: (skipResetExecutionRequest?: boolean) => void;
  updateLinkedWorkflow: (props: {
    parentWorkflowId: string;
    workflow: string;
    runFix?: boolean;
  }) => void;
  adoptTemplateAndRunFix?: (props: {
    parentWorkflowId: string;
    templateId: string;
    regions: string[];
    accounts: string[];
    runFix?: boolean;
  }) => void;
  onChangeStatus: (props: {
    parentWorkflowId: string;
    status: boolean;
  }) => void;
}

interface StateProps {
  isFetchingWorkflow: boolean;
  configs: Config[];
  allWorkflows: { workflow: string; name: string; properties: any }[];
}

type IProps = OwnProps & StateProps & DispatchProps;

type IState = {
  selectedWorkflow: string;
  chooseWorkflow: boolean;
  template: Template | null;
};

class ModalForEditFixV2Inner extends React.Component<IProps, IState> {
  state: IState = {
    selectedWorkflow: '',
    chooseWorkflow: false,
    template: null,
  };

  fetchTemplate = () => {
    const { templateId } = this.props;
    if (templateId)
      api
        .getTemplate(this.props.templateId)
        .then((template) => {
          this.setState({ template: template });
        })
        .catch((err) => console.error('Template', err));
  };

  onSubmit = (values: ChangeFixFormSchema) => {
    const { parentWorkflowId, templateId } = this.props;
    const { type, selectedWorkflow, regions, accounts, runFix } = values;
    if (type == viewType.CUSTOM_WORKFLOW) {
      this.props.updateLinkedWorkflow({
        parentWorkflowId,
        workflow: selectedWorkflow,
        runFix,
      });
    } else if (type === viewType.SUGGESTED) {
      this.props.adoptTemplateAndRunFix({
        parentWorkflowId,
        templateId,
        regions,
        accounts,
        runFix,
      });
    }
    this.props.toggleModal(false);

    // console.log("onsubmit",{values})
  };

  componentDidMount() {
    if (this.props.allWorkflows.length === 0) {
      this.props.refetchWorkflows();
    }

    if (!this.state.template) {
      this.fetchTemplate();
    }
  }

  getViewType = () => {
    let { templateId, linkedWorkflowId, allWorkflows } = this.props;
    if (linkedWorkflowId) {
      let linkedWorkflow = allWorkflows.find(
        (x) => x.workflow === linkedWorkflowId,
      );

      if (
        linkedWorkflow &&
        templateId &&
        linkedWorkflow.properties &&
        linkedWorkflow.properties.templateId === templateId
      ) {
        return viewType.SUGGESTED;
      }
      return viewType.CUSTOM_WORKFLOW;
    }

    return '';
  };

  getInitialValues = (): ChangeFixFormSchema => {
    let type = this.getViewType();
    return {
      type,
      runFix: false,
      selectedWorkflow: this.props.linkedWorkflowId || '',
      regions: [this.props.parentRegion],
      accounts: [this.props.parentAccount],
      templateId: this.props.templateId,
    };
  };

  render() {
    const render = (props: FormikProps<ChangeFixFormSchema>) => {
      return (
        <FixModalForm
          {...this.props}
          {...props}
          templateName={this.state.template?.name || ''}
        />
      );
    };
    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={(
          values: ChangeFixFormSchema,
          actions: FormikActions<ChangeFixFormSchema>,
        ) => {
          this.onSubmit(values);
          actions.setSubmitting(false);
        }}
        render={render}
        validateOnChange={false}
        validationSchema={changeFixFormSchema}
      />
    );
  }
}

type FromProps = IProps & FormikProps<ChangeFixFormSchema>;

const FixModalForm = (props: FromProps) => {
  const getChooseWorkflowsLabel = () => {
    return (
      <>
        Choose Workflow ({props.allWorkflows.length})
        <i className={'fa fa-info p-0 m-0 px-2'} id={'workflows-info'} />
        <UncontrolledTooltip target={'workflows-info'}>
          This workflow can be used as a fix in only the present Account and
          Region
        </UncontrolledTooltip>
        {props.isFetchingWorkflow ? (
          <i
            style={{ fontSize: '12px' }}
            className={
              'fa loading master-tc-continuous text-primary p-0 m-0 pl-1'
            }
          />
        ) : (
          <i
            title={'Refresh'}
            onClick={() => props.refetchWorkflows(true)}
            style={{ fontSize: '12px' }}
            className={
              'fa master-tc-continuous text-primary cursor-pointer p-0 m-0 pl-1'
            }
          />
        )}
      </>
    );
  };

  const getLinkFixOptions = () => {
    if (props.templateId) {
      return [
        {
          label: `Suggested: ${props.templateName}`,
          value: viewType.SUGGESTED,
        },
        { label: getChooseWorkflowsLabel(), value: viewType.CUSTOM_WORKFLOW },
      ];
    }
    return [
      {
        label: `Suggested: Not found`,
        value: viewType.SUGGESTED,
        disabled: true,
      },
      { label: getChooseWorkflowsLabel(), value: viewType.CUSTOM_WORKFLOW },
    ];
  };

  const onChangeRegion = (regions: string[]) => {
    props.handleChange({ target: { name: 'regions', value: regions } });
  };

  const onChangeAccount = (accounts: string[]) => {
    props.handleChange({ target: { name: 'accounts', value: accounts } });
  };

  const onTypeChange = (e) => {
    if (e.target.value === viewType.CUSTOM_WORKFLOW) {
      onChangeRegion([props.parentRegion]);
      onChangeAccount([props.parentAccount]);
    }
    props.handleChange(e);
  };
  const onRunAndLinkFix = (e) => {
    props.handleChange({ target: { name: 'runFix', value: true } });
    setTimeout(props.submitForm, 10);
  };

  const onLinkFix = (e) => {
    props.handleChange({ target: { name: 'runFix', value: false } });
    setTimeout(props.submitForm, 10);
  };

  return (
    <Modal backdrop={'static'} centered={true} isOpen={true} size={'lg'}>
      <ModalHeader toggle={() => props.toggleModal(false)}>
        Fix workflow
      </ModalHeader>
      <ModalBody>
        <>
          <Row>
            <Col md={6} className={'text-warning'}>
              {!props.isFetchingWorkflow && !props.linkedWorkflowId && (
                <>
                  <i className={'fa fa-warning mx-1'} />
                  No fix selected. Choose from below options to link fix
                </>
              )}
            </Col>
            <Col md={6}>
              <div className="d-flex justify-content-end mb-2">
                <UncontrolledDropdown className={'mr-2'}>
                  <DropdownToggle
                    disabled={props.isFetchingWorkflow}
                    color={'primary'}
                    size={'sm'}
                    caret
                    outline={true}
                    className="test-new-account d-inline-block text-center w-auto overflow-visible"
                  >
                    Options
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      disabled={!props.linkedWorkflowId}
                      onClick={() => {
                        props.updateLinkedWorkflow({
                          parentWorkflowId: props.parentWorkflowId,
                          workflow: '',
                        });
                        props.toggleModal(false);
                      }}
                    >
                      Remove Fix
                    </DropdownItem>
                    <DropdownItem
                      disabled={!props.linkedWorkflowId}
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/editor/${props.linkedWorkflowId}`,
                        )
                      }
                    >
                      Edit Fix
                    </DropdownItem>
                    <DropdownItem disabled={!props.linkedWorkflowId}>
                      <div className="d-flex justify-content-between align-items-center">
                        <SwitchStatus>Auto Run Fix</SwitchStatus>
                        <div className="px-2">
                          <SwitchContainer
                            disabled={!props.linkedWorkflowId}
                            size={'small'}
                            data-test="data-switch"
                            value={props.autoRunLinkedWorkflows}
                            onChange={() => {
                              props.onChangeStatus({
                                parentWorkflowId: props.parentWorkflowId,
                                status: !props.autoRunLinkedWorkflows,
                              });
                            }}
                          />
                        </div>
                        <i id={'run-fix-status'} className={'fa fa-info '} />
                        <UncontrolledTooltip target={'run-fix-status'}>
                          Run the fix automatically, every time the Insights are
                          refreshed
                        </UncontrolledTooltip>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <Button
                  size={'sm'}
                  className="test-new-account d-inline-block text-center w-auto overflow-visible"
                  color={'primary'}
                  block={true}
                  onClick={() =>
                    window.open(`${window.location.origin}/editor`)
                  }
                >
                  Create new fix
                </Button>
              </div>
            </Col>
          </Row>

          <div className="p-3 bg-white ">
            <Row>
              <Col>
                <CustomRadioButtons
                  options={getLinkFixOptions()}
                  name={`type`}
                  value={props.values.type}
                  onChange={onTypeChange}
                  orientation={ORIENTATION.vertical}
                  error={props.errors.type}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <InputSelect
                  name={'selectedWorkflow'}
                  value={props.values.selectedWorkflow}
                  onChange={props.handleChange}
                  readOnly={props.values.type !== viewType.CUSTOM_WORKFLOW}
                  error={props.errors.selectedWorkflow}
                  options={props.allWorkflows.map((x) => ({
                    label: x.name,
                    value: x.workflow,
                  }))}
                />
              </Col>
            </Row>
            <hr />

            <Row>
              <Col md={12}>Pick account and region to link fix</Col>
              <Col md={4} className={'ml-2'}>
                <RegionAndCredsComponent
                  readOnly={props.values.type === viewType.CUSTOM_WORKFLOW}
                  direction={'up'}
                  isMultiSelect={true}
                  allowSelectAll={true}
                  configs={props.configs}
                  selectedAccount={props.values.accounts || []}
                  selectedRegion={props.values.regions || []}
                  onAccountChange={onChangeAccount}
                  onRegionChange={onChangeRegion}
                />
              </Col>
            </Row>
          </div>
        </>
      </ModalBody>

      <ModalFooter>
        <Button
          name={'link-fix'}
          color={'primary'}
          className="d-inline-block text-center w-auto overflow-visible"
          //todo disable based on option
          onClick={onLinkFix}
        >
          Link Fix
        </Button>
        <Button
          name={'link-run-fix'}
          color={'primary'}
          className="d-inline-block text-center w-auto overflow-visible"
          //todo disable based on option
          onClick={onRunAndLinkFix}
        >
          Link And Run Fix
        </Button>
        <Button
          name={'cancel-button'}
          color={'primary'}
          outline={true}
          className="d-inline-block text-center w-auto overflow-visible"
          onClick={() => {
            props.toggleModal(false);
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const mapStateToProps = (
  state: AppState,
  ownProps: OwnProps,
): StateProps => {
  return {
    isFetchingWorkflow: state.superTable.insights?.linkFixWorkflows?.loading,
    configs: state.config.data,
    allWorkflows: state.superTable.insights?.linkFixWorkflows?.data || [],
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps => {
  return {
    refetchWorkflows: (skipResetExecutionRequest: boolean | undefined) =>
      superApiCall({
        category: 'insights',
        subCategory: 'linkFixWorkflows',
        operation: ApiOperation.GET,
        dispatch,
        options: {
          region: ownProps.parentRegion,
          account: ownProps.parentAccount,
        },
      }),
    updateLinkedWorkflow: async (props) => {
      await updateLinkedWorkflowAction({ dispatch, ...props });
      if (props.runFix) {
        await runLinkedWorkflowAction({ dispatch, ...props });
      }
    },
    onChangeStatus: (props) =>
      updateAutoRunOfLinkAction({ dispatch, ...props }),
    adoptTemplateAndRunFix: (props) => adoptAndRunFix({ dispatch, ...props }),
  };
};

export const ModalForEditFixV2 = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalForEditFixV2Inner);
