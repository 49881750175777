import { Reducer } from 'redux';
import {
  CreateWorkflow,
  EditorWorkflowResponse,
  FetchReportingWorkflow,
  ReportingWfActionRequest,
  ReportWorkflowSuccess,
  WorkflowAction,
  WorkflowState,
} from './types';
import {
  UpdateItemRequest,
  UpdateItemResponse,
} from '../templates-manage/types';

const initialState: WorkflowState = {
  fetching: false,
  editorWorkflow: undefined,
  fetchingReportWf: false,
  reportingAction: false,
  data: [],
  response: null,
  policies: {},
  update: false,
  groups: {},
  itemUpdateStateMap: {},
};

export const workflowReducer: Reducer<WorkflowState, WorkflowAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case EditorWorkflowResponse:
      return { ...state, editorWorkflow: action.data, fetching: false };
    case UpdateItemRequest:
      return {
        ...state,
        itemUpdateStateMap: {
          ...state.itemUpdateStateMap,
          [action.id]: { updating: action.col },
        },
      };

    case UpdateItemResponse:
      return {
        ...state,
        itemUpdateStateMap: {
          ...state.itemUpdateStateMap,
          [action.id]: { updating: '' },
        },
      };
    case 'ReportingWfActionRequest':
      return { ...state, reportingAction: true };
    case 'ReportingWfActionResponse':
      return { ...state, reportingAction: false };
    case 'FetchReportingWorkflow':
      return { ...state, fetchingReportWf: true };
    case 'ReportWorkflowSuccess':
      return {
        ...state,
        fetchingReportWf: false,
        reportingWorkflow: action.reportingWorkflow,
      };
    case 'FetchWorkflows':
      return { ...state, fetching: true, update: false };
    case 'SetDefaultCloneResponse':
      return { ...state, fetching: false, response: null, update: false };
    case 'CloneWorkflow':
      return { ...state, fetching: true, response: null, update: false };
    case 'CloneWorkflowSuccess':
      return {
        ...state,
        fetching: false,
        response: action.response,
        update: false,
      };
    case 'CreateWorkflow':
      return { ...state, fetching: true };
    case 'WorkflowFetchSuccess':
      return {
        ...state,
        fetching: false,
        data: action.data || state.data,
        update: false,
      };
    case 'FetchWorkflowPolicy':
      return {
        ...state,
        policies: {
          ...state.policies,
          [action.workflow]: {
            ...state.policies[action.workflow],
            fetching: true,
            update: false,
          },
        },
      };
    case 'ReceivedWorkflowPolicy':
      return {
        ...state,
        policies: {
          ...state.policies,
          [action.workflow]: {
            ...state.policies[action.workflow],
            fetching: false,
            data: action.data || state.policies[action.workflow].data,
            update: false,
          },
        },
      };
    case 'WorkflowUpdateSuccess':
      return {
        ...state,
        fetching: false,
        update: true,
      };
    case 'GroupWfRequest':
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.group]: {
            ...state.groups[action.group],
            isFetching: true,
          },
        },
      };
    case 'GroupWfResponse':
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.group]: {
            ...state.groups[action.group],
            isFetching: false,
            data: action.data || state.groups[action.group].data,
          },
        },
      };
    default:
      return state;
  }
};
