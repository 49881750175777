import * as Yup from 'yup';
import { CronSchedule, ParsedCronSchedule, RateSchedule } from 'workflow-model';
import {
  baseScheduleObj,
  S1,
} from '../../../lib/editor/components/ui/nodeUI/trigger/schema';
import { testSchedule } from '../../../lib/store/parking/actions';

export const multiScheduleSchema = Yup.object().shape({
  schedule: Yup.object().shape({
    active: Yup.boolean(),
    value: Yup.array()
      .of<CronSchedule | RateSchedule | ParsedCronSchedule>(baseScheduleObj)
      .when('active', {
        is: true,
        then: Yup.array().of<CronSchedule | RateSchedule | ParsedCronSchedule>(
          S1,
        ),
        otherwise: Yup.array().of<
          CronSchedule | RateSchedule | ParsedCronSchedule
        >(baseScheduleObj),
      }),
  }),
});

export type MultiScheduleSchema = Yup.InferType<typeof multiScheduleSchema>;
