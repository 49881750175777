export const WORKFLOW_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'InActive',
};

export const EXECUTION_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Error',
};

export const STEP_EXECUTION_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
};
