import {
  FilterStep,
  ParkedActionStep,
  STEP_TYPE,
  StepType,
  UnParkedActionStep,
} from '../types';
import {
  IGroupWorkflowModel,
  WORKFLOW_ACTION,
} from 'workflow-model/dist/types/IGroupWorkflowModel';
import { IWorkflow, NODE_TYPES } from 'workflow-model/dist';
import { ActionNode, FilterNode } from 'workflow-model/dist/nodes';

export const getWorkflows = (params: {
  groupWorkflow: IGroupWorkflowModel;
}): {
  parkedWorkflow: IWorkflow;
  unParkedWorkflow: IWorkflow;
  smartScheduleWorkflow: IWorkflow;
} => {
  let { groupWorkflow } = params;
  let parkedWorkflow = groupWorkflow.getChildWorkflowByAction(
    WORKFLOW_ACTION.STOP,
  ).workflow;
  let unParkedWorkflow = groupWorkflow.getChildWorkflowByAction(
    WORKFLOW_ACTION.START,
  ).workflow;

  let smartScheduleWorkflow = groupWorkflow.getChildWorkflowByAction(
    WORKFLOW_ACTION.SMART_SCHEDULE,
  ).workflow;
  return { parkedWorkflow, unParkedWorkflow, smartScheduleWorkflow };
};

function getLastFilterNodeIdInStep(step: FilterStep) {
  return step.nodes[step.nodes.length - 1].nodeId;
}

function getLastActionNodeIdInStep(step: ParkedActionStep) {
  return step.nodes[step.nodes.length - 1].nodeId;
}

export const setSelectedResourceIdForNode = async (params: {
  selectedNodeLabel?: string;
  selectedNodeId?: string;
  nodeId: string;
  workflow: IWorkflow;
}): Promise<void> => {
  let { workflow, nodeId, selectedNodeLabel, selectedNodeId } = params;
  let node = workflow.getNodeById(nodeId);
  let allAvailableResources = await workflow.getAllAvailableResources(node);
  if (selectedNodeLabel)
    node.setSelectedResource(
      allAvailableResources.find((x) => x.label === selectedNodeLabel),
    );
  else
    node.setSelectedResource(
      allAvailableResources.find(
        (x) => x.selectedResourceId === selectedNodeId && x.path == '',
      ),
    );
};

function addFilterNodeForGroupModel(params: {
  groupWorkflow: IGroupWorkflowModel;
  step: StepType;
}) {
  let {
    parkedWorkflow,
    unParkedWorkflow,
    smartScheduleWorkflow,
  } = getWorkflows(params);

  let lastFilterNodeId: string = getLastFilterNodeIdInStep(
    params.step as FilterStep,
  );

  // get all nodes before which new node has to be added.
  let nextNodeIdInParkedWf: string = parkedWorkflow
    .getNodeById(lastFilterNodeId)
    .getNext();
  let nextNodeIdInUnParkedWf: string = unParkedWorkflow
    .getNodeById(lastFilterNodeId)
    .getNext();
  let nextNodeIdInSmartScWf: string = smartScheduleWorkflow
    .getNodeById(lastFilterNodeId)
    .getNext();

  // create new filter node with same ids for all wfs
  let newFilterNodeParkedWF: FilterNode = new FilterNode();
  let newFilterId = newFilterNodeParkedWF.getNodeId();
  let newFilterNodeUnParkedWF: FilterNode = new FilterNode({
    type: NODE_TYPES.FILTER,
    id: newFilterId,
  });
  let newFilterNodeSmartScWf: FilterNode = new FilterNode({
    type: NODE_TYPES.FILTER,
    id: newFilterId,
  });

  // add nodes in wfs
  parkedWorkflow.addNodeBefore(newFilterNodeParkedWF, nextNodeIdInParkedWf);
  unParkedWorkflow.addNodeBefore(
    newFilterNodeUnParkedWF,
    nextNodeIdInUnParkedWf,
  );
  smartScheduleWorkflow.addNodeBefore(
    newFilterNodeSmartScWf,
    nextNodeIdInSmartScWf,
  );

  // change the selected resource of new filter node to prev node
  setSelectedResourceIdForNode({
    workflow: parkedWorkflow,
    nodeId: newFilterId,
    selectedNodeId: lastFilterNodeId,
  });
  setSelectedResourceIdForNode({
    workflow: unParkedWorkflow,
    nodeId: newFilterId,
    selectedNodeId: lastFilterNodeId,
  });
  setSelectedResourceIdForNode({
    workflow: smartScheduleWorkflow,
    nodeId: newFilterId,
    selectedNodeId: lastFilterNodeId,
  });

  // change the selected resource of next node (action ) to new filter node.
  setSelectedResourceIdForNode({
    workflow: parkedWorkflow,
    nodeId: nextNodeIdInParkedWf,
    selectedNodeId: newFilterId,
  });
  setSelectedResourceIdForNode({
    workflow: unParkedWorkflow,
    nodeId: nextNodeIdInUnParkedWf,
    selectedNodeId: newFilterId,
  });
  setSelectedResourceIdForNode({
    workflow: smartScheduleWorkflow,
    nodeId: nextNodeIdInSmartScWf,
    selectedNodeId: newFilterId,
  });
}

function addParkedActionForGroupModel(params: {
  step: StepType;
  groupWorkflow: IGroupWorkflowModel;
}) {
  let { parkedWorkflow, smartScheduleWorkflow } = getWorkflows(params);

  let newActionNode: ActionNode = new ActionNode();
  let newActionNodeForSmartSc = new ActionNode({
    id: newActionNode.getNodeId(),
    type: NODE_TYPES.ACTION,
  });

  let lastParkedActionId = getLastActionNodeIdInStep(
    params.step as ParkedActionStep,
  );

  // get node before which new node has to be added.
  let nextNodeIdInParkedWf: string = parkedWorkflow
    .getNodeById(lastParkedActionId)
    .getNext();

  let nextNodeIdInSmartScWf: string = smartScheduleWorkflow
    .getNodeById(lastParkedActionId)
    .getNext();

  if (nextNodeIdInParkedWf) {
    parkedWorkflow.addNodeBefore(newActionNode, nextNodeIdInParkedWf);
    smartScheduleWorkflow.addNodeBefore(
      newActionNodeForSmartSc,
      nextNodeIdInSmartScWf,
    );
  } else {
    parkedWorkflow.addNodeAtLast(newActionNode);
    smartScheduleWorkflow.addNodeAtLast(newActionNodeForSmartSc);
  }
}

function addUnParkedActionForGroupModel(params: {
  step: StepType;
  groupWorkflow: IGroupWorkflowModel;
}) {
  let { unParkedWorkflow } = getWorkflows(params);
  let newActionNode: ActionNode = new ActionNode();

  let lastUnParkedActionId = getLastActionNodeIdInStep(
    params.step as UnParkedActionStep,
  );

  let nextNodeIdInUnParkedWf: string = unParkedWorkflow
    .getNodeById(lastUnParkedActionId)
    .getNext();
  if (nextNodeIdInUnParkedWf) {
    unParkedWorkflow.addNodeBefore(newActionNode, nextNodeIdInUnParkedWf);
  } else {
    unParkedWorkflow.addNodeAtLast(newActionNode);
  }
}
export const handleAddNewNodeInStep = (
  step: StepType,
  groupWorkflow: IGroupWorkflowModel,
): void => {
  switch (step.type) {
    case STEP_TYPE.FILTER:
      return addFilterNodeForGroupModel({ step, groupWorkflow });
    case STEP_TYPE.PARK_ACTION:
      return addParkedActionForGroupModel({ step, groupWorkflow });
    case STEP_TYPE.UN_PARK_ACTION:
      return addUnParkedActionForGroupModel({ step, groupWorkflow });
  }
};
