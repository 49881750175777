import { TableSubCategories } from './index';
import { SuperTable } from 'super-table/dist/components/Table';
import { ITableDataDef } from 'webapp-genric/dist/super-table/model/table';
import { RegionChildProps } from '../common/withRegionAndCredentials';
import LineLoader from '../common/LineLoader';
import React from 'react';
import { TableModalProps } from 'super-table/dist/components/Table/types';
import { notify } from '../common/toaster';
import 'super-table/dist/super-table.css';

interface SummaryAndDataTableWrapperProps {
  category: string;
  subCategories: string[];
  superApi: (operation: string, options?: any) => void;
  tableData: ITableDataDef;
  summaryData?: ITableDataDef;
  onFilterChange: (filters: any) => void;
  onSearchChange: (searchValues: any) => void;
  tcApi: any;
  modalRenderer: (props: TableModalProps) => JSX.Element;
  isFetchingSummary: boolean;
  isFetchingData: boolean;
}
export const SummaryAndDataTableWrapper = ({
  subCategories,
  onFilterChange,
  tcApi,
  modalRenderer,
  isFetchingSummary,
  isFetchingData,
  category,
  summaryData,
  superApi,
  tableData,
  updateConfigAndRegion,
  regions,
  credentials,
  onSearchChange,
}: SummaryAndDataTableWrapperProps & RegionChildProps) => {
  const renderDataTable = () => {
    return (
      <SuperTable
        resizable={true}
        overflow={true}
        tableId={`super-table-${category}-data`}
        onSearchChange={onSearchChange}
        superTableCategory={category}
        superTableSubCategory={TableSubCategories.TABLE_DATA}
        onFilterChange={onFilterChange}
        onRegionAndCredentialsChange={updateConfigAndRegion}
        superApi={superApi}
        superTableData={tableData}
        regions={regions}
        credentials={credentials}
        spinner={<LineLoader />}
        tcApi={tcApi}
        loading={isFetchingData}
        modalRenderer={modalRenderer}
        notify={notify}
      />
    );
  };

  const renderSummaryTable = () => {
    return (
      <SuperTable
        resizable={false}
        tableId={`super-table-${category}-summary`}
        onSearchChange={onSearchChange}
        superTableCategory={category}
        superTableSubCategory={TableSubCategories.SUMMARY_DATA}
        onFilterChange={onFilterChange}
        onRegionAndCredentialsChange={updateConfigAndRegion}
        superApi={superApi}
        superTableData={summaryData}
        regions={regions}
        credentials={credentials}
        spinner={<LineLoader />}
        tcApi={tcApi}
        loading={isFetchingSummary}
        modalRenderer={modalRenderer}
        notify={notify}
      />
    );
  };

  return (
    <React.Fragment>
      {subCategories.includes(TableSubCategories.SUMMARY_DATA) && (
        <div className={'p-4 bg-white custom-container w-100'}>
          {renderSummaryTable()}
        </div>
      )}

      {subCategories.includes(TableSubCategories.TABLE_DATA) && (
        <div className={'p-4 bg-white custom-container w-100'}>
          {renderDataTable()}
        </div>
      )}
    </React.Fragment>
  );
};
