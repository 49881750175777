import { TableItem } from '../cols';
import * as React from 'react';
import { Badge } from 'reactstrap';

export const TemplateStatus = (item: TableItem) => {
  let { PP, NV, TE } = getTemplateStatus(item);
  return (
    <>
      {TE && (
        <Badge title={'Template Exists'} color="success" pill>
          TE
        </Badge>
      )}
      {NV && (
        <Badge title={'New Version Available'} color="danger" pill>
          NV
        </Badge>
      )}
      {PP && (
        <Badge title={'Pending Publish'} color="warning" pill>
          PP
        </Badge>
      )}
    </>
  );
};

export const TemplateStatusSortValue = (item: TableItem) => {
  let { PP, NV, TE } = getTemplateStatus(item);
  return `${NV}-${PP}-${TE}`;
};

export const getTemplateStatus = (
  item: TableItem,
): { TE: boolean; NV: boolean; PP: boolean } => {
  let templateWfRevision =
    item.templateItem &&
    item.templateItem.properties &&
    item.templateItem.properties['workflowRevision'];

  let result = { TE: false, NV: false, PP: false };

  if (item.workflowItem && item.templateItem && item.templateItem.id) {
    let isSynced = templateWfRevision === item.workflowItem.revision;
    result['TE'] = true;
    result['NV'] = !isSynced;
  }

  if (item.templateItem && item.templateItem.id) {
    let isWebflowSynced =
      item.templateItem.properties &&
      item.templateItem.properties.webflow &&
      item.templateItem.properties.webflow.isLatestPublished;
    result['PP'] = !isWebflowSynced;
  }

  return result;
};
