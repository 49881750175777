import { capitalize, getTenantName } from '../../../../util/commonUtils';

export const copyToClipboard = (content: string): void => {
  if (navigator && (navigator as any).clipboard) {
    (navigator as any).clipboard.writeText(content);
  }
};

export const isLengthyArray = (item: any[]) =>
  Array.isArray(item) && item.length > 0;

export const getUniqueKey = function() {
  return Math.ceil(Math.random() * Date.now()) + Date.now();
};

export const updateTitle = () => {
  const pathArray = window.location.pathname.split('/');
  let tenant = getTenantName() + ' WebApp';
  let section = capitalize(pathArray[1]);
  let title: string = tenant;
  if (pathArray[1] !== 'dashboard' && section) {
    title = `${tenant} (${section})`;
  }
  document.title = title;
};
