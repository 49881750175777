import React from 'react';
import { CategoryList } from '../../common/list/categoryList';
import { SubCategoryList } from '../../common/list/subCategoryList';

import * as WorkflowModel from 'workflow-model/dist';
import { Category } from '../../common/categoryUtils';
import { shouldHideSubCategory } from '../../../config/policyStatusConfig';
import { TAB_NAME } from 'webapp-genric/dist/constants';
export type CategoryChildProps = {
  selectedCategory: string;
  selectedSubCategory: string;
  categories: Category[];
  updateCategoryAndSubCategory: (cat, subCat) => void;
};

type IState = {
  selectedCategory: string;
  selectedSubCategory: string;
};

export type CategoryParentProps = {
  filterCategories?: WorkflowModel.Category[];
  preSelectedCategories?: WorkflowModel.Category[];
  nonFilteringPreSelected?: { category: string; subCategory: string };
  categoriesToExclude?: WorkflowModel.Category[];
  externalCategories?: Category[];
  excludeAllSelector?: boolean;
  tabName?: TAB_NAME;
  noFullHeight?: boolean;
};

export type CategoryStateProps = {
  categories: Category[];
};

export type CategoryWrapperProps<P extends CategoryChildProps> = Omit<
  P,
  keyof CategoryChildProps
> &
  CategoryParentProps &
  CategoryStateProps;

export function withCategories<P extends CategoryChildProps>(
  Component: React.ComponentType<P>,
): React.ComponentClass<CategoryWrapperProps<P>> {
  type IProps = CategoryWrapperProps<P>;

  return class WithCategories extends React.Component<IProps, IState> {
    getCategories() {
      const external = this.props.externalCategories || [];
      const combined = this.props.categories;
      for (const category of external) {
        const existing = combined.find((c) => c.value === category.value);
        if (!existing) {
          combined.push(category);
        } else {
          for (const sub of category.subcategories) {
            if (!existing.subcategories.find((s) => s.value === sub.value)) {
              existing.subcategories.push(sub);
            }
          }
        }
      }
      // console.log('combined', combined);
      return combined;
    }

    // if filterCategories are provided then selectedCategory is first filtered category and first filtered subcategory
    state: IState = {
      selectedCategory:
        (this.props.preSelectedCategories &&
          this.props.preSelectedCategories[0] &&
          this.props.preSelectedCategories[0].category) ||
        (this.props.filterCategories &&
          this.props.filterCategories[0] &&
          this.props.filterCategories[0].category) ||
        (this.props.nonFilteringPreSelected &&
          this.props.nonFilteringPreSelected.category) ||
        this.getCategories()[0].value,
      selectedSubCategory:
        (this.props.preSelectedCategories &&
          this.props.preSelectedCategories[0] &&
          this.props.preSelectedCategories[0].subCategories &&
          this.props.preSelectedCategories[0].subCategories[0]) ||
        (this.props.filterCategories &&
          this.props.filterCategories[0] &&
          this.props.filterCategories[0].subCategories &&
          this.props.filterCategories[0].subCategories[0]) ||
        (this.props.nonFilteringPreSelected &&
          this.props.nonFilteringPreSelected.subCategory) ||
        (this.getCategories()[0].subcategories &&
          this.getCategories()[0].subcategories[0].value),
    };

    onCategoryUpdated = (cate: string, sub: string) => {
      this.setState({ selectedCategory: cate, selectedSubCategory: sub });
    };

    selectCategory = (c) => {
      const categories = this.getCategories();
      let ci = categories.find((i) => i.value === c);
      if (!ci) return;
      let sub =
        ci.subcategories && ci.subcategories[0] && ci.subcategories[0].value;
      this.onCategoryUpdated(c, sub);
    };

    selectSubCategory = (s) => {
      this.onCategoryUpdated(this.state.selectedCategory, s);
    };

    render() {
      let categories: Category[] = this.getCategories();
      if (!categories) return null;
      const { selectedCategory, selectedSubCategory } = this.state;

      const { noFullHeight } = this.props;
      const c = categories.find((i) => i.value === selectedCategory);

      // if not preselected externally
      const shouldShowCategorySelector =
        !this.props.filterCategories ||
        !this.props.filterCategories[0] ||
        !this.props.filterCategories[0].category;
      // if not preselected externally
      const shouldShowSubCategorySelector =
        !shouldHideSubCategory(this.props.tabName) &&
        c &&
        c.subcategories &&
        (!this.props.filterCategories ||
          !this.props.filterCategories[0] ||
          !this.props.filterCategories[0].subCategories ||
          !this.props.filterCategories[0].subCategories[0]);
      const shouldShowSelector =
        shouldShowCategorySelector || shouldShowSubCategorySelector;

      return (
        <div className={noFullHeight ? '' : 'vh-100'}>
          {/*render navbar */}
          {shouldShowSelector && (
            <div className="background-white rounded-lg custom-container p-1 py-3 mb-4">
              {shouldShowCategorySelector && (
                <div className="px-4 py-2">
                  <CategoryList
                    lists={categories}
                    selected={selectedCategory}
                    onSelect={this.selectCategory}
                  />
                </div>
              )}
              {shouldShowSubCategorySelector && (
                <div className="px-4 py-2 ">
                  <SubCategoryList
                    lists={c ? c.subcategories : []}
                    selected={selectedSubCategory}
                    onSelect={this.selectSubCategory}
                  />
                </div>
              )}
            </div>
          )}

          {/*render body*/}
          {
            // @ts-ignore*
            <Component
              selectedCategory={this.state.selectedCategory}
              selectedSubCategory={this.state.selectedSubCategory}
              {...this.props}
              categories={categories}
              updateCategoryAndSubCategory={this.onCategoryUpdated}
            />
          }
        </div>
      );
    }
  };
}
