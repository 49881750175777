import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as React from 'react';

export class NoConfigError extends React.Component<{ onClose: () => void }> {
  render(): React.ReactNode {
    return (
      <Modal backdrop={'static'} centered={true} isOpen={true}>
        <ModalHeader tag={'h5'}>Adopting Templates</ModalHeader>
        <ModalBody>
          <h4 className={'text-danger'}>
            You don't have any active aws account.
          </h4>
        </ModalBody>
        <ModalFooter>
          <Button color={'primary'} outline={true} onClick={this.props.onClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
