import * as React from 'react';
import { TemplatesTable } from '../components/templatesTable';
import TemplateModalContent from '../components/TemplateModalContent';
import { CATEGORIES, SUB_CATEGORIES } from '../../../config/Categories';

import { TableItem, TemplateColNames } from '../components/cols';
import { Template } from '../../../lib/store/templates/types';
import { Category } from '../../common/categoryUtils';
import { workflowFiltersOptions } from '../components/workflowFilters';

interface IProps {
  showTemplateModal: Template | null;
  savingTemplate: boolean;
  toggleTempModal: (template: Template) => void;
  onSaveTemplate: (template: Template) => void;
  onSaveAndPublish: (template: Template) => void;
  categories: Category[];
  fetchingTemplates: boolean;
  fetchingWorkflows: boolean;
  tableData: TableItem[];
  filterOptions: React.ReactNode;
  onSelect: (row: TableItem, isSelect) => void;
  onSelectAll: (isSelect, rows: TableItem[]) => void;
}

interface IState {}

export class StackWorkflows extends React.Component<IProps, IState> {
  getWorkflowsTabContent = () => {
    return (
      <TemplatesTable
        options={this.props.filterOptions}
        title={'Stack Workflows'}
        categories={this.props.categories}
        onSelect={this.props.onSelect}
        onSelectAll={this.props.onSelectAll}
        filterCategories={[]}
        fields={[
          TemplateColNames.WORKFLOW_NAME,
          TemplateColNames.CF_LAST_CREATED,
          TemplateColNames.CF_LAST_DESTROYED,
        ]}
        tableData={this.props.tableData}
        fetching={this.props.fetchingWorkflows || this.props.fetchingTemplates}
      />
    );
  };

  render() {
    return (
      <div>
        {this.props.showTemplateModal && (
          <TemplateModalContent
            isFetching={this.props.savingTemplate}
            categories={CATEGORIES}
            subCategories={SUB_CATEGORIES}
            templateDef={this.props.showTemplateModal}
            closePopup={this.props.toggleTempModal}
            onSave={this.props.onSaveTemplate}
            onSaveAndPublish={this.props.onSaveAndPublish}
          />
        )}
        {this.getWorkflowsTabContent()}
      </div>
    );
  }
}
