import JSONEditor from 'jsoneditor';
import ace, { Annotation, Editor, IEditSession } from 'brace';

import 'brace/mode/json';
import 'brace/ext/language_tools';
import 'brace/ext/searchbox';

import * as React from 'react';
import InputCommonProps from './index.component';
import { Button, Col, InputGroup, Label, Row } from 'reactstrap';
import { copyToClipboard } from '../../util/commonUtils';
import { getWordCompleter } from './codeEditor.component';

if (ace) {
  const langTools = ace.acequire('ace/ext/language_tools');
}

export type SchemaValidationError = {
  path: (string | number)[];
  message: string;
};

interface IProps extends InputCommonProps {
  showCopyControl?: boolean;
  autoCompletionList?: string[];
  onSchemaValidation?: (
    json: any,
  ) => SchemaValidationError[] | Promise<SchemaValidationError[]>;
  style?: any;
}

export class TcJsonEditor extends React.Component<IProps> {
  jsoneditor = null;
  container = null;

  handleChange = (data: any) => {
    this.props &&
      this.props.onChange &&
      this.props.onChange({ data, error: false });
  };

  handleError = (errors) => {
    if (errors && errors.length) {
      errors = errors.filter((x) => x.type !== 'customValidation');
    }
    if (!errors || errors.length === 0)
      this.props.onChange({ data: this.props.value.data, error: false });
    else {
      this.props.onChange({ data: this.props.value.data, error: true });
    }
  };

  handleCodeChange = (data: string) => {
    if (this.jsoneditor.mode === 'code') {
      try {
        let json = JSON.parse(data);
        this.handleChange(json);
      } catch (error) {}
    }
  };

  getOptions = () => {
    return {
      mode: 'tree',
      modes: ['code', 'tree'],
      ace: ace,
      onChangeText: this.handleCodeChange,
      onChangeJSON: this.handleChange,
      autocomplete: this.getAutoComplete(),
      onValidationError: this.handleError,
      onValidate: this.props.onSchemaValidation,
    };
  };
  getAutoComplete = () => {
    let { autoCompletionList } = this.props;
    return {
      caseSensitive: false,
      filter: (token, item) => {
        token = token.replace(/\.\d*\./g, '').replace('"', '');
        let prefix = token.substring(0, token.lastIndexOf('.'));
        let result =
          item.startsWith(prefix) &&
          item
            .toLowerCase()
            .includes(
              token.toLowerCase().substring(token.lastIndexOf('.') + 1),
            );
        return result;
      },
      getOptions: function() {
        return autoCompletionList || [];
      },
    };
  };

  componentDidMount() {
    this.jsoneditor = new JSONEditor(this.container, this.getOptions());
    this.jsoneditor.set(this.props.value.data);
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    let { aceEditor } = this.jsoneditor;
    if (aceEditor && !aceEditor.completers) {
      aceEditor.commands.on('afterExec', (e) => {
        // console.log("afterExec");
        if (e.command.name == 'insertstring' && /^[\w.]$/.test(e.args)) {
          aceEditor.execCommand('startAutocomplete');
        }
      });
      let staticWordCompleter = getWordCompleter(this.props.autoCompletionList);
      aceEditor.completers = [staticWordCompleter];
    }
  }

  render() {
    const {
      label,
      id,
      name,
      value,
      error,
      showCopyControl = true,
    } = this.props;
    const invalid = !!(error && error.length);
    return (
      <Row>
        <Col sm={12}>
          <InputGroup className={'row'}>
            <Col md={8}>{label && <Label for={id}>{label}</Label>}</Col>
            {showCopyControl && (
              <Col md={4} className={'text-right'}>
                <Button
                  className="test-copy-button"
                  size={'sm'}
                  color={'link'}
                  onClick={() =>
                    copyToClipboard(JSON.stringify(value.data, null, 3))
                  }
                >
                  Copy to clipboard
                </Button>
              </Col>
            )}

            <Col md={12}>
              <div
                className="jsoneditor-react-container"
                ref={(elem) => (this.container = elem)}
              />
            </Col>

            {invalid && (
              <Col>
                <small style={{ color: '#fb6340' }}>{error}</small>
              </Col>
            )}
          </InputGroup>
        </Col>
      </Row>
    );
  }
}
