import * as React from 'react';
import { ListGroup, ListGroupItem, Row, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { SidebarCol, SideBarLabel } from './styleComponents';
import { SecureRouteProps } from '../../api/tcApiHelper';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  openUserPortal,
  fetchSubscriptionInfo,
} from '../../lib/store/billing/action';
import api from '../../api';
import collapsedLogo from '../../assets/images/logo.png';
import { mainRoutes, TcRoutes } from '../../config/sidePannel';
import { SubscriptionInfo, TrialStatus } from '../../lib/store/billing/types';
import { PlanInfo } from './Planinfo';
import { getTenantName } from '../../util/commonUtils';

const midTransitionHeight = 800;
const highTransitionHeight = 950;

interface OwnProps extends SecureRouteProps {
  isOpen: boolean;
  toggle: () => void;
  routes?: TcRoutes[];
}
interface StateProps {
  isFetching: boolean;
  subscription: SubscriptionInfo;
}

interface DispatchProps {
  getPortalLink: () => void;
  fetchSubscription: () => void;
}

type IProps = OwnProps & StateProps & DispatchProps;
export class SideBarInner extends React.Component<IProps> {
  isActive = (route) => {
    return (
      this.props.location &&
      this.props.location.pathname &&
      this.props.location.pathname.startsWith(route)
    );
  };

  componentDidMount(): void {
    this.props.getPortalLink();
    this.props.fetchSubscription();
    api.updateUserIdentity().catch(console.error);
  }

  renderList() {
    let routes = this.props.routes || mainRoutes;
    return (
      <ListGroup className="w-100 h-100 d-flex flex-column justify-content-start py-3 px-1">
        {routes.map((route, key) => (
          <div
            className={classnames({
              'p-0': true,
              'border-bottom mx-1': key !== routes.length - 1,
            })}
            key={key}
          >
            {this.props.isOpen && route.name && (
              <div className="text-white-50 font-14 font-weight-400 pl-4 py-1">
                <em>
                  <small>{route.name}</small>
                </em>
              </div>
            )}
            <div className={'d-flex flex-column justify-content-around'}>
              {route.paths.map((e, key) => this.renderMenuItem(e, key))}
            </div>
          </div>
        ))}
      </ListGroup>
    );
  }

  private renderMenuItem(e, index) {
    return (
      <ListGroupItem
        className={classnames({
          'border-0 test-sidebar-links sidebar-nav-links mb-1 py-2': true,
          'bg-white': this.isActive(e.path),
        })}
        key={index}
      >
        <Link
          className={classnames({
            'text-primary': this.isActive(e.path),
            'text-white': !this.isActive(e.path),
          })}
          to={e.path}
        >
          <Row
            className={`px-2 d-flex test-sidebar-icons
                 justify-content-${!this.props.isOpen ? 'center' : 'start'} 
                 align-items-center`}
            noGutters={true}
          >
            {/* {this.renderActiveDot(e)} */}

            {/*sidebar icon*/}
            <i
              className={classnames({
                [e.icon]: true,
                'opacity-10 sidebar-responsive-icon fa-fw': true,
              })}
              id={`sidebar-icon-${e.label.replace(/\s/g, '')}`}
            />

            {/*tooltip*/}
            {!this.props.isOpen && (
              <UncontrolledTooltip
                placement="top-end"
                target={`sidebar-icon-${e.label.replace(/\s/g, '')}`}
              >
                {e.label}
              </UncontrolledTooltip>
            )}

            {/*label*/}
            {this.props.isOpen && (
              <SideBarLabel className="pl-1 font-weight-600">
                {' '}
                <div className="d-flex align-items-center">
                  {e.label.toUpperCase()}
                  {e.label === 'Settings' &&
                  this.props.subscription.status === TrialStatus.Trial ? (
                    <div className="d-flex ml-1 font-weight-900  bg-primary border-2 text-white">
                      <div className="d-flex align-items-center px-1 border-right border-white">
                        <PlanInfo subscription={this.props.subscription} />
                      </div>
                      <div className="mx-1">
                        <div>DAYS</div>
                        <div>LEFT</div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </SideBarLabel>
            )}
          </Row>
        </Link>
      </ListGroupItem>
    );
  }

  private renderActiveDot(e) {
    return (
      <span>
        <i
          className={classnames({
            'fa fa-circle font-8 pr-1 pb-2 position-absolute left-0': this.isActive(
              e.path,
            ),
            'top-3': window.screen.availHeight < highTransitionHeight,
            'top-4': window.screen.availHeight >= highTransitionHeight,
          })}
        />
      </span>
    );
  }

  render(): React.ReactNode {
    return (
      <SidebarCol
        isOpen={this.props.isOpen}
        className={
          'd-flex sidebar flex-column bg-white shadow position-fixed test-sidebar-collapsed overflow-y-scroll'
        }
      >
        <div className={'w-100 h-100'}>{this.renderList()}</div>
      </SidebarCol>
    );
  }
}
function mapStateToProps(state: AppState): StateProps {
  return {
    isFetching: state.subscription.fetching,
    subscription: state.subscription.subscription,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    getPortalLink: () => openUserPortal({ dispatch }),
    fetchSubscription: () => fetchSubscriptionInfo({ ...ownProps, dispatch }),
  };
}

export const SideBar = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SideBarInner);
