import React from 'react';
import { Route, RouteComponentProps, withRouter } from 'react-router';
import { CategoryStateProps } from '../workflows/common/withCategories';
import { Workflow } from '../../lib/store/workflows/types';
import {
  ItemUpdateStateMap,
  MissingWebflowItem,
  TemplateMap,
} from '../../lib/store/templates-manage/types';
import { Template } from '../../lib/store/templates/types';
import { ExecutionsMapType } from '../../lib/store/executions/types';
import { Config } from '../../lib/store/config/types';
import { connect } from 'react-redux';
import { AppState } from '../../lib/store';
import { extractCategories } from '../common/categoryUtils';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  fetchWorkflowExecutionInBatch,
  runBulkWorkflowAction,
  runWorkflow,
} from '../../lib/store/executions/action';
import {
  fetchGroupWorkflow,
  fetchWorkflow,
  updateWorkflow,
} from '../../lib/store/workflows/action';
import {
  convertWfToTemplate,
  deleteAllWorkflows,
  deleteTemplate,
  fetchAllTemplates,
  fetchWebflowItemsWithoutTemplates,
  saveTemplate,
  syncTemplatesFromProdAction,
  toggleTemplateModal,
  toggleWorkflowStatus,
  updateTemplate,
} from '../../lib/store/templates-manage/action';
import { fetchConfig } from '../../lib/store/config/action';
import {
  TableItem,
  TableItemFunctions,
  TemplateColNames,
} from './components/cols';
import Swal from 'sweetalert2';
import {
  filterItemsByExcludeCategory,
  filterItemsByMultipleCategories,
  getFilterCategoriesByTabName,
} from 'webapp-genric/dist/filters';
import { TemplateEditor } from './routes/templates-edit';
import {
  FilterWorkflows,
  stackWorkflowOptions,
  workflowFiltersOptions,
} from './components/workflowFilters';
import {
  FilterTemplates,
  templateFiltersOptions,
} from './components/templateFilters';
import { getTableData } from './components/formatter/tableData';
import { SectionLoader } from '../common/SectionLoader';
import { TemplateTestor } from './routes/templates-test';
import { InsightsFix } from './routes/insights-fix';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { TemplatesSummary } from './routes/summary';
import { MissingCms } from './components/MissingCms';
import { TemplateConvert } from './routes/template-convert';
import { StackWorkflows } from './routes/stack-workflows';
import { TesterWorkflow } from './routes/tester-workflow';
import { Col, Navbar, NavbarBrand } from 'reactstrap';
import { toggleSidebar } from '../../lib/store/sidebar/action';
import { Link } from 'react-router-dom';
import { getLogo_dark, getTenantName } from '../../util/commonUtils';
import { TopBar } from '../common/TopBar';

type OwnProps = {};

const TemplateTestCategory = 'tc-template-test-category';

interface StateProps extends CategoryStateProps {
  templateTestWorkflow: Workflow;
  isSyncingTemplates: boolean;
  deletingWorkflows: boolean;
  fetchingWebflow: boolean;
  webflowRecords: MissingWebflowItem[];
  savingTemplate: boolean;
  fetchingWorkflows: boolean;
  fetchingTemplates: boolean;
  isFetchingExecutions: boolean;
  templatesMap: TemplateMap;
  workflows: Workflow[];
  wfToTemplateMap: { [s: string]: Template };
  itemUpdateStateMap: ItemUpdateStateMap;
  executionsMap: ExecutionsMapType;
  showTemplateModal: Template | null;
  configs: Config[] | null;
}

interface DispatchProps {
  fetchConfig: () => void;
  updateWorkflowProperties: (workflow: string, properties: any) => void;
  runWorkflow: (workflow: string, payload: any) => void;
  runWorkflowInBulk: (workflows: string[], payload: any) => void;
  loadGroupWorkflows: (group: string) => void;
  syncTemplatesFromProd: () => void;
  fetchWeblowItems: () => void;
  fetchWorkflows: () => void;
  fetchTemplates: () => void;
  updateTemplateItem: (props: { template: Template; col: string }) => void;
  deleteTemplateItem: (props: { template: Template; col: string }) => void;
  saveTemplateItem: (props: { template: Template; publish: boolean }) => void;
  toggleWfStatus: (props: { workflow: Workflow; col: string }) => void;
  fetchExecutionsInBatch: (workflows: Workflow[]) => void;
  toggleTempModal: (template: Template) => void;
  deleteSelectedWorkflows: (selectedWorkflows: string[]) => void;
  convertWorkflowToTemplate: (props: {
    workflow: Workflow;
    template: Template;
    col: string;
  }) => void;
}

type IProps = RouteComponentProps & OwnProps & DispatchProps & StateProps;
interface IState {
  workflowSelectedFilter: string;
  templateSelectedFilter: string;
  selectedCreatedStackWfs: string[];
  selectedDestroyStackWfs: string[];
  selectedTemplatesToTest: string[];
  selectedWfsToDelete: string[];
  showTemplateTestModal: boolean;
  selectedTemplateItemId: string;
}

class WorkflowTemplateEditorInner extends React.Component<IProps, IState> {
  state = {
    workflowSelectedFilter: 'all',
    templateSelectedFilter: 'all',
    selectedCreatedStackWfs: [],
    selectedDestroyStackWfs: [],
    selectedWfsToDelete: [],
    selectedTemplatesToTest: [],
    showTemplateTestModal: false,
    selectedTemplateItemId: '',
  };
  componentDidMount(): void {
    this.props.fetchConfig();
    this.props.fetchWeblowItems();
    this.props.fetchWorkflows();
    this.props.fetchTemplates();
    this.props.loadGroupWorkflows('testing');
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    let executionsToFetch = this.props.workflows.filter(
      (x) => Object.keys(this.props.executionsMap).indexOf(x.workflow) === -1,
    );
    if (
      executionsToFetch.length > 0 &&
      !this.props.isFetchingExecutions &&
      !this.props.fetchingWorkflows
    ) {
      this.props.fetchExecutionsInBatch(executionsToFetch);
    }
  }

  updateTemplateTestSettings = (testSettings, template: Template) => {
    if (!template || !template.id) {
      return;
    }
    template.properties.testSettings = testSettings;
    this.props.updateTemplateItem({
      template,
      col: TemplateColNames.TEST_SETTING,
    });
  };

  toggleTemplateStatus = (status, template: Template) => {
    if (!template || !template.id) {
      return;
    }
    template.properties.active = status;
    this.props.updateTemplateItem({
      template,
      col: TemplateColNames.TEMPLATE_OPT,
    });
  };

  updateCategories = ({ category, subCategory, template }) => {
    template.category = category;
    template.subCategory = subCategory;
    this.props.updateTemplateItem({
      template,
      col: TemplateColNames.TEMPLATE_CATEGORY,
    });
  };

  updatePriority = (priority: number, template: Template) => {
    template.properties.priority = priority;
    this.props.updateTemplateItem({
      template,
      col: TemplateColNames.TEMPLATE_PRIORITY,
    });
  };
  updateQueueable = (queueable: boolean, template: Template) => {
    template.properties.queueable = queueable;
    this.props.updateTemplateItem({
      template,
      col: TemplateColNames.TEMPLATE_QUEUEABLE,
    });
  };

  updateLinkedTemplate = (params: {
    linkedTemplates: string[];
    template: Template;
  }) => {
    let { template, linkedTemplates } = params;
    template.properties.linkedTemplates = linkedTemplates || [];
    this.props.updateTemplateItem({
      template,
      col: TemplateColNames.LINKED_TEMPLATES,
    });
  };

  updateTemplateName = (name: string, template: Template) => {
    template.name = name;
    this.props.updateTemplateItem({
      template,
      col: TemplateColNames.TEMPLATE_NAME,
    });
  };

  handleFilter = (filter) => {
    this.setState({ workflowSelectedFilter: filter });
  };
  handleTemplateFilter = (filter) => {
    this.setState({ templateSelectedFilter: filter });
  };
  testTemplates = (params: {
    templateIds: string[];
    region: string;
    account: string;
  }) => {
    // console.log("will run wf", params,this.props.templateTestWorkflow.workflow)
    this.closeTemplateTestModal();
    this.props.runWorkflow(this.props.templateTestWorkflow.workflow, params);
  };

  getTemplateTestWorkflows = () => {
    let { workflows } = this.props;
    let leveled = workflows.map((w) => ({ ...w, ...w.properties }));
    workflows = (filterItemsByMultipleCategories(leveled, [
      {
        category: 'Custom',
        subCategories: [TemplateTestCategory],
      },
    ]) as unknown) as Workflow[];
    return workflows;
  };

  filterCustomWorkflow = (): Workflow[] => {
    let workflows = this.getTemplateTestWorkflows();
    return workflows.filter(
      (x) =>
        x.name.toLowerCase().startsWith('create_stack') ||
        x.name.toLowerCase().startsWith('destroy_stack'),
    );
  };

  getTesterWorkflows = (): Workflow[] => {
    let workflows = this.getTemplateTestWorkflows();
    return workflows.filter(
      (x) =>
        !x.name.toLowerCase().startsWith('create_stack') &&
        !x.name.toLowerCase().startsWith('destroy_stack'),
    );
  };

  getTemplateFunction = (): TableItemFunctions => {
    return {
      updateCategories: this.updateCategories,
      updateTemplatePriority: this.updatePriority,
      updateQueueable: this.updateQueueable,
      deleteTemplate: this.props.deleteTemplateItem,
      toggleTemplateStatus: this.toggleTemplateStatus,
      toggleTemplateModal: this.props.toggleTempModal,
      toggleWorkflowStatus: this.props.toggleWfStatus,
      convertWorkflowToTemplate: this.props.convertWorkflowToTemplate,
      updateTemplateName: this.updateTemplateName,
      updateLinkedTemplate: this.updateLinkedTemplate,
      updateTemplateTestSettings: this.updateTemplateTestSettings,
      getCustomWorkflows: this.filterCustomWorkflow,
      addTemplateTestCategory: this.addTemplateTestCategory,
      removeTemplateTestCategory: this.removeTemplateTestCategory,
      getTemplateTesterWorkflows: this.getTesterWorkflows,
    };
  };

  addTemplateTestCategory = (workflow: Workflow) => {
    let indexOfCustomCat = workflow.properties.categories.findIndex(
      (x) => x.category === 'Custom',
    );
    if (indexOfCustomCat === -1) {
      workflow.properties.categories.push({
        category: 'Custom',
        subCategories: [TemplateTestCategory],
      });
    } else {
      let cat = workflow.properties.categories[indexOfCustomCat];
      cat.subCategories.push(TemplateTestCategory);
      cat.subCategories = cat.subCategories.filter(
        (x, i, a) => a.indexOf(x) === i,
      );
      workflow.properties.categories[indexOfCustomCat] = cat;
    }
    this.props.updateWorkflowProperties(workflow.workflow, workflow.properties);
  };

  removeTemplateTestCategory = (workflow: Workflow) => {
    let indexOfCustomCat = workflow.properties.categories.findIndex(
      (x) => x.category === 'Custom',
    );
    if (indexOfCustomCat !== -1) {
      let cat = workflow.properties.categories[indexOfCustomCat];
      cat.subCategories = cat.subCategories.filter(
        (x) => x !== TemplateTestCategory,
      );
      workflow.properties.categories[indexOfCustomCat] = cat;
      this.props.updateWorkflowProperties(
        workflow.workflow,
        workflow.properties,
      );
    }
  };
  onSaveTemplate = async (template: Template) => {
    this.props.saveTemplateItem({ template, publish: false });
  };

  onSaveAndPublish = async (template: Template) => {
    this.props.saveTemplateItem({ template, publish: true });
  };

  testSelected = () => {
    this.setState({ showTemplateTestModal: true });
  };

  closeTemplateTestModal = () => {
    this.setState({ showTemplateTestModal: false });
  };

  deleteSelected = () => {
    let html = this.props.workflows
      .filter((x) => this.state.selectedWfsToDelete.indexOf(x.workflow) !== -1)
      .map((x, i) => {
        return `<li class="text-left" key=${i}>${x.name}</li>`;
      })
      .join('');
    Swal.fire({
      title:
        'Are you sure you want to delete ?' +
        this.state.selectedWfsToDelete.length +
        ' workflows',
      html: `<ul>
          ${html}
        </ul>`,
      type: 'warning',
      width: '30em',
      showCancelButton: true,
      confirmButtonColor: '#ad1321',
      cancelButtonColor: '#646464',
      confirmButtonText: 'Proceed',
      confirmButtonClass: 'test-delete-confirm',
      cancelButtonClass: 'test-delete-cancel',
    })
      .then((result) => {
        if (result.value) {
          this.props.deleteSelectedWorkflows(this.state.selectedWfsToDelete);
          this.setState({ selectedWfsToDelete: [] });
        }
      })
      .catch(() => {
        //  Cancel delete
      });
  };

  onSelectWfsToDelete = (row: TableItem, isSelect) => {
    let { selectedWfsToDelete } = this.state;
    if (isSelect) {
      selectedWfsToDelete.push(row.id);
    } else {
      selectedWfsToDelete = selectedWfsToDelete.filter((x) => x !== row.id);
    }
    selectedWfsToDelete = selectedWfsToDelete.filter(
      (x, i, a) => !!x && a.indexOf(x) === i,
    );

    this.setState({ selectedWfsToDelete });
  };

  onSelectAllWfsToDelete = (isSelect, rows: TableItem[]) => {
    let { selectedWfsToDelete } = this.state;
    if (isSelect) {
      selectedWfsToDelete = rows.map((x) => x.id);
    } else {
      selectedWfsToDelete = [];
    }
    selectedWfsToDelete = selectedWfsToDelete.filter(
      (x, i, a) => !!x && a.indexOf(x) === i,
    );

    this.setState({ selectedWfsToDelete });
  };

  onSelectTemplatesToTest = (row: TableItem, isSelect) => {
    let { selectedTemplatesToTest } = this.state;
    if (isSelect) {
      selectedTemplatesToTest.push(row.id);
    } else {
      selectedTemplatesToTest = selectedTemplatesToTest.filter(
        (x) => x !== row.id,
      );
    }
    selectedTemplatesToTest = selectedTemplatesToTest.filter(
      (x, i, a) => !!x && a.indexOf(x) === i,
    );
    this.setState({ selectedTemplatesToTest });
  };

  onSelectAllTemplatesToTest = (isSelect, rows: TableItem[]) => {
    let { selectedTemplatesToTest } = this.state;
    if (isSelect) {
      selectedTemplatesToTest = rows.map((x) => x.id);
    } else {
      selectedTemplatesToTest = [];
    }
    selectedTemplatesToTest = selectedTemplatesToTest.filter(
      (x, i, a) => !!x && a.indexOf(x) === i,
    );
    this.setState({ selectedTemplatesToTest });
  };

  onSelectStackWfs = (row: TableItem, isSelect) => {
    let { selectedCreatedStackWfs, selectedDestroyStackWfs } = this.state;
    if (isSelect) {
      selectedCreatedStackWfs.push(row.id);
      if (row.destroyStackWf && row.destroyStackWf.workflow)
        selectedDestroyStackWfs.push(row.destroyStackWf.workflow);
    } else {
      selectedCreatedStackWfs = selectedCreatedStackWfs.filter(
        (x) => x !== row.id,
      );
      if (row.destroyStackWf && row.destroyStackWf.workflow)
        selectedDestroyStackWfs = selectedDestroyStackWfs.filter(
          (x) => x !== row.destroyStackWf.workflow,
        );
    }
    selectedCreatedStackWfs = selectedCreatedStackWfs.filter(
      (x, i, a) => !!x && a.indexOf(x) === i,
    );
    selectedDestroyStackWfs = selectedDestroyStackWfs.filter(
      (x, i, a) => !!x && a.indexOf(x) === i,
    );
    this.setState({ selectedCreatedStackWfs, selectedDestroyStackWfs });
  };

  onSelectAllStackWfs = (isSelect, rows: TableItem[]) => {
    let { selectedCreatedStackWfs, selectedDestroyStackWfs } = this.state;
    if (isSelect) {
      selectedCreatedStackWfs = rows.map((x) => x.id);
      selectedDestroyStackWfs = rows
        .filter((x) => x.destroyStackWf && x.destroyStackWf.workflow)
        .map((x) => x.destroyStackWf.workflow);
    } else {
      selectedCreatedStackWfs = [];
      selectedDestroyStackWfs = [];
    }
    selectedCreatedStackWfs = selectedCreatedStackWfs.filter(
      (x, i, a) => !!x && a.indexOf(x) === i,
    );
    selectedDestroyStackWfs = selectedDestroyStackWfs.filter(
      (x, i, a) => !!x && a.indexOf(x) === i,
    );
    this.setState({ selectedCreatedStackWfs, selectedDestroyStackWfs });
  };

  renderTemplateEditor = (allTemplatesData) => {
    let templateFilterOptions = templateFiltersOptions({
      fetchWorkflows: this.props.fetchWorkflows,
      fetchTemplates: this.props.fetchTemplates,
      selectedFilter: this.state.templateSelectedFilter,
      handleFilter: this.handleTemplateFilter,
    });
    return (
      <TemplateEditor
        {...this.props}
        tableData={allTemplatesData}
        onSaveTemplate={this.onSaveTemplate}
        onSaveAndPublish={this.onSaveAndPublish}
        templateFilter={templateFilterOptions}
      />
    );
  };

  renderTemplateTester = (allTemplatesData) => {
    let testDetailsUrl = this.props.templateTestWorkflow
      ? `/workflow/${this.props.templateTestWorkflow.workflow}/details`
      : '';

    let templateFilterOptions = templateFiltersOptions({
      fetchWorkflows: this.props.fetchWorkflows,
      fetchTemplates: this.props.fetchTemplates,
      selectedFilter: this.state.templateSelectedFilter,
      handleFilter: this.handleTemplateFilter,
      testSelected: this.testSelected,
      testDisabled:
        !this.state.selectedTemplatesToTest.length ||
        !this.props.templateTestWorkflow,
      testDetailsUrl,
    });

    return (
      <TemplateTestor
        {...this.props}
        {...this.state}
        testTemplates={this.testTemplates}
        selectedTemplateIds={this.state.selectedTemplatesToTest}
        closeTemplateTestModal={this.closeTemplateTestModal}
        tableData={allTemplatesData}
        onSaveTemplate={this.onSaveTemplate}
        onSaveAndPublish={this.onSaveAndPublish}
        templateFilter={templateFilterOptions}
        onSelectAll={this.onSelectAllTemplatesToTest}
        onSelect={this.onSelectTemplatesToTest}
      />
    );
  };

  renderTemplateConvert = (allTemplatesData) => {
    let filterOptions = workflowFiltersOptions({
      fetchWorkflows: this.props.fetchWorkflows,
      fetchTemplates: this.props.fetchTemplates,
      selectedFilter: this.state.workflowSelectedFilter,
      handleFilter: this.handleFilter,
      deleteSelected: this.deleteSelected,
      deleteDisabled: !this.state.selectedWfsToDelete.length,
    });
    return (
      <TemplateConvert
        {...this.props}
        tableData={allTemplatesData}
        onSaveTemplate={this.onSaveTemplate}
        onSaveAndPublish={this.onSaveAndPublish}
        filterOptions={filterOptions}
        onSelectAll={this.onSelectAllWfsToDelete}
        onSelect={this.onSelectWfsToDelete}
      />
    );
  };

  renderTemplateTesterWorkflows = (data: TableItem[]) => {
    let filterOptions = workflowFiltersOptions({
      fetchWorkflows: this.props.fetchWorkflows,
      fetchTemplates: this.props.fetchTemplates,
      selectedFilter: this.state.workflowSelectedFilter,
      handleFilter: this.handleFilter,
      deleteSelected: this.deleteSelected,
      deleteDisabled: !this.state.selectedWfsToDelete.length,
    });

    data = filterItemsByMultipleCategories(data, [
      { category: 'Custom', subCategories: [TemplateTestCategory] },
    ]).filter(
      (x) =>
        !x.workflowName.toLowerCase().startsWith('create_stack') &&
        !x.workflowName.toLowerCase().startsWith('destroy_stack'),
    );
    return (
      <TesterWorkflow
        {...this.props}
        tableData={data}
        filterOptions={filterOptions}
      />
    );
  };

  renderStackWorkflows = (data: TableItem[]) => {
    let filterOptions = stackWorkflowOptions({
      fetchWorkflows: this.props.fetchWorkflows,
      selectedFilter: this.state.workflowSelectedFilter,
      handleFilter: this.handleFilter,
      onCreateStack: () => {
        this.props.runWorkflowInBulk(this.state.selectedCreatedStackWfs, {});
      },
      onDestroyStack: () => {
        this.props.runWorkflowInBulk(this.state.selectedDestroyStackWfs, {});
      },
      disabledCreate: !this.state.selectedCreatedStackWfs.length,
      disabledDestroy: !this.state.selectedDestroyStackWfs.length,
    });
    data = filterItemsByMultipleCategories(data, [
      { category: 'Custom', subCategories: [TemplateTestCategory] },
    ]);
    data = data.filter((x) =>
      x.workflowName.toLowerCase().startsWith('create_stack'),
    );
    let categories = extractCategories(data).filter((x) => {
      return x.value !== 'Region' && x.value !== 'Account';
    });
    return (
      <StackWorkflows
        {...this.props}
        tableData={data}
        categories={categories}
        onSaveTemplate={this.onSaveTemplate}
        onSaveAndPublish={this.onSaveAndPublish}
        filterOptions={filterOptions}
        onSelectAll={this.onSelectAllStackWfs}
        onSelect={this.onSelectStackWfs}
      />
    );
  };

  renderInsightsFix = (allTemplatesData: TableItem[]) => {
    let templateFilterOptions = templateFiltersOptions({
      fetchWorkflows: this.props.fetchWorkflows,
      fetchTemplates: this.props.fetchTemplates,
      selectedFilter: this.state.templateSelectedFilter,
      handleFilter: this.handleTemplateFilter,
    });

    let insightsTemplates = filterItemsByMultipleCategories(
      allTemplatesData,
      getFilterCategoriesByTabName(TAB_NAME.INSIGHTS),
    );
    let categories = extractCategories(insightsTemplates);
    return (
      <InsightsFix
        {...this.props}
        categories={categories}
        tableData={insightsTemplates}
        onSaveTemplate={this.onSaveTemplate}
        onSaveAndPublish={this.onSaveAndPublish}
        templateFilter={templateFilterOptions}
      />
    );
  };

  render() {
    let workflows = FilterWorkflows({
      workflows: this.props.workflows,
      templateMap: this.props.templatesMap,
      selectedFilter: this.state.workflowSelectedFilter,
    });
    let templateMap = FilterTemplates({
      templateMap: this.props.templatesMap,
      selectedFilter: this.state.templateSelectedFilter,
    });
    let { data, allTemplatesData } = getTableData({
      templatesMap: templateMap,
      workflows: workflows,
      wfToTemplateMap: this.props.wfToTemplateMap,
      itemUpdateStateMap: this.props.itemUpdateStateMap,
      executionsMap: this.props.executionsMap,
      functions: this.getTemplateFunction(),
    });

    if (this.props.deletingWorkflows) return <SectionLoader />;

    return (
      <div className="">
        <TopBar heading={'Templates UI'} />

        <Route
          exact
          path="/wf-templates-ui/summary"
          render={() => <TemplatesSummary {...this.props} />}
        />

        <Route
          exact
          path="/wf-templates-ui"
          render={() => <TemplatesSummary {...this.props} />}
        />

        <Route
          exact
          path="/wf-templates-ui/convert-wf-template"
          render={() => this.renderTemplateConvert(data)}
        />

        <Route
          exact
          path="/wf-templates-ui/cf-stack-wfs"
          render={() => this.renderStackWorkflows(data)}
        />

        <Route
          exact
          path="/wf-templates-ui/template-tester-wfs"
          render={() => this.renderTemplateTesterWorkflows(data)}
        />

        <Route
          exact
          path="/wf-templates-ui/edit-template"
          render={() => this.renderTemplateEditor(allTemplatesData)}
        />

        <Route
          exact
          path="/wf-templates-ui/test-template"
          render={() => this.renderTemplateTester(allTemplatesData)}
        />

        <Route
          exact
          path="/wf-templates-ui/insights-fixes"
          render={() => this.renderInsightsFix(allTemplatesData)}
        />

        <Route
          exact
          path="/wf-templates-ui/webflow"
          render={() => (
            <MissingCms
              fetching={this.props.fetchingWebflow}
              webflowRecords={this.props.webflowRecords}
            />
          )}
        />
      </div>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  let wfToTemplateMap = {};
  let templateMap = state.templatesManage.templateMap;
  for (let key in state.templatesManage.templateMap) {
    let wfId =
      templateMap[key].properties && templateMap[key].properties.originWorkflow;
    if (wfId) {
      wfToTemplateMap[wfId] = templateMap[key];
    }
  }

  let leveled = state.workflows.data.map((w) => ({ ...w, ...w.properties }));
  let templates = Object.keys(templateMap).map((x) => templateMap[x]);
  const withTemplate = [...leveled, ...templates];
  let categories = extractCategories(withTemplate) || [];
  categories = categories.filter((x) => {
    return x.value !== 'Region' && x.value !== 'Account';
  });
  let testWorkflows =
    state.workflows.groups &&
    state.workflows.groups['testing'] &&
    state.workflows.groups['testing'].data;
  let templateTestWorkflow =
    testWorkflows &&
    testWorkflows.find((x) => x.name === 'templates test parent workflow');

  return {
    isSyncingTemplates: state.templatesManage.isSyncingTemplates,
    fetchingTemplates: state.templatesManage.fetchingTemplates,
    fetchingWorkflows: state.workflows.fetching,
    templatesMap: state.templatesManage.templateMap,
    workflows: state.workflows.data,
    wfToTemplateMap,
    savingTemplate: state.templatesManage.isSavingTemplate,
    itemUpdateStateMap: state.templatesManage.itemUpdateStateMap,
    isFetchingExecutions: state.execution.batchFetching,
    executionsMap: state.execution.workflowExecutions,
    showTemplateModal: state.templatesManage.showTemplateModal,
    categories,
    fetchingWebflow: state.templatesManage.fetchingWebflowItems,
    webflowRecords: state.templatesManage.webflowWithoutTemplate,
    deletingWorkflows: state.templatesManage.isDeletingWorkflows,
    templateTestWorkflow,
    configs: state.config.data,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    updateWorkflowProperties: (workflow: string, properties: any) =>
      updateWorkflow({ dispatch, workflow, properties }),
    runWorkflowInBulk: (workflows: string[], payload: any) =>
      runBulkWorkflowAction({ dispatch, workflows, payload }),
    runWorkflow: (workflow: string, payload: any) => {
      runWorkflow({ dispatch, workflow, payload });
    },
    loadGroupWorkflows: (group) => fetchGroupWorkflow({ dispatch, group }),
    fetchTemplates: () => fetchAllTemplates({ dispatch }),
    fetchWorkflows: () => fetchWorkflow({ dispatch }),
    deleteTemplateItem: (props: { template: Template; col: string }) =>
      deleteTemplate({ dispatch, ...props }),
    updateTemplateItem: (props: { template: Template; col: string }) =>
      updateTemplate({ dispatch, ...props }),
    saveTemplateItem: (props: { template: Template; publish: boolean }) =>
      saveTemplate({ dispatch, ...props }),
    toggleWfStatus: (props: { workflow: Workflow; col: string }) =>
      toggleWorkflowStatus({ dispatch, ...props }),
    toggleTempModal: (template: Template) =>
      toggleTemplateModal({ dispatch, template }),
    convertWorkflowToTemplate: (props: {
      workflow: Workflow;
      template: Template;
      col: string;
    }) => convertWfToTemplate({ dispatch, ...props }),
    deleteSelectedWorkflows: (workflows: string[]) =>
      deleteAllWorkflows({ dispatch, workflows }),
    fetchExecutionsInBatch: (workflows: Workflow[]) =>
      fetchWorkflowExecutionInBatch({
        dispatch,
        workflows,
      }),
    fetchConfig: () => fetchConfig({ dispatch }),
    fetchWeblowItems: () => fetchWebflowItemsWithoutTemplates({ dispatch }),
    syncTemplatesFromProd: () => syncTemplatesFromProdAction({ dispatch }),
  };
}

export const WorkflowTemplateEditorWithConnect = connect<
  StateProps,
  DispatchProps,
  OwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(WorkflowTemplateEditorInner);

export const WorkflowTemplateEditor = withRouter<
  RouteComponentProps,
  typeof WorkflowTemplateEditorWithConnect
>(WorkflowTemplateEditorWithConnect);
