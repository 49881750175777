import * as React from 'react';
import CustomTable from '../../common/table';
import { getCols, TableItem, TemplateColNames } from './cols';
import {
  CategoryChildProps,
  withCategories,
} from '../../workflows/common/withCategories';
import LineLoader from '../../common/LineLoader';
import { Col, Row } from 'reactstrap';
import { filterItemsByMultipleCategories } from 'webapp-genric/dist/filters';

type OwnProps = {
  options?: React.ReactNode;
  title: string;
  tableData: TableItem[];
  fetching;
  fields: string[];
  onSelect?: (row, isSelect) => void;
  onSelectAll?: (isSelect, rows) => void;
};

type IProps = CategoryChildProps & OwnProps;
class TemplatesTableInner extends React.Component<IProps> {
  beforeSaveCell(oldValue, newValue, row: TableItem, column) {
    if (column.colName === TemplateColNames.LINKED_TEMPLATES) {
      let linkedTemplates =
        newValue.target && newValue.target.value ? [newValue.target.value] : [];
      row.functions.updateLinkedTemplate({
        linkedTemplates,
        template: row.templateItem,
      });
    }
    if (column.colName === TemplateColNames.TEMPLATE_QUEUEABLE) {
      if (!row.templateItem) return;
      newValue = newValue === true || newValue === 'true';
      if (newValue !== oldValue)
        row.functions.updateQueueable(newValue, row.templateItem);
    }
    if (column.colName === TemplateColNames.TEMPLATE_PRIORITY) {
      newValue = Number.parseInt(newValue) || 0;
      if (newValue !== oldValue)
        row.functions.updateTemplatePriority(newValue, row.templateItem);
    }
    if (column.colName === TemplateColNames.TEMPLATE_NAME) {
      row.functions.updateTemplateName(newValue, row.templateItem);
    }
  }

  filterWithCategory = () => {
    let { tableData } = this.props;
    if (!tableData) return tableData;
    if (!Array.isArray(tableData)) return tableData;
    return filterItemsByMultipleCategories(tableData, [
      {
        category: this.props.selectedCategory,
        subCategories: [].concat(this.props.selectedSubCategory),
      },
    ]);
  };

  render() {
    let { fetching } = this.props;
    let tableData = this.filterWithCategory();
    let selectRow;
    if (this.props.onSelect) {
      selectRow = {
        mode: 'checkbox',
        onSelect: this.props.onSelect,
        onSelectAll: this.props.onSelectAll,
        clickToSelect: false,
        clickToEdit: true,
        hideSelectAll: false,
      };
    }
    return (
      <Row>
        <Col md={12}>
          <h4>
            {this.props.title} ({tableData ? tableData.length : 0})
          </h4>
          {fetching && <LineLoader />}
          <div className={'bg-white custom-container p-3'}>
            <CustomTable
              options={this.props.options}
              maxHeight={'400px'}
              emptyText={'0 Items found'}
              data={tableData}
              columns={getCols(this.props.fields)}
              selectRow={selectRow}
              cellEditFactory={{
                mode: 'click',
                blurToSave: true,
                beforeSaveCell: this.beforeSaveCell.bind(this),
              }}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

export const TemplatesTable = withCategories(TemplatesTableInner);
