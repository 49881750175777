import * as Yup from 'yup';
import { NotificationType, ScheduleType } from 'workflow-model/dist';
import { multiScheduleSchema } from '../../common/MultiScheduleForm/schema';

export const reportingFormSchema = Yup.object({
  category: Yup.string().nullable(),
  subCategory: Yup.string().nullable(),
  tabName: Yup.string(),
  id: Yup.string(),
  group: Yup.string().required(),
  type: Yup.mixed<NotificationType>().required(
    'Please select one notification type',
  ),
  notifyEmail: Yup.string().when('type', {
    is: NotificationType.EMAIL,
    then: Yup.string()
      .email('Please enter valid email')
      .required('Please provide email'),
    otherwise: Yup.string(),
  }),
  slackConfig: Yup.string().when('type', {
    is: NotificationType.SLACK,
    then: Yup.string().required('Please select one option'),
    otherwise: Yup.string(),
  }),
});

export const multiReportingFormSchema = reportingFormSchema.concat(
  multiScheduleSchema,
);

export type ReportingFormSchema = Yup.InferType<typeof reportingFormSchema>;
export type MultiReportingFormSchema = Yup.InferType<
  typeof multiReportingFormSchema
>;

const data = {
  tabName: 'abhis',
  id: 'abhishek',
  type: 'slack',
  slackConfig: 'sdffs',
  group: 'now',
  schedule: {
    active: true,
    value: [
      {
        type: ScheduleType.RATE,
        value: '',
      },
    ],
  },
};

// console.log(multiReportingFormSchema.validateSync(data));
