import React from 'react';
import { StyledCustomDropDown, StyledOptionCol } from '../../workflows/style';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { Workflow } from '../../../lib/store/workflows/types';
import { ExecutionsMapType } from '../../../lib/store/executions/types';
import { TemplateMap } from '../../../lib/store/templates-manage/types';
import { Link } from 'react-router-dom';

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

type IProps = {
  fetchWorkflows: () => void;
  fetchTemplates: () => void;
  selectedFilter: string;
  handleFilter: (value) => void;
  testSelected?: () => void;
  testDisabled?: boolean;
  testDetailsUrl?: string;
};

export const templateFiltersOptions = (props: IProps) => {
  return (
    <>
      <StyledOptionCol
        className={
          'px-3 d-flex align-self-center justify-content-around text-right text-primary'
        }
        md={{ size: 4 }}
      >
        {props.testSelected && (
          <Button
            title={'Test selected workflows'}
            color={'link'}
            className={'p-0 m-0'}
            onClick={props.testSelected}
            disabled={props.testDisabled}
          >
            Test Selected
          </Button>
        )}
        {props.testDetailsUrl && (
          <>
            <Link
              to={props.testDetailsUrl}
              target={'_blank'}
              id={'test-details-url'}
            >
              <i className={'fa fa fa-external-link ml-1'} aria-hidden="true" />
            </Link>
            <UncontrolledTooltip target={'test-details-url'} delay={0}>
              Template Test Details
            </UncontrolledTooltip>
          </>
        )}
        <Button
          color={'link'}
          className={'p-0 m-0'}
          onClick={() => {
            props.fetchWorkflows();
            props.fetchTemplates();
          }}
        >
          {' '}
          <i className={'fa master-tc-continuous text-primary'}> </i>{' '}
        </Button>
        <StyledCustomDropDown
          name="filter-workflow"
          value={props.selectedFilter}
          options={filterOptions}
          icon={
            props.selectedFilter === 'all'
              ? 'fa master-tc-filter test-filter-button'
              : 'navIcon-filter-applied test-filter-selected'
          }
          onChange={props.handleFilter}
          right
        />
      </StyledOptionCol>
    </>
  );
};

interface FilterProps {
  templateMap: TemplateMap;
  selectedFilter: string;
}

export function FilterTemplates(props: FilterProps) {
  let { templateMap, selectedFilter } = props;
  let map = {};
  switch (selectedFilter) {
    case 'all':
      return templateMap;
    case 'active': {
      for (let key in templateMap) {
        if (templateMap[key].properties.active) map[key] = templateMap[key];
      }
      return map;
    }

    case 'inactive': {
      for (let key in templateMap) {
        if (!templateMap[key].properties.active) map[key] = templateMap[key];
      }
      return map;
    }
    default:
      return templateMap;
  }
}
