import { Execution } from '../executions/types';
import { SEVERITY } from 'workflow-model/dist';
import { ItemUpdateStateMap } from '../templates-manage/types';

export type WorkflowProperties = {
  linkedTemplates?: string[];
  linkedWorkflows?: string[];
  autoRunLinkedWorkflows?: boolean;
  templateId?: string;
  severity?: SEVERITY;
  priority?: number;
  description?: string;
  active: boolean;
  queueable?: boolean;
  categories: {
    category: string;
    subCategories: string[];
  }[];
};

export interface Workflow {
  billingType: string;
  createdAt: string;
  name: string;
  updatedAt: string;
  workflow: string;
  revision?: string;
  group?: string;
  properties: WorkflowProperties;
  definition: string;
  executions?: Execution[];
}

// events
export const FetchWorkflows = 'FetchWorkflows';
export const SetDefaultCloneResponse = 'SetDefaultCloneResponse';
export const CloneWorkflow = 'CloneWorkflow';
export const CloneWorkflowSuccess = 'CloneWorkflowSuccess';
export const WorkflowFetchSuccess = 'WorkflowFetchSuccess';
export const FetchWorkflowPolicy = 'FetchWorkflowPolicy';
export const ReceivedWorkflowPolicy = 'ReceivedWorkflowPolicy';
export const CreateWorkflow = 'CreateWorkflow';
export const CreateWorkflowSuccess = 'CreateWorkflowSuccess';
export const WorkflowUpdateSuccess = 'WorkflowUpdateSuccess';
export const FetchReportingWorkflow = 'FetchReportingWorkflow';
export const ReportWorkflowSuccess = 'ReportWorkflowSuccess';
export const ReportingWfActionRequest = 'ReportingWfActionRequest';
export const ReportingWfActionResponse = 'ReportingWfActionResponse';
export const GroupWfRequest = 'GroupWfRequest';
export const GroupWfResponse = 'GroupWfResponse';
export const UpdateItemRequest = 'UpdateItemRequest';
export const UpdateItemResponse = 'UpdateItemResponse';

export const EditorWorkflowResponse = 'EditorWorkflowResponse';

interface EditorWorkflowResponse {
  type: typeof EditorWorkflowResponse;
  data: Workflow;
}

interface ReportingWfActionRequest {
  type: typeof ReportingWfActionRequest;
}

interface ReportingWfActionResponse {
  type: typeof ReportingWfActionResponse;
}

interface FetchReportingWorkflow {
  type: typeof FetchReportingWorkflow;
}

interface ReportWorkflowSuccess {
  type: typeof ReportWorkflowSuccess;
  reportingWorkflow: Workflow;
}

interface CreateWorkflow {
  type: typeof CreateWorkflow;
}

interface CreateWorkflowSuccess {
  type: typeof CreateWorkflowSuccess;
  data: any;
}

interface FetchWorkflows {
  type: typeof FetchWorkflows;
}

interface WorkflowUpdateSuccess {
  type: typeof WorkflowUpdateSuccess;
}

interface WorkflowFetchSuccess {
  type: typeof WorkflowFetchSuccess;
  data: Workflow[];
}

interface FetchWorkflowPolicy {
  type: typeof FetchWorkflowPolicy;
  workflow: string;
}

interface ReceivedWorkflowPolicy {
  type: typeof ReceivedWorkflowPolicy;
  workflow: string;
  data: any;
}

interface CloneWorkflow {
  type: typeof CloneWorkflow;
}
interface CloneWorkflowSuccess {
  type: typeof CloneWorkflowSuccess;
  response: { success: boolean; error: boolean };
}
interface SetDefaultCloneResponse {
  type: typeof SetDefaultCloneResponse;
}
interface GroupWfRequest {
  type: typeof GroupWfRequest;
  group: string;
}
interface GroupWfResponse {
  type: typeof GroupWfResponse;
  data: Workflow[];
  group: string;
}

interface UpdateItemRequest {
  type: typeof UpdateItemRequest;
  id: string;
  col: string;
}

interface UpdateItemResponse {
  type: typeof UpdateItemResponse;
  id: string;
}

export type WorkflowAction =
  | FetchWorkflows
  | WorkflowFetchSuccess
  | FetchWorkflowPolicy
  | ReceivedWorkflowPolicy
  | CreateWorkflowSuccess
  | CreateWorkflow
  | CloneWorkflowSuccess
  | CloneWorkflow
  | WorkflowUpdateSuccess
  | SetDefaultCloneResponse
  | FetchReportingWorkflow
  | ReportWorkflowSuccess
  | ReportingWfActionRequest
  | ReportingWfActionResponse
  | GroupWfRequest
  | GroupWfResponse
  | UpdateItemRequest
  | UpdateItemResponse
  | EditorWorkflowResponse;

export type AdoptedTemplateMapType = {
  [s: string]: {
    workflow: string;
    name: string;
  };
};
export interface WorkflowState {
  // map of adopted template Id to workflow id
  itemUpdateStateMap: ItemUpdateStateMap;
  fetching: boolean;
  fetchingReportWf: boolean;
  reportingAction: boolean;
  update?: boolean;
  reportingWorkflow?: Workflow;
  editorWorkflow: Workflow;
  data: Workflow[] | null;
  response: { success: boolean; error: boolean } | null;
  policies: {
    [workflow: string]: {
      fetching: boolean;
      data: any;
    };
  };
  groups: {
    [s: string]: {
      data: Workflow[] | null;
      isFetching: boolean;
    };
  };
}
