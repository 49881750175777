import { parse } from 'query-string';
import { RouteComponentProps } from 'react-router';

export default (props: RouteComponentProps & { component: JSX.Element }) => {
  const queryString = props.location.search;
  const { redirect, path, query } = parse(queryString);
  const hash = props.location.hash;
  const q = query && atob(Array.isArray(query) ? query.join('') : query);
  if (redirect) {
    props.history.push(path + (q || hash));
  }

  return props.component;
};
