export const AZURE_SERVICES_SUB_CATEGORIES = [
  'Identity and Access Management',
  'Security Center',
  'Storage Accounts',
  'Database Services',
  'Monitor',
  'Networking',
  'Virtual Machines',
  'AppService',
];

export const AZURE_INSIGHTS_SUB_CATEGORIES = [
  'Azure Security',
  'Azure Operational excellence',
  'Azure cost saving',
  'Azure CIS',
  'Azure Others',
];

export const SERVICES_SUB_CATEGORIES = [
  'API Gateway',
  'AWS Amplify',
  'AWS AppSync',
  'AWS Auto Scaling',
  'AWS Backup',
  'AWS Budgets',
  'AWS Cloud Map',
  'AWS Cost Explorer',
  'AWS DeepLens',
  'AWS Glue',
  'AWS License Manager',
  'AWS Marketplace Subscriptions',
  'AWS Migration Hub',
  'AWS Organizations',
  'AWS RoboMaker',
  'AWS Single Sign-On',
  'AWS Transfer for SFTP',
  'AWS Well-Architected Tool',
  'Alexa for Business',
  'Amazon Chime',
  'Amazon Comprehend',
  'Amazon Connect',
  'Amazon DocumentDB',
  'Amazon Forecast',
  'Amazon FreeRTOS',
  'Amazon GameLift',
  'Amazon Lex',
  'Amazon MQ',
  'Amazon Macie',
  'Amazon Managed Blockchain',
  'Amazon Personalize',
  'Amazon Polly',
  'Amazon Redshift',
  'Amazon SageMaker',
  'Amazon Sumerian',
  'Amazon Textract',
  'Amazon Transcribe',
  'Amazon Translate',
  'AppStream 2.0',
  'Application Discovery Service',
  'Artifact',
  'Athena',
  'Batch',
  'Certificate Manager',
  'Cloud9',
  'CloudFormation',
  'CloudFront',
  'CloudHSM',
  'CloudSearch',
  'CloudTrail',
  'CloudWatch',
  'CodeBuild',
  'CodeCommit',
  'CodeDeploy',
  'CodePipeline',
  'CodeStar',
  'Cognito',
  'Config',
  'Control Tower',
  'Data Pipeline',
  'DataSync',
  'Database Migration Service',
  'Device Farm',
  'Direct Connect',
  'Directory Service',
  'DynamoDB',
  'EC2',
  'ELB',
  'ECR',
  'ECS',
  'EFS',
  'EKS',
  'EMR',
  'ElastiCache',
  'Elastic Beanstalk',
  'Elastic Transcoder',
  'Elasticsearch Service',
  'FSx',
  'Global Accelerator',
  'Ground Station',
  'GuardDuty',
  'IAM',
  'Inspector',
  'IoT 1-Click',
  'IoT Analytics',
  'IoT Core',
  'IoT Device Defender',
  'IoT Device Management',
  'IoT Events',
  'IoT Greengrass',
  'IoT SiteWise',
  'IoT Things Graph',
  'Key Management Service',
  'Kinesis',
  'Kinesis Video Streams',
  'Lambda',
  'Lightsail',
  'MSK',
  'Machine Learning',
  'Managed Services',
  'MediaConnect',
  'MediaConvert',
  'MediaLive',
  'MediaPackage',
  'MediaStore',
  'MediaTailor',
  'Mobile Hub',
  'Neptune',
  'OpsWorks',
  'Personal Health Dashboard',
  'Pinpoint',
  'QuickSight',
  'RDS',
  'Rekognition',
  'Resource Access Manager',
  'Route 53',
  'S3',
  'S3 Glacier',
  'SWF',
  'Secrets Manager',
  'Security Hub',
  'Server Migration Service',
  'Service Catalog',
  'Simple Email Service',
  'Simple Notification Service',
  'Simple Queue Service',
  'Snowball',
  'Step Functions',
  'Storage Gateway',
  'Systems Manager',
  'Trusted Advisor',
  'VPC',
  'WAF & Shield',
  'WorkDocs',
  'WorkLink',
  'WorkMail',
  'WorkSpaces',
  'X-Ray',
];

export const SUB_CATEGORIES = [
  'None',
  'Other',
  'CIS-AWS',
  'Security',
  'Network Security',
  'Fault Tolerance',
  'Backup',
  'Recovery',
  'Storage',
  'Operational Excellence',
  'Reliability',
  'Performance-efficiency',
  'Cost Saving',
  'Resource scheduling',
  'Configuration Management',
  'Change Management',
  'High Availability',
  'Monitoring',
  'Maintenance ',
  'Incident Management',
  'Service Limits',
  'Compliance',
  'Realtime threats',
  'Specific compliance',
  'Access Control',
  'Automation',
  'Network',
  'Data security',
  'Budgets',
  'Cost alerts',
  'AWS well architected tool',
  'Release and Deployment',
  'Reporting',
  'Data Migration',
  'Remediation',
  'Temporary Access Requests',
  'Approved Enforcement',
  'Prediction',
  'AWS Best Practices',
  'Interesting Events',
  'search',
]
  .concat(SERVICES_SUB_CATEGORIES)
  .concat(AZURE_INSIGHTS_SUB_CATEGORIES)
  .concat(AZURE_SERVICES_SUB_CATEGORIES);

export const SUB_CATEGORIES_MAP: { [s: string]: string } = {
  'AWS Auto Scaling': 'ASG',
  'Application Discovery Service': 'ADS',
  'Certificate Manager': 'ACM',
  'Database Migration Service': 'DMS',
  'Directory Service': 'DS',
  'Elastic Beanstalk': 'EBS',
  'Elasticsearch Service': 'ES',
  'Key Management Service': 'KMS',
  'Kinesis Video Streams': 'KVS',
  'Resource Access Manager': 'RAM',
  'Server Migration Service': 'SMS',
  'Simple Email Service': 'SES',
  'Simple Notification Service': 'SNS',
  'Simple Queue Service': 'SQS',
  'Identity and Access Management': 'IAM',
};

export const CATEGORIES = [
  'Custom',
  'Insights',
  'Inventory',
  'By Service',
  'By Solution',
  'Popular',
  'Events',
];
