import api from '../../../api';
import { SGCTypeAction, RequestType, RequestTypeSuccess } from './types';
import { notify } from '../../../components/common/toaster';

export const SGCResourceRequest = function(path: string): SGCTypeAction {
  return {
    type: RequestType,
    path: path,
  };
};

export const SGCResourceResponse = function(
  path: string,
  data: any,
): SGCTypeAction {
  return {
    type: RequestTypeSuccess,
    path: path,
    data: data,
  };
};

export function fetchSGCDefinition(props: {
  dispatch: (e: any) => void;
  path: string;
  suppressErrors?: boolean;
  cloudType: string;
  defaultValue?: {} | [];
}) {
  const { dispatch, suppressErrors = true, cloudType, defaultValue } = props;
  const path = `${cloudType}/${props.path}`;
  dispatch(SGCResourceRequest(path));
  api
    .getSGCTypeDefinition(path)
    .then((result) => {
      dispatch(SGCResourceResponse(path, result));
    })
    .catch((e) => {
      console.error(e);
      if (!suppressErrors) {
        notify({ type: 'error', message: e.message || e });
      }
      dispatch(SGCResourceResponse(path, defaultValue));
    });
}
