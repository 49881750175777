import * as React from 'react';
import { SideBar } from './SideBar';
import { Col, Row } from 'reactstrap';
import { SecureRouteProps } from '../../api/tcApiHelper';
import { AppState } from '../../lib/store';
import { connect } from 'react-redux';
import { TcRoutes } from '../../config/sidePannel';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SidebarContent } from './styleComponents';
import { toggleSidebar } from '../../lib/store/sidebar/action';

interface StateProps {
  isOpen: boolean;
}

type OwnProps = React.PropsWithChildren<{
  routes?: TcRoutes[];
}>;
type DispatchProps = {};

type IProps = OwnProps & DispatchProps & SecureRouteProps & StateProps;

export class SideBarWrapperInner extends React.Component<IProps> {
  render(): React.ReactNode {
    let span;
    span = this.props.isOpen ? { size: 12 } : { size: 12 };
    return (
      <Row style={{ position: 'relative' }}>
        <SideBar {...this.props} toggle={toggleSidebar} />
        <SidebarContent md={span} className="" isOpen={this.props.isOpen}>
          {this.props.children}
        </SidebarContent>
      </Row>
    );
  }
}

function mapStateToProps(state: AppState): StateProps {
  return {
    isOpen: state.sidebar.isOpen,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {};
}

export const SidebarWrapper = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SideBarWrapperInner);
