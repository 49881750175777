import * as React from 'react';
import { StatusPlaceholder, StatusIndicator } from '../style';
import { isLengthyArray } from '../../../util/commonUtils';
import { ExecutionStatusEnum } from './ExecutionStatus';
import { ExecutionsMapType } from '../../../lib/store/executions/types';

interface LastExecutionStatusProps {
  isFetching: boolean;
  statusData: Array<any>;
}

interface LastExecutionStatusState {}

export class LastExecutionsStatus extends React.Component<
  LastExecutionStatusProps,
  LastExecutionStatusState
> {
  render() {
    const { isFetching, statusData } = this.props;
    if (isFetching) {
      return (
        <div className={'d-flex'}>
          {' '}
          {Array.from(Array(3)).map((_val, idx) => (
            <StatusPlaceholder key={idx} />
          ))}
        </div>
      );
    }

    return (
      <div className={'d-flex'}>
        {isLengthyArray(statusData)
          ? statusData.map((data: any, idx) => (
              <StatusIndicator type={data.status} key={idx} />
            ))
          : null}
      </div>
    );
  }
}

interface Iprops {
  workflow: string;
  executionMap: ExecutionsMapType;
  isFetchingWorkflow: boolean;
}

export function getLatestExecutionStatus(props: Iprops) {
  let { workflow, executionMap = {}, isFetchingWorkflow } = props;
  let workflowExecution = executionMap[workflow];
  let executions = workflowExecution ? workflowExecution.exes : [];
  let isFetchingExecution = workflowExecution && workflowExecution.fetching;
  let extUrl = null;
  let workflowOutput: ExecutionStatusEnum = ExecutionStatusEnum.Loading;
  let loading =
    (isFetchingWorkflow || isFetchingExecution) &&
    (!workflowExecution || !workflowExecution.output);
  let executionUrl = `/workflow/${workflow}/details`;
  // console.log({isFetchingWorkflow,isFetchingExecution,workflowExecution})
  if (!loading) {
    if (workflowExecution && workflowExecution.output) {
      let { state, message, url } = workflowExecution.output;
      workflowOutput = message || ExecutionStatusEnum[state];
      extUrl = url;
    } else {
      workflowOutput = ExecutionStatusEnum.NA;
    }
  }
  return {
    result: { workflowOutput, executionUrl, url: extUrl },
    executions,
    isFetchingExecution,
  };
}
