import { FormikProps } from 'formik';
import { TriggerSchema } from '../schema';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { ToggleActiveState } from './ToggleActiveState';
import SchemaComponent, { defaultSchema } from './SchemaComponent';
import { MultiScheduleForm } from '../../../../../../../components/common/MultiScheduleForm/MultiScheduleForm';

export function ScheduleForm(props: FormikProps<TriggerSchema>) {
  const onSchemaChange = ({ jsonSchema, error, index }) => {
    props.setFieldValue(`schedule.value[${index}].userSchema`, jsonSchema);
    if (
      error &&
      (!props.errors.schedule ||
        !props.errors.schedule.value ||
        typeof props.errors.schedule.value[index] !== 'string')
    ) {
      props.setFieldError(`schedule.value[${index}].userSchema`, error);
    }
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <ToggleActiveState {...props} targetType={'schedule'} />
        </Col>
      </Row>
      {props.values.schedule.active && (
        <>
          <MultiScheduleForm<TriggerSchema> {...props} />
          {props.values.schedule.value.map((e, i) => {
            return (
              <Row className={'mt-2'}>
                <Col>
                  <SchemaComponent
                    style={{ height: 'calc(100vh - 500px)' }}
                    schema={e.userSchema || defaultSchema}
                    changeValue={({ jsonSchema, error }) =>
                      onSchemaChange({ jsonSchema, error, index: i })
                    }
                  />
                </Col>
              </Row>
            );
          })}
        </>
      )}
    </>
  );
}
