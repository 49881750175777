import React from 'react';

interface IProps {
  type: string;
  component: JSX.Element | string;
}
export const Badge = ({ type, component }: IProps) => {
  let bg = 'warning';
  switch (type) {
    case 'Success':
    case 'success':
      bg = 'success';
      break;
    case 'Error':
    case 'error':
      bg = 'danger';
      break;
    case 'Running':
    case 'running':
    case 'Pending':
    case 'pending':
    case 'Queued':
    case 'queued':
      bg = 'primary';
      break;
    default:
      break;
  }
  return (
    <span
      style={{ fontSize: '13px' }}
      className={` text-small font-weight-bold text-white badge badge-${bg} p-1`}
    >
      {component}
    </span>
  );
};
