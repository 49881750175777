export enum VAR_TYPE {
  TEXT = 'text',
  JSON = 'json',
}

export const CustomActions = [
  {
    name: 'lambda-cost-detector',
    variables: [
      {
        name: 'threshold',
        value: '',
        description: 'Trigger alarm if cost crosses this threshold',
      },
    ],
    description:
      'Calculates real time cost of lambda for the current date(UTC).',
  },
  {
    name: 'lambda-cost-predictor',
    variables: [
      {
        name: 'threshold',
        value: '',
        description: 'Daily cost threshold',
      },
    ],
    description:
      'Sends alert if the projected daily cost of Lambda crosses the specified threshold for the current date(UTC).',
  },
  {
    name: 'monitoring-data-format',
    variables: [],
    description:
      'Converts monitoring data values from array to single number(average)',
  },
  {
    name: 'utilisation-report',
    variables: [],
    description: 'Generates utilisation data using monitoring node data',
  },
  {
    name: 'generate-schedule-recommendations',
    variables: [
      {
        name: 'thresholds_idleThreshold',
        value: '5',
        description: 'percentage below which machine is considered idle',
      },
      {
        name: 'thresholds_minAwakePeriod',
        value: '0',
        description:
          'duration in minutes, cpu must be busy for at least this much time, otherwise it will discarded',
      },
      {
        name: 'thresholds_minIdlePeriod',
        value: '0',
        description:
          'duration in minutes, cpu must be idle between periods of busy for at least this much time',
      },
    ],
    description:
      'Add a recommendation when the resources should be running, based on past utilisation',
  },
  {
    name: 'calculate-savings',
    variables: [
      {
        name: 'pricingKeysMapper',
        value: 'EC2-Instances',
        description: 'will use this later to support multiple resources',
      },
    ],
    description:
      'determine current pricing and expected savings based on recommended schedules',
  },
  {
    name: 'group-resources',
    variables: [
      {
        name: 'type',
        value: 'property',
        description: `type = 'tag' || 'sg' || 'property' || 'none'`,
      },
      {
        name: 'key',
        value: '$',
        description: `TagKey || Property`,
      },
    ],
    description: 'Group Resource',
  },
  {
    name: 'tags-info-format',
    variables: [],
    description: 'Generates tags summary for all selected resources',
  },
  {
    name: 'list-s3-folders',
    variables: [
      {
        type: VAR_TYPE.JSON,
        name: 'params',
        value: {
          Bucket: 'required',
          Delimiter: '<optional>',
          Prefix: '<optional>',
          MaxKeys: '<optional>',
          EncodingType: '<optional>',
          RequestPayer: '<optional>',
          ContinuationToken: '<optional>',
        },
        description: `Additional parameters`,
      },
    ],
    description: 'List folders in s3',
  },
  {
    name: 'custom',
    variables: [],
  },
];

export type variableType = {
  name: string;
  value: any;
  type?: VAR_TYPE;
  description: string;
};

export type customActionsType = {
  name: string;
  description?: string;
  variables: Array<variableType>;
};
