import * as React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { TextArea } from '../../../../../formInputs/textarea.component';
import { SectionLoader } from '../../../../../../components/common/SectionLoader';
import classnames from 'classnames';
import { JSONSection } from '../../../ui/common/JsonSection';
import CodeEditor from '../../../../../formInputs/codeEditor.component';
import { ModalWrapper } from '../../../ui/common/ModalWrapper';
import CustomRadioButtons, {
  ORIENTATION,
} from '../../../../../formInputs/radio-button.component';
import { viewType } from '../../../../../../components/workflows/common/workflowReportForm';

interface VerificationPopupProps {
  text: string;
  showVerificationPopup: boolean;
  closeVerificationPopup: () => void;
  validateWorkflow: (data) => void;
  recommendedPermissionJSON: any;
  minimumPermissionJSON: any;
  missingPermissionJSON?: any;
  isFetching?: boolean;
  isAuthError?: boolean;
}

interface IState {
  activeTab: string;
}

export default class PolicyDocumentModal extends React.Component<
  VerificationPopupProps,
  IState
> {
  state = {
    activeTab: '1',
  };

  toggle = (activeTab: string) => {
    if (this.state.activeTab !== activeTab) this.setState({ activeTab });
  };

  render() {
    let permission = JSON.stringify(
      this.props.recommendedPermissionJSON,
      null,
      4,
    );
    let minPermission = JSON.stringify(
      this.props.minimumPermissionJSON,
      null,
      4,
    );
    let missingPolicy = JSON.stringify(
      this.props.missingPermissionJSON,
      null,
      4,
    );
    return (
      <Modal
        isOpen={this.props.showVerificationPopup}
        toggle={this.props.closeVerificationPopup}
        size={'xl'}
        backdrop={'static'}
      >
        <ModalWrapper
          text={{ save: 'Retry Validation' }}
          title={'Required Policies'}
          onCancel={this.props.closeVerificationPopup}
          onSubmit={this.props.validateWorkflow}
        >
          {this.props.isFetching ? (
            <SectionLoader />
          ) : (
            <div className={'tc-tab'}>
              <Row className={'my-2'}>
                <Col className={'text-danger ml-3'}>
                  {this.props.isAuthError &&
                    `We are not able to verify policy for ${this.props.text}. 
                          Please make sure you have given recommended IAM permissions`}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === '1',
                        })}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        Recommended policy
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === '2',
                        })}
                        onClick={() => {
                          this.toggle('2');
                        }}
                      >
                        Minimum policy
                      </NavLink>
                    </NavItem>
                    {!this.props.isAuthError &&
                      this.props.missingPermissionJSON && (
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === '3',
                            })}
                            onClick={() => {
                              this.toggle('3');
                            }}
                          >
                            Missing policy
                          </NavLink>
                        </NavItem>
                      )}
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" className={'ml-3 my-2'}>
                      <CodeEditor
                        label={`Recommended policy to run ${this.props.text} and improve your Totalcloud experience`}
                        style={{ height: 'calc(100vh - 400px)', width: '100%' }}
                        value={{ data: permission || '{}' }}
                        name={'node-json'}
                        readOnly={true}
                      />
                    </TabPane>
                    <TabPane tabId="2" className={'ml-3 my-2'}>
                      <CodeEditor
                        label={`Minimum policy to run ${this.props.text}`}
                        style={{ height: 'calc(100vh - 400px)', width: '100%' }}
                        value={{ data: minPermission || '{}' }}
                        name={'node-json'}
                        readOnly={true}
                      />
                    </TabPane>
                    <TabPane tabId="3" className={'ml-3 my-2'}>
                      <CodeEditor
                        label={`Missing policy to run ${this.props.text}`}
                        style={{ height: 'calc(100vh - 400px)', width: '100%' }}
                        value={{ data: missingPolicy || '{}' }}
                        name={'node-json'}
                        readOnly={true}
                      />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </div>
          )}
        </ModalWrapper>
      </Modal>
    );
  }
}
