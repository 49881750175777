import { WORKFLOW_ACTION } from 'workflow-model/dist/types/IGroupWorkflowModel';
import { IGroupWorkflowProps } from 'workflow-model/dist/groupWorkflowModel';
import { GroupWorkflowProperties } from 'workflow-model/dist/types/IGroupWorkflowModel';
import { IResolver } from 'workflow-model/dist/types/IResolver';
import { ChildWorkflowsProps } from 'workflow-model/dist/groupWorkflowModel/index';
import { GroupHistory } from 'workflow-model/dist';

export type GetWorkflowResponseType = {
  user: string;
  name: string;
  properties?: GroupWorkflowProperties;
  group?: string;
  workflows: Array<ChildWorkflowsProps>;
};
export type ChildWorkflowMap = {
  [s: string]: {
    isFetchingWf: boolean;
    workflow: GetWorkflowResponseType;
  };
};
export interface ParkingState {
  isFetchingList: boolean;
  isTesting: boolean;
  isSaving: boolean;
  isFetchingHistory: boolean;
  data: GroupWorkflowListResult[];
  resources: { [s: string]: any }[];
  childWfMap: ChildWorkflowMap;
  history: {
    [group: string]: GroupHistory[];
  };
  fetchingGroupsHistory: string[];
  isFetchGroupPolicy: boolean;
  isFetchingCombinedPolicy: boolean;
  groupPolicy: {
    [s: string]: PolicyResponse;
  };
  multiGroupPolicy: {
    [s: string]: PolicyResponse;
  };
}

export type Tag = {
  Key: string;
  Value: string;
};

export type InstanceStateName =
  | 'pending'
  | 'running'
  | 'shutting-down'
  | 'terminated'
  | 'stopping'
  | 'stopped'
  | string;
export interface InstanceState {
  /**
   * The current state of the instance.
   */
  Name?: InstanceStateName;
}

export type Instance = {
  unit: string;
  description: string;
  rate: number;
  InstanceId: string;
  InstanceType: string;
  Tags: Tag[];
  actual: number;
  cost: number;
  savings: string;
  State?: InstanceState;
};

export interface GroupWorkflowListResult {
  actual: string;
  cost: string;
  createdAt: string;
  group: string;
  updatedAt: string;
  instances: Array<Instance>;
  status: string;
  lastModified: number;
  name: string;
  next: {
    time: number;
    type: WORKFLOW_ACTION;
  };
  properties: {
    [s: string]: string;
  };
  savings: string;
  workflows: Array<{
    workflow: string;
    action: string;
  }>;
  schedules: {
    start: string[];
    stop: string[];
  };
}

export const TestGroupWorkflowResponse = 'TestGroupWorkflowResponse';
export const TestGroupWorkflowRequest = 'TestGroupWorkflowRequest';
export const SaveGroupWorkflowRequest = 'SaveGroupWorkflowRequest';
export const SaveGroupWorkflowResponse = 'SaveGroupWorkflowResponse';
export const ListGroupWorkflowRequest = 'ListGroupWorkflowRequest';
export const ListGroupWorkflowResponse = 'ListGroupWorkflowResponse';
export const GetHistory = 'GetHistory';
export const GetHistorySuccess = 'GetHistorySuccess';
export const GetMultipleHistorySuccess = 'GetMultipleHistorySuccess';
export const FetchBatchHistoryRequest = 'FetchBatchHistoryRequest';
export const FetchBatchHistoryResponse = 'FetchBatchHistoryResponse';
export const SaveBatchHistoryResponse = 'SaveBatchHistoryResponse';
export const SaveFetchingGroupsId = 'SaveFetchingGroupsId';
export const GetGroupWorkflowRequest = 'GetGroupWorkflowRequest';
export const GetGroupWorkflowResponse = 'GetGroupWorkflowResponse';
export const GroupPolicyRequest = 'GroupPolicyRequest';
export const GroupPolicyResponse = 'GroupPolicyResponse';
export const MultiGroupPolicyRequest = 'MultiGroupPolicyRequest';
export const MultiGroupPolicyResponse = 'MultiGroupPolicyResponse';
export const SetGroupWorkflowDeletedResponse =
  'SetGroupWorkflowDeletedResponse';

export interface GetGroupWorkflowRequest {
  type: typeof GetGroupWorkflowRequest;
  group: string;
}

export interface SetGroupWorkflowDeletedResponse {
  type: typeof SetGroupWorkflowDeletedResponse;
  group: string;
}

export interface GetGroupWorkflowResponse {
  type: typeof GetGroupWorkflowResponse;
  data: GetWorkflowResponseType;
  group: string;
}

export interface TestGroupWorkflowRequest {
  type: typeof TestGroupWorkflowRequest;
}

export interface TestGroupWorkflowResponse {
  type: typeof TestGroupWorkflowResponse;
  resources: { [s: string]: any }[];
}

export interface SaveGroupWorkflowRequest {
  type: typeof SaveGroupWorkflowRequest;
}

export interface SaveGroupWorkflowResponse {
  type: typeof SaveGroupWorkflowResponse;
}

export interface ListGroupWorkflowRequest {
  type: typeof ListGroupWorkflowRequest;
}

export interface ListGroupWorkflowResponse {
  type: typeof ListGroupWorkflowResponse;
  data: GroupWorkflowListResult[];
}

export interface GetHistory {
  type: typeof GetHistory;
}

export interface GetHistorySuccess {
  type: typeof GetHistorySuccess;
  data: GroupHistory[];
  group: string;
}

export interface FetchBatchHistoryRequest {
  type: typeof FetchBatchHistoryRequest;
}

export interface FetchBatchHistoryResponse {
  type: typeof FetchBatchHistoryResponse;
}

export interface SaveFetchingGroupsId {
  type: typeof SaveFetchingGroupsId;
  data: string[];
}

export interface GetMultipleHistorySuccess {
  type: typeof GetMultipleHistorySuccess;
  data: any[];
}

export interface SaveBatchHistoryResponse {
  type: typeof SaveBatchHistoryResponse;
  data: GroupHistory[];
}

interface GroupPolicyRequest {
  type: typeof GroupPolicyRequest;
}

interface GroupPolicyResponse {
  type: typeof GroupPolicyResponse;
  data: PolicyResponse;
  group: string;
}

interface MultiGroupPolicyRequest {
  type: typeof MultiGroupPolicyRequest;
}

interface MultiGroupPolicyResponse {
  type: typeof MultiGroupPolicyResponse;
  data: PolicyResponse;
  group: string;
}

export interface PolicyResponse {
  valid: boolean;
  details: { [s: string]: boolean };
  recommended: any;
  minimum: any;
  // minIamToVerify: any;
}

export type GroupWorkflowAction =
  | ListGroupWorkflowRequest
  | ListGroupWorkflowResponse
  | SaveGroupWorkflowRequest
  | SaveGroupWorkflowResponse
  | TestGroupWorkflowRequest
  | TestGroupWorkflowResponse
  | GetHistory
  | GetHistorySuccess
  | SetGroupWorkflowDeletedResponse
  | GetMultipleHistorySuccess
  | FetchBatchHistoryRequest
  | FetchBatchHistoryResponse
  | SaveFetchingGroupsId
  | SaveBatchHistoryResponse
  | GetGroupWorkflowResponse
  | GroupPolicyRequest
  | GroupPolicyResponse
  | MultiGroupPolicyRequest
  | MultiGroupPolicyResponse
  | GetGroupWorkflowResponse
  | GetGroupWorkflowRequest;
