import { TableItem, TemplateColNames } from '../cols';
import * as React from 'react';
import { loader } from './loader';

export const TemplatePriority = (priority: number, item: TableItem) => {
  if (!item.templateItem || !item.templateItem.id) return;
  if (item.updating === TemplateColNames.TEMPLATE_PRIORITY) return loader();
  return priority;
};

export const PriorityValidator = (priority) => {
  if (isNaN(priority)) {
    return {
      valid: false,
      message: 'Digits only',
    };
  }
  return true;
};
