import * as React from 'react';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import {
  fetchGlobalPolicyAction,
  fetchPolicyDoc,
} from '../../../lib/store/policy/action';
import { GlobalPolicyType, IPolicyDoc } from '../../../lib/store/policy/types';
import { ViewPolicyStatus } from '../ViewPolicyStatus';
import {
  PolicyStatus,
  PolicyStatus as Policy_Status,
} from '../../../lib/editor/util/policyValidator';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { getActionsForAccount } from './policyHelper';
import { Config } from '../../../lib/store/config/types';
import {
  getAccountConfig,
  getAwsAccountConfig,
} from '../withRegionAndCredentials';

interface OwnProps {
  text: string;
  tabName: TAB_NAME;
  credentials: string[];
  regions: string[];
}

interface StateProps {
  isFetchingPolicy: boolean;
  isFetchingPolicyDoc: boolean;
  policyDoc: IPolicyDoc;
  availableCredentials: string[];
  globalPolicyMap: GlobalPolicyType;
  configs: Config[];
}

interface DispatchProps {
  fetchGlobalPolicy: () => void;
  fetchPolicyDocument: (params: {
    actions: string[];
    invalidActions?: string[];
  }) => void;
}

type IProps = OwnProps & StateProps & DispatchProps;

type IState = {
  showPolicyModal: boolean;
};

export class PolicyStatusInner extends React.Component<IProps, IState> {
  state: IState = {
    showPolicyModal: false,
  };

  get credentials() {
    const creds = getAwsAccountConfig(this.props.configs)
      .filter((c) => this.props.credentials.includes(c.id))
      .map((c) => c.id);
    return creds.length ? creds : this.props.availableCredentials;
  }

  getAllPoliciesActions = (): {
    actions: { [s: string]: boolean };
    isAuthError: boolean;
  } => {
    return getActionsForAccount({
      globalPolicyMap: this.props.globalPolicyMap,
      accounts: this.credentials,
      tabName: this.props.tabName,
    });
  };

  private fetchPolicyDocument = (props?: {
    actions: string[];
    invalidActions?: string[];
  }) => {
    if (props && props.actions) return this.props.fetchPolicyDocument(props);
    let { actions } = this.getAllPoliciesActions();
    let invalidActions = Object.keys(actions).filter((x) => !actions[x]);
    this.props.fetchPolicyDocument({
      actions: Object.keys(actions),
      invalidActions: invalidActions.length ? invalidActions : undefined,
    });
  };

  componentDidMount() {
    if (!this.props.globalPolicyMap) {
      this.props.fetchGlobalPolicy();
    }
  }

  revalidate = () => {
    this.setState({
      showPolicyModal: false,
    });
    this.props.fetchGlobalPolicy();
  };

  getPolicyStatus = (): { status: Policy_Status; isAuthError: boolean } => {
    if (!this.credentials.length)
      return { isAuthError: false, status: PolicyStatus.NOACCOUNT };
    if (!this.props.globalPolicyMap)
      return { isAuthError: false, status: Policy_Status.WARNING };
    let { actions, isAuthError } = this.getAllPoliciesActions();
    if (isAuthError) return { isAuthError, status: Policy_Status.WARNING };
    let invalidActions = Object.keys(actions).filter((x) => !actions[x]);
    let status =
      invalidActions.length == 0 ? Policy_Status.VALID : Policy_Status.INVALID;
    return { status, isAuthError };
  };

  render = () => {
    let policyStatus = this.getPolicyStatus();
    return (
      <ViewPolicyStatus
        account={this.credentials}
        globalPolicy={this.props.globalPolicyMap}
        configs={this.props.configs}
        isAuthError={policyStatus.isAuthError}
        text={this.props.text}
        isFetchingPolicyDocument={this.props.isFetchingPolicyDoc}
        isFetchingPolicy={this.props.isFetchingPolicy}
        validate={this.revalidate}
        policyDocument={this.props.policyDoc && this.props.policyDoc}
        policyStatus={policyStatus.status}
        fetchPolicyDocument={this.fetchPolicyDocument}
      />
    );
  };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const awsConfigs = getAwsAccountConfig(state.config.data);
  const availableCredentials = awsConfigs.map((c) => c.id);
  return {
    isFetchingPolicy: state.policy.fetchingGlobalPolicy,
    globalPolicyMap: state.policy.globalPolicy,
    isFetchingPolicyDoc: state.policy.fetchingPolicyDoc,
    policyDoc: state.policy.policyDoc,
    availableCredentials: availableCredentials,
    configs: awsConfigs,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchGlobalPolicy: () => fetchGlobalPolicyAction({ dispatch }),
    fetchPolicyDocument: ({ actions, invalidActions }) =>
      fetchPolicyDoc({ dispatch, actions, invalidActions }),
  };
}
export const PolicyStatusV2 = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyStatusInner);
