export interface Overview {
  Success?: number;
  Failed?: number;
  Processing?: number;
  Total?: number;
  SuccessRate?: string;
}

export type OverviewDuration = '7d' | '3d' | '1d' | '12hrs' | '6hrs';

// events
export const FetchOverview = 'FetchOverview';
export const OverviewFetchSuccess = 'OverviewFetchSuccess';

interface FetchOverview {
  type: typeof FetchOverview;
}

interface OverviewFetchSuccess {
  type: typeof OverviewFetchSuccess;
  duration: OverviewDuration;
  data: Overview;
}

export type OverviewAction = FetchOverview | OverviewFetchSuccess;

export interface OverviewState {
  fetching: boolean;
  data: { [s in OverviewDuration]: Overview | null };
}
