import * as React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import { cloneFormSchema, CloneFormSchema } from './cloneFormSchema';
import { CloneForm } from './CloneForm';
import { connect } from 'react-redux';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  cloneWorkflow,
  setDefaultResponse,
} from '../../../lib/store/workflows/action';
import {
  getAccountConfig,
  getAwsAccountConfig,
} from '../../common/withRegionAndCredentials';
import { Config } from '../../../lib/store/config/types';
import { REGIONS } from '../../../lib/editor/components/model/config/Regions';
import { CloneWorkflowParams } from '../../../api';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';

interface OwnProps {
  workflow: string;
  onClose: () => void;
}

interface StateProps {
  isFetching: boolean;
  response: { success: boolean; error: boolean };
  configs: Config[];
}

interface DispatchProps {
  cloneWorkflow: (params: CloneWorkflowParams) => void;
  setDefaultResponse: () => void;
}

type IProps = OwnProps & DispatchProps & StateProps;

let awsConfigs = (configs: Config[]) =>
  getAccountConfig(configs).map((s) => ({
    label: s.name,
    value: s.id,
  }));

interface IState {
  errorStatus: string | null;
}

export class CloneWorkflowInner extends React.Component<IProps, IState> {
  state: IState = {
    errorStatus: null,
  };

  onSubmit = (values: CloneFormSchema) => {
    let { configs, regions, resourceGroups } = values;
    const _configs = configs.map((x) => '@' + x);

    const awsAccounts = getAwsAccountConfig(this.props.configs).filter((x) =>
      configs.includes(x.id),
    );
    const azureAccounts = this.props.configs
      .filter((x) => x.type === CONFIG_TYPES.AZURE)
      .filter((x) => configs.includes(x.id));

    this.setState({ errorStatus: null });
    if (!configs.length)
      return this.setState({ errorStatus: 'Account is required' });

    if (awsAccounts.length && !regions.length)
      return this.setState({
        errorStatus: 'Region is required for this account.',
      });
    if (azureAccounts.length && !resourceGroups.length)
      return this.setState({
        errorStatus: 'ResourceGroup is required for this account.',
      });

    this.props.cloneWorkflow({
      configs: _configs,
      resourceGroups,
      regions,
      workflow: this.props.workflow,
    });
  };

  componentWillUnmount(): void {
    this.props.setDefaultResponse();
  }

  renderForm = (props: FormikProps<CloneFormSchema>) => {
    return (
      <CloneForm
        errorStatus={this.state.errorStatus}
        allAWSConfigs={awsConfigs(this.props.configs)}
        {...this.props}
        {...props}
      />
    );
  };

  render() {
    const initialValues = {
      regions: [REGIONS[0].value],
      configs: [awsConfigs(this.props.configs)[0].value],
      resourceGroups: [],
    };
    return (
      <>
        <Formik<CloneFormSchema>
          initialValues={initialValues}
          onSubmit={(
            values: CloneFormSchema,
            actions: FormikActions<CloneFormSchema>,
          ) => {
            this.onSubmit(values);
            actions.setSubmitting(false);
          }}
          render={this.renderForm}
          validateOnChange={true}
          validationSchema={cloneFormSchema}
        />
      </>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetching: state.workflows.fetching,
    response: state.workflows.response,
    configs: state.config.data,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    setDefaultResponse: () => setDefaultResponse({ dispatch }),
    cloneWorkflow: (params) => cloneWorkflow(dispatch, params),
  };
}

export const CloneWorkflow = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CloneWorkflowInner);
