import React from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export const Styled404 = styled.span`
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  margin: 2.4rem auto;
`;

export const ReportNotFoundFullScreen = () => {
  return (
    <div
      className={
        'mx-auto d-flex align-items-center justify-content-center flex-column bg-white vh-100 w-100'
      }
    >
      <Styled404 style={{ fontSize: '5vw' }} className={'text-muted'}>
        404
      </Styled404>
      <div className={'text-dark text-center font-16'}>
        <p>The report you are looking for does not exist. </p>
        <p>
          This could be because the workflow execution is too old. Please run
          the workflow again to regenerate the report.
        </p>
        <Link className={'btn btn-primary btn-sm mt-3'} to={'/'}>
          Go to Dashboard
        </Link>
      </div>
    </div>
  );
};

interface ReportNotFoundEmbedProps {
  text?: string;
  descriptionTop?: string;
  description?: string;
  buttonText: string;
  buttonLink: string;
}

export const ReportNotFoundEmbed = ({
  text = 'Not Found',
  buttonLink,
  buttonText,
  description = 'This could be because the workflow execution is too old. Please run the workflow again to regenerate the report.',
  descriptionTop = 'The report you are looking for does not exist.',
}: ReportNotFoundEmbedProps) => {
  return (
    <div
      className={
        'mx-auto d-flex align-items-center justify-content-center flex-column bg-white w-100 py-4'
      }
    >
      <Styled404 data-testid={'not-found-main-text'} className={'text-muted'}>
        {text}
      </Styled404>
      <div className={'text-dark text-center font-16'}>
        <p>{descriptionTop}</p>
        <p>{description}</p>

        <Link className={'btn btn-primary btn-sm mt-3'} to={buttonLink}>
          Go to {buttonText}
        </Link>
      </div>
    </div>
  );
};
