import * as React from 'react';
import { ReactNode } from 'react';
import { FormikProps } from 'formik';
import { ModalWrapper } from '../../common/ModalWrapper';
import { Button, Col, Label, Row } from 'reactstrap';
import InputSelect from '../../../../../formInputs/select-option.component';

import { CustomNodeSchema } from './schema';
import {
  CustomActions,
  customActionsType,
  VAR_TYPE,
} from '../../../model/config/CustomActions';
import CodeEditor from '../../../../../formInputs/codeEditor.component';
import InputText from '../../../../../formInputs/input-text.component';
import { TextArea } from '../../../../../formInputs/textarea.component';
import { CustomNodeHelpText } from './help';
import { CustomNodeProps } from './index';
import { getModalTitle } from '../../common/nodeModalHelper';
import { TcJsonEditor } from '../../../../../formInputs/json-editor';
import { getAutoCompleteList } from '../helpers/Utils';
import { OutputKey } from 'workflow-model/dist';

export const defaultCode =
  'async function process(){\n' +
  '  let result = 0;\n' +
  '  let {collection,region} = context;\n' +
  '  for(let i = 0;i< collection.length; i++){\n' +
  '    result += collection[i].key;\n' +
  '  }\n' +
  '  exit(null,result)\n' +
  '}\n' +
  'process().catch(exit);';

interface CustomFormProps
  extends FormikProps<CustomNodeSchema>,
    CustomNodeProps {}

interface IState {
  variablesError: boolean;
  lastNodeOutputKeys: OutputKey[];
}
export class CustomForm extends React.Component<CustomFormProps, IState> {
  state: IState = {
    variablesError: false,
    lastNodeOutputKeys: [],
  };

  componentDidMount(): void {
    this.fetchOutputProperties();
  }

  componentDidUpdate(
    prevProps: Readonly<CustomFormProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (
      this.props.values.selectedResource !== prevProps.values.selectedResource
    ) {
      this.fetchOutputProperties();
    }
  }

  handleTypeChange(event) {
    let { value } = event.target;
    this.props.handleChange(event);
    if (value === 'custom')
      this.props.handleChange({
        target: { name: 'code', value: { data: defaultCode, error: false } },
      });
  }

  async fetchOutputProperties(selectedResourceLabel?: string) {
    if (!selectedResourceLabel)
      selectedResourceLabel = this.props.values.selectedResource;
    if (!selectedResourceLabel) return [];

    try {
      let selectedResourceObj = this.props.availableResources.find(
        (x) => x.label === selectedResourceLabel,
      );
      let lastNodeOutputKeys: Array<OutputKey> = await this.props.workflowModel.getOutputKeysOfSelectedResource(
        selectedResourceObj,
      );
      this.setState({ lastNodeOutputKeys });
    } catch (error) {
      this.setState({ lastNodeOutputKeys: [] });
      console.error(error);
    }
  }

  updateDefaultCode = () => {
    let code = this.props.values.code && this.props.values.code['data'];
    if (!code || !Object.keys(this.props.values.variables || {}).length) return;
    let splitted = code && code.split('\n');
    if (!splitted || splitted.length === 0) return;
    let variablesLine = code && code.split('\n')[1];
    if (!variablesLine) variablesLine = '';
    if (!variablesLine.trim().endsWith('context;')) {
      splitted.splice(
        1,
        0,
        `  let {${Object.keys(this.props.values.variables).join(
          ',',
        )}} = context;  `,
      );
    } else {
      splitted.splice(
        1,
        1,
        `  let {${Object.keys(this.props.values.variables).join(
          ',',
        )}} = context;  `,
      );
    }
    this.props.handleChange({
      target: {
        name: 'code',
        value: {
          data: splitted.join('\n'),
          error: this.props.values.code['error'],
        },
      },
    });
  };

  addNewVariables = () => {
    let { variables } = this.props.values;
    variables['key'] = '';
    this.props.handleChange({
      target: { name: 'variables', value: variables },
    });
  };

  render(): React.ReactNode {
    let { link, variables, code, selectedResource } = this.props.values;
    let currentAction: customActionsType = CustomActions.find(
      (x) => x.name === link,
    );

    let modalTitle: ReactNode = getModalTitle(this.props.selectedNode);
    return (
      <ModalWrapper
        title={modalTitle}
        onCancel={this.props.onClose}
        onSubmit={this.props.submitForm}
        helpText={CustomNodeHelpText}
        jsonData={{
          workflow: this.props.workflowModel,
          selectedNode: this.props.selectedNode,
          data: JSON.parse(JSON.stringify(this.props.values)),
        }}
      >
        <Row>
          <Col md={8}>
            <InputSelect
              className={'mt-2'}
              label={'Select a resource'}
              name={'selectedResource'}
              onChange={this.props.handleChange}
              error={this.props.errors.selectedResource}
              value={selectedResource}
              options={this.props.availableResources.map((x) => ({
                label: x.label,
                value: x.label,
              }))}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InputSelect
              label={'Select a predefined logic'}
              value={link}
              options={CustomActions.map((e) => ({
                label: e.name,
                value: e.name,
              }))}
              onChange={this.handleTypeChange.bind(this)}
              name={'link'}
              error={this.props.errors.link}
            />
          </Col>
        </Row>
        {link === 'custom' && (
          <>
            {Object.keys(this.props.values.variables || {}).length !== 0 ? (
              <TcJsonEditor
                value={{
                  data: this.props.values.variables,
                  error: this.state.variablesError,
                }}
                onChange={({ data, error }) => {
                  this.setState({ variablesError: error });
                  this.props.handleChange({
                    target: { name: 'variables', value: data },
                  });
                  this.updateDefaultCode();
                }}
                autoCompletionList={getAutoCompleteList({
                  ...this.props,
                  ...this.state,
                })}
                name={'Variables'}
                label={'Variables'}
              />
            ) : (
              <Row>
                <Col>
                  <span>Variables</span>
                  <Button
                    color="link"
                    className={'ml-2'}
                    onClick={this.addNewVariables}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <CodeEditor
                  label={'Enter custom code'}
                  name="code"
                  fontSize={16}
                  onChange={this.props.handleChange}
                  value={code}
                  error={
                    !code || code['error'] || this.props.errors.code
                      ? 'Please Enter valid code'
                      : ''
                  }
                  style={{
                    resize: 'vertical',
                    width: '100%',
                    zIndex: -0,
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        {currentAction &&
          currentAction.variables &&
          currentAction.variables.map((x) => {
            if (x.type === VAR_TYPE.JSON) {
              return (
                <TcJsonEditor
                  value={{
                    data: variables[x.name] || x.value,
                    error: this.state.variablesError,
                  }}
                  onChange={({ data, error }) => {
                    this.setState({ variablesError: error });
                    this.props.handleChange({
                      target: { name: `variables.${x.name}`, value: data },
                    });
                  }}
                  autoCompletionList={getAutoCompleteList({
                    ...this.props,
                    ...this.state,
                  })}
                  name={`variables.${x.name}`}
                  label={x.description}
                />
              );
            } else
              return (
                <Row key={x.name}>
                  <Col>
                    <InputText
                      key={x.name}
                      error={
                        this.props.errors.variables &&
                        this.props.errors.variables[x.name]
                      }
                      name={`variables.${x.name}`}
                      value={variables[x.name] || x.value}
                      label={x.description}
                      onChange={this.props.handleChange}
                    />
                  </Col>
                </Row>
              );
          })}
        {currentAction && currentAction.description && (
          <Row className={'mt-3'}>
            <Col>
              <Label className={'text-dark'}>Description</Label>
              <p>{currentAction.description}</p>
            </Col>
          </Row>
        )}
      </ModalWrapper>
    );
  }
}
