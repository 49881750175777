import * as React from 'react';

interface IProps<T> {
  renderComponent: (items: T[]) => JSX.Element[];
  renderGroupedComponents: (items: T[]) => JSX.Element | null;
  components: T[];
  getId: (element: T) => string;
  focusedElement: string;
}

export class ButtonRow<T> extends React.Component<IProps<T>> {
  groups: any = null;

  /*make sure props.renderComponent assigns element id attr*/
  assignGroups = () => {
    let groups = this.props.components.reduce((a, e) => {
      let ele = document.getElementById(this.props.getId(e));
      if (ele) {
        let y = ele.offsetTop;
        // shave down any padding and margin to nearest 2px
        y = Math.floor(y / 2) * 2;
        if (!a[y]) a[y] = [e];
        else a[y].push(e);
      }
      return a;
    }, {});
    // console.log(Object.keys(groups));
    this.groups = Object.keys(groups).map((k) => groups[k]);
    if (this.groups.length > 2) {
      this.groups[1] = this.groups
        .slice(1)
        .reduce(
          (a: { concat: (arg0: any) => void }, e: any) => a.concat(e),
          [],
        );
    }
    // for safe side push last element into group to make space for +1 button
    if (this.groups[1] && this.groups[1].length) {
      this.groups[1].unshift(this.groups[0].pop());
    }
  };

  compare = (a1: T[], a2: T[]) => {
    if (a1 === a2) return true;
    else if (a1.length !== a2.length) return false;
    else {
      let id1 = a1.map((e) => this.props.getId(e)).join('');
      let id2 = a2.map((e) => this.props.getId(e)).join('');
      return id1 === id2;
    }
  };

  shouldComponentUpdate(
    nextProps: Readonly<IProps<T>>,
    nextState: Readonly<{}>,
    nextContext: any,
  ): boolean {
    // console.debug("check update - ", this.groups === null );
    // should always update if groups not set
    if (this.groups === null) return true;
    const hasFocusedChanged =
      this.props.focusedElement !== nextProps.focusedElement;
    const hasListChanged = !this.compare(
      this.props.components,
      nextProps.components,
    );
    const shouldUpdate = hasFocusedChanged || hasListChanged;
    // console.debug("should update - ", hasFocusedChanged, hasListChanged, hasFocusedChanged || hasListChanged);
    if (shouldUpdate) this.groups = null;
    return shouldUpdate;
  }

  componentDidUpdate(
    prevProps: Readonly<IProps<T>>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    // console.debug("did update, modify = ", !this.groups);
    if (!this.groups) {
      this.assignGroups();
      this.forceUpdate();
    }
  }

  componentWillUnmount(): void {
    // console.debug("did unmount");
    this.groups = null;
  }

  render(): React.ReactNode {
    // console.debug("row render - ", this.groups);
    let g1 = this.props.components;
    let g2 = [];
    if (this.groups && this.groups.length > 1) {
      g1 = this.groups[0];
      g2 = this.groups[1];
    }

    // console.log("final", g1, g2);
    return (
      <>
        {this.props.renderComponent(g1)}
        {this.props.renderGroupedComponents(g2)}
      </>
    );
  }
}
