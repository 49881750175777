import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import { TAB_NAME } from 'webapp-genric/dist/constants';

interface CreateNewButtonProps {
  tabName: TAB_NAME;
  buttonText?: string;
}

export const CreateNewButton = ({
  tabName,
  buttonText,
}: CreateNewButtonProps) => {
  return (
    <Link to={'/editor'}>
      <Button
        className="test-new-account d-inline-block text-center w-auto overflow-visible ml-2"
        color={'primary'}
        block={true}
      >
        Create {(buttonText ? buttonText : tabName) || 'Workflow'}
      </Button>
    </Link>
  );
};
