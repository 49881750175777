import { Reducer } from 'redux';
import { AlarmAction, AlarmState } from './types';

const initialState: AlarmState = {
  fetching: false,
  data: [],
};

export const alarmReducer: Reducer<AlarmState, AlarmAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'RequestAlarm':
      return { ...state, fetching: true };
    case 'RequestAlarmCompleted':
      return { ...state, fetching: false, data: action.data || state.data };
    default:
      return state;
  }
};
