import { Workflow } from '../../../lib/store/workflows/types';
import { getSortProperty } from '../../../config/WorkflowCategories';
import { ExecutionsMapType } from '../../../lib/store/executions/types';
import { getLatestExecutionStatus } from './LastExecutionsStatus';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { getResultSortValue } from './formatter/ResultColFormatter';

interface Iprops {
  workflows: Workflow[];
  tabName: TAB_NAME;
  selectedSort: string;
  executionMap: ExecutionsMapType;
}

function getDefaultSort(props: Iprops) {
  let { tabName, workflows, executionMap } = props;
  let sortProperty = getSortProperty(tabName);
  switch (tabName) {
    case TAB_NAME.INSIGHTS: {
      /*
          severity + priority + execution
       */

      return workflows.sort((a, b) => {
        let aExecution = getLatestExecutionStatus({
          workflow: a.workflow,
          executionMap,
          isFetchingWorkflow: false,
        });
        let bExecution = getLatestExecutionStatus({
          workflow: b.workflow,
          executionMap,
          isFetchingWorkflow: false,
        });
        let aResourcesValue = getResultSortValue({
          tabName,
          result: aExecution.result,
        });
        let bResourcesValue = getResultSortValue({
          tabName,
          result: bExecution.result,
        });
        let aPriority = a.properties.priority || 0;
        let bPriority = b.properties.priority || 0;
        // 0 resources should always come at last
        // if(aResourcesValue === 0) return 1;
        // if(bResourcesValue === 0) return 1;

        if (a.properties.severity === b.properties.severity) {
          if (aPriority === bPriority) {
            return bResourcesValue - aResourcesValue;
          } else if (aPriority > bPriority) {
            return -1;
          } else {
            return 1;
          }
        } else if (a.properties.severity > b.properties.severity) {
          if (aResourcesValue === 0) return 1;
          return -1;
        } else {
          if (bResourcesValue === 0) return -1;
          return 1;
        }
      });
    }
    default: {
      return workflows;
      // return workflows.sort((a, b) => {
      //   if (!a.properties[sortProperty]) a.properties[sortProperty] = 0;
      //   if (!b.properties[sortProperty]) b.properties[sortProperty] = 0;
      //   return b.properties[sortProperty] - a.properties[sortProperty];
      // });
    }
  }
}

export function SortWorkflows(props: Iprops) {
  let { selectedSort, workflows, executionMap } = props;
  // console.log({selectedSort})
  switch (selectedSort) {
    case 'default':
      return getDefaultSort(props);
    case 'createdAt':
      return workflows.sort((a, b) =>
        new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1,
      );
    case 'lastRun':
      return workflows.sort((a, b) => {
        if (
          !(
            executionMap[a.workflow] &&
            executionMap[a.workflow].exes &&
            executionMap[a.workflow].exes.length > 0
          )
        )
          return 1;
        if (
          !(
            executionMap[b.workflow] &&
            executionMap[b.workflow].exes &&
            executionMap[b.workflow].exes.length > 0
          )
        )
          return -1;

        return new Date(executionMap[a.workflow].exes[0].startDate) <
          new Date(executionMap[b.workflow].exes[0].startDate)
          ? 1
          : -1;
      });
    default:
      return workflows;
  }
}
