import {
  AddGroupEnded,
  AddGroupStarted,
  AddUserEnded,
  AddUserStarted,
  AssignUserToGroupEnded,
  AssignUserToGroupStarted,
  DeleteGroupEnded,
  DeleteGroupStarted,
  ToggleUserEnded,
  ToggleUserStarted,
  FetchGroupsEnded,
  FetchGroupsStarted,
  FetchUserEnded,
  FetchUserStarted,
  UpdateGroupEnded,
  UpdateGroupStarted,
  FetchTenantStarted,
  FetchTenantEnded,
  TenantAction,
  TenantStore,
} from './types';
import { Reducer } from 'redux';

const initialState: TenantStore = {
  isAddingUser: false,
  isAddingGroup: false,
  isFetchingUsers: false,
  isFetchingGroups: false,
  isDeletingGroup: false,
  isTogglingUserActive: false,
  isModifyingUserGroups: false,
  isUpdatingGroup: false,
  isFetchingTenantDetails: false,
  tenant: null,
  orgUsers: [],
  orgGroups: [],
};

export const tenantReducer: Reducer<TenantStore, TenantAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case FetchUserStarted:
      return { ...state, isFetchingUsers: true };
    case FetchUserEnded:
      return {
        ...state,
        isFetchingUsers: false,
        orgUsers: action.users ? action.users : state.orgUsers,
      };
    case FetchGroupsStarted:
      return { ...state, isFetchingGroups: true };
    case FetchGroupsEnded:
      return {
        ...state,
        isFetchingGroups: false,
        orgGroups: action.groups ? action.groups : state.orgGroups,
      };
    case AddUserStarted:
      return { ...state, isAddingUser: true };
    case AddUserEnded:
      return { ...state, isAddingUser: false };
    case ToggleUserStarted:
      return { ...state, isChangingUserState: true };
    case ToggleUserEnded:
      return { ...state, isChangingUserState: false };
    case AddGroupStarted:
      return { ...state, isAddingGroup: true };
    case AddGroupEnded:
      return { ...state, isAddingGroup: false };
    case DeleteGroupStarted:
      return { ...state, isDeletingGroup: true };
    case DeleteGroupEnded:
      return { ...state, isDeletingGroup: false };
    case AssignUserToGroupStarted:
      return { ...state, isModifyingUserGroups: true };
    case AssignUserToGroupEnded:
      return { ...state, isModifyingUserGroups: false };
    case UpdateGroupStarted:
      return { ...state, isUpdatingGroup: true };
    case UpdateGroupEnded:
      return { ...state, isUpdatingGroup: false };
    case FetchTenantStarted:
      return { ...state, isFetchingTenantDetails: true };
    case FetchTenantEnded:
      return {
        ...state,
        isFetchingTenantDetails: false,
        tenant: action.tenant,
      };
    default:
      return state;
  }
};
