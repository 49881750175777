import * as React from 'react';

import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import CheckBox from '../../lib/formInputs/checkbox.component';

type IProps = {
  colNames: string[];
  onSaveColSettings: (colNames: string[]) => void;
};
type IState = {
  colNames: string[];
  showModal: boolean;
};

export default class ColSettings extends React.Component<IProps, IState> {
  state: IState = {
    colNames: this.props.colNames || [],
    showModal: false,
  };

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  onChangeColSelector = (value) => {
    if (this.state.colNames.indexOf(value) === -1) {
      let colNames = this.state.colNames;
      colNames.push(value);
      this.setState({ colNames });
    } else {
      this.setState({
        colNames: this.state.colNames.filter((x) => x !== value),
      });
    }
  };

  onSelectAll = (isSelectedAll) => {
    if (isSelectedAll) this.setState({ colNames: this.props.colNames });
    else {
      this.setState({ colNames: [] });
    }
  };

  render(): React.ReactNode {
    return (
      <>
        {this.state.showModal && (
          <Modal isOpen={this.state.showModal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>
              <h3 className={'mb-0'}>Settings</h3>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={8} className={'mt-2'}>
                  Select columns to show
                </Col>
                <Col md={2}>
                  <Button
                    outline
                    color={'primary'}
                    onClick={() => {
                      this.toggle();
                      this.props.onSaveColSettings(this.state.colNames);
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              <CheckBox
                onCheckedAll={this.onSelectAll}
                onChange={this.onChangeColSelector}
                options={this.props.colNames.map((x) => ({
                  label: x,
                  value: x,
                }))}
                CheckedValues={this.state.colNames}
                name="colNames"
              />
            </ModalBody>
          </Modal>
        )}

        <i
          className={'fa fa-cog fa-2x text-primary mt-2 mr-2'}
          onClick={this.toggle}
        />
      </>
    );
  }
}
