import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { Button, Row } from 'reactstrap';
import InputText from '../../../lib/formInputs/input-text.component';
import styled from 'styled-components';
import Api from '../../../api';
import { notify } from '../../common/toaster';

const formSchema = yup.object({
  name: yup.string().required('Name is required'),
  code: yup.string().required('Code is required'),
});

type FormSchema = yup.InferType<typeof formSchema>;

const ActionWrapper = styled.div`
  width: 400px;
  height: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
`;

const ActionModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.3);
`;

interface IProps {
  open: boolean;
  onClose: () => void;
  payload: any;
}
interface IState {
  sentCode: boolean;
  code: string;
  wait: boolean;
}

export class ActionModal extends React.Component<IProps, IState> {
  state: IState = {
    sentCode: false,
    code: '',
    wait: false,
  };

  actionSubmit = (values) => {
    this.setState({ wait: true });
    let payload = {
      ...this.props.payload,
      name: values.name,
    };
    Api.supportAction({ payload, hash: values.code })
      .then((res) => {
        notify({ type: 'success', message: 'Action Completed.' });
        this.setState({ wait: false });
        this.props.onClose();
      })
      .catch((err) => {
        this.setState({ wait: false });
        notify({ type: 'error', message: err.message || err });
      });
  };

  // closeForm = () => {};
  getCode = (props: FormikProps<FormSchema>) => {
    if (props.values.name == '') {
      props.setFieldError('name', 'Name is required');
    } else {
      let payload = {
        ...this.props.payload,
        name: props.values.name,
      };

      this.setState({ wait: true });
      Api.sendCode({ payload: payload })
        .then((res) => {
          this.setState({ wait: false });
          notify({ type: 'success', message: 'Code sent.' });
          this.setState({ sentCode: true });
        })
        .catch((err) => {
          this.setState({ wait: false });
          notify({ type: 'error', message: err.message || err });
        });
    }
  };

  renderForm = (props: FormikProps<FormSchema>) => {
    return (
      <div className="mx-auto">
        {this.state.sentCode && (
          <div>
            <InputText
              name={'code'}
              type={'text'}
              placeholder={'Enter code'}
              label={'Code'}
              error={props.errors.code}
              onChange={props.handleChange}
              className="mb-2"
            />
          </div>
        )}
        {!this.state.sentCode && (
          <>
            <div>
              <InputText
                name={'name'}
                type={'text'}
                label={'Name'}
                placeholder={'Enter name'}
                error={props.errors.name}
                onChange={props.handleChange}
                className="mb-2"
              />
            </div>
            <div className={'text-center'}>
              <Button
                color={'success w-50 mx-auto'}
                onClick={() => this.getCode(props)}
                disabled={this.state.wait}
              >
                Next
              </Button>
            </div>
          </>
        )}
        {this.state.sentCode && (
          <div>
            <Button
              color={'primary'}
              disabled={this.state.wait}
              onClick={props.submitForm}
            >
              Confirm
            </Button>
            <Button color={'secondary'} onClick={this.onClose}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    );
  };

  onClose = () => {
    this.setState({ sentCode: false }, () => {
      this.props.onClose();
    });
  };

  render() {
    return (
      <ActionModalWrapper className={`${this.props.open ? '' : 'd-none'}`}>
        <ActionWrapper className={`p-3 shadow`}>
          <span
            className="position-absolute right-3 top-3 cursor-pointer"
            onClick={this.onClose}
          >
            {' '}
            <i className="fa fa-times text-primary"> </i>
          </span>
          <h3>Confirmation</h3>
          <Formik<FormSchema>
            onSubmit={this.actionSubmit}
            initialValues={{ name: '', code: '' }}
            validationSchema={formSchema}
            render={this.renderForm}
          />
        </ActionWrapper>
      </ActionModalWrapper>
    );
  }
}
