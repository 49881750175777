import {
  GlobalPolicyRequest,
  GlobalPolicyResponse,
  PolicyAction,
  PolicyDocRequest,
  PolicyDocResponse,
  PolicyState,
} from './types';
import { Reducer } from 'redux';

const initialState: PolicyState = {
  fetchingGlobalPolicy: false,
  fetching: false,
  fetchingPolicyDoc: false,
  policyDoc: null,
  data: null,
  globalPolicy: null,
};

export const policyReducer: Reducer<PolicyState, PolicyAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GlobalPolicyRequest:
      return { ...state, fetchingGlobalPolicy: true };
    case GlobalPolicyResponse:
      return {
        ...state,
        fetchingGlobalPolicy: false,
        globalPolicy: action.data,
      };
    case PolicyDocRequest:
      return { ...state, fetchingPolicyDoc: true };
    case PolicyDocResponse:
      return { ...state, policyDoc: action.data, fetchingPolicyDoc: false };
    case 'MultiPolicyRequest':
      return { ...state, fetching: true };
    case 'MultiPolicyResponse': {
      if (!state.data || !action.data)
        return {
          ...state,
          data: { [action.account]: action.data },
          fetching: false,
        };
      return {
        ...state,
        data: {
          ...state.data,
          [action.account]: {
            ...state.data[action.account],
            details: {
              ...(state.data[action.account] &&
              state.data[action.account].details
                ? state.data[action.account].details
                : {}),
              ...action.data.details,
            },
            error: action.data.error,
          },
        },
        fetching: false,
      };
    }
    default:
      return state;
  }
};
