import React from 'react';
import { FormikProps } from 'formik';
import { TriggerSchema } from '../schema';
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import InputSelect from '../../../../../../formInputs/select-option.component';
import { Transformers } from '../../../../model/config/Transformers';
import CodeEditor from '../../../../../../formInputs/codeEditor.component';
import { defaultCode } from '../../custom/customForm';

export function MoreSettings(props: FormikProps<TriggerSchema>) {
  const addNewTransformer = () => {
    let transformers = props.values.http.value.inputTransformers;
    transformers.push({ link: Transformers[0].name });
    props.handleChange({
      target: { name: 'http.value.inputTransformers', value: transformers },
    });
  };

  const updateTransformer = (event, index) => {
    let transformers = props.values.http.value.inputTransformers;
    transformers[index] = {
      link: event.target.value,
      code: transformers[index].code,
    };

    if (transformers[index].link === 'custom' && !transformers[index].code) {
      transformers[index].code = { data: defaultCode, error: false };
    }
    if (transformers[index].link !== 'custom') {
      transformers[index].code = undefined;
    }

    props.handleChange({
      target: { name: 'http.value.inputTransformers', value: transformers },
    });
  };

  const getTransformerError = (index, key): string => {
    return (
      props.errors &&
      props.errors.http &&
      props.errors.http.value &&
      props.errors.http.value.inputTransformers[index] &&
      props.errors.http.value.inputTransformers[index][key] &&
      props.errors.http.value.inputTransformers[index][key].toString()
    );
  };

  const removeTransformer = (index) => {
    let transformers = props.values.http.value.inputTransformers.filter(
      (x, i) => i !== index,
    );
    props.handleChange({
      target: { name: 'http.value.inputTransformers', value: transformers },
    });
  };

  const onCodeChange = (value, index) => {
    if (
      !props.values.http.value.inputTransformers[index] ||
      !props.values.http.value.inputTransformers[index].code
    )
      return;
    if (
      props.values.http.value.inputTransformers[index].code.data ===
        value.data &&
      props.values.http.value.inputTransformers[index].code.error ===
        value.error
    )
      return;
    props.handleChange({
      target: { name: `http.value.inputTransformers[${index}].code`, value },
    });
  };
  return (
    <>
      <Row>
        <Col
          className={'text-right'}
          style={{ marginTop: '-48px' }}
          md={{ size: 4, offset: 8 }}
        >
          <Button color="link" id="toggler">
            Advanced Settings
          </Button>
        </Col>
      </Row>
      <UncontrolledCollapse toggler="#toggler">
        <Card>
          <CardBody>
            <Row className={'my-1'}>
              <Col>
                <CustomInput
                  id={'overrideGlobalVariables'}
                  name={'overrideGlobalVariables'}
                  type="checkbox"
                  disabled={false}
                  checked={props.values.http.value.overrideGlobalVariables}
                  className={'text-truncate'}
                  label={'Override global variables with http input'}
                  onChange={(event) => {
                    props.handleChange({
                      target: {
                        name: 'http.value.overrideGlobalVariables',
                        value: !props.values.http.value.overrideGlobalVariables,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span>Input Transformer</span>
                <Button
                  color="link"
                  className={'ml-2'}
                  onClick={addNewTransformer}
                >
                  Add
                </Button>
              </Col>
            </Row>

            {props.values.http.value.inputTransformers &&
              props.values.http.value.inputTransformers.map(
                (transformer, index) => {
                  return (
                    <div key={index}>
                      <Row>
                        <Col md={6}>
                          <InputSelect
                            label={'Select a predefined transformer logic'}
                            value={transformer.link}
                            options={Transformers.map((e) => ({
                              label: e.name,
                              value: e.name,
                            }))}
                            name={'link'}
                            onChange={(event) => {
                              updateTransformer(event, index);
                            }}
                            error={getTransformerError(index, 'link')}
                          />
                        </Col>
                        <Col md={1}>
                          <i
                            className={'fa fa-trash text-danger mt-5'}
                            onClick={() => removeTransformer(index)}
                          />
                        </Col>
                      </Row>
                      {transformer.link === 'custom' && (
                        <Row className={'my-1'}>
                          <Col>
                            <CodeEditor
                              label={'Enter custom code'}
                              name={'code'}
                              fontSize={16}
                              onChange={(val) => onCodeChange(val, index)}
                              value={JSON.parse(
                                JSON.stringify(transformer.code),
                              )}
                              error={
                                !transformer.code ||
                                transformer.code['error'] ||
                                getTransformerError(index, 'code')
                                  ? 'Please Enter valid code'
                                  : ''
                              }
                              style={{
                                resize: 'contain',
                                height: '200px',
                                width: '100%',
                                zIndex: -0,
                              }}
                            />
                          </Col>
                        </Row>
                      )}
                    </div>
                  );
                },
              )}
          </CardBody>
        </Card>
      </UncontrolledCollapse>
    </>
  );
}
