import * as React from 'react';
import { RouteChildrenProps, RouteProps } from 'react-router';
import api from './index';
import { withAuth } from '@okta/okta-react';
import { useEffect } from 'react';

export interface AuthProps {
  auth: {
    getAccessToken: () => string;
    getUser: () => any;
    logout: (s: string) => void;
    isAuthenticated: () => boolean;
  };
}

export type SecureRouteProps = RouteChildrenProps & AuthProps;

export function withTcApi(
  C: typeof React.Component | ((props: SecureRouteProps) => JSX.Element),
) {
  return withAuth(function(props: SecureRouteProps) {
    const logoutIfNotAuthenticated = async () => {
      if (!(await props.auth.isAuthenticated())) props.history.push('/login');
    };

    useEffect(() => {
      // After login verify authentication after every 1 minute
      const interval = setInterval(logoutIfNotAuthenticated, 1000 * 60);
      return () => clearInterval(interval);
    }, []);

    api.setAuthToken(props.auth.getAccessToken);
    api.setUserGetter(props.auth.getUser);
    return <C {...props} />;
  });
}
