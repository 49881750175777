import * as React from 'react';
import { MissingWebflowItem } from '../../../lib/store/templates-manage/types';
import CustomTable, { ICustomTableCol } from '../../common/table';
import LineLoader from '../../common/LineLoader';
import { copyToClipboard } from '../../../util/commonUtils';
import { SectionLoader } from '../../common/SectionLoader';

type OwnProps = {
  fetching: boolean;
  webflowRecords: MissingWebflowItem[];
};

interface MissingCmsState {}

export class MissingCms extends React.Component<OwnProps, MissingCmsState> {
  state = {};

  render() {
    let { fetching, webflowRecords } = this.props;
    return (
      <div>
        <h4>
          Missing CMS Records ({webflowRecords ? webflowRecords.length : 0})
        </h4>
        {fetching ? (
          <SectionLoader />
        ) : (
          <div style={{ marginLeft: '10px' }}>
            <CustomTable
              maxHeight={'300px'}
              emptyText={'Hurray! No Webflow item exists without templates'}
              options={null}
              data={webflowRecords}
              columns={cols}
            />
          </div>
        )}
      </div>
    );
  }
}

const cols: ICustomTableCol[] = [
  {
    dataField: 'name',
    text: 'Name',
    colName: 'Name',
    formatter: (cell: string, row: MissingWebflowItem, index) => {
      return (
        <a target={'_blank'} href={row.url}>
          {cell}
        </a>
      );
    },
  },
  {
    dataField: 'description',
    text: 'Description',
    colName: 'Description',
    formatter: (cell: string, row: MissingWebflowItem, index) => {
      return <div dangerouslySetInnerHTML={{ __html: cell }} />;
    },
  },
  {
    dataField: '',
    text: '',
    colName: 'json_copy',
    isDummyField: true,
    headerStyle: { width: '90px' },
    formatter: (cell: string, row: MissingWebflowItem, index) => {
      let { cmsItemId, slug, publishedOn } = row;
      return (
        <a
          className={'text-decoration-nonde'}
          onClick={() =>
            copyToClipboard(
              JSON.stringify({ cmsItemId, slug, publishedOn }, null, 4),
            )
          }
        >
          Copy json
        </a>
      );
    },
  },
];
