import React, { Component } from 'react';
import { OrgUsers } from './OrgUsers';
import {
  CategoryChildProps,
  withCategories,
} from '../workflows/common/withCategories';
import { OrgGroups } from './OrgGroups';
import { Tenant } from '../../lib/store/tenants/types';
import { AppState } from '../../lib/store';
import { Dispatch } from 'redux';
import { fetchTenantDetails } from '../../lib/store/tenants/action';
import { connect } from 'react-redux';
import { SectionLoader } from '../common/SectionLoader';

type OwnProps = CategoryChildProps & { user: string };
type StateProps = {
  tenant: Tenant;
  isFetchingTenant: boolean;
};
type DispatchProps = {
  fetchTenant: () => void;
};
type IProps = OwnProps & StateProps & DispatchProps;

class UserManagementInner extends Component<IProps> {
  componentDidMount(): void {
    if (!this.props.tenant) {
      this.props.fetchTenant();
    }
  }
  render() {
    if (this.props.isFetchingTenant || !this.props.user) {
      return <SectionLoader />;
    }
    if (!this.props.tenant) {
      return (
        <div className="m-5">
          <h3>
            Contact us to enable support for organisation and user management
          </h3>
        </div>
      );
    }
    if (this.props.tenant && !this.props.tenant.isSubjectAdmin) {
      return (
        <div className="m-5">
          <h3>
            Organisation settings can only be modified by owners.
            <br />
            Please contact{' '}
            {this.props.tenant.orgAdmins &&
              this.props.tenant.orgAdmins.join(',')}
            .
          </h3>
        </div>
      );
    }
    if (this.props.selectedCategory === 'users')
      return <OrgUsers user={this.props.user} />;
    else if (this.props.selectedCategory === 'groups') return <OrgGroups />;
    else return null;
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetchingTenant: state.tenantConfig.isFetchingTenantDetails,
    tenant: state.tenantConfig.tenant,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchTenant: () => fetchTenantDetails({ dispatch }),
  };
}

const StoreWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserManagementInner);

const UserManagementCategoryWrapper = withCategories(StoreWrapper);

export const UserManagement = function(props: { user: string }) {
  const categories = [
    {
      label: 'Users',
      value: 'users',
    },
    {
      label: 'Groups',
      value: 'groups',
    },
  ];
  return (
    <UserManagementCategoryWrapper categories={categories} user={props.user} />
  );
};
