import { Workflow } from '../lib/store/workflows/types';

export const isLengthyArray = (item: any) =>
  Array.isArray(item) && item.length > 0;

export const capitalize = (string: string) =>
  string && string.charAt(0).toUpperCase() + string.slice(1);

export const copyToClipboard = (content: string): void => {
  if (navigator && (navigator as any).clipboard) {
    (navigator as any).clipboard.writeText(content);
  }
};
export const addSimilarKeyValueInArrayOfObject = (data) => {
  let temp = {};
  let modifiedArray = [];
  if (data.length > 0) {
    data.forEach(function(d) {
      if (temp.hasOwnProperty(d.name)) {
        temp[d.name] = temp[d.name] + d.value;
      } else {
        temp[d.name] = d.value;
      }
    });
    for (var prop in temp) {
      modifiedArray.push({ name: prop, value: temp[prop] });
    }
    return modifiedArray;
  }
  return [];
};

export const getAccountFromWorkflow = (workflows, selectedWorkflow) => {
  let workflow = workflows.find((wf) => wf.workflow === selectedWorkflow);
  return getWorkflowAccount(workflow);
};

export const getWorkflowAccount = (workflow: Workflow) => {
  let category =
    workflow &&
    workflow.properties &&
    workflow.properties.categories &&
    workflow.properties.categories.find((cat) => cat.category === 'Account');
  return category && category.subCategories[0];
};

export const getAllAccountIdsFromWorkflow = (workflows) => {
  let accountIds = workflows.map(getWorkflowAccount);
  return accountIds.filter((x, i, a) => a.indexOf(x) === i);
};

export function getTenantName() {
  const domain = window.location.hostname;
  const parts = domain.split('.');
  if (parts.length === 3 && parts[0] !== 'app') {
    /// only applies when url looks like {tenant}.totalcloud.io
    return capitalize(parts[0]);
  }
  return 'TotalCloud';
}

export function getLogo_light() {
  const tenant = getTenantName();
  return `/img/${tenant}_logo_light.png`.toLowerCase();
}

export function getLogo_dark() {
  const tenant = getTenantName();
  return `/img/${tenant}_logo_dark.png`.toLowerCase();
}
