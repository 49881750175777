import { Link } from 'react-router-dom';
import { setPropertyInStore } from '../../../common/withRegionAndCredentials';
import * as React from 'react';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { Badge } from 'reactstrap';
import { VIEW } from '../workflowTable';

export const formatAccountCol = (col, row) => {
  return (
    <span>
      <Link
        to={getLink(row.tabName)}
        onClick={() =>
          row.updateConfigAndRegion('account', [row.accountId || row.id])
        }
      >
        {col || row.name}
      </Link>
    </span>
  );
};

export const formatSummaryAccountLabelCol = (col, row) => {
  return (
    <span>
      <Link
        to={getLink(row.tabName)}
        onClick={() => {
          if (row.isLeaf) {
            row.changeView(VIEW.SERVICE);
          } else {
            row.changeView(VIEW.TABLE);
          }
          row.updateConfigAndRegion('account', row.accountIds);
        }}
      >
        {col}
      </Link>
    </span>
  );
};

const getLink = (tabName: TAB_NAME) => {
  if (tabName === TAB_NAME.UTILISATION_SUMMARY) return '/utilisation';
  if (tabName === TAB_NAME.INVENTORY_GLOBAL) return '/inventory';
};

export const formatAccountLabelCol = (col, row) => {
  if (!col || !Array.isArray(col)) return '';
  return col.map((name, key) => (
    <Badge key={key} color="light" className="ml-0">
      {name}
    </Badge>
  ));
};
