export interface Subscription {
  trial_end: number;
  status: 'active' | 'in_trial';
  plan_id: 'free' | 'flexi';
  plan_amount: number;
  addons: {
    quantity: number;
    unit_price: number;
    amount: number;
  }[];
}

// events
export const RequestSubscriptionStatus = 'RequestSubscriptionStatus';
export const ReceivedPortalToken = 'ReceivedPortalToken';
export const SubscriptionStatus = 'SubscriptionStatus';
export const RequestPortalToken = 'RequestPortalToken';

interface RequestSubscriptionStatus {
  type: typeof RequestSubscriptionStatus;
}

interface SubscriptionStatus {
  type: typeof SubscriptionStatus;
  data: Subscription;
}

interface RequestPortalToken {
  type: typeof RequestPortalToken;
}

interface ReceivedPortalToken {
  type: typeof ReceivedPortalToken;
  url: string;
}

export type SubscriptionAction =
  | RequestSubscriptionStatus
  | SubscriptionStatus
  | ReceivedPortalToken
  | RequestPortalToken;

export type SubscriptionResponse = {
  subscription: Subscription;
  customer: any;
};

export enum TrialStatus {
  FreePlan = 'free_plain',
  PaidPlan = 'paid_plan',
  Trial = 'in_trial',
  Expired = 'expired',
}

export type SubscriptionInfo = {
  status: TrialStatus;
  daysLeft?: number;
  planId?: string;
  planAmount?: number;
  trial_end?: number;
};

export interface SubscriptionState {
  fetching: boolean;
  fetchingPortalToken: boolean;
  subscription: SubscriptionInfo | null;
  portalUrl: string | null;
}
