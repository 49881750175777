import * as React from 'react';
import { Redirect } from 'react-router';
import { AppState } from '../../lib/store';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { fetchConfig } from '../../lib/store/config/action';
import { PageLoader } from './loaders';
import { CONFIG_TYPES } from 'webapp-genric';

type StateProps = {
  isFetching: boolean;
  notHasConfig: boolean;
  initialDataIsAbsent: boolean;
  skipOnBoarding: boolean;
};

type DispatchProps = {
  fetchConfig: () => void;
};

type OwnProps = {
  render: () => JSX.Element;
};

type IProps = StateProps & DispatchProps & OwnProps;

export function OnBoardingInterceptInner(props: IProps): JSX.Element {
  React.useEffect(() => {
    if (props.initialDataIsAbsent) {
      props.fetchConfig();
    }
  });

  if (props.skipOnBoarding) return props.render();

  if (!props.isFetching && props.notHasConfig) {
    return <Redirect to={'/getting-started'} />;
  }

  if (props.initialDataIsAbsent) {
    return <PageLoader />;
  }

  return props.render();
}

function mapStateToProps(state: AppState): StateProps {
  const hasValidConfigs =
    state.config.data &&
    state.config.data.filter((c) => c.type !== CONFIG_TYPES.RESTRICTIONS)
      .length === 0;

  return {
    skipOnBoarding: state.onboarding.adoptionSkipped,
    isFetching: state.config.fetching,
    notHasConfig: hasValidConfigs,
    initialDataIsAbsent: state.config.data === null,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
): DispatchProps {
  return {
    fetchConfig: () => fetchConfig({ dispatch }),
  };
}

export const OnBoardingIntercept = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OnBoardingInterceptInner);
