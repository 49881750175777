import * as Yup from 'yup';
import {
  Alarm,
  AlarmTrigger,
  ViewType,
  CronSchedule,
  HttpTrigger,
  RateSchedule,
  ScheduleTrigger,
  TriggerNodeData,
  ParsedCronSchedule,
  ScheduleType,
  EventTrigger,
  EventTriggerValue,
} from 'workflow-model/dist';

import cron from 'cron-validate';
import { MultiScheduleSchema } from '../../../../../../components/common/MultiScheduleForm/schema';

export const testSchedule = {
  name: 'cron-valid',
  test: function(value: any) {
    if (value.type === ScheduleType.CRON) {
      return !value || !value.date || !value.days || !value.days.length
        ? this.createError({
            message: `invalid schedule`,
            path: `${this.path}.days`,
          })
        : true;
    }
    if (value.type === ScheduleType.PARSED_CRON) {
      let cronString = value.value;
      if (Boolean(cronString)) {
        let cronValues: string[] = cronString.split(':');
        let valid = true;
        for (let i = 0; i < cronValues.length; i++) {
          valid = cron(cronValues[i].trim(), {
            preset: 'aws-cloud-watch',
          }).isValid();

          if (!valid) {
            break;
          }
        }
        return !valid
          ? this.createError({
              message: `Invalid cron expression. Valid format: minute hour day-of-month month day-of-week year`,
              path: `${this.path}.value`,
            })
          : true;
      } else {
        return this.createError({
          message: `Invalid cron expression. Valid format: minute hour day-of-month month day-of-week year`,
          path: `${this.path}.value`,
        });
      }
    }
    if (value.type === ScheduleType.RATE) {
      if (!value.value)
        return this.createError({
          message: 'Invalid schedule value',
          path: `${this.path}.value`,
        });
      return true;
    }
    return true;
  },
};

export const baseScheduleObj = Yup.object().shape<CronSchedule>({
  // @ts-ignore
  type: Yup.string()
    .oneOf([ScheduleType.RATE, ScheduleType.CRON, ScheduleType.PARSED_CRON])
    .required(),
  value: Yup.string(),
  days: Yup.array().of(Yup.string().matches(/(Mon|Tue|Wed|Thu|Fri|Sat|Sun)/)),
  date: Yup.string().matches(/[0-9]{2}:[0-9]{2}/),
  userSchema: Yup.mixed<any>(),
});
export const S1 = baseScheduleObj.test(testSchedule);

export const triggerSchema = Yup.object().shape<TriggerNodeData>({
  alarm: Yup.object().shape<AlarmTrigger>({
    active: Yup.boolean(),
    value: Yup.object()
      .shape({
        namespace: Yup.string(),
        metricNames: Yup.array().of(Yup.string()),
        statistics: Yup.array().of(Yup.string()),
        dimensions: Yup.string(),
      })
      .when('active', {
        is: true,
        then: Yup.object().shape({
          namespace: Yup.string().required(),
          metricNames: Yup.array()
            .of(Yup.string())
            .required(),
          statistics: Yup.array().of(Yup.string()),
          dimensions: Yup.string(),
        }),
      }),
  }),
  http: Yup.object().shape<HttpTrigger>({
    active: Yup.boolean(),
    value: Yup.object().shape({
      url: Yup.string(),
      userSchema: Yup.mixed<any>(),
      inputTransformers: Yup.array().of(
        Yup.object({
          link: Yup.string().required('please select a logic type'),
          code: Yup.object().when('link', {
            is: 'custom',
            then: Yup.object({})
              .shape({
                data: Yup.string().required('Please enter valid code'),
                error: Yup.boolean()
                  .required('Invalid error status')
                  .test('function_error', 'Please enter a valid code', function(
                    value,
                  ) {
                    return !value;
                  }),
              })
              .required('Please Enter valid code'),
            otherwise: Yup.object({
              data: Yup.string(),
              error: Yup.boolean(),
            }),
          }),
        }),
      ),
      overrideGlobalVariables: Yup.boolean(),
    }),
  }),
  schedule: Yup.object().shape<ScheduleTrigger>({
    active: Yup.boolean(),
    value: Yup.array()
      .of<CronSchedule | RateSchedule | ParsedCronSchedule>(baseScheduleObj)
      .when('active', {
        is: true,
        then: Yup.array().of<CronSchedule | RateSchedule | ParsedCronSchedule>(
          S1,
        ),
        otherwise: Yup.array().of<
          CronSchedule | RateSchedule | ParsedCronSchedule
        >(baseScheduleObj),
      }),
  }),
  event: Yup.object().shape<EventTrigger>({
    active: Yup.boolean(),
    value: Yup.object()
      .shape<EventTriggerValue>({
        eventIds: Yup.array().of(Yup.string()),
        pattern: Yup.mixed(),
      })
      .when('active', {
        is: true,
        then: Yup.object().shape({
          eventIds: Yup.array()
            .of(Yup.string())
            .required('Select at least 1 event'),
          pattern: Yup.mixed()
            .test(
              'is-pattern-valid',
              'Pattern is not valid',
              (value) => JSON.stringify(value) !== '{}',
            )
            .test(
              'is-optional-resource-present',
              'Replace <$optionalResources> with resource id',
              (value) => !JSON.stringify(value).includes('$optionalResources'),
            ),
        }),
      }),
  }),
  view: Yup.mixed<ViewType>(),
});

export type TriggerSchema = TriggerNodeData;

export const eventExplorerSchema = Yup.object().shape({
  selectedDepth: Yup.array().of(Yup.string()),
  selectedEvents: Yup.array().of(Yup.mixed()),
  pattern: Yup.object().shape({
    data: Yup.string(),
    error: Yup.boolean().oneOf([false]),
  }),
});

export type EventEditor = Yup.InferType<typeof eventExplorerSchema>;
