import { RecommendationAction, RecommendationStore } from './types';
import { Reducer } from 'redux';

const initialState: RecommendationStore = {
  isFetchingRecommendationWorkflows: false,
  policies: {},
  recommendationWorkflows: null,
  isFetchingRecommendationResources: false,
  recommendations: null,
  isDeleting: false,
  isSaving: false,
  isFetchingPolicy: false,
};

export const recommendationReducer: Reducer<
  RecommendationStore,
  RecommendationAction
> = (state = initialState, action) => {
  switch (action.type) {
    case 'RecommendationConfig':
      return { ...state, isFetchingRecommendationWorkflows: true };
    case 'FetchRecommendationConfigCompleted':
      return {
        ...state,
        isFetchingRecommendationWorkflows: false,
        recommendationWorkflows: action.data || state.recommendationWorkflows,
      };
    case 'FetchRecommendationResource':
      return { ...state, isFetchingRecommendationResources: true };
    case 'FetchRecommendationResourceCompleted':
      return {
        ...state,
        isFetchingRecommendationResources: false,
        recommendations: action.data || state.recommendations,
      };
    case 'SaveRecommendationConfig':
      return { ...state, isSaving: true };
    case 'SaveRecommendationConfigCompleted':
      return { ...state, isSaving: false };
    case 'DeleteRecommendationConfig':
      return { ...state, isDeleting: true };
    case 'DeleteRecommendationConfigCompleted':
      return { ...state, isDeleting: false };
    case 'FetchTemplatePolicy':
      return { ...state, isFetchingPolicy: true };
    case 'FetchTemplatePolicyCompleted':
      return {
        ...state,
        isFetchingPolicy: false,
        policies: {
          ...state.policies,
          [action.templateId]: action.data || state.policies[action.templateId],
        },
      };
    default:
      return state;
  }
};
