import { TableItem, TemplateColNames } from '../cols';
import { loader } from './loader';
import * as React from 'react';

export const LinkedTemplatesDisplay = (
  linkedTemplates: string[],
  item: TableItem,
) => {
  if (!item.templateItem || !item.templateItem.id) return '';
  if (item.updating === TemplateColNames.LINKED_TEMPLATES) return loader();
  let name = item.templatesToLink
    .filter((x) => linkedTemplates.indexOf(x.value) !== -1)
    .map((x) => x.label)
    .join(',');
  return (
    <span className={'text-truncate d-flex'} title={name}>
      {name}{' '}
    </span>
  );
};
