import * as React from 'react';
import { FormikProps } from 'formik';

import { Col, Modal, Row } from 'reactstrap';

import CustomRadioButtons, {
  ORIENTATION,
} from '../../../lib/formInputs/radio-button.component';

import { SectionLoader } from '../../common/SectionLoader';
import { ModalWrapper } from '../../../lib/editor/components/ui/common/ModalWrapper';
import { viewType } from './workflowReportForm';
import { TriggerSchema } from '../../../lib/editor/components/ui/nodeUI/trigger/schema';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { MultiScheduleForm } from '../../common/MultiScheduleForm/MultiScheduleForm';
import SwitchContainer from '../../../lib/switch/SwitchContainer';

interface FormProps extends FormikProps<TriggerSchema> {
  tabName: TAB_NAME;
  onClose: () => void;
  view: viewType;
  toggleRadio: (event) => void;
  isFetching: boolean;
  reportingAction: boolean;
  renderActiveToggle?: boolean;
  toggleActiveValue?: boolean;
  toggleActiveHandler?: (value) => void;
}

const helpText = (props: FormProps) => (
  <div>
    <p>
      You can configure these settings to periodically trigger {props.tabName}.
    </p>
    <p>
      All the Schedule/Cron time will be evaluated in UTC format. Time selected
      or entered should correspond to their UTC time.
    </p>
  </div>
);

export class TriggerWorkflowForm extends React.Component<FormProps> {
  eventHandlers = {
    onChange: this.props.handleChange,
    onBlur: this.props.handleBlur,
  };

  componentDidUpdate(
    prevProps: Readonly<FormProps>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    if (this.props.initialValues && !prevProps.initialValues) {
      this.props.setValues(this.props.initialValues);
    }
    if (
      this.props.view !== prevProps.view &&
      this.props.view === viewType.NOW
    ) {
      this.props.resetForm();
    }
  }

  renderView = () => {
    let { view } = this.props;
    if (view === viewType.NOW) {
      return (
        <Row>
          <Col md={12} className="my-3">
            {`Run all ${this.props.tabName} now`}
          </Col>
        </Row>
      );
    } else {
      return <MultiScheduleForm {...this.props} />;
    }
  };

  getSaveButtonText = (): string => {
    let { view } = this.props;
    if (view === viewType.NOW)
      return this.props.reportingAction ? 'Running..' : 'Run now';
    return this.props.reportingAction ? 'Saving..' : 'Save';
  };

  /**
   * Renders the toggle active button to enable disable the trigger workflow
   */
  renderActiveToggleButton = () => {
    const {
      renderActiveToggle,
      toggleActiveHandler,
      toggleActiveValue,
    } = this.props;
    return (
      renderActiveToggle &&
      toggleActiveHandler && (
        <Row className={''}>
          <Col className={'py-3'}>
            Enable Trigger
            <SwitchContainer
              size={'small'}
              value={toggleActiveValue}
              onChange={toggleActiveHandler}
            />
          </Col>
        </Row>
      )
    );
  };

  render(): React.ReactNode {
    return (
      <Modal
        size={'xl'}
        isOpen={true}
        toggle={this.props.onClose}
        backdrop={'static'}
      >
        <ModalWrapper
          submitDisabled={this.props.isFetching || this.props.reportingAction}
          text={{ save: this.getSaveButtonText() }}
          title={`Refresh ${this.props.tabName} for all AWS Accounts and Regions`}
          onCancel={this.props.onClose}
          onSubmit={this.props.submitForm}
          helpText={helpText(this.props)}
        >
          {(this.props.isFetching || !this.props.values.schedule) &&
          !this.props.reportingAction ? (
            <SectionLoader />
          ) : (
            <>
              {this.renderActiveToggleButton()}
              <Row className={'text-center '}>
                <Col>
                  <CustomRadioButtons
                    onChange={this.props.toggleRadio}
                    options={[
                      { label: 'Run Later', value: viewType.LATER },
                      { label: 'Run Now', value: viewType.NOW },
                    ]}
                    name={'group'}
                    value={this.props.view}
                    orientation={ORIENTATION.horizontal}
                  />
                </Col>
              </Row>
              {this.renderView()}
            </>
          )}
        </ModalWrapper>
      </Modal>
    );
  }
}
