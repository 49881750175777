export const ACTIONS_MAP: {
  [serviceMethod: string]: { service: string; action: string }[];
} = {
  'EC2:describeInstances': [
    {
      service: 'EC2',
      action: 'startInstances',
    },
    {
      service: 'EC2',
      action: 'stopInstances',
    },
    {
      service: 'EC2',
      action: 'rebootInstances',
    },
    {
      service: 'EC2',
      action: 'terminateInstances',
    },
  ],
  'RDS:describeDBInstances': [
    {
      service: 'RDS',
      action: 'startDBInstance',
    },
    {
      service: 'RDS',
      action: 'stopDBInstance',
    },
    {
      service: 'RDS',
      action: 'rebootDBInstance',
    },
    {
      service: 'RDS',
      action: 'deleteDBInstance',
    },
  ],
  'S3:listBuckets': [
    {
      service: 'S3',
      action: 'deleteBucket',
    },
    {
      service: 'S3',
      action: 'deleteBucketPolicy',
    },
    {
      service: 'S3',
      action: 'putBucketAcl',
    },
    {
      service: 'S3',
      action: 'deleteObject',
    },
    {
      service: 'S3',
      action: 'putObject',
    },
  ],
  'EC2:describeVolumes': [
    {
      service: 'EC2',
      action: 'deleteVolume',
    },
    {
      service: 'EC2',
      action: 'attachVolume',
    },
    {
      service: 'EC2',
      action: 'detachVolume',
    },
    {
      service: 'EC2',
      action: 'modifyVolume',
    },
    {
      service: 'EC2',
      action: 'modifyVolumeAttribute',
    },
  ],
  'EC2:describeVpcs': [
    {
      service: 'EC2',
      action: 'deleteVpc',
    },
    {
      service: 'EC2',
      action: 'createVpcEndpoint',
    },
    {
      service: 'EC2',
      action: 'deleteVpcEndpoint',
    },
    {
      service: 'EC2',
      action: 'modifyVpcEndpoint',
    },
  ],
};
