import * as React from 'react';
import { TemplatesTable } from '../components/templatesTable';
import TemplateModalContent from '../components/TemplateModalContent';
import { CATEGORIES, SUB_CATEGORIES } from '../../../config/Categories';

import { TableItem, TemplateColNames } from '../components/cols';
import { Template } from '../../../lib/store/templates/types';
import { Category } from '../../common/categoryUtils';

interface IProps {
  showTemplateModal: Template | null;
  savingTemplate: boolean;
  toggleTempModal: (template: Template) => void;
  onSaveTemplate: (template: Template) => void;
  onSaveAndPublish: (template: Template) => void;
  categories: Category[];
  fetchingTemplates: boolean;
  tableData: TableItem[];
  templateFilter: React.ReactNode;
}

interface IState {}

export class TemplateEditor extends React.Component<IProps, IState> {
  render() {
    return (
      <div>
        {this.props.showTemplateModal && (
          <TemplateModalContent
            isFetching={this.props.savingTemplate}
            categories={CATEGORIES}
            subCategories={SUB_CATEGORIES}
            templateDef={this.props.showTemplateModal}
            closePopup={this.props.toggleTempModal}
            onSave={this.props.onSaveTemplate}
            onSaveAndPublish={this.props.onSaveAndPublish}
          />
        )}
        <TemplatesTable
          options={this.props.templateFilter}
          title={'All Templates'}
          categories={this.props.categories}
          fields={[
            TemplateColNames.TEMPLATE_NAME,
            TemplateColNames.TEMPLATE_CATEGORY,
            TemplateColNames.TEMPLATE_PRIORITY,
            TemplateColNames.TEMPLATE_QUEUEABLE,
            TemplateColNames.TEMPLATE_OPT,
            TemplateColNames.TEMPLATE_STATUS,
            TemplateColNames.CLOUD_TYPES,
          ]}
          tableData={this.props.tableData}
          fetching={this.props.fetchingTemplates}
        />
      </div>
    );
  }
}
