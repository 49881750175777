import * as React from 'react';
import { Button } from 'reactstrap';
import {
  SubscriptionState,
  TrialStatus,
  SubscriptionInfo,
} from '../../lib/store/billing/types';

interface IProps {
  subscription: SubscriptionInfo;
}
export class PlanInfo extends React.Component<IProps> {
  getInfo(status: TrialStatus): React.ReactNode {
    const { subscription } = this.props;
    switch (status) {
      case TrialStatus.Trial:
        return <>{subscription.daysLeft ? `${subscription.daysLeft}` : null}</>;
      case TrialStatus.Expired:
        return `Trial period has ended.`;
      case TrialStatus.FreePlan:
        return `Upgrade`;
      case TrialStatus.PaidPlan:
        return null;
      default:
        return null;
    }
  }

  render() {
    if (this.props.subscription.status === null) {
      return null;
    }

    const trialText = this.getInfo(this.props.subscription.status);

    if (trialText) {
      return <span data-test="trial-message">{trialText}</span>;
    }

    return null;
  }
}
