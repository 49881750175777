import {
  ActionModals,
  CreatorProps,
} from 'webapp-genric/dist/super-table/model/table';
import React from 'react';
import { CloneWorkflow } from '../workflows/common/clone';
import { WorkflowPolicy } from '../workflows/common/policy';
import ShareLinkWorkflowModal from '../workflows/common/ShareLinkWorkflowModal';
import { TAB_NAME } from 'webapp-genric';
import { Adoption } from '../templates-page/Adoption';
import { AdoptionV2 } from '../templates-page/Adoption.v2';
import { TableModalProps } from 'super-table/dist/components/Table/types';
import { ModalForEditFixV2 } from '../workflows/common/ChangeFixModal.v2';
import { ActionModal } from '../manage-workflow/views/ActionModal';
import { SUPPORT_ACTIONS } from 'webapp-genric/dist/support';

export const modalRenderer = (props: TableModalProps) => {
  const {
    creator,
    show,
    onSave,
    onHide,
    onCancel,
    modal,
    credentials,
    regions,
    handlers,
  } = props;
  const { row } = creator;

  const renderModal = () => {
    switch (modal) {
      case ActionModals.LINK_FIX:
        return (
          <ModalForEditFixV2
            parentWorkflowId={row.workflow}
            toggleModal={onCancel}
            templateId={row.linkedTemplate}
            templateName={''}
            autoRunLinkedWorkflows={row.autoRunLinkedWorkflows}
            parentAccount={row.accountId}
            parentRegion={row.regionId}
            linkedWorkflowId={row.linkedWorkflow}
          />
        );
      case ActionModals.WORKFLOW_CLONE:
        return <CloneWorkflow workflow={row.workflow} onClose={onHide} />;
      case ActionModals.WORKFLOW_POLICY:
        return <WorkflowPolicy workflow={row.workflow} onClose={onHide} />;
      case ActionModals.WORKFLOW_SHARE:
        return (
          <ShareLinkWorkflowModal
            workflow={row.workflow}
            tabName={TAB_NAME.WORKFLOWS}
            toggleShareLinkModal={onHide}
            updateShareToken={(token) => {
              // console.log(token);
            }}
            previousShareToken={null}
          />
        );

      case ActionModals.ADOPT_TEMPLATE:
        return (
          <AdoptionV2
            selectedRegion={''}
            hideWfOption={true}
            selectedAccountId={credentials && credentials[0]}
            templates={[row.id]}
            onCompleted={() => {
              onCancel();
              handlers.handleRefresh();
            }}
            onCancel={onCancel}
          />
        );
      case ActionModals.WORKFLOW_ACTION_DISABLE:
        return (
          <ActionModal
            open={true}
            onClose={onCancel}
            payload={{
              action: SUPPORT_ACTIONS.DISABLE_WORKFLOW,
              workflow: creator.rows,
            }}
          />
        );

      case ActionModals.WORKFLOW_ACTION_ENABLE:
        return (
          <ActionModal
            open={true}
            onClose={onCancel}
            payload={{
              action: SUPPORT_ACTIONS.ENABLE_WORKFLOW,
              workflow: creator.rows,
            }}
          />
        );
      default:
        return <div className={'text-danger'} />;
    }
  };

  return renderModal();
};
