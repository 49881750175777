import { default as AntSwitch } from 'antd/lib/switch';
import React from 'react';

interface IProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  size?: 'small';
}

/**
 * use with dropdown elements only where component is destroyed very often
 * Use stateless implementation otherwise
 */
const SwitchContainer = function(props: IProps) {
  const [isActive, setActive] = React.useState(props.value);
  // intercepting this here to show transition before actual action happens
  const toggle = () => {
    setActive(!isActive);

    // Adding a delay in action so that toggle effect is visible
    setTimeout(() => props.onChange(!isActive), 300);
  };
  return (
    <AntSwitch
      className={'mx-2'}
      checked={isActive}
      disabled={props.disabled}
      onChange={toggle}
      size={props.size}
    />
  );
};

export default SwitchContainer;
