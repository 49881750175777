import * as React from 'react';
import { SettingIndex } from '../components/setting';
import { Provider } from 'react-redux';
import { store } from '../lib/store';
import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { AppContainer } from './style';
import { OnBoardingIntercept } from '../components/common/onBoardingIntercept';
import { SidebarWrapper } from '../components/common/sideBarWrapper';
import { Route, Switch } from 'react-router-dom';
import { DeleteAccount } from '../components/setting/components/DeleteAccount';
import { DeleteAccountPage } from '../components/setting/DeleteAccount';

class SettingsPage extends React.Component<SecureRouteProps> {
  renderInner = () => (
    <Provider store={store}>
      <AppContainer fluid={true}>
        <SidebarWrapper {...this.props}>
          <Switch>
            <Route
              path={'/settings/delete-account'}
              exact={true}
              component={DeleteAccountPage}
            />
            <Route
              path={'/'}
              component={(routeProps) => (
                <SettingIndex {...this.props} {...routeProps} />
              )}
            />
          </Switch>
        </SidebarWrapper>
      </AppContainer>
    </Provider>
  );

  render(): React.ReactNode {
    return (
      <Provider store={store}>
        <OnBoardingIntercept render={this.renderInner} />
      </Provider>
    );
  }
}

export default withTcApi(SettingsPage);
