import React from 'react';
import * as Yup from 'yup';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import InputText from '../../lib/formInputs/input-text.component';
import { loadOrgUser } from '../../lib/login/common/loadOrgUser';
import { OktaConfig } from '../../config/okta';

const formSchema = Yup.object().shape({
  domain: Yup.string().required(),
});

type FormDataType = Yup.InferType<typeof formSchema>;

const initialValue: FormDataType = {
  domain: '',
};

export class SupportLogin extends React.Component {
  handleFormSubmit = (
    value: FormDataType,
    action: FormikActions<FormDataType>,
  ) => {
    action.setSubmitting(true);
    loadOrgUser(value.domain)
      .then((success) => {
        if (success) OktaConfig.persist();
        window.location.href = '/okta-login';
        action.setSubmitting(false);
      })
      .catch((e) => {
        console.error(e);
        action.setSubmitting(false);
      });
  };

  render() {
    return (
      <Formik<FormDataType>
        onSubmit={this.handleFormSubmit}
        initialValues={initialValue}
        validationSchema={formSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props: FormikProps<FormDataType>) => {
          return (
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={6}>
                <Form className="mt-5 pt-5">
                  <h3>Customer Email</h3>
                  <FormGroup>
                    <InputText
                      name={'domain'}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.errors.domain}
                      value={props.values.domain}
                      placeholder={'shubham@example.com'}
                    />
                    <Button
                      color={'primary'}
                      type={'submit'}
                      onClick={() => props.handleSubmit()}
                      className={'mt-2'}
                      disabled={props.isSubmitting}
                    >
                      Login
                      {props.isSubmitting && (
                        <i className="ml-2 fa fa-hourglass-1 fa-spin" />
                      )}
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          );
        }}
      </Formik>
    );
  }
}
