import * as React from 'react';
import { TemplatesTable } from '../components/templatesTable';
import TemplateModalContent from '../components/TemplateModalContent';
import { CATEGORIES, SUB_CATEGORIES } from '../../../config/Categories';

import { TableItem, TemplateColNames } from '../components/cols';
import { Template } from '../../../lib/store/templates/types';
import { Category } from '../../common/categoryUtils';
import { TestTemplateModal } from '../components/testTemplateModal';
import { Config } from '../../../lib/store/config/types';
import { getAccountConfig } from '../../common/withRegionAndCredentials';

interface IProps {
  showTemplateModal: Template | null;
  savingTemplate: boolean;
  toggleTempModal: (template: Template) => void;
  onSaveTemplate: (template: Template) => void;
  onSaveAndPublish: (template: Template) => void;
  categories: Category[];
  fetchingTemplates: boolean;
  tableData: TableItem[];
  templateFilter: React.ReactNode;
  onSelect: (row: TableItem, isSelect) => void;
  onSelectAll: (isSelect, rows: TableItem[]) => void;
  configs: Config[] | null;
  selectedTemplateIds: string[];
  closeTemplateTestModal: () => void;
  showTemplateTestModal: boolean;
  testTemplates: (params: {
    templateIds: string[];
    region: string;
    account: string;
    resourceGroup: string;
  }) => void;
}

interface IState {}

export class TemplateTestor extends React.Component<IProps, IState> {
  render() {
    return (
      <div>
        {this.props.showTemplateTestModal && (
          <TestTemplateModal
            templateIds={this.props.selectedTemplateIds}
            onClose={this.props.closeTemplateTestModal}
            onSave={this.props.testTemplates}
            configs={this.props.configs}
            allAWSConfigs={getAccountConfig(this.props.configs).map((u) => ({
              label: u.name,
              value: u.id,
            }))}
          />
        )}
        {this.props.showTemplateModal && (
          <TemplateModalContent
            isFetching={this.props.savingTemplate}
            categories={CATEGORIES}
            subCategories={SUB_CATEGORIES}
            templateDef={this.props.showTemplateModal}
            closePopup={this.props.toggleTempModal}
            onSave={this.props.onSaveTemplate}
            onSaveAndPublish={this.props.onSaveAndPublish}
          />
        )}
        <TemplatesTable
          options={this.props.templateFilter}
          title={'All Templates'}
          categories={this.props.categories}
          onSelect={this.props.onSelect}
          onSelectAll={this.props.onSelectAll}
          fields={[
            TemplateColNames.TEMPLATE_NAME,
            TemplateColNames.CREATE_STACK_WORKFLOW_NAME,
            TemplateColNames.TEST_STATUS,
            TemplateColNames.TESTED_ON,
            TemplateColNames.TEST_SETTING,
            TemplateColNames.TEMPLATE_OPT,
          ]}
          tableData={this.props.tableData}
          fetching={this.props.fetchingTemplates}
        />
      </div>
    );
  }
}
