import { Overview, OverviewDuration } from '../../../lib/store/overview/types';
import * as React from 'react';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { fetchOverviewData } from '../../../lib/store/overview/action';
import OverviewContainer from './overview';
import Row from 'reactstrap/lib/Row';
import { SectionLoader } from '../../common/SectionLoader';

interface StateProps {
  overview: Overview | null;
  isFetching: boolean;
}

interface DispatchProps {
  getOverview: () => void;
}

interface OwnProps {
  duration: OverviewDuration;
  intervals: Array<{ value: string; label: string }>;
  updateTimePeriod: (data?: any) => void;
}

type IProps = StateProps & DispatchProps & OwnProps;

export class OverviewInterceptInner extends React.Component<IProps> {
  componentDidMount(): void {
    this.props.getOverview();
  }

  render(): React.ReactNode {
    if (!this.props.overview && !this.props.isFetching) {
      this.props.getOverview();
    }

    let loading = this.props.isFetching && !this.props.overview;
    if (loading) return <SectionLoader />;

    return (
      <OverviewContainer
        overviewData={this.props.overview}
        intervals={this.props.intervals}
        selectedTimeperiod={this.props.duration}
        updateTimeperiod={this.props.updateTimePeriod}
      />
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetching: state.overview.fetching,
    overview: state.overview.data[ownProps.duration],
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    getOverview: () =>
      fetchOverviewData({ dispatch, duration: ownProps.duration }),
  };
}

export const OverviewIntercept = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(OverviewInterceptInner);
