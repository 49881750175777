import { PageLoader } from './loaders';
import SnackbarComponent from './snackbar';
import * as React from 'react';
import { ReportNotFoundEmbed } from './fallback/ReportNotFound';

export interface IExternalDataDependent {
  data: any;
  refreshProvider: Function;
}

export const withExternalData = function(
  Component,
  provider: Function,
  Loader = PageLoader,
  ErrorHandler = SnackbarComponent,
) {
  class IntermediateLoader extends React.Component<any, any> {
    state = {
      isReady: false,
      data: null,
      error: false,
      errorMessage: '',
    };

    async fetchData() {
      this.setState({ isReady: false });
      let data = await provider(this.props);
      this.setState({ data, isReady: true });
    }

    refresh() {
      this.fetchData().catch((err) => {
        console.error(err);
        this.setState({
          errorMessage: err.message,
          error: true,
          isReady: true,
        });
      });
    }

    componentDidMount(): void {
      //console.log(this.props);
      this.refresh();
    }

    componentDidUpdate(prevProps): void {
      if (prevProps.reportId != this.props.reportId) {
        //  console.log('EXTERNAL DEPEDENCY');
        this.forceUpdate();
      }
    }

    render(): React.ReactNode {
      const { error, errorMessage, data, isReady } = this.state;
      // if (error && errorMessage && errorMessage.trim())
      //   return <ErrorHandler message={errorMessage} type={'error'} />;
      if (error)
        return (
          <ReportNotFoundEmbed
            text={'Resource execution not found'}
            buttonText={'Inventory'}
            buttonLink={'/inventory'}
          />
        );

      if (!isReady) return <Loader />;
      const { forwardedRef } = this.props;
      return (
        <Component
          ref={forwardedRef}
          {...this.props}
          data={data}
          refreshProvider={this.refresh.bind(this)}
        />
      );
    }
  }

  return React.forwardRef<any, any>((props, ref) => {
    return <IntermediateLoader {...props} forwardedRef={ref} />;
  });
};

export const remoteAction = function(
  provider: Function,
  onSuccess: Function | null = null,
  onError: Function | null = null,
  Loader = PageLoader,
  ErrorHandler = SnackbarComponent,
) {
  return class extends React.Component {
    state = {
      isReady: false,
      data: null,
      error: false,
      errorMessage: '',
    };

    async triggerAction() {
      this.setState({ isReady: false });
      let response = await provider();
      if (!response) return;
      if (onSuccess) onSuccess(response);
      this.setState({ data: response, isReady: true });
    }

    componentDidMount(): void {
      this.triggerAction().catch((err) => {
        // console.error(err);
        if (onError) onError(err);
        this.setState({
          errorMessage: err.message,
          isReady: true,
          error: true,
        });
      });
    }

    render(): React.ReactNode {
      const { error, isReady, errorMessage } = this.state;
      if (error) return <ErrorHandler message={errorMessage} type={'error'} />;
      else if (!isReady) return <Loader />;
      return null;
    }
  };
};
