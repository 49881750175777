import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

export const SignupV2 = () => {
  return (
    <>
      <Container fluid className="bg-white px-3 px-md-0">
        <Row className="vh-100 align-items-center flex-column">
          <Col>
            <div className="py-5 text-center">
              <img
                src="/img/totalcloud_logo_dark.png"
                alt="Totalcloud Logo"
                height={56}
                width={208}
              />
            </div>

            <div className="my-3 py-3 text-center w-100 w-md-75 mx-auto">
              <h2 className="display-3 signup-page heading-lead">
                Thank you for your interest in Totalcloud. <br /> Unfortunately,
                we are currently not accepting new sign-ups.
              </h2>
            </div>
            <div className="mt-2 mb-3 text-center">
              <h4 className="font-weight-600">
                You can still login to your existing account.{' '}
                <Link to={'/login'} className="text-primary">
                  Login to Totalcloud
                </Link>
              </h4>
            </div>

            <div className="mt-5 text-center font-16">
              <h4>
                Are you still looking to sign up with us? Reach out to us at{' '}
                <a
                  href={'mailto:support@totalcloud.io'}
                  className="text-black font-weight-600"
                >
                  <strong>support@totalcloud.io</strong>
                </a>{' '}
                to learn more.
              </h4>
            </div>
            <div className="position-absolute bottom-0 left-0 w-100 bg-primary-bg text-center text-white  font-weight-bold right-0 py-3 px-5">
              All rights reserved. Copyright &copy; 2018 - 2021. Kandor Soft
              Labs Private Limited.
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
