import React, { Fragment } from 'react';
import { IResolver, IWorkflow } from 'workflow-model';
import {
  IPolicyDoc,
  PolicyStatus,
} from '../../lib/editor/util/policyValidator';
import { ValidationState } from '../../lib/formInputs/react-select';
import PolicyDocumentModal from '../../lib/editor/components/ops/components/validation/PolicyDocumentModal';
import { REGIONS } from '../../lib/editor/components/model/config/Regions';
import {
  STATUS_CONFIG,
  STATUS_CONFIG_KEY,
  STATUS_CONFIG_TYPE,
} from '../../lib/editor/components/ui/options/config';
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import { GlobalPolicyStatus } from './GlobalPolicyStatus';
import { GlobalPolicyType } from '../../lib/store/policy/types';
import { Config } from '../../lib/store/config/types';

interface OwnProps {
  text: string;
  isAuthError?: boolean;
  isFetchingPolicy: boolean;
  validate: () => void;
  policyDocument: IPolicyDoc;
  policyStatus: STATUS_CONFIG_TYPE;
  fetchPolicyDocument: (props?: {
    actions: string[];
    invalidActions?: string[];
  }) => void;
  isFetchingPolicyDocument: boolean;
  globalPolicy?: GlobalPolicyType;
  configs?: Config[];
  account: string[];
}

type IProps = OwnProps;

type IState = {
  showPolicyDocument: boolean;
};

export class ViewPolicyStatus extends React.Component<IProps, IState> {
  state = {
    showPolicyDocument: false,
  };

  hidePolicyDocument = () => {
    this.setState({ showPolicyDocument: false });
  };

  showPolicyDocument = (props?: {
    actions: string[];
    invalidActions: string[];
  }) => {
    // if (!this.props.policyDocument && !this.props.isFetchingPolicyDocument) {
    this.props.fetchPolicyDocument(props);
    // }
    this.setState({ showPolicyDocument: true });
  };

  render() {
    const {
      policyDocument,
      isFetchingPolicy,
      isFetchingPolicyDocument,
    } = this.props;

    const { showPolicyDocument } = this.state;

    return (
      <div data-test="optionsWrapper" className="d-flex align-items-center">
        {showPolicyDocument && (
          <PolicyDocumentModal
            isAuthError={this.props.isAuthError}
            text={this.props.text}
            isFetching={isFetchingPolicyDocument}
            data-test={'validationPopup'}
            showVerificationPopup={showPolicyDocument}
            closeVerificationPopup={this.hidePolicyDocument}
            validateWorkflow={() => {
              this.hidePolicyDocument();
              this.props.validate();
            }}
            recommendedPermissionJSON={
              policyDocument && policyDocument.recommendedPolicy
            }
            minimumPermissionJSON={policyDocument && policyDocument.minPolicy}
            missingPermissionJSON={
              policyDocument && policyDocument.missingPolicy
            }
          />
        )}
        {/*fetching policy*/}
        {isFetchingPolicy && (
          <>
            {' '}
            <i
              className={' mr-1 fa loading master-tc-continuous text-primary'}
            />
            Validating...
          </>
        )}

        {/* no account */}
        {!isFetchingPolicy &&
          this.props.policyStatus === PolicyStatus.NOACCOUNT && (
            <>
              <span id={'no-account-span'} className=" text-nowrap">
                <i
                  className={
                    ' mr-1 fa fa-exclamation-triangle text-warning cursor-pointer'
                  }
                />
                Select An Account
              </span>
              <UncontrolledTooltip target={'no-account-span'} delay={0}>
                No Account Selected
              </UncontrolledTooltip>
            </>
          )}

        {/* warning */}
        {!isFetchingPolicy && this.props.policyStatus === PolicyStatus.WARNING && (
          <>
            <span id={'warning-span'} className=" text-nowrap">
              <i
                onClick={this.props.validate}
                className={
                  ' mr-1 fa fa-exclamation-triangle text-warning cursor-pointer'
                }
              />
              Validation Warning
            </span>
            <UncontrolledTooltip target={'warning-span'} delay={0}>
              {this.props.isAuthError
                ? 'Missing IAM permissions'
                : 'Invalid policy'}
            </UncontrolledTooltip>
          </>
        )}

        {/* not valid */}
        {!isFetchingPolicy && this.props.policyStatus === PolicyStatus.INVALID && (
          <span className=" text-nowrap">
            <i
              title={'Invalid policy'}
              onClick={this.props.validate}
              className={
                ' mr-1 fa master-tc-continuous text-danger cursor-pointer'
              }
            />
            Validation Failed
          </span>
        )}

        {/*valid policy*/}
        {!isFetchingPolicy && this.props.policyStatus === PolicyStatus.VALID && (
          <span className=" text-nowrap">
            <i
              onClick={this.props.validate}
              className={
                'mr-2 fa master-tc-continuous text-success cursor-pointer'
              }
            />
            Policy Validated
          </span>
        )}

        {/*policy doc*/}
        {!isFetchingPolicy && (
          <i
            title={'Policy document'}
            onClick={() => this.showPolicyDocument()}
            data-test="policy-icon"
            className={'ml-2 new_icon-4list fa-2x  text-primary cursor-pointer'}
            id="tooltip-note"
          />
        )}
        {!isFetchingPolicy && this.props.globalPolicy && this.props.configs && (
          <UncontrolledDropdown place="left">
            <DropdownToggle tag="a">
              <i
                id={'globalPolicy'}
                className="fa fa-caret-down text-primary ml-2"
                aria-hidden="true"
              />
            </DropdownToggle>

            <DropdownMenu style={{ left: '-400px' }}>
              <Row className={'m-2'}>
                <Col>
                  <GlobalPolicyStatus
                    account={this.props.account}
                    globalPolicy={this.props.globalPolicy}
                    configs={this.props.configs}
                    showPolicyDocument={this.showPolicyDocument}
                  />
                </Col>
              </Row>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </div>
    );
  }
}
