import { IWorkflow } from 'workflow-model';
import { IWorkflowOperation } from '../IWorkflowOperation';
import { DATA_RESOLUTION_TYPES } from '../../../api/DataResolver';
import { IResolver } from 'workflow-model';

interface IProps {
  resolver: IResolver;
}

export class WorkflowValidator implements IWorkflowOperation {
  private props: IProps;

  constructor(props: IProps) {
    this.props = props;
  }

  public execute(
    workflow: IWorkflow,
    onComplete: (params: { error: any; value: any }) => any,
  ): void {
    if (!workflow || workflow.getNodes().length === 0) {
      throw new Error('Nothing to validate');
    }
    // if (workflow.getId()) {
    //   this.props.resolver
    //     .resolve(DATA_RESOLUTION_TYPES.VERIFY_WORKFLOW_POLICY, {
    //       workflow: workflow.getId(),
    //     })
    //     .then((x) => onComplete({ error: null, value: x }));
    // } else {
    let { definition } = workflow.serialize({ skipFullValidation: true });

    this.props.resolver
      .resolve(
        DATA_RESOLUTION_TYPES.VERIFY_WORKFLOW_POLICY,
        {
          definition,
        },
        (error) => onComplete({ error, value: null }),
      )
      .then((x) => onComplete({ error: null, value: x }))
      .catch((err) => onComplete({ error: err, value: null }));
    // }
  }
}
