import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import {
  TableSubCategories,
  TableWrapper,
  TableWrapperWithRegion,
} from '../components/tables';
import { Provider } from 'react-redux';
import { store } from '../lib/store';
import { SidebarWrapper } from '../components/common/sideBarWrapper';
import { AppContainer } from './style';
import React from 'react';
import { ViewWrapper } from './ViewWrapper';

const AutoRemediationV2 = (props: SecureRouteProps) => {
  return (
    <Provider store={store}>
      <AppContainer fluid>
        <SidebarWrapper {...props}>
          <TableWrapperWithRegion
            tabName={TAB_NAME.AUTO_REMEDIATION}
            category={'autoRemediation'}
            subCategories={[
              TableSubCategories.TABLE_DATA,
              TableSubCategories.SHARE_SETTINGS_DATA,
            ]}
            {...props}
          />
        </SidebarWrapper>
      </AppContainer>
    </Provider>
  );
};

export default withTcApi(AutoRemediationV2);
