import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-stacked100';
import { renderToString as reactDomRenderToString } from 'react-dom/server';
import { Button } from 'reactstrap';
import * as chartjs from 'chart.js';

export type IDataPoint = {
  name: string;
  value: {
    failing: number;
    passing: number;
    unknown: number;
  };
  children?: IDataPoint[];
};

function renderToString(element: JSX.Element | string): string {
  if (typeof element === 'string') return element;
  return reactDomRenderToString(element);
}

type IChartDataPoint = {
  name: string;
  id: string;
  data: {
    name: string;
    y: {
      failing: number;
      passing: number;
      unknown: number;
    };
    drilldown: string | null;
  }[];
};

function extractPoints(data: IDataPoint[], id: string) {
  const series: IChartDataPoint = { id, name: id, data: [] };
  const drill: IChartDataPoint[] = [];

  for (const point of data) {
    if (!point.children) {
      series.data.push({ name: point.name, y: point.value, drilldown: null });
    } else {
      const nextId = `${id}_${point.name}`;
      const children = extractPoints(point.children, nextId);
      series.data.push({ name: point.name, y: point.value, drilldown: nextId });
      drill.push(children.series);
      children.drill.forEach((d) => drill.push(d));
    }
  }
  return { series, drill };
}

function convertToDataset(series: IChartDataPoint) {
  const labels: string[] = [];
  const failing: number[] = [];
  const passing: number[] = [];
  const unknown: number[] = [];

  for (const e of series.data) {
    labels.push(e.name);
    failing.push(e.y.failing);
    passing.push(e.y.passing);
    unknown.push(e.y.unknown);
  }

  return {
    labels: labels,
    datasets: [
      {
        label: 'Unknown',
        backgroundColor: 'grey',
        data: unknown,
      },
      {
        label: 'Passing',
        backgroundColor: '#C0EC83',
        data: passing,
      },
      {
        label: 'Failing',
        backgroundColor: 'red',
        data: failing,
      },
    ],
  };
}

interface ISummaryChartParams {
  id: string;
  data: IDataPoint[];
  labels: {
    title: JSX.Element | string;
  };
  skipSingleValueItems?: boolean; // starts the chart such that chart is in zoomed in state when loaded
}

interface ISummaryChartState {
  current: string | null | 'root';
  history: string[];
}

export class SummaryChart extends Component<
  ISummaryChartParams,
  ISummaryChartState
> {
  state = {
    current: null,
    history: [],
  };

  zoomIn = () => {
    if (!this.props.skipSingleValueItems) return;
    const se = this.getCurrentSeries();
    if (se.data.length === 1) {
      this.moveTarget([{ _index: 0 }]);
    }
  };

  public moveTarget = (elements?: any[]) => {
    if (!elements) {
      this.setState({ current: 'root', history: [] }, this.zoomIn);
    } else {
      const e = elements[0];
      if (e) {
        const se = this.getCurrentSeries();
        const next = se.data[e._index].drilldown;
        // console.log(e._index, next);
        if (next) {
          this.setState(
            {
              history: [...this.state.history, this.state.current],
              current: next,
            },
            this.zoomIn,
          );
        }
      }
    }
  };

  private getCurrentSeries() {
    const { series, drill } = extractPoints(this.props.data, this.props.id);
    if (this.state.current === 'root') {
      return series;
    } else {
      return drill.find((d) => d.id === this.state.current);
    }
  }

  componentDidMount(): void {
    this.moveTarget();
  }

  componentDidUpdate(
    prevProps: Readonly<ISummaryChartParams>,
    prevState: Readonly<ISummaryChartState>,
    snapshot?: any,
  ): void {
    if (this.props.data !== prevProps.data) {
      this.moveTarget();
    }
  }

  levelUp = () => {
    const history = Array.from(this.state.history);
    const last = history.pop();
    this.setState({ history: history, current: last });
  };

  render() {
    const options: chartjs.ChartOptions = {
      title: {
        display: true,
        text: renderToString(this.props.labels.title),
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      responsive: false,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
      plugins: {
        stacked100: { enable: true },
      },
    };

    const series = this.getCurrentSeries();
    if (!series) return null;

    const history = this.state.history;
    const width =
      window.screen.width > 1500
        ? window.screen.width * 0.78
        : window.screen.width * 0.7;
    const data = convertToDataset(series);
    const datasetSize = data.datasets.length && data.datasets[0].data.length;
    let barPercentage;
    if (datasetSize > 0 && datasetSize < 8) {
      barPercentage = datasetSize / 8;
    }
    for (const set of data.datasets) {
      if (barPercentage) {
        set['barPercentage'] = barPercentage;
      } else {
        set['barPercentage'] = 0.9;
      }
    }

    return (
      <>
        {!!history.length && (
          <Button color={'primary'} onClick={this.levelUp}>
            back
          </Button>
        )}
        <Bar
          data={data}
          options={options}
          onElementsClick={this.moveTarget}
          height={300}
          width={width}
        />
      </>
    );
  }
}
