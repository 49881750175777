import React, { Component } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  NavLink,
} from 'reactstrap';

import { MailSupport, CardWrapper } from './styled';
import {
  RegionChildProps,
  withRegionAndCredentials,
} from '../common/withRegionAndCredentials';
interface OwnProps {}

type IProps = RegionChildProps & OwnProps;

interface IState {}

class SupportPage extends Component<IProps, IState> {
  componentDidMount() {}

  openBillingPortal = () => {
    // this.props.openUserPortal();
    // window.open(this.props.portalUrl, '_blank');
    // return;
  };

  render() {
    return (
      <>
        <Row className={'pr-5 pl-2 pb-5 dashboard-content'}>
          <Col md={12}>
            <h3>Support</h3>
          </Col>
        </Row>
        {/* <Row className={'pr-5 pl-2 pt-5 mt-5 d-flex justify-content-around'}> */}
        <div className="pr-5 mr-4 pt-5 mt-5 d-flex justify-content-around test-support-buttons">
          {/* <CardWrapper href="https://feedback.totalcloud.io/" target="_blank"> */}
          <CardWrapper
            href="https://totalcloud-support.freshdesk.com/support/home"
            target="_blank"
          >
            <Card className="p-5 shadow border-secondary rounded">
              <CardBody className="p-0">
                <CardTitle className="text-center  text-dark">
                  Feedback & Support
                </CardTitle>
                <div className="w-100 d-block text-center text-primary test-feedback-button">
                  <img
                    className="test-feedback-image"
                    src="/img/support-1.png"
                    alt={'img-feedback'}
                  />
                </div>
              </CardBody>
            </Card>
          </CardWrapper>
          <CardWrapper
            href="https://docs.totalcloud.io/faqs/faqs"
            target="_blank"
            width={7.12}
          >
            <Card className="p-5 shadow border-secondary rounded">
              <CardBody className="p-0">
                <CardTitle className="text-center  text-dark">FAQ</CardTitle>
                <div className="w-100 d-block text-center text-primary">
                  <img className="test-faq-image" src="/img/support-2.png" />
                </div>
              </CardBody>
            </Card>
          </CardWrapper>
          <CardWrapper
            href="https://docs.totalcloud.io/"
            target="_blank"
            width={7.12}
            height={7.12}
          >
            <Card className="p-5 shadow border-secondary rounded">
              <CardBody className="p-0">
                <CardTitle className="text-center  text-dark">
                  Knowledge Base
                </CardTitle>
                <div className="w-100 d-block text-center text-primary test-help-button">
                  <img className="test-help-image" src="/img/support-3.png" />
                </div>
              </CardBody>
            </Card>
          </CardWrapper>
        </div>
        {/* </Row> */}
        <Row className="pr-5 pl-2 pt-5 mt-5">
          <Col className="text-center">
            <MailSupport className="">
              For any further queries contact us at{' '}
              <a className="font-italic" href="mailto:support@totalcloud.io">
                support@totalcloud.io
              </a>{' '}
              {/* or the in-app chat */}
            </MailSupport>
          </Col>
        </Row>
      </>
    );
  }
}

export const SupportIndex = withRegionAndCredentials(SupportPage);
