import { OnBoardingPage } from '../../../lib/store/onboarding/types';

export interface OnBoardingQuestionsType {
  question: string;
  multi?: boolean;
  optional?: boolean;
  max?: number;
  options: { name: string; value: string; optional?: boolean }[];
}

export const onBoardingQuestions: {
  [s in Partial<OnBoardingPage>]: OnBoardingQuestionsType | undefined;
} = {
  HearFrom: {
    question: 'Where did you hear about Totalcloud?',
    optional: false,
    max: 1,
    options: [
      { name: 'Social Media', value: 'Social Media' },
      {
        name: 'Blogs such as Medium, Dzone, Dev-to, etc.',
        value: 'Blogs such as Medium, Dzone, Dev-to, etc.',
      },
      { name: 'Email', value: 'Email' },
      { name: 'Search engine results', value: 'Search engine results' },
      { name: 'Word of mouth', value: 'Word of mouth' },
      { name: 'Other', value: 'Other', optional: true },
    ],
  },

  CloudChallenges: {
    question:
      'What are the challenges you currently face with cloud management?',
    optional: false,
    max: 1,
    options: [
      { name: 'Cloud Bills', value: 'Cloud Bills' },
      {
        name: 'Monitoring',
        value: 'Monitoring',
      },
      { name: 'Too much scripting', value: 'Too much scripting' },
      { name: 'Resource Management', value: 'Resource Management' },
      { name: 'Security & Compliance', value: 'Security & Compliance' },
      { name: 'Other', value: 'Other', optional: true },
    ],
  },
  CurrentlyAccomplish: {
    question: 'How do you currently accomplish this task',
    optional: false,
    max: 1,
    options: [
      {
        name: 'I don’t have any current solution',
        value: 'I don’t have any current solution',
      },
      {
        name: 'Third party solutions',
        value: 'Third party solutions',
      },
      {
        name: 'Name of your current solution',
        value: 'Name of your current solution',
        optional: true,
      },
      { name: 'I script my own solutions', value: 'I script my own solutions' },
      { name: 'Other', value: 'Other', optional: true },
    ],
  },
  CurrentCloudProvider: {
    question: 'What is your current cloud provider? (Select all that apply)',
    optional: false,
    max: 0,
    multi: true,
    options: [
      {
        name: 'AWS',
        value: 'AWS',
      },
      {
        name: 'Azure',
        value: 'Azure',
      },
      {
        name: 'GCP',
        value: 'GCP',
      },
      {
        name: 'VMware',
        value: 'VMware',
      },
      { name: 'Other', value: 'Other', optional: true },
    ],
  },
  IntendToGain: {
    question: 'What do you intend to gain from TC?',
    optional: false,
    max: 0,
    multi: true,
    options: [
      {
        name: 'Automation of resources',
        value: 'Automation of resources',
      },
      {
        name: 'Auto-remediate challenges',
        value: 'Auto-remediate challenges',
      },
      {
        name: 'Cut down on cloud bills',
        value: 'Cut down on cloud bills',
      },
      {
        name: 'Monitor existing resources',
        value: 'Monitor existing resources',
      },
      { name: 'Other', value: 'Other', optional: true },
    ],
  },
  RedirectToDashboard: undefined,
};
