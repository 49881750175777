import {
  DateFilterValueSchema,
  NaclFilterValueSchema,
  SgFilterValueSchema,
} from './schema';
import { SGFILTER_CONFIG } from '../../../model/config/SGFilterConfig';
import { NACL_FILTER_CONFIG } from '../../../model/config/NACLFilterConfig';

export const ADD_CONDITION: { label: string; value: string }[] = [
  { label: 'AND', value: '&&' },
  { label: 'OR', value: '||' },
];
export const EXPRESSION_OPTIONS: { [s: string]: string } = {
  '||': 'OR',
  '&&': 'AND',
};

export const CONDITION_TYPE_OPTIONS: { label: string; value: string }[] = [
  { value: 'param', label: 'Param' },
  { value: 'date-filter', label: 'Date' },
  { value: 'tag', label: 'Tags' },
  { value: 'sg-rule', label: 'Security Group' },
  { value: 'nacl-rule', label: 'Network ACLs' },
  { value: 'function', label: 'Function' },
];

export const CONDITION_TYPES: { [s: string]: string } = {
  DATE: 'date-filter',
  PARAM: 'param',
  TAG: 'tag',
  SGRULE: 'sg-rule',
  NACLRULE: 'nacl-rule',
  FUNCTION: 'function',
};

export const TYPES: { [s: string]: string } = {
  'date-filter': 'Date',
  param: 'Param',
  tag: 'Tags',
  'sg-rule': 'Security Group',
  'nacl-rule': 'Network ACLs',
  function: 'Function',
};
export const PARAMS_OPERATORS: { label: string; value: string }[] = [
  { value: '<', label: '<' },
  { value: '>', label: '>' },
  { value: '==', label: '==' },
  { value: '!=', label: '!=' },
  { value: '>=', label: '>=' },
  { value: '<=', label: '<=' },
  // { value: '=~', label: '=~' },
  { value: 'has', label: 'Has' },
  { value: 'notHas', label: 'Not Has' },
  { value: 'exists', label: 'Exists' },
  { value: 'notExists', label: 'Not Exists' },
  { value: 'contains', label: 'Contains' },
  { value: 'startsWith', label: 'Starts With' },
  { value: 'endsWith', label: 'Ends With' },
];

export const OPERATOR_HELP: { [s: string]: string } = {
  '': 'State.Name == running',
  '<': 'DefaultCooldown < 100',
  '>': 'MinSize > 5',
  '==': 'State.Code == 14 or State.Name == running',
  '!=': 'State.Name != running',
  '>=': 'FromPort >= 135',
  '<=': 'ToPort <= 69',
  has:
    'CacheSecurityGroupNames has internal-service (Checks if value is present in key array) ',
  notHas:
    'CacheSecurityGroupNames notHas internal-service (Checks if value is not present in key array)',
  exists: 'VpcId exists ',
  notExists: 'VpcId notExists',
  contains: 'FunctionName contains prod',
  startsWith: 'GroupName startsWith default ',
  endsWith: 'FunctionName endsWith get',
};

export const TAGS_OPERATORS: { label: string; value: string }[] = [
  { value: 'has', label: 'Has' },
  { value: 'notHas', label: 'Not Has' },
];

export const TIME_OPTIONS: { label: string; value: string }[] = [
  { value: 'start', label: 'start' },
  { value: 'now', label: 'now' },
  { value: '-10m', label: 'Last 10 mins' },
  { value: '-30m', label: 'Last 30 mins' },
  { value: '-1h', label: 'Last hour' },
  { value: '-3h', label: 'Last 3 hours' },
  { value: '-6h', label: 'Last 6 hours' },
  { value: '-12h', label: 'Last 12 hours' },
  { value: '-1d', label: 'Last day' },
  { value: '-2d', label: 'Last 2 days' },
  { value: '-3d', label: 'Last 3 days' },
  { value: '-5d', label: 'Last 5 days' },
  { value: '-7d', label: 'Last 7 days' },
];

export const AzureTimePeriod: { label: string; value: string }[] = [
  {
    value: 'PT1M',
    label: '1 min',
  },
  {
    value: 'PT5M',
    label: '5 minutes',
  },
  {
    value: 'PT15M',
    label: '15 minutes',
  },
  {
    value: 'PT30M',
    label: '30 minutes',
  },
  {
    value: 'PT1H',
    label: '1 hour',
  },
  {
    value: 'PT6H',
    label: '6 hours',
  },
  {
    value: 'PT12H',
    label: '12 hours',
  },
  {
    value: 'P1D',
    label: '1 day',
  },
];

export const SKIP_TIME_OPTIONS: { label: string; value: string }[] = [
  { value: '1h', label: 'Coming hour' },
  { value: '3h', label: 'Coming 3 hours' },
  { value: '6h', label: 'Coming 6 hours' },
  { value: '12h', label: 'Coming 12 hours' },
];

export const SG_TYPES: { label: string; value: string }[] = [
  { value: 'inbound', label: 'Inbound' },
  { value: 'outbound', label: 'Outbound' },
];

export const SOURCE_TYPES: {
  label: string;
  value: string;
  source: string;
}[] = [
  { value: 'Public IPv4', label: 'Public IPv4', source: '0.0.0.0/0' },
  { value: 'Public IPv6', label: 'Public IPv6', source: '::/0' },
  { value: 'Any', label: 'Any', source: 'Any' },
  { value: 'Custom', label: 'Custom', source: '' },
];

export const RULE_ACTIONS: { label: string; value: string }[] = [
  { value: 'allow', label: 'Allow' },
  { value: 'deny', label: 'Deny' },
];

export const defaultSgValues: SgFilterValueSchema = {
  source: SOURCE_TYPES[0].source,
  sourceType: SOURCE_TYPES[0].value,
  type: SG_TYPES[0].value,
  protocol: SGFILTER_CONFIG[0].protocol,
  protocolType: SGFILTER_CONFIG[0].value,
  portRange: '0-65535',
};

export const defaultNaclValues: NaclFilterValueSchema = {
  source: SOURCE_TYPES[0].source,
  sourceType: SOURCE_TYPES[0].value,
  type: SG_TYPES[0].value,
  protocol: NACL_FILTER_CONFIG[0].protocolOptions[0].value,
  protocolType: NACL_FILTER_CONFIG[0].value,
  portRange: '0-65535',
  ruleAction: RULE_ACTIONS[0].value,
};

export const defaultDateValues: DateFilterValueSchema = {
  fromDate: 'start',
  toDate: 'now',
  errorCheck: '',
};
