import * as React from 'react';
import { Col, FormFeedback, Input, InputGroup, Label, Row } from 'reactstrap';
import './forminputs.style.css';
import Tooltip from './shared/tooltip.component';
import ExternalLink from './shared/external-link.component';
import InputCommonProps from './index.component';

interface InputTextProps extends InputCommonProps {
  type?: 'search' | 'text' | 'password' | 'hidden' | 'email';
  placeholder?: string;
  useAlt?: boolean;
  avoidErrorText?: boolean;
}

const InputText = (props: InputTextProps) => {
  const {
    id,
    name,
    placeholder,
    type,
    value,
    onChange,
    onBlur,
    error,
    tooltip,
    externalLink,
    useAlt,
    label,
    onFocus,
    onKeyDown,
    readOnly,
    transparent,
    className,
    avoidErrorText,
  } = props;

  const invalid = !!(error && error.length);
  const infoWithError = !invalid ? 'link-without-error' : '';
  const inputWithoutInfo = !tooltip ? '' : '';
  const useAltInput = useAlt ? 'form-control-alternative' : '';
  const inputType = type ? type : 'text';
  const transparentClass = transparent ? 'bg-transparent' : '';
  return (
    <Row className={className}>
      {inputType !== 'hidden' && label && (
        <Col sm={12}>
          <Label className={'text-dark'} for={id}>
            {label}
          </Label>
        </Col>
      )}
      <Col sm={12}>
        <InputGroup className={'mb-0'}>
          <Input
            autoComplete={'off'}
            className={`${useAltInput} ${inputWithoutInfo} ${transparentClass} ${className} text-dark`}
            id={id}
            name={name}
            placeholder={placeholder}
            type={inputType}
            value={value}
            invalid={invalid}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            readOnly={readOnly}
          />
          {props.infoElement && <Tooltip raw={props.infoElement} />}
          {tooltip && <Tooltip info={tooltip} invalid={invalid} />}
          {invalid && !avoidErrorText && <FormFeedback>{error}</FormFeedback>}
          {externalLink && (
            <ExternalLink links={externalLink} className={infoWithError} />
          )}
        </InputGroup>
      </Col>
    </Row>
  );
};

export default InputText;
