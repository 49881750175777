let host = 'https://api.app.totalcloud.io';
if (
  window &&
  window.location &&
  window.location.hostname &&
  !window.location.hostname.startsWith('localhost')
) {
  host = `https://api.${window.location.hostname}`;
}
export const BASE_URL = `${host}/1`;
export const BaseUriExtras = `${host}/2`;
export const BaseUriGroup3 = `${host}/3`;
