import { Workflow } from '../../../../lib/store/workflows/types';
import { Template } from '../../../../lib/store/templates/types';
import {
  ExecutionsMapType,
  WorkflowExecutionOutput,
} from '../../../../lib/store/executions/types';
import { REGIONS } from '../../../../lib/editor/components/model/config/Regions';
import { getRegionsFromWorkflow } from '../utils';
import { getAccountFromWorkflow } from '../formatter/FixColFormatter';
import { Config } from '../../../../lib/store/config/types';
import { TAB_NAME } from 'webapp-genric/dist/constants';

interface OwnProps {
  workflows: Workflow[];
  templates?: Template[];
  executionsMap: ExecutionsMapType;
  isFetchingWorkflow: boolean;
  tabName?: string;
  onAdoptRequest: (templateId: string, option: string) => void;
  onChangeOption: Function;
  toggleActive: Function;
  config?: Config[];
  updateConfigAndRegion?: (key: string, value: string[]) => void;
  setResourceView?: (viewData: any) => void;
}

type InventoryTabDataType = {
  isFetching?: boolean;
  resource: string;
  totalResources: number | string;
  unTagged: number;
  onChangeOption: Function;
  toggleActive: Function;
  setResourceView?: (viewData: any) => void;
  accountId: string;
  accountName: string;
  tabName: TAB_NAME;
  regionName: string;
  executionUrl: string;
  isTemplateItem: boolean;
  tags: { [s: string]: number };
};

export const FormatInventoryTabData = (
  props: OwnProps,
): InventoryTabDataType[] => {
  let result: InventoryTabDataType[] = props.workflows
    .map((x) => {
      let {
        executionsMap,
        isFetchingWorkflow,
        tabName,
        config,
        setResourceView,
        updateConfigAndRegion,
      } = props;

      let exe = executionsMap[x.workflow];
      let executionUrl = `/workflow/${x.workflow}/details`;
      let output: WorkflowExecutionOutput = exe ? exe.output : null;
      let region = REGIONS.find(
        (r) => r.value === getRegionsFromWorkflow([x])[0] || '',
      );
      let accountId = getAccountFromWorkflow(x);
      let workflowConfig = config && config.find((x) => x.id === accountId);
      let regionName = region ? region.label : '';
      let tagsInfo = (output && output.tagsInfo) || {};
      let data = {
        ...x,
        accountId,
        accountName: (workflowConfig && workflowConfig.name) || '',
        regionName,
        tabName,
        resource: x.name,
        ...tagsInfo,
        isFetching: isFetchingWorkflow,
        executionUrl,
        url: output && output.url,
        exe,
        setResourceView,
        updateConfigAndRegion,
      };
      data['toggleActive'] = props.toggleActive.bind(this, data);
      data['onChangeOption'] = props.onChangeOption.bind(this, data);

      return data;
    })
    .reduce((A, e) => A.concat(e), []);

  let tempaltes = [];
  if (props.tabName && props.templates && props.templates.length) {
    tempaltes = props.templates.map((x) => {
      return {
        ...x,
        resource: x.name,
        tabName: props.tabName,
        isTemplateItem: true,
        onAdoptRequest: props.onAdoptRequest,
      };
    });
  }
  return result.concat(tempaltes);
};

export const FormatGlobalInventoryTabData = (props: OwnProps) => {
  let result = FormatInventoryTabData(props);
  result = result.filter((x) => !x.isTemplateItem);
  result = result.reduce((A, e) => {
    let index = A.findIndex((x) => x.accountName === e.accountName);
    if (A.length === 0 || index == -1) {
      e.totalResources = e.totalResources || 0;
      e.unTagged = e.unTagged || 0;
      A.push(e);
    } else {
      A[index].totalResources =
        A[index].totalResources + (e.totalResources || 0);
      A[index].unTagged = A[index].unTagged + (e.unTagged || 0);
      A[index].tags = mergeTags(A[index].tags || {}, e.tags || {});
      delete A[index]['url'];
    }
    return A;
  }, []);
  return result;
};

export const mergeTags = (
  one: { [s: string]: number },
  two: { [s: string]: number },
): { [s: string]: number } => {
  let keys = Object.keys(one || {}).concat(Object.keys(two || {}));
  return keys.reduce((A, e) => {
    A[e] = (one[e] || 0) + (two[e] || 0);
    return A;
  }, {});
};
