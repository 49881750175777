import { Workflow } from '../../../lib/store/workflows/types';
import { Template } from '../../../lib/store/templates/types';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PolicyStatus } from '../../common/PolicyStatusV2';
import { TAB_NAME } from 'webapp-genric/dist/constants';

interface IPolicyStatusRendererParams {
  loading: boolean;
  workflows: Workflow[];
  templates: Template[];
  tabName: TAB_NAME;
  regions: string[];
  credentials: string[];
  renderRegionSelector: boolean;
  hideRegionSelector: () => void;
  triggerWorkflow: Workflow;
}

export class PolicyStatusRenderer extends React.Component<
  IPolicyStatusRendererParams
> {
  policyStatusDiv: null | HTMLElement = null;

  componentDidMount(): void {
    this.policyStatusDiv = document.getElementById('policyStatus');
  }

  render() {
    let { loading, workflows, templates } = this.props;
    return (
      <PolicyStatus
        text={`${this.props.tabName}`}
        hideRegionSelector={this.props.hideRegionSelector}
        renderRegionSelector={this.props.renderRegionSelector}
        schedules={[]}
        isFetchingSchedules={false}
        tabName={this.props.tabName}
        isFetchingWorkflows={loading}
        workflows={workflows}
        templates={templates}
        regions={this.props.regions}
        credentials={this.props.credentials}
        triggerWorkflow={this.props.triggerWorkflow}
      />
    );
  }
}
