import * as React from 'react';
import CodeEditor from '../../../../../../formInputs/codeEditor.component';

interface IProps {
  schema: any;
  changeValue: Function;
  style?: any;
  readOnly?: boolean;
}

interface IState {
  data: string;
  error: boolean;
  errorMessage: string;
}

export const defaultAlarmSchema = {
  type: 'array',
  items: [
    {
      type: 'object',
      properties: {
        AlarmName: {
          type: 'string',
        },
        AlarmDescription: {
          type: 'null',
        },
        AWSAccountId: {
          type: 'string',
        },
        NewStateValue: {
          type: 'string',
        },
        NewStateReason: {
          type: 'string',
        },
        StateChangeTime: {
          type: 'string',
        },
        Region: {
          type: 'string',
        },
        OldStateValue: {
          type: 'string',
        },
        Trigger: {
          type: 'object',
          properties: {
            MetricName: {
              type: 'string',
            },
            Namespace: {
              type: 'string',
            },
            StatisticType: {
              type: 'string',
            },
            Statistic: {
              type: 'string',
            },
            Unit: {
              type: 'null',
            },
            Dimensions: {
              type: 'array',
              items: [
                {
                  type: 'object',
                  properties: {
                    value: {
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                  },
                  required: ['value', 'name'],
                },
                {
                  type: 'object',
                  properties: {
                    value: {
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                  },
                  required: ['value', 'name'],
                },
                {
                  type: 'object',
                  properties: {
                    value: {
                      type: 'string',
                    },
                    name: {
                      type: 'string',
                    },
                  },
                  required: ['value', 'name'],
                },
              ],
            },
            Period: {
              type: 'integer',
            },
            EvaluationPeriods: {
              type: 'integer',
            },
            ComparisonOperator: {
              type: 'string',
            },
            Threshold: {
              type: 'integer',
            },
            TreatMissingData: {
              type: 'string',
            },
            EvaluateLowSampleCountPercentile: {
              type: 'string',
            },
          },
          required: [
            'MetricName',
            'Namespace',
            'StatisticType',
            'Statistic',
            'Unit',
            'Dimensions',
            'Period',
            'EvaluationPeriods',
            'ComparisonOperator',
            'Threshold',
            'TreatMissingData',
            'EvaluateLowSampleCountPercentile',
          ],
        },
      },
      required: [
        'AlarmName',
        'AlarmDescription',
        'AWSAccountId',
        'NewStateValue',
        'NewStateReason',
        'StateChangeTime',
        'Region',
        'OldStateValue',
        'Trigger',
      ],
    },
  ],
};

export const defaultSchema = {
  type: 'object',
  title: '',
  properties: {
    input: {
      type: 'object',
    },
  },
  required: ['input'],
};

export default class SchemaComponent extends React.Component<IProps, IState> {
  state = {
    data: this.props.schema && JSON.stringify(this.props.schema, null, 3),
    error: false,
    errorMessage: '',
  };

  getValidJson(value) {
    try {
      return { data: JSON.parse(value.data), error: false, errorMessage: '' };
    } catch (error) {
      return { error: true, errorMessage: 'Please enter valid json' };
    }
  }

  applyChanges(value) {
    let { error, data, errorMessage } = this.getValidJson(value);
    this.setState({ error, data: value.data, errorMessage });
    this.props.changeValue({ jsonSchema: data, error });
  }

  render(): React.ReactNode {
    let { data, error, errorMessage } = this.state;
    // console.log({ data });
    let style = Object.assign(
      { width: '100%', minHeight: '120px' },
      this.props.style || {},
    );
    return (
      <>
        <CodeEditor
          style={style}
          label={'Payload schema'}
          error={errorMessage}
          value={{ data, error }}
          name={'node-json'}
          readOnly={this.props.readOnly}
          onChange={this.applyChanges.bind(this)}
        />
      </>
    );
  }
}
