import * as React from 'react';
import {
  Button,
  Col,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import './forminputs.style.css';

import InputCommonProps from './index.component';
import Tooltip from './shared/tooltip.component';
import ExternalLink from './shared/external-link.component';
import { CSSProperties } from 'react';

interface TextAreaProps extends InputCommonProps {
  rows: number;
  placeHolder?: string;
  showCopyControls?: boolean;
  defaultValue?: string;
  style?: CSSProperties;
}

export class TextArea extends React.Component<TextAreaProps, any> {
  state = {
    copied: false,
  };

  copyToClipboard = () => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard
        .writeText(this.props.value || this.props.defaultValue)
        .then(() => this.setState({ copied: true }))
        .catch(console.error);
    }
  };

  render() {
    const {
      id,
      rows,
      name,
      label,
      placeHolder,
      value,
      onChange,
      onBlur,
      error,
      tooltip,
      externalLink,
      showCopyControls = true,
      defaultValue,
      transparent,
      className,
      style,
      ...htmlElementProps
    } = this.props;

    const invalid = !!(error && error.length);
    const isInfoAvailable = tooltip && tooltip.length ? 'input-with-info' : '';
    const inputWithoutInfo = !tooltip ? 'brtb' : '';
    const infoWithError = !invalid ? 'link-without-error' : '';
    const propsClassName = className || '';
    const copyButtonText = this.state.copied ? 'copied' : 'copy to clipboard';
    const transparentClass = transparent ? 'bg-transparent' : '';
    return (
      <Row>
        <Col sm={12}>
          {label && (
            <Label for={id} className={'text-dark'}>
              {label}
            </Label>
          )}
        </Col>
        <Col sm={12}>
          <div className={isInfoAvailable + ' ' + 'position-relative'}>
            <Input
              className={`${inputWithoutInfo} ${transparentClass} ${propsClassName} `}
              id={id}
              name={name}
              placeholder={placeHolder}
              type="textarea"
              rows={rows}
              value={value}
              style={style}
              defaultValue={defaultValue}
              onChange={onChange}
              onBlur={onBlur}
              invalid={invalid}
              {...htmlElementProps}
            />
            {tooltip && <Tooltip info={tooltip} invalid={invalid} />}
            {invalid && <FormFeedback>{error}</FormFeedback>}
            {externalLink && (
              <ExternalLink links={externalLink} className={infoWithError} />
            )}

            {showCopyControls && (
              <div className="position-absolute right-1 top-1">
                <Button
                  className="test-copy-button p-0"
                  size={'sm'}
                  color={'link'}
                  onClick={this.copyToClipboard}
                >
                  {copyButtonText}
                </Button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}
