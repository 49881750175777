import * as React from 'react';
import { StyledOptionCol } from '../style';
import InputText from '../../../lib/formInputs/input-text.component';
import { DateRangePicker } from './DateRangePicker';
import { Button, Col, Row } from 'reactstrap';
import { Execution } from '../../../lib/store/executions/types';
import { Workflow } from '../../../lib/store/workflows/types';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { runWorkflow } from '../../../lib/store/executions/action';
import InputDataList from '../../../lib/formInputs/input-datalist.component';
import { LookupAttributes_AttributeKey } from '../util/options';

type OwnProps = {
  execution?: Execution;
  workflow?: Workflow;
  fetching: boolean;
  triggerOptions: any;
  triggerExecutionReFetch: () => void;
};

type StateProps = {};

type DispatchProps = {
  runWorkflow: (props: any) => void;
};

type IProps = OwnProps & StateProps & DispatchProps;

const schema = Yup.object().shape({
  dateRange: Yup.object()
    .shape({
      start: Yup.string().required(),
      end: Yup.string().required(),
    })
    .required(),
  filterKey: Yup.string().required(),
  filterValue: Yup.string().required(),
});

type FormType = Yup.InferType<typeof schema>;

class ToolBarForm extends React.Component<
  FormikProps<FormType> & { readOnly: boolean }
> {
  componentDidUpdate(
    prevProps: Readonly<FormikProps<FormType> & { readOnly: boolean }>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    if (this.props.initialValues && !prevProps.initialValues) {
      this.props.setValues(this.props.initialValues);
    }
  }

  render() {
    return (
      <StyledOptionCol md={12}>
        <Row>
          <Col md={3}>
            <InputDataList
              name={'filterKey'}
              value={this.props.values.filterKey}
              options={LookupAttributes_AttributeKey}
              label={'Filter Key'}
              onChange={this.props.handleChange}
              readOnly={this.props.readOnly}
              error={this.props.errors && this.props.errors.filterKey}
              avoidErrorText={true}
            />
          </Col>
          <Col md={3}>
            <InputText
              name={'filterValue'}
              value={this.props.values.filterValue}
              label={'Filter value'}
              onChange={this.props.handleChange}
              readOnly={this.props.readOnly}
              error={this.props.errors && this.props.errors.filterValue}
              avoidErrorText={true}
            />
          </Col>
          <Col md={5}>
            <div>
              <label>Select Time Range</label>
              <DateRangePicker
                name={'dateRange'}
                onChange={this.props.handleChange}
                value={this.props.values.dateRange}
                disabled={this.props.readOnly}
                size={'large'}
              />
            </div>
          </Col>
          <Col md={1} className="d-flex align-items-end justify-content-center">
            <div>
              <Button
                color={'primary'}
                className="py-2 m-0"
                onClick={() => this.props.handleSubmit()}
                disabled={this.props.readOnly}
                size={'sm'}
              >
                {this.props.readOnly ? (
                  <i className="fa fa-spinner fa-spin text-white" />
                ) : (
                  <i className="fa fa-search text-white" />
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </StyledOptionCol>
    );
  }
}

export class EventLookupToolbarInner extends React.Component<
  IProps,
  { timer?: NodeJS.Timeout | number }
> {
  state = {
    timer: undefined,
  };

  get isDisabled() {
    if (this.props.fetching) {
      return true;
    }

    if (!this.props.workflow) {
      return true;
    }

    return this.props.execution && this.props.execution.status === 'Running';
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    if (
      this.props.execution &&
      this.props.execution.status === 'Running' &&
      !this.state.timer
    ) {
      const timer = setInterval(this.props.triggerExecutionReFetch, 3000);
      this.setState({ timer: timer });
    }
    if (
      this.props.execution &&
      this.props.execution.status === 'Success' &&
      this.state.timer
    ) {
      clearInterval(this.state.timer);
      this.setState({ timer: null });
    }
  }

  renderForm = (props: FormikProps<FormType>) => {
    return (
      <ToolBarForm
        {...props}
        readOnly={this.isDisabled}
        initialValues={this.getInitialValues()}
      />
    );
  };

  handleSubmit = (values: FormType) => {
    const payload = {
      starttime: values.dateRange.start,
      endtime: values.dateRange.end,
      filters:
        values.filterKey && values.filterValue
          ? [
              {
                key: values.filterKey,
                value: values.filterValue,
              },
            ]
          : [],
    };

    this.props.runWorkflow(payload);
  };

  getInitialValues = (): FormType => {
    if (!this.props.triggerOptions) {
      return undefined;
    }
    const { starttime, endtime, filters } = this.props.triggerOptions[0];
    const ini = {
      dateRange: {
        start: starttime,
        end: endtime,
      },
      filterKey: filters && filters.length && filters[0].key,
      filterValue: filters && filters.length && filters[0].value,
    };
    return ini;
  };

  render() {
    return (
      <Formik<FormType>
        initialValues={this.getInitialValues()}
        onSubmit={this.handleSubmit}
        render={this.renderForm}
        validationSchema={schema}
        validateOnChange={false}
      />
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {};
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    runWorkflow: (inputs) =>
      runWorkflow({
        dispatch,
        workflow: ownProps.workflow.workflow,
        payload: inputs,
      }),
  };
}

export const EventLookupToolbar = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(EventLookupToolbarInner);
