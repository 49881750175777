import * as React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  createAdoptionTask,
  TemplatePolicyValidationResult,
  validateTemplatePolicy,
} from './adopt';
import { TextArea } from '../../lib/formInputs/textarea.component';
import { Config, ConfigState } from '../../lib/store/config/types';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { fetchResourceGroup } from '../../lib/store/config/action';
import { connect } from 'react-redux';
import withUser from '../common/withUser';
import { ResourceGroupsFactory } from '../common/resourceGroupsFactory';
import { RegionAndCredsComponent } from '../common/RegionAndCredsComponent';
import { toast } from 'react-toastify';
import { getAwsAccountConfig } from '../common/withRegionAndCredentials';
import useRestrictions, {
  UserRestrictionOptions,
} from '../../lib/user-restriction';
import { CONFIG_TYPES } from 'webapp-genric';

interface DispatchProps {
  fetchResourceGroup: (configId: string) => void;
}

interface OwnProps {
  user: string;
  templates: string[];
  onCompleted: () => void;
  onCancel: () => void;
  hideWfOption?: boolean; /// hides redirection to workflows, used in on-boarding
  selectedAccountId: string;
  selectedRegion: string;
}

interface StateProps {
  account: Config[];
  resourceGroupData: ConfigState['resourceGroupData'];
  restrictions?: UserRestrictionOptions;
}

type IProps = OwnProps & StateProps & DispatchProps;

interface IState {
  region: string;
  account: string;
  resourceGroup: string;
  policy: TemplatePolicyValidationResult | null;
  completed: boolean | null;
  showPolicyModel: boolean;
  errorStatus: string | null;
}

export class AdoptionV2Inner extends React.Component<IProps, IState> {
  state: IState = {
    errorStatus: null,
    region: this.props.selectedRegion || '',
    account:
      this.props.selectedAccountId ||
      (this.props.account &&
        this.props.account[0] &&
        this.props.account[0].id) ||
      '',
    resourceGroup: '',
    policy: {
      validation: { valid: false, details: 'Validation Pending' },
      recommended: {},
      minimum: {},
    },
    completed: false,
    showPolicyModel: false,
  };

  componentDidMount(): void {
    this.validate();
  }

  validate = () => {
    this.setState({ policy: null }, () => {
      validateTemplatePolicy(this.state.account, this.props.templates)
        .then((result) => this.setState({ policy: result }))
        .catch((err) => {
          this.setState({
            policy: {
              validation: { valid: false, details: 'Cannot Validate' },
              recommended: {},
              minimum: {},
            },
          });
          toast('Error validating Policy', { type: 'error' });
        });
    });
  };

  togglePolicyModal = () => {
    this.setState((prev) => ({
      showPolicyModel: !prev.showPolicyModel,
    }));
  };

  save = () => {
    this.setState({ errorStatus: null });
    const { account, resourceGroup, region } = this.state;
    const { templates } = this.props;
    if (!account) return this.setState({ errorStatus: 'Account is required' });
    if (this.isAwsAccount(account) && !region)
      return this.setState({
        errorStatus: 'Region is required for this account.',
      });
    else if (!this.isAwsAccount(account) && !resourceGroup)
      return this.setState({
        errorStatus: 'ResourceGroup is required for this account.',
      });

    this.setState({ completed: null }, () => {
      createAdoptionTask(account, region, resourceGroup, templates)
        .then(() => this.setState({ completed: true }))
        .catch(() =>
          this.setState({ errorStatus: 'Adoption Failed', completed: false }),
        );
    });
  };

  getAccounts = () => {
    return this.props.account.map((a) => ({
      label: a.name,
      value: a.id,
    }));
  };

  showPolicyModal = () => {
    let text = '';
    if (typeof this.state.policy.validation.details === 'string') {
      text = 'Please add addition policy document to perform validation';
    } else {
      let failing = Object.keys(this.state.policy.validation.details)
        .filter((k) => !this.state.policy.validation.details[k])
        .join(', ');
      text = `Following addition permission are required to adopt selected templates ${failing}`;
    }

    let required = JSON.stringify(this.state.policy.recommended, null, 2);
    let additional = JSON.stringify(this.state.policy.minimum, null, 2);
    return (
      <Modal
        size={'lg'}
        backdrop={'static'}
        toggle={this.togglePolicyModal}
        isOpen={true}
      >
        <ModalHeader toggle={this.togglePolicyModal}>
          Required Policy
        </ModalHeader>
        <ModalBody>
          {text && (
            <UncontrolledAlert color={'danger'}>{text}</UncontrolledAlert>
          )}
          <TextArea
            rows={8}
            name={'Required Policies'}
            label={'Required Policies'}
            showCopyControls={true}
            defaultValue={required}
            value={required}
            readOnly={true}
          />
          <TextArea
            rows={8}
            name={'Addition Policies'}
            label={'Addition Policies required for validation'}
            showCopyControls={true}
            defaultValue={additional}
            value={additional}
            readOnly={true}
          />
        </ModalBody>
      </Modal>
    );
  };

  renderFooter = () => {
    if (!this.state.completed)
      return (
        <Button
          className="test-proceed-button"
          color={'primary'}
          disabled={this.isValidating() || this.isCreatingWorkflows()}
          onClick={this.save}
        >
          Proceed
        </Button>
      );

    return (
      <>
        {!this.props.hideWfOption && (
          <Link to={'/workflows'}>
            <Button className="test-workflows-button" color={'primary'}>
              See Workflows
            </Button>
          </Link>
        )}
        <Button
          color={'primary'}
          outline={true}
          onClick={this.props.onCompleted}
        >
          Close
        </Button>
      </>
    );
  };

  isValidating = () => {
    return this.state.policy === null;
  };

  isCreatingWorkflows = () => {
    return this.state.completed === null;
  };

  renderStatus = () => {
    const { account } = this.state;
    if (this.isValidating() && this.isAwsAccount(account)) {
      return (
        <>
          <i className={'fa fa-spinner fa-spin mr-2'} />
          Verifying Policies
        </>
      );
    } else if (this.isCreatingWorkflows()) {
      return (
        <>
          <i className={'fa fa-spinner fa-spin mr-2'} />
          Creating Workflows Adoption Tasks
        </>
      );
    } else if (this.state.policy && this.state.policy.validation.valid) {
      return (
        <>
          <i className={'fa fa-check mr-2'} />
          Validation Successful
        </>
      );
    } else if (
      this.isAwsAccount(account) &&
      this.state.policy &&
      !this.state.policy.validation.valid
    ) {
      return (
        <>
          <Button
            size={'sm'}
            color={'link'}
            className={'mr-1'}
            onClick={this.validate}
          >
            <i className={'fa fa-refresh'} />
          </Button>
          <span className={'text-danger'}>Validation Failed</span>

          <Button
            size={'sm'}
            color={'link'}
            className={'ml-4'}
            onClick={this.togglePolicyModal}
          >
            Recommended Policies
          </Button>
        </>
      );
    } else if (this.state.completed) {
      return <>Workflows Successfully Created</>;
    }
    return null;
  };

  isAwsAccount = (id: string) =>
    getAwsAccountConfig(this.props.account.filter((acc) => acc.id === id))
      .length !== 0;

  handleChange = ({
    target: { name, value },
  }: {
    target: { name: keyof IState; value: any };
  }) => {
    if (name === 'account') {
      if (this.isAwsAccount(value)) {
        this.setState({ account: value, resourceGroup: '' }, this.validate);
      } else {
        this.setState({ account: value, region: '' }, this.validate);
      }
      return;
    }

    this.setState(
      { ...this.state, [name as keyof IState]: value },
      this.validate,
    );
  };

  renderMainModal = () => {
    const { account, errorStatus } = this.state;
    const { resourceGroupData } = this.props;
    let resourceGroupOptions = [];
    let isFetchingResources = false;
    if (account && resourceGroupData && resourceGroupData[account]) {
      const { data, isFetchingResourceGroup } = resourceGroupData[account];
      if (isFetchingResourceGroup) isFetchingResources = true;
      if (data)
        data.forEach((rg) =>
          resourceGroupOptions.push({ label: rg, value: rg }),
        );
    }

    return (
      <Modal
        className="test-adoption-modal"
        backdrop={'static'}
        centered={true}
        size={'lg'}
        isOpen={true}
      >
        <ModalHeader
          className="test-adoption-modal-header"
          tag={'h5'}
          toggle={!this.isCreatingWorkflows() ? this.props.onCancel : () => {}}
        >
          Adopting Templates
        </ModalHeader>
        <ModalBody className="test-adoption-modal-body">
          <Row>
            <Col md={12} className={'d-flex mb-2 justify-content-start'}>
              <ResourceGroupsFactory configId={this.state.account}>
                {({ availableResourceGroups }) => (
                  <RegionAndCredsComponent
                    isMultiSelect={false}
                    availableResourceGroups={availableResourceGroups}
                    configs={this.props.account}
                    selectedAccount={this.state.account}
                    selectedRegion={this.state.region}
                    onAccountChange={(value) =>
                      this.handleChange({
                        target: { name: 'account', value },
                      })
                    }
                    onRegionChange={(value) =>
                      this.handleChange({
                        target: { name: 'region', value },
                      })
                    }
                    showResourceGroup={true}
                    selectedResourceGroup={this.state.resourceGroup}
                    onResourceGroupChange={(value) =>
                      this.handleChange({
                        target: { name: 'resourceGroup', value },
                      })
                    }
                  />
                )}
              </ResourceGroupsFactory>
            </Col>
            {errorStatus && (
              <Col md={12} className={'mt-2 text-danger'}>
                {errorStatus}
              </Col>
            )}
            <Col md={12} className={'my-2 test-validation-status'}>
              {this.renderStatus()}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="test-adoption-modal-footer">
          {this.renderFooter()}
        </ModalFooter>
      </Modal>
    );
  };

  render(): React.ReactNode {
    return (
      <>
        {this.renderMainModal()}
        {this.state.showPolicyModel && this.showPolicyModal()}
      </>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const { data = [], resourceGroupData } = state.config;
  return {
    account: data,
    restrictions: data.find((x) => x.type === CONFIG_TYPES.RESTRICTIONS)
      ?.value as UserRestrictionOptions,
    resourceGroupData,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, DispatchProps, any>,
  ownProps: OwnProps,
): DispatchProps => {
  return {
    fetchResourceGroup: (configId) => fetchResourceGroup(dispatch, configId),
  };
};

export const AdoptionV2 = withUser(
  connect(mapStateToProps, mapDispatchToProps)(AdoptionV2Inner),
);
