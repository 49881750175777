import React, { useContext, useEffect } from 'react';
import { REGIONS } from '../../../lib/editor/components/model/config/Regions';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { Workflow } from '../../../lib/store/workflows/types';
import { Config } from '../../../lib/store/config/types';
import { AppState } from '../../../lib/store';
import { getAccountConfig } from '../../common/withRegionAndCredentials';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import {
  AutoAdoptionV2,
  TemplatesToAdopt,
} from '../../common/AutoAdoptTemplatesV2';
import { superApiCall } from '../../../lib/store/super-table/action';
import { ApiOperation } from '../../../lib/store/super-table/types';
import { TableSubCategories } from '../../tables';
import TableContext from '../../tables/Context';
import { getTriggerWorkflowConfigByTabName } from 'webapp-genric/dist/genricWorkflowsConfig';

interface OwnProps {
  tabName: TAB_NAME;
  hideRegionSelector?: () => void;
  renderRegionSelector: boolean;
  credentials: string[];
  regions: string[];
  category: string;
  subCategory?: string;
}

//

interface DispatchProps {
  fetchAdoptionData: (options: any) => void;
}

export interface AlreadyAdopted {
  accounts: string[];
  resourceGroups: string[];
  regions: string[];
}

interface StateProps {
  isFetching: boolean;
  templates: TemplatesToAdopt[];
  availableCredentials: string[];
  triggerWorkflow?: Workflow;
  configs: Config[];
  autoAdopt: boolean;
  alreadyAdopted: AlreadyAdopted;
}

type AdoptionSettingProps = OwnProps & DispatchProps & StateProps;

const AdoptionSettingInner = (props: AdoptionSettingProps) => {
  const {
    configs,
    credentials,
    isFetching,
    tabName,
    regions,
    hideRegionSelector,
    renderRegionSelector,
    templates,
    triggerWorkflow,
    autoAdopt,
    subCategory,
    category,
    availableCredentials,
    fetchAdoptionData,
    alreadyAdopted,
  } = props;

  const shouldRenderAdoption = () => {
    if (!credentials || !credentials.length) {
      return false;
    }
    if (renderRegionSelector) return true;

    return !isFetching;
  };

  const showNotification = Boolean(templates?.length);

  useEffect(() => {
    if (!credentials.length || !regions.length) return;
    fetchAdoptionData({ filters: { regions, accounts: credentials } });
  }, [regions, credentials]);

  const { refreshTableComponents } = useContext(TableContext);

  if (props.tabName === TAB_NAME.EVENTS) return null;

  return (
    <AutoAdoptionV2
      alreadyAdopted={alreadyAdopted}
      refreshComponents={refreshTableComponents}
      category={category}
      subCategory={subCategory}
      configs={configs}
      hideRegionSelector={hideRegionSelector}
      showRegionSelector={renderRegionSelector}
      showNotification={showNotification}
      tabName={tabName}
      isFetching={isFetching}
      templatesToAdopt={templates}
      account={credentials}
      region={regions || REGIONS.map((x) => x.value)}
      shouldAdopt={shouldRenderAdoption()}
      triggerWorkflow={triggerWorkflow}
      autoAdopt={autoAdopt}
      fetchAdoptionData={fetchAdoptionData}
    />
  );
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const { category, subCategory } = ownProps;
  const awsConfigs = getAccountConfig(state.config.data);
  const availableCredentials = awsConfigs.map((c) => c.id);
  const adoptionData =
    state.superTable[category] && state.superTable[category][subCategory];

  const triggerData =
    state.superTable[category] &&
    state.superTable[category][TableSubCategories.TRIGGER_SETTINGS_DATA];

  let triggerConfig = getTriggerWorkflowConfigByTabName(ownProps.tabName);
  let triggerWorkflow;
  if (triggerConfig) {
    let groupWfs = state.workflows.groups[triggerConfig.group];
    triggerWorkflow =
      groupWfs &&
      groupWfs.data &&
      groupWfs.data.find((x) => x.name === triggerConfig.name);
  }

  return {
    autoAdopt: adoptionData?.data?.autoAdopt,

    alreadyAdopted: adoptionData?.data?.alreadyAdopted || {
      regions: [],
      accounts: [],
      resourceGroups: [],
    },
    availableCredentials: availableCredentials,
    configs: awsConfigs,
    isFetching: adoptionData?.loading,
    templates: adoptionData?.data?.templatesToAdopt,
    triggerWorkflow: triggerData?.data?.workflow || triggerWorkflow,
  };
};

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  const { subCategory, category } = ownProps;
  return {
    fetchAdoptionData: (options) =>
      superApiCall({
        options,
        operation: ApiOperation.GET,
        category,
        subCategory,
        dispatch,
      }),
  };
}

export const AdoptionSetting = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(AdoptionSettingInner);
