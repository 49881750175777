import { MultiReportingFormSchema } from './reportingFormSchema';
import {
  CronSchedule,
  ParsedCronSchedule,
  RateSchedule,
  ScheduleType,
} from 'workflow-model';
import { ScheduleFormSchema } from '../../schedules/components/formType';
import { TriggerSchema } from '../../../lib/editor/components/ui/nodeUI/trigger/schema';

export const convertParsedToCombinedCon = (
  data: MultiReportingFormSchema | TriggerSchema,
) => {
  let cronSchedule = data.schedule.value.filter(
    (v) => v.type === ScheduleType.PARSED_CRON,
  );
  let restSchedule = data.schedule.value.filter(
    (v) => v.type !== ScheduleType.PARSED_CRON,
  );
  let cronCombined = cronSchedule.map((cron) => cron.value).join(' : ');
  const { userSchema, id } = cronSchedule[0] || {};

  data = {
    ...data,
    schedule: {
      ...data.schedule,
      value: restSchedule.length
        ? restSchedule
        : [
            {
              type: ScheduleType.PARSED_CRON,
              value: cronCombined,
              userSchema,
              id,
            },
          ],
    },
  };

  return data;
};

export const convertCombinedToParsed = (
  usedValues: MultiReportingFormSchema,
) => {
  let { value, type, ...all } = usedValues.schedule.value[0] || {};
  if (type == ScheduleType.PARSED_CRON) {
    let cronSplit = value.split(':');
    usedValues.schedule.value = (cronSplit.map((c) => ({
      ...all,
      type,
      value: c.trim(),
    })) as unknown) as Array<RateSchedule | CronSchedule | ParsedCronSchedule>;
  }

  return usedValues;
};
