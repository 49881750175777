import React from 'react';
import { SecureRouteProps } from '../../../api/tcApiHelper';
import ActivationPage from '../components/ActivationPage';
// import { page } from '../../../analytics';

interface IState {
  activationToken: string;
}

export default class Activation extends React.Component<
  SecureRouteProps,
  IState
> {
  constructor(props: SecureRouteProps) {
    super(props);
    this.state = {
      activationToken: props.match.params['activationToken'],
    };
  }

  // componentDidMount() {
  //   page('activation');
  // }

  render() {
    let { activationToken } = this.state;
    return <ActivationPage activationToken={activationToken} />;
  }
}
