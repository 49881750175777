import { SUB_CATEGORIES_MAP } from '../../../../config/Categories';
import { Category } from 'workflow-model/dist';

export type ServiceColDataType = string;

export const getServiceFromCategory = (categories: Category[] = []): string => {
  let serviceCategory = categories.find((x) => x.category === 'By Service');
  if (!serviceCategory) return '';
  return serviceCategory.subCategories[0] || '';
};

export const serviceColDataFormatter = (data) => {
  let categories =
    data.categories || (data.properties && data.properties.categories) || [];
  let service = getServiceFromCategory(categories);
  return SUB_CATEGORIES_MAP[service] || service;
};
