import * as React from 'react';
import { IWorkflow } from 'workflow-model';
import { NavButton } from '../editorHeader/styled';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { SerializedOptions } from 'workflow-model/dist';
import { TcJsonEditor } from '../../../../formInputs/json-editor';

interface IProps {
  workflow: IWorkflow;
}

interface IState {
  showModal: boolean;
}
export class GlobalVariables extends React.Component<IProps, IState> {
  state = {
    showModal: false,
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    return (
      <>
        <NavButton
          color={'link'}
          onClick={this.toggleModal}
          disabled={!this.props.workflow}
        >
          Variables
        </NavButton>
        {this.state.showModal && (
          <GlobalVariablesForm
            toggle={this.toggleModal}
            workflow={this.props.workflow}
          />
        )}
      </>
    );
  }
}

interface IInnerProps {
  workflow: IWorkflow;
  toggle: () => void;
}

interface IInnerState {
  data: { [key: string]: any };
  error: boolean;
}

export const getVariablesJson = (workflow: IWorkflow) => {
  let variables = workflow
    .getSerializedOptions()
    .filter((x) => x.key !== 'credentials')
    .reduce((A, e) => {
      A[e.key] = e.value;
      return A;
    }, {});
  return variables;
};

class GlobalVariablesForm extends React.Component<IInnerProps, IInnerState> {
  state = {
    data: getVariablesJson(this.props.workflow),
    error: false,
  };
  saveVariables = () => {
    if (this.state.error) return;
    let parsedJson = this.state.data;
    let variables: SerializedOptions[] = Object.keys(parsedJson).map((x) => {
      return { key: x, value: parsedJson[x] };
    });
    // console.log({variables})
    this.props.workflow.addOptions(variables);
    this.props.toggle();
  };

  onUserInput = (value): void => {
    // console.log({value});
    this.setState(value);
  };
  render() {
    return (
      <Modal isOpen={true} toggle={this.props.toggle} size={'lg'}>
        <ModalHeader toggle={this.props.toggle}>
          <div>Edit Variables</div>
          <div className="font-italic">
            {
              'Add global variables here, and reference the value anywhere in the workflow using the $wf.variables.<variable_name>'
            }
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <TcJsonEditor
                value={this.state}
                name={'Variables'}
                onChange={this.onUserInput}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'primary'}
            disabled={this.state.error || !this.state.data}
            onClick={this.saveVariables}
          >
            Save
          </Button>
          <Button onClick={this.props.toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
