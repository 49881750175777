import {
  MultiReportingFormSchema,
  ReportingFormSchema,
} from './reportingFormSchema';
import {
  Category,
  CronSchedule,
  INode,
  IWorkflow,
  NODE_TYPES,
  NotificationType,
  RateSchedule,
  ScheduleType,
  SerializedTriggerNodeData,
  SerializedWorkflowReportNodeData,
  TriggerNodeData,
  ViewType,
  Workflow,
  WorkflowReportNodeData,
} from 'workflow-model/dist';
import { TriggerNode } from 'workflow-model/dist/nodes';
import { WorkflowReportNode } from 'workflow-model/dist/nodes/WorkflowReportNode';
import { ThrottlingNode } from 'workflow-model/dist/nodes/ThrottlingNode';
import { TAB_NAME } from 'webapp-genric/dist';
import { defaultTriggerNodeData } from '../../../lib/editor/components/ui/nodeUI/trigger/TriggerForm';

const REPORTING_WORKFLOW_NAME = 'tc-workflows-reporting-workflow';

export const getReportingWorkflowName = (tabName) => {
  if (!tabName) return REPORTING_WORKFLOW_NAME;
  if (tabName === TAB_NAME.INVENTORY_GLOBAL) tabName = TAB_NAME.INVENTORY;
  if (tabName === TAB_NAME.UTILISATION_SUMMARY) tabName = TAB_NAME.UTILISATION;
  else return REPORTING_WORKFLOW_NAME + '_' + tabName.trim();
};

export type CustomReportWorkflowProps = {
  name?: string;
  definition?: string;
  user: string;
  properties: any;
  workflow?: string;
  group?: string;
  category?: string;
  subCategory?: string;
  tabName?: string;
};

export class CustomReportWorkflow {
  private workflow: IWorkflow = null;

  constructor(props: CustomReportWorkflowProps) {
    if (!props) throw new Error('invalid params for ');
    let {
      user,
      definition,
      name,
      properties,
      workflow,
      group,
      category,
      subCategory,
      tabName,
    } = props;
    let categories: Category[] = category
      ? [{ category, subCategories: subCategory ? [].concat(subCategory) : [] }]
      : [];
    if (definition) {
      this.workflow = new Workflow({
        user,
        isModelDirty: true,
        definition,
        name,
        properties,
        workflow,
        group,
      });
    } else {
      this.workflow = new Workflow({
        user,
        isModelDirty: true,
        group: 'reportWorkflow',
        properties: { active: true },
        name: getReportingWorkflowName(tabName),
      });
      // trigger node
      let triggerNode: INode = new TriggerNode();
      let triggerNodeData: SerializedTriggerNodeData = defaultTriggerNodeData;
      triggerNode.setNodeData(triggerNodeData);

      let throttlingNode = new ThrottlingNode();
      throttlingNode.setNodeData({});

      // workflow report node
      let workflowReportNode: INode = new WorkflowReportNode();
      let wfReportNodeData: SerializedWorkflowReportNodeData = {
        categories,
        tabName,
        transport: {
          type: NotificationType.EMAIL,
          options: {
            notifyEmail: user,
            slackConfig: '',
          },
        },
      };

      workflowReportNode.setNodeData(wfReportNodeData);
      this.workflow.addNodeAtLast(triggerNode);
      this.workflow.addNodeAtLast(throttlingNode);
      this.workflow.addNodeAtLast(workflowReportNode);
      workflowReportNode.setSelectedResource({
        selectedResourceId: triggerNode.getNodeId(),
        nodeRef: triggerNode,
        path: '',
        label: 'Trigger.HttpPayload',
        context: {
          outputPath: 'HttpPayload',
        },
      });
    }
  }

  serialize = (data: MultiReportingFormSchema): IWorkflow => {
    let {
      notifyEmail,
      slackConfig,
      type,
      category,
      subCategory,
      tabName,
      schedule,
    } = data;
    let categories: Category[] = category
      ? [{ category, subCategories: subCategory ? [].concat(subCategory) : [] }]
      : [];

    let triggerNode: TriggerNode = this.workflow.getNodesByType(
      NODE_TYPES.TRIGGER,
    )[0] as TriggerNode;

    let desData = triggerNode.deserialize();
    let serData = triggerNode.serialize({
      alarm: {
        active: false,
        value: {
          statistics: [],
          namespace: '',
          dimensions: '',
          metricNames: [],
        },
      },
      http: {
        active: desData.http.active,
        value: desData.http.value,
      },
      event: {
        active: desData.event.active,
        value: desData.event.value,
      },
      schedule,
      view: ViewType.SCHEDULE,
    });
    triggerNode.setNodeData(serData);

    let workflowReportNode: WorkflowReportNode = this.workflow.getNodesByType(
      NODE_TYPES.WORKFLOW_REPORT,
    )[0] as WorkflowReportNode;
    workflowReportNode.setNodeData(
      workflowReportNode.serialize({
        categories,
        notifyEmail,
        slackConfig,
        type,
        tabName,
      }),
    );
    return this.workflow;
  };

  deserialize = (): MultiReportingFormSchema => {
    let triggerNode: TriggerNode = this.workflow.getNodesByType(
      NODE_TYPES.TRIGGER,
    )[0] as TriggerNode;
    let triggerNodeData: TriggerNodeData = triggerNode.deserialize();
    let workflowReportNode: WorkflowReportNode = this.workflow.getNodesByType(
      NODE_TYPES.WORKFLOW_REPORT,
    )[0] as WorkflowReportNode;
    let wfReportNodeData: WorkflowReportNodeData = workflowReportNode.deserialize();

    return {
      ...wfReportNodeData,
      // As  trigger node schedule can be empty so id may not be available.
      id: triggerNodeData.schedule.value[0]?.id,
      schedule: triggerNodeData.schedule,
    };
  };
}
