import { CustomReportWorkflow } from './workflowReportConverter';
import { IWorkflow } from 'workflow-model/dist';
import { viewType, WorkflowReportForm } from './workflowReportForm';
import React from 'react';
import {
  multiReportingFormSchema,
  MultiReportingFormSchema,
  reportingFormSchema,
} from './reportingFormSchema';
import { Formik, FormikActions, FormikProps } from 'formik';
import {
  waitForLiveReportingUrl,
  waitForReportingUrl,
} from '../../../lib/store/workflows/action';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { copyToClipboard } from '../../../util/commonUtils';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import moment from 'moment';
import {
  CronSchedule,
  ParsedCronSchedule,
  RateSchedule,
  ScheduleType,
} from 'workflow-model';
import _ from 'lodash';
import {
  convertCombinedToParsed,
  convertParsedToCombinedCon,
} from './cornTypeConverter';
import useRestrictions from '../../../lib/user-restriction';

interface IWrapperProps {
  tabName: TAB_NAME;
  customWorkflow: CustomReportWorkflow;
  isFetching: boolean;
  reportingAction: boolean;
  sendReportNow: (workflow: IWorkflow) => void;
  saveReportingWorkflow: (workflow: IWorkflow) => void;
}

interface IWrapperState {
  url: string;
  liveUrl: string;
  showModal: number;
  generatingUrl: boolean;
  generatingLiveUrl: boolean;
}

export class WorkflowReportWrapper extends React.Component<
  IWrapperProps,
  IWrapperState
> {
  state = {
    url: '',
    liveUrl: '',
    generatingLiveUrl: false,
    generatingUrl: false,
    showModal: 0, //1 share settings, 2 static url, 3 dynamic url
  };

  handleSubmit = (
    values: MultiReportingFormSchema,
    actions: FormikActions<MultiReportingFormSchema>,
  ) => {
    let { customWorkflow } = this.props;
    let workflow: IWorkflow;
    if (values.group === viewType.NOW) {
      workflow = customWorkflow.serialize({
        ...values,
        schedule: { active: false, value: [] },
      });
      this.props.sendReportNow(workflow);
    } else {
      // console.log('will save');
      // values["value"]=values.scheduleRate;
      let usedValues = _.cloneDeep(values);
      workflow = customWorkflow.serialize(convertCombinedToParsed(usedValues));
      this.props.saveReportingWorkflow(workflow);
    }
    actions.setSubmitting(false);
  };

  openModal = (view) => {
    this.setState({ showModal: view });
  };

  closeModal = () => {
    this.setState({ showModal: 0 });
  };

  getInitialValues = () => {
    let data: MultiReportingFormSchema = this.props.customWorkflow.deserialize();
    return convertParsedToCombinedCon(data);
  };

  renderReportModal = () => {
    let initialValues: MultiReportingFormSchema = this.getInitialValues();
    initialValues.group = viewType.LATER;
    const render = (props: FormikProps<MultiReportingFormSchema>) => {
      return (
        <WorkflowReportForm
          {...props}
          {...this.props}
          onClose={() => this.closeModal()}
        />
      );
    };
    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={render}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={multiReportingFormSchema}
        />
      </>
    );
  };

  getStaticUrl = async () => {
    this.setState({ generatingUrl: true });
    let customWorkflow = this.props.customWorkflow;
    let url = await waitForReportingUrl({
      workflow: customWorkflow.serialize(customWorkflow.deserialize()),
    });
    this.setState({ generatingUrl: false, url: url || '' });
  };

  getDynamicUrl = async () => {
    this.setState({ generatingLiveUrl: true });
    let customWorkflow = this.props.customWorkflow;
    let liveUrl = await waitForLiveReportingUrl({
      workflow: customWorkflow.serialize(customWorkflow.deserialize()),
    });
    this.setState({ generatingLiveUrl: false, liveUrl: liveUrl || '' });
  };

  renderStaticUrlModal = () => {
    return (
      <Modal backdrop={'static'} centered={true} isOpen={true}>
        <ModalHeader toggle={this.closeModal}>
          Share-able Static URL
        </ModalHeader>
        <ModalBody>
          <h4>
            {`Create a share-able URL for the present ${this.props.tabName} status`}
          </h4>
          <Row>
            <Col>
              {this.state.url && !this.state.generatingUrl && (
                <>
                  <code>{this.state.url}</code>
                  <i
                    className="fa fa-clipboard cursor-pointer"
                    onClick={() => copyToClipboard(this.state.url)}
                  />
                </>
              )}
              {this.state.generatingUrl &&
                'Please wait while we generate the URL (This might take a few seconds)'}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className={'my-4 '}>
            <Col md={6}>
              <Button
                color={'primary'}
                block={true}
                disabled={this.state.generatingUrl}
                onClick={this.getStaticUrl}
              >
                {this.state.generatingUrl ? 'Generating' : 'Generate'}
              </Button>
            </Col>
            <Col md={6}>
              <Button
                className="test-clone-button"
                color={'primary'}
                block={true}
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  };

  renderLiveUrlModal = () => {
    return (
      <Modal backdrop={'static'} centered={true} isOpen={true}>
        <ModalHeader toggle={this.closeModal}>Share-able Live URL</ModalHeader>
        <ModalBody>
          <h4>
            {`Create a share-able URL for the present ${this.props.tabName} status`}
          </h4>
          <Row>
            <Col>
              {this.state.liveUrl && !this.state.generatingLiveUrl && (
                <>
                  <code>{this.state.liveUrl}</code>
                  <i
                    className="fa fa-clipboard cursor-pointer"
                    onClick={() => copyToClipboard(this.state.liveUrl)}
                  />
                </>
              )}
              {this.state.generatingLiveUrl &&
                'Please wait while we generate the URL (This might take a few seconds)'}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className={'my-4 '}>
            <Col md={6}>
              <Button
                color={'primary'}
                block={true}
                disabled={this.state.generatingLiveUrl}
                onClick={this.getDynamicUrl}
              >
                {this.state.generatingLiveUrl ? 'Generating' : 'Generate'}
              </Button>
            </Col>
            <Col md={6}>
              <Button
                className="test-clone-button"
                color={'primary'}
                block={true}
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    return (
      <>
        {this.state.showModal === 1 && this.renderReportModal()}
        {this.state.showModal === 2 && this.renderStaticUrlModal()}
        {this.state.showModal === 3 && this.renderLiveUrlModal()}
        <UncontrolledDropdown>
          <DropdownToggle color={'primary'} caret outline={true}>
            <i className="fa fa-share-alt" /> Share
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.openModal(1)}>
              Share Settings
              <div className="float-right">
                <i className="fa fa-info" id={'share-setting-icon'} />
              </div>
              <UncontrolledTooltip target={'share-setting-icon'}>
                {`Configure ${this.props.tabName} sharing`}
              </UncontrolledTooltip>
            </DropdownItem>
            <DropdownItem onClick={() => this.openModal(2)}>
              Generate static Url
              <div className="float-right">
                <i className="fa fa-info" id={'static-url-icon'} />
              </div>
              <UncontrolledTooltip target={'static-url-icon'}>
                {`URL to share present ${this.props.tabName} status`}
              </UncontrolledTooltip>
            </DropdownItem>
            <GenerateLiveUrl
              onGenerate={this.openModal}
              tabName={this.props.tabName}
            />
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }
}

export const GenerateLiveUrl = ({
  onGenerate,
  tabName,
}: {
  onGenerate: (modalIndex: number) => void;
  tabName: TAB_NAME;
}) => {
  const { fetching, value } = useRestrictions('liveReportSharing');

  const getValue = typeof value === 'string' ? value === 'false' : !value;

  return (
    <DropdownItem disabled={fetching || getValue} onClick={() => onGenerate(3)}>
      Generate Live Url
      <div className="float-right">
        <i className="fa fa-info" id={'live-url-icon'} />
      </div>
      <UncontrolledTooltip target={'live-url-icon'}>
        {`URL to share real-time ${tabName} status`}
      </UncontrolledTooltip>
    </DropdownItem>
  );
};
