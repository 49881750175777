// events
import { Template } from '../templates/types';

export const RequestWebflowItemWithoutTemplate =
  'RequestWebflowItemWithoutTemplate';
export const ResponseWebflowItemWithoutTemplate =
  'ResponseWebflowItemWithoutTemplate';
export const FetchTemplatesRequest = 'FetchTemplatesRequest';
export const FetchTemplatesResponse = 'FetchTemplatesResponse';
export const UpdateItemRequest = 'UpdateItemRequest';
export const UpdateItemResponse = 'UpdateItemResponse';

export const ConvertWfToTemplateRequest = 'ConvertWfToTemplateRequest';
export const ConvertWfToTemplateResponse = 'ConvertWfToTemplateResponse';

export const SaveTemplateRequest = 'SaveTemplateRequest';
export const SaveTemplateResponse = 'SaveTemplateResponse';

export const ToggleTemplateModal = 'ToggleTemplateModal';

export const DeleteWorkflowsRequest = 'DeleteWorkflowsRequest';
export const DeleteWorkflowsResponse = 'DeleteWorkflowsResponse';

export const SyncTemplatesRequest = 'SyncTemplatesRequest';
export const SyncTemplatesResponse = 'SyncTemplatesResponse';

interface SyncTemplatesRequest {
  type: typeof SyncTemplatesRequest;
}

interface SyncTemplatesResponse {
  type: typeof SyncTemplatesResponse;
}

interface DeleteWorkflowsRequest {
  type: typeof DeleteWorkflowsRequest;
}

interface DeleteWorkflowsResponse {
  type: typeof DeleteWorkflowsResponse;
}

interface ToggleTemplateModal {
  type: typeof ToggleTemplateModal;
  template: Template;
}

interface ConvertWfToTemplateRequest {
  type: typeof ConvertWfToTemplateRequest;
}

interface ConvertWfToTemplateResponse {
  type: typeof ConvertWfToTemplateResponse;
}

interface SaveTemplateRequest {
  type: typeof SaveTemplateRequest;
}

interface SaveTemplateResponse {
  type: typeof SaveTemplateResponse;
}

interface UpdateItemRequest {
  type: typeof UpdateItemRequest;
  id: string;
  col: string;
}

interface UpdateItemResponse {
  type: typeof UpdateItemResponse;
  id: string;
}

interface FetchTemplatesRequest {
  type: typeof FetchTemplatesRequest;
}

interface FetchTemplatesResponse {
  type: typeof FetchTemplatesResponse;
  data: TemplateMap;
}

interface ResponseWebflowItemWithoutTemplate {
  type: typeof ResponseWebflowItemWithoutTemplate;
  data: MissingWebflowItem[];
}

interface RequestWebflowItemWithoutTemplate {
  type: typeof RequestWebflowItemWithoutTemplate;
}

export type TemplatesManageAction =
  | RequestWebflowItemWithoutTemplate
  | ResponseWebflowItemWithoutTemplate
  | FetchTemplatesRequest
  | FetchTemplatesResponse
  | UpdateItemRequest
  | UpdateItemResponse
  | SaveTemplateRequest
  | SaveTemplateResponse
  | ConvertWfToTemplateRequest
  | ConvertWfToTemplateResponse
  | ToggleTemplateModal
  | DeleteWorkflowsRequest
  | DeleteWorkflowsResponse
  | SyncTemplatesRequest
  | SyncTemplatesResponse;

export type MissingWebflowItem = {
  url: string;
  name: string;
  description: string;
  cmsItemId: string;
  slug: string;
  publishedOn: string;
};

export type TemplateMap = { [s: string]: Template };
export type ItemUpdateStateMap = {
  [s: string]: {
    updating: string; // col name.
  };
};

export interface TemplateManageState {
  isSyncingTemplates: boolean;
  fetchingWebflowItems: boolean;
  webflowWithoutTemplate: MissingWebflowItem[];
  fetchingTemplates: boolean;
  templateMap: TemplateMap;
  itemUpdateStateMap: ItemUpdateStateMap;
  isSavingTemplate: boolean;
  showTemplateModal: Template;
  isDeletingWorkflows: boolean;
}
