/**
 * 1. ingest any data in form of collection
 * 2. extract
 */
import React, { Component } from 'react';
import CustomTable, {
  ICustomTableCol,
  SelectRow,
} from '../../components/common/table';
import { discoverRows } from './discover';
import DownloadMenu from '../../components/report/downloadMenu';
import { Modal, ModalBody, ModalHeader, Col } from 'reactstrap';
import { textFilter } from 'react-bootstrap-table2-filter';
import ColSettings from '../../components/report/colSettings';

type IProps = {
  data: any[];
  divId?: string;
  selectRow?: SelectRow;
  options?: React.ReactNode;
  colFilterFields?: string[];
  hideSearch?: boolean;
  hideOptions?: boolean;
};

type IState = {
  open: boolean;
  data: any | null;
  colsToShow: ICustomTableCol[];
};
export class DataTable extends Component<IProps, IState> {
  getCols = (): ICustomTableCol[] => {
    let columns = discoverRows(this.props.data, this.showModal);
    columns.push({
      dataField: '_id',
      colName: '_id',
      text: 'Id',
      hidden: true,
    });
    if (this.props.colFilterFields) {
      columns = columns.map((x) => {
        if (this.props.colFilterFields.indexOf(x.dataField) !== -1) {
          x.filter = textFilter();
        }
        return x;
      });
    }
    return columns;
  };

  state: IState = {
    open: false,
    data: null,
    colsToShow: [],
  };

  componentDidMount(): void {
    this.setState({ colsToShow: this.getCols() });
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (prevProps.data.length === 0 && this.props.data.length !== 0) {
      this.setState({ colsToShow: this.getCols() });
    }
  }

  showModal = (data: any) => {
    this.setState({
      data,
      open: !this.state.open,
    });
  };

  hideModal = () => {
    this.setState({
      data: null,
      open: !this.state.open,
    });
  };

  onSaveColSettings = (selectedCols: string[]) => {
    let toShow = this.getCols().filter(
      (x) => selectedCols.indexOf(x.colName) !== -1,
    );
    this.setState({ colsToShow: toShow });
  };
  render() {
    const state = this.state;
    const data = this.props.data.map((x, i) => ({ ...x, _id: i }));
    let columns =
      this.state.colsToShow && this.state.colsToShow.length > 0
        ? this.state.colsToShow
        : this.getCols();

    const responsive = columns.length > 8;
    return (
      <>
        {' '}
        <CustomTable
          hideSearch={this.props.hideSearch}
          options={
            <Col md={8} className="d-flex justify-content-end">
              {this.props.options}
              {!this.props.hideOptions && (
                <>
                  <ColSettings
                    onSaveColSettings={this.onSaveColSettings}
                    colNames={this.getCols()
                      .filter((x) => !x.hidden)
                      .map((x) => x.colName)}
                  />
                  <DownloadMenu data={this.props.data} />
                </>
              )}
            </Col>
          }
          data={data}
          columns={columns}
          divId={this.props.divId}
          responsive={responsive}
          selectRow={this.props.selectRow}
        />
        <Modal isOpen={state.open} toggle={this.hideModal}>
          <ModalHeader toggle={this.hideModal}>Details</ModalHeader>
          <ModalBody>
            <pre>{JSON.stringify(state.data, null, 2)}</pre>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
