import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { ConfigState } from '../store/config/types';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';

export interface UserRestrictionOptions {
  allowedAccounts: number;
  allowedRegions: number;
  allowedResourceGroups: number;
  maxExecutions: number;
  liveReportSharing: boolean;
  triggerOptions: {
    recurrent: string[];
    schedule: boolean;
    cron: boolean;
  };
}

const useRestrictions = (restriction: keyof UserRestrictionOptions) => {
  const { data = [], fetching } = useSelector<AppState, ConfigState>(
    (state) => state.config,
  );

  const restrictionConfig = data?.find(
    (d) => d.type === CONFIG_TYPES.RESTRICTIONS,
  );

  const userRestriction = restrictionConfig?.value[restriction];
  return {
    fetching,
    value: userRestriction,
  };
};

export default useRestrictions;
