import { Reducer } from 'redux';
import { SideBarAction, SideBarState } from './types';

const getSideBarToggleState = () =>
  window.localStorage.getItem('sideBarOpen') === null
    ? true
    : window.localStorage.getItem('sideBarOpen') === 'true';

const sidBarToggle = () => {
  const sidebarState = window.localStorage.getItem('sideBarOpen');
  window.localStorage.setItem(
    'sideBarOpen',
    sidebarState === 'true' ? 'false' : 'true',
  );
};

const initialState: SideBarState = {
  isOpen: getSideBarToggleState(),
};

export const sidebarReducer: Reducer<SideBarState, SideBarAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'ToggleSideBar':
      sidBarToggle();
      return { isOpen: getSideBarToggleState() };
    default:
      return state;
  }
};
