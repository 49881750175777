import React from 'react';
import { withExternalData } from '../../../common/externalDependency';
import { fixStructure } from '../../../report/pipeline';
import { rootContainerId } from '../../../report/downloadMenu';
import { DataTable } from '../../../../lib/data-table';
import { TableItem } from '../../../templates-editor/components/cols';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from 'reactstrap';
import { ACTIONS_MAP } from '../../../../lib/editor/components/model/config/ActionsMap';
import {
  getDisplayName,
  getPrettyName,
} from 'workflow-model/dist/helper/utils';
import { ActionsModal } from '../ActionsModal';
import { SERVICE_PARAMS } from '../../../../lib/editor/components/model/config/ServiceParams';
import { Config } from '../../../../lib/store/config/types';
import { getWorkflowAccount } from '../../../../util/commonUtils';
import { getRegionFromWorkflow } from '../formatter/FixColFormatter';
import Swal from 'sweetalert2';
import { ReportBucket } from '../../../../config/resources.json';

interface IProps {
  viewData: any;
  data: any;
  configs: Config[];
  onActionPerformed?: (actionResponseData: any) => void;
}
interface IState {
  selected: any[];
  selectedAction: { service: string; action: string };
}

class InventoryResourceView extends React.Component<IProps, IState> {
  state = {
    selected: [],
    selectedAction: undefined,
  };

  getCloudActions = () => {
    let outputMeta =
      this.props.data &&
      this.props.data.meta &&
      this.props.data.meta.outputMeta;
    if (!outputMeta || !outputMeta.service || !outputMeta.method) return;
    let serviceAction =
      ACTIONS_MAP[`${outputMeta.service}:${outputMeta.method}`];
    return serviceAction;
  };

  getWorkflowAccount = () => {
    let workflow: any = this.props.viewData;
    return getWorkflowAccount(workflow);
  };

  getWorkflowRegion = () => {
    let workflow: any = this.props.viewData;
    let r = getRegionFromWorkflow(workflow);
    return r;
  };

  getSelectedResource = () => {
    let method =
      this.props.data &&
      this.props.data.meta &&
      this.props.data.meta.outputMeta &&
      this.props.data.meta.outputMeta.method;
    return getDisplayName(method || 'Custom');
  };

  // Note in use.. for now , will be discussed as a business requirement.

  /* fireSwalWarning = (x: { service: string; action: string }) => {
    Swal.fire({
      title: 'Do you want to continue?',
      text: `Do you want to perform ${
        x.action
      } on ${this.getSelectedResource()}!`,
      type: 'warning',
      width: '30em',
      showCancelButton: true,
      cancelButtonColor: '#646464',
      confirmButtonText: 'Continue',
    })
      .then(({ value }) => {
        value && this.setState({ selectedAction: x });
      })
      .catch((reason) => false);
  };
  */

  renderActionParamsModal = () => {
    let { selectedAction, selected } = this.state;
    let map = this.props.data && this.props.data.map;

    if (!selectedAction) return '';
    let inputJson =
      SERVICE_PARAMS[`${selectedAction.service}:${selectedAction.action}`];
    return (
      <ActionsModal
        {...selectedAction}
        onActionPerformed={this.props.onActionPerformed}
        selectedResource={this.getSelectedResource()}
        workflowAccount={this.getWorkflowAccount()}
        workflowRegion={this.getWorkflowRegion()}
        workflowId={this.props.viewData.workflow}
        onClose={() => this.setState({ selectedAction: undefined })}
        map={map}
        configs={this.props.configs}
        inputJson={inputJson}
        data={selected}
      />
    );
  };

  renderActions = () => {
    let serviceAction = this.getCloudActions();
    return (
      <UncontrolledDropdown color={'link'} className={'mr-2'}>
        <DropdownToggle
          className={'shadow-none'}
          color="primary"
          size="sm"
          disabled={
            !this.state.selected.length ||
            !serviceAction ||
            !serviceAction.length
          }
        >
          Actions
        </DropdownToggle>
        <DropdownMenu>
          {serviceAction &&
            serviceAction.map((x, i) => {
              return (
                <DropdownItem
                  key={i}
                  onClick={() => this.setState({ selectedAction: x })}
                >
                  {getDisplayName(x.action)}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  onSelect = (row: TableItem, isSelect) => {
    let { selected } = this.state;
    if (isSelect) {
      selected.push(row);
    } else {
      selected = selected.filter((x) => x._id !== row._id);
    }
    this.setState({ selected });
  };

  onSelectAll = (isSelect, rows: any) => {
    let { selected } = this.state;
    if (isSelect) {
      selected = rows;
    } else {
      selected = [];
    }
    this.setState({ selected });
  };

  getSelectRowOptions = () => {
    return {
      mode: 'checkbox',
      onSelect: this.onSelect,
      onSelectAll: this.onSelectAll,
      clickToSelect: false,
      clickToEdit: true,
      hideSelectAll: false,
    };
  };

  getTableData = () => {
    return fixStructure(this.props.data.data);
  };
  render() {
    let data = this.getTableData();
    return (
      <>
        {this.renderActionParamsModal()}
        <DataTable
          data={data}
          divId={rootContainerId}
          options={this.renderActions()}
          selectRow={this.getSelectRowOptions()}
        />
      </>
    );
  }
}

const fetchReportData = async (props) => {
  const { viewData } = props as IProps;
  if (!viewData.url) return;
  let key = viewData.url.split('/').reverse()[0];
  let url = `https://s3.amazonaws.com/${ReportBucket}/${key}.json`;
  let response = await fetch(url);
  let result = await response.json();
  return result;
};

export default withExternalData(InventoryResourceView, fetchReportData);
