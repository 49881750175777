import * as React from 'react';
import { FormikProps } from 'formik';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { CloneFormSchema } from './cloneFormSchema';
import { ResourceGroupsFactory } from '../../common/resourceGroupsFactory';
import { RegionAndCredsComponent } from '../../common/RegionAndCredsComponent';
import { Config } from '../../../lib/store/config/types';

interface FormProps extends FormikProps<CloneFormSchema> {
  allAWSConfigs: { label: string; value: string }[];
  configs: Config[];
  isFetching: boolean;
  response: { success: boolean; error: boolean };
  onClose: () => void;
  errorStatus: string | null;
}

export class CloneForm extends React.Component<FormProps> {
  eventHandlers = {
    onChange: this.props.handleChange,
    onBlur: this.props.handleBlur,
  };

  getFieldValues = (field: keyof CloneFormSchema) => {
    return {
      name: field,
      value: this.props.values[field] || '',
      error: this.props.errors[field]
        ? this.props.errors[field].toString()
        : '',
      touched: this.props.submitCount > 0 || this.props.touched[field] || false,
      placeholder: field,
    };
  };

  render(): React.ReactNode {
    const {
      values,
      configs,
      handleChange,
      allAWSConfigs,
      errorStatus,
    } = this.props;
    return (
      <Modal centered={true} isOpen={true} size="lg">
        <ModalHeader toggle={this.props.onClose}>
          Select Account & Region
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>Select valid accounts to clone workflow</p>
            </Col>
            <Col md={'12'} className={'d-flex align-items-center'}>
              <ResourceGroupsFactory configId={values.configs[0]}>
                {({ availableResourceGroups }) => (
                  <RegionAndCredsComponent
                    configs={configs}
                    isMultiSelect={true}
                    selectedAccount={values.configs}
                    selectedRegion={values.regions}
                    showResourceGroup={true}
                    selectedResourceGroup={values.resourceGroups}
                    availableResourceGroups={availableResourceGroups}
                    onResourceGroupChange={(values) =>
                      handleChange({
                        target: { name: 'resourceGroups', value: values || [] },
                      })
                    }
                    onAccountChange={(values) =>
                      handleChange({
                        target: { name: 'configs', value: values || [] },
                      })
                    }
                    onRegionChange={(values) =>
                      handleChange({
                        target: { name: 'regions', value: values || [] },
                      })
                    }
                  />
                )}
              </ResourceGroupsFactory>
            </Col>
          </Row>

          <Row className={'mt-2'}>
            <Col>
              {this.props.response && this.props.response.success && (
                <span className={'text-success'}>
                  Workflow cloning successful
                </span>
              )}{' '}
              {this.props.response && this.props.response.error && (
                <span className={'text-danger'}>Error in workflow cloning</span>
              )}
            </Col>
          </Row>

          {errorStatus && (
            <div className="text-danger font-12">{errorStatus}</div>
          )}
          <Row className={'my-4'}>
            <Col md={5}>
              {this.props.response && this.props.response.success ? (
                <Button color={'primary'} onClick={this.props.onClose}>
                  Done
                </Button>
              ) : (
                <Button
                  className="test-clone-button"
                  color={'primary'}
                  disabled={this.props.isFetching}
                  onClick={this.props.submitForm}
                >
                  Clone
                </Button>
              )}
            </Col>
            <Col md={1}>
              {this.props.isFetching && (
                <i className={'fa fa-spinner fa-spin fa-2x'} />
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}
