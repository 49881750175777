import { SubscriptionAction, SubscriptionState } from './types';
import { parseSubscription } from './action';
import { Reducer } from 'redux';

const initialState: SubscriptionState = {
  fetching: false,
  fetchingPortalToken: false,
  portalUrl: null,
  subscription: {
    status: null,
  },
};

export const subscriptionReducer: Reducer<
  SubscriptionState,
  SubscriptionAction
> = (state = initialState, action) => {
  switch (action.type) {
    case 'RequestSubscriptionStatus':
      return { ...state, fetching: true };
    case 'SubscriptionStatus':
      return {
        ...state,
        subscription: parseSubscription(action.data),
        fetching: false,
      };
    case 'RequestPortalToken':
      return { ...state, fetchingPortalToken: true };
    case 'ReceivedPortalToken':
      return { ...state, fetchingPortalToken: false, portalUrl: action.url };
    default:
      return state;
  }
};
