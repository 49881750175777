import { TableItem, TableItemFunctions } from '../cols';
import {
  ItemUpdateStateMap,
  MissingWebflowItem,
  TemplateMap,
} from '../../../../lib/store/templates-manage/types';
import { Workflow } from '../../../../lib/store/workflows/types';
import { Template } from '../../../../lib/store/templates/types';
import { ExecutionsMapType } from '../../../../lib/store/executions/types';
import {
  filterItemsByExcludeCategory,
  getFilterCategoriesByTabName,
} from 'webapp-genric/dist/filters';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { CONFIG_TYPES } from 'webapp-genric/dist';
import CloudProvider from 'workflow-model/dist/types/cloudProvider';
import { awsAccountTypeOptions } from '../TemplateModalContent';

export type FormatDataProps = {
  templatesMap: TemplateMap;
  workflows: Workflow[];
  wfToTemplateMap: { [s: string]: Template };
  itemUpdateStateMap: ItemUpdateStateMap;
  executionsMap: ExecutionsMapType;
  functions?: TableItemFunctions;
  webflowRecords?: MissingWebflowItem[];
};

const cloudTypeMap = {
  [CONFIG_TYPES.AWS_PROXY]: CloudProvider.AWS,
  [CONFIG_TYPES.AWS_KEY]: CloudProvider.AWS,
  [CONFIG_TYPES.AWS_ROLE]: CloudProvider.AWS,
  [CONFIG_TYPES.AWS_ORG]: CloudProvider.AWS,
  [CONFIG_TYPES.AWS_ORG_MEMBER]: CloudProvider.AWS,
  [CONFIG_TYPES.AZURE]: CloudProvider.AZURE,
  [CONFIG_TYPES.VMWARE]: CloudProvider.VMWARE,
  [CONFIG_TYPES.Kubernetes]: CloudProvider.Kubernetes,
};

const getCloudTypeFromAllowedAccountTypes = (allowedAccountTypes: string[]) => {
  if (!allowedAccountTypes || allowedAccountTypes.length === 0) {
    return [CloudProvider.UNKNOWN];
  }
  let cloudTypes = [];
  for (let type of allowedAccountTypes) {
    cloudTypes.push(cloudTypeMap[type]);
  }
  return cloudTypes.filter((x, i, a) => a.indexOf(x) === i);
};

export const formatTableData = (props: FormatDataProps): TableItem[] => {
  let {
    workflows,
    itemUpdateStateMap,
    wfToTemplateMap,
    executionsMap,
    functions,
    templatesMap,
  } = props;
  let result: TableItem[] = [];
  let templatesToLink = filterItemsByExcludeCategory(
    Object.keys(templatesMap).map((x) => templatesMap[x]),
    getFilterCategoriesByTabName(TAB_NAME.INSIGHTS),
  ).map((template: Template) => ({
    value: template.id,
    label: template.name,
  }));

  for (let i = 0; i < workflows.length; i++) {
    let wf = workflows[i];
    let templateItem = wfToTemplateMap[wf.workflow];
    let wfExecution = executionsMap[wf.workflow];
    let destroyStackWf = workflows.find(
      (x) =>
        x.name.toLowerCase().startsWith('destroy_stack') &&
        x.name.toLowerCase().replace(/^destroy_stack/, 'create_stack') ===
          wf.name.toLowerCase(),
    );
    let destroyStackWfExecution =
      destroyStackWf && executionsMap[destroyStackWf.workflow];
    let status = (templateItem && itemUpdateStateMap[templateItem.id]) || {};
    status = Object.assign(status, itemUpdateStateMap[wf.workflow] || {});
    result.push({
      _id: i,
      id: wf.workflow,
      workflowName: wf.name,
      workflowUpdatedAt: wf.updatedAt || wf.createdAt,
      templateName: templateItem && templateItem.name,
      templateCategory: templateItem && templateItem.category,
      templateSubCategory: templateItem && templateItem.subCategory,
      templatePriority: templateItem && (templateItem.properties.priority || 0),
      templateQueueable:
        templateItem && (templateItem.properties.queueable || false),
      linkedTemplates:
        (templateItem &&
          templateItem.properties &&
          templateItem.properties.linkedTemplates) ||
        [],
      testSettings:
        (templateItem &&
          templateItem.properties &&
          templateItem.properties.testSettings) ||
        null,
      templateTestStatus:
        (templateItem &&
          templateItem.properties &&
          templateItem.properties.testStatus) ||
        null,
      templatesToLink: templateItem
        ? templatesToLink.filter((x) => x.value !== templateItem.id)
        : templatesToLink,
      slug:
        templateItem &&
        templateItem.properties.webflow &&
        templateItem.properties.webflow.slug,
      templateItem,
      workflowItem: wf,
      executions: (wfExecution && wfExecution.exes) || [],
      destroyStackWfExecutions:
        (destroyStackWfExecution && destroyStackWfExecution.exes) || [],
      destroyStackWf,
      isFetchingExecution: wfExecution && wfExecution.fetching,
      categories: wf.properties.categories,
      ...status,
      functions,
    });
  }
  return result;
};

export const formatTemplateData = (
  props: FormatDataProps,
  templateIds: string[],
): TableItem[] => {
  let result: TableItem[] = [];
  let { templatesMap, itemUpdateStateMap, functions } = props;
  let templatesToLink = filterItemsByExcludeCategory(
    Object.keys(templatesMap).map((x) => templatesMap[x]),
    getFilterCategoriesByTabName(TAB_NAME.INSIGHTS),
  ).map((template: Template) => ({
    value: template.id,
    label: template.name,
  }));

  for (let i = 0; i < templateIds.length; i++) {
    let temp = templateIds[i];
    let status = itemUpdateStateMap[templatesMap[temp].id] || {};
    result.push({
      _id: i,
      id: temp,
      workflowName: '',
      workflowUpdatedAt: '',
      templateName: templatesMap[temp].name,
      templateCategory: templatesMap[temp].category,
      templateSubCategory: templatesMap[temp].subCategory,
      templatePriority: templatesMap[temp].properties.priority || 0,
      templateQueueable: templatesMap[temp].properties.queueable || false,
      cloudTypes: getCloudTypeFromAllowedAccountTypes(
        templatesMap[temp].properties.allowedAccountTypes,
      ),
      linkedTemplates:
        (templatesMap[temp] &&
          templatesMap[temp].properties &&
          templatesMap[temp].properties.linkedTemplates) ||
        [],
      slug:
        templatesMap[temp].properties.webflow &&
        templatesMap[temp].properties.webflow.slug,
      templateItem: templatesMap[temp],
      workflowItem: null,
      executions: [],
      categories: templatesMap[temp].categories,
      isFetchingExecution: false,
      templatesToLink: templatesToLink.filter((x) => x.value !== temp),
      ...status,
      functions,
      testSettings:
        templatesMap[temp].properties &&
        templatesMap[temp].properties.testSettings,
      templateTestStatus:
        templatesMap[temp].properties &&
        templatesMap[temp].properties.testStatus,
    });
  }
  return result;
};

function getOrphanTemplates(params: {
  workflows: Workflow[];
  templatesMap: TemplateMap;
}): string[] {
  let { workflows, templatesMap } = params;
  let existingWorkflowIds = workflows ? workflows.map((x) => x.workflow) : [];
  let orphanTemplates = [];
  for (let key in templatesMap) {
    if (
      existingWorkflowIds.indexOf(
        templatesMap[key].properties.originWorkflow,
      ) === -1
    )
      orphanTemplates.push(key); // only push ids dont create copies of data;
  }
  return orphanTemplates;
}

export const getTableData = (
  params: FormatDataProps,
): {
  data: TableItem[];
  orphanTemplatesData: TableItem[];
  allTemplatesData: TableItem[];
} => {
  let data = formatTableData(params);
  let templateIds = Object.keys(params.templatesMap);
  let orphanTemplateIds = getOrphanTemplates({
    workflows: params.workflows,
    templatesMap: params.templatesMap,
  });
  let orphanTemplatesData = formatTemplateData(params, orphanTemplateIds);
  let allTemplatesData = formatTemplateData(params, templateIds);
  return { data, orphanTemplatesData, allTemplatesData };
};
