import { Transform } from './index';

export class TcPropsExclusion extends Transform {
  apply(block: any): any {
    return JSON.parse(JSON.stringify(block), function(key, value) {
      if (value && value.tcProperties) {
        delete value.tcProperties;
      }
      return value;
    });
  }
}
