import {
  GetGroupWorkflowRequest,
  GetGroupWorkflowResponse,
  GetHistory,
  GetHistorySuccess,
  GetWorkflowResponseType,
  GroupPolicyRequest,
  GroupPolicyResponse,
  GroupWorkflowAction,
  GroupWorkflowListResult,
  ListGroupWorkflowRequest,
  ListGroupWorkflowResponse,
  MultiGroupPolicyRequest,
  MultiGroupPolicyResponse,
  PolicyResponse,
  SaveGroupWorkflowRequest,
  SaveGroupWorkflowResponse,
  TestGroupWorkflowRequest,
  TestGroupWorkflowResponse,
  FetchBatchHistoryRequest,
  FetchBatchHistoryResponse,
  SaveBatchHistoryResponse,
  SaveFetchingGroupsId,
  SetGroupWorkflowDeletedResponse,
} from './type';
import api from '../../../api';
import { notify, Toaster } from '../../../components/common/toaster';
import {
  ChildWorkflow,
  GroupWorkflowSerializedType,
  IGroupWorkflowModel,
  WORKFLOW_ACTION,
} from 'workflow-model/dist/types/IGroupWorkflowModel';
import { StepType } from '../../../components/schedules/types';
import { getSerializedGroupModel } from '../../../components/schedules/converter';
import shortHash from 'short-hash';
import uuid from 'uuid';
import { fetchWorkflow } from '../workflows/action';
import { GroupHistory } from 'workflow-model/dist';

const FetchTestGroupWorkflowRequest = function(): GroupWorkflowAction {
  return {
    type: TestGroupWorkflowRequest,
  };
};

const FetchTestGroupWorkflowResponse = function(
  data: [] | null,
): GroupWorkflowAction {
  return {
    type: TestGroupWorkflowResponse,
    resources: data as { [s: string]: any }[],
  };
};

const FetchSaveWorkflowRequest = function(): GroupWorkflowAction {
  return {
    type: SaveGroupWorkflowRequest,
  };
};

const FetchSaveWorkflowResponse = function(): GroupWorkflowAction {
  return {
    type: SaveGroupWorkflowResponse,
  };
};

const FetchListGroupWfRequest = function(): GroupWorkflowAction {
  return {
    type: ListGroupWorkflowRequest,
  };
};

const FetchListGroupWfResponse = function(
  data: GroupWorkflowListResult[] | null,
): GroupWorkflowAction {
  return {
    type: ListGroupWorkflowResponse,
    data: data as GroupWorkflowListResult[],
  };
};

function sendGetHistoryRequest(): GroupWorkflowAction {
  return {
    type: GetHistory,
  };
}
export const SendBatchHistoryRequest = function(): GroupWorkflowAction {
  return {
    type: FetchBatchHistoryRequest,
  };
};

export const SendBatchHistoryResponse = function(): GroupWorkflowAction {
  return {
    type: FetchBatchHistoryResponse,
  };
};

function SendBatchGroupHistoryResponse(historyData): GroupWorkflowAction {
  return {
    type: SaveBatchHistoryResponse,
    data: historyData as any[],
  };
}

function SendGroupHistoryRequest(groups): GroupWorkflowAction {
  return {
    type: SaveFetchingGroupsId,
    data: groups as any[],
  };
}

function getHistoryResponse(group: string, history: any): GroupWorkflowAction {
  return {
    type: GetHistorySuccess,
    data: history as GroupHistory[],
    group: group,
  };
}

function getGroupWorkflowRequest(group: string): GroupWorkflowAction {
  return {
    type: GetGroupWorkflowRequest,
    group,
  };
}

function setGroupWorkflowDeletedResponse(group: string): GroupWorkflowAction {
  return {
    type: SetGroupWorkflowDeletedResponse,
    group,
  };
}

function getGroupWorkflowResponse(
  group: string,
  data: GetWorkflowResponseType,
): GroupWorkflowAction {
  return {
    type: GetGroupWorkflowResponse,
    data,
    group,
  };
}

export const listGroupWfsAndChildWfs = (params) =>
  listGroupWfsAndChildWfsInner(params).catch((e) => {
    notify({ type: 'error', message: e.message });
  });

const listGroupWfsAndChildWfsInner = async (params: {
  dispatch: (e: any) => void;
}) => {
  let groupWfs: GroupWorkflowListResult[] = await listGroupWorkflows(params);
  fetchWorkflow(params);
  return Promise.all(
    groupWfs.map((x) => getGroupWorkflow({ ...params, group: x.group })),
  );
};

function sendGroupPolicyRequest(): GroupWorkflowAction {
  return {
    type: GroupPolicyRequest,
  };
}

function receivedGroupPolicyResponse(
  group: string,
  data: any,
): GroupWorkflowAction {
  return {
    type: GroupPolicyResponse,
    data: data as PolicyResponse,
    group,
  };
}

function sendMultiGroupPolicyRequest(): GroupWorkflowAction {
  return {
    type: MultiGroupPolicyRequest,
  };
}

function receivedMultiGroupPolicyResponse(
  group: string,
  data: any,
): GroupWorkflowAction {
  return {
    type: MultiGroupPolicyResponse,
    data: data as PolicyResponse,
    group,
  };
}

export const listGroupWorkflows = (params: { dispatch: (e: any) => void }) => {
  let { dispatch } = params;
  dispatch(FetchListGroupWfRequest());
  return api
    .listGroupWorkflows()
    .then((res) => {
      dispatch(FetchListGroupWfResponse(res));
      return res;
    })
    .catch((e) => {
      new Toaster({ type: 'error', message: e.message || e });
      dispatch(FetchListGroupWfResponse(null));
      return [];
    });
};

export const getGroupWorkflow = (params: {
  dispatch: (e: any) => void;
  group: string;
}) => {
  let { dispatch, group } = params;
  dispatch(getGroupWorkflowRequest(group));
  return api
    .getGroupWorkflow({ group })
    .then((res) => {
      return dispatch(getGroupWorkflowResponse(group, res));
    })
    .catch((e) => {
      new Toaster({ type: 'error', message: e.message || e });
      return dispatch(getGroupWorkflowResponse(group, null));
    });
};

const waitForSeconds = async (timeoutInSeconds) => {
  return new Promise((resolve) => setTimeout(resolve, timeoutInSeconds * 1000));
};

const testScheduleInner = async (params: {
  dispatch: (e: any) => void;
  steps: StepType[];
  groupModel: IGroupWorkflowModel;
  skipValidation: boolean;
}) => {
  let { dispatch, steps, groupModel, skipValidation } = params;

  // serialize group modal
  let groupObj: GroupWorkflowSerializedType = getSerializedGroupModel({
    steps,
    groupWorkflow: groupModel,
    skipValidation,
    validationOptions: {
      skipNameValidation: true,
    },
  });

  dispatch(FetchTestGroupWorkflowRequest());

  // set default id if new workflow
  let unParkedWorkflow = groupObj.workflows.find(
    (x) => x.action === WORKFLOW_ACTION.START,
  ).workflow;
  let workflowId = unParkedWorkflow.workflow;
  if (!workflowId) {
    workflowId = shortHash(uuid());
    unParkedWorkflow.workflow = workflowId;
  }
  await api.testGroupWorkflow(groupObj);
  for (let i = 0; i < 6; i++) {
    await waitForSeconds(3);
    let resources = await api.getLatestResources({
      workflows: [
        { action: WORKFLOW_ACTION.START, workflow: { workflow: workflowId } },
      ],
    });
    if (resources && (resources.data === null || typeof resources === 'string'))
      continue; // to do remove check
    return dispatch(FetchTestGroupWorkflowResponse(resources));
  }
  throw new Error('Could not fetch resources');
};

export const testSchedule = (params) =>
  testScheduleInner(params).catch((e) => {
    notify({ type: 'error', message: e.message });
    params.dispatch(FetchTestGroupWorkflowResponse(null));
  });

export const getResources = (params) =>
  getResourcesInner(params).catch((e) => {
    notify({ type: 'error', message: e.message });
    params.dispatch(FetchTestGroupWorkflowResponse(null));
  });

const getResourcesInner = async (params: {
  dispatch: (e: any) => void;
  steps: StepType[];
  groupModel: IGroupWorkflowModel;
  skipValidation: boolean;
}) => {
  let { dispatch, steps, groupModel, skipValidation } = params;
  let groupObj: GroupWorkflowSerializedType = getSerializedGroupModel({
    steps,
    groupWorkflow: groupModel,
    skipValidation,
    validationOptions: {
      skipNameValidation: true,
    },
  });

  dispatch(FetchTestGroupWorkflowRequest()); // change to istesting

  if (groupObj.group) {
    let res = await api.getLatestResources({ group: groupObj.group });
    return dispatch(FetchTestGroupWorkflowResponse(res));
  } else {
    return testSchedule(params);
  }
};

export const deleteWorkflow = (params: {
  dispatch: (e: any) => void;
  groupModel: IGroupWorkflowModel;
}) => {
  let { groupModel, dispatch } = params;
  dispatch(getGroupWorkflowRequest(groupModel.getId()));
  api
    .deleteGroupWorkflow({
      group: groupModel.getId(),
    })
    .then((x) => {
      notify({
        type: 'success',
        message: 'Successfully deleted workflow',
      });
      dispatch(setGroupWorkflowDeletedResponse(groupModel.getId()));
      // return listGroupWorkflows({ ...params });
    })
    .catch((e) => {
      notify({ type: 'error', message: e.message });
      dispatch(setGroupWorkflowDeletedResponse(groupModel.getId()));
      // return listGroupWorkflows({ ...params });
    });
};

export const triggerSkipAction = (params: {
  dispatch: (e: any) => void;
  group: string;
  cron: { startCron: string; endCron: string };
}) => {
  let { group, dispatch, cron } = params;
  dispatch(getGroupWorkflowRequest(group));
  api
    .groupWorkflowSkipAction({
      cron,
      group,
    })
    .then((x) => {
      notify({
        type: 'success',
        message: 'Skip Action trigger successfully',
      });
      getHistory({ dispatch, group });
      return listGroupWfsAndChildWfs({ dispatch: params.dispatch });
    })
    .catch((e) => {
      notify({ type: 'error', message: e.message });
      return listGroupWfsAndChildWfs({ dispatch: params.dispatch });
    });
};

export const triggerAction = (params: {
  dispatch: (e: any) => void;
  groupModel: IGroupWorkflowModel;
  action: WORKFLOW_ACTION;
}) => {
  let { groupModel, action, dispatch } = params;
  dispatch(getGroupWorkflowRequest(groupModel.getId()));
  api
    .groupWorkflowAction({
      data: { force: true },
      group: groupModel.getId(),
      action,
    })
    .then((x) => {
      notify({
        type: 'success',
        message:
          action === WORKFLOW_ACTION.STOP
            ? 'Stop workflow triggered successfully'
            : 'Start workflow triggered Workflow',
      });
      getHistory({ dispatch, group: groupModel.getId() });
      return listGroupWfsAndChildWfs({ dispatch: params.dispatch });
    })
    .catch((e) => {
      notify({ type: 'error', message: e.message });
      return listGroupWfsAndChildWfs({ dispatch: params.dispatch });
    });
};

export const toggleGroupWorkflow = async (params: {
  dispatch: (e: any) => void;
  groupModel: IGroupWorkflowModel;
}) => {
  let { dispatch, groupModel } = params;
  dispatch(getGroupWorkflowRequest(groupModel.getId()));
  await saveSchedule({ dispatch, groupModel, steps: [], skipValidation: true });
  await getGroupWorkflow({ ...params, group: groupModel.getId() });
};

export const saveSchedule = (params: {
  dispatch: (e: any) => void;
  steps: StepType[];
  groupModel: IGroupWorkflowModel;
  skipValidation?: boolean;
}) =>
  saveScheduleInner(params).catch((e) => {
    notify({ type: 'error', message: e.message });
    params.dispatch(FetchSaveWorkflowResponse());
  });

export const saveScheduleInner = async (params: {
  dispatch: (e: any) => void;
  steps: StepType[];
  groupModel: IGroupWorkflowModel;
  skipValidation?: boolean;
}) => {
  let { dispatch, steps, groupModel, skipValidation = false } = params;

  let group: GroupWorkflowSerializedType = getSerializedGroupModel({
    steps,
    groupWorkflow: groupModel,
    skipValidation,
  });

  dispatch(FetchSaveWorkflowRequest());
  if (group.group) {
    await api.updateGroupWorkflow(group);
  } else {
    let res = await api.saveGroupWorkflow(group);
    // update id of group model and child workflows;
    groupModel.setId(res.group);
    if (res && res.workflows && res.workflows.length) {
      for (let wf of res.workflows) {
        let childWorkflow: ChildWorkflow = groupModel.getChildWorkflowByName(
          wf.name,
        );
        childWorkflow && childWorkflow.workflow.setId(wf.workflow);
      }
    }
  }
  await getGroupWorkflow({ ...params, group: groupModel.getId() });
  notify({ type: 'success', message: 'Successfully saved workflow' });
  dispatch(FetchSaveWorkflowResponse());
  await listGroupWorkflows({ ...params });
};

export const saveScheduleAction = async (params: {
  dispatch: (e: any) => void;
  steps: StepType[];
  groupModel: IGroupWorkflowModel;
  skipValidation?: boolean;
}): Promise<string | undefined> => {
  let { dispatch, steps, groupModel, skipValidation = false } = params;

  try {
    let group: GroupWorkflowSerializedType = getSerializedGroupModel({
      steps,
      groupWorkflow: groupModel,
      skipValidation,
    });

    dispatch(FetchSaveWorkflowRequest());
    if (groupModel.getId()) {
      await api.updateGroupWorkflow(group);
    } else {
      let res = await api.saveGroupWorkflow(group);
      // update id of group model and child workflows;
      groupModel.setId(res.group);
      if (res && res.workflows && res.workflows.length) {
        for (let wf of res.workflows) {
          let childWorkflow: ChildWorkflow = groupModel.getChildWorkflowByName(
            wf.name,
          );
          childWorkflow && childWorkflow.workflow.setId(wf.workflow);
        }
      }
    }
    notify({ type: 'success', message: 'Successfully saved workflow' });
    params.dispatch(FetchSaveWorkflowResponse());
    return groupModel.getId();
  } catch (e) {
    notify({ type: 'error', message: e.message });
    params.dispatch(FetchSaveWorkflowResponse());
    return;
  }
};

export const getHistory = (params: {
  dispatch: (e: any) => void;
  group: string;
}) => {
  const { dispatch, group } = params;
  dispatch(sendGetHistoryRequest());
  api
    .getGroupHistory({ group })
    .then((res) => {
      return dispatch(getHistoryResponse(group, res));
    })
    .catch((e) => {
      notify(e);
      return dispatch(getHistoryResponse(group, null));
    });
};

export const getMultipleHistory = (props: {
  dispatch: (e: any) => void;
  groups: GroupWorkflowListResult[];
}) => {
  let { dispatch, groups } = props;
  dispatch(SendBatchHistoryRequest());
  getMultipleHistoryInner({ dispatch, groups })
    .then(() => {
      dispatch(SendBatchHistoryResponse());
    })
    .catch((e) => {
      dispatch(SendBatchHistoryResponse());
      notify({ type: 'error', message: e.message || e });
    });
};

export const getMultipleHistoryInner = async (props: {
  dispatch: (e: any) => void;
  groups: GroupWorkflowListResult[];
}) => {
  let { dispatch, groups } = props;
  // should be same as pageSize
  const batchSize = 20;
  const groupIds = groups.map((schedule) => schedule.group);
  dispatch(SendGroupHistoryRequest(groupIds));
  for (let i = 0; i < groupIds.length; i += batchSize) {
    const batch = groupIds.slice(i, i + batchSize);
    const batchResult = await Promise.all(
      batch.map((x) => fetchGroupHistoryInner(dispatch, x)),
    );
    dispatch(SendBatchGroupHistoryResponse(batchResult));
  }
};

async function fetchGroupHistoryInner(
  dispatch: (e: any) => void,
  group: string,
): Promise<{
  [s: string]: GroupHistory;
}> {
  let history = await api.getGroupHistory({
    group,
  });
  return { [group]: history };
}

export const getGroupPolicy = (params) =>
  getGroupPolicyInner(params).catch((e) => {
    return params.dispatch(
      receivedGroupPolicyResponse(params.groupWorkflow.getId(), null),
    );
  });

const getGroupPolicyInner = async (params: {
  dispatch: (e: any) => void;
  groupWorkflow: IGroupWorkflowModel;
}): Promise<PolicyResponse> => {
  const { dispatch, groupWorkflow } = params;
  let group: GroupWorkflowSerializedType = getSerializedGroupModel({
    validationOptions: { skipNameValidation: true },
    steps: [],
    groupWorkflow,
    skipValidation: true,
  });

  dispatch(sendGroupPolicyRequest());

  let ps: Promise<PolicyResponse>;
  let groupId: string;
  // if (groupWorkflow.getId()) {
  //   ps = api.fetchGroupPolicy({ group: groupWorkflow.getId() });
  //   groupId = groupWorkflow.getId();
  // } else {
  ps = api.fetchGroupPolicy(group);
  groupId = groupWorkflow.getId() || 'create'; // set policy doc with this id.
  // }
  ps.then((res) => {
    return dispatch(receivedGroupPolicyResponse(groupId, res));
  }).catch((err) => {
    console.error(err);
    notify(err);
    return dispatch(receivedGroupPolicyResponse(groupId, null));
  });
  return ps;
};

export function getMultiGroupPolicy(params: {
  dispatch: (e: any) => void;
  groups: string[];
}): Promise<PolicyResponse> {
  const { dispatch, groups } = params;
  const combinedKey = groups.sort().reduce((a, e) => a + e, '');
  dispatch(sendMultiGroupPolicyRequest());
  const ps = api.fetchSchedulePolicy({ groups });
  ps.then((res) => {
    return dispatch(receivedMultiGroupPolicyResponse(combinedKey, res));
  }).catch((err) => {
    console.error(err);
    notify(err);
    return dispatch(receivedMultiGroupPolicyResponse(combinedKey, null));
  });
  return ps;
}
