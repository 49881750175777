import React, { Component } from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { Link } from 'react-router-dom';

import Description from './Description';
import BallotImage from '../assets/images/ballot-check.png';
import BallotImageUncheck from '../assets/images/ballot-uncheck.png';
import { getLoginErrorMessage } from './ErrorHandler';
import { Col } from 'reactstrap';
import { observe } from '../../../analytics/eventCapturer';
import { loadOrgUser } from '../common/loadOrgUser';
import { OktaConfig } from '../../../config/okta';

const emailRegex = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
);

interface LoginFormProps {
  baseUrl?: string;
  auth?: any;
}

interface LoginFormState {
  sessionToken: string;
  email?: string;
  password?: string;
  loginFailed?: boolean;
  loginFailedMessage?: string;
  emailValidate?: boolean;
  keepmelogin?: boolean;
  passwordValidate?: boolean;
  loginStage?: boolean;
  isEmailDirty: boolean;
  isPasswordDirty: boolean;
}

/**
 * @see https://www.thepolyglotdeveloper.com/2016/05/add-type-definitions-external-javascript-library-typescript/
 */
const style = {
  width: '80%',
  margin: 'auto',
};

export default class LoginForm extends Component<
  LoginFormProps,
  LoginFormState
> {
  oktaAuth: any;

  constructor(props: LoginFormProps) {
    super(props);
    this.state = {
      sessionToken: '',
      email: '',
      password: '',
      loginFailed: false,
      loginFailedMessage: '',
      emailValidate: true,
      keepmelogin: false,
      passwordValidate: true,
      loginStage: false,
      isEmailDirty: false,
      isPasswordDirty: false,
    };

    // this.oktaAuth = new OktaAuth({ url: props.baseUrl });

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState(
      {
        isEmailDirty: true,
        isPasswordDirty: true,
      },
      () => {
        if (!emailRegex.test(String(email))) {
          this.setState({ loginStage: false });
        } else {
          this.setState({ loginStage: true });
          loadOrgUser(email)
            .then(() => {
              this.oktaAuth = new OktaAuth({
                pkce: false,
                issuer: OktaConfig.issuer,
                url: OktaConfig.oauth_url,
                clientId: OktaConfig.client_id,
                redirectUri: OktaConfig.redirect_uri,
                scopes: OktaConfig.scope,
              });
              return observe(
                this.oktaAuth.signIn({
                  username: email,
                  password: password,
                }),
                'LOGIN',
              );
            })
            .then((transaction: any) => {
              if (transaction.status === 'SUCCESS') {
                this.setState({
                  sessionToken: transaction.sessionToken,
                  loginStage: false,
                  isEmailDirty: false,
                  isPasswordDirty: false,
                });
                localStorage.setItem('sessionToken', transaction.sessionToken);
                OktaConfig.persist();
                return this.oktaAuth.token
                  .getWithRedirect({
                    sessionToken: transaction.sessionToken,
                    state: OktaConfig.tenantId,
                    scopes: OktaConfig.scope.split(' '),
                    responseType: ['id_token', 'token'],
                  })
                  .then(() => {});
              } else {
                throw 'We cannot handle the ' + transaction.status + ' status';
              }
            })
            .catch((err: any) => {
              this.setState({
                loginFailed: true,
                loginStage: false,
                // loginFailedMessage: 'Email or Password are incorrect',
                loginFailedMessage: getLoginErrorMessage(err),
              });
              console.error(err);
            });
        }
      },
    );
  }

  handleEmailChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      email: e.currentTarget.value,
      loginFailed: false,
      emailValidate: emailRegex.test(e.currentTarget.value),
      isEmailDirty: true,
    });
  }

  handlePasswordChange(e: React.FormEvent<HTMLInputElement>) {
    this.setState({
      password: e.currentTarget.value,
      loginFailed: false,
      isPasswordDirty: true,
    });
  }
  render() {
    const {
      loginFailed,
      loginFailedMessage,
      emailValidate,
      loginStage,
      email,
      password,
      keepmelogin,
      isEmailDirty,
      isPasswordDirty,
    } = this.state;

    let large = window.innerWidth > 500;

    return (
      <div className="login test-login-widget d-flex align-items-center position-relative">
        <div
          className="row"
          style={large ? style : { fontSize: '1rem', margin: 0 }}
        >
          <div className="d-none d-md-block col-lg-6 col-xs-12">
            <Description />
          </div>
          <div
            className={`${
              large ? 'col-lg-5 offset-lg-1 col-xs-12' : 'col p-0'
            }`}
          >
            <div
              className={`row test-login-form login-form ${
                large ? '' : ' m-0'
              }`}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <div className="form-row my-2 title-row">
                    <h3>Login</h3>
                  </div>
                  <div className="form-row my-4 email">
                    <input
                      type="text"
                      className="form-control context-input test-email-field"
                      placeholder="Email"
                      value={email}
                      onChange={this.handleEmailChange}
                      onBlur={(e) => {}}
                    />
                    {email && !emailValidate && (
                      <small>Email is not valid</small>
                    )}
                    {!email && isEmailDirty && <small>Email is required</small>}
                  </div>
                  <div className="form-row my-4 password">
                    <input
                      type="password"
                      className="form-control context-input test-password-field"
                      placeholder="Password"
                      value={password}
                      onChange={this.handlePasswordChange}
                    />
                    {!password && isPasswordDirty && (
                      <small>Password is required</small>
                    )}
                  </div>
                  <div className="form-row keepmelogin my-4">
                    <Col md={6}>
                      {keepmelogin ? (
                        <img
                          src={BallotImage}
                          width="20px"
                          className="tc-ala tc-ala-unchecked-keep-login"
                          onClick={() =>
                            this.setState({
                              keepmelogin: !this.state.keepmelogin,
                            })
                          }
                          alt="checkmark"
                        />
                      ) : (
                        <img
                          src={BallotImageUncheck}
                          width="20px"
                          className="tc-ala tc-ala-checked-keep-login"
                          onClick={() =>
                            this.setState({
                              keepmelogin: !this.state.keepmelogin,
                            })
                          }
                          alt="checkmark"
                        />
                      )}
                      <span className="text-white">Keep me logged in</span>
                    </Col>
                    <Col md={'6'} className="forgot-password">
                      <Link
                        to="/forgotpassword"
                        className="forgot-link test-forgot-link"
                      >
                        Forgot Password?
                      </Link>
                    </Col>
                  </div>
                  <div className={`form-row mt-3 ${!loginFailed && `mb-5`}`}>
                    <button
                      type="submit"
                      className="btn btn-primary mb-2 login-button test-login-button"
                    >
                      {loginStage ? `Logging In...` : `Login`}
                    </button>
                  </div>
                  {loginFailed && (
                    <div className="form-row mb-5 failed-message test-login-error">
                      <p>{loginFailedMessage}</p>
                    </div>
                  )}
                </div>
              </form>
              <div className="form-row have-account d-none">
                <h6>
                  Create a FREE account.
                  <Link to="/signup" className="test-signup-button">
                    Register
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="position-absolute bottom-0 left-0 w-100 bg-primary-bg text-center text-white  font-weight-bold right-0 py-3 px-5">
          All rights reserved. Copyright &copy; 2018 - 2021. Kandor Soft Labs
          Private Limited.
        </div>
      </div>
    );
  }
}
