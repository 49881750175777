import { ICustomTableCol } from '../../common/table';
import {
  Template,
  TemplateTestSettings,
  TemplateTestStatus,
} from '../../../lib/store/templates/types';
import * as React from 'react';
import { Workflow } from '../../../lib/store/workflows/types';
import {
  Execution,
  ExecutionDataType,
} from '../../../lib/store/executions/types';
import { WorkflowName } from './cols/workflowName';
import { TemplateName, TemplateNameValidator } from './cols/TemplateName';
import { TemplateCategory } from './cols/TemplateCategory';
import { PriorityValidator, TemplatePriority } from './cols/TemplatePriority';
import { TemplateStatus, TemplateStatusSortValue } from './cols/TemplateStatus';
import { WorkflowLastUpdated } from './cols/WorkflowLastUpdated';
import { getPastExecutionsFormatter } from '../../workflows/common/workflowTableHelper';
import { TemplateOptions } from './cols/TemplateOptions';
import { WorkflowOptions } from './cols/WorkflowOptions';
import { LinkedTemplatesDisplay } from './cols/LinkedTemplates';
import { TestedOnDisplay, TestStatusDisplay } from './cols/TemplateTestStatus';
import { TemplateTestSettingsDisplay } from './cols/TemplatesTestSettings';
import { CFNameDisplay, getCFCreateStackWfNames } from './cols/CFName';
import { Type } from 'react-bootstrap-table2-editor';
import InputSelect from '../../../lib/formInputs/select-option.component';
import { Link } from 'react-router-dom';
import {
  StackWfsCreatedStatusDisplay,
  StackWfsDestroyedStatusDisplay,
} from './cols/StackWfsStatus';

export enum TemplateColNames {
  'ID' = 'id',
  'WORKFLOW_NAME' = 'Workflow_name',
  'TEMPLATE_NAME' = 'Template_name',
  'CREATE_STACK_WORKFLOW_NAME' = 'Create_Stack_Workflow_Name',
  'TEMPLATE_CATEGORY' = 'Category',
  'TEMPLATE_PRIORITY' = 'Priority',
  'TEMPLATE_QUEUEABLE' = 'Queueable',
  'TEMPLATE_SEVERITY' = 'Severity',
  'CF_LAST_CREATED' = 'CF_Last_Created',
  'CF_LAST_DESTROYED' = 'CF_Last_Destroyed',
  'TEMPLATE_STATUS' = 'Status',
  'TEMPLATE_OPT' = 'Template_Opt',
  'WORKFLOW_OPT' = 'Workflow_Opt',
  'WEBFLOW_OPT' = 'Webflow_Opt',
  'PAST_EXECUTIONS' = 'Past_Executions',
  'LAST_UPDATED' = 'Last_Updated',
  'LINKED_TEMPLATES' = 'Linked_Templates',
  'TESTED_ON' = 'Tested_On',
  'TEST_STATUS' = 'Test_Status',
  'TEST_SETTING' = 'Test_Setting',
  'CLOUD_TYPES' = 'Cloud_Types',
}

export type TableItemFunctions = {
  updateCategories: ({ category, subCategory, template }) => void;
  updateTemplatePriority: (priority: number, template: Template) => void;
  updateQueueable: (queueable: boolean, template: Template) => void;
  updateTemplateTestSettings: (
    testSettings: TemplateTestSettings,
    template: Template,
  ) => void;
  updateLinkedTemplate: (props: {
    linkedTemplates: string[];
    template: Template;
  }) => void;
  updateTemplateName: (name: string, template: Template) => void;
  deleteTemplate: (props: { template: Template; col: string }) => void;
  toggleTemplateModal: (template: Template) => void;
  convertWorkflowToTemplate: (props: {
    workflow: Workflow;
    template: Template;
    col: string;
  }) => void;
  toggleWorkflowStatus: (props: { workflow: Workflow; col: string }) => void;
  removeTemplateTestCategory: (workflow: Workflow) => void;
  addTemplateTestCategory: (workflow: Workflow) => void;
  toggleTemplateStatus: (status: boolean, template: Template) => void;
  getCustomWorkflows: () => Workflow[];
  getTemplateTesterWorkflows: () => { name: string; workflow: string }[];
};

export type TableItem = {
  _id: number;
  id: string;
  workflowName: string;
  workflowUpdatedAt: string;
  templateName: string;
  templateCategory: string[];
  templateSubCategory: string[];
  templatePriority: number;
  templateQueueable: boolean;
  cloudTypes?: string[];
  templateItem: Template;
  workflowItem: Workflow;
  executions: Execution[];
  destroyStackWfExecutions?: Execution[];
  destroyStackWf?: Workflow;
  isFetchingExecution: boolean;
  updating?: string;
  slug: string;
  categories: { category: string; subCategories: string[] }[];
  functions: TableItemFunctions;
  linkedTemplates: string[];
  templatesToLink: {
    value: string;
    label: string;
  }[];

  testSettings: TemplateTestSettings;
  templateTestStatus: TemplateTestStatus;
};

export const cols: ICustomTableCol[] = [
  {
    dataField: 'id',
    text: '',
    hidden: true,
    colName: TemplateColNames.ID,
  },
  {
    dataField: 'workflowName',
    text: 'Wf Name',
    colName: TemplateColNames.WORKFLOW_NAME,
    sort: true,
    editable: false,
    formatter: (cell: string, row: TableItem) => {
      return WorkflowName(cell, row);
    },
  },
  {
    dataField: 'templateName',
    text: 'T Name',
    colName: TemplateColNames.TEMPLATE_NAME,
    sort: true,
    editable: (col, row: TableItem) => !!row.templateItem,
    formatter: (cell: string, row: TableItem) => {
      return TemplateName(cell, row);
    },
    validator: (newValue, row, column) => {
      return TemplateNameValidator(newValue);
    },
  },
  {
    dataField: 'testSettings.createStackWorkflows',
    text: 'Create Stack Wf',
    colName: TemplateColNames.CREATE_STACK_WORKFLOW_NAME,
    editable: false,
    sort: true,
    filterValue: getCFCreateStackWfNames,
    sortValue: getCFCreateStackWfNames,
    formatter: CFNameDisplay,
  },
  {
    dataField: 'templateCategory',
    text: 'Category',
    headerStyle: { width: '90px' },
    colName: TemplateColNames.TEMPLATE_CATEGORY,
    editable: false,
    filterValue: (cell: string[], row: TableItem) => {
      return (
        (cell ? cell.join(',') : '') +
        ' ' +
        (row.templateSubCategory ? row.templateSubCategory.join(',') : '')
      );
    },
    formatter: (cell: string[], row: TableItem) => {
      return TemplateCategory(cell, row);
    },
  },
  {
    dataField: 'templatePriority',
    text: 'Priority',
    headerStyle: { width: '90px' },
    colName: TemplateColNames.TEMPLATE_PRIORITY,
    sort: true,
    editable: (col, row: TableItem) => !!row.templateItem,
    formatter: (col: number, row: TableItem) => {
      return TemplatePriority(col, row);
    },
    validator: (newValue, row, column) => {
      return PriorityValidator(newValue);
    },
  },
  {
    dataField: 'templateQueueable',
    text: 'Queueable',
    headerStyle: { width: '90px' },
    colName: TemplateColNames.TEMPLATE_QUEUEABLE,
    sort: true,
    editor: {
      type: Type.CHECKBOX,
      value: 'true:false',
    },
  },
  // {
  //   dataField: 'templateSeverity',
  //   text: 'Severity',
  //   headerStyle: { width: '90px' },
  //   colName: TemplateColNames.TEMPLATE_SEVERITY,
  //   sort: true,
  //   editable: (col, row: TableItem) => !!row.templateItem,
  //   formatter: (col: number, row: TableItem) => {
  //     return TemplatePriority(col, row);
  //   },
  //   validator: (newValue, row, column) => {
  //     return PriorityValidator(newValue);
  //   },
  // },
  {
    dataField: 'status',
    text: 'Status',
    headerStyle: { width: '90px' },
    colName: TemplateColNames.TEMPLATE_STATUS,
    isDummyField: true,
    editable: false,
    sort: true,
    sortValue: (cell: string, row: TableItem) => {
      return TemplateStatusSortValue(row);
    },
    formatter: (cell: string, row: TableItem) => {
      return TemplateStatus(row);
    },
  },
  {
    dataField: 'workflowUpdatedAt',
    text: 'Updated',
    headerStyle: { width: '100px' },

    colName: TemplateColNames.LAST_UPDATED,
    editable: false,
    sort: true,
    sortValue: (cell: string, row: TableItem) => {
      if (!cell) return 0;
      return new Date(cell);
    },
    formatter: WorkflowLastUpdated,
  },
  {
    dataField: 'executions',
    text: 'Past Exec',
    headerStyle: { width: '100px' },
    colName: TemplateColNames.PAST_EXECUTIONS,
    editable: false,
    formatter: getPastExecutionsFormatter,
  },
  {
    dataField: 'temp_opt',
    text: 'Temp Opt',
    isDummyField: true,
    headerStyle: { width: '90px' },
    colName: TemplateColNames.TEMPLATE_OPT,
    editable: false,
    formatter: TemplateOptions,
  },
  {
    dataField: 'wf_opt',
    text: 'Workflow Opt',
    isDummyField: true,
    headerStyle: { width: '90px' },
    colName: TemplateColNames.WORKFLOW_OPT,
    editable: false,
    formatter: WorkflowOptions,
  },

  {
    dataField: 'cloudTypes',
    text: 'Cloud Types',
    headerStyle: { width: '90px' },
    colName: TemplateColNames.CLOUD_TYPES,
    editable: false,
    sort: true,
  },
  {
    dataField: 'linkedTemplates',
    text: 'Fix',
    colName: TemplateColNames.LINKED_TEMPLATES,
    sort: true,
    editorRenderer: (
      editorProps,
      value,
      row,
      column,
      rowIndex,
      columnIndex,
    ) => {
      let options = row.templatesToLink;
      return (
        <InputSelect
          clear={true}
          value={value[0]}
          options={row.templatesToLink}
          name={'fix-select'}
          onChange={editorProps.onUpdate}
        />
      );
    },
    formatter: LinkedTemplatesDisplay,
  },
  {
    dataField: 'templateTestStatus.testedOn',
    text: 'Tested On',
    headerStyle: { width: '90px' },
    colName: TemplateColNames.TESTED_ON,
    editable: false,
    sort: true,
    formatter: TestedOnDisplay,
  },
  {
    dataField: 'templateTestStatus.status',
    text: 'Test Status',
    headerStyle: { width: '90px' },
    colName: TemplateColNames.TEST_STATUS,
    editable: false,
    sort: true,
    formatter: TestStatusDisplay,
  },
  {
    dataField: 'testSettings',
    text: 'Test Settings',
    headerStyle: { width: '90px' },
    colName: TemplateColNames.TEST_SETTING,
    editable: false,
    sort: true,
    formatter: TemplateTestSettingsDisplay,
  },
  {
    dataField: 'executions',
    text: 'Last Created On',
    headerStyle: { width: '20%' },
    colName: TemplateColNames.CF_LAST_CREATED,
    editable: false,
    sort: true,
    sortValue: (cell: Execution[], row: TableItem) => {
      if (!cell || !cell.length) return 0;
      return cell[0].endDate;
    },
    formatter: StackWfsCreatedStatusDisplay,
  },
  {
    dataField: 'destroyStackWfExecutions',
    text: 'Last Destroyed On',
    headerStyle: { width: '20%' },
    colName: TemplateColNames.CF_LAST_DESTROYED,
    editable: false,
    sort: true,
    sortValue: (cell: Execution[], row: TableItem) => {
      if (!cell || !cell.length) return 0;
      return cell[0].endDate;
    },
    formatter: StackWfsDestroyedStatusDisplay,
  },
];

export const getCols = (fields: string[]): ICustomTableCol[] => {
  if (!fields || fields.length === 0) return cols;

  let resultCols: ICustomTableCol[] = [];
  fields.push(TemplateColNames.ID);
  for (let f of fields) {
    resultCols.push(cols.find((x) => x.colName === f));
  }

  return resultCols.filter((x) => !!x);
};
