import * as React from 'react';
import classnames from 'classnames';
import { StyledReactSelect } from '../../../../lib/editor/StyledEntities';
import api from '../../../../api';
import { withExternalData } from '../../../common/externalDependency';
import { CustomChart } from '../../../../lib/charts/customChart';
import CustomTable from '../../../common/table';
import {
  getUtilisationResourceSummaryColumns,
  TIME_OPTIONS,
  isUnderFilteredTime,
  filterAndGroupDataByDate,
} from './resourceUtilisationUtil';
import { fetchFromUrl } from '../../../../lib/store/executions/action';
import { unitMap } from '../formatter/UtilisationPageFormatters';

export interface ResourceUtilisationProps {
  data: any;
}

export interface ResourceUtilisationState {
  selectedMetric: { label: string; value: string };
  duration: { label: string; value: string };
}

export const getData = (executionData, selectedMetric, duration) => {
  let xCoordinate: string[] = [];
  let value: { [key: string]: number[] } = {};
  if (executionData.monitoringData) {
    let data = executionData.monitoringData;
    data = data.forEach((datum, index) => {
      if (
        datum.addOns &&
        datum.addOns.MetricData &&
        datum.addOns.MetricData.data
      ) {
        let metricData = datum.addOns.MetricData.data;
        metricData = metricData.find(
          (metDat) => metDat.Label === selectedMetric.value,
        );
        if (metricData) {
          if (
            metricData.Timestamps.length > 0 &&
            xCoordinate &&
            xCoordinate.length <= metricData.Timestamps.length
          ) {
            let extractData = filterAndGroupDataByDate(
              metricData,
              duration.value,
            );
            if (xCoordinate.length <= extractData.xCoordinate.length) {
              xCoordinate = extractData.xCoordinate.reverse();
            }
            value[
              `${executionData.resource}-${index}`
            ] = extractData.value.reverse();
          }
        }
      }
    });
  }

  if (duration.value === '1d') {
    xCoordinate = xCoordinate.map((x) => `${x}:00`);
  }
  return {
    label: xCoordinate,
    value,
  };
};

export class ResourceUtilisationInner extends React.Component<
  ResourceUtilisationProps,
  ResourceUtilisationState
> {
  state = {
    selectedMetric: { label: '', value: '' },
    duration: { value: '14d', label: 'Last 14 days' },
  };

  componentDidMount() {
    let metric = Object.keys(this.props.data.metricUtilisationMap)[0];
    this.setState({
      selectedMetric: { label: metric, value: metric },
    });
  }

  handleChange = (option) => {
    this.setState({
      duration: option,
    });
  };
  selectMetric = (met) => {
    this.setState({
      selectedMetric: met,
    });
  };
  getFormattedData = () => {
    let { value } = getData(
      this.props.data,
      this.state.selectedMetric,
      this.state.duration,
    );
    let data = [];
    Object.keys(value).forEach((datum) => {
      if (value[datum] && value[datum].length) {
        const label = datum;
        const sum = value[datum].reduce((a, b) => a + b, 0);
        let avg = sum / value[datum].length;
        data.push({
          label,
          overAllUtilisation: isNaN(avg)
            ? 0
            : `${avg.toFixed(3)} ${
                unitMap[this.props.data.Unit]
                  ? unitMap[this.props.data.Unit]
                  : ''
              }`,
        });
      }
    });
    return data;
  };

  render() {
    const { metricUtilisationMap, Unit } = this.props.data;
    let data = getData(
      this.props.data,
      this.state.selectedMetric,
      this.state.duration,
    );
    let tableData = this.getFormattedData();
    let metricOption = Object.keys(metricUtilisationMap).map((met) => {
      return { label: met, value: met };
    });
    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <StyledReactSelect
              width={'200px'}
              data-test={'metric-name'}
              name={'metricName'}
              onChange={this.selectMetric}
              value={this.state.selectedMetric}
              options={metricOption}
            />
          </div>
          <div className="font-weight-bold">Resource Utilisation</div>
          <div>
            <StyledReactSelect
              width={'200px'}
              data-test="duration"
              name={'StartTime'}
              onChange={this.handleChange}
              value={this.state.duration}
              options={TIME_OPTIONS}
            />
          </div>
        </div>
        <div className="mt-2">
          <CustomChart
            data={data}
            isReady={true}
            height={70}
            yUnit={Unit}
            xUnit={'Time'}
          />
        </div>
        <div className="mt-1">
          {tableData && tableData.length > 0 && (
            <CustomTable
              options={null}
              emptyText={'No Data are available yet'}
              data={tableData}
              columns={getUtilisationResourceSummaryColumns()}
            />
          )}
        </div>
      </>
    );
  }
}

export const checkForAzureMonitoring = (history) => {
  if (history.find((n) => n.nodeType === 'azure-monitoring')) {
    let azureMonitoringIndex = history.findIndex(
      (n) => n.nodeType === 'azure-monitoring',
    );
    return azureMonitoringIndex !== -1 && history[azureMonitoringIndex + 1];
  }
};
const fetchExecutionStepData = async (props) => {
  const { data } = props as ResourceUtilisationProps;
  let { history, status = '' } = await api.getExecutionSteps(
    props.data.exe.exes[0].execution,
  );
  data.history = history;
  let monitoring = history.find((n) => n.nodeType === 'monitoring');
  if (!monitoring) {
    monitoring = checkForAzureMonitoring(history);
  }
  if (monitoring && monitoring.output && monitoring.output.data) {
    let output = monitoring && monitoring.output && monitoring.output.data;
    if (monitoring.output.partial && monitoring.output.url) {
      output = (await fetchFromUrl(monitoring.output.url)).collection;
      if (!output) return data;
      data.monitoringData = output;
    } else {
      data.monitoringData = output;
    }
  }
  data.status = status;
  return data;
};

export const ResourceUtilisation = withExternalData(
  ResourceUtilisationInner,
  fetchExecutionStepData,
);
