import * as React from 'react';
import { Button, CardText, Col, Row } from 'reactstrap';
import classnames from 'classnames';

interface IProps {
  title: string;
  onCancel: () => void | boolean;
  onSubmit: (data?: any) => void;
  onPublishAndSubmit: (data?: any) => void;
  helpText?: React.ReactNode;
  text?: {
    secondrySave?: string;
    save?: string;
    cancel?: string;
  };
}

export class ModalWrapper extends React.Component<IProps, {}> {
  render(): React.ReactNode {
    const cancelText = (this.props.text && this.props.text.cancel) || 'Cancel';
    const saveText = (this.props.text && this.props.text.save) || 'Save';
    const secondrySave =
      (this.props.text && this.props.text.save) || 'Save and publish to stage';
    return (
      <Row noGutters={true}>
        <Col md={12} className={'p-4'}>
          {/*header*/}
          <Row>
            <Col>
              <h4>{this.props.title}</h4>
            </Col>
          </Row>

          {/*body*/}
          <Row className={classnames('border-top', 'border-bottom')}>
            <Col className={classnames('node-ui-modal-body')}>
              {this.props.children}
            </Col>
          </Row>

          {/*footer*/}
          <Row
            className={classnames('d-flex', 'justify-content-start', 'pt-4')}
          >
            <Col md={4}>
              <Button
                color="primary"
                block={true}
                onClick={this.props.onSubmit}
              >
                {saveText}
              </Button>
            </Col>
            {secondrySave && (
              <Col md={4}>
                <Button
                  color="primary"
                  block={true}
                  onClick={this.props.onPublishAndSubmit}
                >
                  {secondrySave}
                </Button>
              </Col>
            )}
            <Col md={4}>
              <Button
                color="primary"
                outline={true}
                block={true}
                onClick={this.props.onCancel}
                disabled={!this.props.onCancel}
              >
                {cancelText}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
