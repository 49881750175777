import { Workflow } from '../../../lib/store/workflows/types';
import { ExecutionsMapType } from '../../../lib/store/executions/types';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { parseExecutionStatus } from './ExecutionStatus';

interface IProps {
  tabName: TAB_NAME;
  workflows: Workflow[];
  executionMap: ExecutionsMapType;
  selectedFilter: string;
}

export const getFailingWorkflows = (props) => {
  let { workflows, executionMap, tabName } = props;

  if (tabName === TAB_NAME.INSIGHTS) {
    return workflows.filter((w) => {
      if (!executionMap[w.workflow]) return false;
      let status =
        executionMap[w.workflow].exes &&
        executionMap[w.workflow].exes.length > 0 &&
        executionMap[w.workflow].exes[0].status;
      if (status === 'Error') return true;
      let message =
        executionMap[w.workflow].output &&
        executionMap[w.workflow].output.message;
      let { isSuccess, isFailure } = parseExecutionStatus(message);
      return !isSuccess && isFailure;
    });
  }

  return workflows.filter((w) => {
    return (
      executionMap[w.workflow] &&
      executionMap[w.workflow].exes &&
      executionMap[w.workflow].exes.length > 0 &&
      executionMap[w.workflow].exes[0].status === 'Error'
    );
  });
};

export const getSuccessWorkflows = (props) => {
  let { workflows, executionMap, tabName } = props;

  if (tabName === TAB_NAME.INSIGHTS) {
    return workflows.filter((w) => {
      if (!executionMap[w.workflow]) return false;
      let message =
        executionMap[w.workflow].output &&
        executionMap[w.workflow].output.message;
      let { isSuccess } = parseExecutionStatus(message);
      return isSuccess;
    });
  }

  return workflows.filter((w) => {
    return (
      executionMap[w.workflow] &&
      executionMap[w.workflow].exes &&
      executionMap[w.workflow].exes.length > 0 &&
      executionMap[w.workflow].exes[0].status === 'Success'
    );
  });
};
export function FilterWorkflows(props: IProps) {
  let { workflows, selectedFilter } = props;
  switch (selectedFilter) {
    case 'all':
      return workflows;
    case 'active':
      return workflows.filter((w) => !!w.properties.active);
    case 'inactive':
      return workflows.filter((w) => !w.properties.active);
    case 'failing':
      return getFailingWorkflows(props);

    case 'succeeded':
      return getSuccessWorkflows(props);

    default:
      return workflows;
  }
}
