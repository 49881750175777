import styled, { css } from 'styled-components';
import { Col, DropdownItem, Nav, Row, Table } from 'reactstrap';

export const ReportWrapper = styled.div`
  height: 100vh;
  padding: 2%;
  background: #fff;
  color: black;
  line-height: 1.5;
`;

export const StyledHeader = styled(Row)`
  padding: 3% 3%;
  text-align: justify;
  vertical-align: middle;
  font-size: large;
`;

export const LogoWrapper = styled(Col)`
  text-align: left;
`;

export const DateWrapper = styled(Col)`
  vertical-align: middle;
  font-size: 22px;
  color: rgba(1, 22, 57, 1);
  text-align: right;
  letter-spacing: 0.33px;
  line-height: 30px;
`;

export const StyledBody = styled(Row)`
  padding: 0.1% 3%;
`;

export const StyledNav = styled(Nav)`
  padding: 0.1% 3%;
`;

export const StyledTabContent = styled('div')`
  padding: 3% 3%;
`;

export const WorkflowTitle = styled(Col)`
  font-size: 32px;
  color: rgba(10, 33, 64, 1);
  text-align: left;
  letter-spacing: 0.48px;
  line-height: 35px;
  margin-bottom: 20px;
`;

export const InfoHeader = styled(Col)`
  opacity: 0.7;
  font-size: 16px;
  color: rgba(10, 33, 64, 1);
  text-align: left;
  letter-spacing: 0.48px;
  line-height: 18px;
`;
export const InfoData = styled(Col)`
  font-family: 'ProximaNova-Semibold', Helvetica, Arial, serif;
  font-size: 18px;
  color: rgba(10, 33, 64, 1);
  text-align: left;
  letter-spacing: 0.54px;
  line-height: 18px;
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const DataTable = styled(Table)``;

export const StyledCell = styled.td`
  min-width: 200px;
  text-align: center;
`;

export const StyledHead = styled.th`
  min-width: 200px;
  text-align: center;
`;

export const GraphWrapper = styled(Row)`
  min-width: 75vw;
`;

export const TableWrapper = styled(Row)`
  //overflow: scroll;
`;

export const StyledDropdownItem = styled(DropdownItem)`
  font-size: medium;
`;
