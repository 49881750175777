import { TableItem, TemplateColNames } from '../cols';
import * as React from 'react';
import { loader } from './loader';

const DOMAIN = 'usecase-totalcloud.webflow.io';

export const TemplateName = (name: string, item: TableItem) => {
  if (item.updating === TemplateColNames.TEMPLATE_NAME) return loader();
  if (item.slug && !item.workflowItem)
    return (
      <a
        target={'_blank'}
        href={`https://${DOMAIN}/use-cases/${item.slug}`}
        className={'text-truncate d-flex'}
        title={name}
      >
        {name}
      </a>
    );
  return (
    <span className={'text-truncate d-flex'} title={name}>
      {name}
    </span>
  );
};

export const TemplateNameValidator = (name: string) => {
  if (!name) {
    return {
      valid: false,
      message: 'Invalid Name',
    };
  }
  return true;
};
