import styled, { keyframes } from 'styled-components';
import colors from '../../design/colors';
import { EXECUTION_STATUS } from '../../config/constants';
import InputSelect from '../../lib/formInputs/select-option.component';
import { Col } from 'reactstrap';
import CustomDropDown from '../../lib/formInputs/drop-down.component';

// font-family: 'proxima-nova', sans-serif;
export const StyledInput = styled.input<{
  fontSize?: string;
  borderColor?: string;
}>`
  background: transparent;
  padding-left: 0.5em;
  box-sizing: border-box;
  border-radius: 0 0.25em 0.25em 0;
  font-size: ${(props) => props.fontSize || '1.2em'};
  height: ${(props) => props.height || '3em'};
  width: 18em;
  border: solid 0.08em ${(props) => props.borderColor || '#cad1d7'};
  color: #5e72e4;
  border-left: 0;
  outline: none;

  ::placeholder {
    color: ${colors.border.normal};
  }
`;
export const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;
export const StatusPlaceholder = styled.div`
  width: 0.5em;
  height: 0.5em;
  border: none;
  border-radius: 50%;
  margin-left: 0.5em;
  background: ${colors.text.primary};
  animation: ${fadeIn} 1s infinite;
`;
export const StatusIndicator = styled(StatusPlaceholder)<{ type: string }>`
  animation: none;
  background: ${(props: any) =>
    props.type === EXECUTION_STATUS.SUCCESS
      ? colors.palette.success
      : props.type === EXECUTION_STATUS.FAILED
      ? colors.palette.error
      : colors.text.secondary};
`;
// font-family: 'proxima-nova', sans-serif;
export const OptionsWrapper = styled.ul`
  padding: 0.5em 0;
  background: #fff;
  width: 8em;
  font-size: 0.95vw;
`;
export const OptionItem = styled.li`
  list-style: none;
  color: ${colors.text.secondary};
  cursor: pointer;
  padding: 0.5em;
  &:hover {
    background: #ccc;
  }
`;
export const WorkflowTitle = styled.span`
  font-size: 1.1em !important;
`;
export const WorkflowExecutionStatusIcon = styled.i`
  color: ${(props) => props.color || colors.palette.green};
  font-size: 1.2em !important;
`;
// font-family: 'proxima-nova', sans-serif;
export const WorkflowExecutionStatus = styled.span`
  font-size: 1.1em;
  color: ${(props) => props.color || colors.text.secPrimary};
`;
export const InputOptions = styled(InputSelect)`
  select {
    border-color: ${colors.text.secPrimary} !important;
    color: ${colors.text.secPrimary};
    font-weight: 600;
    background-image: linear-gradient(
        45deg,
        transparent 50%,
        ${colors.text.secPrimary} 50%
      ),
      linear-gradient(135deg, ${colors.text.secPrimary} 50%, transparent 50%) !important;
    background: transparent;
    padding: 0.49em;
    font-size: 0.88vw !important;
    border: solid 0.11em ${colors.text.secPrimary};
    cursor: pointer;
    height: 2.5em;
    min-width: 8em;
    background-position: calc(100% - 1em) calc(1em),
      calc(100% - 0.5em) calc(1em), calc(100% - 2.5em) 0.5em !important;
    border-radius: 0.25em !important;
  }

  .form-control:focus {
    color: ${colors.text.secPrimary};
  }
`;
export const StyledOptionCol = styled(Col)`
  font-size: 1.2em;

  i {
    // -webkit-text-stroke: 0.08em #f5f6fa;
    color: #1d8cf8;
    cursor: pointer;
  }
`;

export const StyledCustomDropDown = styled(CustomDropDown)`
  .custom-dropdown-title {
    color: #1d8cf8 !important;
    text-decoration: none !important;
  }
  .custom-dropdown-title:hover {
    color: #1d8cf8 !important;
  }
`;

export const SwitchStatus = styled.div`
  color: #212529;
`;
