import { FormikProps } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import {
  CronSchedule,
  ParsedCronSchedule,
  RateSchedule,
  ScheduleType,
} from 'workflow-model/dist';
import moment from 'moment';
import { MultiScheduleSchema } from './schema';
import InputText from '../../../lib/formInputs/input-text.component';
import { TimePickerWrapper } from '../../../lib/formInputs/TimePickerWrapper';
import { WeekPickerWrapper } from '../../../lib/formInputs/WeekPickerWrapper';
import InputSelect from '../../../lib/formInputs/select-option.component';
import { defaultSchedule } from '../../../lib/editor/components/ui/nodeUI/trigger/TriggerForm';
import CustomRadioButtons, {
  ORIENTATION,
} from '../../../lib/formInputs/radio-button.component';
import useRestrictions from '../../../lib/user-restriction';
import { Schedule } from '../../../lib/store/schedules/types';
import _ from 'lodash';
import SwitchContainer from '../../../lib/switch/SwitchContainer';
import { reportingFormSchema } from '../../workflows/common/reportingFormSchema';

export const recurrentOptions = [
  { label: 'Every Hour', value: 'rate(1 hour)' },
  { label: 'Every day', value: 'rate(1 day)' },
  { label: 'Every week', value: 'rate(7 days)' },
];

export const ParsedCronWrapper = (props: {
  parent: FormikProps<MultiScheduleSchema>;
  index: number;
  element: ParsedCronSchedule;
}) => {
  return (
    <>
      <Col md={12}>
        <label>
          Enter Cron
          <i id={'cron-tooltip'} className={'fa fa-info-circle mx-2'}>
            {' '}
          </i>
        </label>
        <InputText
          name={`schedule.value[${props.index}].value`}
          value={props.element.value}
          onChange={props.parent.handleChange}
          onBlur={props.parent.handleBlur}
          data-test={`parsed-cron-${props.index}`}
        />
        <div>
          <small data-test="react-select-error" className="text-red">
            {props.parent.errors &&
              props.parent.errors.schedule &&
              props.parent.errors.schedule.value &&
              props.parent.errors.schedule.value[props.index] &&
              props.parent.errors.schedule.value[props.index].value}
          </small>
        </div>
        <UncontrolledTooltip target={'cron-tooltip'} delay={0}>
          Cron format - minute hour day-of-month month day-of-week year. Eg: 5
          14 ? * * 2020. You could also add multiple Cron expressions separated
          by ":"
        </UncontrolledTooltip>
      </Col>
    </>
  );
};

export const CronWrapper = (props: {
  parent: FormikProps<MultiScheduleSchema>;
  index: number;
  element: CronSchedule;
}) => {
  React.useEffect(() => {
    if (!props.element.date) {
      props.parent.setFieldValue(
        `schedule.value[${props.index}].date`,
        '09:00',
      );
    }
  });
  return (
    <>
      <Col>
        <label> Select Time (HH:mm)</label>
        <TimePickerWrapper
          name={`schedule.value[${props.index}].date`}
          allowClear={false}
          onChange={props.parent.handleChange}
          defaultValue={moment('09:00', 'HH:mm')}
          value={props.element.date}
          size="large"
          className={'w-100'}
          format={'HH:mm'}
          data-test={`time-picker-${props.index}`}
          onBlur={props.parent.handleBlur}
          secondStep={60}
          error={
            props.parent.errors &&
            props.parent.errors.schedule &&
            props.parent.errors.schedule.value &&
            props.parent.errors.schedule.value[props.index] &&
            props.parent.errors.schedule.value[props.index]['date']
          }
        />
      </Col>
      <Col>
        <label> Select Days</label>
        <WeekPickerWrapper
          name={`schedule.value[${props.index}].days`}
          value={props.element.days}
          onChange={props.parent.handleChange}
          data-test={`days-picker-${props.index}`}
          onBlur={props.parent.handleBlur}
          error={
            props.parent.errors &&
            props.parent.errors.schedule &&
            props.parent.errors.schedule.value &&
            props.parent.errors.schedule.value[props.index] &&
            props.parent.errors.schedule.value[props.index]['days']
          }
        />
      </Col>
    </>
  );
};

export const RateWrapper = (props: {
  parent: FormikProps<MultiScheduleSchema>;
  index: number;
  element: RateSchedule;
}) => {
  const { value } = useRestrictions('triggerOptions');

  const recurrent: string[] = value?.recurrent || [];

  const userRecurrentOptions = recurrentOptions.filter((option) =>
    recurrent.includes(option.value),
  );

  const { touched, errors } = props.parent;
  const isError =
    touched.schedule?.value[props.index] &&
    touched.schedule?.value[props.index].value &&
    errors.schedule?.value[props.index] &&
    errors.schedule?.value[props.index].value;

  return (
    <Col>
      <label> Select Interval</label>
      <InputSelect
        error={isError}
        name={`schedule.value[${props.index}].value`}
        value={props.element.value}
        onChange={props.parent.handleChange}
        onBlur={(e) => {
          props.parent.handleBlur({
            target: { name: `schedule.value[${props.index}].value` },
          });
        }}
        options={userRecurrentOptions}
        data-test={`rate-${props.index}`}
      />
    </Col>
  );
};

export const scheduleTypeOptions = [
  { label: 'Recurrent', value: ScheduleType.RATE },
  { label: 'Schedule', value: ScheduleType.CRON },
  { label: 'Cron', value: ScheduleType.PARSED_CRON },
];

export function MultiScheduleForm<T extends any>(props: FormikProps<T>) {
  const { value } = useRestrictions('triggerOptions');

  const userScheduleTypeOptions = useMemo(
    () => [
      {
        label: 'Recurrent',
        value: ScheduleType.RATE,
      },
      {
        label: 'Schedule',
        value: ScheduleType.CRON,
        disabled: !value?.schedule,
      },
      {
        label: 'Cron',
        value: ScheduleType.PARSED_CRON,
        disabled: !value?.cron,
      },
    ],
    [value],
  );

  const handleTypeChange = (event, index) => {
    let { value } = event.target;
    if (value === ScheduleType.RATE) {
      props.handleChange({
        target: {
          value: defaultSchedule.value,
          name: `schedule.value[${index}].value`,
        },
      });
    }
    if (value === ScheduleType.CRON) {
      props.handleChange({
        target: { name: `schedule.value[${index}].date`, value: '09:00' },
      });
    }
    if (value === ScheduleType.PARSED_CRON) {
      props.handleChange({
        target: { value: '', name: `schedule.value[${index}].value` },
      });
    }
    props.handleChange(event);
  };

  // Handle default value here
  // If default value is select and it doesn't match the configs set it to empty

  useEffect(() => {
    const schedules: (
      | ParsedCronSchedule
      | RateSchedule
      | CronSchedule
    )[] = Object.assign(props.values.schedule.value);
    const newSchedules = [];
    schedules.map((sch, index) => {
      switch (sch.type) {
        case ScheduleType.RATE:
          const recurrentOptions = value?.recurrent || [];
          if (!recurrentOptions.includes(sch.value)) {
            sch.value = '';
          }
          newSchedules[index] = sch;
          break;
        case ScheduleType.PARSED_CRON:
          const isdisabled = userScheduleTypeOptions.find(
            (x) => x.value === ScheduleType.PARSED_CRON,
          ).disabled;
          if (isdisabled) sch.value = '';
          break;
        case ScheduleType.CRON:
          const isDisabled = userScheduleTypeOptions.find(
            (x) => x.value === ScheduleType.CRON,
          ).disabled;
          if (isDisabled) {
            sch.value = '';
            sch.days = [];
            sch.date = '';
          }
      }

      newSchedules[index] = sch;
    });

    if (!_.isEqual(schedules, newSchedules)) {
      props.setFieldValue('schedule.value', newSchedules);
    }
  }, [props.values, userScheduleTypeOptions, value]);

  return (
    <>
      {props.values.schedule.value.map((e, i) => {
        return (
          <>
            <Row
              key={`schedule${i}`}
              className={'align-items-baseline mb-2 py-1'}
            >
              <Col md={12}>
                <label>How often should this run?</label>
                <CustomRadioButtons
                  options={userScheduleTypeOptions}
                  name={`schedule.value[${i}].type`}
                  value={e.type}
                  onBlur={props.handleBlur}
                  onChange={(event) => handleTypeChange(event, i)}
                  orientation={ORIENTATION.horizontal}
                />
              </Col>

              <Col md={8}>
                <Row>
                  {e.type === ScheduleType.PARSED_CRON && (
                    <ParsedCronWrapper index={i} element={e} parent={props} />
                  )}

                  {e.type === ScheduleType.CRON && (
                    <CronWrapper index={i} element={e} parent={props} />
                  )}
                  {e.type === ScheduleType.RATE && (
                    <RateWrapper index={i} element={e} parent={props} />
                  )}
                </Row>
              </Col>
            </Row>
          </>
        );
      })}
    </>
  );
}
