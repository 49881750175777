import merge from 'deepmerge';
import { flatten } from 'flat';
import { intersection, isEqual, union } from 'lodash';
import { wordify } from '../../../../util/formatting';
import eventPatternOptions from './ui.json';

function isSubset(main, sub) {
  return isEqual(sub, intersection(main, sub));
}

function listOptions() {
  const flattened = flatten(eventPatternOptions, { safe: true });
  const withDefaultRemoved = {};
  for (const key of Object.keys(flattened)) {
    withDefaultRemoved[key.replace('.default', '')] = flattened[key];
  }
  return withDefaultRemoved;
}

export function getAvailableEventPatternOptions(selected = []) {
  const options = listOptions();
  const levels = [];
  for (const key of Object.keys(options)) {
    const parts = key.split('.');
    for (let i = 0; i < parts.length && i <= selected.length; i++) {
      if (!isSubset(selected, parts.slice(0, i))) continue;
      if (!levels[i]) levels[i] = [];
      const level = levels[i];
      const part = parts[i];
      if (!level.includes(part)) level.push(part);
    }
  }
  const patterns = options[selected.join('.')];
  return { levels, selected, patterns };
}

export function convertListToOptions(options: string[]) {
  return options.map((u) => ({ label: wordify(u), value: u }));
}

export function convertPatternListToOptions(options: any[]) {
  return options.map((u) => ({
    label: u.displayOptionName || 'All Events',
    value: u,
  }));
}

const combineMerge = (target, source, options) => {
  return union(target, source);
};

export function mergePatterns(a, b) {
  return merge(a, b, { arrayMerge: combineMerge });
}

export function getSelectedFromId(id: string) {
  const options = listOptions();
  // console.log("trying to find", id);
  const selectedOption = Object.keys(options).find((o) => {
    return options[o].find((e) => e.id === id);
  });
  if (!selectedOption) return [];
  return selectedOption.split('.');
}
