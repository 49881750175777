import * as React from 'react';
import { AppContainer } from './style';
import { withTcApi } from '../api/tcApiHelper';
import ShareWorkflowExternally from '../components/workflows/common/ShareWorkflowExternally';

class ShareWorkflow extends React.Component<
  { location: any; match: any },
  { token: string }
> {
  state = {
    token: this.props.match.params.token,
  };

  render(): React.ReactNode {
    return (
      <AppContainer fluid={true}>
        <ShareWorkflowExternally token={this.state.token} />
      </AppContainer>
    );
  }
}

export default withTcApi(ShareWorkflow);
