import {
  AddGroupEnded,
  AddGroupStarted,
  AddUserEnded,
  AddUserStarted,
  AssignUserToGroupEnded,
  AssignUserToGroupStarted,
  DeleteGroupEnded,
  DeleteGroupStarted,
  ToggleUserEnded,
  ToggleUserStarted,
  FetchGroupsEnded,
  FetchGroupsStarted,
  FetchTenantEnded,
  FetchTenantStarted,
  FetchUserEnded,
  FetchUserStarted,
  OrgGroup,
  OrgUser,
  UpdateGroupEnded,
  UpdateGroupStarted,
} from './types';
import { Dispatch } from 'redux';
import { notify } from '../../../components/common/toaster';
import api from '../../../api';

export function fetchUsers(props: { dispatch: Dispatch }) {
  props.dispatch({
    type: FetchUserStarted,
  });
  api
    .listOrgUsers()
    .then((users) => {
      props.dispatch({ type: FetchUserEnded, users: users });
    })
    .catch((error) => {
      props.dispatch({ type: FetchUserEnded, users: null });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}

export function fetchGroups(props: { dispatch: Dispatch }) {
  props.dispatch({
    type: FetchGroupsStarted,
  });
  api
    .listOrgGroups()
    .then((groups) => {
      props.dispatch({ type: FetchGroupsEnded, groups: groups });
    })
    .catch((error) => {
      props.dispatch({ type: FetchGroupsEnded, groups: null });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}

export function addUser(props: {
  dispatch: Dispatch;
  user: Partial<OrgUser>;
  groups?: OrgGroup[];
}) {
  props.dispatch({ type: AddUserStarted });
  const groupIds = props.groups.map((u) => u.id);
  const profile = {
    email: props.user.email,
    firstName: props.user.firstName,
    lastName: props.user.lastName,
  };
  api
    .addOrgUser(profile, groupIds)
    .then(() => props.dispatch({ type: AddUserEnded }))
    .then(() => fetchUsers(props))
    .catch((error) => {
      props.dispatch({ type: AddUserEnded });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}

export function addGroup(props: {
  dispatch: Dispatch;
  name: string;
  accounts: string[];
  users?: OrgUser[];
}) {
  props.dispatch({ type: AddGroupStarted });
  const userIds = props.users.map((u) => u.id);
  api
    .createOrgGroup(
      props.name,
      { accounts: props.accounts },
      { userIds: userIds },
    )
    .then(() => props.dispatch({ type: AddGroupEnded }))
    .then(() => fetchGroups(props))
    .catch((error) => {
      props.dispatch({ type: AddGroupEnded });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}

export function toggleUserStatus(props: { dispatch: Dispatch; user: OrgUser }) {
  props.dispatch({ type: ToggleUserStarted });
  api
    .toggleUserStatus(props.user.id)
    .then(() => props.dispatch({ type: ToggleUserEnded }))
    .then(() => fetchUsers(props))
    .catch((error) => {
      props.dispatch({ type: ToggleUserEnded });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}

export function deleteGroup(props: { dispatch: Dispatch; group: OrgGroup }) {
  props.dispatch({ type: DeleteGroupStarted });
  api
    .deleteOrgGroup(props.group.id)
    .then(() => props.dispatch({ type: DeleteGroupEnded, group: props.group }))
    .then(() => fetchGroups(props))
    .then(() => fetchUsers(props))
    .catch((error) => {
      props.dispatch({ type: DeleteGroupEnded });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}

export function reAssignGroupsToUser(props: {
  dispatch: Dispatch;
  user: OrgUser;
  groups: OrgGroup[];
}) {
  props.dispatch({ type: AssignUserToGroupStarted });
  const groupIds = props.groups.map((u) => u.id);
  api
    .reAssignUsers(props.user.id, groupIds)
    .then(() => fetchUsers(props))
    .then(() => fetchGroups(props))
    .then(() => props.dispatch({ type: AssignUserToGroupEnded }))
    .catch((error) => {
      props.dispatch({ type: AssignUserToGroupEnded });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}

export function updateGroup(props: {
  dispatch: Dispatch;
  name: string;
  groupId: string;
  accounts: string[];
}) {
  props.dispatch({ type: UpdateGroupStarted });
  api
    .updateGroup(props.name, props.groupId, { accounts: props.accounts })
    .then(() => fetchGroups(props))
    .then(() => props.dispatch({ type: UpdateGroupEnded }))
    .catch((error) => {
      props.dispatch({ type: UpdateGroupEnded });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}

export function fetchTenantDetails(props: { dispatch: Dispatch }) {
  props.dispatch({ type: FetchTenantStarted });
  api
    .getUserDetails()
    .then((userDetails) => {
      const tenant = userDetails.orgInfo.isOrgUser
        ? {
            ...userDetails.orgInfo.request.org,
            isSubjectAdmin: userDetails.orgInfo.isAdmin,
          }
        : null;
      props.dispatch({ type: FetchTenantEnded, tenant: tenant });
    })
    .catch((error) => {
      props.dispatch({ type: FetchTenantEnded });
      console.error(error);
      notify({ type: 'error', message: error.message });
    });
}
