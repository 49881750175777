import React from 'react';
import { FormikProps } from 'formik';
import { EventEditor } from '../../schema';
import { Col, Label, Row } from 'reactstrap';
import {
  convertListToOptions,
  convertPatternListToOptions,
  getAvailableEventPatternOptions,
  mergePatterns,
} from './util';
import CodeEditor from '../../../../../../../formInputs/codeEditor.component';
import InputSelect from '../../../../../../../formInputs/select-option.component';
import MultiSelect from '../../../../../../../formInputs/multi-select.component';

export class EventExplorer extends React.Component<
  FormikProps<EventEditor> & { srcErrors: string[] }
> {
  handlerCodeEditorUpdate = (value) => {
    this.props.handleChange({ target: { value, name: 'pattern' } });
    this.props.handleSubmit();
  };
  handleEventSelection = (value) => {
    if (!Array.isArray(value)) return;
    this.props.handleChange({
      target: { value: value.map((v) => v.value), name: 'selectedEvents' },
    });
    let pattern = {};
    for (const event of value) {
      // console.log(event.value);
      if (event.value.pattern) {
        pattern = mergePatterns(pattern, event.value.pattern);
      }
    }
    this.handlerCodeEditorUpdate({
      data: JSON.stringify(pattern, null, 4),
      error: false,
    });
  };

  getChangeHandler = (index: number) => {
    return (event: any) => {
      this.props.handleChange(event);
      for (let j = index + 1; j < this.props.values.selectedDepth.length; j++) {
        this.props.handleChange({
          target: { value: '', name: `selectedDepth[${j}]` },
        });
      }
      this.props.handleChange({
        target: { value: [], name: 'selectedEvents' },
      });
    };
  };

  render() {
    const selectedDepth = this.props.values.selectedDepth.filter((x) => x);
    const { levels, patterns } = getAvailableEventPatternOptions(selectedDepth);
    const labels = [
      'Select a service',
      'Select a resource',
      'Select an Event Type',
    ];
    const selectableEvents: any[] = patterns
      ? convertPatternListToOptions(patterns)
      : [];

    return (
      <Row>
        <Col md={6}>
          {levels.map((e, i) => {
            return (
              <InputSelect
                key={i}
                label={labels[i]}
                className={'mb-2'}
                value={selectedDepth[i]}
                options={convertListToOptions(e)}
                name={`selectedDepth[${i}]`}
                onChange={this.getChangeHandler(i)}
              />
            );
          })}
          {selectableEvents && selectableEvents.length > 0 && (
            <MultiSelect
              key={'msv2' + selectedDepth.join(':')}
              label={'Select Events'}
              options={selectableEvents}
              name={'selectedEvents'}
              value={this.props.values.selectedEvents}
              onChange={this.handleEventSelection}
            />
          )}
          {this.props.srcErrors.map((err, i) => (
            <div key={`error-${i}`}>
              <Label className={'text-danger'}>{err}</Label>
            </div>
          ))}
        </Col>
        <Col md={6}>
          <CodeEditor
            label={'Customise Pattern'}
            name={'pattern'}
            value={this.props.values.pattern}
            onChange={this.handlerCodeEditorUpdate}
            error={JSON.stringify(this.props.errors.pattern)}
            showCopyControl={false}
          />
        </Col>
      </Row>
    );
  }
}
