import md5 from 'md5';
import { BaseUriExtras } from '../../../config/api';
import { OktaConfig, loadDefault } from '../../../config/okta';

async function loadOrg(email: string) {
  const domain = email.slice(email.indexOf('@') + 1);
  const tenantId = md5(domain);
  const url = `${BaseUriExtras}/tenant/${tenantId}`;
  const response = await fetch(url);
  const { response: { data } = { data: undefined } } = await response.json();
  return { tenantId, data };
}

/*
tries to load org details for an email
If successful, updates the config
 */
export async function loadOrgUser(email: string) {
  const { tenantId, data } = await loadOrg(email);
  if (data) {
    const { registration_token, client_id, okta_domain } = data;
    OktaConfig.registrationToken = registration_token;
    OktaConfig.client_id = client_id;
    OktaConfig.oauth_url = okta_domain;
    OktaConfig.tenantId = tenantId;
  } else {
    loadDefault();
  }
  return data;
}

export async function preventOrgRegistration(email: string) {
  const { tenantId, data } = await loadOrg(email);
  if (data) {
    const errorMsg =
      'Organisation Access has been enabled for your domain. Please ask your administrator for invitation.';
    throw { errorMsg: errorMsg, errorCauses: [{ errorSummary: errorMsg }] };
  }
}
