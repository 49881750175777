import api from '../../../api';
import { notify } from '../../../components/common/toaster';

import {
  FetchOverview,
  Overview,
  OverviewAction,
  OverviewDuration,
  OverviewFetchSuccess,
} from './types';

export const SendOverviewRequest = function(): OverviewAction {
  return {
    type: FetchOverview,
  };
};

export const OverviewResponseRecieved = function(
  duration: OverviewDuration,
  data: Overview | null,
): OverviewAction {
  return {
    type: OverviewFetchSuccess,
    data: data as Overview,
    duration: duration,
  };
};

export const fetchOverviewData = function(params: {
  dispatch: (e: any) => void;
  duration: OverviewDuration;
}) {
  let { dispatch, duration } = params;
  dispatch(SendOverviewRequest());
  api
    .getOverview(`timeperiod=${duration}`)
    .then((data) => {
      return dispatch(OverviewResponseRecieved(duration, data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(OverviewResponseRecieved(duration, null));
    });
};
