import React, { Component } from 'react';
import {
  Button,
  Card,
  CardGroup,
  CardImg,
  Col,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { GroupWorkflowListResult } from '../../lib/store/parking/type';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { listGroupWorkflows } from '../../lib/store/parking/actions';
import { connect } from 'react-redux';
import {
  getAggregatedSummary,
  getLatestModifiedSchedule,
} from '../schedules/format';
import { SectionLoader } from '../common/SectionLoader';
import { Overview } from '../../lib/store/overview/types';
import { fetchOverviewData } from '../../lib/store/overview/action';
import {
  RegionChildProps,
  withRegionAndCredentials,
} from '../common/withRegionAndCredentials';
import { getHistory } from '../../lib/store/parking/actions';
import { GroupHistory } from 'workflow-model';
import { getTime, shortName, modifyActionName } from '../schedules/helper';

interface OwnProps {}

interface DispatchProps {
  fetchSchedules: () => any;
  fetchOverview: () => void;
  getHistory: (group) => void;
}

interface StateProps {
  isFetchingSchedules: boolean;
  schedules: GroupWorkflowListResult[];
  overview: Overview;
  isFetchingOverView: boolean;
  isFetchingHistory: boolean;
  executionHistory: any;
}

type IProps = RegionChildProps & StateProps & DispatchProps & OwnProps;

export class DashboardInner extends Component<IProps> {
  componentDidMount(): void {
    this.props.fetchSchedules();
    this.props.fetchOverview();
  }
  componentDidUpdate(prevProps): void {
    if (prevProps.schedules !== this.props.schedules) {
      if (this.props.schedules.length > 0) {
        let lastModifiedSchedule = getLatestModifiedSchedule(
          this.props.schedules,
        );
        this.props.getHistory(lastModifiedSchedule.group);
      }
    }
  }

  render() {
    return (
      <Row>
        <Col md={12}>
          <Row className="d-flex justify-content-start dashboard-content align-items-start px-4">
            <div>
              <h3>Your Dashboard</h3>
            </div>
          </Row>
          {/* <Row className="d-flex align-items-stretch mt-5"> */}
          <CardGroup>
            <Card
              className="p-4 mx-4 d-flex dashboard-card align-items-center shadow test-schedules-card"
              data-test={'dashboard-schedule-card'}
            >
              {this.getSchedulesCard()}
            </Card>
            <Card className="p-4 mx-4 d-flex align-items-center dashboard-card shadow test-templates-card">
              <span className="text-white m-0 mt-3 pl-2 pr-2 pt-1 pb-1"> </span>
              <CardImg
                top
                src="/img/welcome-template-redirect.png"
                alt="welcome-template-redirect"
                className="w-25 mt-1 h-35"
              />
              <h5 className="d-flex align-items-center text-primary dashboard-card-title font-18">
                Templates
              </h5>
              <div className="h-100 d-flex flex-column justify-content-end">
                <div>
                  <p className="pt-2 text-center dashboard-card-text">
                    Explore pre-existing workflows that are common among users.
                    You can select and edit one to meet your custom
                    requirements.
                  </p>

                  <div className="pt-2 text-center">
                    <Link to="/templates">
                      <Button color={'primary'}>
                        <span className="px-4 test-templates-view">View</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Card>
            <Card className="p-4 mx-4 d-flex align-items-center dashboard-card shadow test-workflows-card">
              {this.getWorkflowSummaryCard()}
            </Card>
          </CardGroup>
          {/* </Row> */}
          <Row className="mt-12">
            <Col
              md={{ size: 12 }}
              className="d-flex justify-content-center align-items-center text-primary mt-5"
            >
              <Link to="/editor" target="_blank">
                <div className="d-flex align-items-center custom-blue font-20 test-workflows-button">
                  <i className="navIcon-editor mr-2 font-larger " />
                  Create Custom Workflow
                </div>
              </Link>
              {/* <a href={'#'}>
                <div className="d-flex align-items-center">
                  <span className="border border-primary border-3 px-2 py-1 mr-2">
                    <i className="fa fa-play" />
                  </span>
                  Watch Product demo
                </div>
              </a> */}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  private getWorkflowSummaryCard() {
    // if (!this.props.overview) {
    //   return <SectionLoader />;
    // }
    if (this.props.overview) {
      var { Success, Failed, Total } = this.props.overview;
    }
    return (
      <>
        <span className="text-white m-0 mt-3 pl-2 pr-2 pt-1 pb-1"> </span>
        <CardImg
          top
          src="/img/dashboard_workflows.png"
          alt="welcome-template-redirect"
          className="w-25 mt-3"
        />
        <h5 className="d-flex align-items-center text-primary mt-2 pt-1 font-18">
          <Link to="/workflows">
            <span className="dashboard-card-title">Workflows</span>
          </Link>
        </h5>
        <div className="d-flex justify-content-between align-items-start w-100 mt-4">
          <div className="text-center">
            {!this.props.overview ? (
              <i
                data-test="workflow-loader"
                className={'fa fa-2x fa-spin fa-spinner'}
              />
            ) : (
              <h4 className="custom-blue">{Total}</h4>
            )}
            <p className="dashboard-card-count-title">Total Executions</p>
          </div>
          <div className="text-center">
            {!this.props.overview ? (
              <i
                data-test="workflow-loader"
                className={'fa fa-2x fa-spin fa-spinner'}
              />
            ) : (
              <h4 className="success-green">{Success}</h4>
            )}
            <p className="dashboard-card-count-title">Successful Executions</p>
          </div>
          <div className="text-center">
            {!this.props.overview ? (
              <i
                data-test="workflow-loader"
                className={'fa fa-2x fa-spin fa-spinner'}
              />
            ) : (
              <h4 className="text-danger ">{Failed}</h4>
            )}
            <p className="dashboard-card-count-title">Failed Executions</p>
          </div>
        </div>
        <small className="text-muted mt-4">summary for last 7 days</small>
      </>
    );
  }

  getActionDetails = (scheduleName, lastAction, modifiedTime) => {
    return (
      <>
        <div className="mb-0 disboard-card-count" id="lastaction">
          <p className="text-primary">{`${scheduleName &&
            shortName(scheduleName, 12)}`}</p>
          <p className="text-primary">{`${lastAction &&
            shortName(modifyActionName(lastAction), 8)} at ${modifiedTime}`}</p>
        </div>
        <UncontrolledTooltip placement="bottom" target={'lastaction'}>
          {`${scheduleName} ${modifyActionName(lastAction)} at ${modifiedTime}`}
        </UncontrolledTooltip>
      </>
    );
  };

  private getSchedulesCard() {
    const { schedules, isFetchingSchedules, isFetchingHistory } = this.props;
    let { savings, savingPercentage } = getAggregatedSummary(schedules);
    if (!Number(savingPercentage)) {
      savingPercentage = '0';
    }
    let lastModifiedSchedule =
      schedules && getLatestModifiedSchedule(schedules);
    const scheduleName = lastModifiedSchedule && lastModifiedSchedule.name;
    const modifiedTime =
      lastModifiedSchedule && getTime(lastModifiedSchedule.lastModified);
    let history =
      lastModifiedSchedule &&
      this.props.executionHistory &&
      this.props.executionHistory[lastModifiedSchedule.group];
    const sortedHistory =
      history && history.sort((a, b) => b.startDate - a.startDate);
    const filterActionFromHistory =
      sortedHistory &&
      sortedHistory.filter((history) => history.actions.length > 0);

    const selectedActionFromHistory =
      filterActionFromHistory && filterActionFromHistory.length > 0
        ? filterActionFromHistory[0]
        : sortedHistory && sortedHistory[0];

    const lastAction = selectedActionFromHistory
      ? selectedActionFromHistory.action
      : '';
    return (
      <>
        <span className="text-white m-0 pl-2 pr-2 pt-1 pb-1 most-used-tag">
          Most Used
        </span>
        <CardImg
          top
          src="/img/dashboard-schedules.png"
          alt="welcome-template-redirect"
          className="w-25 mt-4"
        />
        <h5 className="d-flex align-items-center text-primary mt-2 font-18">
          <Link to="/schedules">
            <span className="dashboard-card-title test-schedules-button">
              Resource Scheduling
            </span>
          </Link>
        </h5>
        <div className="w-100 mt-4">
          <div className="row my-1">
            <div
              className="col-md-6 mb-0 dashboard-card-count-title"
              data-test={'dashboard-schedule-total'}
            >
              Schedules Running
            </div>
            <div className="col-md-6 text-center">
              {!schedules.length && isFetchingSchedules ? (
                <i
                  data-test="workflow-loader"
                  className={'fa fa-2x fa-spin fa-spinner'}
                />
              ) : (
                <h4
                  className="mb-0 text-primary disboard-card-count"
                  data-test={'dashboard-schedule-count'}
                >
                  {schedules.length}
                </h4>
              )}
            </div>
          </div>
          <div className="row my-1">
            <div
              className="col-md-6 mb-0 dashboard-card-count-title"
              data-test={'dashboard-schedule-saving'}
            >
              Money Saved
            </div>
            <div className="col-md-6 text-center">
              {!schedules.length && isFetchingSchedules ? (
                <i
                  data-test="workflow-loader"
                  className={'fa fa-2x fa-spin fa-spinner'}
                />
              ) : (
                <h4
                  className="mb-0 text-primary dashboard-card-count"
                  data-test={'dashboard-schedule-percentage'}
                >
                  {/* <span className="font-24 ">$</span> */}
                  {`$${savings}(${savingPercentage}%)`}
                </h4>
              )}
            </div>
          </div>
          <div className="row my-1">
            <div
              className="col-md-6 mb-0 dashboard-card-count-title"
              data-test={'dashboard-schedule-lastAction'}
            >
              Last Action
            </div>
            <div
              className="col-md-6 text-center"
              data-test={'dashboard-schedule-lastActionData'}
            >
              {(!schedules.length && isFetchingSchedules) ||
              isFetchingHistory ? (
                <i
                  data-test="workflow-loader"
                  className={'fa fa-2x fa-spin fa-spinner'}
                />
              ) : (
                <>
                  {schedules.length > 0 && lastAction ? (
                    this.getActionDetails(
                      scheduleName,
                      lastAction,
                      modifiedTime,
                    )
                  ) : (
                    <p className="text-primary">No Action</p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const WithRegion = withRegionAndCredentials(DashboardInner);

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetchingSchedules: state.parking.isFetchingList,
    schedules: state.parking.data,
    isFetchingOverView: state.overview.fetching,
    overview: state.overview.data['7d'],
    isFetchingHistory: state.parking.isFetchingHistory,
    executionHistory: state.parking.history,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchSchedules: () => listGroupWorkflows({ dispatch }),
    fetchOverview: () => fetchOverviewData({ dispatch, duration: '7d' }),
    getHistory: (group) => {
      return getHistory({ dispatch, group });
    },
  };
}

export const Dashboard = connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(WithRegion);
