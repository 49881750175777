import Chart, { ChartData, ChartOptions, ChartPoint } from 'chart.js';
import { Period } from './constants';
import moment from 'moment';

export const getTimeUnit = (
  period: Period,
  duration: { fromTime: number; toTime },
): Chart.TimeUnit => {
  const difference = Math.round((duration.toTime - duration.fromTime) / 1000);
  let unit: Chart.TimeUnit = 'day';
  if (difference > Period.YEAR * 4) {
    unit = 'year';
  } else if (difference > Period.YEAR) {
    unit = 'quarter';
  } else if (difference > Period.QUARTER && difference - Period.YEAR <= 600) {
    unit = 'month';
  } else if (difference > Period.MONTH && difference - Period.QUARTER <= 600) {
    unit = 'week';
  } else if (difference > Period.WEEK && difference - Period.MONTH <= 600) {
    unit = 'day';
  } else if (difference > Period.DAY && difference - Period.WEEK <= 600) {
    unit = 'day';
  } else if (difference > Period.HOUR && difference - Period.DAY <= 600) {
    unit = 'hour';
  } else if (difference > Period.SEC && difference - Period.HOUR <= 600) {
    unit = 'minute';
  }
  return unit;
};

export const options = (
  period: Period,
  duration: { fromTime: number; toTime: number },
): ChartOptions => {
  return {
    legend: {
      position: 'bottom',
      align: 'center',
      fullWidth: false,
      labels: {
        boxWidth: 5,
        usePointStyle: true,
      },
    },
    tooltips: {
      backgroundColor: '#FFFFFFEE',
      bodyFontColor: '#343434',
      titleFontColor: '#343434',
    },
    scales: {
      xAxes: [
        {
          bounds: 'data',
          display: true,
          type: 'time',
          time: {
            unit: getTimeUnit(period, duration),
          },
          scaleLabel: {
            display: true,
            labelString: 'Days',
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Count',
          },
        },
      ],
    },
  };
};

let randomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

export const convertData = (
  data: ChartData,
  renderTimestamp: boolean,
): ChartData => {
  const labels = renderTimestamp
    ? data.labels.map((timestamp) => moment(Number(timestamp)))
    : data.labels;
  const datasets = data.datasets.map((ds) => {
    let data: Array<number | null | undefined> | ChartPoint[] = ds.data;
    if (renderTimestamp) {
      data = (data as number[]).map(
        (datum, index): ChartPoint => ({
          // @ts-ignore
          x: labels[index],
          y: datum,
          t: undefined,
          r: undefined,
        }),
      );
    }
    const bgColor = randomColor();
    const borderColor = bgColor + '99';

    return {
      ...ds,
      data,
      fill: false,
      backgroundColor: bgColor,
      borderColor: borderColor,
      borderWidth: 1,
      pointRadius: 1,
      lineTension: 0,
    };
  });

  return { labels, datasets };
};
