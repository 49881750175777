const fonts = {
  family: {
    // heading: '"Lato", sans-serif',
    // content: '"Lato", sans-serif'
    heading: '"ProximaNova", sans-serif',
    content: '"ProximaNova", sans-serif', // something
    logo: 'Arimo',
  },
  weight: {
    heading: {
      normal: 500,
      bold: 700,
    },
    content: {
      normal: 300,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  size: {
    heading: {
      xLarge: '3.75em',
      large: '2.62em',
      medium: '2em',
      regular: '1.5em',
      small: '1.37em',
    },
    content: {
      large: '1.15em',
      medium: '1.125em',
      regular: '1em',
      small: '0.87em',
    },
  },
};

export default fonts;
