// import * as React from "react";

export enum DEVICE_TYPE {
  SMALL,
  MEDIUM,
  LARGE,
  XLARGE,
}

export const DEVICE_KEY = {
  [DEVICE_TYPE.SMALL]: 'mobile',
  [DEVICE_TYPE.MEDIUM]: 'tablet',
  [DEVICE_TYPE.LARGE]: 'desktop',
  [DEVICE_TYPE.XLARGE]: 'largeDesktop',
};
export const SCREEN_BREAKPOINTS = {
  small: 1024,
  medium: 1280,
  large: 1440,
  larger: 1580,
  xlarge: 1980,
};
// export const DeviceContext = React.createContext({
//   device: DEVICE_KEY[DEVICE_TYPE.LARGE],
//   isScreenSizeDetermined: false,
//   changeDeviceType: () => {}
// });
