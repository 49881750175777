import React from 'react';
import { toggleSidebar } from '../../../lib/store/sidebar/action';
import { Link } from 'react-router-dom';
import { getLogo_dark, getTenantName } from '../../../util/commonUtils';

export const TopBar = ({
  heading,
}: { heading?: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <React.Fragment>
      <div
        className={'d-flex align-items-center shadow-sm header bg-white px-3'}
      >
        <span onClick={toggleSidebar}>
          <i className={'fa fa-bars cursor-pointer text-dark font-18'} />
        </span>
        <Link className="test-tc-logo text-primary ml-3" to={'/'}>
          <div className="navbar-brand logo lead font-weight-bold">
            <img src={getLogo_dark()} alt={`${getTenantName()} Logo`} />
          </div>
        </Link>
        {heading}
      </div>
      <div style={{ height: '56px', marginBottom: '16px' }} />
    </React.Fragment>
  );
};
