import React from 'react';
import Description from './Description';
import { Link } from 'react-router-dom';
// import { page } from '../../../analytics';

interface IProps {
  message: string;
}

interface IState {}

const style = {
  width: '80%',
  margin: 'auto',
};

export default class ResetErrorPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  // componentDidMount() {
  //   page('reseterror');
  // }

  render() {
    const { message } = this.props;
    return (
      <div className="container-fluid login">
        <div className="row" style={style}>
          <div className="d-none d-md-block col-lg-5 offset-lg-1 col-xs-12">
            <Description />
          </div>
          <div className="col-lg-4 offset-lg-1 col-xs-12">
            <div className="row login-form">
              <div className="form-group verification">
                <div className="form-row title-row text-center">
                  <h3 className="title">Reset Password</h3>
                </div>
                <div className="form-row text-center content">
                  <h5>
                    {/* The page you are looking for might have been removed, had its
                                            name changed, or is temporarily unavailable. */}
                    {message}
                  </h5>
                </div>
              </div>
              <div className="form-row have-account text-center">
                {/* <h6>
                                    Go to<Link to="/login">Login</Link>
                                </h6> */}
                <h5>
                  Go back to login page.&nbsp;<Link to="/login">Login</Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
