import * as React from 'react';

import Description from '../components/Description';
// import { page } from '../../../analytics';

const style = {
  width: '80%',
  margin: 'auto',
};

const Verification: React.FC = () => {
  // page('accountcreated');
  return (
    <div className="container-fluid login test-verification-component d-flex align-items-center ">
      <div className="row" style={style}>
        <div className="d-none d-md-block col-lg-5 offset-lg-1 col-xs-12">
          <Description />
        </div>
        <div className="col-lg-4 offset-lg-1 col-xs-12">
          <div className="row login-form">
            <div className="form-group verification">
              <div className="form-row title-row text-center">
                <h3 className="title test-verification-title">
                  Verification Link Sent
                </h3>
              </div>
              <div className="form-row text-center content test-verification-content">
                <h5>
                  You have been sent a verification link on your email. Please
                  access the link to complete the registration.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
