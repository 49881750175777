import * as yup from 'yup';

export const adoptionSelectorSchema = yup.object({
  regions: yup.array().of(yup.string()),
  accounts: yup
    .array()
    .required()
    .of(yup.string())
    .min(1, 'Select one or more accounts'),
  resourceGroups: yup.array().of(yup.string()),
});

export type AdoptionSelectorSchema = yup.InferType<
  typeof adoptionSelectorSchema
>;
