import { Reducer } from 'redux';
import { OverviewAction, OverviewState } from './types';

const initialState: OverviewState = {
  fetching: false,
  data: {
    '7d': null,
    '1d': null,
    '3d': null,
    '12hrs': null,
    '6hrs': null,
  },
};

export const overviewReducer: Reducer<OverviewState, OverviewAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'FetchOverview':
      return { ...state, fetching: true };
    case 'OverviewFetchSuccess':
      return {
        fetching: false,
        data: {
          ...state.data,
          [action.duration]: action.data || state.data[action.duration],
        },
      };
    default:
      return state;
  }
};
