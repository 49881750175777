import { Reducer } from 'redux';
import {
  DeleteWorkflowsRequest,
  DeleteWorkflowsResponse,
  FetchTemplatesRequest,
  FetchTemplatesResponse,
  RequestWebflowItemWithoutTemplate,
  ResponseWebflowItemWithoutTemplate,
  SaveTemplateRequest,
  SaveTemplateResponse,
  SyncTemplatesRequest,
  SyncTemplatesResponse,
  TemplateManageState,
  TemplatesManageAction,
  ToggleTemplateModal,
  UpdateItemRequest,
  UpdateItemResponse,
} from './types';

const initialState: TemplateManageState = {
  isSyncingTemplates: false,
  fetchingWebflowItems: false,
  fetchingTemplates: false,
  templateMap: {},
  itemUpdateStateMap: {},
  webflowWithoutTemplate: null,
  isSavingTemplate: false,
  showTemplateModal: null,
  isDeletingWorkflows: false,
};

export const templatesManageReducer: Reducer<
  TemplateManageState,
  TemplatesManageAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SyncTemplatesRequest:
      return { ...state, isSyncingTemplates: true };
    case SyncTemplatesResponse:
      return { ...state, isSyncingTemplates: false };

    case RequestWebflowItemWithoutTemplate:
      return { ...state, fetchingWebflowItems: true };
    case ResponseWebflowItemWithoutTemplate:
      return {
        ...state,
        fetchingWebflowItems: false,
        webflowWithoutTemplate: action.data,
      };
    case FetchTemplatesRequest:
      return { ...state, fetchingTemplates: true };
    case FetchTemplatesResponse:
      return { ...state, fetchingTemplates: false, templateMap: action.data };
    case UpdateItemRequest:
      return {
        ...state,
        itemUpdateStateMap: {
          ...state.itemUpdateStateMap,
          [action.id]: { updating: action.col },
        },
      };
    case UpdateItemResponse:
      return {
        ...state,
        itemUpdateStateMap: {
          ...state.itemUpdateStateMap,
          [action.id]: { updating: '' },
        },
      };
    case SaveTemplateRequest:
      return { ...state, isSavingTemplate: true };
    case SaveTemplateResponse:
      return { ...state, isSavingTemplate: false };
    case ToggleTemplateModal:
      return { ...state, showTemplateModal: action.template };
    case DeleteWorkflowsRequest:
      return { ...state, isDeletingWorkflows: true };
    case DeleteWorkflowsResponse:
      return { ...state, isDeletingWorkflows: false };
    default:
      return state;
  }
};
