import { TableItem, TemplateColNames } from '../cols';
import * as React from 'react';
import InlineCategory from '../InlineCategory';
import { loader } from './loader';

export const TemplateCategory = (category: string[], item: TableItem) => {
  if (!item.templateItem || !item.templateItem.id) return;
  if (item.updating === TemplateColNames.TEMPLATE_CATEGORY) return loader();
  return (
    <InlineCategory
      updateCategories={item.functions.updateCategories}
      template={item.templateItem}
    />
  );
};
