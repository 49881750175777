import { TableItem } from '../cols';
import * as React from 'react';
const DOMAIN = 'usecase-totalcloud.webflow.io';

export const WorkflowName = (name: string, item: TableItem) => {
  if (item.slug)
    return (
      <a
        target={'_blank'}
        href={`https://${DOMAIN}/use-cases/${item.slug}`}
        className={'text-truncate d-flex'}
        title={name}
      >
        {name}
      </a>
    );
  return (
    <span className={'text-truncate d-flex'} title={name}>
      {name}
    </span>
  );
};
