import * as React from 'react';

import { Provider } from 'react-redux';
import { store } from '../lib/store';
import { Row } from 'reactstrap';
import { SideBar } from '../components/common/SideBar';
import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { TemplateIndex } from '../components/templates-page';
import { AppContainer } from './style';
import { OnBoardingIntercept } from '../components/common/onBoardingIntercept';
import { SidebarWrapper } from '../components/common/sideBarWrapper';
import { NavBarWrapper } from '../components/common/navbarWrapper';

export class TemplatesView extends React.Component<SecureRouteProps> {
  render(): React.ReactNode {
    return (
      <Provider store={store}>
        <OnBoardingIntercept render={this.renderInner} />
      </Provider>
    );
  }

  renderInner = () => (
    <Provider store={store}>
      <AppContainer fluid={true}>
        <SidebarWrapper {...this.props}>
          <div className="dashboard-content">
            <TemplateIndex {...this.props} />
          </div>
        </SidebarWrapper>
      </AppContainer>
    </Provider>
  );
}

export default withTcApi(TemplatesView);
