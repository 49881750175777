import { JSONAction, JsonValueState } from './types';
import { Reducer } from 'redux';

const initialState: JsonValueState = {
  data: '{}',
  fetching: false,
};

export const RequestJSON = 'RequestJSON';
export const RequestJSONSuccess = 'RequestJSONSuccess';

export const JSONReducer: Reducer<JsonValueState, JSONAction> = (
  state = initialState,
  action,
): JsonValueState => {
  switch (action.type) {
    case 'RequestJSON':
      return { ...state, fetching: true };
    case 'RequestJSONSuccess':
      return { ...state, data: action.data, fetching: false };
    default:
      return { ...state };
  }
};
