import { Workflow } from '../../../lib/store/workflows/types';

import { REGIONS } from '../../../lib/editor/components/model/config/Regions';
import { Config } from '../../../lib/store/config/types';
import { Template } from '../../../lib/store/templates/types';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';

function getPropertyFromWorkflow(workflows: Workflow[], property) {
  let regionsSubCat = workflows.map((x) => {
    let rC =
      x.properties &&
      x.properties.categories &&
      x.properties.categories.find((c) => c.category === property);
    if (!rC) return null;
    return rC.subCategories || [];
  });
  regionsSubCat = regionsSubCat.filter((x) => !!x);
  let regions = regionsSubCat.reduce((A, e) => A.concat(e), []);
  return regions.filter((x, i, a) => a.indexOf(x) === i);
}

export const getRegionsFromWorkflow = (workflows: Workflow[]) => {
  return getPropertyFromWorkflow(workflows, 'Region');
};

export const getResourceGroupsFromWorkflow = (workflows: Workflow[]) => {
  return getPropertyFromWorkflow(workflows, 'ResourceGroup');
};

/*
return templates that are not adopted even if in one region account combination.
 */

const isTemplateAdoptedInAllAccountAndRegion = (
  regionAccountMap: { templateId: string; region: string; account: string }[],
  template: Template,
  regions: string[],
  accounts: string[],
  configs: Config[],
) => {
  let skipAccount = false;
  let possibleCombinations: {
    account?: string;
    region: string;
    templateId: string;
    allowedAccountsTypes?: CONFIG_TYPES[];
  }[] = [];
  if (!accounts || !accounts.length) {
    possibleCombinations = regions.map((r) => ({
      region: r,
      templateId: template.id,
    }));
    skipAccount = true;
  } else {
    possibleCombinations = regions
      .map((r) =>
        accounts.map((a) => ({
          account: a,
          region: r,
          templateId: template.id,
          allowedAccountsTypes:
            template.properties && template.properties.allowedAccountTypes,
        })),
      )
      .reduce((A, e) => A.concat(e), []);
  }

  // filter if account should not be adopted for that template.
  let validPossibleCombinations = possibleCombinations.filter((combi) => {
    let accountObj = configs.find((x) => x.id === combi.account);
    if (!accountObj || !combi.allowedAccountsTypes) return true;
    return combi.allowedAccountsTypes.includes(accountObj.type);
  });

  validPossibleCombinations = validPossibleCombinations.filter((x) => {
    let alreadyAdopted = regionAccountMap.find((ra) => {
      return (
        ra.templateId === x.templateId &&
        ra.region === x.region &&
        (skipAccount || ra.account === x.account)
      );
    });
    return !alreadyAdopted;
  });
  return validPossibleCombinations.length > 0;
};

export const getUnadoptedTemplates = (params: {
  configs: Config[];
  regions: string[];
  accounts: string[];
  templates: Template[];
  workflows: Workflow[];
}): string[] => {
  let { workflows, templates, accounts, regions, configs } = params;

  if (!regions) regions = REGIONS.map((x) => x.value);
  let regionAccountMap = workflows.map((x) => {
    if (!x.properties || !x.properties.templateId) return null;
    let regionCat =
      x.properties.categories &&
      x.properties.categories.find((x) => x.category === 'Region');
    let accountCat =
      x.properties.categories &&
      x.properties.categories.find((x) => x.category === 'Account');
    return {
      templateId: x.properties.templateId,
      region:
        regionCat && regionCat.subCategories && regionCat.subCategories[0],
      account:
        accountCat && accountCat.subCategories && accountCat.subCategories[0],
    };
  });

  regionAccountMap = regionAccountMap.filter((x) => !!x);
  // console.log(regionAccountMap, regions, accounts);

  // all templates that are not adopted by all regions and account
  return templates
    .filter((t) => {
      return isTemplateAdoptedInAllAccountAndRegion(
        regionAccountMap,
        t,
        regions,
        accounts,
        configs,
      );
    })
    .map((x) => x.id);
};
