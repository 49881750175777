import { Col, Row } from 'reactstrap';
import React from 'react';
import { TAB_NAME } from 'webapp-genric/dist/constants';

interface PageHeadingProps {
  /**
   * A Category to differentiate type of page.
   */
  category?: string;
  /**
   * A subCategory to differentiate between different Components of the page
   */
  subCategory?: string;
  heading?: React.ReactNode;
  tabName: TAB_NAME;
}

export const Heading = (props: PageHeadingProps) => {
  const { tabName, heading } = props;
  return (
    <h3 data-test="list-layout-heading" className="mb-0">
      {heading || tabName}
    </h3>
  );
};
