import {
  FetchTemplates,
  SelectTemplateFetchSuccess,
  Template,
  TemplateAdoptionRequest,
  TemplateAdoptionResponse,
  TemplateFetchSuccess,
  TemplatesAction,
} from './types';
import api from '../../../api';
import { notify } from '../../../components/common/toaster';
import { store } from '../index';
import {
  createWorkflow,
  fetchGroupWorkflow,
  fetchWorkflow,
} from '../workflows/action';
import { Workflow as WorkflowModel } from 'workflow-model/dist';
import { findWorkflowWithRegionAndAccount } from '../../../components/common/categoryUtils';
import { getWorkflowModelParamsFromTemplate } from 'workflow-model/dist/workflowModel';
import { superApiCall } from '../super-table/action';
import { ApiOperation } from '../super-table/types';
import { TemplatesToAdopt } from '../../../components/common/AutoAdoptTemplatesV2';

export const SendTemplateRequest = function(): TemplatesAction {
  return {
    type: FetchTemplates,
  };
};

export const TemplateResponse = function(
  data: Template[] | null,
): TemplatesAction {
  return {
    type: TemplateFetchSuccess,
    data: data as Template[],
  };
};
export const SelectedTemplateResponse = function(
  data: Template | null,
): TemplatesAction {
  return {
    type: SelectTemplateFetchSuccess,
    selected: data as Template,
  };
};

export const SendTemplateAdoptionRequest = function(): TemplatesAction {
  return {
    type: TemplateAdoptionRequest,
  };
};

export const SendTemplateAdoptionResponse = function(): TemplatesAction {
  return {
    type: TemplateAdoptionResponse,
  };
};
export const fetchTemplates = (props: { dispatch: (e: any) => void }) => {
  let { dispatch } = props;
  dispatch(SendTemplateRequest());
  api
    .listTemplates({ extended: false, active: true })
    .then((data) => {
      return dispatch(TemplateResponse(data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(TemplateResponse(null));
    });
};

export const fetchTemplate = (props: {
  dispatch: (e: any) => void;
  templateId: string;
}) => {
  let { dispatch, templateId } = props;
  dispatch(SendTemplateRequest());
  api
    .getTemplate(templateId)
    .then((data) => {
      return dispatch(SelectedTemplateResponse(data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SelectedTemplateResponse(null));
    });
};

// to reduce the time required for user to see the templates page
function loadAtRun() {
  fetchTemplates({ dispatch: store.dispatch });
}

loadAtRun();

interface TemplateAdoptionActionProps {
  dispatch: (e: any) => void;
  templatesObj?: TemplatesToAdopt[];
  templateIds?: any[];
  resourceGroup?: string[];
  account?: string[];
  region?: string[];
}

export const adoptTemplates = (
  props: TemplateAdoptionActionProps & {
    category?: string;
    subCategory?: string;
    options?: any;
  },
) => {
  const { category, options, subCategory, dispatch } = props;
  props.dispatch(SendTemplateAdoptionRequest());
  return adoptTemplatesInner(props as TemplateAdoptionActionProps)
    .catch((error) => {
      console.error(error);
      props.dispatch(SendTemplateAdoptionResponse());
      notify({ type: 'error', message: 'Template adoption failed' });
    })
    .finally(() => {
      category &&
        superApiCall({
          operation: ApiOperation.GET,
          category,
          subCategory,
          dispatch,
          options,
        });
    });
};

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const waitForAdoptionWorkflowToFinish = async (
  executionId,
  retryCount = 0,
): Promise<number> => {
  if (retryCount === 40) return 1; // assuming adoption workflow still running and need to reload workflows
  if (!executionId) return 0;
  await wait(5000);
  let executionHistory = await api.getExecutionSteps(executionId);
  if (executionHistory && executionHistory.status === 'Success') {
    let { history } = executionHistory;
    let adoptionNode = history.find((x) => x.nodeType === 'templateAdoption');
    if (!adoptionNode || !adoptionNode.output || !adoptionNode.output.data)
      return 0;
    return adoptionNode.output.data.length;
  }

  if (executionHistory && executionHistory.status === 'Error') {
    throw new Error('Error in workflow execution status');
  }
  return waitForAdoptionWorkflowToFinish(
    executionId,
    (retryCount = retryCount + 1),
  );
};

const adoptTemplatesInner = async (props: TemplateAdoptionActionProps) => {
  let {
    account,
    templateIds,
    region,
    dispatch,
    resourceGroup,
    templatesObj,
  } = props;
  const { executionId } = await api.createBatchAdoptionJob({
    resourceGroup,
    region,
    account,
    templateIds,
    templatesObj,
  });
  let numberOfWorkflowsAdopted = await waitForAdoptionWorkflowToFinish(
    executionId,
  );
  // // notify({type:"success", message: "Successfully adopted templates"});
  // console.log({ numberOfWorkflowsAdopted });
  if (numberOfWorkflowsAdopted) {
    await fetchWorkflow({ dispatch });
    props.dispatch(SendTemplateAdoptionResponse());
  } else {
    props.dispatch(SendTemplateAdoptionResponse());
  }
};

export const getSerializedWorkflowFromTemplate = async (props: {
  user: string;
  templateId: string;
  account: string;
  region: string;
  group?: string;
  skipRegionAndAccount?: boolean;
}) => {
  let {
    account,
    templateId,
    region,
    user,
    group,
    skipRegionAndAccount,
  } = props;
  const template = await api.getTemplate(templateId);
  let modelProps = getWorkflowModelParamsFromTemplate({ user, template });
  modelProps.group = group;
  const wf = new WorkflowModel(modelProps);
  if (!skipRegionAndAccount) {
    wf.setCredentials(account);
    wf.setRegion(region);
  }

  if (!wf.isActive()) {
    wf.toggleActive();
  }
  const serialized = wf.serialize({
    skipCredsAndRegionValidation: skipRegionAndAccount,
  });

  return serialized;
};

const adoptSingleTemplateInner = async function(props: {
  dispatch: (e: any) => void;
  user: string;
  templateId: string;
  account: string;
  region: string;
  group?: string;
  skipRegionAndAccount?: boolean;
}) {
  let serialized = await getSerializedWorkflowFromTemplate(props);
  return await createWorkflow({
    dispatch: props.dispatch,
    ...serialized,
    definition: JSON.stringify(serialized.definition),
  });
};

export const adoptSingleTemplate = function(props: {
  dispatch: (e: any) => void;
  user: string;
  templateId: string;
  account: string;
  region: string;
  skipRegionAndAccount?: boolean;
}) {
  props.dispatch(SendTemplateAdoptionRequest());
  return adoptSingleTemplateInner(props)
    .then(() => props.dispatch(SendTemplateAdoptionResponse()))
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      props.dispatch(SendTemplateAdoptionResponse());
    });
};

export const getWorkflowsAdoptedFormTemplate = async (props: {
  user: string;
  name: string;
  templateId: string;
  account: string;
  region: string;
  group?: string;
  skipRegionAndAccount?: boolean;
}) => {
  const {
    account,
    region,
    group,
    name,
    skipRegionAndAccount,
    templateId,
  } = props;
  const queryParams = group && `group=${group}`;
  let workflows = await api.getWorkflows(queryParams);

  let named = workflows.filter(
    (wf) =>
      wf.name === name ||
      (wf.properties && wf.properties.templateId === templateId),
  );
  let tgt;

  if (skipRegionAndAccount) tgt = named;
  else tgt = findWorkflowWithRegionAndAccount(named, [region], [account]);

  return tgt;
};

async function upsertTemplateInner(props: {
  dispatch: (e: any) => void;
  user: string;
  name: string;
  templateId: string;
  account: string;
  region: string;
  group?: string;
  skipRegionAndAccount?: boolean;
}) {
  const { group, dispatch } = props;

  let wfs = await getWorkflowsAdoptedFormTemplate({ ...props });
  if (wfs.length) return;

  await adoptSingleTemplateInner(props);
  if (group) {
    await fetchGroupWorkflow({ dispatch, group });
  }
}

// ensures template is present for user
export const upsertTemplate = (props: {
  dispatch: (e: any) => void;
  user: string;
  name: string;
  templateId: string;
  account: string;
  region: string;
  group?: string;
  skipRegionAndAccount?: boolean;
}) => {
  props.dispatch(SendTemplateAdoptionRequest());
  return upsertTemplateInner(props)
    .then(() => {
      return props.dispatch(SendTemplateAdoptionResponse());
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      props.dispatch(SendTemplateAdoptionResponse());
    });
};
