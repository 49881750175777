import React from 'react';
import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import {
  TableSubCategories,
  TableWrapper,
  TableWrapperWithRegion,
} from '../components/tables';
import { Provider } from 'react-redux';
import { store } from '../lib/store';
import { SidebarWrapper } from '../components/common/sideBarWrapper';
import { AppContainer } from './style';
import { ViewWrapper } from './ViewWrapper';

const InsightsTabV2 = (props: SecureRouteProps) => {
  return (
    <Provider store={store}>
      <AppContainer fluid>
        <SidebarWrapper {...props}>
          <TableWrapperWithRegion
            {...props}
            tabName={TAB_NAME.INSIGHTS}
            category={'insights'}
            subCategories={[
              TableSubCategories.TABLE_DATA,
              TableSubCategories.CATEGORY_DATA,
              TableSubCategories.SUMMARY_DATA,
              TableSubCategories.TRIGGER_SETTINGS_DATA,
              TableSubCategories.SHARE_SETTINGS_DATA,
              TableSubCategories.ADOPTION_SETTINGS_DATA,
            ]}
            hideCategory={true}
          />
        </SidebarWrapper>
      </AppContainer>
    </Provider>
  );
};

export default withTcApi(InsightsTabV2);
