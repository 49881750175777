import * as React from 'react';
import { getHelpComponent } from '../../common/commonHelp';

export const CustomNodeHelpText: React.ReactNode = (
  <>
    {getHelpComponent({
      heading: 'Custom Node',
      para1:
        'The Custom node gives you the ability to write custom scripts to perform operations on the input data. The custom code is executed in a NodeJS v10 sandbox.',
      para2: '',
      para3: '',
      params: [
        {
          key: 'Select a resource',
          description:
            'Choose the output of a particular node that you wish to work on.',
        },
        {
          key: 'Select a predefined logic',
          description:
            'We’ve developed a few predefined custom logic, like the lambda cost predictor and detector, tags-info-format, etc., but you can enter almost any logic through this node. To add your custom logic, select “custom” in this dropdown.',
        },
      ],
      examples: [],
      notes: [
        'A context object is available in the custom function which contains a collection which is the output of the previous node (input to this node) and the selected region in the action or resource node previous to this node.',
        'You could transform the data in whatever way you want. Please note that the collection object is read-only. Any transformations should be on a copy of it.',
        'Call exit with (null, result). If there is an error, you could pass the error as the first parameter to the exit function and the second parameter should be null.',
        "It is not possible to directly use workflow variables in custom code using the '$wf.variables <variable_name>'. To use workflow variables, you need to add variables in the custom node, located just above the custom code window and refer to the workflow variables. The variables defined here can be used directly within the custom code.",
        "View the output for the Custom Node by opening the workflow output, and clicking 'Logs'. Any output printed in the workflow will be shown there.",
      ],
      moreLink: 'https://docs.totalcloud.io/workflows/nodes/custom-node',
    })}
  </>
);
