import * as React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export function SectionLoader() {
  return (
    <div className="w-100 h-100vh d-flex justify-content-center align-items-center">
      <Loader
        visible={true}
        type="Grid"
        color="#00BFFF"
        height={100}
        width={100}
      />
    </div>
  );
}
