export interface ExecutionHistory {
  input: any;
  output: any;
  cause: any;
}

export interface Execution {
  workflow: string;
  execution: string;
  status: 'Queued' | 'Running' | 'Error' | 'Success';
  startDate: number;
  workflowName?: string;
  endDate: number;
  history?: ExecutionHistory[];
  timestamp: string;
  parent?: string;
  group?: string;
  properties?: {
    subject: string;
  };
}

// events
export const ResetExecutionMap = 'ResetExecutionMap';
export const FetchExecution = 'FetchExecution';
export const FetchBatchExecution = 'FetchBatchExecution';
export const FetchBatchExecutionSuccess = 'FetchBatchExecutionSuccess';
export const FetchExecutionSuccess = 'FetchExecutionSuccess';
export const FetchBatchWorkflowExecution = 'FetchBatchWorkflowExecution';
export const FetchBatchWorkflowExecutionSuccess =
  'FetchBatchWorkflowExecutionSuccess';

export const FetchWorkflowExecution = 'FetchWorkflowExecution';
export const FetchWorkflowExecutionSuccess = 'FetchWorkflowExecutionSuccess';

interface FetchBatchWorkflowExecution {
  type: typeof FetchBatchWorkflowExecution;
  workflows: string[];
}

interface FetchBatchWorkflowExecutionSuccess {
  type: typeof FetchBatchWorkflowExecutionSuccess;
  workflows: {
    workflow: string;
    executions: Execution[];
    output: WorkflowExecutionOutput | null;
  }[];
}

interface ResetExecutionMap {
  type: typeof ResetExecutionMap;
}

interface FetchBatchExecution {
  type: typeof FetchBatchExecution;
}
interface FetchBatchExecutionSuccess {
  type: typeof FetchBatchExecutionSuccess;
}
interface FetchExecution {
  type: typeof FetchExecution;
}

interface FetchExecutionSuccess {
  type: typeof FetchExecutionSuccess;
  duration: string;
  data: Execution[];
}

interface FetchWorkflowExecution {
  type: typeof FetchWorkflowExecution;
  workflow: string;
}

export type TagsInfoType = {
  totalResources: number;
  unTagged: number;
  tags: { [s: string]: number };
};

export type WorkflowExecutionOutput = {
  state: string;
  url?: string;
  message?: string;
  tagsInfo?: TagsInfoType;
  eventLookupResult?: {
    initialOptions: any;
    result: any[];
  };
  utilisationReport?: {
    Unit?: string;
    metricUtilisationMap: {
      [s: string]: {
        high: { value: number; label: string };
        low: { value: number; label: string };
      };
    };
    resource: string;
    totalResources: number;
    result: { [s: string]: number }[];
    utilisation: {
      [s: string]: {
        overAllUtilisation: number;
        highUtilisation: number;
        lowUtilisation: number;
      };
    };
  };
};

interface FetchWorkflowExecutionSuccess {
  type: typeof FetchWorkflowExecutionSuccess;
  workflow: string;
  data: Execution[];
  output: WorkflowExecutionOutput | null;
}

export type ExecutionAction =
  | FetchExecution
  | FetchExecutionSuccess
  | FetchWorkflowExecution
  | FetchWorkflowExecutionSuccess
  | FetchBatchExecutionSuccess
  | FetchBatchExecution
  | FetchBatchWorkflowExecution
  | FetchBatchWorkflowExecutionSuccess
  | ResetExecutionMap;

export type ExecutionDataType = {
  exes: Execution[];
  fetching: boolean;
  output: WorkflowExecutionOutput | null;
};

export type ExecutionsMapType = {
  [s: string]: ExecutionDataType;
};

export interface ExecutionState {
  fetching: boolean;
  batchFetching: boolean;
  data: {
    //// object of execution by duration
    [s: string]: Execution[];
  };
  workflowExecutions: ExecutionsMapType;
}
