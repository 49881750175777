import * as React from 'react';
import {
  OverviewCardLeft,
  InnerSec,
  IntervalLabel,
  OverviewCard,
} from '../styledEntities';
import colors from '../../../design/colors';
import { Row, Col } from 'reactstrap';

export interface OverviewData {
  Success?: number;
  Failed?: number;
  Processing?: number;
  Total?: number;
  SuccessRate?: string;
}

interface OverviewProps {
  intervals: Array<{ value: string; label: string }>;
  selectedTimeperiod: string;
  updateTimeperiod: Function;
  // updateDateRange: Function;
  overviewData: OverviewData | null;
}

interface OverviewState {}

export default class Overview extends React.Component<
  OverviewProps,
  OverviewState
> {
  updateInterval = (val: any) => {
    this.props.updateTimeperiod(val);
  };

  renderDataCard = (props: {
    color: any;
    value: React.ReactNode;
    label: React.ReactNode;
  }) => {
    return (
      <div data-test="data-card-value">
        {/* <DataCard> */}
        <div
          className="data-value"
          style={{
            color: props.color,
            fontSize: '2.5em',
          }}
        >
          {props.value}
        </div>
        <div
          style={{
            color: '#0A2140',
            opacity: 0.5,
            fontSize: '1em',
            fontWeight: 600,
            paddingTop: '1em',
            fontStyle: 'italic',
          }}
          className="data-label"
        >
          {props.label}
        </div>
        {/* </DataCard> */}
      </div>
    );
  };

  renderData = () => {
    const { overviewData } = this.props;
    if (!overviewData) {
      return null;
    }
    const { Success, Failed, Total } = overviewData;

    return (
      <Row
        className="test-summary-info p-3"
        style={{
          fontSize: '1em',
        }}
      >
        <InnerSec>
          {this.renderDataCard({
            value: Total ? Total : 0,
            label: 'Total Executions',
            color: colors.text.secondary,
          })}

          {this.renderDataCard({
            value: Success ? Success : 0,
            label: 'Successful',
            color: colors.palette.green,
          })}

          {this.renderDataCard({
            value: Failed ? Failed : 0,
            label: 'Failed',
            color: colors.palette.red,
          })}
        </InnerSec>
      </Row>
    );
  };

  render() {
    const { selectedTimeperiod, intervals } = this.props;
    return (
      <OverviewCard className="overview-card">
        <OverviewCardLeft className="overview-card-left test-summary-card">
          <Row
            className="test-summary-header p-3"
            style={{
              fontSize: '1.2em',
            }}
          >
            <InnerSec lg="6">Executions</InnerSec>
            <InnerSec lg="6">
              {intervals.map((interval, idx) => (
                <IntervalLabel
                  key={idx}
                  className={
                    selectedTimeperiod == interval.value ? 'active' : ''
                  }
                  onClick={() => this.updateInterval(interval.value)}
                >
                  {interval.label}
                </IntervalLabel>
              ))}
              {/* <CustomDateRangeFilter updateDateRange={this.props.updateDateRange} /> */}
            </InnerSec>
          </Row>

          {this.renderData()}
        </OverviewCardLeft>
      </OverviewCard>
    );
  }
}
