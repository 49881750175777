import React from 'react';
import { Config } from '../../lib/store/config/types';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { fetchConfig } from '../../lib/store/config/action';
import { Col, Row } from 'reactstrap';
import { REGIONS } from '../../lib/editor/components/model/config/Regions';
import { RegionAndCredsComponent } from './RegionAndCredsComponent';
import _ from 'lodash';
import { toggleSidebar } from '../../lib/store/sidebar/action';
import { getLogo_dark, getTenantName } from '../../util/commonUtils';
import { Link } from 'react-router-dom';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { PolicyStatusV2 } from './PolicyValidation';
import { AuthProps } from '../../api/tcApiHelper';
import { DummyField } from './DummyField';
import { withAuth } from '@okta/okta-react';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';

export const getAccountConfig = (configs: Config[] = []): Config[] => {
  if (!configs) return [];
  return configs.filter(
    (u) =>
      u.type === CONFIG_TYPES.AWS_KEY ||
      u.type === CONFIG_TYPES.AWS_ROLE ||
      u.type === CONFIG_TYPES.AWS_PROXY ||
      u.type === CONFIG_TYPES.AWS_ORG_MEMBER ||
      u.type === CONFIG_TYPES.AZURE ||
      u.type === CONFIG_TYPES.VMWARE ||
      u.type === CONFIG_TYPES.Kubernetes ||
      u.type === CONFIG_TYPES.TerraformCloud,
  );
};

export const getAwsAccountConfig = (configs: Config[] = []): Config[] => {
  if (!configs) return [];
  return configs.filter(
    (u) =>
      u.type === CONFIG_TYPES.AWS_KEY ||
      u.type === CONFIG_TYPES.AWS_ROLE ||
      u.type === CONFIG_TYPES.AWS_PROXY ||
      u.type === CONFIG_TYPES.AWS_ORG_MEMBER,
  );
};

export type RegionChildProps = {
  regions: string[];
  credentials: string[];
  updateConfigAndRegion: (updateKey: string, value: string[]) => void;
};

export type RegionParentProps = {
  tabName?: TAB_NAME;
};

export function getPropertyFromStore(property, defaultValue) {
  try {
    return JSON.parse(window.localStorage.getItem(property)) || defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
}

export function setPropertyInStore(property, value) {
  try {
    window.localStorage.setItem(property, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
}

export const withRegionAndCredentialsIntermediate = function<
  P extends RegionChildProps
>(Component: React.ComponentType<P>) {
  type OwnProps = Exclude<P, RegionChildProps> & RegionParentProps & AuthProps;

  type StateProps = {
    _configs: Config[] | null;
    _isFetching: boolean;
  };
  type DispatchProps = {
    fetchConfig: () => void;
  };

  type IProps = OwnProps & StateProps & DispatchProps & { user: any };

  type IState = {
    user: string | null;
  };

  class WithRegionAndCredentials extends React.Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = { user: null };
    }

    get _configs() {
      return getAccountConfig(this.props._configs);
    }

    getSelectedGlobalRegionsFromStorage = () => {
      return getPropertyFromStore('tc_global_region', []);
    };

    setGlobalRegionsInStorage = (regions: string[]) => {
      setPropertyInStore('tc_global_region', regions);
      this.forceUpdate();
    };

    getSelectedGlobalCredsFromStorage = () => {
      return getPropertyFromStore('tc_global_credentials', []);
    };

    setGlobalCredsInStorage = (credentials: string[]) => {
      setPropertyInStore('tc_global_credentials', credentials);
      this.forceUpdate();
    };

    getSelectedRegions = (): string[] => {
      let selectedRegions = this.getSelectedGlobalRegionsFromStorage();
      if (selectedRegions.length === 0) {
        selectedRegions = REGIONS.map((x) => x.value);
        this.setGlobalRegionsInStorage(selectedRegions);
      }
      return selectedRegions;
    };

    getSelectedAccounts = () => {
      let selectedAccounts = this.getSelectedGlobalCredsFromStorage();
      selectedAccounts = selectedAccounts.filter((x) =>
        this._configs.find((c) => c.id === x),
      );

      if (
        selectedAccounts.length === 0 &&
        this._configs &&
        this._configs.length !== 0
      ) {
        selectedAccounts = this._configs.map((x) => x.id);
        this.setGlobalCredsInStorage(selectedAccounts);
      }
      return selectedAccounts;
    };

    updateConfigAndRegion = (updateKey, value) => {
      if (updateKey === 'region') {
        this.setGlobalRegionsInStorage(value);
      } else if (updateKey === 'account') {
        this.setGlobalCredsInStorage(value);
      } else if (updateKey === 'accountAndRegion') {
        this.setGlobalCredsInStorage([value[0]]);
        this.setGlobalRegionsInStorage([value[1]]);
      }
    };

    componentDidMount() {
      this.setUser();
      if (!this.props._configs) {
        this.props.fetchConfig();
      }
    }

    componentDidUpdate(
      prevProps: Readonly<IProps>,
      prevState: Readonly<IState>,
    ) {
      if (!_.isEqual(prevProps.auth, this.props.auth)) {
        this.setUser();
      }
      if (!_.isEqual(prevProps._configs, this.props._configs)) {
        this.props.fetchConfig();
      }
    }

    setUser = () => {
      this.props.auth
        .getUser()
        .then((auth) => {
          auth && this.setState({ user: auth.email });
        })
        .catch((err) => console.error(err));
    };

    render() {
      const _configs = this._configs;
      if (!_configs) return <i className="fa fa-spin fa-spinner" />;
      const selectedRegions = this.getSelectedRegions();
      const selectedAccounts = this.getSelectedAccounts();
      const { user } = this.state;
      const { tabName } = this.props;
      return (
        <div>
          {/*render navbar */}
          <Row className="d-flex header align-items-center justify-content-between pl-3 pr-4 no-gutters">
            <Col
              md={4}
              id={'leftHeader'}
              className={'d-flex align-items-center'}
            >
              <span onClick={toggleSidebar}>
                <i className={'fa fa-bars cursor-pointer text-dark font-18'} />
              </span>
              <Link className="test-tc-logo text-primary ml-3" to={'/'}>
                <div className="navbar-brand logo lead font-weight-bold">
                  <img src={getLogo_dark()} alt={`${getTenantName()} Logo`} />
                  {/*{getTenantName()}*/}
                </div>
              </Link>
            </Col>
            <Col md={8}>
              <Row className={'d-flex justify-content-end align-items-center'}>
                {!user ? (
                  <DummyField width={'200px'} />
                ) : (
                  Boolean(tabName) && (
                    <div className="p-1">
                      <PolicyStatusV2
                        text={tabName}
                        tabName={tabName}
                        credentials={selectedAccounts}
                        regions={selectedRegions}
                      />
                    </div>
                  )
                )}
                {!user ? (
                  <DummyField width={'200px'} />
                ) : (
                  <div className={'d-flex justify-content-end'}>
                    <RegionAndCredsComponent
                      configs={this._configs}
                      onAccountChange={this.setGlobalCredsInStorage}
                      onRegionChange={this.setGlobalRegionsInStorage}
                      selectedAccount={selectedAccounts}
                      selectedRegion={selectedRegions}
                      isMultiSelect={true}
                      allowSelectAll={true}
                    />
                  </div>
                )}
              </Row>
            </Col>
          </Row>

          {/*render body*/}
          <Component
            {...this.props}
            regions={this.getSelectedRegions()}
            credentials={this.getSelectedAccounts()}
            updateConfigAndRegion={this.updateConfigAndRegion}
          />
        </div>
      );
    }
  }

  function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
    return {
      _configs: state.config.data,
      _isFetching: state.config.fetching,
    };
  }

  function mapDispatchToProps(
    dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
    ownProps: OwnProps,
  ): DispatchProps {
    return {
      fetchConfig: () => fetchConfig({ dispatch }),
    };
  }

  return connect<StateProps, DispatchProps, OwnProps, AppState>(
    mapStateToProps,
    mapDispatchToProps,
    // @ts-ignore
  )(WithRegionAndCredentials);
};

export const withRegionAndCredentials = (Component: React.ComponentType<any>) =>
  withAuth(withRegionAndCredentialsIntermediate(Component));
