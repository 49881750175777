import * as yup from 'yup';

export const testTemplateSchema = yup.object({
  region: yup.string().required(),
  account: yup.string().required(),
  resourceGroup: yup.string(),
  skipNodeIds: yup.array().of(yup.string()),
});

export type TestTemplateFormSchema = yup.InferType<typeof testTemplateSchema>;
