import React from 'react';
import { SecureRouteProps } from '../../../api/tcApiHelper';
import ResetForm from '../components/ResetForm';
import ResetErrorPage from '../components/ResetErrorPage';
import { MSG_NO_INTERNET } from '../components/ErrorHandler';
import { OktaConfig } from '../../../config/okta';

interface IState {
  recoveryToken: string;
  recoveryTokenError: boolean;
  recoveryTokenErrorMessage: string;
  stateToken: string;
  verificationProgress: boolean;
}

export default class Reset extends React.Component<SecureRouteProps, IState> {
  constructor(props: SecureRouteProps) {
    super(props);
    this.state = {
      recoveryToken: props.match.params['recoveryToken'],
      recoveryTokenError: false,
      recoveryTokenErrorMessage: '',
      stateToken: '',
      verificationProgress: false,
    };
  }

  componentDidMount() {
    this.verifyToken();
  }

  verifyToken(): void {
    this.setState({ verificationProgress: true });
    fetch(`${OktaConfig.REACT_APP_RECOVERY_TOKEN_VERIFY_URL}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        // stateToken: cookies.oktaStateToken,
        // stateToken: this.props.recoveryToken,
        // newPassword,
        recoveryToken: this.state.recoveryToken,
      }),
    })
      // .then((res) => console.log(res));
      .then((res) => res.json())
      .then((resp) => {
        if (resp.errorCauses) {
          this.setState({
            // passwordReset: false,
            recoveryTokenError: true,
            recoveryTokenErrorMessage: resp.errorSummary,
            verificationProgress: false,
          });

          console.error('err');
        } else {
          // if (resp.status === 'SUCCESS') this.setState({ status: true });
          this.setState({
            stateToken: resp.stateToken,
            verificationProgress: false,
            recoveryTokenErrorMessage: '',
            recoveryTokenError: false,
          });
        }
      })
      .catch((err) => {
        // console.log('net is down');
        this.setState({
          // passwordReset: false,
          recoveryTokenError: true,
          recoveryTokenErrorMessage: MSG_NO_INTERNET,
          verificationProgress: false,
        });
      });
  }

  render() {
    // let { stateToken } = this.state;
    // return <ResetForm recoveryToken={recoveryToken} />;

    let {
      recoveryTokenError,
      stateToken,
      recoveryTokenErrorMessage,
      verificationProgress,
    } = this.state;

    if (recoveryTokenError) {
      return <ResetErrorPage message={recoveryTokenErrorMessage} />;
    }
    if (verificationProgress) {
      return <ResetErrorPage message="Verifying..." />;
    }
    return <ResetForm stateToken={stateToken} />;
  }
}
