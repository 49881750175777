import { Button } from 'reactstrap';
import * as React from 'react';
import moment from 'moment';

const LabelLastexecution = (row) => {
  let t =
    row.exe && row.exe.exes && row.exe.exes.length && row.exe.exes[0].timestamp
      ? row.exe.exes.length && row.exe.exes[0].timestamp
      : '';

  return t ? `Last executed ${t}` : '';
};

export const formatResourceCol = (col, row) => {
  let value = col;
  if (row.setResourceView && row.url)
    return (
      <div
        className="px-0 text-darker font-weight-bold cursor-pointer"
        onClick={() => row.setResourceView(row)}
      >
        <i className="fa fa-line-chart mr-1 font-8" />
        <div>{value}</div>
        <div className={'text-muted font-weight-normal'}>
          <small>{LabelLastexecution(row)}</small>
        </div>
      </div>
    );
  return (
    <div title={value} className={''}>
      <div className={'text-dark font-weight-400 text-truncate'}>{value}</div>
      <div className={'text-muted'}>
        <small>{LabelLastexecution(row)}</small>
      </div>
    </div>
  );
};
