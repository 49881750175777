export type OnBoardingPage =
  // | 'Landing'
  // | 'Goal'
  // | 'Experts'
  // | 'DefineSuccess'
  | 'HearFrom'
  | 'CloudChallenges'
  | 'CurrentlyAccomplish'
  | 'CurrentCloudProvider'
  | 'IntendToGain'
  // | 'Account'
  // | 'Templates'
  // | 'Adoption'
  // | 'Custom'
  // | 'RedirectToEditor'
  | 'RedirectToDashboard';

export type OnBoardingProgress = 'GS' | 'AWS' | 'Templates' | 'Finish';

export const LandingSelected = 'LandingSelected';
export const GoalsSelected = 'GoalsSelected';
export const DefineSuccessSelected = 'DefineSuccessSelected';
export const PreviousBoardingState = 'PreviousBoardingState';
export const WantsExpertHelp = 'WantsExpertHelp';
export const ExpertHelpGiven = 'ExpertHelpGiven';
export const ExpertHelpRejected = 'ExpertHelpRejected';
export const AWSAccountSkipped = 'AWSAccountSkipped';
export const AWSAccountAdded = 'AWSAccountAdded';
export const RequestAdoption = 'RequestAdoption';
export const AdoptionRejected = 'AdoptionRejected';
export const AdoptionAccepted = 'AdoptionAccepted';
export const AdoptionCompleted = 'AdoptionCompleted';
export const AdoptionSkipped = 'AdoptionSkipped';
export const EndWithEditor = 'EndWithEditor';
export const EndWithDashBoard = 'EndWithDashBoard';
export const HearFromSelected = 'HearFromSelected';
export const CloudChallengesSelected = 'CloudChallengesSelected';
export const CurrentlyAccomplishSelected = 'CurrentlyAccomplishSelected';
export const CurrentCloudProviderSelected = 'CurrentCloudProviderSelected';
export const IntendToGainSelected = 'IntendToGainSelected';

/// now type all this again :(

export type LandingSelected = {
  type: typeof LandingSelected;
  payload: Array<string>;
};

export type GoalsSelected = {
  type: typeof GoalsSelected;
  payload: string;
};

export type DefineSuccessSelected = {
  type: typeof DefineSuccessSelected;
};

export type PreviousBoardingState = {
  type: typeof PreviousBoardingState;
};

export type WantsExpertHelp = {
  type: typeof WantsExpertHelp;
};

export type ExpertHelpGiven = {
  type: typeof ExpertHelpGiven;
};

export type ExpertHelpRejected = {
  type: typeof ExpertHelpRejected;
};

export type AWSAccountSkipped = {
  type: typeof AWSAccountSkipped;
};

export type AWSAccountAdded = {
  type: typeof AWSAccountAdded;
};

export type RequestAdoption = {
  type: typeof RequestAdoption;
};

export type AdoptionRejected = {
  type: typeof AdoptionRejected;
};

export type AdoptionAccepted = {
  type: typeof AdoptionAccepted;
};

export type AdoptionCompleted = {
  type: typeof AdoptionCompleted;
};

export type AdoptionSkipped = {
  type: typeof AdoptionSkipped;
};

export type EndWithEditor = {
  type: typeof EndWithEditor;
};

export type EndWithDashBoard = {
  type: typeof EndWithDashBoard;
};

export type HearFromSelected = {
  type: typeof HearFromSelected;
};

export type CloudChallengesSelected = {
  type: typeof CloudChallengesSelected;
};

export type CurrentlyAccomplishSelected = {
  type: typeof CurrentlyAccomplishSelected;
};

export type CurrentCloudProviderSelected = {
  type: typeof CurrentCloudProviderSelected;
};

export type IntendToGainSelected = {
  type: typeof IntendToGainSelected;
};

export type OnBoardingAction =
  | LandingSelected
  | GoalsSelected
  | DefineSuccessSelected
  | PreviousBoardingState
  | WantsExpertHelp
  | ExpertHelpGiven
  | ExpertHelpRejected
  | AWSAccountSkipped
  | AWSAccountAdded
  | RequestAdoption
  | AdoptionRejected
  | AdoptionAccepted
  | AdoptionCompleted
  | AdoptionSkipped
  | EndWithEditor
  | EndWithDashBoard
  | IntendToGainSelected
  | HearFromSelected
  | CurrentCloudProviderSelected
  | CurrentlyAccomplishSelected
  | CloudChallengesSelected;

export type OnBoardingState = {
  progress: OnBoardingProgress;
  current: OnBoardingPage;
  landingSelected: Array<string>;
  goalSelected: string;
  completedSteps: OnBoardingProgress[];
  adoptionSkipped: boolean;
};
