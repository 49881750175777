import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import * as React from 'react';

export class Logout extends React.Component<SecureRouteProps> {
  componentDidMount(): void {
    this.props.auth.logout('/dashboard');
  }
  componentWillUnmount() {
    localStorage.removeItem('tc_global_credentials');
    localStorage.removeItem('___TenantConfig___');
  }

  render(): React.ReactNode {
    return null;
  }
}

export default withTcApi(Logout);
