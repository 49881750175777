import { Reducer } from 'redux';
import {
  ManageAction,
  ManageState,
  RequestBatchExecution,
  RequestWorkflowList,
  RequestWorkflowReport,
  ResponseBatchExecution,
  ResponseWorkflowList,
  ResponseWorkflowReport,
} from './types';

const initialState: ManageState = {
  fetchingWorkflow: false,
  data: {},
};

export const manageReducer: Reducer<ManageState, ManageAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case RequestWorkflowList:
      return { ...state, fetchingWorkflow: true };
    case ResponseWorkflowList:
      return {
        ...state,
        fetchingWorkflow: false,
        data: action.data.reduce((A, e) => {
          A[e.workflow] = { workflow: e };
          return A;
        }, {}),
      };
    case RequestBatchExecution: {
      let data = state.data;
      for (let wfId of action.data) {
        data[wfId].executions = {
          ...data[wfId].executions,
          fetching: true,
        };
      }
      return { ...state, data };
    }

    case ResponseBatchExecution: {
      let data = state.data;
      for (let wf of action.data) {
        data[wf.workflow].executions = {
          ...data[wf.workflow].executions,
          fetching: false,
          exes:
            wf.executions ||
            (data[wf.workflow] &&
              data[wf.workflow].executions &&
              data[wf.workflow].executions.exes),
          output:
            wf.output ||
            (data[wf.workflow] &&
              data[wf.workflow].executions &&
              data[wf.workflow].executions.output),
        };
      }
      return { ...state, data };
    }

    case RequestWorkflowReport: {
      let data = state.data;
      for (let wfId of action.data) {
        data[wfId].report = {
          ...data[wfId].report,
          fetching: true,
        };
      }
      return { ...state, data };
    }
    case ResponseWorkflowReport: {
      let data = state.data;
      for (let wf of action.data) {
        data[wf.workflow].report = {
          fetching: false,
          data:
            wf.data ||
            (data[wf.workflow] &&
              data[wf.workflow].report &&
              data[wf.workflow].report.data),
        };
      }
      return { ...state, data };
    }
    default:
      return state;
  }
};
