import {
  ReceivedPortalToken,
  RequestSubscriptionStatus,
  Subscription,
  SubscriptionAction,
  SubscriptionResponse,
  SubscriptionStatus,
  TrialStatus,
  SubscriptionInfo,
} from './types';
import api from '../../../api';

export const requestSubscriptionInfo = function(): SubscriptionAction {
  return {
    type: RequestSubscriptionStatus,
  };
};

export const setSubscriptionResult = function(data: any): SubscriptionAction {
  return {
    type: SubscriptionStatus,
    data: (data as SubscriptionResponse).subscription,
  };
};

export const requestPortalToken = function(): SubscriptionAction {
  return {
    type: 'RequestPortalToken',
  };
};

export const setPortalUrlReceived = function(url: string): SubscriptionAction {
  return {
    type: ReceivedPortalToken,
    url: url,
  };
};

export function parseSubscription(
  subscription: Subscription,
): SubscriptionInfo {
  let { trial_end, status, plan_id, addons } = subscription;
  let diffTime = trial_end * 1000 - Date.now();

  let returnVal: SubscriptionInfo = {
    status: TrialStatus.Expired,
    planId: subscription.plan_id,
    planAmount: subscription.plan_amount,
  };

  if (addons) {
    for (const addon of addons) {
      returnVal.planAmount += addon.amount;
    }
  }

  if (plan_id === 'free') returnVal.status = TrialStatus.FreePlan;
  else if (status === 'active') returnVal.status = TrialStatus.PaidPlan;
  else if (status === 'in_trial') {
    let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    returnVal.status = TrialStatus.Trial;
    returnVal.daysLeft = days;
  }
  return returnVal;
}

type InfoProps = { auth: { getAccessToken: () => string; getUser: () => any } };

export const fetchSubscriptionInfo = (
  props: InfoProps & { dispatch: Function },
) => {
  api.setAuthToken(props.auth.getAccessToken);
  api.setUserGetter(props.auth.getUser);
  props.dispatch(requestSubscriptionInfo());
  api
    .getUserSubscriptionDetails()
    .then((data) => {
      return props.dispatch(setSubscriptionResult(data));
    })
    .catch((e) => {
      console.error(e);
    });
};

export const openUserPortal = (props: { dispatch: Function }) => {
  props.dispatch(requestPortalToken());
  api.initPortalSession().then(function(session) {
    const { access_url } = session;
    props.dispatch(setPortalUrlReceived(access_url));
  });
};
