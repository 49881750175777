import { withAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';
import { PageLoader } from '../common/loaders';
import * as React from 'react';

class AuthWidget extends React.Component<any, any> {
  state = { authenticated: null, userinfo: null };

  /**
   * Helper function that watches the authenticate state, then applies it
   * as a boolean (authenticated) as well as attaches the userinfo data.
   */
  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated !== this.state.authenticated) {
      if (authenticated && !this.state.userinfo) {
        const userinfo = await this.props.auth.getUser();
        this.setState({ authenticated, userinfo });
      } else {
        this.setState({ authenticated });
      }
    }
  }

  componentDidMount() {
    this.checkAuthentication().catch(console.error);
  }

  componentDidUpdate() {
    this.checkAuthentication().catch(console.error);
  }

  login() {
    //console.log(this.props.auth);
    // this.props.auth.login('/dashboard');
    // this.props.history.push('/signin/register');
    this.props.history.push('/signup');
  }

  render() {
    if (this.state.authenticated === null) {
      return <PageLoader />;
    } else if (this.state.authenticated) {
      return <Redirect to="/dashboard" />;
    } else {
      this.login();
      return null;
    }
  }
}

export default withAuth(AuthWidget);
