import { events } from './events';
import { track } from './analytics';
import { BASE_URL } from '../config/api';
import uuid from 'uuid';

export function captureUIEvents() {
  const querySelector = document.querySelector('body');
  querySelector &&
    querySelector.addEventListener('click', captureAnalyticsEvents);
}

const captureAnalyticsEvents: EventListener = (event) => {
  if (!event.target) return;
  // @ts-ignore
  let knownParent = event.target.closest('.tc-ala');
  if (knownParent) {
    //  let className = knownParent.className;
    let id = knownParent.className
      .split(' ')
      .find((x: string) => x.startsWith('tc-ala-'));
    // alert(id);
    track(elements[id], null);
  }
};

export const registerAnalytics = () => {
  if (localStorage.getItem('analyticUserId')) {
    return;
  }
  const generateUuid = uuid();
  localStorage.setItem('analyticUserId', generateUuid);
};

export const observe = (promise, eventName) => {
  return Promise.resolve(promise)
    .then((args) => {
      if (args.errorCauses) {
        throw args;
      } else {
        track(`${eventName}_SUCCESS`, null);
        return args;
      }
    })
    .catch((err) => {
      track(`${eventName}_FAILURE`, null);
      throw err;
    });
};

export const captureEvent = (func, eventName) => {
  return function() {
    track(`${eventName}_CLICKED`, null);
    return func();
  };
};

const elements: { [s: string]: string } = {
  'tc-ala-save-wf': events.SAVE_WORKFLOW_CLICKED,
  'tc-ala-createwfbtn': events.CREATE_NEW_WORKFLOW_CLICKED,
  'tc-ala-ta_et_new_blank': events.CHOSEN_TO_CREATE_FROM_SCRATCH,
  'tc-ala-ta_et_new_select_tp': events.CHOSEN_TO_USE_TEMPLATES,
  'tc-ala-tm-init-choice-button': events.INITIAL_CHOICE_COMPLETED,
  // 'tc-ala-ta_et_nav_active_toggle' : events.WORKFLOW_TOGGLE_CLICKED,
  'tc-ala-custom-toggle-wf': events.WORKFLOW_TOGGLE_CLICKED,
  'tc-ala-checked-keep-login': events.CHECKED_KEEP_ME_LOGIN,
  'tc-ala-unchecked-keep-login': events.UNCHECKED_KEEP_ME_LOGIN,
};

export function captureAPIResponse(
  url: string,
  responseData: any,
  method: string,
) {
  try {
    return captureAPIResponseUnSafe(url, responseData, method);
  } catch (e) {
    console.error(e);
  }
}

export function captureAPIResponseUnSafe(
  url: string,
  responseData: any,
  method: string,
): void {
  // let keyString = responseData.config.method+' '+responseData.config.url;
  let keyString = method + ' ' + url;

  let event = apis.get(keyString);
  // let data =
  //   responseData.data.response.data
  //     ? responseData.data.response.data[apiDataKey.get(keyString)]
  //     : responseData.data.response[apiDataKey.get(keyString)];
  // @ts-ignore
  let data = responseData[apiDataKey.get(keyString)];

  if (event) {
    track(event, { data });
  }
}

export function captureAPIError(url: string, error: any, method: string): void {
  // let keyString = error.config.method+' '+error.config.url;
  let keyString = method + ' ' + url;
  let event = apis.get(keyString);

  if (event) {
    track(event + '_error', null);
  }
}

const apis = new Map([
  [`post ${BASE_URL}/workflows`, events.WORKFLOW_CREATED],
  [`put ${BASE_URL}/workflows`, events.WORKFLOW_UPDATED],
  [`post ${BASE_URL}/validate-creds`, events.SYNC_VALIDATION],
]);

const apiDataKey = new Map([
  [`post ${BASE_URL}/workflows`, 'workflow'],
  [`put ${BASE_URL}/workflows`, 'workflow'],
]);

//wf/exe id - /[a-z 0-9]{8}$/
