import { GroupWorkflowListResult } from '../../lib/store/parking/type';
import Moment from 'moment';
import {
  pairSchedules,
  getTimeZoneWithAbbr,
  expressionParser,
  numberOfDaysScheduleRuns,
  convertTimeFromCustomTimezoneToUTCTime,
  convertUTCTimetoCustomTimeZoneTime,
} from './helper';
import { WORKFLOW_ACTION } from 'workflow-model/dist/types/IGroupWorkflowModel';
import { GroupHistory } from 'workflow-model/dist';

export function getAggregatedSummary(groups: GroupWorkflowListResult[]) {
  let actualCost = 0,
    scheduledCost = 0;
  for (const group of groups) {
    actualCost += Number(group.actual || '0');
    scheduledCost += Number(group.cost || '0');
  }

  return {
    actual: actualCost.toFixed(0),
    cost: scheduledCost.toFixed(0),
    savings: (actualCost - scheduledCost).toFixed(0),
    savingPercentage: (
      ((actualCost - scheduledCost) / actualCost) *
      100
    ).toFixed(0),
  };
}

export function getLatestModifiedSchedule(groups: GroupWorkflowListResult[]) {
  let modified: GroupWorkflowListResult;
  for (const group of groups) {
    if (!modified) {
      modified = group;
    } else {
      if (group.lastModified > modified.lastModified) {
        modified = group;
      }
    }
  }
  return modified;
}
export function formatSchedule(
  schedules: {
    start: string[];
    stop: string[];
  },
  timezone,
): { week: string; more: number; time: string; extraSchedules: any[] } {
  if (!schedules.start.length && !schedules.stop.length)
    return { week: 'Not Scheduled', time: '', more: 0, extraSchedules: [] };
  const pairs = pairSchedules({
    startSchedules: schedules.start,
    endSchedules: schedules.stop,
  });
  if (!pairs.length)
    return { week: 'Not Scheduled', time: '', more: 0, extraSchedules: [] };
  const p0 = pairs[0];
  const pos = p0.start.split(' ');
  const poe = p0.end.split(' ');
  const startWeek = pos[4].split('-').join(' - ');
  const startHour = numPad(pos[1])
    .toString()
    .slice(-2);
  const stopHour = numPad(poe[1])
    .toString()
    .slice(-2);
  const startMinute = numPad(pos[0])
    .toString()
    .slice(-2);
  const stopMinute = numPad(poe[0])
    .toString()
    .slice(-2);
  let timeZoneDetails = '';
  if (timezone) {
    timeZoneDetails = getTimeZoneWithAbbr(timezone);
  }
  let extraSchedules = [];
  if (pairs.length > 1) {
    pairs.forEach((pair) => {
      extraSchedules.push(expressionParser(pair.start, pair.end));
    });
  }
  return {
    week: `${startWeek}`,
    time: `${
      timezone
        ? convertUTCTimetoCustomTimeZoneTime(
            `${startHour}:${startMinute}`,
            timezone,
          )
        : `${startHour}:${startMinute}`
    }- ${
      timezone
        ? convertUTCTimetoCustomTimeZoneTime(
            `${stopHour}:${stopMinute}`,
            timezone,
          )
        : `${stopHour}:${stopMinute}`
    } ${timeZoneDetails && `(UTC ${timeZoneDetails})`}`,
    more: pairs.length - 1,
    extraSchedules: extraSchedules.slice(1),
  };
}

export function numPad(n: string) {
  return Number(n) > 10 ? n : '0' + n;
}

export function formatTime(time: number) {
  const date = Moment.utc(time);
  return date.format('ddd, hh:mm a');
}

export function getSavings(group: GroupWorkflowListResult) {
  if (!group.savings) return '--';
  return (
    '$' +
    (Number(group.actual || '0') - Number(group.cost || '0')).toFixed(0) +
    ` (${group.savings})`
  );
}

export function getSavingReason(group) {
  if (!group.savings) return { Before: null, After: null };
  const numberOfDaysRuns = numberOfDaysScheduleRuns(group);
  const costPerHour: number = group.resources.reduce(
    (accum, resource) => accum + resource.rate,
    0,
  );
  const numberOfHours: number = group.cost / (costPerHour * numberOfDaysRuns);
  return {
    Before: `No. of hours(24)  X No. of Days(30) X Cost of resource per hour(${costPerHour})`,
    After: `No. of hours(${numberOfHours.toFixed()}) X No. of Days(${numberOfDaysRuns}) X Cost of resource per hour(${costPerHour})`,
  };
}

export function formatLastModifiedTime(t: number | null) {
  if (!t) return 'Never';
  return Moment.utc(t).fromNow();
}

export function getHistoryHeading(
  h: GroupHistory,
): { time: string; status: string; timestamp: string } {
  let status = '';
  if (h.status.toLowerCase() === 'success') {
    if (h.action === WORKFLOW_ACTION.STOP) status = 'Parked';
    else if (h.action === WORKFLOW_ACTION.START) status = 'UnParked';
    else if (h.action === WORKFLOW_ACTION.SMART_SCHEDULE)
      status = 'Parked by Smart Schedule';
    else status = h.status;
  } else status = h.status;
  const timestamp = h.timestamp;
  const time = Moment.utc(h.startDate).format('DD MMM, ddd, hh:mm a');
  return { status, timestamp, time };
}

export function num2(n: Number) {
  return n > 9 ? n.toString() : '0' + n.toString();
}

export function extractPrimaryKey(resource, rType: string) {
  const prefix = rType ? rType.split(' ').reverse()[0] : '';
  const firstIdKey = Object.keys(resource).find((key) => {
    return (
      key.includes(prefix) &&
      key.includes('Id') &&
      typeof resource[key] === 'string'
    );
  });
  return resource[firstIdKey];
}

export function extractName(resource, rType) {
  const tcProperties = resource.tcProperties;
  let name = '';
  const tags = tcProperties && tcProperties.tags;
  if (tags) {
    const nameTag = tags.find((t) => t.startsWith('Name'));
    if (nameTag) {
      name = nameTag.replace('Name=', '');
    }
  }
  if (!name) {
    name = extractPrimaryKey(resource, rType);
  }
  return name;
}

export function mergeSchedules(
  schedules: {
    [s: string]: {
      start: { hour: number; minute: number };
      stop: { hour: number; minute: number };
    }[];
  }[],
) {
  const combined: {
    [s: string]: {
      start: { hour: number; minute: number };
      stop: { hour: number; minute: number };
    }[];
  } = {};

  for (const schedule of schedules) {
    for (const key of Object.keys(schedule)) {
      if (!combined[key]) combined[key] = [];
      combined[key] = [...combined[key], ...schedule[key]];
    }
  }

  return combined;
}

export function mergeSavings(values: string[]) {
  values = values.filter((v) => v);
  const nums = values.map((v) => Number(v.replace('%', '').trim()));
  if (nums.length) {
    let average: number = nums.reduce((s, r) => s + r, 0) / nums.length;
    return `${average.toFixed(0)}%`;
  } else {
    return null;
  }
}
