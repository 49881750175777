// events
export const ToggleSideBar = 'ToggleSideBar';

interface ToggleSideBar {
  type: typeof ToggleSideBar;
}

export type SideBarAction = ToggleSideBar;

export interface SideBarState {
  isOpen: boolean;
}
