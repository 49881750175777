import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './assets/css/index.css';
import ErrorBoundary from './ErrorBoundary';
import './lib/login/index.scss';
import { captureUIEvents } from './analytics/eventCapturer';
import { initiateHelpHero } from './heroHelp/heroHelp';
import * as Sentry from '@sentry/browser';
import { version } from './release';
import { SentryDSN, SentryEnv } from './config/resources.json';
// load();

if (!(process && process.env && process.env.CI)) {
  // telemetry
  Sentry.init({
    dsn: SentryDSN,
    environment: SentryEnv,
    release: version,
  });
}

const ErrorCatcherWrappedApp = (): any => {
  return (
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  );
};

ReactDOM.render(
  <ErrorCatcherWrappedApp />,
  document.getElementById('root') as HTMLElement,
);

// ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
// registerServiceWorker();

captureUIEvents();
// initiateHelpHero();
