import { TAB_NAME } from 'webapp-genric/dist/constants';

export const shouldValidateTemplates = (tabName: TAB_NAME) => {
  return (
    tabName === TAB_NAME.INVENTORY ||
    tabName === TAB_NAME.UTILISATION ||
    tabName === TAB_NAME.INSIGHTS ||
    tabName === TAB_NAME.EVENTS
  );
};

export const shouldAdoptTemplates = (tabName: TAB_NAME) => {
  if (
    tabName === TAB_NAME.INSIGHTS ||
    tabName === TAB_NAME.UTILISATION ||
    tabName === TAB_NAME.INVENTORY
  )
    return true;
  return false;
};

export const shouldRenderTriggerOptions = (tabName: TAB_NAME) => {
  if (
    tabName === TAB_NAME.INSIGHTS ||
    tabName === TAB_NAME.UTILISATION ||
    tabName === TAB_NAME.INVENTORY ||
    tabName === TAB_NAME.INVENTORY_GLOBAL
  )
    return true;
  return false;
};

export const shouldHideSubCategory = (tabName: TAB_NAME) => {
  if (tabName === TAB_NAME.INVENTORY) return true;
};
