import { TableItem, TemplateColNames } from '../cols';
import * as React from 'react';
import { StyledCustomDropDown, SwitchStatus } from '../../../workflows/style';
import { loader } from './loader';
import SwitchContainer from '../../../../lib/switch/SwitchContainer';

const Workflow_OPTIONS = [
  {
    label: (
      <>
        <i className={'fa fa-edit mr-1'} />{' '}
        <span className="test-edit-button">Edit</span>
      </>
    ),
    value: 'edit',
  },
  {
    label: (
      <>
        <i className={'fa fa-edit mr-1'} />{' '}
        <span className="test-edit-button">Add template test category </span>
      </>
    ),
    value: 'add-test-category',
  },
  {
    label: (
      <>
        <i className={'fa fa-edit mr-1'} />{' '}
        <span className="test-edit-button">Remove template test category </span>
      </>
    ),
    value: 'remove-test-category',
  },
  {
    label: (
      <>
        <i className={'fa fa-info-circle mr-1'} />{' '}
        <span className="test-exec-details">Execution Details</span>
      </>
    ),
    value: 'execution-details',
  },
];

const customDropDownFooter = (item: TableItem) => {
  const { workflowItem } = item;
  const active = workflowItem.properties
    ? workflowItem.properties.active
    : false;
  return (
    <div className="d-flex justify-content-between align-items-center text-uppercase">
      <SwitchStatus>Stage</SwitchStatus>
      <div className="px-2">
        <SwitchContainer
          data-test="data-switch"
          value={active}
          onChange={(value) =>
            item.functions.toggleWorkflowStatus({
              workflow: item.workflowItem,
              col: TemplateColNames.WORKFLOW_OPT,
            })
          }
        />
      </div>
      <SwitchStatus>Deploy</SwitchStatus>
    </div>
  );
};

const onChangeWfOption = (option: any, item: TableItem) => {
  const workflow = item.workflowItem;
  switch (option) {
    case 'edit':
      window.open(`${window.location.origin}/editor/${workflow.workflow}`);
      return;
    case 'execution-details':
      window.open(
        `${window.location.origin}/workflow/${workflow.workflow}/details`,
      );
    case 'add-test-category':
      return item.functions.addTemplateTestCategory(item.workflowItem);
    case 'remove-test-category':
      return item.functions.removeTemplateTestCategory(item.workflowItem);

    default:
      return;
  }
};
export const WorkflowOptions = (name: string, item: TableItem) => {
  if (item.updating === TemplateColNames.WORKFLOW_OPT) return loader();
  return (
    <StyledCustomDropDown
      className="test-options-component"
      name="sort-workflow"
      value={''}
      options={Workflow_OPTIONS}
      title={'Options'}
      onChange={(option) => onChangeWfOption(option, item)}
      right
      customDropDownFooter={customDropDownFooter(item)}
    />
  );
};
