import {
  OktaHostname,
  OktaClientId,
  OktaRegistrationToken,
  OktaTenantId,
} from './resources.json';

/// always load config
class TenantConfig {
  private _oauth_url: string = '';
  private _client_id: string = '';
  private _registrationToken: string = '';
  private _tenantId: string = '';

  public scope: string = 'openid profile email';

  get issuer(): string {
    return `${this.oauth_url}/oauth2/default`;
  }

  get client_id(): string {
    return this._client_id;
  }

  get oauth_url(): string {
    return this._oauth_url;
  }

  get registrationToken(): string {
    return this._registrationToken;
  }

  set registrationToken(value: string) {
    this._registrationToken = value;
  }

  set oauth_url(value: string) {
    this._oauth_url = value;
  }

  set tenantId(value: string) {
    this._tenantId = value;
  }

  set client_id(value: string) {
    this._client_id = value;
  }

  get redirect_uri(): string {
    // return window.location.origin + '/' + this._tenantId + '/implicit/callback';
    return window.location.origin + '/implicit/callback';
  }

  public REACT_APP_BASE_URL: string = OktaHostname;

  get REACT_APP_ISSUER(): string {
    return this.oauth_url + '/oauth2/default';
  }

  get REACT_APP_FORGOT_PASSWORD_URL(): string {
    return this.oauth_url + '/api/v1/authn/recovery/password';
  }

  get REACT_APP_RECOVERY_TOKEN_VERIFY_URL(): string {
    return this.oauth_url + '/api/v1/authn/recovery/token';
  }

  get REACT_APP_RESET_PASSWORD_URL(): string {
    return this.oauth_url + '/api/v1/authn/credentials/reset_password';
  }

  get REACT_APP_RECOVERY_PASSWORD_URL(): string {
    return this.oauth_url + '/api/v1/authn/recovery/token';
  }

  public REACT_APP_SEND_ACTIVATION_EMAIL: string = ``;

  get REACT_APP_SIGNUP_URL(): string {
    return (
      this.oauth_url +
      '/api/v1/registration/' +
      this.registrationToken +
      '/register'
    );
  }

  get REACT_APP_CREATE_USER(): string {
    return this.oauth_url + '/api/v1/users?activate=false';
  }

  get REACT_APP_CLIENTID(): string {
    return this.client_id;
  }

  persist() {
    const location: string = '___TenantConfig___';
    try {
      const self = JSON.stringify({
        oauth_url: this.oauth_url,
        client_id: this.client_id,
        registrationToken: this.registrationToken,
        tenantId: this._tenantId,
      });
      window.localStorage.setItem(location, self);
    } catch (e) {
      console.error(e);
    }
  }
}

const location: string = '___TenantConfig___';
const config = new TenantConfig();
try {
  const stored = window.localStorage.getItem(location);
  if (stored) {
    const { oauth_url, client_id, registrationToken, tenantId } = JSON.parse(
      stored,
    );
    if (tenantId) {
      config.tenantId = tenantId;
    }
    if (oauth_url) {
      config.oauth_url = oauth_url;
    }
    if (client_id) {
      config.client_id = client_id;
    }
    if (registrationToken) {
      config.registrationToken = registrationToken;
    }
    // remove only on logout
    // window.localStorage.removeItem(location);
  }
} catch (e) {
  console.error(e);
}

export const loadDefault = function() {
  config.oauth_url = OktaHostname;
  config.client_id = OktaClientId;
  config.registrationToken = OktaRegistrationToken;
  config.tenantId = OktaTenantId;
};

// ensure config is always present while loading dashboard
export const ensureConfigIsLoaded = function() {
  if (!config.oauth_url) {
    loadDefault();
  }
};

// console.log('config', config);

export const OktaConfig = config;
export default config;
