import React from 'react';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  getGroupWorkflowDetails,
  runTriggerWorkflow,
  toggleWorkflowActive,
} from '../../../lib/store/workflows/action';
import {
  IWorkflow,
  NODE_TYPES,
  ScheduleType,
  TriggerNodeData,
  Workflow,
} from 'workflow-model/dist';
import { connect } from 'react-redux';
import * as WorkflowType from '../../../lib/store/workflows/types';
import { viewType } from './workflowReportForm';
import { Formik, FormikActions, FormikProps } from 'formik';
import { TriggerWorkflowForm } from './WorkflowsTriggerForm';
import { TriggerNode } from 'workflow-model/dist/nodes';
import {
  TriggerSchema,
  triggerSchema,
} from '../../../lib/editor/components/ui/nodeUI/trigger/schema';
import { getTriggerWorkflowConfigByTabName } from 'webapp-genric/dist/genricWorkflowsConfig';
import { CronSchedule, ParsedCronSchedule, RateSchedule } from 'workflow-model';
import _ from 'lodash';
import {
  convertCombinedToParsed,
  convertParsedToCombinedCon,
} from './cornTypeConverter';
import { tr } from 'cronstrue/dist/i18n/locales/tr';

interface StateProps {
  isFetchingGroup: boolean;
  triggerWorkflow: WorkflowType.Workflow;
  workflowModel: Workflow;
}

interface DispatchProps {
  getWorkflow: (params: { group: string; workflow: string }) => void;
  toggleTriggerWorkflowState: (workflowId: WorkflowType.Workflow) => void;
  runTriggerWorkflow: (workflowId: string) => void;
}

interface OwnProps {
  user: string;
  tabName: TAB_NAME;
  closeModal: () => void;
  sendReportNow: (workflow: IWorkflow) => void;
  saveTriggerWorkflow: (params: { workflow: IWorkflow; group: string }) => void;
  reportingAction: boolean;
}

type IProps = OwnProps & DispatchProps & StateProps;

interface IState {
  view: viewType;
  workflowModel: Workflow | null;
}

class TriggerOptionsInner extends React.Component<IProps, IState> {
  state: IState = {
    view: viewType.LATER,
    workflowModel: null,
  };

  fetchWorkflow = () => {
    let triggerConfig = getTriggerWorkflowConfigByTabName(this.props.tabName);

    if (
      this.props.triggerWorkflow &&
      !this.props.isFetchingGroup &&
      !this.props.triggerWorkflow.definition &&
      triggerConfig
    ) {
      this.props.getWorkflow({
        group: triggerConfig.group,
        workflow: this.props.triggerWorkflow.workflow,
      });
    }

    if (this.props.workflowModel && !this.state.workflowModel) {
      this.setState({ workflowModel: this.props.workflowModel });
    }
  };

  componentDidMount(): void {
    this.fetchWorkflow();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    this.fetchWorkflow();
  }

  toggleRadio = (event) => {
    this.setState({ view: event.target.value });
  };
  handleSubmit = (
    values: TriggerSchema,
    actions: FormikActions<TriggerSchema>,
  ) => {
    let usedValues = _.cloneDeep(values);
    let { view } = this.state;
    let triggerNode: TriggerNode = this.state.workflowModel.getNodesByType(
      NODE_TYPES.TRIGGER,
    )[0] as TriggerNode;
    //This will prevent updating  @param values as it is passed as a reference here.
    usedValues.schedule.active = true;
    triggerNode.setNodeData(
      triggerNode.serialize(
        convertCombinedToParsed(usedValues) as TriggerNodeData,
      ),
    );

    if (view === viewType.NOW) {
      // console.log('will dry run ');
      this.props.runTriggerWorkflow(this.state.workflowModel.getId());
    } else {
      // console.log('will save');
      let triggerConfig = getTriggerWorkflowConfigByTabName(this.props.tabName);
      if (!triggerConfig) return;
      this.props.saveTriggerWorkflow({
        workflow: this.state.workflowModel,
        group: triggerConfig.group,
      });
    }
    actions.setSubmitting(false);
  };

  getDefaultValues = (): TriggerSchema => {
    if (this.state.workflowModel) {
      let triggerNode: TriggerNode = this.state.workflowModel.getNodesByType(
        NODE_TYPES.TRIGGER,
      )[0] as TriggerNode;
      let data = triggerNode.deserialize();
      return convertParsedToCombinedCon(data) as TriggerSchema;
    }
  };

  toggleActiveHandler = (value: boolean) => {
    this.props.toggleTriggerWorkflowState(this.props.triggerWorkflow);
  };

  render() {
    let initialValues: TriggerSchema = this.getDefaultValues();
    const render = (props: FormikProps<TriggerSchema>) => {
      return (
        <TriggerWorkflowForm
          {...props}
          {...this.props}
          renderActiveToggle={true}
          toggleActiveHandler={this.toggleActiveHandler}
          toggleActiveValue={this.props.triggerWorkflow?.properties?.active}
          onClose={this.props.closeModal}
          toggleRadio={this.toggleRadio}
          view={this.state.view}
          isFetching={this.props.isFetchingGroup}
          initialValues={this.getDefaultValues()}
        />
      );
    };
    return (
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={render}
          validateOnChange={false}
          validateOnBlur={true}
          validationSchema={triggerSchema}
        />
      </>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  let workflowModel = null;
  let triggerConfig = getTriggerWorkflowConfigByTabName(ownProps.tabName);
  let triggerWorkflow: WorkflowType.Workflow;
  if (triggerConfig) {
    let groupWfs = state.workflows.groups[triggerConfig.group];
    triggerWorkflow =
      groupWfs &&
      groupWfs.data &&
      groupWfs.data.find((x) => x.name === triggerConfig.name);
  }
  if (triggerWorkflow && triggerWorkflow.definition) {
    workflowModel = new Workflow({
      isModelDirty: true,
      ...triggerWorkflow,
      user: ownProps.user,
    });
  }
  return {
    isFetchingGroup:
      triggerConfig && state.workflows.groups[triggerConfig.group]
        ? state.workflows.groups[triggerConfig.group].isFetching
        : false,
    triggerWorkflow,
    workflowModel,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    getWorkflow: ({ group, workflow }) =>
      getGroupWorkflowDetails({ dispatch, group, workflow }),
    runTriggerWorkflow: (workflow) =>
      runTriggerWorkflow({ dispatch, workflow }),
    toggleTriggerWorkflowState: (workflow) =>
      toggleWorkflowActive({ dispatch, workflow }),
  };
}

export const TriggerWorkflowOptions = connect<
  StateProps,
  DispatchProps,
  OwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(TriggerOptionsInner);
