export enum Period {
  MONTH = 2592000,
  DAY = 86400,
  WEEK = 604800,
  QUARTER = 7776000,
  HOUR = 3600,
  SEC = 60,
  YEAR = 31356000,
}

export const periodMap: { [p in Period]: string } = {
  [Period.MONTH]: '1m',
  [Period.HOUR]: '1h',
  [Period.DAY]: '1d',
  [Period.WEEK]: '1w',
  [Period.SEC]: '1s',
  [Period.QUARTER]: '1q',
  [Period.YEAR]: '1y',
};
