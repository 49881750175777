import * as React from 'react';
import {
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from 'reactstrap';
import classnames from 'classnames';

import { JSONSection } from './JsonSection';
import { JsonSectionDataType } from './ModalWrapper';
import CodeEditor from '../../../../formInputs/codeEditor.component';

interface IProps {
  helpText: React.ReactNode;
  jsonData?: JsonSectionDataType;
  rawJsonData?: string;
}

interface IState {
  activeTab: string;
}

export default class HelpSection extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };
  }

  toggle = (activeTab) => {
    if (this.state.activeTab !== activeTab) this.setState({ activeTab });
  };

  render(): React.ReactNode {
    let { activeTab } = this.state;
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                this.toggle('1');
              }}
            >
              Info
            </NavLink>
          </NavItem>
          {(this.props.jsonData || this.props.rawJsonData) && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  this.toggle('2');
                }}
              >
                JSON
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col className={classnames('help-body')}>
                <div>{this.props.helpText}</div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            {this.props.jsonData && (
              <JSONSection jsonData={this.props.jsonData} />
            )}
            {!this.props.jsonData && this.props.rawJsonData && (
              <CodeEditor
                value={{ data: this.props.rawJsonData || '{}' }}
                name={'node-json'}
                readOnly={true}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
