import * as React from 'react';
import * as Sentry from '@sentry/browser';

const _console_error = console.error;
console.error = function() {
  Sentry.captureException.apply(Sentry, arguments);
  _console_error.apply(console, arguments);
};

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, info);
    console.error(error);
    let eventId = Sentry.captureException(Object.assign({}, error, info));
    Sentry.showReportDialog({ eventId: eventId });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <h1>Something went wrong.</h1>;
      return (
        <div
          style={{
            fontFamily: 'proxima-nova,sans-serif',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <h1
            style={{
              fontSize: '28px',
              textAlign: 'center',
            }}
          >
            Oops! Something went wrong!
          </h1>
          <br />
          <p
            style={{
              fontSize: '20px',
              textAlign: 'center',
            }}
          >
            We've taken a note of this. Please try refreshing the page. If the
            problem persists, please reach out to us at support@totalcloud.io or
            by clicking the chat icon on the bottom right hand side.
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}
