import * as React from 'react';
import Dashboard from '../../../deprecatedDashBoardComponents';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import {
  OnBoardingPage,
  OnBoardingState,
} from '../../../../lib/store/onboarding/types';
import { SecureRouteProps } from '../../../../api/tcApiHelper';
import { Redirect } from 'react-router';
import { useState } from 'react';
import { onBoardingQuestions } from '../../common/questions';
import { OnBoardingQuestionView } from './OnBoardingQuestionView';
import api from '../../../../api';
import { setOnBoardingState } from '../../../../lib/store/onboarding/localStore';
import { recordAdoptionFinishSelected } from '../../../../lib/store/onboarding/actions';

interface OnBoardingV2Props extends SecureRouteProps {}

export const next = (current: OnBoardingPage): OnBoardingPage => {
  if (current === 'HearFrom') return 'CloudChallenges';
  if (current === 'CloudChallenges') return 'CurrentlyAccomplish';
  if (current === 'CurrentlyAccomplish') return 'CurrentCloudProvider';
  if (current === 'CurrentCloudProvider') return 'IntendToGain';
  if (current === 'IntendToGain') return 'RedirectToDashboard';
  return 'HearFrom';
};

export const back = (current: OnBoardingPage): OnBoardingPage => {
  if (current === 'CloudChallenges') return 'HearFrom';
  if (current === 'CurrentlyAccomplish') return 'CloudChallenges';
  if (current === 'CurrentCloudProvider') return 'CurrentlyAccomplish';
  if (current === 'IntendToGain') return 'CurrentCloudProvider';
  return 'HearFrom';
};

export const OnBoardingV2 = (props: OnBoardingV2Props) => {
  const [current, setCurrent] = useState<OnBoardingPage>('HearFrom');
  const [now, setNow] = useState<number>(1);
  const [answers, setAnswers] = useState<{ [k: string]: string[] }>({});

  const onPressBack = () => {
    setCurrent(back(current));
    setNow(now - 1);
  };

  const onSelectAnswer = (answer: string[]) => {
    setAnswers({ ...answers, [current]: answer });
    api.setMarketingInfo(current, answer.join(', '));

    if (current === 'IntendToGain') {
      const onBoardingState: OnBoardingState = {
        current: next(current),
        adoptionSkipped: true,
        completedSteps: ['GS'],
        goalSelected: answer.join(', '),
        landingSelected: answer,
        progress: 'Finish',
      };

      recordAdoptionFinishSelected();
      setOnBoardingState(onBoardingState);
    }

    setCurrent(next(current));
    setNow(now + 1);
  };

  const currentQuestion = onBoardingQuestions[current];

  if (current === 'RedirectToDashboard') return <Redirect to={'/dashboard'} />;

  return (
    <React.Fragment>
      <Dashboard />
      <Modal
        size={'xl'}
        isOpen={true}
        backdrop="static"
        centered={true}
        className="color-onBoarding-background p-0"
      >
        <ModalBody>
          <Row className={'px-2 pt-4'}>
            <Col
              md={'12'}
              className={'pb-2 d-flex w-100 justify-content-center'}
            >
              <h5 className="font-18">
                Tell us more about you so we can customise what you see!
              </h5>
            </Col>
            <Col md={12} className=" my-auto pt-3">
              <OnBoardingQuestionView
                now={now}
                total={5}
                {...props}
                answers={answers[current]}
                currentQuestion={currentQuestion}
                onSelectAnswer={onSelectAnswer}
              />
            </Col>

            {current !== 'HearFrom' && (
              <div className="position-absolute">
                <div
                  className="font-14 custom-blue text-underline cursor-pointer"
                  onClick={onPressBack}
                >
                  <span>
                    <i className="fa fa-long-arrow-left" />
                  </span>
                  &nbsp; Back
                </div>
              </div>
            )}
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
