export const SGFILTER_CONFIG = [
  {
    value: 'any',
    protocol: 'ANY',
    port: '<input>',
    label: 'Any',
  },
  {
    value: 'customtcp',
    protocol: 'TCP',
    port: '<input>',
    label: 'Custom TCP Rule',
  },
  {
    value: 'customudp',
    protocol: 'UDP',
    port: '<input>',
    label: 'Custom UDP Rule',
  },
  {
    value: 'customicmpv6',
    protocol: 'ICMPV6',
    port: '0-65535',
    label: 'Custom ICMP Rule - IPv6',
  },
  {
    value: 'alltcp',
    protocol: 'TCP',
    port: '0-65535',
    label: 'All TCP',
  },
  {
    value: 'alludp',
    protocol: 'UDP',
    port: '0-65535',
    label: 'All UDP',
  },
  {
    value: 'allicmp',
    protocol: 'ICMP',
    port: '0-65535',
    label: 'All ICMP - IPv4',
  },
  {
    value: 'allicmpv6',
    protocol: 'ICMPV6',
    port: '0-65535',
    label: 'All ICMP - IPv6',
  },
  {
    value: '-1',
    protocol: 'ALL',
    port: '0-65535',
    label: 'All traffic',
  },
  {
    value: 'ssh',
    protocol: 'TCP',
    port: '22',
    label: 'SSH',
  },
  {
    value: 'smtp',
    protocol: 'TCP',
    port: '25',
    label: 'SMTP',
  },
  {
    value: 'dns-udp',
    protocol: 'UDP',
    port: '53',
    label: 'DNS (UDP)',
  },
  {
    value: 'dns-tcp',
    protocol: 'TCP',
    port: '53',
    label: 'DNS (TCP)',
  },
  {
    value: 'http',
    protocol: 'TCP',
    port: '80',
    label: 'HTTP',
  },
  {
    value: 'pop3',
    protocol: 'TCP',
    port: '110',
    label: 'POP3',
  },
  {
    value: 'imap',
    protocol: 'TCP',
    port: '143',
    label: 'IMAP',
  },
  {
    value: 'ldap',
    protocol: 'TCP',
    port: '389',
    label: 'LDAP',
  },
  {
    value: 'https',
    protocol: 'TCP',
    port: '443',
    label: 'HTTPS',
  },
  {
    value: 'smb',
    protocol: 'TCP',
    port: '445',
    label: 'SMB',
  },
  {
    value: 'smtps',
    protocol: 'TCP',
    port: '465',
    label: 'SMTPS',
  },
  {
    value: 'imaps',
    protocol: 'TCP',
    port: '993',
    label: 'IMAPS',
  },
  {
    value: 'pop3s',
    protocol: 'TCP',
    port: '995',
    label: 'POP3S',
  },
  {
    value: 'mssql',
    protocol: 'TCP',
    port: '1433',
    label: 'MS SQL',
  },
  {
    value: 'nfs',
    protocol: 'TCP',
    port: '2049',
    label: 'NFS',
  },
  {
    value: 'mysql',
    protocol: 'TCP',
    port: '3306',
    label: 'MYSQL/Aurora',
  },
  {
    value: 'rdp',
    protocol: 'TCP',
    port: '3389',
    label: 'RDP',
  },
  {
    value: 'redshift',
    protocol: 'TCP',
    port: '5439',
    label: 'Redshift',
  },
  {
    value: 'postgreSQL',
    protocol: 'TCP',
    port: '5432',
    label: 'PostgreSQL',
  },
  {
    value: 'oracle-rds',
    protocol: 'TCP',
    port: '1521',
    label: 'Oracle-RDS',
  },
  {
    value: 'winrm-http',
    protocol: 'TCP',
    port: '5985',
    label: 'WinRM-HTTP',
  },
  {
    value: 'winrm-https',
    protocol: 'TCP',
    port: '5986',
    label: 'WinRM-HTTPS',
  },
  {
    value: 'egpu',
    protocol: 'TCP',
    port: '2007',
    label: 'Elastic Graphics',
  },
];
