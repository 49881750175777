import { identifyHeroUser } from '../heroHelp/heroHelp';
import axios from 'axios';
import querystring from 'querystring';
import retry from 'async-retry';
import * as Sentry from '@sentry/browser';
import { identify } from '../analytics/analytics';
import {
  captureAPIError,
  captureAPIResponse,
} from '../analytics/eventCapturer';
import { BASE_URL, BaseUriExtras, BaseUriGroup3 } from '../config/api';
import { IApi, NodeType, TargetType } from 'workflow-model/dist';
import { Template } from '../lib/store/templates/types';
import { TemplatesToAdopt } from '../components/common/AutoAdoptTemplatesV2';
import { IUserNode } from 'workflow-model/dist/types/IUserNode';
import { sgcS3Bucket } from '../config/resources.json';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';

type ApiMethodType = 'list' | 'get' | 'post' | 'put' | 'delete';

export interface CloneWorkflowParams {
  workflow: string;
  configs: string[];
  regions: string[];
  resourceGroups: string[];
}

type HttpParameter<T extends string = undefined> = {
  [Key in T]: string;
};

class Api implements IApi {
  constructor() {
    this.marketingInfo = {};
  }

  authTokenGetter!: () => string;

  authUserGetter!: () => Promise<any>;

  marketingInfo: {
    [s: string]: any;
  };

  setMarketingInfo(key: string, data: any) {
    this.marketingInfo = { ...this.marketingInfo, [key]: data };
    this.updateUserIdentity().catch(console.error);
  }

  static makeUrl(endPoint: string, extra?: boolean) {
    if (extra) {
      return `${BaseUriExtras}${endPoint}`;
    }
    return `${BASE_URL}${endPoint}`;
  }

  static makeAzureUrl(path: string) {
    return `https://tc-sdk-resources.totalcloud.io/${path}`;
  }

  static makeSGCUrl(path: string) {
    return `https://${sgcS3Bucket}.s3.amazonaws.com/${path}`;
  }
  static makeK8Url(path: string) {
    return `https://k8.totalcloud.io/${path}`;
  }

  static makeGroup3Url(endPoint: string) {
    return `${BaseUriGroup3}${endPoint}`;
  }

  get authToken() {
    return this.authTokenGetter && this.authTokenGetter();
  }

  setAuthToken(tokenGetter: () => string) {
    this.authTokenGetter = tokenGetter;
    // console.log(this.authToken)
  }

  setUserGetter(userGetter: () => any) {
    this.authUserGetter = userGetter;
  }

  getHeaders = async () => {
    let headers: { 'Content-Type': string; Authorization?: string } = {
      'Content-Type': 'application/json',
    };
    if (!this.authToken) {
      throw new Error('Auth token missing.');
    }
    headers.Authorization = `Bearer ${await this.authToken}`;
    return headers;
  };

  async callApi(
    type: ApiMethodType,
    url: string,
    payload?: any,
    eh?: (error: any) => void,
    skipRetry?: boolean,
  ): Promise<any> {
    if (type === 'get' && !skipRetry) {
      /**
       * bug fix
       * if user token is not yet set, this means we are trying to prefetch an
       * api ahead of its usage, in this case wait for a second to let user getter to be set
       * **/
      if (!this.authToken) {
        return new Promise((resolve) => {
          setTimeout(() => resolve(this.callApi(type, url, payload, eh)), 1000);
        });
      }

      return retry(async () => this.callApiUnsafe(type, url, payload, eh), {
        retries: 10,
        randomize: true,
      });
    }
    return this.callApiUnsafe(type, url, payload, eh);
  }

  async getResponse(type, url, payload, options, action) {
    let response;
    if (type === 'delete' && payload) {
      response = await action(url, { ...options, data: payload });
    } else {
      response = payload
        ? await action(url, payload, options)
        : await action(url, options);
    }
    return response;
  }

  async callApiUnsafe(
    type: ApiMethodType,
    url: string,
    payload?: any,
    eh?: (error: any) => void,
  ): Promise<any> {
    try {
      let action: (url: string, payload?: any, options?: any) => Promise<any>;
      // @ts-ignore
      action = axios[type];
      let options = {
        headers: await this.getHeaders(),
      };

      let response = await this.getResponse(
        type,
        url,
        payload,
        options,
        action,
      );
      const { data } = response;

      if (data && data.response && data.response.data) {
        //call function in an analytics wrapper. pass in url and data.
        //in the wrapper, check whether url is in a lookup. If so, send track event
        //Also, format data in the wrapper
        // track(events.API_CALL_SUCCESSFUL, { url, type, data: data.response.data });
        captureAPIResponse(url, data.response.data, type);
        return data.response.data;
      } else if (data.response) {
        // track(events.API_CALL_SUCCESSFUL, { url, type, data: data.response });
        captureAPIResponse(url, data.response, type);
        return data.response;
      } else {
        captureAPIResponse(url, data, type);
        return data;
      }
    } catch (error) {
      console.error('api-error', error);
      let captured = new Error(`Api call error ${url}`);
      Sentry.captureException(captured);

      // track(events.API_CALL_FAILED, { url, type, error });
      let responseData, statusCode;

      let erMsg = 'something went wrong';
      let errMsgSource = 'unknown';

      if (error && error.response) {
        responseData = error.response.data;
        statusCode = error.response.status;
      } else {
        // network error
        erMsg = 'Please check your internet connection';
      }

      let prettyError: any = {
        errorCode: statusCode,
        message: erMsg,
        source: errMsgSource,
      };

      if (
        responseData &&
        responseData.response &&
        responseData.response.error &&
        responseData.response.error.source === 'server'
      ) {
        prettyError = responseData.response.error;
      }

      captureAPIError(url, prettyError, type);

      if (eh) eh(prettyError);
      else throw prettyError;
    } finally {
      if (['post', 'put', 'delete'].includes(type)) {
        this.updateUserIdentity().catch(console.error);
      }
    }
  }

  async callApiSafe(
    method: Function,
    onError: (error: any) => void,
    ...args: any[]
  ) {
    let self = this;
    let sudo = Object.assign({}, self);
    sudo.callApi = async function(
      type: ApiMethodType,
      url: string,
      payload?: any,
    ) {
      return self.callApi(type, url, payload, onError);
    };
    return method.call(sudo, ...args);
  }

  async updateUserIdentity() {
    let user = await this.authUserGetter();
    Sentry.configureScope((scope) => scope.setUser(user));

    // To handle undefined exception.
    let { sub } = user || {};
    // LogRocket.identify(sub, user);
    let wf = await this.getUserDetails();
    identify(sub, { ...user, ...wf, ...this.marketingInfo });
    identifyHeroUser(sub);
  }

  async getUserDetails() {
    const url = Api.makeUrl('/user/details');
    let options = {
      headers: await this.getHeaders(),
    };
    const {
      data: {
        response: { data },
      },
    } = await axios.get(url, options);
    return data;
  }

  async getOverview(queryParams?: string) {
    const url = Api.makeUrl(`/summary${queryParams ? `?${queryParams}` : ''}`);
    return this.callApi('get', url);
  }

  async getExecutionsList(queryParams?: string) {
    const url = Api.makeUrl(
      `/executions${queryParams ? `?${queryParams}` : ''}`,
    );
    return this.callApi('get', url);
  }

  async getWorkflows(queryParams?: string) {
    const url = Api.makeUrl(
      `/workflows${queryParams ? `?${queryParams}` : ''}`,
    );
    return this.callApi('get', url);
  }

  async getWorkflowExecutionHistory({
    workflow,
    limit = -1,
  }: {
    workflow: string;
    limit?: number;
  }) {
    const url = Api.makeUrl(
      `/workflows/${workflow}/executions${limit > -1 ? `?limit=${limit}` : ''}`,
    );
    return this.callApi('get', url);
  }

  async getWorkflowDetails(workflow: string) {
    const url = Api.makeUrl(`/workflows/${workflow}`);
    return this.callApi('get', url, null, null, true);
  }

  async getWorkflowByName(wfname: string) {
    const url = Api.makeUrl(`/workflows/name/${wfname}`);
    return this.callApi('get', url);
  }

  async getExecutionSteps(execution: string) {
    const url = Api.makeUrl(`/executions/${execution}`);
    return this.callApi('get', url);
  }

  async updateWorkflow(payload: any) {
    const url = Api.makeUrl('/workflows');
    return this.callApi('put', url, payload);
  }

  async getConfigs(queryParams?: string) {
    const url = Api.makeUrl(`/configs${queryParams ? `?${queryParams}` : ''}`);
    return this.callApi('get', url);
  }

  async editConfig(payload: any) {
    const url = Api.makeUrl('/configs');
    return this.callApi('put', url, payload);
  }

  async saveConfig(payload: any) {
    const url = Api.makeUrl('/configs');
    return this.callApi('post', url, payload);
  }

  async saveGroup(payload: any) {
    const url = Api.makeUrl('/configs/group', true);
    return this.callApi('post', url, payload);
  }

  async editGroup(payload: any) {
    const url = Api.makeUrl('/configs/group', true);
    return this.callApi('put', url, payload);
  }

  async deleteConfig(id: string) {
    const url = Api.makeUrl('/configs/' + id);
    return this.callApi('delete', url);
  }

  async removeConfigFromGroup(payload: any) {
    const url = Api.makeUrl('/configs/group', true);
    return this.callApi('delete', url, payload);
  }

  async validate(payload: any) {
    const url = Api.makeUrl('/validate-creds');
    return this.callApi('post', url, payload);
  }

  async fetchAwsOrgMembers(payload: any) {
    const url = Api.makeUrl('/aws-org-members', true);
    return this.callApi('post', url, payload);
  }

  //Create new workflow
  async createWorkflow(payload: any) {
    const url = Api.makeUrl(`/workflows`);
    return this.callApi('post', url, payload);
  }

  async destroyWorkflow(workflow: string) {
    const url = Api.makeUrl(`/workflows/${workflow}`);
    return this.callApi('delete', url);
  }

  async editWorkflow(payload: any) {
    const url = Api.makeUrl(`/workflows`);
    return this.callApi('put', url, payload);
  }

  async verifyWorkflowPolicy(param: {
    workflow?: string;
    definition?: string;
  }) {
    const url = Api.makeUrl(`/workflows/verifyPolicy`);
    return this.callApi('post', url, param);
  }

  async getVisualizerData(workflowId: string) {
    const url = Api.makeUrl(`/workflows/${workflowId}/visualizer`);
    return this.callApi('get', url);
  }

  //Run Workflow Child
  async executeWorkflowDryRun(payload: any) {
    const url = Api.makeUrl(`/workflows/${payload.workflow}/dry-run`);
    return this.callApi('post', url, payload);
  }

  //To run workflow directly call this
  async executeWorkflowInstantRun(workflowId: string, input?: any) {
    const url = Api.makeUrl(`/workflows/${workflowId}/run`);
    return this.callApi('post', url, { input: input, forceRun: true });
  }

  async validateSlack(code: string, config: object) {
    const url = Api.makeUrl(`/validate-slack`);
    let payload = { ...config, code };
    return this.callApi('post', url, payload);
  }

  async createEndpoint() {
    const url = Api.makeUrl(`/endpoint/create`);
    return this.callApi('get', url);
  }

  async getAvailableAlarms(param: {
    sns?: string;
    aws: string;
    region: string;
  }) {
    const url = Api.makeUrl(`/endpoint/list-alarms`);
    const payload = { region: param.region, aws: param.aws, sns: param.sns };
    return this.callApi('post', url, payload);
  }

  async convertToTemplate(params: any) {
    const url = Api.makeUrl(`/templates-engine`, true);
    return this.callApi('post', url, params);
  }

  async createTemplate(params: any) {
    const url = Api.makeUrl(`/templates`, true);
    return this.callApi('post', url, params);
  }

  async updateTemplate(payload: any) {
    const url = Api.makeUrl('/templates', true);
    return this.callApi('put', url, payload);
  }

  async deleteTemplate(param: any) {
    const url = Api.makeUrl(`/templates/${param.id}`, true);
    return this.callApi('delete', url);
  }

  async getTemplate(id: string) {
    const url = Api.makeUrl(`/templates/${id}`, true);
    return this.callApi('get', url);
  }

  async validatePolicyForTemplates(props: { account: string; ids: string[] }) {
    let { account, ids } = props;
    const url = Api.makeUrl(`/template-get-combined-policies`, true);
    return this.callApi('post', url, { ids, account });
  }

  async createBatchAdoptionJob(props: {
    resourceGroup?: string | string[];
    region?: string | string[];
    account?: string | string[];
    templateIds?: any[];
    templatesObj?: TemplatesToAdopt[];
  }) {
    let { region, account, templateIds, resourceGroup, templatesObj } = props;
    const url = Api.makeUrl(`/template-batch-adoption`, true);
    return this.callApi('post', url, {
      region,
      account,
      templateIds,
      templatesObj,
      resourceGroups: resourceGroup && [].concat(resourceGroup),
    });
  }

  async listTemplates(queryParams?: {
    [s: string]: string | number | boolean;
  }) {
    const url = Api.makeUrl(
      `/templates${
        queryParams ? `?${querystring.stringify(queryParams)}` : ''
      }`,
      true,
    );
    return this.callApi('get', url);
  }

  async publishTemplate(template: Template, publishMain?: boolean) {
    const url = Api.makeUrl(`/templates-webflow-engine`, true);
    return this.callApi('post', url, { template, publishMain });
  }

  async unPublishTemplate(template: Template, unPublishMain?: boolean) {
    const url = Api.makeUrl(`/templates-webflow-unpublish`, true);
    return this.callApi('post', url, { template, unPublishMain });
  }

  async getCmsWithMissingTemplates() {
    const url = Api.makeUrl(`/templates-missing`, true);
    return this.callApi('get', url);
  }

  async initPortalSession() {
    const url = Api.makeUrl(`/user/portal-init`);
    return this.callApi('get', url);
  }

  async getUserSubscriptionDetails(): Promise<any> {
    const url = Api.makeUrl(`/user/payment-details`);
    return this.callApi('get', url);
  }

  async getMappedParams(props: {
    service: string;
    method: string;
    outputKeys: any[];
    inputJson: any;
  }) {
    let { service, method, outputKeys, inputJson } = props;
    const url = Api.makeUrl(`/params-mapping`, false);
    return this.callApi('post', url, {
      service,
      method,
      outputKeys,
      inputJson,
    });
  }

  async getKeysFromSchema(props: { schema: any }) {
    let { schema } = props;
    const url = Api.makeUrl(`/schema-to-keys`, false);

    return this.callApi('post', url, { schema });
  }

  async cloneWorkflow(props: CloneWorkflowParams) {
    const url = Api.makeUrl(`/clone`, false);
    return this.callApi('post', url, props);
  }

  async listGroupWorkflows() {
    const url = Api.makeUrl(`/groups`, true);
    return this.callApi('get', url);
  }

  async saveGroupWorkflow(group: any) {
    const url = Api.makeUrl(`/groups`, true);
    return this.callApi('post', url, group);
  }

  async updateGroupWorkflow(group: any) {
    const url = Api.makeUrl(`/groups`, true);
    return this.callApi('put', url, group);
  }

  async getLatestResources(group: any) {
    const url = Api.makeUrl(`/groups/resources`, true);
    return this.callApi('post', url, group);
  }

  async testGroupWorkflow(group: any) {
    const url = Api.makeUrl(`/groups/test`, true);
    return this.callApi('post', url, group);
  }

  async groupWorkflowSkipAction(params: {
    group: string;
    cron: { startCron: string; endCron: string };
  }) {
    const url = Api.makeUrl(`/groups/${params.group}/skip`, true);
    return this.callApi('post', url, params.cron);
  }

  async groupWorkflowAction(params: {
    group: string;
    action: string;
    data: any;
  }) {
    const url = Api.makeUrl(
      `/groups/${params.group}/actions/${params.action}`,
      true,
    );
    return this.callApi('post', url, params.data);
  }

  async sendCode(payload) {
    const url = Api.makeUrl(`/support/send-code`, true);
    return this.callApi('post', url, payload);
  }

  async getSupportConfigs(data: { forUser: string; type: CONFIG_TYPES }) {
    const query = `?${querystring.stringify(data)}`;
    const url = Api.makeUrl(`/support/user/configs${query}`, true);
    return this.callApi('get', url);
  }

  async supportAction(data: { payload: any; hash: string }) {
    const url = Api.makeUrl(`/support/action`, true);
    return this.callApi('post', url, data);
  }

  async getShareWorkflowUrl(data: {
    workflow: string;
    tabName: string;
    permissions: string[];
  }) {
    const url = Api.makeUrl(`/workflows/${data.workflow}/details-token`);
    return this.callApi('post', url, {
      tabName: data.tabName,
      permissions: data.permissions,
    });
  }

  async getGroupHistory(data: { group: string }) {
    const url = Api.makeUrl(`/groups/${data.group}/history`, true);
    return this.callApi('get', url);
  }

  async getGroupWorkflow(data: { group: string }) {
    const url = Api.makeUrl(`/groups/${data.group}`, true);
    return this.callApi('get', url);
  }

  async fetchGroupPolicy(group: any) {
    const url = Api.makeUrl(`/groups/validate`, true);
    return this.callApi('post', url, group);
  }

  async fetchSchedulePolicy(data: { groups: string[] }) {
    const url = Api.makeUrl(`/groups/validate-multiple`, true);
    return this.callApi('post', url, data);
  }

  async deleteGroupWorkflow(data: { group: string }) {
    const url = Api.makeUrl(`/groups/${data.group}`, true);
    return this.callApi('delete', url);
  }

  async listRecommendationConfigs() {
    const url = Api.makeUrl(`/recommendation/config`, true);
    return this.callApi('get', url);
  }

  async createRecommendationConfigs(data: any) {
    const url = Api.makeUrl(`/recommendation/config`, true);
    return this.callApi('post', url, data);
  }

  async deleteRecommendationConfigs(data: { id: string }) {
    const url = Api.makeUrl(`/recommendation/config/${data.id}`, true);
    return this.callApi('delete', url);
  }

  async updateRecommendationConfigs(data: any) {
    const url = Api.makeUrl(`/recommendation/config/${data.id}`, true);
    return this.callApi('put', url, data);
  }

  async listRecommendedSchedule() {
    const url = Api.makeUrl(`/recommendation/resources`, true);
    return this.callApi('get', url);
  }

  async submitCannyForm(payload: { title: string; details: string }) {
    const url = Api.makeUrl(`/external-integration/canny`, true);
    return this.callApi('post', url, payload);
  }

  async getMultiPolicy(payload: {
    groups: string[];
    workflows: string[];
    templates: string[];
    account: string;
  }) {
    const url = Api.makeUrl(`/policy/validate-multiple`, true);
    return this.callApi('post', url, payload);
  }

  async getGlobalPolicy() {
    const url = Api.makeUrl(`/policy/validate-global`, true);
    return this.callApi('get', url);
  }

  async getPolicyDoc(payload: {
    actions: string[];
    invalidActions?: string[];
  }) {
    const url = Api.makeUrl(`/policy/document`, true);
    return this.callApi('post', url, payload);
  }

  async batchGetExecutionStatus(payload: { workflows: string[] }) {
    const url = Api.makeUrl(`/execution/status`, true);
    return this.callApi('post', url, payload);
  }

  async syncDevTemplatesFromProd() {
    const url = Api.makeUrl(`/templates-sync`, true);
    return this.callApi('get', url, null, null, true);
  }

  async validateParams(payload: {
    service: string;
    method: string;
    params: any;
    variables: any;
  }) {
    const url = Api.makeUrl(`/validate/params`, true);
    return this.callApi('post', url, payload);
  }

  async getAzureTypeDefinition(path: string) {
    const url = Api.makeAzureUrl(path);
    return this.callApi('get', url, undefined, () => undefined, true);
  }
  async getSGCTypeDefinition(path: string) {
    const url = Api.makeSGCUrl(path);
    let response = await fetch(url);
    let result = await response.json();
    return result;
  }

  async geK8TypeDefinition(path: string) {
    const url = Api.makeK8Url(path);
    return this.callApi('get', url, undefined, () => undefined, true);
  }

  async linkAndAdoptFix(payload: {
    accounts: string[];
    regions: string[];
    templateId: string;
    parentWorkflowId: string;
    runFix?: boolean;
  }) {
    const url = Api.makeUrl(`/workflows/link-fix`);
    return this.callApi('post', url, payload);
  }

  async listOrgUsers() {
    const url = Api.makeGroup3Url(`/tenant/users`);
    return this.callApi('get', url);
  }

  async listOrgGroups() {
    const url = Api.makeGroup3Url(`/tenant/groups`);
    return this.callApi('get', url);
  }

  async addOrgUser(
    profile: { email: string; firstName: string; lastName: string },
    groupIds: string[],
  ) {
    const url = Api.makeGroup3Url(`/tenant/users`);
    return this.callApi('post', url, { profile, groupIds });
  }

  async deleteUserAccount(params: any) {
    const url = Api.makeGroup3Url(`/user-management/account`);
    return this.callApi('delete', url, params);
  }

  async createOrgGroup(
    name: string,
    access: { accounts: string[] },
    assign: { userIds: string[] },
  ) {
    const url = Api.makeGroup3Url(`/tenant/groups`);
    return this.callApi('post', url, { name, access, assign });
  }

  async reAssignUsers(userId: string, groupIds: string[]) {
    const url = Api.makeGroup3Url(`/tenant/users/${userId}`);
    return this.callApi('put', url, { groupIds });
  }

  async updateGroup(
    name: string,
    groupId: string,
    access: { accounts: string[] },
  ) {
    const url = Api.makeGroup3Url(`/tenant/groups/${groupId}`);
    return this.callApi('put', url, { name, access });
  }

  async toggleUserStatus(userId: string) {
    const url = Api.makeGroup3Url(`/tenant/users/${userId}/activate`);
    return this.callApi('post', url, {});
  }

  async deleteOrgGroup(groupId: string) {
    const url = Api.makeGroup3Url(`/tenant/groups/${groupId}`);
    return this.callApi('delete', url);
  }

  async mapParamsForAliasData(params: {
    method: string;
    service: string;
    inputJson: any;
    dataLink?: string;
    data?: any;
    map: any;
  }) {
    const url = Api.makeGroup3Url(`/params-mapping-with-alias-data`);
    return this.callApi('post', url, params);
  }

  async customWorkflowAction(params: {
    target: TargetType;
    inputs: any;
    account: string;
    region: string;
    inputData?: any;
    nodeType: NodeType;
    workflowId?: string;
  }) {
    const url = Api.makeUrl(`/workflows/custom`);
    return this.callApi('post', url, params);
  }

  async listResourceGroups(params: { configId: string }) {
    const url = Api.makeGroup3Url(`/config/resourceGroups`);
    return this.callApi('post', url, params);
  }

  async getSuperTableData(options?: any) {
    const url = Api.makeGroup3Url(`/super-api/table/definition/global`);
    return this.callApi('post', url, options);
  }

  listEKSCluster({ configId, region }: { configId: string; region: string }) {
    const url = Api.makeGroup3Url(
      `/config/eksKubeConfig?configId=${configId}&region=${region}`,
    );
    return this.callApi('get', url);
  }

  async doSuperTableAction(params: any) {
    const url = Api.makeGroup3Url(`/super-api/rpc`);
    return this.callApi('post', url, params);
  }

  async listUserScripts() {
    const url = Api.makeGroup3Url(`/user/custom-scripts`);
    return this.callApi('get', url);
  }

  async createUserScript(params: HttpParameter) {
    const url = Api.makeGroup3Url(`/user/custom-scripts`);
    return this.callApi('post', url, params);
  }

  async updateUserScript(params: HttpParameter<'id'>) {
    const url = Api.makeGroup3Url(`/user/custom-scripts/${params.id}`);
    return this.callApi('post', url, params);
  }

  async deleteUserScript(params: HttpParameter<'id'>) {
    const url = Api.makeGroup3Url(`/user/custom-scripts/${params.id}`);
    return this.callApi('delete', url);
  }

  async listUserNodes() {
    const url = Api.makeGroup3Url(`/user/custom-nodes`);
    return this.callApi('get', url);
  }

  async createUserNode(params: HttpParameter) {
    const url = Api.makeGroup3Url(`/user/custom-nodes`);
    return this.callApi('post', url, params);
  }

  async updateUserNode(params: HttpParameter<'id'>) {
    const url = Api.makeGroup3Url(`/user/custom-nodes/${params.id}`);
    return this.callApi('post', url, params);
  }

  async deleteUserNode(params: HttpParameter<'id'>) {
    const url = Api.makeGroup3Url(`/user/custom-nodes/${params.id}`);
    return this.callApi('delete', url);
  }
}

export default new Api();
