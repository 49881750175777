import * as React from 'react';
import { Selectorlabel, InnerSec, ListWorkflow } from '../styledEntities';
import { isLengthyArray } from '../../../util/commonUtils';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import '../../../assets/css/ExecutionListScrollbar.css';
import colors from '../../../design/colors';
import { Row, ListGroup, Badge, ListGroupItem, Col } from 'reactstrap';
import { Execution } from '../../../lib/store/executions/types';

// export interface ExecutionListItemType {
//   execution: string;
//   user: string;
//   workflow: string;
//   status: string;
//   starttime: string;
//   endtime: string;
//   input: string;
//   output: string;
//   details: any;
//   timestamp: string;
// }

export type ExecutionListItemType = Execution;

interface FilterOption {
  label: String;
  value: String;
}
export default class ExecutionsList extends React.Component<
  {
    executionData: Array<ExecutionListItemType> | null;
    insideWorkflowDetails?: boolean;
    selectedExecution?: ExecutionListItemType;
    onSelect?: Function;
  },
  {
    filterOptions: Array<FilterOption>;
    selectedFilter: FilterOption;
  }
> {
  state = {
    filterOptions: [
      { label: 'All', value: 'All' },
      { label: 'Successful', value: 'Success' },
      { label: 'Failed', value: 'Error' },
    ],
    selectedFilter: { label: 'All', value: 'All' },
  };

  selectFilter = (filter: any) => {
    this.setState({ selectedFilter: filter });
  };

  renderList = () => {
    const { executionData } = this.props;
    if (!executionData || !isLengthyArray(executionData)) {
      return null;
    }
    const { selectedFilter } = this.state;
    return executionData.map((data, idx) => {
      if (selectedFilter.value === 'All') {
        return this.renderListItem(data, idx);
      } else {
        return data.status === selectedFilter.value
          ? this.renderListItem(data, idx)
          : null;
      }
    });
  };

  renderListItem = (data: any, idx: any) => {
    // console.log('data:::>', data);
    const { insideWorkflowDetails, selectedExecution, onSelect } = this.props;
    return (
      <ListGroupItem
        as="li"
        className={`
          ${
            selectedExecution && selectedExecution === data
              ? 'active justify-content-between'
              : 'justify-content-between'
          }
          test-${
            idx === 0 ? 'selected' : 'selectable'
          }-execution mr-2 py-3 cursor-auto pl-0 border-0`}
        key={idx}
        onClick={() => {
          return onSelect ? onSelect(data) : null;
        }}
      >
        <Row>
          <Col md={9} className={'text-truncate'}>
            {insideWorkflowDetails ? data.execution : data.workflowName}
            <span
              style={{
                fontSize: '0.8em',
                color: 'rgb(10, 33, 64)',
                fontStyle: 'italic',
                opacity: 0.6,
              }}
            >
              ({data.timestamp})
            </span>
          </Col>
          <Col md={3}>
            <Badge
              className="float-right"
              style={{
                backgroundColor: 'transparent',
                fontWeight: 500,
                fontSize: '0.9em',
                padding: '0',
                color: `${
                  data.status.toLowerCase() === 'error'
                    ? colors.palette.red
                    : data.status.toLowerCase() === 'running'
                    ? colors.text.secPrimary
                    : colors.palette.green
                }`,
              }}
            >
              <div className="test-execution-status status">{data.status}</div>
            </Badge>
          </Col>
        </Row>
      </ListGroupItem>
      // <ExecutionListItem
      //   key={idx}
      //   insideWorkflowDetails={insideWorkflowDetails}
      //   className={
      //     selectedExecution && selectedExecution === data ? 'active' : ''
      //   }
      //   onClick={() => (onSelect ? onSelect(data) : null)}
      // >
      //   <div className="wfname">
      //     {insideWorkflowDetails ? data.execution : data.workflowName}
      //     <span className="days-count">({data.timestamp})</span>
      //   </div>
      //   <div className={`status ${data.status.toLowerCase()}`}>
      //     {data.status}
      //   </div>
      // </ExecutionListItem>
    );
  };

  render() {
    const { executionData, insideWorkflowDetails } = this.props;
    const { filterOptions, selectedFilter } = this.state;
    return (
      <Row
        id="ta_fp_exec_list"
        className="test-exec-list mt-3 align-items-baseline"
      >
        <InnerSec className="test-exec-header pb-2" md={8}>
          <h6>{insideWorkflowDetails ? 'Executions' : 'Latest Executions'}</h6>
        </InnerSec>
        <InnerSec className="test-exec-timeperiod pb-2" md={4}>
          {filterOptions.map((filter, idx) => (
            <Selectorlabel
              key={idx}
              className={selectedFilter.value === filter.value ? 'active' : ''}
              onClick={() => this.selectFilter(filter)}
            >
              {filter.label}
            </Selectorlabel>
          ))}
        </InnerSec>
        <InnerSec className="test-exec-wf-list" lg="12">
          <div className={'w-100 border'}> </div>
          {/* <ExecutionListWrapper insideWorkflowDetails={insideWorkflowDetails}>
            {isLengthyArray(executionData) ? this.renderList() : null}
          </ExecutionListWrapper> */}
          {/* className="executionListScrollBar" */}
          <SimpleBar className="executionListScrollBar">
            <ListWorkflow flush={true} id="list-workflow">
              {isLengthyArray(executionData) ? this.renderList() : null}
            </ListWorkflow>
          </SimpleBar>
        </InnerSec>
      </Row>
    );
  }
}
