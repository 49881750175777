//SHOULD BE REMOVED
import api from '../../../../api';
import { SGCActionApi, SGCResourceApi } from '../../../store/sgc/types';

export const getResourceOptionsForService = (
  service: string,
  resourcesData: SGCResourceApi[],
) => {
  let matchedService = resourcesData.find((d) => d.service === service);
  if (!matchedService) return [];
  return matchedService.resources
    .filter((x) => x.active)
    .map((x) => ({ label: x.displayName, value: x.method }));
};

export const getActionOptionsForService = (
  service: any,
  actionsData: SGCActionApi[],
) => {
  let matchedService = actionsData.find((d) => d.service === service);
  if (!matchedService) return [];
  return matchedService.actions
    .filter((x) => x.active)
    .map((x) => ({ label: x.displayName, value: x.method }));
};

export const getMappedParamsForService = async (props: {
  service: string;
  method: string;
  outputKeys: any[];
  inputJson: {
    [s: string]: any;
  };
  paramsOptions: any;
}) => {
  const { service, method, outputKeys, inputJson, paramsOptions } = props;
  // console.log({ service, method, outputKeys, inputJson });
  let _inputJson: { [p: string]: any } = inputJson;
  if (
    !inputJson ||
    (inputJson && Object.getOwnPropertyNames(inputJson).length === 0)
  )
    _inputJson = paramsOptions;

  try {
    return await api.getMappedParams({
      service,
      method,
      outputKeys,
      inputJson: _inputJson,
    });
  } catch (e) {
    return inputJson;
  }
};
