import {
  CloudChallengesSelected,
  CurrentlyAccomplishSelected,
  IntendToGainSelected,
  OnBoardingAction,
  OnBoardingPage,
  OnBoardingState,
} from './types';
import { Reducer } from 'redux';
import { getLastOnBoardingState } from './localStore';

const initialState: OnBoardingState = {
  progress: 'GS',
  current: 'HearFrom',
  landingSelected: [],
  goalSelected: '',
  completedSteps: [],
  adoptionSkipped: false,
};

export const onBoardingReducer: Reducer<OnBoardingState, OnBoardingAction> = (
  state = getLastOnBoardingState() || initialState,
  action,
): OnBoardingState => {
  switch (action.type) {
    case 'LandingSelected':
      return {
        ...state,
        progress: 'GS',
        landingSelected: action.payload,
        current: 'HearFrom',
        completedSteps: [],
      };

    case 'WantsExpertHelp':
      return {
        ...state,
        progress: 'GS',
        current: 'HearFrom',
        completedSteps: [],
      };

    case 'ExpertHelpGiven':
      return {
        ...state,
        progress: 'Finish',
        current: 'RedirectToDashboard',
        completedSteps: ['GS'],
        adoptionSkipped: true,
      };

    case 'ExpertHelpRejected':
      return {
        ...state,
        progress: 'GS',
        current: 'HearFrom',
        completedSteps: [],
      };
    case 'GoalsSelected':
      return {
        ...state,
        progress: 'GS',
        current: 'HearFrom',
        goalSelected: action.payload,
        adoptionSkipped: true,
      };
    case 'DefineSuccessSelected':
      return {
        ...state,
        progress: 'Finish',
        current: 'RedirectToDashboard',
        completedSteps: ['GS'],
        adoptionSkipped: true,
      };
    case 'PreviousBoardingState': {
      let previousState: OnBoardingPage =
        state.current === 'HearFrom' ? 'HearFrom' : 'CloudChallenges';
      return {
        ...state,
        progress: 'GS',
        current: previousState,
        completedSteps: [],
        adoptionSkipped: previousState !== 'HearFrom',
      };
    }
    case 'HearFromSelected':
      return {
        ...state,
        progress: 'GS',
        current: 'CloudChallenges',
        completedSteps: ['GS'],
        adoptionSkipped: true,
      };

    case 'CloudChallengesSelected':
      return {
        ...state,
        progress: 'Finish',
        current: 'CurrentlyAccomplish',
        completedSteps: ['GS'],
        adoptionSkipped: true,
      };

    case 'CurrentlyAccomplishSelected':
      return {
        ...state,
        progress: 'Finish',
        current: 'CurrentCloudProvider',
        completedSteps: ['GS'],
        adoptionSkipped: true,
      };

    case 'IntendToGainSelected':
      return {
        ...state,
        progress: 'Finish',
        current: 'RedirectToDashboard',
        completedSteps: ['GS'],
        adoptionSkipped: true,
        goalSelected: 'Yes',
      };
    default:
      return { ...state };
  }
};
