import React from 'react';
import { FormikProps } from 'formik';
import { TriggerSchema } from '../schema';
import { Col, CustomInput, Row } from 'reactstrap';
import { ToggleActiveState } from './ToggleActiveState';
import { copyToClipboard } from '../../../../common/Utils';
import SchemaComponent, { defaultSchema } from './SchemaComponent';
import InputText from '../../../../../../formInputs/input-text.component';
import { MoreSettings } from './moreSettings';

export function HttpForm(props: FormikProps<TriggerSchema>) {
  const copy = () => copyToClipboard(props.values.http.value.url);
  const onSchemaChange = ({ jsonSchema, error }) => {
    props.setFieldValue('http.value.userSchema', jsonSchema);
    if (error) {
      props.setFieldError('http.value.userSchema', error);
    }
  };
  return (
    <>
      <Row>
        <Col>
          <ToggleActiveState {...props} targetType={'http'} />
        </Col>
      </Row>

      <MoreSettings {...props} />
      <Row>
        <Col md={11} className={'mr-0'}>
          <InputText
            label={'Http url to trigger this workflow programmatically'}
            name={'http-url'}
            placeholder={
              'HTTP URL will be available once the workflow is saved'
            }
            value={props.values.http.value.url}
            readOnly={true}
          />
        </Col>
        <Col className={'mt-4 ml-0'} md={1}>
          {props.values.http.value.url && (
            <i className="fa fa-clipboard cursor-pointer mt-3" onClick={copy} />
          )}
        </Col>
      </Row>
      <Row className={'mt-2'}>
        <Col>
          <SchemaComponent
            style={{ height: 'calc(100vh - 420px)' }}
            schema={props.values.http.value.userSchema || defaultSchema}
            changeValue={onSchemaChange}
          />
        </Col>
      </Row>
    </>
  );
}
