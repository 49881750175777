import { Col, Row } from 'reactstrap';
import { CategoryList } from '../../common/list/categoryList';
import { SubCategoryList } from '../../common/list/subCategoryList';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DummyField } from '../../common/DummyField';
import { TAB_NAME } from 'webapp-genric/dist/constants';

interface CategorySelectorProps {
  hideCategory?: boolean;
  hideSubCategory?: boolean;
  categories: { category: string; subCategories: string[] }[];
  onSelectCategory: (category: string, subCategory?: string) => void;
  selected: { category: string; subCategory?: string };
  isLoading: boolean;
}
export const CategorySelector = (props: CategorySelectorProps) => {
  const {
    categories = [],
    isLoading,
    hideSubCategory,
    hideCategory,
    selected,
    onSelectCategory,
  } = props;

  const shouldShowCategorySelector = !hideCategory && categories.length;
  const shouldShowSubCategorySelector =
    !hideSubCategory &&
    Boolean(categories.length) &&
    Boolean(
      categories.find((x) => x.category === selected.category)?.subCategories
        ?.length,
    );
  const shouldShowSelector =
    shouldShowCategorySelector || shouldShowSubCategorySelector;
  const subCategoriesList =
    categories
      .find((x) => x.category == selected.category)
      ?.subCategories?.map((x) => ({ label: x, value: x })) || [];
  const categoryList = categories.map((x) => ({
    label: x.category,
    value: x.category,
  }));

  useEffect(() => {
    if (categories.length == 2) {
      let selected = categories.find((x) => x.category !== 'All')?.category;
      if (selected) {
        onSelectCategory(selected);
      }
    }
  }, [categories]);

  if (isLoading)
    return (
      <div className="background-white rounded-lg custom-container p-1 py-3 w-100">
        <div className="d-flex w-100">
          <DummyField width={'50px'} className={'mx-2'} />
          <DummyField width={'50px'} className={'mx-2'} />
          <DummyField width={'50px'} className={'mx-2'} />
          <DummyField width={'50px'} className={'mx-2'} />
          <DummyField width={'50px'} className={'mx-2'} />
        </div>
      </div>
    );
  return (
    <React.Fragment>
      {shouldShowSelector && (
        <div className="background-white rounded-lg custom-container p-1 py-3 w-100">
          {shouldShowCategorySelector && (
            <div className="px-4 py-2">
              <CategoryList
                lists={categoryList}
                selected={selected.category}
                onSelect={(category) => onSelectCategory(category, 'All')}
              />
            </div>
          )}
          {shouldShowSubCategorySelector && (
            <div className="px-4 py-2 ">
              <SubCategoryList
                lists={selected.category ? subCategoriesList : []}
                selected={selected.subCategory}
                onSelect={(subCategory) =>
                  onSelectCategory(selected.category, subCategory)
                }
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
