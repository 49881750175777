import * as React from 'react';
import { Col, Row } from 'reactstrap';
import {
  WorkflowReport,
  WorkflowTriggerTimeStampDivId,
} from '../../workflows/common/workflowReport';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { Workflow } from '../../../lib/store/workflows/types';
import { Template } from '../../../lib/store/templates/types';
import { shouldRenderTriggerOptions } from '../../../config/policyStatusConfig';

type IProps = React.PropsWithChildren<{
  user?: string;
  tabName?: TAB_NAME;
  count: string;
  topLevelFilter: React.ReactNode | null;
  selectedCategory?: string;
  selectedSubCategory?: string;
  heading: string;
  credentials?: string[];
  loading?: boolean;
  workflows?: Workflow[];
  templates?: Template[];
  regions?: string[];
  hideReportGenerator?: boolean;
}>;

export function ListHeading(props: IProps) {
  return (
    <>
      {/*title*/}
      <Row className="no-gutters">
        <Col md={4} className="d-flex align-items-center">
          <h3 data-test="list-layout-heading" className="mb-0">
            {props.heading}
            <small
              data-test="list-layout-count"
              className="px-2 small text-muted font-italic font-weight-light test-workflows-count"
            >
              {props.count}
            </small>
          </h3>
        </Col>
        <Col
          md={8}
          className={'text-right d-flex justify-content-end align-items-center'}
        >
          {shouldRenderTriggerOptions(props.tabName) && (
            // <Row className="no-gutters my-0">
            <div className={'text-right text-primary mx-2'}>
              <div id={WorkflowTriggerTimeStampDivId}></div>
            </div>
            // </Row>
          )}
          {props.user && !props.hideReportGenerator && (
            <WorkflowReport
              category={props.selectedCategory}
              subCategory={props.selectedSubCategory}
              tabName={props.tabName}
              user={props.user}
              credentials={props.credentials}
              loading={props.loading}
              regions={props.regions}
              templates={props.templates}
              workflows={props.workflows}
            />
          )}
        </Col>
        <Col className={'ml-auto'}>
          <Row className={'justify-content-end'}>{props.topLevelFilter}</Row>
        </Col>
      </Row>

      <Row className={'pt-2 no-gutters'} data-test="list-layout-children">
        {props.children}
      </Row>
    </>
  );
}
