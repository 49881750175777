import * as React from 'react';
import Select from 'react-select';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import { NoOptionsMessage, Option } from './Option';
import { CustomStyles } from './Styles';
import { Group } from './Group';

import classNames from 'classnames';
import InputCommonProps from '../index.component';
import { Direction } from 'reactstrap/lib/Dropdown';
import _ from 'lodash';
import CloudProvider from 'workflow-model/dist/types/cloudProvider';
import { Config } from '../../store/config/types';

export const selectAllOption = {
  label: 'Select all',
  value: '*',
};

interface IState {
  searchString: string;
  options: any[];
}

interface IProps extends InputCommonProps {
  options: any[];
  allowSelectAll?: boolean;
  isMulti?: boolean;
  onChange: (value) => void;
  footer?: JSX.Element;
  icon?: string;
  disabled?: boolean;
  emptyText?: string | JSX.Element;
  direction?: Direction;
  isSingleSelectWithinGroup?: boolean;
  singleSelectGroup?: any[];
  maxSelection?: number;
  config?: Config[];
}

const ReactSelect = (props) => {
  return (
    <Select
      autoFocus
      backspaceRemovesValue={false}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isClearable={false}
      menuIsOpen
      placeholder="Search..."
      styles={CustomStyles}
      tabSelectsValue={false}
      isDisabled={props.readOnly}
      {...props}
    />
  );
};

export class CustomReactSelect extends React.Component<IProps, IState> {
  allAvailableOptions = () => {
    let options = (this.state && this.state.options) || this.props.options;
    return options.find((x) => x.options)
      ? options.map((x) => x.options).reduce((A, e) => A.concat(e), [])
      : options;
  };

  areAllOptionSelected = (selected = []) => {
    let allOptions = this.allAvailableOptions();
    if (allOptions.length === 0) return false;
    if (
      selected &&
      selected.filter((x) => x.value !== selectAllOption.value).length !==
        allOptions.length
    )
      return false;

    let nestedOptions = selected.filter((x) => x.options);
    if (nestedOptions.length === 0) return true;

    let partialSelected = nestedOptions.find((x) => {
      let options = allOptions.find((a) => a.label === x.label).options;
      return (
        (x.options && x.options.length) !== ((options && options.length) || 0)
      );
    });
    return !partialSelected;
  };

  getInitialOptions = () => {
    return this.props.options.map((group) => {
      if (group.options && group.options.find((x) => x.options)) {
        group.options = group.options.map((x) => ({
          ...x,
          expanded: false,
          value: x.label,
        }));
      }
      return { ...group, expanded: false };
    });
  };

  getInitialValue = () => {
    let { value, isMulti, allowSelectAll } = this.props;
    const { options } = this.state;
    if (!value) return this.props.isMulti ? [] : null;

    if (!isMulti) value = [value];

    let selectedOptions = [];

    for (let s of options) {
      if (s.options) {
        for (let innerOption of s.options) {
          if (innerOption.options) {
            let selectedInnerOptions = innerOption.options.filter((x) =>
              value.includes(x.value),
            );
            if (selectedInnerOptions.length !== 0)
              selectedOptions.push({
                ...innerOption,
                options: selectedInnerOptions,
              });
          } else if (innerOption.value && value.includes(innerOption.value)) {
            selectedOptions.push(innerOption);
          }
        }
      } else if (s.value && value.includes(s.value)) {
        selectedOptions.push(s);
      }
    }

    if (
      isMulti &&
      allowSelectAll &&
      this.areAllOptionSelected(selectedOptions)
    ) {
      return [selectAllOption, ...selectedOptions];
    }

    return isMulti ? selectedOptions : selectedOptions[0];
  };

  state = {
    searchString: '',
    options: this.getInitialOptions(),
  };

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    if (!_.isEqual(this.props.options, prevProps.options)) {
      this.setState({ options: this.getInitialOptions() });
    }
  }

  onSingleSelectChange = (value) => {
    let selectedValue = value.options ? value.options[0] : value;
    this.props.onChange(selectedValue && selectedValue.value);
  };

  onSelectSingleWithinGroupChange = (
    newValue: {
      label: string;
      options: any[];
      value: string;
      type: CloudProvider;
    }[],
    meta: any,
  ) => {
    const { value } = this.props;

    let options = this.allAvailableOptions();

    if (!this.props.singleSelectGroup) {
      throw new Error(
        'Property singleSelectGroup is required with property isSingleSelectGroup',
      );
    }

    newValue = newValue
      .map((x) => {
        return x.options ? x.options : x;
      })
      .reduce((A: any, e) => A.concat(e), []);

    let newValues = newValue.map(({ value, type }) => ({ key: type, value }));
    this.props.onChange(newValues);
  };

  onMultiSelectChange = (value, actionMeta) => {
    if (value && value.length > 0)
      value = value.reduce((A, e) => A.concat(e), []);
    const { option } = actionMeta;
    let newValue = value;
    let valueToSet = newValue || [];
    const areAllSelected = this.areAllOptionSelected(newValue);
    /*
      if  selected option is selectAll and are values are already selected
          || current selection is not selectAll but all values are deselected except selectAll option,
        set to []
     else if selected option is selectAll and all values are not selected
          || if current selected value was only remaining to selectAll
          select all values along with selectA; option
     */
    if (
      (option && option.value === selectAllOption.value && areAllSelected) ||
      (newValue.length === 1 &&
        newValue[0].value === selectAllOption.value &&
        (!option || option.value !== selectAllOption.value))
    ) {
      valueToSet = [];
    } else if (
      (option &&
        option.value === selectAllOption.value &&
        !this.areAllOptionSelected(newValue)) ||
      this.areAllOptionSelected(newValue)
    ) {
      valueToSet = [selectAllOption, ...this.allAvailableOptions()];
    }

    let selectedValue = valueToSet
      .map((x) => {
        return x.options ? x.options : x;
      })
      .reduce((A, e) => A.concat(e), [])
      .filter((x) => x.value !== selectAllOption.value)
      .map((x) => x.value);

    const { maxSelection } = this.props;

    if (maxSelection === undefined) {
      this.props.onChange(selectedValue);
    } else if (maxSelection && selectedValue.length <= maxSelection) {
      this.props.onChange(selectedValue);
    }
  };

  filterOption = ({ label, value }, string) => {
    if (this.state.searchString && !string) this.setState({ searchString: '' });
    if (
      label.toLocaleLowerCase().includes(string) ||
      (value && value.toLocaleLowerCase().includes(string))
    )
      return true;

    // check if a group as the filter string as label
    const groupOptions = this.state.options.filter((group: any) => {
      let isNestedOptionsSelected =
        group.options &&
        group.options.filter(
          (op) =>
            op.options &&
            op.options.filter(
              (f) =>
                f.label.toLocaleLowerCase().includes(string) ||
                f.value.toLocaleLowerCase().includes(string),
            ).length,
        ).length;
      if (isNestedOptionsSelected) {
        if (!this.state.searchString || this.state.searchString !== string)
          this.setState({ searchString: string });
      }
      return (
        group.label.toLocaleLowerCase().includes(string) ||
        isNestedOptionsSelected
      );
    });

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        let gOptionsValue: any[] = groupOption.options || [];
        const option = gOptionsValue.find((opt) => opt.label === label);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  onExpandOption = (data) => {
    data.expanded = !data.expanded;
    this.setState(this.state);
  };

  CustomOption = (props) => {
    return (
      <Option
        {...props}
        singleSelectWithinGroup={this.props.isSingleSelectWithinGroup}
        searchString={this.state.searchString}
        onExpand={this.onExpandOption}
      />
    );
  };

  CustomGroup = (props) => {
    return (
      <Group
        {...props}
        singleSelectWithinGroup={this.props.isSingleSelectWithinGroup}
        onExpand={this.onExpandOption}
      />
    );
  };

  CustomNoOptionsMessage = (props) => {
    return (
      <NoOptionsMessage
        {...props}
        emptyText={this.props.emptyText || 'No Options'}
      />
    );
  };

  getCommonProps = () => {
    return {
      components: {
        Group: this.CustomGroup,
        NoOptionsMessage: this.CustomNoOptionsMessage,
        Option: this.CustomOption,
        DropdownIndicator,
        IndicatorSeparator: null,
      },
      isMulti: this.props.isMulti,
      filterOption: this.filterOption,
      readOnly: this.props.readOnly,
      name: this.props.name,
      multiGroup: this.props.isSingleSelectWithinGroup,
    };
  };

  renderReactSelect = () => {
    const { options } = this.state;
    let value = this.getInitialValue();
    const commonProps = this.getCommonProps();
    if (!this.props.isMulti) {
      return (
        <ReactSelect
          {...commonProps}
          options={options}
          value={value}
          onChange={this.onSingleSelectChange}
        />
      );
    }
    if (this.props.allowSelectAll) {
      return (
        <ReactSelect
          {...commonProps}
          options={options.length ? [selectAllOption, ...options] : []}
          value={value}
          onChange={this.onMultiSelectChange}
        />
      );
    }

    return (
      <ReactSelect
        {...commonProps}
        onChange={
          this.props.isSingleSelectWithinGroup
            ? this.onSelectSingleWithinGroupChange
            : this.onMultiSelectChange
        }
        options={options}
        value={value}
      />
    );
  };

  render() {
    const {
      disabled,
      icon,
      label,
      className,
      id,
      name,
      value,
      direction = 'down',
    } = this.props;

    return (
      <UncontrolledDropdown
        className={`${className} mx-2`}
        id={id}
        name={name}
        value={value}
        direction={direction}
        disabled={disabled}
      >
        <DropdownToggle
          tag="a"
          className={classNames({
            'text-info': !disabled,
            'text-muted': disabled,
            'cursor-pointer test-options-button d-flex align-items-center text-decoration-none text-nowrap': true,
          })}
          caret={direction === 'down'}
        >
          {icon && <i className={icon} aria-hidden="true" />}
          {label && label}
          <i className="fa fa-caret-down pl-2" aria-hidden="true" />
        </DropdownToggle>
        <DropdownMenu style={{ zIndex: 1000 }} right={true}>
          {this.renderReactSelect()}
          {this.props.footer && (
            <>
              <DropdownItem className="test-divider" divider />
              <DropdownItem disabled>{this.props.footer}</DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

const DropdownIndicator = () => (
  <i className={' text-muted fa fa-search pr-3'} />
);
