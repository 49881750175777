import * as React from 'react';
import { Col, Button, UncontrolledTooltip } from 'reactstrap';
import { ICustomTableCol } from '../../common/table';
import { optionsColDisplayFormatter } from '../../workflows/common/formatter/OptionsColFormatter';

export const getNameFormatter = (colContent, row) => {
  return (
    <Col className="py-2">
      <div
        id={`workflow-name-${row.name || row.id}`}
        data-test="workflow-list-item"
        className={
          'align-self-start text-truncate test-workflow-list-item pb-1'
        }
      >
        <strong>{row.name}</strong>
      </div>
      <div>{row.description}</div>
    </Col>
  );
};

export const getSampleFormatter = (colContent, row): React.ReactNode => {
  return (
    <>
      <span id={`sample-output-${row.id}`}>
        <Button size={'sm'} color={'link'} disabled={true}>
          View Sample
        </Button>
      </span>
      <UncontrolledTooltip target={`sample-output-${row.id}`}>
        coming soon
      </UncontrolledTooltip>
    </>
  );
};

let cols: ICustomTableCol[] = [
  {
    colName: 'Template Name',
    dataField: 'name',
    text: 'Template Name',
    formatter: getNameFormatter,
  },
  {
    colName: 'Sample',
    headerStyle: { width: '15%', textAlign: 'center' },
    dataField: '',
    isDummyField: true,
    text: 'Sample',
    formatter: getSampleFormatter,
    align: 'center',
  },
  {
    colName: 'Options',
    headerStyle: { width: '12%' },
    dataField: 'options',
    text: '',
    formatter: optionsColDisplayFormatter,
    // hidden: true,
  },
];

export const getCols = (): ICustomTableCol[] => {
  return cols;
};
