import { TableItem, TemplateColNames } from '../cols';
import * as React from 'react';
import { StyledCustomDropDown, SwitchStatus } from '../../../workflows/style';
import { loader } from './loader';
import { Template } from '../../../../lib/store/templates/types';
import SwitchContainer from '../../../../lib/switch/SwitchContainer';
import { Workflow } from '../../../../lib/store/workflows/types';

let disabledStyle = { cursor: 'not-allowed' };

const getTemplateOptions = (template: Template, workflow: Workflow) => {
  let firstOption = {
    label: (
      <span style={!workflow || !workflow.workflow ? disabledStyle : {}}>
        Convert to Template
      </span>
    ),
    value: 'convert',
  };
  return [
    firstOption,
    {
      label: (
        <span style={!template || !template.id ? disabledStyle : {}}>
          Edit and Save
        </span>
      ),
      value: 'edit',
    },
    {
      label: (
        <span style={!template || !template.id ? disabledStyle : {}}>
          Delete
        </span>
      ),
      value: 'delete',
    },
  ];
};

const customDropDownFooterTemplate = (item: TableItem) => {
  let template = item.templateItem;
  if (!template || !template.id) return;
  const active =
    template && template['properties']
      ? template['properties']['active']
      : false;
  return (
    <div className="d-flex justify-content-between align-items-center text-uppercase">
      <SwitchStatus>Stage</SwitchStatus>
      <div className="px-2">
        <SwitchContainer
          value={active}
          onChange={(value) =>
            item.functions.toggleTemplateStatus(value, item.templateItem)
          }
        />
      </div>
      <SwitchStatus>Deploy</SwitchStatus>
    </div>
  );
};

const onChangeTempOption = (option: any, item: TableItem) => {
  const template = item.templateItem;
  switch (option) {
    // todo
    case 'convert':
      if (!item.workflowItem) return;
      return item.functions.convertWorkflowToTemplate({
        template,
        workflow: item.workflowItem,
        col: TemplateColNames.TEMPLATE_OPT,
      });
    case 'edit':
      return (
        template && template.id && item.functions.toggleTemplateModal(template)
      );
    case 'delete':
      return (
        template &&
        template.id &&
        item.functions.deleteTemplate({
          template,
          col: TemplateColNames.TEMPLATE_OPT,
        })
      );
    default:
      return;
  }
};

export const TemplateOptions = (name: string, item: TableItem) => {
  if (item.updating === TemplateColNames.TEMPLATE_OPT) return loader();
  return (
    <StyledCustomDropDown
      className="test-options-component"
      name="sort-workflow"
      value={''}
      options={getTemplateOptions(item.templateItem, item.workflowItem)}
      title={'Options'}
      onChange={(option) => onChangeTempOption(option, item)}
      right
      customDropDownFooter={customDropDownFooterTemplate(item)}
    />
  );
};
