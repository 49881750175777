export const NACL_FILTER_CONFIG = [
  {
    label: 'Any',
    portRange: '<input>',
    value: 'Any',
    protocolOptions: [
      {
        label: 'Any',
        value: '-2',
      },
    ],
  },
  {
    label: 'Custom TCP Rule',
    portRange: '<input>',
    value: 'Custom TCP Rule',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'Custom UDP Rule',
    portRange: '<input>',
    value: 'Custom UDP Rule',
    protocolOptions: [
      {
        label: 'UDP (17)',
        value: '17',
      },
    ],
  },
  {
    label: 'Custom Protocol Rule',
    protocolOptions: [
      {
        label: 'ALL (-1)',
        value: '-1',
      },
      {
        label: 'HOPOPT (0)',
        value: '0',
      },
      {
        label: 'ICMP (1)',
        value: '1',
      },
      {
        label: 'IGMP (2)',
        value: '2',
      },
      {
        label: 'GGP (3)',
        value: '3',
      },
      {
        label: 'IPv4 (4)',
        value: '4',
      },
      {
        label: 'ST (5)',
        value: '5',
      },
      {
        label: 'TCP (6)',
        value: '6',
      },
      {
        label: 'CBT (7)',
        value: '7',
      },
      {
        label: 'EGP (8)',
        value: '8',
      },
      {
        label: 'IGP (9)',
        value: '9',
      },
      {
        label: 'BBN-RCC-MON (10)',
        value: '10',
      },
      {
        label: 'NVP-II (11)',
        value: '11',
      },
      {
        label: 'PUP (12)',
        value: '12',
      },
      {
        label: 'ARGUS (13)',
        value: '13',
      },
      {
        label: 'EMCON (14)',
        value: '14',
      },
      {
        label: 'XNET (15)',
        value: '15',
      },
      {
        label: 'CHAOS (16)',
        value: '16',
      },
      {
        label: 'UDP (17)',
        value: '17',
      },
      {
        label: 'MUX (18)',
        value: '18',
      },
      {
        label: 'DCN-MEAS (19)',
        value: '19',
      },
      {
        label: 'HMP (20)',
        value: '20',
      },
      {
        label: 'PRM (21)',
        value: '21',
      },
      {
        label: 'XNS-IDP (22)',
        value: '22',
      },
      {
        label: 'TRUNK-1 (23)',
        value: '23',
      },
      {
        label: 'TRUNK-2 (24)',
        value: '24',
      },
      {
        label: 'LEAF-1 (25)',
        value: '25',
      },
      {
        label: 'LEAF-2 (26)',
        value: '26',
      },
      {
        label: 'RDP (27)',
        value: '27',
      },
      {
        label: 'IRTP (28)',
        value: '28',
      },
      {
        label: 'ISO-TP4 (29)',
        value: '29',
      },
      {
        label: 'NETBLT (30)',
        value: '30',
      },
      {
        label: 'MFE-NSP (31)',
        value: '31',
      },
      {
        label: 'MERIT-INP (32)',
        value: '32',
      },
      {
        label: 'DCCP (33)',
        value: '33',
      },
      {
        label: '3PC (34)',
        value: '34',
      },
      {
        label: 'IDPR (35)',
        value: '35',
      },
      {
        label: 'XTP (36)',
        value: '36',
      },
      {
        label: 'DDP (37)',
        value: '37',
      },
      {
        label: 'IDPR-CMTP (38)',
        value: '38',
      },
      {
        label: 'TP++ (39)',
        value: '39',
      },
      {
        label: 'IL (40)',
        value: '40',
      },
      {
        label: 'IPv6 (41)',
        value: '41',
      },
      {
        label: 'SDRP (42)',
        value: '42',
      },
      {
        label: 'IPv6-Route (43)',
        value: '43',
      },
      {
        label: 'IPv6-Frag (44)',
        value: '44',
      },
      {
        label: 'IDRP (45)',
        value: '45',
      },
      {
        label: 'RSVP (46)',
        value: '46',
      },
      {
        label: 'GRE (47)',
        value: '47',
      },
      {
        label: 'DSR (48)',
        value: '48',
      },
      {
        label: 'BNA (49)',
        value: '49',
      },
      {
        label: 'ESP (50)',
        value: '50',
      },
      {
        label: 'AH (51)',
        value: '51',
      },
      {
        label: 'I-NLSP (52)',
        value: '52',
      },
      {
        label: 'SWIPE (53)',
        value: '53',
      },
      {
        label: 'NARP (54)',
        value: '54',
      },
      {
        label: 'MOBILE (55)',
        value: '55',
      },
      {
        label: 'TLSP (56)',
        value: '56',
      },
      {
        label: 'IPv6-ICMP (58)',
        value: '58',
      },
      {
        label: 'IPv6-NoNxt (59)',
        value: '59',
      },
      {
        label: 'IPv6-Opts (60)',
        value: '60',
      },
      {
        label: '61',
        value: '61',
      },
      {
        label: 'CFTP (62)',
        value: '62',
      },
      {
        label: '63',
        value: '63',
      },
      {
        label: 'SAT-EXPAK (64)',
        value: '64',
      },
      {
        label: 'KRYPTOLAN (65)',
        value: '65',
      },
      {
        label: 'RVD (66)',
        value: '66',
      },
      {
        label: 'IPPC (67)',
        value: '67',
      },
      {
        label: '68',
        value: '68',
      },
      {
        label: 'SAT-MON (69)',
        value: '69',
      },
      {
        label: 'VISA (70)',
        value: '70',
      },
      {
        label: 'IPCV (71)',
        value: '71',
      },
      {
        label: 'CPNX (72)',
        value: '72',
      },
      {
        label: 'CPHB (73)',
        value: '73',
      },
      {
        label: 'WSN (74)',
        value: '74',
      },
      {
        label: 'PVP (75)',
        value: '75',
      },
      {
        label: 'BR-SAT-MON (76)',
        value: '76',
      },
      {
        label: 'SUN-ND (77)',
        value: '77',
      },
      {
        label: 'WB-MON (78)',
        value: '78',
      },
      {
        label: 'WB-EXPAK (79)',
        value: '79',
      },
      {
        label: 'ISO-IP (80)',
        value: '80',
      },
      {
        label: 'VMTP (81)',
        value: '81',
      },
      {
        label: 'SECURE-VMTP (82)',
        value: '82',
      },
      {
        label: 'VINES (83)',
        value: '83',
      },
      {
        label: 'IPTM (84)',
        value: '84',
      },
      {
        label: 'TTP (84)',
        value: '84',
      },
      {
        label: 'NSFNET-IGP (85)',
        value: '85',
      },
      {
        label: 'DGP (86)',
        value: '86',
      },
      {
        label: 'TCF (87)',
        value: '87',
      },
      {
        label: 'EIGRP (88)',
        value: '88',
      },
      {
        label: 'OSPFIGP (89)',
        value: '89',
      },
      {
        label: 'Sprite-RPC (90)',
        value: '90',
      },
      {
        label: 'LARP (91)',
        value: '91',
      },
      {
        label: 'MTP (92)',
        value: '92',
      },
      {
        label: 'AX.25 (93)',
        value: '93',
      },
      {
        label: 'IPIP (94)',
        value: '94',
      },
      {
        label: 'MICP (95)',
        value: '95',
      },
      {
        label: 'SCC-SP (96)',
        value: '96',
      },
      {
        label: 'ETHERIP (97)',
        value: '97',
      },
      {
        label: 'ENCAP (98)',
        value: '98',
      },
      {
        label: '99',
        value: '99',
      },
      {
        label: 'GMTP (100)',
        value: '100',
      },
      {
        label: 'IFMP (101)',
        value: '101',
      },
      {
        label: 'PNNI (102)',
        value: '102',
      },
      {
        label: 'PIM (103)',
        value: '103',
      },
      {
        label: 'ARIS (104)',
        value: '104',
      },
      {
        label: 'SCPS (105)',
        value: '105',
      },
      {
        label: 'QNX (106)',
        value: '106',
      },
      {
        label: 'A/N (107)',
        value: '107',
      },
      {
        label: 'IPComp (108)',
        value: '108',
      },
      {
        label: 'SNP (109)',
        value: '109',
      },
      {
        label: 'Compaq-Peer (110)',
        value: '110',
      },
      {
        label: 'IPX-in-IP (111)',
        value: '111',
      },
      {
        label: 'VRRP (112)',
        value: '112',
      },
      {
        label: 'PGM (113)',
        value: '113',
      },
      {
        label: '114',
        value: '114',
      },
      {
        label: 'L2TP (115)',
        value: '115',
      },
      {
        label: 'DDX (116)',
        value: '116',
      },
      {
        label: 'IATP (117)',
        value: '117',
      },
      {
        label: 'STP (118)',
        value: '118',
      },
      {
        label: 'SRP (119)',
        value: '119',
      },
      {
        label: 'UTI (120)',
        value: '120',
      },
      {
        label: 'SMP (121)',
        value: '121',
      },
      {
        label: 'SM (122)',
        value: '122',
      },
      {
        label: 'PTP (123)',
        value: '123',
      },
      {
        label: 'ISIS over IPv4 (124)',
        value: '124',
      },
      {
        label: 'FIRE (125)',
        value: '125',
      },
      {
        label: 'CRTP (126)',
        value: '126',
      },
      {
        label: 'CRUDP (127)',
        value: '127',
      },
      {
        label: 'SSCOPMCE (128)',
        value: '128',
      },
      {
        label: 'IPLT (129)',
        value: '129',
      },
      {
        label: 'SPS (130)',
        value: '130',
      },
      {
        label: 'PIPE (131)',
        value: '131',
      },
      {
        label: 'SCTP (132)',
        value: '132',
      },
      {
        label: 'FC (133)',
        value: '133',
      },
      {
        label: 'RSVP-E2E-IGNORE (134)',
        value: '134',
      },
      {
        label: 'Mobility Header (135)',
        value: '135',
      },
      {
        label: 'UDPLite (136)',
        value: '136',
      },
      {
        label: 'MPLS-in-IP (137)',
        value: '137',
      },
      {
        label: 'manet (138)',
        value: '138',
      },
      {
        label: 'HIP (139)',
        value: '139',
      },
      {
        label: 'Shim6 (140)',
        value: '140',
      },
      {
        label: 'WESP (141)',
        value: '141',
      },
      {
        label: 'ROHC (142)',
        value: '142',
      },
      {
        label: '253',
        value: '253',
      },
      {
        label: '254',
        value: '254',
      },
    ],
    portRange: 'ALL',
    value: 'Custom Protocol Rule',
  },
  {
    label: 'ALL TCP',
    portRange: 'ALL',
    value: 'ALL TCP',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'ALL UDP',
    portRange: 'ALL',
    value: 'ALL UDP',
    protocolOptions: [
      {
        label: 'UDP (17)',
        value: '17',
      },
    ],
  },
  {
    label: 'All ICMP - IPv4',
    portRange: 'ALL',
    value: 'All ICMP - IPv4',
    protocolOptions: [
      {
        label: 'ICMP (1)',
        value: '1',
      },
    ],
  },
  {
    label: 'All ICMP - IPv6',
    portRange: 'ALL',
    value: 'All ICMP - IPv6',
    protocolOptions: [
      {
        label: 'IPv6-ICMP (58)',
        value: '58',
      },
    ],
  },
  {
    label: 'ALL Traffic',
    portRange: 'ALL',
    value: 'ALL Traffic',
    protocolOptions: [
      {
        label: 'ALL',
        value: '-1',
      },
    ],
  },
  {
    label: 'SSH (22)',
    portRange: '22',
    value: 'SSH (22)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'telnet (23)',
    portRange: '23',
    value: 'telnet (23)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'SMTP (25)',
    portRange: '25',
    value: 'SMTP (25)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'nameserver (42)',
    portRange: '42',
    value: 'nameserver (42)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'DNS (UDP) (53)',
    portRange: '53',
    value: 'DNS (UDP) (53)',
    protocolOptions: [
      {
        label: 'UDP (17)',
        value: '6',
      },
    ],
  },
  {
    label: 'DNS (TCP) (53)',
    portRange: '53',
    value: 'DNS (TCP) (53)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'HTTP (80)',
    portRange: '80',
    value: 'HTTP (80)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'POP3 (110)',
    portRange: '110',
    value: 'POP3 (110)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'IMAP (143)',
    portRange: '143',
    value: 'IMAP (143)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'LDAP (389)',
    portRange: '389',
    value: 'LDAP (389)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'HTTPS (443)',
    portRange: '443',
    value: 'HTTPS (443)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'SMB (445)',
    portRange: '445',
    value: 'SMB (445)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'SMTPS (465)',
    portRange: '465',
    value: 'SMTPS (465)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'IMAPS (993)',
    portRange: '993',
    value: 'IMAPS (993)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'POP3S (995)',
    portRange: '995',
    value: 'POP3S (995)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'MS SQL (1433)',
    portRange: '1433',
    value: 'MS SQL (1433)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'Oracle (1521)',
    portRange: '1521',
    value: 'Oracle (1521)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'MySQL/Aurora (3306)',
    portRange: '3306',
    value: 'MySQL/Aurora (3306)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'NFS (2049)',
    portRange: '2049',
    value: 'NFS (2049)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'RDP (3389)',
    portRange: '3389',
    value: 'RDP (3389)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'PostgreSQL (5432)',
    portRange: '5432',
    value: 'PostgreSQL (5432)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'Redshift (5439)',
    portRange: '5439',
    value: 'Redshift (5439)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'WinRM-HTTP (5985)',
    portRange: '5985',
    value: 'WinRM-HTTP (5985)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'WinRM-HTTPS (5986)',
    portRange: '5986',
    value: 'WinRM-HTTPS (5986)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'HTTP* (8080)',
    portRange: '8080',
    value: 'HTTP* (8080)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
  {
    label: 'HTTPS* (8443)',
    portRange: '8443',
    value: 'HTTPS* (8443)',
    protocolOptions: [
      {
        label: 'TCP (6)',
        value: '6',
      },
    ],
  },
];
