import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Redirect as ReactRedirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { SecureRoute, Security } from '@okta/okta-react';
import Redirect from './components/common/redirect';
import { ensureConfigIsLoaded, OktaConfig } from './config/okta';
import AuthWidget from './components/auth';
import Dashboard from './views/dashboard';

// css import
import './assets/fonts/tcicon.ttf';
import './assets/css/tcIcon.css';
import './assets/css/tcIconDoc.css';
import './assets/css/navicon.css';
import './assets/css/tc-icons.css';
import './assets/black-dashboard-pro/vendor/font-awesome/css/font-awesome.min.css';
import './assets/black-dashboard-pro/css/nucleo-icons.css';
import './assets/css/customIcon.css';
import './assets/css/icomoon.css';
import 'antd/dist/antd.css';
import './assets/scss/global.scss';
import './assets/css/font.css';
import './assets/css/App.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'jsoneditor/dist/jsoneditor.css';

import WfTemplates from './views/wfTemplates';
import WorkflowEditor from './views/workflowEditor';
import EditWorkflow from './views/editWorkflow';
import OnboardingPage from './views/onboarding';
import { Toaster } from './components/common/toaster';
import Templates from './views/templates';
import Login from './lib/login/pages/Login';
import Signup from './lib/login/pages/Signup';
import Reset from './lib/login/pages/Reset';
import Forgot from './lib/login/pages/Forgot';
import Verification from './lib/login/pages/Verification';
import Logout from './views/logout';
import Activation from './lib/login/pages/Activation';
import Settings from './views/settings';
import Support from './views/support';
import { page } from './analytics/analytics';
import { ImplicitCallbackHandler } from './components/auth/ImplicitCallbackHandler';
import { RouteChildrenProps } from 'react-router';
import { updateTitle } from './lib/editor/components/common/Utils';
import { deviceWidthUpdate } from './components/common/utils';
import TriggerExternally from './views/TriggerExternally';
import ShareWorkflow from './views/ShareWorkflow';
import OktaAuth from './components/auth/oktaAuth';
import { SupportLogin } from './components/auth/supportLogin';
import WorkflowsTabV2 from './views/workflows.v2';
import InsightsTabV2 from './views/insights.v2';
import AutoRemediationTabV2 from './views/autoRemediation.v2';
import FallBack from './views/FallBack';

const SchedulesPage = import('./views/Schedules');
const WorkflowsPage = import('./views/workflows');
const InsightsTabPage = import('./views/InsightsTab');
const InventoryTabPage = import('./views/InventoryTab');
const ActionsHistoryPage = import('./views/ActionsHistory');
const AutoRemediationTabPage = import('./views/AutoRemediationTab');
const UtilisationTabPage = import('./views/UtilisationTab');
const PreferencesPage = import('./views/preferences');
const WorkflowDetailsPage = import('./views/workflowDetails');
const RequestFeaturePage = import('./views/requestFeature');
const EventsPage = import('./views/Events');
const MWFPage = import('./views/managementWorkflows');
const MWF = lazy(() => MWFPage);
const Workflow = lazy(() => WorkflowsPage);
const InsightsTab = lazy(() => InsightsTabPage);
const InventoryTab = lazy(() => InventoryTabPage);
const AutoRemediationTab = lazy(() => AutoRemediationTabPage);
const UtilisationTab = lazy(() => UtilisationTabPage);
const Preferences = lazy(() => PreferencesPage);
const WorkflowDetails = lazy(() => WorkflowDetailsPage);
const ActionsHistory = lazy(() => ActionsHistoryPage);
const Schedules = lazy(() => SchedulesPage);
const RequestFeature = lazy(() => RequestFeaturePage);
const Events = lazy(() => EventsPage);

/*no prefetch since they open through url or not customer facing*/
const Report = lazy(() => import('./views/report'));
const WorkflowsReport = lazy(() => import('./views/workflowsReport'));
const WorkflowsReportV2 = lazy(() => import('./views/workflowsReport-V2'));
const WorkflowsReportLive = lazy(() => import('./views/workflowsReportLive'));
const TcAgentUI = lazy(() => import('./views/tc-agent-ui'));

const RedirectWrapper = withRouter((props: RouteComponentProps) => (
  <Redirect {...props} component={<AuthWidget {...props} />} />
));

function customAuthHandler({
  auth,
  history,
}: RouteChildrenProps & { auth: any }) {
  // Redirect to the /login page that has a CustomLoginComponent
  if (history.location.pathname === '/okta-login') {
    return auth.redirect();
  }
  history.push('/login');
}

const AppRouter = withRouter(function(props: RouteComponentProps) {
  page(props.location.pathname);
  updateTitle();
  ensureConfigIsLoaded();
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Security
        issuer={OktaConfig.issuer}
        client_id={OktaConfig.client_id}
        redirect_uri={OktaConfig.redirect_uri}
        onAuthRequired={customAuthHandler}
      >
        <Switch>
          <Route path="/" exact={true} component={RedirectWrapper} />
          <Route
            path="/implicit/callback"
            component={ImplicitCallbackHandler}
          />

          <Route
            path="/signin/register"
            render={() => <ReactRedirect to={'/signup'} />}
          />
          <Route
            path="/activation/:activationToken"
            exact
            component={Activation}
          />
          <Route path="/okta-login" exact render={OktaAuth} />
          <Route path="/support-login" exact component={SupportLogin} />
          <Route path="/login" exact render={Login} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/reset/:recoveryToken" exact component={Reset} />
          <Route path="/forgotpassword" exact component={Forgot} />
          <Route path="/verification-sent" exact component={Verification} />
          <SecureRoute path="/manage" component={MWF} />
          <SecureRoute
            path="/getting-started"
            exact={true}
            component={OnboardingPage}
          />
          <SecureRoute path="/dashboard" exact={true} component={Dashboard} />
          <SecureRoute
            path="/workflows"
            exact={false}
            component={WorkflowsTabV2}
          />
          <SecureRoute
            path="/insights"
            exact={false}
            component={InsightsTabV2}
          />
          <SecureRoute
            path={`/actions/history`}
            exact={true}
            component={ActionsHistory}
          />
          <SecureRoute
            path="/inventory"
            exact={false}
            component={InventoryTab}
          />
          <SecureRoute
            path="/request-feature"
            exact={true}
            component={RequestFeature}
          />
          <SecureRoute
            path="/auto-remediation"
            exact={true}
            component={AutoRemediationTabV2}
          />
          <SecureRoute
            path="/utilisation"
            exact={false}
            component={UtilisationTab}
          />
          <SecureRoute path="/events" exact={true} component={Events} />

          <SecureRoute path="/schedules" exact={false} component={Schedules} />
          <SecureRoute path="/accounts" exact={false} component={Preferences} />
          <SecureRoute path="/settings" component={Settings} />
          <SecureRoute path="/support" exact={true} component={Support} />
          <SecureRoute
            path="/workflow/:workflow/details"
            exact={false}
            component={WorkflowDetails}
          />
          <SecureRoute path="/editor" exact={true} component={WorkflowEditor} />
          <SecureRoute
            path="/editor/:workflow"
            exact={true}
            component={EditWorkflow}
          />

          <SecureRoute
            path="/newworkflow"
            exact={true}
            render={(props: RouteChildrenProps) => {
              let search = props.location.search;
              return <ReactRedirect to={`/editor/${search}`} />;
            }}
          />
          <SecureRoute
            path="/workflows-report/:reportId"
            exact={true}
            component={WorkflowsReport}
          />
          <SecureRoute
            path="/workflows-report-v2/:userHash/:tabHash/:reportId"
            exact={true}
            component={WorkflowsReportV2}
          />
          <SecureRoute
            path="/workflows-report-live/:userHash/:tabHash/:reportId/:hookUrl"
            exact={true}
            component={WorkflowsReportLive}
          />
          <SecureRoute
            path="/report/:reportId"
            exact={true}
            component={Report}
          />
          <SecureRoute
            path="/schedule/group/:token"
            exact={true}
            component={TriggerExternally}
          />
          <SecureRoute
            path="/workflow-share/:token"
            exact={true}
            component={ShareWorkflow}
          />
          <SecureRoute
            path="/wf-templates-ui"
            exact={false}
            component={WfTemplates}
          />
          <SecureRoute
            path="/tc-agent-admin"
            exact={false}
            component={TcAgentUI}
          />
          <SecureRoute path="/templates" exact={true} component={Templates} />
          <SecureRoute path="/logout" exact={true} component={Logout} />
          <SecureRoute path="/" component={FallBack} />
        </Switch>
      </Security>
    </Suspense>
  );
});

export default class App extends React.Component {
  componentDidMount() {
    deviceWidthUpdate();
    this.addEventHandlers();
  }

  addEventHandlers = () => {
    window.addEventListener('resize', this.updateDevice);
  };

  updateDevice() {
    window.requestAnimationFrame(() => {
      deviceWidthUpdate();
    });
  }

  public render() {
    return (
      <>
        <Toaster />
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>

        {/*disable small screen blocker for now*/}
        {/*{(this.state as any).device === DEVICE_KEY[DEVICE_TYPE.SMALL] ? (*/}
        {/*  <SmallScreenAlert />*/}
        {/*) : null}*/}
      </>
    );
  }
}
