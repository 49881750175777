import * as Yup from 'yup';

export const testSettingsFormSchema = Yup.object({
  createStackWorkflows: Yup.array()
    .of(Yup.string().required())
    .min(1, 'Select at least one workflow'),
  destroyStackWorkflows: Yup.array()
    .of(Yup.string().required())
    .min(
      1,
      'Destroy stack workflow should be available for each create stack workflow',
    ),
  testerWorkflows: Yup.array()
    .of(Yup.string().required()),
  expectedOutput: Yup.object()
    .test('testing Function', 'Please select test function', function(
      expectedOutput,
    ) {
      let keys = Object.keys(expectedOutput || {});
      if (keys.length === 0) return false;
      for (let i = 0; i < keys.length; i++) {
        if (!expectedOutput[keys[i]].testFunction) {
          return false;
        }
      }
      return true;
    })
    .nullable(),

});

export type TestSettingsFormSchema = Yup.InferType<
  typeof testSettingsFormSchema
>;
