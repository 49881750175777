import React, { useState } from 'react';
import { withTcApi } from '../../api/tcApiHelper';
import { withRegionAndCredentials } from '../common/withRegionAndCredentials';
import {
  DeleteAccount,
  DeleteAccountSuccess,
  DeleteAccountError,
} from './components';
import api from '../../api';

/**
 * Delete Account Page Component without Region and Accounts Topbar
 *
 */
export const DeleteAccountPageInner = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [status, setStatus] = useState(null);

  const onDeleteAccount = (reason: string) => {
    api
      .deleteUserAccount({ reason })
      .then((res) => {
        setMessage(
          'Our team will be working on your account deletion and will close your Totalcloud account in 2-3 days.',
        );
        setStatus('success');
      })
      .catch((err) => {
        setMessage(
          'Looks like there is a problem in submitting your request, Please try again later or contact support.',
        );
        setStatus('error');
      });
  };

  if (status === 'success') {
    return <DeleteAccountSuccess message={message} />;
  } else if (status === 'error') {
    return <DeleteAccountError message={message} />;
  }

  return <DeleteAccount onDeleteAccount={onDeleteAccount} />;
};

/**
 * Delete Account Page Component TC API wrapper with Region Topbar
 *
 */
export const DeleteAccountPage = withTcApi(
  withRegionAndCredentials(DeleteAccountPageInner),
);
