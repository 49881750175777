import React from 'react';

export interface TableContextType {
  refreshTableComponents: () => void;
}

const TableContext = React.createContext<TableContextType>({
  refreshTableComponents: () => {},
});

export default TableContext;
