import { components } from 'react-select';
import * as React from 'react';
import classNames from 'classnames';
import { selectAllOption } from './custom-react-multi-select';

export const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      {props.emptyText}
    </components.NoOptionsMessage>
  );
};

const onInnerClick = (props, nestedOption) => {
  let updatedValue = { ...props.data, options: [nestedOption] };
  return props.setValue(updatedValue);
};

const onInnerClickIfMulti = (props, nestedOption) => {
  let options = [];
  let selectedValues = props.getValue() || [];
  let indexOfSelectedGroup =
    selectedValues &&
    selectedValues.findIndex((x) => x.label === props.data.label);

  if (indexOfSelectedGroup === -1) {
    options = [nestedOption];
    selectedValues.push({ ...props.data, options });
  } else {
    options = selectedValues[indexOfSelectedGroup].options;
    let isSelected = options.find((x) => x.value === nestedOption.value);
    if (isSelected)
      options = options.filter((x) => x.value !== nestedOption.value);
    else {
      options.push(nestedOption);
    }
    if (options.length === 0) {
      selectedValues = selectedValues.filter(
        (x, i) => i !== indexOfSelectedGroup,
      );
    } else selectedValues[indexOfSelectedGroup] = { ...props.data, options };
  }

  return props.setValue(selectedValues);
};

const getInnerOption = (props, nestedOption) => {
  const onSelect = () => {
    if (props.isMulti || props.singleSelectCredentialGroup)
      return onInnerClickIfMulti(props, nestedOption);
    return onInnerClick(props, nestedOption);
  };

  const isSelected = () => {
    let selectedValues = props.getValue();
    let indexOfSelectedGroup = selectedValues.findIndex(
      (x) => x.label === props.data.label,
    );
    if (indexOfSelectedGroup === -1) return false;
    let singleSelectedValue = selectedValues[indexOfSelectedGroup];
    let found =
      singleSelectedValue &&
      selectedValues[indexOfSelectedGroup].options &&
      selectedValues[indexOfSelectedGroup].options.find(
        (x) => x.value === nestedOption.value,
      );

    return Boolean(found);
  };
  return (
    <div className="nested-optgroup-option pl-3" key={nestedOption.value}>
      <components.Option
        className={classNames({
          nestedInputSelected: isSelected(),
          nestedInput: !isSelected(),
          'd-flex': true,
        })}
        {...props}
        isSelected={isSelected()}
        isFocused={undefined}
        innerProps={{
          ...props.innerProps,
          onClick: (e) => {
            return onSelect();
          },
        }}
      >
        {props.isMulti && (
          <input type="checkbox" checked={isSelected()} onChange={() => null} />
        )}
        <label title={nestedOption.label} className={'text-truncate ml-2'}>
          {nestedOption.label}
        </label>
      </components.Option>
    </div>
  );
};

const renderNestedOption = (props, nestedOptions) => {
  const { data, label, isSelected, searchString } = props;
  const arePartialChildrenSelected = () => {
    let selectedValues = props.getValue() || [];
    let indexOfSelectedGroup = selectedValues.findIndex(
      (x) => x.label === props.data.label,
    );
    if (indexOfSelectedGroup === -1) return false;

    let singleSelectValue = selectedValues[indexOfSelectedGroup];
    let propOptions = props.data.options;
    return (
      propOptions &&
      propOptions.length !== singleSelectValue &&
      singleSelectValue.options &&
      singleSelectValue.options.length
    );
  };
  const getSelectedOptionsCount = () => {
    let selectedValues = props.getValue() || [];
    let indexOfSelectedGroup = selectedValues.findIndex(
      (x) => x.label === props.data.label,
    );
    if (indexOfSelectedGroup === -1) return 0;

    let singleSelectValue = selectedValues[indexOfSelectedGroup];
    return (
      singleSelectValue &&
      singleSelectValue.options &&
      singleSelectValue.options.length
    );
  };

  if (
    searchString &&
    !nestedOptions.find(
      (x) =>
        x.label.toLocaleLowerCase().includes(searchString) ||
        x.value.toLocaleLowerCase().includes(searchString),
    )
  )
    return <div></div>;
  return (
    <div className="nested-optgroup">
      <div className={'d-flex '}>
        <div onClick={() => props.onExpand(props.data)}>
          <i
            className={classNames({
              'pl-4  ml-1 text-muted': true,
              'fa fa-caret-down': props.data.expanded,
              'fa fa-caret-right': !props.data.expanded,
            })}
          />
        </div>
        <components.Option
          {...props}
          className={'d-flex pl-1 mb-2'}
          innerProps={{
            ...props.innerProps,
            onClick:
              props.isMulti && !props.singleSelectWithinGroup
                ? props.innerProps.onClick
                : () => null,
          }}
        >
          {props.isMulti && !props.singleSelectWithinGroup && (
            <input
              type="checkbox"
              checked={isSelected && !arePartialChildrenSelected()}
              onChange={() => null}
              ref={(input) => {
                if (input) {
                  input.indeterminate = arePartialChildrenSelected();
                }
              }}
            />
          )}{' '}
          <div
            title={label}
            style={{ maxWidth: '100px' }}
            className={'text-truncate ml-2'}
          >
            <span>{label}</span>
          </div>
          {props.isMulti && getSelectedOptionsCount() !== 0 && (
            <small className={'ml-2 text-lowercase text-muted'}>
              {`(${getSelectedOptionsCount()} selected)`}
            </small>
          )}
        </components.Option>
      </div>

      {data.expanded &&
        nestedOptions.map((nestedOption) => {
          if (
            searchString &&
            !nestedOption.label.toLocaleLowerCase().includes(searchString) &&
            !nestedOption.value.toLocaleLowerCase().includes(searchString)
          )
            return;
          return getInnerOption(props, nestedOption);
        })}
    </div>
  );
};

export const Option = (props) => {
  const { data, isSelected, label } = props;

  const nestedOptions = data.options;

  if (nestedOptions) {
    return renderNestedOption(props, nestedOptions);
  }

  if (
    props.label === selectAllOption.label &&
    props.data.value === selectAllOption.value
  ) {
    return <RenderSelectAll {...props} />;
  }

  return (
    <div>
      <components.Option {...props} className={'d-flex'}>
        {props.isMulti ? (
          <input type="checkbox" checked={isSelected} onChange={() => null} />
        ) : (
          <input type="radio" checked={isSelected} onChange={() => null} />
        )}
        <label title={label} className={'text-truncate ml-2'}>
          {label}
        </label>
      </components.Option>
    </div>
  );
};

const RenderSelectAll = (props) => {
  const { isSelected, label, getValue, options } = props;

  const arePartialChildrenSelected = () => {
    let selectedValues = getValue();
    let availableOptions = options.find((x) => x.options)
      ? options.map((x) => x.options || []).reduce((A, e) => A.concat(e), [])
      : options;
    availableOptions = availableOptions.filter(
      (x) => x.value !== selectAllOption.value,
    );
    selectedValues = selectedValues.filter(
      (x) => x.value !== selectAllOption.value,
    );
    if (selectedValues.length === 0) return false;
    if (selectedValues.length != availableOptions.length) return true;

    // if not partial check if any nested group is partial.

    let nestedGroups = selectedValues.filter((x) => x.options);
    if (nestedGroups.length === 0) return false; // if no nested group available
    //
    let partialSelectedNestedGroup = nestedGroups.find((x) => {
      let availableOptionsValue = availableOptions.find(
        (g) => g.label === x.label,
      );
      return (
        x.options &&
        x.options.length !== availableOptionsValue &&
        availableOptionsValue.options &&
        availableOptionsValue.options.length
      );
    });

    return !!partialSelectedNestedGroup;
  };

  return (
    <components.Option {...props} className={'d-flex pl-4'}>
      {props.isMulti && (
        <input
          type="checkbox"
          checked={isSelected && !arePartialChildrenSelected()}
          onChange={() => null}
          ref={(input) => {
            if (input) {
              input.indeterminate = arePartialChildrenSelected();
            }
          }}
        />
      )}
      <label title={label} className={'text-truncate ml-2'}>
        {label}
      </label>
    </components.Option>
  );
};
