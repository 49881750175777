import * as React from 'react';
import { AppContainer } from './style';
import { withTcApi } from '../api/tcApiHelper';
import TriggerActionExternally from '../components/schedules/components/TriggerActionExternally';

class TriggerExternally extends React.Component<
  { location: any; match: any },
  { token: string }
> {
  state = {
    token: this.props.match.params.token,
  };

  render(): React.ReactNode {
    return (
      <AppContainer fluid={true}>
        <TriggerActionExternally token={this.state.token} />
      </AppContainer>
    );
  }
}

export default withTcApi(TriggerExternally);
