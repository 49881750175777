import { destroyWorkflow } from './../workflows/action';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';

export type TemplateTestSettings = {
  createStackWorkflows: string[];
  destroyStackWorkflows: string[];
  testerWorkflows: string[],
  expectedOutput: {
    [s: string]: {
      testFunction: string;
    };
  };
};

export type TemplateTestStatus = {
  workflowId: string;
  executionId: string;
  testedOn: string;
  status: string;
};
export interface Template {
  category?: string[];
  subCategory?: string[];
  categories: {
    category: string;
    subCategories: string[];
  }[];
  definition: string;
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;

  properties: {
    allowedAccountTypes?: CONFIG_TYPES[];
    testSettings?: TemplateTestSettings;
    severity?: number;
    workflowRevision?: string;
    queueable?: boolean;
    nodeDescription?: { [nodeId: string]: string };
    testStatus?: TemplateTestStatus;
    linkedTemplates?: string[];
    webflow?: {
      publishedOn?: string;
      cmsItemId?: string;
      isLatestPublished?: boolean;
      slug?: string;
    };
    priority?: number;
    active: boolean;
    originWorkflow?: string;
  };
}

// events
export const FetchTemplate = 'FetchTemplate';
export const FetchTemplates = 'FetchTemplates';
export const TemplateFetchSuccess = 'TemplateFetchSuccess';
export const SelectTemplateFetchSuccess = 'SelectTemplateFetchSuccess';
export const TemplateAdoptionRequest = 'TemplateAdoptionRequest';
export const TemplateAdoptionResponse = 'TemplateAdoptionResponse';

interface TemplateAdoptionResponse {
  type: typeof TemplateAdoptionResponse;
}

interface TemplateAdoptionRequest {
  type: typeof TemplateAdoptionRequest;
}

interface TemplateAdoptionResponse {
  type: typeof TemplateAdoptionResponse;
}

interface FetchTemplate {
  type: typeof FetchTemplate;
}

interface FetchTemplates {
  type: typeof FetchTemplates;
}

interface TemplateFetchSuccess {
  type: typeof TemplateFetchSuccess;
  data: Template[];
}
interface SelectTemplateFetchSuccess {
  type: typeof SelectTemplateFetchSuccess;
  selected: Template;
}

export type TemplatesAction =
  | FetchTemplate
  | FetchTemplates
  | TemplateFetchSuccess
  | SelectTemplateFetchSuccess
  | TemplateAdoptionRequest
  | TemplateAdoptionResponse;

export interface TemplateState {
  fetching: boolean;
  adopting: boolean;
  data: Template[] | null;
  selected: Template | null;
}
