import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import React, { useCallback, useMemo } from 'react';
import { periodMap } from '../constants';
import _ from 'lodash';

interface PeriodDropdownProps<T> {
  options?: T[];
  onChange: (option: T) => void;
  selected?: T;
}

/**
 * Render a period selector for the Graph, accept a list of options , and an onChange function to send changes to parent options, and a preselected option.
 * @param [] options
 * @param onChange
 * @param selected
 * @constructor
 */
export const PeriodDropDown = React.memo<
  PeriodDropdownProps<{ key: string; value: number }>
>(
  ({ options, onChange, selected }) => {
    // Calculating the dropdownOptions Only if options prop changes.

    const dropdownOptions = useMemo(
      () =>
        options ||
        Object.entries(periodMap).map(([value, key]) => ({
          key,
          value: Number(value),
        })),
      [options],
    );

    // Dropdown text Calculation if selected is changed.
    const DropdownText = useMemo(() => selected?.key, [selected]);

    const onChangePeriod = useCallback(
      (option) => {
        onChange(option);
      },
      [dropdownOptions],
    );

    return (
      <div className={'d-flex align-items-center'}>
        <UncontrolledDropdown>
          <DropdownToggle color={'link'} size={'sm'}>
            Period {DropdownText}
          </DropdownToggle>
          <DropdownMenu>
            {dropdownOptions.map((opt, index) => (
              <DropdownItem
                data-testid={`chart-period-selector-${index}`}
                active={opt.value === selected.value}
                key={JSON.stringify(opt)}
                onClick={() => onChangePeriod(opt)}
              >
                {opt.key}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  },
  (prevProps, nexProps) => _.isEqual(prevProps, nexProps),
);
