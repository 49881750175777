import * as React from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Toaster extends React.Component {
  render(): React.ReactNode {
    return (
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnHover
        transition={Slide}
      />
    );
  }
}

type ToastProps = {
  type?: 'info' | 'success' | 'warn' | 'error';
  message: string | JSX.Element;
};

let isBeingUsed = false;
setInterval(() => {
  isBeingUsed = false;
}, 1500);

export function notify(props: ToastProps) {
  if (isBeingUsed) return;
  if (props.type) {
    toast[props.type](props.message);
  } else {
    toast(props.message);
  }

  isBeingUsed = true;
}
