import * as React from 'react';
import {
  Badge,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import InputSelect from '../../../lib/formInputs/select-option.component';
import { ICustomTableCol } from '../../common/table';
import { Fragment } from 'react';
import {
  tagColDisplayFormatter,
  tagHeaderDisplayFormatter,
} from './formatter/TagsColFormatter';

interface IProps {
  data: any[];
  toggleModal: (value: boolean) => void;
  tags: { key: string; value: string }[];
  extraCols: ICustomTableCol[];
  onSaveExtraCols: (extraCols: ICustomTableCol[]) => void;
}
interface IState {
  selectedTagKey: string;
  selectedTagValue: string;
  tabsPill: string[];
}

class ControlOptionsTagModal extends React.Component<IProps, IState> {
  state: IState = {
    selectedTagKey: '',
    selectedTagValue: '',
    tabsPill: this.props.extraCols.map((x) => x.text),
  };

  getTagsValues = () => {
    let filteredTags = this.props.tags;
    let { selectedTagKey } = this.state;
    if (selectedTagKey)
      filteredTags = filteredTags.filter((x) => x.key === selectedTagKey);
    return filteredTags
      .map((x) => x.value)
      .filter((x, i, a) => a.indexOf(x) === i)
      .sort();
  };

  addTagKeyValue = () => {
    let { selectedTagKey, selectedTagValue, tabsPill } = this.state;
    if (!selectedTagKey) return;
    if (selectedTagValue)
      tabsPill.push(`${selectedTagKey}=${selectedTagValue}`);
    else tabsPill.push(`${selectedTagKey}`);
    this.setState({ tabsPill, selectedTagValue: '', selectedTagKey: '' });
  };

  onSave = () => {
    let extraCols: ICustomTableCol[] = this.state.tabsPill.map((x) => {
      return {
        headerFormatter: tagHeaderDisplayFormatter,
        headerStyle: { 'max-width': '12%' },
        dataField: 'tags',
        text: x,
        colName: x,
        sort: true,
        formatter: (col: { [s: string]: number }) =>
          tagColDisplayFormatter(col, x),
      };
    });
    this.props.onSaveExtraCols(extraCols);
    this.props.toggleModal(false);
  };

  removeTagKeyValue = (pillValue) => {
    let { tabsPill } = this.state;
    tabsPill = tabsPill.filter((x) => x !== pillValue);
    this.setState({ tabsPill });
  };

  render() {
    let tags = getTags(this.props.data);
    let tagKeys = tags
      .map((x) => x.key)
      .filter((x, i, a) => a.indexOf(x) === i)
      .sort();

    return (
      <Modal size={'lg'} backdrop={'static'} centered={true} isOpen={true}>
        <ModalHeader toggle={() => this.props.toggleModal(false)}>
          Control
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>Choose how to view resources split</Col>
          </Row>
          <Row>
            <Col className={'d-flex  mt-2'}>
              {this.state.tabsPill.map((x, i) => {
                return (
                  <Badge
                    key={i}
                    color="primary"
                    style={{ maxWidth: '150px' }}
                    className={'d-flex'}
                  >
                    <span
                      className={'align-self-center text-truncate'}
                      title={x}
                    >
                      {x}{' '}
                    </span>
                    <i
                      className={'fa fa-close float-right ml-2'}
                      onClick={() => this.removeTagKeyValue(x)}
                    />
                  </Badge>
                );
              })}
            </Col>
          </Row>
          <Row className={'mb-4'}>
            <Col md={5}>
              <InputSelect
                readOnly={this.state.tabsPill.length >= 3}
                name={'tag-data-list'}
                value={this.state.selectedTagKey}
                onChange={(e) => {
                  this.setState({ selectedTagKey: e.target.value });
                }}
                label={'Tag Key'}
                options={tagKeys.map((x) => ({ label: x, value: x }))}
              />
            </Col>
            <Col md={5}>
              <InputSelect
                readOnly={this.state.tabsPill.length >= 3}
                name={'tag-data-list'}
                value={this.state.selectedTagValue}
                onChange={(e) => {
                  this.setState({ selectedTagValue: e.target.value });
                }}
                label={'Tag Value(optional)'}
                options={this.getTagsValues().map((x) => ({
                  label: x,
                  value: x,
                }))}
              />
            </Col>
            <Col md={2} className="pt-4 mt-1">
              <Button
                color={'primary'}
                className="px-3"
                onClick={this.addTagKeyValue}
              >
                Add
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button color={'primary'} onClick={this.onSave}>
                Save
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

const getTags = (data: any[]): { key: string; value: string }[] => {
  return data
    .map((x) => Object.keys(x.tags || {}))
    .reduce((A, e) => A.concat(e), [])
    .filter((x, i, a) => a.indexOf(x) === i)
    .map((x) => {
      let key = x.split('=')[0];
      let value = x.split('=')[1];
      return { key, value };
    });
};

export const ControlTagsOption = function(props) {
  const { data, onSaveExtraCols, extraCols } = props;
  const [isModalOpen, toggleModal] = React.useState(false);
  return (
    <>
      <Button color={'primary'} size={'sm'} onClick={() => toggleModal(true)}>
        Groups
      </Button>
      {isModalOpen && (
        <ControlOptionsTagModal
          onSaveExtraCols={onSaveExtraCols}
          data={data}
          toggleModal={toggleModal}
          tags={getTags(data)}
          extraCols={extraCols}
        />
      )}
    </>
  );
};
