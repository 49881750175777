import React from 'react';
import { Workflow } from '../../../../lib/store/workflows/types';
import { ExecutionStatusEnum, parseExecutionStatus } from '../ExecutionStatus';
import { SummaryChart } from '../../../../lib/charts/summaryChart';
import { AppState } from '../../../../lib/store';
import { connect } from 'react-redux';
import { Config } from '../../../../lib/store/config/types';
import { ICustomTableCol } from '../../../common/table';
import {
  getGroupedWorkflows,
  getServiceTableData,
  getSummaryTableData,
} from '../dataFormatter/summaryDataFormatter';
import { VIEW } from '../workflowTable';
import { SEVERITY } from 'workflow-model/dist';

export type WorkflowS = Workflow & {
  result: { workflowOutput: ExecutionStatusEnum };
  isFetchingExecution: boolean;
};

type IWorkflowSummaryProps = {
  data: WorkflowS[];
  isReady: boolean;
  columns: ICustomTableCol[];
  emptyText: string;
  options: any;
  updateConfigAndRegion: (key: string, value: string[]) => void;
};

export function getCategoryValue(
  workflow: WorkflowS,
  property: string,
): string {
  const {
    properties: { categories },
  } = workflow;
  let value = 'None';
  if (categories) {
    const c = categories.find((x) => x.category === property);
    if (c && c.subCategories[0]) {
      value = c.subCategories[0];
    }
  }
  return value;
}

export type InsightSummaryResult = {
  all: number;
  failing: number;
  passing: number;
  unknown: number;
  high: number;
  medium: number;
  low: number;
};

const getPercent = (num, total) => {
  return Math.ceil((num * 100) / (total || 1));
};

export function getResultCount(workflows: WorkflowS[]): InsightSummaryResult {
  let failing = 0,
    passing = 0,
    unknown = 0,
    all = 0,
    highSeverity = 0,
    mediumSeverity = 0,
    lowSeverity = 0;

  for (const workflow of workflows) {
    let severity = workflow.properties
      ? workflow.properties.severity
      : SEVERITY.LOW;
    if (workflow.result && workflow.result.workflowOutput) {
      if (workflow.result.workflowOutput === ExecutionStatusEnum.Loading) {
        continue;
      }
      let status = workflow.result.workflowOutput as string;
      const { isFailure, isSuccess } = parseExecutionStatus(status);
      if (isSuccess) {
        passing += 1;
      } else if (isFailure) {
        if (severity === SEVERITY.LOW) lowSeverity += 1;
        else if (severity === SEVERITY.MEDIUM) mediumSeverity += 1;
        else if (severity === SEVERITY.HIGH) highSeverity += 1;
        failing += 1;
      } else {
        unknown += 1;
      }
      all += 1;
    }
  }

  let failingPer = getPercent(failing, all);
  let passingPer = getPercent(passing, all);
  let unknownPer = getPercent(unknown, all);
  let highSeverityPer = getPercent(highSeverity, all);
  let mediumSeverityPer = getPercent(mediumSeverity, all);
  let lowSeverityPer = getPercent(lowSeverity, all);
  return {
    all,
    failing: failingPer,
    passing: passingPer,
    unknown: unknownPer,
    high: highSeverityPer,
    low: lowSeverityPer,
    medium: mediumSeverityPer,
  };
}

// tree util
export function expandChildren(
  workflows: WorkflowS[],
  properties: string[],
  updateConfigAndRegion: Function,
) {
  const property = properties[0];
  // console.log('property', property);
  const groups = {};
  for (const workflow of workflows) {
    const group = getCategoryValue(workflow, property);
    if (!groups[group]) groups[group] = [];
    groups[group].push(workflow);
  }
  return Object.keys(groups).map((group) => {
    return {
      name: group,
      accountId: group,
      updateConfigAndRegion: updateConfigAndRegion,
      value: getResultCount(groups[group]),
      children:
        properties.length && properties.length > 1
          ? expandChildren(
              groups[group],
              properties.slice(1),
              updateConfigAndRegion,
            )
          : null,
    };
  });
}

export const InsightSummaryDataFormatter = (props) => {
  if (props.view === VIEW.SERVICE || props.view === VIEW.PURE_SERVICE) {
    return getServiceTableData(props.data, props.config).map((x) => ({
      ...x,
      changeView: props.changeView,
      onChangeSeverityFilter: props.onChangeSeverityFilter,
      onChangeAccountFilter: props.onChangeAccountFilter,
      onChangeResultFilter: props.onChangeResultFilter,
      onChangeServiceFilter: props.onChangeServiceFilter,
      isFetching: props.isFetchingExecutions,
    }));
  }
  let groupedObj = getGroupedWorkflows({
    workflows: props.data,
    configs: props.config,
  });
  return getSummaryTableData(groupedObj).map((x) => ({
    ...x,
    isFetching: props.isFetchingExecutions,
    changeView: props.changeView,
    updateConfigAndRegion: props.updateConfigAndRegion,
    onChangeSeverityFilter: props.onChangeSeverityFilter,
    onChangeAccountFilter: props.onChangeAccountFilter,
    onChangeResultFilter: props.onChangeResultFilter,
    onChangeServiceFilter: props.onChangeServiceFilter,
  }));

  // const hierarchy = ['Account', 'Insights'];
  // let data = expandChildren(props.data, hierarchy, props.updateConfigAndRegion);
  // data = replaceConfigIds(props.config, data);
  // return data;
};

type StateProps = {
  config?: Config[];
};

type IProps = IWorkflowSummaryProps & StateProps;

export function replaceConfigIds(config, data) {
  let summaryData = [];
  data.forEach((summary) => {
    let account = config && config.find((x) => x.id === summary.name);
    if (account) {
      summaryData.push({
        ...summary,
        name: account && account.name,
      });
    }
  });
  return summaryData;
}

// type same as table
function WorkflowSummaryInner(props: IProps) {
  const hierarchy = ['Account', 'Insights'];
  let data = expandChildren(props.data, hierarchy, props.updateConfigAndRegion);
  /// replace config ids with actual config names
  data = replaceConfigIds(props.config, data);
  if (!props.isReady) {
    return (
      <div className="w-100 p-5 my-3 text-center">
        Loading summary
        <i className="fa fa-spin fa-spinner ml-1" />
      </div>
    );
  }
  return (
    <>
      <h4>Summary</h4>
      <SummaryChart
        id={'root'}
        data={data}
        labels={{ title: 'Insight Summary' }}
        skipSingleValueItems={true}
      />
    </>
  );
}

function mapStateToProps(
  state: AppState,
  ownProps: IWorkflowSummaryProps,
): StateProps {
  return {
    config: state.config.data,
  };
}

export const InsightSummary = connect<StateProps>(mapStateToProps)(
  WorkflowSummaryInner,
);
