import {
  OrgGroup,
  OrgPolicy,
  OrgUser,
  Tenant,
} from '../../lib/store/tenants/types';
import React from 'react';
import { AppState } from '../../lib/store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  addGroup,
  deleteGroup,
  fetchGroups,
  fetchUsers,
  updateGroup,
} from '../../lib/store/tenants/action';
import LineLoader from '../common/LineLoader';
import {
  Badge,
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
} from 'reactstrap';
import CustomTable, { ICustomTableCol } from '../common/table';
import { confirm } from './util';
import { AddGroup } from './AddGroup';
import { Config } from '../../lib/store/config/types';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';
import { ORDER } from '../workflows/common/workflowTable';
import { EditGroup } from './EditGroup';
import classNames from 'classnames';

type OwnProps = {};

type StateProps = {
  isFetchingGroups: boolean;
  isFetchingUsers: boolean;
  isAddingGroup: boolean;
  isDeletingGroup: boolean;
  isFetchingConfig: boolean;
  isUpdatingGroup: boolean;
  tenant: Tenant;
  config: Config[];
  users: OrgUser[];
  groups: OrgGroup[];
};

type DispatchProps = {
  fetchUsers: () => void;
  fetchGroups: () => void;
  addGroup: (name: string, users: OrgUser[], accounts: string[]) => void;
  modifyGroup: (name: string, groupId: string, accounts: string[]) => void;
  deleteGroup: (group: OrgGroup) => void;
};

type IProps = OwnProps & DispatchProps & StateProps;

class OrgGroupsInner extends React.Component<IProps> {
  getGroups = () => {
    return this.props.groups.map((u) => {
      return {
        ...u,
        deleteGroup: () =>
          confirm().then((proceed) => proceed && this.props.deleteGroup(u)),
      };
    });
  };

  componentDidMount(): void {
    this.props.fetchGroups();
    this.props.fetchUsers();
  }

  actionFormatter = (cell: any, group: OrgGroup) => {
    return (
      <EditGroup
        config={this.props.config}
        existingGroups={this.props.groups}
        group={group}
        modify={this.props.modifyGroup}
      >
        {({ toggleModal }) => (
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="primary" outline={true}>
              Actions <i className="fa fa-chevron-circle-down ml-2" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem color="default" size="sm" onClick={toggleModal}>
                <i className="fa fa-edit mr-2" /> Edit
              </DropdownItem>
              <DropdownItem
                color="danger"
                size="sm"
                onClick={group['deleteGroup']}
              >
                <i className="fa fa-minus-circle mr-2" /> Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )}
      </EditGroup>
    );
  };

  policyFormatter = (policies: OrgPolicy[]) => {
    const accounts = policies.reduce(
      (a: any[], e: OrgPolicy) => a.concat(e.resources),
      [],
    );
    return (
      <>
        {accounts.map((account, key) => {
          const config = this.props.config.find((u) => u.id === account);
          return (
            <Badge key={key} color={'light'} className="text-capitalize">
              {config ? config.name : account}
            </Badge>
          );
        })}
      </>
    );
  };

  getColumns(): ICustomTableCol[] {
    return [
      {
        colName: 'Group Name',
        dataField: 'name',
        text: 'Group Name',
        sort: true,
        classes: 'text-truncate',
      },
      {
        colName: 'Member Count',
        dataField: 'users.length',
        text: 'Member Count',
        sort: true,
      },
      {
        colName: 'Accounts',
        dataField: 'policies',
        text: 'Accounts',
        classes: 'text-capitalize',
        formatter: this.policyFormatter,
      },
      {
        colName: 'Actions',
        dataField: '',
        text: '',
        formatter: this.actionFormatter,
        classes: 'text-right',
      },
    ];
  }

  render() {
    const isBusy =
      this.props.isFetchingConfig ||
      this.props.isFetchingUsers ||
      this.props.isFetchingGroups ||
      this.props.isAddingGroup ||
      this.props.isDeletingGroup ||
      this.props.isUpdatingGroup;
    const config = this.props.config.filter((u) =>
      [
        CONFIG_TYPES.AWS_ROLE,
        CONFIG_TYPES.AWS_KEY,
        CONFIG_TYPES.AWS_PROXY,
        CONFIG_TYPES.AZURE,
      ].includes(u.type),
    );
    return (
      <>
        {isBusy && <LineLoader />}
        <CustomTable
          sortField={'name'}
          order={ORDER.ASCENDING}
          options={
            <Col md={8} className="d-flex justify-content-end">
              <Button
                color={'primary'}
                outline={true}
                onClick={this.props.fetchGroups}
              >
                <i
                  className={classNames({
                    'fa fa-refresh': true,
                    'fa-spin': this.props.isFetchingGroups,
                  })}
                />
              </Button>
              <AddGroup
                config={config}
                users={this.props.users}
                existingGroups={this.props.groups}
                addGroup={this.props.addGroup}
              />
            </Col>
          }
          emptyText={
            isBusy
              ? 'Fetching groups in this organisation ....'
              : 'Create groups into your organisation'
          }
          data={this.getGroups()}
          columns={this.getColumns()}
          divId={'org-users'}
          responsive={false}
        />
      </>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetchingUsers: state.tenantConfig.isFetchingUsers,
    isFetchingGroups: state.tenantConfig.isFetchingGroups,
    isFetchingConfig: state.config.fetching,
    tenant: state.tenantConfig.tenant,
    config: state.config.data || [],
    users: state.tenantConfig.orgUsers,
    groups: state.tenantConfig.orgGroups,
    isAddingGroup: state.tenantConfig.isAddingGroup,
    isDeletingGroup: state.tenantConfig.isDeletingGroup,
    isUpdatingGroup: state.tenantConfig.isUpdatingGroup,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps,
): DispatchProps {
  return {
    addGroup: (name, users, accounts) =>
      addGroup({ dispatch, name, users, accounts }),
    deleteGroup: (group) => deleteGroup({ dispatch, group }),
    fetchUsers: () => fetchUsers({ dispatch }),
    fetchGroups: () => fetchGroups({ dispatch }),
    modifyGroup: (name, groupId, accounts) =>
      updateGroup({ dispatch, name, groupId, accounts }),
  };
}

export const OrgGroups = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgGroupsInner);
