import {
  INode,
  IOption,
  OutputKey,
  SerializedOptions,
  workflowOutputKeysSchema,
} from 'workflow-model/dist';

export interface IComboBoxInput {
  label: string;
  value: string;
}

export const getAutoCompleteList = (props: {
  lastNodeOutputKeys: OutputKey[];
  workflowOutputSchema?: workflowOutputKeysSchema;
  definitionVariables?: Array<SerializedOptions>;
  selectedNode?: INode;
}) => {
  let autoCompletionList =
    props.lastNodeOutputKeys &&
    props.lastNodeOutputKeys.map((x) => 'obj.' + x.name);
  if (props.workflowOutputSchema) {
    let otherNodesKeys = Object.keys(props.workflowOutputSchema)
      .filter((x) => x !== props.selectedNode.getNodeId())
      .map((nodeId) => {
        let keys = Object.keys(props.workflowOutputSchema[nodeId])
          .map((keyType) =>
            props.workflowOutputSchema[nodeId][keyType].map(
              (x) => `${keyType}.${x}`,
            ),
          )
          .reduce((A, e) => A.concat(e), []);
        return keys.map((k) => `$wf.${nodeId}.${k}`);
      })
      .reduce((A, e) => A.concat(e), []);
    autoCompletionList = autoCompletionList.concat(otherNodesKeys);
  }
  if (props.definitionVariables) {
    let variables = props.definitionVariables.map((x) => {
      return `$wf.variables.${x.key}`;
    });
    autoCompletionList = autoCompletionList.concat(variables);
  }
  return autoCompletionList;
};
