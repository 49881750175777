import * as React from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { ButtonText } from '../../onboarding/common/buttonText';
import classnames from 'classnames';
import { ButtonRow } from '../buttonRow';

interface IProps {
  lists: {
    label: string;
    value: string;
  }[];
  selected: string;
  onSelect(data?: any): void;
}

export class CategoryList extends React.Component<IProps> {
  isActive(value: string) {
    return this.props.selected === value;
  }

  renderList = (li: any[]) => {
    return li.map((list, key) => (
      <span
        className={`mr-3 font-14 test-categories cursor-pointer text-capitalize ${
          this.isActive(list.value) ? 'text-primary' : ''
        }`}
        id={list.value}
        onClick={() => this.props.onSelect(list.value)}
        key={key}
      >
        {list.label}
      </span>
    ));
  };

  renderExtras = (li: any[]) => {
    if (!li.length) return null;
    return (
      <UncontrolledDropdown>
        <DropdownToggle caret color={'link'} className={'border'}>
          +{li.length} Items
        </DropdownToggle>
        <DropdownMenu>
          {li.map((list, key) => (
            <DropdownItem
              active={this.isActive(list.value)}
              id={list.value}
              onClick={() => this.props.onSelect(list.value)}
              key={key}
            >
              {list.label.toUpperCase()}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  render(): React.ReactNode {
    const { lists } = this.props;
    if (!lists) return null;
    return (
      <ButtonRow
        renderComponent={this.renderList}
        renderGroupedComponents={this.renderExtras}
        components={lists}
        getId={(li) => li.value}
        focusedElement={this.props.selected}
      />
    );
  }
}
