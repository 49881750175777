import * as React from 'react';
import { Workflow } from '../../../../lib/store/workflows/types';
import { loader } from '../../../templates-editor/components/cols/loader';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { COL_NAMES } from '../workflowTableHelper';
import { ModalForEditFix } from '../ChangeFixModal';
import {
  filterItemsByExcludeCategory,
  filterItemsByMultipleCategories,
} from 'webapp-genric/dist/filters';
import { WorkflowExecutionOutput } from '../../../../lib/store/executions/types';
import { ExecutionStatusEnum } from '../ExecutionStatus';
import { Config } from '../../../../lib/store/config/types';
import { getAccountConfig } from '../../../common/withRegionAndCredentials';

export type FixColDataType = {
  configs: Config[];
  autoRunLinkedWorkflows: boolean;
  allWorkflows: { workflow: string; name: string; properties: any }[];
  linkedTemplateName: string;
  parentWorkflowId: string;
  account: string;
  region: string;
  group: string;
  linkedTemplate: string;
  linkedWorkflow: string;
  linkedWorkflowOutput: WorkflowExecutionOutput;
  isFetchingWorkflow: boolean;
  refetchWorkflows: (skipResetExecutionRequest?: boolean) => void;
  adoptTemplateAndRunFix?: (props: {
    parentWorkflowId: string;
    templateId: string;
    regions: string[];
    accounts: string[];
    runFix?: boolean;
  }) => void;
  runLinkedWorkflow: (props: {
    parentWorkflowId: string;
    workflow: string;
  }) => void;
  updateLinkedWorkflow: (props: {
    parentWorkflowId: string;
    workflow: string;
    runFix?: boolean;
  }) => void;
  updateAutoRunOfLink: (props: {
    parentWorkflowId: string;
    status: boolean;
  }) => void;
};

export const fixColDataFormatter = (
  data,
  configs: Config[],
): FixColDataType => {
  let region = getRegionFromWorkflow(data);
  let account = getAccountFromWorkflow(data);
  let allWorkflows = filterItemsByExcludeCategory(data.allWorkflows || [], [
    {
      category: 'Insights',
      subCategories: [],
    },
  ]);
  allWorkflows = filterItemsByMultipleCategories(allWorkflows || [], [
    { category: 'Region', subCategories: [region || ''] },
    { category: 'Account', subCategories: [account || ''] },
  ]);
  let linkedTemplate =
    data.properties &&
    data.properties.linkedTemplates &&
    data.properties.linkedTemplates[0];
  let linkedTemplateObj = (data.allTemplates || []).find(
    (x) => x.id === linkedTemplate,
  );
  return {
    configs: getAccountConfig(configs),
    isFetchingWorkflow: data.isFetching,
    refetchWorkflows: data.fetchWorkflows,
    autoRunLinkedWorkflows:
      data.properties && data.properties.autoRunLinkedWorkflows,
    allWorkflows,
    linkedWorkflowOutput: data.linkedWorkflowOutput,
    parentWorkflowId: data.workflow,
    region,
    account,
    group: 'insights-fix-workflows',
    linkedTemplateName: linkedTemplateObj ? linkedTemplateObj.name : undefined,
    linkedTemplate: linkedTemplateObj ? linkedTemplateObj.id : undefined,
    linkedWorkflow:
      data.properties &&
      data.properties.linkedWorkflows &&
      data.properties.linkedWorkflows[0],
    adoptTemplateAndRunFix: data.adoptTemplateAndRunFix,
    runLinkedWorkflow: data.runLinkedWorkflow,
    updateLinkedWorkflow: data.updateLinkedWorkflow,
    updateAutoRunOfLink: data.updateAutoRunOfLink,
  };
};

export const getRegionFromWorkflow = (wf: Workflow) => {
  return getSubCategory(wf, 'Region');
};

const getSubCategory = (
  wf: Workflow,
  category: string,
  returnAll = false,
): string => {
  let cat =
    wf.properties &&
    wf.properties.categories &&
    wf.properties.categories.find((c) => c.category === category);
  if (!cat || !cat.subCategories) return;
  return cat.subCategories[0];
};

const getSubCategories = (wf: Workflow, category: string): string[] => {
  let cat =
    wf.properties &&
    wf.properties.categories &&
    wf.properties.categories.find((c) => c.category === category);
  if (!cat || !cat.subCategories) return [];
  return cat.subCategories;
};

export const getAccountFromWorkflow = (wf: Workflow) => {
  return getSubCategory(wf, 'Account');
};

export const getAllAccountFromWorkflow = (wf: Workflow) => {
  return getSubCategories(wf, 'Account');
};

const getFixButton = (col: FixColDataType) => {
  let buttonText: string | React.ReactNode = col.linkedWorkflow
      ? 'Run fix'
      : 'Fix Available',
    color = 'primary';
  let tooltipText = '';
  // console.log(col.linkedWorkflowOutput);
  if (col.linkedWorkflowOutput) {
    switch (col.linkedWorkflowOutput.state) {
      case 'permission':
      case 'error': {
        buttonText = 'Error';
        color = 'danger';
        tooltipText = ExecutionStatusEnum[col.linkedWorkflowOutput.state];
        break;
      }

      case 'success':
      case 'custom': {
        buttonText = 'Fixed';
        color = 'success';
        tooltipText = col.linkedWorkflowOutput.message
          ? col.linkedWorkflowOutput.message
              .replace('acted on', 'fixed')
              .replace('found', 'fixed')
          : 'Fixed';
        break;
      }

      case 'running':
      case 'queued': {
        buttonText = ExecutionStatusEnum[col.linkedWorkflowOutput.state];
        color = 'warning';
        tooltipText = ExecutionStatusEnum[col.linkedWorkflowOutput.state];
        break;
      }
    }
  }
  return (
    <>
      <DropdownToggle
        id={'fix' + col.parentWorkflowId}
        size={'sm'}
        color={color}
        style={{ maxWidth: '100px' }}
        className="px-2 d-inline-block text-center w-auto overflow-visible d-flex align-items-center"
      >
        <span className={'text-truncate'}>{buttonText}</span>
        <i className="fa fa-caret-down pl-2" aria-hidden="true" />
      </DropdownToggle>
      {tooltipText && (
        <UncontrolledTooltip target={'fix' + col.parentWorkflowId} delay={0}>
          {tooltipText}
        </UncontrolledTooltip>
      )}
    </>
  );
};

const RunFix = function(props: { col: FixColDataType; row: any }) {
  const { col, row } = props;
  const [isChangeFixModalOpen, toggleChangeFixModal] = React.useState(false);
  return (
    <>
      {isChangeFixModalOpen && (
        <ModalForEditFix
          configs={col.configs}
          isFetchingWorkflow={col.isFetchingWorkflow}
          refetchWorkflows={col.refetchWorkflows}
          templateId={col.linkedTemplate}
          templateName={col.linkedTemplateName}
          linkedWorkflowId={col.linkedWorkflow}
          parentWorkflowId={col.parentWorkflowId}
          allWorkflows={col.allWorkflows}
          toggleModal={toggleChangeFixModal}
          updateLinkedWorkflow={col.updateLinkedWorkflow}
          onChangeStatus={col.updateAutoRunOfLink}
          autoRunLinkedWorkflows={col.autoRunLinkedWorkflows}
          parentRegion={col.region}
          parentAccount={col.account}
          adoptTemplateAndRunFix={props.col.adoptTemplateAndRunFix}
          // adoptTemplateAndRunFix={() => {
          //   props.col.adoptTemplateAndRunFix({
          //     ...props.col,
          //     name: '',
          //     templateId: props.col.linkedTemplate,
          //     skipRun: true,
          //   });
          // }}
        />
      )}

      {props.row.updating === COL_NAMES.FIX ? (
        <Col className={'d-flex justify-content-center'}>{loader()}</Col>
      ) : !props.col ||
        (!props.col.linkedTemplate && !props.col.linkedWorkflow) ? (
        <Button
          size={'sm'}
          color={'primary'}
          caret
          className="d-inline-block text-center w-auto overflow-visible"
          onClick={() => toggleChangeFixModal(true)}
        >
          Link Fix
        </Button>
      ) : (
        <UncontrolledDropdown>
          {props.row.updating === COL_NAMES.FIX ? (
            <Col className={'d-flex justify-content-center'}>{loader()}</Col>
          ) : (
            getFixButton(col)
          )}
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                if (props.col.linkedWorkflow)
                  return props.col.runLinkedWorkflow({
                    parentWorkflowId: props.col.parentWorkflowId,
                    workflow: props.col.linkedWorkflow,
                  });
                if (props.col.linkedTemplate)
                  return props.col.adoptTemplateAndRunFix({
                    parentWorkflowId: props.col.parentWorkflowId,
                    templateId: props.col.linkedTemplate,
                    regions: [props.col.region],
                    accounts: [props.col.account],
                    runFix: true,
                  });
              }}
              disabled={false}
            >
              Run Now
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                window.open(
                  `${window.location.origin}/workflow/${props.col.linkedWorkflow}/details`,
                )
              }
              disabled={!props.col.linkedWorkflow}
            >
              History
            </DropdownItem>
            <DropdownItem onClick={() => toggleChangeFixModal(true)}>
              Configure Fix
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </>
  );
};
export const fixColDisplayFormatter = (fix: FixColDataType, row: any) => {
  return <RunFix col={fix} row={row} />;
};
