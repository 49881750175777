import React from 'react';

export const globalHelpText: React.ReactNode = (
  <>
    <div className="font-italics">
      Use the variables that are declared for this workflow by using the syntax{' '}
      <mark>{'$wf.variables <variable_name>'}</mark>
    </div>
    <br />
    <div>
      More on how to use variables{' '}
      <a
        href=" https://docs.totalcloud.io/workflows/usecase-guides/using-variables"
        target={'_blank'}
      >
        here
      </a>
    </div>
  </>
);

export function getHelpComponent(params: INodeHelpData): React.ReactNode {
  return (
    <>
      <h4>
        <strong>{params.heading}</strong>
      </h4>
      {params.para1 && <>{params.para1}</>}
      {params.para2 && (
        <p>
          <br />
          {params.para2}
        </p>
      )}
      {params.para3 && (
        <p>
          <br />
          {params.para3}
        </p>
      )}
      <br />
      <br />
      {params.params.length > 0 && (
        <h4>
          <strong>Parameters:</strong>
        </h4>
      )}
      {params.params.length > 0 && (
        <ul>
          {params.params.map((element, index) => {
            return (
              <li key={index}>
                <strong>{element.key + ': '}</strong>
                {element.description}
              </li>
            );
          })}
        </ul>
      )}
      <br />
      {params.examples.length > 0 && (
        <h4>
          <strong>Examples:</strong>
        </h4>
      )}
      {params.examples.length > 0 && (
        <ul>
          {params.examples.map((element, index) => (
            <li key={index}>{element}</li>
          ))}
        </ul>
      )}
      <br />
      {params.notes.length > 0 && (
        <h4>
          <strong>Notes:</strong>
        </h4>
      )}
      {params.notes.length > 0 && (
        <ul>
          {params.notes.map((element, index) => (
            <li key={index}>{element}</li>
          ))}
        </ul>
      )}
      <br />
      {params.moreLink && (
        <>
          Learn more about this node{' '}
          <a href={params.moreLink} target="_blank">
            here.
          </a>
        </>
      )}
      <br />
      <br />
      {globalHelpText}
    </>
  );
}

export interface INodeHelpData {
  heading: string;
  para1: string;
  para2: string;
  para3: string;
  params: Array<{ key; description }>;
  examples: Array<string>;
  notes: Array<string>;
  moreLink: string;
}
