import React from 'react';
import { Button, Card, CardGroup, CardImg, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Welcome extends React.Component {
  render() {
    return (
      <Row>
        <Col md={12}>
          <Row className="d-flex justify-content-between align-items-start px-4">
            <div>
              <h4>Welcome Aboard</h4>
            </div>
            <div>
              {/* <Button className="rounded-0 bg-gradient-red d-flex align-items-center">
                <span className="border border-white px-2 py-1 mr-2">
                  <i className="fa fa-play-circle-o text-white" />
                </span>
                Quick App Tour
              </Button> */}
            </div>
          </Row>
          <Row className="d-flex justify-content-center my-3 pt-5">
            <h4>How would you like to proceed ahead ?</h4>
          </Row>
          <Row className="d-flex align-items-stretch">
            <CardGroup>
              {/*<Col md={4} className={"px-4"}>*/}
              <Card className="px-4 mx-4 pt-3 pb-4 d-flex align-items-center shadow">
                <CardImg
                  top
                  src="/img/welcome-schedule-redirect.png"
                  alt="welcome-template-redirect"
                  className="w-25 mt-3"
                />
                <h5 className="d-flex align-items-center text-primary">
                  Resource Scheduling
                </h5>

                <p className="pt-2 text-left">
                  Explore pre-existing Schedules that are common among users.
                  You can select and edit one meet your custom requirements.
                </p>

                <div className="pt-2 d-flex align-items-center">
                  <Link to="/schedules">
                    <Button color={'primary'}>
                      <span className="px-4">View</span>
                    </Button>
                  </Link>
                </div>
              </Card>
              {/*</Col>*/}
              {/*<Col md={4} className={"px-4"}>*/}
              <Card className="p-4 mx-4 d-flex align-items-center shadow">
                <CardImg
                  top
                  src="/img/welcome-template-redirect.png"
                  alt="welcome-template-redirect"
                  className="w-25 mt-3"
                />
                <h5 className="d-flex align-items-center text-primary">
                  Workflow Templates
                </h5>

                <p className="pt-2 text-left">
                  Explore pre-existing workflows that are common among users.
                  You can select and edit one to meet your custom requirements.
                </p>

                <div className="pt-2 d-flex align-items-center">
                  <Link to="/templates">
                    <Button color={'primary'}>
                      <span className="px-4">View</span>
                    </Button>
                  </Link>
                </div>
              </Card>
              {/*</Col>*/}
              {/*<Col md={4} className={"px-4"}>*/}
              <Card className="p-4 mx-4 d-flex align-items-center shadow">
                <CardImg
                  top
                  src="/img/welcome_custom_workflow.png"
                  alt="welcome-template-redirect"
                  className="w-25 mt-3"
                />
                <h5 className="d-flex align-items-center text-primary mt-3">
                  Create Custom Workflows
                </h5>

                <p className="pt-2 text-left">
                  You will be enabled with powerful editor to create the
                  workflow you need to achieve your goal.
                </p>

                <div className="pt-3 mt-3 mb-1 d-flex align-items-center">
                  <Link to="/editor">
                    <Button color={'primary'}>
                      <span className="px-4">View</span>
                    </Button>
                  </Link>
                </div>
              </Card>
              {/*</Col>*/}
            </CardGroup>
          </Row>
        </Col>
      </Row>
    );
  }
}
