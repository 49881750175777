import {
  ITableDataDef,
  TableMeta,
} from 'webapp-genric/dist/super-table/model/table/types';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { TableContextProps } from 'super-table/dist/components/Table/types';

export const GET_SUPER_TABLE_DATA = 'GET_SUPER_TABLE_DATA';
export const GET_SUPER_TABLE_DATA_SUCCESS = 'GET_SUPER_TABLE_DATA_SUCCESS';
export const GET_SUPER_TABLE_DATA_FAILED = 'GET_SUPER_TABLE_DATA_FAILED';

export const SUPER_TABLE_ACTION_REQUEST = 'SUPER_TABLE_ACTION_REQUEST';
export const SUPER_TABLE_ACTION_SUCCESS = 'SUPER_TABLE_ACTION_SUCCESS';
export const SUPER_TABLE_ACTION_FAILED = 'SUPER_TABLE_ACTION_FAILED';

export enum ApiOperation {
  GET = 'get',
  ACTION = 'action',
}

export interface SuperApiProps {
  dispatch: ThunkDispatch<any, any, AnyAction>;
  category: string;
  subCategory: string;
  operation: ApiOperation;
  options?: TableMeta & TableContextProps & any;
}

export interface GET_SUPER_TABLE_DATA {
  type: typeof GET_SUPER_TABLE_DATA;
  payload: {
    category: string;
    subCategory: string;
  };
}

export interface GET_SUPER_TABLE_DATA_SUCCESS {
  type: typeof GET_SUPER_TABLE_DATA_SUCCESS;
  payload: {
    category: string;
    subCategory: string;
    data: any;
  };
}

export interface GET_SUPER_TABLE_DATA_FAILED {
  type: typeof GET_SUPER_TABLE_DATA_FAILED;
  payload: {
    category: string;
    subCategory: string;
    data: string;
  };
}

export interface SUPER_TABLE_ACTION_REQUEST {
  type: typeof SUPER_TABLE_ACTION_REQUEST;
  payload: {
    category: string;
    subCategory: string;
  };
}

export interface SUPER_TABLE_ACTION_SUCCESS {
  type: typeof SUPER_TABLE_ACTION_SUCCESS;
  payload: {
    category: string;
    subCategory: string;
    data: any;
  };
}

export interface SUPER_TABLE_ACTION_FAILED {
  type: typeof SUPER_TABLE_ACTION_FAILED;
  payload: {
    category: string;
    subCategory: string;
    data: string;
  };
}

export type SuperTableAction =
  | GET_SUPER_TABLE_DATA
  | GET_SUPER_TABLE_DATA_SUCCESS
  | GET_SUPER_TABLE_DATA_FAILED
  | SUPER_TABLE_ACTION_REQUEST
  | SUPER_TABLE_ACTION_SUCCESS
  | SUPER_TABLE_ACTION_FAILED;

export interface SuperApiProvider {
  doSuperTableAction: (props: any) => Promise<any>;
  getSuperTableData: (props: any) => Promise<any>;
}
