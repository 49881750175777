import * as React from 'react';
import { FormikProps } from 'formik';

import {
  Button,
  Col,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import MultiSelect from '../../../lib/formInputs/multi-select.component';
import { TestSettingsFormSchema } from './testSettingsFormSchema';
import {
  Template,
  TemplateTestSettings,
} from '../../../lib/store/templates/types';
import { Workflow } from '../../../lib/store/workflows/types';
import AceEditor from 'react-ace';
import nestedProperty from 'nested-property';

const queryTextAreaStyle: Object = {
  resize: 'none',
  width: '100%',
  height: '200px',
  zIndex: -0,
};

const defaultCode =
  'async function process(){\n' +
  '  let result = false;\n' +
  '  let {stepOutput} = context;\n' +
  '  if(stepOutput && stepOutput.length === 2) result = true;\n' +
  '  exit(null,result)\n' +
  '}\n' +
  'process().catch(exit);';

interface FormProps extends FormikProps<TestSettingsFormSchema> {
  hideTestSettingsModal: () => void;
  testSettings: TemplateTestSettings;
  template: Template;
  onSave: (data: TestSettingsFormSchema, template: Template) => void;
  getCustomWorkflows: () => Workflow[];
  getTemplateTesterWorkflows: () => { name: string; workflow: string }[];
}

const expectedOutput = (template: Template) => {
  let options = [];
  if (!template || !template.properties || !template.properties.nodeDescription)
    return options;
  for (let key in template.properties.nodeDescription) {
    options.push({
      label: key,
      value: key,
    });
  }
  return options;
};

interface IState {
  aceEditor: any;
  aceError: string;
}
export class TestSettingsForm extends React.Component<FormProps, IState> {
  eventHandlers = {
    onChange: this.props.handleChange,
    onBlur: this.props.handleBlur,
  };

  state = {
    aceError: '',
    aceEditor: null,
  };

  validateCodeEditor() {
    this.setState({ aceError: '' });
    if (!this.state.aceEditor || !this.state.aceEditor.editor) return true;
    let annotations = this.state.aceEditor.editor.getSession().getAnnotations();
    if (!annotations) return true;
    let errors = annotations.filter((x) => x.type === 'error');
    if (errors.length === 0) return true;
    this.setState({ aceError: 'Please Enter valid code' });
    return false;
  }

  withNamespace(fieldName, ns) {
    return ns ? `${ns}.${fieldName}` : fieldName;
  }

  getNestedProperty(data, key) {
    let defaultValue: any = '';
    if (key.startsWith('value.')) defaultValue = {};
    return nestedProperty.get(data, key) || defaultValue;
  }

  getFieldValues = (field, ns?) => {
    field = this.withNamespace(field, ns);
    return {
      name: field,
      value: this.getNestedProperty(this.props.values, field),
      error: this.getNestedProperty(this.props.errors, field),
      touched:
        this.props.submitCount > 0 ||
        this.getNestedProperty(this.props.touched, field) ||
        false,
    };
  };

  onUpdateTestFunction = (nodeId: string, value) => {
    let expectedOutput = this.props.values.expectedOutput || {};
    expectedOutput[nodeId] = { testFunction: value };
    this.props.handleChange({
      target: { name: 'expectedOutput', value: expectedOutput },
    });
  };
  updateExpectedOutput = (values) => {
    if (!values) values = [];
    let expectedOutput = {};
    for (let v of values) {
      expectedOutput[v.value] = this.props.values.expectedOutput[v.value] || {
        testFunction: defaultCode,
      };
    }
    this.props.handleChange({
      target: { name: 'expectedOutput', value: expectedOutput },
    });
  };

  onSelectTesterWorkflows = (values) => {
    if (!values) values = [];
    this.props.handleChange({
      target: {
        name: 'testerWorkflows',
        value: values.map((x) => x.value),
      },
    });
  };

  onSelectCreateStackWorkflow = (values) => {
    if (!values) values = [];

    this.props.handleChange({
      target: {
        name: 'createStackWorkflows',
        value: values.map((x) => x.value),
      },
    });

    let destroyStackWorkflowsName = values.map(
      (x) =>
        x.label.toLowerCase().startsWith('create_stack') &&
        x.label.toLowerCase().replace(/^create_stack/, 'destroy_stack'),
    );
    let destroyStackWorkflows = destroyStackWorkflowsName.map((x) => {
      return (
        x &&
        this.props
          .getCustomWorkflows()
          .find((w) => w.name.toLowerCase() === x.toLowerCase())
      );
    });

    let destroyStackWorkflowsIds = [];

    for (let wf of destroyStackWorkflows) {
      if (!wf) {
        destroyStackWorkflowsIds = [];
        break;
      }
      destroyStackWorkflowsIds.push(wf.workflow);
    }
    this.props.handleChange({
      target: {
        name: 'destroyStackWorkflows',
        value: destroyStackWorkflowsIds,
      },
    });
  };

  saveForm = (data) => {
    let isValidCode = this.validateCodeEditor();
    if (!isValidCode) return;
    this.props.handleSubmit(data);
  };
  setEditorRef = (element) => {
    this.setState({ aceEditor: element });
  };

  render(): React.ReactNode {
    let workflowOptions = this.props
      .getCustomWorkflows()
      .map((x) => ({ label: x.name, value: x.workflow }));

    let testerWorkflowOptions = this.props
      .getTemplateTesterWorkflows()
      .map((x) => ({ label: x.name, value: x.workflow }));
    return (
      <Modal
        centered={true}
        size={'lg'}
        isOpen={true}
        toggle={this.props.hideTestSettingsModal}
        backdrop={'static'}
      >
        <ModalHeader toggle={this.props.hideTestSettingsModal}>
          <strong>Template Test Settings Modal</strong>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <MultiSelect
                {...this.getFieldValues('createStackWorkflows')}
                {...this.eventHandlers}
                value={workflowOptions.filter(
                  (x) =>
                    this.props.values.createStackWorkflows.indexOf(x.value) !==
                    -1,
                )}
                label={'Select create stack workflows'}
                options={workflowOptions.filter((x) =>
                  x.label.toLowerCase().startsWith('create_stack'),
                )}
                onChange={this.onSelectCreateStackWorkflow}
                error={(
                  this.props.errors.createStackWorkflows ||
                  this.props.errors.destroyStackWorkflows ||
                  ''
                ).toString()}
              />
            </Col>
            <Col md={1} className={'mt-5'}>
              <i className="fa fa-info" id={'stack-wf-name-tip'} />
              <UncontrolledTooltip target={'stack-wf-name-tip'}>
                All Workflows with category tc-template-test-category will be
                shown in the list. Only select create stack workflow from the
                list. Destroy stack workflow should exists for each create stack
                workflow with name convention(case insensitive):
                create_stack_service, destroy_stack_service
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <MultiSelect
                error={(this.props.errors.expectedOutput || '').toString()}
                name={'expectedOutputKeys'}
                label={'Select node id for expected output'}
                options={expectedOutput(this.props.template)}
                value={Object.keys(
                  this.props.values.expectedOutput,
                ).map((x) => ({ label: x, value: x }))}
                onChange={this.updateExpectedOutput}
              />
            </Col>
          </Row>
          {Object.keys(this.props.values.expectedOutput).map(
            (nodeId, index) => {
              return (
                <Row key={index} className="mb-2">
                  <Col md={12} className="text-capitalize">
                    {nodeId}
                  </Col>
                  <Col md={12}>
                    <AceEditor
                      ref={this.setEditorRef}
                      {...this.getFieldValues(
                        `${nodeId}.testFunction`,
                        'expectedOutput',
                      )}
                      name="codeEditor"
                      setOptions={{
                        showLineNumbers: false,
                        tabSize: 2,
                      }}
                      fontSize={16}
                      style={queryTextAreaStyle}
                      mode="javascript"
                      theme="customMonokai"
                      editorProps={{ $blockScrolling: true }}
                      onChange={(code) =>
                        this.onUpdateTestFunction(nodeId, code)
                      }
                    />
                    {this.state.aceError && (
                      <div className="input-error" style={{ color: 'red' }}>
                        {this.state.aceError}
                      </div>
                    )}
                  </Col>
                </Row>
              );
            },
          )}

          <Row>
            <Col md={6}>
              <MultiSelect
                {...this.getFieldValues('testerWorkflows')}
                {...this.eventHandlers}
                value={testerWorkflowOptions.filter(
                  (x) =>
                    this.props.values.testerWorkflows.indexOf(x.value) !== -1,
                )}
                label={'Select tester workflow ids (optional) '}
                options={testerWorkflowOptions}
                onChange={this.onSelectTesterWorkflows}
                error={(this.props.errors.testerWorkflows || '').toString()}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" onClick={this.saveForm}>
            Save
          </Button>
          <Button color="outline" onClick={this.props.hideTestSettingsModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
