import api from '../../api';
import { waitForAdoptionWorkflowToFinish } from '../../lib/store/templates/action';
import { notify } from '../common/toaster';

export type TemplatePolicyValidationResult = {
  minimum: object;
  validation: { valid: boolean; details: string };
  recommended: object;
};

export async function validateTemplatePolicy(
  account: string,
  ids: string[],
): Promise<TemplatePolicyValidationResult> {
  return api.validatePolicyForTemplates({ account, ids });
}

export async function createAdoptionTask(
  account: string,
  region: string,
  resourceGroup: string,
  templateIds: string[],
) {
  try {
    const { executionId } = await api.createBatchAdoptionJob({
      resourceGroup,
      region,
      account,
      templateIds,
    });
    let numberOfWorkflowsAdopted = await waitForAdoptionWorkflowToFinish(
      executionId,
    );
    if (numberOfWorkflowsAdopted)
      notify({ type: 'success', message: 'Template adopted successfully' });
    else notify({ type: 'warn', message: 'Template already adopted' });
  } catch (e) {
    console.error(e);
    notify({ type: 'error', message: 'Template adoption failed' });
    throw new Error(e);
  }
}
