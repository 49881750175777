import React, { useMemo } from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Alert,
  UncontrolledAlert,
} from 'reactstrap';

import { RegionAndCredsComponent } from '../../common/RegionAndCredsComponent';
import {
  adoptionSelectorSchema,
  AdoptionSelectorSchema,
} from './adoptionSelectorSchema';
import { Config, ConfigState } from '../../../lib/store/config/types';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import useRestrictions from '../../../lib/user-restriction';
import { AlreadyAdopted } from '../../page-header/AdoptionSetting';
import { getAwsAccountConfig } from '../../common/withRegionAndCredentials';
import { CONFIG_TYPES } from 'webapp-genric/dist/configTypes';

const MaxAccountAdoptedMessage =
  'You are not allowed to select any account. This is because either you have already adopted the maximum allowed accounts, or adoption is disabled. Contact support for more information.';

interface IProps {
  configs: Config[];
  selectedAccounts: string[];
  selectedRegions: string[];
  toggle: () => void;
  onSubmit: (values: AdoptionSelectorSchema) => void;
  tabName: TAB_NAME;
  resourceGroupData: ConfigState['resourceGroupData'];
  fetchResourceGroups: (configIds: string[]) => void;
  alreadyAdopted: AlreadyAdopted;
}

export class AdoptionSelectorModal extends React.Component<IProps> {
  onSubmit = (values: AdoptionSelectorSchema) => {
    this.props.onSubmit(values);
  };

  getInitialValues = (): AdoptionSelectorSchema => {
    return {
      regions: [],
      accounts: [],
      resourceGroups: [],
    };
  };

  private static getResourceGroupOptions(values, resourceGroupData) {
    let availableResourceGroups = [];
    let isResourceGroupFetchRequired = false;
    let resourceGroupsFetchIsWorking = false;
    for (const accountId of values.accounts) {
      if (!resourceGroupData[accountId]) {
        isResourceGroupFetchRequired = true;
      } else if (resourceGroupData[accountId].isFetchingResourceGroup) {
        resourceGroupsFetchIsWorking = true;
      } else if (resourceGroupData[accountId].data) {
        availableResourceGroups = availableResourceGroups.concat(
          resourceGroupData[accountId].data,
        );
      }
    }
    return {
      availableResourceGroups,
      isResourceGroupFetchRequired,
      resourceGroupsFetchIsWorking,
    };
  }

  render() {
    const render = (props: FormikProps<AdoptionSelectorSchema>) => {
      let resourceGroupOptions = AdoptionSelectorModal.getResourceGroupOptions(
        props.values,
        this.props.resourceGroupData,
      );
      props.values.resourceGroups = resourceGroupOptions.availableResourceGroups.filter(
        (x) => props.values.resourceGroups.indexOf(x) !== -1,
      );
      return <ModalForm {...this.props} {...props} {...resourceGroupOptions} />;
    };
    return (
      <Formik
        initialValues={this.getInitialValues()}
        onSubmit={(
          values: AdoptionSelectorSchema,
          actions: FormikActions<AdoptionSelectorSchema>,
        ) => {
          this.onSubmit(values);
          actions.setSubmitting(false);
        }}
        render={render}
        validateOnChange={false}
        validationSchema={adoptionSelectorSchema}
      />
    );
  }
}

type FromProps = IProps &
  FormikProps<AdoptionSelectorSchema> & {
    availableResourceGroups: string[];
    isResourceGroupFetchRequired: boolean;
    resourceGroupsFetchIsWorking: boolean;
    alreadyAdopted: AlreadyAdopted;
  };

const ModalForm = (props: FromProps) => {
  const onChangeRegion = (regions: string[]) => {
    props.handleChange({ target: { name: 'regions', value: regions } });
  };

  const onChangeAccount = (accounts: string[]) => {
    props.handleChange({ target: { name: 'accounts', value: accounts } });
  };

  const onChangeResourceGroups = (resourceGroups: string[]) => {
    props.handleChange({
      target: { name: 'resourceGroups', value: resourceGroups },
    });
  };

  const { alreadyAdopted } = props;
  const {
    accounts: adoptedAccounts,
    regions: adoptedRegions,
    resourceGroups: adoptedResourceGroups,
  } = alreadyAdopted;

  const restrictAccount = useRestrictions('allowedAccounts');
  const restrictResourceGroup = useRestrictions('allowedResourceGroups');
  const restrictRegions = useRestrictions('allowedRegions');

  const maxAccountSelection =
    parseInt(restrictAccount.value) >= adoptedAccounts.length
      ? parseInt(restrictAccount.value) - adoptedAccounts.length
      : 0;

  const maxRegionSelection =
    parseInt(restrictRegions.value) >= adoptedRegions.length
      ? parseInt(restrictRegions.value) - adoptedRegions.length
      : 0;

  const maxResourceGroupSelection =
    parseInt(restrictResourceGroup.value) >= adoptedResourceGroups.length
      ? parseInt(restrictResourceGroup.value) - adoptedResourceGroups.length
      : 0;

  const isAdoptionDisabled = useMemo(() => {
    const acc = props.values.accounts || [];
    const regions = props.values.regions || [];
    const resG = props.values.resourceGroups || [];

    let disabled = false;

    const awsAccounts = getAwsAccountConfig(
      props.configs.filter((conf) => acc.includes(conf.id)),
    ).length;
    const azureAccount =
      props.configs.find((conf) => acc.includes(conf.id))?.type ===
      CONFIG_TYPES.AZURE;

    if (
      !acc.length ||
      (awsAccounts && !regions.length) ||
      (azureAccount && !resG.length)
    )
      disabled = true;

    return disabled;
  }, [props.configs, props.values]);

  return (
    <Modal backdrop={'static'} centered={true} isOpen={true} size={'lg'}>
      <ModalHeader toggle={props.toggle}>
        {`Select Credentials, Regions and Resource-Groups for which you want
            to adopt ${props.tabName}.`}
      </ModalHeader>
      <ModalBody>
        {maxAccountSelection === 0 && (
          <div className="text-warning font-12 mb-2">
            {MaxAccountAdoptedMessage}
          </div>
        )}
        <Row>
          <Col className={'d-flex'}>
            <RegionAndCredsComponent
              restrictMaxSelection={true}
              maxAccountSelection={maxAccountSelection}
              maxRegionSelection={maxRegionSelection}
              maxResourceGroupSelection={maxResourceGroupSelection}
              isMultiSelect={true}
              allowSelectAll={false}
              configs={props.configs}
              selectedAccount={props.values.accounts || []}
              selectedRegion={props.values.regions || []}
              selectedResourceGroup={props.values.resourceGroups || []}
              onAccountChange={onChangeAccount}
              onRegionChange={onChangeRegion}
              onResourceGroupChange={onChangeResourceGroups}
              showResourceGroup={true}
              availableResourceGroups={props.availableResourceGroups}
            />
          </Col>
        </Row>
        {(props.isResourceGroupFetchRequired ||
          props.resourceGroupsFetchIsWorking) && (
          <Row className={'mt-3'}>
            <Col>
              <Button
                color={'secondary'}
                onClick={() => props.fetchResourceGroups(props.values.accounts)}
                size={'sm'}
              >
                Fetch Resource Groups
                {props.resourceGroupsFetchIsWorking && (
                  <i className="ml-1 fa fa-spin fa-spinner" />
                )}
              </Button>
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Row className={'mt-4'}>
          <Col md={12}>
            <Button
              disabled={isAdoptionDisabled}
              color={'primary'}
              size={'sm'}
              onClick={props.submitForm}
              className={'mr-2'}
            >
              Adopt
            </Button>
            <Button
              size={'sm'}
              className="test-clone-button"
              color={'primary'}
              outline
              onClick={props.toggle}
            >
              Close
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};
