import React from 'react';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { Col, Row } from 'reactstrap';
import { InsightSummary, WorkflowS } from './insightSummary';
import CustomTable, { ICustomTableCol } from '../../../common/table';
import { CustomBreadCrumb } from '../CustomBreadCrumb';
import { EmptyListText } from '../../../../config/WorkflowCategories';
import { BreadCrumb } from '../breadCrumbs';

type IProps = {
  viewSelector?: JSX.Element;
  isLoading: boolean;
  tabName?: TAB_NAME;
  isFetchingExecutions: boolean;
  columns: ICustomTableCol[];
  updateConfigAndRegion: (key: string, value: string[]) => void;
  summaryData: any;
  breadCrum: BreadCrumb;
  options: any;
};
export const RenderSummaryChart = (props: IProps) => {
  let loadingText = props.isLoading
    ? 'Loading...'
    : EmptyListText[props.tabName];
  if (
    props.tabName === TAB_NAME.INSIGHTS ||
    props.tabName === TAB_NAME.INVENTORY ||
    props.tabName === TAB_NAME.UTILISATION
  ) {
    return (
      <Col md={12} className={'py-4 mb-2 px-4 bg-white custom-container'}>
        <Row>
          <Col md={9}>
            {' '}
            {props.breadCrum && <CustomBreadCrumb data={props.breadCrum} />}
          </Col>
          {props.tabName === TAB_NAME.INSIGHTS && (
            <Col md={3} data-test="view-selector">
              <Row>{props.viewSelector}</Row>
            </Col>
          )}
        </Row>
        <CustomTable
          options={props.options}
          data={props.summaryData}
          columns={props.columns}
          emptyText={loadingText}
          pagination={false}
        />
      </Col>
    );
  }
  return null;
};
