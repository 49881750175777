import React, { ReactNode } from 'react';
import { FilterType, INode } from 'workflow-model/dist';
import { copyToClipboard } from '../../../../../util/commonUtils';
import {
  Button,
  Col,
  Input,
  Row,
  UncontrolledCollapse,
  UncontrolledTooltip,
} from 'reactstrap';
import ParamsForm from '../nodeUI/filter/ParamsForm';
import { Formik } from 'formik';
import InputText from '../../../../formInputs/input-text.component';
import InputSelect from '../../../../formInputs/select-option.component';
import { PARAMS_OPERATORS } from '../nodeUI/filter/config';

export const getModalTitle = (node: INode): ReactNode => {
  let modalTitle: ReactNode = (
    <>
      <Row>
        <Col md={5}>
          {`Node Name: ${node.getNodeName()}`}
          <div>
            <small className={'text-muted'}>
              ({`Node Id: ${node.getNodeId()} `}
              <i
                id={'copy-node-id'}
                className="fa fa-clipboard cursor-pointer"
                onClick={() => copyToClipboard(node.getNodeId())}
              />
              <UncontrolledTooltip target={'copy-node-id'} delay={0}>
                Copy to clipboard
              </UncontrolledTooltip>
              )
            </small>
          </div>
        </Col>
        <Col md={7}>
          Description:
          <input
            className="bottom-border ml-2 font-14 w-50"
            defaultValue={node.getNodeDescription()}
            onChange={(e) => node.setNodeDescription(e.target.value)}
            placeholder="Enter description(Optional)"
          />
        </Col>
      </Row>

      <ConditionalSettings node={node} />
    </>
  );
  return modalTitle;
};

type IProps = {
  node: INode;
};

export class ConditionalSettings extends React.Component<IProps> {
  onAddSettings = () => {
    this.props.node.addConditionalSetting({
      id: 'c1',
      value: '',
      key: '',
      operator: '==',
      type: FilterType.PARAM,
    });
    this.forceUpdate();
  };

  onChange = (event, condition) => {
    this.props.node.addConditionalSetting({
      ...condition,
      [event.target.name]: event.target.value,
    });
    this.forceUpdate();
  };
  render() {
    let { node } = this.props;
    let conditions = node.getConditionalSetting().conditions;
    return (
      <>
        <Row>
          <Col
            className={'text-right'}
            style={{ marginTop: '-60px' }}
            md={{ size: 4, offset: 8 }}
          >
            <Button className={'p-0'} color="link" id="toggler">
              Set Conditional
            </Button>
          </Col>
        </Row>

        <UncontrolledCollapse toggler="#toggler">
          <Row>
            <Col>
              <small>
                Node execution will be skipped if expression evaluates to false.
              </small>
              {conditions && conditions.length == 0 && (
                <Button
                  id={'add-condition'}
                  color={'link'}
                  onClick={this.onAddSettings}
                >
                  Add Condition.
                </Button>
              )}
            </Col>
            <Col md={12}>
              {conditions &&
                conditions.length !== 0 &&
                conditions.map((x, i) => {
                  return (
                    <Row key={i}>
                      <Col md={4}>
                        <InputText
                          onChange={(event) => this.onChange(event, x)}
                          name={'key'}
                          value={x.key}
                          label={'Key'}
                        />
                      </Col>
                      <Col md={3}>
                        <InputSelect
                          onChange={(event) => this.onChange(event, x)}
                          name={'operator'}
                          label={'Operator'}
                          value={x.operator}
                          options={PARAMS_OPERATORS}
                        />
                      </Col>
                      <Col md={4}>
                        <InputText
                          onChange={(event) => this.onChange(event, x)}
                          name={'value'}
                          value={x.value}
                          label={'Value'}
                        />
                      </Col>
                      <Col
                        onClick={() => {
                          node.removeConditionalSetting(x.id);
                          this.forceUpdate();
                        }}
                      >
                        <i
                          id={'trash-icon'}
                          className={'fa fa-trash text-danger cursor-pointer'}
                        />
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          </Row>
        </UncontrolledCollapse>
      </>
    );
  }
}
