import {
  Config,
  ConfigAction,
  ConfigState,
  FetchEksConfig,
  FetchEksConfigCompleted,
  FetchResourceGroupCompleted,
  FetchResourceGroupStarted,
} from './types';
import { Reducer } from 'redux';

const initialState: ConfigState = {
  fetching: false,
  data: null,
  saving: false,
  deleting: false,
  resourceGroupData: {},
  eksConfigState: {},
};

function merge(array: Config[] | null, element: Config | null): Config[] {
  if (!array) {
    if (!element) return [];
    return [element];
  }
  if (!element) {
    return array;
  }
  const existing = array.find((e) => e.id === element.id);
  if (existing) {
    Object.assign(existing, element);
    return array;
  }
  return [...array, element];
}

function remove(array: Config[], id: string): Config[] {
  if (!id) return array;
  let existing = array.findIndex((e) => e.id === id);
  if (existing === -1) {
    throw new Error('could not find this element ' + id);
  }
  return [...array.slice(0, existing), ...array.slice(existing + 1)];
}

// todo add resource group fetching...

//  Rendering
// render(
//  <UI id={configId}>
// )
// renderUI { resourceGroup : ..... }

export const configReducer: Reducer<ConfigState, ConfigAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'RequestConfig':
      return { ...state, fetching: true };
    case 'RequestConfigSuccess':
      return { ...state, data: action.data, fetching: false };
    case 'SaveConfigRequest':
      return { ...state, saving: true };
    case 'SaveConfigSuccess':
      return { ...state, saving: false, data: merge(state.data, action.data) };
    case 'ValidateConfigRequest':
      return { ...state, saving: true };
    case 'ValidateConfigSuccess':
      return {
        ...state,
        saving: false,
      };
    case 'DeleteConfigRequest':
      return { ...state, deleting: true };
    case 'DeleteConfigSuccess':
      return {
        ...state,
        deleting: false,
        data: remove(state.data as Config[], action.data as string),
      };

    case FetchResourceGroupStarted:
      return {
        ...state,
        resourceGroupData: {
          ...state.resourceGroupData,
          [action.data.configId]: { isFetchingResourceGroup: true },
        },
      };

    case FetchResourceGroupCompleted:
      return {
        ...state,
        resourceGroupData: {
          ...state.resourceGroupData,
          [action.data.configId]: {
            isFetchingResourceGroup: false,
            data: action.data.resourceGroup,
          },
        },
      };

    case FetchEksConfig:
      const path = `/${action.data.configId}/${action.data.region}`;
      return {
        ...state,
        eksConfigState: {
          [path]: {
            fetching: true,
            data:
              state.eksConfigState[path] && state.eksConfigState[path].data
                ? state.eksConfigState[path].data
                : [],
          },
        },
      };

    case FetchEksConfigCompleted:
      const eksPath = `/${action.data.configId}/${action.data.region}`;
      return {
        ...state,
        eksConfigState: {
          [eksPath]: {
            data: action.data.data,
            fetching: false,
          },
        },
      };

    default:
      return state;
  }
};
