export const SERVICE_PARAMS: {
  [s: string]: {
    [s: string]: any;
  };
} = {
  'ACM:addTagsToCertificate': {
    CertificateArn: 'required',
    Tags: [
      {
        Value: 'required',
        Key: 'required',
      },
    ],
  },
  'ACM:deleteCertificate': {
    CertificateArn: 'required',
  },
  'ACM:describeCertificate': {
    CertificateArn: 'required',
  },
  'ACM:exportCertificate': {
    CertificateArn: 'required',
    Passphrase: 'required',
  },
  'ACM:getCertificate': {
    CertificateArn: 'required',
  },
  'ACM:importCertificate': {
    Certificate: 'required',
    PrivateKey: 'required',
    CertificateArn: '<optional>',
    CertificateChain: '<optional>',
  },
  'ACM:listCertificates': {
    CertificateStatuses: ['<optional1>', '<optional2>'],
    Includes: [
      {
        extendedKeyUsage: '<optional>',
        keyUsage: '<optional>',
        keyTypes: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'ACM:listTagsForCertificate': {
    CertificateArn: 'required',
  },
  'ACM:removeTagsFromCertificate': {
    CertificateArn: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'ACM:renewCertificate': {
    CertificateArn: 'required',
  },
  'ACM:requestCertificate': {
    DomainName: 'required',
    DomainValidationOptions: ['<optional1>', '<optional2>'],
    ValidationMethod: '<optional>',
    SubjectAlternativeNames: ['<optional1>', '<optional2>'],
    IdempotencyToken: '<optional>',
    Options: [
      {
        CertificateTransparencyLoggingPreference: '<optional>',
      },
    ],
    CertificateAuthorityArn: '<optional>',
  },
  'ACM:resendValidationEmail': {
    CertificateArn: 'required',
    Domain: 'required',
    ValidationDomain: 'required',
  },
  'ACM:updateCertificateOptions': {
    CertificateArn: 'required',
    Options: [
      {
        CertificateTransparencyLoggingPreference: '<optional>',
      },
    ],
  },
  'ACMPCA:createCertificateAuthority': {
    CertificateAuthorityConfiguration: {
      Subject: {
        Country: '<optional>',
        Organization: '<optional>',
        OrganizationalUnit: '<optional>',
        DistinguishedNameQualifier: '<optional>',
        State: '<optional>',
        CommonName: '<optional>',
        SerialNumber: '<optional>',
        Locality: '<optional>',
        Title: '<optional>',
        Surname: '<optional>',
        GivenName: '<optional>',
        Initials: ['<optional1>', '<optional2>'],
        Pseudonym: '<optional>',
        GenerationQualifier: '<optional>',
      },
    },
    CertificateAuthorityType: 'required',
    RevocationConfiguration: {
      CrlConfiguration: {
        ExpirationInDays: ['<optional1>', '<optional2>'],
        CustomCname: '<optional>',
        S3BucketName: '<optional>',
      },
    },
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    IdempotencyToken: '<optional>',
  },
  'ACMPCA:createCertificateAuthorityAuditReport': {
    CertificateAuthorityArn: 'required',
    S3BucketName: 'required',
    AuditReportResponseFormat: 'required',
  },
  'ACMPCA:createPermission': {
    CertificateAuthorityArn: 'required',
    Principal: 'required',
    Actions: ['required1', 'required2'],
    SourceAccount: '<optional>',
  },
  'ACMPCA:deleteCertificateAuthority': {
    CertificateAuthorityArn: 'required',
    PermanentDeletionTimeInDays: ['<optional1>', '<optional2>'],
  },
  'ACMPCA:deletePermission': {
    CertificateAuthorityArn: 'required',
    Principal: 'required',
    SourceAccount: '<optional>',
  },
  'ACMPCA:describeCertificateAuthority': {
    CertificateAuthorityArn: 'required',
  },
  'ACMPCA:describeCertificateAuthorityAuditReport': {
    CertificateAuthorityArn: 'required',
    AuditReportId: 'required',
  },
  'ACMPCA:getCertificate': {
    CertificateAuthorityArn: 'required',
    CertificateArn: 'required',
  },
  'ACMPCA:getCertificateAuthorityCertificate': {
    CertificateAuthorityArn: 'required',
  },
  'ACMPCA:getCertificateAuthorityCsr': {
    CertificateAuthorityArn: 'required',
  },
  'ACMPCA:importCertificateAuthorityCertificate': {
    CertificateAuthorityArn: 'required',
    Certificate: 'required',
    CertificateChain: '<optional>',
  },
  'ACMPCA:issueCertificate': {
    CertificateAuthorityArn: 'required',
    Csr: 'required',
    SigningAlgorithm: 'required',
    Validity: {
      Value: 'required',
      Type: 'required',
    },
    TemplateArn: '<optional>',
    IdempotencyToken: '<optional>',
  },
  'ACMPCA:listCertificateAuthorities': {},
  'ACMPCA:listPermissions': {
    CertificateAuthorityArn: 'required',
  },
  'ACMPCA:listTags': {
    CertificateAuthorityArn: 'required',
  },
  'ACMPCA:restoreCertificateAuthority': {
    CertificateAuthorityArn: 'required',
  },
  'ACMPCA:revokeCertificate': {
    CertificateAuthorityArn: 'required',
    CertificateSerial: 'required',
    RevocationReason: 'required',
  },
  'ACMPCA:tagCertificateAuthority': {
    CertificateAuthorityArn: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'ACMPCA:untagCertificateAuthority': {
    CertificateAuthorityArn: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'ACMPCA:updateCertificateAuthority': {
    CertificateAuthorityArn: 'required',
    RevocationConfiguration: {
      CrlConfiguration: {
        ExpirationInDays: ['<optional1>', '<optional2>'],
        CustomCname: '<optional>',
        S3BucketName: '<optional>',
      },
    },
    Status: '<optional>',
  },
  'APIGateway:createApiKey': {
    name: '<optional>',
    description: '<optional>',
    enabled: '<optional>',
    generateDistinctId: '<optional>',
    value: '<optional>',
    stageKeys: [
      {
        restApiId: '<optional>',
        stageName: '<optional>',
      },
    ],
    customerId: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'APIGateway:createAuthorizer': {
    restApiId: 'required',
    name: 'required',
    type: 'required',
    providerARNs: ['<optional1>', '<optional2>'],
    authType: '<optional>',
    authorizerUri: '<optional>',
    authorizerCredentials: ['<optional1>', '<optional2>'],
    identitySource: '<optional>',
    identityValidationExpression: '<optional>',
    authorizerResultTtlInSeconds: ['<optional1>', '<optional2>'],
  },
  'APIGateway:createBasePathMapping': {
    domainName: 'required',
    restApiId: 'required',
    basePath: '<optional>',
    stage: '<optional>',
  },
  'APIGateway:createDeployment': {
    restApiId: 'required',
    stageName: '<optional>',
    stageDescription: '<optional>',
    description: '<optional>',
    cacheClusterEnabled: '<optional>',
    cacheClusterSize: '<optional>',
    variables: ['<optional1>', '<optional2>'],
    canarySettings: [
      {
        percentTraffic: '<optional>',
        stageVariableOverrides: ['<optional1>', '<optional2>'],
        useStageCache: '<optional>',
      },
    ],
    tracingEnabled: '<optional>',
  },
  'APIGateway:createDocumentationPart': {
    restApiId: 'required',
    location: {
      path: '<optional>',
      method: '<optional>',
      statusCode: '<optional>',
      name: '<optional>',
    },
    properties: ['required1', 'required2'],
  },
  'APIGateway:createDocumentationVersion': {
    restApiId: 'required',
    documentationVersion: 'required',
    stageName: '<optional>',
    description: '<optional>',
  },
  'APIGateway:createDomainName': {
    domainName: 'required',
    certificateName: '<optional>',
    certificateBody: '<optional>',
    certificatePrivateKey: '<optional>',
    certificateChain: '<optional>',
    certificateArn: '<optional>',
    regionalCertificateName: '<optional>',
    regionalCertificateArn: '<optional>',
    endpointConfiguration: {
      types: ['<optional1>', '<optional2>'],
    },
    tags: ['<optional1>', '<optional2>'],
    securityPolicy: '<optional>',
  },
  'APIGateway:createModel': {
    restApiId: 'required',
    name: 'required',
    contentType: 'required',
    description: '<optional>',
    schema: '<optional>',
  },
  'APIGateway:createRequestValidator': {
    restApiId: 'required',
    name: '<optional>',
    validateRequestBody: '<optional>',
    validateRequestParameters: ['<optional1>', '<optional2>'],
  },
  'APIGateway:createResource': {
    restApiId: 'required',
    parentId: 'required',
    pathPart: 'required',
  },
  'APIGateway:createRestApi': {
    name: 'required',
    description: '<optional>',
    version: '<optional>',
    cloneFrom: '<optional>',
    binaryMediaTypes: ['<optional1>', '<optional2>'],
    minimumCompressionSize: '<optional>',
    apiKeySource: '<optional>',
    endpointConfiguration: {
      types: ['<optional1>', '<optional2>'],
    },
    policy: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'APIGateway:createStage': {
    restApiId: 'required',
    stageName: 'required',
    deploymentId: 'required',
    description: '<optional>',
    cacheClusterEnabled: '<optional>',
    cacheClusterSize: '<optional>',
    variables: ['<optional1>', '<optional2>'],
    documentationVersion: '<optional>',
    canarySettings: [
      {
        percentTraffic: '<optional>',
        deploymentId: '<optional>',
        stageVariableOverrides: ['<optional1>', '<optional2>'],
        useStageCache: '<optional>',
      },
    ],
    tracingEnabled: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'APIGateway:createUsagePlan': {
    name: 'required',
    description: '<optional>',
    apiStages: [
      {
        apiId: '<optional>',
        stage: '<optional>',
        throttle: '<optional>',
      },
    ],
    throttle: {
      burstLimit: '<optional>',
      rateLimit: '<optional>',
    },
    quota: {
      limit: '<optional>',
      offset: '<optional>',
      period: '<optional>',
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'APIGateway:createUsagePlanKey': {
    usagePlanId: 'required',
    keyId: 'required',
    keyType: 'required',
  },
  'APIGateway:createVpcLink': {
    name: 'required',
    targetArns: ['required1', 'required2'],
    description: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'APIGateway:deleteApiKey': {
    apiKey: 'required',
  },
  'APIGateway:deleteAuthorizer': {
    restApiId: 'required',
    authorizerId: 'required',
  },
  'APIGateway:deleteBasePathMapping': {
    domainName: 'required',
    basePath: 'required',
  },
  'APIGateway:deleteClientCertificate': {
    clientCertificateId: 'required',
  },
  'APIGateway:deleteDeployment': {
    restApiId: 'required',
    deploymentId: 'required',
  },
  'APIGateway:deleteDocumentationPart': {
    restApiId: 'required',
    documentationPartId: 'required',
  },
  'APIGateway:deleteDocumentationVersion': {
    restApiId: 'required',
    documentationVersion: 'required',
  },
  'APIGateway:deleteDomainName': {
    domainName: 'required',
  },
  'APIGateway:deleteGatewayResponse': {
    restApiId: 'required',
    responseType: 'required',
  },
  'APIGateway:deleteIntegration': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
  },
  'APIGateway:deleteIntegrationResponse': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    statusCode: 'required',
  },
  'APIGateway:deleteMethod': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
  },
  'APIGateway:deleteMethodResponse': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    statusCode: 'required',
  },
  'APIGateway:deleteModel': {
    restApiId: 'required',
    modelName: 'required',
  },
  'APIGateway:deleteRequestValidator': {
    restApiId: 'required',
    requestValidatorId: 'required',
  },
  'APIGateway:deleteResource': {
    restApiId: 'required',
    resourceId: 'required',
  },
  'APIGateway:deleteRestApi': {
    restApiId: 'required',
  },
  'APIGateway:deleteStage': {
    restApiId: 'required',
    stageName: 'required',
  },
  'APIGateway:deleteUsagePlan': {
    usagePlanId: 'required',
  },
  'APIGateway:deleteUsagePlanKey': {
    usagePlanId: 'required',
    keyId: 'required',
  },
  'APIGateway:deleteVpcLink': {
    vpcLinkId: 'required',
  },
  'APIGateway:flushStageAuthorizersCache': {
    restApiId: 'required',
    stageName: 'required',
  },
  'APIGateway:flushStageCache': {
    restApiId: 'required',
    stageName: 'required',
  },
  'APIGateway:generateClientCertificate': {
    description: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'APIGateway:getAccount': {},
  'APIGateway:getApiKey': {
    apiKey: 'required',
    includeValue: '<optional>',
  },
  'APIGateway:getApiKeys': {
    position: '<optional>',
    limit: '<optional>',
    nameQuery: '<optional>',
    customerId: '<optional>',
    includeValues: ['<optional1>', '<optional2>'],
  },
  'APIGateway:getAuthorizer': {
    restApiId: 'required',
    authorizerId: 'required',
  },
  'APIGateway:getAuthorizers': {
    restApiId: 'required',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getBasePathMapping': {
    domainName: 'required',
    basePath: 'required',
  },
  'APIGateway:getBasePathMappings': {
    domainName: 'required',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getClientCertificate': {
    clientCertificateId: 'required',
  },
  'APIGateway:getClientCertificates': {
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getDeployment': {
    restApiId: 'required',
    deploymentId: 'required',
    embed: '<optional>',
  },
  'APIGateway:getDeployments': {
    restApiId: 'required',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getDocumentationPart': {
    restApiId: 'required',
    documentationPartId: 'required',
  },
  'APIGateway:getDocumentationParts': {
    restApiId: 'required',
    type: '<optional>',
    nameQuery: '<optional>',
    path: '<optional>',
    position: '<optional>',
    limit: '<optional>',
    locationStatus: '<optional>',
  },
  'APIGateway:getDocumentationVersion': {
    restApiId: 'required',
    documentationVersion: 'required',
  },
  'APIGateway:getDocumentationVersions': {
    restApiId: 'required',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getDomainName': {
    domainName: 'required',
  },
  'APIGateway:getDomainNames': {
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getExport': {
    restApiId: 'required',
    stageName: 'required',
    exportType: 'required',
    parameters: ['<optional1>', '<optional2>'],
    accepts: ['<optional1>', '<optional2>'],
  },
  'APIGateway:getGatewayResponse': {
    restApiId: 'required',
    responseType: 'required',
  },
  'APIGateway:getGatewayResponses': {
    restApiId: 'required',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getIntegration': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
  },
  'APIGateway:getIntegrationResponse': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    statusCode: 'required',
  },
  'APIGateway:getMethod': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
  },
  'APIGateway:getMethodResponse': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    statusCode: 'required',
  },
  'APIGateway:getModel': {
    restApiId: 'required',
    modelName: 'required',
    flatten: '<optional>',
  },
  'APIGateway:getModelTemplate': {
    restApiId: 'required',
    modelName: 'required',
  },
  'APIGateway:getModels': {
    restApiId: 'required',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getRequestValidator': {
    restApiId: 'required',
    requestValidatorId: 'required',
  },
  'APIGateway:getRequestValidators': {
    restApiId: 'required',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getResource': {
    restApiId: 'required',
    resourceId: 'required',
    embed: '<optional>',
  },
  'APIGateway:getResources': {
    restApiId: 'required',
    position: '<optional>',
    limit: '<optional>',
    embed: '<optional>',
  },
  'APIGateway:getRestApi': {
    restApiId: 'required',
  },
  'APIGateway:getRestApis': {
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getSdk': {
    restApiId: 'required',
    stageName: 'required',
    sdkType: 'required',
    parameters: ['<optional1>', '<optional2>'],
  },
  'APIGateway:getSdkType': {
    id: 'required',
  },
  'APIGateway:getSdkTypes': {
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getStage': {
    restApiId: 'required',
    stageName: 'required',
  },
  'APIGateway:getStages': {
    restApiId: 'required',
    deploymentId: '<optional>',
  },
  'APIGateway:getTags': {
    resourceArn: 'required',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getUsage': {
    usagePlanId: 'required',
    startDate: 'required',
    endDate: 'required',
    keyId: '<optional>',
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getUsagePlan': {
    usagePlanId: 'required',
  },
  'APIGateway:getUsagePlanKey': {
    usagePlanId: 'required',
    keyId: 'required',
  },
  'APIGateway:getUsagePlanKeys': {
    usagePlanId: 'required',
    position: '<optional>',
    limit: '<optional>',
    nameQuery: '<optional>',
  },
  'APIGateway:getUsagePlans': {
    position: '<optional>',
    keyId: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:getVpcLink': {
    vpcLinkId: 'required',
  },
  'APIGateway:getVpcLinks': {
    position: '<optional>',
    limit: '<optional>',
  },
  'APIGateway:importApiKeys': {
    body: 'required',
    format: 'required',
    failOnWarnings: ['<optional1>', '<optional2>'],
  },
  'APIGateway:importDocumentationParts': {
    restApiId: 'required',
    body: 'required',
    mode: '<optional>',
    failOnWarnings: ['<optional1>', '<optional2>'],
  },
  'APIGateway:importRestApi': {
    body: 'required',
    failOnWarnings: ['<optional1>', '<optional2>'],
    parameters: ['<optional1>', '<optional2>'],
  },
  'APIGateway:putGatewayResponse': {
    restApiId: 'required',
    responseType: 'required',
    statusCode: '<optional>',
    responseParameters: ['<optional1>', '<optional2>'],
    responseTemplates: ['<optional1>', '<optional2>'],
  },
  'APIGateway:putIntegration': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    type: 'required',
    integrationHttpMethod: '<optional>',
    uri: '<optional>',
    connectionType: '<optional>',
    connectionId: '<optional>',
    credentials: ['<optional1>', '<optional2>'],
    requestParameters: ['<optional1>', '<optional2>'],
    requestTemplates: ['<optional1>', '<optional2>'],
    passthroughBehavior: '<optional>',
    cacheNamespace: '<optional>',
    cacheKeyParameters: ['<optional1>', '<optional2>'],
    contentHandling: '<optional>',
    timeoutInMillis: ['<optional1>', '<optional2>'],
  },
  'APIGateway:putIntegrationResponse': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    statusCode: 'required',
    selectionPattern: '<optional>',
    responseParameters: ['<optional1>', '<optional2>'],
    responseTemplates: ['<optional1>', '<optional2>'],
    contentHandling: '<optional>',
  },
  'APIGateway:putMethod': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    authorizationType: 'required',
    authorizerId: '<optional>',
    apiKeyRequired: '<optional>',
    operationName: '<optional>',
    requestParameters: ['<optional1>', '<optional2>'],
    requestModels: ['<optional1>', '<optional2>'],
    requestValidatorId: '<optional>',
    authorizationScopes: ['<optional1>', '<optional2>'],
  },
  'APIGateway:putMethodResponse': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    statusCode: 'required',
    responseParameters: ['<optional1>', '<optional2>'],
    responseModels: ['<optional1>', '<optional2>'],
  },
  'APIGateway:putRestApi': {
    restApiId: 'required',
    body: 'required',
    mode: '<optional>',
    failOnWarnings: ['<optional1>', '<optional2>'],
    parameters: ['<optional1>', '<optional2>'],
  },
  'APIGateway:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'APIGateway:testInvokeAuthorizer': {
    restApiId: 'required',
    authorizerId: 'required',
    headers: ['<optional1>', '<optional2>'],
    multiValueHeaders: ['<optional1>', '<optional2>'],
    pathWithQueryString: '<optional>',
    body: '<optional>',
    stageVariables: ['<optional1>', '<optional2>'],
    additionalContext: '<optional>',
  },
  'APIGateway:testInvokeMethod': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    pathWithQueryString: '<optional>',
    body: '<optional>',
    headers: ['<optional1>', '<optional2>'],
    multiValueHeaders: ['<optional1>', '<optional2>'],
    clientCertificateId: '<optional>',
    stageVariables: ['<optional1>', '<optional2>'],
  },
  'APIGateway:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'APIGateway:updateAccount': {
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateApiKey': {
    apiKey: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateAuthorizer': {
    restApiId: 'required',
    authorizerId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateBasePathMapping': {
    domainName: 'required',
    basePath: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateClientCertificate': {
    clientCertificateId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateDeployment': {
    restApiId: 'required',
    deploymentId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateDocumentationPart': {
    restApiId: 'required',
    documentationPartId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateDocumentationVersion': {
    restApiId: 'required',
    documentationVersion: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateDomainName': {
    domainName: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateGatewayResponse': {
    restApiId: 'required',
    responseType: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateIntegration': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateIntegrationResponse': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    statusCode: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateMethod': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateMethodResponse': {
    restApiId: 'required',
    resourceId: 'required',
    httpMethod: 'required',
    statusCode: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateModel': {
    restApiId: 'required',
    modelName: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateRequestValidator': {
    restApiId: 'required',
    requestValidatorId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateResource': {
    restApiId: 'required',
    resourceId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateRestApi': {
    restApiId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateStage': {
    restApiId: 'required',
    stageName: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateUsage': {
    usagePlanId: 'required',
    keyId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateUsagePlan': {
    usagePlanId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'APIGateway:updateVpcLink': {
    vpcLinkId: 'required',
    patchOperations: [
      {
        op: '<optional>',
        path: '<optional>',
        value: '<optional>',
        from: '<optional>',
      },
    ],
  },
  'AccessAnalyzer:createAnalyzer': {
    analyzerName: 'required',
    type: 'required',
    archiveRules: ['<optional1>', '<optional2>'],
    clientToken: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'AccessAnalyzer:createArchiveRule': {
    analyzerName: 'required',
    filter: 'required',
    ruleName: 'required',
    clientToken: '<optional>',
  },
  'AccessAnalyzer:deleteAnalyzer': {
    analyzerName: 'required',
    clientToken: '<optional>',
  },
  'AccessAnalyzer:deleteArchiveRule': {
    analyzerName: 'required',
    ruleName: 'required',
    clientToken: '<optional>',
  },
  'AccessAnalyzer:getAnalyzedResource': {
    analyzerArn: 'required',
    resourceArn: 'required',
  },
  'AccessAnalyzer:getAnalyzer': {
    analyzerName: 'required',
  },
  'AccessAnalyzer:getArchiveRule': {
    analyzerName: 'required',
    ruleName: 'required',
  },
  'AccessAnalyzer:getFinding': {
    analyzerArn: 'required',
    id: 'required',
  },
  'AccessAnalyzer:listAnalyzedResources': {
    analyzerArn: 'required',
    resourceType: '<optional>',
  },
  'AccessAnalyzer:listAnalyzers': {
    type: '<optional>',
  },
  'AccessAnalyzer:listArchiveRules': {
    analyzerName: 'required',
  },
  'AccessAnalyzer:listFindings': {
    analyzerArn: 'required',
    filter: '<optional>',
    sort: {
      attributeName: '<optional>',
      orderBy: '<optional>',
    },
  },
  'AccessAnalyzer:listTagsForResource': {
    resourceArn: 'required',
  },
  'AccessAnalyzer:startResourceScan': {
    analyzerArn: 'required',
    resourceArn: 'required',
  },
  'AccessAnalyzer:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'AccessAnalyzer:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'AccessAnalyzer:updateArchiveRule': {
    analyzerName: 'required',
    filter: 'required',
    ruleName: 'required',
    clientToken: '<optional>',
  },
  'AccessAnalyzer:updateFindings': {
    analyzerArn: 'required',
    status: 'required',
    clientToken: '<optional>',
    ids: ['<optional1>', '<optional2>'],
    resourceArn: '<optional>',
  },
  'AlexaForBusiness:approveSkill': {
    SkillId: 'required',
  },
  'AlexaForBusiness:associateContactWithAddressBook': {
    ContactArn: 'required',
    AddressBookArn: 'required',
  },
  'AlexaForBusiness:associateDeviceWithNetworkProfile': {
    DeviceArn: 'required',
    NetworkProfileArn: 'required',
  },
  'AlexaForBusiness:associateDeviceWithRoom': {
    DeviceArn: '<optional>',
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:associateSkillGroupWithRoom': {
    SkillGroupArn: '<optional>',
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:associateSkillWithSkillGroup': {
    SkillId: 'required',
    SkillGroupArn: '<optional>',
  },
  'AlexaForBusiness:associateSkillWithUsers': {
    SkillId: 'required',
  },
  'AlexaForBusiness:createAddressBook': {
    Name: 'required',
    Description: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'AlexaForBusiness:createBusinessReportSchedule': {
    Format: 'required',
    ContentRange: {
      Interval: '<optional>',
    },
    ScheduleName: '<optional>',
    S3BucketName: '<optional>',
    S3KeyPrefix: '<optional>',
    Recurrence: {
      StartDate: '<optional>',
    },
    ClientRequestToken: '<optional>',
  },
  'AlexaForBusiness:createConferenceProvider': {
    ConferenceProviderName: 'required',
    ConferenceProviderType: 'required',
    MeetingSetting: {
      RequirePin: 'required',
    },
    IPDialIn: '<optional>',
    PSTNDialIn: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'AlexaForBusiness:createContact': {
    FirstName: 'required',
    PhoneNumbers: ['<optional1>', '<optional2>'],
    SipAddresses: ['<optional1>', '<optional2>'],
    DisplayName: '<optional>',
    LastName: '<optional>',
    PhoneNumber: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'AlexaForBusiness:createGatewayGroup': {
    Name: 'required',
    ClientRequestToken: 'required',
    Description: '<optional>',
  },
  'AlexaForBusiness:createNetworkProfile': {
    NetworkProfileName: 'required',
    Ssid: 'required',
    SecurityType: 'required',
    ClientRequestToken: 'required',
    Description: '<optional>',
    EapMethod: '<optional>',
    CurrentPassword: '<optional>',
    NextPassword: '<optional>',
    CertificateAuthorityArn: '<optional>',
    TrustAnchors: ['<optional1>', '<optional2>'],
  },
  'AlexaForBusiness:createProfile': {
    ProfileName: 'required',
    Timezone: 'required',
    Address: 'required',
    DistanceUnit: 'required',
    TemperatureUnit: 'required',
    WakeWord: 'required',
    ClientRequestToken: '<optional>',
    SetupModeDisabled: '<optional>',
    MaxVolumeLimit: '<optional>',
    PSTNEnabled: '<optional>',
  },
  'AlexaForBusiness:createRoom': {
    RoomName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    ProfileArn: '<optional>',
    ProviderCalendarId: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'AlexaForBusiness:createSkillGroup': {
    SkillGroupName: 'required',
    Description: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'AlexaForBusiness:createUser': {
    UserId: 'required',
    Tags: ['<optional1>', '<optional2>'],
    FirstName: '<optional>',
    LastName: '<optional>',
    Email: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'AlexaForBusiness:deleteAddressBook': {
    AddressBookArn: 'required',
  },
  'AlexaForBusiness:deleteBusinessReportSchedule': {
    ScheduleArn: 'required',
  },
  'AlexaForBusiness:deleteConferenceProvider': {
    ConferenceProviderArn: 'required',
  },
  'AlexaForBusiness:deleteContact': {
    ContactArn: 'required',
  },
  'AlexaForBusiness:deleteDevice': {
    DeviceArn: 'required',
  },
  'AlexaForBusiness:deleteDeviceUsageData': {
    DeviceArn: 'required',
    DeviceUsageType: 'required',
  },
  'AlexaForBusiness:deleteGatewayGroup': {
    GatewayGroupArn: 'required',
  },
  'AlexaForBusiness:deleteNetworkProfile': {
    NetworkProfileArn: 'required',
  },
  'AlexaForBusiness:deleteProfile': {
    ProfileArn: '<optional>',
  },
  'AlexaForBusiness:deleteRoom': {
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:deleteRoomSkillParameter': {
    SkillId: 'required',
    ParameterKey: 'required',
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:deleteSkillAuthorization': {
    SkillId: 'required',
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:deleteSkillGroup': {
    SkillGroupArn: '<optional>',
  },
  'AlexaForBusiness:deleteUser': {
    EnrollmentId: 'required',
    UserArn: '<optional>',
  },
  'AlexaForBusiness:disassociateContactFromAddressBook': {
    ContactArn: 'required',
    AddressBookArn: 'required',
  },
  'AlexaForBusiness:disassociateDeviceFromRoom': {
    DeviceArn: '<optional>',
  },
  'AlexaForBusiness:disassociateSkillFromSkillGroup': {
    SkillId: 'required',
    SkillGroupArn: '<optional>',
  },
  'AlexaForBusiness:disassociateSkillFromUsers': {
    SkillId: 'required',
  },
  'AlexaForBusiness:disassociateSkillGroupFromRoom': {
    SkillGroupArn: '<optional>',
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:forgetSmartHomeAppliances': {
    RoomArn: 'required',
  },
  'AlexaForBusiness:getAddressBook': {
    AddressBookArn: 'required',
  },
  'AlexaForBusiness:getConferencePreference': {},
  'AlexaForBusiness:getConferenceProvider': {
    ConferenceProviderArn: 'required',
  },
  'AlexaForBusiness:getContact': {
    ContactArn: 'required',
  },
  'AlexaForBusiness:getDevice': {
    DeviceArn: '<optional>',
  },
  'AlexaForBusiness:getGateway': {
    GatewayArn: 'required',
  },
  'AlexaForBusiness:getGatewayGroup': {
    GatewayGroupArn: 'required',
  },
  'AlexaForBusiness:getInvitationConfiguration': {},
  'AlexaForBusiness:getNetworkProfile': {
    NetworkProfileArn: 'required',
  },
  'AlexaForBusiness:getProfile': {
    ProfileArn: '<optional>',
  },
  'AlexaForBusiness:getRoom': {
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:getRoomSkillParameter': {
    SkillId: 'required',
    ParameterKey: 'required',
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:getSkillGroup': {
    SkillGroupArn: '<optional>',
  },
  'AlexaForBusiness:listBusinessReportSchedules': {},
  'AlexaForBusiness:listConferenceProviders': {},
  'AlexaForBusiness:listDeviceEvents': {
    DeviceArn: 'required',
    EventType: '<optional>',
  },
  'AlexaForBusiness:listGatewayGroups': {},
  'AlexaForBusiness:listGateways': {
    GatewayGroupArn: '<optional>',
  },
  'AlexaForBusiness:listSkills': {
    SkillGroupArn: '<optional>',
    EnablementType: '<optional>',
    SkillType: '<optional>',
  },
  'AlexaForBusiness:listSkillsStoreCategories': {},
  'AlexaForBusiness:listSkillsStoreSkillsByCategory': {
    CategoryId: 'required',
  },
  'AlexaForBusiness:listSmartHomeAppliances': {
    RoomArn: 'required',
  },
  'AlexaForBusiness:listTags': {
    Arn: 'required',
  },
  'AlexaForBusiness:putConferencePreference': {
    ConferencePreference: {
      DefaultConferenceProviderArn: '<optional>',
    },
  },
  'AlexaForBusiness:putInvitationConfiguration': {
    OrganizationName: 'required',
    ContactEmail: '<optional>',
    PrivateSkillIds: ['<optional1>', '<optional2>'],
  },
  'AlexaForBusiness:putRoomSkillParameter': {
    SkillId: 'required',
    RoomSkillParameter: {
      ParameterKey: 'required',
      ParameterValue: 'required',
    },
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:putSkillAuthorization': {
    AuthorizationResult: 'required',
    SkillId: 'required',
    RoomArn: '<optional>',
  },
  'AlexaForBusiness:registerAVSDevice': {
    ClientId: 'required',
    UserCode: 'required',
    ProductId: 'required',
    DeviceSerialNumber: 'required',
    AmazonId: 'required',
  },
  'AlexaForBusiness:rejectSkill': {
    SkillId: 'required',
  },
  'AlexaForBusiness:resolveRoom': {
    UserId: 'required',
    SkillId: 'required',
  },
  'AlexaForBusiness:revokeInvitation': {
    UserArn: '<optional>',
    EnrollmentId: '<optional>',
  },
  'AlexaForBusiness:searchAddressBooks': {
    SortCriteria: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'AlexaForBusiness:searchContacts': {
    SortCriteria: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'AlexaForBusiness:searchDevices': {
    SortCriteria: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'AlexaForBusiness:searchNetworkProfiles': {
    SortCriteria: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'AlexaForBusiness:searchProfiles': {
    SortCriteria: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'AlexaForBusiness:searchRooms': {
    SortCriteria: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'AlexaForBusiness:searchSkillGroups': {
    SortCriteria: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'AlexaForBusiness:searchUsers': {
    SortCriteria: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'AlexaForBusiness:sendAnnouncement': {
    RoomFilters: [
      {
        Key: 'required',
        Values: ['required1', 'required2'],
      },
    ],
    Content: {
      TextList: '<optional>',
      SsmlList: '<optional>',
      AudioList: '<optional>',
    },
    ClientRequestToken: 'required',
    TimeToLiveInSeconds: ['<optional1>', '<optional2>'],
  },
  'AlexaForBusiness:sendInvitation': {
    UserArn: '<optional>',
  },
  'AlexaForBusiness:startDeviceSync': {
    Features: ['required1', 'required2'],
    RoomArn: '<optional>',
    DeviceArn: '<optional>',
  },
  'AlexaForBusiness:startSmartHomeApplianceDiscovery': {
    RoomArn: 'required',
  },
  'AlexaForBusiness:tagResource': {
    Arn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'AlexaForBusiness:untagResource': {
    Arn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'AlexaForBusiness:updateAddressBook': {
    AddressBookArn: 'required',
    Name: '<optional>',
    Description: '<optional>',
  },
  'AlexaForBusiness:updateBusinessReportSchedule': {
    ScheduleArn: 'required',
    S3BucketName: '<optional>',
    S3KeyPrefix: '<optional>',
    Format: '<optional>',
    ScheduleName: '<optional>',
    Recurrence: {
      StartDate: '<optional>',
    },
  },
  'AlexaForBusiness:updateConferenceProvider': {
    ConferenceProviderArn: 'required',
    ConferenceProviderType: 'required',
    MeetingSetting: {
      RequirePin: 'required',
    },
    IPDialIn: '<optional>',
    PSTNDialIn: '<optional>',
  },
  'AlexaForBusiness:updateContact': {
    ContactArn: 'required',
    PhoneNumbers: ['<optional1>', '<optional2>'],
    SipAddresses: ['<optional1>', '<optional2>'],
    DisplayName: '<optional>',
    FirstName: '<optional>',
    LastName: '<optional>',
    PhoneNumber: '<optional>',
  },
  'AlexaForBusiness:updateDevice': {
    DeviceArn: '<optional>',
    DeviceName: '<optional>',
  },
  'AlexaForBusiness:updateGateway': {
    GatewayArn: 'required',
    Name: '<optional>',
    Description: '<optional>',
    SoftwareVersion: '<optional>',
  },
  'AlexaForBusiness:updateGatewayGroup': {
    GatewayGroupArn: 'required',
    Name: '<optional>',
    Description: '<optional>',
  },
  'AlexaForBusiness:updateNetworkProfile': {
    NetworkProfileArn: 'required',
    NetworkProfileName: '<optional>',
    Description: '<optional>',
    CurrentPassword: '<optional>',
    NextPassword: '<optional>',
    CertificateAuthorityArn: '<optional>',
    TrustAnchors: ['<optional1>', '<optional2>'],
  },
  'AlexaForBusiness:updateProfile': {
    ProfileArn: '<optional>',
    ProfileName: '<optional>',
    IsDefault: '<optional>',
    Timezone: '<optional>',
    Address: '<optional>',
    DistanceUnit: '<optional>',
    TemperatureUnit: '<optional>',
    WakeWord: '<optional>',
    SetupModeDisabled: '<optional>',
    MaxVolumeLimit: '<optional>',
    PSTNEnabled: '<optional>',
  },
  'AlexaForBusiness:updateRoom': {
    RoomArn: '<optional>',
    RoomName: '<optional>',
    Description: '<optional>',
    ProviderCalendarId: '<optional>',
    ProfileArn: '<optional>',
  },
  'AlexaForBusiness:updateSkillGroup': {
    SkillGroupArn: '<optional>',
    SkillGroupName: '<optional>',
    Description: '<optional>',
  },
  'Amplify:createApp': {
    name: 'required',
    customRules: [
      {
        status: '<optional>',
        condition: '<optional>',
      },
    ],
    description: '<optional>',
    repository: '<optional>',
    platform: '<optional>',
    iamServiceRoleArn: '<optional>',
    oauthToken: '<optional>',
    accessToken: '<optional>',
    environmentVariables: ['<optional1>', '<optional2>'],
    enableBranchAutoBuild: '<optional>',
    enableBasicAuth: '<optional>',
    basicAuthCredentials: ['<optional1>', '<optional2>'],
    tags: ['<optional1>', '<optional2>'],
    buildSpec: '<optional>',
    enableAutoBranchCreation: '<optional>',
    autoBranchCreationPatterns: ['<optional1>', '<optional2>'],
    autoBranchCreationConfig: {
      stage: '<optional>',
      framework: '<optional>',
      enableAutoBuild: '<optional>',
      environmentVariables: ['<optional1>', '<optional2>'],
      basicAuthCredentials: ['<optional1>', '<optional2>'],
      enableBasicAuth: '<optional>',
      buildSpec: '<optional>',
    },
  },
  'Amplify:createBackendEnvironment': {
    appId: 'required',
    environmentName: 'required',
    stackName: '<optional>',
    deploymentArtifacts: ['<optional1>', '<optional2>'],
  },
  'Amplify:createBranch': {
    appId: 'required',
    branchName: 'required',
    description: '<optional>',
    stage: '<optional>',
    framework: '<optional>',
    enableNotification: '<optional>',
    enableAutoBuild: '<optional>',
    environmentVariables: ['<optional1>', '<optional2>'],
    basicAuthCredentials: ['<optional1>', '<optional2>'],
    enableBasicAuth: '<optional>',
    tags: ['<optional1>', '<optional2>'],
    buildSpec: '<optional>',
    ttl: '<optional>',
    displayName: '<optional>',
  },
  'Amplify:createDeployment': {
    appId: 'required',
    branchName: 'required',
    fileMap: '<optional>',
  },
  'Amplify:createDomainAssociation': {
    appId: 'required',
    domainName: 'required',
    subDomainSettings: [
      {
        prefix: 'required',
        branchName: 'required',
      },
    ],
    enableAutoSubDomain: '<optional>',
  },
  'Amplify:createWebhook': {
    appId: 'required',
    branchName: 'required',
    description: '<optional>',
  },
  'Amplify:deleteApp': {
    appId: 'required',
  },
  'Amplify:deleteBackendEnvironment': {
    appId: 'required',
    environmentName: 'required',
  },
  'Amplify:deleteBranch': {
    appId: 'required',
    branchName: 'required',
  },
  'Amplify:deleteDomainAssociation': {
    appId: 'required',
    domainName: 'required',
  },
  'Amplify:deleteJob': {
    appId: 'required',
    branchName: 'required',
    jobId: 'required',
  },
  'Amplify:deleteWebhook': {
    webhookId: 'required',
  },
  'Amplify:generateAccessLogs': {
    domainName: 'required',
    appId: 'required',
    startTime: '<optional>',
    endTime: '<optional>',
  },
  'Amplify:getApp': {
    appId: 'required',
  },
  'Amplify:getArtifactUrl': {
    artifactId: 'required',
  },
  'Amplify:getBackendEnvironment': {
    appId: 'required',
    environmentName: 'required',
  },
  'Amplify:getBranch': {
    appId: 'required',
    branchName: 'required',
  },
  'Amplify:getDomainAssociation': {
    appId: 'required',
    domainName: 'required',
  },
  'Amplify:getJob': {
    appId: 'required',
    branchName: 'required',
    jobId: 'required',
  },
  'Amplify:getWebhook': {
    webhookId: 'required',
  },
  'Amplify:listApps': {},
  'Amplify:listArtifacts': {
    appId: 'required',
    branchName: 'required',
    jobId: 'required',
  },
  'Amplify:listBackendEnvironments': {
    appId: 'required',
    environmentName: '<optional>',
  },
  'Amplify:listBranches': {
    appId: 'required',
  },
  'Amplify:listDomainAssociations': {
    appId: 'required',
  },
  'Amplify:listJobs': {
    appId: 'required',
    branchName: 'required',
  },
  'Amplify:listTagsForResource': {
    resourceArn: 'required',
  },
  'Amplify:listWebhooks': {
    appId: 'required',
  },
  'Amplify:startDeployment': {
    appId: 'required',
    branchName: 'required',
    jobId: '<optional>',
    sourceUrl: '<optional>',
  },
  'Amplify:startJob': {
    appId: 'required',
    branchName: 'required',
    jobType: 'required',
    jobId: '<optional>',
    jobReason: '<optional>',
    commitId: '<optional>',
    commitMessage: '<optional>',
    commitTime: '<optional>',
  },
  'Amplify:stopJob': {
    appId: 'required',
    branchName: 'required',
    jobId: 'required',
  },
  'Amplify:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'Amplify:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'Amplify:updateApp': {
    appId: 'required',
    customRules: [
      {
        status: '<optional>',
        condition: '<optional>',
      },
    ],
    name: '<optional>',
    description: '<optional>',
    platform: '<optional>',
    iamServiceRoleArn: '<optional>',
    environmentVariables: ['<optional1>', '<optional2>'],
    enableBranchAutoBuild: '<optional>',
    enableBasicAuth: '<optional>',
    basicAuthCredentials: ['<optional1>', '<optional2>'],
    buildSpec: '<optional>',
    enableAutoBranchCreation: '<optional>',
    autoBranchCreationPatterns: ['<optional1>', '<optional2>'],
    autoBranchCreationConfig: {
      stage: '<optional>',
      framework: '<optional>',
      enableAutoBuild: '<optional>',
      environmentVariables: ['<optional1>', '<optional2>'],
      basicAuthCredentials: ['<optional1>', '<optional2>'],
      enableBasicAuth: '<optional>',
      buildSpec: '<optional>',
    },
  },
  'Amplify:updateBranch': {
    appId: 'required',
    branchName: 'required',
    description: '<optional>',
    framework: '<optional>',
    stage: '<optional>',
    enableNotification: '<optional>',
    enableAutoBuild: '<optional>',
    environmentVariables: ['<optional1>', '<optional2>'],
    basicAuthCredentials: ['<optional1>', '<optional2>'],
    enableBasicAuth: '<optional>',
    buildSpec: '<optional>',
    ttl: '<optional>',
    displayName: '<optional>',
  },
  'Amplify:updateDomainAssociation': {
    appId: 'required',
    domainName: 'required',
    subDomainSettings: [
      {
        prefix: 'required',
        branchName: 'required',
      },
    ],
    enableAutoSubDomain: '<optional>',
  },
  'Amplify:updateWebhook': {
    webhookId: 'required',
    branchName: '<optional>',
    description: '<optional>',
  },
  'ApiGatewayManagementApi:deleteConnection': {
    ConnectionId: 'required',
  },
  'ApiGatewayManagementApi:getConnection': {
    ConnectionId: 'required',
  },
  'ApiGatewayManagementApi:postToConnection': {
    ConnectionId: 'required',
    Data: ['required1', 'required2'],
  },
  'ApiGatewayV2:createApi': {
    RouteSelectionExpression: 'required',
    ProtocolType: 'required',
    Name: 'required',
    ApiKeySelectionExpression: '<optional>',
    Description: '<optional>',
    DisableSchemaValidation: '<optional>',
    Version: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:createApiMapping': {
    DomainName: 'required',
    Stage: 'required',
    ApiId: 'required',
    ApiMappingKey: '<optional>',
  },
  'ApiGatewayV2:createAuthorizer': {
    ApiId: 'required',
    AuthorizerUri: 'required',
    AuthorizerType: 'required',
    IdentitySource: 'required',
    Name: 'required',
    AuthorizerCredentialsArn: '<optional>',
    AuthorizerResultTtlInSeconds: ['<optional1>', '<optional2>'],
    IdentityValidationExpression: '<optional>',
    ProviderArns: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:createDeployment': {
    ApiId: 'required',
    Description: '<optional>',
    StageName: '<optional>',
  },
  'ApiGatewayV2:createDomainName': {
    DomainName: 'required',
    DomainNameConfigurations: [
      {
        ApiGatewayDomainName: '<optional>',
        CertificateArn: '<optional>',
        CertificateName: '<optional>',
        CertificateUploadDate: '<optional>',
        EndpointType: '<optional>',
        HostedZoneId: '<optional>',
        SecurityPolicy: '<optional>',
        DomainNameStatus: '<optional>',
        DomainNameStatusMessage: '<optional>',
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:createIntegration': {
    ApiId: 'required',
    IntegrationType: 'required',
    ConnectionId: '<optional>',
    ConnectionType: '<optional>',
    ContentHandlingStrategy: '<optional>',
    CredentialsArn: '<optional>',
    Description: '<optional>',
    IntegrationMethod: '<optional>',
    IntegrationUri: '<optional>',
    PassthroughBehavior: '<optional>',
    RequestParameters: ['<optional1>', '<optional2>'],
    RequestTemplates: ['<optional1>', '<optional2>'],
    TemplateSelectionExpression: '<optional>',
    TimeoutInMillis: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:createIntegrationResponse': {
    ApiId: 'required',
    IntegrationId: 'required',
    IntegrationResponseKey: 'required',
    ContentHandlingStrategy: '<optional>',
    ResponseParameters: ['<optional1>', '<optional2>'],
    ResponseTemplates: ['<optional1>', '<optional2>'],
    TemplateSelectionExpression: '<optional>',
  },
  'ApiGatewayV2:createModel': {
    ApiId: 'required',
    Schema: 'required',
    Name: 'required',
    ContentType: '<optional>',
    Description: '<optional>',
  },
  'ApiGatewayV2:createRoute': {
    ApiId: 'required',
    RouteKey: 'required',
    ApiKeyRequired: '<optional>',
    AuthorizationScopes: ['<optional1>', '<optional2>'],
    AuthorizationType: '<optional>',
    AuthorizerId: '<optional>',
    ModelSelectionExpression: '<optional>',
    OperationName: '<optional>',
    RequestModels: ['<optional1>', '<optional2>'],
    RequestParameters: ['<optional1>', '<optional2>'],
    RouteResponseSelectionExpression: '<optional>',
    Target: '<optional>',
  },
  'ApiGatewayV2:createRouteResponse': {
    ApiId: 'required',
    RouteId: 'required',
    RouteResponseKey: 'required',
    ModelSelectionExpression: '<optional>',
    ResponseModels: ['<optional1>', '<optional2>'],
    ResponseParameters: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:createStage': {
    ApiId: 'required',
    StageName: 'required',
    AccessLogSettings: [
      {
        DestinationArn: '<optional>',
        Format: '<optional>',
      },
    ],
    ClientCertificateId: '<optional>',
    DefaultRouteSettings: [
      {
        DataTraceEnabled: '<optional>',
        DetailedMetricsEnabled: '<optional>',
        LoggingLevel: '<optional>',
        ThrottlingBurstLimit: '<optional>',
        ThrottlingRateLimit: '<optional>',
      },
    ],
    DeploymentId: '<optional>',
    Description: '<optional>',
    RouteSettings: ['<optional1>', '<optional2>'],
    StageVariables: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:deleteApi': {
    ApiId: 'required',
  },
  'ApiGatewayV2:deleteApiMapping': {
    ApiMappingId: 'required',
    DomainName: 'required',
  },
  'ApiGatewayV2:deleteAuthorizer': {
    AuthorizerId: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:deleteCorsConfiguration': {
    ApiId: 'required',
  },
  'ApiGatewayV2:deleteDeployment': {
    ApiId: 'required',
    DeploymentId: 'required',
  },
  'ApiGatewayV2:deleteDomainName': {
    DomainName: 'required',
  },
  'ApiGatewayV2:deleteIntegration': {
    ApiId: 'required',
    IntegrationId: 'required',
  },
  'ApiGatewayV2:deleteIntegrationResponse': {
    ApiId: 'required',
    IntegrationResponseId: 'required',
    IntegrationId: 'required',
  },
  'ApiGatewayV2:deleteModel': {
    ModelId: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:deleteRoute': {
    ApiId: 'required',
    RouteId: 'required',
  },
  'ApiGatewayV2:deleteRouteResponse': {
    RouteResponseId: 'required',
    ApiId: 'required',
    RouteId: 'required',
  },
  'ApiGatewayV2:deleteRouteSettings': {
    StageName: 'required',
    RouteKey: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:deleteStage': {
    StageName: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:getApi': {
    ApiId: 'required',
  },
  'ApiGatewayV2:getApiMapping': {
    ApiMappingId: 'required',
    DomainName: 'required',
  },
  'ApiGatewayV2:getApiMappings': {
    DomainName: 'required',
  },
  'ApiGatewayV2:getApis': {},
  'ApiGatewayV2:getAuthorizer': {
    AuthorizerId: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:getAuthorizers': {
    ApiId: 'required',
  },
  'ApiGatewayV2:getDeployment': {
    ApiId: 'required',
    DeploymentId: 'required',
  },
  'ApiGatewayV2:getDeployments': {
    ApiId: 'required',
  },
  'ApiGatewayV2:getDomainName': {
    DomainName: 'required',
  },
  'ApiGatewayV2:getDomainNames': {},
  'ApiGatewayV2:getIntegration': {
    ApiId: 'required',
    IntegrationId: 'required',
  },
  'ApiGatewayV2:getIntegrationResponse': {
    ApiId: 'required',
    IntegrationResponseId: 'required',
    IntegrationId: 'required',
  },
  'ApiGatewayV2:getIntegrationResponses': {
    IntegrationId: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:getIntegrations': {
    ApiId: 'required',
  },
  'ApiGatewayV2:getModel': {
    ModelId: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:getModelTemplate': {
    ModelId: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:getModels': {
    ApiId: 'required',
  },
  'ApiGatewayV2:getRoute': {
    ApiId: 'required',
    RouteId: 'required',
  },
  'ApiGatewayV2:getRouteResponse': {
    RouteResponseId: 'required',
    ApiId: 'required',
    RouteId: 'required',
  },
  'ApiGatewayV2:getRouteResponses': {
    RouteId: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:getRoutes': {
    ApiId: 'required',
  },
  'ApiGatewayV2:getStage': {
    StageName: 'required',
    ApiId: 'required',
  },
  'ApiGatewayV2:getStages': {
    ApiId: 'required',
  },
  'ApiGatewayV2:getTags': {
    ResourceArn: 'required',
  },
  'ApiGatewayV2:importApi': {
    Body: 'required',
    Basepath: '<optional>',
    FailOnWarnings: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:reimportApi': {
    ApiId: 'required',
    Body: 'required',
    Basepath: '<optional>',
    FailOnWarnings: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:tagResource': {
    ResourceArn: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'ApiGatewayV2:updateApi': {
    ApiId: 'required',
    ApiKeySelectionExpression: '<optional>',
    Description: '<optional>',
    DisableSchemaValidation: '<optional>',
    Name: '<optional>',
    RouteSelectionExpression: '<optional>',
    Version: '<optional>',
  },
  'ApiGatewayV2:updateApiMapping': {
    ApiMappingId: 'required',
    ApiId: 'required',
    DomainName: 'required',
    ApiMappingKey: '<optional>',
    Stage: '<optional>',
  },
  'ApiGatewayV2:updateAuthorizer': {
    AuthorizerId: 'required',
    ApiId: 'required',
    AuthorizerCredentialsArn: '<optional>',
    AuthorizerResultTtlInSeconds: ['<optional1>', '<optional2>'],
    AuthorizerType: '<optional>',
    AuthorizerUri: '<optional>',
    IdentitySource: '<optional>',
    IdentityValidationExpression: '<optional>',
    Name: '<optional>',
    ProviderArns: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:updateDeployment': {
    ApiId: 'required',
    DeploymentId: 'required',
    Description: '<optional>',
  },
  'ApiGatewayV2:updateDomainName': {
    DomainName: 'required',
    DomainNameConfigurations: [
      {
        ApiGatewayDomainName: '<optional>',
        CertificateArn: '<optional>',
        CertificateName: '<optional>',
        CertificateUploadDate: '<optional>',
        EndpointType: '<optional>',
        HostedZoneId: '<optional>',
        SecurityPolicy: '<optional>',
        DomainNameStatus: '<optional>',
        DomainNameStatusMessage: '<optional>',
      },
    ],
  },
  'ApiGatewayV2:updateIntegration': {
    ApiId: 'required',
    IntegrationId: 'required',
    ConnectionId: '<optional>',
    ConnectionType: '<optional>',
    ContentHandlingStrategy: '<optional>',
    CredentialsArn: '<optional>',
    Description: '<optional>',
    IntegrationMethod: '<optional>',
    IntegrationType: '<optional>',
    IntegrationUri: '<optional>',
    PassthroughBehavior: '<optional>',
    RequestParameters: ['<optional1>', '<optional2>'],
    RequestTemplates: ['<optional1>', '<optional2>'],
    TemplateSelectionExpression: '<optional>',
    TimeoutInMillis: ['<optional1>', '<optional2>'],
  },
  'ApiGatewayV2:updateIntegrationResponse': {
    ApiId: 'required',
    IntegrationResponseId: 'required',
    IntegrationId: 'required',
    ContentHandlingStrategy: '<optional>',
    IntegrationResponseKey: '<optional>',
    ResponseParameters: ['<optional1>', '<optional2>'],
    ResponseTemplates: ['<optional1>', '<optional2>'],
    TemplateSelectionExpression: '<optional>',
  },
  'ApiGatewayV2:updateModel': {
    ModelId: 'required',
    ApiId: 'required',
    ContentType: '<optional>',
    Description: '<optional>',
    Name: '<optional>',
    Schema: '<optional>',
  },
  'ApiGatewayV2:updateRoute': {
    ApiId: 'required',
    RouteId: 'required',
    ApiKeyRequired: '<optional>',
    AuthorizationScopes: ['<optional1>', '<optional2>'],
    AuthorizationType: '<optional>',
    AuthorizerId: '<optional>',
    ModelSelectionExpression: '<optional>',
    OperationName: '<optional>',
    RequestModels: ['<optional1>', '<optional2>'],
    RequestParameters: ['<optional1>', '<optional2>'],
    RouteKey: '<optional>',
    RouteResponseSelectionExpression: '<optional>',
    Target: '<optional>',
  },
  'ApiGatewayV2:updateRouteResponse': {
    RouteResponseId: 'required',
    ApiId: 'required',
    RouteId: 'required',
    ModelSelectionExpression: '<optional>',
    ResponseModels: ['<optional1>', '<optional2>'],
    ResponseParameters: ['<optional1>', '<optional2>'],
    RouteResponseKey: '<optional>',
  },
  'ApiGatewayV2:updateStage': {
    StageName: 'required',
    ApiId: 'required',
    AccessLogSettings: [
      {
        DestinationArn: '<optional>',
        Format: '<optional>',
      },
    ],
    ClientCertificateId: '<optional>',
    DefaultRouteSettings: [
      {
        DataTraceEnabled: '<optional>',
        DetailedMetricsEnabled: '<optional>',
        LoggingLevel: '<optional>',
        ThrottlingBurstLimit: '<optional>',
        ThrottlingRateLimit: '<optional>',
      },
    ],
    DeploymentId: '<optional>',
    Description: '<optional>',
    RouteSettings: ['<optional1>', '<optional2>'],
    StageVariables: ['<optional1>', '<optional2>'],
  },
  'AppConfig:createApplication': {
    Name: 'required',
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'AppConfig:createConfigurationProfile': {
    ApplicationId: 'required',
    Name: 'required',
    LocationUri: 'required',
    RetrievalRoleArn: 'required',
    Validators: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'AppConfig:createDeploymentStrategy': {
    Name: 'required',
    DeploymentDurationInMinutes: ['required1', 'required2'],
    GrowthFactor: 'required',
    ReplicateTo: 'required',
    Description: '<optional>',
    FinalBakeTimeInMinutes: ['<optional1>', '<optional2>'],
    GrowthType: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'AppConfig:createEnvironment': {
    ApplicationId: 'required',
    Name: 'required',
    Description: '<optional>',
    Monitors: [
      {
        AlarmArn: '<optional>',
        AlarmRoleArn: '<optional>',
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
  },
  'AppConfig:deleteApplication': {
    ApplicationId: 'required',
  },
  'AppConfig:deleteConfigurationProfile': {
    ApplicationId: 'required',
    ConfigurationProfileId: 'required',
  },
  'AppConfig:deleteDeploymentStrategy': {
    DeploymentStrategyId: 'required',
  },
  'AppConfig:deleteEnvironment': {
    ApplicationId: 'required',
    EnvironmentId: 'required',
  },
  'AppConfig:getApplication': {
    ApplicationId: 'required',
  },
  'AppConfig:getConfiguration': {
    Application: 'required',
    Environment: 'required',
    Configuration: 'required',
    ClientId: 'required',
    ClientConfigurationVersion: '<optional>',
  },
  'AppConfig:getConfigurationProfile': {
    ApplicationId: 'required',
    ConfigurationProfileId: 'required',
  },
  'AppConfig:getDeployment': {
    ApplicationId: 'required',
    EnvironmentId: 'required',
    DeploymentNumber: 'required',
  },
  'AppConfig:getDeploymentStrategy': {
    DeploymentStrategyId: 'required',
  },
  'AppConfig:getEnvironment': {
    ApplicationId: 'required',
    EnvironmentId: 'required',
  },
  'AppConfig:listApplications': {},
  'AppConfig:listConfigurationProfiles': {
    ApplicationId: 'required',
  },
  'AppConfig:listDeploymentStrategies': {},
  'AppConfig:listDeployments': {
    ApplicationId: 'required',
    EnvironmentId: 'required',
  },
  'AppConfig:listEnvironments': {
    ApplicationId: 'required',
  },
  'AppConfig:listTagsForResource': {
    ResourceArn: 'required',
  },
  'AppConfig:startDeployment': {
    ApplicationId: 'required',
    EnvironmentId: 'required',
    DeploymentStrategyId: 'required',
    ConfigurationProfileId: 'required',
    ConfigurationVersion: 'required',
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'AppConfig:stopDeployment': {
    ApplicationId: 'required',
    EnvironmentId: 'required',
    DeploymentNumber: 'required',
  },
  'AppConfig:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'AppConfig:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'AppConfig:updateApplication': {
    ApplicationId: 'required',
    Name: '<optional>',
    Description: '<optional>',
  },
  'AppConfig:updateConfigurationProfile': {
    ApplicationId: 'required',
    ConfigurationProfileId: 'required',
    Validators: ['<optional1>', '<optional2>'],
    Name: '<optional>',
    Description: '<optional>',
    RetrievalRoleArn: '<optional>',
  },
  'AppConfig:updateDeploymentStrategy': {
    DeploymentStrategyId: 'required',
    Description: '<optional>',
    DeploymentDurationInMinutes: ['<optional1>', '<optional2>'],
    FinalBakeTimeInMinutes: ['<optional1>', '<optional2>'],
    GrowthFactor: '<optional>',
    GrowthType: '<optional>',
  },
  'AppConfig:updateEnvironment': {
    ApplicationId: 'required',
    EnvironmentId: 'required',
    Name: '<optional>',
    Description: '<optional>',
    Monitors: [
      {
        AlarmArn: '<optional>',
        AlarmRoleArn: '<optional>',
      },
    ],
  },
  'AppConfig:validateConfiguration': {
    ApplicationId: 'required',
    ConfigurationProfileId: 'required',
    ConfigurationVersion: 'required',
  },
  'AppMesh:createMesh': {
    meshName: 'required',
    spec: {
      egressFilter: '<optional>',
    },
    tags: [
      {
        value: '<optional>',
      },
    ],
    clientToken: '<optional>',
  },
  'AppMesh:createRoute': {
    meshName: 'required',
    routeName: 'required',
    spec: {
      httpRoute: '<optional>',
      tcpRoute: '<optional>',
    },
    virtualRouterName: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
    clientToken: '<optional>',
  },
  'AppMesh:createVirtualNode': {
    meshName: 'required',
    spec: {
      backends: [
        {
          virtualService: '<optional>',
        },
      ],
      listeners: [
        {
          healthCheck: {
            path: '<optional>',
            port: '<optional>',
          },
        },
      ],
      logging: {
        accessLog: {
          file: '<optional>',
        },
      },
      serviceDiscovery: {
        awsCloudMap: {
          attributes: ['<optional1>', '<optional2>'],
        },
        dns: ['<optional1>', '<optional2>'],
      },
    },
    virtualNodeName: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
    clientToken: '<optional>',
  },
  'AppMesh:createVirtualRouter': {
    meshName: 'required',
    spec: {
      listeners: ['<optional1>', '<optional2>'],
    },
    virtualRouterName: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
    clientToken: '<optional>',
  },
  'AppMesh:createVirtualService': {
    meshName: 'required',
    spec: {
      provider: {
        virtualNode: '<optional>',
        virtualRouter: '<optional>',
      },
    },
    virtualServiceName: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
    clientToken: '<optional>',
  },
  'AppMesh:deleteMesh': {
    meshName: 'required',
  },
  'AppMesh:deleteRoute': {
    meshName: 'required',
    routeName: 'required',
    virtualRouterName: 'required',
  },
  'AppMesh:deleteVirtualNode': {
    meshName: 'required',
    virtualNodeName: 'required',
  },
  'AppMesh:deleteVirtualRouter': {
    meshName: 'required',
    virtualRouterName: 'required',
  },
  'AppMesh:deleteVirtualService': {
    meshName: 'required',
    virtualServiceName: 'required',
  },
  'AppMesh:describeMesh': {
    meshName: 'required',
  },
  'AppMesh:describeRoute': {
    meshName: 'required',
    routeName: 'required',
    virtualRouterName: 'required',
  },
  'AppMesh:describeVirtualNode': {
    meshName: 'required',
    virtualNodeName: 'required',
  },
  'AppMesh:describeVirtualRouter': {
    meshName: 'required',
    virtualRouterName: 'required',
  },
  'AppMesh:describeVirtualService': {
    meshName: 'required',
    virtualServiceName: 'required',
  },
  'AppMesh:listMeshes': {
    limit: '<optional>',
  },
  'AppMesh:listRoutes': {
    meshName: 'required',
    virtualRouterName: 'required',
    limit: '<optional>',
  },
  'AppMesh:listTagsForResource': {
    resourceArn: 'required',
    limit: '<optional>',
  },
  'AppMesh:listVirtualNodes': {
    meshName: 'required',
    limit: '<optional>',
  },
  'AppMesh:listVirtualRouters': {
    meshName: 'required',
    limit: '<optional>',
  },
  'AppMesh:listVirtualServices': {
    meshName: 'required',
    limit: '<optional>',
  },
  'AppMesh:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
  },
  'AppMesh:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'AppMesh:updateMesh': {
    meshName: 'required',
    spec: {
      egressFilter: '<optional>',
    },
    clientToken: '<optional>',
  },
  'AppMesh:updateRoute': {
    meshName: 'required',
    routeName: 'required',
    spec: {
      httpRoute: '<optional>',
      tcpRoute: '<optional>',
    },
    virtualRouterName: 'required',
    clientToken: '<optional>',
  },
  'AppMesh:updateVirtualNode': {
    meshName: 'required',
    spec: {
      backends: [
        {
          virtualService: '<optional>',
        },
      ],
      listeners: [
        {
          healthCheck: {
            path: '<optional>',
            port: '<optional>',
          },
        },
      ],
      logging: {
        accessLog: {
          file: '<optional>',
        },
      },
      serviceDiscovery: {
        awsCloudMap: {
          attributes: ['<optional1>', '<optional2>'],
        },
        dns: ['<optional1>', '<optional2>'],
      },
    },
    virtualNodeName: 'required',
    clientToken: '<optional>',
  },
  'AppMesh:updateVirtualRouter': {
    meshName: 'required',
    spec: {
      listeners: ['<optional1>', '<optional2>'],
    },
    virtualRouterName: 'required',
    clientToken: '<optional>',
  },
  'AppMesh:updateVirtualService': {
    meshName: 'required',
    spec: {
      provider: {
        virtualNode: '<optional>',
        virtualRouter: '<optional>',
      },
    },
    virtualServiceName: 'required',
    clientToken: '<optional>',
  },
  'AppStream:associateFleet': {
    FleetName: 'required',
    StackName: 'required',
  },
  'AppStream:batchAssociateUserStack': {
    UserStackAssociations: [
      {
        SendEmailNotification: '<optional>',
      },
    ],
  },
  'AppStream:batchDisassociateUserStack': {
    UserStackAssociations: [
      {
        SendEmailNotification: '<optional>',
      },
    ],
  },
  'AppStream:copyImage': {
    SourceImageName: 'required',
    DestinationImageName: 'required',
    DestinationRegion: 'required',
    DestinationImageDescription: '<optional>',
  },
  'AppStream:createDirectoryConfig': {
    DirectoryName: 'required',
    OrganizationalUnitDistinguishedNames: ['required1', 'required2'],
    ServiceAccountCredentials: [
      {
        AccountName: 'required',
        AccountPassword: 'required',
      },
    ],
  },
  'AppStream:createFleet': {
    Name: 'required',
    InstanceType: 'required',
    ComputeCapacity: {
      DesiredInstances: ['required1', 'required2'],
    },
    ImageName: '<optional>',
    ImageArn: '<optional>',
    FleetType: '<optional>',
    VpcConfig: {
      SubnetIds: ['<optional1>', '<optional2>'],
      SecurityGroupIds: ['<optional1>', '<optional2>'],
    },
    MaxUserDurationInSeconds: ['<optional1>', '<optional2>'],
    DisconnectTimeoutInSeconds: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    DisplayName: '<optional>',
    EnableDefaultInternetAccess: '<optional>',
    DomainJoinInfo: {
      DirectoryName: '<optional>',
      OrganizationalUnitDistinguishedName: '<optional>',
    },
    Tags: ['<optional1>', '<optional2>'],
    IdleDisconnectTimeoutInSeconds: ['<optional1>', '<optional2>'],
  },
  'AppStream:createImageBuilder': {
    Name: 'required',
    InstanceType: 'required',
    ImageName: '<optional>',
    ImageArn: '<optional>',
    Description: '<optional>',
    DisplayName: '<optional>',
    VpcConfig: {
      SubnetIds: ['<optional1>', '<optional2>'],
      SecurityGroupIds: ['<optional1>', '<optional2>'],
    },
    EnableDefaultInternetAccess: '<optional>',
    DomainJoinInfo: {
      DirectoryName: '<optional>',
      OrganizationalUnitDistinguishedName: '<optional>',
    },
    AppstreamAgentVersion: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'AppStream:createImageBuilderStreamingURL': {
    Name: 'required',
    Validity: '<optional>',
  },
  'AppStream:createStack': {
    Name: 'required',
    StorageConnectors: [
      {
        ResourceIdentifier: '<optional>',
        Domains: ['<optional1>', '<optional2>'],
      },
    ],
    UserSettings: ['<optional1>', '<optional2>'],
    ApplicationSettings: [
      {
        SettingsGroup: '<optional>',
      },
    ],
    Description: '<optional>',
    DisplayName: '<optional>',
    RedirectURL: '<optional>',
    FeedbackURL: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'AppStream:createStreamingURL': {
    StackName: 'required',
    FleetName: 'required',
    UserId: 'required',
    ApplicationId: '<optional>',
    Validity: '<optional>',
    SessionContext: '<optional>',
  },
  'AppStream:createUsageReportSubscription': {},
  'AppStream:createUser': {
    UserName: 'required',
    AuthenticationType: 'required',
    MessageAction: '<optional>',
    FirstName: '<optional>',
    LastName: '<optional>',
  },
  'AppStream:deleteDirectoryConfig': {
    DirectoryName: 'required',
  },
  'AppStream:deleteFleet': {
    Name: 'required',
  },
  'AppStream:deleteImage': {
    Name: 'required',
  },
  'AppStream:deleteImageBuilder': {
    Name: 'required',
  },
  'AppStream:deleteImagePermissions': {
    Name: 'required',
    SharedAccountId: 'required',
  },
  'AppStream:deleteStack': {
    Name: 'required',
  },
  'AppStream:deleteUsageReportSubscription': {},
  'AppStream:deleteUser': {
    UserName: 'required',
    AuthenticationType: 'required',
  },
  'AppStream:describeDirectoryConfigs': {
    DirectoryNames: ['<optional1>', '<optional2>'],
  },
  'AppStream:describeFleets': {
    Names: ['<optional1>', '<optional2>'],
  },
  'AppStream:describeImageBuilders': {
    Names: ['<optional1>', '<optional2>'],
  },
  'AppStream:describeImagePermissions': {
    Name: 'required',
    SharedAwsAccountIds: ['<optional1>', '<optional2>'],
  },
  'AppStream:describeImages': {
    Names: ['<optional1>', '<optional2>'],
    Arns: ['<optional1>', '<optional2>'],
    Type: '<optional>',
  },
  'AppStream:describeSessions': {
    StackName: 'required',
    FleetName: 'required',
    UserId: '<optional>',
    Limit: '<optional>',
    AuthenticationType: '<optional>',
  },
  'AppStream:describeStacks': {
    Names: ['<optional1>', '<optional2>'],
  },
  'AppStream:describeUsageReportSubscriptions': {},
  'AppStream:describeUserStackAssociations': {
    StackName: '<optional>',
    UserName: '<optional>',
    AuthenticationType: '<optional>',
  },
  'AppStream:describeUsers': {
    AuthenticationType: 'required',
  },
  'AppStream:disableUser': {
    UserName: 'required',
    AuthenticationType: 'required',
  },
  'AppStream:disassociateFleet': {
    FleetName: 'required',
    StackName: 'required',
  },
  'AppStream:enableUser': {
    UserName: 'required',
    AuthenticationType: 'required',
  },
  'AppStream:expireSession': {
    SessionId: 'required',
  },
  'AppStream:listAssociatedFleets': {
    StackName: 'required',
  },
  'AppStream:listAssociatedStacks': {
    FleetName: 'required',
  },
  'AppStream:listTagsForResource': {
    ResourceArn: 'required',
  },
  'AppStream:startFleet': {
    Name: 'required',
  },
  'AppStream:startImageBuilder': {
    Name: 'required',
    AppstreamAgentVersion: '<optional>',
  },
  'AppStream:stopFleet': {
    Name: 'required',
  },
  'AppStream:stopImageBuilder': {
    Name: 'required',
  },
  'AppStream:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'AppStream:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'AppStream:updateDirectoryConfig': {
    DirectoryName: 'required',
    ServiceAccountCredentials: ['<optional1>', '<optional2>'],
    OrganizationalUnitDistinguishedNames: ['<optional1>', '<optional2>'],
  },
  'AppStream:updateFleet': {
    ComputeCapacity: '<optional>',
    ImageName: '<optional>',
    ImageArn: '<optional>',
    Name: '<optional>',
    InstanceType: '<optional>',
    VpcConfig: {
      SubnetIds: ['<optional1>', '<optional2>'],
      SecurityGroupIds: ['<optional1>', '<optional2>'],
    },
    MaxUserDurationInSeconds: ['<optional1>', '<optional2>'],
    DisconnectTimeoutInSeconds: ['<optional1>', '<optional2>'],
    DeleteVpcConfig: '<optional>',
    Description: '<optional>',
    DisplayName: '<optional>',
    EnableDefaultInternetAccess: '<optional>',
    DomainJoinInfo: {
      DirectoryName: '<optional>',
      OrganizationalUnitDistinguishedName: '<optional>',
    },
    IdleDisconnectTimeoutInSeconds: ['<optional1>', '<optional2>'],
    AttributesToDelete: '<optional>',
  },
  'AppStream:updateImagePermissions': {
    Name: 'required',
    SharedAccountId: 'required',
    ImagePermissions: [
      {
        allowFleet: '<optional>',
        allowImageBuilder: '<optional>',
      },
    ],
  },
  'AppStream:updateStack': {
    Name: 'required',
    StorageConnectors: [
      {
        ResourceIdentifier: '<optional>',
        Domains: ['<optional1>', '<optional2>'],
      },
    ],
    UserSettings: ['<optional1>', '<optional2>'],
    ApplicationSettings: [
      {
        SettingsGroup: '<optional>',
      },
    ],
    DisplayName: '<optional>',
    Description: '<optional>',
    DeleteStorageConnectors: ['<optional1>', '<optional2>'],
    RedirectURL: '<optional>',
    FeedbackURL: '<optional>',
    AttributesToDelete: '<optional>',
  },
  'AppSync:createApiCache': {
    apiId: 'required',
    ttl: 'required',
    apiCachingBehavior: 'required',
    type: 'required',
    transitEncryptionEnabled: '<optional>',
    atRestEncryptionEnabled: '<optional>',
  },
  'AppSync:createApiKey': {
    apiId: 'required',
    description: '<optional>',
    expires: ['<optional1>', '<optional2>'],
  },
  'AppSync:createDataSource': {
    apiId: 'required',
    name: 'required',
    type: 'required',
    dynamodbConfig: {
      useCallerCredentials: ['<optional1>', '<optional2>'],
    },
    lambdaConfig: '<optional>',
    elasticsearchConfig: '<optional>',
    httpConfig: {
      endpoint: '<optional>',
      authorizationConfig: {
        awsIamConfig: {
          signingRegion: '<optional>',
          signingServiceName: '<optional>',
        },
      },
    },
    description: '<optional>',
    serviceRoleArn: '<optional>',
    relationalDatabaseConfig: {
      relationalDatabaseSourceType: '<optional>',
      rdsHttpEndpointConfig: {
        awsRegion: '<optional>',
        dbClusterIdentifier: '<optional>',
        databaseName: '<optional>',
        schema: '<optional>',
        awsSecretStoreArn: '<optional>',
      },
    },
  },
  'AppSync:createFunction': {
    apiId: 'required',
    name: 'required',
    dataSourceName: 'required',
    requestMappingTemplate: 'required',
    functionVersion: 'required',
    description: '<optional>',
    responseMappingTemplate: '<optional>',
  },
  'AppSync:createGraphqlApi': {
    name: 'required',
    authenticationType: 'required',
    logConfig: '<optional>',
    userPoolConfig: {
      appIdClientRegex: '<optional>',
    },
    openIDConnectConfig: {
      clientId: '<optional>',
      iatTTL: '<optional>',
      authTTL: '<optional>',
    },
    additionalAuthenticationProviders: [
      {
        authenticationType: '<optional>',
        openIDConnectConfig: {
          clientId: '<optional>',
          iatTTL: '<optional>',
          authTTL: '<optional>',
        },
        userPoolConfig: {
          appIdClientRegex: '<optional>',
        },
      },
    ],
    tags: ['<optional1>', '<optional2>'],
  },
  'AppSync:createResolver': {
    apiId: 'required',
    typeName: 'required',
    fieldName: 'required',
    requestMappingTemplate: 'required',
    dataSourceName: '<optional>',
    responseMappingTemplate: '<optional>',
    kind: '<optional>',
    pipelineConfig: {
      functions: ['<optional1>', '<optional2>'],
    },
  },
  'AppSync:createType': {
    apiId: 'required',
    definition: 'required',
    format: 'required',
  },
  'AppSync:deleteApiCache': {
    apiId: 'required',
  },
  'AppSync:deleteApiKey': {
    apiId: 'required',
    id: 'required',
  },
  'AppSync:deleteDataSource': {
    apiId: 'required',
    name: 'required',
  },
  'AppSync:deleteFunction': {
    apiId: 'required',
    functionId: 'required',
  },
  'AppSync:deleteGraphqlApi': {
    apiId: 'required',
  },
  'AppSync:deleteResolver': {
    apiId: 'required',
    typeName: 'required',
    fieldName: 'required',
  },
  'AppSync:deleteType': {
    apiId: 'required',
    typeName: 'required',
  },
  'AppSync:flushApiCache': {
    apiId: 'required',
  },
  'AppSync:getApiCache': {
    apiId: 'required',
  },
  'AppSync:getDataSource': {
    apiId: 'required',
    name: 'required',
  },
  'AppSync:getFunction': {
    apiId: 'required',
    functionId: 'required',
  },
  'AppSync:getGraphqlApi': {
    apiId: 'required',
  },
  'AppSync:getIntrospectionSchema': {
    apiId: 'required',
    format: 'required',
    includeDirectives: ['<optional1>', '<optional2>'],
  },
  'AppSync:getResolver': {
    apiId: 'required',
    typeName: 'required',
    fieldName: 'required',
  },
  'AppSync:getSchemaCreationStatus': {
    apiId: 'required',
  },
  'AppSync:getType': {
    apiId: 'required',
    typeName: 'required',
    format: 'required',
  },
  'AppSync:listApiKeys': {
    apiId: 'required',
  },
  'AppSync:listDataSources': {
    apiId: 'required',
  },
  'AppSync:listFunctions': {
    apiId: 'required',
  },
  'AppSync:listGraphqlApis': {},
  'AppSync:listResolvers': {
    apiId: 'required',
    typeName: 'required',
  },
  'AppSync:listResolversByFunction': {
    apiId: 'required',
    functionId: 'required',
  },
  'AppSync:listTagsForResource': {
    resourceArn: 'required',
  },
  'AppSync:listTypes': {
    apiId: 'required',
    format: 'required',
  },
  'AppSync:startSchemaCreation': {
    apiId: 'required',
    definition: 'required',
  },
  'AppSync:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'AppSync:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'AppSync:updateApiCache': {
    apiId: 'required',
    ttl: 'required',
    apiCachingBehavior: 'required',
    type: 'required',
  },
  'AppSync:updateApiKey': {
    apiId: 'required',
    id: 'required',
    description: '<optional>',
    expires: ['<optional1>', '<optional2>'],
  },
  'AppSync:updateDataSource': {
    apiId: 'required',
    name: 'required',
    type: 'required',
    dynamodbConfig: {
      useCallerCredentials: ['<optional1>', '<optional2>'],
    },
    lambdaConfig: '<optional>',
    elasticsearchConfig: '<optional>',
    httpConfig: {
      endpoint: '<optional>',
      authorizationConfig: {
        awsIamConfig: {
          signingRegion: '<optional>',
          signingServiceName: '<optional>',
        },
      },
    },
    description: '<optional>',
    serviceRoleArn: '<optional>',
    relationalDatabaseConfig: {
      relationalDatabaseSourceType: '<optional>',
      rdsHttpEndpointConfig: {
        awsRegion: '<optional>',
        dbClusterIdentifier: '<optional>',
        databaseName: '<optional>',
        schema: '<optional>',
        awsSecretStoreArn: '<optional>',
      },
    },
  },
  'AppSync:updateFunction': {
    apiId: 'required',
    name: 'required',
    functionId: 'required',
    dataSourceName: 'required',
    requestMappingTemplate: 'required',
    functionVersion: 'required',
    description: '<optional>',
    responseMappingTemplate: '<optional>',
  },
  'AppSync:updateGraphqlApi': {
    apiId: 'required',
    name: 'required',
    logConfig: '<optional>',
    userPoolConfig: {
      appIdClientRegex: '<optional>',
    },
    openIDConnectConfig: {
      clientId: '<optional>',
      iatTTL: '<optional>',
      authTTL: '<optional>',
    },
    additionalAuthenticationProviders: [
      {
        authenticationType: '<optional>',
        openIDConnectConfig: {
          clientId: '<optional>',
          iatTTL: '<optional>',
          authTTL: '<optional>',
        },
        userPoolConfig: {
          appIdClientRegex: '<optional>',
        },
      },
    ],
    authenticationType: '<optional>',
  },
  'AppSync:updateResolver': {
    apiId: 'required',
    typeName: 'required',
    fieldName: 'required',
    requestMappingTemplate: 'required',
    dataSourceName: '<optional>',
    responseMappingTemplate: '<optional>',
    kind: '<optional>',
    pipelineConfig: {
      functions: ['<optional1>', '<optional2>'],
    },
  },
  'AppSync:updateType': {
    apiId: 'required',
    typeName: 'required',
    format: 'required',
    definition: '<optional>',
  },
  'ApplicationAutoScaling:deleteScalingPolicy': {
    PolicyName: 'required',
    ServiceNamespace: 'required',
    ResourceId: 'required',
    ScalableDimension: 'required',
  },
  'ApplicationAutoScaling:deleteScheduledAction': {
    ServiceNamespace: 'required',
    ScheduledActionName: 'required',
    ResourceId: 'required',
    ScalableDimension: 'required',
  },
  'ApplicationAutoScaling:deregisterScalableTarget': {
    ServiceNamespace: 'required',
    ResourceId: 'required',
    ScalableDimension: 'required',
  },
  'ApplicationAutoScaling:describeScalableTargets': {
    ServiceNamespace: 'required',
    ResourceIds: ['<optional1>', '<optional2>'],
    ScalableDimension: '<optional>',
  },
  'ApplicationAutoScaling:describeScalingActivities': {
    ServiceNamespace: 'required',
    ResourceId: '<optional>',
    ScalableDimension: '<optional>',
  },
  'ApplicationAutoScaling:describeScalingPolicies': {
    ServiceNamespace: 'required',
    PolicyNames: ['<optional1>', '<optional2>'],
    ResourceId: '<optional>',
    ScalableDimension: '<optional>',
  },
  'ApplicationAutoScaling:describeScheduledActions': {
    ServiceNamespace: 'required',
    ScheduledActionNames: ['<optional1>', '<optional2>'],
    ResourceId: '<optional>',
    ScalableDimension: '<optional>',
  },
  'ApplicationAutoScaling:putScalingPolicy': {
    PolicyName: 'required',
    ServiceNamespace: 'required',
    ResourceId: 'required',
    ScalableDimension: 'required',
    StepScalingPolicyConfiguration: {
      AdjustmentType: '<optional>',
      StepAdjustments: [
        {
          MetricIntervalLowerBound: '<optional>',
          MetricIntervalUpperBound: '<optional>',
        },
      ],
      MinAdjustmentMagnitude: '<optional>',
      Cooldown: '<optional>',
      MetricAggregationType: '<optional>',
    },
    TargetTrackingScalingPolicyConfiguration: {
      PredefinedMetricSpecification: {
        ResourceLabel: '<optional>',
      },
      CustomizedMetricSpecification: {
        Dimensions: ['<optional1>', '<optional2>'],
        Unit: '<optional>',
      },
      ScaleOutCooldown: '<optional>',
      ScaleInCooldown: '<optional>',
      DisableScaleIn: '<optional>',
    },
    PolicyType: '<optional>',
  },
  'ApplicationAutoScaling:putScheduledAction': {
    ServiceNamespace: 'required',
    ScheduledActionName: 'required',
    ResourceId: 'required',
    ScalableDimension: 'required',
    Schedule: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    ScalableTargetAction: {
      MinCapacity: '<optional>',
      MaxCapacity: '<optional>',
    },
  },
  'ApplicationAutoScaling:registerScalableTarget': {
    ServiceNamespace: 'required',
    ResourceId: 'required',
    ScalableDimension: 'required',
    MinCapacity: '<optional>',
    MaxCapacity: '<optional>',
    RoleARN: '<optional>',
  },
  'ApplicationInsights:createApplication': {
    ResourceGroupName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    OpsCenterEnabled: '<optional>',
    OpsItemSNSTopicArn: '<optional>',
  },
  'ApplicationInsights:createComponent': {
    ResourceGroupName: 'required',
    ComponentName: 'required',
    ResourceList: 'required',
  },
  'ApplicationInsights:createLogPattern': {
    ResourceGroupName: 'required',
    PatternSetName: 'required',
    PatternName: 'required',
    Pattern: 'required',
    Rank: 'required',
  },
  'ApplicationInsights:deleteApplication': {
    ResourceGroupName: 'required',
  },
  'ApplicationInsights:deleteComponent': {
    ResourceGroupName: 'required',
    ComponentName: 'required',
  },
  'ApplicationInsights:deleteLogPattern': {
    ResourceGroupName: 'required',
    PatternSetName: 'required',
    PatternName: 'required',
  },
  'ApplicationInsights:describeApplication': {
    ResourceGroupName: 'required',
  },
  'ApplicationInsights:describeComponent': {
    ResourceGroupName: 'required',
    ComponentName: 'required',
  },
  'ApplicationInsights:describeComponentConfiguration': {
    ResourceGroupName: 'required',
    ComponentName: 'required',
  },
  'ApplicationInsights:describeComponentConfigurationRecommendation': {
    ResourceGroupName: 'required',
    ComponentName: 'required',
    Tier: 'required',
  },
  'ApplicationInsights:describeLogPattern': {
    ResourceGroupName: 'required',
    PatternSetName: 'required',
    PatternName: 'required',
  },
  'ApplicationInsights:describeObservation': {
    ObservationId: 'required',
  },
  'ApplicationInsights:describeProblem': {
    ProblemId: 'required',
  },
  'ApplicationInsights:describeProblemObservations': {
    ProblemId: 'required',
  },
  'ApplicationInsights:listApplications': {},
  'ApplicationInsights:listComponents': {
    ResourceGroupName: 'required',
  },
  'ApplicationInsights:listLogPatternSets': {
    ResourceGroupName: 'required',
  },
  'ApplicationInsights:listLogPatterns': {
    ResourceGroupName: 'required',
    PatternSetName: '<optional>',
  },
  'ApplicationInsights:listProblems': {
    ResourceGroupName: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
  },
  'ApplicationInsights:listTagsForResource': {
    ResourceARN: 'required',
  },
  'ApplicationInsights:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'ApplicationInsights:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'ApplicationInsights:updateApplication': {
    ResourceGroupName: 'required',
    OpsCenterEnabled: '<optional>',
    OpsItemSNSTopicArn: '<optional>',
    RemoveSNSTopic: '<optional>',
  },
  'ApplicationInsights:updateComponent': {
    ResourceGroupName: 'required',
    ComponentName: 'required',
    NewComponentName: '<optional>',
    ResourceList: '<optional>',
  },
  'ApplicationInsights:updateComponentConfiguration': {
    ResourceGroupName: 'required',
    ComponentName: 'required',
    Monitor: '<optional>',
    Tier: '<optional>',
    ComponentConfiguration: '<optional>',
  },
  'ApplicationInsights:updateLogPattern': {
    ResourceGroupName: 'required',
    PatternSetName: 'required',
    PatternName: 'required',
    Pattern: '<optional>',
    Rank: '<optional>',
  },
  'Athena:batchGetNamedQuery': {
    NamedQueryIds: ['required1', 'required2'],
  },
  'Athena:batchGetQueryExecution': {
    QueryExecutionIds: ['required1', 'required2'],
  },
  'Athena:createNamedQuery': {
    Name: 'required',
    Database: 'required',
    QueryString: 'required',
    Description: '<optional>',
    ClientRequestToken: '<optional>',
    WorkGroup: '<optional>',
  },
  'Athena:createWorkGroup': {
    Name: 'required',
    Configuration: {
      ResultConfiguration: {
        OutputLocation: '<optional>',
        EncryptionConfiguration: {
          KmsKey: '<optional>',
        },
      },
      EnforceWorkGroupConfiguration: '<optional>',
      PublishCloudWatchMetricsEnabled: '<optional>',
      BytesScannedCutoffPerQuery: '<optional>',
    },
    Description: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Athena:deleteNamedQuery': {
    NamedQueryId: 'required',
  },
  'Athena:deleteWorkGroup': {
    WorkGroup: 'required',
    RecursiveDeleteOption: '<optional>',
  },
  'Athena:getNamedQuery': {
    NamedQueryId: 'required',
  },
  'Athena:getQueryExecution': {
    QueryExecutionId: 'required',
  },
  'Athena:getQueryResults': {
    QueryExecutionId: 'required',
  },
  'Athena:getWorkGroup': {
    WorkGroup: 'required',
  },
  'Athena:listNamedQueries': {
    WorkGroup: '<optional>',
  },
  'Athena:listQueryExecutions': {
    WorkGroup: '<optional>',
  },
  'Athena:listTagsForResource': {
    ResourceARN: 'required',
  },
  'Athena:listWorkGroups': {},
  'Athena:startQueryExecution': {
    QueryString: 'required',
    ResultConfiguration: {
      OutputLocation: '<optional>',
      EncryptionConfiguration: {
        KmsKey: '<optional>',
      },
    },
    ClientRequestToken: '<optional>',
    QueryExecutionContext: {
      Database: '<optional>',
    },
    WorkGroup: '<optional>',
  },
  'Athena:stopQueryExecution': {
    QueryExecutionId: 'required',
  },
  'Athena:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Athena:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Athena:updateWorkGroup': {
    WorkGroup: 'required',
    ConfigurationUpdates: [
      {
        EnforceWorkGroupConfiguration: '<optional>',
        ResultConfigurationUpdates: [
          {
            OutputLocation: '<optional>',
            RemoveOutputLocation: '<optional>',
            EncryptionConfiguration: {
              KmsKey: '<optional>',
            },
            RemoveEncryptionConfiguration: '<optional>',
          },
        ],
        PublishCloudWatchMetricsEnabled: '<optional>',
        BytesScannedCutoffPerQuery: '<optional>',
        RemoveBytesScannedCutoffPerQuery: '<optional>',
      },
    ],
    Description: '<optional>',
    State: '<optional>',
  },
  'AugmentedAIRuntime:deleteHumanLoop': {
    HumanLoopName: 'required',
  },
  'AugmentedAIRuntime:describeHumanLoop': {
    HumanLoopName: 'required',
  },
  'AugmentedAIRuntime:listHumanLoops': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    SortOrder: '<optional>',
  },
  'AugmentedAIRuntime:startHumanLoop': {
    HumanLoopName: 'required',
    FlowDefinitionArn: 'required',
    HumanLoopInput: {
      InputContent: 'required',
    },
    DataAttributes: ['<optional1>', '<optional2>'],
  },
  'AugmentedAIRuntime:stopHumanLoop': {
    HumanLoopName: 'required',
  },
  'AutoScaling:attachInstances': {
    AutoScalingGroupName: 'required',
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:attachLoadBalancerTargetGroups': {
    AutoScalingGroupName: 'required',
    TargetGroupARNs: ['required1', 'required2'],
  },
  'AutoScaling:attachLoadBalancers': {
    AutoScalingGroupName: 'required',
    LoadBalancerNames: ['required1', 'required2'],
  },
  'AutoScaling:batchDeleteScheduledAction': {
    AutoScalingGroupName: 'required',
    ScheduledActionNames: ['required1', 'required2'],
  },
  'AutoScaling:batchPutScheduledUpdateGroupAction': {
    AutoScalingGroupName: 'required',
    ScheduledUpdateGroupActions: [
      {
        StartTime: '<optional>',
        EndTime: '<optional>',
        Recurrence: '<optional>',
        MinSize: '<optional>',
        MaxSize: '<optional>',
        DesiredCapacity: '<optional>',
      },
    ],
  },
  'AutoScaling:completeLifecycleAction': {
    LifecycleHookName: 'required',
    AutoScalingGroupName: 'required',
    LifecycleActionResult: 'required',
    LifecycleActionToken: '<optional>',
    InstanceId: '<optional>',
  },
  'AutoScaling:createAutoScalingGroup': {
    AutoScalingGroupName: 'required',
    MinSize: 'required',
    MaxSize: 'required',
    LifecycleHookSpecificationList: {
      NotificationMetadata: ['<optional1>', '<optional2>'],
      HeartbeatTimeout: '<optional>',
      DefaultResult: '<optional>',
      NotificationTargetARN: '<optional>',
      RoleARN: '<optional>',
    },
    Tags: [
      {
        ResourceId: '<optional>',
        ResourceType: '<optional>',
        Value: '<optional>',
        PropagateAtLaunch: '<optional>',
      },
    ],
    LaunchConfigurationName: '<optional>',
    LaunchTemplate: {
      LaunchTemplateId: '<optional>',
      LaunchTemplateName: '<optional>',
      Version: '<optional>',
    },
    MixedInstancesPolicy: {
      LaunchTemplate: {
        LaunchTemplateSpecification: {
          LaunchTemplateId: '<optional>',
          LaunchTemplateName: '<optional>',
          Version: '<optional>',
        },
        Overrides: [
          {
            InstanceType: '<optional>',
          },
        ],
      },
      InstancesDistribution: {
        OnDemandAllocationStrategy: '<optional>',
        OnDemandBaseCapacity: '<optional>',
        OnDemandPercentageAboveBaseCapacity: '<optional>',
        SpotAllocationStrategy: '<optional>',
        SpotInstancePools: ['<optional1>', '<optional2>'],
        SpotMaxPrice: '<optional>',
      },
    },
    InstanceId: '<optional>',
    DesiredCapacity: '<optional>',
    DefaultCooldown: '<optional>',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    LoadBalancerNames: ['<optional1>', '<optional2>'],
    TargetGroupARNs: ['<optional1>', '<optional2>'],
    HealthCheckType: '<optional>',
    HealthCheckGracePeriod: '<optional>',
    PlacementGroup: '<optional>',
    VPCZoneIdentifier: '<optional>',
    TerminationPolicies: ['<optional1>', '<optional2>'],
    NewInstancesProtectedFromScaleIn: '<optional>',
    ServiceLinkedRoleARN: '<optional>',
  },
  'AutoScaling:createLaunchConfiguration': {
    LaunchConfigurationName: 'required',
    BlockDeviceMappings: [
      {
        VirtualName: '<optional>',
        Ebs: [
          {
            SnapshotId: '<optional>',
            VolumeSize: '<optional>',
            VolumeType: '<optional>',
            DeleteOnTermination: '<optional>',
            Iops: ['<optional1>', '<optional2>'],
            Encrypted: '<optional>',
          },
        ],
        NoDevice: '<optional>',
      },
    ],
    ImageId: '<optional>',
    KeyName: '<optional>',
    SecurityGroups: ['<optional1>', '<optional2>'],
    ClassicLinkVPCId: '<optional>',
    ClassicLinkVPCSecurityGroups: ['<optional1>', '<optional2>'],
    UserData: ['<optional1>', '<optional2>'],
    InstanceId: '<optional>',
    InstanceType: '<optional>',
    KernelId: '<optional>',
    RamdiskId: '<optional>',
    InstanceMonitoring: {
      Enabled: '<optional>',
    },
    SpotPrice: '<optional>',
    IamInstanceProfile: '<optional>',
    EbsOptimized: '<optional>',
    AssociatePublicIpAddress: '<optional>',
    PlacementTenancy: '<optional>',
  },
  'AutoScaling:createOrUpdateTags': {
    Tags: [
      {
        ResourceId: '<optional>',
        ResourceType: '<optional>',
        Value: '<optional>',
        PropagateAtLaunch: '<optional>',
      },
    ],
  },
  'AutoScaling:deleteAutoScalingGroup': {
    AutoScalingGroupName: 'required',
    ForceDelete: '<optional>',
  },
  'AutoScaling:deleteLaunchConfiguration': {
    LaunchConfigurationName: 'required',
  },
  'AutoScaling:deleteLifecycleHook': {
    LifecycleHookName: 'required',
    AutoScalingGroupName: 'required',
  },
  'AutoScaling:deleteNotificationConfiguration': {
    AutoScalingGroupName: 'required',
    TopicARN: 'required',
  },
  'AutoScaling:deletePolicy': {
    PolicyName: 'required',
    AutoScalingGroupName: '<optional>',
  },
  'AutoScaling:deleteScheduledAction': {
    AutoScalingGroupName: 'required',
    ScheduledActionName: 'required',
  },
  'AutoScaling:deleteTags': {
    Tags: [
      {
        ResourceId: '<optional>',
        ResourceType: '<optional>',
        Value: '<optional>',
        PropagateAtLaunch: '<optional>',
      },
    ],
  },
  'AutoScaling:describeAccountLimits': {},
  'AutoScaling:describeAdjustmentTypes': {},
  'AutoScaling:describeAutoScalingGroups': {
    AutoScalingGroupNames: ['<optional1>', '<optional2>'],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeAutoScalingInstances': {
    InstanceIds: ['<optional1>', '<optional2>'],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeAutoScalingNotificationTypes': {},
  'AutoScaling:describeLaunchConfigurations': {
    LaunchConfigurationNames: ['<optional1>', '<optional2>'],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeLifecycleHookTypes': {},
  'AutoScaling:describeLifecycleHooks': {
    AutoScalingGroupName: 'required',
    LifecycleHookNames: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeLoadBalancerTargetGroups': {
    AutoScalingGroupName: 'required',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeLoadBalancers': {
    AutoScalingGroupName: 'required',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeMetricCollectionTypes': {},
  'AutoScaling:describeNotificationConfigurations': {
    AutoScalingGroupNames: ['<optional1>', '<optional2>'],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describePolicies': {
    AutoScalingGroupName: '<optional>',
    PolicyNames: ['<optional1>', '<optional2>'],
    PolicyTypes: ['<optional1>', '<optional2>'],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeScalingActivities': {
    ActivityIds: ['<optional1>', '<optional2>'],
    AutoScalingGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeScalingProcessTypes': {},
  'AutoScaling:describeScheduledActions': {
    AutoScalingGroupName: '<optional>',
    ScheduledActionNames: ['<optional1>', '<optional2>'],
    StartTime: '<optional>',
    EndTime: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeTags': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:describeTerminationPolicyTypes': {},
  'AutoScaling:detachInstances': {
    AutoScalingGroupName: 'required',
    ShouldDecrementDesiredCapacity: 'required',
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:detachLoadBalancerTargetGroups': {
    AutoScalingGroupName: 'required',
    TargetGroupARNs: ['required1', 'required2'],
  },
  'AutoScaling:detachLoadBalancers': {
    AutoScalingGroupName: 'required',
    LoadBalancerNames: ['required1', 'required2'],
  },
  'AutoScaling:disableMetricsCollection': {
    AutoScalingGroupName: 'required',
    Metrics: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:enableMetricsCollection': {
    AutoScalingGroupName: 'required',
    Granularity: 'required',
    Metrics: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:enterStandby': {
    AutoScalingGroupName: 'required',
    ShouldDecrementDesiredCapacity: 'required',
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:executePolicy': {
    PolicyName: 'required',
    AutoScalingGroupName: '<optional>',
    HonorCooldown: '<optional>',
    MetricValue: '<optional>',
    BreachThreshold: '<optional>',
  },
  'AutoScaling:exitStandby': {
    AutoScalingGroupName: 'required',
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:putLifecycleHook': {
    LifecycleHookName: 'required',
    AutoScalingGroupName: 'required',
    LifecycleTransition: '<optional>',
    RoleARN: '<optional>',
    NotificationTargetARN: '<optional>',
    NotificationMetadata: ['<optional1>', '<optional2>'],
    HeartbeatTimeout: '<optional>',
    DefaultResult: '<optional>',
  },
  'AutoScaling:putNotificationConfiguration': {
    AutoScalingGroupName: 'required',
    TopicARN: 'required',
    NotificationTypes: ['required1', 'required2'],
  },
  'AutoScaling:putScalingPolicy': {
    AutoScalingGroupName: 'required',
    PolicyName: 'required',
    StepAdjustments: [
      {
        MetricIntervalLowerBound: '<optional>',
        MetricIntervalUpperBound: '<optional>',
      },
    ],
    TargetTrackingConfiguration: {
      PredefinedMetricSpecification: {
        ResourceLabel: '<optional>',
      },
      CustomizedMetricSpecification: {
        Dimensions: ['<optional1>', '<optional2>'],
        Unit: '<optional>',
      },
      DisableScaleIn: '<optional>',
    },
    PolicyType: '<optional>',
    AdjustmentType: '<optional>',
    MinAdjustmentStep: '<optional>',
    MinAdjustmentMagnitude: '<optional>',
    ScalingAdjustment: '<optional>',
    Cooldown: '<optional>',
    MetricAggregationType: '<optional>',
    EstimatedInstanceWarmup: '<optional>',
  },
  'AutoScaling:putScheduledUpdateGroupAction': {
    AutoScalingGroupName: 'required',
    ScheduledActionName: 'required',
    Time: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Recurrence: '<optional>',
    MinSize: '<optional>',
    MaxSize: '<optional>',
    DesiredCapacity: '<optional>',
  },
  'AutoScaling:recordLifecycleActionHeartbeat': {
    LifecycleHookName: 'required',
    AutoScalingGroupName: 'required',
    LifecycleActionToken: '<optional>',
    InstanceId: '<optional>',
  },
  'AutoScaling:resumeProcesses': {
    AutoScalingGroupName: 'required',
    ScalingProcesses: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:setDesiredCapacity': {
    AutoScalingGroupName: 'required',
    DesiredCapacity: 'required',
    HonorCooldown: '<optional>',
  },
  'AutoScaling:setInstanceHealth': {
    InstanceId: 'required',
    HealthStatus: 'required',
    ShouldRespectGracePeriod: '<optional>',
  },
  'AutoScaling:setInstanceProtection': {
    InstanceIds: ['required1', 'required2'],
    AutoScalingGroupName: 'required',
    ProtectedFromScaleIn: 'required',
  },
  'AutoScaling:suspendProcesses': {
    AutoScalingGroupName: 'required',
    ScalingProcesses: ['<optional1>', '<optional2>'],
  },
  'AutoScaling:terminateInstanceInAutoScalingGroup': {
    InstanceId: 'required',
    ShouldDecrementDesiredCapacity: 'required',
  },
  'AutoScaling:updateAutoScalingGroup': {
    AutoScalingGroupName: 'required',
    LaunchConfigurationName: '<optional>',
    LaunchTemplate: {
      LaunchTemplateId: '<optional>',
      LaunchTemplateName: '<optional>',
      Version: '<optional>',
    },
    MixedInstancesPolicy: {
      LaunchTemplate: {
        LaunchTemplateSpecification: {
          LaunchTemplateId: '<optional>',
          LaunchTemplateName: '<optional>',
          Version: '<optional>',
        },
        Overrides: [
          {
            InstanceType: '<optional>',
          },
        ],
      },
      InstancesDistribution: {
        OnDemandAllocationStrategy: '<optional>',
        OnDemandBaseCapacity: '<optional>',
        OnDemandPercentageAboveBaseCapacity: '<optional>',
        SpotAllocationStrategy: '<optional>',
        SpotInstancePools: ['<optional1>', '<optional2>'],
        SpotMaxPrice: '<optional>',
      },
    },
    MinSize: '<optional>',
    MaxSize: '<optional>',
    DesiredCapacity: '<optional>',
    DefaultCooldown: '<optional>',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    HealthCheckType: '<optional>',
    HealthCheckGracePeriod: '<optional>',
    PlacementGroup: '<optional>',
    VPCZoneIdentifier: '<optional>',
    TerminationPolicies: ['<optional1>', '<optional2>'],
    NewInstancesProtectedFromScaleIn: '<optional>',
    ServiceLinkedRoleARN: '<optional>',
  },
  'AutoScalingPlans:createScalingPlan': {
    ScalingPlanName: 'required',
    ApplicationSource: {
      CloudFormationStackARN: '<optional>',
      TagFilters: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    ScalingInstructions: [
      {
        PredefinedLoadMetricSpecification: {
          ResourceLabel: '<optional>',
        },
        CustomizedLoadMetricSpecification: {
          Dimensions: ['<optional1>', '<optional2>'],
          Unit: '<optional>',
        },
        ScheduledActionBufferTime: '<optional>',
        PredictiveScalingMaxCapacityBehavior: '<optional>',
        PredictiveScalingMaxCapacityBuffer: '<optional>',
        PredictiveScalingMode: '<optional>',
        ScalingPolicyUpdateBehavior: '<optional>',
        DisableDynamicScaling: '<optional>',
        TargetTrackingConfigurations: [
          {
            PredefinedScalingMetricSpecification: {
              ResourceLabel: '<optional>',
            },
            CustomizedScalingMetricSpecification: {
              Dimensions: ['<optional1>', '<optional2>'],
              Unit: '<optional>',
            },
            DisableScaleIn: '<optional>',
            ScaleOutCooldown: '<optional>',
            ScaleInCooldown: '<optional>',
            EstimatedInstanceWarmup: '<optional>',
          },
        ],
      },
    ],
  },
  'AutoScalingPlans:deleteScalingPlan': {
    ScalingPlanName: 'required',
    ScalingPlanVersion: 'required',
  },
  'AutoScalingPlans:describeScalingPlanResources': {
    ScalingPlanName: 'required',
    ScalingPlanVersion: 'required',
  },
  'AutoScalingPlans:describeScalingPlans': {
    ScalingPlanNames: ['<optional1>', '<optional2>'],
    ScalingPlanVersion: '<optional>',
    ApplicationSources: [
      {
        CloudFormationStackARN: '<optional>',
        TagFilters: [
          {
            Key: '<optional>',
            Values: ['<optional1>', '<optional2>'],
          },
        ],
      },
    ],
  },
  'AutoScalingPlans:getScalingPlanResourceForecastData': {
    ScalingPlanName: 'required',
    ScalingPlanVersion: 'required',
    ServiceNamespace: 'required',
    ResourceId: 'required',
    ScalableDimension: 'required',
    ForecastDataType: 'required',
    StartTime: 'required',
    EndTime: 'required',
  },
  'AutoScalingPlans:updateScalingPlan': {
    ScalingPlanName: 'required',
    ScalingPlanVersion: 'required',
    ScalingInstructions: [
      {
        PredefinedLoadMetricSpecification: {
          ResourceLabel: '<optional>',
        },
        CustomizedLoadMetricSpecification: {
          Dimensions: ['<optional1>', '<optional2>'],
          Unit: '<optional>',
        },
        ScheduledActionBufferTime: '<optional>',
        PredictiveScalingMaxCapacityBehavior: '<optional>',
        PredictiveScalingMaxCapacityBuffer: '<optional>',
        PredictiveScalingMode: '<optional>',
        ScalingPolicyUpdateBehavior: '<optional>',
        DisableDynamicScaling: '<optional>',
        TargetTrackingConfigurations: [
          {
            PredefinedScalingMetricSpecification: {
              ResourceLabel: '<optional>',
            },
            CustomizedScalingMetricSpecification: {
              Dimensions: ['<optional1>', '<optional2>'],
              Unit: '<optional>',
            },
            DisableScaleIn: '<optional>',
            ScaleOutCooldown: '<optional>',
            ScaleInCooldown: '<optional>',
            EstimatedInstanceWarmup: '<optional>',
          },
        ],
      },
    ],
    ApplicationSource: {
      CloudFormationStackARN: '<optional>',
      TagFilters: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'Backup:createBackupPlan': {
    BackupPlan: {
      Rules: [
        {
          ScheduleExpression: '<optional>',
          StartWindowMinutes: ['<optional1>', '<optional2>'],
          CompletionWindowMinutes: ['<optional1>', '<optional2>'],
          Lifecycle: {
            MoveToColdStorageAfterDays: ['<optional1>', '<optional2>'],
            DeleteAfterDays: ['<optional1>', '<optional2>'],
          },
          RecoveryPointTags: ['<optional1>', '<optional2>'],
        },
      ],
    },
    BackupPlanTags: ['<optional1>', '<optional2>'],
    CreatorRequestId: '<optional>',
  },
  'Backup:createBackupSelection': {
    BackupPlanId: 'required',
    BackupSelection: {
      Resources: ['<optional1>', '<optional2>'],
      ListOfTags: ['<optional1>', '<optional2>'],
    },
    CreatorRequestId: '<optional>',
  },
  'Backup:createBackupVault': {
    BackupVaultName: 'required',
    BackupVaultTags: ['<optional1>', '<optional2>'],
    EncryptionKeyArn: '<optional>',
    CreatorRequestId: '<optional>',
  },
  'Backup:deleteBackupPlan': {
    BackupPlanId: 'required',
  },
  'Backup:deleteBackupSelection': {
    BackupPlanId: 'required',
    SelectionId: 'required',
  },
  'Backup:deleteBackupVault': {
    BackupVaultName: 'required',
  },
  'Backup:deleteBackupVaultAccessPolicy': {
    BackupVaultName: 'required',
  },
  'Backup:deleteBackupVaultNotifications': {
    BackupVaultName: 'required',
  },
  'Backup:deleteRecoveryPoint': {
    BackupVaultName: 'required',
    RecoveryPointArn: 'required',
  },
  'Backup:describeBackupJob': {
    BackupJobId: 'required',
  },
  'Backup:describeBackupVault': {
    BackupVaultName: 'required',
  },
  'Backup:describeProtectedResource': {
    ResourceArn: 'required',
  },
  'Backup:describeRecoveryPoint': {
    BackupVaultName: 'required',
    RecoveryPointArn: 'required',
  },
  'Backup:describeRestoreJob': {
    RestoreJobId: 'required',
  },
  'Backup:exportBackupPlanTemplate': {
    BackupPlanId: 'required',
  },
  'Backup:getBackupPlan': {
    BackupPlanId: 'required',
    VersionId: '<optional>',
  },
  'Backup:getBackupPlanFromJSON': {
    BackupPlanTemplateJson: 'required',
  },
  'Backup:getBackupPlanFromTemplate': {
    BackupPlanTemplateId: 'required',
  },
  'Backup:getBackupSelection': {
    BackupPlanId: 'required',
    SelectionId: 'required',
  },
  'Backup:getBackupVaultAccessPolicy': {
    BackupVaultName: 'required',
  },
  'Backup:getBackupVaultNotifications': {
    BackupVaultName: 'required',
  },
  'Backup:getRecoveryPointRestoreMetadata': {
    BackupVaultName: 'required',
    RecoveryPointArn: 'required',
  },
  'Backup:getSupportedResourceTypes': {},
  'Backup:listBackupJobs': {
    ByResourceArn: '<optional>',
    ByState: '<optional>',
    ByBackupVaultName: '<optional>',
    ByCreatedBefore: '<optional>',
    ByCreatedAfter: '<optional>',
    ByResourceType: '<optional>',
  },
  'Backup:listBackupPlanTemplates': {},
  'Backup:listBackupPlanVersions': {
    BackupPlanId: 'required',
  },
  'Backup:listBackupPlans': {
    IncludeDeleted: '<optional>',
  },
  'Backup:listBackupSelections': {
    BackupPlanId: 'required',
  },
  'Backup:listBackupVaults': {},
  'Backup:listProtectedResources': {},
  'Backup:listRecoveryPointsByBackupVault': {
    BackupVaultName: 'required',
    ByResourceArn: '<optional>',
    ByResourceType: '<optional>',
    ByBackupPlanId: '<optional>',
    ByCreatedBefore: '<optional>',
    ByCreatedAfter: '<optional>',
  },
  'Backup:listRecoveryPointsByResource': {
    ResourceArn: 'required',
  },
  'Backup:listRestoreJobs': {},
  'Backup:listTags': {
    ResourceArn: 'required',
  },
  'Backup:putBackupVaultAccessPolicy': {
    BackupVaultName: 'required',
    Policy: '<optional>',
  },
  'Backup:putBackupVaultNotifications': {
    BackupVaultName: 'required',
    SNSTopicArn: 'required',
    BackupVaultEvents: ['required1', 'required2'],
  },
  'Backup:startBackupJob': {
    BackupVaultName: 'required',
    ResourceArn: 'required',
    IamRoleArn: 'required',
    IdempotencyToken: '<optional>',
    StartWindowMinutes: ['<optional1>', '<optional2>'],
    CompleteWindowMinutes: ['<optional1>', '<optional2>'],
    Lifecycle: {
      MoveToColdStorageAfterDays: ['<optional1>', '<optional2>'],
      DeleteAfterDays: ['<optional1>', '<optional2>'],
    },
    RecoveryPointTags: ['<optional1>', '<optional2>'],
  },
  'Backup:startRestoreJob': {
    RecoveryPointArn: 'required',
    Metadata: ['required1', 'required2'],
    IamRoleArn: 'required',
    IdempotencyToken: '<optional>',
    ResourceType: '<optional>',
  },
  'Backup:stopBackupJob': {
    BackupJobId: 'required',
  },
  'Backup:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'Backup:untagResource': {
    ResourceArn: 'required',
    TagKeyList: 'required',
  },
  'Backup:updateBackupPlan': {
    BackupPlanId: 'required',
    BackupPlan: {
      Rules: [
        {
          ScheduleExpression: '<optional>',
          StartWindowMinutes: ['<optional1>', '<optional2>'],
          CompletionWindowMinutes: ['<optional1>', '<optional2>'],
          Lifecycle: {
            MoveToColdStorageAfterDays: ['<optional1>', '<optional2>'],
            DeleteAfterDays: ['<optional1>', '<optional2>'],
          },
          RecoveryPointTags: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'Backup:updateRecoveryPointLifecycle': {
    BackupVaultName: 'required',
    RecoveryPointArn: 'required',
    Lifecycle: {
      MoveToColdStorageAfterDays: ['<optional1>', '<optional2>'],
      DeleteAfterDays: ['<optional1>', '<optional2>'],
    },
  },
  'Batch:cancelJob': {
    jobId: 'required',
    reason: 'required',
  },
  'Batch:createComputeEnvironment': {
    computeEnvironmentName: 'required',
    type: 'required',
    serviceRole: 'required',
    computeResources: [
      {
        desiredvCpus: '<optional>',
        imageId: '<optional>',
        securityGroupIds: ['<optional1>', '<optional2>'],
        ec2KeyPair: '<optional>',
        tags: ['<optional1>', '<optional2>'],
        placementGroup: '<optional>',
        bidPercentage: '<optional>',
        spotIamFleetRole: '<optional>',
        launchTemplate: {
          launchTemplateId: '<optional>',
          launchTemplateName: '<optional>',
          version: '<optional>',
        },
      },
    ],
    state: '<optional>',
  },
  'Batch:createJobQueue': {
    jobQueueName: 'required',
    priority: 'required',
    computeEnvironmentOrder: {
      order: 'required',
      computeEnvironment: 'required',
    },
    state: '<optional>',
  },
  'Batch:deleteComputeEnvironment': {
    computeEnvironment: 'required',
  },
  'Batch:deleteJobQueue': {
    jobQueue: 'required',
  },
  'Batch:deregisterJobDefinition': {
    jobDefinition: 'required',
  },
  'Batch:describeComputeEnvironments': {
    computeEnvironments: ['<optional1>', '<optional2>'],
  },
  'Batch:describeJobDefinitions': {
    jobDefinitions: ['<optional1>', '<optional2>'],
    jobDefinitionName: '<optional>',
    status: '<optional>',
  },
  'Batch:describeJobQueues': {
    jobQueues: ['<optional1>', '<optional2>'],
  },
  'Batch:describeJobs': {
    jobs: ['required1', 'required2'],
  },
  'Batch:listJobs': {
    jobQueue: '<optional>',
    arrayJobId: '<optional>',
    multiNodeJobId: '<optional>',
    jobStatus: '<optional>',
  },
  'Batch:registerJobDefinition': {
    jobDefinitionName: 'required',
    type: 'required',
    containerProperties: [
      {
        image: '<optional>',
        vcpus: '<optional>',
        memory: '<optional>',
        command: '<optional>',
        jobRoleArn: '<optional>',
        volumes: [
          {
            host: {
              sourcePath: '<optional>',
            },
            name: '<optional>',
          },
        ],
        environment: {
          name: '<optional>',
          value: '<optional>',
        },
        mountPoints: [
          {
            containerPath: '<optional>',
            readOnly: '<optional>',
            sourceVolume: '<optional>',
          },
        ],
        readonlyRootFilesystem: '<optional>',
        privileged: '<optional>',
        ulimits: ['<optional1>', '<optional2>'],
        user: '<optional>',
        instanceType: '<optional>',
        resourceRequirements: ['<optional1>', '<optional2>'],
      },
    ],
    nodeProperties: [
      {
        nodeRangeProperties: [
          {
            container: {
              image: '<optional>',
              vcpus: '<optional>',
              memory: '<optional>',
              command: '<optional>',
              jobRoleArn: '<optional>',
              volumes: [
                {
                  host: {
                    sourcePath: '<optional>',
                  },
                  name: '<optional>',
                },
              ],
              environment: {
                name: '<optional>',
                value: '<optional>',
              },
              mountPoints: [
                {
                  containerPath: '<optional>',
                  readOnly: '<optional>',
                  sourceVolume: '<optional>',
                },
              ],
              readonlyRootFilesystem: '<optional>',
              privileged: '<optional>',
              ulimits: ['<optional1>', '<optional2>'],
              user: '<optional>',
              instanceType: '<optional>',
              resourceRequirements: ['<optional1>', '<optional2>'],
            },
          },
        ],
      },
    ],
    parameters: ['<optional1>', '<optional2>'],
    retryStrategy: {
      attempts: ['<optional1>', '<optional2>'],
    },
    timeout: {
      attemptDurationSeconds: ['<optional1>', '<optional2>'],
    },
  },
  'Batch:submitJob': {
    jobName: 'required',
    jobQueue: 'required',
    jobDefinition: 'required',
    containerOverrides: [
      {
        vcpus: '<optional>',
        memory: '<optional>',
        command: '<optional>',
        instanceType: '<optional>',
        environment: {
          name: '<optional>',
          value: '<optional>',
        },
        resourceRequirements: ['<optional1>', '<optional2>'],
      },
    ],
    nodeOverrides: [
      {
        numNodes: ['<optional1>', '<optional2>'],
        nodePropertyOverrides: [
          {
            containerOverrides: [
              {
                vcpus: '<optional>',
                memory: '<optional>',
                command: '<optional>',
                instanceType: '<optional>',
                environment: {
                  name: '<optional>',
                  value: '<optional>',
                },
                resourceRequirements: ['<optional1>', '<optional2>'],
              },
            ],
          },
        ],
      },
    ],
    arrayProperties: [
      {
        size: '<optional>',
      },
    ],
    dependsOn: {
      jobId: '<optional>',
      type: '<optional>',
    },
    parameters: ['<optional1>', '<optional2>'],
    retryStrategy: {
      attempts: ['<optional1>', '<optional2>'],
    },
    timeout: {
      attemptDurationSeconds: ['<optional1>', '<optional2>'],
    },
  },
  'Batch:terminateJob': {
    jobId: 'required',
    reason: 'required',
  },
  'Batch:updateComputeEnvironment': {
    computeEnvironment: 'required',
    state: '<optional>',
    computeResources: [
      {
        minvCpus: '<optional>',
        maxvCpus: '<optional>',
        desiredvCpus: '<optional>',
      },
    ],
    serviceRole: '<optional>',
  },
  'Batch:updateJobQueue': {
    jobQueue: 'required',
    computeEnvironmentOrder: '<optional>',
    state: '<optional>',
    priority: '<optional>',
  },
  'Budgets:createBudget': {
    AccountId: 'required',
    Budget: {
      BudgetLimit: '<optional>',
      PlannedBudgetLimits: ['<optional1>', '<optional2>'],
      CostFilters: ['<optional1>', '<optional2>'],
      CostTypes: [
        {
          IncludeTax: '<optional>',
          IncludeSubscription: '<optional>',
          UseBlended: '<optional>',
          IncludeRefund: '<optional>',
          IncludeCredit: '<optional>',
          IncludeUpfront: '<optional>',
          IncludeRecurring: '<optional>',
          IncludeOtherSubscription: '<optional>',
          IncludeSupport: '<optional>',
          IncludeDiscount: '<optional>',
          UseAmortized: '<optional>',
        },
      ],
      TimePeriod: {
        Start: '<optional>',
        End: '<optional>',
      },
      CalculatedSpend: {
        ForecastedSpend: '<optional>',
      },
      LastUpdatedTime: '<optional>',
    },
    NotificationsWithSubscribers: [
      {
        Notification: {
          ThresholdType: '<optional>',
          NotificationState: '<optional>',
        },
      },
    ],
  },
  'Budgets:createNotification': {
    AccountId: 'required',
    BudgetName: 'required',
    Notification: {
      ThresholdType: '<optional>',
      NotificationState: '<optional>',
    },
    Subscribers: [
      {
        SubscriptionType: 'required',
        Address: 'required',
      },
    ],
  },
  'Budgets:createSubscriber': {
    AccountId: 'required',
    BudgetName: 'required',
    Notification: {
      ThresholdType: '<optional>',
      NotificationState: '<optional>',
    },
    Subscriber: {
      SubscriptionType: 'required',
      Address: 'required',
    },
  },
  'Budgets:deleteBudget': {
    AccountId: 'required',
    BudgetName: 'required',
  },
  'Budgets:deleteNotification': {
    AccountId: 'required',
    BudgetName: 'required',
    Notification: {
      ThresholdType: '<optional>',
      NotificationState: '<optional>',
    },
  },
  'Budgets:deleteSubscriber': {
    AccountId: 'required',
    BudgetName: 'required',
    Notification: {
      ThresholdType: '<optional>',
      NotificationState: '<optional>',
    },
    Subscriber: {
      SubscriptionType: 'required',
      Address: 'required',
    },
  },
  'Budgets:describeBudget': {
    AccountId: 'required',
    BudgetName: 'required',
  },
  'Budgets:describeBudgetPerformanceHistory': {
    AccountId: 'required',
    BudgetName: 'required',
    TimePeriod: {
      Start: '<optional>',
      End: '<optional>',
    },
  },
  'Budgets:describeBudgets': {
    AccountId: 'required',
  },
  'Budgets:describeNotificationsForBudget': {
    AccountId: 'required',
    BudgetName: 'required',
  },
  'Budgets:describeSubscribersForNotification': {
    AccountId: 'required',
    BudgetName: 'required',
    Notification: {
      ThresholdType: '<optional>',
      NotificationState: '<optional>',
    },
  },
  'Budgets:updateBudget': {
    AccountId: 'required',
    NewBudget: {
      BudgetLimit: '<optional>',
      PlannedBudgetLimits: ['<optional1>', '<optional2>'],
      CostFilters: ['<optional1>', '<optional2>'],
      CostTypes: [
        {
          IncludeTax: '<optional>',
          IncludeSubscription: '<optional>',
          UseBlended: '<optional>',
          IncludeRefund: '<optional>',
          IncludeCredit: '<optional>',
          IncludeUpfront: '<optional>',
          IncludeRecurring: '<optional>',
          IncludeOtherSubscription: '<optional>',
          IncludeSupport: '<optional>',
          IncludeDiscount: '<optional>',
          UseAmortized: '<optional>',
        },
      ],
      TimePeriod: {
        Start: '<optional>',
        End: '<optional>',
      },
      CalculatedSpend: {
        ForecastedSpend: '<optional>',
      },
      LastUpdatedTime: '<optional>',
    },
  },
  'Budgets:updateNotification': {
    AccountId: 'required',
    BudgetName: 'required',
    OldNotification: {
      ThresholdType: '<optional>',
      NotificationState: '<optional>',
    },
    NewNotification: {
      ThresholdType: '<optional>',
      NotificationState: '<optional>',
    },
  },
  'Budgets:updateSubscriber': {
    AccountId: 'required',
    BudgetName: 'required',
    Notification: {
      ThresholdType: '<optional>',
      NotificationState: '<optional>',
    },
    OldSubscriber: {
      SubscriptionType: 'required',
      Address: 'required',
    },
    NewSubscriber: {
      SubscriptionType: 'required',
      Address: 'required',
    },
  },
  'CUR:deleteReportDefinition': {
    ReportName: '<optional>',
  },
  'CUR:describeReportDefinitions': {},
  'CUR:modifyReportDefinition': {
    ReportName: 'required',
    ReportDefinition: {
      AdditionalArtifacts: ['<optional1>', '<optional2>'],
      RefreshClosedReports: ['<optional1>', '<optional2>'],
      ReportVersioning: '<optional>',
    },
  },
  'CUR:putReportDefinition': {
    ReportDefinition: {
      AdditionalArtifacts: ['<optional1>', '<optional2>'],
      RefreshClosedReports: ['<optional1>', '<optional2>'],
      ReportVersioning: '<optional>',
    },
  },
  'Chime:associatePhoneNumberWithUser': {
    AccountId: 'required',
    UserId: 'required',
    E164PhoneNumber: 'required',
  },
  'Chime:associatePhoneNumbersWithVoiceConnector': {
    VoiceConnectorId: 'required',
    E164PhoneNumbers: ['<optional1>', '<optional2>'],
  },
  'Chime:associatePhoneNumbersWithVoiceConnectorGroup': {
    VoiceConnectorGroupId: 'required',
    E164PhoneNumbers: ['<optional1>', '<optional2>'],
    ForceAssociate: '<optional>',
  },
  'Chime:batchCreateAttendee': {
    MeetingId: 'required',
    Attendees: [
      {
        ExternalUserId: 'required',
      },
    ],
  },
  'Chime:batchCreateRoomMembership': {
    AccountId: 'required',
    RoomId: 'required',
    MembershipItemList: {
      MemberId: '<optional>',
      Role: '<optional>',
    },
  },
  'Chime:batchDeletePhoneNumber': {
    PhoneNumberIds: ['required1', 'required2'],
  },
  'Chime:batchSuspendUser': {
    AccountId: 'required',
    UserIdList: 'required',
  },
  'Chime:batchUnsuspendUser': {
    AccountId: 'required',
    UserIdList: 'required',
  },
  'Chime:batchUpdatePhoneNumber': {
    UpdatePhoneNumberRequestItems: [
      {
        ProductType: '<optional>',
      },
    ],
  },
  'Chime:batchUpdateUser': {
    AccountId: 'required',
    UpdateUserRequestItems: [
      {
        LicenseType: '<optional>',
      },
    ],
  },
  'Chime:createAccount': {
    Name: 'required',
  },
  'Chime:createAttendee': {
    MeetingId: 'required',
    ExternalUserId: 'required',
  },
  'Chime:createBot': {
    DisplayName: 'required',
    AccountId: 'required',
    Domain: '<optional>',
  },
  'Chime:createMeeting': {
    ClientRequestToken: 'required',
    MeetingHostId: '<optional>',
    MediaRegion: '<optional>',
    NotificationsConfiguration: {
      SnsTopicArn: '<optional>',
      SqsQueueArn: '<optional>',
    },
  },
  'Chime:createPhoneNumberOrder': {
    ProductType: 'required',
    E164PhoneNumbers: ['required1', 'required2'],
  },
  'Chime:createRoom': {
    AccountId: 'required',
    Name: 'required',
    ClientRequestToken: '<optional>',
  },
  'Chime:createRoomMembership': {
    AccountId: 'required',
    RoomId: 'required',
    MemberId: 'required',
    Role: '<optional>',
  },
  'Chime:createVoiceConnector': {
    Name: 'required',
    RequireEncryption: 'required',
  },
  'Chime:createVoiceConnectorGroup': {
    Name: 'required',
    VoiceConnectorItems: ['<optional1>', '<optional2>'],
  },
  'Chime:deleteAccount': {
    AccountId: 'required',
  },
  'Chime:deleteAttendee': {
    MeetingId: 'required',
    AttendeeId: 'required',
  },
  'Chime:deleteEventsConfiguration': {
    AccountId: 'required',
    BotId: 'required',
  },
  'Chime:deleteMeeting': {
    MeetingId: 'required',
  },
  'Chime:deletePhoneNumber': {
    PhoneNumberId: 'required',
  },
  'Chime:deleteRoom': {
    AccountId: 'required',
    RoomId: 'required',
  },
  'Chime:deleteRoomMembership': {
    AccountId: 'required',
    RoomId: 'required',
    MemberId: 'required',
  },
  'Chime:deleteVoiceConnector': {
    VoiceConnectorId: 'required',
  },
  'Chime:deleteVoiceConnectorGroup': {
    VoiceConnectorGroupId: 'required',
  },
  'Chime:deleteVoiceConnectorOrigination': {
    VoiceConnectorId: 'required',
  },
  'Chime:deleteVoiceConnectorStreamingConfiguration': {
    VoiceConnectorId: 'required',
  },
  'Chime:deleteVoiceConnectorTermination': {
    VoiceConnectorId: 'required',
  },
  'Chime:deleteVoiceConnectorTerminationCredentials': {
    VoiceConnectorId: 'required',
    Usernames: ['<optional1>', '<optional2>'],
  },
  'Chime:disassociatePhoneNumberFromUser': {
    AccountId: 'required',
    UserId: 'required',
  },
  'Chime:disassociatePhoneNumbersFromVoiceConnector': {
    VoiceConnectorId: 'required',
    E164PhoneNumbers: ['<optional1>', '<optional2>'],
  },
  'Chime:disassociatePhoneNumbersFromVoiceConnectorGroup': {
    VoiceConnectorGroupId: 'required',
    E164PhoneNumbers: ['<optional1>', '<optional2>'],
  },
  'Chime:getAccount': {
    AccountId: 'required',
  },
  'Chime:getAccountSettings': {
    AccountId: 'required',
  },
  'Chime:getAttendee': {
    MeetingId: 'required',
    AttendeeId: 'required',
  },
  'Chime:getBot': {
    AccountId: 'required',
    BotId: 'required',
  },
  'Chime:getEventsConfiguration': {
    AccountId: 'required',
    BotId: 'required',
  },
  'Chime:getGlobalSettings': {},
  'Chime:getMeeting': {
    MeetingId: 'required',
  },
  'Chime:getPhoneNumber': {
    PhoneNumberId: 'required',
  },
  'Chime:getPhoneNumberOrder': {
    PhoneNumberOrderId: 'required',
  },
  'Chime:getPhoneNumberSettings': {},
  'Chime:getRoom': {
    AccountId: 'required',
    RoomId: 'required',
  },
  'Chime:getUser': {
    AccountId: 'required',
    UserId: 'required',
  },
  'Chime:getUserSettings': {
    AccountId: 'required',
    UserId: 'required',
  },
  'Chime:getVoiceConnector': {
    VoiceConnectorId: 'required',
  },
  'Chime:getVoiceConnectorGroup': {
    VoiceConnectorGroupId: 'required',
  },
  'Chime:getVoiceConnectorLoggingConfiguration': {
    VoiceConnectorId: 'required',
  },
  'Chime:getVoiceConnectorOrigination': {
    VoiceConnectorId: 'required',
  },
  'Chime:getVoiceConnectorStreamingConfiguration': {
    VoiceConnectorId: 'required',
  },
  'Chime:getVoiceConnectorTermination': {
    VoiceConnectorId: 'required',
  },
  'Chime:getVoiceConnectorTerminationHealth': {
    VoiceConnectorId: 'required',
  },
  'Chime:inviteUsers': {
    AccountId: 'required',
    UserEmailList: 'required',
  },
  'Chime:listAccounts': {
    Name: '<optional>',
    UserEmail: '<optional>',
  },
  'Chime:listAttendees': {
    MeetingId: 'required',
  },
  'Chime:listBots': {
    AccountId: 'required',
  },
  'Chime:listMeetings': {},
  'Chime:listPhoneNumberOrders': {},
  'Chime:listPhoneNumbers': {
    Status: '<optional>',
    ProductType: '<optional>',
    FilterName: '<optional>',
    FilterValue: '<optional>',
  },
  'Chime:listRoomMemberships': {
    AccountId: 'required',
    RoomId: 'required',
  },
  'Chime:listRooms': {
    AccountId: 'required',
    MemberId: '<optional>',
  },
  'Chime:listUsers': {
    AccountId: 'required',
    UserEmail: '<optional>',
  },
  'Chime:listVoiceConnectorGroups': {},
  'Chime:listVoiceConnectorTerminationCredentials': {
    VoiceConnectorId: 'required',
  },
  'Chime:listVoiceConnectors': {},
  'Chime:logoutUser': {
    AccountId: 'required',
    UserId: 'required',
  },
  'Chime:putEventsConfiguration': {
    AccountId: 'required',
    BotId: 'required',
    OutboundEventsHTTPSEndpoint: '<optional>',
    LambdaFunctionArn: '<optional>',
  },
  'Chime:putVoiceConnectorLoggingConfiguration': {
    VoiceConnectorId: 'required',
    LoggingConfiguration: {
      EnableSIPLogs: ['<optional1>', '<optional2>'],
    },
  },
  'Chime:putVoiceConnectorOrigination': {
    VoiceConnectorId: 'required',
    Origination: {
      Routes: [
        {
          Host: '<optional>',
          Port: '<optional>',
          Protocol: '<optional>',
          Priority: '<optional>',
          Weight: '<optional>',
        },
      ],
      Disabled: '<optional>',
    },
  },
  'Chime:putVoiceConnectorStreamingConfiguration': {
    VoiceConnectorId: 'required',
    StreamingConfiguration: {
      Disabled: '<optional>',
    },
  },
  'Chime:putVoiceConnectorTermination': {
    VoiceConnectorId: 'required',
    Termination: {
      CpsLimit: '<optional>',
      DefaultPhoneNumber: '<optional>',
      CallingRegions: ['<optional1>', '<optional2>'],
      CidrAllowedList: '<optional>',
      Disabled: '<optional>',
    },
  },
  'Chime:putVoiceConnectorTerminationCredentials': {
    VoiceConnectorId: 'required',
    Credentials: [
      {
        Username: '<optional>',
        Password: '<optional>',
      },
    ],
  },
  'Chime:regenerateSecurityToken': {
    AccountId: 'required',
    BotId: 'required',
  },
  'Chime:resetPersonalPIN': {
    AccountId: 'required',
    UserId: 'required',
  },
  'Chime:restorePhoneNumber': {
    PhoneNumberId: 'required',
  },
  'Chime:searchAvailablePhoneNumbers': {
    AreaCode: '<optional>',
    City: '<optional>',
    Country: '<optional>',
    State: '<optional>',
    TollFreePrefix: '<optional>',
  },
  'Chime:updateAccount': {
    AccountId: 'required',
    Name: '<optional>',
  },
  'Chime:updateAccountSettings': {
    AccountId: 'required',
    AccountSettings: [
      {
        DisableRemoteControl: '<optional>',
        EnableDialOut: '<optional>',
      },
    ],
  },
  'Chime:updateBot': {
    AccountId: 'required',
    BotId: 'required',
    Disabled: '<optional>',
  },
  'Chime:updateGlobalSettings': {
    BusinessCalling: {
      CdrBucket: '<optional>',
    },
    VoiceConnector: {
      CdrBucket: '<optional>',
    },
  },
  'Chime:updatePhoneNumber': {
    PhoneNumberId: 'required',
    ProductType: '<optional>',
  },
  'Chime:updatePhoneNumberSettings': {
    CallingName: 'required',
  },
  'Chime:updateRoom': {
    AccountId: 'required',
    RoomId: 'required',
    Name: '<optional>',
  },
  'Chime:updateRoomMembership': {
    AccountId: 'required',
    RoomId: 'required',
    MemberId: 'required',
    Role: '<optional>',
  },
  'Chime:updateUser': {
    AccountId: 'required',
    UserId: 'required',
    LicenseType: '<optional>',
  },
  'Chime:updateUserSettings': {
    AccountId: 'required',
    UserId: 'required',
    UserSettings: [
      {
        Telephony: {
          InboundCalling: 'required',
          OutboundCalling: 'required',
          SMS: ['required1', 'required2'],
        },
      },
    ],
  },
  'Chime:updateVoiceConnector': {
    VoiceConnectorId: 'required',
    Name: 'required',
    RequireEncryption: 'required',
  },
  'Chime:updateVoiceConnectorGroup': {
    VoiceConnectorGroupId: 'required',
    Name: 'required',
    VoiceConnectorItems: [
      {
        VoiceConnectorId: 'required',
        Priority: 'required',
      },
    ],
  },
  'Cloud9:createEnvironmentEC2': {
    name: 'required',
    instanceType: 'required',
    description: '<optional>',
    clientRequestToken: '<optional>',
    subnetId: '<optional>',
    automaticStopTimeMinutes: ['<optional1>', '<optional2>'],
    ownerArn: '<optional>',
  },
  'Cloud9:createEnvironmentMembership': {
    environmentId: 'required',
    userArn: 'required',
    permissions: ['required1', 'required2'],
  },
  'Cloud9:deleteEnvironment': {
    environmentId: 'required',
  },
  'Cloud9:deleteEnvironmentMembership': {
    environmentId: 'required',
    userArn: 'required',
  },
  'Cloud9:describeEnvironmentMemberships': {
    userArn: '<optional>',
    environmentId: '<optional>',
    permissions: ['<optional1>', '<optional2>'],
  },
  'Cloud9:describeEnvironmentStatus': {
    environmentId: 'required',
  },
  'Cloud9:describeEnvironments': {
    environmentIds: ['required1', 'required2'],
  },
  'Cloud9:listEnvironments': {},
  'Cloud9:updateEnvironment': {
    environmentId: 'required',
    name: '<optional>',
    description: '<optional>',
  },
  'Cloud9:updateEnvironmentMembership': {
    environmentId: 'required',
    userArn: 'required',
    permissions: ['required1', 'required2'],
  },
  'CloudDirectory:addFacetToObject': {
    DirectoryArn: 'required',
    SchemaFacet: {
      SchemaArn: '<optional>',
      FacetName: '<optional>',
    },
    ObjectReference: {
      Selector: '<optional>',
    },
    ObjectAttributeList: {
      Value: {
        StringValue: '<optional>',
        BinaryValue: '<optional>',
        BooleanValue: '<optional>',
        NumberValue: '<optional>',
        DatetimeValue: '<optional>',
      },
    },
  },
  'CloudDirectory:applySchema': {
    PublishedSchemaArn: 'required',
    DirectoryArn: 'required',
  },
  'CloudDirectory:attachObject': {
    DirectoryArn: 'required',
    ParentReference: {
      Selector: '<optional>',
    },
    ChildReference: {
      Selector: '<optional>',
    },
    LinkName: 'required',
  },
  'CloudDirectory:attachPolicy': {
    DirectoryArn: 'required',
    PolicyReference: {
      Selector: '<optional>',
    },
    ObjectReference: {
      Selector: '<optional>',
    },
  },
  'CloudDirectory:attachToIndex': {
    DirectoryArn: 'required',
    IndexReference: {
      Selector: '<optional>',
    },
    TargetReference: {
      Selector: '<optional>',
    },
  },
  'CloudDirectory:attachTypedLink': {
    DirectoryArn: 'required',
    SourceObjectReference: {
      Selector: '<optional>',
    },
    TargetObjectReference: {
      Selector: '<optional>',
    },
    TypedLinkFacet: {
      SchemaArn: 'required',
      TypedLinkName: 'required',
    },
    Attributes: [
      {
        Value: {
          StringValue: '<optional>',
          BinaryValue: '<optional>',
          BooleanValue: '<optional>',
          NumberValue: '<optional>',
          DatetimeValue: '<optional>',
        },
      },
    ],
  },
  'CloudDirectory:batchRead': {
    DirectoryArn: 'required',
    Operations: [
      {
        ListObjectAttributes: [
          {
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            FacetFilter: {
              SchemaArn: '<optional>',
              FacetName: '<optional>',
            },
            ObjectReference: {
              Selector: '<optional>',
            },
          },
        ],
        ListObjectChildren: [
          {
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            ObjectReference: {
              Selector: '<optional>',
            },
          },
        ],
        ListAttachedIndices: [
          {
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            TargetReference: {
              Selector: '<optional>',
            },
          },
        ],
        ListObjectParentPaths: [
          {
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            ObjectReference: {
              Selector: '<optional>',
            },
          },
        ],
        GetObjectInformation: {
          ObjectReference: {
            Selector: '<optional>',
          },
        },
        GetObjectAttributes: [
          {
            ObjectReference: {
              Selector: '<optional>',
            },
            SchemaFacet: {
              SchemaArn: '<optional>',
              FacetName: '<optional>',
            },
          },
        ],
        ListObjectParents: [
          {
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            ObjectReference: {
              Selector: '<optional>',
            },
          },
        ],
        ListObjectPolicies: [
          {
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            ObjectReference: {
              Selector: '<optional>',
            },
          },
        ],
        ListPolicyAttachments: [
          {
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            PolicyReference: {
              Selector: '<optional>',
            },
          },
        ],
        LookupPolicy: {
          NextToken: '<optional>',
          MaxResults: ['<optional1>', '<optional2>'],
          ObjectReference: {
            Selector: '<optional>',
          },
        },
        ListIndex: {
          RangesOnIndexedValues: [
            {
              AttributeKey: '<optional>',
              Range: {
                StartValue: {
                  StringValue: '<optional>',
                  BinaryValue: '<optional>',
                  BooleanValue: '<optional>',
                  NumberValue: '<optional>',
                  DatetimeValue: '<optional>',
                },
                EndValue: {
                  StringValue: '<optional>',
                  BinaryValue: '<optional>',
                  BooleanValue: '<optional>',
                  NumberValue: '<optional>',
                  DatetimeValue: '<optional>',
                },
              },
            },
          ],
          MaxResults: ['<optional1>', '<optional2>'],
          NextToken: '<optional>',
          IndexReference: {
            Selector: '<optional>',
          },
        },
        ListOutgoingTypedLinks: [
          {
            FilterAttributeRanges: [
              {
                AttributeName: '<optional>',
                Range: {
                  StartValue: {
                    StringValue: '<optional>',
                    BinaryValue: '<optional>',
                    BooleanValue: '<optional>',
                    NumberValue: '<optional>',
                    DatetimeValue: '<optional>',
                  },
                  EndValue: {
                    StringValue: '<optional>',
                    BinaryValue: '<optional>',
                    BooleanValue: '<optional>',
                    NumberValue: '<optional>',
                    DatetimeValue: '<optional>',
                  },
                },
              },
            ],
            FilterTypedLink: '<optional>',
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            ObjectReference: {
              Selector: '<optional>',
            },
          },
        ],
        ListIncomingTypedLinks: [
          {
            FilterAttributeRanges: [
              {
                AttributeName: '<optional>',
                Range: {
                  StartValue: {
                    StringValue: '<optional>',
                    BinaryValue: '<optional>',
                    BooleanValue: '<optional>',
                    NumberValue: '<optional>',
                    DatetimeValue: '<optional>',
                  },
                  EndValue: {
                    StringValue: '<optional>',
                    BinaryValue: '<optional>',
                    BooleanValue: '<optional>',
                    NumberValue: '<optional>',
                    DatetimeValue: '<optional>',
                  },
                },
              },
            ],
            FilterTypedLink: '<optional>',
            NextToken: '<optional>',
            MaxResults: ['<optional1>', '<optional2>'],
            ObjectReference: {
              Selector: '<optional>',
            },
          },
        ],
        GetLinkAttributes: [
          {
            TypedLinkSpecifier: {
              SourceObjectReference: {
                Selector: '<optional>',
              },
              TargetObjectReference: {
                Selector: '<optional>',
              },
              IdentityAttributeValues: [
                {
                  Value: {
                    StringValue: '<optional>',
                    BinaryValue: '<optional>',
                    BooleanValue: '<optional>',
                    NumberValue: '<optional>',
                    DatetimeValue: '<optional>',
                  },
                },
              ],
            },
          },
        ],
      },
    ],
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:batchWrite': {
    DirectoryArn: 'required',
    Operations: [
      {
        CreateObject: {
          ParentReference: {
            Selector: '<optional>',
          },
          LinkName: '<optional>',
          BatchReferenceName: '<optional>',
          SchemaFacet: {
            SchemaArn: '<optional>',
            FacetName: '<optional>',
          },
          ObjectAttributeList: {
            Value: {
              StringValue: '<optional>',
              BinaryValue: '<optional>',
              BooleanValue: '<optional>',
              NumberValue: '<optional>',
              DatetimeValue: '<optional>',
            },
          },
        },
        AttachObject: {
          ParentReference: {
            Selector: '<optional>',
          },
          ChildReference: {
            Selector: '<optional>',
          },
        },
        DetachObject: {
          BatchReferenceName: '<optional>',
          ParentReference: {
            Selector: '<optional>',
          },
        },
        UpdateObjectAttributes: [
          {
            ObjectReference: {
              Selector: '<optional>',
            },
            AttributeUpdates: [
              {
                ObjectAttributeKey: '<optional>',
                ObjectAttributeAction: {
                  ObjectAttributeActionType: '<optional>',
                  ObjectAttributeUpdateValue: {
                    StringValue: '<optional>',
                    BinaryValue: '<optional>',
                    BooleanValue: '<optional>',
                    NumberValue: '<optional>',
                    DatetimeValue: '<optional>',
                  },
                },
              },
            ],
          },
        ],
        DeleteObject: {
          ObjectReference: {
            Selector: '<optional>',
          },
        },
        AddFacetToObject: {
          SchemaFacet: {
            SchemaArn: '<optional>',
            FacetName: '<optional>',
          },
          ObjectAttributeList: {
            Value: {
              StringValue: '<optional>',
              BinaryValue: '<optional>',
              BooleanValue: '<optional>',
              NumberValue: '<optional>',
              DatetimeValue: '<optional>',
            },
          },
          ObjectReference: {
            Selector: '<optional>',
          },
        },
        RemoveFacetFromObject: {
          SchemaFacet: {
            SchemaArn: '<optional>',
            FacetName: '<optional>',
          },
          ObjectReference: {
            Selector: '<optional>',
          },
        },
        AttachPolicy: {
          PolicyReference: {
            Selector: '<optional>',
          },
          ObjectReference: {
            Selector: '<optional>',
          },
        },
        DetachPolicy: {
          PolicyReference: {
            Selector: '<optional>',
          },
          ObjectReference: {
            Selector: '<optional>',
          },
        },
        CreateIndex: {
          ParentReference: {
            Selector: '<optional>',
          },
          LinkName: '<optional>',
          BatchReferenceName: '<optional>',
        },
        AttachToIndex: {
          IndexReference: {
            Selector: '<optional>',
          },
          TargetReference: {
            Selector: '<optional>',
          },
        },
        DetachFromIndex: {
          IndexReference: {
            Selector: '<optional>',
          },
          TargetReference: {
            Selector: '<optional>',
          },
        },
        AttachTypedLink: {
          SourceObjectReference: {
            Selector: '<optional>',
          },
          TargetObjectReference: {
            Selector: '<optional>',
          },
          Attributes: [
            {
              Value: {
                StringValue: '<optional>',
                BinaryValue: '<optional>',
                BooleanValue: '<optional>',
                NumberValue: '<optional>',
                DatetimeValue: '<optional>',
              },
            },
          ],
        },
        DetachTypedLink: {
          TypedLinkSpecifier: {
            SourceObjectReference: {
              Selector: '<optional>',
            },
            TargetObjectReference: {
              Selector: '<optional>',
            },
            IdentityAttributeValues: [
              {
                Value: {
                  StringValue: '<optional>',
                  BinaryValue: '<optional>',
                  BooleanValue: '<optional>',
                  NumberValue: '<optional>',
                  DatetimeValue: '<optional>',
                },
              },
            ],
          },
        },
        UpdateLinkAttributes: [
          {
            TypedLinkSpecifier: {
              SourceObjectReference: {
                Selector: '<optional>',
              },
              TargetObjectReference: {
                Selector: '<optional>',
              },
              IdentityAttributeValues: [
                {
                  Value: {
                    StringValue: '<optional>',
                    BinaryValue: '<optional>',
                    BooleanValue: '<optional>',
                    NumberValue: '<optional>',
                    DatetimeValue: '<optional>',
                  },
                },
              ],
            },
            AttributeUpdates: [
              {
                AttributeKey: '<optional>',
                AttributeAction: {
                  AttributeActionType: '<optional>',
                  AttributeUpdateValue: {
                    StringValue: '<optional>',
                    BinaryValue: '<optional>',
                    BooleanValue: '<optional>',
                    NumberValue: '<optional>',
                    DatetimeValue: '<optional>',
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
  'CloudDirectory:createDirectory': {
    Name: 'required',
    SchemaArn: 'required',
  },
  'CloudDirectory:createFacet': {
    SchemaArn: 'required',
    Name: 'required',
    Attributes: [
      {
        AttributeDefinition: {
          DefaultValue: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
          IsImmutable: '<optional>',
          Rules: ['<optional1>', '<optional2>'],
        },
        AttributeReference: '<optional>',
        RequiredBehavior: '<optional>',
      },
    ],
    ObjectType: '<optional>',
    FacetStyle: '<optional>',
  },
  'CloudDirectory:createIndex': {
    DirectoryArn: 'required',
    OrderedIndexedAttributeList: {
      SchemaArn: 'required',
      FacetName: 'required',
      Name: 'required',
    },
    IsUnique: 'required',
    ParentReference: {
      Selector: '<optional>',
    },
    LinkName: '<optional>',
  },
  'CloudDirectory:createObject': {
    DirectoryArn: 'required',
    SchemaFacets: [
      {
        SchemaArn: '<optional>',
        FacetName: '<optional>',
      },
    ],
    ObjectAttributeList: {
      Value: {
        StringValue: '<optional>',
        BinaryValue: '<optional>',
        BooleanValue: '<optional>',
        NumberValue: '<optional>',
        DatetimeValue: '<optional>',
      },
    },
    ParentReference: {
      Selector: '<optional>',
    },
    LinkName: '<optional>',
  },
  'CloudDirectory:createSchema': {
    Name: 'required',
  },
  'CloudDirectory:createTypedLinkFacet': {
    SchemaArn: 'required',
    Facet: {
      Attributes: [
        {
          DefaultValue: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
          IsImmutable: '<optional>',
          Rules: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'CloudDirectory:deleteDirectory': {
    DirectoryArn: 'required',
  },
  'CloudDirectory:deleteFacet': {
    SchemaArn: 'required',
    Name: 'required',
  },
  'CloudDirectory:deleteObject': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
  },
  'CloudDirectory:deleteSchema': {
    SchemaArn: 'required',
  },
  'CloudDirectory:deleteTypedLinkFacet': {
    SchemaArn: 'required',
    Name: 'required',
  },
  'CloudDirectory:detachFromIndex': {
    DirectoryArn: 'required',
    IndexReference: {
      Selector: '<optional>',
    },
    TargetReference: {
      Selector: '<optional>',
    },
  },
  'CloudDirectory:detachObject': {
    DirectoryArn: 'required',
    ParentReference: {
      Selector: '<optional>',
    },
    LinkName: 'required',
  },
  'CloudDirectory:detachPolicy': {
    DirectoryArn: 'required',
    PolicyReference: {
      Selector: '<optional>',
    },
    ObjectReference: {
      Selector: '<optional>',
    },
  },
  'CloudDirectory:detachTypedLink': {
    DirectoryArn: 'required',
    TypedLinkSpecifier: {
      SourceObjectReference: {
        Selector: '<optional>',
      },
      TargetObjectReference: {
        Selector: '<optional>',
      },
      IdentityAttributeValues: [
        {
          Value: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
        },
      ],
    },
  },
  'CloudDirectory:disableDirectory': {
    DirectoryArn: 'required',
  },
  'CloudDirectory:enableDirectory': {
    DirectoryArn: 'required',
  },
  'CloudDirectory:getAppliedSchemaVersion': {
    SchemaArn: 'required',
  },
  'CloudDirectory:getDirectory': {
    DirectoryArn: 'required',
  },
  'CloudDirectory:getFacet': {
    SchemaArn: 'required',
    Name: 'required',
  },
  'CloudDirectory:getLinkAttributes': {
    DirectoryArn: 'required',
    TypedLinkSpecifier: {
      SourceObjectReference: {
        Selector: '<optional>',
      },
      TargetObjectReference: {
        Selector: '<optional>',
      },
      IdentityAttributeValues: [
        {
          Value: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
        },
      ],
    },
    AttributeNames: ['required1', 'required2'],
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:getObjectAttributes': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    SchemaFacet: {
      SchemaArn: '<optional>',
      FacetName: '<optional>',
    },
    AttributeNames: ['required1', 'required2'],
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:getObjectInformation': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:getSchemaAsJson': {
    SchemaArn: 'required',
  },
  'CloudDirectory:getTypedLinkFacetInformation': {
    SchemaArn: 'required',
    Name: 'required',
  },
  'CloudDirectory:listAppliedSchemaArns': {
    DirectoryArn: 'required',
    SchemaArn: '<optional>',
  },
  'CloudDirectory:listAttachedIndices': {
    DirectoryArn: 'required',
    TargetReference: {
      Selector: '<optional>',
    },
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:listDevelopmentSchemaArns': {},
  'CloudDirectory:listDirectories': {
    state: '<optional>',
  },
  'CloudDirectory:listFacetAttributes': {
    SchemaArn: 'required',
    Name: 'required',
  },
  'CloudDirectory:listFacetNames': {
    SchemaArn: 'required',
  },
  'CloudDirectory:listIncomingTypedLinks': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    FilterAttributeRanges: [
      {
        AttributeName: '<optional>',
        Range: {
          StartMode: '<optional>',
          EndMode: '<optional>',
        },
      },
    ],
    FilterTypedLink: {
      SchemaArn: '<optional>',
      TypedLinkName: '<optional>',
    },
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:listIndex': {
    DirectoryArn: 'required',
    IndexReference: {
      Selector: '<optional>',
    },
    RangesOnIndexedValues: [
      {
        AttributeKey: '<optional>',
        Range: {
          StartValue: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
          EndValue: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
        },
      },
    ],
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:listManagedSchemaArns': {
    SchemaArn: '<optional>',
  },
  'CloudDirectory:listObjectAttributes': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    ConsistencyLevel: '<optional>',
    FacetFilter: {
      SchemaArn: '<optional>',
      FacetName: '<optional>',
    },
  },
  'CloudDirectory:listObjectChildren': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:listObjectParentPaths': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
  },
  'CloudDirectory:listObjectParents': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    ConsistencyLevel: '<optional>',
    IncludeAllLinksToEachParent: '<optional>',
  },
  'CloudDirectory:listObjectPolicies': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:listOutgoingTypedLinks': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    FilterAttributeRanges: [
      {
        AttributeName: '<optional>',
        Range: {
          StartMode: '<optional>',
          EndMode: '<optional>',
        },
      },
    ],
    FilterTypedLink: {
      SchemaArn: '<optional>',
      TypedLinkName: '<optional>',
    },
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:listPolicyAttachments': {
    DirectoryArn: 'required',
    PolicyReference: {
      Selector: '<optional>',
    },
    ConsistencyLevel: '<optional>',
  },
  'CloudDirectory:listPublishedSchemaArns': {
    SchemaArn: '<optional>',
  },
  'CloudDirectory:listTagsForResource': {
    ResourceArn: 'required',
  },
  'CloudDirectory:listTypedLinkFacetAttributes': {
    SchemaArn: 'required',
    Name: 'required',
  },
  'CloudDirectory:listTypedLinkFacetNames': {
    SchemaArn: 'required',
  },
  'CloudDirectory:lookupPolicy': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
  },
  'CloudDirectory:publishSchema': {
    DevelopmentSchemaArn: 'required',
    Version: 'required',
    MinorVersion: '<optional>',
    Name: '<optional>',
  },
  'CloudDirectory:putSchemaFromJson': {
    SchemaArn: 'required',
    Document: 'required',
  },
  'CloudDirectory:removeFacetFromObject': {
    DirectoryArn: 'required',
    SchemaFacet: {
      SchemaArn: '<optional>',
      FacetName: '<optional>',
    },
    ObjectReference: {
      Selector: '<optional>',
    },
  },
  'CloudDirectory:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'CloudDirectory:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'CloudDirectory:updateFacet': {
    SchemaArn: 'required',
    Name: 'required',
    AttributeUpdates: [
      {
        Attribute: {
          AttributeDefinition: {
            DefaultValue: {
              StringValue: '<optional>',
              BinaryValue: '<optional>',
              BooleanValue: '<optional>',
              NumberValue: '<optional>',
              DatetimeValue: '<optional>',
            },
            IsImmutable: '<optional>',
            Rules: ['<optional1>', '<optional2>'],
          },
          AttributeReference: '<optional>',
          RequiredBehavior: '<optional>',
        },
        Action: '<optional>',
      },
    ],
    ObjectType: '<optional>',
  },
  'CloudDirectory:updateLinkAttributes': {
    DirectoryArn: 'required',
    TypedLinkSpecifier: {
      SourceObjectReference: {
        Selector: '<optional>',
      },
      TargetObjectReference: {
        Selector: '<optional>',
      },
      IdentityAttributeValues: [
        {
          Value: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
        },
      ],
    },
    AttributeUpdates: [
      {
        AttributeKey: '<optional>',
        AttributeAction: {
          AttributeActionType: '<optional>',
          AttributeUpdateValue: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
        },
      },
    ],
  },
  'CloudDirectory:updateObjectAttributes': {
    DirectoryArn: 'required',
    ObjectReference: {
      Selector: '<optional>',
    },
    AttributeUpdates: [
      {
        ObjectAttributeKey: '<optional>',
        ObjectAttributeAction: {
          ObjectAttributeActionType: '<optional>',
          ObjectAttributeUpdateValue: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
        },
      },
    ],
  },
  'CloudDirectory:updateSchema': {
    SchemaArn: 'required',
    Name: 'required',
  },
  'CloudDirectory:updateTypedLinkFacet': {
    SchemaArn: 'required',
    Name: 'required',
    AttributeUpdates: [
      {
        Attribute: {
          DefaultValue: {
            StringValue: '<optional>',
            BinaryValue: '<optional>',
            BooleanValue: '<optional>',
            NumberValue: '<optional>',
            DatetimeValue: '<optional>',
          },
          IsImmutable: '<optional>',
          Rules: ['<optional1>', '<optional2>'],
        },
      },
    ],
    IdentityAttributeOrder: 'required',
  },
  'CloudDirectory:upgradeAppliedSchema': {
    PublishedSchemaArn: 'required',
    DirectoryArn: 'required',
  },
  'CloudDirectory:upgradePublishedSchema': {
    DevelopmentSchemaArn: 'required',
    PublishedSchemaArn: 'required',
    MinorVersion: 'required',
  },
  'CloudFormation:cancelUpdateStack': {
    StackName: 'required',
    ClientRequestToken: '<optional>',
  },
  'CloudFormation:continueUpdateRollback': {
    StackName: 'required',
    RoleARN: '<optional>',
    ResourcesToSkip: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'CloudFormation:createChangeSet': {
    StackName: 'required',
    ChangeSetName: 'required',
    RollbackConfiguration: {
      RollbackTriggers: ['<optional1>', '<optional2>'],
      MonitoringTimeInMinutes: ['<optional1>', '<optional2>'],
    },
    Tags: ['<optional1>', '<optional2>'],
    TemplateBody: '<optional>',
    TemplateURL: '<optional>',
    UsePreviousTemplate: '<optional>',
    Parameters: [
      {
        ParameterKey: '<optional>',
        ParameterValue: '<optional>',
        UsePreviousValue: '<optional>',
        ResolvedValue: '<optional>',
      },
    ],
    Capabilities: ['<optional1>', '<optional2>'],
    ResourceTypes: ['<optional1>', '<optional2>'],
    RoleARN: '<optional>',
    NotificationARNs: ['<optional1>', '<optional2>'],
    ClientToken: '<optional>',
    Description: '<optional>',
    ChangeSetType: '<optional>',
  },
  'CloudFormation:createStack': {
    StackName: 'required',
    RollbackConfiguration: {
      RollbackTriggers: ['<optional1>', '<optional2>'],
      MonitoringTimeInMinutes: ['<optional1>', '<optional2>'],
    },
    Tags: ['<optional1>', '<optional2>'],
    TemplateBody: '<optional>',
    TemplateURL: '<optional>',
    Parameters: [
      {
        ParameterKey: '<optional>',
        ParameterValue: '<optional>',
        UsePreviousValue: '<optional>',
        ResolvedValue: '<optional>',
      },
    ],
    DisableRollback: '<optional>',
    TimeoutInMinutes: ['<optional1>', '<optional2>'],
    NotificationARNs: ['<optional1>', '<optional2>'],
    Capabilities: ['<optional1>', '<optional2>'],
    ResourceTypes: ['<optional1>', '<optional2>'],
    RoleARN: '<optional>',
    OnFailure: '<optional>',
    StackPolicyBody: '<optional>',
    StackPolicyURL: '<optional>',
    ClientRequestToken: '<optional>',
    EnableTerminationProtection: '<optional>',
  },
  'CloudFormation:createStackInstances': {
    StackSetName: 'required',
    Accounts: ['required1', 'required2'],
    Regions: ['required1', 'required2'],
    ParameterOverrides: [
      {
        ParameterKey: '<optional>',
        ParameterValue: '<optional>',
        UsePreviousValue: '<optional>',
        ResolvedValue: '<optional>',
      },
    ],
    OperationPreferences: [
      {
        RegionOrder: '<optional>',
        FailureToleranceCount: '<optional>',
        FailureTolerancePercentage: '<optional>',
        MaxConcurrentCount: '<optional>',
        MaxConcurrentPercentage: '<optional>',
      },
    ],
    OperationId: '<optional>',
  },
  'CloudFormation:createStackSet': {
    StackSetName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    TemplateBody: '<optional>',
    TemplateURL: '<optional>',
    Parameters: [
      {
        ParameterKey: '<optional>',
        ParameterValue: '<optional>',
        UsePreviousValue: '<optional>',
        ResolvedValue: '<optional>',
      },
    ],
    Capabilities: ['<optional1>', '<optional2>'],
    AdministrationRoleARN: '<optional>',
    ExecutionRoleName: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'CloudFormation:deleteChangeSet': {
    ChangeSetName: 'required',
    StackName: '<optional>',
  },
  'CloudFormation:deleteStack': {
    StackName: 'required',
    RetainResources: ['<optional1>', '<optional2>'],
    RoleARN: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'CloudFormation:deleteStackInstances': {
    StackSetName: 'required',
    Accounts: ['required1', 'required2'],
    Regions: ['required1', 'required2'],
    RetainStacks: ['required1', 'required2'],
    OperationPreferences: [
      {
        RegionOrder: '<optional>',
        FailureToleranceCount: '<optional>',
        FailureTolerancePercentage: '<optional>',
        MaxConcurrentCount: '<optional>',
        MaxConcurrentPercentage: '<optional>',
      },
    ],
    OperationId: '<optional>',
  },
  'CloudFormation:deleteStackSet': {
    StackSetName: 'required',
  },
  'CloudFormation:deregisterType': {
    Arn: '<optional>',
    Type: '<optional>',
    TypeName: '<optional>',
    VersionId: '<optional>',
  },
  'CloudFormation:describeAccountLimits': {},
  'CloudFormation:describeChangeSet': {
    ChangeSetName: 'required',
    StackName: '<optional>',
  },
  'CloudFormation:describeStackDriftDetectionStatus': {
    StackDriftDetectionId: 'required',
  },
  'CloudFormation:describeStackEvents': {
    StackName: '<optional>',
  },
  'CloudFormation:describeStackInstance': {
    StackSetName: 'required',
    StackInstanceAccount: 'required',
    StackInstanceRegion: 'required',
  },
  'CloudFormation:describeStackResource': {
    StackName: 'required',
    LogicalResourceId: 'required',
  },
  'CloudFormation:describeStackResourceDrifts': {
    StackName: 'required',
    StackResourceDriftStatusFilters: ['<optional1>', '<optional2>'],
  },
  'CloudFormation:describeStackResources': {
    StackName: '<optional>',
    LogicalResourceId: '<optional>',
    PhysicalResourceId: '<optional>',
  },
  'CloudFormation:describeStackSet': {
    StackSetName: 'required',
  },
  'CloudFormation:describeStackSetOperation': {
    StackSetName: 'required',
    OperationId: 'required',
  },
  'CloudFormation:describeStacks': {
    StackName: '<optional>',
  },
  'CloudFormation:describeType': {
    Type: '<optional>',
    TypeName: '<optional>',
    Arn: '<optional>',
    VersionId: '<optional>',
  },
  'CloudFormation:describeTypeRegistration': {
    RegistrationToken: 'required',
  },
  'CloudFormation:detectStackDrift': {
    StackName: 'required',
    LogicalResourceIds: ['<optional1>', '<optional2>'],
  },
  'CloudFormation:detectStackResourceDrift': {
    StackName: 'required',
    LogicalResourceId: 'required',
  },
  'CloudFormation:detectStackSetDrift': {
    StackSetName: 'required',
    OperationPreferences: [
      {
        RegionOrder: '<optional>',
        FailureToleranceCount: '<optional>',
        FailureTolerancePercentage: '<optional>',
        MaxConcurrentCount: '<optional>',
        MaxConcurrentPercentage: '<optional>',
      },
    ],
    OperationId: '<optional>',
  },
  'CloudFormation:estimateTemplateCost': {
    TemplateBody: '<optional>',
    TemplateURL: '<optional>',
    Parameters: [
      {
        ParameterKey: '<optional>',
        ParameterValue: '<optional>',
        UsePreviousValue: '<optional>',
        ResolvedValue: '<optional>',
      },
    ],
  },
  'CloudFormation:executeChangeSet': {
    ChangeSetName: 'required',
    StackName: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'CloudFormation:getStackPolicy': {
    StackName: 'required',
  },
  'CloudFormation:getTemplate': {
    StackName: '<optional>',
    ChangeSetName: '<optional>',
    TemplateStage: '<optional>',
  },
  'CloudFormation:getTemplateSummary': {
    TemplateBody: '<optional>',
    TemplateURL: '<optional>',
    StackName: '<optional>',
    StackSetName: '<optional>',
  },
  'CloudFormation:listChangeSets': {
    StackName: 'required',
  },
  'CloudFormation:listExports': {},
  'CloudFormation:listImports': {
    ExportName: 'required',
  },
  'CloudFormation:listStackInstances': {
    StackSetName: 'required',
    StackInstanceAccount: '<optional>',
    StackInstanceRegion: '<optional>',
  },
  'CloudFormation:listStackResources': {
    StackName: 'required',
  },
  'CloudFormation:listStackSetOperationResults': {
    StackSetName: 'required',
    OperationId: 'required',
  },
  'CloudFormation:listStackSetOperations': {
    StackSetName: 'required',
  },
  'CloudFormation:listStackSets': {
    Status: '<optional>',
  },
  'CloudFormation:listStacks': {
    StackStatusFilter: '<optional>',
  },
  'CloudFormation:listTypeRegistrations': {
    Type: '<optional>',
    TypeName: '<optional>',
    TypeArn: '<optional>',
    RegistrationStatusFilter: '<optional>',
  },
  'CloudFormation:listTypeVersions': {
    Type: '<optional>',
    TypeName: '<optional>',
    Arn: '<optional>',
    DeprecatedStatus: '<optional>',
  },
  'CloudFormation:listTypes': {
    Visibility: '<optional>',
    ProvisioningType: '<optional>',
    DeprecatedStatus: '<optional>',
  },
  'CloudFormation:recordHandlerProgress': {
    BearerToken: 'required',
    OperationStatus: 'required',
    CurrentOperationStatus: '<optional>',
    StatusMessage: '<optional>',
    ErrorCode: '<optional>',
    ResourceModel: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'CloudFormation:registerType': {
    TypeName: 'required',
    SchemaHandlerPackage: 'required',
    LoggingConfig: '<optional>',
    Type: '<optional>',
    ExecutionRoleArn: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'CloudFormation:setStackPolicy': {
    StackName: 'required',
    StackPolicyBody: '<optional>',
    StackPolicyURL: '<optional>',
  },
  'CloudFormation:setTypeDefaultVersion': {
    Arn: '<optional>',
    Type: '<optional>',
    TypeName: '<optional>',
    VersionId: '<optional>',
  },
  'CloudFormation:signalResource': {
    StackName: 'required',
    LogicalResourceId: 'required',
    UniqueId: 'required',
    Status: 'required',
  },
  'CloudFormation:stopStackSetOperation': {
    StackSetName: 'required',
    OperationId: 'required',
  },
  'CloudFormation:updateStack': {
    StackName: 'required',
    RollbackConfiguration: {
      RollbackTriggers: ['<optional1>', '<optional2>'],
      MonitoringTimeInMinutes: ['<optional1>', '<optional2>'],
    },
    Tags: ['<optional1>', '<optional2>'],
    TemplateBody: '<optional>',
    TemplateURL: '<optional>',
    UsePreviousTemplate: '<optional>',
    StackPolicyDuringUpdateBody: '<optional>',
    StackPolicyDuringUpdateURL: '<optional>',
    Parameters: [
      {
        ParameterKey: '<optional>',
        ParameterValue: '<optional>',
        UsePreviousValue: '<optional>',
        ResolvedValue: '<optional>',
      },
    ],
    Capabilities: ['<optional1>', '<optional2>'],
    ResourceTypes: ['<optional1>', '<optional2>'],
    RoleARN: '<optional>',
    StackPolicyBody: '<optional>',
    StackPolicyURL: '<optional>',
    NotificationARNs: ['<optional1>', '<optional2>'],
    ClientRequestToken: '<optional>',
  },
  'CloudFormation:updateStackInstances': {
    StackSetName: 'required',
    Accounts: ['required1', 'required2'],
    Regions: ['required1', 'required2'],
    ParameterOverrides: [
      {
        ParameterKey: '<optional>',
        ParameterValue: '<optional>',
        UsePreviousValue: '<optional>',
        ResolvedValue: '<optional>',
      },
    ],
    OperationPreferences: [
      {
        RegionOrder: '<optional>',
        FailureToleranceCount: '<optional>',
        FailureTolerancePercentage: '<optional>',
        MaxConcurrentCount: '<optional>',
        MaxConcurrentPercentage: '<optional>',
      },
    ],
    OperationId: '<optional>',
  },
  'CloudFormation:updateStackSet': {
    StackSetName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    TemplateBody: '<optional>',
    TemplateURL: '<optional>',
    UsePreviousTemplate: '<optional>',
    Parameters: [
      {
        ParameterKey: '<optional>',
        ParameterValue: '<optional>',
        UsePreviousValue: '<optional>',
        ResolvedValue: '<optional>',
      },
    ],
    Capabilities: ['<optional1>', '<optional2>'],
    OperationPreferences: [
      {
        RegionOrder: '<optional>',
        FailureToleranceCount: '<optional>',
        FailureTolerancePercentage: '<optional>',
        MaxConcurrentCount: '<optional>',
        MaxConcurrentPercentage: '<optional>',
      },
    ],
    AdministrationRoleARN: '<optional>',
    ExecutionRoleName: '<optional>',
    OperationId: '<optional>',
    Accounts: ['<optional1>', '<optional2>'],
    Regions: ['<optional1>', '<optional2>'],
  },
  'CloudFormation:updateTerminationProtection': {
    EnableTerminationProtection: 'required',
    StackName: 'required',
  },
  'CloudFormation:validateTemplate': {
    TemplateBody: '<optional>',
    TemplateURL: '<optional>',
  },
  'CloudFront:createCloudFrontOriginAccessIdentity': {
    CloudFrontOriginAccessIdentityConfig: {
      CallerReference: 'required',
      Comment: 'required',
    },
  },
  'CloudFront:createDistribution': {
    DistributionConfig: {
      Aliases: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
      DefaultRootObject: '<optional>',
      OriginGroups: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
      CacheBehaviors: [
        {
          Items: [
            {
              AllowedMethods: [
                {
                  CachedMethods: ['<optional1>', '<optional2>'],
                },
              ],
              SmoothStreaming: '<optional>',
              DefaultTTL: '<optional>',
              MaxTTL: '<optional>',
              Compress: '<optional>',
              LambdaFunctionAssociations: [
                {
                  Items: [
                    {
                      IncludeBody: '<optional>',
                    },
                  ],
                },
              ],
              FieldLevelEncryptionId: '<optional>',
              ForwardedValues: [
                {
                  Headers: [
                    {
                      Items: ['<optional1>', '<optional2>'],
                    },
                  ],
                  QueryStringCacheKeys: [
                    {
                      Items: ['<optional1>', '<optional2>'],
                    },
                  ],
                  Cookies: [
                    {
                      WhitelistedNames: [
                        {
                          Items: ['<optional1>', '<optional2>'],
                        },
                      ],
                    },
                  ],
                },
              ],
              TrustedSigners: [
                {
                  Items: ['<optional1>', '<optional2>'],
                },
              ],
            },
          ],
        },
      ],
      CustomErrorResponses: [
        {
          Items: [
            {
              ResponsePagePath: '<optional>',
              ResponseCode: '<optional>',
              ErrorCachingMinTTL: '<optional>',
            },
          ],
        },
      ],
      Logging: '<optional>',
      PriceClass: '<optional>',
      ViewerCertificate: {
        CloudFrontDefaultCertificate: '<optional>',
        IAMCertificateId: '<optional>',
        ACMCertificateArn: '<optional>',
        SSLSupportMethod: '<optional>',
        MinimumProtocolVersion: '<optional>',
        Certificate: '<optional>',
        CertificateSource: '<optional>',
      },
      Restrictions: [
        {
          GeoRestriction: {
            Items: ['<optional1>', '<optional2>'],
          },
        },
      ],
      WebACLId: '<optional>',
      HttpVersion: '<optional>',
      IsIPV6Enabled: '<optional>',
      Origins: [
        {
          Items: [
            {
              OriginPath: '<optional>',
              CustomHeaders: [
                {
                  Items: ['<optional1>', '<optional2>'],
                },
              ],
              S3OriginConfig: '<optional>',
              CustomOriginConfig: {
                OriginSslProtocols: ['<optional1>', '<optional2>'],
                OriginReadTimeout: '<optional>',
                OriginKeepaliveTimeout: '<optional>',
              },
            },
          ],
        },
      ],
      DefaultCacheBehavior: {
        AllowedMethods: [
          {
            CachedMethods: ['<optional1>', '<optional2>'],
          },
        ],
        SmoothStreaming: '<optional>',
        DefaultTTL: '<optional>',
        MaxTTL: '<optional>',
        Compress: '<optional>',
        LambdaFunctionAssociations: [
          {
            Items: [
              {
                IncludeBody: '<optional>',
              },
            ],
          },
        ],
        FieldLevelEncryptionId: '<optional>',
        ForwardedValues: [
          {
            Headers: [
              {
                Items: ['<optional1>', '<optional2>'],
              },
            ],
            QueryStringCacheKeys: [
              {
                Items: ['<optional1>', '<optional2>'],
              },
            ],
            Cookies: [
              {
                WhitelistedNames: [
                  {
                    Items: ['<optional1>', '<optional2>'],
                  },
                ],
              },
            ],
          },
        ],
        TrustedSigners: [
          {
            Items: ['<optional1>', '<optional2>'],
          },
        ],
      },
    },
  },
  'CloudFront:createDistributionWithTags': {
    DistributionConfigWithTags: [
      {
        DistributionConfig: {
          Aliases: [
            {
              Items: ['<optional1>', '<optional2>'],
            },
          ],
          DefaultRootObject: '<optional>',
          OriginGroups: [
            {
              Items: ['<optional1>', '<optional2>'],
            },
          ],
          CacheBehaviors: [
            {
              Items: [
                {
                  AllowedMethods: [
                    {
                      CachedMethods: ['<optional1>', '<optional2>'],
                    },
                  ],
                  SmoothStreaming: '<optional>',
                  DefaultTTL: '<optional>',
                  MaxTTL: '<optional>',
                  Compress: '<optional>',
                  LambdaFunctionAssociations: [
                    {
                      Items: [
                        {
                          IncludeBody: '<optional>',
                        },
                      ],
                    },
                  ],
                  FieldLevelEncryptionId: '<optional>',
                  ForwardedValues: [
                    {
                      Headers: [
                        {
                          Items: ['<optional1>', '<optional2>'],
                        },
                      ],
                      QueryStringCacheKeys: [
                        {
                          Items: ['<optional1>', '<optional2>'],
                        },
                      ],
                      Cookies: [
                        {
                          WhitelistedNames: [
                            {
                              Items: ['<optional1>', '<optional2>'],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                  TrustedSigners: [
                    {
                      Items: ['<optional1>', '<optional2>'],
                    },
                  ],
                },
              ],
            },
          ],
          CustomErrorResponses: [
            {
              Items: [
                {
                  ResponsePagePath: '<optional>',
                  ResponseCode: '<optional>',
                  ErrorCachingMinTTL: '<optional>',
                },
              ],
            },
          ],
          Logging: '<optional>',
          PriceClass: '<optional>',
          ViewerCertificate: {
            CloudFrontDefaultCertificate: '<optional>',
            IAMCertificateId: '<optional>',
            ACMCertificateArn: '<optional>',
            SSLSupportMethod: '<optional>',
            MinimumProtocolVersion: '<optional>',
            Certificate: '<optional>',
            CertificateSource: '<optional>',
          },
          Restrictions: [
            {
              GeoRestriction: {
                Items: ['<optional1>', '<optional2>'],
              },
            },
          ],
          WebACLId: '<optional>',
          HttpVersion: '<optional>',
          IsIPV6Enabled: '<optional>',
          Origins: [
            {
              Items: [
                {
                  OriginPath: '<optional>',
                  CustomHeaders: [
                    {
                      Items: ['<optional1>', '<optional2>'],
                    },
                  ],
                  S3OriginConfig: '<optional>',
                  CustomOriginConfig: {
                    OriginSslProtocols: ['<optional1>', '<optional2>'],
                    OriginReadTimeout: '<optional>',
                    OriginKeepaliveTimeout: '<optional>',
                  },
                },
              ],
            },
          ],
          DefaultCacheBehavior: {
            AllowedMethods: [
              {
                CachedMethods: ['<optional1>', '<optional2>'],
              },
            ],
            SmoothStreaming: '<optional>',
            DefaultTTL: '<optional>',
            MaxTTL: '<optional>',
            Compress: '<optional>',
            LambdaFunctionAssociations: [
              {
                Items: [
                  {
                    IncludeBody: '<optional>',
                  },
                ],
              },
            ],
            FieldLevelEncryptionId: '<optional>',
            ForwardedValues: [
              {
                Headers: [
                  {
                    Items: ['<optional1>', '<optional2>'],
                  },
                ],
                QueryStringCacheKeys: [
                  {
                    Items: ['<optional1>', '<optional2>'],
                  },
                ],
                Cookies: [
                  {
                    WhitelistedNames: [
                      {
                        Items: ['<optional1>', '<optional2>'],
                      },
                    ],
                  },
                ],
              },
            ],
            TrustedSigners: [
              {
                Items: ['<optional1>', '<optional2>'],
              },
            ],
          },
        },
        Tags: [
          {
            Items: [
              {
                Value: '<optional>',
              },
            ],
          },
        ],
      },
    ],
  },
  'CloudFront:createFieldLevelEncryptionConfig': {
    FieldLevelEncryptionConfig: {
      Comment: '<optional>',
      QueryArgProfileConfig: {
        QueryArgProfiles: [
          {
            Items: ['<optional1>', '<optional2>'],
          },
        ],
      },
      ContentTypeProfileConfig: {
        ContentTypeProfiles: [
          {
            Items: [
              {
                ProfileId: '<optional>',
              },
            ],
          },
        ],
      },
    },
  },
  'CloudFront:createFieldLevelEncryptionProfile': {
    FieldLevelEncryptionProfileConfig: {
      Comment: '<optional>',
      EncryptionEntities: [
        {
          Items: [
            {
              FieldPatterns: [
                {
                  Items: ['<optional1>', '<optional2>'],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  'CloudFront:createInvalidation': {
    DistributionId: 'required',
    InvalidationBatch: {
      Paths: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'CloudFront:createPublicKey': {
    PublicKeyConfig: {
      Comment: '<optional>',
    },
  },
  'CloudFront:createStreamingDistribution': {
    StreamingDistributionConfig: {
      Aliases: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
      Logging: '<optional>',
      PriceClass: '<optional>',
      TrustedSigners: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'CloudFront:createStreamingDistributionWithTags': {
    StreamingDistributionConfigWithTags: [
      {
        StreamingDistributionConfig: {
          Aliases: [
            {
              Items: ['<optional1>', '<optional2>'],
            },
          ],
          Logging: '<optional>',
          PriceClass: '<optional>',
          TrustedSigners: [
            {
              Items: ['<optional1>', '<optional2>'],
            },
          ],
        },
        Tags: [
          {
            Items: [
              {
                Value: '<optional>',
              },
            ],
          },
        ],
      },
    ],
  },
  'CloudFront:deleteCloudFrontOriginAccessIdentity': {
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:deleteDistribution': {
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:deleteFieldLevelEncryptionConfig': {
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:deleteFieldLevelEncryptionProfile': {
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:deletePublicKey': {
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:deleteServiceLinkedRole': {
    RoleName: 'required',
  },
  'CloudFront:deleteStreamingDistribution': {
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:getCloudFrontOriginAccessIdentity': {
    Id: 'required',
  },
  'CloudFront:getCloudFrontOriginAccessIdentityConfig': {
    Id: 'required',
  },
  'CloudFront:getDistribution': {
    Id: 'required',
  },
  'CloudFront:getDistributionConfig': {
    Id: 'required',
  },
  'CloudFront:getFieldLevelEncryption': {
    Id: 'required',
  },
  'CloudFront:getFieldLevelEncryptionConfig': {
    Id: 'required',
  },
  'CloudFront:getFieldLevelEncryptionProfile': {
    Id: 'required',
  },
  'CloudFront:getFieldLevelEncryptionProfileConfig': {
    Id: 'required',
  },
  'CloudFront:getInvalidation': {
    DistributionId: 'required',
    Id: 'required',
  },
  'CloudFront:getPublicKey': {
    Id: 'required',
  },
  'CloudFront:getPublicKeyConfig': {
    Id: 'required',
  },
  'CloudFront:getStreamingDistribution': {
    Id: 'required',
  },
  'CloudFront:getStreamingDistributionConfig': {
    Id: 'required',
  },
  'CloudFront:listCloudFrontOriginAccessIdentities': {},
  'CloudFront:listDistributions': {},
  'CloudFront:listDistributionsByWebACLId': {
    WebACLId: 'required',
  },
  'CloudFront:listFieldLevelEncryptionConfigs': {},
  'CloudFront:listFieldLevelEncryptionProfiles': {},
  'CloudFront:listInvalidations': {
    DistributionId: 'required',
  },
  'CloudFront:listPublicKeys': {},
  'CloudFront:listStreamingDistributions': {},
  'CloudFront:listTagsForResource': {
    Resource: 'required',
  },
  'CloudFront:tagResource': {
    Resource: 'required',
    Tags: [
      {
        Items: [
          {
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'CloudFront:untagResource': {
    Resource: 'required',
    TagKeys: [
      {
        Items: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'CloudFront:updateCloudFrontOriginAccessIdentity': {
    CloudFrontOriginAccessIdentityConfig: {
      CallerReference: 'required',
      Comment: 'required',
    },
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:updateDistribution': {
    DistributionConfig: {
      Aliases: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
      DefaultRootObject: '<optional>',
      OriginGroups: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
      CacheBehaviors: [
        {
          Items: [
            {
              AllowedMethods: [
                {
                  CachedMethods: ['<optional1>', '<optional2>'],
                },
              ],
              SmoothStreaming: '<optional>',
              DefaultTTL: '<optional>',
              MaxTTL: '<optional>',
              Compress: '<optional>',
              LambdaFunctionAssociations: [
                {
                  Items: [
                    {
                      IncludeBody: '<optional>',
                    },
                  ],
                },
              ],
              FieldLevelEncryptionId: '<optional>',
              ForwardedValues: [
                {
                  Headers: [
                    {
                      Items: ['<optional1>', '<optional2>'],
                    },
                  ],
                  QueryStringCacheKeys: [
                    {
                      Items: ['<optional1>', '<optional2>'],
                    },
                  ],
                  Cookies: [
                    {
                      WhitelistedNames: [
                        {
                          Items: ['<optional1>', '<optional2>'],
                        },
                      ],
                    },
                  ],
                },
              ],
              TrustedSigners: [
                {
                  Items: ['<optional1>', '<optional2>'],
                },
              ],
            },
          ],
        },
      ],
      CustomErrorResponses: [
        {
          Items: [
            {
              ResponsePagePath: '<optional>',
              ResponseCode: '<optional>',
              ErrorCachingMinTTL: '<optional>',
            },
          ],
        },
      ],
      Logging: '<optional>',
      PriceClass: '<optional>',
      ViewerCertificate: {
        CloudFrontDefaultCertificate: '<optional>',
        IAMCertificateId: '<optional>',
        ACMCertificateArn: '<optional>',
        SSLSupportMethod: '<optional>',
        MinimumProtocolVersion: '<optional>',
        Certificate: '<optional>',
        CertificateSource: '<optional>',
      },
      Restrictions: [
        {
          GeoRestriction: {
            Items: ['<optional1>', '<optional2>'],
          },
        },
      ],
      WebACLId: '<optional>',
      HttpVersion: '<optional>',
      IsIPV6Enabled: '<optional>',
      Origins: [
        {
          Items: [
            {
              OriginPath: '<optional>',
              CustomHeaders: [
                {
                  Items: ['<optional1>', '<optional2>'],
                },
              ],
              S3OriginConfig: '<optional>',
              CustomOriginConfig: {
                OriginSslProtocols: ['<optional1>', '<optional2>'],
                OriginReadTimeout: '<optional>',
                OriginKeepaliveTimeout: '<optional>',
              },
            },
          ],
        },
      ],
      DefaultCacheBehavior: {
        AllowedMethods: [
          {
            CachedMethods: ['<optional1>', '<optional2>'],
          },
        ],
        SmoothStreaming: '<optional>',
        DefaultTTL: '<optional>',
        MaxTTL: '<optional>',
        Compress: '<optional>',
        LambdaFunctionAssociations: [
          {
            Items: [
              {
                IncludeBody: '<optional>',
              },
            ],
          },
        ],
        FieldLevelEncryptionId: '<optional>',
        ForwardedValues: [
          {
            Headers: [
              {
                Items: ['<optional1>', '<optional2>'],
              },
            ],
            QueryStringCacheKeys: [
              {
                Items: ['<optional1>', '<optional2>'],
              },
            ],
            Cookies: [
              {
                WhitelistedNames: [
                  {
                    Items: ['<optional1>', '<optional2>'],
                  },
                ],
              },
            ],
          },
        ],
        TrustedSigners: [
          {
            Items: ['<optional1>', '<optional2>'],
          },
        ],
      },
    },
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:updateFieldLevelEncryptionConfig': {
    FieldLevelEncryptionConfig: {
      Comment: '<optional>',
      QueryArgProfileConfig: {
        QueryArgProfiles: [
          {
            Items: ['<optional1>', '<optional2>'],
          },
        ],
      },
      ContentTypeProfileConfig: {
        ContentTypeProfiles: [
          {
            Items: [
              {
                ProfileId: '<optional>',
              },
            ],
          },
        ],
      },
    },
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:updateFieldLevelEncryptionProfile': {
    FieldLevelEncryptionProfileConfig: {
      Comment: '<optional>',
      EncryptionEntities: [
        {
          Items: [
            {
              FieldPatterns: [
                {
                  Items: ['<optional1>', '<optional2>'],
                },
              ],
            },
          ],
        },
      ],
    },
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:updatePublicKey': {
    PublicKeyConfig: {
      Comment: '<optional>',
    },
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudFront:updateStreamingDistribution': {
    StreamingDistributionConfig: {
      Aliases: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
      Logging: '<optional>',
      PriceClass: '<optional>',
      TrustedSigners: [
        {
          Items: ['<optional1>', '<optional2>'],
        },
      ],
    },
    Id: 'required',
    IfMatch: '<optional>',
  },
  'CloudHSM:addTagsToResource': {
    ResourceArn: 'required',
    TagList: {
      Key: 'required',
      Value: 'required',
    },
  },
  'CloudHSM:createHapg': {
    Label: 'required',
  },
  'CloudHSM:createHsm': {
    SubnetId: 'required',
    SshKey: 'required',
    IamRoleArn: 'required',
    SubscriptionType: 'required',
    EniIp: '<optional>',
    ExternalId: '<optional>',
    ClientToken: '<optional>',
    SyslogIp: '<optional>',
  },
  'CloudHSM:createLunaClient': {
    Certificate: 'required',
    Label: '<optional>',
  },
  'CloudHSM:deleteHapg': {
    HapgArn: 'required',
  },
  'CloudHSM:deleteHsm': {
    HsmArn: 'required',
  },
  'CloudHSM:deleteLunaClient': {
    ClientArn: 'required',
  },
  'CloudHSM:describeHapg': {
    HapgArn: 'required',
  },
  'CloudHSM:describeHsm': {
    HsmArn: '<optional>',
    HsmSerialNumber: '<optional>',
  },
  'CloudHSM:describeLunaClient': {
    ClientArn: '<optional>',
    CertificateFingerprint: '<optional>',
  },
  'CloudHSM:getConfig': {
    ClientArn: 'required',
    ClientVersion: 'required',
    HapgList: 'required',
  },
  'CloudHSM:listAvailableZones': {},
  'CloudHSM:listHapgs': {},
  'CloudHSM:listHsms': {},
  'CloudHSM:listLunaClients': {},
  'CloudHSM:listTagsForResource': {
    ResourceArn: 'required',
  },
  'CloudHSM:modifyHapg': {
    HapgArn: 'required',
    Label: '<optional>',
    PartitionSerialList: '<optional>',
  },
  'CloudHSM:modifyHsm': {
    HsmArn: 'required',
    SubnetId: '<optional>',
    EniIp: '<optional>',
    IamRoleArn: '<optional>',
    ExternalId: '<optional>',
    SyslogIp: '<optional>',
  },
  'CloudHSM:modifyLunaClient': {
    ClientArn: 'required',
    Certificate: 'required',
  },
  'CloudHSM:removeTagsFromResource': {
    ResourceArn: 'required',
    TagKeyList: 'required',
  },
  'CloudHSMV2:copyBackupToRegion': {
    DestinationRegion: 'required',
    BackupId: 'required',
  },
  'CloudHSMV2:createCluster': {
    SubnetIds: ['required1', 'required2'],
    HsmType: 'required',
    SourceBackupId: '<optional>',
  },
  'CloudHSMV2:createHsm': {
    ClusterId: 'required',
    AvailabilityZone: 'required',
    IpAddress: '<optional>',
  },
  'CloudHSMV2:deleteBackup': {
    BackupId: 'required',
  },
  'CloudHSMV2:deleteCluster': {
    ClusterId: 'required',
  },
  'CloudHSMV2:deleteHsm': {
    ClusterId: 'required',
    HsmId: '<optional>',
    EniId: '<optional>',
    EniIp: '<optional>',
  },
  'CloudHSMV2:describeBackups': {
    Filters: ['<optional1>', '<optional2>'],
    SortAscending: '<optional>',
  },
  'CloudHSMV2:describeClusters': {
    Filters: ['<optional1>', '<optional2>'],
  },
  'CloudHSMV2:initializeCluster': {
    ClusterId: 'required',
    SignedCert: 'required',
    TrustAnchor: 'required',
  },
  'CloudHSMV2:listTags': {
    ResourceId: 'required',
  },
  'CloudHSMV2:restoreBackup': {
    BackupId: 'required',
  },
  'CloudHSMV2:tagResource': {
    ResourceId: 'required',
    TagList: {
      Key: 'required',
      Value: 'required',
    },
  },
  'CloudHSMV2:untagResource': {
    ResourceId: 'required',
    TagKeyList: 'required',
  },
  'CloudSearch:buildSuggesters': {
    DomainName: 'required',
  },
  'CloudSearch:createDomain': {
    DomainName: 'required',
  },
  'CloudSearch:defineAnalysisScheme': {
    DomainName: 'required',
    AnalysisScheme: {
      AnalysisOptions: [
        {
          Synonyms: ['<optional1>', '<optional2>'],
          Stopwords: ['<optional1>', '<optional2>'],
          StemmingDictionary: '<optional>',
          JapaneseTokenizationDictionary: '<optional>',
          AlgorithmicStemming: '<optional>',
        },
      ],
    },
  },
  'CloudSearch:defineExpression': {
    DomainName: 'required',
    Expression: {
      ExpressionName: 'required',
      ExpressionValue: 'required',
    },
  },
  'CloudSearch:defineIndexField': {
    DomainName: 'required',
    IndexField: {
      IntOptions: [
        {
          DefaultValue: '<optional>',
          SourceField: '<optional>',
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
          SortEnabled: '<optional>',
        },
      ],
      DoubleOptions: [
        {
          DefaultValue: '<optional>',
          SourceField: '<optional>',
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
          SortEnabled: '<optional>',
        },
      ],
      LiteralOptions: [
        {
          DefaultValue: '<optional>',
          SourceField: '<optional>',
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
          SortEnabled: '<optional>',
        },
      ],
      TextOptions: [
        {
          DefaultValue: '<optional>',
          SourceField: '<optional>',
          ReturnEnabled: '<optional>',
          SortEnabled: '<optional>',
          HighlightEnabled: '<optional>',
          AnalysisScheme: '<optional>',
        },
      ],
      DateOptions: [
        {
          DefaultValue: '<optional>',
          SourceField: '<optional>',
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
          SortEnabled: '<optional>',
        },
      ],
      LatLonOptions: [
        {
          DefaultValue: '<optional>',
          SourceField: '<optional>',
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
          SortEnabled: '<optional>',
        },
      ],
      IntArrayOptions: [
        {
          DefaultValue: '<optional>',
          SourceFields: ['<optional1>', '<optional2>'],
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
        },
      ],
      DoubleArrayOptions: [
        {
          DefaultValue: '<optional>',
          SourceFields: ['<optional1>', '<optional2>'],
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
        },
      ],
      LiteralArrayOptions: [
        {
          DefaultValue: '<optional>',
          SourceFields: ['<optional1>', '<optional2>'],
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
        },
      ],
      TextArrayOptions: [
        {
          DefaultValue: '<optional>',
          SourceFields: ['<optional1>', '<optional2>'],
          ReturnEnabled: '<optional>',
          HighlightEnabled: '<optional>',
          AnalysisScheme: '<optional>',
        },
      ],
      DateArrayOptions: [
        {
          DefaultValue: '<optional>',
          SourceFields: ['<optional1>', '<optional2>'],
          FacetEnabled: '<optional>',
          SearchEnabled: '<optional>',
          ReturnEnabled: '<optional>',
        },
      ],
    },
  },
  'CloudSearch:defineSuggester': {
    DomainName: 'required',
    Suggester: {
      DocumentSuggesterOptions: [
        {
          FuzzyMatching: '<optional>',
          SortExpression: '<optional>',
        },
      ],
    },
  },
  'CloudSearch:deleteAnalysisScheme': {
    DomainName: 'required',
    AnalysisSchemeName: 'required',
  },
  'CloudSearch:deleteDomain': {
    DomainName: 'required',
  },
  'CloudSearch:deleteExpression': {
    DomainName: 'required',
    ExpressionName: 'required',
  },
  'CloudSearch:deleteIndexField': {
    DomainName: 'required',
    IndexFieldName: 'required',
  },
  'CloudSearch:deleteSuggester': {
    DomainName: 'required',
    SuggesterName: 'required',
  },
  'CloudSearch:describeAnalysisSchemes': {
    DomainName: 'required',
    AnalysisSchemeNames: ['<optional1>', '<optional2>'],
    Deployed: '<optional>',
  },
  'CloudSearch:describeAvailabilityOptions': {
    DomainName: 'required',
    Deployed: '<optional>',
  },
  'CloudSearch:describeDomainEndpointOptions': {
    DomainName: 'required',
    Deployed: '<optional>',
  },
  'CloudSearch:describeDomains': {
    DomainNames: ['<optional1>', '<optional2>'],
  },
  'CloudSearch:describeExpressions': {
    DomainName: 'required',
    ExpressionNames: ['<optional1>', '<optional2>'],
    Deployed: '<optional>',
  },
  'CloudSearch:describeIndexFields': {
    DomainName: 'required',
    FieldNames: ['<optional1>', '<optional2>'],
    Deployed: '<optional>',
  },
  'CloudSearch:describeScalingParameters': {
    DomainName: 'required',
  },
  'CloudSearch:describeServiceAccessPolicies': {
    DomainName: 'required',
    Deployed: '<optional>',
  },
  'CloudSearch:describeSuggesters': {
    DomainName: 'required',
    SuggesterNames: ['<optional1>', '<optional2>'],
    Deployed: '<optional>',
  },
  'CloudSearch:indexDocuments': {
    DomainName: 'required',
  },
  'CloudSearch:listDomainNames': {},
  'CloudSearch:updateAvailabilityOptions': {
    DomainName: 'required',
    MultiAZ: 'required',
  },
  'CloudSearch:updateDomainEndpointOptions': {
    DomainName: 'required',
    DomainEndpointOptions: [
      {
        EnforceHTTPS: ['<optional1>', '<optional2>'],
        TLSSecurityPolicy: '<optional>',
      },
    ],
  },
  'CloudSearch:updateScalingParameters': {
    DomainName: 'required',
    ScalingParameters: [
      {
        DesiredInstanceType: '<optional>',
        DesiredReplicationCount: '<optional>',
        DesiredPartitionCount: '<optional>',
      },
    ],
  },
  'CloudSearch:updateServiceAccessPolicies': {
    DomainName: 'required',
    AccessPolicies: ['required1', 'required2'],
  },
  'CloudSearchDomain:search': {
    query: 'required',
    cursor: '<optional>',
    expr: '<optional>',
    facet: '<optional>',
    filterQuery: '<optional>',
    highlight: '<optional>',
    partial: '<optional>',
    queryOptions: ['<optional1>', '<optional2>'],
    queryParser: '<optional>',
    return: '<optional>',
    size: '<optional>',
    sort: '<optional>',
    start: '<optional>',
    stats: ['<optional1>', '<optional2>'],
  },
  'CloudSearchDomain:suggest': {
    query: 'required',
    suggester: 'required',
    size: '<optional>',
  },
  'CloudSearchDomain:uploadDocuments': {
    documents: ['required1', 'required2'],
    contentType: 'required',
  },
  'CloudTrail:addTags': {
    ResourceId: 'required',
    TagsList: {
      Value: '<optional>',
    },
  },
  'CloudTrail:createTrail': {
    Name: 'required',
    S3BucketName: 'required',
    S3KeyPrefix: '<optional>',
    SnsTopicName: '<optional>',
    IncludeGlobalServiceEvents: ['<optional1>', '<optional2>'],
    IsMultiRegionTrail: '<optional>',
    EnableLogFileValidation: '<optional>',
    CloudWatchLogsLogGroupArn: '<optional>',
    CloudWatchLogsRoleArn: '<optional>',
    KmsKeyId: '<optional>',
    IsOrganizationTrail: '<optional>',
  },
  'CloudTrail:deleteTrail': {
    Name: 'required',
  },
  'CloudTrail:describeTrails': {
    trailNameList: '<optional>',
    includeShadowTrails: ['<optional1>', '<optional2>'],
  },
  'CloudTrail:getEventSelectors': {
    TrailName: 'required',
  },
  'CloudTrail:getInsightSelectors': {
    TrailName: 'required',
  },
  'CloudTrail:getTrail': {
    Name: 'required',
  },
  'CloudTrail:getTrailStatus': {
    Name: 'required',
  },
  'CloudTrail:listPublicKeys': {
    StartTime: '<optional>',
    EndTime: '<optional>',
  },
  'CloudTrail:listTags': {
    ResourceIdList: 'required',
  },
  'CloudTrail:listTrails': {},
  'CloudTrail:lookupEvents': {
    LookupAttributes: ['<optional1>', '<optional2>'],
    StartTime: '<optional>',
    EndTime: '<optional>',
  },
  'CloudTrail:putEventSelectors': {
    TrailName: 'required',
    EventSelectors: [
      {
        ReadWriteType: '<optional>',
        IncludeManagementEvents: ['<optional1>', '<optional2>'],
        DataResources: [
          {
            Type: '<optional>',
            Values: ['<optional1>', '<optional2>'],
          },
        ],
      },
    ],
  },
  'CloudTrail:putInsightSelectors': {
    TrailName: 'required',
    InsightSelectors: [
      {
        InsightType: '<optional>',
      },
    ],
  },
  'CloudTrail:removeTags': {
    ResourceId: 'required',
    TagsList: {
      Value: '<optional>',
    },
  },
  'CloudTrail:startLogging': {
    Name: 'required',
  },
  'CloudTrail:stopLogging': {
    Name: 'required',
  },
  'CloudTrail:updateTrail': {
    Name: 'required',
    S3BucketName: '<optional>',
    S3KeyPrefix: '<optional>',
    SnsTopicName: '<optional>',
    IncludeGlobalServiceEvents: ['<optional1>', '<optional2>'],
    IsMultiRegionTrail: '<optional>',
    EnableLogFileValidation: '<optional>',
    CloudWatchLogsLogGroupArn: '<optional>',
    CloudWatchLogsRoleArn: '<optional>',
    KmsKeyId: '<optional>',
    IsOrganizationTrail: '<optional>',
  },
  'CloudWatch:deleteAlarms': {
    AlarmNames: ['required1', 'required2'],
  },
  'CloudWatch:deleteAnomalyDetector': {
    Namespace: 'required',
    MetricName: 'required',
    Stat: 'required',
    Dimensions: ['<optional1>', '<optional2>'],
  },
  'CloudWatch:deleteDashboards': {
    DashboardNames: ['required1', 'required2'],
  },
  'CloudWatch:deleteInsightRules': {
    RuleNames: ['required1', 'required2'],
  },
  'CloudWatch:describeAlarmHistory': {
    AlarmName: '<optional>',
    HistoryItemType: '<optional>',
    StartDate: '<optional>',
    EndDate: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'CloudWatch:describeAlarms': {
    AlarmNames: ['<optional1>', '<optional2>'],
    AlarmNamePrefix: '<optional>',
    StateValue: '<optional>',
    ActionPrefix: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'CloudWatch:describeAlarmsForMetric': {
    MetricName: 'required',
    Namespace: 'required',
    Dimensions: ['<optional1>', '<optional2>'],
    Statistic: '<optional>',
    ExtendedStatistic: '<optional>',
    Period: '<optional>',
    Unit: '<optional>',
  },
  'CloudWatch:describeAnomalyDetectors': {
    Dimensions: ['<optional1>', '<optional2>'],
    Namespace: '<optional>',
    MetricName: '<optional>',
  },
  'CloudWatch:describeInsightRules': {},
  'CloudWatch:disableAlarmActions': {
    AlarmNames: ['required1', 'required2'],
  },
  'CloudWatch:disableInsightRules': {
    RuleNames: ['required1', 'required2'],
  },
  'CloudWatch:enableAlarmActions': {
    AlarmNames: ['required1', 'required2'],
  },
  'CloudWatch:enableInsightRules': {
    RuleNames: ['required1', 'required2'],
  },
  'CloudWatch:getDashboard': {
    DashboardName: 'required',
  },
  'CloudWatch:getInsightRuleReport': {
    RuleName: 'required',
    StartTime: 'required',
    EndTime: 'required',
    Period: 'required',
    MaxContributorCount: '<optional>',
    Metrics: ['<optional1>', '<optional2>'],
    OrderBy: '<optional>',
  },
  'CloudWatch:getMetricData': {
    MetricDataQueries: [
      {
        MetricStat: {
          Unit: '<optional>',
          Metric: {
            Namespace: '<optional>',
            MetricName: '<optional>',
            Dimensions: ['<optional1>', '<optional2>'],
          },
        },
        Expression: '<optional>',
        Label: '<optional>',
        ReturnData: ['<optional1>', '<optional2>'],
      },
    ],
    StartTime: 'required',
    EndTime: 'required',
    ScanBy: '<optional>',
    MaxDatapoints: ['<optional1>', '<optional2>'],
  },
  'CloudWatch:getMetricStatistics': {
    Namespace: 'required',
    MetricName: 'required',
    StartTime: 'required',
    EndTime: 'required',
    Period: 'required',
    Dimensions: ['<optional1>', '<optional2>'],
    Statistics: ['<optional1>', '<optional2>'],
    ExtendedStatistics: ['<optional1>', '<optional2>'],
    Unit: '<optional>',
  },
  'CloudWatch:getMetricWidgetImage': {
    MetricWidget: 'required',
    OutputFormat: '<optional>',
  },
  'CloudWatch:listDashboards': {
    DashboardNamePrefix: '<optional>',
  },
  'CloudWatch:listMetrics': {
    Dimensions: [
      {
        Value: '<optional>',
      },
    ],
    Namespace: '<optional>',
    MetricName: '<optional>',
  },
  'CloudWatch:listTagsForResource': {
    ResourceARN: 'required',
  },
  'CloudWatch:putAnomalyDetector': {
    Namespace: 'required',
    MetricName: 'required',
    Stat: 'required',
    Dimensions: ['<optional1>', '<optional2>'],
    Configuration: {
      ExcludedTimeRanges: ['<optional1>', '<optional2>'],
      MetricTimezone: '<optional>',
    },
  },
  'CloudWatch:putDashboard': {
    DashboardName: 'required',
    DashboardBody: 'required',
  },
  'CloudWatch:putInsightRule': {
    RuleName: 'required',
    RuleDefinition: 'required',
    RuleState: '<optional>',
  },
  'CloudWatch:putMetricAlarm': {
    AlarmName: 'required',
    EvaluationPeriods: ['required1', 'required2'],
    ComparisonOperator: 'required',
    Dimensions: ['<optional1>', '<optional2>'],
    Metrics: [
      {
        MetricStat: {
          Unit: '<optional>',
          Metric: {
            Namespace: '<optional>',
            MetricName: '<optional>',
            Dimensions: ['<optional1>', '<optional2>'],
          },
        },
        Expression: '<optional>',
        Label: '<optional>',
        ReturnData: ['<optional1>', '<optional2>'],
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
    AlarmDescription: '<optional>',
    ActionsEnabled: '<optional>',
    OKActions: ['<optional1>', '<optional2>'],
    AlarmActions: ['<optional1>', '<optional2>'],
    InsufficientDataActions: ['<optional1>', '<optional2>'],
    MetricName: '<optional>',
    Namespace: '<optional>',
    Statistic: '<optional>',
    ExtendedStatistic: '<optional>',
    Period: '<optional>',
    Unit: '<optional>',
    DatapointsToAlarm: '<optional>',
    Threshold: '<optional>',
    TreatMissingData: ['<optional1>', '<optional2>'],
    EvaluateLowSampleCountPercentile: '<optional>',
    ThresholdMetricId: '<optional>',
  },
  'CloudWatch:putMetricData': {
    Namespace: 'required',
    MetricData: [
      {
        Dimensions: ['<optional1>', '<optional2>'],
        Timestamp: '<optional>',
        Value: '<optional>',
        StatisticValues: ['<optional1>', '<optional2>'],
        Values: ['<optional1>', '<optional2>'],
        Counts: ['<optional1>', '<optional2>'],
        Unit: '<optional>',
        StorageResolution: '<optional>',
      },
    ],
  },
  'CloudWatch:setAlarmState': {
    AlarmName: 'required',
    StateValue: 'required',
    StateReason: 'required',
    StateReasonData: ['<optional1>', '<optional2>'],
  },
  'CloudWatch:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'CloudWatch:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'CloudWatchEvents:activateEventSource': {
    Name: 'required',
  },
  'CloudWatchEvents:createEventBus': {
    Name: 'required',
    EventSourceName: '<optional>',
  },
  'CloudWatchEvents:createPartnerEventSource': {
    Name: 'required',
    Account: 'required',
  },
  'CloudWatchEvents:deactivateEventSource': {
    Name: 'required',
  },
  'CloudWatchEvents:deleteEventBus': {
    Name: 'required',
  },
  'CloudWatchEvents:deletePartnerEventSource': {
    Name: 'required',
    Account: 'required',
  },
  'CloudWatchEvents:deleteRule': {
    Name: 'required',
    EventBusName: '<optional>',
    Force: '<optional>',
  },
  'CloudWatchEvents:describeEventBus': {
    Name: '<optional>',
  },
  'CloudWatchEvents:describeEventSource': {
    Name: 'required',
  },
  'CloudWatchEvents:describePartnerEventSource': {
    Name: 'required',
  },
  'CloudWatchEvents:describeRule': {
    Name: 'required',
    EventBusName: '<optional>',
  },
  'CloudWatchEvents:disableRule': {
    Name: 'required',
    EventBusName: '<optional>',
  },
  'CloudWatchEvents:enableRule': {
    Name: 'required',
    EventBusName: '<optional>',
  },
  'CloudWatchEvents:listEventBuses': {
    NamePrefix: '<optional>',
    Limit: '<optional>',
  },
  'CloudWatchEvents:listEventSources': {
    NamePrefix: '<optional>',
    Limit: '<optional>',
  },
  'CloudWatchEvents:listPartnerEventSourceAccounts': {
    EventSourceName: 'required',
    Limit: '<optional>',
  },
  'CloudWatchEvents:listPartnerEventSources': {
    NamePrefix: 'required',
    Limit: '<optional>',
  },
  'CloudWatchEvents:listRuleNamesByTarget': {
    TargetArn: 'required',
    EventBusName: '<optional>',
    Limit: '<optional>',
  },
  'CloudWatchEvents:listRules': {
    NamePrefix: '<optional>',
    EventBusName: '<optional>',
    Limit: '<optional>',
  },
  'CloudWatchEvents:listTagsForResource': {
    ResourceARN: 'required',
  },
  'CloudWatchEvents:listTargetsByRule': {
    Rule: 'required',
    EventBusName: '<optional>',
    Limit: '<optional>',
  },
  'CloudWatchEvents:putEvents': {
    Entries: [
      {
        Time: '<optional>',
        Source: '<optional>',
        Resources: ['<optional1>', '<optional2>'],
        DetailType: '<optional>',
        Detail: '<optional>',
        EventBusName: '<optional>',
      },
    ],
  },
  'CloudWatchEvents:putPartnerEvents': {
    Entries: [
      {
        Time: '<optional>',
        Source: '<optional>',
        Resources: ['<optional1>', '<optional2>'],
        DetailType: '<optional>',
        Detail: '<optional>',
      },
    ],
  },
  'CloudWatchEvents:putPermission': {
    Action: 'required',
    Principal: 'required',
    StatementId: 'required',
    Condition: '<optional>',
    EventBusName: '<optional>',
  },
  'CloudWatchEvents:putRule': {
    Name: 'required',
    Tags: ['<optional1>', '<optional2>'],
    ScheduleExpression: '<optional>',
    EventPattern: '<optional>',
    State: '<optional>',
    Description: '<optional>',
    RoleArn: '<optional>',
    EventBusName: '<optional>',
  },
  'CloudWatchEvents:putTargets': {
    Rule: 'required',
    Targets: [
      {
        RoleArn: '<optional>',
        Input: '<optional>',
        InputPath: '<optional>',
        InputTransformer: {
          InputPathsMap: '<optional>',
        },
        KinesisParameters: ['<optional1>', '<optional2>'],
        RunCommandParameters: ['<optional1>', '<optional2>'],
        EcsParameters: [
          {
            TaskCount: '<optional>',
            LaunchType: '<optional>',
            NetworkConfiguration: {
              awsvpcConfiguration: {
                SecurityGroups: ['<optional1>', '<optional2>'],
                AssignPublicIp: '<optional>',
              },
            },
            PlatformVersion: '<optional>',
            Group: '<optional>',
          },
        ],
        BatchParameters: [
          {
            ArrayProperties: [
              {
                Size: '<optional>',
              },
            ],
            RetryStrategy: {
              Attempts: ['<optional1>', '<optional2>'],
            },
          },
        ],
        SqsParameters: [
          {
            MessageGroupId: '<optional>',
          },
        ],
      },
    ],
    EventBusName: '<optional>',
  },
  'CloudWatchEvents:removePermission': {
    StatementId: 'required',
    EventBusName: '<optional>',
  },
  'CloudWatchEvents:removeTargets': {
    Rule: 'required',
    Ids: ['required1', 'required2'],
    EventBusName: '<optional>',
    Force: '<optional>',
  },
  'CloudWatchEvents:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'CloudWatchEvents:testEventPattern': {
    EventPattern: 'required',
    Event: 'required',
  },
  'CloudWatchEvents:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'CloudWatchLogs:associateKmsKey': {
    logGroupName: 'required',
    kmsKeyId: 'required',
  },
  'CloudWatchLogs:cancelExportTask': {
    taskId: 'required',
  },
  'CloudWatchLogs:createExportTask': {
    logGroupName: 'required',
    from: 'required',
    to: 'required',
    destination: 'required',
    taskName: '<optional>',
    logStreamNamePrefix: '<optional>',
    destinationPrefix: '<optional>',
  },
  'CloudWatchLogs:createLogGroup': {
    logGroupName: 'required',
    kmsKeyId: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'CloudWatchLogs:createLogStream': {
    logGroupName: 'required',
    logStreamName: 'required',
  },
  'CloudWatchLogs:deleteDestination': {
    destinationName: 'required',
  },
  'CloudWatchLogs:deleteLogGroup': {
    logGroupName: 'required',
  },
  'CloudWatchLogs:deleteLogStream': {
    logGroupName: 'required',
    logStreamName: 'required',
  },
  'CloudWatchLogs:deleteMetricFilter': {
    logGroupName: 'required',
    filterName: 'required',
  },
  'CloudWatchLogs:deleteResourcePolicy': {
    policyName: '<optional>',
  },
  'CloudWatchLogs:deleteRetentionPolicy': {
    logGroupName: 'required',
  },
  'CloudWatchLogs:deleteSubscriptionFilter': {
    logGroupName: 'required',
    filterName: 'required',
  },
  'CloudWatchLogs:describeDestinations': {
    DestinationNamePrefix: '<optional>',
    limit: '<optional>',
  },
  'CloudWatchLogs:describeExportTasks': {
    taskId: '<optional>',
    statusCode: '<optional>',
    limit: '<optional>',
  },
  'CloudWatchLogs:describeLogGroups': {
    logGroupNamePrefix: '<optional>',
    limit: '<optional>',
  },
  'CloudWatchLogs:describeLogStreams': {
    logGroupName: 'required',
    logStreamNamePrefix: '<optional>',
    orderBy: '<optional>',
    descending: '<optional>',
    limit: '<optional>',
  },
  'CloudWatchLogs:describeMetricFilters': {
    logGroupName: '<optional>',
    filterNamePrefix: '<optional>',
    limit: '<optional>',
    metricName: '<optional>',
    metricNamespace: '<optional>',
  },
  'CloudWatchLogs:describeQueries': {
    logGroupName: '<optional>',
    status: '<optional>',
  },
  'CloudWatchLogs:describeResourcePolicies': {
    limit: '<optional>',
  },
  'CloudWatchLogs:describeSubscriptionFilters': {
    logGroupName: 'required',
    filterNamePrefix: '<optional>',
    limit: '<optional>',
  },
  'CloudWatchLogs:disassociateKmsKey': {
    logGroupName: 'required',
  },
  'CloudWatchLogs:filterLogEvents': {
    logGroupName: 'required',
    logStreamNames: ['<optional1>', '<optional2>'],
    logStreamNamePrefix: '<optional>',
    startTime: '<optional>',
    endTime: '<optional>',
    filterPattern: '<optional>',
    limit: '<optional>',
    interleaved: '<optional>',
  },
  'CloudWatchLogs:getLogEvents': {
    logGroupName: 'required',
    logStreamName: 'required',
    startTime: '<optional>',
    endTime: '<optional>',
    limit: '<optional>',
    startFromHead: '<optional>',
  },
  'CloudWatchLogs:getLogGroupFields': {
    logGroupName: 'required',
    time: '<optional>',
  },
  'CloudWatchLogs:getLogRecord': {
    logRecordPointer: 'required',
  },
  'CloudWatchLogs:getQueryResults': {
    queryId: 'required',
  },
  'CloudWatchLogs:listTagsLogGroup': {
    logGroupName: 'required',
  },
  'CloudWatchLogs:putDestination': {
    destinationName: 'required',
    targetArn: 'required',
    roleArn: 'required',
  },
  'CloudWatchLogs:putDestinationPolicy': {
    destinationName: 'required',
    accessPolicy: 'required',
  },
  'CloudWatchLogs:putLogEvents': {
    logGroupName: 'required',
    logStreamName: 'required',
    logEvents: [
      {
        timestamp: 'required',
        message: 'required',
      },
    ],
    sequenceToken: '<optional>',
  },
  'CloudWatchLogs:putMetricFilter': {
    logGroupName: 'required',
    filterName: 'required',
    filterPattern: 'required',
    metricTransformations: [
      {
        defaultValue: '<optional>',
      },
    ],
  },
  'CloudWatchLogs:putResourcePolicy': {
    policyName: '<optional>',
    policyDocument: '<optional>',
  },
  'CloudWatchLogs:putRetentionPolicy': {
    logGroupName: 'required',
    retentionInDays: ['required1', 'required2'],
  },
  'CloudWatchLogs:putSubscriptionFilter': {
    logGroupName: 'required',
    filterName: 'required',
    filterPattern: 'required',
    destinationArn: 'required',
    roleArn: '<optional>',
    distribution: '<optional>',
  },
  'CloudWatchLogs:startQuery': {
    logGroupName: 'required',
    startTime: 'required',
    endTime: 'required',
    queryString: 'required',
    limit: '<optional>',
  },
  'CloudWatchLogs:stopQuery': {
    queryId: 'required',
  },
  'CloudWatchLogs:tagLogGroup': {
    logGroupName: 'required',
    tags: ['required1', 'required2'],
  },
  'CloudWatchLogs:testMetricFilter': {
    filterPattern: 'required',
    logEventMessages: ['required1', 'required2'],
  },
  'CloudWatchLogs:untagLogGroup': {
    logGroupName: 'required',
    tags: ['required1', 'required2'],
  },
  'CodeBuild:batchDeleteBuilds': {
    ids: ['required1', 'required2'],
  },
  'CodeBuild:batchGetBuilds': {
    ids: ['required1', 'required2'],
  },
  'CodeBuild:batchGetProjects': {
    names: ['required1', 'required2'],
  },
  'CodeBuild:batchGetReportGroups': {
    reportGroupArns: ['required1', 'required2'],
  },
  'CodeBuild:batchGetReports': {
    reportArns: ['required1', 'required2'],
  },
  'CodeBuild:createProject': {
    name: 'required',
    source: {
      location: '<optional>',
      gitCloneDepth: '<optional>',
      gitSubmodulesConfig: '<optional>',
      buildspec: '<optional>',
      auth: {
        resource: '<optional>',
      },
      reportBuildStatus: '<optional>',
      insecureSsl: '<optional>',
      sourceIdentifier: '<optional>',
    },
    artifacts: [
      {
        location: '<optional>',
        path: '<optional>',
        namespaceType: '<optional>',
        name: '<optional>',
        packaging: '<optional>',
        overrideArtifactName: '<optional>',
        encryptionDisabled: '<optional>',
        artifactIdentifier: '<optional>',
      },
    ],
    environment: {
      environmentVariables: [
        {
          type: '<optional>',
        },
      ],
      privilegedMode: '<optional>',
      certificate: '<optional>',
      registryCredential: '<optional>',
      imagePullCredentialsType: '<optional>',
    },
    serviceRole: 'required',
    secondarySources: [
      {
        location: '<optional>',
        gitCloneDepth: '<optional>',
        gitSubmodulesConfig: '<optional>',
        buildspec: '<optional>',
        auth: {
          resource: '<optional>',
        },
        reportBuildStatus: '<optional>',
        insecureSsl: '<optional>',
        sourceIdentifier: '<optional>',
      },
    ],
    secondarySourceVersions: ['<optional1>', '<optional2>'],
    secondaryArtifacts: [
      {
        location: '<optional>',
        path: '<optional>',
        namespaceType: '<optional>',
        name: '<optional>',
        packaging: '<optional>',
        overrideArtifactName: '<optional>',
        encryptionDisabled: '<optional>',
        artifactIdentifier: '<optional>',
      },
    ],
    cache: {
      location: '<optional>',
      modes: ['<optional1>', '<optional2>'],
    },
    logsConfig: {
      cloudWatchLogs: [
        {
          groupName: '<optional>',
          streamName: '<optional>',
        },
      ],
      s3Logs: [
        {
          location: '<optional>',
          encryptionDisabled: '<optional>',
        },
      ],
    },
    description: '<optional>',
    sourceVersion: '<optional>',
    timeoutInMinutes: ['<optional1>', '<optional2>'],
    queuedTimeoutInMinutes: ['<optional1>', '<optional2>'],
    encryptionKey: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    vpcConfig: {
      vpcId: '<optional>',
      subnets: ['<optional1>', '<optional2>'],
      securityGroupIds: ['<optional1>', '<optional2>'],
    },
    badgeEnabled: '<optional>',
  },
  'CodeBuild:createReportGroup': {
    name: 'required',
    type: 'required',
    exportConfig: {
      exportConfigType: '<optional>',
      s3Destination: {
        bucket: '<optional>',
        path: '<optional>',
        packaging: '<optional>',
        encryptionKey: '<optional>',
        encryptionDisabled: '<optional>',
      },
    },
  },
  'CodeBuild:createWebhook': {
    projectName: 'required',
    filterGroups: [
      {
        excludeMatchedPattern: '<optional>',
      },
    ],
    branchFilter: '<optional>',
  },
  'CodeBuild:deleteProject': {
    name: 'required',
  },
  'CodeBuild:deleteReport': {
    arn: 'required',
  },
  'CodeBuild:deleteReportGroup': {
    arn: 'required',
  },
  'CodeBuild:deleteResourcePolicy': {
    resourceArn: 'required',
  },
  'CodeBuild:deleteSourceCredentials': {
    arn: 'required',
  },
  'CodeBuild:deleteWebhook': {
    projectName: 'required',
  },
  'CodeBuild:describeTestCases': {
    reportArn: 'required',
    filter: {
      status: '<optional>',
    },
  },
  'CodeBuild:getResourcePolicy': {
    resourceArn: 'required',
  },
  'CodeBuild:importSourceCredentials': {
    token: 'required',
    serverType: 'required',
    authType: 'required',
    username: '<optional>',
  },
  'CodeBuild:invalidateProjectCache': {
    projectName: 'required',
  },
  'CodeBuild:listBuilds': {
    sortOrder: '<optional>',
  },
  'CodeBuild:listBuildsForProject': {
    projectName: 'required',
    sortOrder: '<optional>',
  },
  'CodeBuild:listCuratedEnvironmentImages': {},
  'CodeBuild:listProjects': {
    sortBy: '<optional>',
    sortOrder: '<optional>',
  },
  'CodeBuild:listReportGroups': {
    sortOrder: '<optional>',
    sortBy: '<optional>',
  },
  'CodeBuild:listReports': {
    sortOrder: '<optional>',
    filter: {
      status: '<optional>',
    },
  },
  'CodeBuild:listReportsForReportGroup': {
    reportGroupArn: 'required',
    sortOrder: '<optional>',
    filter: {
      status: '<optional>',
    },
  },
  'CodeBuild:listSharedProjects': {
    sortBy: '<optional>',
    sortOrder: '<optional>',
  },
  'CodeBuild:listSharedReportGroups': {
    sortOrder: '<optional>',
    sortBy: '<optional>',
  },
  'CodeBuild:listSourceCredentials': {},
  'CodeBuild:putResourcePolicy': {
    policy: 'required',
    resourceArn: 'required',
  },
  'CodeBuild:startBuild': {
    projectName: 'required',
    secondarySourcesOverride: {
      location: '<optional>',
      gitCloneDepth: '<optional>',
      gitSubmodulesConfig: '<optional>',
      buildspec: '<optional>',
      auth: {
        resource: '<optional>',
      },
      reportBuildStatus: '<optional>',
      insecureSsl: '<optional>',
      sourceIdentifier: '<optional>',
    },
    secondarySourcesVersionOverride: '<optional>',
    artifactsOverride: {
      location: '<optional>',
      path: '<optional>',
      namespaceType: '<optional>',
      name: '<optional>',
      packaging: '<optional>',
      overrideArtifactName: '<optional>',
      encryptionDisabled: '<optional>',
      artifactIdentifier: '<optional>',
    },
    secondaryArtifactsOverride: {
      location: '<optional>',
      path: '<optional>',
      namespaceType: '<optional>',
      name: '<optional>',
      packaging: '<optional>',
      overrideArtifactName: '<optional>',
      encryptionDisabled: '<optional>',
      artifactIdentifier: '<optional>',
    },
    environmentVariablesOverride: {
      type: '<optional>',
    },
    sourceAuthOverride: {
      resource: '<optional>',
    },
    gitSubmodulesConfigOverride: '<optional>',
    cacheOverride: {
      location: '<optional>',
      modes: ['<optional1>', '<optional2>'],
    },
    logsConfigOverride: {
      cloudWatchLogs: [
        {
          groupName: '<optional>',
          streamName: '<optional>',
        },
      ],
      s3Logs: [
        {
          location: '<optional>',
          encryptionDisabled: '<optional>',
        },
      ],
    },
    registryCredentialOverride: '<optional>',
    sourceVersion: '<optional>',
    sourceTypeOverride: '<optional>',
    sourceLocationOverride: '<optional>',
    gitCloneDepthOverride: '<optional>',
    buildspecOverride: '<optional>',
    insecureSslOverride: '<optional>',
    reportBuildStatusOverride: '<optional>',
    environmentTypeOverride: '<optional>',
    imageOverride: '<optional>',
    computeTypeOverride: '<optional>',
    certificateOverride: '<optional>',
    serviceRoleOverride: '<optional>',
    privilegedModeOverride: '<optional>',
    timeoutInMinutesOverride: '<optional>',
    queuedTimeoutInMinutesOverride: '<optional>',
    idempotencyToken: '<optional>',
    imagePullCredentialsTypeOverride: '<optional>',
  },
  'CodeBuild:stopBuild': {
    id: 'required',
  },
  'CodeBuild:updateProject': {
    name: 'required',
    source: {
      location: '<optional>',
      gitCloneDepth: '<optional>',
      gitSubmodulesConfig: '<optional>',
      buildspec: '<optional>',
      auth: {
        resource: '<optional>',
      },
      reportBuildStatus: '<optional>',
      insecureSsl: '<optional>',
      sourceIdentifier: '<optional>',
    },
    secondarySources: [
      {
        location: '<optional>',
        gitCloneDepth: '<optional>',
        gitSubmodulesConfig: '<optional>',
        buildspec: '<optional>',
        auth: {
          resource: '<optional>',
        },
        reportBuildStatus: '<optional>',
        insecureSsl: '<optional>',
        sourceIdentifier: '<optional>',
      },
    ],
    secondarySourceVersions: ['<optional1>', '<optional2>'],
    artifacts: [
      {
        location: '<optional>',
        path: '<optional>',
        namespaceType: '<optional>',
        name: '<optional>',
        packaging: '<optional>',
        overrideArtifactName: '<optional>',
        encryptionDisabled: '<optional>',
        artifactIdentifier: '<optional>',
      },
    ],
    secondaryArtifacts: [
      {
        location: '<optional>',
        path: '<optional>',
        namespaceType: '<optional>',
        name: '<optional>',
        packaging: '<optional>',
        overrideArtifactName: '<optional>',
        encryptionDisabled: '<optional>',
        artifactIdentifier: '<optional>',
      },
    ],
    cache: {
      location: '<optional>',
      modes: ['<optional1>', '<optional2>'],
    },
    environment: {
      environmentVariables: [
        {
          type: '<optional>',
        },
      ],
      privilegedMode: '<optional>',
      certificate: '<optional>',
      registryCredential: '<optional>',
      imagePullCredentialsType: '<optional>',
    },
    logsConfig: {
      cloudWatchLogs: [
        {
          groupName: '<optional>',
          streamName: '<optional>',
        },
      ],
      s3Logs: [
        {
          location: '<optional>',
          encryptionDisabled: '<optional>',
        },
      ],
    },
    description: '<optional>',
    sourceVersion: '<optional>',
    serviceRole: '<optional>',
    timeoutInMinutes: ['<optional1>', '<optional2>'],
    queuedTimeoutInMinutes: ['<optional1>', '<optional2>'],
    encryptionKey: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    vpcConfig: {
      vpcId: '<optional>',
      subnets: ['<optional1>', '<optional2>'],
      securityGroupIds: ['<optional1>', '<optional2>'],
    },
    badgeEnabled: '<optional>',
  },
  'CodeBuild:updateReportGroup': {
    arn: 'required',
    exportConfig: {
      exportConfigType: '<optional>',
      s3Destination: {
        bucket: '<optional>',
        path: '<optional>',
        packaging: '<optional>',
        encryptionKey: '<optional>',
        encryptionDisabled: '<optional>',
      },
    },
  },
  'CodeBuild:updateWebhook': {
    projectName: 'required',
    filterGroups: [
      {
        excludeMatchedPattern: '<optional>',
      },
    ],
    branchFilter: '<optional>',
    rotateSecret: '<optional>',
  },
  'CodeCommit:associateApprovalRuleTemplateWithRepository': {
    approvalRuleTemplateName: 'required',
    repositoryName: 'required',
  },
  'CodeCommit:batchAssociateApprovalRuleTemplateWithRepositories': {
    approvalRuleTemplateName: 'required',
    repositoryNames: ['required1', 'required2'],
  },
  'CodeCommit:batchDescribeMergeConflicts': {
    repositoryName: 'required',
    destinationCommitSpecifier: 'required',
    sourceCommitSpecifier: 'required',
    mergeOption: 'required',
    maxMergeHunks: ['<optional1>', '<optional2>'],
    maxConflictFiles: ['<optional1>', '<optional2>'],
    filePaths: ['<optional1>', '<optional2>'],
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
  },
  'CodeCommit:batchDisassociateApprovalRuleTemplateFromRepositories': {
    approvalRuleTemplateName: 'required',
    repositoryNames: ['required1', 'required2'],
  },
  'CodeCommit:batchGetCommits': {
    commitIds: ['required1', 'required2'],
    repositoryName: 'required',
  },
  'CodeCommit:batchGetRepositories': {
    repositoryNames: ['required1', 'required2'],
  },
  'CodeCommit:createApprovalRuleTemplate': {
    approvalRuleTemplateName: 'required',
    approvalRuleTemplateContent: 'required',
    approvalRuleTemplateDescription: '<optional>',
  },
  'CodeCommit:createBranch': {
    repositoryName: 'required',
    branchName: 'required',
    commitId: 'required',
  },
  'CodeCommit:createCommit': {
    repositoryName: 'required',
    branchName: 'required',
    putFiles: [
      {
        fileMode: '<optional>',
        fileContent: '<optional>',
        sourceFile: {
          isMove: '<optional>',
        },
      },
    ],
    deleteFiles: ['<optional1>', '<optional2>'],
    setFileModes: ['<optional1>', '<optional2>'],
    parentCommitId: '<optional>',
    authorName: '<optional>',
    email: '<optional>',
    commitMessage: '<optional>',
    keepEmptyFolders: ['<optional1>', '<optional2>'],
  },
  'CodeCommit:createPullRequest': {
    title: 'required',
    targets: [
      {
        destinationReference: '<optional>',
      },
    ],
    description: '<optional>',
    clientRequestToken: '<optional>',
  },
  'CodeCommit:createPullRequestApprovalRule': {
    pullRequestId: 'required',
    approvalRuleName: 'required',
    approvalRuleContent: 'required',
  },
  'CodeCommit:createRepository': {
    repositoryName: 'required',
    repositoryDescription: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'CodeCommit:createUnreferencedMergeCommit': {
    repositoryName: 'required',
    sourceCommitSpecifier: 'required',
    destinationCommitSpecifier: 'required',
    mergeOption: 'required',
    conflictResolution: {
      replaceContents: [
        {
          content: '<optional>',
          fileMode: '<optional>',
        },
      ],
      deleteFiles: ['<optional1>', '<optional2>'],
      setFileModes: ['<optional1>', '<optional2>'],
    },
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
    authorName: '<optional>',
    email: '<optional>',
    commitMessage: '<optional>',
    keepEmptyFolders: ['<optional1>', '<optional2>'],
  },
  'CodeCommit:deleteApprovalRuleTemplate': {
    approvalRuleTemplateName: 'required',
  },
  'CodeCommit:deleteBranch': {
    repositoryName: 'required',
    branchName: 'required',
  },
  'CodeCommit:deleteCommentContent': {
    commentId: 'required',
  },
  'CodeCommit:deleteFile': {
    repositoryName: 'required',
    branchName: 'required',
    filePath: 'required',
    parentCommitId: 'required',
    keepEmptyFolders: ['<optional1>', '<optional2>'],
    commitMessage: '<optional>',
    name: '<optional>',
    email: '<optional>',
  },
  'CodeCommit:deletePullRequestApprovalRule': {
    pullRequestId: 'required',
    approvalRuleName: 'required',
  },
  'CodeCommit:deleteRepository': {
    repositoryName: 'required',
  },
  'CodeCommit:describeMergeConflicts': {
    repositoryName: 'required',
    destinationCommitSpecifier: 'required',
    sourceCommitSpecifier: 'required',
    mergeOption: 'required',
    filePath: 'required',
    maxMergeHunks: ['<optional1>', '<optional2>'],
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
  },
  'CodeCommit:describePullRequestEvents': {
    pullRequestId: 'required',
    pullRequestEventType: '<optional>',
    actorArn: '<optional>',
  },
  'CodeCommit:disassociateApprovalRuleTemplateFromRepository': {
    approvalRuleTemplateName: 'required',
    repositoryName: 'required',
  },
  'CodeCommit:evaluatePullRequestApprovalRules': {
    pullRequestId: 'required',
    revisionId: 'required',
  },
  'CodeCommit:getApprovalRuleTemplate': {
    approvalRuleTemplateName: 'required',
  },
  'CodeCommit:getBlob': {
    repositoryName: 'required',
    blobId: 'required',
  },
  'CodeCommit:getBranch': {
    repositoryName: '<optional>',
    branchName: '<optional>',
  },
  'CodeCommit:getComment': {
    commentId: 'required',
  },
  'CodeCommit:getCommentsForComparedCommit': {
    repositoryName: 'required',
    afterCommitId: 'required',
    beforeCommitId: '<optional>',
  },
  'CodeCommit:getCommentsForPullRequest': {
    pullRequestId: 'required',
    repositoryName: '<optional>',
    beforeCommitId: '<optional>',
    afterCommitId: '<optional>',
  },
  'CodeCommit:getCommit': {
    repositoryName: 'required',
    commitId: 'required',
  },
  'CodeCommit:getDifferences': {
    repositoryName: 'required',
    afterCommitSpecifier: 'required',
    beforeCommitSpecifier: '<optional>',
    beforePath: '<optional>',
    afterPath: '<optional>',
  },
  'CodeCommit:getFile': {
    repositoryName: 'required',
    filePath: 'required',
    commitSpecifier: '<optional>',
  },
  'CodeCommit:getFolder': {
    repositoryName: 'required',
    folderPath: 'required',
    commitSpecifier: '<optional>',
  },
  'CodeCommit:getMergeCommit': {
    repositoryName: 'required',
    sourceCommitSpecifier: 'required',
    destinationCommitSpecifier: 'required',
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
  },
  'CodeCommit:getMergeConflicts': {
    repositoryName: 'required',
    destinationCommitSpecifier: 'required',
    sourceCommitSpecifier: 'required',
    mergeOption: 'required',
    conflictDetailLevel: '<optional>',
    maxConflictFiles: ['<optional1>', '<optional2>'],
    conflictResolutionStrategy: '<optional>',
  },
  'CodeCommit:getMergeOptions': {
    repositoryName: 'required',
    sourceCommitSpecifier: 'required',
    destinationCommitSpecifier: 'required',
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
  },
  'CodeCommit:getPullRequest': {
    pullRequestId: 'required',
  },
  'CodeCommit:getPullRequestApprovalStates': {
    pullRequestId: 'required',
    revisionId: 'required',
  },
  'CodeCommit:getPullRequestOverrideState': {
    pullRequestId: 'required',
    revisionId: 'required',
  },
  'CodeCommit:getRepository': {
    repositoryName: 'required',
  },
  'CodeCommit:getRepositoryTriggers': {
    repositoryName: 'required',
  },
  'CodeCommit:listApprovalRuleTemplates': {},
  'CodeCommit:listAssociatedApprovalRuleTemplatesForRepository': {
    repositoryName: 'required',
  },
  'CodeCommit:listBranches': {
    repositoryName: 'required',
  },
  'CodeCommit:listPullRequests': {
    repositoryName: 'required',
    authorArn: '<optional>',
    pullRequestStatus: '<optional>',
  },
  'CodeCommit:listRepositories': {
    sortBy: '<optional>',
    order: '<optional>',
  },
  'CodeCommit:listRepositoriesForApprovalRuleTemplate': {
    approvalRuleTemplateName: 'required',
  },
  'CodeCommit:listTagsForResource': {
    resourceArn: 'required',
  },
  'CodeCommit:mergeBranchesByFastForward': {
    repositoryName: 'required',
    sourceCommitSpecifier: 'required',
    destinationCommitSpecifier: 'required',
    targetBranch: '<optional>',
  },
  'CodeCommit:mergeBranchesBySquash': {
    repositoryName: 'required',
    sourceCommitSpecifier: 'required',
    destinationCommitSpecifier: 'required',
    conflictResolution: {
      replaceContents: [
        {
          content: '<optional>',
          fileMode: '<optional>',
        },
      ],
      deleteFiles: ['<optional1>', '<optional2>'],
      setFileModes: ['<optional1>', '<optional2>'],
    },
    targetBranch: '<optional>',
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
    authorName: '<optional>',
    email: '<optional>',
    commitMessage: '<optional>',
    keepEmptyFolders: ['<optional1>', '<optional2>'],
  },
  'CodeCommit:mergeBranchesByThreeWay': {
    repositoryName: 'required',
    sourceCommitSpecifier: 'required',
    destinationCommitSpecifier: 'required',
    conflictResolution: {
      replaceContents: [
        {
          content: '<optional>',
          fileMode: '<optional>',
        },
      ],
      deleteFiles: ['<optional1>', '<optional2>'],
      setFileModes: ['<optional1>', '<optional2>'],
    },
    targetBranch: '<optional>',
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
    authorName: '<optional>',
    email: '<optional>',
    commitMessage: '<optional>',
    keepEmptyFolders: ['<optional1>', '<optional2>'],
  },
  'CodeCommit:mergePullRequestByFastForward': {
    pullRequestId: 'required',
    repositoryName: 'required',
    sourceCommitId: '<optional>',
  },
  'CodeCommit:mergePullRequestBySquash': {
    pullRequestId: 'required',
    repositoryName: 'required',
    conflictResolution: {
      replaceContents: [
        {
          content: '<optional>',
          fileMode: '<optional>',
        },
      ],
      deleteFiles: ['<optional1>', '<optional2>'],
      setFileModes: ['<optional1>', '<optional2>'],
    },
    sourceCommitId: '<optional>',
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
    commitMessage: '<optional>',
    authorName: '<optional>',
    email: '<optional>',
    keepEmptyFolders: ['<optional1>', '<optional2>'],
  },
  'CodeCommit:mergePullRequestByThreeWay': {
    pullRequestId: 'required',
    repositoryName: 'required',
    conflictResolution: {
      replaceContents: [
        {
          content: '<optional>',
          fileMode: '<optional>',
        },
      ],
      deleteFiles: ['<optional1>', '<optional2>'],
      setFileModes: ['<optional1>', '<optional2>'],
    },
    sourceCommitId: '<optional>',
    conflictDetailLevel: '<optional>',
    conflictResolutionStrategy: '<optional>',
    commitMessage: '<optional>',
    authorName: '<optional>',
    email: '<optional>',
    keepEmptyFolders: ['<optional1>', '<optional2>'],
  },
  'CodeCommit:overridePullRequestApprovalRules': {
    pullRequestId: 'required',
    revisionId: 'required',
    overrideStatus: 'required',
  },
  'CodeCommit:postCommentForComparedCommit': {
    repositoryName: 'required',
    afterCommitId: 'required',
    content: 'required',
    beforeCommitId: '<optional>',
    location: {
      filePath: '<optional>',
      filePosition: '<optional>',
      relativeFileVersion: '<optional>',
    },
    clientRequestToken: '<optional>',
  },
  'CodeCommit:postCommentForPullRequest': {
    pullRequestId: 'required',
    repositoryName: 'required',
    beforeCommitId: 'required',
    afterCommitId: 'required',
    content: 'required',
    location: {
      filePath: '<optional>',
      filePosition: '<optional>',
      relativeFileVersion: '<optional>',
    },
    clientRequestToken: '<optional>',
  },
  'CodeCommit:postCommentReply': {
    inReplyTo: 'required',
    content: 'required',
    clientRequestToken: '<optional>',
  },
  'CodeCommit:putFile': {
    repositoryName: 'required',
    branchName: 'required',
    fileContent: 'required',
    filePath: 'required',
    fileMode: '<optional>',
    parentCommitId: '<optional>',
    commitMessage: '<optional>',
    name: '<optional>',
    email: '<optional>',
  },
  'CodeCommit:putRepositoryTriggers': {
    repositoryName: 'required',
    triggers: [
      {
        customData: ['<optional1>', '<optional2>'],
        branches: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'CodeCommit:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'CodeCommit:testRepositoryTriggers': {
    repositoryName: 'required',
    triggers: [
      {
        customData: ['<optional1>', '<optional2>'],
        branches: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'CodeCommit:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'CodeCommit:updateApprovalRuleTemplateContent': {
    approvalRuleTemplateName: 'required',
    newRuleContent: 'required',
    existingRuleContentSha256: '<optional>',
  },
  'CodeCommit:updateApprovalRuleTemplateDescription': {
    approvalRuleTemplateName: 'required',
    approvalRuleTemplateDescription: 'required',
  },
  'CodeCommit:updateApprovalRuleTemplateName': {
    oldApprovalRuleTemplateName: 'required',
    newApprovalRuleTemplateName: 'required',
  },
  'CodeCommit:updateComment': {
    commentId: 'required',
    content: 'required',
  },
  'CodeCommit:updateDefaultBranch': {
    repositoryName: 'required',
    defaultBranchName: 'required',
  },
  'CodeCommit:updatePullRequestApprovalRuleContent': {
    pullRequestId: 'required',
    approvalRuleName: 'required',
    newRuleContent: 'required',
    existingRuleContentSha256: '<optional>',
  },
  'CodeCommit:updatePullRequestApprovalState': {
    pullRequestId: 'required',
    revisionId: 'required',
    approvalState: 'required',
  },
  'CodeCommit:updatePullRequestDescription': {
    pullRequestId: 'required',
    description: 'required',
  },
  'CodeCommit:updatePullRequestStatus': {
    pullRequestId: 'required',
    pullRequestStatus: 'required',
  },
  'CodeCommit:updatePullRequestTitle': {
    pullRequestId: 'required',
    title: 'required',
  },
  'CodeCommit:updateRepositoryDescription': {
    repositoryName: 'required',
    repositoryDescription: '<optional>',
  },
  'CodeCommit:updateRepositoryName': {
    oldName: 'required',
    newName: 'required',
  },
  'CodeDeploy:addTagsToOnPremisesInstances': {
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    instanceNames: ['required1', 'required2'],
  },
  'CodeDeploy:batchGetApplicationRevisions': {
    applicationName: 'required',
    revisions: [
      {
        revisionType: '<optional>',
        s3Location: {
          bucket: '<optional>',
          key: '<optional>',
          bundleType: '<optional>',
          version: '<optional>',
          eTag: '<optional>',
        },
        gitHubLocation: {
          repository: '<optional>',
          commitId: '<optional>',
        },
        string: {
          content: '<optional>',
          sha256: '<optional>',
        },
        appSpecContent: {
          content: '<optional>',
          sha256: '<optional>',
        },
      },
    ],
  },
  'CodeDeploy:batchGetApplications': {
    applicationNames: ['required1', 'required2'],
  },
  'CodeDeploy:batchGetDeploymentGroups': {
    applicationName: 'required',
    deploymentGroupNames: ['required1', 'required2'],
  },
  'CodeDeploy:batchGetDeploymentInstances': {
    deploymentId: 'required',
    instanceIds: ['required1', 'required2'],
  },
  'CodeDeploy:batchGetDeploymentTargets': {
    deploymentId: '<optional>',
    targetIds: ['<optional1>', '<optional2>'],
  },
  'CodeDeploy:batchGetDeployments': {
    deploymentIds: ['required1', 'required2'],
  },
  'CodeDeploy:batchGetOnPremisesInstances': {
    instanceNames: ['required1', 'required2'],
  },
  'CodeDeploy:continueDeployment': {
    deploymentId: '<optional>',
    deploymentWaitType: '<optional>',
  },
  'CodeDeploy:createApplication': {
    applicationName: 'required',
    computePlatform: '<optional>',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'CodeDeploy:createDeployment': {
    applicationName: 'required',
    deploymentGroupName: '<optional>',
    revision: {
      revisionType: '<optional>',
      s3Location: {
        bucket: '<optional>',
        key: '<optional>',
        bundleType: '<optional>',
        version: '<optional>',
        eTag: '<optional>',
      },
      gitHubLocation: {
        repository: '<optional>',
        commitId: '<optional>',
      },
      string: {
        content: '<optional>',
        sha256: '<optional>',
      },
      appSpecContent: {
        content: '<optional>',
        sha256: '<optional>',
      },
    },
    deploymentConfigName: '<optional>',
    description: '<optional>',
    ignoreApplicationStopFailures: ['<optional1>', '<optional2>'],
    targetInstances: [
      {
        tagFilters: [
          {
            Key: '<optional>',
            Value: '<optional>',
            Type: '<optional>',
          },
        ],
        autoScalingGroups: ['<optional1>', '<optional2>'],
        ec2TagSet: {
          ec2TagSetList: {
            Key: '<optional>',
            Value: '<optional>',
            Type: '<optional>',
          },
        },
      },
    ],
    autoRollbackConfiguration: {
      enabled: '<optional>',
      eventIds: ['<optional1>', '<optional2>'],
    },
    updateOutdatedInstancesOnly: '<optional>',
    fileExistsBehavior: '<optional>',
  },
  'CodeDeploy:createDeploymentConfig': {
    deploymentConfigName: 'required',
    minimumHealthyHosts: [
      {
        value: '<optional>',
        type: '<optional>',
      },
    ],
    trafficRoutingConfig: {
      type: '<optional>',
      timeBasedCanary: {
        canaryPercentage: '<optional>',
        canaryInterval: '<optional>',
      },
      timeBasedLinear: {
        linearPercentage: '<optional>',
        linearInterval: '<optional>',
      },
    },
    computePlatform: '<optional>',
  },
  'CodeDeploy:createDeploymentGroup': {
    applicationName: 'required',
    deploymentGroupName: 'required',
    serviceRoleArn: 'required',
    deploymentConfigName: '<optional>',
    ec2TagFilters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    ],
    onPremisesInstanceTagFilters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    ],
    autoScalingGroups: ['<optional1>', '<optional2>'],
    triggerConfigurations: [
      {
        triggerName: '<optional>',
        triggerTargetArn: '<optional>',
        triggerEvents: ['<optional1>', '<optional2>'],
      },
    ],
    alarmConfiguration: {
      enabled: '<optional>',
      ignorePollAlarmFailure: '<optional>',
      alarms: [
        {
          name: '<optional>',
        },
      ],
    },
    autoRollbackConfiguration: {
      enabled: '<optional>',
      eventIds: ['<optional1>', '<optional2>'],
    },
    deploymentStyle: {
      deploymentType: '<optional>',
      deploymentOption: '<optional>',
    },
    blueGreenDeploymentConfiguration: {
      terminateBlueInstancesOnDeploymentSuccess: {
        action: '<optional>',
        terminationWaitTimeInMinutes: ['<optional1>', '<optional2>'],
      },
      deploymentReadyOption: {
        actionOnTimeout: '<optional>',
        waitTimeInMinutes: ['<optional1>', '<optional2>'],
      },
      greenFleetProvisioningOption: {
        action: '<optional>',
      },
    },
    loadBalancerInfo: {
      elbInfoList: {
        name: '<optional>',
      },
      targetGroupInfoList: {
        name: '<optional>',
      },
      targetGroupPairInfoList: {
        targetGroups: [
          {
            name: '<optional>',
          },
        ],
        prodTrafficRoute: {
          listenerArns: ['<optional1>', '<optional2>'],
        },
        testTrafficRoute: {
          listenerArns: ['<optional1>', '<optional2>'],
        },
      },
    },
    ec2TagSet: {
      ec2TagSetList: {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    },
    ecsServices: [
      {
        serviceName: '<optional>',
        clusterName: '<optional>',
      },
    ],
    onPremisesTagSet: {
      onPremisesTagSetList: {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    },
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'CodeDeploy:deleteApplication': {
    applicationName: 'required',
  },
  'CodeDeploy:deleteDeploymentConfig': {
    deploymentConfigName: 'required',
  },
  'CodeDeploy:deleteDeploymentGroup': {
    applicationName: 'required',
    deploymentGroupName: 'required',
  },
  'CodeDeploy:deleteGitHubAccountToken': {
    tokenName: '<optional>',
  },
  'CodeDeploy:deregisterOnPremisesInstance': {
    instanceName: 'required',
  },
  'CodeDeploy:getApplication': {
    applicationName: 'required',
  },
  'CodeDeploy:getApplicationRevision': {
    applicationName: 'required',
    revision: {
      revisionType: '<optional>',
      s3Location: {
        bucket: '<optional>',
        key: '<optional>',
        bundleType: '<optional>',
        version: '<optional>',
        eTag: '<optional>',
      },
      gitHubLocation: {
        repository: '<optional>',
        commitId: '<optional>',
      },
      string: {
        content: '<optional>',
        sha256: '<optional>',
      },
      appSpecContent: {
        content: '<optional>',
        sha256: '<optional>',
      },
    },
  },
  'CodeDeploy:getDeployment': {
    deploymentId: 'required',
  },
  'CodeDeploy:getDeploymentConfig': {
    deploymentConfigName: 'required',
  },
  'CodeDeploy:getDeploymentGroup': {
    applicationName: 'required',
    deploymentGroupName: 'required',
  },
  'CodeDeploy:getDeploymentInstance': {
    deploymentId: 'required',
    instanceId: 'required',
  },
  'CodeDeploy:getDeploymentTarget': {
    deploymentId: '<optional>',
    targetId: '<optional>',
  },
  'CodeDeploy:getOnPremisesInstance': {
    instanceName: 'required',
  },
  'CodeDeploy:listApplicationRevisions': {
    applicationName: 'required',
    sortBy: '<optional>',
    sortOrder: '<optional>',
    s3Bucket: '<optional>',
    s3KeyPrefix: '<optional>',
    deployed: '<optional>',
  },
  'CodeDeploy:listApplications': {},
  'CodeDeploy:listDeploymentConfigs': {},
  'CodeDeploy:listDeploymentGroups': {
    applicationName: 'required',
  },
  'CodeDeploy:listDeploymentInstances': {
    deploymentId: 'required',
    instanceStatusFilter: '<optional>',
    instanceTypeFilter: '<optional>',
  },
  'CodeDeploy:listDeploymentTargets': {
    deploymentId: '<optional>',
    targetFilters: ['<optional1>', '<optional2>'],
  },
  'CodeDeploy:listDeployments': {
    applicationName: '<optional>',
    deploymentGroupName: '<optional>',
    includeOnlyStatuses: ['<optional1>', '<optional2>'],
    createTimeRange: {
      start: '<optional>',
      end: '<optional>',
    },
  },
  'CodeDeploy:listGitHubAccountTokenNames': {},
  'CodeDeploy:listOnPremisesInstances': {
    registrationStatus: '<optional>',
    tagFilters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    ],
  },
  'CodeDeploy:listTagsForResource': {
    ResourceArn: 'required',
  },
  'CodeDeploy:putLifecycleEventHookExecutionStatus': {
    deploymentId: '<optional>',
    lifecycleEventHookExecutionId: '<optional>',
    status: '<optional>',
  },
  'CodeDeploy:registerApplicationRevision': {
    applicationName: 'required',
    revision: {
      revisionType: '<optional>',
      s3Location: {
        bucket: '<optional>',
        key: '<optional>',
        bundleType: '<optional>',
        version: '<optional>',
        eTag: '<optional>',
      },
      gitHubLocation: {
        repository: '<optional>',
        commitId: '<optional>',
      },
      string: {
        content: '<optional>',
        sha256: '<optional>',
      },
      appSpecContent: {
        content: '<optional>',
        sha256: '<optional>',
      },
    },
    description: '<optional>',
  },
  'CodeDeploy:registerOnPremisesInstance': {
    instanceName: 'required',
    iamSessionArn: '<optional>',
    iamUserArn: '<optional>',
  },
  'CodeDeploy:removeTagsFromOnPremisesInstances': {
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    instanceNames: ['required1', 'required2'],
  },
  'CodeDeploy:skipWaitTimeForInstanceTermination': {
    deploymentId: '<optional>',
  },
  'CodeDeploy:stopDeployment': {
    deploymentId: 'required',
    autoRollbackEnabled: '<optional>',
  },
  'CodeDeploy:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'CodeDeploy:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'CodeDeploy:updateApplication': {
    applicationName: '<optional>',
    newApplicationName: '<optional>',
  },
  'CodeDeploy:updateDeploymentGroup': {
    applicationName: 'required',
    currentDeploymentGroupName: 'required',
    newDeploymentGroupName: '<optional>',
    deploymentConfigName: '<optional>',
    ec2TagFilters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    ],
    onPremisesInstanceTagFilters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    ],
    autoScalingGroups: ['<optional1>', '<optional2>'],
    serviceRoleArn: '<optional>',
    triggerConfigurations: [
      {
        triggerName: '<optional>',
        triggerTargetArn: '<optional>',
        triggerEvents: ['<optional1>', '<optional2>'],
      },
    ],
    alarmConfiguration: {
      enabled: '<optional>',
      ignorePollAlarmFailure: '<optional>',
      alarms: [
        {
          name: '<optional>',
        },
      ],
    },
    autoRollbackConfiguration: {
      enabled: '<optional>',
      eventIds: ['<optional1>', '<optional2>'],
    },
    deploymentStyle: {
      deploymentType: '<optional>',
      deploymentOption: '<optional>',
    },
    blueGreenDeploymentConfiguration: {
      terminateBlueInstancesOnDeploymentSuccess: {
        action: '<optional>',
        terminationWaitTimeInMinutes: ['<optional1>', '<optional2>'],
      },
      deploymentReadyOption: {
        actionOnTimeout: '<optional>',
        waitTimeInMinutes: ['<optional1>', '<optional2>'],
      },
      greenFleetProvisioningOption: {
        action: '<optional>',
      },
    },
    loadBalancerInfo: {
      elbInfoList: {
        name: '<optional>',
      },
      targetGroupInfoList: {
        name: '<optional>',
      },
      targetGroupPairInfoList: {
        targetGroups: [
          {
            name: '<optional>',
          },
        ],
        prodTrafficRoute: {
          listenerArns: ['<optional1>', '<optional2>'],
        },
        testTrafficRoute: {
          listenerArns: ['<optional1>', '<optional2>'],
        },
      },
    },
    ec2TagSet: {
      ec2TagSetList: {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    },
    ecsServices: [
      {
        serviceName: '<optional>',
        clusterName: '<optional>',
      },
    ],
    onPremisesTagSet: {
      onPremisesTagSetList: {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    },
  },
  'CodeGuruProfiler:configureAgent': {
    profilingGroupName: 'required',
    fleetInstanceId: '<optional>',
  },
  'CodeGuruProfiler:createProfilingGroup': {
    clientToken: 'required',
    profilingGroupName: 'required',
    agentOrchestrationConfig: '<optional>',
  },
  'CodeGuruProfiler:deleteProfilingGroup': {
    profilingGroupName: 'required',
  },
  'CodeGuruProfiler:describeProfilingGroup': {
    profilingGroupName: 'required',
  },
  'CodeGuruProfiler:getProfile': {
    profilingGroupName: 'required',
    accept: '<optional>',
    endTime: '<optional>',
    maxDepth: '<optional>',
    period: '<optional>',
    startTime: '<optional>',
  },
  'CodeGuruProfiler:listProfileTimes': {
    endTime: 'required',
    period: 'required',
    profilingGroupName: 'required',
    startTime: 'required',
    orderBy: '<optional>',
  },
  'CodeGuruProfiler:listProfilingGroups': {
    includeDescription: '<optional>',
  },
  'CodeGuruProfiler:postAgentProfile': {
    agentProfile: 'required',
    contentType: 'required',
    profilingGroupName: 'required',
    profileToken: '<optional>',
  },
  'CodeGuruProfiler:updateProfilingGroup': {
    agentOrchestrationConfig: {
      profilingEnabled: 'required',
    },
    profilingGroupName: 'required',
  },
  'CodeGuruReviewer:associateRepository': {
    Repository: {
      CodeCommit: '<optional>',
    },
    ClientRequestToken: '<optional>',
  },
  'CodeGuruReviewer:describeRepositoryAssociation': {
    AssociationArn: 'required',
  },
  'CodeGuruReviewer:disassociateRepository': {
    AssociationArn: 'required',
  },
  'CodeGuruReviewer:listRepositoryAssociations': {
    ProviderTypes: ['<optional1>', '<optional2>'],
    States: ['<optional1>', '<optional2>'],
    Names: ['<optional1>', '<optional2>'],
    Owners: ['<optional1>', '<optional2>'],
  },
  'CodePipeline:acknowledgeJob': {
    jobId: 'required',
    nonce: 'required',
  },
  'CodePipeline:acknowledgeThirdPartyJob': {
    jobId: 'required',
    nonce: 'required',
    clientToken: 'required',
  },
  'CodePipeline:createCustomActionType': {
    category: 'required',
    provider: 'required',
    version: 'required',
    inputArtifactDetails: [
      {
        minimumCount: 'required',
        maximumCount: 'required',
      },
    ],
    outputArtifactDetails: [
      {
        minimumCount: 'required',
        maximumCount: 'required',
      },
    ],
    configurationProperties: [
      {
        queryable: '<optional>',
        description: '<optional>',
        type: '<optional>',
      },
    ],
    tags: ['<optional1>', '<optional2>'],
    settings: [
      {
        thirdPartyConfigurationUrl: '<optional>',
        entityUrlTemplate: '<optional>',
        executionUrlTemplate: '<optional>',
        revisionUrlTemplate: '<optional>',
      },
    ],
  },
  'CodePipeline:createPipeline': {
    pipeline: {
      artifactStore: {
        encryptionKey: '<optional>',
      },
      artifactStores: ['<optional1>', '<optional2>'],
      version: '<optional>',
      stages: [
        {
          blockers: ['<optional1>', '<optional2>'],
          actions: [
            {
              runOrder: '<optional>',
              configuration: '<optional>',
              outputArtifacts: ['<optional1>', '<optional2>'],
              inputArtifacts: ['<optional1>', '<optional2>'],
              roleArn: '<optional>',
              region: '<optional>',
            },
          ],
        },
      ],
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'CodePipeline:deleteCustomActionType': {
    category: 'required',
    provider: 'required',
    version: 'required',
  },
  'CodePipeline:deletePipeline': {
    name: 'required',
  },
  'CodePipeline:deleteWebhook': {
    name: 'required',
  },
  'CodePipeline:deregisterWebhookWithThirdParty': {
    webhookName: '<optional>',
  },
  'CodePipeline:disableStageTransition': {
    pipelineName: 'required',
    stageName: 'required',
    transitionType: 'required',
    reason: 'required',
  },
  'CodePipeline:enableStageTransition': {
    pipelineName: 'required',
    stageName: 'required',
    transitionType: 'required',
  },
  'CodePipeline:getJobDetails': {
    jobId: 'required',
  },
  'CodePipeline:getPipeline': {
    name: 'required',
    version: '<optional>',
  },
  'CodePipeline:getPipelineExecution': {
    pipelineName: 'required',
    pipelineExecutionId: 'required',
  },
  'CodePipeline:getPipelineState': {
    name: 'required',
  },
  'CodePipeline:getThirdPartyJobDetails': {
    jobId: 'required',
    clientToken: 'required',
  },
  'CodePipeline:listActionExecutions': {
    pipelineName: 'required',
    filter: {
      pipelineExecutionId: '<optional>',
    },
  },
  'CodePipeline:listActionTypes': {
    actionOwnerFilter: '<optional>',
  },
  'CodePipeline:listPipelineExecutions': {
    pipelineName: 'required',
  },
  'CodePipeline:listPipelines': {},
  'CodePipeline:listTagsForResource': {
    resourceArn: 'required',
  },
  'CodePipeline:listWebhooks': {},
  'CodePipeline:pollForJobs': {
    actionTypeId: {
      category: 'required',
      owner: 'required',
      provider: 'required',
      version: 'required',
    },
    maxBatchSize: '<optional>',
    queryParam: '<optional>',
  },
  'CodePipeline:pollForThirdPartyJobs': {
    actionTypeId: {
      category: 'required',
      owner: 'required',
      provider: 'required',
      version: 'required',
    },
    maxBatchSize: '<optional>',
  },
  'CodePipeline:putActionRevision': {
    pipelineName: 'required',
    stageName: 'required',
    actionName: 'required',
    actionRevision: {
      revisionId: 'required',
      revisionChangeId: 'required',
      created: 'required',
    },
  },
  'CodePipeline:putApprovalResult': {
    pipelineName: 'required',
    stageName: 'required',
    actionName: 'required',
    result: {
      summary: 'required',
      status: 'required',
    },
    token: 'required',
  },
  'CodePipeline:putJobFailureResult': {
    jobId: 'required',
    failureDetails: [
      {
        externalExecutionId: '<optional>',
      },
    ],
  },
  'CodePipeline:putJobSuccessResult': {
    jobId: 'required',
    currentRevision: {
      created: '<optional>',
      revisionSummary: '<optional>',
    },
    continuationToken: '<optional>',
    executionDetails: [
      {
        summary: '<optional>',
        externalExecutionId: '<optional>',
        percentComplete: '<optional>',
      },
    ],
  },
  'CodePipeline:putThirdPartyJobFailureResult': {
    jobId: 'required',
    clientToken: 'required',
    failureDetails: [
      {
        externalExecutionId: '<optional>',
      },
    ],
  },
  'CodePipeline:putThirdPartyJobSuccessResult': {
    jobId: 'required',
    clientToken: 'required',
    currentRevision: {
      created: '<optional>',
      revisionSummary: '<optional>',
    },
    continuationToken: '<optional>',
    executionDetails: [
      {
        summary: '<optional>',
        externalExecutionId: '<optional>',
        percentComplete: '<optional>',
      },
    ],
  },
  'CodePipeline:putWebhook': {
    webhook: {
      filters: [
        {
          matchEquals: ['<optional1>', '<optional2>'],
        },
      ],
      authenticationConfiguration: {
        AllowedIPRange: '<optional>',
        SecretToken: '<optional>',
      },
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'CodePipeline:registerWebhookWithThirdParty': {
    webhookName: '<optional>',
  },
  'CodePipeline:retryStageExecution': {
    pipelineName: 'required',
    stageName: 'required',
    pipelineExecutionId: 'required',
    retryMode: 'required',
  },
  'CodePipeline:startPipelineExecution': {
    name: 'required',
    clientRequestToken: '<optional>',
  },
  'CodePipeline:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        key: 'required',
        value: 'required',
      },
    ],
  },
  'CodePipeline:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'CodePipeline:updatePipeline': {
    pipeline: {
      artifactStore: {
        encryptionKey: '<optional>',
      },
      artifactStores: ['<optional1>', '<optional2>'],
      version: '<optional>',
      stages: [
        {
          blockers: ['<optional1>', '<optional2>'],
          actions: [
            {
              runOrder: '<optional>',
              configuration: '<optional>',
              outputArtifacts: ['<optional1>', '<optional2>'],
              inputArtifacts: ['<optional1>', '<optional2>'],
              roleArn: '<optional>',
              region: '<optional>',
            },
          ],
        },
      ],
    },
  },
  'CodeStar:associateTeamMember': {
    projectId: 'required',
    userArn: 'required',
    projectRole: 'required',
    clientRequestToken: '<optional>',
    remoteAccessAllowed: '<optional>',
  },
  'CodeStar:createProject': {
    name: 'required',
    id: 'required',
    sourceCode: {
      source: {
        s3: {
          bucketName: '<optional>',
          bucketKey: '<optional>',
        },
      },
      destination: {
        codeCommit: '<optional>',
        gitHub: {
          description: '<optional>',
        },
      },
    },
    toolchain: {
      roleArn: '<optional>',
      stackParameters: ['<optional1>', '<optional2>'],
      source: {
        s3: {
          bucketName: '<optional>',
          bucketKey: '<optional>',
        },
      },
    },
    description: '<optional>',
    clientRequestToken: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'CodeStar:createUserProfile': {
    userArn: 'required',
    displayName: 'required',
    emailAddress: 'required',
    sshPublicKey: '<optional>',
  },
  'CodeStar:deleteProject': {
    id: 'required',
    clientRequestToken: '<optional>',
    deleteStack: '<optional>',
  },
  'CodeStar:deleteUserProfile': {
    userArn: 'required',
  },
  'CodeStar:describeProject': {
    id: 'required',
  },
  'CodeStar:describeUserProfile': {
    userArn: 'required',
  },
  'CodeStar:disassociateTeamMember': {
    projectId: 'required',
    userArn: 'required',
  },
  'CodeStar:listProjects': {},
  'CodeStar:listResources': {
    projectId: 'required',
  },
  'CodeStar:listTagsForProject': {
    id: 'required',
  },
  'CodeStar:listTeamMembers': {
    projectId: 'required',
  },
  'CodeStar:listUserProfiles': {},
  'CodeStar:tagProject': {
    id: 'required',
    tags: ['required1', 'required2'],
  },
  'CodeStar:untagProject': {
    id: 'required',
    tags: ['required1', 'required2'],
  },
  'CodeStar:updateProject': {
    id: 'required',
    name: '<optional>',
    description: '<optional>',
  },
  'CodeStar:updateTeamMember': {
    projectId: 'required',
    userArn: 'required',
    projectRole: '<optional>',
    remoteAccessAllowed: '<optional>',
  },
  'CodeStar:updateUserProfile': {
    userArn: 'required',
    displayName: '<optional>',
    emailAddress: '<optional>',
    sshPublicKey: '<optional>',
  },
  'CodeStarNotifications:createNotificationRule': {
    Name: 'required',
    EventTypeIds: ['required1', 'required2'],
    Resource: 'required',
    Targets: [
      {
        TargetType: '<optional>',
        TargetAddress: '<optional>',
      },
    ],
    DetailType: 'required',
    ClientRequestToken: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
    Status: '<optional>',
  },
  'CodeStarNotifications:deleteNotificationRule': {
    Arn: 'required',
  },
  'CodeStarNotifications:deleteTarget': {
    TargetAddress: 'required',
    ForceUnsubscribeAll: '<optional>',
  },
  'CodeStarNotifications:describeNotificationRule': {
    Arn: 'required',
  },
  'CodeStarNotifications:listEventTypes': {
    Filters: ['<optional1>', '<optional2>'],
  },
  'CodeStarNotifications:listNotificationRules': {
    Filters: ['<optional1>', '<optional2>'],
  },
  'CodeStarNotifications:listTagsForResource': {
    Arn: 'required',
  },
  'CodeStarNotifications:listTargets': {
    Filters: ['<optional1>', '<optional2>'],
  },
  'CodeStarNotifications:subscribe': {
    Arn: 'required',
    Target: {
      TargetType: '<optional>',
      TargetAddress: '<optional>',
    },
    ClientRequestToken: '<optional>',
  },
  'CodeStarNotifications:tagResource': {
    Arn: 'required',
    Tags: ['required1', 'required2'],
  },
  'CodeStarNotifications:unsubscribe': {
    Arn: 'required',
    TargetAddress: 'required',
  },
  'CodeStarNotifications:untagResource': {
    Arn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'CodeStarNotifications:updateNotificationRule': {
    Arn: 'required',
    Name: '<optional>',
    Status: '<optional>',
    EventTypeIds: ['<optional1>', '<optional2>'],
    Targets: [
      {
        TargetType: '<optional>',
        TargetAddress: '<optional>',
      },
    ],
    DetailType: '<optional>',
  },
  'CodeStarconnections:createConnection': {
    ProviderType: 'required',
    ConnectionName: 'required',
  },
  'CodeStarconnections:deleteConnection': {
    ConnectionArn: 'required',
  },
  'CodeStarconnections:getConnection': {
    ConnectionArn: 'required',
  },
  'CodeStarconnections:listConnections': {
    ProviderTypeFilter: '<optional>',
  },
  'CognitoIdentity:createIdentityPool': {
    IdentityPoolName: 'required',
    AllowUnauthenticatedIdentities: ['required1', 'required2'],
    SupportedLoginProviders: ['<optional1>', '<optional2>'],
    DeveloperProviderName: '<optional>',
    OpenIdConnectProviderARNs: ['<optional1>', '<optional2>'],
    CognitoIdentityProviders: [
      {
        ProviderName: '<optional>',
        ClientId: '<optional>',
        ServerSideTokenCheck: '<optional>',
      },
    ],
    SamlProviderARNs: ['<optional1>', '<optional2>'],
    IdentityPoolTags: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentity:deleteIdentities': {
    IdentityIdsToDelete: 'required',
  },
  'CognitoIdentity:deleteIdentityPool': {
    IdentityPoolId: 'required',
  },
  'CognitoIdentity:describeIdentity': {
    IdentityId: 'required',
  },
  'CognitoIdentity:describeIdentityPool': {
    IdentityPoolId: 'required',
  },
  'CognitoIdentity:getCredentialsForIdentity': {
    IdentityId: 'required',
    Logins: ['<optional1>', '<optional2>'],
    CustomRoleArn: '<optional>',
  },
  'CognitoIdentity:getId': {
    IdentityPoolId: 'required',
    AccountId: '<optional>',
    Logins: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentity:getIdentityPoolRoles': {
    IdentityPoolId: 'required',
  },
  'CognitoIdentity:getOpenIdToken': {
    IdentityId: 'required',
    Logins: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentity:getOpenIdTokenForDeveloperIdentity': {
    IdentityPoolId: 'required',
    Logins: ['required1', 'required2'],
    IdentityId: '<optional>',
    TokenDuration: '<optional>',
  },
  'CognitoIdentity:listIdentities': {
    IdentityPoolId: 'required',
    HideDisabled: '<optional>',
  },
  'CognitoIdentity:listIdentityPools': {},
  'CognitoIdentity:listTagsForResource': {
    ResourceArn: 'required',
  },
  'CognitoIdentity:lookupDeveloperIdentity': {
    IdentityPoolId: 'required',
    IdentityId: '<optional>',
    DeveloperUserIdentifier: '<optional>',
  },
  'CognitoIdentity:mergeDeveloperIdentities': {
    SourceUserIdentifier: 'required',
    DestinationUserIdentifier: 'required',
    DeveloperProviderName: 'required',
    IdentityPoolId: 'required',
  },
  'CognitoIdentity:setIdentityPoolRoles': {
    IdentityPoolId: 'required',
    Roles: ['required1', 'required2'],
    RoleMappings: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentity:tagResource': {
    ResourceArn: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentity:unlinkDeveloperIdentity': {
    IdentityId: 'required',
    IdentityPoolId: 'required',
    DeveloperProviderName: 'required',
    DeveloperUserIdentifier: 'required',
  },
  'CognitoIdentity:unlinkIdentity': {
    IdentityId: 'required',
    Logins: ['required1', 'required2'],
    LoginsToRemove: 'required',
  },
  'CognitoIdentity:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentity:updateIdentityPool': {
    IdentityPoolId: 'required',
    IdentityPoolName: 'required',
    AllowUnauthenticatedIdentities: ['required1', 'required2'],
    SupportedLoginProviders: ['<optional1>', '<optional2>'],
    DeveloperProviderName: '<optional>',
    OpenIdConnectProviderARNs: ['<optional1>', '<optional2>'],
    CognitoIdentityProviders: [
      {
        ProviderName: '<optional>',
        ClientId: '<optional>',
        ServerSideTokenCheck: '<optional>',
      },
    ],
    SamlProviderARNs: ['<optional1>', '<optional2>'],
    IdentityPoolTags: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentityServiceProvider:addCustomAttributes': {
    UserPoolId: 'required',
    CustomAttributes: [
      {
        Name: '<optional>',
        AttributeDataType: '<optional>',
        DeveloperOnlyAttribute: '<optional>',
        Mutable: '<optional>',
        Required: '<optional>',
        NumberAttributeConstraints: [
          {
            MinValue: '<optional>',
            MaxValue: '<optional>',
          },
        ],
        StringAttributeConstraints: [
          {
            MinLength: '<optional>',
            MaxLength: '<optional>',
          },
        ],
      },
    ],
  },
  'CognitoIdentityServiceProvider:adminAddUserToGroup': {
    UserPoolId: 'required',
    Username: 'required',
    GroupName: 'required',
  },
  'CognitoIdentityServiceProvider:adminConfirmSignUp': {
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:adminCreateUser': {
    UserPoolId: 'required',
    Username: 'required',
    UserAttributes: [
      {
        Value: '<optional>',
      },
    ],
    ValidationData: [
      {
        Value: '<optional>',
      },
    ],
    TemporaryPassword: '<optional>',
    ForceAliasCreation: '<optional>',
    MessageAction: '<optional>',
    DesiredDeliveryMediums: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentityServiceProvider:adminDeleteUser': {
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:adminDeleteUserAttributes': {
    UserPoolId: 'required',
    Username: 'required',
    UserAttributeNames: ['required1', 'required2'],
  },
  'CognitoIdentityServiceProvider:adminDisableProviderForUser': {
    UserPoolId: 'required',
    User: {
      ProviderName: '<optional>',
      ProviderAttributeName: '<optional>',
      ProviderAttributeValue: '<optional>',
    },
  },
  'CognitoIdentityServiceProvider:adminDisableUser': {
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:adminEnableUser': {
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:adminForgetDevice': {
    UserPoolId: 'required',
    Username: 'required',
    DeviceKey: 'required',
  },
  'CognitoIdentityServiceProvider:adminGetDevice': {
    DeviceKey: 'required',
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:adminGetUser': {
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:adminInitiateAuth': {
    UserPoolId: 'required',
    ClientId: 'required',
    AuthFlow: 'required',
    ContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
        HttpHeaders: [
          {
            headerName: '<optional>',
            headerValue: '<optional>',
          },
        ],
      },
    ],
    AuthParameters: ['<optional1>', '<optional2>'],
    ClientMetadata: ['<optional1>', '<optional2>'],
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:adminLinkProviderForUser': {
    UserPoolId: 'required',
    DestinationUser: {
      ProviderName: '<optional>',
      ProviderAttributeName: '<optional>',
      ProviderAttributeValue: '<optional>',
    },
    SourceUser: {
      ProviderName: '<optional>',
      ProviderAttributeName: '<optional>',
      ProviderAttributeValue: '<optional>',
    },
  },
  'CognitoIdentityServiceProvider:adminListDevices': {
    UserPoolId: 'required',
    Username: 'required',
    Limit: '<optional>',
    PaginationToken: '<optional>',
  },
  'CognitoIdentityServiceProvider:adminListGroupsForUser': {
    Username: 'required',
    UserPoolId: 'required',
    Limit: '<optional>',
  },
  'CognitoIdentityServiceProvider:adminListUserAuthEvents': {
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:adminRemoveUserFromGroup': {
    UserPoolId: 'required',
    Username: 'required',
    GroupName: 'required',
  },
  'CognitoIdentityServiceProvider:adminResetUserPassword': {
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:adminRespondToAuthChallenge': {
    UserPoolId: 'required',
    ClientId: 'required',
    ChallengeName: 'required',
    ContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
        HttpHeaders: [
          {
            headerName: '<optional>',
            headerValue: '<optional>',
          },
        ],
      },
    ],
    ChallengeResponses: ['<optional1>', '<optional2>'],
    Session: '<optional>',
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:adminSetUserMFAPreference': {
    Username: 'required',
    UserPoolId: 'required',
    SMSMfaSettings: [
      {
        Enabled: '<optional>',
        PreferredMfa: '<optional>',
      },
    ],
    SoftwareTokenMfaSettings: [
      {
        Enabled: '<optional>',
        PreferredMfa: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:adminSetUserPassword': {
    UserPoolId: 'required',
    Username: 'required',
    Password: 'required',
    Permanent: '<optional>',
  },
  'CognitoIdentityServiceProvider:adminSetUserSettings': {
    UserPoolId: 'required',
    Username: 'required',
    MFAOptions: [
      {
        DeliveryMedium: '<optional>',
        AttributeName: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:adminUpdateAuthEventFeedback': {
    UserPoolId: 'required',
    Username: 'required',
    EventId: 'required',
    FeedbackValue: 'required',
  },
  'CognitoIdentityServiceProvider:adminUpdateDeviceStatus': {
    UserPoolId: 'required',
    Username: 'required',
    DeviceKey: 'required',
    DeviceRememberedStatus: '<optional>',
  },
  'CognitoIdentityServiceProvider:adminUpdateUserAttributes': {
    UserPoolId: 'required',
    Username: 'required',
    UserAttributes: [
      {
        Value: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:adminUserGlobalSignOut': {
    UserPoolId: 'required',
    Username: 'required',
  },
  'CognitoIdentityServiceProvider:associateSoftwareToken': {
    AccessToken: '<optional>',
    Session: '<optional>',
  },
  'CognitoIdentityServiceProvider:changePassword': {
    PreviousPassword: 'required',
    ProposedPassword: 'required',
    AccessToken: 'required',
  },
  'CognitoIdentityServiceProvider:confirmDevice': {
    AccessToken: 'required',
    DeviceKey: 'required',
    DeviceSecretVerifierConfig: {
      PasswordVerifier: '<optional>',
      Salt: '<optional>',
    },
    DeviceName: '<optional>',
  },
  'CognitoIdentityServiceProvider:confirmForgotPassword': {
    ClientId: 'required',
    Username: 'required',
    ConfirmationCode: 'required',
    Password: 'required',
    SecretHash: '<optional>',
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
    UserContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'CognitoIdentityServiceProvider:confirmSignUp': {
    ClientId: 'required',
    Username: 'required',
    ConfirmationCode: 'required',
    SecretHash: '<optional>',
    ForceAliasCreation: '<optional>',
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
    UserContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'CognitoIdentityServiceProvider:createGroup': {
    GroupName: 'required',
    UserPoolId: 'required',
    Description: '<optional>',
    RoleArn: '<optional>',
    Precedence: '<optional>',
  },
  'CognitoIdentityServiceProvider:createIdentityProvider': {
    UserPoolId: 'required',
    ProviderName: 'required',
    ProviderType: 'required',
    ProviderDetails: ['required1', 'required2'],
    AttributeMapping: '<optional>',
    IdpIdentifiers: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentityServiceProvider:createResourceServer': {
    UserPoolId: 'required',
    Identifier: 'required',
    Name: 'required',
    Scopes: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentityServiceProvider:createUserImportJob': {
    JobName: 'required',
    UserPoolId: 'required',
    CloudWatchLogsRoleArn: 'required',
  },
  'CognitoIdentityServiceProvider:createUserPool': {
    PoolName: 'required',
    SmsConfiguration: {
      ExternalId: '<optional>',
    },
    UserPoolAddOns: ['<optional1>', '<optional2>'],
    Policies: [
      {
        PasswordPolicy: {
          MinimumLength: '<optional>',
          RequireUppercase: '<optional>',
          RequireLowercase: '<optional>',
          RequireNumbers: ['<optional1>', '<optional2>'],
          RequireSymbols: ['<optional1>', '<optional2>'],
          TemporaryPasswordValidityDays: ['<optional1>', '<optional2>'],
        },
      },
    ],
    LambdaConfig: {
      PreSignUp: '<optional>',
      CustomMessage: '<optional>',
      PostConfirmation: '<optional>',
      PreAuthentication: '<optional>',
      PostAuthentication: '<optional>',
      DefineAuthChallenge: '<optional>',
      CreateAuthChallenge: '<optional>',
      VerifyAuthChallengeResponse: '<optional>',
      PreTokenGeneration: '<optional>',
      UserMigration: '<optional>',
    },
    AutoVerifiedAttributes: ['<optional1>', '<optional2>'],
    AliasAttributes: ['<optional1>', '<optional2>'],
    UsernameAttributes: ['<optional1>', '<optional2>'],
    SmsVerificationMessage: '<optional>',
    EmailVerificationMessage: '<optional>',
    EmailVerificationSubject: '<optional>',
    VerificationMessageTemplate: {
      SmsMessage: '<optional>',
      EmailMessage: '<optional>',
      EmailSubject: '<optional>',
      EmailMessageByLink: '<optional>',
      EmailSubjectByLink: '<optional>',
      DefaultEmailOption: '<optional>',
    },
    SmsAuthenticationMessage: '<optional>',
    MfaConfiguration: '<optional>',
    DeviceConfiguration: {
      ChallengeRequiredOnNewDevice: '<optional>',
      DeviceOnlyRememberedOnUserPrompt: '<optional>',
    },
    EmailConfiguration: {
      SourceArn: '<optional>',
      ReplyToEmailAddress: '<optional>',
      EmailSendingAccount: '<optional>',
    },
    UserPoolTags: ['<optional1>', '<optional2>'],
    AdminCreateUserConfig: {
      AllowAdminCreateUserOnly: '<optional>',
      UnusedAccountValidityDays: ['<optional1>', '<optional2>'],
      InviteMessageTemplate: {
        SMSMessage: '<optional>',
        EmailMessage: '<optional>',
        EmailSubject: '<optional>',
      },
    },
    Schema: {
      Name: '<optional>',
      AttributeDataType: '<optional>',
      DeveloperOnlyAttribute: '<optional>',
      Mutable: '<optional>',
      Required: '<optional>',
      NumberAttributeConstraints: [
        {
          MinValue: '<optional>',
          MaxValue: '<optional>',
        },
      ],
      StringAttributeConstraints: [
        {
          MinLength: '<optional>',
          MaxLength: '<optional>',
        },
      ],
    },
  },
  'CognitoIdentityServiceProvider:createUserPoolClient': {
    UserPoolId: 'required',
    ClientName: 'required',
    AnalyticsConfiguration: {
      UserDataShared: '<optional>',
    },
    GenerateSecret: '<optional>',
    RefreshTokenValidity: '<optional>',
    ReadAttributes: ['<optional1>', '<optional2>'],
    WriteAttributes: ['<optional1>', '<optional2>'],
    ExplicitAuthFlows: ['<optional1>', '<optional2>'],
    SupportedIdentityProviders: ['<optional1>', '<optional2>'],
    CallbackURLs: ['<optional1>', '<optional2>'],
    LogoutURLs: ['<optional1>', '<optional2>'],
    DefaultRedirectURI: '<optional>',
    AllowedOAuthFlows: ['<optional1>', '<optional2>'],
    AllowedOAuthScopes: ['<optional1>', '<optional2>'],
    AllowedOAuthFlowsUserPoolClient: '<optional>',
  },
  'CognitoIdentityServiceProvider:createUserPoolDomain': {
    Domain: 'required',
    UserPoolId: 'required',
    CustomDomainConfig: '<optional>',
  },
  'CognitoIdentityServiceProvider:deleteGroup': {
    GroupName: 'required',
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:deleteIdentityProvider': {
    UserPoolId: 'required',
    ProviderName: 'required',
  },
  'CognitoIdentityServiceProvider:deleteResourceServer': {
    UserPoolId: 'required',
    Identifier: 'required',
  },
  'CognitoIdentityServiceProvider:deleteUser': {
    AccessToken: 'required',
  },
  'CognitoIdentityServiceProvider:deleteUserAttributes': {
    UserAttributeNames: ['required1', 'required2'],
    AccessToken: 'required',
  },
  'CognitoIdentityServiceProvider:deleteUserPool': {
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:deleteUserPoolClient': {
    UserPoolId: 'required',
    ClientId: 'required',
  },
  'CognitoIdentityServiceProvider:deleteUserPoolDomain': {
    Domain: 'required',
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:describeIdentityProvider': {
    UserPoolId: 'required',
    ProviderName: 'required',
  },
  'CognitoIdentityServiceProvider:describeResourceServer': {
    UserPoolId: 'required',
    Identifier: 'required',
  },
  'CognitoIdentityServiceProvider:describeRiskConfiguration': {
    UserPoolId: 'required',
    ClientId: '<optional>',
  },
  'CognitoIdentityServiceProvider:describeUserImportJob': {
    UserPoolId: 'required',
    JobId: 'required',
  },
  'CognitoIdentityServiceProvider:describeUserPool': {
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:describeUserPoolClient': {
    UserPoolId: 'required',
    ClientId: 'required',
  },
  'CognitoIdentityServiceProvider:describeUserPoolDomain': {
    Domain: 'required',
  },
  'CognitoIdentityServiceProvider:forgetDevice': {
    DeviceKey: 'required',
    AccessToken: '<optional>',
  },
  'CognitoIdentityServiceProvider:forgotPassword': {
    ClientId: 'required',
    Username: 'required',
    SecretHash: '<optional>',
    UserContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
      },
    ],
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:getCSVHeader': {
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:getDevice': {
    DeviceKey: 'required',
    AccessToken: '<optional>',
  },
  'CognitoIdentityServiceProvider:getGroup': {
    GroupName: 'required',
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:getIdentityProviderByIdentifier': {
    UserPoolId: 'required',
    IdpIdentifier: 'required',
  },
  'CognitoIdentityServiceProvider:getSigningCertificate': {
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:getUICustomization': {
    UserPoolId: 'required',
    ClientId: '<optional>',
  },
  'CognitoIdentityServiceProvider:getUser': {
    AccessToken: 'required',
  },
  'CognitoIdentityServiceProvider:getUserAttributeVerificationCode': {
    AccessToken: 'required',
    AttributeName: 'required',
  },
  'CognitoIdentityServiceProvider:getUserPoolMfaConfig': {
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:globalSignOut': {
    AccessToken: 'required',
  },
  'CognitoIdentityServiceProvider:initiateAuth': {
    AuthFlow: 'required',
    ClientId: 'required',
    AuthParameters: ['<optional1>', '<optional2>'],
    ClientMetadata: ['<optional1>', '<optional2>'],
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
    UserContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'CognitoIdentityServiceProvider:listDevices': {
    AccessToken: 'required',
    Limit: '<optional>',
    PaginationToken: '<optional>',
  },
  'CognitoIdentityServiceProvider:listGroups': {
    UserPoolId: 'required',
    Limit: '<optional>',
  },
  'CognitoIdentityServiceProvider:listIdentityProviders': {
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:listResourceServers': {
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:listTagsForResource': {
    ResourceArn: 'required',
  },
  'CognitoIdentityServiceProvider:listUserImportJobs': {
    UserPoolId: 'required',
    PaginationToken: '<optional>',
  },
  'CognitoIdentityServiceProvider:listUserPoolClients': {
    UserPoolId: 'required',
  },
  'CognitoIdentityServiceProvider:listUserPools': {},
  'CognitoIdentityServiceProvider:listUsers': {
    UserPoolId: 'required',
    AttributesToGet: '<optional>',
    Limit: '<optional>',
    PaginationToken: '<optional>',
    Filter: '<optional>',
  },
  'CognitoIdentityServiceProvider:listUsersInGroup': {
    UserPoolId: 'required',
    GroupName: 'required',
    Limit: '<optional>',
  },
  'CognitoIdentityServiceProvider:resendConfirmationCode': {
    ClientId: 'required',
    Username: 'required',
    SecretHash: '<optional>',
    UserContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
      },
    ],
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:respondToAuthChallenge': {
    ClientId: 'required',
    ChallengeName: 'required',
    Session: '<optional>',
    ChallengeResponses: ['<optional1>', '<optional2>'],
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
    UserContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'CognitoIdentityServiceProvider:setRiskConfiguration': {
    UserPoolId: 'required',
    CompromisedCredentialsRiskConfiguration: {
      EventFilter: '<optional>',
    },
    AccountTakeoverRiskConfiguration: {
      NotifyConfiguration: {
        From: '<optional>',
        ReplyTo: '<optional>',
        BlockEmail: {
          HtmlBody: '<optional>',
          TextBody: '<optional>',
        },
        NoActionEmail: {
          HtmlBody: '<optional>',
          TextBody: '<optional>',
        },
        MfaEmail: {
          HtmlBody: '<optional>',
          TextBody: '<optional>',
        },
      },
      Actions: [
        {
          LowAction: '<optional>',
          MediumAction: '<optional>',
          HighAction: '<optional>',
        },
      ],
    },
    ClientId: '<optional>',
    RiskExceptionConfiguration: {
      BlockedIPRangeList: '<optional>',
      SkippedIPRangeList: '<optional>',
    },
  },
  'CognitoIdentityServiceProvider:setUICustomization': {
    UserPoolId: 'required',
    ClientId: '<optional>',
    CSS: '<optional>',
    ImageFile: '<optional>',
  },
  'CognitoIdentityServiceProvider:setUserMFAPreference': {
    AccessToken: 'required',
    SMSMfaSettings: [
      {
        Enabled: '<optional>',
        PreferredMfa: '<optional>',
      },
    ],
    SoftwareTokenMfaSettings: [
      {
        Enabled: '<optional>',
        PreferredMfa: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:setUserPoolMfaConfig': {
    UserPoolId: 'required',
    SmsMfaConfiguration: {
      SmsAuthenticationMessage: '<optional>',
      SmsConfiguration: {
        ExternalId: '<optional>',
      },
    },
    SoftwareTokenMfaConfiguration: {
      Enabled: '<optional>',
    },
    MfaConfiguration: '<optional>',
  },
  'CognitoIdentityServiceProvider:setUserSettings': {
    AccessToken: 'required',
    MFAOptions: [
      {
        DeliveryMedium: '<optional>',
        AttributeName: '<optional>',
      },
    ],
  },
  'CognitoIdentityServiceProvider:signUp': {
    ClientId: 'required',
    Username: 'required',
    Password: 'required',
    UserAttributes: [
      {
        Value: '<optional>',
      },
    ],
    ValidationData: [
      {
        Value: '<optional>',
      },
    ],
    SecretHash: '<optional>',
    AnalyticsMetadata: [
      {
        AnalyticsEndpointId: '<optional>',
      },
    ],
    UserContextData: [
      {
        EncodedData: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'CognitoIdentityServiceProvider:startUserImportJob': {
    UserPoolId: 'required',
    JobId: 'required',
  },
  'CognitoIdentityServiceProvider:stopUserImportJob': {
    UserPoolId: 'required',
    JobId: 'required',
  },
  'CognitoIdentityServiceProvider:tagResource': {
    ResourceArn: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentityServiceProvider:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentityServiceProvider:updateAuthEventFeedback': {
    UserPoolId: 'required',
    Username: 'required',
    EventId: 'required',
    FeedbackToken: 'required',
    FeedbackValue: 'required',
  },
  'CognitoIdentityServiceProvider:updateDeviceStatus': {
    AccessToken: 'required',
    DeviceKey: 'required',
    DeviceRememberedStatus: '<optional>',
  },
  'CognitoIdentityServiceProvider:updateGroup': {
    GroupName: 'required',
    UserPoolId: 'required',
    Description: '<optional>',
    RoleArn: '<optional>',
    Precedence: '<optional>',
  },
  'CognitoIdentityServiceProvider:updateIdentityProvider': {
    UserPoolId: 'required',
    ProviderName: 'required',
    ProviderDetails: ['<optional1>', '<optional2>'],
    AttributeMapping: '<optional>',
    IdpIdentifiers: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentityServiceProvider:updateResourceServer': {
    UserPoolId: 'required',
    Identifier: 'required',
    Name: 'required',
    Scopes: ['<optional1>', '<optional2>'],
  },
  'CognitoIdentityServiceProvider:updateUserAttributes': {
    UserAttributes: [
      {
        Value: '<optional>',
      },
    ],
    AccessToken: 'required',
  },
  'CognitoIdentityServiceProvider:updateUserPool': {
    UserPoolId: 'required',
    SmsConfiguration: {
      ExternalId: '<optional>',
    },
    UserPoolAddOns: ['<optional1>', '<optional2>'],
    Policies: [
      {
        PasswordPolicy: {
          MinimumLength: '<optional>',
          RequireUppercase: '<optional>',
          RequireLowercase: '<optional>',
          RequireNumbers: ['<optional1>', '<optional2>'],
          RequireSymbols: ['<optional1>', '<optional2>'],
          TemporaryPasswordValidityDays: ['<optional1>', '<optional2>'],
        },
      },
    ],
    LambdaConfig: {
      PreSignUp: '<optional>',
      CustomMessage: '<optional>',
      PostConfirmation: '<optional>',
      PreAuthentication: '<optional>',
      PostAuthentication: '<optional>',
      DefineAuthChallenge: '<optional>',
      CreateAuthChallenge: '<optional>',
      VerifyAuthChallengeResponse: '<optional>',
      PreTokenGeneration: '<optional>',
      UserMigration: '<optional>',
    },
    AutoVerifiedAttributes: ['<optional1>', '<optional2>'],
    SmsVerificationMessage: '<optional>',
    EmailVerificationMessage: '<optional>',
    EmailVerificationSubject: '<optional>',
    VerificationMessageTemplate: {
      SmsMessage: '<optional>',
      EmailMessage: '<optional>',
      EmailSubject: '<optional>',
      EmailMessageByLink: '<optional>',
      EmailSubjectByLink: '<optional>',
      DefaultEmailOption: '<optional>',
    },
    SmsAuthenticationMessage: '<optional>',
    MfaConfiguration: '<optional>',
    DeviceConfiguration: {
      ChallengeRequiredOnNewDevice: '<optional>',
      DeviceOnlyRememberedOnUserPrompt: '<optional>',
    },
    EmailConfiguration: {
      SourceArn: '<optional>',
      ReplyToEmailAddress: '<optional>',
      EmailSendingAccount: '<optional>',
    },
    UserPoolTags: ['<optional1>', '<optional2>'],
    AdminCreateUserConfig: {
      AllowAdminCreateUserOnly: '<optional>',
      UnusedAccountValidityDays: ['<optional1>', '<optional2>'],
      InviteMessageTemplate: {
        SMSMessage: '<optional>',
        EmailMessage: '<optional>',
        EmailSubject: '<optional>',
      },
    },
  },
  'CognitoIdentityServiceProvider:updateUserPoolClient': {
    UserPoolId: 'required',
    ClientId: 'required',
    AnalyticsConfiguration: {
      UserDataShared: '<optional>',
    },
    ClientName: '<optional>',
    RefreshTokenValidity: '<optional>',
    ReadAttributes: ['<optional1>', '<optional2>'],
    WriteAttributes: ['<optional1>', '<optional2>'],
    ExplicitAuthFlows: ['<optional1>', '<optional2>'],
    SupportedIdentityProviders: ['<optional1>', '<optional2>'],
    CallbackURLs: ['<optional1>', '<optional2>'],
    LogoutURLs: ['<optional1>', '<optional2>'],
    DefaultRedirectURI: '<optional>',
    AllowedOAuthFlows: ['<optional1>', '<optional2>'],
    AllowedOAuthScopes: ['<optional1>', '<optional2>'],
    AllowedOAuthFlowsUserPoolClient: '<optional>',
  },
  'CognitoIdentityServiceProvider:updateUserPoolDomain': {
    Domain: 'required',
    UserPoolId: 'required',
    CustomDomainConfig: {
      CertificateArn: 'required',
    },
  },
  'CognitoIdentityServiceProvider:verifySoftwareToken': {
    UserCode: 'required',
    AccessToken: '<optional>',
    Session: '<optional>',
    FriendlyDeviceName: '<optional>',
  },
  'CognitoIdentityServiceProvider:verifyUserAttribute': {
    AccessToken: 'required',
    AttributeName: 'required',
    Code: 'required',
  },
  'CognitoSync:bulkPublish': {
    IdentityPoolId: 'required',
  },
  'CognitoSync:deleteDataset': {
    IdentityPoolId: 'required',
    IdentityId: 'required',
    DatasetName: 'required',
  },
  'CognitoSync:describeDataset': {
    IdentityPoolId: 'required',
    IdentityId: 'required',
    DatasetName: 'required',
  },
  'CognitoSync:describeIdentityPoolUsage': {
    IdentityPoolId: 'required',
  },
  'CognitoSync:describeIdentityUsage': {
    IdentityPoolId: 'required',
    IdentityId: 'required',
  },
  'CognitoSync:getBulkPublishDetails': {
    IdentityPoolId: 'required',
  },
  'CognitoSync:getCognitoEvents': {
    IdentityPoolId: 'required',
  },
  'CognitoSync:getIdentityPoolConfiguration': {
    IdentityPoolId: 'required',
  },
  'CognitoSync:listDatasets': {
    IdentityId: 'required',
    IdentityPoolId: 'required',
  },
  'CognitoSync:listIdentityPoolUsage': {},
  'CognitoSync:listRecords': {
    IdentityPoolId: 'required',
    IdentityId: 'required',
    DatasetName: 'required',
    LastSyncCount: '<optional>',
    SyncSessionToken: '<optional>',
  },
  'CognitoSync:registerDevice': {
    IdentityPoolId: 'required',
    IdentityId: 'required',
    Platform: 'required',
    Token: 'required',
  },
  'CognitoSync:setCognitoEvents': {
    IdentityPoolId: 'required',
    Events: ['required1', 'required2'],
  },
  'CognitoSync:setIdentityPoolConfiguration': {
    IdentityPoolId: 'required',
    PushSync: {
      ApplicationArns: ['<optional1>', '<optional2>'],
      RoleArn: '<optional>',
    },
    CognitoStreams: [
      {
        StreamName: '<optional>',
        RoleArn: '<optional>',
        StreamingStatus: '<optional>',
      },
    ],
  },
  'CognitoSync:subscribeToDataset': {
    IdentityPoolId: 'required',
    IdentityId: 'required',
    DatasetName: 'required',
    DeviceId: 'required',
  },
  'CognitoSync:unsubscribeFromDataset': {
    IdentityPoolId: 'required',
    IdentityId: 'required',
    DatasetName: 'required',
    DeviceId: 'required',
  },
  'CognitoSync:updateRecords': {
    IdentityPoolId: 'required',
    IdentityId: 'required',
    DatasetName: 'required',
    SyncSessionToken: 'required',
    RecordPatches: [
      {
        Value: '<optional>',
        DeviceLastModifiedDate: '<optional>',
      },
    ],
    DeviceId: '<optional>',
    ClientContext: '<optional>',
  },
  'Comprehend:batchDetectDominantLanguage': {
    TextList: 'required',
  },
  'Comprehend:batchDetectEntities': {
    TextList: 'required',
    LanguageCode: 'required',
  },
  'Comprehend:batchDetectKeyPhrases': {
    TextList: 'required',
    LanguageCode: 'required',
  },
  'Comprehend:batchDetectSentiment': {
    TextList: 'required',
    LanguageCode: 'required',
  },
  'Comprehend:batchDetectSyntax': {
    TextList: 'required',
    LanguageCode: 'required',
  },
  'Comprehend:classifyDocument': {
    Text: 'required',
    EndpointArn: 'required',
  },
  'Comprehend:createDocumentClassifier': {
    DocumentClassifierName: 'required',
    DataAccessRoleArn: 'required',
    InputDataConfig: {
      S3Uri: 'required',
    },
    LanguageCode: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    VpcConfig: '<optional>',
    OutputDataConfig: {
      S3Uri: '<optional>',
      KmsKeyId: '<optional>',
    },
    ClientRequestToken: '<optional>',
    VolumeKmsKeyId: '<optional>',
  },
  'Comprehend:createEndpoint': {
    EndpointName: 'required',
    ModelArn: 'required',
    DesiredInferenceUnits: ['required1', 'required2'],
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    ClientRequestToken: '<optional>',
  },
  'Comprehend:createEntityRecognizer': {
    RecognizerName: 'required',
    DataAccessRoleArn: 'required',
    InputDataConfig: {
      Annotations: ['<optional1>', '<optional2>'],
      EntityList: '<optional>',
    },
    LanguageCode: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    VpcConfig: '<optional>',
    ClientRequestToken: '<optional>',
    VolumeKmsKeyId: '<optional>',
  },
  'Comprehend:deleteDocumentClassifier': {
    DocumentClassifierArn: 'required',
  },
  'Comprehend:deleteEndpoint': {
    EndpointArn: 'required',
  },
  'Comprehend:deleteEntityRecognizer': {
    EntityRecognizerArn: 'required',
  },
  'Comprehend:describeDocumentClassificationJob': {
    JobId: 'required',
  },
  'Comprehend:describeDocumentClassifier': {
    DocumentClassifierArn: 'required',
  },
  'Comprehend:describeDominantLanguageDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:describeEndpoint': {
    EndpointArn: 'required',
  },
  'Comprehend:describeEntitiesDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:describeEntityRecognizer': {
    EntityRecognizerArn: 'required',
  },
  'Comprehend:describeKeyPhrasesDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:describeSentimentDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:describeTopicsDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:detectDominantLanguage': {
    Text: 'required',
  },
  'Comprehend:detectEntities': {
    Text: 'required',
    LanguageCode: 'required',
  },
  'Comprehend:detectKeyPhrases': {
    Text: 'required',
    LanguageCode: 'required',
  },
  'Comprehend:detectSentiment': {
    Text: 'required',
    LanguageCode: 'required',
  },
  'Comprehend:detectSyntax': {
    Text: 'required',
    LanguageCode: 'required',
  },
  'Comprehend:listDocumentClassificationJobs': {
    Filter: {
      JobName: '<optional>',
      JobStatus: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'Comprehend:listDocumentClassifiers': {
    Filter: {
      Status: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'Comprehend:listDominantLanguageDetectionJobs': {
    Filter: {
      JobName: '<optional>',
      JobStatus: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'Comprehend:listEndpoints': {
    Filter: {
      ModelArn: '<optional>',
      Status: '<optional>',
      CreationTimeBefore: '<optional>',
      CreationTimeAfter: '<optional>',
    },
  },
  'Comprehend:listEntitiesDetectionJobs': {
    Filter: {
      JobName: '<optional>',
      JobStatus: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'Comprehend:listEntityRecognizers': {
    Filter: {
      Status: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'Comprehend:listKeyPhrasesDetectionJobs': {
    Filter: {
      JobName: '<optional>',
      JobStatus: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'Comprehend:listSentimentDetectionJobs': {
    Filter: {
      JobName: '<optional>',
      JobStatus: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'Comprehend:listTagsForResource': {
    ResourceArn: 'required',
  },
  'Comprehend:listTopicsDetectionJobs': {
    Filter: {
      JobName: '<optional>',
      JobStatus: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'Comprehend:startDocumentClassificationJob': {
    DocumentClassifierArn: 'required',
    InputDataConfig: {
      InputFormat: '<optional>',
    },
    OutputDataConfig: {
      KmsKeyId: '<optional>',
    },
    DataAccessRoleArn: 'required',
    VpcConfig: '<optional>',
    JobName: '<optional>',
    ClientRequestToken: '<optional>',
    VolumeKmsKeyId: '<optional>',
  },
  'Comprehend:startDominantLanguageDetectionJob': {
    InputDataConfig: {
      InputFormat: '<optional>',
    },
    OutputDataConfig: {
      KmsKeyId: '<optional>',
    },
    DataAccessRoleArn: 'required',
    VpcConfig: '<optional>',
    JobName: '<optional>',
    ClientRequestToken: '<optional>',
    VolumeKmsKeyId: '<optional>',
  },
  'Comprehend:startEntitiesDetectionJob': {
    InputDataConfig: {
      InputFormat: '<optional>',
    },
    OutputDataConfig: {
      KmsKeyId: '<optional>',
    },
    DataAccessRoleArn: 'required',
    LanguageCode: 'required',
    VpcConfig: '<optional>',
    JobName: '<optional>',
    EntityRecognizerArn: '<optional>',
    ClientRequestToken: '<optional>',
    VolumeKmsKeyId: '<optional>',
  },
  'Comprehend:startKeyPhrasesDetectionJob': {
    InputDataConfig: {
      InputFormat: '<optional>',
    },
    OutputDataConfig: {
      KmsKeyId: '<optional>',
    },
    DataAccessRoleArn: 'required',
    LanguageCode: 'required',
    VpcConfig: '<optional>',
    JobName: '<optional>',
    ClientRequestToken: '<optional>',
    VolumeKmsKeyId: '<optional>',
  },
  'Comprehend:startSentimentDetectionJob': {
    InputDataConfig: {
      InputFormat: '<optional>',
    },
    OutputDataConfig: {
      KmsKeyId: '<optional>',
    },
    DataAccessRoleArn: 'required',
    LanguageCode: 'required',
    VpcConfig: '<optional>',
    JobName: '<optional>',
    ClientRequestToken: '<optional>',
    VolumeKmsKeyId: '<optional>',
  },
  'Comprehend:startTopicsDetectionJob': {
    InputDataConfig: {
      InputFormat: '<optional>',
    },
    OutputDataConfig: {
      KmsKeyId: '<optional>',
    },
    DataAccessRoleArn: 'required',
    VpcConfig: '<optional>',
    JobName: '<optional>',
    NumberOfTopics: ['<optional1>', '<optional2>'],
    ClientRequestToken: '<optional>',
    VolumeKmsKeyId: '<optional>',
  },
  'Comprehend:stopDominantLanguageDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:stopEntitiesDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:stopKeyPhrasesDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:stopSentimentDetectionJob': {
    JobId: 'required',
  },
  'Comprehend:stopTrainingDocumentClassifier': {
    DocumentClassifierArn: 'required',
  },
  'Comprehend:stopTrainingEntityRecognizer': {
    EntityRecognizerArn: 'required',
  },
  'Comprehend:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'Comprehend:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Comprehend:updateEndpoint': {
    EndpointArn: 'required',
    DesiredInferenceUnits: ['required1', 'required2'],
  },
  'ComprehendMedical:describeEntitiesDetectionV2Job': {
    JobId: 'required',
  },
  'ComprehendMedical:describePHIDetectionJob': {
    JobId: 'required',
  },
  'ComprehendMedical:detectEntities': {
    Text: 'required',
  },
  'ComprehendMedical:detectEntitiesV2': {
    Text: 'required',
  },
  'ComprehendMedical:detectPHI': {
    Text: 'required',
  },
  'ComprehendMedical:inferICD10CM': {
    Text: 'required',
  },
  'ComprehendMedical:inferRxNorm': {
    Text: 'required',
  },
  'ComprehendMedical:listEntitiesDetectionV2Jobs': {
    Filter: {
      JobName: '<optional>',
      JobStatus: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'ComprehendMedical:listPHIDetectionJobs': {
    Filter: {
      JobName: '<optional>',
      JobStatus: '<optional>',
      SubmitTimeBefore: '<optional>',
      SubmitTimeAfter: '<optional>',
    },
  },
  'ComprehendMedical:startEntitiesDetectionV2Job': {
    InputDataConfig: {
      S3Key: '<optional>',
    },
    OutputDataConfig: {
      S3Key: '<optional>',
    },
    DataAccessRoleArn: 'required',
    LanguageCode: 'required',
    JobName: '<optional>',
    ClientRequestToken: '<optional>',
    KMSKey: '<optional>',
  },
  'ComprehendMedical:startPHIDetectionJob': {
    InputDataConfig: {
      S3Key: '<optional>',
    },
    OutputDataConfig: {
      S3Key: '<optional>',
    },
    DataAccessRoleArn: 'required',
    LanguageCode: 'required',
    JobName: '<optional>',
    ClientRequestToken: '<optional>',
    KMSKey: '<optional>',
  },
  'ComprehendMedical:stopEntitiesDetectionV2Job': {
    JobId: 'required',
  },
  'ComprehendMedical:stopPHIDetectionJob': {
    JobId: 'required',
  },
  'ComputeOptimizer:getAutoScalingGroupRecommendations': {
    accountIds: ['<optional1>', '<optional2>'],
    autoScalingGroupArns: ['<optional1>', '<optional2>'],
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'ComputeOptimizer:getEC2InstanceRecommendations': {
    instanceArns: ['<optional1>', '<optional2>'],
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
    accountIds: ['<optional1>', '<optional2>'],
  },
  'ComputeOptimizer:getEC2RecommendationProjectedMetrics': {
    instanceArn: 'required',
    stat: 'required',
    period: 'required',
    startTime: 'required',
    endTime: 'required',
  },
  'ComputeOptimizer:getEnrollmentStatus': {},
  'ComputeOptimizer:getRecommendationSummaries': {
    accountIds: ['<optional1>', '<optional2>'],
  },
  'ComputeOptimizer:updateEnrollmentStatus': {
    status: 'required',
    includeMemberAccounts: ['<optional1>', '<optional2>'],
  },
  'ConfigService:batchGetAggregateResourceConfig': {
    ConfigurationAggregatorName: 'required',
    ResourceIdentifiers: [
      {
        ResourceName: '<optional>',
      },
    ],
  },
  'ConfigService:batchGetResourceConfig': {
    resourceKeys: [
      {
        resourceType: 'required',
        resourceId: 'required',
      },
    ],
  },
  'ConfigService:deleteAggregationAuthorization': {
    AuthorizedAccountId: 'required',
    AuthorizedAwsRegion: 'required',
  },
  'ConfigService:deleteConfigRule': {
    ConfigRuleName: 'required',
  },
  'ConfigService:deleteConfigurationAggregator': {
    ConfigurationAggregatorName: 'required',
  },
  'ConfigService:deleteConfigurationRecorder': {
    ConfigurationRecorderName: 'required',
  },
  'ConfigService:deleteConformancePack': {
    ConformancePackName: 'required',
  },
  'ConfigService:deleteDeliveryChannel': {
    DeliveryChannelName: 'required',
  },
  'ConfigService:deleteEvaluationResults': {
    ConfigRuleName: 'required',
  },
  'ConfigService:deleteOrganizationConfigRule': {
    OrganizationConfigRuleName: 'required',
  },
  'ConfigService:deleteOrganizationConformancePack': {
    OrganizationConformancePackName: 'required',
  },
  'ConfigService:deletePendingAggregationRequest': {
    RequesterAccountId: 'required',
    RequesterAwsRegion: 'required',
  },
  'ConfigService:deleteRemediationConfiguration': {
    ConfigRuleName: 'required',
    ResourceType: '<optional>',
  },
  'ConfigService:deleteRemediationExceptions': {
    ConfigRuleName: 'required',
    ResourceKeys: [
      {
        ResourceType: '<optional>',
        ResourceId: '<optional>',
      },
    ],
  },
  'ConfigService:deleteResourceConfig': {
    ResourceType: 'required',
    ResourceId: 'required',
  },
  'ConfigService:deleteRetentionConfiguration': {
    RetentionConfigurationName: 'required',
  },
  'ConfigService:deliverConfigSnapshot': {
    deliveryChannelName: 'required',
  },
  'ConfigService:describeAggregateComplianceByConfigRules': {
    ConfigurationAggregatorName: 'required',
    Filters: [
      {
        ConfigRuleName: '<optional>',
        ComplianceType: '<optional>',
        AccountId: '<optional>',
        AwsRegion: '<optional>',
      },
    ],
    Limit: '<optional>',
  },
  'ConfigService:describeAggregationAuthorizations': {
    Limit: '<optional>',
  },
  'ConfigService:describeComplianceByConfigRule': {
    ConfigRuleNames: ['<optional1>', '<optional2>'],
    ComplianceTypes: ['<optional1>', '<optional2>'],
  },
  'ConfigService:describeComplianceByResource': {
    ResourceType: '<optional>',
    ResourceId: '<optional>',
    ComplianceTypes: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeConfigRuleEvaluationStatus': {
    ConfigRuleNames: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeConfigRules': {
    ConfigRuleNames: ['<optional1>', '<optional2>'],
  },
  'ConfigService:describeConfigurationAggregatorSourcesStatus': {
    ConfigurationAggregatorName: 'required',
    UpdateStatus: '<optional>',
    Limit: '<optional>',
  },
  'ConfigService:describeConfigurationAggregators': {
    ConfigurationAggregatorNames: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeConfigurationRecorderStatus': {
    ConfigurationRecorderNames: ['<optional1>', '<optional2>'],
  },
  'ConfigService:describeConfigurationRecorders': {
    ConfigurationRecorderNames: ['<optional1>', '<optional2>'],
  },
  'ConfigService:describeConformancePackCompliance': {
    ConformancePackName: 'required',
    Filters: [
      {
        ConfigRuleNames: ['<optional1>', '<optional2>'],
        ComplianceType: '<optional>',
      },
    ],
    Limit: '<optional>',
  },
  'ConfigService:describeConformancePackStatus': {
    ConformancePackNames: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeConformancePacks': {
    ConformancePackNames: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeDeliveryChannelStatus': {
    DeliveryChannelNames: ['<optional1>', '<optional2>'],
  },
  'ConfigService:describeDeliveryChannels': {
    DeliveryChannelNames: ['<optional1>', '<optional2>'],
  },
  'ConfigService:describeOrganizationConfigRuleStatuses': {
    OrganizationConfigRuleNames: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeOrganizationConfigRules': {
    OrganizationConfigRuleNames: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeOrganizationConformancePackStatuses': {
    OrganizationConformancePackNames: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeOrganizationConformancePacks': {
    OrganizationConformancePackNames: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describePendingAggregationRequests': {
    Limit: '<optional>',
  },
  'ConfigService:describeRemediationConfigurations': {
    ConfigRuleNames: ['required1', 'required2'],
  },
  'ConfigService:describeRemediationExceptions': {
    ConfigRuleName: 'required',
    ResourceKeys: [
      {
        ResourceType: '<optional>',
        ResourceId: '<optional>',
      },
    ],
    Limit: '<optional>',
  },
  'ConfigService:describeRemediationExecutionStatus': {
    ConfigRuleName: 'required',
    ResourceKeys: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:describeRetentionConfigurations': {
    RetentionConfigurationNames: ['<optional1>', '<optional2>'],
  },
  'ConfigService:getAggregateComplianceDetailsByConfigRule': {
    ConfigurationAggregatorName: 'required',
    ConfigRuleName: 'required',
    AccountId: 'required',
    AwsRegion: 'required',
    ComplianceType: '<optional>',
    Limit: '<optional>',
  },
  'ConfigService:getAggregateConfigRuleComplianceSummary': {
    ConfigurationAggregatorName: 'required',
    Filters: [
      {
        AccountId: '<optional>',
        AwsRegion: '<optional>',
      },
    ],
    GroupByKey: '<optional>',
    Limit: '<optional>',
  },
  'ConfigService:getAggregateDiscoveredResourceCounts': {
    ConfigurationAggregatorName: 'required',
    Filters: [
      {
        ResourceType: '<optional>',
        AccountId: '<optional>',
        Region: '<optional>',
      },
    ],
    GroupByKey: '<optional>',
    Limit: '<optional>',
  },
  'ConfigService:getAggregateResourceConfig': {
    ConfigurationAggregatorName: 'required',
    ResourceIdentifier: {
      ResourceName: '<optional>',
    },
  },
  'ConfigService:getComplianceDetailsByConfigRule': {
    ConfigRuleName: 'required',
    ComplianceTypes: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'ConfigService:getComplianceDetailsByResource': {
    ResourceType: 'required',
    ResourceId: 'required',
    ComplianceTypes: ['<optional1>', '<optional2>'],
  },
  'ConfigService:getComplianceSummaryByConfigRule': {},
  'ConfigService:getComplianceSummaryByResourceType': {
    ResourceTypes: ['<optional1>', '<optional2>'],
  },
  'ConfigService:getConformancePackComplianceDetails': {
    ConformancePackName: 'required',
    Filters: [
      {
        ConfigRuleNames: ['<optional1>', '<optional2>'],
        ComplianceType: '<optional>',
        ResourceType: '<optional>',
        ResourceIds: ['<optional1>', '<optional2>'],
      },
    ],
    Limit: '<optional>',
  },
  'ConfigService:getConformancePackComplianceSummary': {
    ConformancePackNames: ['required1', 'required2'],
    Limit: '<optional>',
  },
  'ConfigService:getDiscoveredResourceCounts': {
    resourceTypes: ['<optional1>', '<optional2>'],
    limit: '<optional>',
  },
  'ConfigService:getOrganizationConfigRuleDetailedStatus': {
    OrganizationConfigRuleName: 'required',
    Filters: [
      {
        AccountId: '<optional>',
        MemberAccountRuleStatus: '<optional>',
      },
    ],
    Limit: '<optional>',
  },
  'ConfigService:getOrganizationConformancePackDetailedStatus': {
    OrganizationConformancePackName: 'required',
    Filters: [
      {
        AccountId: '<optional>',
        Status: '<optional>',
      },
    ],
    Limit: '<optional>',
  },
  'ConfigService:getResourceConfigHistory': {
    resourceType: 'required',
    resourceId: 'required',
    laterTime: '<optional>',
    earlierTime: '<optional>',
    chronologicalOrder: '<optional>',
    limit: '<optional>',
  },
  'ConfigService:listAggregateDiscoveredResources': {
    ConfigurationAggregatorName: 'required',
    ResourceType: 'required',
    Filters: [
      {
        AccountId: '<optional>',
        ResourceId: '<optional>',
        ResourceName: '<optional>',
        Region: '<optional>',
      },
    ],
    Limit: '<optional>',
  },
  'ConfigService:listDiscoveredResources': {
    resourceType: 'required',
    resourceIds: ['<optional1>', '<optional2>'],
    resourceName: '<optional>',
    limit: '<optional>',
    includeDeletedResources: ['<optional1>', '<optional2>'],
  },
  'ConfigService:listTagsForResource': {
    ResourceArn: 'required',
    Limit: '<optional>',
  },
  'ConfigService:putAggregationAuthorization': {
    AuthorizedAccountId: 'required',
    AuthorizedAwsRegion: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ConfigService:putConfigRule': {
    ConfigRule: {
      ConfigRuleName: '<optional>',
      ConfigRuleArn: '<optional>',
      ConfigRuleId: '<optional>',
      Description: '<optional>',
      Scope: {
        ComplianceResourceTypes: ['<optional1>', '<optional2>'],
        TagKey: '<optional>',
        TagValue: '<optional>',
        ComplianceResourceId: '<optional>',
      },
      InputParameters: ['<optional1>', '<optional2>'],
      MaximumExecutionFrequency: '<optional>',
      ConfigRuleState: '<optional>',
      CreatedBy: '<optional>',
      Source: {
        SourceDetails: [
          {
            EventSource: '<optional>',
            MessageType: '<optional>',
            MaximumExecutionFrequency: '<optional>',
          },
        ],
      },
    },
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ConfigService:putConfigurationAggregator': {
    ConfigurationAggregatorName: 'required',
    AccountAggregationSources: [
      {
        AllAwsRegions: ['<optional1>', '<optional2>'],
        AwsRegions: ['<optional1>', '<optional2>'],
      },
    ],
    OrganizationAggregationSource: {
      AwsRegions: ['<optional1>', '<optional2>'],
      AllAwsRegions: ['<optional1>', '<optional2>'],
    },
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ConfigService:putConfigurationRecorder': {
    ConfigurationRecorder: {
      name: '<optional>',
      roleARN: '<optional>',
      recordingGroup: {
        allSupported: '<optional>',
        includeGlobalResourceTypes: ['<optional1>', '<optional2>'],
        resourceTypes: ['<optional1>', '<optional2>'],
      },
    },
  },
  'ConfigService:putConformancePack': {
    ConformancePackName: 'required',
    DeliveryS3Bucket: 'required',
    ConformancePackInputParameters: ['<optional1>', '<optional2>'],
    TemplateS3Uri: '<optional>',
    TemplateBody: '<optional>',
    DeliveryS3KeyPrefix: '<optional>',
  },
  'ConfigService:putDeliveryChannel': {
    DeliveryChannel: {
      name: '<optional>',
      s3BucketName: '<optional>',
      s3KeyPrefix: '<optional>',
      snsTopicARN: '<optional>',
      configSnapshotDeliveryProperties: [
        {
          deliveryFrequency: '<optional>',
        },
      ],
    },
  },
  'ConfigService:putEvaluations': {
    ResultToken: 'required',
    Evaluations: [
      {
        Annotation: '<optional>',
      },
    ],
    TestMode: '<optional>',
  },
  'ConfigService:putOrganizationConfigRule': {
    OrganizationConfigRuleName: 'required',
    OrganizationManagedRuleMetadata: [
      {
        Description: '<optional>',
        InputParameters: ['<optional1>', '<optional2>'],
        MaximumExecutionFrequency: '<optional>',
        ResourceTypesScope: '<optional>',
        ResourceIdScope: '<optional>',
        TagKeyScope: '<optional>',
        TagValueScope: '<optional>',
      },
    ],
    OrganizationCustomRuleMetadata: [
      {
        Description: '<optional>',
        InputParameters: ['<optional1>', '<optional2>'],
        MaximumExecutionFrequency: '<optional>',
        ResourceTypesScope: '<optional>',
        ResourceIdScope: '<optional>',
        TagKeyScope: '<optional>',
        TagValueScope: '<optional>',
      },
    ],
    ExcludedAccounts: ['<optional1>', '<optional2>'],
  },
  'ConfigService:putOrganizationConformancePack': {
    OrganizationConformancePackName: 'required',
    DeliveryS3Bucket: 'required',
    ConformancePackInputParameters: ['<optional1>', '<optional2>'],
    TemplateS3Uri: '<optional>',
    TemplateBody: '<optional>',
    DeliveryS3KeyPrefix: '<optional>',
    ExcludedAccounts: ['<optional1>', '<optional2>'],
  },
  'ConfigService:putRemediationConfigurations': {
    RemediationConfigurations: [
      {
        TargetVersion: '<optional>',
        Parameters: ['<optional1>', '<optional2>'],
        ResourceType: '<optional>',
      },
    ],
  },
  'ConfigService:putRemediationExceptions': {
    ConfigRuleName: 'required',
    ResourceKeys: [
      {
        ResourceType: '<optional>',
        ResourceId: '<optional>',
      },
    ],
    Message: '<optional>',
    ExpirationTime: '<optional>',
  },
  'ConfigService:putResourceConfig': {
    ResourceType: 'required',
    SchemaVersionId: 'required',
    ResourceId: 'required',
    Configuration: 'required',
    ResourceName: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'ConfigService:putRetentionConfiguration': {
    RetentionPeriodInDays: ['required1', 'required2'],
  },
  'ConfigService:selectResourceConfig': {
    Expression: 'required',
    Limit: '<optional>',
  },
  'ConfigService:startConfigRulesEvaluation': {
    ConfigRuleNames: ['<optional1>', '<optional2>'],
  },
  'ConfigService:startConfigurationRecorder': {
    ConfigurationRecorderName: 'required',
  },
  'ConfigService:startRemediationExecution': {
    ConfigRuleName: 'required',
    ResourceKeys: [
      {
        resourceType: 'required',
        resourceId: 'required',
      },
    ],
  },
  'ConfigService:stopConfigurationRecorder': {
    ConfigurationRecorderName: 'required',
  },
  'ConfigService:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ConfigService:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Connect:createUser': {
    Username: 'required',
    PhoneConfig: {
      AutoAccept: '<optional>',
      AfterContactWorkTimeLimit: '<optional>',
      DeskPhoneNumber: '<optional>',
    },
    SecurityProfileIds: ['required1', 'required2'],
    RoutingProfileId: 'required',
    InstanceId: 'required',
    Password: '<optional>',
    IdentityInfo: {
      FirstName: '<optional>',
      LastName: '<optional>',
      Email: '<optional>',
    },
    DirectoryUserId: '<optional>',
    HierarchyGroupId: '<optional>',
  },
  'Connect:deleteUser': {
    InstanceId: 'required',
    UserId: 'required',
  },
  'Connect:describeUser': {
    UserId: 'required',
    InstanceId: 'required',
  },
  'Connect:describeUserHierarchyGroup': {
    HierarchyGroupId: 'required',
    InstanceId: 'required',
  },
  'Connect:describeUserHierarchyStructure': {
    InstanceId: 'required',
  },
  'Connect:getContactAttributes': {
    InstanceId: 'required',
    InitialContactId: 'required',
  },
  'Connect:getCurrentMetricData': {
    InstanceId: 'required',
    CurrentMetrics: [
      {
        Name: '<optional>',
        Unit: '<optional>',
      },
    ],
    Groupings: ['<optional1>', '<optional2>'],
    Filters: ['<optional1>', '<optional2>'],
  },
  'Connect:getFederationToken': {
    InstanceId: 'required',
  },
  'Connect:getMetricData': {
    InstanceId: 'required',
    StartTime: 'required',
    EndTime: 'required',
    HistoricalMetrics: [
      {
        Name: '<optional>',
        Threshold: {
          Comparison: '<optional>',
          ThresholdValue: '<optional>',
        },
        Statistic: '<optional>',
        Unit: '<optional>',
      },
    ],
    Groupings: ['<optional1>', '<optional2>'],
    Filters: ['<optional1>', '<optional2>'],
  },
  'Connect:listContactFlows': {
    InstanceId: 'required',
    ContactFlowTypes: ['<optional1>', '<optional2>'],
  },
  'Connect:listHoursOfOperations': {
    InstanceId: 'required',
  },
  'Connect:listPhoneNumbers': {
    InstanceId: 'required',
    PhoneNumberTypes: ['<optional1>', '<optional2>'],
    PhoneNumberCountryCodes: ['<optional1>', '<optional2>'],
  },
  'Connect:listQueues': {
    InstanceId: 'required',
    QueueTypes: ['<optional1>', '<optional2>'],
  },
  'Connect:listRoutingProfiles': {
    InstanceId: 'required',
  },
  'Connect:listSecurityProfiles': {
    InstanceId: 'required',
  },
  'Connect:listTagsForResource': {
    resourceArn: 'required',
  },
  'Connect:listUserHierarchyGroups': {
    InstanceId: 'required',
  },
  'Connect:listUsers': {
    InstanceId: 'required',
  },
  'Connect:startChatContact': {
    InstanceId: 'required',
    ContactFlowId: 'required',
    ParticipantDetails: [
      {
        DisplayName: 'required',
      },
    ],
    InitialMessage: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
    ClientToken: '<optional>',
  },
  'Connect:startOutboundVoiceContact': {
    DestinationPhoneNumber: 'required',
    ContactFlowId: 'required',
    InstanceId: 'required',
    ClientToken: '<optional>',
    SourcePhoneNumber: '<optional>',
    QueueId: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
  },
  'Connect:stopContact': {
    ContactId: 'required',
    InstanceId: 'required',
  },
  'Connect:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'Connect:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'Connect:updateContactAttributes': {
    InitialContactId: 'required',
    InstanceId: 'required',
    Attributes: ['required1', 'required2'],
  },
  'Connect:updateUserHierarchy': {
    UserId: 'required',
    InstanceId: 'required',
    HierarchyGroupId: '<optional>',
  },
  'Connect:updateUserIdentityInfo': {
    IdentityInfo: {
      FirstName: '<optional>',
      LastName: '<optional>',
      Email: '<optional>',
    },
    UserId: 'required',
    InstanceId: 'required',
  },
  'Connect:updateUserPhoneConfig': {
    PhoneConfig: {
      AutoAccept: '<optional>',
      AfterContactWorkTimeLimit: '<optional>',
      DeskPhoneNumber: '<optional>',
    },
    UserId: 'required',
    InstanceId: 'required',
  },
  'Connect:updateUserRoutingProfile': {
    RoutingProfileId: 'required',
    UserId: 'required',
    InstanceId: 'required',
  },
  'Connect:updateUserSecurityProfiles': {
    SecurityProfileIds: ['required1', 'required2'],
    UserId: 'required',
    InstanceId: 'required',
  },
  'ConnectParticipant:createParticipantConnection': {
    Type: 'required',
    ParticipantToken: 'required',
  },
  'ConnectParticipant:disconnectParticipant': {
    ConnectionToken: 'required',
    ClientToken: '<optional>',
  },
  'ConnectParticipant:getTranscript': {
    ConnectionToken: 'required',
    ContactId: '<optional>',
    ScanDirection: '<optional>',
    SortOrder: '<optional>',
    StartPosition: {
      Id: '<optional>',
      AbsoluteTime: '<optional>',
      MostRecent: '<optional>',
    },
  },
  'ConnectParticipant:sendEvent': {
    ContentType: 'required',
    ConnectionToken: 'required',
    Content: '<optional>',
    ClientToken: '<optional>',
  },
  'ConnectParticipant:sendMessage': {
    ContentType: 'required',
    Content: 'required',
    ConnectionToken: 'required',
    ClientToken: '<optional>',
  },
  'CostExplorer:createCostCategoryDefinition': {
    Name: 'required',
    RuleVersion: 'required',
    Rules: [
      {
        Rule: {
          Or: '<optional>',
          And: '<optional>',
          Not: '<optional>',
          Dimensions: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
          Tags: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
          CostCategories: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
        },
      },
    ],
  },
  'CostExplorer:deleteCostCategoryDefinition': {
    CostCategoryArn: 'required',
  },
  'CostExplorer:describeCostCategoryDefinition': {
    CostCategoryArn: 'required',
    EffectiveOn: '<optional>',
  },
  'CostExplorer:getCostAndUsage': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    Granularity: '<optional>',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    Metrics: ['<optional1>', '<optional2>'],
    GroupBy: {
      Type: '<optional>',
      Key: '<optional>',
    },
    NextPageToken: '<optional>',
  },
  'CostExplorer:getCostAndUsageWithResources': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    Granularity: '<optional>',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      CostCategories: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    Metrics: ['<optional1>', '<optional2>'],
    GroupBy: {
      Type: '<optional>',
      Key: '<optional>',
    },
    NextPageToken: '<optional>',
  },
  'CostExplorer:getCostForecast': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    Metric: 'required',
    Granularity: 'required',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    PredictionIntervalLevel: '<optional>',
  },
  'CostExplorer:getDimensionValues': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    Dimension: 'required',
    SearchString: '<optional>',
    Context: '<optional>',
    NextPageToken: '<optional>',
  },
  'CostExplorer:getReservationCoverage': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    GroupBy: {
      Type: '<optional>',
      Key: '<optional>',
    },
    Granularity: '<optional>',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    Metrics: ['<optional1>', '<optional2>'],
    NextPageToken: '<optional>',
  },
  'CostExplorer:getReservationPurchaseRecommendation': {
    Service: 'required',
    AccountId: '<optional>',
    AccountScope: '<optional>',
    LookbackPeriodInDays: ['<optional1>', '<optional2>'],
    TermInYears: ['<optional1>', '<optional2>'],
    PaymentOption: '<optional>',
    ServiceSpecification: {
      EC2Specification: {
        OfferingClass: '<optional>',
      },
    },
    PageSize: '<optional>',
    NextPageToken: '<optional>',
  },
  'CostExplorer:getReservationUtilization': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    GroupBy: {
      Type: '<optional>',
      Key: '<optional>',
    },
    Granularity: '<optional>',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    NextPageToken: '<optional>',
  },
  'CostExplorer:getRightsizingRecommendation': {
    Service: 'required',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    PageSize: '<optional>',
    NextPageToken: '<optional>',
  },
  'CostExplorer:getSavingsPlansCoverage': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    GroupBy: {
      Type: '<optional>',
      Key: '<optional>',
    },
    Granularity: '<optional>',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      CostCategories: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    Metrics: ['<optional1>', '<optional2>'],
  },
  'CostExplorer:getSavingsPlansPurchaseRecommendation': {
    SavingsPlansType: 'required',
    TermInYears: ['required1', 'required2'],
    PaymentOption: 'required',
    LookbackPeriodInDays: ['required1', 'required2'],
    NextPageToken: '<optional>',
    PageSize: '<optional>',
  },
  'CostExplorer:getSavingsPlansUtilization': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    Granularity: '<optional>',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      CostCategories: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'CostExplorer:getSavingsPlansUtilizationDetails': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      CostCategories: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'CostExplorer:getTags': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    SearchString: '<optional>',
    TagKey: '<optional>',
    NextPageToken: '<optional>',
  },
  'CostExplorer:getUsageForecast': {
    TimePeriod: {
      Start: 'required',
      End: 'required',
    },
    Metric: 'required',
    Granularity: 'required',
    Filter: {
      Or: '<optional>',
      And: '<optional>',
      Not: '<optional>',
      Dimensions: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
      Tags: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    PredictionIntervalLevel: '<optional>',
  },
  'CostExplorer:listCostCategoryDefinitions': {
    EffectiveOn: '<optional>',
  },
  'CostExplorer:updateCostCategoryDefinition': {
    CostCategoryArn: 'required',
    RuleVersion: 'required',
    Rules: [
      {
        Rule: {
          Or: '<optional>',
          And: '<optional>',
          Not: '<optional>',
          Dimensions: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
          Tags: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
          CostCategories: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
        },
      },
    ],
  },
  'DAX:createCluster': {
    ClusterName: 'required',
    NodeType: 'required',
    ReplicationFactor: 'required',
    IamRoleArn: 'required',
    SSESpecification: '<optional>',
    Description: '<optional>',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    SubnetGroupName: '<optional>',
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    PreferredMaintenanceWindow: '<optional>',
    NotificationTopicArn: '<optional>',
    ParameterGroupName: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DAX:createParameterGroup': {
    ParameterGroupName: 'required',
    Description: '<optional>',
  },
  'DAX:createSubnetGroup': {
    SubnetGroupName: 'required',
    SubnetIds: ['required1', 'required2'],
    Description: '<optional>',
  },
  'DAX:decreaseReplicationFactor': {
    ClusterName: 'required',
    NewReplicationFactor: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    NodeIdsToRemove: '<optional>',
  },
  'DAX:deleteCluster': {
    ClusterName: 'required',
  },
  'DAX:deleteParameterGroup': {
    ParameterGroupName: 'required',
  },
  'DAX:deleteSubnetGroup': {
    SubnetGroupName: 'required',
  },
  'DAX:describeClusters': {
    ClusterNames: ['<optional1>', '<optional2>'],
  },
  'DAX:describeDefaultParameters': {},
  'DAX:describeEvents': {
    SourceName: '<optional>',
    SourceType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Duration: '<optional>',
  },
  'DAX:describeParameterGroups': {
    ParameterGroupNames: ['<optional1>', '<optional2>'],
  },
  'DAX:describeParameters': {
    ParameterGroupName: 'required',
    Source: '<optional>',
  },
  'DAX:describeSubnetGroups': {
    SubnetGroupNames: ['<optional1>', '<optional2>'],
  },
  'DAX:increaseReplicationFactor': {
    ClusterName: 'required',
    NewReplicationFactor: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
  },
  'DAX:listTags': {
    ResourceName: 'required',
  },
  'DAX:rebootNode': {
    ClusterName: 'required',
    NodeId: 'required',
  },
  'DAX:tagResource': {
    ResourceName: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DAX:untagResource': {
    ResourceName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'DAX:updateCluster': {
    ClusterName: 'required',
    Description: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    NotificationTopicArn: '<optional>',
    NotificationTopicStatus: '<optional>',
    ParameterGroupName: '<optional>',
    SecurityGroupIds: ['<optional1>', '<optional2>'],
  },
  'DAX:updateParameterGroup': {
    ParameterGroupName: 'required',
    ParameterNameValues: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
      },
    ],
  },
  'DAX:updateSubnetGroup': {
    SubnetGroupName: 'required',
    Description: '<optional>',
    SubnetIds: ['<optional1>', '<optional2>'],
  },
  'DLM:createLifecyclePolicy': {
    ExecutionRoleArn: 'required',
    Description: 'required',
    State: 'required',
    PolicyDetails: [
      {
        PolicyType: '<optional>',
        ResourceTypes: ['<optional1>', '<optional2>'],
        TargetTags: ['<optional1>', '<optional2>'],
        Schedules: [
          {
            Name: '<optional>',
            CopyTags: ['<optional1>', '<optional2>'],
            TagsToAdd: '<optional>',
            VariableTags: ['<optional1>', '<optional2>'],
            CreateRule: {
              Times: ['<optional1>', '<optional2>'],
            },
            RetainRule: '<optional>',
          },
        ],
        Parameters: [
          {
            ExcludeBootVolume: '<optional>',
          },
        ],
      },
    ],
  },
  'DLM:deleteLifecyclePolicy': {
    PolicyId: 'required',
  },
  'DLM:getLifecyclePolicies': {
    PolicyIds: ['<optional1>', '<optional2>'],
    State: '<optional>',
    ResourceTypes: ['<optional1>', '<optional2>'],
    TargetTags: ['<optional1>', '<optional2>'],
    TagsToAdd: '<optional>',
  },
  'DLM:getLifecyclePolicy': {
    PolicyId: 'required',
  },
  'DLM:listTagsForResource': {
    ResourceArn: 'required',
  },
  'DLM:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'DLM:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'DLM:updateLifecyclePolicy': {
    PolicyId: 'required',
    PolicyDetails: [
      {
        PolicyType: '<optional>',
        ResourceTypes: ['<optional1>', '<optional2>'],
        TargetTags: ['<optional1>', '<optional2>'],
        Schedules: [
          {
            Name: '<optional>',
            CopyTags: ['<optional1>', '<optional2>'],
            TagsToAdd: '<optional>',
            VariableTags: ['<optional1>', '<optional2>'],
            CreateRule: {
              Times: ['<optional1>', '<optional2>'],
            },
            RetainRule: '<optional>',
          },
        ],
        Parameters: [
          {
            ExcludeBootVolume: '<optional>',
          },
        ],
      },
    ],
    ExecutionRoleArn: '<optional>',
    State: '<optional>',
    Description: '<optional>',
  },
  'DMS:addTagsToResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DMS:applyPendingMaintenanceAction': {
    ReplicationInstanceArn: 'required',
    ApplyAction: 'required',
    OptInType: 'required',
  },
  'DMS:createEndpoint': {
    EndpointIdentifier: 'required',
    EndpointType: 'required',
    EngineName: 'required',
    DynamoDbSettings: ['<optional1>', '<optional2>'],
    ElasticsearchSettings: [
      {
        FullLoadErrorPercentage: '<optional>',
        ErrorRetryDuration: '<optional>',
      },
    ],
    Username: '<optional>',
    Password: '<optional>',
    ServerName: '<optional>',
    Port: '<optional>',
    DatabaseName: '<optional>',
    ExtraConnectionAttributes: ['<optional1>', '<optional2>'],
    KmsKeyId: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    CertificateArn: '<optional>',
    SslMode: '<optional>',
    ServiceAccessRoleArn: '<optional>',
    ExternalTableDefinition: '<optional>',
    S3Settings: [
      {
        ServiceAccessRoleArn: '<optional>',
        ExternalTableDefinition: '<optional>',
        CsvRowDelimiter: '<optional>',
        CsvDelimiter: '<optional>',
        BucketFolder: '<optional>',
        BucketName: '<optional>',
        CompressionType: '<optional>',
        EncryptionMode: '<optional>',
        ServerSideEncryptionKmsKeyId: '<optional>',
        DataFormat: '<optional>',
        EncodingType: '<optional>',
        DictPageSizeLimit: '<optional>',
        RowGroupLength: '<optional>',
        DataPageSize: '<optional>',
        ParquetVersion: '<optional>',
        EnableStatistics: ['<optional1>', '<optional2>'],
        IncludeOpForFullLoad: '<optional>',
        CdcInsertsOnly: '<optional>',
        TimestampColumnName: '<optional>',
      },
    ],
    DmsTransferSettings: [
      {
        ServiceAccessRoleArn: '<optional>',
        BucketName: '<optional>',
      },
    ],
    MongoDbSettings: [
      {
        Username: '<optional>',
        Password: '<optional>',
        ServerName: '<optional>',
        Port: '<optional>',
        DatabaseName: '<optional>',
        AuthType: '<optional>',
        AuthMechanism: '<optional>',
        NestingLevel: '<optional>',
        ExtractDocId: '<optional>',
        DocsToInvestigate: '<optional>',
        AuthSource: '<optional>',
        KmsKeyId: '<optional>',
      },
    ],
    KinesisSettings: [
      {
        StreamArn: '<optional>',
        MessageFormat: '<optional>',
        ServiceAccessRoleArn: '<optional>',
      },
    ],
    RedshiftSettings: [
      {
        AcceptAnyDate: '<optional>',
        AfterConnectScript: '<optional>',
        BucketFolder: '<optional>',
        BucketName: '<optional>',
        ConnectionTimeout: '<optional>',
        DatabaseName: '<optional>',
        DateFormat: '<optional>',
        EmptyAsNull: '<optional>',
        EncryptionMode: '<optional>',
        FileTransferUploadStreams: ['<optional1>', '<optional2>'],
        LoadTimeout: '<optional>',
        MaxFileSize: '<optional>',
        Password: '<optional>',
        Port: '<optional>',
        RemoveQuotes: ['<optional1>', '<optional2>'],
        ReplaceInvalidChars: ['<optional1>', '<optional2>'],
        ReplaceChars: ['<optional1>', '<optional2>'],
        ServerName: '<optional>',
        ServiceAccessRoleArn: '<optional>',
        ServerSideEncryptionKmsKeyId: '<optional>',
        TimeFormat: '<optional>',
        TrimBlanks: ['<optional1>', '<optional2>'],
        TruncateColumns: ['<optional1>', '<optional2>'],
        Username: '<optional>',
        WriteBufferSize: '<optional>',
      },
    ],
  },
  'DMS:createEventSubscription': {
    SubscriptionName: 'required',
    SnsTopicArn: 'required',
    SourceType: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    SourceIds: ['<optional1>', '<optional2>'],
    Enabled: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DMS:createReplicationInstance': {
    ReplicationInstanceIdentifier: 'required',
    ReplicationInstanceClass: 'required',
    AllocatedStorage: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    AvailabilityZone: '<optional>',
    ReplicationSubnetGroupIdentifier: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    MultiAZ: '<optional>',
    EngineVersion: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    PubliclyAccessible: '<optional>',
    DnsNameServers: ['<optional1>', '<optional2>'],
  },
  'DMS:createReplicationSubnetGroup': {
    ReplicationSubnetGroupIdentifier: 'required',
    ReplicationSubnetGroupDescription: 'required',
    SubnetIds: ['required1', 'required2'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DMS:createReplicationTask': {
    ReplicationTaskIdentifier: 'required',
    SourceEndpointArn: 'required',
    TargetEndpointArn: 'required',
    ReplicationInstanceArn: 'required',
    MigrationType: 'required',
    TableMappings: ['required1', 'required2'],
    ReplicationTaskSettings: ['<optional1>', '<optional2>'],
    CdcStartTime: '<optional>',
    CdcStartPosition: '<optional>',
    CdcStopPosition: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DMS:deleteCertificate': {
    CertificateArn: 'required',
  },
  'DMS:deleteConnection': {
    EndpointArn: 'required',
    ReplicationInstanceArn: 'required',
  },
  'DMS:deleteEndpoint': {
    EndpointArn: 'required',
  },
  'DMS:deleteEventSubscription': {
    SubscriptionName: 'required',
  },
  'DMS:deleteReplicationInstance': {
    ReplicationInstanceArn: 'required',
  },
  'DMS:deleteReplicationSubnetGroup': {
    ReplicationSubnetGroupIdentifier: 'required',
  },
  'DMS:deleteReplicationTask': {
    ReplicationTaskArn: 'required',
  },
  'DMS:describeAccountAttributes': {},
  'DMS:describeCertificates': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeConnections': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeEndpointTypes': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeEndpoints': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeEventCategories': {
    SourceType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'DMS:describeEventSubscriptions': {
    SubscriptionName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeEvents': {
    SourceIdentifier: '<optional>',
    SourceType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Duration: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeOrderableReplicationInstances': {
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describePendingMaintenanceActions': {
    ReplicationInstanceArn: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeRefreshSchemasStatus': {
    EndpointArn: 'required',
  },
  'DMS:describeReplicationInstanceTaskLogs': {
    ReplicationInstanceArn: 'required',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeReplicationInstances': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeReplicationSubnetGroups': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeReplicationTaskAssessmentResults': {
    ReplicationTaskArn: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeReplicationTasks': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    WithoutSettings: ['<optional1>', '<optional2>'],
  },
  'DMS:describeSchemas': {
    EndpointArn: 'required',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DMS:describeTableStatistics': {
    ReplicationTaskArn: 'required',
    MaxRecords: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'DMS:importCertificate': {
    CertificateIdentifier: 'required',
    CertificatePem: '<optional>',
    CertificateWallet: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DMS:listTagsForResource': {
    ResourceArn: 'required',
  },
  'DMS:modifyEndpoint': {
    EndpointArn: 'required',
    DynamoDbSettings: ['<optional1>', '<optional2>'],
    ElasticsearchSettings: [
      {
        FullLoadErrorPercentage: '<optional>',
        ErrorRetryDuration: '<optional>',
      },
    ],
    EndpointIdentifier: '<optional>',
    EndpointType: '<optional>',
    EngineName: '<optional>',
    Username: '<optional>',
    Password: '<optional>',
    ServerName: '<optional>',
    Port: '<optional>',
    DatabaseName: '<optional>',
    ExtraConnectionAttributes: ['<optional1>', '<optional2>'],
    CertificateArn: '<optional>',
    SslMode: '<optional>',
    ServiceAccessRoleArn: '<optional>',
    ExternalTableDefinition: '<optional>',
    S3Settings: [
      {
        ServiceAccessRoleArn: '<optional>',
        ExternalTableDefinition: '<optional>',
        CsvRowDelimiter: '<optional>',
        CsvDelimiter: '<optional>',
        BucketFolder: '<optional>',
        BucketName: '<optional>',
        CompressionType: '<optional>',
        EncryptionMode: '<optional>',
        ServerSideEncryptionKmsKeyId: '<optional>',
        DataFormat: '<optional>',
        EncodingType: '<optional>',
        DictPageSizeLimit: '<optional>',
        RowGroupLength: '<optional>',
        DataPageSize: '<optional>',
        ParquetVersion: '<optional>',
        EnableStatistics: ['<optional1>', '<optional2>'],
        IncludeOpForFullLoad: '<optional>',
        CdcInsertsOnly: '<optional>',
        TimestampColumnName: '<optional>',
      },
    ],
    DmsTransferSettings: [
      {
        ServiceAccessRoleArn: '<optional>',
        BucketName: '<optional>',
      },
    ],
    MongoDbSettings: [
      {
        Username: '<optional>',
        Password: '<optional>',
        ServerName: '<optional>',
        Port: '<optional>',
        DatabaseName: '<optional>',
        AuthType: '<optional>',
        AuthMechanism: '<optional>',
        NestingLevel: '<optional>',
        ExtractDocId: '<optional>',
        DocsToInvestigate: '<optional>',
        AuthSource: '<optional>',
        KmsKeyId: '<optional>',
      },
    ],
    KinesisSettings: [
      {
        StreamArn: '<optional>',
        MessageFormat: '<optional>',
        ServiceAccessRoleArn: '<optional>',
      },
    ],
    RedshiftSettings: [
      {
        AcceptAnyDate: '<optional>',
        AfterConnectScript: '<optional>',
        BucketFolder: '<optional>',
        BucketName: '<optional>',
        ConnectionTimeout: '<optional>',
        DatabaseName: '<optional>',
        DateFormat: '<optional>',
        EmptyAsNull: '<optional>',
        EncryptionMode: '<optional>',
        FileTransferUploadStreams: ['<optional1>', '<optional2>'],
        LoadTimeout: '<optional>',
        MaxFileSize: '<optional>',
        Password: '<optional>',
        Port: '<optional>',
        RemoveQuotes: ['<optional1>', '<optional2>'],
        ReplaceInvalidChars: ['<optional1>', '<optional2>'],
        ReplaceChars: ['<optional1>', '<optional2>'],
        ServerName: '<optional>',
        ServiceAccessRoleArn: '<optional>',
        ServerSideEncryptionKmsKeyId: '<optional>',
        TimeFormat: '<optional>',
        TrimBlanks: ['<optional1>', '<optional2>'],
        TruncateColumns: ['<optional1>', '<optional2>'],
        Username: '<optional>',
        WriteBufferSize: '<optional>',
      },
    ],
  },
  'DMS:modifyEventSubscription': {
    SubscriptionName: 'required',
    SnsTopicArn: '<optional>',
    SourceType: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    Enabled: '<optional>',
  },
  'DMS:modifyReplicationInstance': {
    ReplicationInstanceArn: 'required',
    AllocatedStorage: '<optional>',
    ApplyImmediately: '<optional>',
    ReplicationInstanceClass: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    PreferredMaintenanceWindow: '<optional>',
    MultiAZ: '<optional>',
    EngineVersion: '<optional>',
    AllowMajorVersionUpgrade: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    ReplicationInstanceIdentifier: '<optional>',
  },
  'DMS:modifyReplicationSubnetGroup': {
    ReplicationSubnetGroupIdentifier: 'required',
    SubnetIds: ['required1', 'required2'],
    ReplicationSubnetGroupDescription: '<optional>',
  },
  'DMS:modifyReplicationTask': {
    ReplicationTaskArn: 'required',
    ReplicationTaskIdentifier: '<optional>',
    MigrationType: '<optional>',
    TableMappings: ['<optional1>', '<optional2>'],
    ReplicationTaskSettings: ['<optional1>', '<optional2>'],
    CdcStartTime: '<optional>',
    CdcStartPosition: '<optional>',
    CdcStopPosition: '<optional>',
  },
  'DMS:rebootReplicationInstance': {
    ReplicationInstanceArn: 'required',
    ForceFailover: '<optional>',
  },
  'DMS:refreshSchemas': {
    EndpointArn: 'required',
    ReplicationInstanceArn: 'required',
  },
  'DMS:reloadTables': {
    ReplicationTaskArn: 'required',
    TablesToReload: {
      SchemaName: '<optional>',
      TableName: '<optional>',
    },
    ReloadOption: '<optional>',
  },
  'DMS:removeTagsFromResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'DMS:startReplicationTask': {
    ReplicationTaskArn: 'required',
    StartReplicationTaskType: 'required',
    CdcStartTime: '<optional>',
    CdcStartPosition: '<optional>',
    CdcStopPosition: '<optional>',
  },
  'DMS:startReplicationTaskAssessment': {
    ReplicationTaskArn: 'required',
  },
  'DMS:stopReplicationTask': {
    ReplicationTaskArn: 'required',
  },
  'DMS:testConnection': {
    ReplicationInstanceArn: 'required',
    EndpointArn: 'required',
  },
  'DataExchange:cancelJob': {
    JobId: 'required',
  },
  'DataExchange:createDataSet': {
    AssetType: 'required',
    Description: 'required',
    Name: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'DataExchange:createJob': {
    Type: 'required',
    Details: [
      {
        ExportAssetToSignedUrl: '<optional>',
        ExportAssetsToS3: {
          AssetDestinations: [
            {
              Key: '<optional>',
            },
          ],
        },
        ImportAssetFromSignedUrl: '<optional>',
        ImportAssetsFromS3: '<optional>',
      },
    ],
  },
  'DataExchange:createRevision': {
    DataSetId: 'required',
    Comment: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'DataExchange:deleteAsset': {
    RevisionId: 'required',
    AssetId: 'required',
    DataSetId: 'required',
  },
  'DataExchange:deleteDataSet': {
    DataSetId: 'required',
  },
  'DataExchange:deleteRevision': {
    RevisionId: 'required',
    DataSetId: 'required',
  },
  'DataExchange:getAsset': {
    RevisionId: 'required',
    AssetId: 'required',
    DataSetId: 'required',
  },
  'DataExchange:getDataSet': {
    DataSetId: 'required',
  },
  'DataExchange:getJob': {
    JobId: 'required',
  },
  'DataExchange:getRevision': {
    RevisionId: 'required',
    DataSetId: 'required',
  },
  'DataExchange:listDataSetRevisions': {
    DataSetId: 'required',
  },
  'DataExchange:listDataSets': {
    Origin: '<optional>',
  },
  'DataExchange:listJobs': {
    DataSetId: '<optional>',
    RevisionId: '<optional>',
  },
  'DataExchange:listRevisionAssets': {
    RevisionId: 'required',
    DataSetId: 'required',
  },
  'DataExchange:listTagsForResource': {
    ResourceArn: 'required',
  },
  'DataExchange:startJob': {
    JobId: 'required',
  },
  'DataExchange:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'DataExchange:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'DataExchange:updateAsset': {
    RevisionId: 'required',
    AssetId: 'required',
    DataSetId: 'required',
    Name: 'required',
  },
  'DataExchange:updateDataSet': {
    DataSetId: 'required',
    Description: '<optional>',
    Name: '<optional>',
  },
  'DataExchange:updateRevision': {
    RevisionId: 'required',
    DataSetId: 'required',
    Comment: '<optional>',
    Finalized: '<optional>',
  },
  'DataPipeline:activatePipeline': {
    pipelineId: 'required',
    parameterValues: ['<optional1>', '<optional2>'],
    startTimestamp: '<optional>',
  },
  'DataPipeline:addTags': {
    pipelineId: 'required',
    tags: [
      {
        key: 'required',
        value: 'required',
      },
    ],
  },
  'DataPipeline:createPipeline': {
    name: 'required',
    uniqueId: 'required',
    tags: ['<optional1>', '<optional2>'],
    description: '<optional>',
  },
  'DataPipeline:deactivatePipeline': {
    pipelineId: 'required',
    cancelActive: '<optional>',
  },
  'DataPipeline:deletePipeline': {
    pipelineId: 'required',
  },
  'DataPipeline:describeObjects': {
    pipelineId: 'required',
    objectIds: ['required1', 'required2'],
    evaluateExpressions: ['<optional1>', '<optional2>'],
    marker: '<optional>',
  },
  'DataPipeline:describePipelines': {
    pipelineIds: ['required1', 'required2'],
  },
  'DataPipeline:evaluateExpression': {
    pipelineId: 'required',
    objectId: 'required',
    expression: 'required',
  },
  'DataPipeline:getPipelineDefinition': {
    pipelineId: 'required',
    version: '<optional>',
  },
  'DataPipeline:listPipelines': {
    marker: '<optional>',
  },
  'DataPipeline:pollForTask': {
    workerGroup: 'required',
    hostname: '<optional>',
    instanceIdentity: {
      document: '<optional>',
      signature: '<optional>',
    },
  },
  'DataPipeline:putPipelineDefinition': {
    pipelineId: 'required',
    pipelineObjects: [
      {
        fields: [
          {
            stringValue: '<optional>',
            refValue: '<optional>',
          },
        ],
      },
    ],
    parameterObjects: ['<optional1>', '<optional2>'],
    parameterValues: ['<optional1>', '<optional2>'],
  },
  'DataPipeline:queryObjects': {
    pipelineId: 'required',
    sphere: 'required',
    query: {
      selectors: [
        {
          fieldName: '<optional>',
          operator: {
            type: '<optional>',
            values: ['<optional1>', '<optional2>'],
          },
        },
      ],
    },
    marker: '<optional>',
    limit: '<optional>',
  },
  'DataPipeline:removeTags': {
    pipelineId: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'DataPipeline:reportTaskProgress': {
    taskId: 'required',
    fields: [
      {
        stringValue: '<optional>',
        refValue: '<optional>',
      },
    ],
  },
  'DataPipeline:reportTaskRunnerHeartbeat': {
    taskrunnerId: 'required',
    workerGroup: '<optional>',
    hostname: '<optional>',
  },
  'DataPipeline:setStatus': {
    pipelineId: 'required',
    objectIds: ['required1', 'required2'],
    status: 'required',
  },
  'DataPipeline:setTaskStatus': {
    taskId: 'required',
    taskStatus: 'required',
    errorId: '<optional>',
    errorMessage: '<optional>',
    errorStackTrace: '<optional>',
  },
  'DataPipeline:validatePipelineDefinition': {
    pipelineId: 'required',
    pipelineObjects: [
      {
        fields: [
          {
            stringValue: '<optional>',
            refValue: '<optional>',
          },
        ],
      },
    ],
    parameterObjects: ['<optional1>', '<optional2>'],
    parameterValues: ['<optional1>', '<optional2>'],
  },
  'DataSync:cancelTaskExecution': {
    TaskExecutionArn: 'required',
  },
  'DataSync:createAgent': {
    ActivationKey: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    AgentName: '<optional>',
  },
  'DataSync:createLocationEfs': {
    EfsFilesystemArn: 'required',
    Ec2Config: {
      SubnetArn: 'required',
      SecurityGroupArns: ['required1', 'required2'],
    },
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    Subdirectory: '<optional>',
  },
  'DataSync:createLocationNfs': {
    Subdirectory: 'required',
    ServerHostname: 'required',
    OnPremConfig: {
      AgentArns: ['required1', 'required2'],
    },
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    MountOptions: [
      {
        Version: '<optional>',
      },
    ],
  },
  'DataSync:createLocationS3': {
    S3BucketArn: 'required',
    S3Config: {
      BucketAccessRoleArn: 'required',
    },
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    Subdirectory: '<optional>',
  },
  'DataSync:createLocationSmb': {
    Subdirectory: 'required',
    ServerHostname: 'required',
    User: 'required',
    Password: 'required',
    AgentArns: ['required1', 'required2'],
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    Domain: '<optional>',
    MountOptions: [
      {
        Version: '<optional>',
      },
    ],
  },
  'DataSync:createTask': {
    SourceLocationArn: 'required',
    DestinationLocationArn: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    CloudWatchLogGroupArn: '<optional>',
    Name: '<optional>',
    Options: [
      {
        VerifyMode: '<optional>',
        Atime: '<optional>',
        Mtime: '<optional>',
        Uid: '<optional>',
        Gid: '<optional>',
        PreserveDeletedFiles: ['<optional1>', '<optional2>'],
        PreserveDevices: ['<optional1>', '<optional2>'],
        PosixPermissions: ['<optional1>', '<optional2>'],
        BytesPerSecond: '<optional>',
      },
    ],
    Excludes: [
      {
        FilterType: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DataSync:deleteAgent': {
    AgentArn: 'required',
  },
  'DataSync:deleteLocation': {
    LocationArn: 'required',
  },
  'DataSync:deleteTask': {
    TaskArn: 'required',
  },
  'DataSync:describeAgent': {
    AgentArn: 'required',
  },
  'DataSync:describeLocationEfs': {
    LocationArn: 'required',
  },
  'DataSync:describeLocationNfs': {
    LocationArn: 'required',
  },
  'DataSync:describeLocationS3': {
    LocationArn: 'required',
  },
  'DataSync:describeLocationSmb': {
    LocationArn: 'required',
  },
  'DataSync:describeTask': {
    TaskArn: 'required',
  },
  'DataSync:describeTaskExecution': {
    TaskExecutionArn: 'required',
  },
  'DataSync:listAgents': {},
  'DataSync:listLocations': {},
  'DataSync:listTagsForResource': {
    ResourceArn: 'required',
  },
  'DataSync:listTaskExecutions': {
    TaskArn: '<optional>',
  },
  'DataSync:listTasks': {},
  'DataSync:startTaskExecution': {
    TaskArn: 'required',
    OverrideOptions: [
      {
        VerifyMode: '<optional>',
        Atime: '<optional>',
        Mtime: '<optional>',
        Uid: '<optional>',
        Gid: '<optional>',
        PreserveDeletedFiles: ['<optional1>', '<optional2>'],
        PreserveDevices: ['<optional1>', '<optional2>'],
        PosixPermissions: ['<optional1>', '<optional2>'],
        BytesPerSecond: '<optional>',
      },
    ],
    Includes: [
      {
        FilterType: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DataSync:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'DataSync:untagResource': {
    ResourceArn: 'required',
    Keys: ['required1', 'required2'],
  },
  'DataSync:updateAgent': {
    AgentArn: 'required',
    Name: '<optional>',
  },
  'DataSync:updateTask': {
    TaskArn: 'required',
    Options: [
      {
        VerifyMode: '<optional>',
        Atime: '<optional>',
        Mtime: '<optional>',
        Uid: '<optional>',
        Gid: '<optional>',
        PreserveDeletedFiles: ['<optional1>', '<optional2>'],
        PreserveDevices: ['<optional1>', '<optional2>'],
        PosixPermissions: ['<optional1>', '<optional2>'],
        BytesPerSecond: '<optional>',
      },
    ],
    Excludes: [
      {
        FilterType: '<optional>',
        Value: '<optional>',
      },
    ],
    Name: '<optional>',
    CloudWatchLogGroupArn: '<optional>',
  },
  'Detective:acceptInvitation': {
    GraphArn: 'required',
  },
  'Detective:createGraph': {},
  'Detective:createMembers': {
    GraphArn: 'required',
    Accounts: [
      {
        AccountId: 'required',
        EmailAddress: 'required',
      },
    ],
    Message: '<optional>',
  },
  'Detective:deleteGraph': {
    GraphArn: 'required',
  },
  'Detective:deleteMembers': {
    GraphArn: 'required',
    AccountIds: ['required1', 'required2'],
  },
  'Detective:disassociateMembership': {
    GraphArn: 'required',
  },
  'Detective:getMembers': {
    GraphArn: 'required',
    AccountIds: ['required1', 'required2'],
  },
  'Detective:listGraphs': {},
  'Detective:listInvitations': {},
  'Detective:listMembers': {
    GraphArn: 'required',
  },
  'Detective:rejectInvitation': {
    GraphArn: 'required',
  },
  'DeviceFarm:createDevicePool': {
    projectArn: 'required',
    name: 'required',
    rules: [
      {
        attribute: '<optional>',
        operator: '<optional>',
        value: '<optional>',
      },
    ],
    description: '<optional>',
    maxDevices: ['<optional1>', '<optional2>'],
  },
  'DeviceFarm:createInstanceProfile': {
    name: 'required',
    description: '<optional>',
    packageCleanup: '<optional>',
    excludeAppPackagesFromCleanup: '<optional>',
    rebootAfterUse: '<optional>',
  },
  'DeviceFarm:createNetworkProfile': {
    projectArn: 'required',
    name: 'required',
    description: '<optional>',
    type: '<optional>',
    uplinkBandwidthBits: ['<optional1>', '<optional2>'],
    downlinkBandwidthBits: ['<optional1>', '<optional2>'],
    uplinkDelayMs: ['<optional1>', '<optional2>'],
    downlinkDelayMs: ['<optional1>', '<optional2>'],
    uplinkJitterMs: ['<optional1>', '<optional2>'],
    downlinkJitterMs: ['<optional1>', '<optional2>'],
    uplinkLossPercent: '<optional>',
    downlinkLossPercent: '<optional>',
  },
  'DeviceFarm:createProject': {
    name: 'required',
    defaultJobTimeoutMinutes: ['<optional1>', '<optional2>'],
  },
  'DeviceFarm:createRemoteAccessSession': {
    projectArn: 'required',
    deviceArn: 'required',
    instanceArn: '<optional>',
    sshPublicKey: '<optional>',
    remoteDebugEnabled: '<optional>',
    remoteRecordEnabled: '<optional>',
    remoteRecordAppArn: '<optional>',
    name: '<optional>',
    clientId: '<optional>',
    configuration: {
      billingMethod: '<optional>',
      vpceConfigurationArns: ['<optional1>', '<optional2>'],
    },
    interactionMode: '<optional>',
    skipAppResign: '<optional>',
  },
  'DeviceFarm:createTestGridProject': {
    name: 'required',
    description: '<optional>',
  },
  'DeviceFarm:createTestGridUrl': {
    projectArn: 'required',
    expiresInSeconds: ['required1', 'required2'],
  },
  'DeviceFarm:createUpload': {
    projectArn: 'required',
    name: 'required',
    type: 'required',
    contentType: '<optional>',
  },
  'DeviceFarm:createVPCEConfiguration': {
    vpceConfigurationName: 'required',
    vpceServiceName: 'required',
    serviceDnsName: 'required',
    vpceConfigurationDescription: '<optional>',
  },
  'DeviceFarm:deleteDevicePool': {
    arn: 'required',
  },
  'DeviceFarm:deleteInstanceProfile': {
    arn: 'required',
  },
  'DeviceFarm:deleteNetworkProfile': {
    arn: 'required',
  },
  'DeviceFarm:deleteProject': {
    arn: 'required',
  },
  'DeviceFarm:deleteRemoteAccessSession': {
    arn: 'required',
  },
  'DeviceFarm:deleteRun': {
    arn: 'required',
  },
  'DeviceFarm:deleteTestGridProject': {
    projectArn: 'required',
  },
  'DeviceFarm:deleteUpload': {
    arn: 'required',
  },
  'DeviceFarm:deleteVPCEConfiguration': {
    arn: 'required',
  },
  'DeviceFarm:getAccountSettings': {},
  'DeviceFarm:getDevice': {
    arn: 'required',
  },
  'DeviceFarm:getDeviceInstance': {
    arn: 'required',
  },
  'DeviceFarm:getDevicePool': {
    arn: 'required',
  },
  'DeviceFarm:getDevicePoolCompatibility': {
    devicePoolArn: 'required',
    test: {
      testPackageArn: '<optional>',
      testSpecArn: '<optional>',
      filter: '<optional>',
      parameters: ['<optional1>', '<optional2>'],
    },
    configuration: {
      extraDataPackageArn: '<optional>',
      networkProfileArn: '<optional>',
      locale: '<optional>',
      location: '<optional>',
      vpceConfigurationArns: ['<optional1>', '<optional2>'],
      customerArtifactPaths: [
        {
          iosPaths: ['<optional1>', '<optional2>'],
          androidPaths: ['<optional1>', '<optional2>'],
          deviceHostPaths: ['<optional1>', '<optional2>'],
        },
      ],
      radios: [
        {
          wifi: '<optional>',
          bluetooth: '<optional>',
          nfc: '<optional>',
          gps: ['<optional1>', '<optional2>'],
        },
      ],
      auxiliaryApps: ['<optional1>', '<optional2>'],
      billingMethod: '<optional>',
    },
    appArn: '<optional>',
    testType: '<optional>',
  },
  'DeviceFarm:getInstanceProfile': {
    arn: 'required',
  },
  'DeviceFarm:getJob': {
    arn: 'required',
  },
  'DeviceFarm:getNetworkProfile': {
    arn: 'required',
  },
  'DeviceFarm:getOfferingStatus': {},
  'DeviceFarm:getProject': {
    arn: 'required',
  },
  'DeviceFarm:getRemoteAccessSession': {
    arn: 'required',
  },
  'DeviceFarm:getRun': {
    arn: 'required',
  },
  'DeviceFarm:getSuite': {
    arn: 'required',
  },
  'DeviceFarm:getTest': {
    arn: 'required',
  },
  'DeviceFarm:getTestGridProject': {
    projectArn: 'required',
  },
  'DeviceFarm:getTestGridSession': {
    projectArn: '<optional>',
    sessionId: '<optional>',
    sessionArn: '<optional>',
  },
  'DeviceFarm:getUpload': {
    arn: 'required',
  },
  'DeviceFarm:getVPCEConfiguration': {
    arn: 'required',
  },
  'DeviceFarm:installToRemoteAccessSession': {
    remoteAccessSessionArn: 'required',
    appArn: 'required',
  },
  'DeviceFarm:listArtifacts': {
    arn: 'required',
    type: 'required',
  },
  'DeviceFarm:listDeviceInstances': {},
  'DeviceFarm:listDevicePools': {
    arn: 'required',
    type: '<optional>',
  },
  'DeviceFarm:listDevices': {
    arn: '<optional>',
    filters: [
      {
        attribute: '<optional>',
        operator: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'DeviceFarm:listInstanceProfiles': {},
  'DeviceFarm:listJobs': {
    arn: 'required',
  },
  'DeviceFarm:listNetworkProfiles': {
    arn: 'required',
    type: '<optional>',
  },
  'DeviceFarm:listOfferingPromotions': {},
  'DeviceFarm:listOfferingTransactions': {},
  'DeviceFarm:listOfferings': {},
  'DeviceFarm:listProjects': {
    arn: '<optional>',
  },
  'DeviceFarm:listRemoteAccessSessions': {
    arn: 'required',
  },
  'DeviceFarm:listRuns': {
    arn: 'required',
  },
  'DeviceFarm:listSamples': {
    arn: 'required',
  },
  'DeviceFarm:listSuites': {
    arn: 'required',
  },
  'DeviceFarm:listTagsForResource': {
    ResourceARN: 'required',
  },
  'DeviceFarm:listTestGridProjects': {
    maxResult: '<optional>',
  },
  'DeviceFarm:listTestGridSessionActions': {
    sessionArn: 'required',
    maxResult: '<optional>',
  },
  'DeviceFarm:listTestGridSessionArtifacts': {
    sessionArn: 'required',
    type: '<optional>',
    maxResult: '<optional>',
  },
  'DeviceFarm:listTestGridSessions': {
    projectArn: 'required',
    status: '<optional>',
    creationTimeAfter: '<optional>',
    creationTimeBefore: '<optional>',
    endTimeAfter: '<optional>',
    endTimeBefore: '<optional>',
    maxResult: '<optional>',
  },
  'DeviceFarm:listTests': {
    arn: 'required',
  },
  'DeviceFarm:listUniqueProblems': {
    arn: 'required',
  },
  'DeviceFarm:listUploads': {
    arn: 'required',
    type: '<optional>',
  },
  'DeviceFarm:listVPCEConfigurations': {},
  'DeviceFarm:purchaseOffering': {
    offeringId: '<optional>',
    quantity: '<optional>',
    offeringPromotionId: '<optional>',
  },
  'DeviceFarm:renewOffering': {
    offeringId: '<optional>',
    quantity: '<optional>',
  },
  'DeviceFarm:scheduleRun': {
    projectArn: 'required',
    test: {
      testPackageArn: '<optional>',
      testSpecArn: '<optional>',
      filter: '<optional>',
      parameters: ['<optional1>', '<optional2>'],
    },
    deviceSelectionConfiguration: {
      filters: [
        {
          attribute: '<optional>',
          operator: '<optional>',
          values: ['<optional1>', '<optional2>'],
        },
      ],
    },
    configuration: {
      extraDataPackageArn: '<optional>',
      networkProfileArn: '<optional>',
      locale: '<optional>',
      location: '<optional>',
      vpceConfigurationArns: ['<optional1>', '<optional2>'],
      customerArtifactPaths: [
        {
          iosPaths: ['<optional1>', '<optional2>'],
          androidPaths: ['<optional1>', '<optional2>'],
          deviceHostPaths: ['<optional1>', '<optional2>'],
        },
      ],
      radios: [
        {
          wifi: '<optional>',
          bluetooth: '<optional>',
          nfc: '<optional>',
          gps: ['<optional1>', '<optional2>'],
        },
      ],
      auxiliaryApps: ['<optional1>', '<optional2>'],
      billingMethod: '<optional>',
    },
    appArn: '<optional>',
    devicePoolArn: '<optional>',
    name: '<optional>',
    executionConfiguration: {
      jobTimeoutMinutes: ['<optional1>', '<optional2>'],
      accountsCleanup: '<optional>',
      appPackagesCleanup: '<optional>',
      videoCapture: '<optional>',
      skipAppResign: '<optional>',
    },
  },
  'DeviceFarm:stopJob': {
    arn: 'required',
  },
  'DeviceFarm:stopRemoteAccessSession': {
    arn: 'required',
  },
  'DeviceFarm:stopRun': {
    arn: 'required',
  },
  'DeviceFarm:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'DeviceFarm:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'DeviceFarm:updateDeviceInstance': {
    arn: 'required',
    profileArn: '<optional>',
    labels: ['<optional1>', '<optional2>'],
  },
  'DeviceFarm:updateDevicePool': {
    arn: 'required',
    name: '<optional>',
    description: '<optional>',
    rules: [
      {
        attribute: '<optional>',
        operator: '<optional>',
        value: '<optional>',
      },
    ],
    maxDevices: ['<optional1>', '<optional2>'],
    clearMaxDevices: ['<optional1>', '<optional2>'],
  },
  'DeviceFarm:updateInstanceProfile': {
    arn: 'required',
    name: '<optional>',
    description: '<optional>',
    packageCleanup: '<optional>',
    excludeAppPackagesFromCleanup: '<optional>',
    rebootAfterUse: '<optional>',
  },
  'DeviceFarm:updateNetworkProfile': {
    arn: 'required',
    name: '<optional>',
    description: '<optional>',
    type: '<optional>',
    uplinkBandwidthBits: ['<optional1>', '<optional2>'],
    downlinkBandwidthBits: ['<optional1>', '<optional2>'],
    uplinkDelayMs: ['<optional1>', '<optional2>'],
    downlinkDelayMs: ['<optional1>', '<optional2>'],
    uplinkJitterMs: ['<optional1>', '<optional2>'],
    downlinkJitterMs: ['<optional1>', '<optional2>'],
    uplinkLossPercent: '<optional>',
    downlinkLossPercent: '<optional>',
  },
  'DeviceFarm:updateProject': {
    arn: 'required',
    name: '<optional>',
    defaultJobTimeoutMinutes: ['<optional1>', '<optional2>'],
  },
  'DeviceFarm:updateTestGridProject': {
    projectArn: 'required',
    name: '<optional>',
    description: '<optional>',
  },
  'DeviceFarm:updateUpload': {
    arn: 'required',
    name: '<optional>',
    contentType: '<optional>',
    editContent: '<optional>',
  },
  'DeviceFarm:updateVPCEConfiguration': {
    arn: 'required',
    vpceConfigurationName: '<optional>',
    vpceServiceName: '<optional>',
    serviceDnsName: '<optional>',
    vpceConfigurationDescription: '<optional>',
  },
  'DirectConnect:acceptDirectConnectGatewayAssociationProposal': {
    directConnectGatewayId: 'required',
    proposalId: 'required',
    associatedGatewayOwnerAccount: 'required',
    overrideAllowedPrefixesToDirectConnectGateway: {
      cidr: '<optional>',
    },
  },
  'DirectConnect:allocateConnectionOnInterconnect': {
    bandwidth: 'required',
    connectionName: 'required',
    ownerAccount: 'required',
    interconnectId: 'required',
    vlan: 'required',
  },
  'DirectConnect:allocateHostedConnection': {
    connectionId: 'required',
    ownerAccount: 'required',
    bandwidth: 'required',
    connectionName: 'required',
    vlan: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
  },
  'DirectConnect:allocatePrivateVirtualInterface': {
    connectionId: 'required',
    ownerAccount: 'required',
    newPrivateVirtualInterfaceAllocation: {
      mtu: '<optional>',
      authKey: '<optional>',
      amazonAddress: '<optional>',
      addressFamily: '<optional>',
      customerAddress: '<optional>',
      tags: [
        {
          value: '<optional>',
        },
      ],
    },
  },
  'DirectConnect:allocatePublicVirtualInterface': {
    connectionId: 'required',
    ownerAccount: 'required',
    newPublicVirtualInterfaceAllocation: {
      authKey: '<optional>',
      amazonAddress: '<optional>',
      customerAddress: '<optional>',
      addressFamily: '<optional>',
      routeFilterPrefixes: [
        {
          cidr: '<optional>',
        },
      ],
      tags: [
        {
          value: '<optional>',
        },
      ],
    },
  },
  'DirectConnect:allocateTransitVirtualInterface': {
    connectionId: 'required',
    ownerAccount: 'required',
    newTransitVirtualInterfaceAllocation: {
      virtualInterfaceName: '<optional>',
      vlan: '<optional>',
      asn: '<optional>',
      mtu: '<optional>',
      authKey: '<optional>',
      amazonAddress: '<optional>',
      customerAddress: '<optional>',
      addressFamily: '<optional>',
      tags: [
        {
          value: '<optional>',
        },
      ],
    },
  },
  'DirectConnect:associateConnectionWithLag': {
    connectionId: 'required',
    lagId: 'required',
  },
  'DirectConnect:associateHostedConnection': {
    connectionId: 'required',
    parentConnectionId: 'required',
  },
  'DirectConnect:associateVirtualInterface': {
    virtualInterfaceId: 'required',
    connectionId: 'required',
  },
  'DirectConnect:confirmConnection': {
    connectionId: 'required',
  },
  'DirectConnect:confirmPrivateVirtualInterface': {
    virtualInterfaceId: 'required',
    virtualGatewayId: '<optional>',
    directConnectGatewayId: '<optional>',
  },
  'DirectConnect:confirmPublicVirtualInterface': {
    virtualInterfaceId: 'required',
  },
  'DirectConnect:confirmTransitVirtualInterface': {
    virtualInterfaceId: 'required',
    directConnectGatewayId: 'required',
  },
  'DirectConnect:createBGPPeer': {
    virtualInterfaceId: '<optional>',
    newBGPPeer: {
      asn: '<optional>',
      authKey: '<optional>',
      addressFamily: '<optional>',
      amazonAddress: '<optional>',
      customerAddress: '<optional>',
    },
  },
  'DirectConnect:createConnection': {
    location: 'required',
    bandwidth: 'required',
    connectionName: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
    lagId: '<optional>',
  },
  'DirectConnect:createDirectConnectGateway': {
    directConnectGatewayName: 'required',
    amazonSideAsn: '<optional>',
  },
  'DirectConnect:createDirectConnectGatewayAssociation': {
    directConnectGatewayId: 'required',
    gatewayId: '<optional>',
    addAllowedPrefixesToDirectConnectGateway: {
      cidr: '<optional>',
    },
    virtualGatewayId: '<optional>',
  },
  'DirectConnect:createDirectConnectGatewayAssociationProposal': {
    directConnectGatewayId: 'required',
    directConnectGatewayOwnerAccount: 'required',
    gatewayId: 'required',
    addAllowedPrefixesToDirectConnectGateway: {
      cidr: '<optional>',
    },
    removeAllowedPrefixesToDirectConnectGateway: {
      cidr: '<optional>',
    },
  },
  'DirectConnect:createInterconnect': {
    interconnectName: 'required',
    bandwidth: 'required',
    location: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
    lagId: '<optional>',
  },
  'DirectConnect:createLag': {
    numberOfConnections: ['required1', 'required2'],
    location: 'required',
    connectionsBandwidth: 'required',
    lagName: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
    childConnectionTags: [
      {
        value: '<optional>',
      },
    ],
    connectionId: '<optional>',
  },
  'DirectConnect:createPrivateVirtualInterface': {
    connectionId: 'required',
    newPrivateVirtualInterface: {
      mtu: '<optional>',
      authKey: '<optional>',
      amazonAddress: '<optional>',
      customerAddress: '<optional>',
      addressFamily: '<optional>',
      virtualGatewayId: '<optional>',
      directConnectGatewayId: '<optional>',
      tags: [
        {
          value: '<optional>',
        },
      ],
    },
  },
  'DirectConnect:createPublicVirtualInterface': {
    connectionId: 'required',
    newPublicVirtualInterface: {
      authKey: '<optional>',
      amazonAddress: '<optional>',
      customerAddress: '<optional>',
      addressFamily: '<optional>',
      routeFilterPrefixes: [
        {
          cidr: '<optional>',
        },
      ],
      tags: [
        {
          value: '<optional>',
        },
      ],
    },
  },
  'DirectConnect:createTransitVirtualInterface': {
    connectionId: 'required',
    newTransitVirtualInterface: {
      virtualInterfaceName: '<optional>',
      vlan: '<optional>',
      asn: '<optional>',
      mtu: '<optional>',
      authKey: '<optional>',
      amazonAddress: '<optional>',
      customerAddress: '<optional>',
      addressFamily: '<optional>',
      directConnectGatewayId: '<optional>',
      tags: [
        {
          value: '<optional>',
        },
      ],
    },
  },
  'DirectConnect:deleteBGPPeer': {
    virtualInterfaceId: '<optional>',
    asn: '<optional>',
    customerAddress: '<optional>',
    bgpPeerId: '<optional>',
  },
  'DirectConnect:deleteConnection': {
    connectionId: 'required',
  },
  'DirectConnect:deleteDirectConnectGateway': {
    directConnectGatewayId: 'required',
  },
  'DirectConnect:deleteDirectConnectGatewayAssociation': {
    associationId: '<optional>',
    directConnectGatewayId: '<optional>',
    virtualGatewayId: '<optional>',
  },
  'DirectConnect:deleteDirectConnectGatewayAssociationProposal': {
    proposalId: 'required',
  },
  'DirectConnect:deleteInterconnect': {
    interconnectId: 'required',
  },
  'DirectConnect:deleteLag': {
    lagId: 'required',
  },
  'DirectConnect:deleteVirtualInterface': {
    virtualInterfaceId: 'required',
  },
  'DirectConnect:describeConnectionLoa': {
    connectionId: 'required',
    providerName: '<optional>',
    loaContentType: '<optional>',
  },
  'DirectConnect:describeConnections': {
    connectionId: '<optional>',
  },
  'DirectConnect:describeConnectionsOnInterconnect': {
    interconnectId: 'required',
  },
  'DirectConnect:describeDirectConnectGatewayAssociationProposals': {
    directConnectGatewayId: '<optional>',
    proposalId: '<optional>',
    associatedGatewayId: '<optional>',
  },
  'DirectConnect:describeDirectConnectGatewayAssociations': {
    associationId: '<optional>',
    associatedGatewayId: '<optional>',
    directConnectGatewayId: '<optional>',
    virtualGatewayId: '<optional>',
  },
  'DirectConnect:describeDirectConnectGatewayAttachments': {
    directConnectGatewayId: '<optional>',
    virtualInterfaceId: '<optional>',
  },
  'DirectConnect:describeDirectConnectGateways': {
    directConnectGatewayId: '<optional>',
  },
  'DirectConnect:describeHostedConnections': {
    connectionId: 'required',
  },
  'DirectConnect:describeInterconnectLoa': {
    interconnectId: 'required',
    providerName: '<optional>',
    loaContentType: '<optional>',
  },
  'DirectConnect:describeInterconnects': {
    interconnectId: '<optional>',
  },
  'DirectConnect:describeLags': {
    lagId: '<optional>',
  },
  'DirectConnect:describeLoa': {
    connectionId: 'required',
    providerName: '<optional>',
    loaContentType: '<optional>',
  },
  'DirectConnect:describeLocations': {},
  'DirectConnect:describeTags': {
    resourceArns: ['required1', 'required2'],
  },
  'DirectConnect:describeVirtualGateways': {},
  'DirectConnect:describeVirtualInterfaces': {
    connectionId: '<optional>',
    virtualInterfaceId: '<optional>',
  },
  'DirectConnect:disassociateConnectionFromLag': {
    connectionId: 'required',
    lagId: 'required',
  },
  'DirectConnect:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
  },
  'DirectConnect:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'DirectConnect:updateDirectConnectGatewayAssociation': {
    associationId: '<optional>',
    addAllowedPrefixesToDirectConnectGateway: {
      cidr: '<optional>',
    },
    removeAllowedPrefixesToDirectConnectGateway: {
      cidr: '<optional>',
    },
  },
  'DirectConnect:updateLag': {
    lagId: 'required',
    lagName: '<optional>',
    minimumLinks: ['<optional1>', '<optional2>'],
  },
  'DirectConnect:updateVirtualInterfaceAttributes': {
    virtualInterfaceId: 'required',
    mtu: '<optional>',
  },
  'DirectoryService:acceptSharedDirectory': {
    SharedDirectoryId: 'required',
  },
  'DirectoryService:addIpRoutes': {
    DirectoryId: 'required',
    IpRoutes: [
      {
        CidrIp: '<optional>',
        Description: '<optional>',
      },
    ],
    UpdateSecurityGroupForDirectoryControllers: ['<optional1>', '<optional2>'],
  },
  'DirectoryService:addTagsToResource': {
    ResourceId: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'DirectoryService:cancelSchemaExtension': {
    DirectoryId: 'required',
    SchemaExtensionId: 'required',
  },
  'DirectoryService:connectDirectory': {
    Name: 'required',
    Password: 'required',
    Size: 'required',
    ConnectSettings: [
      {
        VpcId: 'required',
        SubnetIds: ['required1', 'required2'],
        CustomerDnsIps: ['required1', 'required2'],
        CustomerUserName: 'required',
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
    ShortName: '<optional>',
    Description: '<optional>',
  },
  'DirectoryService:createAlias': {
    DirectoryId: 'required',
    Alias: 'required',
  },
  'DirectoryService:createComputer': {
    DirectoryId: 'required',
    ComputerName: 'required',
    Password: 'required',
    OrganizationalUnitDistinguishedName: '<optional>',
    ComputerAttributes: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DirectoryService:createConditionalForwarder': {
    DirectoryId: 'required',
    RemoteDomainName: 'required',
    DnsIpAddrs: ['required1', 'required2'],
  },
  'DirectoryService:createDirectory': {
    Name: 'required',
    Password: 'required',
    Size: 'required',
    VpcSettings: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    ShortName: '<optional>',
    Description: '<optional>',
  },
  'DirectoryService:createLogSubscription': {
    DirectoryId: 'required',
    LogGroupName: 'required',
  },
  'DirectoryService:createMicrosoftAD': {
    Name: 'required',
    Password: 'required',
    VpcSettings: [
      {
        VpcId: 'required',
        SubnetIds: ['required1', 'required2'],
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
    ShortName: '<optional>',
    Description: '<optional>',
    Edition: '<optional>',
  },
  'DirectoryService:createSnapshot': {
    DirectoryId: 'required',
    Name: '<optional>',
  },
  'DirectoryService:createTrust': {
    DirectoryId: 'required',
    RemoteDomainName: 'required',
    TrustPassword: 'required',
    TrustDirection: 'required',
    TrustType: '<optional>',
    ConditionalForwarderIpAddrs: ['<optional1>', '<optional2>'],
    SelectiveAuth: '<optional>',
  },
  'DirectoryService:deleteConditionalForwarder': {
    DirectoryId: 'required',
    RemoteDomainName: 'required',
  },
  'DirectoryService:deleteDirectory': {
    DirectoryId: 'required',
  },
  'DirectoryService:deleteLogSubscription': {
    DirectoryId: 'required',
  },
  'DirectoryService:deleteSnapshot': {
    SnapshotId: 'required',
  },
  'DirectoryService:deleteTrust': {
    TrustId: 'required',
    DeleteAssociatedConditionalForwarder: '<optional>',
  },
  'DirectoryService:deregisterCertificate': {
    DirectoryId: 'required',
    CertificateId: 'required',
  },
  'DirectoryService:deregisterEventTopic': {
    DirectoryId: 'required',
    TopicName: 'required',
  },
  'DirectoryService:describeCertificate': {
    DirectoryId: 'required',
    CertificateId: 'required',
  },
  'DirectoryService:describeConditionalForwarders': {
    DirectoryId: 'required',
    RemoteDomainNames: ['<optional1>', '<optional2>'],
  },
  'DirectoryService:describeDirectories': {
    DirectoryIds: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'DirectoryService:describeDomainControllers': {
    DirectoryId: 'required',
    DomainControllerIds: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'DirectoryService:describeEventTopics': {
    DirectoryId: '<optional>',
    TopicNames: ['<optional1>', '<optional2>'],
  },
  'DirectoryService:describeLDAPSSettings': {
    DirectoryId: 'required',
    Type: '<optional>',
    Limit: '<optional>',
  },
  'DirectoryService:describeSharedDirectories': {
    OwnerDirectoryId: 'required',
    SharedDirectoryIds: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'DirectoryService:describeSnapshots': {
    DirectoryId: '<optional>',
    SnapshotIds: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'DirectoryService:describeTrusts': {
    DirectoryId: '<optional>',
    TrustIds: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'DirectoryService:disableLDAPS': {
    DirectoryId: 'required',
    Type: '<optional>',
  },
  'DirectoryService:disableRadius': {
    DirectoryId: 'required',
  },
  'DirectoryService:disableSso': {
    DirectoryId: 'required',
    UserName: '<optional>',
    Password: '<optional>',
  },
  'DirectoryService:enableLDAPS': {
    DirectoryId: 'required',
    Type: '<optional>',
  },
  'DirectoryService:enableRadius': {
    DirectoryId: 'required',
    RadiusSettings: [
      {
        RadiusServers: ['<optional1>', '<optional2>'],
        RadiusPort: '<optional>',
        RadiusTimeout: '<optional>',
        RadiusRetries: ['<optional1>', '<optional2>'],
        SharedSecret: '<optional>',
        AuthenticationProtocol: '<optional>',
        DisplayLabel: '<optional>',
        UseSameUsername: '<optional>',
      },
    ],
  },
  'DirectoryService:enableSso': {
    DirectoryId: 'required',
    UserName: '<optional>',
    Password: '<optional>',
  },
  'DirectoryService:getDirectoryLimits': {},
  'DirectoryService:getSnapshotLimits': {
    DirectoryId: 'required',
  },
  'DirectoryService:listCertificates': {
    DirectoryId: 'required',
    Limit: '<optional>',
  },
  'DirectoryService:listIpRoutes': {
    DirectoryId: 'required',
    Limit: '<optional>',
  },
  'DirectoryService:listLogSubscriptions': {
    DirectoryId: '<optional>',
    Limit: '<optional>',
  },
  'DirectoryService:listSchemaExtensions': {
    DirectoryId: 'required',
    Limit: '<optional>',
  },
  'DirectoryService:listTagsForResource': {
    ResourceId: 'required',
    Limit: '<optional>',
  },
  'DirectoryService:registerCertificate': {
    DirectoryId: 'required',
    CertificateData: ['required1', 'required2'],
  },
  'DirectoryService:registerEventTopic': {
    DirectoryId: 'required',
    TopicName: 'required',
  },
  'DirectoryService:rejectSharedDirectory': {
    SharedDirectoryId: 'required',
  },
  'DirectoryService:removeIpRoutes': {
    DirectoryId: 'required',
    CidrIps: ['required1', 'required2'],
  },
  'DirectoryService:removeTagsFromResource': {
    ResourceId: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'DirectoryService:resetUserPassword': {
    DirectoryId: 'required',
    UserName: 'required',
    NewPassword: 'required',
  },
  'DirectoryService:restoreFromSnapshot': {
    SnapshotId: 'required',
  },
  'DirectoryService:shareDirectory': {
    DirectoryId: 'required',
    ShareTarget: {
      Id: 'required',
      Type: 'required',
    },
    ShareMethod: 'required',
    ShareNotes: ['<optional1>', '<optional2>'],
  },
  'DirectoryService:startSchemaExtension': {
    DirectoryId: 'required',
    CreateSnapshotBeforeSchemaExtension: 'required',
    LdifContent: 'required',
    Description: 'required',
  },
  'DirectoryService:unshareDirectory': {
    DirectoryId: 'required',
    UnshareTarget: {
      Id: 'required',
      Type: 'required',
    },
  },
  'DirectoryService:updateConditionalForwarder': {
    DirectoryId: 'required',
    RemoteDomainName: 'required',
    DnsIpAddrs: ['required1', 'required2'],
  },
  'DirectoryService:updateNumberOfDomainControllers': {
    DirectoryId: 'required',
    DesiredNumber: 'required',
  },
  'DirectoryService:updateRadius': {
    DirectoryId: 'required',
    RadiusSettings: [
      {
        RadiusServers: ['<optional1>', '<optional2>'],
        RadiusPort: '<optional>',
        RadiusTimeout: '<optional>',
        RadiusRetries: ['<optional1>', '<optional2>'],
        SharedSecret: '<optional>',
        AuthenticationProtocol: '<optional>',
        DisplayLabel: '<optional>',
        UseSameUsername: '<optional>',
      },
    ],
  },
  'DirectoryService:updateTrust': {
    TrustId: 'required',
    SelectiveAuth: '<optional>',
  },
  'DirectoryService:verifyTrust': {
    TrustId: 'required',
  },
  'Discovery:associateConfigurationItemsToApplication': {
    applicationConfigurationId: 'required',
    configurationIds: ['required1', 'required2'],
  },
  'Discovery:batchDeleteImportData': {
    importTaskIds: ['required1', 'required2'],
  },
  'Discovery:createApplication': {
    name: 'required',
    description: '<optional>',
  },
  'Discovery:createTags': {
    configurationIds: ['required1', 'required2'],
    tags: [
      {
        key: 'required',
        value: 'required',
      },
    ],
  },
  'Discovery:deleteApplications': {
    configurationIds: ['required1', 'required2'],
  },
  'Discovery:deleteTags': {
    configurationIds: ['required1', 'required2'],
    tags: ['<optional1>', '<optional2>'],
  },
  'Discovery:describeAgents': {
    filters: ['<optional1>', '<optional2>'],
    agentIds: ['<optional1>', '<optional2>'],
  },
  'Discovery:describeConfigurations': {
    configurationIds: ['required1', 'required2'],
  },
  'Discovery:describeContinuousExports': {
    exportIds: ['<optional1>', '<optional2>'],
  },
  'Discovery:describeExportConfigurations': {
    exportIds: ['<optional1>', '<optional2>'],
  },
  'Discovery:describeExportTasks': {
    filters: ['<optional1>', '<optional2>'],
    exportIds: ['<optional1>', '<optional2>'],
  },
  'Discovery:describeImportTasks': {
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Discovery:describeTags': {
    filters: ['<optional1>', '<optional2>'],
  },
  'Discovery:disassociateConfigurationItemsFromApplication': {
    applicationConfigurationId: 'required',
    configurationIds: ['required1', 'required2'],
  },
  'Discovery:exportConfigurations': {},
  'Discovery:getDiscoverySummary': {},
  'Discovery:listConfigurations': {
    configurationType: 'required',
    filters: ['<optional1>', '<optional2>'],
    orderBy: {
      sortOrder: '<optional>',
    },
  },
  'Discovery:listServerNeighbors': {
    configurationId: 'required',
    portInformationNeeded: '<optional>',
    neighborConfigurationIds: ['<optional1>', '<optional2>'],
  },
  'Discovery:startContinuousExport': {},
  'Discovery:startDataCollectionByAgentIds': {
    agentIds: ['required1', 'required2'],
  },
  'Discovery:startExportTask': {
    filters: ['<optional1>', '<optional2>'],
    exportDataFormat: '<optional>',
    startTime: '<optional>',
    endTime: '<optional>',
  },
  'Discovery:startImportTask': {
    name: 'required',
    importUrl: 'required',
    clientRequestToken: '<optional>',
  },
  'Discovery:stopContinuousExport': {
    exportId: 'required',
  },
  'Discovery:stopDataCollectionByAgentIds': {
    agentIds: ['required1', 'required2'],
  },
  'Discovery:updateApplication': {
    configurationId: 'required',
    name: '<optional>',
    description: '<optional>',
  },
  'DocDB:addTagsToResource': {
    ResourceName: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DocDB:applyPendingMaintenanceAction': {
    ResourceIdentifier: 'required',
    ApplyAction: 'required',
    OptInType: 'required',
  },
  'DocDB:copyDBClusterParameterGroup': {
    SourceDBClusterParameterGroupIdentifier: 'required',
    TargetDBClusterParameterGroupIdentifier: 'required',
    TargetDBClusterParameterGroupDescription: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DocDB:copyDBClusterSnapshot': {
    SourceDBClusterSnapshotIdentifier: 'required',
    TargetDBClusterSnapshotIdentifier: 'required',
    KmsKeyId: '<optional>',
    PreSignedUrl: '<optional>',
    CopyTags: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DocDB:createDBCluster': {
    DBClusterIdentifier: 'required',
    Engine: 'required',
    MasterUsername: 'required',
    MasterUserPassword: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    BackupRetentionPeriod: '<optional>',
    DBClusterParameterGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    DBSubnetGroupName: '<optional>',
    EngineVersion: '<optional>',
    Port: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    StorageEncrypted: '<optional>',
    KmsKeyId: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    DeletionProtection: '<optional>',
  },
  'DocDB:createDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    DBParameterGroupFamily: 'required',
    Description: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DocDB:createDBClusterSnapshot': {
    DBClusterSnapshotIdentifier: 'required',
    DBClusterIdentifier: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DocDB:createDBInstance': {
    DBInstanceIdentifier: 'required',
    DBInstanceClass: 'required',
    Engine: 'required',
    DBClusterIdentifier: 'required',
    AvailabilityZone: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    PromotionTier: '<optional>',
  },
  'DocDB:createDBSubnetGroup': {
    DBSubnetGroupName: 'required',
    DBSubnetGroupDescription: 'required',
    SubnetIds: ['required1', 'required2'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'DocDB:deleteDBCluster': {
    DBClusterIdentifier: 'required',
    SkipFinalSnapshot: '<optional>',
    FinalDBSnapshotIdentifier: '<optional>',
  },
  'DocDB:deleteDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
  },
  'DocDB:deleteDBClusterSnapshot': {
    DBClusterSnapshotIdentifier: 'required',
  },
  'DocDB:deleteDBInstance': {
    DBInstanceIdentifier: 'required',
  },
  'DocDB:deleteDBSubnetGroup': {
    DBSubnetGroupName: 'required',
  },
  'DocDB:describeCertificates': {
    Filters: ['<optional1>', '<optional2>'],
    CertificateIdentifier: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeDBClusterParameterGroups': {
    DBClusterParameterGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeDBClusterParameters': {
    DBClusterParameterGroupName: 'required',
    Source: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeDBClusterSnapshotAttributes': {
    DBClusterSnapshotIdentifier: 'required',
  },
  'DocDB:describeDBClusterSnapshots': {
    DBClusterIdentifier: '<optional>',
    DBClusterSnapshotIdentifier: '<optional>',
    SnapshotType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    IncludeShared: '<optional>',
    IncludePublic: '<optional>',
  },
  'DocDB:describeDBClusters': {
    DBClusterIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeDBEngineVersions': {
    Engine: '<optional>',
    EngineVersion: '<optional>',
    DBParameterGroupFamily: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    DefaultOnly: '<optional>',
    ListSupportedCharacterSets: ['<optional1>', '<optional2>'],
    ListSupportedTimezones: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeDBInstances': {
    DBInstanceIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeDBSubnetGroups': {
    DBSubnetGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeEngineDefaultClusterParameters': {
    DBParameterGroupFamily: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeEventCategories': {
    SourceType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'DocDB:describeEvents': {
    SourceIdentifier: '<optional>',
    SourceType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Duration: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describeOrderableDBInstanceOptions': {
    Engine: 'required',
    EngineVersion: '<optional>',
    DBInstanceClass: '<optional>',
    LicenseModel: '<optional>',
    Vpc: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:describePendingMaintenanceActions': {
    ResourceIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'DocDB:failoverDBCluster': {
    DBClusterIdentifier: '<optional>',
    TargetDBInstanceIdentifier: '<optional>',
  },
  'DocDB:listTagsForResource': {
    ResourceName: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'DocDB:modifyDBCluster': {
    DBClusterIdentifier: 'required',
    NewDBClusterIdentifier: '<optional>',
    ApplyImmediately: '<optional>',
    BackupRetentionPeriod: '<optional>',
    DBClusterParameterGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Port: '<optional>',
    MasterUserPassword: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    CloudwatchLogsExportConfiguration: {
      EnableLogTypes: ['<optional1>', '<optional2>'],
      DisableLogTypes: ['<optional1>', '<optional2>'],
    },
    EngineVersion: '<optional>',
    DeletionProtection: '<optional>',
  },
  'DocDB:modifyDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
      },
    ],
  },
  'DocDB:modifyDBClusterSnapshotAttribute': {
    DBClusterSnapshotIdentifier: 'required',
    AttributeName: 'required',
    ValuesToAdd: '<optional>',
    ValuesToRemove: '<optional>',
  },
  'DocDB:modifyDBInstance': {
    DBInstanceIdentifier: 'required',
    DBInstanceClass: '<optional>',
    ApplyImmediately: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    NewDBInstanceIdentifier: '<optional>',
    PromotionTier: '<optional>',
  },
  'DocDB:modifyDBSubnetGroup': {
    DBSubnetGroupName: 'required',
    SubnetIds: ['required1', 'required2'],
    DBSubnetGroupDescription: '<optional>',
  },
  'DocDB:rebootDBInstance': {
    DBInstanceIdentifier: 'required',
    ForceFailover: '<optional>',
  },
  'DocDB:removeTagsFromResource': {
    ResourceName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'DocDB:resetDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    ResetAllParameters: ['<optional1>', '<optional2>'],
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
      },
    ],
  },
  'DocDB:restoreDBClusterFromSnapshot': {
    DBClusterIdentifier: 'required',
    SnapshotIdentifier: 'required',
    Engine: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    EngineVersion: '<optional>',
    Port: '<optional>',
    DBSubnetGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    DeletionProtection: '<optional>',
  },
  'DocDB:restoreDBClusterToPointInTime': {
    DBClusterIdentifier: 'required',
    SourceDBClusterIdentifier: 'required',
    RestoreToTime: '<optional>',
    UseLatestRestorableTime: '<optional>',
    Port: '<optional>',
    DBSubnetGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    DeletionProtection: '<optional>',
  },
  'DocDB:startDBCluster': {
    DBClusterIdentifier: 'required',
  },
  'DocDB:stopDBCluster': {
    DBClusterIdentifier: 'required',
  },
  'DynamoDB:batchGetItem': {
    RequestItems: ['required1', 'required2'],
    ReturnConsumedCapacity: '<optional>',
  },
  'DynamoDB:batchWriteItem': {
    RequestItems: ['required1', 'required2'],
    ReturnConsumedCapacity: '<optional>',
    ReturnItemCollectionMetrics: ['<optional1>', '<optional2>'],
  },
  'DynamoDB:createBackup': {
    TableName: 'required',
    BackupName: 'required',
  },
  'DynamoDB:createGlobalTable': {
    GlobalTableName: 'required',
    ReplicationGroup: {
      RegionName: '<optional>',
    },
  },
  'DynamoDB:createTable': {
    AttributeDefinitions: [
      {
        AttributeName: 'required',
        AttributeType: 'required',
      },
    ],
    TableName: 'required',
    KeySchema: {
      AttributeName: 'required',
      KeyType: 'required',
    },
    LocalSecondaryIndexes: [
      {
        Projection: {
          ProjectionType: '<optional>',
          NonKeyAttributes: ['<optional1>', '<optional2>'],
        },
      },
    ],
    GlobalSecondaryIndexes: [
      {
        ProvisionedThroughput: '<optional>',
        Projection: {
          ProjectionType: '<optional>',
          NonKeyAttributes: ['<optional1>', '<optional2>'],
        },
      },
    ],
    ProvisionedThroughput: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
    BillingMode: '<optional>',
    StreamSpecification: {
      StreamEnabled: '<optional>',
      StreamViewType: '<optional>',
    },
    SSESpecification: {
      Enabled: '<optional>',
      SSEType: '<optional>',
      KMSMasterKeyId: '<optional>',
    },
  },
  'DynamoDB:deleteBackup': {
    BackupArn: 'required',
  },
  'DynamoDB:deleteItem': {
    TableName: 'required',
    Key: 'required',
    Expected: '<optional>',
    ConditionalOperator: '<optional>',
    ReturnValues: ['<optional1>', '<optional2>'],
    ReturnConsumedCapacity: '<optional>',
    ReturnItemCollectionMetrics: ['<optional1>', '<optional2>'],
    ConditionExpression: '<optional>',
    ExpressionAttributeNames: ['<optional1>', '<optional2>'],
    ExpressionAttributeValues: ['<optional1>', '<optional2>'],
  },
  'DynamoDB:deleteTable': {
    TableName: 'required',
  },
  'DynamoDB:describeBackup': {
    BackupArn: 'required',
  },
  'DynamoDB:describeContinuousBackups': {
    TableName: 'required',
  },
  'DynamoDB:describeContributorInsights': {
    TableName: 'required',
    IndexName: '<optional>',
  },
  'DynamoDB:describeEndpoints': {},
  'DynamoDB:describeGlobalTable': {
    GlobalTableName: 'required',
  },
  'DynamoDB:describeGlobalTableSettings': {
    GlobalTableName: 'required',
  },
  'DynamoDB:describeLimits': {},
  'DynamoDB:describeTable': {
    TableName: 'required',
  },
  'DynamoDB:describeTableReplicaAutoScaling': {
    TableName: 'required',
  },
  'DynamoDB:describeTimeToLive': {
    TableName: 'required',
  },
  'DynamoDB:getItem': {
    TableName: 'required',
    Key: 'required',
    AttributesToGet: '<optional>',
    ConsistentRead: '<optional>',
    ReturnConsumedCapacity: '<optional>',
    ProjectionExpression: '<optional>',
    ExpressionAttributeNames: ['<optional1>', '<optional2>'],
  },
  'DynamoDB:listBackups': {
    TableName: '<optional>',
    Limit: '<optional>',
    TimeRangeLowerBound: '<optional>',
    TimeRangeUpperBound: '<optional>',
    ExclusiveStartBackupArn: '<optional>',
    BackupType: '<optional>',
  },
  'DynamoDB:listContributorInsights': {
    TableName: '<optional>',
  },
  'DynamoDB:listGlobalTables': {
    ExclusiveStartGlobalTableName: '<optional>',
    Limit: '<optional>',
    RegionName: '<optional>',
  },
  'DynamoDB:listTables': {
    ExclusiveStartTableName: '<optional>',
    Limit: '<optional>',
  },
  'DynamoDB:listTagsOfResource': {
    ResourceArn: 'required',
  },
  'DynamoDB:putItem': {
    TableName: 'required',
    Item: 'required',
    Expected: '<optional>',
    ReturnValues: ['<optional1>', '<optional2>'],
    ReturnConsumedCapacity: '<optional>',
    ReturnItemCollectionMetrics: ['<optional1>', '<optional2>'],
    ConditionalOperator: '<optional>',
    ConditionExpression: '<optional>',
    ExpressionAttributeNames: ['<optional1>', '<optional2>'],
    ExpressionAttributeValues: ['<optional1>', '<optional2>'],
  },
  'DynamoDB:query': {
    TableName: 'required',
    IndexName: '<optional>',
    Select: '<optional>',
    AttributesToGet: '<optional>',
    Limit: '<optional>',
    ConsistentRead: '<optional>',
    KeyConditions: ['<optional1>', '<optional2>'],
    QueryFilter: '<optional>',
    ConditionalOperator: '<optional>',
    ScanIndexForward: '<optional>',
    ExclusiveStartKey: '<optional>',
    ReturnConsumedCapacity: '<optional>',
    ProjectionExpression: '<optional>',
    FilterExpression: '<optional>',
    KeyConditionExpression: '<optional>',
    ExpressionAttributeNames: ['<optional1>', '<optional2>'],
    ExpressionAttributeValues: ['<optional1>', '<optional2>'],
  },
  'DynamoDB:restoreTableFromBackup': {
    TargetTableName: 'required',
    BackupArn: 'required',
  },
  'DynamoDB:restoreTableToPointInTime': {
    SourceTableName: 'required',
    TargetTableName: 'required',
    UseLatestRestorableTime: '<optional>',
    RestoreDateTime: '<optional>',
  },
  'DynamoDB:scan': {
    TableName: 'required',
    IndexName: '<optional>',
    AttributesToGet: '<optional>',
    Limit: '<optional>',
    Select: '<optional>',
    ScanFilter: '<optional>',
    ConditionalOperator: '<optional>',
    ExclusiveStartKey: '<optional>',
    ReturnConsumedCapacity: '<optional>',
    TotalSegments: ['<optional1>', '<optional2>'],
    Segment: '<optional>',
    ProjectionExpression: '<optional>',
    FilterExpression: '<optional>',
    ExpressionAttributeNames: ['<optional1>', '<optional2>'],
    ExpressionAttributeValues: ['<optional1>', '<optional2>'],
    ConsistentRead: '<optional>',
  },
  'DynamoDB:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'DynamoDB:transactGetItems': {
    TransactItems: [
      {
        Get: {
          ProjectionExpression: '<optional>',
          ExpressionAttributeNames: ['<optional1>', '<optional2>'],
        },
      },
    ],
    ReturnConsumedCapacity: '<optional>',
  },
  'DynamoDB:transactWriteItems': {
    TransactItems: [
      {
        ConditionCheck: {
          ExpressionAttributeNames: ['<optional1>', '<optional2>'],
          ExpressionAttributeValues: ['<optional1>', '<optional2>'],
          ReturnValuesOnConditionCheckFailure: '<optional>',
        },
        Put: {
          ConditionExpression: '<optional>',
          ExpressionAttributeNames: ['<optional1>', '<optional2>'],
          ExpressionAttributeValues: ['<optional1>', '<optional2>'],
          ReturnValuesOnConditionCheckFailure: '<optional>',
        },
        Delete: {
          ConditionExpression: '<optional>',
          ExpressionAttributeNames: ['<optional1>', '<optional2>'],
          ExpressionAttributeValues: ['<optional1>', '<optional2>'],
          ReturnValuesOnConditionCheckFailure: '<optional>',
        },
        Update: {
          ConditionExpression: '<optional>',
          ExpressionAttributeNames: ['<optional1>', '<optional2>'],
          ExpressionAttributeValues: ['<optional1>', '<optional2>'],
          ReturnValuesOnConditionCheckFailure: '<optional>',
        },
      },
    ],
    ReturnConsumedCapacity: '<optional>',
    ReturnItemCollectionMetrics: ['<optional1>', '<optional2>'],
    ClientRequestToken: '<optional>',
  },
  'DynamoDB:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'DynamoDB:updateContinuousBackups': {
    TableName: 'required',
    PointInTimeRecoverySpecification: {
      PointInTimeRecoveryEnabled: 'required',
    },
  },
  'DynamoDB:updateContributorInsights': {
    TableName: 'required',
    ContributorInsightsAction: 'required',
    IndexName: '<optional>',
  },
  'DynamoDB:updateGlobalTable': {
    GlobalTableName: 'required',
    ReplicaUpdates: [
      {
        Create: '<optional>',
        Delete: '<optional>',
      },
    ],
  },
  'DynamoDB:updateGlobalTableSettings': {
    GlobalTableName: 'required',
    GlobalTableProvisionedWriteCapacityAutoScalingSettingsUpdate: {
      MinimumUnits: ['<optional1>', '<optional2>'],
      MaximumUnits: ['<optional1>', '<optional2>'],
      AutoScalingDisabled: '<optional>',
      AutoScalingRoleArn: '<optional>',
      ScalingPolicyUpdate: {
        PolicyName: '<optional>',
        TargetTrackingScalingPolicyConfiguration: {
          DisableScaleIn: '<optional>',
          ScaleInCooldown: '<optional>',
          ScaleOutCooldown: '<optional>',
        },
      },
    },
    GlobalTableGlobalSecondaryIndexSettingsUpdate: {
      ProvisionedWriteCapacityUnits: ['<optional1>', '<optional2>'],
      ProvisionedWriteCapacityAutoScalingSettingsUpdate: {
        MinimumUnits: ['<optional1>', '<optional2>'],
        MaximumUnits: ['<optional1>', '<optional2>'],
        AutoScalingDisabled: '<optional>',
        AutoScalingRoleArn: '<optional>',
        ScalingPolicyUpdate: {
          PolicyName: '<optional>',
          TargetTrackingScalingPolicyConfiguration: {
            DisableScaleIn: '<optional>',
            ScaleInCooldown: '<optional>',
            ScaleOutCooldown: '<optional>',
          },
        },
      },
    },
    ReplicaSettingsUpdate: {
      ReplicaProvisionedReadCapacityUnits: ['<optional1>', '<optional2>'],
      ReplicaProvisionedReadCapacityAutoScalingSettingsUpdate: {
        MinimumUnits: ['<optional1>', '<optional2>'],
        MaximumUnits: ['<optional1>', '<optional2>'],
        AutoScalingDisabled: '<optional>',
        AutoScalingRoleArn: '<optional>',
        ScalingPolicyUpdate: {
          PolicyName: '<optional>',
          TargetTrackingScalingPolicyConfiguration: {
            DisableScaleIn: '<optional>',
            ScaleInCooldown: '<optional>',
            ScaleOutCooldown: '<optional>',
          },
        },
      },
      ReplicaGlobalSecondaryIndexSettingsUpdate: {
        ProvisionedReadCapacityUnits: ['<optional1>', '<optional2>'],
        ProvisionedReadCapacityAutoScalingSettingsUpdate: {
          MinimumUnits: ['<optional1>', '<optional2>'],
          MaximumUnits: ['<optional1>', '<optional2>'],
          AutoScalingDisabled: '<optional>',
          AutoScalingRoleArn: '<optional>',
          ScalingPolicyUpdate: {
            PolicyName: '<optional>',
            TargetTrackingScalingPolicyConfiguration: {
              DisableScaleIn: '<optional>',
              ScaleInCooldown: '<optional>',
              ScaleOutCooldown: '<optional>',
            },
          },
        },
      },
    },
    GlobalTableBillingMode: '<optional>',
    GlobalTableProvisionedWriteCapacityUnits: ['<optional1>', '<optional2>'],
  },
  'DynamoDB:updateItem': {
    TableName: 'required',
    Key: 'required',
    AttributeUpdates: ['<optional1>', '<optional2>'],
    Expected: '<optional>',
    ConditionalOperator: '<optional>',
    ReturnValues: ['<optional1>', '<optional2>'],
    ReturnConsumedCapacity: '<optional>',
    ReturnItemCollectionMetrics: ['<optional1>', '<optional2>'],
    UpdateExpression: '<optional>',
    ConditionExpression: '<optional>',
    ExpressionAttributeNames: ['<optional1>', '<optional2>'],
    ExpressionAttributeValues: ['<optional1>', '<optional2>'],
  },
  'DynamoDB:updateTable': {
    TableName: 'required',
    AttributeDefinitions: ['<optional1>', '<optional2>'],
    ProvisionedThroughput: '<optional>',
    GlobalSecondaryIndexUpdates: [
      {
        Update: '<optional>',
        Create: {
          ProvisionedThroughput: '<optional>',
          Projection: {
            ProjectionType: '<optional>',
            NonKeyAttributes: ['<optional1>', '<optional2>'],
          },
        },
        Delete: '<optional>',
      },
    ],
    BillingMode: '<optional>',
    StreamSpecification: {
      StreamEnabled: '<optional>',
      StreamViewType: '<optional>',
    },
    SSESpecification: {
      Enabled: '<optional>',
      SSEType: '<optional>',
      KMSMasterKeyId: '<optional>',
    },
  },
  'DynamoDB:updateTableReplicaAutoScaling': {
    TableName: 'required',
    GlobalSecondaryIndexUpdates: [
      {
        IndexName: '<optional>',
        ProvisionedWriteCapacityAutoScalingUpdate: {
          MinimumUnits: ['<optional1>', '<optional2>'],
          MaximumUnits: ['<optional1>', '<optional2>'],
          AutoScalingDisabled: '<optional>',
          AutoScalingRoleArn: '<optional>',
          ScalingPolicyUpdate: {
            PolicyName: '<optional>',
            TargetTrackingScalingPolicyConfiguration: {
              DisableScaleIn: '<optional>',
              ScaleInCooldown: '<optional>',
              ScaleOutCooldown: '<optional>',
            },
          },
        },
      },
    ],
    ProvisionedWriteCapacityAutoScalingUpdate: {
      MinimumUnits: ['<optional1>', '<optional2>'],
      MaximumUnits: ['<optional1>', '<optional2>'],
      AutoScalingDisabled: '<optional>',
      AutoScalingRoleArn: '<optional>',
      ScalingPolicyUpdate: {
        PolicyName: '<optional>',
        TargetTrackingScalingPolicyConfiguration: {
          DisableScaleIn: '<optional>',
          ScaleInCooldown: '<optional>',
          ScaleOutCooldown: '<optional>',
        },
      },
    },
    ReplicaUpdates: [
      {
        ReplicaGlobalSecondaryIndexUpdates: [
          {
            IndexName: '<optional>',
            ProvisionedReadCapacityAutoScalingUpdate: {
              MinimumUnits: ['<optional1>', '<optional2>'],
              MaximumUnits: ['<optional1>', '<optional2>'],
              AutoScalingDisabled: '<optional>',
              AutoScalingRoleArn: '<optional>',
              ScalingPolicyUpdate: {
                PolicyName: '<optional>',
                TargetTrackingScalingPolicyConfiguration: {
                  DisableScaleIn: '<optional>',
                  ScaleInCooldown: '<optional>',
                  ScaleOutCooldown: '<optional>',
                },
              },
            },
          },
        ],
        ReplicaProvisionedReadCapacityAutoScalingUpdate: {
          MinimumUnits: ['<optional1>', '<optional2>'],
          MaximumUnits: ['<optional1>', '<optional2>'],
          AutoScalingDisabled: '<optional>',
          AutoScalingRoleArn: '<optional>',
          ScalingPolicyUpdate: {
            PolicyName: '<optional>',
            TargetTrackingScalingPolicyConfiguration: {
              DisableScaleIn: '<optional>',
              ScaleInCooldown: '<optional>',
              ScaleOutCooldown: '<optional>',
            },
          },
        },
      },
    ],
  },
  'DynamoDB:updateTimeToLive': {
    TableName: 'required',
    TimeToLiveSpecification: {
      Enabled: 'required',
      AttributeName: 'required',
    },
  },
  'DynamoDBStreams:describeStream': {
    StreamArn: 'required',
    Limit: '<optional>',
    ExclusiveStartShardId: '<optional>',
  },
  'DynamoDBStreams:getRecords': {
    ShardIterator: 'required',
    Limit: '<optional>',
  },
  'DynamoDBStreams:getShardIterator': {
    StreamArn: 'required',
    ShardId: 'required',
    ShardIteratorType: 'required',
    SequenceNumber: '<optional>',
  },
  'DynamoDBStreams:listStreams': {
    TableName: '<optional>',
    Limit: '<optional>',
    ExclusiveStartStreamArn: '<optional>',
  },
  'EBS:getSnapshotBlock': {
    SnapshotId: 'required',
    BlockIndex: 'required',
    BlockToken: 'required',
  },
  'EBS:listChangedBlocks': {
    SecondSnapshotId: 'required',
    FirstSnapshotId: '<optional>',
    StartingBlockIndex: '<optional>',
  },
  'EBS:listSnapshotBlocks': {
    SnapshotId: 'required',
    StartingBlockIndex: '<optional>',
  },
  'EC2:acceptReservedInstancesExchangeQuote': {
    ReservedInstanceIds: ['required1', 'required2'],
    TargetConfigurations: [
      {
        InstanceCount: '<optional>',
      },
    ],
  },
  'EC2:acceptTransitGatewayPeeringAttachment': {
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:acceptTransitGatewayVpcAttachment': {
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:acceptVpcEndpointConnections': {
    ServiceId: 'required',
    VpcEndpointIds: ['required1', 'required2'],
  },
  'EC2:acceptVpcPeeringConnection': {
    VpcPeeringConnectionId: '<optional>',
  },
  'EC2:advertiseByoipCidr': {
    Cidr: 'required',
  },
  'EC2:allocateAddress': {
    Domain: '<optional>',
    Address: '<optional>',
    PublicIpv4Pool: '<optional>',
  },
  'EC2:allocateHosts': {
    AvailabilityZone: 'required',
    InstanceType: 'required',
    Quantity: 'required',
    AutoPlacement: '<optional>',
    ClientToken: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
    HostRecovery: '<optional>',
  },
  'EC2:applySecurityGroupsToClientVpnTargetNetwork': {
    ClientVpnEndpointId: 'required',
    VpcId: 'required',
    SecurityGroupIds: ['required1', 'required2'],
  },
  'EC2:assignIpv6Addresses': {
    NetworkInterfaceId: 'required',
    Ipv6AddressCount: '<optional>',
    Ipv6Addresses: ['<optional1>', '<optional2>'],
  },
  'EC2:assignPrivateIpAddresses': {
    NetworkInterfaceId: 'required',
    AllowReassignment: '<optional>',
    PrivateIpAddresses: ['<optional1>', '<optional2>'],
    SecondaryPrivateIpAddressCount: '<optional>',
  },
  'EC2:associateAddress': {
    AllocationId: '<optional>',
    InstanceId: '<optional>',
    PublicIp: '<optional>',
    AllowReassociation: '<optional>',
    NetworkInterfaceId: '<optional>',
    PrivateIpAddress: '<optional>',
  },
  'EC2:associateClientVpnTargetNetwork': {
    ClientVpnEndpointId: 'required',
    SubnetId: 'required',
    ClientToken: '<optional>',
  },
  'EC2:associateDhcpOptions': {
    DhcpOptionsId: 'required',
    VpcId: 'required',
  },
  'EC2:associateIamInstanceProfile': {
    IamInstanceProfile: {
      Arn: '<optional>',
      Name: '<optional>',
    },
    InstanceId: 'required',
  },
  'EC2:associateRouteTable': {
    RouteTableId: 'required',
    SubnetId: 'required',
  },
  'EC2:associateSubnetCidrBlock': {
    Ipv6CidrBlock: 'required',
    SubnetId: 'required',
  },
  'EC2:associateTransitGatewayMulticastDomain': {
    TransitGatewayMulticastDomainId: '<optional>',
    TransitGatewayAttachmentId: '<optional>',
    SubnetIds: ['<optional1>', '<optional2>'],
  },
  'EC2:associateTransitGatewayRouteTable': {
    TransitGatewayRouteTableId: 'required',
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:associateVpcCidrBlock': {
    VpcId: 'required',
    AmazonProvidedIpv6CidrBlock: '<optional>',
    CidrBlock: '<optional>',
  },
  'EC2:attachClassicLinkVpc': {
    Groups: ['required1', 'required2'],
    InstanceId: 'required',
    VpcId: 'required',
  },
  'EC2:attachInternetGateway': {
    InternetGatewayId: 'required',
    VpcId: 'required',
  },
  'EC2:attachNetworkInterface': {
    DeviceIndex: 'required',
    InstanceId: 'required',
    NetworkInterfaceId: 'required',
  },
  'EC2:attachVolume': {
    Device: 'required',
    InstanceId: 'required',
    VolumeId: 'required',
  },
  'EC2:attachVpnGateway': {
    VpcId: 'required',
    VpnGatewayId: 'required',
  },
  'EC2:authorizeClientVpnIngress': {
    ClientVpnEndpointId: 'required',
    TargetNetworkCidr: 'required',
    AccessGroupId: '<optional>',
    AuthorizeAllGroups: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    ClientToken: '<optional>',
  },
  'EC2:authorizeSecurityGroupEgress': {
    GroupId: 'required',
    IpPermissions: [
      {
        FromPort: '<optional>',
        IpProtocol: '<optional>',
        IpRanges: [
          {
            CidrIp: '<optional>',
            Description: '<optional>',
          },
        ],
        Ipv6Ranges: [
          {
            CidrIpv6: '<optional>',
            Description: '<optional>',
          },
        ],
        PrefixListIds: [
          {
            Description: '<optional>',
            PrefixListId: '<optional>',
          },
        ],
        ToPort: '<optional>',
        UserIdGroupPairs: [
          {
            Description: '<optional>',
            GroupId: '<optional>',
            GroupName: '<optional>',
            PeeringStatus: '<optional>',
            UserId: '<optional>',
            VpcId: '<optional>',
            VpcPeeringConnectionId: '<optional>',
          },
        ],
      },
    ],
    CidrIp: '<optional>',
    FromPort: '<optional>',
    IpProtocol: '<optional>',
    ToPort: '<optional>',
    SourceSecurityGroupName: '<optional>',
    SourceSecurityGroupOwnerId: '<optional>',
  },
  'EC2:authorizeSecurityGroupIngress': {
    CidrIp: '<optional>',
    FromPort: '<optional>',
    GroupId: '<optional>',
    GroupName: '<optional>',
    IpPermissions: [
      {
        FromPort: '<optional>',
        IpProtocol: '<optional>',
        IpRanges: [
          {
            CidrIp: '<optional>',
            Description: '<optional>',
          },
        ],
        Ipv6Ranges: [
          {
            CidrIpv6: '<optional>',
            Description: '<optional>',
          },
        ],
        PrefixListIds: [
          {
            Description: '<optional>',
            PrefixListId: '<optional>',
          },
        ],
        ToPort: '<optional>',
        UserIdGroupPairs: [
          {
            Description: '<optional>',
            GroupId: '<optional>',
            GroupName: '<optional>',
            PeeringStatus: '<optional>',
            UserId: '<optional>',
            VpcId: '<optional>',
            VpcPeeringConnectionId: '<optional>',
          },
        ],
      },
    ],
    IpProtocol: '<optional>',
    SourceSecurityGroupName: '<optional>',
    SourceSecurityGroupOwnerId: '<optional>',
    ToPort: '<optional>',
  },
  'EC2:bundleInstance': {
    InstanceId: 'required',
    Storage: {
      S3: {
        AWSAccessKeyId: '<optional>',
        Bucket: '<optional>',
        Prefix: '<optional>',
        UploadPolicy: '<optional>',
        UploadPolicySignature: '<optional>',
      },
    },
  },
  'EC2:cancelBundleTask': {
    BundleId: 'required',
  },
  'EC2:cancelCapacityReservation': {
    CapacityReservationId: 'required',
  },
  'EC2:cancelConversionTask': {
    ConversionTaskId: 'required',
    ReasonMessage: '<optional>',
  },
  'EC2:cancelExportTask': {
    ExportTaskId: 'required',
  },
  'EC2:cancelImportTask': {
    CancelReason: '<optional>',
    ImportTaskId: '<optional>',
  },
  'EC2:cancelReservedInstancesListing': {
    ReservedInstancesListingId: 'required',
  },
  'EC2:cancelSpotFleetRequests': {
    SpotFleetRequestIds: ['required1', 'required2'],
    TerminateInstances: ['required1', 'required2'],
  },
  'EC2:cancelSpotInstanceRequests': {
    SpotInstanceRequestIds: ['required1', 'required2'],
  },
  'EC2:confirmProductInstance': {
    InstanceId: 'required',
    ProductCode: 'required',
  },
  'EC2:copyFpgaImage': {
    SourceFpgaImageId: 'required',
    SourceRegion: 'required',
    Description: '<optional>',
    Name: '<optional>',
    ClientToken: '<optional>',
  },
  'EC2:copyImage': {
    Name: 'required',
    SourceImageId: 'required',
    SourceRegion: 'required',
    ClientToken: '<optional>',
    Description: '<optional>',
    Encrypted: '<optional>',
    KmsKeyId: '<optional>',
  },
  'EC2:copySnapshot': {
    SourceRegion: 'required',
    SourceSnapshotId: 'required',
    Description: '<optional>',
    DestinationRegion: '<optional>',
    Encrypted: '<optional>',
    KmsKeyId: '<optional>',
    PresignedUrl: '<optional>',
  },
  'EC2:createCapacityReservation': {
    InstanceType: 'required',
    InstancePlatform: 'required',
    AvailabilityZone: 'required',
    InstanceCount: 'required',
    ClientToken: '<optional>',
    Tenancy: '<optional>',
    EbsOptimized: '<optional>',
    EphemeralStorage: '<optional>',
    EndDate: '<optional>',
    EndDateType: '<optional>',
    InstanceMatchCriteria: ['<optional1>', '<optional2>'],
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createClientVpnEndpoint': {
    ClientCidrBlock: 'required',
    ServerCertificateArn: 'required',
    AuthenticationOptions: [
      {
        Type: '<optional>',
        ActiveDirectory: {
          DirectoryId: '<optional>',
        },
        MutualAuthentication: {
          ClientRootCertificateChainArn: '<optional>',
        },
      },
    ],
    ConnectionLogOptions: [
      {
        Enabled: '<optional>',
        CloudwatchLogGroup: '<optional>',
        CloudwatchLogStream: '<optional>',
      },
    ],
    DnsServers: ['<optional1>', '<optional2>'],
    TransportProtocol: '<optional>',
    Description: '<optional>',
    ClientToken: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createClientVpnRoute': {
    ClientVpnEndpointId: 'required',
    DestinationCidrBlock: 'required',
    TargetVpcSubnetId: 'required',
    Description: '<optional>',
    ClientToken: '<optional>',
  },
  'EC2:createCustomerGateway': {
    BgpAsn: 'required',
    PublicIp: 'required',
    Type: 'required',
  },
  'EC2:createDefaultSubnet': {
    AvailabilityZone: 'required',
  },
  'EC2:createDefaultVpc': {},
  'EC2:createDhcpOptions': {
    DhcpConfigurations: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:createEgressOnlyInternetGateway': {
    VpcId: 'required',
    ClientToken: '<optional>',
  },
  'EC2:createFleet': {
    LaunchTemplateConfigs: [
      {
        LaunchTemplateSpecification: {
          LaunchTemplateId: '<optional>',
          LaunchTemplateName: '<optional>',
          Version: '<optional>',
        },
        Overrides: [
          {
            InstanceType: '<optional>',
            MaxPrice: '<optional>',
            SubnetId: '<optional>',
            AvailabilityZone: '<optional>',
            WeightedCapacity: '<optional>',
            Priority: '<optional>',
            Placement: {
              AvailabilityZone: '<optional>',
              Affinity: '<optional>',
              GroupName: '<optional>',
              PartitionNumber: '<optional>',
              HostId: '<optional>',
              Tenancy: '<optional>',
              SpreadDomain: '<optional>',
            },
          },
        ],
      },
    ],
    TargetCapacitySpecification: {
      OnDemandTargetCapacity: '<optional>',
      SpotTargetCapacity: '<optional>',
      DefaultTargetCapacityType: '<optional>',
    },
    ClientToken: '<optional>',
    SpotOptions: [
      {
        AllocationStrategy: '<optional>',
        InstanceInterruptionBehavior: '<optional>',
        InstancePoolsToUseCount: '<optional>',
        SingleInstanceType: '<optional>',
        SingleAvailabilityZone: '<optional>',
        MinTargetCapacity: '<optional>',
        MaxTotalPrice: '<optional>',
      },
    ],
    OnDemandOptions: [
      {
        AllocationStrategy: '<optional>',
        SingleInstanceType: '<optional>',
        SingleAvailabilityZone: '<optional>',
        MinTargetCapacity: '<optional>',
        MaxTotalPrice: '<optional>',
      },
    ],
    ExcessCapacityTerminationPolicy: '<optional>',
    TerminateInstancesWithExpiration: '<optional>',
    Type: '<optional>',
    ValidFrom: '<optional>',
    ValidUntil: '<optional>',
    ReplaceUnhealthyInstances: ['<optional1>', '<optional2>'],
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createFlowLogs': {
    ResourceIds: ['required1', 'required2'],
    ResourceType: 'required',
    TrafficType: 'required',
    ClientToken: '<optional>',
    DeliverLogsPermissionArn: '<optional>',
    LogGroupName: '<optional>',
    LogDestinationType: '<optional>',
    LogDestination: '<optional>',
  },
  'EC2:createFpgaImage': {
    InputStorageLocation: {
      Bucket: '<optional>',
      Key: '<optional>',
    },
    LogsStorageLocation: {
      Bucket: '<optional>',
      Key: '<optional>',
    },
    Description: '<optional>',
    Name: '<optional>',
    ClientToken: '<optional>',
  },
  'EC2:createImage': {
    InstanceId: 'required',
    Name: 'required',
    BlockDeviceMappings: [
      {
        DeviceName: '<optional>',
        VirtualName: '<optional>',
        Ebs: [
          {
            DeleteOnTermination: '<optional>',
            Iops: ['<optional1>', '<optional2>'],
            SnapshotId: '<optional>',
            VolumeSize: '<optional>',
            VolumeType: '<optional>',
            Encrypted: '<optional>',
            KmsKeyId: '<optional>',
          },
        ],
        NoDevice: '<optional>',
      },
    ],
    Description: '<optional>',
    NoReboot: '<optional>',
  },
  'EC2:createInstanceExportTask': {
    InstanceId: 'required',
    Description: '<optional>',
    ExportToS3Task: {
      ContainerFormat: '<optional>',
      DiskImageFormat: '<optional>',
      S3Bucket: '<optional>',
      S3Prefix: '<optional>',
    },
    TargetEnvironment: '<optional>',
  },
  'EC2:createInternetGateway': {},
  'EC2:createKeyPair': {
    KeyName: 'required',
  },
  'EC2:createLaunchTemplate': {
    LaunchTemplateName: 'required',
    LaunchTemplateData: [
      {
        KernelId: '<optional>',
        EbsOptimized: '<optional>',
        IamInstanceProfile: {
          Arn: '<optional>',
          Name: '<optional>',
        },
        BlockDeviceMappings: [
          {
            DeviceName: '<optional>',
            VirtualName: '<optional>',
            Ebs: [
              {
                Encrypted: '<optional>',
                DeleteOnTermination: '<optional>',
                Iops: ['<optional1>', '<optional2>'],
                KmsKeyId: '<optional>',
                SnapshotId: '<optional>',
                VolumeSize: '<optional>',
                VolumeType: '<optional>',
              },
            ],
            NoDevice: '<optional>',
          },
        ],
        NetworkInterfaces: [
          {
            AssociatePublicIpAddress: '<optional>',
            DeleteOnTermination: '<optional>',
            Description: '<optional>',
            DeviceIndex: '<optional>',
            Groups: ['<optional1>', '<optional2>'],
            InterfaceType: '<optional>',
            Ipv6AddressCount: '<optional>',
            Ipv6Addresses: [
              {
                Ipv6Address: '<optional>',
              },
            ],
            NetworkInterfaceId: '<optional>',
            PrivateIpAddress: '<optional>',
            PrivateIpAddresses: [
              {
                Primary: '<optional>',
                PrivateIpAddress: '<optional>',
              },
            ],
            SecondaryPrivateIpAddressCount: '<optional>',
            SubnetId: '<optional>',
          },
        ],
        ImageId: '<optional>',
        InstanceType: '<optional>',
        KeyName: '<optional>',
        Monitoring: {
          Enabled: '<optional>',
        },
        Placement: {
          AvailabilityZone: '<optional>',
          Affinity: '<optional>',
          GroupName: '<optional>',
          HostId: '<optional>',
          Tenancy: '<optional>',
          SpreadDomain: '<optional>',
        },
        RamDiskId: '<optional>',
        DisableApiTermination: '<optional>',
        InstanceInitiatedShutdownBehavior: '<optional>',
        UserData: ['<optional1>', '<optional2>'],
        TagSpecifications: [
          {
            ResourceType: '<optional>',
            Tags: [
              {
                Key: '<optional>',
                Value: '<optional>',
              },
            ],
          },
        ],
        ElasticGpuSpecifications: ['<optional1>', '<optional2>'],
        ElasticInferenceAccelerators: ['<optional1>', '<optional2>'],
        SecurityGroupIds: ['<optional1>', '<optional2>'],
        SecurityGroups: ['<optional1>', '<optional2>'],
        InstanceMarketOptions: [
          {
            MarketType: '<optional>',
            SpotOptions: [
              {
                MaxPrice: '<optional>',
                SpotInstanceType: '<optional>',
                BlockDurationMinutes: ['<optional1>', '<optional2>'],
                ValidUntil: '<optional>',
                InstanceInterruptionBehavior: '<optional>',
              },
            ],
          },
        ],
        CreditSpecification: '<optional>',
        CpuOptions: [
          {
            CoreCount: '<optional>',
            ThreadsPerCore: '<optional>',
          },
        ],
        CapacityReservationSpecification: {
          CapacityReservationPreference: '<optional>',
          CapacityReservationTarget: {
            CapacityReservationId: '<optional>',
          },
        },
        LicenseSpecifications: [
          {
            LicenseConfigurationArn: '<optional>',
          },
        ],
        HibernationOptions: [
          {
            Configured: '<optional>',
          },
        ],
      },
    ],
    ClientToken: '<optional>',
    VersionDescription: '<optional>',
  },
  'EC2:createLaunchTemplateVersion': {
    LaunchTemplateData: [
      {
        KernelId: '<optional>',
        EbsOptimized: '<optional>',
        IamInstanceProfile: {
          Arn: '<optional>',
          Name: '<optional>',
        },
        BlockDeviceMappings: [
          {
            DeviceName: '<optional>',
            VirtualName: '<optional>',
            Ebs: [
              {
                Encrypted: '<optional>',
                DeleteOnTermination: '<optional>',
                Iops: ['<optional1>', '<optional2>'],
                KmsKeyId: '<optional>',
                SnapshotId: '<optional>',
                VolumeSize: '<optional>',
                VolumeType: '<optional>',
              },
            ],
            NoDevice: '<optional>',
          },
        ],
        NetworkInterfaces: [
          {
            AssociatePublicIpAddress: '<optional>',
            DeleteOnTermination: '<optional>',
            Description: '<optional>',
            DeviceIndex: '<optional>',
            Groups: ['<optional1>', '<optional2>'],
            InterfaceType: '<optional>',
            Ipv6AddressCount: '<optional>',
            Ipv6Addresses: [
              {
                Ipv6Address: '<optional>',
              },
            ],
            NetworkInterfaceId: '<optional>',
            PrivateIpAddress: '<optional>',
            PrivateIpAddresses: [
              {
                Primary: '<optional>',
                PrivateIpAddress: '<optional>',
              },
            ],
            SecondaryPrivateIpAddressCount: '<optional>',
            SubnetId: '<optional>',
          },
        ],
        ImageId: '<optional>',
        InstanceType: '<optional>',
        KeyName: '<optional>',
        Monitoring: {
          Enabled: '<optional>',
        },
        Placement: {
          AvailabilityZone: '<optional>',
          Affinity: '<optional>',
          GroupName: '<optional>',
          HostId: '<optional>',
          Tenancy: '<optional>',
          SpreadDomain: '<optional>',
        },
        RamDiskId: '<optional>',
        DisableApiTermination: '<optional>',
        InstanceInitiatedShutdownBehavior: '<optional>',
        UserData: ['<optional1>', '<optional2>'],
        TagSpecifications: [
          {
            ResourceType: '<optional>',
            Tags: [
              {
                Key: '<optional>',
                Value: '<optional>',
              },
            ],
          },
        ],
        ElasticGpuSpecifications: ['<optional1>', '<optional2>'],
        ElasticInferenceAccelerators: ['<optional1>', '<optional2>'],
        SecurityGroupIds: ['<optional1>', '<optional2>'],
        SecurityGroups: ['<optional1>', '<optional2>'],
        InstanceMarketOptions: [
          {
            MarketType: '<optional>',
            SpotOptions: [
              {
                MaxPrice: '<optional>',
                SpotInstanceType: '<optional>',
                BlockDurationMinutes: ['<optional1>', '<optional2>'],
                ValidUntil: '<optional>',
                InstanceInterruptionBehavior: '<optional>',
              },
            ],
          },
        ],
        CreditSpecification: '<optional>',
        CpuOptions: [
          {
            CoreCount: '<optional>',
            ThreadsPerCore: '<optional>',
          },
        ],
        CapacityReservationSpecification: {
          CapacityReservationPreference: '<optional>',
          CapacityReservationTarget: {
            CapacityReservationId: '<optional>',
          },
        },
        LicenseSpecifications: [
          {
            LicenseConfigurationArn: '<optional>',
          },
        ],
        HibernationOptions: [
          {
            Configured: '<optional>',
          },
        ],
      },
    ],
    ClientToken: '<optional>',
    LaunchTemplateId: '<optional>',
    LaunchTemplateName: '<optional>',
    SourceVersion: '<optional>',
    VersionDescription: '<optional>',
  },
  'EC2:createLocalGatewayRoute': {
    DestinationCidrBlock: 'required',
    LocalGatewayRouteTableId: 'required',
    LocalGatewayVirtualInterfaceGroupId: 'required',
  },
  'EC2:createLocalGatewayRouteTableVpcAssociation': {
    LocalGatewayRouteTableId: 'required',
    VpcId: 'required',
  },
  'EC2:createNatGateway': {
    AllocationId: 'required',
    SubnetId: 'required',
    ClientToken: '<optional>',
  },
  'EC2:createNetworkAcl': {
    VpcId: 'required',
  },
  'EC2:createNetworkAclEntry': {
    Egress: 'required',
    NetworkAclId: 'required',
    Protocol: 'required',
    RuleAction: 'required',
    RuleNumber: 'required',
    CidrBlock: '<optional>',
    IcmpTypeCode: {
      Code: '<optional>',
      Type: '<optional>',
    },
    Ipv6CidrBlock: '<optional>',
    PortRange: {
      From: '<optional>',
      To: '<optional>',
    },
  },
  'EC2:createNetworkInterface': {
    SubnetId: 'required',
    Description: '<optional>',
    Groups: ['<optional1>', '<optional2>'],
    Ipv6AddressCount: '<optional>',
    Ipv6Addresses: [
      {
        Ipv6Address: '<optional>',
      },
    ],
    PrivateIpAddress: '<optional>',
    PrivateIpAddresses: [
      {
        Primary: '<optional>',
        PrivateIpAddress: '<optional>',
      },
    ],
    SecondaryPrivateIpAddressCount: '<optional>',
    InterfaceType: '<optional>',
  },
  'EC2:createNetworkInterfacePermission': {
    NetworkInterfaceId: 'required',
    Permission: 'required',
    AwsAccountId: '<optional>',
    AwsService: '<optional>',
  },
  'EC2:createPlacementGroup': {
    GroupName: '<optional>',
    Strategy: '<optional>',
    PartitionCount: '<optional>',
  },
  'EC2:createReservedInstancesListing': {
    ClientToken: 'required',
    InstanceCount: 'required',
    PriceSchedules: [
      {
        CurrencyCode: '<optional>',
        Price: '<optional>',
        Term: '<optional>',
      },
    ],
    ReservedInstancesId: 'required',
  },
  'EC2:createRoute': {
    RouteTableId: 'required',
    DestinationCidrBlock: '<optional>',
    DestinationIpv6CidrBlock: '<optional>',
    EgressOnlyInternetGatewayId: '<optional>',
    GatewayId: '<optional>',
    InstanceId: '<optional>',
    NatGatewayId: '<optional>',
    TransitGatewayId: '<optional>',
    NetworkInterfaceId: '<optional>',
    VpcPeeringConnectionId: '<optional>',
  },
  'EC2:createRouteTable': {
    VpcId: 'required',
  },
  'EC2:createSecurityGroup': {
    Description: 'required',
    GroupName: 'required',
    VpcId: '<optional>',
  },
  'EC2:createSnapshot': {
    VolumeId: 'required',
    Description: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createSnapshots': {
    InstanceSpecification: {
      InstanceId: '<optional>',
      ExcludeBootVolume: '<optional>',
    },
    Description: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
    CopyTagsFromSource: '<optional>',
  },
  'EC2:createSpotDatafeedSubscription': {
    Bucket: 'required',
    Prefix: '<optional>',
  },
  'EC2:createSubnet': {
    CidrBlock: 'required',
    VpcId: 'required',
    AvailabilityZone: '<optional>',
    AvailabilityZoneId: '<optional>',
    Ipv6CidrBlock: '<optional>',
  },
  'EC2:createTags': {
    Resources: ['required1', 'required2'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'EC2:createTrafficMirrorFilter': {
    Description: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
    ClientToken: '<optional>',
  },
  'EC2:createTrafficMirrorFilterRule': {
    TrafficMirrorFilterId: 'required',
    TrafficDirection: 'required',
    RuleNumber: 'required',
    RuleAction: 'required',
    DestinationCidrBlock: 'required',
    SourceCidrBlock: 'required',
    DestinationPortRange: {
      FromPort: '<optional>',
      ToPort: '<optional>',
    },
    SourcePortRange: {
      FromPort: '<optional>',
      ToPort: '<optional>',
    },
    Protocol: '<optional>',
    Description: '<optional>',
    ClientToken: '<optional>',
  },
  'EC2:createTrafficMirrorSession': {
    NetworkInterfaceId: 'required',
    TrafficMirrorTargetId: 'required',
    TrafficMirrorFilterId: 'required',
    SessionNumber: 'required',
    PacketLength: '<optional>',
    VirtualNetworkId: '<optional>',
    Description: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
    ClientToken: '<optional>',
  },
  'EC2:createTrafficMirrorTarget': {
    NetworkInterfaceId: '<optional>',
    NetworkLoadBalancerArn: '<optional>',
    Description: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
    ClientToken: '<optional>',
  },
  'EC2:createTransitGateway': {
    Description: '<optional>',
    Options: [
      {
        AmazonSideAsn: '<optional>',
        AutoAcceptSharedAttachments: ['<optional1>', '<optional2>'],
        DefaultRouteTableAssociation: '<optional>',
        DefaultRouteTablePropagation: '<optional>',
        VpnEcmpSupport: '<optional>',
        DnsSupport: '<optional>',
      },
    ],
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createTransitGatewayMulticastDomain': {
    TransitGatewayId: 'required',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createTransitGatewayPeeringAttachment': {
    TransitGatewayId: 'required',
    PeerTransitGatewayId: 'required',
    PeerAccountId: 'required',
    PeerRegion: 'required',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createTransitGatewayRoute': {
    DestinationCidrBlock: 'required',
    TransitGatewayRouteTableId: 'required',
    TransitGatewayAttachmentId: '<optional>',
    Blackhole: '<optional>',
  },
  'EC2:createTransitGatewayRouteTable': {
    TransitGatewayId: 'required',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createTransitGatewayVpcAttachment': {
    TransitGatewayId: 'required',
    VpcId: 'required',
    SubnetIds: ['required1', 'required2'],
    Options: [
      {
        DnsSupport: '<optional>',
        Ipv6Support: '<optional>',
      },
    ],
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createVolume': {
    AvailabilityZone: 'required',
    Encrypted: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    KmsKeyId: '<optional>',
    Size: '<optional>',
    SnapshotId: '<optional>',
    VolumeType: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createVpc': {
    CidrBlock: 'required',
    AmazonProvidedIpv6CidrBlock: '<optional>',
    InstanceTenancy: '<optional>',
  },
  'EC2:createVpcEndpoint': {
    VpcId: 'required',
    ServiceName: 'required',
    VpcEndpointType: '<optional>',
    PolicyDocument: '<optional>',
    RouteTableIds: ['<optional1>', '<optional2>'],
    SubnetIds: ['<optional1>', '<optional2>'],
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    ClientToken: '<optional>',
    PrivateDnsEnabled: '<optional>',
  },
  'EC2:createVpcEndpointConnectionNotification': {
    ConnectionNotificationArn: 'required',
    ConnectionEvents: ['required1', 'required2'],
    ServiceId: '<optional>',
    VpcEndpointId: '<optional>',
    ClientToken: '<optional>',
  },
  'EC2:createVpcEndpointServiceConfiguration': {
    NetworkLoadBalancerArns: ['required1', 'required2'],
    AcceptanceRequired: '<optional>',
    ClientToken: '<optional>',
  },
  'EC2:createVpcPeeringConnection': {
    PeerOwnerId: '<optional>',
    PeerVpcId: '<optional>',
    VpcId: '<optional>',
    PeerRegion: '<optional>',
  },
  'EC2:createVpnConnection': {
    CustomerGatewayId: 'required',
    Type: 'required',
    VpnGatewayId: '<optional>',
    TransitGatewayId: '<optional>',
    Options: [
      {
        StaticRoutesOnly: '<optional>',
        TunnelOptions: [
          {
            TunnelInsideCidr: '<optional>',
            PreSharedKey: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:createVpnConnectionRoute': {
    DestinationCidrBlock: 'required',
    VpnConnectionId: 'required',
  },
  'EC2:createVpnGateway': {
    Type: 'required',
    AvailabilityZone: '<optional>',
    AmazonSideAsn: '<optional>',
  },
  'EC2:deleteClientVpnEndpoint': {
    ClientVpnEndpointId: 'required',
  },
  'EC2:deleteClientVpnRoute': {
    ClientVpnEndpointId: 'required',
    DestinationCidrBlock: 'required',
    TargetVpcSubnetId: '<optional>',
  },
  'EC2:deleteCustomerGateway': {
    CustomerGatewayId: 'required',
  },
  'EC2:deleteDhcpOptions': {
    DhcpOptionsId: 'required',
  },
  'EC2:deleteEgressOnlyInternetGateway': {
    EgressOnlyInternetGatewayId: 'required',
  },
  'EC2:deleteFleets': {
    FleetIds: ['required1', 'required2'],
    TerminateInstances: ['required1', 'required2'],
  },
  'EC2:deleteFlowLogs': {
    FlowLogIds: ['required1', 'required2'],
  },
  'EC2:deleteFpgaImage': {
    FpgaImageId: 'required',
  },
  'EC2:deleteInternetGateway': {
    InternetGatewayId: 'required',
  },
  'EC2:deleteKeyPair': {
    KeyName: 'required',
  },
  'EC2:deleteLaunchTemplate': {
    LaunchTemplateId: '<optional>',
    LaunchTemplateName: '<optional>',
  },
  'EC2:deleteLaunchTemplateVersions': {
    Versions: ['required1', 'required2'],
    LaunchTemplateId: '<optional>',
    LaunchTemplateName: '<optional>',
  },
  'EC2:deleteLocalGatewayRoute': {
    DestinationCidrBlock: 'required',
    LocalGatewayRouteTableId: 'required',
  },
  'EC2:deleteLocalGatewayRouteTableVpcAssociation': {
    LocalGatewayRouteTableVpcAssociationId: 'required',
  },
  'EC2:deleteNatGateway': {
    NatGatewayId: 'required',
  },
  'EC2:deleteNetworkAcl': {
    NetworkAclId: 'required',
  },
  'EC2:deleteNetworkAclEntry': {
    Egress: 'required',
    NetworkAclId: 'required',
    RuleNumber: 'required',
  },
  'EC2:deleteNetworkInterface': {
    NetworkInterfaceId: 'required',
  },
  'EC2:deleteNetworkInterfacePermission': {
    NetworkInterfacePermissionId: 'required',
    Force: '<optional>',
  },
  'EC2:deletePlacementGroup': {
    GroupName: 'required',
  },
  'EC2:deleteQueuedReservedInstances': {
    ReservedInstancesIds: ['required1', 'required2'],
  },
  'EC2:deleteRoute': {
    RouteTableId: 'required',
    DestinationCidrBlock: '<optional>',
    DestinationIpv6CidrBlock: '<optional>',
  },
  'EC2:deleteRouteTable': {
    RouteTableId: 'required',
  },
  'EC2:deleteSecurityGroup': {
    GroupId: '<optional>',
    GroupName: '<optional>',
  },
  'EC2:deleteSnapshot': {
    SnapshotId: 'required',
  },
  'EC2:deleteSpotDatafeedSubscription': {},
  'EC2:deleteSubnet': {
    SubnetId: 'required',
  },
  'EC2:deleteTags': {
    Resources: ['required1', 'required2'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'EC2:deleteTrafficMirrorFilter': {
    TrafficMirrorFilterId: 'required',
  },
  'EC2:deleteTrafficMirrorFilterRule': {
    TrafficMirrorFilterRuleId: 'required',
  },
  'EC2:deleteTrafficMirrorSession': {
    TrafficMirrorSessionId: 'required',
  },
  'EC2:deleteTrafficMirrorTarget': {
    TrafficMirrorTargetId: 'required',
  },
  'EC2:deleteTransitGateway': {
    TransitGatewayId: 'required',
  },
  'EC2:deleteTransitGatewayMulticastDomain': {
    TransitGatewayMulticastDomainId: 'required',
  },
  'EC2:deleteTransitGatewayPeeringAttachment': {
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:deleteTransitGatewayRoute': {
    TransitGatewayRouteTableId: 'required',
    DestinationCidrBlock: 'required',
  },
  'EC2:deleteTransitGatewayRouteTable': {
    TransitGatewayRouteTableId: 'required',
  },
  'EC2:deleteTransitGatewayVpcAttachment': {
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:deleteVolume': {
    VolumeId: 'required',
  },
  'EC2:deleteVpc': {
    VpcId: 'required',
  },
  'EC2:deleteVpcEndpointConnectionNotifications': {
    ConnectionNotificationIds: ['required1', 'required2'],
  },
  'EC2:deleteVpcEndpointServiceConfigurations': {
    ServiceIds: ['required1', 'required2'],
  },
  'EC2:deleteVpcEndpoints': {
    VpcEndpointIds: ['required1', 'required2'],
  },
  'EC2:deleteVpcPeeringConnection': {
    VpcPeeringConnectionId: 'required',
  },
  'EC2:deleteVpnConnection': {
    VpnConnectionId: 'required',
  },
  'EC2:deleteVpnConnectionRoute': {
    DestinationCidrBlock: 'required',
    VpnConnectionId: 'required',
  },
  'EC2:deleteVpnGateway': {
    VpnGatewayId: 'required',
  },
  'EC2:deprovisionByoipCidr': {
    Cidr: 'required',
  },
  'EC2:deregisterImage': {
    ImageId: 'required',
  },
  'EC2:deregisterTransitGatewayMulticastGroupMembers': {
    TransitGatewayMulticastDomainId: '<optional>',
    GroupIpAddress: '<optional>',
    NetworkInterfaceIds: ['<optional1>', '<optional2>'],
  },
  'EC2:deregisterTransitGatewayMulticastGroupSources': {
    TransitGatewayMulticastDomainId: '<optional>',
    GroupIpAddress: '<optional>',
    NetworkInterfaceIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeAccountAttributes': {
    AttributeNames: ['<optional1>', '<optional2>'],
  },
  'EC2:describeAddresses': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    PublicIps: ['<optional1>', '<optional2>'],
    AllocationIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeAggregateIdFormat': {},
  'EC2:describeAvailabilityZones': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ZoneNames: ['<optional1>', '<optional2>'],
    ZoneIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeBundleTasks': {
    BundleIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeByoipCidrs': {},
  'EC2:describeCapacityReservations': {
    CapacityReservationIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeClassicLinkInstances': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeClientVpnAuthorizationRules': {
    ClientVpnEndpointId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeClientVpnConnections': {
    ClientVpnEndpointId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeClientVpnEndpoints': {
    ClientVpnEndpointIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeClientVpnRoutes': {
    ClientVpnEndpointId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeClientVpnTargetNetworks': {
    ClientVpnEndpointId: 'required',
    AssociationIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeCoipPools': {
    PoolIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeConversionTasks': {
    ConversionTaskIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeCustomerGateways': {
    CustomerGatewayIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeDhcpOptions': {
    DhcpOptionsIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeEgressOnlyInternetGateways': {
    EgressOnlyInternetGatewayIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeElasticGpus': {
    ElasticGpuIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeExportImageTasks': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ExportImageTaskIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeExportTasks': {
    ExportTaskIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeFastSnapshotRestores': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeFleetHistory': {
    FleetId: 'required',
    StartTime: 'required',
    EventType: '<optional>',
  },
  'EC2:describeFleetInstances': {
    FleetId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeFleets': {
    FleetIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeFlowLogs': {
    Filter: {
      Name: '<optional>',
      Values: ['<optional1>', '<optional2>'],
    },
    FlowLogIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeFpgaImageAttribute': {
    FpgaImageId: 'required',
    Attribute: 'required',
  },
  'EC2:describeFpgaImages': {
    FpgaImageIds: ['<optional1>', '<optional2>'],
    Owners: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeHostReservationOfferings': {
    Filter: {
      Name: '<optional>',
      Values: ['<optional1>', '<optional2>'],
    },
    MaxDuration: '<optional>',
    MinDuration: '<optional>',
    OfferingId: '<optional>',
  },
  'EC2:describeHostReservations': {
    Filter: {
      Name: '<optional>',
      Values: ['<optional1>', '<optional2>'],
    },
    HostReservationIdSet: '<optional>',
  },
  'EC2:describeHosts': {
    Filter: {
      Name: '<optional>',
      Values: ['<optional1>', '<optional2>'],
    },
    HostIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeIamInstanceProfileAssociations': {
    AssociationIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeIdFormat': {
    Resource: '<optional>',
  },
  'EC2:describeIdentityIdFormat': {
    PrincipalArn: 'required',
    Resource: '<optional>',
  },
  'EC2:describeImageAttribute': {
    Attribute: 'required',
    ImageId: 'required',
  },
  'EC2:describeImages': {
    Owners: ['<accountId>'],
    ExecutableUsers: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ImageIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeImportImageTasks': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ImportTaskIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeImportSnapshotTasks': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ImportTaskIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeInstanceAttribute': {
    Attribute: 'required',
    InstanceId: 'required',
  },
  'EC2:describeInstanceCreditSpecifications': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeInstanceStatus': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    InstanceIds: ['<optional1>', '<optional2>'],
    IncludeAllInstances: ['<optional1>', '<optional2>'],
  },
  'EC2:describeInstanceTypeOfferings': {
    LocationType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeInstanceTypes': {
    InstanceTypes: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeInstances': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeInternetGateways': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    InternetGatewayIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeKeyPairs': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    KeyNames: ['<optional1>', '<optional2>'],
  },
  'EC2:describeLaunchTemplateVersions': {
    LaunchTemplateId: '<optional>',
    LaunchTemplateName: '<optional>',
    Versions: ['<optional1>', '<optional2>'],
    MinVersion: '<optional>',
    MaxVersion: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeLaunchTemplates': {
    LaunchTemplateIds: ['<optional1>', '<optional2>'],
    LaunchTemplateNames: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeLocalGatewayRouteTableVirtualInterfaceGroupAssociations': {
    LocalGatewayRouteTableVirtualInterfaceGroupAssociationIds: [
      '<optional1>',
      '<optional2>',
    ],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeLocalGatewayRouteTableVpcAssociations': {
    LocalGatewayRouteTableVpcAssociationIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeLocalGatewayRouteTables': {
    LocalGatewayRouteTableIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeLocalGatewayVirtualInterfaceGroups': {
    LocalGatewayVirtualInterfaceGroupIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeLocalGatewayVirtualInterfaces': {
    LocalGatewayVirtualInterfaceIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeLocalGateways': {
    LocalGatewayIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeMovingAddresses': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    PublicIps: ['<optional1>', '<optional2>'],
  },
  'EC2:describeNatGateways': {
    Filter: {
      Name: '<optional>',
      Values: ['<optional1>', '<optional2>'],
    },
    NatGatewayIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeNetworkAcls': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    NetworkAclIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeNetworkInterfaceAttribute': {
    NetworkInterfaceId: 'required',
    Attribute: '<optional>',
  },
  'EC2:describeNetworkInterfacePermissions': {
    NetworkInterfacePermissionIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeNetworkInterfaces': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    NetworkInterfaceIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describePlacementGroups': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    GroupNames: ['<optional1>', '<optional2>'],
  },
  'EC2:describePrefixLists': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    PrefixListIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describePrincipalIdFormat': {
    Resources: ['<optional1>', '<optional2>'],
  },
  'EC2:describePublicIpv4Pools': {
    PoolIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeRegions': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    RegionNames: ['<optional1>', '<optional2>'],
  },
  'EC2:describeReservedInstances': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    OfferingClass: '<optional>',
    ReservedInstancesIds: ['<optional1>', '<optional2>'],
    OfferingType: '<optional>',
  },
  'EC2:describeReservedInstancesListings': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ReservedInstancesId: '<optional>',
    ReservedInstancesListingId: '<optional>',
  },
  'EC2:describeReservedInstancesModifications': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ReservedInstancesModificationIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeReservedInstancesOfferings': {
    AvailabilityZone: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    IncludeMarketplace: '<optional>',
    InstanceType: '<optional>',
    MaxDuration: '<optional>',
    MaxInstanceCount: '<optional>',
    MinDuration: '<optional>',
    OfferingClass: '<optional>',
    ProductDescription: '<optional>',
    ReservedInstancesOfferingIds: ['<optional1>', '<optional2>'],
    InstanceTenancy: '<optional>',
    OfferingType: '<optional>',
  },
  'EC2:describeRouteTables': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    RouteTableIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeScheduledInstanceAvailability': {
    FirstSlotStartTimeRange: {
      EarliestTime: 'required',
      LatestTime: 'required',
    },
    Recurrence: {
      Frequency: '<optional>',
      Interval: '<optional>',
      OccurrenceDays: ['<optional1>', '<optional2>'],
      OccurrenceRelativeToEnd: '<optional>',
      OccurrenceUnit: '<optional>',
    },
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxSlotDurationInHours: ['<optional1>', '<optional2>'],
    MinSlotDurationInHours: ['<optional1>', '<optional2>'],
  },
  'EC2:describeScheduledInstances': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ScheduledInstanceIds: ['<optional1>', '<optional2>'],
    SlotStartTimeRange: {
      EarliestTime: '<optional>',
      LatestTime: '<optional>',
    },
  },
  'EC2:describeSecurityGroupReferences': {
    GroupId: 'required',
  },
  'EC2:describeSecurityGroups': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    GroupIds: ['<optional1>', '<optional2>'],
    GroupNames: ['<optional1>', '<optional2>'],
  },
  'EC2:describeSnapshotAttribute': {
    Attribute: 'required',
    SnapshotId: 'required',
  },
  'EC2:describeSnapshots': {
    OwnerIds: ['<accountId>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    RestorableByUserIds: ['<optional1>', '<optional2>'],
    SnapshotIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeSpotDatafeedSubscription': {},
  'EC2:describeSpotFleetInstances': {
    SpotFleetRequestId: 'required',
  },
  'EC2:describeSpotFleetRequestHistory': {
    SpotFleetRequestId: 'required',
    StartTime: 'required',
    EventType: '<optional>',
  },
  'EC2:describeSpotFleetRequests': {
    SpotFleetRequestIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeSpotInstanceRequests': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    SpotInstanceRequestIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeSpotPriceHistory': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    AvailabilityZone: '<optional>',
    EndTime: '<optional>',
    InstanceTypes: ['<optional1>', '<optional2>'],
    ProductDescriptions: ['<optional1>', '<optional2>'],
    StartTime: '<optional>',
  },
  'EC2:describeStaleSecurityGroups': {
    VpcId: 'required',
  },
  'EC2:describeSubnets': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    SubnetIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeTags': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTrafficMirrorFilters': {
    TrafficMirrorFilterIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTrafficMirrorSessions': {
    TrafficMirrorSessionIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTrafficMirrorTargets': {
    TrafficMirrorTargetIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTransitGatewayAttachments': {
    TransitGatewayAttachmentIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTransitGatewayMulticastDomains': {
    TransitGatewayMulticastDomainIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTransitGatewayPeeringAttachments': {
    TransitGatewayAttachmentIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTransitGatewayRouteTables': {
    TransitGatewayRouteTableIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTransitGatewayVpcAttachments': {
    TransitGatewayAttachmentIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeTransitGateways': {
    TransitGatewayIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeVolumeAttribute': {
    Attribute: 'required',
    VolumeId: 'required',
  },
  'EC2:describeVolumeStatus': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VolumeIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeVolumes': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VolumeIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeVolumesModifications': {
    VolumeIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeVpcAttribute': {
    Attribute: 'required',
    VpcId: 'required',
  },
  'EC2:describeVpcClassicLink': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VpcIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeVpcClassicLinkDnsSupport': {
    VpcIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeVpcEndpointConnectionNotifications': {
    ConnectionNotificationId: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeVpcEndpointConnections': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeVpcEndpointServiceConfigurations': {
    ServiceIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeVpcEndpointServicePermissions': {
    ServiceId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeVpcEndpointServices': {
    ServiceNames: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeVpcEndpoints': {
    VpcEndpointIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:describeVpcPeeringConnections': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VpcPeeringConnectionIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeVpcs': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VpcIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeVpnConnections': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VpnConnectionIds: ['<optional1>', '<optional2>'],
  },
  'EC2:describeVpnGateways': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VpnGatewayIds: ['<optional1>', '<optional2>'],
  },
  'EC2:detachClassicLinkVpc': {
    InstanceId: 'required',
    VpcId: 'required',
  },
  'EC2:detachInternetGateway': {
    InternetGatewayId: 'required',
    VpcId: 'required',
  },
  'EC2:detachNetworkInterface': {
    AttachmentId: 'required',
    Force: '<optional>',
  },
  'EC2:detachVolume': {
    VolumeId: 'required',
    Device: '<optional>',
    Force: '<optional>',
    InstanceId: '<optional>',
  },
  'EC2:detachVpnGateway': {
    VpcId: 'required',
    VpnGatewayId: 'required',
  },
  'EC2:disableEbsEncryptionByDefault': {},
  'EC2:disableFastSnapshotRestores': {
    AvailabilityZones: ['required1', 'required2'],
    SourceSnapshotIds: ['required1', 'required2'],
  },
  'EC2:disableTransitGatewayRouteTablePropagation': {
    TransitGatewayRouteTableId: 'required',
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:disableVgwRoutePropagation': {
    GatewayId: 'required',
    RouteTableId: 'required',
  },
  'EC2:disableVpcClassicLink': {
    VpcId: 'required',
  },
  'EC2:disableVpcClassicLinkDnsSupport': {
    VpcId: '<optional>',
  },
  'EC2:disassociateAddress': {
    AssociationId: '<optional>',
    PublicIp: '<optional>',
  },
  'EC2:disassociateClientVpnTargetNetwork': {
    ClientVpnEndpointId: 'required',
    AssociationId: 'required',
  },
  'EC2:disassociateIamInstanceProfile': {
    AssociationId: 'required',
  },
  'EC2:disassociateRouteTable': {
    AssociationId: 'required',
  },
  'EC2:disassociateSubnetCidrBlock': {
    AssociationId: 'required',
  },
  'EC2:disassociateTransitGatewayMulticastDomain': {
    TransitGatewayMulticastDomainId: '<optional>',
    TransitGatewayAttachmentId: '<optional>',
    SubnetIds: ['<optional1>', '<optional2>'],
  },
  'EC2:disassociateTransitGatewayRouteTable': {
    TransitGatewayRouteTableId: 'required',
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:disassociateVpcCidrBlock': {
    AssociationId: 'required',
  },
  'EC2:enableEbsEncryptionByDefault': {},
  'EC2:enableFastSnapshotRestores': {
    AvailabilityZones: ['required1', 'required2'],
    SourceSnapshotIds: ['required1', 'required2'],
  },
  'EC2:enableTransitGatewayRouteTablePropagation': {
    TransitGatewayRouteTableId: 'required',
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:enableVgwRoutePropagation': {
    GatewayId: 'required',
    RouteTableId: 'required',
  },
  'EC2:enableVolumeIO': {
    VolumeId: 'required',
  },
  'EC2:enableVpcClassicLink': {
    VpcId: 'required',
  },
  'EC2:enableVpcClassicLinkDnsSupport': {
    VpcId: '<optional>',
  },
  'EC2:exportClientVpnClientCertificateRevocationList': {
    ClientVpnEndpointId: 'required',
  },
  'EC2:exportClientVpnClientConfiguration': {
    ClientVpnEndpointId: 'required',
  },
  'EC2:exportImage': {
    DiskImageFormat: 'required',
    ImageId: 'required',
    S3ExportLocation: {
      S3Prefix: '<optional>',
    },
    ClientToken: '<optional>',
    Description: '<optional>',
    RoleName: '<optional>',
  },
  'EC2:exportTransitGatewayRoutes': {
    TransitGatewayRouteTableId: 'required',
    S3Bucket: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:getCapacityReservationUsage': {
    CapacityReservationId: 'required',
  },
  'EC2:getCoipPoolUsage': {
    PoolId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:getConsoleOutput': {
    InstanceId: 'required',
    Latest: '<optional>',
  },
  'EC2:getConsoleScreenshot': {
    InstanceId: 'required',
    WakeUp: '<optional>',
  },
  'EC2:getDefaultCreditSpecification': {
    InstanceFamily: 'required',
  },
  'EC2:getEbsDefaultKmsKeyId': {},
  'EC2:getEbsEncryptionByDefault': {},
  'EC2:getHostReservationPurchasePreview': {
    HostIdSet: 'required',
    OfferingId: 'required',
  },
  'EC2:getLaunchTemplateData': {
    InstanceId: 'required',
  },
  'EC2:getPasswordData': {
    InstanceId: 'required',
  },
  'EC2:getReservedInstancesExchangeQuote': {
    ReservedInstanceIds: ['required1', 'required2'],
    TargetConfigurations: [
      {
        InstanceCount: '<optional>',
      },
    ],
  },
  'EC2:getTransitGatewayAttachmentPropagations': {
    TransitGatewayAttachmentId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:getTransitGatewayMulticastDomainAssociations': {
    TransitGatewayMulticastDomainId: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:getTransitGatewayRouteTableAssociations': {
    TransitGatewayRouteTableId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:getTransitGatewayRouteTablePropagations': {
    TransitGatewayRouteTableId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:importClientVpnClientCertificateRevocationList': {
    ClientVpnEndpointId: 'required',
    CertificateRevocationList: 'required',
  },
  'EC2:importImage': {
    Architecture: '<optional>',
    ClientData: [
      {
        Comment: '<optional>',
        UploadEnd: '<optional>',
        UploadSize: '<optional>',
        UploadStart: '<optional>',
      },
    ],
    ClientToken: '<optional>',
    Description: '<optional>',
    DiskContainers: [
      {
        Description: '<optional>',
        DeviceName: '<optional>',
        Format: '<optional>',
        SnapshotId: '<optional>',
        Url: '<optional>',
        UserBucket: {
          S3Bucket: '<optional>',
          S3Key: '<optional>',
        },
      },
    ],
    Encrypted: '<optional>',
    Hypervisor: '<optional>',
    KmsKeyId: '<optional>',
    LicenseType: '<optional>',
    Platform: '<optional>',
    RoleName: '<optional>',
  },
  'EC2:importInstance': {
    Platform: 'required',
    DiskImages: [
      {
        Description: '<optional>',
        Image: '<optional>',
        Volume: '<optional>',
      },
    ],
    Description: '<optional>',
    LaunchSpecification: {
      AdditionalInfo: '<optional>',
      Architecture: '<optional>',
      GroupIds: ['<optional1>', '<optional2>'],
      GroupNames: ['<optional1>', '<optional2>'],
      InstanceInitiatedShutdownBehavior: '<optional>',
      InstanceType: '<optional>',
      Monitoring: '<optional>',
      Placement: {
        AvailabilityZone: '<optional>',
        Affinity: '<optional>',
        GroupName: '<optional>',
        PartitionNumber: '<optional>',
        HostId: '<optional>',
        Tenancy: '<optional>',
        SpreadDomain: '<optional>',
      },
      PrivateIpAddress: '<optional>',
      SubnetId: '<optional>',
      UserData: [
        {
          Data: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'EC2:importKeyPair': {
    KeyName: 'required',
    PublicKeyMaterial: 'required',
  },
  'EC2:importSnapshot': {
    ClientData: [
      {
        Comment: '<optional>',
        UploadEnd: '<optional>',
        UploadSize: '<optional>',
        UploadStart: '<optional>',
      },
    ],
    ClientToken: '<optional>',
    Description: '<optional>',
    DiskContainer: {
      Description: '<optional>',
      Format: '<optional>',
      Url: '<optional>',
      UserBucket: {
        S3Bucket: '<optional>',
        S3Key: '<optional>',
      },
    },
    Encrypted: '<optional>',
    KmsKeyId: '<optional>',
    RoleName: '<optional>',
  },
  'EC2:importVolume': {
    AvailabilityZone: 'required',
    Image: {
      Bytes: ['required1', 'required2'],
      Format: 'required',
      ImportManifestUrl: 'required',
    },
    Volume: {
      Size: 'required',
    },
    Description: '<optional>',
  },
  'EC2:modifyCapacityReservation': {
    CapacityReservationId: 'required',
    InstanceCount: '<optional>',
    EndDate: '<optional>',
    EndDateType: '<optional>',
  },
  'EC2:modifyClientVpnEndpoint': {
    ClientVpnEndpointId: 'required',
    ServerCertificateArn: '<optional>',
    ConnectionLogOptions: [
      {
        Enabled: '<optional>',
        CloudwatchLogGroup: '<optional>',
        CloudwatchLogStream: '<optional>',
      },
    ],
    DnsServers: [
      {
        CustomDnsServers: ['<optional1>', '<optional2>'],
        Enabled: '<optional>',
      },
    ],
    Description: '<optional>',
  },
  'EC2:modifyDefaultCreditSpecification': {
    InstanceFamily: 'required',
    CpuCredits: ['required1', 'required2'],
  },
  'EC2:modifyEbsDefaultKmsKeyId': {
    KmsKeyId: 'required',
  },
  'EC2:modifyFleet': {
    FleetId: 'required',
    TargetCapacitySpecification: {
      OnDemandTargetCapacity: '<optional>',
      SpotTargetCapacity: '<optional>',
      DefaultTargetCapacityType: '<optional>',
    },
    ExcessCapacityTerminationPolicy: '<optional>',
  },
  'EC2:modifyFpgaImageAttribute': {
    FpgaImageId: 'required',
    Attribute: '<optional>',
    OperationType: '<optional>',
    UserIds: ['<optional1>', '<optional2>'],
    UserGroups: ['<optional1>', '<optional2>'],
    ProductCodes: ['<optional1>', '<optional2>'],
    LoadPermission: {
      Add: {
        Group: '<optional>',
        UserId: '<optional>',
      },
      Remove: {
        Group: '<optional>',
        UserId: '<optional>',
      },
    },
    Description: '<optional>',
    Name: '<optional>',
  },
  'EC2:modifyHosts': {
    HostIds: ['required1', 'required2'],
    AutoPlacement: '<optional>',
    HostRecovery: '<optional>',
  },
  'EC2:modifyIdFormat': {
    Resource: 'required',
    UseLongIds: ['required1', 'required2'],
  },
  'EC2:modifyIdentityIdFormat': {
    PrincipalArn: 'required',
    Resource: 'required',
    UseLongIds: ['required1', 'required2'],
  },
  'EC2:modifyImageAttribute': {
    ImageId: 'required',
    Attribute: '<optional>',
    Description: {
      Value: '<optional>',
    },
    LaunchPermission: {
      Add: {
        Group: '<optional>',
        UserId: '<optional>',
      },
      Remove: {
        Group: '<optional>',
        UserId: '<optional>',
      },
    },
    OperationType: '<optional>',
    ProductCodes: ['<optional1>', '<optional2>'],
    UserGroups: ['<optional1>', '<optional2>'],
    UserIds: ['<optional1>', '<optional2>'],
    Value: '<optional>',
  },
  'EC2:modifyInstanceAttribute': {
    InstanceId: 'required',
    SourceDestCheck: {
      Value: '<optional>',
    },
    Attribute: '<optional>',
    BlockDeviceMappings: [
      {
        DeviceName: '<optional>',
        Ebs: [
          {
            DeleteOnTermination: '<optional>',
            VolumeId: '<optional>',
          },
        ],
        NoDevice: '<optional>',
        VirtualName: '<optional>',
      },
    ],
    DisableApiTermination: {
      Value: '<optional>',
    },
    EbsOptimized: {
      Value: '<optional>',
    },
    EnaSupport: {
      Value: '<optional>',
    },
    Groups: ['<optional1>', '<optional2>'],
    InstanceInitiatedShutdownBehavior: {
      Value: '<optional>',
    },
    InstanceType: {
      Value: '<optional>',
    },
    Kernel: {
      Value: '<optional>',
    },
    Ramdisk: {
      Value: '<optional>',
    },
    SriovNetSupport: {
      Value: '<optional>',
    },
    UserData: [
      {
        Value: '<optional>',
      },
    ],
    Value: '<optional>',
  },
  'EC2:modifyInstanceCapacityReservationAttributes': {
    InstanceId: 'required',
    CapacityReservationSpecification: {
      CapacityReservationPreference: '<optional>',
      CapacityReservationTarget: {
        CapacityReservationId: '<optional>',
      },
    },
  },
  'EC2:modifyInstanceCreditSpecification': {
    InstanceCreditSpecifications: [
      {
        InstanceId: '<optional>',
        CpuCredits: ['<optional1>', '<optional2>'],
      },
    ],
    ClientToken: '<optional>',
  },
  'EC2:modifyInstanceEventStartTime': {
    InstanceId: 'required',
    InstanceEventId: 'required',
    NotBefore: 'required',
  },
  'EC2:modifyInstanceMetadataOptions': {
    InstanceId: 'required',
    HttpTokens: ['<optional1>', '<optional2>'],
    HttpPutResponseHopLimit: '<optional>',
    HttpEndpoint: '<optional>',
  },
  'EC2:modifyInstancePlacement': {
    InstanceId: 'required',
    Affinity: '<optional>',
    GroupName: '<optional>',
    HostId: '<optional>',
    Tenancy: '<optional>',
    PartitionNumber: '<optional>',
  },
  'EC2:modifyLaunchTemplate': {
    ClientToken: '<optional>',
    LaunchTemplateId: '<optional>',
    LaunchTemplateName: '<optional>',
    DefaultVersion: '<optional>',
  },
  'EC2:modifyNetworkInterfaceAttribute': {
    NetworkInterfaceId: 'required',
    Attachment: {
      AttachmentId: '<optional>',
      DeleteOnTermination: '<optional>',
    },
    Description: {
      Value: '<optional>',
    },
    Groups: ['<optional1>', '<optional2>'],
    SourceDestCheck: {
      Value: '<optional>',
    },
  },
  'EC2:modifyReservedInstances': {
    ReservedInstancesIds: ['required1', 'required2'],
    TargetConfigurations: [
      {
        AvailabilityZone: '<optional>',
        InstanceCount: '<optional>',
        InstanceType: '<optional>',
        Platform: '<optional>',
        Scope: '<optional>',
      },
    ],
    ClientToken: '<optional>',
  },
  'EC2:modifySnapshotAttribute': {
    SnapshotId: 'required',
    Attribute: '<optional>',
    CreateVolumePermission: {
      Add: {
        Group: '<optional>',
        UserId: '<optional>',
      },
      Remove: {
        Group: '<optional>',
        UserId: '<optional>',
      },
    },
    GroupNames: ['<optional1>', '<optional2>'],
    OperationType: '<optional>',
    UserIds: ['<optional1>', '<optional2>'],
  },
  'EC2:modifySpotFleetRequest': {
    SpotFleetRequestId: 'required',
    ExcessCapacityTerminationPolicy: '<optional>',
    TargetCapacity: '<optional>',
    OnDemandTargetCapacity: '<optional>',
  },
  'EC2:modifySubnetAttribute': {
    SubnetId: 'required',
    AssignIpv6AddressOnCreation: {
      Value: '<optional>',
    },
    MapPublicIpOnLaunch: {
      Value: '<optional>',
    },
  },
  'EC2:modifyTrafficMirrorFilterNetworkServices': {
    TrafficMirrorFilterId: 'required',
    AddNetworkServices: ['<optional1>', '<optional2>'],
    RemoveNetworkServices: ['<optional1>', '<optional2>'],
  },
  'EC2:modifyTrafficMirrorFilterRule': {
    TrafficMirrorFilterRuleId: 'required',
    TrafficDirection: '<optional>',
    RuleNumber: '<optional>',
    RuleAction: '<optional>',
    DestinationPortRange: {
      FromPort: '<optional>',
      ToPort: '<optional>',
    },
    SourcePortRange: {
      FromPort: '<optional>',
      ToPort: '<optional>',
    },
    Protocol: '<optional>',
    DestinationCidrBlock: '<optional>',
    SourceCidrBlock: '<optional>',
    Description: '<optional>',
    RemoveFields: ['<optional1>', '<optional2>'],
  },
  'EC2:modifyTrafficMirrorSession': {
    TrafficMirrorSessionId: 'required',
    TrafficMirrorTargetId: '<optional>',
    TrafficMirrorFilterId: '<optional>',
    PacketLength: '<optional>',
    SessionNumber: '<optional>',
    VirtualNetworkId: '<optional>',
    Description: '<optional>',
    RemoveFields: ['<optional1>', '<optional2>'],
  },
  'EC2:modifyTransitGatewayVpcAttachment': {
    TransitGatewayAttachmentId: 'required',
    AddSubnetIds: ['<optional1>', '<optional2>'],
    RemoveSubnetIds: ['<optional1>', '<optional2>'],
    Options: [
      {
        DnsSupport: '<optional>',
        Ipv6Support: '<optional>',
      },
    ],
  },
  'EC2:modifyVolume': {
    VolumeId: 'required',
    Size: '<optional>',
    VolumeType: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
  },
  'EC2:modifyVolumeAttribute': {
    VolumeId: 'required',
    AutoEnableIO: {
      Value: '<optional>',
    },
  },
  'EC2:modifyVpcAttribute': {
    VpcId: 'required',
    EnableDnsHostnames: [
      {
        Value: '<optional>',
      },
    ],
    EnableDnsSupport: {
      Value: '<optional>',
    },
  },
  'EC2:modifyVpcEndpoint': {
    VpcEndpointId: 'required',
    ResetPolicy: '<optional>',
    PolicyDocument: '<optional>',
    AddRouteTableIds: ['<optional1>', '<optional2>'],
    RemoveRouteTableIds: ['<optional1>', '<optional2>'],
    AddSubnetIds: ['<optional1>', '<optional2>'],
    RemoveSubnetIds: ['<optional1>', '<optional2>'],
    AddSecurityGroupIds: ['<optional1>', '<optional2>'],
    RemoveSecurityGroupIds: ['<optional1>', '<optional2>'],
    PrivateDnsEnabled: '<optional>',
  },
  'EC2:modifyVpcEndpointConnectionNotification': {
    ConnectionNotificationId: 'required',
    ConnectionNotificationArn: '<optional>',
    ConnectionEvents: ['<optional1>', '<optional2>'],
  },
  'EC2:modifyVpcEndpointServiceConfiguration': {
    ServiceId: 'required',
    AcceptanceRequired: '<optional>',
    AddNetworkLoadBalancerArns: ['<optional1>', '<optional2>'],
    RemoveNetworkLoadBalancerArns: ['<optional1>', '<optional2>'],
  },
  'EC2:modifyVpcEndpointServicePermissions': {
    ServiceId: 'required',
    AddAllowedPrincipals: ['<optional1>', '<optional2>'],
    RemoveAllowedPrincipals: ['<optional1>', '<optional2>'],
  },
  'EC2:modifyVpcPeeringConnectionOptions': {
    VpcPeeringConnectionId: 'required',
    AccepterPeeringConnectionOptions: [
      {
        AllowDnsResolutionFromRemoteVpc: '<optional>',
        AllowEgressFromLocalClassicLinkToRemoteVpc: '<optional>',
        AllowEgressFromLocalVpcToRemoteClassicLink: '<optional>',
      },
    ],
    RequesterPeeringConnectionOptions: [
      {
        AllowDnsResolutionFromRemoteVpc: '<optional>',
        AllowEgressFromLocalClassicLinkToRemoteVpc: '<optional>',
        AllowEgressFromLocalVpcToRemoteClassicLink: '<optional>',
      },
    ],
  },
  'EC2:modifyVpcTenancy': {
    VpcId: 'required',
    InstanceTenancy: 'required',
  },
  'EC2:modifyVpnConnection': {
    VpnConnectionId: 'required',
    TransitGatewayId: '<optional>',
    VpnGatewayId: '<optional>',
  },
  'EC2:modifyVpnTunnelCertificate': {
    VpnConnectionId: 'required',
    VpnTunnelOutsideIpAddress: 'required',
  },
  'EC2:modifyVpnTunnelOptions': {
    VpnConnectionId: 'required',
    VpnTunnelOutsideIpAddress: 'required',
    TunnelOptions: [
      {
        TunnelInsideCidr: '<optional>',
        PreSharedKey: '<optional>',
        Phase1LifetimeSeconds: ['<optional1>', '<optional2>'],
        Phase2LifetimeSeconds: ['<optional1>', '<optional2>'],
        RekeyMarginTimeSeconds: ['<optional1>', '<optional2>'],
        RekeyFuzzPercentage: '<optional>',
        ReplayWindowSize: '<optional>',
        DPDTimeoutSeconds: ['<optional1>', '<optional2>'],
        Phase1EncryptionAlgorithms: [
          {
            Value: '<optional>',
          },
        ],
        Phase2EncryptionAlgorithms: [
          {
            Value: '<optional>',
          },
        ],
        Phase1IntegrityAlgorithms: [
          {
            Value: '<optional>',
          },
        ],
        Phase2IntegrityAlgorithms: [
          {
            Value: '<optional>',
          },
        ],
        Phase1DHGroupNumbers: [
          {
            Value: '<optional>',
          },
        ],
        Phase2DHGroupNumbers: [
          {
            Value: '<optional>',
          },
        ],
        IKEVersions: [
          {
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'EC2:monitorInstances': {
    InstanceIds: ['required1', 'required2'],
  },
  'EC2:moveAddressToVpc': {
    PublicIp: 'required',
  },
  'EC2:provisionByoipCidr': {
    Cidr: 'required',
    CidrAuthorizationContext: '<optional>',
    Description: '<optional>',
  },
  'EC2:purchaseHostReservation': {
    HostIdSet: 'required',
    OfferingId: 'required',
    ClientToken: '<optional>',
    CurrencyCode: '<optional>',
    LimitPrice: '<optional>',
  },
  'EC2:purchaseReservedInstancesOffering': {
    InstanceCount: 'required',
    ReservedInstancesOfferingId: 'required',
    LimitPrice: {
      Amount: '<optional>',
      CurrencyCode: '<optional>',
    },
  },
  'EC2:purchaseScheduledInstances': {
    PurchaseRequests: [
      {
        InstanceCount: 'required',
        PurchaseToken: 'required',
      },
    ],
    ClientToken: '<optional>',
  },
  'EC2:rebootInstances': {
    InstanceIds: ['required1', 'required2'],
  },
  'EC2:registerImage': {
    Name: 'required',
    ImageLocation: '<optional>',
    Architecture: '<optional>',
    BlockDeviceMappings: [
      {
        DeviceName: '<optional>',
        VirtualName: '<optional>',
        Ebs: [
          {
            DeleteOnTermination: '<optional>',
            Iops: ['<optional1>', '<optional2>'],
            SnapshotId: '<optional>',
            VolumeSize: '<optional>',
            VolumeType: '<optional>',
            Encrypted: '<optional>',
            KmsKeyId: '<optional>',
          },
        ],
        NoDevice: '<optional>',
      },
    ],
    Description: '<optional>',
    EnaSupport: '<optional>',
    KernelId: '<optional>',
    BillingProducts: ['<optional1>', '<optional2>'],
    RamdiskId: '<optional>',
    RootDeviceName: '<optional>',
    SriovNetSupport: '<optional>',
    VirtualizationType: '<optional>',
  },
  'EC2:registerTransitGatewayMulticastGroupMembers': {
    TransitGatewayMulticastDomainId: '<optional>',
    GroupIpAddress: '<optional>',
    NetworkInterfaceIds: ['<optional1>', '<optional2>'],
  },
  'EC2:registerTransitGatewayMulticastGroupSources': {
    TransitGatewayMulticastDomainId: '<optional>',
    GroupIpAddress: '<optional>',
    NetworkInterfaceIds: ['<optional1>', '<optional2>'],
  },
  'EC2:rejectTransitGatewayPeeringAttachment': {
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:rejectTransitGatewayVpcAttachment': {
    TransitGatewayAttachmentId: 'required',
  },
  'EC2:rejectVpcEndpointConnections': {
    ServiceId: 'required',
    VpcEndpointIds: ['required1', 'required2'],
  },
  'EC2:rejectVpcPeeringConnection': {
    VpcPeeringConnectionId: 'required',
  },
  'EC2:releaseAddress': {
    AllocationId: '<optional>',
    PublicIp: '<optional>',
  },
  'EC2:releaseHosts': {
    HostIds: ['required1', 'required2'],
  },
  'EC2:replaceIamInstanceProfileAssociation': {
    IamInstanceProfile: {
      Arn: '<optional>',
      Name: '<optional>',
    },
    AssociationId: 'required',
  },
  'EC2:replaceNetworkAclAssociation': {
    AssociationId: 'required',
    NetworkAclId: 'required',
  },
  'EC2:replaceNetworkAclEntry': {
    Egress: 'required',
    NetworkAclId: 'required',
    Protocol: 'required',
    RuleAction: 'required',
    RuleNumber: 'required',
    CidrBlock: '<optional>',
    IcmpTypeCode: {
      Code: '<optional>',
      Type: '<optional>',
    },
    Ipv6CidrBlock: '<optional>',
    PortRange: {
      From: '<optional>',
      To: '<optional>',
    },
  },
  'EC2:replaceRoute': {
    RouteTableId: 'required',
    DestinationCidrBlock: '<optional>',
    DestinationIpv6CidrBlock: '<optional>',
    EgressOnlyInternetGatewayId: '<optional>',
    GatewayId: '<optional>',
    InstanceId: '<optional>',
    NatGatewayId: '<optional>',
    TransitGatewayId: '<optional>',
    NetworkInterfaceId: '<optional>',
    VpcPeeringConnectionId: '<optional>',
  },
  'EC2:replaceRouteTableAssociation': {
    AssociationId: 'required',
    RouteTableId: 'required',
  },
  'EC2:replaceTransitGatewayRoute': {
    DestinationCidrBlock: 'required',
    TransitGatewayRouteTableId: 'required',
    TransitGatewayAttachmentId: '<optional>',
    Blackhole: '<optional>',
  },
  'EC2:reportInstanceStatus': {
    Instances: ['required1', 'required2'],
    ReasonCodes: ['required1', 'required2'],
    Status: 'required',
    Description: '<optional>',
    EndTime: '<optional>',
    StartTime: '<optional>',
  },
  'EC2:requestSpotFleet': {
    SpotFleetRequestConfig: {
      AllocationStrategy: '<optional>',
      OnDemandAllocationStrategy: '<optional>',
      ClientToken: '<optional>',
      ExcessCapacityTerminationPolicy: '<optional>',
      FulfilledCapacity: '<optional>',
      OnDemandFulfilledCapacity: '<optional>',
      LaunchSpecifications: [
        {
          SecurityGroups: [
            {
              GroupName: '<optional>',
              GroupId: '<optional>',
            },
          ],
          AddressingType: '<optional>',
          BlockDeviceMappings: [
            {
              DeviceName: '<optional>',
              VirtualName: '<optional>',
              Ebs: [
                {
                  DeleteOnTermination: '<optional>',
                  Iops: ['<optional1>', '<optional2>'],
                  SnapshotId: '<optional>',
                  VolumeSize: '<optional>',
                  VolumeType: '<optional>',
                  Encrypted: '<optional>',
                  KmsKeyId: '<optional>',
                },
              ],
              NoDevice: '<optional>',
            },
          ],
          EbsOptimized: '<optional>',
          IamInstanceProfile: {
            Arn: '<optional>',
            Name: '<optional>',
          },
          ImageId: '<optional>',
          InstanceType: '<optional>',
          KernelId: '<optional>',
          KeyName: '<optional>',
          Monitoring: {
            Enabled: '<optional>',
          },
          NetworkInterfaces: [
            {
              AssociatePublicIpAddress: '<optional>',
              DeleteOnTermination: '<optional>',
              Description: '<optional>',
              DeviceIndex: '<optional>',
              Groups: ['<optional1>', '<optional2>'],
              Ipv6AddressCount: '<optional>',
              Ipv6Addresses: [
                {
                  Ipv6Address: '<optional>',
                },
              ],
              NetworkInterfaceId: '<optional>',
              PrivateIpAddress: '<optional>',
              PrivateIpAddresses: [
                {
                  Primary: '<optional>',
                  PrivateIpAddress: '<optional>',
                },
              ],
              SecondaryPrivateIpAddressCount: '<optional>',
              SubnetId: '<optional>',
              InterfaceType: '<optional>',
            },
          ],
          Placement: {
            AvailabilityZone: '<optional>',
            GroupName: '<optional>',
            Tenancy: '<optional>',
          },
          RamdiskId: '<optional>',
          SpotPrice: '<optional>',
          SubnetId: '<optional>',
          UserData: ['<optional1>', '<optional2>'],
          WeightedCapacity: '<optional>',
          TagSpecifications: [
            {
              ResourceType: '<optional>',
              Tags: [
                {
                  Key: '<optional>',
                  Value: '<optional>',
                },
              ],
            },
          ],
        },
      ],
      LaunchTemplateConfigs: [
        {
          LaunchTemplateSpecification: {
            LaunchTemplateId: '<optional>',
            LaunchTemplateName: '<optional>',
            Version: '<optional>',
          },
          Overrides: [
            {
              InstanceType: '<optional>',
              SpotPrice: '<optional>',
              SubnetId: '<optional>',
              AvailabilityZone: '<optional>',
              WeightedCapacity: '<optional>',
              Priority: '<optional>',
            },
          ],
        },
      ],
      SpotPrice: '<optional>',
      OnDemandTargetCapacity: '<optional>',
      OnDemandMaxTotalPrice: '<optional>',
      SpotMaxTotalPrice: '<optional>',
      TerminateInstancesWithExpiration: '<optional>',
      Type: '<optional>',
      ValidFrom: '<optional>',
      ValidUntil: '<optional>',
      ReplaceUnhealthyInstances: ['<optional1>', '<optional2>'],
      InstanceInterruptionBehavior: '<optional>',
      LoadBalancersConfig: {
        ClassicLoadBalancersConfig: {
          ClassicLoadBalancers: [
            {
              Name: '<optional>',
            },
          ],
        },
        TargetGroupsConfig: {
          TargetGroups: [
            {
              Arn: '<optional>',
            },
          ],
        },
      },
      InstancePoolsToUseCount: '<optional>',
    },
  },
  'EC2:requestSpotInstances': {
    LaunchSpecification: {
      SecurityGroupIds: ['<optional1>', '<optional2>'],
      SecurityGroups: ['<optional1>', '<optional2>'],
      AddressingType: '<optional>',
      BlockDeviceMappings: [
        {
          DeviceName: '<optional>',
          VirtualName: '<optional>',
          Ebs: [
            {
              DeleteOnTermination: '<optional>',
              Iops: ['<optional1>', '<optional2>'],
              SnapshotId: '<optional>',
              VolumeSize: '<optional>',
              VolumeType: '<optional>',
              Encrypted: '<optional>',
              KmsKeyId: '<optional>',
            },
          ],
          NoDevice: '<optional>',
        },
      ],
      EbsOptimized: '<optional>',
      IamInstanceProfile: {
        Arn: '<optional>',
        Name: '<optional>',
      },
      ImageId: '<optional>',
      InstanceType: '<optional>',
      KernelId: '<optional>',
      KeyName: '<optional>',
      Monitoring: '<optional>',
      NetworkInterfaces: [
        {
          AssociatePublicIpAddress: '<optional>',
          DeleteOnTermination: '<optional>',
          Description: '<optional>',
          DeviceIndex: '<optional>',
          Groups: ['<optional1>', '<optional2>'],
          Ipv6AddressCount: '<optional>',
          Ipv6Addresses: [
            {
              Ipv6Address: '<optional>',
            },
          ],
          NetworkInterfaceId: '<optional>',
          PrivateIpAddress: '<optional>',
          PrivateIpAddresses: [
            {
              Primary: '<optional>',
              PrivateIpAddress: '<optional>',
            },
          ],
          SecondaryPrivateIpAddressCount: '<optional>',
          SubnetId: '<optional>',
          InterfaceType: '<optional>',
        },
      ],
      Placement: {
        AvailabilityZone: '<optional>',
        GroupName: '<optional>',
        Tenancy: '<optional>',
      },
      RamdiskId: '<optional>',
      SubnetId: '<optional>',
      UserData: ['<optional1>', '<optional2>'],
    },
    AvailabilityZoneGroup: '<optional>',
    BlockDurationMinutes: ['<optional1>', '<optional2>'],
    ClientToken: '<optional>',
    InstanceCount: '<optional>',
    LaunchGroup: '<optional>',
    SpotPrice: '<optional>',
    Type: '<optional>',
    ValidFrom: '<optional>',
    ValidUntil: '<optional>',
    InstanceInterruptionBehavior: '<optional>',
  },
  'EC2:resetEbsDefaultKmsKeyId': {},
  'EC2:resetFpgaImageAttribute': {
    FpgaImageId: 'required',
    Attribute: '<optional>',
  },
  'EC2:resetImageAttribute': {
    Attribute: 'required',
    ImageId: 'required',
  },
  'EC2:resetInstanceAttribute': {
    Attribute: 'required',
    InstanceId: 'required',
  },
  'EC2:resetNetworkInterfaceAttribute': {
    NetworkInterfaceId: 'required',
    SourceDestCheck: '<optional>',
  },
  'EC2:resetSnapshotAttribute': {
    Attribute: 'required',
    SnapshotId: 'required',
  },
  'EC2:restoreAddressToClassic': {
    PublicIp: 'required',
  },
  'EC2:revokeClientVpnIngress': {
    ClientVpnEndpointId: 'required',
    TargetNetworkCidr: 'required',
    AccessGroupId: '<optional>',
    RevokeAllGroups: ['<optional1>', '<optional2>'],
  },
  'EC2:revokeSecurityGroupEgress': {
    GroupId: 'required',
    IpPermissions: [
      {
        FromPort: '<optional>',
        IpProtocol: '<optional>',
        IpRanges: [
          {
            CidrIp: '<optional>',
            Description: '<optional>',
          },
        ],
        Ipv6Ranges: [
          {
            CidrIpv6: '<optional>',
            Description: '<optional>',
          },
        ],
        PrefixListIds: [
          {
            Description: '<optional>',
            PrefixListId: '<optional>',
          },
        ],
        ToPort: '<optional>',
        UserIdGroupPairs: [
          {
            Description: '<optional>',
            GroupId: '<optional>',
            GroupName: '<optional>',
            PeeringStatus: '<optional>',
            UserId: '<optional>',
            VpcId: '<optional>',
            VpcPeeringConnectionId: '<optional>',
          },
        ],
      },
    ],
    CidrIp: '<optional>',
    FromPort: '<optional>',
    IpProtocol: '<optional>',
    ToPort: '<optional>',
    SourceSecurityGroupName: '<optional>',
    SourceSecurityGroupOwnerId: '<optional>',
  },
  'EC2:revokeSecurityGroupIngress': {
    GroupId: 'required',
    GroupName: 'required',
    CidrIp: '<optional>',
    FromPort: '<optional>',
    IpPermissions: [
      {
        FromPort: '<optional>',
        IpProtocol: '<optional>',
        IpRanges: [
          {
            CidrIp: '<optional>',
            Description: '<optional>',
          },
        ],
        Ipv6Ranges: [
          {
            CidrIpv6: '<optional>',
            Description: '<optional>',
          },
        ],
        PrefixListIds: [
          {
            Description: '<optional>',
            PrefixListId: '<optional>',
          },
        ],
        ToPort: '<optional>',
        UserIdGroupPairs: [
          {
            Description: '<optional>',
            GroupId: '<optional>',
            GroupName: '<optional>',
            PeeringStatus: '<optional>',
            UserId: '<optional>',
            VpcId: '<optional>',
            VpcPeeringConnectionId: '<optional>',
          },
        ],
      },
    ],
    IpProtocol: '<optional>',
    SourceSecurityGroupName: '<optional>',
    SourceSecurityGroupOwnerId: '<optional>',
    ToPort: '<optional>',
  },
  'EC2:runInstances': {
    MaxCount: 'required',
    MinCount: 'required',
    Monitoring: '<optional>',
    ElasticGpuSpecification: '<optional>',
    ElasticInferenceAccelerators: ['<optional1>', '<optional2>'],
    CreditSpecification: '<optional>',
    BlockDeviceMappings: [
      {
        DeviceName: '<optional>',
        VirtualName: '<optional>',
        Ebs: [
          {
            DeleteOnTermination: '<optional>',
            Iops: ['<optional1>', '<optional2>'],
            SnapshotId: '<optional>',
            VolumeSize: '<optional>',
            VolumeType: '<optional>',
            Encrypted: '<optional>',
            KmsKeyId: '<optional>',
          },
        ],
        NoDevice: '<optional>',
      },
    ],
    ImageId: '<optional>',
    InstanceType: '<optional>',
    Ipv6AddressCount: '<optional>',
    Ipv6Addresses: [
      {
        Ipv6Address: '<optional>',
      },
    ],
    KernelId: '<optional>',
    KeyName: '<optional>',
    Placement: {
      AvailabilityZone: '<optional>',
      Affinity: '<optional>',
      GroupName: '<optional>',
      PartitionNumber: '<optional>',
      HostId: '<optional>',
      Tenancy: '<optional>',
      SpreadDomain: '<optional>',
    },
    RamdiskId: '<optional>',
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    SecurityGroups: ['<optional1>', '<optional2>'],
    SubnetId: '<optional>',
    UserData: ['<optional1>', '<optional2>'],
    AdditionalInfo: '<optional>',
    ClientToken: '<optional>',
    DisableApiTermination: '<optional>',
    EbsOptimized: '<optional>',
    IamInstanceProfile: {
      Arn: '<optional>',
      Name: '<optional>',
    },
    InstanceInitiatedShutdownBehavior: '<optional>',
    NetworkInterfaces: [
      {
        AssociatePublicIpAddress: '<optional>',
        DeleteOnTermination: '<optional>',
        Description: '<optional>',
        DeviceIndex: '<optional>',
        Groups: ['<optional1>', '<optional2>'],
        Ipv6AddressCount: '<optional>',
        Ipv6Addresses: [
          {
            Ipv6Address: '<optional>',
          },
        ],
        NetworkInterfaceId: '<optional>',
        PrivateIpAddress: '<optional>',
        PrivateIpAddresses: [
          {
            Primary: '<optional>',
            PrivateIpAddress: '<optional>',
          },
        ],
        SecondaryPrivateIpAddressCount: '<optional>',
        SubnetId: '<optional>',
        InterfaceType: '<optional>',
      },
    ],
    PrivateIpAddress: '<optional>',
    TagSpecifications: [
      {
        ResourceType: '<optional>',
        Tags: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
    LaunchTemplate: {
      LaunchTemplateId: '<optional>',
      LaunchTemplateName: '<optional>',
      Version: '<optional>',
    },
    InstanceMarketOptions: [
      {
        MarketType: '<optional>',
        SpotOptions: [
          {
            MaxPrice: '<optional>',
            SpotInstanceType: '<optional>',
            BlockDurationMinutes: ['<optional1>', '<optional2>'],
            ValidUntil: '<optional>',
            InstanceInterruptionBehavior: '<optional>',
          },
        ],
      },
    ],
    CpuOptions: [
      {
        CoreCount: '<optional>',
        ThreadsPerCore: '<optional>',
      },
    ],
    CapacityReservationSpecification: {
      CapacityReservationPreference: '<optional>',
      CapacityReservationTarget: {
        CapacityReservationId: '<optional>',
      },
    },
    HibernationOptions: [
      {
        Configured: '<optional>',
      },
    ],
    LicenseSpecifications: [
      {
        LicenseConfigurationArn: '<optional>',
      },
    ],
  },
  'EC2:runScheduledInstances': {
    LaunchSpecification: {
      BlockDeviceMappings: [
        {
          DeviceName: '<optional>',
          Ebs: [
            {
              DeleteOnTermination: '<optional>',
              Encrypted: '<optional>',
              Iops: ['<optional1>', '<optional2>'],
              SnapshotId: '<optional>',
              VolumeSize: '<optional>',
              VolumeType: '<optional>',
            },
          ],
          NoDevice: '<optional>',
          VirtualName: '<optional>',
        },
      ],
      EbsOptimized: '<optional>',
      IamInstanceProfile: {
        Arn: '<optional>',
        Name: '<optional>',
      },
      InstanceType: '<optional>',
      KernelId: '<optional>',
      KeyName: '<optional>',
      Monitoring: {
        Enabled: '<optional>',
      },
      NetworkInterfaces: [
        {
          AssociatePublicIpAddress: '<optional>',
          DeleteOnTermination: '<optional>',
          Description: '<optional>',
          DeviceIndex: '<optional>',
          Groups: ['<optional1>', '<optional2>'],
          Ipv6AddressCount: '<optional>',
          Ipv6Addresses: [
            {
              Ipv6Address: '<optional>',
            },
          ],
          NetworkInterfaceId: '<optional>',
          PrivateIpAddress: '<optional>',
          PrivateIpAddressConfigs: [
            {
              Primary: '<optional>',
              PrivateIpAddress: '<optional>',
            },
          ],
          SecondaryPrivateIpAddressCount: '<optional>',
          SubnetId: '<optional>',
        },
      ],
      Placement: {
        AvailabilityZone: '<optional>',
        GroupName: '<optional>',
      },
      RamdiskId: '<optional>',
      SecurityGroupIds: ['<optional1>', '<optional2>'],
      SubnetId: '<optional>',
      UserData: ['<optional1>', '<optional2>'],
    },
    ScheduledInstanceId: 'required',
    ClientToken: '<optional>',
    InstanceCount: '<optional>',
  },
  'EC2:searchLocalGatewayRoutes': {
    LocalGatewayRouteTableId: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:searchTransitGatewayMulticastGroups': {
    TransitGatewayMulticastDomainId: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'EC2:searchTransitGatewayRoutes': {
    TransitGatewayRouteTableId: 'required',
    Filters: ['<optional1>', '<optional2>'],
  },
  'EC2:sendDiagnosticInterrupt': {
    InstanceId: 'required',
  },
  'EC2:startInstances': {
    InstanceIds: ['required1', 'required2'],
    AdditionalInfo: '<optional>',
  },
  'EC2:startVpcEndpointServicePrivateDnsVerification': {
    ServiceId: 'required',
  },
  'EC2:stopInstances': {
    InstanceIds: ['required1', 'required2'],
    Hibernate: '<optional>',
    Force: '<optional>',
  },
  'EC2:terminateClientVpnConnections': {
    ClientVpnEndpointId: 'required',
    ConnectionId: '<optional>',
    Username: '<optional>',
  },
  'EC2:terminateInstances': {
    InstanceIds: ['required1', 'required2'],
  },
  'EC2:unassignIpv6Addresses': {
    Ipv6Addresses: ['required1', 'required2'],
    NetworkInterfaceId: 'required',
  },
  'EC2:unassignPrivateIpAddresses': {
    NetworkInterfaceId: 'required',
    PrivateIpAddresses: ['required1', 'required2'],
  },
  'EC2:unmonitorInstances': {
    InstanceIds: ['required1', 'required2'],
  },
  'EC2:updateSecurityGroupRuleDescriptionsEgress': {
    IpPermissions: [
      {
        FromPort: '<optional>',
        IpProtocol: '<optional>',
        IpRanges: [
          {
            CidrIp: '<optional>',
            Description: '<optional>',
          },
        ],
        Ipv6Ranges: [
          {
            CidrIpv6: '<optional>',
            Description: '<optional>',
          },
        ],
        PrefixListIds: [
          {
            Description: '<optional>',
            PrefixListId: '<optional>',
          },
        ],
        ToPort: '<optional>',
        UserIdGroupPairs: [
          {
            Description: '<optional>',
            GroupId: '<optional>',
            GroupName: '<optional>',
            PeeringStatus: '<optional>',
            UserId: '<optional>',
            VpcId: '<optional>',
            VpcPeeringConnectionId: '<optional>',
          },
        ],
      },
    ],
    GroupId: '<optional>',
    GroupName: '<optional>',
  },
  'EC2:updateSecurityGroupRuleDescriptionsIngress': {
    IpPermissions: [
      {
        FromPort: '<optional>',
        IpProtocol: '<optional>',
        IpRanges: [
          {
            CidrIp: '<optional>',
            Description: '<optional>',
          },
        ],
        Ipv6Ranges: [
          {
            CidrIpv6: '<optional>',
            Description: '<optional>',
          },
        ],
        PrefixListIds: [
          {
            Description: '<optional>',
            PrefixListId: '<optional>',
          },
        ],
        ToPort: '<optional>',
        UserIdGroupPairs: [
          {
            Description: '<optional>',
            GroupId: '<optional>',
            GroupName: '<optional>',
            PeeringStatus: '<optional>',
            UserId: '<optional>',
            VpcId: '<optional>',
            VpcPeeringConnectionId: '<optional>',
          },
        ],
      },
    ],
    GroupId: '<optional>',
    GroupName: '<optional>',
  },
  'EC2:withdrawByoipCidr': {
    Cidr: 'required',
  },
  'EC2InstanceConnect:sendSSHPublicKey': {
    InstanceId: 'required',
    InstanceOSUser: 'required',
    SSHPublicKey: 'required',
    AvailabilityZone: 'required',
  },
  'ECR:batchCheckLayerAvailability': {
    repositoryName: 'required',
    layerDigests: ['required1', 'required2'],
    registryId: '<optional>',
  },
  'ECR:batchDeleteImage': {
    repositoryName: 'required',
    imageIds: [
      {
        imageDigest: '<optional>',
        imageTag: '<optional>',
      },
    ],
    registryId: '<optional>',
  },
  'ECR:batchGetImage': {
    repositoryName: 'required',
    imageIds: [
      {
        imageDigest: '<optional>',
        imageTag: '<optional>',
      },
    ],
    registryId: '<optional>',
    acceptedMediaTypes: ['<optional1>', '<optional2>'],
  },
  'ECR:completeLayerUpload': {
    repositoryName: 'required',
    uploadId: 'required',
    layerDigests: ['required1', 'required2'],
    registryId: '<optional>',
  },
  'ECR:createRepository': {
    repositoryName: 'required',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ECR:deleteLifecyclePolicy': {
    repositoryName: 'required',
    registryId: '<optional>',
  },
  'ECR:deleteRepository': {
    repositoryName: 'required',
    registryId: '<optional>',
    force: '<optional>',
  },
  'ECR:deleteRepositoryPolicy': {
    repositoryName: 'required',
    registryId: '<optional>',
  },
  'ECR:describeImageScanFindings': {
    repositoryName: 'required',
    imageId: {
      imageDigest: '<optional>',
      imageTag: '<optional>',
    },
    registryId: '<optional>',
  },
  'ECR:describeImages': {
    repositoryName: 'required',
    registryId: '<optional>',
    imageIds: [
      {
        imageDigest: '<optional>',
        imageTag: '<optional>',
      },
    ],
    filter: {
      tagStatus: '<optional>',
    },
  },
  'ECR:describeRepositories': {
    registryId: '<optional>',
    repositoryNames: ['<optional1>', '<optional2>'],
  },
  'ECR:getAuthorizationToken': {
    registryIds: ['<optional1>', '<optional2>'],
  },
  'ECR:getDownloadUrlForLayer': {
    repositoryName: 'required',
    layerDigest: 'required',
    registryId: '<optional>',
  },
  'ECR:getLifecyclePolicy': {
    repositoryName: 'required',
    registryId: '<optional>',
  },
  'ECR:getLifecyclePolicyPreview': {
    repositoryName: 'required',
    registryId: '<optional>',
    imageIds: [
      {
        imageDigest: '<optional>',
        imageTag: '<optional>',
      },
    ],
    filter: {
      tagStatus: '<optional>',
    },
  },
  'ECR:getRepositoryPolicy': {
    repositoryName: 'required',
    registryId: '<optional>',
  },
  'ECR:initiateLayerUpload': {
    repositoryName: 'required',
    registryId: '<optional>',
  },
  'ECR:listImages': {
    repositoryName: 'required',
    registryId: '<optional>',
    filter: {
      tagStatus: '<optional>',
    },
  },
  'ECR:listTagsForResource': {
    resourceArn: 'required',
  },
  'ECR:putImage': {
    repositoryName: 'required',
    imageManifest: 'required',
    registryId: '<optional>',
    imageTag: '<optional>',
  },
  'ECR:putImageScanningConfiguration': {
    repositoryName: 'required',
    imageScanningConfiguration: {
      scanOnPush: '<optional>',
    },
    registryId: '<optional>',
  },
  'ECR:putImageTagMutability': {
    repositoryName: 'required',
    imageTagMutability: 'required',
    registryId: '<optional>',
  },
  'ECR:putLifecyclePolicy': {
    repositoryName: 'required',
    lifecyclePolicyText: 'required',
    registryId: '<optional>',
  },
  'ECR:setRepositoryPolicy': {
    repositoryName: 'required',
    policyText: 'required',
    registryId: '<optional>',
    force: '<optional>',
  },
  'ECR:startImageScan': {
    repositoryName: 'required',
    imageId: {
      imageDigest: '<optional>',
      imageTag: '<optional>',
    },
    registryId: '<optional>',
  },
  'ECR:startLifecyclePolicyPreview': {
    repositoryName: 'required',
    registryId: '<optional>',
    lifecyclePolicyText: '<optional>',
  },
  'ECR:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ECR:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'ECR:uploadLayerPart': {
    repositoryName: 'required',
    uploadId: 'required',
    partFirstByte: 'required',
    partLastByte: 'required',
    layerPartBlob: 'required',
    registryId: '<optional>',
  },
  'ECS:createCapacityProvider': {
    name: 'required',
    autoScalingGroupProvider: {
      managedScaling: {
        status: '<optional>',
        targetCapacity: '<optional>',
        minimumScalingStepSize: '<optional>',
        maximumScalingStepSize: '<optional>',
      },
      managedTerminationProtection: '<optional>',
    },
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'ECS:createCluster': {
    clusterName: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    settings: [
      {
        name: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'ECS:createService': {
    serviceName: 'required',
    networkConfiguration: {
      awsvpcConfiguration: {
        securityGroups: ['<optional1>', '<optional2>'],
        assignPublicIp: '<optional>',
      },
    },
    deploymentController: '<optional>',
    cluster: '<optional>',
    taskDefinition: '<optional>',
    loadBalancers: [
      {
        targetGroupArn: '<optional>',
        loadBalancerName: '<optional>',
        containerName: '<optional>',
        containerPort: '<optional>',
      },
    ],
    serviceRegistries: [
      {
        registryArn: '<optional>',
        port: '<optional>',
        containerName: '<optional>',
        containerPort: '<optional>',
      },
    ],
    desiredCount: '<optional>',
    clientToken: '<optional>',
    launchType: '<optional>',
    platformVersion: '<optional>',
    role: '<optional>',
    deploymentConfiguration: {
      maximumPercent: '<optional>',
      minimumHealthyPercent: '<optional>',
    },
    placementConstraints: [
      {
        type: '<optional>',
        expression: '<optional>',
      },
    ],
    placementStrategy: {
      type: '<optional>',
      field: '<optional>',
    },
    healthCheckGracePeriodSeconds: ['<optional1>', '<optional2>'],
    schedulingStrategy: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    enableECSManagedTags: ['<optional1>', '<optional2>'],
    propagateTags: ['<optional1>', '<optional2>'],
  },
  'ECS:createTaskSet': {
    service: 'required',
    cluster: 'required',
    taskDefinition: 'required',
    networkConfiguration: {
      awsvpcConfiguration: {
        securityGroups: ['<optional1>', '<optional2>'],
        assignPublicIp: '<optional>',
      },
    },
    externalId: '<optional>',
    loadBalancers: [
      {
        targetGroupArn: '<optional>',
        loadBalancerName: '<optional>',
        containerName: '<optional>',
        containerPort: '<optional>',
      },
    ],
    serviceRegistries: [
      {
        registryArn: '<optional>',
        port: '<optional>',
        containerName: '<optional>',
        containerPort: '<optional>',
      },
    ],
    launchType: '<optional>',
    platformVersion: '<optional>',
    scale: {
      value: '<optional>',
      unit: '<optional>',
    },
    clientToken: '<optional>',
  },
  'ECS:deleteAccountSetting': {
    name: 'required',
    principalArn: '<optional>',
  },
  'ECS:deleteAttributes': {
    attributes: [
      {
        value: '<optional>',
        targetType: '<optional>',
        targetId: '<optional>',
      },
    ],
    cluster: '<optional>',
  },
  'ECS:deleteCluster': {
    cluster: 'required',
  },
  'ECS:deleteService': {
    service: 'required',
    cluster: '<optional>',
    force: '<optional>',
  },
  'ECS:deleteTaskSet': {
    cluster: 'required',
    service: 'required',
    taskSet: 'required',
    force: '<optional>',
  },
  'ECS:deregisterContainerInstance': {
    containerInstance: 'required',
    cluster: '<optional>',
    force: '<optional>',
  },
  'ECS:deregisterTaskDefinition': {
    taskDefinition: 'required',
  },
  'ECS:describeCapacityProviders': {
    capacityProviders: ['<optional1>', '<optional2>'],
    include: '<optional>',
  },
  'ECS:describeClusters': {
    clusters: ['<optional1>', '<optional2>'],
    include: '<optional>',
  },
  'ECS:describeContainerInstances': {
    containerInstances: ['required1', 'required2'],
    cluster: '<optional>',
    include: '<optional>',
  },
  'ECS:describeServices': {
    services: ['required1', 'required2'],
    cluster: '<optional>',
    include: '<optional>',
  },
  'ECS:describeTaskDefinition': {
    taskDefinition: 'required',
    include: '<optional>',
  },
  'ECS:describeTaskSets': {
    cluster: 'required',
    service: 'required',
    taskSets: ['<optional1>', '<optional2>'],
  },
  'ECS:describeTasks': {
    tasks: ['required1', 'required2'],
    cluster: '<optional>',
    include: '<optional>',
  },
  'ECS:discoverPollEndpoint': {
    containerInstance: '<optional>',
    cluster: '<optional>',
  },
  'ECS:listAccountSettings': {
    name: '<optional>',
    value: '<optional>',
    principalArn: '<optional>',
    effectiveSettings: ['<optional1>', '<optional2>'],
  },
  'ECS:listAttributes': {
    targetType: 'required',
    cluster: '<optional>',
    attributeName: '<optional>',
    attributeValue: '<optional>',
  },
  'ECS:listClusters': {},
  'ECS:listContainerInstances': {
    cluster: '<optional>',
    filter: '<optional>',
    status: '<optional>',
  },
  'ECS:listServices': {
    cluster: '<optional>',
    launchType: '<optional>',
    schedulingStrategy: '<optional>',
  },
  'ECS:listTagsForResource': {
    resourceArn: 'required',
  },
  'ECS:listTaskDefinitionFamilies': {
    familyPrefix: '<optional>',
    status: '<optional>',
  },
  'ECS:listTaskDefinitions': {
    familyPrefix: '<optional>',
    status: '<optional>',
    sort: '<optional>',
  },
  'ECS:listTasks': {
    cluster: '<optional>',
    containerInstance: '<optional>',
    family: '<optional>',
    startedBy: '<optional>',
    serviceName: '<optional>',
    desiredStatus: '<optional>',
    launchType: '<optional>',
  },
  'ECS:putAccountSetting': {
    name: 'required',
    value: 'required',
    principalArn: '<optional>',
  },
  'ECS:putAccountSettingDefault': {
    name: 'required',
    value: 'required',
  },
  'ECS:putAttributes': {
    attributes: [
      {
        value: '<optional>',
        targetType: '<optional>',
        targetId: '<optional>',
      },
    ],
    cluster: '<optional>',
  },
  'ECS:putClusterCapacityProviders': {
    cluster: 'required',
    capacityProviders: ['required1', 'required2'],
    defaultCapacityProviderStrategy: {
      weight: '<optional>',
      base: '<optional>',
    },
  },
  'ECS:registerContainerInstance': {
    attributes: [
      {
        value: '<optional>',
        targetType: '<optional>',
        targetId: '<optional>',
      },
    ],
    platformDevices: ['<optional1>', '<optional2>'],
    cluster: '<optional>',
    instanceIdentityDocument: '<optional>',
    instanceIdentityDocumentSignature: '<optional>',
    totalResources: [
      {
        name: '<optional>',
        type: '<optional>',
        doubleValue: '<optional>',
        longValue: '<optional>',
        integerValue: '<optional>',
        stringSetValue: '<optional>',
      },
    ],
    versionInfo: {
      agentVersion: '<optional>',
      agentHash: '<optional>',
      dockerVersion: '<optional>',
    },
    containerInstanceArn: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'ECS:registerTaskDefinition': {
    family: 'required',
    containerDefinitions: [
      {
        name: '<optional>',
        image: '<optional>',
        repositoryCredentials: ['<optional1>', '<optional2>'],
        cpu: '<optional>',
        memory: '<optional>',
        memoryReservation: '<optional>',
        links: ['<optional1>', '<optional2>'],
        portMappings: [
          {
            containerPort: '<optional>',
            hostPort: '<optional>',
            protocol: '<optional>',
          },
        ],
        essential: '<optional>',
        entryPoint: '<optional>',
        command: '<optional>',
        environment: {
          name: '<optional>',
          value: '<optional>',
        },
        mountPoints: [
          {
            sourceVolume: '<optional>',
            containerPath: '<optional>',
            readOnly: '<optional>',
          },
        ],
        volumesFrom: {
          sourceContainer: '<optional>',
          readOnly: '<optional>',
        },
        linuxParameters: [
          {
            capabilities: [
              {
                add: '<optional>',
                drop: '<optional>',
              },
            ],
            devices: [
              {
                containerPath: '<optional>',
                permissions: ['<optional1>', '<optional2>'],
              },
            ],
            initProcessEnabled: '<optional>',
            sharedMemorySize: '<optional>',
            tmpfs: [
              {
                mountOptions: ['<optional1>', '<optional2>'],
              },
            ],
          },
        ],
        secrets: ['<optional1>', '<optional2>'],
        dependsOn: '<optional>',
        startTimeout: '<optional>',
        stopTimeout: '<optional>',
        hostname: '<optional>',
        user: '<optional>',
        workingDirectory: '<optional>',
        disableNetworking: '<optional>',
        privileged: '<optional>',
        readonlyRootFilesystem: '<optional>',
        dnsServers: ['<optional1>', '<optional2>'],
        dnsSearchDomains: ['<optional1>', '<optional2>'],
        extraHosts: ['<optional1>', '<optional2>'],
        dockerSecurityOptions: ['<optional1>', '<optional2>'],
        interactive: '<optional>',
        pseudoTerminal: '<optional>',
        dockerLabels: ['<optional1>', '<optional2>'],
        ulimits: ['<optional1>', '<optional2>'],
        logConfiguration: {
          options: ['<optional1>', '<optional2>'],
          secretOptions: ['<optional1>', '<optional2>'],
        },
        healthCheck: {
          interval: '<optional>',
          timeout: '<optional>',
          retries: ['<optional1>', '<optional2>'],
          startPeriod: '<optional>',
        },
        systemControls: [
          {
            namespace: '<optional>',
            value: '<optional>',
          },
        ],
        resourceRequirements: ['<optional1>', '<optional2>'],
      },
    ],
    proxyConfiguration: {
      type: '<optional>',
      properties: [
        {
          name: '<optional>',
          value: '<optional>',
        },
      ],
    },
    taskRoleArn: '<optional>',
    executionRoleArn: '<optional>',
    networkMode: '<optional>',
    volumes: [
      {
        name: '<optional>',
        host: {
          sourcePath: '<optional>',
        },
        dockerVolumeConfiguration: {
          scope: '<optional>',
          autoprovision: '<optional>',
          driver: '<optional>',
          driverOpts: ['<optional1>', '<optional2>'],
          labels: ['<optional1>', '<optional2>'],
        },
      },
    ],
    placementConstraints: [
      {
        type: '<optional>',
        expression: '<optional>',
      },
    ],
    requiresCompatibilities: ['<optional1>', '<optional2>'],
    cpu: '<optional>',
    memory: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    pidMode: '<optional>',
    ipcMode: '<optional>',
  },
  'ECS:runTask': {
    taskDefinition: 'required',
    overrides: [
      {
        containerOverrides: [
          {
            name: '<optional>',
            command: '<optional>',
            environment: {
              name: '<optional>',
              value: '<optional>',
            },
            cpu: '<optional>',
            memory: '<optional>',
            memoryReservation: '<optional>',
            resourceRequirements: ['<optional1>', '<optional2>'],
          },
        ],
        taskRoleArn: '<optional>',
        executionRoleArn: '<optional>',
      },
    ],
    networkConfiguration: {
      awsvpcConfiguration: {
        securityGroups: ['<optional1>', '<optional2>'],
        assignPublicIp: '<optional>',
      },
    },
    cluster: '<optional>',
    count: '<optional>',
    startedBy: '<optional>',
    group: '<optional>',
    placementConstraints: [
      {
        type: '<optional>',
        expression: '<optional>',
      },
    ],
    placementStrategy: {
      type: '<optional>',
      field: '<optional>',
    },
    launchType: '<optional>',
    platformVersion: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    enableECSManagedTags: ['<optional1>', '<optional2>'],
    propagateTags: ['<optional1>', '<optional2>'],
  },
  'ECS:startTask': {
    taskDefinition: 'required',
    containerInstances: ['required1', 'required2'],
    overrides: [
      {
        containerOverrides: [
          {
            name: '<optional>',
            command: '<optional>',
            environment: {
              name: '<optional>',
              value: '<optional>',
            },
            cpu: '<optional>',
            memory: '<optional>',
            memoryReservation: '<optional>',
            resourceRequirements: ['<optional1>', '<optional2>'],
          },
        ],
        taskRoleArn: '<optional>',
        executionRoleArn: '<optional>',
      },
    ],
    networkConfiguration: {
      awsvpcConfiguration: {
        securityGroups: ['<optional1>', '<optional2>'],
        assignPublicIp: '<optional>',
      },
    },
    cluster: '<optional>',
    startedBy: '<optional>',
    group: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    enableECSManagedTags: ['<optional1>', '<optional2>'],
    propagateTags: ['<optional1>', '<optional2>'],
  },
  'ECS:stopTask': {
    task: 'required',
    cluster: '<optional>',
    reason: '<optional>',
  },
  'ECS:submitAttachmentStateChanges': {
    attachments: [
      {
        attachmentArn: 'required',
        status: 'required',
      },
    ],
    cluster: '<optional>',
  },
  'ECS:submitContainerStateChange': {
    cluster: '<optional>',
    task: '<optional>',
    containerName: '<optional>',
    status: '<optional>',
    exitCode: '<optional>',
    reason: '<optional>',
    networkBindings: [
      {
        bindIP: '<optional>',
        containerPort: '<optional>',
        hostPort: '<optional>',
        protocol: '<optional>',
      },
    ],
  },
  'ECS:submitTaskStateChange': {
    attachments: ['<optional1>', '<optional2>'],
    cluster: '<optional>',
    task: '<optional>',
    status: '<optional>',
    reason: '<optional>',
    containers: [
      {
        containerName: '<optional>',
        exitCode: '<optional>',
        networkBindings: [
          {
            bindIP: '<optional>',
            containerPort: '<optional>',
            hostPort: '<optional>',
            protocol: '<optional>',
          },
        ],
        reason: '<optional>',
        status: '<optional>',
      },
    ],
    pullStartedAt: '<optional>',
    pullStoppedAt: '<optional>',
    executionStoppedAt: '<optional>',
  },
  'ECS:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'ECS:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'ECS:updateClusterSettings': {
    cluster: 'required',
    settings: [
      {
        name: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'ECS:updateContainerAgent': {
    containerInstance: 'required',
    cluster: '<optional>',
  },
  'ECS:updateContainerInstancesState': {
    containerInstances: ['required1', 'required2'],
    status: 'required',
    cluster: '<optional>',
  },
  'ECS:updateService': {
    service: 'required',
    networkConfiguration: {
      awsvpcConfiguration: {
        securityGroups: ['<optional1>', '<optional2>'],
        assignPublicIp: '<optional>',
      },
    },
    cluster: '<optional>',
    desiredCount: '<optional>',
    taskDefinition: '<optional>',
    deploymentConfiguration: {
      maximumPercent: '<optional>',
      minimumHealthyPercent: '<optional>',
    },
    platformVersion: '<optional>',
    forceNewDeployment: '<optional>',
    healthCheckGracePeriodSeconds: ['<optional1>', '<optional2>'],
  },
  'ECS:updateServicePrimaryTaskSet': {
    cluster: 'required',
    service: 'required',
    primaryTaskSet: 'required',
  },
  'ECS:updateTaskSet': {
    cluster: 'required',
    service: 'required',
    taskSet: 'required',
    scale: {
      value: '<optional>',
      unit: '<optional>',
    },
  },
  'EFS:createFileSystem': {
    CreationToken: 'required',
    Tags: ['<optional1>', '<optional2>'],
    PerformanceMode: '<optional>',
    Encrypted: '<optional>',
    KmsKeyId: '<optional>',
    ThroughputMode: '<optional>',
    ProvisionedThroughputInMibps: ['<optional1>', '<optional2>'],
  },
  'EFS:createMountTarget': {
    FileSystemId: 'required',
    SubnetId: 'required',
    IpAddress: '<optional>',
    SecurityGroups: ['<optional1>', '<optional2>'],
  },
  'EFS:createTags': {
    FileSystemId: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'EFS:deleteFileSystem': {
    FileSystemId: 'required',
  },
  'EFS:deleteMountTarget': {
    MountTargetId: 'required',
  },
  'EFS:deleteTags': {
    FileSystemId: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'EFS:describeFileSystems': {
    CreationToken: '<optional>',
    FileSystemId: '<optional>',
  },
  'EFS:describeLifecycleConfiguration': {
    FileSystemId: 'required',
  },
  'EFS:describeMountTargetSecurityGroups': {
    MountTargetId: 'required',
  },
  'EFS:describeMountTargets': {
    FileSystemId: '<optional>',
    MountTargetId: '<optional>',
  },
  'EFS:describeTags': {
    FileSystemId: 'required',
  },
  'EFS:modifyMountTargetSecurityGroups': {
    MountTargetId: 'required',
    SecurityGroups: ['<optional1>', '<optional2>'],
  },
  'EFS:putLifecycleConfiguration': {
    FileSystemId: 'required',
    LifecyclePolicies: [
      {
        TransitionToIA: '<optional>',
      },
    ],
  },
  'EFS:updateFileSystem': {
    FileSystemId: 'required',
    ThroughputMode: '<optional>',
    ProvisionedThroughputInMibps: ['<optional1>', '<optional2>'],
  },
  'EKS:createCluster': {
    name: 'required',
    roleArn: 'required',
    resourcesVpcConfig: {
      subnetIds: ['<optional1>', '<optional2>'],
      securityGroupIds: ['<optional1>', '<optional2>'],
      endpointPublicAccess: '<optional>',
      endpointPrivateAccess: '<optional>',
    },
    version: '<optional>',
    logging: {
      clusterLogging: {
        types: ['<optional1>', '<optional2>'],
        enabled: '<optional>',
      },
    },
    clientRequestToken: '<optional>',
  },
  'EKS:createFargateProfile': {
    fargateProfileName: 'required',
    clusterName: 'required',
    podExecutionRoleArn: 'required',
    subnets: ['<optional1>', '<optional2>'],
    selectors: [
      {
        namespace: '<optional>',
        labels: ['<optional1>', '<optional2>'],
      },
    ],
    clientRequestToken: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'EKS:createNodegroup': {
    clusterName: 'required',
    nodegroupName: 'required',
    subnets: ['required1', 'required2'],
    nodeRole: 'required',
    scalingConfig: {
      minSize: '<optional>',
      maxSize: '<optional>',
      desiredSize: '<optional>',
    },
    diskSize: '<optional>',
    instanceTypes: ['<optional1>', '<optional2>'],
    amiType: '<optional>',
    remoteAccess: {
      ec2SshKey: '<optional>',
      sourceSecurityGroups: ['<optional1>', '<optional2>'],
    },
    labels: ['<optional1>', '<optional2>'],
    tags: ['<optional1>', '<optional2>'],
    clientRequestToken: '<optional>',
    version: '<optional>',
    releaseVersion: '<optional>',
  },
  'EKS:deleteCluster': {
    name: 'required',
  },
  'EKS:deleteFargateProfile': {
    clusterName: 'required',
    fargateProfileName: 'required',
  },
  'EKS:deleteNodegroup': {
    clusterName: 'required',
    nodegroupName: 'required',
  },
  'EKS:describeCluster': {
    name: 'required',
  },
  'EKS:describeFargateProfile': {
    clusterName: 'required',
    fargateProfileName: 'required',
  },
  'EKS:describeNodegroup': {
    clusterName: 'required',
    nodegroupName: 'required',
  },
  'EKS:describeUpdate': {
    name: 'required',
    updateId: 'required',
  },
  'EKS:listClusters': {},
  'EKS:listFargateProfiles': {
    clusterName: 'required',
  },
  'EKS:listNodegroups': {
    clusterName: 'required',
  },
  'EKS:listTagsForResource': {
    resourceArn: 'required',
  },
  'EKS:listUpdates': {
    name: 'required',
  },
  'EKS:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'EKS:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'EKS:updateClusterConfig': {
    name: 'required',
    resourcesVpcConfig: {
      subnetIds: ['<optional1>', '<optional2>'],
      securityGroupIds: ['<optional1>', '<optional2>'],
      endpointPublicAccess: '<optional>',
      endpointPrivateAccess: '<optional>',
    },
    logging: {
      clusterLogging: {
        types: ['<optional1>', '<optional2>'],
        enabled: '<optional>',
      },
    },
    clientRequestToken: '<optional>',
  },
  'EKS:updateClusterVersion': {
    name: 'required',
    version: 'required',
    clientRequestToken: '<optional>',
  },
  'EKS:updateNodegroupConfig': {
    clusterName: 'required',
    nodegroupName: 'required',
    labels: [
      {
        addOrUpdateLabels: ['<optional1>', '<optional2>'],
        removeLabels: ['<optional1>', '<optional2>'],
      },
    ],
    scalingConfig: {
      minSize: '<optional>',
      maxSize: '<optional>',
      desiredSize: '<optional>',
    },
    clientRequestToken: '<optional>',
  },
  'EKS:updateNodegroupVersion': {
    clusterName: 'required',
    nodegroupName: 'required',
    version: '<optional>',
    releaseVersion: '<optional>',
    force: '<optional>',
    clientRequestToken: '<optional>',
  },
  'ELB:addTags': {
    LoadBalancerNames: ['required1', 'required2'],
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'ELB:applySecurityGroupsToLoadBalancer': {
    LoadBalancerName: 'required',
    SecurityGroups: ['required1', 'required2'],
  },
  'ELB:attachLoadBalancerToSubnets': {
    LoadBalancerName: 'required',
    Subnets: ['required1', 'required2'],
  },
  'ELB:configureHealthCheck': {
    LoadBalancerName: 'required',
    HealthCheck: {
      Target: 'required',
      Interval: 'required',
      Timeout: 'required',
      UnhealthyThreshold: 'required',
      HealthyThreshold: 'required',
    },
  },
  'ELB:createAppCookieStickinessPolicy': {
    LoadBalancerName: 'required',
    PolicyName: 'required',
    CookieName: 'required',
  },
  'ELB:createLBCookieStickinessPolicy': {
    LoadBalancerName: 'required',
    PolicyName: 'required',
    CookieExpirationPeriod: '<optional>',
  },
  'ELB:createLoadBalancer': {
    LoadBalancerName: 'required',
    Listeners: [
      {
        InstanceProtocol: '<optional>',
        SSLCertificateId: '<optional>',
      },
    ],
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    AvailabilityZones: ['<optional1>', '<optional2>'],
    Subnets: ['<optional1>', '<optional2>'],
    SecurityGroups: ['<optional1>', '<optional2>'],
    Scheme: '<optional>',
  },
  'ELB:createLoadBalancerListeners': {
    LoadBalancerName: 'required',
    Listeners: [
      {
        InstanceProtocol: '<optional>',
        SSLCertificateId: '<optional>',
      },
    ],
  },
  'ELB:createLoadBalancerPolicy': {
    LoadBalancerName: 'required',
    PolicyName: 'required',
    PolicyTypeName: 'required',
    PolicyAttributes: [
      {
        AttributeName: '<optional>',
        AttributeValue: '<optional>',
      },
    ],
  },
  'ELB:deleteLoadBalancer': {
    LoadBalancerName: 'required',
  },
  'ELB:deleteLoadBalancerListeners': {
    LoadBalancerName: 'required',
    LoadBalancerPorts: ['required1', 'required2'],
  },
  'ELB:deleteLoadBalancerPolicy': {
    LoadBalancerName: 'required',
    PolicyName: 'required',
  },
  'ELB:deregisterInstancesFromLoadBalancer': {
    LoadBalancerName: 'required',
    Instances: [
      {
        InstanceId: '<optional>',
      },
    ],
  },
  'ELB:describeAccountLimits': {
    PageSize: '<optional>',
  },
  'ELB:describeInstanceHealth': {
    LoadBalancerName: 'required',
    Instances: [
      {
        InstanceId: '<optional>',
      },
    ],
  },
  'ELB:describeLoadBalancerAttributes': {
    LoadBalancerName: 'required',
  },
  'ELB:describeLoadBalancerPolicies': {
    LoadBalancerName: '<optional>',
    PolicyNames: ['<optional1>', '<optional2>'],
  },
  'ELB:describeLoadBalancerPolicyTypes': {
    PolicyTypeNames: ['<optional1>', '<optional2>'],
  },
  'ELB:describeLoadBalancers': {
    LoadBalancerNames: ['<optional1>', '<optional2>'],
    PageSize: '<optional>',
  },
  'ELB:describeTags': {
    LoadBalancerNames: ['required1', 'required2'],
  },
  'ELB:detachLoadBalancerFromSubnets': {
    LoadBalancerName: 'required',
    Subnets: ['required1', 'required2'],
  },
  'ELB:disableAvailabilityZonesForLoadBalancer': {
    LoadBalancerName: 'required',
    AvailabilityZones: ['required1', 'required2'],
  },
  'ELB:enableAvailabilityZonesForLoadBalancer': {
    LoadBalancerName: 'required',
    AvailabilityZones: ['required1', 'required2'],
  },
  'ELB:modifyLoadBalancerAttributes': {
    LoadBalancerName: 'required',
    LoadBalancerAttributes: [
      {
        CrossZoneLoadBalancing: '<optional>',
        AccessLog: {
          S3BucketName: '<optional>',
          EmitInterval: '<optional>',
          S3BucketPrefix: '<optional>',
        },
        ConnectionDraining: {
          Timeout: '<optional>',
        },
        ConnectionSettings: ['<optional1>', '<optional2>'],
        AdditionalAttributes: [
          {
            Key: '<optional>',
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'ELB:registerInstancesWithLoadBalancer': {
    LoadBalancerName: 'required',
    Instances: [
      {
        InstanceId: '<optional>',
      },
    ],
  },
  'ELB:removeTags': {
    LoadBalancerNames: ['required1', 'required2'],
    Tags: [
      {
        Key: '<optional>',
      },
    ],
  },
  'ELB:setLoadBalancerListenerSSLCertificate': {
    LoadBalancerName: 'required',
    LoadBalancerPort: 'required',
    SSLCertificateId: 'required',
  },
  'ELB:setLoadBalancerPoliciesForBackendServer': {
    LoadBalancerName: 'required',
    InstancePort: 'required',
    PolicyNames: ['required1', 'required2'],
  },
  'ELB:setLoadBalancerPoliciesOfListener': {
    LoadBalancerName: 'required',
    LoadBalancerPort: 'required',
    PolicyNames: ['required1', 'required2'],
  },
  'ELBv2:addListenerCertificates': {
    ListenerArn: 'required',
    Certificates: [
      {
        CertificateArn: '<optional>',
        IsDefault: '<optional>',
      },
    ],
  },
  'ELBv2:addTags': {
    ResourceArns: ['required1', 'required2'],
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'ELBv2:createListener': {
    LoadBalancerArn: 'required',
    Protocol: 'required',
    Port: 'required',
    DefaultActions: [
      {
        TargetGroupArn: '<optional>',
        AuthenticateOidcConfig: {
          ClientSecret: '<optional>',
          SessionCookieName: '<optional>',
          Scope: '<optional>',
          SessionTimeout: '<optional>',
          AuthenticationRequestExtraParams: ['<optional1>', '<optional2>'],
          OnUnauthenticatedRequest: '<optional>',
          UseExistingClientSecret: '<optional>',
        },
        AuthenticateCognitoConfig: {
          SessionCookieName: '<optional>',
          Scope: '<optional>',
          SessionTimeout: '<optional>',
          AuthenticationRequestExtraParams: ['<optional1>', '<optional2>'],
          OnUnauthenticatedRequest: '<optional>',
        },
        Order: '<optional>',
        RedirectConfig: {
          Protocol: '<optional>',
          Port: '<optional>',
          Host: '<optional>',
          Path: '<optional>',
          Query: '<optional>',
        },
        FixedResponseConfig: {
          MessageBody: '<optional>',
          ContentType: '<optional>',
        },
      },
    ],
    SslPolicy: '<optional>',
    Certificates: [
      {
        CertificateArn: '<optional>',
        IsDefault: '<optional>',
      },
    ],
  },
  'ELBv2:createLoadBalancer': {
    Name: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    Subnets: ['<optional1>', '<optional2>'],
    SubnetMappings: [
      {
        SubnetId: '<optional>',
        AllocationId: '<optional>',
      },
    ],
    SecurityGroups: ['<optional1>', '<optional2>'],
    Scheme: '<optional>',
    Type: '<optional>',
    IpAddressType: '<optional>',
  },
  'ELBv2:createRule': {
    ListenerArn: 'required',
    Conditions: [
      {
        Field: '<optional>',
        Values: ['<optional1>', '<optional2>'],
        HostHeaderConfig: {
          Values: ['<optional1>', '<optional2>'],
        },
        PathPatternConfig: {
          Values: ['<optional1>', '<optional2>'],
        },
        HttpHeaderConfig: {
          HttpHeaderName: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
        QueryStringConfig: {
          Values: [
            {
              Key: '<optional>',
              Value: '<optional>',
            },
          ],
        },
        HttpRequestMethodConfig: {
          Values: ['<optional1>', '<optional2>'],
        },
        SourceIpConfig: {
          Values: ['<optional1>', '<optional2>'],
        },
      },
    ],
    Priority: 'required',
    Actions: [
      {
        TargetGroupArn: '<optional>',
        AuthenticateOidcConfig: {
          ClientSecret: '<optional>',
          SessionCookieName: '<optional>',
          Scope: '<optional>',
          SessionTimeout: '<optional>',
          AuthenticationRequestExtraParams: ['<optional1>', '<optional2>'],
          OnUnauthenticatedRequest: '<optional>',
          UseExistingClientSecret: '<optional>',
        },
        AuthenticateCognitoConfig: {
          SessionCookieName: '<optional>',
          Scope: '<optional>',
          SessionTimeout: '<optional>',
          AuthenticationRequestExtraParams: ['<optional1>', '<optional2>'],
          OnUnauthenticatedRequest: '<optional>',
        },
        Order: '<optional>',
        RedirectConfig: {
          Protocol: '<optional>',
          Port: '<optional>',
          Host: '<optional>',
          Path: '<optional>',
          Query: '<optional>',
        },
        FixedResponseConfig: {
          MessageBody: '<optional>',
          ContentType: '<optional>',
        },
      },
    ],
  },
  'ELBv2:createTargetGroup': {
    Name: 'required',
    Matcher: '<optional>',
    Protocol: '<optional>',
    Port: '<optional>',
    VpcId: '<optional>',
    HealthCheckProtocol: '<optional>',
    HealthCheckPort: '<optional>',
    HealthCheckEnabled: '<optional>',
    HealthCheckPath: '<optional>',
    HealthCheckIntervalSeconds: ['<optional1>', '<optional2>'],
    HealthCheckTimeoutSeconds: ['<optional1>', '<optional2>'],
    HealthyThresholdCount: '<optional>',
    UnhealthyThresholdCount: '<optional>',
    TargetType: '<optional>',
  },
  'ELBv2:deleteListener': {
    ListenerArn: 'required',
  },
  'ELBv2:deleteLoadBalancer': {
    LoadBalancerArn: 'required',
  },
  'ELBv2:deleteRule': {
    RuleArn: 'required',
  },
  'ELBv2:deleteTargetGroup': {
    TargetGroupArn: 'required',
  },
  'ELBv2:deregisterTargets': {
    TargetGroupArn: 'required',
    Targets: [
      {
        Port: '<optional>',
        AvailabilityZone: '<optional>',
      },
    ],
  },
  'ELBv2:describeAccountLimits': {
    PageSize: '<optional>',
  },
  'ELBv2:describeListenerCertificates': {
    ListenerArn: 'required',
    PageSize: '<optional>',
  },
  'ELBv2:describeListeners': {
    LoadBalancerArn: '<optional>',
    ListenerArns: ['<optional1>', '<optional2>'],
    PageSize: '<optional>',
  },
  'ELBv2:describeLoadBalancerAttributes': {
    LoadBalancerArn: 'required',
  },
  'ELBv2:describeLoadBalancers': {
    LoadBalancerArns: ['<optional1>', '<optional2>'],
    Names: ['<optional1>', '<optional2>'],
    PageSize: '<optional>',
  },
  'ELBv2:describeRules': {
    ListenerArn: '<optional>',
    RuleArns: ['<optional1>', '<optional2>'],
    PageSize: '<optional>',
  },
  'ELBv2:describeSSLPolicies': {
    Names: ['<optional1>', '<optional2>'],
    PageSize: '<optional>',
  },
  'ELBv2:describeTags': {
    ResourceArns: ['required1', 'required2'],
  },
  'ELBv2:describeTagsForLoadBalancers': {
    ResourceArns: ['required1', 'required2'],
  },
  'ELBv2:describeTargetGroupAttributes': {
    TargetGroupArn: 'required',
  },
  'ELBv2:describeTargetGroups': {
    LoadBalancerArn: '<optional>',
    TargetGroupArns: ['<optional1>', '<optional2>'],
    Names: ['<optional1>', '<optional2>'],
    PageSize: '<optional>',
  },
  'ELBv2:describeTargetHealth': {
    TargetGroupArn: 'required',
    Targets: [
      {
        Port: '<optional>',
        AvailabilityZone: '<optional>',
      },
    ],
  },
  'ELBv2:modifyListener': {
    ListenerArn: 'required',
    DefaultActions: [
      {
        TargetGroupArn: '<optional>',
        AuthenticateOidcConfig: {
          ClientSecret: '<optional>',
          SessionCookieName: '<optional>',
          Scope: '<optional>',
          SessionTimeout: '<optional>',
          AuthenticationRequestExtraParams: ['<optional1>', '<optional2>'],
          OnUnauthenticatedRequest: '<optional>',
          UseExistingClientSecret: '<optional>',
        },
        AuthenticateCognitoConfig: {
          SessionCookieName: '<optional>',
          Scope: '<optional>',
          SessionTimeout: '<optional>',
          AuthenticationRequestExtraParams: ['<optional1>', '<optional2>'],
          OnUnauthenticatedRequest: '<optional>',
        },
        Order: '<optional>',
        RedirectConfig: {
          Protocol: '<optional>',
          Port: '<optional>',
          Host: '<optional>',
          Path: '<optional>',
          Query: '<optional>',
        },
        FixedResponseConfig: {
          MessageBody: '<optional>',
          ContentType: '<optional>',
        },
      },
    ],
    Port: '<optional>',
    Protocol: '<optional>',
    SslPolicy: '<optional>',
    Certificates: [
      {
        CertificateArn: '<optional>',
        IsDefault: '<optional>',
      },
    ],
  },
  'ELBv2:modifyLoadBalancerAttributes': {
    LoadBalancerArn: 'required',
    Attributes: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ELBv2:modifyRule': {
    RuleArn: 'required',
    Actions: [
      {
        TargetGroupArn: '<optional>',
        AuthenticateOidcConfig: {
          ClientSecret: '<optional>',
          SessionCookieName: '<optional>',
          Scope: '<optional>',
          SessionTimeout: '<optional>',
          AuthenticationRequestExtraParams: ['<optional1>', '<optional2>'],
          OnUnauthenticatedRequest: '<optional>',
          UseExistingClientSecret: '<optional>',
        },
        AuthenticateCognitoConfig: {
          SessionCookieName: '<optional>',
          Scope: '<optional>',
          SessionTimeout: '<optional>',
          AuthenticationRequestExtraParams: ['<optional1>', '<optional2>'],
          OnUnauthenticatedRequest: '<optional>',
        },
        Order: '<optional>',
        RedirectConfig: {
          Protocol: '<optional>',
          Port: '<optional>',
          Host: '<optional>',
          Path: '<optional>',
          Query: '<optional>',
        },
        FixedResponseConfig: {
          MessageBody: '<optional>',
          ContentType: '<optional>',
        },
      },
    ],
    Conditions: [
      {
        Field: '<optional>',
        Values: ['<optional1>', '<optional2>'],
        HostHeaderConfig: {
          Values: ['<optional1>', '<optional2>'],
        },
        PathPatternConfig: {
          Values: ['<optional1>', '<optional2>'],
        },
        HttpHeaderConfig: {
          HttpHeaderName: '<optional>',
          Values: ['<optional1>', '<optional2>'],
        },
        QueryStringConfig: {
          Values: [
            {
              Key: '<optional>',
              Value: '<optional>',
            },
          ],
        },
        HttpRequestMethodConfig: {
          Values: ['<optional1>', '<optional2>'],
        },
        SourceIpConfig: {
          Values: ['<optional1>', '<optional2>'],
        },
      },
    ],
  },
  'ELBv2:modifyTargetGroup': {
    TargetGroupArn: 'required',
    Matcher: '<optional>',
    HealthCheckProtocol: '<optional>',
    HealthCheckPort: '<optional>',
    HealthCheckPath: '<optional>',
    HealthCheckEnabled: '<optional>',
    HealthCheckIntervalSeconds: ['<optional1>', '<optional2>'],
    HealthCheckTimeoutSeconds: ['<optional1>', '<optional2>'],
    HealthyThresholdCount: '<optional>',
    UnhealthyThresholdCount: '<optional>',
  },
  'ELBv2:modifyTargetGroupAttributes': {
    TargetGroupArn: 'required',
    Attributes: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ELBv2:registerTargets': {
    TargetGroupArn: 'required',
    Targets: [
      {
        Port: '<optional>',
        AvailabilityZone: '<optional>',
      },
    ],
  },
  'ELBv2:removeListenerCertificates': {
    ListenerArn: 'required',
    Certificates: [
      {
        CertificateArn: '<optional>',
        IsDefault: '<optional>',
      },
    ],
  },
  'ELBv2:removeTags': {
    ResourceArns: ['required1', 'required2'],
    TagKeys: ['required1', 'required2'],
  },
  'ELBv2:setIpAddressType': {
    LoadBalancerArn: 'required',
    IpAddressType: 'required',
  },
  'ELBv2:setRulePriorities': {
    RulePriorities: [
      {
        RuleArn: '<optional>',
        Priority: '<optional>',
      },
    ],
  },
  'ELBv2:setSecurityGroups': {
    LoadBalancerArn: 'required',
    SecurityGroups: ['required1', 'required2'],
  },
  'ELBv2:setSubnets': {
    LoadBalancerArn: 'required',
    Subnets: ['<optional1>', '<optional2>'],
    SubnetMappings: [
      {
        SubnetId: '<optional>',
        AllocationId: '<optional>',
      },
    ],
  },
  'EMR:addInstanceFleet': {
    ClusterId: 'required',
    InstanceFleet: {
      Name: '<optional>',
      TargetOnDemandCapacity: '<optional>',
      TargetSpotCapacity: '<optional>',
      InstanceTypeConfigs: [
        {
          WeightedCapacity: '<optional>',
          BidPrice: '<optional>',
          BidPriceAsPercentageOfOnDemandPrice: '<optional>',
          EbsConfiguration: {
            EbsBlockDeviceConfigs: [
              {
                VolumesPerInstance: '<optional>',
                VolumeSpecification: {
                  Iops: ['<optional1>', '<optional2>'],
                },
              },
            ],
            EbsOptimized: '<optional>',
          },
          Configurations: [
            {
              Classification: '<optional>',
              Configurations: ['<optional1>', '<optional2>'],
              Properties: ['<optional1>', '<optional2>'],
            },
          ],
        },
      ],
      LaunchSpecifications: [
        {
          SpotSpecification: {
            BlockDurationMinutes: ['<optional1>', '<optional2>'],
          },
        },
      ],
    },
  },
  'EMR:addInstanceGroups': {
    InstanceGroups: [
      {
        Name: '<optional>',
        Market: '<optional>',
        BidPrice: '<optional>',
        Configurations: [
          {
            Classification: '<optional>',
            Configurations: ['<optional1>', '<optional2>'],
            Properties: ['<optional1>', '<optional2>'],
          },
        ],
        EbsConfiguration: {
          EbsBlockDeviceConfigs: [
            {
              VolumesPerInstance: '<optional>',
              VolumeSpecification: {
                Iops: ['<optional1>', '<optional2>'],
              },
            },
          ],
          EbsOptimized: '<optional>',
        },
        AutoScalingPolicy: {
          Rules: [
            {
              Description: '<optional>',
              Action: {
                Market: '<optional>',
                SimpleScalingPolicyConfiguration: {
                  AdjustmentType: '<optional>',
                  CoolDown: '<optional>',
                },
              },
              Trigger: {
                CloudWatchAlarmDefinition: {
                  EvaluationPeriods: ['<optional1>', '<optional2>'],
                  Namespace: '<optional>',
                  Statistic: '<optional>',
                  Unit: '<optional>',
                  Dimensions: [
                    {
                      Key: '<optional>',
                      Value: '<optional>',
                    },
                  ],
                },
              },
            },
          ],
        },
      },
    ],
    JobFlowId: 'required',
  },
  'EMR:addJobFlowSteps': {
    JobFlowId: 'required',
    Steps: [
      {
        ActionOnFailure: '<optional>',
        HadoopJarStep: {
          Properties: [
            {
              Key: '<optional>',
              Value: '<optional>',
            },
          ],
          MainClass: '<optional>',
          Args: ['<optional1>', '<optional2>'],
        },
      },
    ],
  },
  'EMR:addTags': {
    ResourceId: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'EMR:cancelSteps': {
    ClusterId: '<optional>',
    StepIds: ['<optional1>', '<optional2>'],
  },
  'EMR:createSecurityConfiguration': {
    Name: 'required',
    SecurityConfiguration: 'required',
  },
  'EMR:deleteSecurityConfiguration': {
    Name: 'required',
  },
  'EMR:describeCluster': {
    ClusterId: 'required',
  },
  'EMR:describeJobFlows': {
    CreatedAfter: '<optional>',
    CreatedBefore: '<optional>',
    JobFlowIds: ['<optional1>', '<optional2>'],
    JobFlowStates: ['<optional1>', '<optional2>'],
  },
  'EMR:describeSecurityConfiguration': {
    Name: 'required',
  },
  'EMR:describeStep': {
    ClusterId: 'required',
    StepId: 'required',
  },
  'EMR:getBlockPublicAccessConfiguration': {},
  'EMR:listBootstrapActions': {
    ClusterId: 'required',
  },
  'EMR:listClusters': {
    CreatedAfter: '<optional>',
    CreatedBefore: '<optional>',
    ClusterStates: ['<optional1>', '<optional2>'],
  },
  'EMR:listInstanceFleets': {
    ClusterId: 'required',
  },
  'EMR:listInstanceGroups': {
    ClusterId: 'required',
  },
  'EMR:listInstances': {
    ClusterId: 'required',
    InstanceGroupId: '<optional>',
    InstanceGroupTypes: ['<optional1>', '<optional2>'],
    InstanceFleetId: '<optional>',
    InstanceFleetType: '<optional>',
    InstanceStates: ['<optional1>', '<optional2>'],
  },
  'EMR:listSecurityConfigurations': {},
  'EMR:listSteps': {
    ClusterId: 'required',
    StepStates: ['<optional1>', '<optional2>'],
    StepIds: ['<optional1>', '<optional2>'],
  },
  'EMR:modifyCluster': {
    ClusterId: 'required',
    StepConcurrencyLevel: '<optional>',
  },
  'EMR:modifyInstanceFleet': {
    ClusterId: 'required',
    InstanceFleet: {
      TargetOnDemandCapacity: '<optional>',
      TargetSpotCapacity: '<optional>',
    },
  },
  'EMR:modifyInstanceGroups': {
    InstanceGroups: [
      {
        InstanceCount: '<optional>',
        EC2InstanceIdsToTerminate: '<optional>',
        ShrinkPolicy: {
          DecommissionTimeout: '<optional>',
          InstanceResizePolicy: {
            InstancesToTerminate: '<optional>',
            InstancesToProtect: '<optional>',
            InstanceTerminationTimeout: '<optional>',
          },
        },
        Configurations: [
          {
            Classification: '<optional>',
            Configurations: ['<optional1>', '<optional2>'],
            Properties: ['<optional1>', '<optional2>'],
          },
        ],
      },
    ],
    ClusterId: '<optional>',
  },
  'EMR:putAutoScalingPolicy': {
    ClusterId: 'required',
    InstanceGroupId: 'required',
    AutoScalingPolicy: {
      Rules: [
        {
          Description: '<optional>',
          Action: {
            Market: '<optional>',
            SimpleScalingPolicyConfiguration: {
              AdjustmentType: '<optional>',
              CoolDown: '<optional>',
            },
          },
          Trigger: {
            CloudWatchAlarmDefinition: {
              EvaluationPeriods: ['<optional1>', '<optional2>'],
              Namespace: '<optional>',
              Statistic: '<optional>',
              Unit: '<optional>',
              Dimensions: [
                {
                  Key: '<optional>',
                  Value: '<optional>',
                },
              ],
            },
          },
        },
      ],
    },
  },
  'EMR:putBlockPublicAccessConfiguration': {
    BlockPublicAccessConfiguration: {
      PermittedPublicSecurityGroupRuleRanges: [
        {
          MaxRange: '<optional>',
        },
      ],
    },
  },
  'EMR:removeAutoScalingPolicy': {
    ClusterId: 'required',
    InstanceGroupId: 'required',
  },
  'EMR:removeTags': {
    ResourceId: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'EMR:runJobFlow': {
    Name: 'required',
    Instances: [
      {
        MasterInstanceType: '<optional>',
        SlaveInstanceType: '<optional>',
        InstanceCount: '<optional>',
        InstanceGroups: [
          {
            Name: '<optional>',
            Market: '<optional>',
            BidPrice: '<optional>',
            Configurations: [
              {
                Classification: '<optional>',
                Configurations: ['<optional1>', '<optional2>'],
                Properties: ['<optional1>', '<optional2>'],
              },
            ],
            EbsConfiguration: {
              EbsBlockDeviceConfigs: [
                {
                  VolumesPerInstance: '<optional>',
                  VolumeSpecification: {
                    Iops: ['<optional1>', '<optional2>'],
                  },
                },
              ],
              EbsOptimized: '<optional>',
            },
            AutoScalingPolicy: {
              Rules: [
                {
                  Description: '<optional>',
                  Action: {
                    Market: '<optional>',
                    SimpleScalingPolicyConfiguration: {
                      AdjustmentType: '<optional>',
                      CoolDown: '<optional>',
                    },
                  },
                  Trigger: {
                    CloudWatchAlarmDefinition: {
                      EvaluationPeriods: ['<optional1>', '<optional2>'],
                      Namespace: '<optional>',
                      Statistic: '<optional>',
                      Unit: '<optional>',
                      Dimensions: [
                        {
                          Key: '<optional>',
                          Value: '<optional>',
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        ],
        InstanceFleets: [
          {
            Name: '<optional>',
            TargetOnDemandCapacity: '<optional>',
            TargetSpotCapacity: '<optional>',
            InstanceTypeConfigs: [
              {
                WeightedCapacity: '<optional>',
                BidPrice: '<optional>',
                BidPriceAsPercentageOfOnDemandPrice: '<optional>',
                EbsConfiguration: {
                  EbsBlockDeviceConfigs: [
                    {
                      VolumesPerInstance: '<optional>',
                      VolumeSpecification: {
                        Iops: ['<optional1>', '<optional2>'],
                      },
                    },
                  ],
                  EbsOptimized: '<optional>',
                },
                Configurations: [
                  {
                    Classification: '<optional>',
                    Configurations: ['<optional1>', '<optional2>'],
                    Properties: ['<optional1>', '<optional2>'],
                  },
                ],
              },
            ],
            LaunchSpecifications: [
              {
                SpotSpecification: {
                  BlockDurationMinutes: ['<optional1>', '<optional2>'],
                },
              },
            ],
          },
        ],
        Ec2KeyName: '<optional>',
        Placement: {
          AvailabilityZone: '<optional>',
          AvailabilityZones: ['<optional1>', '<optional2>'],
        },
        KeepJobFlowAliveWhenNoSteps: ['<optional1>', '<optional2>'],
        TerminationProtected: '<optional>',
        HadoopVersion: '<optional>',
        Ec2SubnetId: '<optional>',
        Ec2SubnetIds: ['<optional1>', '<optional2>'],
        EmrManagedMasterSecurityGroup: '<optional>',
        EmrManagedSlaveSecurityGroup: '<optional>',
        ServiceAccessSecurityGroup: '<optional>',
        AdditionalMasterSecurityGroups: ['<optional1>', '<optional2>'],
        AdditionalSlaveSecurityGroups: ['<optional1>', '<optional2>'],
      },
    ],
    Steps: [
      {
        ActionOnFailure: '<optional>',
        HadoopJarStep: {
          Properties: [
            {
              Key: '<optional>',
              Value: '<optional>',
            },
          ],
          MainClass: '<optional>',
          Args: ['<optional1>', '<optional2>'],
        },
      },
    ],
    BootstrapActions: [
      {
        ScriptBootstrapAction: {
          Args: ['<optional1>', '<optional2>'],
        },
      },
    ],
    KerberosAttributes: [
      {
        CrossRealmTrustPrincipalPassword: '<optional>',
        ADDomainJoinUser: '<optional>',
        ADDomainJoinPassword: '<optional>',
      },
    ],
    LogUri: '<optional>',
    AdditionalInfo: '<optional>',
    AmiVersion: '<optional>',
    ReleaseLabel: '<optional>',
    SupportedProducts: ['<optional1>', '<optional2>'],
    NewSupportedProducts: [
      {
        Name: '<optional>',
        Args: ['<optional1>', '<optional2>'],
      },
    ],
    Applications: [
      {
        Name: '<optional>',
        Version: '<optional>',
        Args: ['<optional1>', '<optional2>'],
        AdditionalInfo: '<optional>',
      },
    ],
    Configurations: [
      {
        Classification: '<optional>',
        Configurations: ['<optional1>', '<optional2>'],
        Properties: ['<optional1>', '<optional2>'],
      },
    ],
    VisibleToAllUsers: ['<optional1>', '<optional2>'],
    JobFlowRole: '<optional>',
    ServiceRole: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    SecurityConfiguration: '<optional>',
    AutoScalingRole: '<optional>',
    ScaleDownBehavior: '<optional>',
    CustomAmiId: '<optional>',
    EbsRootVolumeSize: '<optional>',
    RepoUpgradeOnBoot: '<optional>',
  },
  'EMR:setTerminationProtection': {
    JobFlowIds: ['required1', 'required2'],
    TerminationProtected: 'required',
  },
  'EMR:setVisibleToAllUsers': {
    JobFlowIds: ['required1', 'required2'],
    VisibleToAllUsers: ['required1', 'required2'],
  },
  'EMR:terminateJobFlows': {
    JobFlowIds: ['required1', 'required2'],
  },
  'ES:addTags': {
    ARN: 'required',
    TagList: {
      Key: 'required',
      Value: 'required',
    },
  },
  'ES:cancelElasticsearchServiceSoftwareUpdate': {
    DomainName: 'required',
  },
  'ES:createElasticsearchDomain': {
    DomainName: 'required',
    ElasticsearchVersion: '<optional>',
    ElasticsearchClusterConfig: {
      InstanceType: '<optional>',
      InstanceCount: '<optional>',
      DedicatedMasterEnabled: '<optional>',
      ZoneAwarenessEnabled: '<optional>',
      ZoneAwarenessConfig: {
        AvailabilityZoneCount: '<optional>',
      },
      DedicatedMasterType: '<optional>',
      DedicatedMasterCount: '<optional>',
    },
    EBSOptions: [
      {
        EBSEnabled: '<optional>',
        VolumeType: '<optional>',
        VolumeSize: '<optional>',
        Iops: ['<optional1>', '<optional2>'],
      },
    ],
    AccessPolicies: ['<optional1>', '<optional2>'],
    SnapshotOptions: [
      {
        AutomatedSnapshotStartHour: '<optional>',
      },
    ],
    VPCOptions: [
      {
        SubnetIds: ['<optional1>', '<optional2>'],
        SecurityGroupIds: ['<optional1>', '<optional2>'],
      },
    ],
    CognitoOptions: [
      {
        Enabled: '<optional>',
        UserPoolId: '<optional>',
        IdentityPoolId: '<optional>',
        RoleArn: '<optional>',
      },
    ],
    EncryptionAtRestOptions: [
      {
        Enabled: '<optional>',
        KmsKeyId: '<optional>',
      },
    ],
    NodeToNodeEncryptionOptions: [
      {
        Enabled: '<optional>',
      },
    ],
    AdvancedOptions: ['<optional1>', '<optional2>'],
    LogPublishingOptions: ['<optional1>', '<optional2>'],
  },
  'ES:deleteElasticsearchDomain': {
    DomainName: 'required',
  },
  'ES:deleteElasticsearchServiceRole': {},
  'ES:describeElasticsearchDomain': {
    DomainName: 'required',
  },
  'ES:describeElasticsearchDomainConfig': {
    DomainName: 'required',
  },
  'ES:describeElasticsearchDomains': {
    DomainNames: ['required1', 'required2'],
  },
  'ES:describeElasticsearchInstanceTypeLimits': {
    InstanceType: 'required',
    ElasticsearchVersion: 'required',
    DomainName: '<optional>',
  },
  'ES:describeReservedElasticsearchInstanceOfferings': {
    ReservedElasticsearchInstanceOfferingId: '<optional>',
  },
  'ES:describeReservedElasticsearchInstances': {
    ReservedElasticsearchInstanceId: '<optional>',
  },
  'ES:getCompatibleElasticsearchVersions': {
    DomainName: '<optional>',
  },
  'ES:getUpgradeHistory': {
    DomainName: 'required',
  },
  'ES:getUpgradeStatus': {
    DomainName: 'required',
  },
  'ES:listDomainNames': {},
  'ES:listElasticsearchInstanceTypes': {
    ElasticsearchVersion: 'required',
    DomainName: '<optional>',
  },
  'ES:listElasticsearchVersions': {},
  'ES:listTags': {
    ARN: 'required',
  },
  'ES:purchaseReservedElasticsearchInstanceOffering': {
    ReservedElasticsearchInstanceOfferingId: 'required',
    ReservationName: 'required',
    InstanceCount: '<optional>',
  },
  'ES:removeTags': {
    ARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'ES:startElasticsearchServiceSoftwareUpdate': {
    DomainName: 'required',
  },
  'ES:updateElasticsearchDomainConfig': {
    DomainName: 'required',
    ElasticsearchClusterConfig: {
      InstanceType: '<optional>',
      InstanceCount: '<optional>',
      DedicatedMasterEnabled: '<optional>',
      ZoneAwarenessEnabled: '<optional>',
      ZoneAwarenessConfig: {
        AvailabilityZoneCount: '<optional>',
      },
      DedicatedMasterType: '<optional>',
      DedicatedMasterCount: '<optional>',
    },
    EBSOptions: [
      {
        EBSEnabled: '<optional>',
        VolumeType: '<optional>',
        VolumeSize: '<optional>',
        Iops: ['<optional1>', '<optional2>'],
      },
    ],
    SnapshotOptions: [
      {
        AutomatedSnapshotStartHour: '<optional>',
      },
    ],
    VPCOptions: [
      {
        SubnetIds: ['<optional1>', '<optional2>'],
        SecurityGroupIds: ['<optional1>', '<optional2>'],
      },
    ],
    CognitoOptions: [
      {
        Enabled: '<optional>',
        UserPoolId: '<optional>',
        IdentityPoolId: '<optional>',
        RoleArn: '<optional>',
      },
    ],
    AdvancedOptions: ['<optional1>', '<optional2>'],
    AccessPolicies: ['<optional1>', '<optional2>'],
    LogPublishingOptions: ['<optional1>', '<optional2>'],
  },
  'ES:upgradeElasticsearchDomain': {
    DomainName: 'required',
    TargetVersion: 'required',
    PerformCheckOnly: '<optional>',
  },
  'ElastiCache:addTagsToResource': {
    ResourceName: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ElastiCache:authorizeCacheSecurityGroupIngress': {
    CacheSecurityGroupName: 'required',
    EC2SecurityGroupName: 'required',
    EC2SecurityGroupOwnerId: 'required',
  },
  'ElastiCache:batchApplyUpdateAction': {
    ReplicationGroupIds: ['required1', 'required2'],
    ServiceUpdateName: 'required',
  },
  'ElastiCache:batchStopUpdateAction': {
    ReplicationGroupIds: ['required1', 'required2'],
    ServiceUpdateName: 'required',
  },
  'ElastiCache:completeMigration': {
    ReplicationGroupId: 'required',
    Force: '<optional>',
  },
  'ElastiCache:copySnapshot': {
    SourceSnapshotName: 'required',
    TargetSnapshotName: 'required',
    TargetBucket: '<optional>',
  },
  'ElastiCache:createCacheCluster': {
    CacheClusterId: 'required',
    ReplicationGroupId: '<optional>',
    AZMode: '<optional>',
    PreferredAvailabilityZone: '<optional>',
    PreferredAvailabilityZones: ['<optional1>', '<optional2>'],
    NumCacheNodes: ['<optional1>', '<optional2>'],
    CacheNodeType: '<optional>',
    Engine: '<optional>',
    EngineVersion: '<optional>',
    CacheParameterGroupName: '<optional>',
    CacheSubnetGroupName: '<optional>',
    CacheSecurityGroupNames: ['<optional1>', '<optional2>'],
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    SnapshotArns: ['<optional1>', '<optional2>'],
    SnapshotName: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    Port: '<optional>',
    NotificationTopicArn: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    SnapshotRetentionLimit: '<optional>',
    SnapshotWindow: '<optional>',
    AuthToken: '<optional>',
  },
  'ElastiCache:createCacheParameterGroup': {
    CacheParameterGroupName: 'required',
    CacheParameterGroupFamily: 'required',
    Description: 'required',
  },
  'ElastiCache:createCacheSecurityGroup': {
    CacheSecurityGroupName: 'required',
    Description: 'required',
  },
  'ElastiCache:createCacheSubnetGroup': {
    CacheSubnetGroupName: 'required',
    CacheSubnetGroupDescription: 'required',
    SubnetIds: ['required1', 'required2'],
  },
  'ElastiCache:createReplicationGroup': {
    ReplicationGroupId: 'required',
    ReplicationGroupDescription: 'required',
    PrimaryClusterId: '<optional>',
    AutomaticFailoverEnabled: '<optional>',
    NumCacheClusters: ['<optional1>', '<optional2>'],
    PreferredCacheClusterAZs: ['<optional1>', '<optional2>'],
    NumNodeGroups: ['<optional1>', '<optional2>'],
    ReplicasPerNodeGroup: '<optional>',
    NodeGroupConfiguration: {
      NodeGroupId: '<optional>',
      Slots: ['<optional1>', '<optional2>'],
      ReplicaCount: '<optional>',
      PrimaryAvailabilityZone: '<optional>',
      ReplicaAvailabilityZones: ['<optional1>', '<optional2>'],
    },
    CacheNodeType: '<optional>',
    Engine: '<optional>',
    EngineVersion: '<optional>',
    CacheParameterGroupName: '<optional>',
    CacheSubnetGroupName: '<optional>',
    CacheSecurityGroupNames: ['<optional1>', '<optional2>'],
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    SnapshotArns: ['<optional1>', '<optional2>'],
    SnapshotName: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    Port: '<optional>',
    NotificationTopicArn: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    SnapshotRetentionLimit: '<optional>',
    SnapshotWindow: '<optional>',
    AuthToken: '<optional>',
    TransitEncryptionEnabled: '<optional>',
    AtRestEncryptionEnabled: '<optional>',
  },
  'ElastiCache:createSnapshot': {
    SnapshotName: 'required',
    ReplicationGroupId: '<optional>',
    CacheClusterId: '<optional>',
  },
  'ElastiCache:decreaseReplicaCount': {
    ReplicationGroupId: 'required',
    ApplyImmediately: 'required',
    ReplicaConfiguration: {
      PreferredAvailabilityZones: ['<optional1>', '<optional2>'],
    },
    NewReplicaCount: '<optional>',
    ReplicasToRemove: '<optional>',
  },
  'ElastiCache:deleteCacheCluster': {
    CacheClusterId: 'required',
    FinalSnapshotIdentifier: '<optional>',
  },
  'ElastiCache:deleteCacheParameterGroup': {
    CacheParameterGroupName: 'required',
  },
  'ElastiCache:deleteCacheSecurityGroup': {
    CacheSecurityGroupName: 'required',
  },
  'ElastiCache:deleteCacheSubnetGroup': {
    CacheSubnetGroupName: 'required',
  },
  'ElastiCache:deleteReplicationGroup': {
    ReplicationGroupId: 'required',
    RetainPrimaryCluster: '<optional>',
    FinalSnapshotIdentifier: '<optional>',
  },
  'ElastiCache:deleteSnapshot': {
    SnapshotName: 'required',
  },
  'ElastiCache:describeCacheClusters': {
    CacheClusterId: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    ShowCacheNodeInfo: '<optional>',
    ShowCacheClustersNotInReplicationGroups: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeCacheEngineVersions': {
    Engine: '<optional>',
    EngineVersion: '<optional>',
    CacheParameterGroupFamily: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    DefaultOnly: '<optional>',
  },
  'ElastiCache:describeCacheParameterGroups': {
    CacheParameterGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeCacheParameters': {
    CacheParameterGroupName: 'required',
    Source: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeCacheSecurityGroups': {
    CacheSecurityGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeCacheSubnetGroups': {
    CacheSubnetGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeEngineDefaultParameters': {
    CacheParameterGroupFamily: 'required',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeEvents': {
    SourceIdentifier: '<optional>',
    SourceType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Duration: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeReplicationGroups': {
    ReplicationGroupId: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeReservedCacheNodes': {
    ReservedCacheNodeId: '<optional>',
    ReservedCacheNodesOfferingId: '<optional>',
    CacheNodeType: '<optional>',
    Duration: '<optional>',
    ProductDescription: '<optional>',
    OfferingType: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeReservedCacheNodesOfferings': {
    ReservedCacheNodesOfferingId: '<optional>',
    CacheNodeType: '<optional>',
    Duration: '<optional>',
    ProductDescription: '<optional>',
    OfferingType: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeServiceUpdates': {
    ServiceUpdateName: '<optional>',
    ServiceUpdateStatus: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:describeSnapshots': {
    ReplicationGroupId: '<optional>',
    CacheClusterId: '<optional>',
    SnapshotName: '<optional>',
    SnapshotSource: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    ShowNodeGroupConfig: '<optional>',
  },
  'ElastiCache:describeUpdateActions': {
    ServiceUpdateName: '<optional>',
    ReplicationGroupIds: ['<optional1>', '<optional2>'],
    ServiceUpdateStatus: '<optional>',
    ServiceUpdateTimeRange: {
      StartTime: '<optional>',
      EndTime: '<optional>',
    },
    UpdateActionStatus: '<optional>',
    ShowNodeLevelUpdateStatus: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:increaseReplicaCount': {
    ReplicationGroupId: 'required',
    ApplyImmediately: 'required',
    ReplicaConfiguration: {
      PreferredAvailabilityZones: ['<optional1>', '<optional2>'],
    },
    NewReplicaCount: '<optional>',
  },
  'ElastiCache:listAllowedNodeTypeModifications': {
    CacheClusterId: '<optional>',
    ReplicationGroupId: '<optional>',
  },
  'ElastiCache:listTagsForResource': {
    ResourceName: 'required',
  },
  'ElastiCache:modifyCacheCluster': {
    CacheClusterId: 'required',
    NumCacheNodes: ['<optional1>', '<optional2>'],
    CacheNodeIdsToRemove: '<optional>',
    AZMode: '<optional>',
    NewAvailabilityZones: ['<optional1>', '<optional2>'],
    CacheSecurityGroupNames: ['<optional1>', '<optional2>'],
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    PreferredMaintenanceWindow: '<optional>',
    NotificationTopicArn: '<optional>',
    CacheParameterGroupName: '<optional>',
    NotificationTopicStatus: '<optional>',
    ApplyImmediately: '<optional>',
    EngineVersion: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    SnapshotRetentionLimit: '<optional>',
    SnapshotWindow: '<optional>',
    CacheNodeType: '<optional>',
  },
  'ElastiCache:modifyCacheParameterGroup': {
    CacheParameterGroupName: 'required',
    ParameterNameValues: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
      },
    ],
  },
  'ElastiCache:modifyCacheSubnetGroup': {
    CacheSubnetGroupName: 'required',
    CacheSubnetGroupDescription: '<optional>',
    SubnetIds: ['<optional1>', '<optional2>'],
  },
  'ElastiCache:modifyReplicationGroup': {
    ReplicationGroupId: 'required',
    ReplicationGroupDescription: '<optional>',
    PrimaryClusterId: '<optional>',
    SnapshottingClusterId: '<optional>',
    AutomaticFailoverEnabled: '<optional>',
    CacheSecurityGroupNames: ['<optional1>', '<optional2>'],
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    PreferredMaintenanceWindow: '<optional>',
    NotificationTopicArn: '<optional>',
    CacheParameterGroupName: '<optional>',
    NotificationTopicStatus: '<optional>',
    ApplyImmediately: '<optional>',
    EngineVersion: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    SnapshotRetentionLimit: '<optional>',
    SnapshotWindow: '<optional>',
    CacheNodeType: '<optional>',
    NodeGroupId: '<optional>',
  },
  'ElastiCache:modifyReplicationGroupShardConfiguration': {
    ReplicationGroupId: 'required',
    NodeGroupCount: 'required',
    ApplyImmediately: 'required',
    ReshardingConfiguration: {
      NodeGroupId: '<optional>',
      PreferredAvailabilityZones: ['<optional1>', '<optional2>'],
    },
    NodeGroupsToRemove: '<optional>',
    NodeGroupsToRetain: '<optional>',
  },
  'ElastiCache:purchaseReservedCacheNodesOffering': {
    ReservedCacheNodesOfferingId: 'required',
    ReservedCacheNodeId: '<optional>',
    CacheNodeCount: '<optional>',
  },
  'ElastiCache:rebootCacheCluster': {
    CacheClusterId: 'required',
    CacheNodeIdsToReboot: 'required',
  },
  'ElastiCache:removeTagsFromResource': {
    ResourceName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'ElastiCache:resetCacheParameterGroup': {
    CacheParameterGroupName: 'required',
    ResetAllParameters: ['<optional1>', '<optional2>'],
    ParameterNameValues: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
      },
    ],
  },
  'ElastiCache:revokeCacheSecurityGroupIngress': {
    CacheSecurityGroupName: 'required',
    EC2SecurityGroupName: 'required',
    EC2SecurityGroupOwnerId: 'required',
  },
  'ElastiCache:startMigration': {
    ReplicationGroupId: 'required',
    CustomerNodeEndpointList: {
      Address: '<optional>',
      Port: '<optional>',
    },
  },
  'ElastiCache:testFailover': {
    ReplicationGroupId: 'required',
    NodeGroupId: 'required',
  },
  'ElasticBeanstalk:abortEnvironmentUpdate': {
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:applyEnvironmentManagedAction': {
    ActionId: 'required',
    EnvironmentName: '<optional>',
    EnvironmentId: '<optional>',
  },
  'ElasticBeanstalk:checkDNSAvailability': {
    CNAMEPrefix: 'required',
  },
  'ElasticBeanstalk:composeEnvironments': {
    ApplicationName: '<optional>',
    GroupName: '<optional>',
    VersionLabels: ['<optional1>', '<optional2>'],
  },
  'ElasticBeanstalk:createApplication': {
    ApplicationName: 'required',
    ResourceLifecycleConfig: {
      ServiceRole: '<optional>',
      VersionLifecycleConfig: {
        MaxCountRule: {
          MaxCount: '<optional>',
          DeleteSourceFromS3: '<optional>',
        },
        MaxAgeRule: {
          MaxAgeInDays: ['<optional1>', '<optional2>'],
          DeleteSourceFromS3: '<optional>',
        },
      },
    },
    Description: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ElasticBeanstalk:createApplicationVersion': {
    ApplicationName: 'required',
    VersionLabel: 'required',
    SourceBuildInformation: '<optional>',
    BuildConfiguration: {
      ArtifactName: '<optional>',
      ComputeType: '<optional>',
      TimeoutInMinutes: ['<optional1>', '<optional2>'],
    },
    Description: '<optional>',
    SourceBundle: {
      S3Bucket: '<optional>',
      S3Key: '<optional>',
    },
    AutoCreateApplication: '<optional>',
    Process: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ElasticBeanstalk:createConfigurationTemplate': {
    ApplicationName: 'required',
    TemplateName: 'required',
    SolutionStackName: '<optional>',
    PlatformArn: '<optional>',
    SourceConfiguration: {
      ApplicationName: '<optional>',
      TemplateName: '<optional>',
    },
    EnvironmentId: '<optional>',
    Description: '<optional>',
    OptionSettings: [
      {
        ResourceName: '<optional>',
        Namespace: '<optional>',
        OptionName: '<optional>',
        Value: '<optional>',
      },
    ],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ElasticBeanstalk:createEnvironment': {
    ApplicationName: 'required',
    EnvironmentName: '<optional>',
    GroupName: '<optional>',
    Description: '<optional>',
    CNAMEPrefix: '<optional>',
    Tier: {
      Name: '<optional>',
      Type: '<optional>',
      Version: '<optional>',
    },
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    VersionLabel: '<optional>',
    TemplateName: '<optional>',
    SolutionStackName: '<optional>',
    PlatformArn: '<optional>',
    OptionSettings: [
      {
        ResourceName: '<optional>',
        Namespace: '<optional>',
        OptionName: '<optional>',
        Value: '<optional>',
      },
    ],
    OptionsToRemove: {
      ResourceName: '<optional>',
      Namespace: '<optional>',
      OptionName: '<optional>',
    },
  },
  'ElasticBeanstalk:createPlatformVersion': {
    PlatformName: 'required',
    PlatformVersion: 'required',
    PlatformDefinitionBundle: {
      S3Bucket: '<optional>',
      S3Key: '<optional>',
    },
    EnvironmentName: '<optional>',
    OptionSettings: [
      {
        ResourceName: '<optional>',
        Namespace: '<optional>',
        OptionName: '<optional>',
        Value: '<optional>',
      },
    ],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'ElasticBeanstalk:createStorageLocation': {},
  'ElasticBeanstalk:deleteApplication': {
    ApplicationName: 'required',
    TerminateEnvByForce: '<optional>',
  },
  'ElasticBeanstalk:deleteApplicationVersion': {
    ApplicationName: 'required',
    VersionLabel: 'required',
    DeleteSourceBundle: '<optional>',
  },
  'ElasticBeanstalk:deleteConfigurationTemplate': {
    ApplicationName: 'required',
    TemplateName: 'required',
  },
  'ElasticBeanstalk:deleteEnvironmentConfiguration': {
    ApplicationName: 'required',
    EnvironmentName: 'required',
  },
  'ElasticBeanstalk:deletePlatformVersion': {
    PlatformArn: '<optional>',
  },
  'ElasticBeanstalk:describeAccountAttributes': {},
  'ElasticBeanstalk:describeApplicationVersions': {
    ApplicationName: '<optional>',
    VersionLabels: ['<optional1>', '<optional2>'],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElasticBeanstalk:describeApplications': {
    ApplicationNames: ['<optional1>', '<optional2>'],
  },
  'ElasticBeanstalk:describeConfigurationOptions': {
    ApplicationName: '<optional>',
    TemplateName: '<optional>',
    EnvironmentName: '<optional>',
    SolutionStackName: '<optional>',
    PlatformArn: '<optional>',
    Options: [
      {
        ResourceName: '<optional>',
        Namespace: '<optional>',
        OptionName: '<optional>',
      },
    ],
  },
  'ElasticBeanstalk:describeConfigurationSettings': {
    ApplicationName: 'required',
    TemplateName: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:describeEnvironmentHealth': {
    EnvironmentName: '<optional>',
    EnvironmentId: '<optional>',
    AttributeNames: ['<optional1>', '<optional2>'],
  },
  'ElasticBeanstalk:describeEnvironmentManagedActionHistory': {
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:describeEnvironmentManagedActions': {
    EnvironmentName: '<optional>',
    EnvironmentId: '<optional>',
    Status: '<optional>',
  },
  'ElasticBeanstalk:describeEnvironmentResources': {
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:describeEnvironments': {
    ApplicationName: '<optional>',
    VersionLabel: '<optional>',
    EnvironmentIds: ['<optional1>', '<optional2>'],
    EnvironmentNames: ['<optional1>', '<optional2>'],
    IncludeDeleted: '<optional>',
    IncludedDeletedBackTo: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElasticBeanstalk:describeEvents': {
    ApplicationName: '<optional>',
    VersionLabel: '<optional>',
    TemplateName: '<optional>',
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
    PlatformArn: '<optional>',
    RequestId: '<optional>',
    Severity: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElasticBeanstalk:describeInstancesHealth': {
    EnvironmentName: '<optional>',
    EnvironmentId: '<optional>',
    AttributeNames: ['<optional1>', '<optional2>'],
  },
  'ElasticBeanstalk:describePlatformVersion': {
    PlatformArn: '<optional>',
  },
  'ElasticBeanstalk:listAvailableSolutionStacks': {},
  'ElasticBeanstalk:listPlatformVersions': {
    Filters: [
      {
        Type: '<optional>',
        Operator: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'ElasticBeanstalk:listTagsForResource': {
    ResourceArn: 'required',
  },
  'ElasticBeanstalk:rebuildEnvironment': {
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:requestEnvironmentInfo': {
    InfoType: 'required',
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:restartAppServer': {
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:retrieveEnvironmentInfo': {
    InfoType: 'required',
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:swapEnvironmentCNAMEs': {
    SourceEnvironmentId: '<optional>',
    SourceEnvironmentName: '<optional>',
    DestinationEnvironmentId: '<optional>',
    DestinationEnvironmentName: '<optional>',
  },
  'ElasticBeanstalk:terminateEnvironment': {
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
    TerminateResources: ['<optional1>', '<optional2>'],
    ForceTerminate: '<optional>',
  },
  'ElasticBeanstalk:updateApplication': {
    ApplicationName: 'required',
    Description: '<optional>',
  },
  'ElasticBeanstalk:updateApplicationResourceLifecycle': {
    ApplicationName: 'required',
    ResourceLifecycleConfig: {
      ServiceRole: '<optional>',
      VersionLifecycleConfig: {
        MaxCountRule: {
          MaxCount: '<optional>',
          DeleteSourceFromS3: '<optional>',
        },
        MaxAgeRule: {
          MaxAgeInDays: ['<optional1>', '<optional2>'],
          DeleteSourceFromS3: '<optional>',
        },
      },
    },
  },
  'ElasticBeanstalk:updateApplicationVersion': {
    ApplicationName: 'required',
    VersionLabel: 'required',
    Description: '<optional>',
  },
  'ElasticBeanstalk:updateConfigurationTemplate': {
    ApplicationName: 'required',
    TemplateName: 'required',
    Description: '<optional>',
    OptionSettings: [
      {
        ResourceName: '<optional>',
        Namespace: '<optional>',
        OptionName: '<optional>',
        Value: '<optional>',
      },
    ],
    OptionsToRemove: {
      ResourceName: '<optional>',
      Namespace: '<optional>',
      OptionName: '<optional>',
    },
  },
  'ElasticBeanstalk:updateEnvironment': {
    ApplicationName: '<optional>',
    EnvironmentId: '<optional>',
    EnvironmentName: '<optional>',
    GroupName: '<optional>',
    Description: '<optional>',
    Tier: {
      Name: '<optional>',
      Type: '<optional>',
      Version: '<optional>',
    },
    VersionLabel: '<optional>',
    TemplateName: '<optional>',
    SolutionStackName: '<optional>',
    PlatformArn: '<optional>',
    OptionSettings: [
      {
        ResourceName: '<optional>',
        Namespace: '<optional>',
        OptionName: '<optional>',
        Value: '<optional>',
      },
    ],
    OptionsToRemove: {
      ResourceName: '<optional>',
      Namespace: '<optional>',
      OptionName: '<optional>',
    },
  },
  'ElasticBeanstalk:updateTagsForResource': {
    ResourceArn: 'required',
    TagsToAdd: {
      Key: '<optional>',
      Value: '<optional>',
    },
    TagsToRemove: '<optional>',
  },
  'ElasticBeanstalk:validateConfigurationSettings': {
    ApplicationName: 'required',
    OptionSettings: [
      {
        ResourceName: '<optional>',
        Namespace: '<optional>',
        OptionName: '<optional>',
        Value: '<optional>',
      },
    ],
    TemplateName: '<optional>',
    EnvironmentName: '<optional>',
  },
  'ElasticInference:listTagsForResource': {
    resourceArn: 'required',
  },
  'ElasticInference:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'ElasticInference:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'ElasticTranscoder:cancelJob': {
    Id: 'required',
  },
  'ElasticTranscoder:createJob': {
    PipelineId: 'required',
    Input: {
      Key: '<optional>',
      FrameRate: '<optional>',
      Resolution: '<optional>',
      AspectRatio: '<optional>',
      Interlaced: '<optional>',
      Container: '<optional>',
      Encryption: {
        Mode: '<optional>',
        Key: '<optional>',
        KeyMd5: '<optional>',
        InitializationVector: '<optional>',
      },
      TimeSpan: {
        StartTime: '<optional>',
        Duration: '<optional>',
      },
      InputCaptions: [
        {
          MergePolicy: '<optional>',
          CaptionSources: [
            {
              Key: '<optional>',
              Language: '<optional>',
              TimeOffset: '<optional>',
              Label: '<optional>',
              Encryption: {
                Mode: '<optional>',
                Key: '<optional>',
                KeyMd5: '<optional>',
                InitializationVector: '<optional>',
              },
            },
          ],
        },
      ],
      DetectedProperties: [
        {
          Width: '<optional>',
          Height: '<optional>',
          FrameRate: '<optional>',
          FileSize: '<optional>',
          DurationMillis: ['<optional1>', '<optional2>'],
        },
      ],
    },
    Inputs: [
      {
        Key: '<optional>',
        FrameRate: '<optional>',
        Resolution: '<optional>',
        AspectRatio: '<optional>',
        Interlaced: '<optional>',
        Container: '<optional>',
        Encryption: {
          Mode: '<optional>',
          Key: '<optional>',
          KeyMd5: '<optional>',
          InitializationVector: '<optional>',
        },
        TimeSpan: {
          StartTime: '<optional>',
          Duration: '<optional>',
        },
        InputCaptions: [
          {
            MergePolicy: '<optional>',
            CaptionSources: [
              {
                Key: '<optional>',
                Language: '<optional>',
                TimeOffset: '<optional>',
                Label: '<optional>',
                Encryption: {
                  Mode: '<optional>',
                  Key: '<optional>',
                  KeyMd5: '<optional>',
                  InitializationVector: '<optional>',
                },
              },
            ],
          },
        ],
        DetectedProperties: [
          {
            Width: '<optional>',
            Height: '<optional>',
            FrameRate: '<optional>',
            FileSize: '<optional>',
            DurationMillis: ['<optional1>', '<optional2>'],
          },
        ],
      },
    ],
    Output: {
      Key: '<optional>',
      ThumbnailPattern: '<optional>',
      ThumbnailEncryption: {
        Mode: '<optional>',
        Key: '<optional>',
        KeyMd5: '<optional>',
        InitializationVector: '<optional>',
      },
      Rotate: '<optional>',
      PresetId: '<optional>',
      SegmentDuration: '<optional>',
      Watermarks: [
        {
          PresetWatermarkId: '<optional>',
          InputKey: '<optional>',
          Encryption: {
            Mode: '<optional>',
            Key: '<optional>',
            KeyMd5: '<optional>',
            InitializationVector: '<optional>',
          },
        },
      ],
      AlbumArt: {
        MergePolicy: '<optional>',
        Artwork: {
          InputKey: '<optional>',
          MaxWidth: '<optional>',
          MaxHeight: '<optional>',
          SizingPolicy: '<optional>',
          PaddingPolicy: '<optional>',
          AlbumArtFormat: '<optional>',
          Encryption: {
            Mode: '<optional>',
            Key: '<optional>',
            KeyMd5: '<optional>',
            InitializationVector: '<optional>',
          },
        },
      },
      Composition: {
        TimeSpan: {
          StartTime: '<optional>',
          Duration: '<optional>',
        },
      },
      Captions: [
        {
          MergePolicy: '<optional>',
          CaptionSources: [
            {
              Key: '<optional>',
              Language: '<optional>',
              TimeOffset: '<optional>',
              Label: '<optional>',
              Encryption: {
                Mode: '<optional>',
                Key: '<optional>',
                KeyMd5: '<optional>',
                InitializationVector: '<optional>',
              },
            },
          ],
          CaptionFormats: [
            {
              Format: '<optional>',
              Pattern: '<optional>',
              Encryption: {
                Mode: '<optional>',
                Key: '<optional>',
                KeyMd5: '<optional>',
                InitializationVector: '<optional>',
              },
            },
          ],
        },
      ],
      Encryption: {
        Mode: '<optional>',
        Key: '<optional>',
        KeyMd5: '<optional>',
        InitializationVector: '<optional>',
      },
    },
    Outputs: [
      {
        Key: '<optional>',
        ThumbnailPattern: '<optional>',
        ThumbnailEncryption: {
          Mode: '<optional>',
          Key: '<optional>',
          KeyMd5: '<optional>',
          InitializationVector: '<optional>',
        },
        Rotate: '<optional>',
        PresetId: '<optional>',
        SegmentDuration: '<optional>',
        Watermarks: [
          {
            PresetWatermarkId: '<optional>',
            InputKey: '<optional>',
            Encryption: {
              Mode: '<optional>',
              Key: '<optional>',
              KeyMd5: '<optional>',
              InitializationVector: '<optional>',
            },
          },
        ],
        AlbumArt: {
          MergePolicy: '<optional>',
          Artwork: {
            InputKey: '<optional>',
            MaxWidth: '<optional>',
            MaxHeight: '<optional>',
            SizingPolicy: '<optional>',
            PaddingPolicy: '<optional>',
            AlbumArtFormat: '<optional>',
            Encryption: {
              Mode: '<optional>',
              Key: '<optional>',
              KeyMd5: '<optional>',
              InitializationVector: '<optional>',
            },
          },
        },
        Composition: {
          TimeSpan: {
            StartTime: '<optional>',
            Duration: '<optional>',
          },
        },
        Captions: [
          {
            MergePolicy: '<optional>',
            CaptionSources: [
              {
                Key: '<optional>',
                Language: '<optional>',
                TimeOffset: '<optional>',
                Label: '<optional>',
                Encryption: {
                  Mode: '<optional>',
                  Key: '<optional>',
                  KeyMd5: '<optional>',
                  InitializationVector: '<optional>',
                },
              },
            ],
            CaptionFormats: [
              {
                Format: '<optional>',
                Pattern: '<optional>',
                Encryption: {
                  Mode: '<optional>',
                  Key: '<optional>',
                  KeyMd5: '<optional>',
                  InitializationVector: '<optional>',
                },
              },
            ],
          },
        ],
        Encryption: {
          Mode: '<optional>',
          Key: '<optional>',
          KeyMd5: '<optional>',
          InitializationVector: '<optional>',
        },
      },
    ],
    OutputKeyPrefix: '<optional>',
    Playlists: [
      {
        Name: '<optional>',
        Format: '<optional>',
        OutputKeys: ['<optional1>', '<optional2>'],
        HlsContentProtection: {
          Method: '<optional>',
          Key: '<optional>',
          KeyMd5: '<optional>',
          InitializationVector: '<optional>',
          LicenseAcquisitionUrl: '<optional>',
          KeyStoragePolicy: '<optional>',
        },
        PlayReadyDrm: {
          Format: '<optional>',
          Key: '<optional>',
          KeyMd5: '<optional>',
          KeyId: '<optional>',
          InitializationVector: '<optional>',
          LicenseAcquisitionUrl: '<optional>',
        },
      },
    ],
    UserMetadata: ['<optional1>', '<optional2>'],
  },
  'ElasticTranscoder:createPipeline': {
    Name: 'required',
    InputBucket: 'required',
    Role: 'required',
    OutputBucket: '<optional>',
    AwsKmsKeyArn: '<optional>',
    Notifications: [
      {
        Progressing: '<optional>',
        Completed: '<optional>',
        Warning: '<optional>',
        Error: '<optional>',
      },
    ],
    ContentConfig: {
      Bucket: '<optional>',
      StorageClass: '<optional>',
      Permissions: [
        {
          GranteeType: '<optional>',
          Grantee: '<optional>',
          Access: '<optional>',
        },
      ],
    },
    ThumbnailConfig: {
      Bucket: '<optional>',
      StorageClass: '<optional>',
      Permissions: [
        {
          GranteeType: '<optional>',
          Grantee: '<optional>',
          Access: '<optional>',
        },
      ],
    },
  },
  'ElasticTranscoder:createPreset': {
    Name: 'required',
    Container: 'required',
    Description: '<optional>',
    Video: {
      Codec: '<optional>',
      CodecOptions: ['<optional1>', '<optional2>'],
      KeyframesMaxDist: '<optional>',
      FixedGOP: '<optional>',
      BitRate: '<optional>',
      FrameRate: '<optional>',
      MaxFrameRate: '<optional>',
      Resolution: '<optional>',
      AspectRatio: '<optional>',
      MaxWidth: '<optional>',
      MaxHeight: '<optional>',
      DisplayAspectRatio: '<optional>',
      SizingPolicy: '<optional>',
      PaddingPolicy: '<optional>',
      Watermarks: [
        {
          Id: '<optional>',
          MaxWidth: '<optional>',
          MaxHeight: '<optional>',
          SizingPolicy: '<optional>',
          HorizontalAlign: '<optional>',
          HorizontalOffset: '<optional>',
          VerticalAlign: '<optional>',
          VerticalOffset: '<optional>',
          Opacity: '<optional>',
          Target: '<optional>',
        },
      ],
    },
    Audio: [
      {
        Codec: '<optional>',
        SampleRate: '<optional>',
        BitRate: '<optional>',
        Channels: ['<optional1>', '<optional2>'],
        AudioPackingMode: '<optional>',
        CodecOptions: [
          {
            Profile: '<optional>',
            BitDepth: '<optional>',
            BitOrder: '<optional>',
            Signed: '<optional>',
          },
        ],
      },
    ],
    Thumbnails: [
      {
        Format: '<optional>',
        Interval: '<optional>',
        Resolution: '<optional>',
        AspectRatio: '<optional>',
        MaxWidth: '<optional>',
        MaxHeight: '<optional>',
        SizingPolicy: '<optional>',
        PaddingPolicy: '<optional>',
      },
    ],
  },
  'ElasticTranscoder:deletePipeline': {
    Id: 'required',
  },
  'ElasticTranscoder:deletePreset': {
    Id: 'required',
  },
  'ElasticTranscoder:listJobsByPipeline': {
    PipelineId: 'required',
    Ascending: '<optional>',
    PageToken: '<optional>',
  },
  'ElasticTranscoder:listJobsByStatus': {
    Status: 'required',
    Ascending: '<optional>',
    PageToken: '<optional>',
  },
  'ElasticTranscoder:listPipelines': {
    Ascending: '<optional>',
    PageToken: '<optional>',
  },
  'ElasticTranscoder:listPresets': {
    Ascending: '<optional>',
    PageToken: '<optional>',
  },
  'ElasticTranscoder:readJob': {
    Id: 'required',
  },
  'ElasticTranscoder:readPipeline': {
    Id: 'required',
  },
  'ElasticTranscoder:readPreset': {
    Id: 'required',
  },
  'ElasticTranscoder:testRole': {
    Role: 'required',
    InputBucket: 'required',
    OutputBucket: 'required',
    Topics: ['required1', 'required2'],
  },
  'ElasticTranscoder:updatePipeline': {
    Id: 'required',
    Name: '<optional>',
    InputBucket: '<optional>',
    Role: '<optional>',
    AwsKmsKeyArn: '<optional>',
    Notifications: [
      {
        Progressing: '<optional>',
        Completed: '<optional>',
        Warning: '<optional>',
        Error: '<optional>',
      },
    ],
    ContentConfig: {
      Bucket: '<optional>',
      StorageClass: '<optional>',
      Permissions: [
        {
          GranteeType: '<optional>',
          Grantee: '<optional>',
          Access: '<optional>',
        },
      ],
    },
    ThumbnailConfig: {
      Bucket: '<optional>',
      StorageClass: '<optional>',
      Permissions: [
        {
          GranteeType: '<optional>',
          Grantee: '<optional>',
          Access: '<optional>',
        },
      ],
    },
  },
  'ElasticTranscoder:updatePipelineNotifications': {
    Id: 'required',
    Notifications: [
      {
        Progressing: '<optional>',
        Completed: '<optional>',
        Warning: '<optional>',
        Error: '<optional>',
      },
    ],
  },
  'ElasticTranscoder:updatePipelineStatus': {
    Id: 'required',
    Status: 'required',
  },
  'EventBridge:activateEventSource': {
    Name: 'required',
  },
  'EventBridge:createEventBus': {
    Name: 'required',
    EventSourceName: '<optional>',
  },
  'EventBridge:createPartnerEventSource': {
    Name: 'required',
    Account: 'required',
  },
  'EventBridge:deactivateEventSource': {
    Name: 'required',
  },
  'EventBridge:deleteEventBus': {
    Name: 'required',
  },
  'EventBridge:deletePartnerEventSource': {
    Name: 'required',
    Account: 'required',
  },
  'EventBridge:deleteRule': {
    Name: 'required',
    EventBusName: '<optional>',
    Force: '<optional>',
  },
  'EventBridge:describeEventBus': {
    Name: '<optional>',
  },
  'EventBridge:describeEventSource': {
    Name: 'required',
  },
  'EventBridge:describePartnerEventSource': {
    Name: 'required',
  },
  'EventBridge:describeRule': {
    Name: 'required',
    EventBusName: '<optional>',
  },
  'EventBridge:disableRule': {
    Name: 'required',
    EventBusName: '<optional>',
  },
  'EventBridge:enableRule': {
    Name: 'required',
    EventBusName: '<optional>',
  },
  'EventBridge:listEventBuses': {
    NamePrefix: '<optional>',
    Limit: '<optional>',
  },
  'EventBridge:listEventSources': {
    NamePrefix: '<optional>',
    Limit: '<optional>',
  },
  'EventBridge:listPartnerEventSourceAccounts': {
    EventSourceName: 'required',
    Limit: '<optional>',
  },
  'EventBridge:listPartnerEventSources': {
    NamePrefix: 'required',
    Limit: '<optional>',
  },
  'EventBridge:listRuleNamesByTarget': {
    TargetArn: 'required',
    EventBusName: '<optional>',
    Limit: '<optional>',
  },
  'EventBridge:listRules': {
    NamePrefix: '<optional>',
    EventBusName: '<optional>',
    Limit: '<optional>',
  },
  'EventBridge:listTagsForResource': {
    ResourceARN: 'required',
  },
  'EventBridge:listTargetsByRule': {
    Rule: 'required',
    EventBusName: '<optional>',
    Limit: '<optional>',
  },
  'EventBridge:putEvents': {
    Entries: [
      {
        Time: '<optional>',
        Source: '<optional>',
        Resources: ['<optional1>', '<optional2>'],
        DetailType: '<optional>',
        Detail: '<optional>',
        EventBusName: '<optional>',
      },
    ],
  },
  'EventBridge:putPartnerEvents': {
    Entries: [
      {
        Time: '<optional>',
        Source: '<optional>',
        Resources: ['<optional1>', '<optional2>'],
        DetailType: '<optional>',
        Detail: '<optional>',
      },
    ],
  },
  'EventBridge:putPermission': {
    Action: 'required',
    Principal: 'required',
    StatementId: 'required',
    Condition: '<optional>',
    EventBusName: '<optional>',
  },
  'EventBridge:putRule': {
    Name: 'required',
    Tags: ['<optional1>', '<optional2>'],
    ScheduleExpression: '<optional>',
    EventPattern: '<optional>',
    State: '<optional>',
    Description: '<optional>',
    RoleArn: '<optional>',
    EventBusName: '<optional>',
  },
  'EventBridge:putTargets': {
    Rule: 'required',
    Targets: [
      {
        RoleArn: '<optional>',
        Input: '<optional>',
        InputPath: '<optional>',
        InputTransformer: {
          InputPathsMap: '<optional>',
        },
        KinesisParameters: ['<optional1>', '<optional2>'],
        RunCommandParameters: ['<optional1>', '<optional2>'],
        EcsParameters: [
          {
            TaskCount: '<optional>',
            LaunchType: '<optional>',
            NetworkConfiguration: {
              awsvpcConfiguration: {
                SecurityGroups: ['<optional1>', '<optional2>'],
                AssignPublicIp: '<optional>',
              },
            },
            PlatformVersion: '<optional>',
            Group: '<optional>',
          },
        ],
        BatchParameters: [
          {
            ArrayProperties: [
              {
                Size: '<optional>',
              },
            ],
            RetryStrategy: {
              Attempts: ['<optional1>', '<optional2>'],
            },
          },
        ],
        SqsParameters: [
          {
            MessageGroupId: '<optional>',
          },
        ],
      },
    ],
    EventBusName: '<optional>',
  },
  'EventBridge:removePermission': {
    StatementId: 'required',
    EventBusName: '<optional>',
  },
  'EventBridge:removeTargets': {
    Rule: 'required',
    Ids: ['required1', 'required2'],
    EventBusName: '<optional>',
    Force: '<optional>',
  },
  'EventBridge:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'EventBridge:testEventPattern': {
    EventPattern: 'required',
    Event: 'required',
  },
  'EventBridge:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'FMS:associateAdminAccount': {
    AdminAccount: 'required',
  },
  'FMS:deleteNotificationChannel': {},
  'FMS:deletePolicy': {
    PolicyId: 'required',
    DeleteAllPolicyResources: ['<optional1>', '<optional2>'],
  },
  'FMS:disassociateAdminAccount': {},
  'FMS:getAdminAccount': {},
  'FMS:getComplianceDetail': {
    PolicyId: 'required',
    MemberAccount: 'required',
  },
  'FMS:getNotificationChannel': {},
  'FMS:getPolicy': {
    PolicyId: 'required',
  },
  'FMS:getProtectionStatus': {
    PolicyId: 'required',
    MemberAccountId: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
  },
  'FMS:listComplianceStatus': {
    PolicyId: 'required',
  },
  'FMS:listMemberAccounts': {},
  'FMS:listPolicies': {},
  'FMS:putNotificationChannel': {
    SnsTopicArn: 'required',
    SnsRoleName: 'required',
  },
  'FMS:putPolicy': {
    Policy: {
      PolicyId: '<optional>',
      PolicyUpdateToken: '<optional>',
      ResourceTypeList: '<optional>',
      ResourceTags: [
        {
          Value: '<optional>',
        },
      ],
      IncludeMap: '<optional>',
      ExcludeMap: '<optional>',
      SecurityServicePolicyData: [
        {
          ManagedServiceData: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'FSx:cancelDataRepositoryTask': {
    TaskId: 'required',
  },
  'FSx:createBackup': {
    FileSystemId: 'required',
    ClientRequestToken: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'FSx:createDataRepositoryTask': {
    Type: 'required',
    FileSystemId: 'required',
    Report: {
      Path: '<optional>',
      Format: '<optional>',
      Scope: '<optional>',
    },
    Paths: ['<optional1>', '<optional2>'],
    ClientRequestToken: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'FSx:createFileSystem': {
    FileSystemType: 'required',
    StorageCapacity: 'required',
    SubnetIds: ['required1', 'required2'],
    WindowsConfiguration: {
      ActiveDirectoryId: '<optional>',
      SelfManagedActiveDirectoryConfiguration: {
        OrganizationalUnitDistinguishedName: '<optional>',
        FileSystemAdministratorsGroup: '<optional>',
      },
      WeeklyMaintenanceStartTime: '<optional>',
      DailyAutomaticBackupStartTime: '<optional>',
      AutomaticBackupRetentionDays: ['<optional1>', '<optional2>'],
      CopyTagsToBackups: ['<optional1>', '<optional2>'],
    },
    ClientRequestToken: '<optional>',
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    LustreConfiguration: {
      WeeklyMaintenanceStartTime: '<optional>',
      ImportPath: '<optional>',
      ExportPath: '<optional>',
      ImportedFileChunkSize: '<optional>',
    },
  },
  'FSx:createFileSystemFromBackup': {
    BackupId: 'required',
    SubnetIds: ['required1', 'required2'],
    WindowsConfiguration: {
      ActiveDirectoryId: '<optional>',
      SelfManagedActiveDirectoryConfiguration: {
        OrganizationalUnitDistinguishedName: '<optional>',
        FileSystemAdministratorsGroup: '<optional>',
      },
      WeeklyMaintenanceStartTime: '<optional>',
      DailyAutomaticBackupStartTime: '<optional>',
      AutomaticBackupRetentionDays: ['<optional1>', '<optional2>'],
      CopyTagsToBackups: ['<optional1>', '<optional2>'],
    },
    ClientRequestToken: '<optional>',
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'FSx:deleteBackup': {
    BackupId: 'required',
    ClientRequestToken: '<optional>',
  },
  'FSx:deleteFileSystem': {
    FileSystemId: 'required',
    ClientRequestToken: '<optional>',
    WindowsConfiguration: {
      SkipFinalBackup: '<optional>',
      FinalBackupTags: [
        {
          Key: '<optional>',
          Value: '<optional>',
        },
      ],
    },
  },
  'FSx:describeBackups': {
    BackupIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'FSx:describeDataRepositoryTasks': {
    TaskIds: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'FSx:describeFileSystems': {
    FileSystemIds: ['<optional1>', '<optional2>'],
  },
  'FSx:listTagsForResource': {
    ResourceARN: 'required',
  },
  'FSx:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'FSx:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'FSx:updateFileSystem': {
    FileSystemId: 'required',
    ClientRequestToken: '<optional>',
    WindowsConfiguration: {
      WeeklyMaintenanceStartTime: '<optional>',
      DailyAutomaticBackupStartTime: '<optional>',
      AutomaticBackupRetentionDays: ['<optional1>', '<optional2>'],
      SelfManagedActiveDirectoryConfiguration: {
        UserName: '<optional>',
        Password: '<optional>',
        DnsIps: ['<optional1>', '<optional2>'],
      },
    },
    LustreConfiguration: {
      WeeklyMaintenanceStartTime: '<optional>',
    },
  },
  'Firehose:createDeliveryStream': {
    DeliveryStreamName: 'required',
    KinesisStreamSourceConfiguration: '<optional>',
    S3DestinationConfiguration: {
      Prefix: '<optional>',
      ErrorOutputPrefix: '<optional>',
      BufferingHints: [
        {
          SizeInMBs: ['<optional1>', '<optional2>'],
          IntervalInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      CompressionFormat: '<optional>',
      EncryptionConfiguration: {
        NoEncryptionConfig: '<optional>',
        KMSEncryptionConfig: '<optional>',
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
    },
    ExtendedS3DestinationConfiguration: {
      Prefix: '<optional>',
      ErrorOutputPrefix: '<optional>',
      BufferingHints: [
        {
          SizeInMBs: ['<optional1>', '<optional2>'],
          IntervalInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      CompressionFormat: '<optional>',
      EncryptionConfiguration: {
        NoEncryptionConfig: '<optional>',
        KMSEncryptionConfig: '<optional>',
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
      ProcessingConfiguration: {
        Enabled: '<optional>',
        Processors: [
          {
            Parameters: ['<optional1>', '<optional2>'],
          },
        ],
      },
      S3BackupMode: '<optional>',
      S3BackupConfiguration: {
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
      DataFormatConversionConfiguration: {
        SchemaConfiguration: {
          RoleARN: '<optional>',
          CatalogId: '<optional>',
          DatabaseName: '<optional>',
          TableName: '<optional>',
          Region: '<optional>',
          VersionId: '<optional>',
        },
        InputFormatConfiguration: {
          Deserializer: {
            OpenXJsonSerDe: {
              ConvertDotsInJsonKeysToUnderscores: [
                '<optional1>',
                '<optional2>',
              ],
              CaseInsensitive: '<optional>',
              ColumnToJsonKeyMappings: ['<optional1>', '<optional2>'],
            },
            HiveJsonSerDe: {
              TimestampFormats: ['<optional1>', '<optional2>'],
            },
          },
        },
        OutputFormatConfiguration: {
          Serializer: {
            ParquetSerDe: {
              BlockSizeBytes: ['<optional1>', '<optional2>'],
              PageSizeBytes: ['<optional1>', '<optional2>'],
              Compression: '<optional>',
              EnableDictionaryCompression: '<optional>',
              MaxPaddingBytes: ['<optional1>', '<optional2>'],
              WriterVersion: '<optional>',
            },
            OrcSerDe: {
              StripeSizeBytes: ['<optional1>', '<optional2>'],
              BlockSizeBytes: ['<optional1>', '<optional2>'],
              RowIndexStride: '<optional>',
              EnablePadding: '<optional>',
              PaddingTolerance: '<optional>',
              Compression: '<optional>',
              BloomFilterColumns: ['<optional1>', '<optional2>'],
              BloomFilterFalsePositiveProbability: '<optional>',
              DictionaryKeyThreshold: '<optional>',
              FormatVersion: '<optional>',
            },
          },
        },
        Enabled: '<optional>',
      },
    },
    RedshiftDestinationConfiguration: {
      RetryOptions: [
        {
          DurationInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      ProcessingConfiguration: {
        Enabled: '<optional>',
        Processors: [
          {
            Parameters: ['<optional1>', '<optional2>'],
          },
        ],
      },
      S3BackupMode: '<optional>',
      S3BackupConfiguration: {
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
      CopyCommand: {
        DataTableColumns: ['<optional1>', '<optional2>'],
        CopyOptions: ['<optional1>', '<optional2>'],
      },
      S3Configuration: {
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
    },
    ElasticsearchDestinationConfiguration: {
      IndexRotationPeriod: '<optional>',
      BufferingHints: [
        {
          IntervalInSeconds: ['<optional1>', '<optional2>'],
          SizeInMBs: ['<optional1>', '<optional2>'],
        },
      ],
      RetryOptions: [
        {
          DurationInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      S3BackupMode: '<optional>',
      ProcessingConfiguration: {
        Enabled: '<optional>',
        Processors: [
          {
            Parameters: ['<optional1>', '<optional2>'],
          },
        ],
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
      S3Configuration: {
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
    },
    SplunkDestinationConfiguration: {
      HECAcknowledgmentTimeoutInSeconds: ['<optional1>', '<optional2>'],
      RetryOptions: [
        {
          DurationInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      S3BackupMode: '<optional>',
      ProcessingConfiguration: {
        Enabled: '<optional>',
        Processors: [
          {
            Parameters: ['<optional1>', '<optional2>'],
          },
        ],
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
      S3Configuration: {
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
    },
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    DeliveryStreamType: '<optional>',
  },
  'Firehose:deleteDeliveryStream': {
    DeliveryStreamName: 'required',
  },
  'Firehose:describeDeliveryStream': {
    DeliveryStreamName: 'required',
    Limit: '<optional>',
    ExclusiveStartDestinationId: '<optional>',
  },
  'Firehose:getKinesisStream': {
    DeliveryStreamARN: 'required',
  },
  'Firehose:listDeliveryStreams': {
    Limit: '<optional>',
    DeliveryStreamType: '<optional>',
    ExclusiveStartDeliveryStreamName: '<optional>',
  },
  'Firehose:listTagsForDeliveryStream': {
    DeliveryStreamName: 'required',
    ExclusiveStartTagKey: '<optional>',
    Limit: '<optional>',
  },
  'Firehose:putRecord': {
    DeliveryStreamName: 'required',
    Record: {
      Data: ['required1', 'required2'],
    },
  },
  'Firehose:putRecordBatch': {
    DeliveryStreamName: 'required',
    Records: [
      {
        Data: ['required1', 'required2'],
      },
    ],
  },
  'Firehose:startDeliveryStreamEncryption': {
    DeliveryStreamName: 'required',
  },
  'Firehose:stopDeliveryStreamEncryption': {
    DeliveryStreamName: 'required',
  },
  'Firehose:tagDeliveryStream': {
    DeliveryStreamName: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'Firehose:untagDeliveryStream': {
    DeliveryStreamName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Firehose:updateDestination': {
    DeliveryStreamName: 'required',
    CurrentDeliveryStreamVersionId: 'required',
    DestinationId: 'required',
    S3DestinationUpdate: {
      RoleARN: '<optional>',
      BucketARN: '<optional>',
      Prefix: '<optional>',
      ErrorOutputPrefix: '<optional>',
      BufferingHints: [
        {
          SizeInMBs: ['<optional1>', '<optional2>'],
          IntervalInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      CompressionFormat: '<optional>',
      EncryptionConfiguration: {
        NoEncryptionConfig: '<optional>',
        KMSEncryptionConfig: '<optional>',
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
    },
    ExtendedS3DestinationUpdate: {
      RoleARN: '<optional>',
      BucketARN: '<optional>',
      Prefix: '<optional>',
      ErrorOutputPrefix: '<optional>',
      BufferingHints: [
        {
          SizeInMBs: ['<optional1>', '<optional2>'],
          IntervalInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      CompressionFormat: '<optional>',
      EncryptionConfiguration: {
        NoEncryptionConfig: '<optional>',
        KMSEncryptionConfig: '<optional>',
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
      ProcessingConfiguration: {
        Enabled: '<optional>',
        Processors: [
          {
            Parameters: ['<optional1>', '<optional2>'],
          },
        ],
      },
      S3BackupMode: '<optional>',
      S3BackupUpdate: {
        RoleARN: '<optional>',
        BucketARN: '<optional>',
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
      DataFormatConversionConfiguration: {
        SchemaConfiguration: {
          RoleARN: '<optional>',
          CatalogId: '<optional>',
          DatabaseName: '<optional>',
          TableName: '<optional>',
          Region: '<optional>',
          VersionId: '<optional>',
        },
        InputFormatConfiguration: {
          Deserializer: {
            OpenXJsonSerDe: {
              ConvertDotsInJsonKeysToUnderscores: [
                '<optional1>',
                '<optional2>',
              ],
              CaseInsensitive: '<optional>',
              ColumnToJsonKeyMappings: ['<optional1>', '<optional2>'],
            },
            HiveJsonSerDe: {
              TimestampFormats: ['<optional1>', '<optional2>'],
            },
          },
        },
        OutputFormatConfiguration: {
          Serializer: {
            ParquetSerDe: {
              BlockSizeBytes: ['<optional1>', '<optional2>'],
              PageSizeBytes: ['<optional1>', '<optional2>'],
              Compression: '<optional>',
              EnableDictionaryCompression: '<optional>',
              MaxPaddingBytes: ['<optional1>', '<optional2>'],
              WriterVersion: '<optional>',
            },
            OrcSerDe: {
              StripeSizeBytes: ['<optional1>', '<optional2>'],
              BlockSizeBytes: ['<optional1>', '<optional2>'],
              RowIndexStride: '<optional>',
              EnablePadding: '<optional>',
              PaddingTolerance: '<optional>',
              Compression: '<optional>',
              BloomFilterColumns: ['<optional1>', '<optional2>'],
              BloomFilterFalsePositiveProbability: '<optional>',
              DictionaryKeyThreshold: '<optional>',
              FormatVersion: '<optional>',
            },
          },
        },
        Enabled: '<optional>',
      },
    },
    RedshiftDestinationUpdate: {
      RoleARN: '<optional>',
      ClusterJDBCURL: '<optional>',
      CopyCommand: {
        DataTableColumns: ['<optional1>', '<optional2>'],
        CopyOptions: ['<optional1>', '<optional2>'],
      },
      Username: '<optional>',
      Password: '<optional>',
      RetryOptions: [
        {
          DurationInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      S3Update: {
        RoleARN: '<optional>',
        BucketARN: '<optional>',
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
      ProcessingConfiguration: {
        Enabled: '<optional>',
        Processors: [
          {
            Parameters: ['<optional1>', '<optional2>'],
          },
        ],
      },
      S3BackupMode: '<optional>',
      S3BackupUpdate: {
        RoleARN: '<optional>',
        BucketARN: '<optional>',
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
    },
    ElasticsearchDestinationUpdate: {
      RoleARN: '<optional>',
      DomainARN: '<optional>',
      IndexName: '<optional>',
      TypeName: '<optional>',
      IndexRotationPeriod: '<optional>',
      BufferingHints: [
        {
          IntervalInSeconds: ['<optional1>', '<optional2>'],
          SizeInMBs: ['<optional1>', '<optional2>'],
        },
      ],
      RetryOptions: [
        {
          DurationInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      S3Update: {
        RoleARN: '<optional>',
        BucketARN: '<optional>',
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
      ProcessingConfiguration: {
        Enabled: '<optional>',
        Processors: [
          {
            Parameters: ['<optional1>', '<optional2>'],
          },
        ],
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
    },
    SplunkDestinationUpdate: {
      HECEndpoint: '<optional>',
      HECEndpointType: '<optional>',
      HECToken: '<optional>',
      HECAcknowledgmentTimeoutInSeconds: ['<optional1>', '<optional2>'],
      RetryOptions: [
        {
          DurationInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      S3BackupMode: '<optional>',
      S3Update: {
        RoleARN: '<optional>',
        BucketARN: '<optional>',
        Prefix: '<optional>',
        ErrorOutputPrefix: '<optional>',
        BufferingHints: [
          {
            SizeInMBs: ['<optional1>', '<optional2>'],
            IntervalInSeconds: ['<optional1>', '<optional2>'],
          },
        ],
        CompressionFormat: '<optional>',
        EncryptionConfiguration: {
          NoEncryptionConfig: '<optional>',
          KMSEncryptionConfig: '<optional>',
        },
        CloudWatchLoggingOptions: [
          {
            Enabled: '<optional>',
            LogGroupName: '<optional>',
            LogStreamName: '<optional>',
          },
        ],
      },
      ProcessingConfiguration: {
        Enabled: '<optional>',
        Processors: [
          {
            Parameters: ['<optional1>', '<optional2>'],
          },
        ],
      },
      CloudWatchLoggingOptions: [
        {
          Enabled: '<optional>',
          LogGroupName: '<optional>',
          LogStreamName: '<optional>',
        },
      ],
    },
  },
  'ForecastQueryService:queryForecast': {
    ForecastArn: 'required',
    Filters: ['required1', 'required2'],
    StartDate: '<optional>',
    EndDate: '<optional>',
  },
  'ForecastService:createDataset': {
    DatasetName: 'required',
    Domain: 'required',
    DatasetType: 'required',
    Schema: {
      Attributes: [
        {
          AttributeName: '<optional>',
          AttributeType: '<optional>',
        },
      ],
    },
    EncryptionConfig: '<optional>',
    DataFrequency: '<optional>',
  },
  'ForecastService:createDatasetGroup': {
    DatasetGroupName: 'required',
    Domain: 'required',
    DatasetArns: ['<optional1>', '<optional2>'],
  },
  'ForecastService:createDatasetImportJob': {
    DatasetImportJobName: 'required',
    DatasetArn: 'required',
    DataSource: {
      S3Config: {
        KMSKeyArn: '<optional>',
      },
    },
    TimestampFormat: '<optional>',
  },
  'ForecastService:createForecast': {
    ForecastName: 'required',
    PredictorArn: 'required',
    ForecastTypes: ['<optional1>', '<optional2>'],
  },
  'ForecastService:createForecastExportJob': {
    ForecastExportJobName: 'required',
    ForecastArn: 'required',
    Destination: {
      S3Config: {
        KMSKeyArn: '<optional>',
      },
    },
  },
  'ForecastService:createPredictor': {
    PredictorName: 'required',
    ForecastHorizon: 'required',
    InputDataConfig: {
      SupplementaryFeatures: ['<optional1>', '<optional2>'],
    },
    FeaturizationConfig: {
      ForecastDimensions: ['<optional1>', '<optional2>'],
      Featurizations: [
        {
          FeaturizationPipeline: {
            FeaturizationMethodParameters: ['<optional1>', '<optional2>'],
          },
        },
      ],
    },
    HPOConfig: {
      ParameterRanges: [
        {
          CategoricalParameterRanges: ['<optional1>', '<optional2>'],
          ContinuousParameterRanges: [
            {
              ScalingType: '<optional>',
            },
          ],
          IntegerParameterRanges: [
            {
              ScalingType: '<optional>',
            },
          ],
        },
      ],
    },
    EncryptionConfig: '<optional>',
    AlgorithmArn: '<optional>',
    PerformAutoML: '<optional>',
    PerformHPO: '<optional>',
    TrainingParameters: ['<optional1>', '<optional2>'],
    EvaluationParameters: [
      {
        NumberOfBacktestWindows: ['<optional1>', '<optional2>'],
        BackTestWindowOffset: '<optional>',
      },
    ],
  },
  'ForecastService:deleteDataset': {
    DatasetArn: 'required',
  },
  'ForecastService:deleteDatasetGroup': {
    DatasetGroupArn: 'required',
  },
  'ForecastService:deleteDatasetImportJob': {
    DatasetImportJobArn: 'required',
  },
  'ForecastService:deleteForecast': {
    ForecastArn: 'required',
  },
  'ForecastService:deleteForecastExportJob': {
    ForecastExportJobArn: 'required',
  },
  'ForecastService:deletePredictor': {
    PredictorArn: 'required',
  },
  'ForecastService:describeDataset': {
    DatasetArn: 'required',
  },
  'ForecastService:describeDatasetGroup': {
    DatasetGroupArn: 'required',
  },
  'ForecastService:describeDatasetImportJob': {
    DatasetImportJobArn: 'required',
  },
  'ForecastService:describeForecast': {
    ForecastArn: 'required',
  },
  'ForecastService:describeForecastExportJob': {
    ForecastExportJobArn: 'required',
  },
  'ForecastService:describePredictor': {
    PredictorArn: 'required',
  },
  'ForecastService:getAccuracyMetrics': {
    PredictorArn: 'required',
  },
  'ForecastService:listDatasetGroups': {},
  'ForecastService:listDatasetImportJobs': {
    Filters: ['<optional1>', '<optional2>'],
  },
  'ForecastService:listDatasets': {},
  'ForecastService:listForecastExportJobs': {
    Filters: ['<optional1>', '<optional2>'],
  },
  'ForecastService:listForecasts': {
    Filters: ['<optional1>', '<optional2>'],
  },
  'ForecastService:listPredictors': {
    Filters: ['<optional1>', '<optional2>'],
  },
  'ForecastService:updateDatasetGroup': {
    DatasetGroupArn: 'required',
    DatasetArns: ['required1', 'required2'],
  },
  'FraudDetector:batchCreateVariable': {
    variableEntries: [
      {
        name: '<optional>',
        dataType: '<optional>',
        dataSource: '<optional>',
        defaultValue: '<optional>',
        description: '<optional>',
        variableType: '<optional>',
      },
    ],
  },
  'FraudDetector:batchGetVariable': {
    names: ['required1', 'required2'],
  },
  'FraudDetector:createDetectorVersion': {
    detectorId: 'required',
    rules: [
      {
        detectorId: 'required',
        ruleId: 'required',
        ruleVersion: 'required',
      },
    ],
    modelVersions: ['<optional1>', '<optional2>'],
    description: '<optional>',
    externalModelEndpoints: ['<optional1>', '<optional2>'],
  },
  'FraudDetector:createModelVersion': {
    modelId: 'required',
    modelType: 'required',
    description: '<optional>',
  },
  'FraudDetector:createRule': {
    ruleId: 'required',
    detectorId: 'required',
    expression: 'required',
    language: 'required',
    outcomes: ['required1', 'required2'],
    description: '<optional>',
  },
  'FraudDetector:createVariable': {
    name: 'required',
    dataType: 'required',
    dataSource: 'required',
    defaultValue: 'required',
    description: '<optional>',
    variableType: '<optional>',
  },
  'FraudDetector:deleteDetectorVersion': {
    detectorId: 'required',
    detectorVersionId: 'required',
  },
  'FraudDetector:deleteEvent': {
    eventId: 'required',
  },
  'FraudDetector:describeDetector': {
    detectorId: 'required',
  },
  'FraudDetector:describeModelVersions': {
    modelId: '<optional>',
    modelVersionNumber: '<optional>',
    modelType: '<optional>',
  },
  'FraudDetector:getDetectorVersion': {
    detectorId: 'required',
    detectorVersionId: 'required',
  },
  'FraudDetector:getDetectors': {
    detectorId: '<optional>',
  },
  'FraudDetector:getExternalModels': {
    modelEndpoint: '<optional>',
  },
  'FraudDetector:getModelVersion': {
    modelId: 'required',
    modelType: 'required',
    modelVersionNumber: 'required',
  },
  'FraudDetector:getModels': {
    modelType: '<optional>',
    modelId: '<optional>',
  },
  'FraudDetector:getOutcomes': {
    name: '<optional>',
  },
  'FraudDetector:getPrediction': {
    detectorId: 'required',
    eventId: 'required',
    detectorVersionId: '<optional>',
    eventAttributes: ['<optional1>', '<optional2>'],
    externalModelEndpointDataBlobs: ['<optional1>', '<optional2>'],
  },
  'FraudDetector:getRules': {
    detectorId: 'required',
    ruleId: '<optional>',
    ruleVersion: '<optional>',
  },
  'FraudDetector:getVariables': {
    name: '<optional>',
  },
  'FraudDetector:putDetector': {
    detectorId: 'required',
    description: '<optional>',
  },
  'FraudDetector:putExternalModel': {
    modelEndpoint: 'required',
    modelSource: 'required',
    role: {
      arn: 'required',
      name: 'required',
    },
    inputConfiguration: {
      format: '<optional>',
      jsonInputTemplate: '<optional>',
      csvInputTemplate: '<optional>',
    },
    outputConfiguration: {
      jsonKeyToVariableMap: '<optional>',
      csvIndexToVariableMap: '<optional>',
    },
    modelEndpointStatus: 'required',
  },
  'FraudDetector:putModel': {
    modelId: 'required',
    modelType: 'required',
    trainingDataSource: {
      dataLocation: 'required',
      dataAccessRoleArn: 'required',
    },
    modelVariables: [
      {
        index: '<optional>',
      },
    ],
    labelSchema: {
      labelKey: 'required',
      labelMapper: 'required',
    },
    description: '<optional>',
  },
  'FraudDetector:putOutcome': {
    name: 'required',
    description: '<optional>',
  },
  'FraudDetector:updateDetectorVersion': {
    detectorId: 'required',
    detectorVersionId: 'required',
    externalModelEndpoints: ['required1', 'required2'],
    rules: [
      {
        detectorId: 'required',
        ruleId: 'required',
        ruleVersion: 'required',
      },
    ],
    modelVersions: ['<optional1>', '<optional2>'],
    description: '<optional>',
  },
  'FraudDetector:updateDetectorVersionMetadata': {
    detectorId: 'required',
    detectorVersionId: 'required',
    description: 'required',
  },
  'FraudDetector:updateDetectorVersionStatus': {
    detectorId: 'required',
    detectorVersionId: 'required',
    status: 'required',
  },
  'FraudDetector:updateModelVersion': {
    modelId: 'required',
    modelType: 'required',
    modelVersionNumber: 'required',
    description: 'required',
    status: 'required',
  },
  'FraudDetector:updateRuleMetadata': {
    rule: {
      detectorId: 'required',
      ruleId: 'required',
      ruleVersion: 'required',
    },
    description: 'required',
  },
  'FraudDetector:updateRuleVersion': {
    rule: {
      detectorId: 'required',
      ruleId: 'required',
      ruleVersion: 'required',
    },
    expression: 'required',
    language: 'required',
    outcomes: ['required1', 'required2'],
    description: '<optional>',
  },
  'FraudDetector:updateVariable': {
    name: 'required',
    defaultValue: '<optional>',
    description: '<optional>',
    variableType: '<optional>',
  },
  'GameLift:acceptMatch': {
    TicketId: 'required',
    PlayerIds: ['required1', 'required2'],
    AcceptanceType: 'required',
  },
  'GameLift:createAlias': {
    Name: 'required',
    RoutingStrategy: {
      Type: '<optional>',
      FleetId: '<optional>',
      Message: '<optional>',
    },
    Description: '<optional>',
  },
  'GameLift:createBuild': {
    Name: '<optional>',
    Version: '<optional>',
    StorageLocation: {
      Bucket: '<optional>',
      Key: '<optional>',
      RoleArn: '<optional>',
      ObjectVersion: '<optional>',
    },
    OperatingSystem: '<optional>',
  },
  'GameLift:createFleet': {
    Name: 'required',
    EC2InstanceType: 'required',
    EC2InboundPermissions: ['<optional1>', '<optional2>'],
    RuntimeConfiguration: {
      ServerProcesses: [
        {
          Parameters: ['<optional1>', '<optional2>'],
        },
      ],
      MaxConcurrentGameSessionActivations: ['<optional1>', '<optional2>'],
      GameSessionActivationTimeoutSeconds: ['<optional1>', '<optional2>'],
    },
    Description: '<optional>',
    BuildId: '<optional>',
    ScriptId: '<optional>',
    ServerLaunchPath: '<optional>',
    ServerLaunchParameters: ['<optional1>', '<optional2>'],
    LogPaths: ['<optional1>', '<optional2>'],
    NewGameSessionProtectionPolicy: '<optional>',
    ResourceCreationLimitPolicy: {
      NewGameSessionsPerCreator: '<optional>',
      PolicyPeriodInMinutes: ['<optional1>', '<optional2>'],
    },
    MetricGroups: ['<optional1>', '<optional2>'],
    PeerVpcAwsAccountId: '<optional>',
    PeerVpcId: '<optional>',
    FleetType: '<optional>',
    InstanceRoleArn: '<optional>',
  },
  'GameLift:createGameSession': {
    MaximumPlayerSessionCount: 'required',
    GameProperties: ['<optional1>', '<optional2>'],
    FleetId: '<optional>',
    AliasId: '<optional>',
    Name: '<optional>',
    CreatorId: '<optional>',
    GameSessionId: '<optional>',
    IdempotencyToken: '<optional>',
    GameSessionData: ['<optional1>', '<optional2>'],
  },
  'GameLift:createGameSessionQueue': {
    Name: 'required',
    TimeoutInSeconds: ['<optional1>', '<optional2>'],
    PlayerLatencyPolicies: [
      {
        MaximumIndividualPlayerLatencyMilliseconds: [
          '<optional1>',
          '<optional2>',
        ],
        PolicyDurationSeconds: ['<optional1>', '<optional2>'],
      },
    ],
    Destinations: [
      {
        DestinationArn: '<optional>',
      },
    ],
  },
  'GameLift:createMatchmakingConfiguration': {
    Name: 'required',
    GameSessionQueueArns: ['required1', 'required2'],
    RequestTimeoutSeconds: ['required1', 'required2'],
    AcceptanceRequired: 'required',
    RuleSetName: 'required',
    GameProperties: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    AcceptanceTimeoutSeconds: ['<optional1>', '<optional2>'],
    NotificationTarget: '<optional>',
    AdditionalPlayerCount: '<optional>',
    CustomEventData: ['<optional1>', '<optional2>'],
    GameSessionData: ['<optional1>', '<optional2>'],
    BackfillMode: '<optional>',
  },
  'GameLift:createMatchmakingRuleSet': {
    Name: 'required',
    RuleSetBody: 'required',
  },
  'GameLift:createPlayerSession': {
    GameSessionId: 'required',
    PlayerId: 'required',
    PlayerData: ['<optional1>', '<optional2>'],
  },
  'GameLift:createPlayerSessions': {
    GameSessionId: 'required',
    PlayerIds: ['required1', 'required2'],
    PlayerDataMap: '<optional>',
  },
  'GameLift:createScript': {
    Name: '<optional>',
    Version: '<optional>',
    StorageLocation: {
      Bucket: '<optional>',
      Key: '<optional>',
      RoleArn: '<optional>',
      ObjectVersion: '<optional>',
    },
    ZipFile: '<optional>',
  },
  'GameLift:createVpcPeeringAuthorization': {
    GameLiftAwsAccountId: 'required',
    PeerVpcId: 'required',
  },
  'GameLift:createVpcPeeringConnection': {
    FleetId: 'required',
    PeerVpcAwsAccountId: 'required',
    PeerVpcId: 'required',
  },
  'GameLift:deleteAlias': {
    AliasId: 'required',
  },
  'GameLift:deleteBuild': {
    BuildId: 'required',
  },
  'GameLift:deleteFleet': {
    FleetId: 'required',
  },
  'GameLift:deleteGameSessionQueue': {
    Name: 'required',
  },
  'GameLift:deleteMatchmakingConfiguration': {
    Name: 'required',
  },
  'GameLift:deleteMatchmakingRuleSet': {
    Name: 'required',
  },
  'GameLift:deleteScalingPolicy': {
    Name: 'required',
    FleetId: 'required',
  },
  'GameLift:deleteScript': {
    ScriptId: 'required',
  },
  'GameLift:deleteVpcPeeringAuthorization': {
    GameLiftAwsAccountId: 'required',
    PeerVpcId: 'required',
  },
  'GameLift:deleteVpcPeeringConnection': {
    FleetId: 'required',
    VpcPeeringConnectionId: 'required',
  },
  'GameLift:describeAlias': {
    AliasId: 'required',
  },
  'GameLift:describeBuild': {
    BuildId: 'required',
  },
  'GameLift:describeEC2InstanceLimits': {
    EC2InstanceType: '<optional>',
  },
  'GameLift:describeFleetAttributes': {
    FleetIds: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'GameLift:describeFleetCapacity': {
    FleetIds: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'GameLift:describeFleetEvents': {
    FleetId: 'required',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:describeFleetPortSettings': {
    FleetId: 'required',
  },
  'GameLift:describeFleetUtilization': {
    FleetIds: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'GameLift:describeGameSessionDetails': {
    FleetId: '<optional>',
    GameSessionId: '<optional>',
    AliasId: '<optional>',
    StatusFilter: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:describeGameSessionPlacement': {
    PlacementId: 'required',
  },
  'GameLift:describeGameSessionQueues': {
    Names: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'GameLift:describeGameSessions': {
    FleetId: '<optional>',
    GameSessionId: '<optional>',
    AliasId: '<optional>',
    StatusFilter: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:describeInstances': {
    FleetId: 'required',
    InstanceId: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:describeMatchmaking': {
    TicketIds: ['required1', 'required2'],
  },
  'GameLift:describeMatchmakingConfigurations': {
    Names: ['<optional1>', '<optional2>'],
    RuleSetName: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:describeMatchmakingRuleSets': {
    Names: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'GameLift:describePlayerSessions': {
    GameSessionId: '<optional>',
    PlayerId: '<optional>',
    PlayerSessionId: '<optional>',
    PlayerSessionStatusFilter: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:describeRuntimeConfiguration': {
    FleetId: 'required',
  },
  'GameLift:describeScalingPolicies': {
    FleetId: 'required',
    StatusFilter: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:describeScript': {
    ScriptId: 'required',
  },
  'GameLift:describeVpcPeeringAuthorizations': {},
  'GameLift:describeVpcPeeringConnections': {
    FleetId: '<optional>',
  },
  'GameLift:getGameSessionLogUrl': {
    GameSessionId: 'required',
  },
  'GameLift:getInstanceAccess': {
    FleetId: 'required',
    InstanceId: 'required',
  },
  'GameLift:listAliases': {
    RoutingStrategyType: '<optional>',
    Name: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:listBuilds': {
    Status: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:listFleets': {
    BuildId: '<optional>',
    ScriptId: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:listScripts': {
    Limit: '<optional>',
  },
  'GameLift:listTagsForResource': {
    ResourceARN: 'required',
  },
  'GameLift:putScalingPolicy': {
    Name: 'required',
    FleetId: 'required',
    MetricName: 'required',
    TargetConfiguration: '<optional>',
    ScalingAdjustment: '<optional>',
    ScalingAdjustmentType: '<optional>',
    Threshold: '<optional>',
    ComparisonOperator: '<optional>',
    EvaluationPeriods: ['<optional1>', '<optional2>'],
    PolicyType: '<optional>',
  },
  'GameLift:requestUploadCredentials': {
    BuildId: 'required',
  },
  'GameLift:resolveAlias': {
    AliasId: 'required',
  },
  'GameLift:searchGameSessions': {
    FleetId: '<optional>',
    AliasId: '<optional>',
    FilterExpression: '<optional>',
    SortExpression: '<optional>',
    Limit: '<optional>',
  },
  'GameLift:startFleetActions': {
    FleetId: 'required',
    Actions: ['required1', 'required2'],
  },
  'GameLift:startGameSessionPlacement': {
    PlacementId: 'required',
    GameSessionQueueName: 'required',
    MaximumPlayerSessionCount: 'required',
    GameProperties: ['<optional1>', '<optional2>'],
    GameSessionName: '<optional>',
    PlayerLatencies: [
      {
        PlayerId: '<optional>',
        RegionIdentifier: '<optional>',
        LatencyInMilliseconds: ['<optional1>', '<optional2>'],
      },
    ],
    DesiredPlayerSessions: [
      {
        PlayerId: '<optional>',
        PlayerData: ['<optional1>', '<optional2>'],
      },
    ],
    GameSessionData: ['<optional1>', '<optional2>'],
  },
  'GameLift:startMatchBackfill': {
    ConfigurationName: 'required',
    GameSessionArn: 'required',
    Players: [
      {
        PlayerId: '<optional>',
        PlayerAttributes: ['<optional1>', '<optional2>'],
        Team: '<optional>',
        LatencyInMs: ['<optional1>', '<optional2>'],
      },
    ],
    TicketId: '<optional>',
  },
  'GameLift:startMatchmaking': {
    ConfigurationName: 'required',
    Players: [
      {
        PlayerId: '<optional>',
        PlayerAttributes: ['<optional1>', '<optional2>'],
        Team: '<optional>',
        LatencyInMs: ['<optional1>', '<optional2>'],
      },
    ],
    TicketId: '<optional>',
  },
  'GameLift:stopFleetActions': {
    FleetId: 'required',
    Actions: ['required1', 'required2'],
  },
  'GameLift:stopGameSessionPlacement': {
    PlacementId: 'required',
  },
  'GameLift:stopMatchmaking': {
    TicketId: 'required',
  },
  'GameLift:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'GameLift:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'GameLift:updateAlias': {
    AliasId: 'required',
    Name: '<optional>',
    Description: '<optional>',
    RoutingStrategy: {
      Type: '<optional>',
      FleetId: '<optional>',
      Message: '<optional>',
    },
  },
  'GameLift:updateBuild': {
    BuildId: 'required',
    Name: '<optional>',
    Version: '<optional>',
  },
  'GameLift:updateFleetAttributes': {
    FleetId: 'required',
    Name: '<optional>',
    Description: '<optional>',
    NewGameSessionProtectionPolicy: '<optional>',
    ResourceCreationLimitPolicy: {
      NewGameSessionsPerCreator: '<optional>',
      PolicyPeriodInMinutes: ['<optional1>', '<optional2>'],
    },
    MetricGroups: ['<optional1>', '<optional2>'],
  },
  'GameLift:updateFleetCapacity': {
    FleetId: 'required',
    DesiredInstances: ['<optional1>', '<optional2>'],
    MinSize: '<optional>',
    MaxSize: '<optional>',
  },
  'GameLift:updateFleetPortSettings': {
    FleetId: 'required',
    InboundPermissionAuthorizations: ['<optional1>', '<optional2>'],
    InboundPermissionRevocations: ['<optional1>', '<optional2>'],
  },
  'GameLift:updateGameSession': {
    GameSessionId: 'required',
    MaximumPlayerSessionCount: '<optional>',
    Name: '<optional>',
    PlayerSessionCreationPolicy: '<optional>',
    ProtectionPolicy: '<optional>',
  },
  'GameLift:updateGameSessionQueue': {
    Name: 'required',
    TimeoutInSeconds: ['<optional1>', '<optional2>'],
    PlayerLatencyPolicies: [
      {
        MaximumIndividualPlayerLatencyMilliseconds: [
          '<optional1>',
          '<optional2>',
        ],
        PolicyDurationSeconds: ['<optional1>', '<optional2>'],
      },
    ],
    Destinations: [
      {
        DestinationArn: '<optional>',
      },
    ],
  },
  'GameLift:updateMatchmakingConfiguration': {
    Name: 'required',
    GameProperties: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    GameSessionQueueArns: ['<optional1>', '<optional2>'],
    RequestTimeoutSeconds: ['<optional1>', '<optional2>'],
    AcceptanceTimeoutSeconds: ['<optional1>', '<optional2>'],
    AcceptanceRequired: '<optional>',
    RuleSetName: '<optional>',
    NotificationTarget: '<optional>',
    AdditionalPlayerCount: '<optional>',
    CustomEventData: ['<optional1>', '<optional2>'],
    GameSessionData: ['<optional1>', '<optional2>'],
    BackfillMode: '<optional>',
  },
  'GameLift:updateRuntimeConfiguration': {
    FleetId: 'required',
    RuntimeConfiguration: {
      ServerProcesses: [
        {
          Parameters: ['<optional1>', '<optional2>'],
        },
      ],
      MaxConcurrentGameSessionActivations: ['<optional1>', '<optional2>'],
      GameSessionActivationTimeoutSeconds: ['<optional1>', '<optional2>'],
    },
  },
  'GameLift:updateScript': {
    ScriptId: 'required',
    Name: '<optional>',
    Version: '<optional>',
    StorageLocation: {
      Bucket: '<optional>',
      Key: '<optional>',
      RoleArn: '<optional>',
      ObjectVersion: '<optional>',
    },
    ZipFile: '<optional>',
  },
  'GameLift:validateMatchmakingRuleSet': {
    RuleSetBody: 'required',
  },
  'Glacier:abortMultipartUpload': {
    accountId: 'required',
    vaultName: 'required',
    uploadId: 'required',
  },
  'Glacier:abortVaultLock': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:addTagsToVault': {
    accountId: 'required',
    vaultName: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'Glacier:completeMultipartUpload': {
    accountId: 'required',
    vaultName: 'required',
    uploadId: 'required',
    archiveSize: '<optional>',
    checksum: '<optional>',
  },
  'Glacier:completeVaultLock': {
    accountId: 'required',
    vaultName: 'required',
    lockId: 'required',
  },
  'Glacier:createVault': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:deleteArchive': {
    accountId: 'required',
    vaultName: 'required',
    archiveId: 'required',
  },
  'Glacier:deleteVault': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:deleteVaultAccessPolicy': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:deleteVaultNotifications': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:describeJob': {
    accountId: 'required',
    vaultName: 'required',
    jobId: 'required',
  },
  'Glacier:describeVault': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:getDataRetrievalPolicy': {
    accountId: 'required',
  },
  'Glacier:getJobOutput': {
    accountId: 'required',
    vaultName: 'required',
    jobId: 'required',
    range: '<optional>',
  },
  'Glacier:getVaultAccessPolicy': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:getVaultLock': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:getVaultNotifications': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:initiateJob': {
    accountId: 'required',
    vaultName: 'required',
    jobParameters: [
      {
        Format: '<optional>',
        Type: '<optional>',
        ArchiveId: '<optional>',
        Description: '<optional>',
        SNSTopic: '<optional>',
        RetrievalByteRange: '<optional>',
        Tier: '<optional>',
        InventoryRetrievalParameters: [
          {
            StartDate: '<optional>',
            EndDate: '<optional>',
            Limit: '<optional>',
            Marker: '<optional>',
          },
        ],
        SelectParameters: [
          {
            InputSerialization: {
              csv: {
                FileHeaderInfo: '<optional>',
                Comments: ['<optional1>', '<optional2>'],
                QuoteEscapeCharacter: '<optional>',
                RecordDelimiter: '<optional>',
                FieldDelimiter: '<optional>',
                QuoteCharacter: '<optional>',
              },
            },
            ExpressionType: '<optional>',
            Expression: '<optional>',
            OutputSerialization: {
              csv: {
                QuoteFields: ['<optional1>', '<optional2>'],
                QuoteEscapeCharacter: '<optional>',
                RecordDelimiter: '<optional>',
                FieldDelimiter: '<optional>',
                QuoteCharacter: '<optional>',
              },
            },
          },
        ],
        OutputLocation: {
          S3: {
            BucketName: '<optional>',
            Prefix: '<optional>',
            Encryption: {
              EncryptionType: '<optional>',
              KMSKeyId: '<optional>',
              KMSContext: '<optional>',
            },
            CannedACL: '<optional>',
            AccessControlList: {
              Grantee: {
                DisplayName: '<optional>',
                URI: '<optional>',
                ID: '<optional>',
                EmailAddress: '<optional>',
              },
              Permission: '<optional>',
            },
            Tagging: '<optional>',
            UserMetadata: ['<optional1>', '<optional2>'],
            StorageClass: '<optional>',
          },
        },
      },
    ],
  },
  'Glacier:initiateMultipartUpload': {
    accountId: 'required',
    vaultName: 'required',
    archiveDescription: '<optional>',
    partSize: '<optional>',
  },
  'Glacier:initiateVaultLock': {
    accountId: 'required',
    vaultName: 'required',
    policy: {
      Policy: '<optional>',
    },
  },
  'Glacier:listJobs': {
    accountId: 'required',
    vaultName: 'required',
    limit: '<optional>',
    marker: '<optional>',
    statuscode: '<optional>',
    completed: '<optional>',
  },
  'Glacier:listMultipartUploads': {
    accountId: 'required',
    vaultName: 'required',
    marker: '<optional>',
    limit: '<optional>',
  },
  'Glacier:listParts': {
    accountId: 'required',
    vaultName: 'required',
    uploadId: 'required',
    marker: '<optional>',
    limit: '<optional>',
  },
  'Glacier:listProvisionedCapacity': {
    accountId: 'required',
  },
  'Glacier:listTagsForVault': {
    accountId: 'required',
    vaultName: 'required',
  },
  'Glacier:listVaults': {
    accountId: 'required',
    marker: '<optional>',
    limit: '<optional>',
  },
  'Glacier:purchaseProvisionedCapacity': {
    accountId: 'required',
  },
  'Glacier:removeTagsFromVault': {
    accountId: 'required',
    vaultName: 'required',
    TagKeys: ['<optional1>', '<optional2>'],
  },
  'Glacier:setDataRetrievalPolicy': {
    accountId: 'required',
    Policy: {
      Rules: [
        {
          Strategy: '<optional>',
          BytesPerHour: '<optional>',
        },
      ],
    },
  },
  'Glacier:setVaultAccessPolicy': {
    accountId: 'required',
    vaultName: 'required',
    policy: {
      Policy: '<optional>',
    },
  },
  'Glacier:setVaultNotifications': {
    accountId: 'required',
    vaultName: 'required',
    vaultNotificationConfig: {
      SNSTopic: '<optional>',
      Events: ['<optional1>', '<optional2>'],
    },
  },
  'Glacier:uploadArchive': {
    vaultName: 'required',
    accountId: 'required',
    archiveDescription: '<optional>',
    checksum: '<optional>',
    body: '<optional>',
  },
  'Glacier:uploadMultipartPart': {
    accountId: 'required',
    vaultName: 'required',
    uploadId: 'required',
    checksum: '<optional>',
    range: '<optional>',
    body: '<optional>',
  },
  'GlobalAccelerator:createAccelerator': {
    Name: 'required',
    IdempotencyToken: 'required',
    IpAddressType: '<optional>',
    Enabled: '<optional>',
  },
  'GlobalAccelerator:createEndpointGroup': {
    ListenerArn: 'required',
    EndpointGroupRegion: 'required',
    IdempotencyToken: 'required',
    EndpointConfigurations: [
      {
        EndpointId: '<optional>',
        Weight: '<optional>',
      },
    ],
    TrafficDialPercentage: '<optional>',
    HealthCheckPort: '<optional>',
    HealthCheckProtocol: '<optional>',
    HealthCheckPath: '<optional>',
    HealthCheckIntervalSeconds: ['<optional1>', '<optional2>'],
    ThresholdCount: '<optional>',
  },
  'GlobalAccelerator:createListener': {
    AcceleratorArn: 'required',
    PortRanges: [
      {
        FromPort: '<optional>',
        ToPort: '<optional>',
      },
    ],
    Protocol: 'required',
    IdempotencyToken: 'required',
    ClientAffinity: '<optional>',
  },
  'GlobalAccelerator:deleteAccelerator': {
    AcceleratorArn: 'required',
  },
  'GlobalAccelerator:deleteEndpointGroup': {
    EndpointGroupArn: 'required',
  },
  'GlobalAccelerator:deleteListener': {
    ListenerArn: 'required',
  },
  'GlobalAccelerator:describeAccelerator': {
    AcceleratorArn: 'required',
  },
  'GlobalAccelerator:describeAcceleratorAttributes': {
    AcceleratorArn: '<optional>',
  },
  'GlobalAccelerator:describeEndpointGroup': {
    EndpointGroupArn: 'required',
  },
  'GlobalAccelerator:describeListener': {
    ListenerArn: 'required',
  },
  'GlobalAccelerator:listAccelerators': {},
  'GlobalAccelerator:listEndpointGroups': {
    ListenerArn: 'required',
  },
  'GlobalAccelerator:listListeners': {
    AcceleratorArn: 'required',
  },
  'GlobalAccelerator:updateAccelerator': {
    AcceleratorArn: 'required',
    Name: '<optional>',
    IpAddressType: '<optional>',
    Enabled: '<optional>',
  },
  'GlobalAccelerator:updateAcceleratorAttributes': {
    AcceleratorArn: '<optional>',
    FlowLogsEnabled: '<optional>',
    FlowLogsS3Bucket: '<optional>',
    FlowLogsS3Prefix: '<optional>',
  },
  'GlobalAccelerator:updateEndpointGroup': {
    EndpointGroupArn: 'required',
    EndpointConfigurations: [
      {
        EndpointId: '<optional>',
        Weight: '<optional>',
      },
    ],
    TrafficDialPercentage: '<optional>',
    HealthCheckPort: '<optional>',
    HealthCheckProtocol: '<optional>',
    HealthCheckPath: '<optional>',
    HealthCheckIntervalSeconds: ['<optional1>', '<optional2>'],
    ThresholdCount: '<optional>',
  },
  'GlobalAccelerator:updateListener': {
    ListenerArn: 'required',
    PortRanges: [
      {
        FromPort: '<optional>',
        ToPort: '<optional>',
      },
    ],
    Protocol: '<optional>',
    ClientAffinity: '<optional>',
  },
  'Glue:batchCreatePartition': {
    DatabaseName: 'required',
    TableName: 'required',
    PartitionInputList: {
      Values: ['<optional1>', '<optional2>'],
      LastAccessTime: '<optional>',
      StorageDescriptor: {
        Columns: [
          {
            Type: '<optional>',
            Comment: '<optional>',
          },
        ],
        Location: '<optional>',
        InputFormat: '<optional>',
        OutputFormat: '<optional>',
        Compressed: '<optional>',
        NumberOfBuckets: ['<optional1>', '<optional2>'],
        SerdeInfo: {
          Name: '<optional>',
          SerializationLibrary: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
        },
        BucketColumns: ['<optional1>', '<optional2>'],
        SortColumns: ['<optional1>', '<optional2>'],
        Parameters: ['<optional1>', '<optional2>'],
        SkewedInfo: {
          SkewedColumnNames: ['<optional1>', '<optional2>'],
          SkewedColumnValues: ['<optional1>', '<optional2>'],
          SkewedColumnValueLocationMaps: ['<optional1>', '<optional2>'],
        },
        StoredAsSubDirectories: ['<optional1>', '<optional2>'],
      },
      Parameters: ['<optional1>', '<optional2>'],
      LastAnalyzedTime: '<optional>',
    },
    CatalogId: '<optional>',
  },
  'Glue:batchDeleteConnection': {
    ConnectionNameList: 'required',
    CatalogId: '<optional>',
  },
  'Glue:batchDeletePartition': {
    DatabaseName: 'required',
    TableName: 'required',
    PartitionsToDelete: {
      Values: ['required1', 'required2'],
    },
    CatalogId: '<optional>',
  },
  'Glue:batchDeleteTable': {
    DatabaseName: 'required',
    TablesToDelete: 'required',
    CatalogId: '<optional>',
  },
  'Glue:batchDeleteTableVersion': {
    DatabaseName: 'required',
    TableName: 'required',
    VersionIds: ['required1', 'required2'],
    CatalogId: '<optional>',
  },
  'Glue:batchGetCrawlers': {
    CrawlerNames: ['required1', 'required2'],
  },
  'Glue:batchGetDevEndpoints': {
    DevEndpointNames: ['required1', 'required2'],
  },
  'Glue:batchGetJobs': {
    JobNames: ['required1', 'required2'],
  },
  'Glue:batchGetPartition': {
    DatabaseName: 'required',
    TableName: 'required',
    PartitionsToGet: {
      Values: ['required1', 'required2'],
    },
    CatalogId: '<optional>',
  },
  'Glue:batchGetTriggers': {
    TriggerNames: ['required1', 'required2'],
  },
  'Glue:batchGetWorkflows': {
    Names: ['required1', 'required2'],
    IncludeGraph: '<optional>',
  },
  'Glue:batchStopJobRun': {
    JobName: 'required',
    JobRunIds: ['required1', 'required2'],
  },
  'Glue:cancelMLTaskRun': {
    TransformId: 'required',
    TaskRunId: 'required',
  },
  'Glue:createClassifier': {
    GrokClassifier: {
      CustomPatterns: ['<optional1>', '<optional2>'],
    },
    XMLClassifier: {
      RowTag: '<optional>',
    },
    JsonClassifier: '<optional>',
    CsvClassifier: {
      Delimiter: '<optional>',
      QuoteSymbol: '<optional>',
      ContainsHeader: '<optional>',
      Header: '<optional>',
      DisableValueTrimming: '<optional>',
      AllowSingleColumn: '<optional>',
    },
  },
  'Glue:createConnection': {
    ConnectionInput: {
      Description: '<optional>',
      MatchCriteria: ['<optional1>', '<optional2>'],
      PhysicalConnectionRequirements: [
        {
          SubnetId: '<optional>',
          SecurityGroupIdList: '<optional>',
          AvailabilityZone: '<optional>',
        },
      ],
    },
    CatalogId: '<optional>',
  },
  'Glue:createCrawler': {
    Name: 'required',
    Role: 'required',
    Targets: [
      {
        S3Targets: [
          {
            Path: '<optional>',
            Exclusions: ['<optional1>', '<optional2>'],
          },
        ],
        JdbcTargets: [
          {
            ConnectionName: '<optional>',
            Path: '<optional>',
            Exclusions: ['<optional1>', '<optional2>'],
          },
        ],
        DynamoDBTargets: [
          {
            Path: '<optional>',
          },
        ],
        CatalogTargets: ['<optional1>', '<optional2>'],
      },
    ],
    DatabaseName: '<optional>',
    Description: '<optional>',
    Schedule: '<optional>',
    Classifiers: ['<optional1>', '<optional2>'],
    TablePrefix: '<optional>',
    SchemaChangePolicy: {
      UpdateBehavior: '<optional>',
      DeleteBehavior: '<optional>',
    },
    Configuration: '<optional>',
    CrawlerSecurityConfiguration: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'Glue:createDatabase': {
    DatabaseInput: {
      Description: '<optional>',
      LocationUri: '<optional>',
      Parameters: ['<optional1>', '<optional2>'],
    },
    CatalogId: '<optional>',
  },
  'Glue:createDevEndpoint': {
    EndpointName: 'required',
    RoleArn: 'required',
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    SubnetId: '<optional>',
    PublicKey: '<optional>',
    PublicKeys: ['<optional1>', '<optional2>'],
    NumberOfNodes: ['<optional1>', '<optional2>'],
    ExtraPythonLibsS3Path: '<optional>',
    ExtraJarsS3Path: '<optional>',
    SecurityConfiguration: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
    Arguments: ['<optional1>', '<optional2>'],
  },
  'Glue:createJob': {
    Name: 'required',
    Role: 'required',
    Command: {
      Name: '<optional>',
      ScriptLocation: '<optional>',
      PythonVersion: '<optional>',
    },
    Description: '<optional>',
    LogUri: '<optional>',
    ExecutionProperty: {
      MaxConcurrentRuns: ['<optional1>', '<optional2>'],
    },
    DefaultArguments: ['<optional1>', '<optional2>'],
    Connections: [
      {
        Connections: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRetries: ['<optional1>', '<optional2>'],
    AllocatedCapacity: '<optional>',
    Timeout: '<optional>',
    MaxCapacity: '<optional>',
    SecurityConfiguration: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
    NotificationProperty: {
      NotifyDelayAfter: '<optional>',
    },
    NumberOfWorkers: ['<optional1>', '<optional2>'],
    WorkerType: '<optional>',
  },
  'Glue:createMLTransform': {
    Name: 'required',
    InputRecordTables: [
      {
        CatalogId: '<optional>',
        ConnectionName: '<optional>',
      },
    ],
    Parameters: [
      {
        FindMatchesParameters: [
          {
            PrimaryKeyColumnName: '<optional>',
            PrecisionRecallTradeoff: '<optional>',
            AccuracyCostTradeoff: '<optional>',
            EnforceProvidedLabels: ['<optional1>', '<optional2>'],
          },
        ],
      },
    ],
    Role: 'required',
    Description: '<optional>',
    MaxCapacity: '<optional>',
    WorkerType: '<optional>',
    NumberOfWorkers: ['<optional1>', '<optional2>'],
    Timeout: '<optional>',
    MaxRetries: ['<optional1>', '<optional2>'],
  },
  'Glue:createPartition': {
    DatabaseName: 'required',
    TableName: 'required',
    PartitionInput: {
      Values: ['<optional1>', '<optional2>'],
      LastAccessTime: '<optional>',
      StorageDescriptor: {
        Columns: [
          {
            Type: '<optional>',
            Comment: '<optional>',
          },
        ],
        Location: '<optional>',
        InputFormat: '<optional>',
        OutputFormat: '<optional>',
        Compressed: '<optional>',
        NumberOfBuckets: ['<optional1>', '<optional2>'],
        SerdeInfo: {
          Name: '<optional>',
          SerializationLibrary: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
        },
        BucketColumns: ['<optional1>', '<optional2>'],
        SortColumns: ['<optional1>', '<optional2>'],
        Parameters: ['<optional1>', '<optional2>'],
        SkewedInfo: {
          SkewedColumnNames: ['<optional1>', '<optional2>'],
          SkewedColumnValues: ['<optional1>', '<optional2>'],
          SkewedColumnValueLocationMaps: ['<optional1>', '<optional2>'],
        },
        StoredAsSubDirectories: ['<optional1>', '<optional2>'],
      },
      Parameters: ['<optional1>', '<optional2>'],
      LastAnalyzedTime: '<optional>',
    },
    CatalogId: '<optional>',
  },
  'Glue:createScript': {
    DagNodes: [
      {
        LineNumber: '<optional>',
        Args: [
          {
            Param: '<optional>',
          },
        ],
      },
    ],
    DagEdges: [
      {
        TargetParameter: '<optional>',
      },
    ],
    Language: '<optional>',
  },
  'Glue:createSecurityConfiguration': {
    Name: 'required',
    EncryptionConfiguration: {
      S3Encryption: {
        S3EncryptionMode: '<optional>',
        KmsKeyArn: '<optional>',
      },
      CloudWatchEncryption: {
        CloudWatchEncryptionMode: '<optional>',
        KmsKeyArn: '<optional>',
      },
      JobBookmarksEncryption: {
        JobBookmarksEncryptionMode: '<optional>',
        KmsKeyArn: '<optional>',
      },
    },
  },
  'Glue:createTable': {
    DatabaseName: 'required',
    TableInput: {
      Description: '<optional>',
      Owner: '<optional>',
      LastAccessTime: '<optional>',
      LastAnalyzedTime: '<optional>',
      Retention: '<optional>',
      StorageDescriptor: {
        Columns: [
          {
            Type: '<optional>',
            Comment: '<optional>',
          },
        ],
        Location: '<optional>',
        InputFormat: '<optional>',
        OutputFormat: '<optional>',
        Compressed: '<optional>',
        NumberOfBuckets: ['<optional1>', '<optional2>'],
        SerdeInfo: {
          Name: '<optional>',
          SerializationLibrary: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
        },
        BucketColumns: ['<optional1>', '<optional2>'],
        SortColumns: ['<optional1>', '<optional2>'],
        Parameters: ['<optional1>', '<optional2>'],
        SkewedInfo: {
          SkewedColumnNames: ['<optional1>', '<optional2>'],
          SkewedColumnValues: ['<optional1>', '<optional2>'],
          SkewedColumnValueLocationMaps: ['<optional1>', '<optional2>'],
        },
        StoredAsSubDirectories: ['<optional1>', '<optional2>'],
      },
      PartitionKeys: [
        {
          Type: '<optional>',
          Comment: '<optional>',
        },
      ],
      ViewOriginalText: '<optional>',
      ViewExpandedText: '<optional>',
      TableType: '<optional>',
      Parameters: ['<optional1>', '<optional2>'],
    },
    CatalogId: '<optional>',
  },
  'Glue:createTrigger': {
    Name: 'required',
    Type: 'required',
    Actions: [
      {
        JobName: '<optional>',
        Arguments: ['<optional1>', '<optional2>'],
        Timeout: '<optional>',
        SecurityConfiguration: '<optional>',
        NotificationProperty: {
          NotifyDelayAfter: '<optional>',
        },
        CrawlerName: '<optional>',
      },
    ],
    WorkflowName: '<optional>',
    Schedule: '<optional>',
    Predicate: {
      Logical: '<optional>',
      Conditions: [
        {
          LogicalOperator: '<optional>',
          JobName: '<optional>',
          State: '<optional>',
          CrawlerName: '<optional>',
          CrawlState: '<optional>',
        },
      ],
    },
    Description: '<optional>',
    StartOnCreation: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'Glue:createUserDefinedFunction': {
    DatabaseName: 'required',
    FunctionInput: {
      FunctionName: '<optional>',
      ClassName: '<optional>',
      OwnerName: '<optional>',
      OwnerType: '<optional>',
      ResourceUris: {
        ResourceType: '<optional>',
        Uri: '<optional>',
      },
    },
    CatalogId: '<optional>',
  },
  'Glue:createWorkflow': {
    Name: 'required',
    Description: '<optional>',
    DefaultRunProperties: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
  },
  'Glue:deleteClassifier': {
    Name: 'required',
  },
  'Glue:deleteConnection': {
    ConnectionName: 'required',
    CatalogId: '<optional>',
  },
  'Glue:deleteCrawler': {
    Name: 'required',
  },
  'Glue:deleteDatabase': {
    Name: 'required',
    CatalogId: '<optional>',
  },
  'Glue:deleteDevEndpoint': {
    EndpointName: 'required',
  },
  'Glue:deleteJob': {
    JobName: 'required',
  },
  'Glue:deleteMLTransform': {
    TransformId: 'required',
  },
  'Glue:deletePartition': {
    DatabaseName: 'required',
    TableName: 'required',
    PartitionValues: ['required1', 'required2'],
    CatalogId: '<optional>',
  },
  'Glue:deleteResourcePolicy': {
    PolicyHashCondition: '<optional>',
  },
  'Glue:deleteSecurityConfiguration': {
    Name: 'required',
  },
  'Glue:deleteTable': {
    DatabaseName: 'required',
    Name: 'required',
    CatalogId: '<optional>',
  },
  'Glue:deleteTableVersion': {
    DatabaseName: 'required',
    TableName: 'required',
    VersionId: 'required',
    CatalogId: '<optional>',
  },
  'Glue:deleteTrigger': {
    Name: 'required',
  },
  'Glue:deleteUserDefinedFunction': {
    DatabaseName: 'required',
    FunctionName: 'required',
    CatalogId: '<optional>',
  },
  'Glue:deleteWorkflow': {
    Name: 'required',
  },
  'Glue:getCatalogImportStatus': {
    CatalogId: '<optional>',
  },
  'Glue:getClassifier': {
    Name: 'required',
  },
  'Glue:getClassifiers': {},
  'Glue:getConnection': {
    Name: 'required',
    CatalogId: '<optional>',
    HidePassword: '<optional>',
  },
  'Glue:getConnections': {
    CatalogId: '<optional>',
    Filter: {
      MatchCriteria: ['<optional1>', '<optional2>'],
      ConnectionType: '<optional>',
    },
    HidePassword: '<optional>',
  },
  'Glue:getCrawler': {
    Name: 'required',
  },
  'Glue:getCrawlerMetrics': {
    CrawlerNameList: '<optional>',
  },
  'Glue:getCrawlers': {},
  'Glue:getDataCatalogEncryptionSettings': {
    CatalogId: '<optional>',
  },
  'Glue:getDatabase': {
    Name: 'required',
    CatalogId: '<optional>',
  },
  'Glue:getDatabases': {
    CatalogId: '<optional>',
  },
  'Glue:getDataflowGraph': {
    PythonScript: '<optional>',
  },
  'Glue:getDevEndpoint': {
    EndpointName: 'required',
  },
  'Glue:getDevEndpoints': {},
  'Glue:getJob': {
    JobName: 'required',
  },
  'Glue:getJobBookmark': {
    JobName: 'required',
    RunId: '<optional>',
  },
  'Glue:getJobRun': {
    JobName: 'required',
    RunId: 'required',
    PredecessorsIncluded: '<optional>',
  },
  'Glue:getJobRuns': {
    JobName: 'required',
  },
  'Glue:getJobs': {},
  'Glue:getMLTaskRun': {
    TransformId: 'required',
    TaskRunId: 'required',
  },
  'Glue:getMLTaskRuns': {
    TransformId: 'required',
    Sort: '<optional>',
    Filter: {
      TaskRunType: '<optional>',
      Status: '<optional>',
      StartedBefore: '<optional>',
      StartedAfter: '<optional>',
    },
  },
  'Glue:getMLTransform': {
    TransformId: 'required',
  },
  'Glue:getMLTransforms': {
    Sort: '<optional>',
    Filter: {
      Name: '<optional>',
      TransformType: '<optional>',
      Status: '<optional>',
      CreatedBefore: '<optional>',
      CreatedAfter: '<optional>',
      LastModifiedBefore: '<optional>',
      LastModifiedAfter: '<optional>',
      Schema: {
        Name: '<optional>',
        DataType: '<optional>',
      },
    },
  },
  'Glue:getMapping': {
    Source: {
      DatabaseName: 'required',
      TableName: 'required',
    },
    Sinks: ['<optional1>', '<optional2>'],
    Location: {
      Jdbc: {
        Param: '<optional>',
      },
      S3: {
        Param: '<optional>',
      },
      DynamoDB: {
        Param: '<optional>',
      },
    },
  },
  'Glue:getPartition': {
    DatabaseName: 'required',
    TableName: 'required',
    PartitionValues: ['required1', 'required2'],
    CatalogId: '<optional>',
  },
  'Glue:getPartitions': {
    DatabaseName: 'required',
    TableName: 'required',
    Segment: '<optional>',
    CatalogId: '<optional>',
    Expression: '<optional>',
  },
  'Glue:getPlan': {
    Mapping: {
      SourceTable: '<optional>',
      SourcePath: '<optional>',
      SourceType: '<optional>',
      TargetTable: '<optional>',
      TargetPath: '<optional>',
      TargetType: '<optional>',
    },
    Source: {
      DatabaseName: 'required',
      TableName: 'required',
    },
    Sinks: ['<optional1>', '<optional2>'],
    Location: {
      Jdbc: {
        Param: '<optional>',
      },
      S3: {
        Param: '<optional>',
      },
      DynamoDB: {
        Param: '<optional>',
      },
    },
    Language: '<optional>',
  },
  'Glue:getResourcePolicy': {},
  'Glue:getSecurityConfiguration': {
    Name: 'required',
  },
  'Glue:getSecurityConfigurations': {},
  'Glue:getTable': {
    DatabaseName: 'required',
    Name: 'required',
    CatalogId: '<optional>',
  },
  'Glue:getTableVersion': {
    DatabaseName: 'required',
    TableName: 'required',
    CatalogId: '<optional>',
    VersionId: '<optional>',
  },
  'Glue:getTableVersions': {
    DatabaseName: 'required',
    TableName: 'required',
    CatalogId: '<optional>',
  },
  'Glue:getTables': {
    DatabaseName: 'required',
    CatalogId: '<optional>',
    Expression: '<optional>',
  },
  'Glue:getTags': {
    ResourceArn: 'required',
  },
  'Glue:getTrigger': {
    Name: 'required',
  },
  'Glue:getTriggers': {
    DependentJobName: '<optional>',
  },
  'Glue:getUserDefinedFunction': {
    DatabaseName: 'required',
    FunctionName: 'required',
    CatalogId: '<optional>',
  },
  'Glue:getUserDefinedFunctions': {
    DatabaseName: 'required',
    Pattern: 'required',
    CatalogId: '<optional>',
  },
  'Glue:getWorkflow': {
    Name: 'required',
    IncludeGraph: '<optional>',
  },
  'Glue:getWorkflowRun': {
    Name: 'required',
    RunId: 'required',
    IncludeGraph: '<optional>',
  },
  'Glue:getWorkflowRunProperties': {
    Name: 'required',
    RunId: 'required',
  },
  'Glue:getWorkflowRuns': {
    Name: 'required',
    IncludeGraph: '<optional>',
  },
  'Glue:importCatalogToGlue': {
    CatalogId: '<optional>',
  },
  'Glue:listCrawlers': {
    Tags: ['<optional1>', '<optional2>'],
  },
  'Glue:listDevEndpoints': {
    Tags: ['<optional1>', '<optional2>'],
  },
  'Glue:listJobs': {
    Tags: ['<optional1>', '<optional2>'],
  },
  'Glue:listTriggers': {
    DependentJobName: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'Glue:listWorkflows': {},
  'Glue:putDataCatalogEncryptionSettings': {
    DataCatalogEncryptionSettings: [
      {
        EncryptionAtRest: {
          SseAwsKmsKeyId: '<optional>',
        },
        ConnectionPasswordEncryption: {
          AwsKmsKeyId: '<optional>',
        },
      },
    ],
    CatalogId: '<optional>',
  },
  'Glue:putResourcePolicy': {
    PolicyInJson: 'required',
    PolicyHashCondition: '<optional>',
    PolicyExistsCondition: '<optional>',
  },
  'Glue:putWorkflowRunProperties': {
    Name: 'required',
    RunId: 'required',
    RunProperties: ['required1', 'required2'],
  },
  'Glue:resetJobBookmark': {
    JobName: 'required',
  },
  'Glue:searchTables': {
    CatalogId: '<optional>',
    Filters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        Comparator: '<optional>',
      },
    ],
    SearchText: '<optional>',
    SortCriteria: [
      {
        FieldName: '<optional>',
        Sort: '<optional>',
      },
    ],
  },
  'Glue:startCrawler': {
    Name: 'required',
  },
  'Glue:startCrawlerSchedule': {
    CrawlerName: 'required',
  },
  'Glue:startExportLabelsTaskRun': {
    TransformId: 'required',
    OutputS3Path: 'required',
  },
  'Glue:startImportLabelsTaskRun': {
    TransformId: 'required',
    InputS3Path: 'required',
    ReplaceAllLabels: ['<optional1>', '<optional2>'],
  },
  'Glue:startJobRun': {
    JobName: 'required',
    JobRunId: '<optional>',
    Arguments: ['<optional1>', '<optional2>'],
    AllocatedCapacity: '<optional>',
    Timeout: '<optional>',
    MaxCapacity: '<optional>',
    SecurityConfiguration: '<optional>',
    NotificationProperty: {
      NotifyDelayAfter: '<optional>',
    },
    WorkerType: '<optional>',
    NumberOfWorkers: ['<optional1>', '<optional2>'],
  },
  'Glue:startMLEvaluationTaskRun': {
    TransformId: 'required',
  },
  'Glue:startMLLabelingSetGenerationTaskRun': {
    TransformId: 'required',
    OutputS3Path: 'required',
  },
  'Glue:startTrigger': {
    Name: 'required',
  },
  'Glue:startWorkflowRun': {
    Name: 'required',
  },
  'Glue:stopCrawler': {
    Name: 'required',
  },
  'Glue:stopCrawlerSchedule': {
    CrawlerName: 'required',
  },
  'Glue:stopTrigger': {
    Name: 'required',
  },
  'Glue:tagResource': {
    ResourceArn: 'required',
    TagsToAdd: 'required',
  },
  'Glue:untagResource': {
    ResourceArn: 'required',
    TagsToRemove: 'required',
  },
  'Glue:updateClassifier': {
    GrokClassifier: {
      Classification: '<optional>',
      GrokPattern: '<optional>',
      CustomPatterns: ['<optional1>', '<optional2>'],
    },
    XMLClassifier: {
      Classification: '<optional>',
      RowTag: '<optional>',
    },
    JsonClassifier: {
      JsonPath: '<optional>',
    },
    CsvClassifier: {
      Delimiter: '<optional>',
      QuoteSymbol: '<optional>',
      ContainsHeader: '<optional>',
      Header: '<optional>',
      DisableValueTrimming: '<optional>',
      AllowSingleColumn: '<optional>',
    },
  },
  'Glue:updateConnection': {
    Name: 'required',
    ConnectionInput: {
      Description: '<optional>',
      MatchCriteria: ['<optional1>', '<optional2>'],
      PhysicalConnectionRequirements: [
        {
          SubnetId: '<optional>',
          SecurityGroupIdList: '<optional>',
          AvailabilityZone: '<optional>',
        },
      ],
    },
    CatalogId: '<optional>',
  },
  'Glue:updateCrawler': {
    Name: 'required',
    Targets: [
      {
        S3Targets: [
          {
            Path: '<optional>',
            Exclusions: ['<optional1>', '<optional2>'],
          },
        ],
        JdbcTargets: [
          {
            ConnectionName: '<optional>',
            Path: '<optional>',
            Exclusions: ['<optional1>', '<optional2>'],
          },
        ],
        DynamoDBTargets: [
          {
            Path: '<optional>',
          },
        ],
        CatalogTargets: ['<optional1>', '<optional2>'],
      },
    ],
    Role: '<optional>',
    DatabaseName: '<optional>',
    Description: '<optional>',
    Schedule: '<optional>',
    Classifiers: ['<optional1>', '<optional2>'],
    TablePrefix: '<optional>',
    SchemaChangePolicy: {
      UpdateBehavior: '<optional>',
      DeleteBehavior: '<optional>',
    },
    Configuration: '<optional>',
    CrawlerSecurityConfiguration: '<optional>',
  },
  'Glue:updateCrawlerSchedule': {
    CrawlerName: 'required',
    Schedule: '<optional>',
  },
  'Glue:updateDatabase': {
    Name: 'required',
    DatabaseInput: {
      Description: '<optional>',
      LocationUri: '<optional>',
      Parameters: ['<optional1>', '<optional2>'],
    },
    CatalogId: '<optional>',
  },
  'Glue:updateDevEndpoint': {
    EndpointName: 'required',
    PublicKey: '<optional>',
    AddPublicKeys: ['<optional1>', '<optional2>'],
    DeletePublicKeys: ['<optional1>', '<optional2>'],
    CustomLibraries: [
      {
        ExtraPythonLibsS3Path: '<optional>',
        ExtraJarsS3Path: '<optional>',
      },
    ],
    UpdateEtlLibraries: ['<optional1>', '<optional2>'],
    DeleteArguments: ['<optional1>', '<optional2>'],
    AddArguments: ['<optional1>', '<optional2>'],
  },
  'Glue:updateJob': {
    JobName: 'required',
    JobUpdate: {
      Description: '<optional>',
      LogUri: '<optional>',
      Role: '<optional>',
      ExecutionProperty: {
        MaxConcurrentRuns: ['<optional1>', '<optional2>'],
      },
      Command: {
        Name: '<optional>',
        ScriptLocation: '<optional>',
        PythonVersion: '<optional>',
      },
      DefaultArguments: ['<optional1>', '<optional2>'],
      Connections: [
        {
          Connections: ['<optional1>', '<optional2>'],
        },
      ],
      MaxRetries: ['<optional1>', '<optional2>'],
      AllocatedCapacity: '<optional>',
      Timeout: '<optional>',
      MaxCapacity: '<optional>',
      WorkerType: '<optional>',
      NumberOfWorkers: ['<optional1>', '<optional2>'],
      SecurityConfiguration: '<optional>',
      NotificationProperty: {
        NotifyDelayAfter: '<optional>',
      },
    },
  },
  'Glue:updateMLTransform': {
    TransformId: 'required',
    Parameters: [
      {
        FindMatchesParameters: [
          {
            PrimaryKeyColumnName: '<optional>',
            PrecisionRecallTradeoff: '<optional>',
            AccuracyCostTradeoff: '<optional>',
            EnforceProvidedLabels: ['<optional1>', '<optional2>'],
          },
        ],
      },
    ],
    Name: '<optional>',
    Description: '<optional>',
    Role: '<optional>',
    MaxCapacity: '<optional>',
    WorkerType: '<optional>',
    NumberOfWorkers: ['<optional1>', '<optional2>'],
    Timeout: '<optional>',
    MaxRetries: ['<optional1>', '<optional2>'],
  },
  'Glue:updatePartition': {
    DatabaseName: 'required',
    TableName: 'required',
    PartitionValueList: 'required',
    PartitionInput: {
      Values: ['<optional1>', '<optional2>'],
      LastAccessTime: '<optional>',
      StorageDescriptor: {
        Columns: [
          {
            Type: '<optional>',
            Comment: '<optional>',
          },
        ],
        Location: '<optional>',
        InputFormat: '<optional>',
        OutputFormat: '<optional>',
        Compressed: '<optional>',
        NumberOfBuckets: ['<optional1>', '<optional2>'],
        SerdeInfo: {
          Name: '<optional>',
          SerializationLibrary: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
        },
        BucketColumns: ['<optional1>', '<optional2>'],
        SortColumns: ['<optional1>', '<optional2>'],
        Parameters: ['<optional1>', '<optional2>'],
        SkewedInfo: {
          SkewedColumnNames: ['<optional1>', '<optional2>'],
          SkewedColumnValues: ['<optional1>', '<optional2>'],
          SkewedColumnValueLocationMaps: ['<optional1>', '<optional2>'],
        },
        StoredAsSubDirectories: ['<optional1>', '<optional2>'],
      },
      Parameters: ['<optional1>', '<optional2>'],
      LastAnalyzedTime: '<optional>',
    },
    CatalogId: '<optional>',
  },
  'Glue:updateTable': {
    DatabaseName: 'required',
    TableInput: {
      Description: '<optional>',
      Owner: '<optional>',
      LastAccessTime: '<optional>',
      LastAnalyzedTime: '<optional>',
      Retention: '<optional>',
      StorageDescriptor: {
        Columns: [
          {
            Type: '<optional>',
            Comment: '<optional>',
          },
        ],
        Location: '<optional>',
        InputFormat: '<optional>',
        OutputFormat: '<optional>',
        Compressed: '<optional>',
        NumberOfBuckets: ['<optional1>', '<optional2>'],
        SerdeInfo: {
          Name: '<optional>',
          SerializationLibrary: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
        },
        BucketColumns: ['<optional1>', '<optional2>'],
        SortColumns: ['<optional1>', '<optional2>'],
        Parameters: ['<optional1>', '<optional2>'],
        SkewedInfo: {
          SkewedColumnNames: ['<optional1>', '<optional2>'],
          SkewedColumnValues: ['<optional1>', '<optional2>'],
          SkewedColumnValueLocationMaps: ['<optional1>', '<optional2>'],
        },
        StoredAsSubDirectories: ['<optional1>', '<optional2>'],
      },
      PartitionKeys: [
        {
          Type: '<optional>',
          Comment: '<optional>',
        },
      ],
      ViewOriginalText: '<optional>',
      ViewExpandedText: '<optional>',
      TableType: '<optional>',
      Parameters: ['<optional1>', '<optional2>'],
    },
    CatalogId: '<optional>',
    SkipArchive: '<optional>',
  },
  'Glue:updateTrigger': {
    Name: 'required',
    TriggerUpdate: {
      Name: '<optional>',
      Description: '<optional>',
      Schedule: '<optional>',
      Actions: [
        {
          JobName: '<optional>',
          Arguments: ['<optional1>', '<optional2>'],
          Timeout: '<optional>',
          SecurityConfiguration: '<optional>',
          NotificationProperty: {
            NotifyDelayAfter: '<optional>',
          },
          CrawlerName: '<optional>',
        },
      ],
      Predicate: {
        Logical: '<optional>',
        Conditions: [
          {
            LogicalOperator: '<optional>',
            JobName: '<optional>',
            State: '<optional>',
            CrawlerName: '<optional>',
            CrawlState: '<optional>',
          },
        ],
      },
    },
  },
  'Glue:updateUserDefinedFunction': {
    DatabaseName: 'required',
    FunctionName: 'required',
    FunctionInput: {
      FunctionName: '<optional>',
      ClassName: '<optional>',
      OwnerName: '<optional>',
      OwnerType: '<optional>',
      ResourceUris: {
        ResourceType: '<optional>',
        Uri: '<optional>',
      },
    },
    CatalogId: '<optional>',
  },
  'Glue:updateWorkflow': {
    Name: 'required',
    Description: '<optional>',
    DefaultRunProperties: ['<optional1>', '<optional2>'],
  },
  'Greengrass:associateRoleToGroup': {
    GroupId: 'required',
    RoleArn: '<optional>',
  },
  'Greengrass:associateServiceRoleToAccount': {
    RoleArn: '<optional>',
  },
  'Greengrass:createConnectorDefinition': {
    AmznClientToken: '<optional>',
    InitialVersion: {
      Connectors: [
        {
          ConnectorArn: '<optional>',
          Id: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
        },
      ],
    },
    Name: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:createConnectorDefinitionVersion': {
    ConnectorDefinitionId: 'required',
    AmznClientToken: '<optional>',
    Connectors: [
      {
        ConnectorArn: '<optional>',
        Id: '<optional>',
        Parameters: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Greengrass:createCoreDefinition': {
    AmznClientToken: '<optional>',
    InitialVersion: {
      Cores: [
        {
          CertificateArn: '<optional>',
          Id: '<optional>',
          SyncShadow: '<optional>',
          ThingArn: '<optional>',
        },
      ],
    },
    Name: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:createCoreDefinitionVersion': {
    CoreDefinitionId: 'required',
    AmznClientToken: '<optional>',
    Cores: [
      {
        CertificateArn: '<optional>',
        Id: '<optional>',
        SyncShadow: '<optional>',
        ThingArn: '<optional>',
      },
    ],
  },
  'Greengrass:createDeployment': {
    GroupId: 'required',
    AmznClientToken: '<optional>',
    DeploymentId: '<optional>',
    DeploymentType: '<optional>',
    GroupVersionId: '<optional>',
  },
  'Greengrass:createDeviceDefinition': {
    AmznClientToken: '<optional>',
    InitialVersion: {
      Devices: [
        {
          CertificateArn: '<optional>',
          Id: '<optional>',
          SyncShadow: '<optional>',
          ThingArn: '<optional>',
        },
      ],
    },
    Name: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:createDeviceDefinitionVersion': {
    DeviceDefinitionId: 'required',
    AmznClientToken: '<optional>',
    Devices: [
      {
        CertificateArn: '<optional>',
        Id: '<optional>',
        SyncShadow: '<optional>',
        ThingArn: '<optional>',
      },
    ],
  },
  'Greengrass:createFunctionDefinition': {
    AmznClientToken: '<optional>',
    InitialVersion: {
      DefaultConfig: {
        Execution: {
          IsolationMode: '<optional>',
          RunAs: [
            {
              Gid: '<optional>',
              Uid: '<optional>',
            },
          ],
        },
      },
      Functions: [
        {
          FunctionArn: '<optional>',
          FunctionConfiguration: {
            EncodingType: '<optional>',
            Environment: {
              AccessSysfs: ['<optional1>', '<optional2>'],
              Execution: {
                IsolationMode: '<optional>',
                RunAs: [
                  {
                    Gid: '<optional>',
                    Uid: '<optional>',
                  },
                ],
              },
              ResourceAccessPolicies: [
                {
                  Permission: '<optional>',
                  ResourceId: '<optional>',
                },
              ],
              Variables: ['<optional1>', '<optional2>'],
            },
            ExecArgs: ['<optional1>', '<optional2>'],
            Executable: '<optional>',
            MemorySize: '<optional>',
            Pinned: '<optional>',
            Timeout: '<optional>',
          },
          Id: '<optional>',
        },
      ],
    },
    Name: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:createFunctionDefinitionVersion': {
    FunctionDefinitionId: 'required',
    AmznClientToken: '<optional>',
    DefaultConfig: {
      Execution: {
        IsolationMode: '<optional>',
        RunAs: [
          {
            Gid: '<optional>',
            Uid: '<optional>',
          },
        ],
      },
    },
    Functions: [
      {
        FunctionArn: '<optional>',
        FunctionConfiguration: {
          EncodingType: '<optional>',
          Environment: {
            AccessSysfs: ['<optional1>', '<optional2>'],
            Execution: {
              IsolationMode: '<optional>',
              RunAs: [
                {
                  Gid: '<optional>',
                  Uid: '<optional>',
                },
              ],
            },
            ResourceAccessPolicies: [
              {
                Permission: '<optional>',
                ResourceId: '<optional>',
              },
            ],
            Variables: ['<optional1>', '<optional2>'],
          },
          ExecArgs: ['<optional1>', '<optional2>'],
          Executable: '<optional>',
          MemorySize: '<optional>',
          Pinned: '<optional>',
          Timeout: '<optional>',
        },
        Id: '<optional>',
      },
    ],
  },
  'Greengrass:createGroup': {
    AmznClientToken: '<optional>',
    InitialVersion: {
      ConnectorDefinitionVersionArn: '<optional>',
      CoreDefinitionVersionArn: '<optional>',
      DeviceDefinitionVersionArn: '<optional>',
      FunctionDefinitionVersionArn: '<optional>',
      LoggerDefinitionVersionArn: '<optional>',
      ResourceDefinitionVersionArn: '<optional>',
      SubscriptionDefinitionVersionArn: '<optional>',
    },
    Name: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:createGroupCertificateAuthority': {
    GroupId: 'required',
    AmznClientToken: '<optional>',
  },
  'Greengrass:createGroupVersion': {
    GroupId: 'required',
    AmznClientToken: '<optional>',
    ConnectorDefinitionVersionArn: '<optional>',
    CoreDefinitionVersionArn: '<optional>',
    DeviceDefinitionVersionArn: '<optional>',
    FunctionDefinitionVersionArn: '<optional>',
    LoggerDefinitionVersionArn: '<optional>',
    ResourceDefinitionVersionArn: '<optional>',
    SubscriptionDefinitionVersionArn: '<optional>',
  },
  'Greengrass:createLoggerDefinition': {
    AmznClientToken: '<optional>',
    InitialVersion: {
      Loggers: [
        {
          Component: '<optional>',
          Id: '<optional>',
          Level: '<optional>',
          Space: '<optional>',
          Type: '<optional>',
        },
      ],
    },
    Name: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:createLoggerDefinitionVersion': {
    LoggerDefinitionId: 'required',
    AmznClientToken: '<optional>',
    Loggers: [
      {
        Component: '<optional>',
        Id: '<optional>',
        Level: '<optional>',
        Space: '<optional>',
        Type: '<optional>',
      },
    ],
  },
  'Greengrass:createResourceDefinition': {
    AmznClientToken: '<optional>',
    InitialVersion: {
      Resources: [
        {
          Id: '<optional>',
          Name: '<optional>',
          ResourceDataContainer: {
            LocalDeviceResourceData: [
              {
                GroupOwnerSetting: {
                  AutoAddGroupOwner: '<optional>',
                  GroupOwner: '<optional>',
                },
                SourcePath: '<optional>',
              },
            ],
            LocalVolumeResourceData: [
              {
                DestinationPath: '<optional>',
                GroupOwnerSetting: {
                  AutoAddGroupOwner: '<optional>',
                  GroupOwner: '<optional>',
                },
                SourcePath: '<optional>',
              },
            ],
            S3MachineLearningModelResourceData: [
              {
                DestinationPath: '<optional>',
                S3Uri: '<optional>',
              },
            ],
            SageMakerMachineLearningModelResourceData: [
              {
                DestinationPath: '<optional>',
                SageMakerJobArn: '<optional>',
              },
            ],
            SecretsManagerSecretResourceData: [
              {
                ARN: '<optional>',
                AdditionalStagingLabelsToDownload: '<optional>',
              },
            ],
          },
        },
      ],
    },
    Name: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:createResourceDefinitionVersion': {
    ResourceDefinitionId: 'required',
    AmznClientToken: '<optional>',
    Resources: [
      {
        Id: '<optional>',
        Name: '<optional>',
        ResourceDataContainer: {
          LocalDeviceResourceData: [
            {
              GroupOwnerSetting: {
                AutoAddGroupOwner: '<optional>',
                GroupOwner: '<optional>',
              },
              SourcePath: '<optional>',
            },
          ],
          LocalVolumeResourceData: [
            {
              DestinationPath: '<optional>',
              GroupOwnerSetting: {
                AutoAddGroupOwner: '<optional>',
                GroupOwner: '<optional>',
              },
              SourcePath: '<optional>',
            },
          ],
          S3MachineLearningModelResourceData: [
            {
              DestinationPath: '<optional>',
              S3Uri: '<optional>',
            },
          ],
          SageMakerMachineLearningModelResourceData: [
            {
              DestinationPath: '<optional>',
              SageMakerJobArn: '<optional>',
            },
          ],
          SecretsManagerSecretResourceData: [
            {
              ARN: '<optional>',
              AdditionalStagingLabelsToDownload: '<optional>',
            },
          ],
        },
      },
    ],
  },
  'Greengrass:createSoftwareUpdateJob': {
    AmznClientToken: '<optional>',
    S3UrlSignerRole: '<optional>',
    SoftwareToUpdate: '<optional>',
    UpdateAgentLogLevel: '<optional>',
    UpdateTargets: ['<optional1>', '<optional2>'],
    UpdateTargetsArchitecture: '<optional>',
    UpdateTargetsOperatingSystem: '<optional>',
  },
  'Greengrass:createSubscriptionDefinition': {
    AmznClientToken: '<optional>',
    InitialVersion: {
      Subscriptions: [
        {
          Id: '<optional>',
          Source: '<optional>',
          Subject: '<optional>',
          Target: '<optional>',
        },
      ],
    },
    Name: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:createSubscriptionDefinitionVersion': {
    SubscriptionDefinitionId: 'required',
    AmznClientToken: '<optional>',
    Subscriptions: [
      {
        Id: '<optional>',
        Source: '<optional>',
        Subject: '<optional>',
        Target: '<optional>',
      },
    ],
  },
  'Greengrass:deleteConnectorDefinition': {
    ConnectorDefinitionId: 'required',
  },
  'Greengrass:deleteCoreDefinition': {
    CoreDefinitionId: 'required',
  },
  'Greengrass:deleteDeviceDefinition': {
    DeviceDefinitionId: 'required',
  },
  'Greengrass:deleteFunctionDefinition': {
    FunctionDefinitionId: 'required',
  },
  'Greengrass:deleteGroup': {
    GroupId: 'required',
  },
  'Greengrass:deleteLoggerDefinition': {
    LoggerDefinitionId: 'required',
  },
  'Greengrass:deleteResourceDefinition': {
    ResourceDefinitionId: 'required',
  },
  'Greengrass:deleteSubscriptionDefinition': {
    SubscriptionDefinitionId: 'required',
  },
  'Greengrass:disassociateRoleFromGroup': {
    GroupId: 'required',
  },
  'Greengrass:disassociateServiceRoleFromAccount': {},
  'Greengrass:getAssociatedRole': {
    GroupId: 'required',
  },
  'Greengrass:getBulkDeploymentStatus': {
    BulkDeploymentId: 'required',
  },
  'Greengrass:getConnectivityInfo': {
    ThingName: 'required',
  },
  'Greengrass:getConnectorDefinition': {
    ConnectorDefinitionId: 'required',
  },
  'Greengrass:getConnectorDefinitionVersion': {
    ConnectorDefinitionId: 'required',
    ConnectorDefinitionVersionId: 'required',
  },
  'Greengrass:getCoreDefinition': {
    CoreDefinitionId: 'required',
  },
  'Greengrass:getCoreDefinitionVersion': {
    CoreDefinitionId: 'required',
    CoreDefinitionVersionId: 'required',
  },
  'Greengrass:getDeploymentStatus': {
    GroupId: 'required',
    DeploymentId: 'required',
  },
  'Greengrass:getDeviceDefinition': {
    DeviceDefinitionId: 'required',
  },
  'Greengrass:getDeviceDefinitionVersion': {
    DeviceDefinitionVersionId: 'required',
    DeviceDefinitionId: 'required',
  },
  'Greengrass:getFunctionDefinition': {
    FunctionDefinitionId: 'required',
  },
  'Greengrass:getFunctionDefinitionVersion': {
    FunctionDefinitionId: 'required',
    FunctionDefinitionVersionId: 'required',
  },
  'Greengrass:getGroup': {
    GroupId: 'required',
  },
  'Greengrass:getGroupCertificateAuthority': {
    CertificateAuthorityId: 'required',
    GroupId: 'required',
  },
  'Greengrass:getGroupCertificateConfiguration': {
    GroupId: 'required',
  },
  'Greengrass:getGroupVersion': {
    GroupVersionId: 'required',
    GroupId: 'required',
  },
  'Greengrass:getLoggerDefinition': {
    LoggerDefinitionId: 'required',
  },
  'Greengrass:getLoggerDefinitionVersion': {
    LoggerDefinitionVersionId: 'required',
    LoggerDefinitionId: 'required',
  },
  'Greengrass:getResourceDefinition': {
    ResourceDefinitionId: 'required',
  },
  'Greengrass:getResourceDefinitionVersion': {
    ResourceDefinitionVersionId: 'required',
    ResourceDefinitionId: 'required',
  },
  'Greengrass:getServiceRoleForAccount': {},
  'Greengrass:getSubscriptionDefinition': {
    SubscriptionDefinitionId: 'required',
  },
  'Greengrass:getSubscriptionDefinitionVersion': {
    SubscriptionDefinitionId: 'required',
    SubscriptionDefinitionVersionId: 'required',
  },
  'Greengrass:listBulkDeploymentDetailedReports': {
    BulkDeploymentId: 'required',
  },
  'Greengrass:listBulkDeployments': {},
  'Greengrass:listConnectorDefinitionVersions': {
    ConnectorDefinitionId: 'required',
  },
  'Greengrass:listConnectorDefinitions': {},
  'Greengrass:listCoreDefinitionVersions': {
    CoreDefinitionId: 'required',
  },
  'Greengrass:listCoreDefinitions': {},
  'Greengrass:listDeployments': {
    GroupId: 'required',
  },
  'Greengrass:listDeviceDefinitionVersions': {
    DeviceDefinitionId: 'required',
  },
  'Greengrass:listDeviceDefinitions': {},
  'Greengrass:listFunctionDefinitionVersions': {
    FunctionDefinitionId: 'required',
  },
  'Greengrass:listFunctionDefinitions': {},
  'Greengrass:listGroupCertificateAuthorities': {
    GroupId: 'required',
  },
  'Greengrass:listGroupVersions': {
    GroupId: 'required',
  },
  'Greengrass:listGroups': {},
  'Greengrass:listLoggerDefinitionVersions': {
    LoggerDefinitionId: 'required',
  },
  'Greengrass:listLoggerDefinitions': {},
  'Greengrass:listResourceDefinitionVersions': {
    ResourceDefinitionId: 'required',
  },
  'Greengrass:listResourceDefinitions': {},
  'Greengrass:listSubscriptionDefinitionVersions': {
    SubscriptionDefinitionId: 'required',
  },
  'Greengrass:listSubscriptionDefinitions': {},
  'Greengrass:listTagsForResource': {
    ResourceArn: 'required',
  },
  'Greengrass:resetDeployments': {
    GroupId: 'required',
    AmznClientToken: '<optional>',
    Force: '<optional>',
  },
  'Greengrass:startBulkDeployment': {
    AmznClientToken: '<optional>',
    ExecutionRoleArn: '<optional>',
    InputFileUri: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Greengrass:stopBulkDeployment': {
    BulkDeploymentId: 'required',
  },
  'Greengrass:tagResource': {
    ResourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'Greengrass:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'Greengrass:updateConnectivityInfo': {
    ThingName: 'required',
    ConnectivityInfo: {
      HostAddress: '<optional>',
      Id: '<optional>',
      Metadata: ['<optional1>', '<optional2>'],
      PortNumber: '<optional>',
    },
  },
  'Greengrass:updateConnectorDefinition': {
    ConnectorDefinitionId: 'required',
    Name: '<optional>',
  },
  'Greengrass:updateCoreDefinition': {
    CoreDefinitionId: 'required',
    Name: '<optional>',
  },
  'Greengrass:updateDeviceDefinition': {
    DeviceDefinitionId: 'required',
    Name: '<optional>',
  },
  'Greengrass:updateFunctionDefinition': {
    FunctionDefinitionId: 'required',
    Name: '<optional>',
  },
  'Greengrass:updateGroup': {
    GroupId: 'required',
    Name: '<optional>',
  },
  'Greengrass:updateGroupCertificateConfiguration': {
    GroupId: 'required',
    CertificateExpiryInMilliseconds: ['<optional1>', '<optional2>'],
  },
  'Greengrass:updateLoggerDefinition': {
    LoggerDefinitionId: 'required',
    Name: '<optional>',
  },
  'Greengrass:updateResourceDefinition': {
    ResourceDefinitionId: 'required',
    Name: '<optional>',
  },
  'Greengrass:updateSubscriptionDefinition': {
    SubscriptionDefinitionId: 'required',
    Name: '<optional>',
  },
  'GroundStation:cancelContact': {
    contactId: 'required',
  },
  'GroundStation:createConfig': {
    configData: [
      {
        antennaDownlinkConfig: {
          spectrumConfig: {
            polarization: '<optional>',
          },
        },
        antennaDownlinkDemodDecodeConfig: {
          spectrumConfig: {
            polarization: '<optional>',
          },
        },
        antennaUplinkConfig: {
          spectrumConfig: {
            polarization: '<optional>',
          },
        },
        dataflowEndpointConfig: '<optional>',
        trackingConfig: '<optional>',
        uplinkEchoConfig: '<optional>',
      },
    ],
    name: 'required',
    tags: ['<optional1>', '<optional2>'],
  },
  'GroundStation:createDataflowEndpointGroup': {
    endpointDetails: [
      {
        endpoint: {
          address: '<optional>',
          name: '<optional>',
          status: '<optional>',
        },
        securityDetails: ['<optional1>', '<optional2>'],
      },
    ],
    tags: ['<optional1>', '<optional2>'],
  },
  'GroundStation:createMissionProfile': {
    dataflowEdges: ['required1', 'required2'],
    minimumViableContactDurationSeconds: ['required1', 'required2'],
    name: 'required',
    trackingConfigArn: 'required',
    contactPostPassDurationSeconds: ['<optional1>', '<optional2>'],
    contactPrePassDurationSeconds: ['<optional1>', '<optional2>'],
    tags: ['<optional1>', '<optional2>'],
  },
  'GroundStation:deleteConfig': {
    configId: 'required',
    configType: 'required',
  },
  'GroundStation:deleteDataflowEndpointGroup': {
    dataflowEndpointGroupId: 'required',
  },
  'GroundStation:deleteMissionProfile': {
    missionProfileId: 'required',
  },
  'GroundStation:describeContact': {
    contactId: 'required',
  },
  'GroundStation:getConfig': {
    configId: 'required',
    configType: 'required',
  },
  'GroundStation:getDataflowEndpointGroup': {
    dataflowEndpointGroupId: 'required',
  },
  'GroundStation:getMinuteUsage': {
    month: 'required',
    year: 'required',
  },
  'GroundStation:getMissionProfile': {
    missionProfileId: 'required',
  },
  'GroundStation:getSatellite': {
    satelliteId: 'required',
  },
  'GroundStation:listConfigs': {},
  'GroundStation:listContacts': {
    endTime: 'required',
    startTime: 'required',
    statusList: 'required',
    groundStation: '<optional>',
    missionProfileArn: '<optional>',
    satelliteArn: '<optional>',
  },
  'GroundStation:listDataflowEndpointGroups': {},
  'GroundStation:listGroundStations': {},
  'GroundStation:listMissionProfiles': {},
  'GroundStation:listSatellites': {},
  'GroundStation:listTagsForResource': {
    resourceArn: 'required',
  },
  'GroundStation:reserveContact': {
    endTime: 'required',
    groundStation: 'required',
    missionProfileArn: 'required',
    satelliteArn: 'required',
    startTime: 'required',
    tags: ['<optional1>', '<optional2>'],
  },
  'GroundStation:tagResource': {
    resourceArn: 'required',
    tags: ['<optional1>', '<optional2>'],
  },
  'GroundStation:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'GroundStation:updateConfig': {
    configData: [
      {
        antennaDownlinkConfig: {
          spectrumConfig: {
            polarization: '<optional>',
          },
        },
        antennaDownlinkDemodDecodeConfig: {
          spectrumConfig: {
            polarization: '<optional>',
          },
        },
        antennaUplinkConfig: {
          spectrumConfig: {
            polarization: '<optional>',
          },
        },
        dataflowEndpointConfig: '<optional>',
        trackingConfig: '<optional>',
        uplinkEchoConfig: '<optional>',
      },
    ],
    configId: 'required',
    configType: 'required',
    name: 'required',
  },
  'GroundStation:updateMissionProfile': {
    missionProfileId: 'required',
    contactPostPassDurationSeconds: ['<optional1>', '<optional2>'],
    contactPrePassDurationSeconds: ['<optional1>', '<optional2>'],
    dataflowEdges: ['<optional1>', '<optional2>'],
    minimumViableContactDurationSeconds: ['<optional1>', '<optional2>'],
    name: '<optional>',
    trackingConfigArn: '<optional>',
  },
  'GuardDuty:acceptInvitation': {
    DetectorId: 'required',
    MasterId: 'required',
    InvitationId: 'required',
  },
  'GuardDuty:archiveFindings': {
    DetectorId: 'required',
    FindingIds: ['required1', 'required2'],
  },
  'GuardDuty:createDetector': {
    Enable: 'required',
    ClientToken: '<optional>',
    FindingPublishingFrequency: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'GuardDuty:createFilter': {
    DetectorId: 'required',
    Name: 'required',
    FindingCriteria: [
      {
        Criterion: '<optional>',
      },
    ],
    Description: '<optional>',
    Action: '<optional>',
    Rank: '<optional>',
    ClientToken: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'GuardDuty:createIPSet': {
    DetectorId: 'required',
    Name: 'required',
    Format: 'required',
    Location: 'required',
    Activate: 'required',
    ClientToken: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'GuardDuty:createMembers': {
    DetectorId: 'required',
    AccountDetails: [
      {
        AccountId: 'required',
        Email: 'required',
      },
    ],
  },
  'GuardDuty:createPublishingDestination': {
    DetectorId: 'required',
    DestinationType: 'required',
    DestinationProperties: [
      {
        DestinationArn: '<optional>',
        KmsKeyArn: '<optional>',
      },
    ],
    ClientToken: '<optional>',
  },
  'GuardDuty:createSampleFindings': {
    DetectorId: 'required',
    FindingTypes: ['<optional1>', '<optional2>'],
  },
  'GuardDuty:createThreatIntelSet': {
    DetectorId: 'required',
    Name: 'required',
    Format: 'required',
    Location: 'required',
    Activate: 'required',
    ClientToken: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'GuardDuty:declineInvitations': {
    AccountIds: ['required1', 'required2'],
  },
  'GuardDuty:deleteDetector': {
    DetectorId: 'required',
  },
  'GuardDuty:deleteFilter': {
    DetectorId: 'required',
    FilterName: '<optional>',
  },
  'GuardDuty:deleteIPSet': {
    DetectorId: 'required',
    IpSetId: 'required',
  },
  'GuardDuty:deleteInvitations': {
    AccountIds: ['required1', 'required2'],
  },
  'GuardDuty:deleteMembers': {
    DetectorId: 'required',
    AccountIds: ['required1', 'required2'],
  },
  'GuardDuty:deletePublishingDestination': {
    DetectorId: 'required',
    DestinationId: 'required',
  },
  'GuardDuty:deleteThreatIntelSet': {
    DetectorId: 'required',
    ThreatIntelSetId: 'required',
  },
  'GuardDuty:describePublishingDestination': {
    DetectorId: 'required',
    DestinationId: 'required',
  },
  'GuardDuty:disassociateFromMasterAccount': {
    DetectorId: 'required',
  },
  'GuardDuty:disassociateMembers': {
    DetectorId: 'required',
    AccountIds: ['required1', 'required2'],
  },
  'GuardDuty:getDetector': {
    DetectorId: 'required',
  },
  'GuardDuty:getFilter': {
    DetectorId: 'required',
    FilterName: '<optional>',
  },
  'GuardDuty:getFindings': {
    DetectorId: 'required',
    FindingIds: ['required1', 'required2'],
    SortCriteria: [
      {
        AttributeName: '<optional>',
        OrderBy: '<optional>',
      },
    ],
  },
  'GuardDuty:getFindingsStatistics': {
    DetectorId: 'required',
    FindingStatisticTypes: ['required1', 'required2'],
    FindingCriteria: [
      {
        Criterion: '<optional>',
      },
    ],
  },
  'GuardDuty:getIPSet': {
    DetectorId: 'required',
    IpSetId: 'required',
  },
  'GuardDuty:getInvitationsCount': {},
  'GuardDuty:getMasterAccount': {
    DetectorId: 'required',
  },
  'GuardDuty:getMembers': {
    DetectorId: 'required',
    AccountIds: ['required1', 'required2'],
  },
  'GuardDuty:getThreatIntelSet': {
    DetectorId: 'required',
    ThreatIntelSetId: 'required',
  },
  'GuardDuty:inviteMembers': {
    DetectorId: 'required',
    AccountIds: ['required1', 'required2'],
    DisableEmailNotification: '<optional>',
    Message: '<optional>',
  },
  'GuardDuty:listDetectors': {},
  'GuardDuty:listFilters': {
    DetectorId: 'required',
  },
  'GuardDuty:listFindings': {
    DetectorId: 'required',
    FindingCriteria: [
      {
        Criterion: '<optional>',
      },
    ],
    SortCriteria: [
      {
        AttributeName: '<optional>',
        OrderBy: '<optional>',
      },
    ],
  },
  'GuardDuty:listIPSets': {
    DetectorId: 'required',
  },
  'GuardDuty:listInvitations': {},
  'GuardDuty:listMembers': {
    DetectorId: 'required',
    OnlyAssociated: '<optional>',
  },
  'GuardDuty:listPublishingDestinations': {
    DetectorId: 'required',
  },
  'GuardDuty:listTagsForResource': {
    ResourceArn: 'required',
  },
  'GuardDuty:listThreatIntelSets': {
    DetectorId: 'required',
  },
  'GuardDuty:startMonitoringMembers': {
    DetectorId: 'required',
    AccountIds: ['required1', 'required2'],
  },
  'GuardDuty:stopMonitoringMembers': {
    DetectorId: 'required',
    AccountIds: ['required1', 'required2'],
  },
  'GuardDuty:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'GuardDuty:unarchiveFindings': {
    DetectorId: 'required',
    FindingIds: ['required1', 'required2'],
  },
  'GuardDuty:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'GuardDuty:updateDetector': {
    DetectorId: 'required',
    Enable: '<optional>',
    FindingPublishingFrequency: '<optional>',
  },
  'GuardDuty:updateFilter': {
    DetectorId: 'required',
    Description: '<optional>',
    Action: '<optional>',
    Rank: '<optional>',
    FindingCriteria: [
      {
        Criterion: '<optional>',
      },
    ],
    FilterName: '<optional>',
  },
  'GuardDuty:updateFindingsFeedback': {
    DetectorId: 'required',
    FindingIds: ['required1', 'required2'],
    Feedback: 'required',
    Comments: ['<optional1>', '<optional2>'],
  },
  'GuardDuty:updateIPSet': {
    DetectorId: 'required',
    IpSetId: 'required',
    Name: '<optional>',
    Location: '<optional>',
    Activate: '<optional>',
  },
  'GuardDuty:updatePublishingDestination': {
    DetectorId: 'required',
    DestinationId: 'required',
    DestinationProperties: [
      {
        DestinationArn: '<optional>',
        KmsKeyArn: '<optional>',
      },
    ],
  },
  'GuardDuty:updateThreatIntelSet': {
    DetectorId: 'required',
    ThreatIntelSetId: 'required',
    Name: '<optional>',
    Location: '<optional>',
    Activate: '<optional>',
  },
  'Health:describeAffectedAccountsForOrganization': {
    eventArn: 'required',
  },
  'Health:describeAffectedEntities': {
    filter: {
      entityArns: ['<optional1>', '<optional2>'],
      entityValues: ['<optional1>', '<optional2>'],
      lastUpdatedTimes: [
        {
          from: '<optional>',
          to: '<optional>',
        },
      ],
      tags: ['<optional1>', '<optional2>'],
      statusCodes: ['<optional1>', '<optional2>'],
    },
    locale: '<optional>',
  },
  'Health:describeAffectedEntitiesForOrganization': {
    organizationEntityFilters: [
      {
        eventArn: 'required',
        awsAccountId: 'required',
      },
    ],
    locale: '<optional>',
  },
  'Health:describeEntityAggregates': {
    eventArns: ['<optional1>', '<optional2>'],
  },
  'Health:describeEventAggregates': {
    aggregateField: 'required',
    filter: {
      eventArns: ['<optional1>', '<optional2>'],
      eventTypeCodes: ['<optional1>', '<optional2>'],
      services: ['<optional1>', '<optional2>'],
      regions: ['<optional1>', '<optional2>'],
      availabilityZones: ['<optional1>', '<optional2>'],
      startTimes: [
        {
          from: '<optional>',
          to: '<optional>',
        },
      ],
      endTimes: [
        {
          from: '<optional>',
          to: '<optional>',
        },
      ],
      lastUpdatedTimes: [
        {
          from: '<optional>',
          to: '<optional>',
        },
      ],
      entityArns: ['<optional1>', '<optional2>'],
      entityValues: ['<optional1>', '<optional2>'],
      eventTypeCategories: ['<optional1>', '<optional2>'],
      tags: ['<optional1>', '<optional2>'],
      eventStatusCodes: ['<optional1>', '<optional2>'],
    },
  },
  'Health:describeEventDetails': {
    eventArns: ['required1', 'required2'],
    locale: '<optional>',
  },
  'Health:describeEventDetailsForOrganization': {
    organizationEventDetailFilters: [
      {
        eventArn: 'required',
        awsAccountId: 'required',
      },
    ],
    locale: '<optional>',
  },
  'Health:describeEventTypes': {
    filter: {
      eventTypeCodes: ['<optional1>', '<optional2>'],
      services: ['<optional1>', '<optional2>'],
      eventTypeCategories: ['<optional1>', '<optional2>'],
    },
    locale: '<optional>',
  },
  'Health:describeEvents': {
    filter: {
      eventArns: ['<optional1>', '<optional2>'],
      eventTypeCodes: ['<optional1>', '<optional2>'],
      services: ['<optional1>', '<optional2>'],
      regions: ['<optional1>', '<optional2>'],
      availabilityZones: ['<optional1>', '<optional2>'],
      startTimes: [
        {
          from: '<optional>',
          to: '<optional>',
        },
      ],
      endTimes: [
        {
          from: '<optional>',
          to: '<optional>',
        },
      ],
      lastUpdatedTimes: [
        {
          from: '<optional>',
          to: '<optional>',
        },
      ],
      entityArns: ['<optional1>', '<optional2>'],
      entityValues: ['<optional1>', '<optional2>'],
      eventTypeCategories: ['<optional1>', '<optional2>'],
      tags: ['<optional1>', '<optional2>'],
      eventStatusCodes: ['<optional1>', '<optional2>'],
    },
    locale: '<optional>',
  },
  'Health:describeEventsForOrganization': {
    filter: {
      eventTypeCodes: ['<optional1>', '<optional2>'],
      awsAccountIds: ['<optional1>', '<optional2>'],
      services: ['<optional1>', '<optional2>'],
      regions: ['<optional1>', '<optional2>'],
      startTime: {
        from: '<optional>',
        to: '<optional>',
      },
      endTime: {
        from: '<optional>',
        to: '<optional>',
      },
      lastUpdatedTime: {
        from: '<optional>',
        to: '<optional>',
      },
      entityArns: ['<optional1>', '<optional2>'],
      entityValues: ['<optional1>', '<optional2>'],
      eventTypeCategories: ['<optional1>', '<optional2>'],
      eventStatusCodes: ['<optional1>', '<optional2>'],
    },
    locale: '<optional>',
  },
  'Health:describeHealthServiceStatusForOrganization': {},
  'Health:disableHealthServiceAccessForOrganization': {},
  'Health:enableHealthServiceAccessForOrganization': {},
  'IAM:addClientIDToOpenIDConnectProvider': {
    OpenIDConnectProviderArn: 'required',
    ClientID: 'required',
  },
  'IAM:addRoleToInstanceProfile': {
    InstanceProfileName: 'required',
    RoleName: 'required',
  },
  'IAM:addUserToGroup': {
    GroupName: 'required',
    UserName: 'required',
  },
  'IAM:attachGroupPolicy': {
    GroupName: 'required',
    PolicyArn: 'required',
  },
  'IAM:attachRolePolicy': {
    RoleName: 'required',
    PolicyArn: 'required',
  },
  'IAM:attachUserPolicy': {
    UserName: 'required',
    PolicyArn: 'required',
  },
  'IAM:changePassword': {
    OldPassword: 'required',
    NewPassword: 'required',
  },
  'IAM:createAccessKey': {
    UserName: '<optional>',
  },
  'IAM:createAccountAlias': {
    AccountAlias: 'required',
  },
  'IAM:createGroup': {
    GroupName: 'required',
    Path: '<optional>',
  },
  'IAM:createInstanceProfile': {
    InstanceProfileName: 'required',
    Path: '<optional>',
  },
  'IAM:createLoginProfile': {
    UserName: 'required',
    Password: 'required',
    PasswordResetRequired: '<optional>',
  },
  'IAM:createOpenIDConnectProvider': {
    Url: 'required',
    ThumbprintList: 'required',
    ClientIDList: '<optional>',
  },
  'IAM:createPolicy': {
    PolicyName: 'required',
    PolicyDocument: 'required',
    Path: '<optional>',
    Description: '<optional>',
  },
  'IAM:createPolicyVersion': {
    PolicyArn: 'required',
    PolicyDocument: 'required',
    SetAsDefault: '<optional>',
  },
  'IAM:createRole': {
    RoleName: 'required',
    AssumeRolePolicyDocument: 'required',
    Tags: ['<optional1>', '<optional2>'],
    Path: '<optional>',
    Description: '<optional>',
    MaxSessionDuration: '<optional>',
    PermissionsBoundary: '<optional>',
  },
  'IAM:createSAMLProvider': {
    SAMLMetadataDocument: 'required',
    Name: 'required',
  },
  'IAM:createServiceLinkedRole': {
    AWSServiceName: 'required',
    Description: '<optional>',
    CustomSuffix: '<optional>',
  },
  'IAM:createServiceSpecificCredential': {
    UserName: 'required',
    ServiceName: 'required',
  },
  'IAM:createUser': {
    UserName: 'required',
    Path: '<optional>',
    PermissionsBoundary: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'IAM:createVirtualMFADevice': {
    VirtualMFADeviceName: 'required',
    Path: '<optional>',
  },
  'IAM:deactivateMFADevice': {
    UserName: 'required',
    SerialNumber: 'required',
  },
  'IAM:deleteAccessKey': {
    AccessKeyId: 'required',
    UserName: '<optional>',
  },
  'IAM:deleteAccountAlias': {
    AccountAlias: 'required',
  },
  'IAM:deleteAccountPasswordPolicy': {},
  'IAM:deleteGroup': {
    GroupName: 'required',
  },
  'IAM:deleteGroupPolicy': {
    GroupName: 'required',
    PolicyName: 'required',
  },
  'IAM:deleteInstanceProfile': {
    InstanceProfileName: 'required',
  },
  'IAM:deleteLoginProfile': {
    UserName: 'required',
  },
  'IAM:deleteOpenIDConnectProvider': {
    OpenIDConnectProviderArn: 'required',
  },
  'IAM:deletePolicy': {
    PolicyArn: 'required',
  },
  'IAM:deletePolicyVersion': {
    PolicyArn: 'required',
    VersionId: 'required',
  },
  'IAM:deleteRole': {
    RoleName: 'required',
  },
  'IAM:deleteRolePermissionsBoundary': {
    RoleName: 'required',
  },
  'IAM:deleteRolePolicy': {
    RoleName: 'required',
    PolicyName: 'required',
  },
  'IAM:deleteSAMLProvider': {
    SAMLProviderArn: 'required',
  },
  'IAM:deleteSSHPublicKey': {
    UserName: 'required',
    SSHPublicKeyId: 'required',
  },
  'IAM:deleteServerCertificate': {
    ServerCertificateName: 'required',
  },
  'IAM:deleteServiceLinkedRole': {
    RoleName: 'required',
  },
  'IAM:deleteServiceSpecificCredential': {
    ServiceSpecificCredentialId: 'required',
    UserName: '<optional>',
  },
  'IAM:deleteSigningCertificate': {
    CertificateId: 'required',
    UserName: '<optional>',
  },
  'IAM:deleteUser': {
    UserName: 'required',
  },
  'IAM:deleteUserPermissionsBoundary': {
    UserName: 'required',
  },
  'IAM:deleteUserPolicy': {
    UserName: 'required',
    PolicyName: 'required',
  },
  'IAM:deleteVirtualMFADevice': {
    SerialNumber: 'required',
  },
  'IAM:detachGroupPolicy': {
    GroupName: 'required',
    PolicyArn: 'required',
  },
  'IAM:detachRolePolicy': {
    RoleName: 'required',
    PolicyArn: 'required',
  },
  'IAM:detachUserPolicy': {
    UserName: 'required',
    PolicyArn: 'required',
  },
  'IAM:enableMFADevice': {
    UserName: 'required',
    SerialNumber: 'required',
    AuthenticationCode1: 'required',
    AuthenticationCode2: 'required',
  },
  'IAM:generateCredentialReport': {},
  'IAM:generateOrganizationsAccessReport': {
    EntityPath: 'required',
    OrganizationsPolicyId: '<optional>',
  },
  'IAM:generateServiceLastAccessedDetails': {
    Arn: 'required',
  },
  'IAM:getAccessKeyLastUsed': {
    AccessKeyId: 'required',
  },
  'IAM:getAccountAuthorizationDetails': {
    Filter: '<optional>',
  },
  'IAM:getAccountPasswordPolicy': {},
  'IAM:getAccountSummary': {},
  'IAM:getContextKeysForCustomPolicy': {
    PolicyInputList: 'required',
  },
  'IAM:getContextKeysForPrincipalPolicy': {
    PolicySourceArn: 'required',
    PolicyInputList: '<optional>',
  },
  'IAM:getCredentialReport': {},
  'IAM:getGroup': {
    GroupName: 'required',
  },
  'IAM:getGroupPolicy': {
    GroupName: 'required',
    PolicyName: 'required',
  },
  'IAM:getInstanceProfile': {
    InstanceProfileName: 'required',
  },
  'IAM:getLoginProfile': {
    UserName: 'required',
  },
  'IAM:getOpenIDConnectProvider': {
    OpenIDConnectProviderArn: 'required',
  },
  'IAM:getOrganizationsAccessReport': {
    JobId: 'required',
    SortKey: '<optional>',
  },
  'IAM:getPolicy': {
    PolicyArn: 'required',
  },
  'IAM:getPolicyVersion': {
    PolicyArn: 'required',
    VersionId: 'required',
  },
  'IAM:getRole': {
    RoleName: 'required',
  },
  'IAM:getRolePolicy': {
    RoleName: 'required',
    PolicyName: 'required',
  },
  'IAM:getSAMLProvider': {
    SAMLProviderArn: 'required',
  },
  'IAM:getSSHPublicKey': {
    UserName: 'required',
    SSHPublicKeyId: 'required',
    Encoding: 'required',
  },
  'IAM:getServerCertificate': {
    ServerCertificateName: 'required',
  },
  'IAM:getServiceLastAccessedDetails': {
    JobId: 'required',
  },
  'IAM:getServiceLastAccessedDetailsWithEntities': {
    JobId: 'required',
    ServiceNamespace: 'required',
  },
  'IAM:getServiceLinkedRoleDeletionStatus': {
    DeletionTaskId: 'required',
  },
  'IAM:getUser': {
    UserName: '<optional>',
  },
  'IAM:getUserPolicy': {
    UserName: 'required',
    PolicyName: 'required',
  },
  'IAM:listAccessKeys': {
    UserName: '<optional>',
  },
  'IAM:listAccountAliases': {},
  'IAM:listAttachedGroupPolicies': {
    GroupName: 'required',
    PathPrefix: '<optional>',
  },
  'IAM:listAttachedRolePolicies': {
    RoleName: 'required',
    PathPrefix: '<optional>',
  },
  'IAM:listAttachedUserPolicies': {
    UserName: 'required',
    PathPrefix: '<optional>',
  },
  'IAM:listEntitiesForPolicy': {
    PolicyArn: 'required',
    EntityFilter: '<optional>',
    PathPrefix: '<optional>',
    PolicyUsageFilter: '<optional>',
  },
  'IAM:listGroupPolicies': {
    GroupName: 'required',
  },
  'IAM:listGroups': {
    PathPrefix: '<optional>',
  },
  'IAM:listGroupsForUser': {
    UserName: 'required',
  },
  'IAM:listInstanceProfiles': {
    PathPrefix: '<optional>',
  },
  'IAM:listInstanceProfilesForRole': {
    RoleName: 'required',
  },
  'IAM:listMFADevices': {
    UserName: '<optional>',
  },
  'IAM:listOpenIDConnectProviders': {},
  'IAM:listPolicies': {
    Scope: '<optional>',
    OnlyAttached: '<optional>',
    PathPrefix: '<optional>',
    PolicyUsageFilter: '<optional>',
  },
  'IAM:listPoliciesGrantingServiceAccess': {
    Arn: 'required',
    ServiceNamespaces: ['required1', 'required2'],
  },
  'IAM:listPolicyVersions': {
    PolicyArn: 'required',
  },
  'IAM:listRolePolicies': {
    RoleName: 'required',
  },
  'IAM:listRoleTags': {
    RoleName: 'required',
  },
  'IAM:listRoles': {
    PathPrefix: '<optional>',
  },
  'IAM:listSAMLProviders': {},
  'IAM:listSSHPublicKeys': {
    UserName: '<optional>',
  },
  'IAM:listServerCertificates': {
    PathPrefix: '<optional>',
  },
  'IAM:listServiceSpecificCredentials': {
    UserName: '<optional>',
    ServiceName: '<optional>',
  },
  'IAM:listSigningCertificates': {
    UserName: '<optional>',
  },
  'IAM:listUserPolicies': {
    UserName: 'required',
  },
  'IAM:listUserTags': {
    UserName: 'required',
  },
  'IAM:listUsers': {
    PathPrefix: '<optional>',
  },
  'IAM:listVirtualMFADevices': {
    AssignmentStatus: '<optional>',
  },
  'IAM:putGroupPolicy': {
    GroupName: 'required',
    PolicyName: 'required',
    PolicyDocument: 'required',
  },
  'IAM:putRolePermissionsBoundary': {
    RoleName: 'required',
    PermissionsBoundary: 'required',
  },
  'IAM:putRolePolicy': {
    RoleName: 'required',
    PolicyName: 'required',
    PolicyDocument: 'required',
  },
  'IAM:putUserPermissionsBoundary': {
    UserName: 'required',
    PermissionsBoundary: 'required',
  },
  'IAM:putUserPolicy': {
    UserName: 'required',
    PolicyName: 'required',
    PolicyDocument: 'required',
  },
  'IAM:removeClientIDFromOpenIDConnectProvider': {
    OpenIDConnectProviderArn: 'required',
    ClientID: 'required',
  },
  'IAM:removeRoleFromInstanceProfile': {
    InstanceProfileName: 'required',
    RoleName: 'required',
  },
  'IAM:removeUserFromGroup': {
    GroupName: 'required',
    UserName: 'required',
  },
  'IAM:resetServiceSpecificCredential': {
    ServiceSpecificCredentialId: 'required',
    UserName: '<optional>',
  },
  'IAM:resyncMFADevice': {
    UserName: 'required',
    SerialNumber: 'required',
    AuthenticationCode1: 'required',
    AuthenticationCode2: 'required',
  },
  'IAM:setDefaultPolicyVersion': {
    PolicyArn: 'required',
    VersionId: 'required',
  },
  'IAM:setSecurityTokenServicePreferences': {
    GlobalEndpointTokenVersion: 'required',
  },
  'IAM:simulateCustomPolicy': {
    PolicyInputList: 'required',
    ActionNames: ['required1', 'required2'],
    ResourceArns: ['<optional1>', '<optional2>'],
    ResourcePolicy: '<optional>',
    ResourceOwner: '<optional>',
    CallerArn: '<optional>',
    ContextEntries: [
      {
        ContextKeyName: '<optional>',
        ContextKeyValues: ['<optional1>', '<optional2>'],
        ContextKeyType: '<optional>',
      },
    ],
    ResourceHandlingOption: '<optional>',
  },
  'IAM:simulatePrincipalPolicy': {
    PolicySourceArn: 'required',
    ActionNames: ['required1', 'required2'],
    PolicyInputList: '<optional>',
    ResourceArns: ['<optional1>', '<optional2>'],
    ResourcePolicy: '<optional>',
    ResourceOwner: '<optional>',
    CallerArn: '<optional>',
    ContextEntries: [
      {
        ContextKeyName: '<optional>',
        ContextKeyValues: ['<optional1>', '<optional2>'],
        ContextKeyType: '<optional>',
      },
    ],
    ResourceHandlingOption: '<optional>',
  },
  'IAM:tagRole': {
    RoleName: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'IAM:tagUser': {
    UserName: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'IAM:untagRole': {
    RoleName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'IAM:untagUser': {
    UserName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'IAM:updateAccessKey': {
    AccessKeyId: 'required',
    Status: 'required',
    UserName: '<optional>',
  },
  'IAM:updateAccountPasswordPolicy': {
    MinimumPasswordLength: '<optional>',
    RequireSymbols: ['<optional1>', '<optional2>'],
    RequireNumbers: ['<optional1>', '<optional2>'],
    RequireUppercaseCharacters: ['<optional1>', '<optional2>'],
    RequireLowercaseCharacters: ['<optional1>', '<optional2>'],
    AllowUsersToChangePassword: '<optional>',
    MaxPasswordAge: '<optional>',
    PasswordReusePrevention: '<optional>',
    HardExpiry: '<optional>',
  },
  'IAM:updateAssumeRolePolicy': {
    RoleName: 'required',
    PolicyDocument: 'required',
  },
  'IAM:updateGroup': {
    GroupName: 'required',
    NewPath: '<optional>',
    NewGroupName: '<optional>',
  },
  'IAM:updateLoginProfile': {
    UserName: 'required',
    Password: '<optional>',
    PasswordResetRequired: '<optional>',
  },
  'IAM:updateOpenIDConnectProviderThumbprint': {
    OpenIDConnectProviderArn: 'required',
    ThumbprintList: 'required',
  },
  'IAM:updateRole': {
    RoleName: 'required',
    Description: '<optional>',
    MaxSessionDuration: '<optional>',
  },
  'IAM:updateRoleDescription': {
    RoleName: 'required',
    Description: 'required',
  },
  'IAM:updateSAMLProvider': {
    SAMLMetadataDocument: 'required',
    SAMLProviderArn: 'required',
  },
  'IAM:updateSSHPublicKey': {
    UserName: 'required',
    SSHPublicKeyId: 'required',
    Status: 'required',
  },
  'IAM:updateServerCertificate': {
    ServerCertificateName: 'required',
    NewPath: '<optional>',
    NewServerCertificateName: '<optional>',
  },
  'IAM:updateServiceSpecificCredential': {
    ServiceSpecificCredentialId: 'required',
    Status: 'required',
    UserName: '<optional>',
  },
  'IAM:updateSigningCertificate': {
    CertificateId: 'required',
    Status: 'required',
    UserName: '<optional>',
  },
  'IAM:updateUser': {
    UserName: 'required',
    NewPath: '<optional>',
    NewUserName: '<optional>',
  },
  'IAM:uploadSSHPublicKey': {
    UserName: 'required',
    SSHPublicKeyBody: 'required',
  },
  'IAM:uploadServerCertificate': {
    ServerCertificateName: 'required',
    CertificateBody: 'required',
    PrivateKey: 'required',
    Path: '<optional>',
    CertificateChain: '<optional>',
  },
  'IAM:uploadSigningCertificate': {
    CertificateBody: 'required',
    UserName: '<optional>',
  },
  'Imagebuilder:cancelImageCreation': {
    imageBuildVersionArn: 'required',
    clientToken: 'required',
  },
  'Imagebuilder:createComponent': {
    name: 'required',
    semanticVersion: 'required',
    platform: 'required',
    clientToken: 'required',
    description: '<optional>',
    changeDescription: '<optional>',
    data: ['<optional1>', '<optional2>'],
    uri: '<optional>',
    kmsKeyId: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Imagebuilder:createDistributionConfiguration': {
    name: 'required',
    distributions: [
      {
        amiDistributionConfiguration: {
          name: '<optional>',
          description: '<optional>',
          amiTags: ['<optional1>', '<optional2>'],
          launchPermission: {
            userIds: ['<optional1>', '<optional2>'],
            userGroups: ['<optional1>', '<optional2>'],
          },
        },
        licenseConfigurationArns: ['<optional1>', '<optional2>'],
      },
    ],
    clientToken: 'required',
    description: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Imagebuilder:createImage': {
    imageRecipeArn: 'required',
    infrastructureConfigurationArn: 'required',
    clientToken: 'required',
    distributionConfigurationArn: '<optional>',
    imageTestsConfiguration: {
      imageTestsEnabled: '<optional>',
      timeoutMinutes: ['<optional1>', '<optional2>'],
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'Imagebuilder:createImagePipeline': {
    name: 'required',
    imageRecipeArn: 'required',
    infrastructureConfigurationArn: 'required',
    clientToken: 'required',
    description: '<optional>',
    distributionConfigurationArn: '<optional>',
    imageTestsConfiguration: {
      imageTestsEnabled: '<optional>',
      timeoutMinutes: ['<optional1>', '<optional2>'],
    },
    schedule: {
      scheduleExpression: '<optional>',
      pipelineExecutionStartCondition: '<optional>',
    },
    status: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Imagebuilder:createImageRecipe': {
    name: 'required',
    semanticVersion: 'required',
    components: [
      {
        componentArn: 'required',
      },
    ],
    parentImage: 'required',
    clientToken: 'required',
    description: '<optional>',
    blockDeviceMappings: [
      {
        deviceName: '<optional>',
        ebs: [
          {
            encrypted: '<optional>',
            deleteOnTermination: '<optional>',
            iops: ['<optional1>', '<optional2>'],
            kmsKeyId: '<optional>',
            snapshotId: '<optional>',
            volumeSize: '<optional>',
            volumeType: '<optional>',
          },
        ],
        virtualName: '<optional>',
        noDevice: '<optional>',
      },
    ],
    tags: ['<optional1>', '<optional2>'],
  },
  'Imagebuilder:createInfrastructureConfiguration': {
    name: 'required',
    instanceProfileName: 'required',
    clientToken: 'required',
    description: '<optional>',
    instanceTypes: ['<optional1>', '<optional2>'],
    securityGroupIds: ['<optional1>', '<optional2>'],
    subnetId: '<optional>',
    logging: {
      s3Logs: [
        {
          s3BucketName: '<optional>',
          s3KeyPrefix: '<optional>',
        },
      ],
    },
    keyPair: '<optional>',
    terminateInstanceOnFailure: '<optional>',
    snsTopicArn: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Imagebuilder:deleteComponent': {
    componentBuildVersionArn: 'required',
  },
  'Imagebuilder:deleteDistributionConfiguration': {
    distributionConfigurationArn: 'required',
  },
  'Imagebuilder:deleteImage': {
    imageBuildVersionArn: 'required',
  },
  'Imagebuilder:deleteImagePipeline': {
    imagePipelineArn: 'required',
  },
  'Imagebuilder:deleteImageRecipe': {
    imageRecipeArn: 'required',
  },
  'Imagebuilder:deleteInfrastructureConfiguration': {
    infrastructureConfigurationArn: 'required',
  },
  'Imagebuilder:getComponent': {
    componentBuildVersionArn: 'required',
  },
  'Imagebuilder:getComponentPolicy': {
    componentArn: 'required',
  },
  'Imagebuilder:getDistributionConfiguration': {
    distributionConfigurationArn: 'required',
  },
  'Imagebuilder:getImage': {
    imageBuildVersionArn: 'required',
  },
  'Imagebuilder:getImagePipeline': {
    imagePipelineArn: 'required',
  },
  'Imagebuilder:getImagePolicy': {
    imageArn: 'required',
  },
  'Imagebuilder:getImageRecipe': {
    imageRecipeArn: 'required',
  },
  'Imagebuilder:getImageRecipePolicy': {
    imageRecipeArn: 'required',
  },
  'Imagebuilder:getInfrastructureConfiguration': {
    infrastructureConfigurationArn: 'required',
  },
  'Imagebuilder:importComponent': {
    name: 'required',
    semanticVersion: 'required',
    type: 'required',
    format: 'required',
    platform: 'required',
    clientToken: 'required',
    description: '<optional>',
    changeDescription: '<optional>',
    data: ['<optional1>', '<optional2>'],
    uri: '<optional>',
    kmsKeyId: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'Imagebuilder:listComponentBuildVersions': {
    componentVersionArn: 'required',
  },
  'Imagebuilder:listComponents': {
    owner: '<optional>',
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Imagebuilder:listDistributionConfigurations': {
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Imagebuilder:listImageBuildVersions': {
    imageVersionArn: 'required',
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Imagebuilder:listImagePipelineImages': {
    imagePipelineArn: '<optional>',
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Imagebuilder:listImagePipelines': {
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Imagebuilder:listImageRecipes': {
    owner: '<optional>',
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Imagebuilder:listImages': {
    owner: '<optional>',
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Imagebuilder:listInfrastructureConfigurations': {
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Imagebuilder:listTagsForResource': {
    resourceArn: 'required',
  },
  'Imagebuilder:putComponentPolicy': {
    componentArn: 'required',
    policy: 'required',
  },
  'Imagebuilder:putImagePolicy': {
    imageArn: 'required',
    policy: 'required',
  },
  'Imagebuilder:putImageRecipePolicy': {
    imageRecipeArn: 'required',
    policy: 'required',
  },
  'Imagebuilder:startImagePipelineExecution': {
    imagePipelineArn: 'required',
    clientToken: 'required',
  },
  'Imagebuilder:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'Imagebuilder:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'Imagebuilder:updateDistributionConfiguration': {
    distributionConfigurationArn: 'required',
    clientToken: 'required',
    distributions: [
      {
        amiDistributionConfiguration: {
          name: '<optional>',
          description: '<optional>',
          amiTags: ['<optional1>', '<optional2>'],
          launchPermission: {
            userIds: ['<optional1>', '<optional2>'],
            userGroups: ['<optional1>', '<optional2>'],
          },
        },
        licenseConfigurationArns: ['<optional1>', '<optional2>'],
      },
    ],
    description: '<optional>',
  },
  'Imagebuilder:updateImagePipeline': {
    imagePipelineArn: 'required',
    clientToken: 'required',
    description: '<optional>',
    imageRecipeArn: '<optional>',
    infrastructureConfigurationArn: '<optional>',
    distributionConfigurationArn: '<optional>',
    imageTestsConfiguration: {
      imageTestsEnabled: '<optional>',
      timeoutMinutes: ['<optional1>', '<optional2>'],
    },
    schedule: {
      scheduleExpression: '<optional>',
      pipelineExecutionStartCondition: '<optional>',
    },
    status: '<optional>',
  },
  'Imagebuilder:updateInfrastructureConfiguration': {
    infrastructureConfigurationArn: 'required',
    clientToken: 'required',
    description: '<optional>',
    instanceTypes: ['<optional1>', '<optional2>'],
    instanceProfileName: '<optional>',
    securityGroupIds: ['<optional1>', '<optional2>'],
    subnetId: '<optional>',
    logging: {
      s3Logs: [
        {
          s3BucketName: '<optional>',
          s3KeyPrefix: '<optional>',
        },
      ],
    },
    keyPair: '<optional>',
    terminateInstanceOnFailure: '<optional>',
    snsTopicArn: '<optional>',
  },
  'ImportExport:cancelJob': {
    JobId: 'required',
    APIVersion: '<optional>',
  },
  'ImportExport:createJob': {
    JobType: 'required',
    Manifest: 'required',
    ValidateOnly: 'required',
    ManifestAddendum: '<optional>',
    APIVersion: '<optional>',
  },
  'ImportExport:getShippingLabel': {
    jobIds: ['required1', 'required2'],
    name: '<optional>',
    company: '<optional>',
    phoneNumber: '<optional>',
    country: '<optional>',
    stateOrProvince: '<optional>',
    city: '<optional>',
    postalCode: '<optional>',
    street1: '<optional>',
    street2: '<optional>',
    street3: '<optional>',
    APIVersion: '<optional>',
  },
  'ImportExport:getStatus': {
    JobId: 'required',
    APIVersion: '<optional>',
  },
  'ImportExport:listJobs': {
    MaxJobs: ['<optional1>', '<optional2>'],
    APIVersion: '<optional>',
  },
  'ImportExport:updateJob': {
    JobId: 'required',
    Manifest: 'required',
    JobType: 'required',
    ValidateOnly: 'required',
    APIVersion: '<optional>',
  },
  'Inspector:addAttributesToFindings': {
    findingArns: ['required1', 'required2'],
    attributes: [
      {
        value: '<optional>',
      },
    ],
  },
  'Inspector:createAssessmentTarget': {
    assessmentTargetName: 'required',
    resourceGroupArn: '<optional>',
  },
  'Inspector:createAssessmentTemplate': {
    assessmentTargetArn: 'required',
    assessmentTemplateName: 'required',
    durationInSeconds: ['required1', 'required2'],
    rulesPackageArns: ['required1', 'required2'],
    userAttributesForFindings: [
      {
        value: '<optional>',
      },
    ],
  },
  'Inspector:createExclusionsPreview': {
    assessmentTemplateArn: 'required',
  },
  'Inspector:createResourceGroup': {
    resourceGroupTags: [
      {
        value: '<optional>',
      },
    ],
  },
  'Inspector:deleteAssessmentRun': {
    assessmentRunArn: 'required',
  },
  'Inspector:deleteAssessmentTarget': {
    assessmentTargetArn: 'required',
  },
  'Inspector:deleteAssessmentTemplate': {
    assessmentTemplateArn: 'required',
  },
  'Inspector:describeAssessmentRuns': {
    assessmentRunArns: ['required1', 'required2'],
  },
  'Inspector:describeAssessmentTargets': {
    assessmentTargetArns: ['required1', 'required2'],
  },
  'Inspector:describeAssessmentTemplates': {
    assessmentTemplateArns: ['required1', 'required2'],
  },
  'Inspector:describeCrossAccountAccessRole': {},
  'Inspector:describeExclusions': {
    exclusionArns: ['required1', 'required2'],
    locale: '<optional>',
  },
  'Inspector:describeFindings': {
    findingArns: ['required1', 'required2'],
    locale: '<optional>',
  },
  'Inspector:describeResourceGroups': {
    resourceGroupArns: ['required1', 'required2'],
  },
  'Inspector:describeRulesPackages': {
    rulesPackageArns: ['required1', 'required2'],
    locale: '<optional>',
  },
  'Inspector:getAssessmentReport': {
    assessmentRunArn: 'required',
    reportFileFormat: 'required',
    reportType: 'required',
  },
  'Inspector:getExclusionsPreview': {
    assessmentTemplateArn: 'required',
    previewToken: 'required',
    locale: '<optional>',
  },
  'Inspector:getTelemetryMetadata': {
    assessmentRunArn: 'required',
  },
  'Inspector:listAssessmentRunAgents': {
    assessmentRunArn: 'required',
    filter: '<optional>',
  },
  'Inspector:listAssessmentRuns': {
    assessmentTemplateArns: ['<optional1>', '<optional2>'],
    filter: {
      namePattern: '<optional>',
      states: ['<optional1>', '<optional2>'],
      durationRange: {
        minSeconds: ['<optional1>', '<optional2>'],
        maxSeconds: ['<optional1>', '<optional2>'],
      },
      rulesPackageArns: ['<optional1>', '<optional2>'],
      startTimeRange: {
        beginDate: '<optional>',
        endDate: '<optional>',
      },
      completionTimeRange: {
        beginDate: '<optional>',
        endDate: '<optional>',
      },
      stateChangeTimeRange: {
        beginDate: '<optional>',
        endDate: '<optional>',
      },
    },
  },
  'Inspector:listAssessmentTargets': {
    filter: {
      assessmentTargetNamePattern: '<optional>',
    },
  },
  'Inspector:listAssessmentTemplates': {
    assessmentTargetArns: ['<optional1>', '<optional2>'],
    filter: {
      namePattern: '<optional>',
      durationRange: {
        minSeconds: ['<optional1>', '<optional2>'],
        maxSeconds: ['<optional1>', '<optional2>'],
      },
      rulesPackageArns: ['<optional1>', '<optional2>'],
    },
  },
  'Inspector:listEventSubscriptions': {
    resourceArn: '<optional>',
  },
  'Inspector:listExclusions': {
    assessmentRunArn: 'required',
  },
  'Inspector:listFindings': {
    filter: {
      agentIds: ['<optional1>', '<optional2>'],
      autoScalingGroups: ['<optional1>', '<optional2>'],
      ruleNames: ['<optional1>', '<optional2>'],
      severities: ['<optional1>', '<optional2>'],
      rulesPackageArns: ['<optional1>', '<optional2>'],
      attributes: [
        {
          value: '<optional>',
        },
      ],
      userAttributes: [
        {
          value: '<optional>',
        },
      ],
      creationTimeRange: {
        beginDate: '<optional>',
        endDate: '<optional>',
      },
    },
    assessmentRunArns: ['<optional1>', '<optional2>'],
  },
  'Inspector:listRulesPackages': {},
  'Inspector:listTagsForResource': {
    resourceArn: 'required',
  },
  'Inspector:previewAgents': {
    previewAgentsArn: 'required',
  },
  'Inspector:registerCrossAccountAccessRole': {
    roleArn: 'required',
  },
  'Inspector:removeAttributesFromFindings': {
    findingArns: ['required1', 'required2'],
    attributeKeys: ['required1', 'required2'],
  },
  'Inspector:setTagsForResource': {
    resourceArn: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
  },
  'Inspector:startAssessmentRun': {
    assessmentTemplateArn: 'required',
    assessmentRunName: '<optional>',
  },
  'Inspector:stopAssessmentRun': {
    assessmentRunArn: 'required',
    stopAction: '<optional>',
  },
  'Inspector:subscribeToEvent': {
    resourceArn: 'required',
    event: 'required',
    topicArn: 'required',
  },
  'Inspector:unsubscribeFromEvent': {
    resourceArn: 'required',
    event: 'required',
    topicArn: 'required',
  },
  'Inspector:updateAssessmentTarget': {
    assessmentTargetArn: 'required',
    assessmentTargetName: 'required',
    resourceGroupArn: '<optional>',
  },
  'IoT1ClickDevicesService:claimDevicesByClaimCode': {
    ClaimCode: 'required',
  },
  'IoT1ClickDevicesService:describeDevice': {
    DeviceId: 'required',
  },
  'IoT1ClickDevicesService:finalizeDeviceClaim': {
    DeviceId: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'IoT1ClickDevicesService:getDeviceMethods': {
    DeviceId: 'required',
  },
  'IoT1ClickDevicesService:initiateDeviceClaim': {
    DeviceId: 'required',
  },
  'IoT1ClickDevicesService:invokeDeviceMethod': {
    DeviceId: 'required',
    DeviceMethod: {
      DeviceType: '<optional>',
      MethodName: '<optional>',
    },
    DeviceMethodParameters: ['<optional1>', '<optional2>'],
  },
  'IoT1ClickDevicesService:listDeviceEvents': {
    DeviceId: 'required',
    FromTimeStamp: 'required',
    ToTimeStamp: 'required',
  },
  'IoT1ClickDevicesService:listDevices': {
    DeviceType: '<optional>',
  },
  'IoT1ClickDevicesService:listTagsForResource': {
    ResourceArn: 'required',
  },
  'IoT1ClickDevicesService:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'IoT1ClickDevicesService:unclaimDevice': {
    DeviceId: 'required',
  },
  'IoT1ClickDevicesService:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'IoT1ClickDevicesService:updateDeviceState': {
    DeviceId: 'required',
    Enabled: '<optional>',
  },
  'IoT1ClickProjects:associateDeviceWithPlacement': {
    projectName: 'required',
    placementName: 'required',
    deviceId: 'required',
    deviceTemplateName: 'required',
  },
  'IoT1ClickProjects:createPlacement': {
    placementName: 'required',
    projectName: 'required',
    attributes: ['<optional1>', '<optional2>'],
  },
  'IoT1ClickProjects:createProject': {
    projectName: 'required',
    description: '<optional>',
    placementTemplate: {
      defaultAttributes: ['<optional1>', '<optional2>'],
      deviceTemplates: ['<optional1>', '<optional2>'],
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'IoT1ClickProjects:deletePlacement': {
    placementName: 'required',
    projectName: 'required',
  },
  'IoT1ClickProjects:deleteProject': {
    projectName: 'required',
  },
  'IoT1ClickProjects:describePlacement': {
    placementName: 'required',
    projectName: 'required',
  },
  'IoT1ClickProjects:describeProject': {
    projectName: 'required',
  },
  'IoT1ClickProjects:disassociateDeviceFromPlacement': {
    projectName: 'required',
    placementName: 'required',
    deviceTemplateName: 'required',
  },
  'IoT1ClickProjects:getDevicesInPlacement': {
    projectName: 'required',
    placementName: 'required',
  },
  'IoT1ClickProjects:listPlacements': {
    projectName: 'required',
  },
  'IoT1ClickProjects:listProjects': {},
  'IoT1ClickProjects:listTagsForResource': {
    resourceArn: 'required',
  },
  'IoT1ClickProjects:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'IoT1ClickProjects:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'IoT1ClickProjects:updatePlacement': {
    placementName: 'required',
    projectName: 'required',
    attributes: ['<optional1>', '<optional2>'],
  },
  'IoT1ClickProjects:updateProject': {
    projectName: 'required',
    description: '<optional>',
    placementTemplate: {
      defaultAttributes: ['<optional1>', '<optional2>'],
      deviceTemplates: ['<optional1>', '<optional2>'],
    },
  },
  'IoTAnalytics:batchPutMessage': {
    channelName: 'required',
    messages: [
      {
        messageId: 'required',
        payload: 'required',
      },
    ],
  },
  'IoTAnalytics:cancelPipelineReprocessing': {
    pipelineName: 'required',
    reprocessingId: 'required',
  },
  'IoTAnalytics:createChannel': {
    channelName: 'required',
    channelStorage: {
      serviceManagedS3: '<optional>',
      customerManagedS3: {
        keyPrefix: '<optional>',
      },
    },
    tags: ['<optional1>', '<optional2>'],
    retentionPeriod: {
      unlimited: '<optional>',
      numberOfDays: ['<optional1>', '<optional2>'],
    },
  },
  'IoTAnalytics:createDataset': {
    datasetName: 'required',
    actions: [
      {
        actionName: '<optional>',
        queryAction: {
          filters: [
            {
              deltaTime: '<optional>',
            },
          ],
        },
        containerAction: {
          variables: [
            {
              stringValue: '<optional>',
              doubleValue: '<optional>',
              datasetContentVersionValue: '<optional>',
              outputFileUriValue: '<optional>',
            },
          ],
        },
      },
    ],
    triggers: [
      {
        schedule: {
          expression: '<optional>',
        },
        dataset: '<optional>',
      },
    ],
    contentDeliveryRules: [
      {
        entryName: '<optional>',
        destination: {
          iotEventsDestinationConfiguration: '<optional>',
          s3DestinationConfiguration: {
            glueConfiguration: '<optional>',
          },
        },
      },
    ],
    tags: ['<optional1>', '<optional2>'],
    retentionPeriod: {
      unlimited: '<optional>',
      numberOfDays: ['<optional1>', '<optional2>'],
    },
    versioningConfiguration: {
      unlimited: '<optional>',
      maxVersions: ['<optional1>', '<optional2>'],
    },
  },
  'IoTAnalytics:createDatasetContent': {
    datasetName: 'required',
  },
  'IoTAnalytics:createDatastore': {
    datastoreName: 'required',
    datastoreStorage: {
      serviceManagedS3: '<optional>',
      customerManagedS3: {
        keyPrefix: '<optional>',
      },
    },
    tags: ['<optional1>', '<optional2>'],
    retentionPeriod: {
      unlimited: '<optional>',
      numberOfDays: ['<optional1>', '<optional2>'],
    },
  },
  'IoTAnalytics:createPipeline': {
    pipelineName: 'required',
    pipelineActivities: [
      {
        channel: {
          next: '<optional>',
        },
        lambda: {
          next: '<optional>',
        },
        datastore: '<optional>',
        addAttributes: [
          {
            next: '<optional>',
          },
        ],
        removeAttributes: [
          {
            next: '<optional>',
          },
        ],
        selectAttributes: [
          {
            next: '<optional>',
          },
        ],
        filter: {
          next: '<optional>',
        },
        math: {
          next: '<optional>',
        },
        deviceRegistryEnrich: {
          next: '<optional>',
        },
        deviceShadowEnrich: {
          next: '<optional>',
        },
      },
    ],
    tags: ['<optional1>', '<optional2>'],
  },
  'IoTAnalytics:deleteChannel': {
    channelName: 'required',
  },
  'IoTAnalytics:deleteDataset': {
    datasetName: 'required',
  },
  'IoTAnalytics:deleteDatasetContent': {
    datasetName: 'required',
    versionId: '<optional>',
  },
  'IoTAnalytics:deleteDatastore': {
    datastoreName: 'required',
  },
  'IoTAnalytics:deletePipeline': {
    pipelineName: 'required',
  },
  'IoTAnalytics:describeChannel': {
    channelName: 'required',
    includeStatistics: ['<optional1>', '<optional2>'],
  },
  'IoTAnalytics:describeDataset': {
    datasetName: 'required',
  },
  'IoTAnalytics:describeDatastore': {
    datastoreName: 'required',
    includeStatistics: ['<optional1>', '<optional2>'],
  },
  'IoTAnalytics:describeLoggingOptions': {},
  'IoTAnalytics:describePipeline': {
    pipelineName: 'required',
  },
  'IoTAnalytics:getDatasetContent': {
    datasetName: 'required',
    versionId: '<optional>',
  },
  'IoTAnalytics:listChannels': {},
  'IoTAnalytics:listDatasetContents': {
    datasetName: 'required',
    scheduledOnOrAfter: '<optional>',
    scheduledBefore: '<optional>',
  },
  'IoTAnalytics:listDatasets': {},
  'IoTAnalytics:listDatastores': {},
  'IoTAnalytics:listPipelines': {},
  'IoTAnalytics:listTagsForResource': {
    resourceArn: 'required',
  },
  'IoTAnalytics:putLoggingOptions': {
    loggingOptions: [
      {
        roleArn: 'required',
        level: 'required',
        enabled: 'required',
      },
    ],
  },
  'IoTAnalytics:runPipelineActivity': {
    pipelineActivity: {
      channel: {
        next: '<optional>',
      },
      lambda: {
        next: '<optional>',
      },
      datastore: '<optional>',
      addAttributes: [
        {
          next: '<optional>',
        },
      ],
      removeAttributes: [
        {
          next: '<optional>',
        },
      ],
      selectAttributes: [
        {
          next: '<optional>',
        },
      ],
      filter: {
        next: '<optional>',
      },
      math: {
        next: '<optional>',
      },
      deviceRegistryEnrich: {
        next: '<optional>',
      },
      deviceShadowEnrich: {
        next: '<optional>',
      },
    },
    payloads: ['required1', 'required2'],
  },
  'IoTAnalytics:sampleChannelData': {
    channelName: 'required',
    maxMessages: ['<optional1>', '<optional2>'],
    startTime: '<optional>',
    endTime: '<optional>',
  },
  'IoTAnalytics:startPipelineReprocessing': {
    pipelineName: 'required',
    startTime: '<optional>',
    endTime: '<optional>',
  },
  'IoTAnalytics:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        key: 'required',
        value: 'required',
      },
    ],
  },
  'IoTAnalytics:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'IoTAnalytics:updateChannel': {
    channelName: 'required',
    channelStorage: {
      serviceManagedS3: {
        type: '<optional>',
      },
      customerManagedS3: {
        keyPrefix: '<optional>',
      },
    },
    retentionPeriod: {
      unlimited: '<optional>',
      numberOfDays: ['<optional1>', '<optional2>'],
    },
  },
  'IoTAnalytics:updateDataset': {
    datasetName: 'required',
    actions: [
      {
        actionName: '<optional>',
        queryAction: {
          filters: [
            {
              deltaTime: '<optional>',
            },
          ],
        },
        containerAction: {
          variables: [
            {
              stringValue: '<optional>',
              doubleValue: '<optional>',
              datasetContentVersionValue: '<optional>',
              outputFileUriValue: '<optional>',
            },
          ],
        },
      },
    ],
    triggers: [
      {
        schedule: {
          expression: '<optional>',
        },
        dataset: '<optional>',
      },
    ],
    contentDeliveryRules: [
      {
        entryName: '<optional>',
        destination: {
          iotEventsDestinationConfiguration: '<optional>',
          s3DestinationConfiguration: {
            glueConfiguration: '<optional>',
          },
        },
      },
    ],
    retentionPeriod: {
      unlimited: '<optional>',
      numberOfDays: ['<optional1>', '<optional2>'],
    },
    versioningConfiguration: {
      unlimited: '<optional>',
      maxVersions: ['<optional1>', '<optional2>'],
    },
  },
  'IoTAnalytics:updateDatastore': {
    datastoreName: 'required',
    datastoreStorage: {
      serviceManagedS3: {
        type: '<optional>',
      },
      customerManagedS3: {
        keyPrefix: '<optional>',
      },
    },
    retentionPeriod: {
      unlimited: '<optional>',
      numberOfDays: ['<optional1>', '<optional2>'],
    },
  },
  'IoTAnalytics:updatePipeline': {
    pipelineName: 'required',
    pipelineActivities: [
      {
        channel: {
          next: '<optional>',
        },
        lambda: {
          next: '<optional>',
        },
        datastore: '<optional>',
        addAttributes: [
          {
            next: '<optional>',
          },
        ],
        removeAttributes: [
          {
            next: '<optional>',
          },
        ],
        selectAttributes: [
          {
            next: '<optional>',
          },
        ],
        filter: {
          next: '<optional>',
        },
        math: {
          next: '<optional>',
        },
        deviceRegistryEnrich: {
          next: '<optional>',
        },
        deviceShadowEnrich: {
          next: '<optional>',
        },
      },
    ],
  },
  'IoTEvents:createDetectorModel': {
    detectorModelName: 'required',
    detectorModelDefinition: {
      states: [
        {
          onInput: {
            eventIds: [
              {
                condition: '<optional>',
                actions: [
                  {
                    setVariable: '<optional>',
                    sns: ['<optional1>', '<optional2>'],
                    iotTopicPublish: '<optional>',
                    setTimer: '<optional>',
                    clearTimer: '<optional>',
                    resetTimer: '<optional>',
                    lambda: '<optional>',
                    iotEvents: ['<optional1>', '<optional2>'],
                    sqs: [
                      {
                        useBase64: '<optional>',
                      },
                    ],
                    firehose: {
                      separator: '<optional>',
                    },
                  },
                ],
              },
            ],
            transitionEvents: [
              {
                actions: [
                  {
                    setVariable: '<optional>',
                    sns: ['<optional1>', '<optional2>'],
                    iotTopicPublish: '<optional>',
                    setTimer: '<optional>',
                    clearTimer: '<optional>',
                    resetTimer: '<optional>',
                    lambda: '<optional>',
                    iotEvents: ['<optional1>', '<optional2>'],
                    sqs: [
                      {
                        useBase64: '<optional>',
                      },
                    ],
                    firehose: {
                      separator: '<optional>',
                    },
                  },
                ],
              },
            ],
          },
          onEnter: {
            eventIds: [
              {
                condition: '<optional>',
                actions: [
                  {
                    setVariable: '<optional>',
                    sns: ['<optional1>', '<optional2>'],
                    iotTopicPublish: '<optional>',
                    setTimer: '<optional>',
                    clearTimer: '<optional>',
                    resetTimer: '<optional>',
                    lambda: '<optional>',
                    iotEvents: ['<optional1>', '<optional2>'],
                    sqs: [
                      {
                        useBase64: '<optional>',
                      },
                    ],
                    firehose: {
                      separator: '<optional>',
                    },
                  },
                ],
              },
            ],
          },
          onExit: {
            eventIds: [
              {
                condition: '<optional>',
                actions: [
                  {
                    setVariable: '<optional>',
                    sns: ['<optional1>', '<optional2>'],
                    iotTopicPublish: '<optional>',
                    setTimer: '<optional>',
                    clearTimer: '<optional>',
                    resetTimer: '<optional>',
                    lambda: '<optional>',
                    iotEvents: ['<optional1>', '<optional2>'],
                    sqs: [
                      {
                        useBase64: '<optional>',
                      },
                    ],
                    firehose: {
                      separator: '<optional>',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    roleArn: 'required',
    tags: ['<optional1>', '<optional2>'],
    detectorModelDescription: '<optional>',
    key: '<optional>',
    evaluationMethod: '<optional>',
  },
  'IoTEvents:createInput': {
    inputName: 'required',
    inputDefinition: {
      attributes: [
        {
          jsonPath: 'required',
        },
      ],
    },
    tags: ['<optional1>', '<optional2>'],
    inputDescription: '<optional>',
  },
  'IoTEvents:deleteDetectorModel': {
    detectorModelName: 'required',
  },
  'IoTEvents:deleteInput': {
    inputName: 'required',
  },
  'IoTEvents:describeDetectorModel': {
    detectorModelName: 'required',
    detectorModelVersion: '<optional>',
  },
  'IoTEvents:describeInput': {
    inputName: 'required',
  },
  'IoTEvents:describeLoggingOptions': {},
  'IoTEvents:listDetectorModelVersions': {
    detectorModelName: 'required',
  },
  'IoTEvents:listDetectorModels': {},
  'IoTEvents:listInputs': {},
  'IoTEvents:listTagsForResource': {
    resourceArn: 'required',
  },
  'IoTEvents:putLoggingOptions': {
    loggingOptions: [
      {
        detectorDebugOptions: [
          {
            keyValue: '<optional>',
          },
        ],
      },
    ],
  },
  'IoTEvents:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        key: 'required',
        value: 'required',
      },
    ],
  },
  'IoTEvents:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'IoTEvents:updateDetectorModel': {
    detectorModelName: 'required',
    detectorModelDefinition: {
      states: [
        {
          onInput: {
            eventIds: [
              {
                condition: '<optional>',
                actions: [
                  {
                    setVariable: '<optional>',
                    sns: ['<optional1>', '<optional2>'],
                    iotTopicPublish: '<optional>',
                    setTimer: '<optional>',
                    clearTimer: '<optional>',
                    resetTimer: '<optional>',
                    lambda: '<optional>',
                    iotEvents: ['<optional1>', '<optional2>'],
                    sqs: [
                      {
                        useBase64: '<optional>',
                      },
                    ],
                    firehose: {
                      separator: '<optional>',
                    },
                  },
                ],
              },
            ],
            transitionEvents: [
              {
                actions: [
                  {
                    setVariable: '<optional>',
                    sns: ['<optional1>', '<optional2>'],
                    iotTopicPublish: '<optional>',
                    setTimer: '<optional>',
                    clearTimer: '<optional>',
                    resetTimer: '<optional>',
                    lambda: '<optional>',
                    iotEvents: ['<optional1>', '<optional2>'],
                    sqs: [
                      {
                        useBase64: '<optional>',
                      },
                    ],
                    firehose: {
                      separator: '<optional>',
                    },
                  },
                ],
              },
            ],
          },
          onEnter: {
            eventIds: [
              {
                condition: '<optional>',
                actions: [
                  {
                    setVariable: '<optional>',
                    sns: ['<optional1>', '<optional2>'],
                    iotTopicPublish: '<optional>',
                    setTimer: '<optional>',
                    clearTimer: '<optional>',
                    resetTimer: '<optional>',
                    lambda: '<optional>',
                    iotEvents: ['<optional1>', '<optional2>'],
                    sqs: [
                      {
                        useBase64: '<optional>',
                      },
                    ],
                    firehose: {
                      separator: '<optional>',
                    },
                  },
                ],
              },
            ],
          },
          onExit: {
            eventIds: [
              {
                condition: '<optional>',
                actions: [
                  {
                    setVariable: '<optional>',
                    sns: ['<optional1>', '<optional2>'],
                    iotTopicPublish: '<optional>',
                    setTimer: '<optional>',
                    clearTimer: '<optional>',
                    resetTimer: '<optional>',
                    lambda: '<optional>',
                    iotEvents: ['<optional1>', '<optional2>'],
                    sqs: [
                      {
                        useBase64: '<optional>',
                      },
                    ],
                    firehose: {
                      separator: '<optional>',
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    roleArn: 'required',
    detectorModelDescription: '<optional>',
    evaluationMethod: '<optional>',
  },
  'IoTEvents:updateInput': {
    inputName: 'required',
    inputDefinition: {
      attributes: [
        {
          jsonPath: 'required',
        },
      ],
    },
    inputDescription: '<optional>',
  },
  'IoTEventsData:batchPutMessage': {
    messages: [
      {
        messageId: 'required',
        inputName: 'required',
        payload: 'required',
      },
    ],
  },
  'IoTEventsData:batchUpdateDetector': {
    detectors: [
      {
        keyValue: '<optional>',
      },
    ],
  },
  'IoTEventsData:describeDetector': {
    detectorModelName: 'required',
    keyValue: '<optional>',
  },
  'IoTEventsData:listDetectors': {
    detectorModelName: 'required',
    stateName: '<optional>',
  },
  'IoTJobsDataPlane:describeJobExecution': {
    jobId: 'required',
    thingName: 'required',
    includeJobDocument: '<optional>',
    executionNumber: '<optional>',
  },
  'IoTJobsDataPlane:getPendingJobExecutions': {
    thingName: 'required',
  },
  'IoTJobsDataPlane:startNextPendingJobExecution': {
    thingName: 'required',
    statusDetails: ['<optional1>', '<optional2>'],
    stepTimeoutInMinutes: ['<optional1>', '<optional2>'],
  },
  'IoTJobsDataPlane:updateJobExecution': {
    jobId: 'required',
    thingName: 'required',
    status: 'required',
    statusDetails: ['<optional1>', '<optional2>'],
    stepTimeoutInMinutes: ['<optional1>', '<optional2>'],
    expectedVersion: '<optional>',
    includeJobExecutionState: '<optional>',
    includeJobDocument: '<optional>',
    executionNumber: '<optional>',
  },
  'IoTSecureTunneling:closeTunnel': {
    tunnelId: 'required',
    delete: '<optional>',
  },
  'IoTSecureTunneling:describeTunnel': {
    tunnelId: 'required',
  },
  'IoTSecureTunneling:listTagsForResource': {
    resourceArn: 'required',
  },
  'IoTSecureTunneling:listTunnels': {
    thingName: '<optional>',
  },
  'IoTSecureTunneling:openTunnel': {
    tags: ['<optional1>', '<optional2>'],
    destinationConfig: '<optional>',
    description: '<optional>',
    timeoutConfig: {
      maxLifetimeTimeoutMinutes: ['<optional1>', '<optional2>'],
    },
  },
  'IoTSecureTunneling:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        key: 'required',
        value: 'required',
      },
    ],
  },
  'IoTSecureTunneling:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'IoTThingsGraph:associateEntityToThing': {
    thingName: 'required',
    entityId: 'required',
    namespaceVersion: '<optional>',
  },
  'IoTThingsGraph:createFlowTemplate': {
    definition: {
      language: 'required',
      text: 'required',
    },
    compatibleNamespaceVersion: '<optional>',
  },
  'IoTThingsGraph:createSystemInstance': {
    definition: {
      language: 'required',
      text: 'required',
    },
    target: 'required',
    tags: ['<optional1>', '<optional2>'],
    greengrassGroupName: '<optional>',
    s3BucketName: '<optional>',
    metricsConfiguration: {
      cloudMetricEnabled: '<optional>',
      metricRuleRoleArn: '<optional>',
    },
    flowActionsRoleArn: '<optional>',
  },
  'IoTThingsGraph:createSystemTemplate': {
    definition: {
      language: 'required',
      text: 'required',
    },
    compatibleNamespaceVersion: '<optional>',
  },
  'IoTThingsGraph:deleteFlowTemplate': {
    id: 'required',
  },
  'IoTThingsGraph:deleteNamespace': {},
  'IoTThingsGraph:deleteSystemInstance': {
    id: '<optional>',
  },
  'IoTThingsGraph:deleteSystemTemplate': {
    id: 'required',
  },
  'IoTThingsGraph:deploySystemInstance': {
    id: '<optional>',
  },
  'IoTThingsGraph:deprecateFlowTemplate': {
    id: 'required',
  },
  'IoTThingsGraph:deprecateSystemTemplate': {
    id: 'required',
  },
  'IoTThingsGraph:describeNamespace': {
    namespaceName: '<optional>',
  },
  'IoTThingsGraph:dissociateEntityFromThing': {
    thingName: 'required',
    entityType: 'required',
  },
  'IoTThingsGraph:getEntities': {
    ids: ['required1', 'required2'],
    namespaceVersion: '<optional>',
  },
  'IoTThingsGraph:getFlowTemplate': {
    id: 'required',
    revisionNumber: '<optional>',
  },
  'IoTThingsGraph:getFlowTemplateRevisions': {
    id: 'required',
  },
  'IoTThingsGraph:getNamespaceDeletionStatus': {},
  'IoTThingsGraph:getSystemInstance': {
    id: 'required',
  },
  'IoTThingsGraph:getSystemTemplate': {
    id: 'required',
    revisionNumber: '<optional>',
  },
  'IoTThingsGraph:getSystemTemplateRevisions': {
    id: 'required',
  },
  'IoTThingsGraph:getUploadStatus': {
    uploadId: 'required',
  },
  'IoTThingsGraph:listFlowExecutionMessages': {
    flowExecutionId: 'required',
  },
  'IoTThingsGraph:listTagsForResource': {
    resourceArn: 'required',
  },
  'IoTThingsGraph:searchEntities': {
    entityTypes: ['required1', 'required2'],
    filters: [
      {
        name: '<optional>',
        value: '<optional>',
      },
    ],
    namespaceVersion: '<optional>',
  },
  'IoTThingsGraph:searchFlowExecutions': {
    systemInstanceId: 'required',
    flowExecutionId: '<optional>',
    startTime: '<optional>',
    endTime: '<optional>',
  },
  'IoTThingsGraph:searchFlowTemplates': {
    filters: ['<optional1>', '<optional2>'],
  },
  'IoTThingsGraph:searchSystemInstances': {
    filters: [
      {
        name: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'IoTThingsGraph:searchSystemTemplates': {
    filters: ['<optional1>', '<optional2>'],
  },
  'IoTThingsGraph:searchThings': {
    entityId: 'required',
    namespaceVersion: '<optional>',
  },
  'IoTThingsGraph:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        key: 'required',
        value: 'required',
      },
    ],
  },
  'IoTThingsGraph:undeploySystemInstance': {
    id: '<optional>',
  },
  'IoTThingsGraph:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'IoTThingsGraph:updateFlowTemplate': {
    id: 'required',
    definition: {
      language: 'required',
      text: 'required',
    },
    compatibleNamespaceVersion: '<optional>',
  },
  'IoTThingsGraph:updateSystemTemplate': {
    id: 'required',
    definition: {
      language: 'required',
      text: 'required',
    },
    compatibleNamespaceVersion: '<optional>',
  },
  'IoTThingsGraph:uploadEntityDefinitions': {
    document: '<optional>',
    syncWithPublicNamespace: '<optional>',
    deprecateExistingEntities: ['<optional1>', '<optional2>'],
  },
  'Iot:acceptCertificateTransfer': {
    certificateId: 'required',
    setAsActive: '<optional>',
  },
  'Iot:addThingToBillingGroup': {
    billingGroupName: '<optional>',
    billingGroupArn: '<optional>',
    thingName: '<optional>',
    thingArn: '<optional>',
  },
  'Iot:addThingToThingGroup': {
    thingGroupName: '<optional>',
    thingGroupArn: '<optional>',
    thingName: '<optional>',
    thingArn: '<optional>',
    overrideDynamicGroups: ['<optional1>', '<optional2>'],
  },
  'Iot:associateTargetsWithJob': {
    targets: ['required1', 'required2'],
    jobId: 'required',
    comment: '<optional>',
  },
  'Iot:attachPolicy': {
    policyName: 'required',
    target: 'required',
  },
  'Iot:attachPrincipalPolicy': {
    policyName: 'required',
    principal: 'required',
  },
  'Iot:attachSecurityProfile': {
    securityProfileName: 'required',
    securityProfileTargetArn: 'required',
  },
  'Iot:attachThingPrincipal': {
    thingName: 'required',
    principal: 'required',
  },
  'Iot:cancelAuditMitigationActionsTask': {
    taskId: 'required',
  },
  'Iot:cancelAuditTask': {
    taskId: 'required',
  },
  'Iot:cancelCertificateTransfer': {
    certificateId: 'required',
  },
  'Iot:cancelJob': {
    jobId: 'required',
    reasonCode: '<optional>',
    comment: '<optional>',
    force: '<optional>',
  },
  'Iot:cancelJobExecution': {
    jobId: 'required',
    thingName: 'required',
    force: '<optional>',
    expectedVersion: '<optional>',
    statusDetails: ['<optional1>', '<optional2>'],
  },
  'Iot:clearDefaultAuthorizer': {},
  'Iot:confirmTopicRuleDestination': {
    confirmationToken: 'required',
  },
  'Iot:createAuthorizer': {
    authorizerName: 'required',
    authorizerFunctionArn: 'required',
    tokenKeyName: 'required',
    tokenSigningPublicKeys: ['required1', 'required2'],
    status: '<optional>',
  },
  'Iot:createBillingGroup': {
    billingGroupName: 'required',
    billingGroupProperties: [
      {
        billingGroupDescription: '<optional>',
      },
    ],
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createCertificateFromCsr': {
    certificateSigningRequest: 'required',
    setAsActive: '<optional>',
  },
  'Iot:createDomainConfiguration': {
    domainConfigurationName: 'required',
    domainName: '<optional>',
    serverCertificateArns: ['<optional1>', '<optional2>'],
    validationCertificateArn: '<optional>',
    authorizerConfig: {
      defaultAuthorizerName: '<optional>',
      allowAuthorizerOverride: '<optional>',
    },
    serviceType: '<optional>',
  },
  'Iot:createDynamicThingGroup': {
    thingGroupName: 'required',
    queryString: 'required',
    thingGroupProperties: [
      {
        thingGroupDescription: '<optional>',
        attributePayload: {
          attributes: ['<optional1>', '<optional2>'],
          merge: '<optional>',
        },
      },
    ],
    indexName: '<optional>',
    queryVersion: '<optional>',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createJob': {
    jobId: 'required',
    targets: ['required1', 'required2'],
    jobExecutionsRolloutConfig: {
      maximumPerMinute: '<optional>',
      exponentialRate: {
        rateIncreaseCriteria: [
          {
            numberOfNotifiedThings: ['<optional1>', '<optional2>'],
            numberOfSucceededThings: ['<optional1>', '<optional2>'],
          },
        ],
      },
    },
    abortConfig: '<optional>',
    documentSource: '<optional>',
    document: '<optional>',
    description: '<optional>',
    presignedUrlConfig: {
      roleArn: '<optional>',
      expiresInSec: '<optional>',
    },
    targetSelection: '<optional>',
    timeoutConfig: {
      inProgressTimeoutInMinutes: ['<optional1>', '<optional2>'],
    },
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createKeysAndCertificate': {
    setAsActive: '<optional>',
  },
  'Iot:createMitigationAction': {
    actionName: 'required',
    roleArn: 'required',
    actionParams: [
      {
        updateDeviceCertificateParams: ['<optional1>', '<optional2>'],
        updateCACertificateParams: ['<optional1>', '<optional2>'],
        addThingsToThingGroupParams: [
          {
            overrideDynamicGroups: ['<optional1>', '<optional2>'],
          },
        ],
        replaceDefaultPolicyVersionParams: ['<optional1>', '<optional2>'],
        enableIoTLoggingParams: ['<optional1>', '<optional2>'],
        publishFindingToSnsParams: ['<optional1>', '<optional2>'],
      },
    ],
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createOTAUpdate': {
    otaUpdateId: 'required',
    targets: ['required1', 'required2'],
    files: [
      {
        fileName: '<optional>',
        fileVersion: '<optional>',
        fileLocation: {
          stream: {
            streamId: '<optional>',
            fileId: '<optional>',
          },
          s3Location: {
            bucket: '<optional>',
            key: '<optional>',
            version: '<optional>',
          },
        },
        codeSigning: {
          awsSignerJobId: '<optional>',
          startSigningJobParameter: {
            signingProfileParameter: {
              certificateArn: '<optional>',
              platform: '<optional>',
              certificatePathOnDevice: '<optional>',
            },
            signingProfileName: '<optional>',
            destination: {
              s3Destination: {
                bucket: '<optional>',
                prefix: '<optional>',
              },
            },
          },
          customCodeSigning: {
            signature: {
              inlineDocument: '<optional>',
            },
            certificateChain: {
              certificateName: '<optional>',
              inlineDocument: '<optional>',
            },
            hashAlgorithm: '<optional>',
            signatureAlgorithm: '<optional>',
          },
        },
        attributes: ['<optional1>', '<optional2>'],
      },
    ],
    roleArn: 'required',
    description: '<optional>',
    targetSelection: '<optional>',
    awsJobExecutionsRolloutConfig: {
      maximumPerMinute: '<optional>',
    },
    additionalParameters: ['<optional1>', '<optional2>'],
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createPolicy': {
    policyName: 'required',
    policyDocument: 'required',
  },
  'Iot:createPolicyVersion': {
    policyName: 'required',
    policyDocument: 'required',
    setAsDefault: '<optional>',
  },
  'Iot:createProvisioningClaim': {
    templateName: 'required',
  },
  'Iot:createProvisioningTemplate': {
    templateName: 'required',
    templateBody: 'required',
    provisioningRoleArn: 'required',
    description: '<optional>',
    enabled: '<optional>',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createProvisioningTemplateVersion': {
    templateName: 'required',
    templateBody: 'required',
    setAsDefault: '<optional>',
  },
  'Iot:createRoleAlias': {
    roleAlias: 'required',
    roleArn: 'required',
    credentialDurationSeconds: ['<optional1>', '<optional2>'],
  },
  'Iot:createScheduledAudit': {
    frequency: 'required',
    targetCheckNames: ['required1', 'required2'],
    scheduledAuditName: 'required',
    dayOfMonth: '<optional>',
    dayOfWeek: '<optional>',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createSecurityProfile': {
    securityProfileName: 'required',
    behaviors: [
      {
        metric: '<optional>',
        criteria: [
          {
            comparisonOperator: '<optional>',
            value: {
              count: '<optional>',
              cidrs: ['<optional1>', '<optional2>'],
              ports: ['<optional1>', '<optional2>'],
            },
            durationSeconds: ['<optional1>', '<optional2>'],
            consecutiveDatapointsToAlarm: '<optional>',
            consecutiveDatapointsToClear: '<optional>',
            statisticalThreshold: {
              statistic: '<optional>',
            },
          },
        ],
      },
    ],
    securityProfileDescription: '<optional>',
    alertTargets: ['<optional1>', '<optional2>'],
    additionalMetricsToRetain: '<optional>',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createStream': {
    streamId: 'required',
    files: [
      {
        fileId: '<optional>',
        s3Location: {
          bucket: '<optional>',
          key: '<optional>',
          version: '<optional>',
        },
      },
    ],
    roleArn: 'required',
    description: '<optional>',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createThing': {
    thingName: 'required',
    thingTypeName: '<optional>',
    attributePayload: {
      attributes: ['<optional1>', '<optional2>'],
      merge: '<optional>',
    },
    billingGroupName: '<optional>',
  },
  'Iot:createThingGroup': {
    thingGroupName: 'required',
    parentGroupName: '<optional>',
    thingGroupProperties: [
      {
        thingGroupDescription: '<optional>',
        attributePayload: {
          attributes: ['<optional1>', '<optional2>'],
          merge: '<optional>',
        },
      },
    ],
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createThingType': {
    thingTypeName: 'required',
    thingTypeProperties: [
      {
        thingTypeDescription: '<optional>',
        searchableAttributes: ['<optional1>', '<optional2>'],
      },
    ],
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:createTopicRule': {
    ruleName: 'required',
    topicRulePayload: {
      description: '<optional>',
      ruleDisabled: '<optional>',
      awsIotSqlVersion: '<optional>',
      errorAction: {
        dynamoDB: {
          operation: '<optional>',
          hashKeyType: '<optional>',
          rangeKeyField: '<optional>',
          rangeKeyValue: '<optional>',
          rangeKeyType: '<optional>',
          payloadField: '<optional>',
        },
        dynamoDBv2: '<optional>',
        lambda: '<optional>',
        sns: [
          {
            messageFormat: '<optional>',
          },
        ],
        sqs: [
          {
            useBase64: '<optional>',
          },
        ],
        kinesis: {
          partitionKey: '<optional>',
        },
        republish: '<optional>',
        s3: {
          cannedAcl: '<optional>',
        },
        firehose: {
          separator: '<optional>',
        },
        cloudwatchMetric: {
          metricTimestamp: '<optional>',
        },
        cloudwatchAlarm: '<optional>',
        elasticsearch: '<optional>',
        salesforce: '<optional>',
        iotAnalytics: [
          {
            channelArn: '<optional>',
            channelName: '<optional>',
            roleArn: '<optional>',
          },
        ],
        iotEvents: [
          {
            messageId: '<optional>',
          },
        ],
        stepFunctions: [
          {
            executionNamePrefix: '<optional>',
          },
        ],
      },
      actions: [
        {
          dynamoDB: {
            operation: '<optional>',
            hashKeyType: '<optional>',
            rangeKeyField: '<optional>',
            rangeKeyValue: '<optional>',
            rangeKeyType: '<optional>',
            payloadField: '<optional>',
          },
          dynamoDBv2: '<optional>',
          lambda: '<optional>',
          sns: [
            {
              messageFormat: '<optional>',
            },
          ],
          sqs: [
            {
              useBase64: '<optional>',
            },
          ],
          kinesis: {
            partitionKey: '<optional>',
          },
          republish: '<optional>',
          s3: {
            cannedAcl: '<optional>',
          },
          firehose: {
            separator: '<optional>',
          },
          cloudwatchMetric: {
            metricTimestamp: '<optional>',
          },
          cloudwatchAlarm: '<optional>',
          elasticsearch: '<optional>',
          salesforce: '<optional>',
          iotAnalytics: [
            {
              channelArn: '<optional>',
              channelName: '<optional>',
              roleArn: '<optional>',
            },
          ],
          iotEvents: [
            {
              messageId: '<optional>',
            },
          ],
          stepFunctions: [
            {
              executionNamePrefix: '<optional>',
            },
          ],
        },
      ],
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'Iot:createTopicRuleDestination': {
    destinationConfiguration: {
      httpUrlConfiguration: '<optional>',
    },
  },
  'Iot:deleteAccountAuditConfiguration': {
    deleteScheduledAudits: ['<optional1>', '<optional2>'],
  },
  'Iot:deleteAuthorizer': {
    authorizerName: 'required',
  },
  'Iot:deleteBillingGroup': {
    billingGroupName: 'required',
    expectedVersion: '<optional>',
  },
  'Iot:deleteCACertificate': {
    certificateId: 'required',
  },
  'Iot:deleteCertificate': {
    certificateId: 'required',
    forceDelete: '<optional>',
  },
  'Iot:deleteDomainConfiguration': {
    domainConfigurationName: 'required',
  },
  'Iot:deleteDynamicThingGroup': {
    thingGroupName: 'required',
    expectedVersion: '<optional>',
  },
  'Iot:deleteJob': {
    jobId: 'required',
    force: '<optional>',
  },
  'Iot:deleteJobExecution': {
    jobId: 'required',
    thingName: 'required',
    executionNumber: 'required',
    force: '<optional>',
  },
  'Iot:deleteMitigationAction': {
    actionName: 'required',
  },
  'Iot:deleteOTAUpdate': {
    otaUpdateId: 'required',
    deleteStream: '<optional>',
    forceDeleteAWSJob: '<optional>',
  },
  'Iot:deletePolicy': {
    policyName: 'required',
  },
  'Iot:deletePolicyVersion': {
    policyName: 'required',
    policyVersionId: 'required',
  },
  'Iot:deleteProvisioningTemplate': {
    templateName: 'required',
  },
  'Iot:deleteProvisioningTemplateVersion': {
    templateName: 'required',
    versionId: 'required',
  },
  'Iot:deleteRegistrationCode': {},
  'Iot:deleteRoleAlias': {
    roleAlias: 'required',
  },
  'Iot:deleteScheduledAudit': {
    scheduledAuditName: 'required',
  },
  'Iot:deleteSecurityProfile': {
    securityProfileName: 'required',
    expectedVersion: '<optional>',
  },
  'Iot:deleteStream': {
    streamId: 'required',
  },
  'Iot:deleteThing': {
    thingName: 'required',
    expectedVersion: '<optional>',
  },
  'Iot:deleteThingGroup': {
    thingGroupName: 'required',
    expectedVersion: '<optional>',
  },
  'Iot:deleteThingType': {
    thingTypeName: 'required',
  },
  'Iot:deleteTopicRule': {
    ruleName: 'required',
  },
  'Iot:deleteTopicRuleDestination': {
    arn: 'required',
  },
  'Iot:deleteV2LoggingLevel': {
    targetType: 'required',
    targetName: 'required',
  },
  'Iot:deprecateThingType': {
    thingTypeName: 'required',
    undoDeprecate: '<optional>',
  },
  'Iot:describeAccountAuditConfiguration': {},
  'Iot:describeAuditFinding': {
    findingId: 'required',
  },
  'Iot:describeAuditMitigationActionsTask': {
    taskId: 'required',
  },
  'Iot:describeAuditTask': {
    taskId: 'required',
  },
  'Iot:describeAuthorizer': {
    authorizerName: 'required',
  },
  'Iot:describeBillingGroup': {
    billingGroupName: 'required',
  },
  'Iot:describeCACertificate': {
    certificateId: 'required',
  },
  'Iot:describeCertificate': {
    certificateId: 'required',
  },
  'Iot:describeDefaultAuthorizer': {},
  'Iot:describeDomainConfiguration': {
    domainConfigurationName: 'required',
  },
  'Iot:describeEndpoint': {
    endpointType: '<optional>',
  },
  'Iot:describeEventConfigurations': {},
  'Iot:describeIndex': {
    indexName: 'required',
  },
  'Iot:describeJob': {
    jobId: 'required',
  },
  'Iot:describeJobExecution': {
    jobId: 'required',
    thingName: 'required',
    executionNumber: '<optional>',
  },
  'Iot:describeMitigationAction': {
    actionName: 'required',
  },
  'Iot:describeProvisioningTemplate': {
    templateName: 'required',
  },
  'Iot:describeProvisioningTemplateVersion': {
    templateName: 'required',
    versionId: 'required',
  },
  'Iot:describeRoleAlias': {
    roleAlias: 'required',
  },
  'Iot:describeScheduledAudit': {
    scheduledAuditName: 'required',
  },
  'Iot:describeSecurityProfile': {
    securityProfileName: 'required',
  },
  'Iot:describeStream': {
    streamId: 'required',
  },
  'Iot:describeThing': {
    thingName: 'required',
  },
  'Iot:describeThingGroup': {
    thingGroupName: 'required',
  },
  'Iot:describeThingRegistrationTask': {
    taskId: 'required',
  },
  'Iot:describeThingType': {
    thingTypeName: 'required',
  },
  'Iot:detachPolicy': {
    policyName: 'required',
    target: 'required',
  },
  'Iot:detachPrincipalPolicy': {
    policyName: 'required',
    principal: 'required',
  },
  'Iot:detachSecurityProfile': {
    securityProfileName: 'required',
    securityProfileTargetArn: 'required',
  },
  'Iot:detachThingPrincipal': {
    thingName: 'required',
    principal: 'required',
  },
  'Iot:disableTopicRule': {
    ruleName: 'required',
  },
  'Iot:enableTopicRule': {
    ruleName: 'required',
  },
  'Iot:getCardinality': {
    queryString: 'required',
    indexName: '<optional>',
    aggregationField: '<optional>',
    queryVersion: '<optional>',
  },
  'Iot:getEffectivePolicies': {
    principal: '<optional>',
    cognitoIdentityPoolId: '<optional>',
    thingName: '<optional>',
  },
  'Iot:getIndexingConfiguration': {},
  'Iot:getJobDocument': {
    jobId: 'required',
  },
  'Iot:getLoggingOptions': {},
  'Iot:getOTAUpdate': {
    otaUpdateId: 'required',
  },
  'Iot:getPercentiles': {
    queryString: 'required',
    indexName: '<optional>',
    aggregationField: '<optional>',
    queryVersion: '<optional>',
    percents: ['<optional1>', '<optional2>'],
  },
  'Iot:getPolicy': {
    policyName: 'required',
  },
  'Iot:getPolicyVersion': {
    policyName: 'required',
    policyVersionId: 'required',
  },
  'Iot:getRegistrationCode': {},
  'Iot:getStatistics': {
    queryString: 'required',
    indexName: '<optional>',
    aggregationField: '<optional>',
    queryVersion: '<optional>',
  },
  'Iot:getTopicRule': {
    ruleName: 'required',
  },
  'Iot:getTopicRuleDestination': {
    arn: 'required',
  },
  'Iot:getV2LoggingOptions': {},
  'Iot:listActiveViolations': {
    thingName: '<optional>',
    securityProfileName: '<optional>',
  },
  'Iot:listAttachedPolicies': {
    target: 'required',
    recursive: '<optional>',
    marker: '<optional>',
    pageSize: '<optional>',
  },
  'Iot:listAuditFindings': {
    taskId: '<optional>',
    checkName: '<optional>',
    resourceIdentifier: {
      deviceCertificateId: '<optional>',
      caCertificateId: '<optional>',
      cognitoIdentityPoolId: '<optional>',
      clientId: '<optional>',
      policyVersionIdentifier: {
        policyName: '<optional>',
        policyVersionId: '<optional>',
      },
      account: '<optional>',
    },
    startTime: '<optional>',
    endTime: '<optional>',
  },
  'Iot:listAuditMitigationActionsExecutions': {
    taskId: 'required',
    findingId: 'required',
    actionStatus: '<optional>',
  },
  'Iot:listAuditMitigationActionsTasks': {
    startTime: 'required',
    endTime: 'required',
    auditTaskId: '<optional>',
    findingId: '<optional>',
    taskStatus: '<optional>',
  },
  'Iot:listAuditTasks': {
    startTime: 'required',
    endTime: 'required',
    taskType: '<optional>',
    taskStatus: '<optional>',
  },
  'Iot:listAuthorizers': {
    pageSize: '<optional>',
    marker: '<optional>',
    ascendingOrder: '<optional>',
    status: '<optional>',
  },
  'Iot:listBillingGroups': {
    namePrefixFilter: '<optional>',
  },
  'Iot:listCACertificates': {
    pageSize: '<optional>',
    marker: '<optional>',
    ascendingOrder: '<optional>',
  },
  'Iot:listCertificates': {
    pageSize: '<optional>',
    marker: '<optional>',
    ascendingOrder: '<optional>',
  },
  'Iot:listCertificatesByCA': {
    caCertificateId: 'required',
    pageSize: '<optional>',
    marker: '<optional>',
    ascendingOrder: '<optional>',
  },
  'Iot:listDomainConfigurations': {
    marker: '<optional>',
    pageSize: '<optional>',
    serviceType: '<optional>',
  },
  'Iot:listIndices': {},
  'Iot:listJobExecutionsForJob': {
    jobId: 'required',
    status: '<optional>',
  },
  'Iot:listJobExecutionsForThing': {
    thingName: 'required',
    status: '<optional>',
  },
  'Iot:listJobs': {
    status: '<optional>',
    targetSelection: '<optional>',
    thingGroupName: '<optional>',
    thingGroupId: '<optional>',
  },
  'Iot:listMitigationActions': {
    actionType: '<optional>',
  },
  'Iot:listOTAUpdates': {
    otaUpdateStatus: '<optional>',
  },
  'Iot:listOutgoingCertificates': {
    pageSize: '<optional>',
    marker: '<optional>',
    ascendingOrder: '<optional>',
  },
  'Iot:listPolicies': {
    marker: '<optional>',
    pageSize: '<optional>',
    ascendingOrder: '<optional>',
  },
  'Iot:listPolicyPrincipals': {
    policyName: 'required',
    marker: '<optional>',
    pageSize: '<optional>',
    ascendingOrder: '<optional>',
  },
  'Iot:listPolicyVersions': {
    policyName: 'required',
  },
  'Iot:listPrincipalPolicies': {
    principal: 'required',
    marker: '<optional>',
    pageSize: '<optional>',
    ascendingOrder: '<optional>',
  },
  'Iot:listPrincipalThings': {
    principal: 'required',
  },
  'Iot:listProvisioningTemplateVersions': {
    templateName: 'required',
  },
  'Iot:listProvisioningTemplates': {},
  'Iot:listRoleAliases': {
    pageSize: '<optional>',
    marker: '<optional>',
    ascendingOrder: '<optional>',
  },
  'Iot:listScheduledAudits': {},
  'Iot:listSecurityProfiles': {},
  'Iot:listSecurityProfilesForTarget': {
    securityProfileTargetArn: 'required',
    recursive: '<optional>',
  },
  'Iot:listStreams': {
    ascendingOrder: '<optional>',
  },
  'Iot:listTagsForResource': {
    resourceArn: 'required',
  },
  'Iot:listTargetsForPolicy': {
    policyName: 'required',
    marker: '<optional>',
    pageSize: '<optional>',
  },
  'Iot:listTargetsForSecurityProfile': {
    securityProfileName: 'required',
  },
  'Iot:listThingGroups': {
    parentGroup: '<optional>',
    namePrefixFilter: '<optional>',
    recursive: '<optional>',
  },
  'Iot:listThingGroupsForThing': {
    thingName: 'required',
  },
  'Iot:listThingPrincipals': {
    thingName: 'required',
  },
  'Iot:listThingRegistrationTaskReports': {
    taskId: 'required',
    reportType: 'required',
  },
  'Iot:listThingRegistrationTasks': {
    status: '<optional>',
  },
  'Iot:listThingTypes': {
    thingTypeName: '<optional>',
  },
  'Iot:listThings': {
    attributeName: '<optional>',
    attributeValue: '<optional>',
    thingTypeName: '<optional>',
  },
  'Iot:listThingsInBillingGroup': {
    billingGroupName: 'required',
  },
  'Iot:listThingsInThingGroup': {
    thingGroupName: 'required',
    recursive: '<optional>',
  },
  'Iot:listTopicRuleDestinations': {},
  'Iot:listTopicRules': {
    topic: '<optional>',
    ruleDisabled: '<optional>',
  },
  'Iot:listV2LoggingLevels': {
    targetType: '<optional>',
  },
  'Iot:listViolationEvents': {
    startTime: 'required',
    endTime: 'required',
    thingName: '<optional>',
    securityProfileName: '<optional>',
  },
  'Iot:registerCACertificate': {
    caCertificate: 'required',
    verificationCertificate: 'required',
    setAsActive: '<optional>',
    allowAutoRegistration: '<optional>',
    registrationConfig: {
      templateBody: '<optional>',
      roleArn: '<optional>',
    },
  },
  'Iot:registerCertificate': {
    certificatePem: 'required',
    caCertificatePem: '<optional>',
    setAsActive: '<optional>',
    status: '<optional>',
  },
  'Iot:registerThing': {
    templateBody: 'required',
    parameters: ['<optional1>', '<optional2>'],
  },
  'Iot:rejectCertificateTransfer': {
    certificateId: 'required',
    rejectReason: '<optional>',
  },
  'Iot:removeThingFromBillingGroup': {
    billingGroupName: '<optional>',
    billingGroupArn: '<optional>',
    thingName: '<optional>',
    thingArn: '<optional>',
  },
  'Iot:removeThingFromThingGroup': {
    thingGroupName: '<optional>',
    thingGroupArn: '<optional>',
    thingName: '<optional>',
    thingArn: '<optional>',
  },
  'Iot:replaceTopicRule': {
    ruleName: 'required',
    topicRulePayload: {
      description: '<optional>',
      ruleDisabled: '<optional>',
      awsIotSqlVersion: '<optional>',
      errorAction: {
        dynamoDB: {
          operation: '<optional>',
          hashKeyType: '<optional>',
          rangeKeyField: '<optional>',
          rangeKeyValue: '<optional>',
          rangeKeyType: '<optional>',
          payloadField: '<optional>',
        },
        dynamoDBv2: '<optional>',
        lambda: '<optional>',
        sns: [
          {
            messageFormat: '<optional>',
          },
        ],
        sqs: [
          {
            useBase64: '<optional>',
          },
        ],
        kinesis: {
          partitionKey: '<optional>',
        },
        republish: '<optional>',
        s3: {
          cannedAcl: '<optional>',
        },
        firehose: {
          separator: '<optional>',
        },
        cloudwatchMetric: {
          metricTimestamp: '<optional>',
        },
        cloudwatchAlarm: '<optional>',
        elasticsearch: '<optional>',
        salesforce: '<optional>',
        iotAnalytics: [
          {
            channelArn: '<optional>',
            channelName: '<optional>',
            roleArn: '<optional>',
          },
        ],
        iotEvents: [
          {
            messageId: '<optional>',
          },
        ],
        stepFunctions: [
          {
            executionNamePrefix: '<optional>',
          },
        ],
      },
      actions: [
        {
          dynamoDB: {
            operation: '<optional>',
            hashKeyType: '<optional>',
            rangeKeyField: '<optional>',
            rangeKeyValue: '<optional>',
            rangeKeyType: '<optional>',
            payloadField: '<optional>',
          },
          dynamoDBv2: '<optional>',
          lambda: '<optional>',
          sns: [
            {
              messageFormat: '<optional>',
            },
          ],
          sqs: [
            {
              useBase64: '<optional>',
            },
          ],
          kinesis: {
            partitionKey: '<optional>',
          },
          republish: '<optional>',
          s3: {
            cannedAcl: '<optional>',
          },
          firehose: {
            separator: '<optional>',
          },
          cloudwatchMetric: {
            metricTimestamp: '<optional>',
          },
          cloudwatchAlarm: '<optional>',
          elasticsearch: '<optional>',
          salesforce: '<optional>',
          iotAnalytics: [
            {
              channelArn: '<optional>',
              channelName: '<optional>',
              roleArn: '<optional>',
            },
          ],
          iotEvents: [
            {
              messageId: '<optional>',
            },
          ],
          stepFunctions: [
            {
              executionNamePrefix: '<optional>',
            },
          ],
        },
      ],
    },
  },
  'Iot:searchIndex': {
    queryString: 'required',
    indexName: '<optional>',
    queryVersion: '<optional>',
  },
  'Iot:setDefaultAuthorizer': {
    authorizerName: 'required',
  },
  'Iot:setDefaultPolicyVersion': {
    policyName: 'required',
    policyVersionId: 'required',
  },
  'Iot:setLoggingOptions': {
    loggingOptionsPayload: {
      logLevel: '<optional>',
    },
  },
  'Iot:setV2LoggingLevel': {
    logTarget: {
      targetName: '<optional>',
    },
    logLevel: 'required',
  },
  'Iot:setV2LoggingOptions': {
    roleArn: '<optional>',
    defaultLogLevel: '<optional>',
    disableAllLogs: ['<optional1>', '<optional2>'],
  },
  'Iot:startAuditMitigationActionsTask': {
    taskId: 'required',
    target: {
      auditTaskId: '<optional>',
      findingIds: ['<optional1>', '<optional2>'],
      auditCheckToReasonCodeFilter: '<optional>',
    },
    auditCheckToActionsMapping: 'required',
    clientRequestToken: 'required',
  },
  'Iot:startOnDemandAuditTask': {
    targetCheckNames: ['required1', 'required2'],
  },
  'Iot:startThingRegistrationTask': {
    templateBody: 'required',
    inputFileBucket: 'required',
    inputFileKey: 'required',
    roleArn: 'required',
  },
  'Iot:stopThingRegistrationTask': {
    taskId: 'required',
  },
  'Iot:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Iot:testAuthorization': {
    authInfos: [
      {
        actionType: '<optional>',
        resources: ['<optional1>', '<optional2>'],
      },
    ],
    principal: '<optional>',
    cognitoIdentityPoolId: '<optional>',
    clientId: '<optional>',
    policyNamesToAdd: '<optional>',
    policyNamesToSkip: '<optional>',
  },
  'Iot:testInvokeAuthorizer': {
    authorizerName: 'required',
    token: 'required',
    tokenSignature: 'required',
  },
  'Iot:transferCertificate': {
    certificateId: 'required',
    targetAwsAccount: 'required',
    transferMessage: '<optional>',
  },
  'Iot:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'Iot:updateAccountAuditConfiguration': {
    roleArn: '<optional>',
    auditNotificationTargetConfigurations: ['<optional1>', '<optional2>'],
    auditCheckConfigurations: ['<optional1>', '<optional2>'],
  },
  'Iot:updateAuthorizer': {
    authorizerName: 'required',
    authorizerFunctionArn: '<optional>',
    tokenKeyName: '<optional>',
    tokenSigningPublicKeys: ['<optional1>', '<optional2>'],
    status: '<optional>',
  },
  'Iot:updateBillingGroup': {
    billingGroupName: 'required',
    billingGroupProperties: [
      {
        billingGroupDescription: '<optional>',
      },
    ],
    expectedVersion: '<optional>',
  },
  'Iot:updateCACertificate': {
    certificateId: 'required',
    newStatus: '<optional>',
    newAutoRegistrationStatus: '<optional>',
    registrationConfig: {
      templateBody: '<optional>',
      roleArn: '<optional>',
    },
    removeAutoRegistration: '<optional>',
  },
  'Iot:updateCertificate': {
    certificateId: 'required',
    newStatus: 'required',
  },
  'Iot:updateDomainConfiguration': {
    domainConfigurationName: 'required',
    authorizerConfig: {
      defaultAuthorizerName: '<optional>',
      allowAuthorizerOverride: '<optional>',
    },
    domainConfigurationStatus: '<optional>',
    removeAuthorizerConfig: '<optional>',
  },
  'Iot:updateDynamicThingGroup': {
    thingGroupName: 'required',
    thingGroupProperties: [
      {
        thingGroupDescription: '<optional>',
        attributePayload: {
          attributes: ['<optional1>', '<optional2>'],
          merge: '<optional>',
        },
      },
    ],
    expectedVersion: '<optional>',
    indexName: '<optional>',
    queryString: '<optional>',
    queryVersion: '<optional>',
  },
  'Iot:updateEventConfigurations': {
    eventConfigurations: ['<optional1>', '<optional2>'],
  },
  'Iot:updateIndexingConfiguration': {
    thingIndexingConfiguration: {
      thingConnectivityIndexingMode: '<optional>',
    },
    thingGroupIndexingConfiguration: '<optional>',
  },
  'Iot:updateJob': {
    jobId: 'required',
    jobExecutionsRolloutConfig: {
      maximumPerMinute: '<optional>',
      exponentialRate: {
        rateIncreaseCriteria: [
          {
            numberOfNotifiedThings: ['<optional1>', '<optional2>'],
            numberOfSucceededThings: ['<optional1>', '<optional2>'],
          },
        ],
      },
    },
    abortConfig: '<optional>',
    description: '<optional>',
    presignedUrlConfig: {
      roleArn: '<optional>',
      expiresInSec: '<optional>',
    },
    timeoutConfig: {
      inProgressTimeoutInMinutes: ['<optional1>', '<optional2>'],
    },
  },
  'Iot:updateMitigationAction': {
    actionName: 'required',
    actionParams: [
      {
        updateDeviceCertificateParams: ['<optional1>', '<optional2>'],
        updateCACertificateParams: ['<optional1>', '<optional2>'],
        addThingsToThingGroupParams: [
          {
            overrideDynamicGroups: ['<optional1>', '<optional2>'],
          },
        ],
        replaceDefaultPolicyVersionParams: ['<optional1>', '<optional2>'],
        enableIoTLoggingParams: ['<optional1>', '<optional2>'],
        publishFindingToSnsParams: ['<optional1>', '<optional2>'],
      },
    ],
    roleArn: '<optional>',
  },
  'Iot:updateProvisioningTemplate': {
    templateName: 'required',
    description: '<optional>',
    enabled: '<optional>',
    defaultVersionId: '<optional>',
    provisioningRoleArn: '<optional>',
  },
  'Iot:updateRoleAlias': {
    roleAlias: 'required',
    roleArn: '<optional>',
    credentialDurationSeconds: ['<optional1>', '<optional2>'],
  },
  'Iot:updateScheduledAudit': {
    scheduledAuditName: 'required',
    frequency: '<optional>',
    dayOfMonth: '<optional>',
    dayOfWeek: '<optional>',
    targetCheckNames: ['<optional1>', '<optional2>'],
  },
  'Iot:updateSecurityProfile': {
    securityProfileName: 'required',
    behaviors: [
      {
        metric: '<optional>',
        criteria: [
          {
            comparisonOperator: '<optional>',
            value: {
              count: '<optional>',
              cidrs: ['<optional1>', '<optional2>'],
              ports: ['<optional1>', '<optional2>'],
            },
            durationSeconds: ['<optional1>', '<optional2>'],
            consecutiveDatapointsToAlarm: '<optional>',
            consecutiveDatapointsToClear: '<optional>',
            statisticalThreshold: {
              statistic: '<optional>',
            },
          },
        ],
      },
    ],
    securityProfileDescription: '<optional>',
    alertTargets: ['<optional1>', '<optional2>'],
    additionalMetricsToRetain: '<optional>',
    deleteBehaviors: ['<optional1>', '<optional2>'],
    deleteAlertTargets: ['<optional1>', '<optional2>'],
    deleteAdditionalMetricsToRetain: '<optional>',
    expectedVersion: '<optional>',
  },
  'Iot:updateStream': {
    streamId: 'required',
    description: '<optional>',
    files: [
      {
        fileId: '<optional>',
        s3Location: {
          bucket: '<optional>',
          key: '<optional>',
          version: '<optional>',
        },
      },
    ],
    roleArn: '<optional>',
  },
  'Iot:updateThing': {
    thingName: 'required',
    thingTypeName: '<optional>',
    attributePayload: {
      attributes: ['<optional1>', '<optional2>'],
      merge: '<optional>',
    },
    expectedVersion: '<optional>',
    removeThingType: '<optional>',
  },
  'Iot:updateThingGroup': {
    thingGroupName: 'required',
    thingGroupProperties: [
      {
        thingGroupDescription: '<optional>',
        attributePayload: {
          attributes: ['<optional1>', '<optional2>'],
          merge: '<optional>',
        },
      },
    ],
    expectedVersion: '<optional>',
  },
  'Iot:updateThingGroupsForThing': {
    thingName: '<optional>',
    thingGroupsToAdd: '<optional>',
    thingGroupsToRemove: '<optional>',
    overrideDynamicGroups: ['<optional1>', '<optional2>'],
  },
  'Iot:updateTopicRuleDestination': {
    arn: 'required',
    status: 'required',
  },
  'Iot:validateSecurityProfileBehaviors': {
    behaviors: [
      {
        metric: '<optional>',
        criteria: [
          {
            comparisonOperator: '<optional>',
            value: {
              count: '<optional>',
              cidrs: ['<optional1>', '<optional2>'],
              ports: ['<optional1>', '<optional2>'],
            },
            durationSeconds: ['<optional1>', '<optional2>'],
            consecutiveDatapointsToAlarm: '<optional>',
            consecutiveDatapointsToClear: '<optional>',
            statisticalThreshold: {
              statistic: '<optional>',
            },
          },
        ],
      },
    ],
  },
  'IotData:deleteThingShadow': {
    thingName: 'required',
  },
  'IotData:getThingShadow': {
    thingName: 'required',
  },
  'IotData:publish': {
    topic: 'required',
    qos: ['<optional1>', '<optional2>'],
    payload: '<optional>',
  },
  'IotData:updateThingShadow': {
    thingName: 'required',
    payload: 'required',
  },
  'KMS:cancelKeyDeletion': {
    KeyId: 'required',
  },
  'KMS:connectCustomKeyStore': {
    CustomKeyStoreId: 'required',
  },
  'KMS:createAlias': {
    AliasName: 'required',
    TargetKeyId: 'required',
  },
  'KMS:createCustomKeyStore': {
    CustomKeyStoreName: 'required',
    CloudHsmClusterId: 'required',
    TrustAnchorCertificate: 'required',
    KeyStorePassword: 'required',
  },
  'KMS:createGrant': {
    KeyId: 'required',
    GranteePrincipal: 'required',
    Operations: ['required1', 'required2'],
    RetiringPrincipal: '<optional>',
    Constraints: [
      {
        EncryptionContextSubset: '<optional>',
        EncryptionContextEquals: ['<optional1>', '<optional2>'],
      },
    ],
    GrantTokens: ['<optional1>', '<optional2>'],
    Name: '<optional>',
  },
  'KMS:createKey': {
    Tags: ['<optional1>', '<optional2>'],
    Policy: '<optional>',
    Description: '<optional>',
    KeyUsage: '<optional>',
    Origin: '<optional>',
    CustomKeyStoreId: '<optional>',
    BypassPolicyLockoutSafetyCheck: '<optional>',
  },
  'KMS:decrypt': {
    CiphertextBlob: 'required',
    EncryptionContext: '<optional>',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:deleteAlias': {
    AliasName: 'required',
  },
  'KMS:deleteCustomKeyStore': {
    CustomKeyStoreId: 'required',
  },
  'KMS:deleteImportedKeyMaterial': {
    KeyId: 'required',
  },
  'KMS:describeCustomKeyStores': {
    CustomKeyStoreId: '<optional>',
    CustomKeyStoreName: '<optional>',
    Limit: '<optional>',
  },
  'KMS:describeKey': {
    KeyId: 'required',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:disableKey': {
    KeyId: 'required',
  },
  'KMS:disableKeyRotation': {
    KeyId: 'required',
  },
  'KMS:disconnectCustomKeyStore': {
    CustomKeyStoreId: 'required',
  },
  'KMS:enableKey': {
    KeyId: 'required',
  },
  'KMS:enableKeyRotation': {
    KeyId: 'required',
  },
  'KMS:encrypt': {
    KeyId: 'required',
    Plaintext: 'required',
    EncryptionContext: '<optional>',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:generateDataKey': {
    KeyId: 'required',
    EncryptionContext: '<optional>',
    NumberOfBytes: ['<optional1>', '<optional2>'],
    KeySpec: '<optional>',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:generateDataKeyPair': {
    KeyId: 'required',
    KeyPairSpec: 'required',
    EncryptionContext: '<optional>',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:generateDataKeyPairWithoutPlaintext': {
    KeyId: 'required',
    KeyPairSpec: 'required',
    EncryptionContext: '<optional>',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:generateDataKeyWithoutPlaintext': {
    KeyId: 'required',
    EncryptionContext: '<optional>',
    KeySpec: '<optional>',
    NumberOfBytes: ['<optional1>', '<optional2>'],
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:generateRandom': {
    NumberOfBytes: ['<optional1>', '<optional2>'],
    CustomKeyStoreId: '<optional>',
  },
  'KMS:getKeyPolicy': {
    KeyId: 'required',
    PolicyName: 'required',
  },
  'KMS:getKeyRotationStatus': {
    KeyId: 'required',
  },
  'KMS:getParametersForImport': {
    KeyId: 'required',
    WrappingAlgorithm: 'required',
    WrappingKeySpec: 'required',
  },
  'KMS:getPublicKey': {
    KeyId: 'required',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:importKeyMaterial': {
    KeyId: 'required',
    ImportToken: 'required',
    EncryptedKeyMaterial: 'required',
    ValidTo: '<optional>',
    ExpirationModel: '<optional>',
  },
  'KMS:listAliases': {
    KeyId: '<optional>',
    Limit: '<optional>',
  },
  'KMS:listGrants': {
    KeyId: 'required',
    Limit: '<optional>',
  },
  'KMS:listKeyPolicies': {
    KeyId: 'required',
    Limit: '<optional>',
  },
  'KMS:listKeys': {
    Limit: '<optional>',
  },
  'KMS:listResourceTags': {
    KeyId: 'required',
    Limit: '<optional>',
  },
  'KMS:listRetirableGrants': {
    RetiringPrincipal: 'required',
    Limit: '<optional>',
  },
  'KMS:putKeyPolicy': {
    KeyId: 'required',
    PolicyName: 'required',
    Policy: 'required',
    BypassPolicyLockoutSafetyCheck: '<optional>',
  },
  'KMS:reEncrypt': {
    CiphertextBlob: 'required',
    DestinationKeyId: 'required',
    SourceEncryptionContext: '<optional>',
    DestinationEncryptionContext: '<optional>',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:retireGrant': {
    GrantToken: '<optional>',
    KeyId: '<optional>',
    GrantId: '<optional>',
  },
  'KMS:revokeGrant': {
    KeyId: 'required',
    GrantId: 'required',
  },
  'KMS:scheduleKeyDeletion': {
    KeyId: 'required',
    PendingWindowInDays: ['<optional1>', '<optional2>'],
  },
  'KMS:sign': {
    KeyId: 'required',
    Message: 'required',
    SigningAlgorithm: 'required',
    MessageType: '<optional>',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'KMS:tagResource': {
    KeyId: 'required',
    Tags: [
      {
        TagKey: 'required',
        TagValue: 'required',
      },
    ],
  },
  'KMS:untagResource': {
    KeyId: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'KMS:updateAlias': {
    AliasName: 'required',
    TargetKeyId: 'required',
  },
  'KMS:updateCustomKeyStore': {
    CustomKeyStoreId: 'required',
    NewCustomKeyStoreName: '<optional>',
    KeyStorePassword: '<optional>',
    CloudHsmClusterId: '<optional>',
  },
  'KMS:updateKeyDescription': {
    KeyId: 'required',
    Description: 'required',
  },
  'KMS:verify': {
    KeyId: 'required',
    Message: 'required',
    Signature: 'required',
    SigningAlgorithm: 'required',
    MessageType: '<optional>',
    GrantTokens: ['<optional1>', '<optional2>'],
  },
  'Kafka:createCluster': {
    BrokerNodeGroupInfo: {
      BrokerAZDistribution: '<optional>',
      SecurityGroups: ['<optional1>', '<optional2>'],
      StorageInfo: {
        EbsStorageInfo: {
          VolumeSize: '<optional>',
        },
      },
    },
    KafkaVersion: 'required',
    NumberOfBrokerNodes: ['required1', 'required2'],
    ClusterName: 'required',
    ConfigurationInfo: '<optional>',
    EncryptionInfo: {
      EncryptionAtRest: '<optional>',
      EncryptionInTransit: {
        ClientBroker: '<optional>',
        InCluster: '<optional>',
      },
    },
    ClientAuthentication: {
      Tls: [
        {
          CertificateAuthorityArnList: '<optional>',
        },
      ],
    },
    EnhancedMonitoring: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'Kafka:createConfiguration': {
    ServerProperties: ['required1', 'required2'],
    KafkaVersions: ['required1', 'required2'],
    Name: 'required',
    Description: '<optional>',
  },
  'Kafka:deleteCluster': {
    ClusterArn: 'required',
    CurrentVersion: '<optional>',
  },
  'Kafka:describeCluster': {
    ClusterArn: 'required',
  },
  'Kafka:describeClusterOperation': {
    ClusterOperationArn: 'required',
  },
  'Kafka:describeConfiguration': {
    Arn: 'required',
  },
  'Kafka:describeConfigurationRevision': {
    Revision: 'required',
    Arn: 'required',
  },
  'Kafka:getBootstrapBrokers': {
    ClusterArn: 'required',
  },
  'Kafka:listClusterOperations': {
    ClusterArn: 'required',
  },
  'Kafka:listClusters': {
    ClusterNameFilter: '<optional>',
  },
  'Kafka:listConfigurationRevisions': {
    Arn: 'required',
  },
  'Kafka:listConfigurations': {},
  'Kafka:listNodes': {
    ClusterArn: 'required',
  },
  'Kafka:listTagsForResource': {
    ResourceArn: 'required',
  },
  'Kafka:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'Kafka:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'Kafka:updateBrokerCount': {
    ClusterArn: 'required',
    CurrentVersion: 'required',
    TargetNumberOfBrokerNodes: ['required1', 'required2'],
  },
  'Kafka:updateBrokerStorage': {
    ClusterArn: 'required',
    TargetBrokerEBSVolumeInfo: {
      VolumeSizeGB: 'required',
      KafkaBrokerNodeId: 'required',
    },
    CurrentVersion: 'required',
  },
  'Kafka:updateClusterConfiguration': {
    ClusterArn: 'required',
    CurrentVersion: 'required',
    ConfigurationInfo: {
      Revision: 'required',
      Arn: 'required',
    },
  },
  'Kafka:updateMonitoring': {
    ClusterArn: 'required',
    CurrentVersion: 'required',
    OpenMonitoring: {
      Prometheus: {
        JmxExporter: '<optional>',
        NodeExporter: '<optional>',
      },
    },
    EnhancedMonitoring: '<optional>',
  },
  'Kendra:batchDeleteDocument': {
    IndexId: 'required',
    DocumentIdList: 'required',
  },
  'Kendra:batchPutDocument': {
    IndexId: 'required',
    Documents: [
      {
        Title: '<optional>',
        Blob: '<optional>',
        S3Path: '<optional>',
        Attributes: [
          {
            Value: {
              StringValue: '<optional>',
              StringListValue: '<optional>',
              LongValue: '<optional>',
              DateValue: '<optional>',
            },
          },
        ],
        AccessControlList: '<optional>',
        ContentType: '<optional>',
      },
    ],
    RoleArn: '<optional>',
  },
  'Kendra:createDataSource': {
    Name: 'required',
    IndexId: 'required',
    Type: 'required',
    Configuration: {
      S3Configuration: {
        InclusionPrefixes: ['<optional1>', '<optional2>'],
        ExclusionPatterns: ['<optional1>', '<optional2>'],
        DocumentsMetadataConfiguration: {
          S3Prefix: '<optional>',
        },
        AccessControlListConfiguration: {
          KeyPath: '<optional>',
        },
      },
      SharePointConfiguration: {
        CrawlAttachments: ['<optional1>', '<optional2>'],
        VpcConfiguration: '<optional>',
        FieldMappings: [
          {
            DateFieldFormat: '<optional>',
          },
        ],
        DocumentTitleFieldName: '<optional>',
      },
      DatabaseConfiguration: {
        VpcConfiguration: '<optional>',
        AclConfiguration: '<optional>',
        ColumnConfiguration: {
          DocumentTitleColumnName: '<optional>',
          FieldMappings: [
            {
              DateFieldFormat: '<optional>',
            },
          ],
        },
      },
    },
    RoleArn: 'required',
    Description: '<optional>',
    Schedule: '<optional>',
  },
  'Kendra:createFaq': {
    IndexId: 'required',
    Name: 'required',
    S3Path: {
      Bucket: 'required',
      Key: 'required',
    },
    RoleArn: 'required',
    Description: '<optional>',
  },
  'Kendra:createIndex': {
    Name: 'required',
    RoleArn: 'required',
    ServerSideEncryptionConfiguration: {
      KmsKeyId: '<optional>',
    },
    Description: '<optional>',
  },
  'Kendra:deleteFaq': {
    Id: 'required',
    IndexId: 'required',
  },
  'Kendra:deleteIndex': {
    Id: 'required',
  },
  'Kendra:describeDataSource': {
    Id: 'required',
    IndexId: 'required',
  },
  'Kendra:describeFaq': {
    Id: 'required',
    IndexId: 'required',
  },
  'Kendra:describeIndex': {
    Id: 'required',
  },
  'Kendra:listDataSourceSyncJobs': {
    Id: 'required',
    IndexId: 'required',
    StartTimeFilter: {
      StartTime: '<optional>',
      EndTime: '<optional>',
    },
    StatusFilter: '<optional>',
  },
  'Kendra:listDataSources': {
    IndexId: 'required',
  },
  'Kendra:listFaqs': {
    IndexId: 'required',
  },
  'Kendra:listIndices': {},
  'Kendra:query': {
    IndexId: 'required',
    QueryText: 'required',
    AttributeFilter: {
      AndAllFilters: ['<optional1>', '<optional2>'],
      OrAllFilters: ['<optional1>', '<optional2>'],
      NotFilter: '<optional>',
      EqualsTo: {
        Value: {
          StringValue: '<optional>',
          StringListValue: '<optional>',
          LongValue: '<optional>',
          DateValue: '<optional>',
        },
      },
      ContainsAll: {
        Value: {
          StringValue: '<optional>',
          StringListValue: '<optional>',
          LongValue: '<optional>',
          DateValue: '<optional>',
        },
      },
      ContainsAny: {
        Value: {
          StringValue: '<optional>',
          StringListValue: '<optional>',
          LongValue: '<optional>',
          DateValue: '<optional>',
        },
      },
      GreaterThan: {
        Value: {
          StringValue: '<optional>',
          StringListValue: '<optional>',
          LongValue: '<optional>',
          DateValue: '<optional>',
        },
      },
      GreaterThanOrEquals: [
        {
          Value: {
            StringValue: '<optional>',
            StringListValue: '<optional>',
            LongValue: '<optional>',
            DateValue: '<optional>',
          },
        },
      ],
      LessThan: {
        Value: {
          StringValue: '<optional>',
          StringListValue: '<optional>',
          LongValue: '<optional>',
          DateValue: '<optional>',
        },
      },
      LessThanOrEquals: [
        {
          Value: {
            StringValue: '<optional>',
            StringListValue: '<optional>',
            LongValue: '<optional>',
            DateValue: '<optional>',
          },
        },
      ],
    },
    Facets: [
      {
        DocumentAttributeKey: '<optional>',
      },
    ],
    RequestedDocumentAttributes: ['<optional1>', '<optional2>'],
    QueryResultTypeFilter: '<optional>',
    PageNumber: '<optional>',
    PageSize: '<optional>',
  },
  'Kendra:startDataSourceSyncJob': {
    Id: 'required',
    IndexId: 'required',
  },
  'Kendra:stopDataSourceSyncJob': {
    Id: 'required',
    IndexId: 'required',
  },
  'Kendra:submitFeedback': {
    IndexId: 'required',
    QueryId: 'required',
    ClickFeedbackItems: ['<optional1>', '<optional2>'],
    RelevanceFeedbackItems: ['<optional1>', '<optional2>'],
  },
  'Kendra:updateDataSource': {
    Id: 'required',
    IndexId: 'required',
    Configuration: {
      S3Configuration: {
        InclusionPrefixes: ['<optional1>', '<optional2>'],
        ExclusionPatterns: ['<optional1>', '<optional2>'],
        DocumentsMetadataConfiguration: {
          S3Prefix: '<optional>',
        },
        AccessControlListConfiguration: {
          KeyPath: '<optional>',
        },
      },
      SharePointConfiguration: {
        CrawlAttachments: ['<optional1>', '<optional2>'],
        VpcConfiguration: '<optional>',
        FieldMappings: [
          {
            DateFieldFormat: '<optional>',
          },
        ],
        DocumentTitleFieldName: '<optional>',
      },
      DatabaseConfiguration: {
        VpcConfiguration: '<optional>',
        AclConfiguration: '<optional>',
        ColumnConfiguration: {
          DocumentTitleColumnName: '<optional>',
          FieldMappings: [
            {
              DateFieldFormat: '<optional>',
            },
          ],
        },
      },
    },
    Name: '<optional>',
    Description: '<optional>',
    Schedule: '<optional>',
    RoleArn: '<optional>',
  },
  'Kendra:updateIndex': {
    Id: 'required',
    DocumentMetadataConfigurationUpdates: [
      {
        Relevance: {
          Freshness: '<optional>',
          Importance: '<optional>',
          Duration: '<optional>',
          RankOrder: '<optional>',
          ValueImportanceMap: '<optional>',
        },
        Search: {
          Facetable: '<optional>',
          Searchable: '<optional>',
          Displayable: '<optional>',
        },
      },
    ],
    Name: '<optional>',
    RoleArn: '<optional>',
    Description: '<optional>',
  },
  'Kinesis:addTagsToStream': {
    StreamName: 'required',
    Tags: ['required1', 'required2'],
  },
  'Kinesis:createStream': {
    StreamName: 'required',
    ShardCount: 'required',
  },
  'Kinesis:decreaseStreamRetentionPeriod': {
    StreamName: 'required',
    RetentionPeriodHours: ['required1', 'required2'],
  },
  'Kinesis:deleteStream': {
    StreamName: 'required',
    EnforceConsumerDeletion: '<optional>',
  },
  'Kinesis:deregisterStreamConsumer': {
    StreamARN: '<optional>',
    ConsumerName: '<optional>',
    ConsumerARN: '<optional>',
  },
  'Kinesis:describeLimits': {},
  'Kinesis:describeStream': {
    StreamName: 'required',
    Limit: '<optional>',
    ExclusiveStartShardId: '<optional>',
  },
  'Kinesis:describeStreamConsumer': {
    StreamARN: '<optional>',
    ConsumerName: '<optional>',
    ConsumerARN: '<optional>',
  },
  'Kinesis:describeStreamSummary': {
    StreamName: 'required',
  },
  'Kinesis:disableEnhancedMonitoring': {
    StreamName: 'required',
    ShardLevelMetrics: ['required1', 'required2'],
  },
  'Kinesis:enableEnhancedMonitoring': {
    StreamName: 'required',
    ShardLevelMetrics: ['required1', 'required2'],
  },
  'Kinesis:getRecords': {
    ShardIterator: 'required',
    Limit: '<optional>',
  },
  'Kinesis:getShardIterator': {
    StreamName: 'required',
    ShardId: 'required',
    ShardIteratorType: 'required',
    StartingSequenceNumber: '<optional>',
    Timestamp: '<optional>',
  },
  'Kinesis:increaseStreamRetentionPeriod': {
    StreamName: 'required',
    RetentionPeriodHours: ['required1', 'required2'],
  },
  'Kinesis:listShards': {
    StreamName: '<optional>',
    ExclusiveStartShardId: '<optional>',
    StreamCreationTimestamp: '<optional>',
  },
  'Kinesis:listStreamConsumers': {
    StreamARN: 'required',
    StreamCreationTimestamp: '<optional>',
  },
  'Kinesis:listStreams': {
    Limit: '<optional>',
    ExclusiveStartStreamName: '<optional>',
  },
  'Kinesis:listTagsForStream': {
    StreamName: 'required',
    ExclusiveStartTagKey: '<optional>',
    Limit: '<optional>',
  },
  'Kinesis:mergeShards': {
    StreamName: 'required',
    ShardToMerge: 'required',
    AdjacentShardToMerge: 'required',
  },
  'Kinesis:putRecord': {
    StreamName: 'required',
    Data: ['required1', 'required2'],
    PartitionKey: 'required',
    ExplicitHashKey: '<optional>',
    SequenceNumberForOrdering: '<optional>',
  },
  'Kinesis:putRecords': {
    Records: [
      {
        ExplicitHashKey: '<optional>',
      },
    ],
    StreamName: 'required',
  },
  'Kinesis:registerStreamConsumer': {
    StreamARN: 'required',
    ConsumerName: 'required',
  },
  'Kinesis:removeTagsFromStream': {
    StreamName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Kinesis:splitShard': {
    StreamName: 'required',
    ShardToSplit: 'required',
    NewStartingHashKey: 'required',
  },
  'Kinesis:startStreamEncryption': {
    StreamName: 'required',
    EncryptionType: 'required',
    KeyId: 'required',
  },
  'Kinesis:stopStreamEncryption': {
    StreamName: 'required',
    EncryptionType: 'required',
    KeyId: 'required',
  },
  'Kinesis:updateShardCount': {
    StreamName: 'required',
    TargetShardCount: 'required',
    ScalingType: 'required',
  },
  'KinesisAnalytics:addApplicationCloudWatchLoggingOption': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    CloudWatchLoggingOption: {
      LogStreamARN: 'required',
      RoleARN: 'required',
    },
  },
  'KinesisAnalytics:addApplicationInput': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    Input: {
      InputProcessingConfiguration: '<optional>',
      KinesisStreamsInput: '<optional>',
      KinesisFirehoseInput: '<optional>',
      InputParallelism: {
        Count: '<optional>',
      },
      InputSchema: {
        RecordEncoding: '<optional>',
        RecordFormat: {
          MappingParameters: [
            {
              JSONMappingParameters: ['<optional1>', '<optional2>'],
              CSVMappingParameters: ['<optional1>', '<optional2>'],
            },
          ],
        },
        RecordColumns: [
          {
            Mapping: '<optional>',
          },
        ],
      },
    },
  },
  'KinesisAnalytics:addApplicationInputProcessingConfiguration': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    InputId: 'required',
    InputProcessingConfiguration: {
      InputLambdaProcessor: {
        ResourceARN: 'required',
        RoleARN: 'required',
      },
    },
  },
  'KinesisAnalytics:addApplicationOutput': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    Output: {
      KinesisStreamsOutput: '<optional>',
      KinesisFirehoseOutput: '<optional>',
      LambdaOutput: '<optional>',
    },
  },
  'KinesisAnalytics:addApplicationReferenceDataSource': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    ReferenceDataSource: {
      S3ReferenceDataSource: '<optional>',
      ReferenceSchema: {
        RecordEncoding: '<optional>',
        RecordFormat: {
          MappingParameters: [
            {
              JSONMappingParameters: ['<optional1>', '<optional2>'],
              CSVMappingParameters: ['<optional1>', '<optional2>'],
            },
          ],
        },
        RecordColumns: [
          {
            Mapping: '<optional>',
          },
        ],
      },
    },
  },
  'KinesisAnalytics:createApplication': {
    ApplicationName: 'required',
    Inputs: [
      {
        InputProcessingConfiguration: '<optional>',
        KinesisStreamsInput: '<optional>',
        KinesisFirehoseInput: '<optional>',
        InputParallelism: {
          Count: '<optional>',
        },
        InputSchema: {
          RecordEncoding: '<optional>',
          RecordFormat: {
            MappingParameters: [
              {
                JSONMappingParameters: ['<optional1>', '<optional2>'],
                CSVMappingParameters: ['<optional1>', '<optional2>'],
              },
            ],
          },
          RecordColumns: [
            {
              Mapping: '<optional>',
            },
          ],
        },
      },
    ],
    Outputs: [
      {
        KinesisStreamsOutput: '<optional>',
        KinesisFirehoseOutput: '<optional>',
        LambdaOutput: '<optional>',
      },
    ],
    CloudWatchLoggingOptions: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    ApplicationDescription: '<optional>',
    ApplicationCode: '<optional>',
  },
  'KinesisAnalytics:deleteApplication': {
    ApplicationName: 'required',
    CreateTimestamp: 'required',
  },
  'KinesisAnalytics:deleteApplicationCloudWatchLoggingOption': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    CloudWatchLoggingOptionId: 'required',
  },
  'KinesisAnalytics:deleteApplicationInputProcessingConfiguration': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    InputId: 'required',
  },
  'KinesisAnalytics:deleteApplicationOutput': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    OutputId: 'required',
  },
  'KinesisAnalytics:deleteApplicationReferenceDataSource': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    ReferenceId: 'required',
  },
  'KinesisAnalytics:describeApplication': {
    ApplicationName: 'required',
  },
  'KinesisAnalytics:discoverInputSchema': {
    S3Configuration: '<optional>',
    InputProcessingConfiguration: '<optional>',
    ResourceARN: '<optional>',
    RoleARN: '<optional>',
    InputStartingPositionConfiguration: {
      InputStartingPosition: '<optional>',
    },
  },
  'KinesisAnalytics:listApplications': {
    Limit: '<optional>',
    ExclusiveStartApplicationName: '<optional>',
  },
  'KinesisAnalytics:listTagsForResource': {
    ResourceARN: 'required',
  },
  'KinesisAnalytics:startApplication': {
    ApplicationName: 'required',
    InputConfigurations: [
      {
        InputStartingPositionConfiguration: {
          InputStartingPosition: '<optional>',
        },
      },
    ],
  },
  'KinesisAnalytics:stopApplication': {
    ApplicationName: 'required',
  },
  'KinesisAnalytics:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'KinesisAnalytics:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'KinesisAnalytics:updateApplication': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    ApplicationUpdate: {
      InputUpdates: [
        {
          NamePrefixUpdate: '<optional>',
          InputProcessingConfigurationUpdate: {
            InputLambdaProcessorUpdate: {
              ResourceARNUpdate: '<optional>',
              RoleARNUpdate: '<optional>',
            },
          },
          KinesisStreamsInputUpdate: {
            ResourceARNUpdate: '<optional>',
            RoleARNUpdate: '<optional>',
          },
          KinesisFirehoseInputUpdate: {
            ResourceARNUpdate: '<optional>',
            RoleARNUpdate: '<optional>',
          },
          InputSchemaUpdate: {
            RecordFormatUpdate: {
              MappingParameters: [
                {
                  JSONMappingParameters: ['<optional1>', '<optional2>'],
                  CSVMappingParameters: ['<optional1>', '<optional2>'],
                },
              ],
            },
            RecordEncodingUpdate: '<optional>',
            RecordColumnUpdates: [
              {
                Mapping: '<optional>',
              },
            ],
          },
          InputParallelismUpdate: {
            CountUpdate: '<optional>',
          },
        },
      ],
      ApplicationCodeUpdate: '<optional>',
      OutputUpdates: [
        {
          NameUpdate: '<optional>',
          KinesisStreamsOutputUpdate: {
            ResourceARNUpdate: '<optional>',
            RoleARNUpdate: '<optional>',
          },
          KinesisFirehoseOutputUpdate: {
            ResourceARNUpdate: '<optional>',
            RoleARNUpdate: '<optional>',
          },
          LambdaOutputUpdate: {
            ResourceARNUpdate: '<optional>',
            RoleARNUpdate: '<optional>',
          },
          DestinationSchemaUpdate: '<optional>',
        },
      ],
      ReferenceDataSourceUpdates: [
        {
          TableNameUpdate: '<optional>',
          S3ReferenceDataSourceUpdate: {
            BucketARNUpdate: '<optional>',
            FileKeyUpdate: '<optional>',
            ReferenceRoleARNUpdate: '<optional>',
          },
          ReferenceSchemaUpdate: {
            RecordEncoding: '<optional>',
            RecordFormat: {
              MappingParameters: [
                {
                  JSONMappingParameters: ['<optional1>', '<optional2>'],
                  CSVMappingParameters: ['<optional1>', '<optional2>'],
                },
              ],
            },
            RecordColumns: [
              {
                Mapping: '<optional>',
              },
            ],
          },
        },
      ],
      CloudWatchLoggingOptionUpdates: [
        {
          LogStreamARNUpdate: '<optional>',
          RoleARNUpdate: '<optional>',
        },
      ],
    },
  },
  'KinesisAnalyticsV2:addApplicationCloudWatchLoggingOption': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    CloudWatchLoggingOption: {
      LogStreamARN: 'required',
    },
  },
  'KinesisAnalyticsV2:addApplicationInput': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    Input: {
      InputProcessingConfiguration: '<optional>',
      KinesisStreamsInput: '<optional>',
      KinesisFirehoseInput: '<optional>',
      InputParallelism: {
        Count: '<optional>',
      },
      InputSchema: {
        RecordEncoding: '<optional>',
        RecordFormat: {
          MappingParameters: [
            {
              JSONMappingParameters: ['<optional1>', '<optional2>'],
              CSVMappingParameters: ['<optional1>', '<optional2>'],
            },
          ],
        },
        RecordColumns: [
          {
            Mapping: '<optional>',
          },
        ],
      },
    },
  },
  'KinesisAnalyticsV2:addApplicationInputProcessingConfiguration': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    InputId: 'required',
    InputProcessingConfiguration: {
      InputLambdaProcessor: {
        ResourceARN: 'required',
      },
    },
  },
  'KinesisAnalyticsV2:addApplicationOutput': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    Output: {
      KinesisStreamsOutput: '<optional>',
      KinesisFirehoseOutput: '<optional>',
      LambdaOutput: '<optional>',
    },
  },
  'KinesisAnalyticsV2:addApplicationReferenceDataSource': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    ReferenceDataSource: {
      S3ReferenceDataSource: {
        BucketARN: '<optional>',
        FileKey: '<optional>',
      },
      ReferenceSchema: {
        RecordEncoding: '<optional>',
        RecordFormat: {
          MappingParameters: [
            {
              JSONMappingParameters: ['<optional1>', '<optional2>'],
              CSVMappingParameters: ['<optional1>', '<optional2>'],
            },
          ],
        },
        RecordColumns: [
          {
            Mapping: '<optional>',
          },
        ],
      },
    },
  },
  'KinesisAnalyticsV2:addApplicationVpcConfiguration': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    VpcConfiguration: {
      SubnetIds: ['required1', 'required2'],
      SecurityGroupIds: ['required1', 'required2'],
    },
  },
  'KinesisAnalyticsV2:createApplication': {
    ApplicationName: 'required',
    RuntimeEnvironment: 'required',
    ServiceExecutionRole: 'required',
    ApplicationConfiguration: {
      SqlApplicationConfiguration: {
        Inputs: [
          {
            InputProcessingConfiguration: '<optional>',
            KinesisStreamsInput: '<optional>',
            KinesisFirehoseInput: '<optional>',
            InputParallelism: {
              Count: '<optional>',
            },
            InputSchema: {
              RecordEncoding: '<optional>',
              RecordFormat: {
                MappingParameters: [
                  {
                    JSONMappingParameters: ['<optional1>', '<optional2>'],
                    CSVMappingParameters: ['<optional1>', '<optional2>'],
                  },
                ],
              },
              RecordColumns: [
                {
                  Mapping: '<optional>',
                },
              ],
            },
          },
        ],
        Outputs: [
          {
            KinesisStreamsOutput: '<optional>',
            KinesisFirehoseOutput: '<optional>',
            LambdaOutput: '<optional>',
          },
        ],
        ReferenceDataSources: [
          {
            S3ReferenceDataSource: {
              BucketARN: '<optional>',
              FileKey: '<optional>',
            },
            ReferenceSchema: {
              RecordEncoding: '<optional>',
              RecordFormat: {
                MappingParameters: [
                  {
                    JSONMappingParameters: ['<optional1>', '<optional2>'],
                    CSVMappingParameters: ['<optional1>', '<optional2>'],
                  },
                ],
              },
              RecordColumns: [
                {
                  Mapping: '<optional>',
                },
              ],
            },
          },
        ],
      },
      FlinkApplicationConfiguration: {
        CheckpointConfiguration: {
          CheckpointingEnabled: '<optional>',
          CheckpointInterval: '<optional>',
          MinPauseBetweenCheckpoints: ['<optional1>', '<optional2>'],
        },
        MonitoringConfiguration: {
          MetricsLevel: '<optional>',
          LogLevel: '<optional>',
        },
        ParallelismConfiguration: {
          Parallelism: '<optional>',
          ParallelismPerKPU: '<optional>',
          AutoScalingEnabled: '<optional>',
        },
      },
      EnvironmentProperties: ['<optional1>', '<optional2>'],
      ApplicationSnapshotConfiguration: '<optional>',
      ApplicationCodeConfiguration: {
        CodeContent: {
          TextContent: '<optional>',
          ZipFileContent: '<optional>',
          S3ContentLocation: {
            ObjectVersion: '<optional>',
          },
        },
      },
    },
    CloudWatchLoggingOptions: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    ApplicationDescription: '<optional>',
  },
  'KinesisAnalyticsV2:createApplicationSnapshot': {
    ApplicationName: 'required',
    SnapshotName: 'required',
  },
  'KinesisAnalyticsV2:deleteApplication': {
    ApplicationName: 'required',
    CreateTimestamp: 'required',
  },
  'KinesisAnalyticsV2:deleteApplicationCloudWatchLoggingOption': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    CloudWatchLoggingOptionId: 'required',
  },
  'KinesisAnalyticsV2:deleteApplicationInputProcessingConfiguration': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    InputId: 'required',
  },
  'KinesisAnalyticsV2:deleteApplicationOutput': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    OutputId: 'required',
  },
  'KinesisAnalyticsV2:deleteApplicationReferenceDataSource': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    ReferenceId: 'required',
  },
  'KinesisAnalyticsV2:deleteApplicationSnapshot': {
    ApplicationName: 'required',
    SnapshotName: 'required',
    SnapshotCreationTimestamp: 'required',
  },
  'KinesisAnalyticsV2:deleteApplicationVpcConfiguration': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    VpcConfigurationId: 'required',
  },
  'KinesisAnalyticsV2:describeApplication': {
    ApplicationName: 'required',
    IncludeAdditionalDetails: ['<optional1>', '<optional2>'],
  },
  'KinesisAnalyticsV2:describeApplicationSnapshot': {
    ApplicationName: 'required',
    SnapshotName: 'required',
  },
  'KinesisAnalyticsV2:discoverInputSchema': {
    ServiceExecutionRole: 'required',
    S3Configuration: '<optional>',
    InputProcessingConfiguration: '<optional>',
    ResourceARN: '<optional>',
    InputStartingPositionConfiguration: {
      InputStartingPosition: '<optional>',
    },
  },
  'KinesisAnalyticsV2:listApplicationSnapshots': {
    ApplicationName: 'required',
    Limit: '<optional>',
  },
  'KinesisAnalyticsV2:listApplications': {
    Limit: '<optional>',
  },
  'KinesisAnalyticsV2:listTagsForResource': {
    ResourceARN: 'required',
  },
  'KinesisAnalyticsV2:startApplication': {
    ApplicationName: 'required',
    RunConfiguration: {
      SqlRunConfigurations: [
        {
          InputStartingPositionConfiguration: {
            InputStartingPosition: '<optional>',
          },
        },
      ],
      ApplicationRestoreConfiguration: {
        SnapshotName: '<optional>',
      },
    },
  },
  'KinesisAnalyticsV2:stopApplication': {
    ApplicationName: 'required',
  },
  'KinesisAnalyticsV2:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'KinesisAnalyticsV2:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'KinesisAnalyticsV2:updateApplication': {
    ApplicationName: 'required',
    CurrentApplicationVersionId: 'required',
    ApplicationConfigurationUpdate: {
      SqlApplicationConfigurationUpdate: {
        InputUpdates: [
          {
            NamePrefixUpdate: '<optional>',
            InputProcessingConfigurationUpdate: '<optional>',
            KinesisStreamsInputUpdate: '<optional>',
            KinesisFirehoseInputUpdate: '<optional>',
            InputSchemaUpdate: {
              RecordFormatUpdate: {
                MappingParameters: [
                  {
                    JSONMappingParameters: ['<optional1>', '<optional2>'],
                    CSVMappingParameters: ['<optional1>', '<optional2>'],
                  },
                ],
              },
              RecordEncodingUpdate: '<optional>',
              RecordColumnUpdates: [
                {
                  Mapping: '<optional>',
                },
              ],
            },
            InputParallelismUpdate: '<optional>',
          },
        ],
        OutputUpdates: [
          {
            NameUpdate: '<optional>',
            KinesisStreamsOutputUpdate: '<optional>',
            KinesisFirehoseOutputUpdate: '<optional>',
            LambdaOutputUpdate: '<optional>',
            DestinationSchemaUpdate: '<optional>',
          },
        ],
        ReferenceDataSourceUpdates: [
          {
            TableNameUpdate: '<optional>',
            S3ReferenceDataSourceUpdate: {
              BucketARNUpdate: '<optional>',
              FileKeyUpdate: '<optional>',
            },
            ReferenceSchemaUpdate: {
              RecordEncoding: '<optional>',
              RecordFormat: {
                MappingParameters: [
                  {
                    JSONMappingParameters: ['<optional1>', '<optional2>'],
                    CSVMappingParameters: ['<optional1>', '<optional2>'],
                  },
                ],
              },
              RecordColumns: [
                {
                  Mapping: '<optional>',
                },
              ],
            },
          },
        ],
      },
      ApplicationCodeConfigurationUpdate: {
        CodeContentTypeUpdate: '<optional>',
        CodeContentUpdate: {
          TextContentUpdate: '<optional>',
          ZipFileContentUpdate: '<optional>',
          S3ContentLocationUpdate: {
            BucketARNUpdate: '<optional>',
            FileKeyUpdate: '<optional>',
            ObjectVersionUpdate: '<optional>',
          },
        },
      },
      FlinkApplicationConfigurationUpdate: {
        CheckpointConfigurationUpdate: {
          ConfigurationTypeUpdate: '<optional>',
          CheckpointingEnabledUpdate: '<optional>',
          CheckpointIntervalUpdate: '<optional>',
          MinPauseBetweenCheckpointsUpdate: '<optional>',
        },
        MonitoringConfigurationUpdate: {
          ConfigurationTypeUpdate: '<optional>',
          MetricsLevelUpdate: '<optional>',
          LogLevelUpdate: '<optional>',
        },
        ParallelismConfigurationUpdate: {
          ConfigurationTypeUpdate: '<optional>',
          ParallelismUpdate: '<optional>',
          ParallelismPerKPUUpdate: '<optional>',
          AutoScalingEnabledUpdate: '<optional>',
        },
      },
      EnvironmentPropertyUpdates: ['<optional1>', '<optional2>'],
      ApplicationSnapshotConfigurationUpdate: '<optional>',
    },
    RunConfigurationUpdate: {
      ApplicationRestoreConfiguration: {
        SnapshotName: '<optional>',
      },
    },
    CloudWatchLoggingOptionUpdates: [
      {
        LogStreamARNUpdate: '<optional>',
      },
    ],
    ServiceExecutionRoleUpdate: '<optional>',
  },
  'KinesisVideo:createSignalingChannel': {
    ChannelName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    ChannelType: '<optional>',
    SingleMasterConfiguration: {
      MessageTtlSeconds: ['<optional1>', '<optional2>'],
    },
  },
  'KinesisVideo:createStream': {
    StreamName: 'required',
    DeviceName: '<optional>',
    MediaType: '<optional>',
    KmsKeyId: '<optional>',
    DataRetentionInHours: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
  },
  'KinesisVideo:deleteSignalingChannel': {
    ChannelARN: 'required',
    CurrentVersion: '<optional>',
  },
  'KinesisVideo:deleteStream': {
    StreamARN: 'required',
    CurrentVersion: '<optional>',
  },
  'KinesisVideo:describeSignalingChannel': {
    ChannelName: '<optional>',
    ChannelARN: '<optional>',
  },
  'KinesisVideo:describeStream': {
    StreamName: '<optional>',
    StreamARN: '<optional>',
  },
  'KinesisVideo:getDataEndpoint': {
    APIName: 'required',
    StreamName: '<optional>',
    StreamARN: '<optional>',
  },
  'KinesisVideo:getSignalingChannelEndpoint': {
    ChannelARN: 'required',
    SingleMasterChannelEndpointConfiguration: {
      Protocols: ['<optional1>', '<optional2>'],
      Role: '<optional>',
    },
  },
  'KinesisVideo:listSignalingChannels': {
    ChannelNameCondition: {
      ComparisonOperator: '<optional>',
      ComparisonValue: '<optional>',
    },
  },
  'KinesisVideo:listStreams': {
    StreamNameCondition: {
      ComparisonOperator: '<optional>',
      ComparisonValue: '<optional>',
    },
  },
  'KinesisVideo:listTagsForResource': {
    ResourceARN: 'required',
  },
  'KinesisVideo:listTagsForStream': {
    StreamARN: '<optional>',
    StreamName: '<optional>',
  },
  'KinesisVideo:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'KinesisVideo:tagStream': {
    Tags: ['required1', 'required2'],
    StreamARN: '<optional>',
    StreamName: '<optional>',
  },
  'KinesisVideo:untagResource': {
    ResourceARN: 'required',
    TagKeyList: 'required',
  },
  'KinesisVideo:untagStream': {
    TagKeyList: 'required',
    StreamARN: '<optional>',
    StreamName: '<optional>',
  },
  'KinesisVideo:updateDataRetention': {
    CurrentVersion: 'required',
    Operation: 'required',
    DataRetentionChangeInHours: ['required1', 'required2'],
    StreamName: '<optional>',
    StreamARN: '<optional>',
  },
  'KinesisVideo:updateSignalingChannel': {
    ChannelARN: 'required',
    CurrentVersion: 'required',
    SingleMasterConfiguration: {
      MessageTtlSeconds: ['<optional1>', '<optional2>'],
    },
  },
  'KinesisVideo:updateStream': {
    CurrentVersion: 'required',
    StreamName: '<optional>',
    StreamARN: '<optional>',
    DeviceName: '<optional>',
    MediaType: '<optional>',
  },
  'KinesisVideoArchivedMedia:getDASHStreamingSessionURL': {
    StreamName: '<optional>',
    StreamARN: '<optional>',
    PlaybackMode: '<optional>',
    DisplayFragmentTimestamp: '<optional>',
    DisplayFragmentNumber: '<optional>',
    DASHFragmentSelector: {
      FragmentSelectorType: '<optional>',
      TimestampRange: {
        StartTimestamp: '<optional>',
        EndTimestamp: '<optional>',
      },
    },
    Expires: ['<optional1>', '<optional2>'],
    MaxManifestFragmentResults: ['<optional1>', '<optional2>'],
  },
  'KinesisVideoArchivedMedia:getHLSStreamingSessionURL': {
    StreamName: '<optional>',
    StreamARN: '<optional>',
    PlaybackMode: '<optional>',
    HLSFragmentSelector: {
      FragmentSelectorType: '<optional>',
      TimestampRange: {
        StartTimestamp: '<optional>',
        EndTimestamp: '<optional>',
      },
    },
    ContainerFormat: '<optional>',
    DiscontinuityMode: '<optional>',
    DisplayFragmentTimestamp: '<optional>',
    Expires: ['<optional1>', '<optional2>'],
    MaxMediaPlaylistFragmentResults: ['<optional1>', '<optional2>'],
  },
  'KinesisVideoArchivedMedia:getMediaForFragmentList': {
    StreamName: 'required',
    Fragments: ['required1', 'required2'],
  },
  'KinesisVideoArchivedMedia:listFragments': {
    StreamName: 'required',
    FragmentSelector: '<optional>',
  },
  'KinesisVideoMedia:getMedia': {
    StartSelector: {
      AfterFragmentNumber: '<optional>',
      StartTimestamp: '<optional>',
      ContinuationToken: '<optional>',
    },
    StreamName: '<optional>',
    StreamARN: '<optional>',
  },
  'KinesisVideoSignalingChannels:getIceServerConfig': {
    ChannelARN: 'required',
    ClientId: '<optional>',
    Service: '<optional>',
    Username: '<optional>',
  },
  'KinesisVideoSignalingChannels:sendAlexaOfferToMaster': {
    ChannelARN: 'required',
    SenderClientId: 'required',
    MessagePayload: 'required',
  },
  'LakeFormation:batchGrantPermissions': {
    Entries: [
      {
        Principal: {
          DataLakePrincipalIdentifier: '<optional>',
        },
        Resource: {
          Catalog: '<optional>',
          Database: '<optional>',
          Table: '<optional>',
          TableWithColumns: [
            {
              DatabaseName: '<optional>',
              Name: '<optional>',
              ColumnNames: ['<optional1>', '<optional2>'],
              ColumnWildcard: {
                ExcludedColumnNames: ['<optional1>', '<optional2>'],
              },
            },
          ],
          DataLocation: '<optional>',
        },
        Permissions: ['<optional1>', '<optional2>'],
        PermissionsWithGrantOption: '<optional>',
      },
    ],
    CatalogId: '<optional>',
  },
  'LakeFormation:batchRevokePermissions': {
    Entries: [
      {
        Principal: {
          DataLakePrincipalIdentifier: '<optional>',
        },
        Resource: {
          Catalog: {
            type: '<optional>',
          },
          Database: '<optional>',
          Table: '<optional>',
          TableWithColumns: [
            {
              DatabaseName: '<optional>',
              Name: '<optional>',
              ColumnNames: ['<optional1>', '<optional2>'],
              ColumnWildcard: {
                ExcludedColumnNames: ['<optional1>', '<optional2>'],
              },
            },
          ],
          DataLocation: '<optional>',
        },
        Permissions: ['<optional1>', '<optional2>'],
        PermissionsWithGrantOption: '<optional>',
      },
    ],
    CatalogId: '<optional>',
  },
  'LakeFormation:deregisterResource': {
    ResourceArn: 'required',
  },
  'LakeFormation:describeResource': {
    ResourceArn: 'required',
  },
  'LakeFormation:getDataLakeSettings': {
    CatalogId: '<optional>',
  },
  'LakeFormation:getEffectivePermissionsForPath': {
    ResourceArn: 'required',
    CatalogId: '<optional>',
  },
  'LakeFormation:grantPermissions': {
    Principal: {
      DataLakePrincipalIdentifier: '<optional>',
    },
    Resource: {
      Catalog: {
        type: '<optional>',
      },
      Database: '<optional>',
      Table: '<optional>',
      TableWithColumns: [
        {
          DatabaseName: '<optional>',
          Name: '<optional>',
          ColumnNames: ['<optional1>', '<optional2>'],
          ColumnWildcard: {
            ExcludedColumnNames: ['<optional1>', '<optional2>'],
          },
        },
      ],
      DataLocation: '<optional>',
    },
    Permissions: ['required1', 'required2'],
    CatalogId: '<optional>',
    PermissionsWithGrantOption: '<optional>',
  },
  'LakeFormation:listPermissions': {
    Resource: {
      Catalog: {
        type: '<optional>',
      },
      Database: '<optional>',
      Table: '<optional>',
      TableWithColumns: [
        {
          DatabaseName: '<optional>',
          Name: '<optional>',
          ColumnNames: ['<optional1>', '<optional2>'],
          ColumnWildcard: {
            ExcludedColumnNames: ['<optional1>', '<optional2>'],
          },
        },
      ],
      DataLocation: '<optional>',
    },
    CatalogId: '<optional>',
    Principal: {
      DataLakePrincipalIdentifier: '<optional>',
    },
    ResourceType: '<optional>',
  },
  'LakeFormation:listResources': {
    FilterConditionList: {
      Field: '<optional>',
      ComparisonOperator: '<optional>',
      StringValueList: '<optional>',
    },
  },
  'LakeFormation:putDataLakeSettings': {
    DataLakeSettings: [
      {
        DataLakeAdmins: [
          {
            DataLakePrincipalIdentifier: '<optional>',
          },
        ],
        CreateDatabaseDefaultPermissions: [
          {
            Principal: {
              DataLakePrincipalIdentifier: '<optional>',
            },
            Permissions: ['<optional1>', '<optional2>'],
          },
        ],
        CreateTableDefaultPermissions: [
          {
            Principal: {
              DataLakePrincipalIdentifier: '<optional>',
            },
            Permissions: ['<optional1>', '<optional2>'],
          },
        ],
      },
    ],
    CatalogId: '<optional>',
  },
  'LakeFormation:registerResource': {
    ResourceArn: 'required',
    UseServiceLinkedRole: '<optional>',
    RoleArn: '<optional>',
  },
  'LakeFormation:revokePermissions': {
    Principal: {
      DataLakePrincipalIdentifier: '<optional>',
    },
    Resource: {
      Catalog: {
        type: '<optional>',
      },
      Database: '<optional>',
      Table: '<optional>',
      TableWithColumns: [
        {
          DatabaseName: '<optional>',
          Name: '<optional>',
          ColumnNames: ['<optional1>', '<optional2>'],
          ColumnWildcard: {
            ExcludedColumnNames: ['<optional1>', '<optional2>'],
          },
        },
      ],
      DataLocation: '<optional>',
    },
    Permissions: ['required1', 'required2'],
    CatalogId: '<optional>',
    PermissionsWithGrantOption: '<optional>',
  },
  'LakeFormation:updateResource': {
    RoleArn: 'required',
    ResourceArn: 'required',
  },
  'Lambda:addLayerVersionPermission': {
    LayerName: 'required',
    VersionNumber: 'required',
    StatementId: 'required',
    Action: 'required',
    Principal: 'required',
    OrganizationId: '<optional>',
    RevisionId: '<optional>',
  },
  'Lambda:addPermission': {
    FunctionName: 'required',
    StatementId: 'required',
    Action: 'required',
    Principal: 'required',
    SourceArn: '<optional>',
    SourceAccount: '<optional>',
    EventSourceToken: '<optional>',
    Qualifier: '<optional>',
    RevisionId: '<optional>',
  },
  'Lambda:createAlias': {
    FunctionName: 'required',
    Name: 'required',
    FunctionVersion: 'required',
    Description: '<optional>',
    RoutingConfig: {
      AdditionalVersionWeights: ['<optional1>', '<optional2>'],
    },
  },
  'Lambda:createEventSourceMapping': {
    EventSourceArn: 'required',
    FunctionName: 'required',
    Enabled: '<optional>',
    BatchSize: '<optional>',
    StartingPosition: '<optional>',
    StartingPositionTimestamp: '<optional>',
  },
  'Lambda:createFunction': {
    FunctionName: 'required',
    Runtime: 'required',
    Role: 'required',
    Handler: 'required',
    Code: {
      ZipFile: '<optional>',
      S3Bucket: '<optional>',
      S3Key: '<optional>',
      S3ObjectVersion: '<optional>',
    },
    Description: '<optional>',
    Timeout: '<optional>',
    MemorySize: '<optional>',
    Publish: '<optional>',
    VpcConfig: {
      SubnetIds: ['<optional1>', '<optional2>'],
      SecurityGroupIds: ['<optional1>', '<optional2>'],
    },
    DeadLetterConfig: {
      TargetArn: '<optional>',
    },
    Environment: {
      Variables: ['<optional1>', '<optional2>'],
    },
    KMSKeyArn: '<optional>',
    TracingConfig: {
      Mode: '<optional>',
    },
    Tags: ['<optional1>', '<optional2>'],
    Layers: ['<optional1>', '<optional2>'],
  },
  'Lambda:deleteAlias': {
    FunctionName: 'required',
    Name: 'required',
  },
  'Lambda:deleteEventSourceMapping': {
    UUID: 'required',
  },
  'Lambda:deleteFunction': {
    FunctionName: 'required',
    Qualifier: '<optional>',
  },
  'Lambda:deleteFunctionConcurrency': {
    FunctionName: 'required',
  },
  'Lambda:deleteFunctionEventInvokeConfig': {
    FunctionName: 'required',
    Qualifier: '<optional>',
  },
  'Lambda:deleteLayerVersion': {
    LayerName: 'required',
    VersionNumber: 'required',
  },
  'Lambda:deleteProvisionedConcurrencyConfig': {
    FunctionName: 'required',
    Qualifier: 'required',
  },
  'Lambda:getAccountSettings': {},
  'Lambda:getAlias': {
    FunctionName: 'required',
    Name: 'required',
  },
  'Lambda:getEventSourceMapping': {
    UUID: 'required',
  },
  'Lambda:getFunction': {
    FunctionName: 'required',
    Qualifier: '<optional>',
  },
  'Lambda:getFunctionConcurrency': {
    FunctionName: 'required',
  },
  'Lambda:getFunctionConfiguration': {
    FunctionName: 'required',
    Qualifier: '<optional>',
  },
  'Lambda:getFunctionEventInvokeConfig': {
    FunctionName: 'required',
    Qualifier: '<optional>',
  },
  'Lambda:getLayerVersion': {
    LayerName: 'required',
    VersionNumber: 'required',
  },
  'Lambda:getLayerVersionByArn': {
    Arn: 'required',
  },
  'Lambda:getLayerVersionPolicy': {
    LayerName: 'required',
    VersionNumber: 'required',
  },
  'Lambda:getPolicy': {
    FunctionName: 'required',
    Qualifier: '<optional>',
  },
  'Lambda:getProvisionedConcurrencyConfig': {
    FunctionName: 'required',
    Qualifier: 'required',
  },
  'Lambda:invoke': {
    FunctionName: 'required',
    InvocationType: '<optional>',
    LogType: '<optional>',
    ClientContext: '<optional>',
    Payload: '<optional>',
    Qualifier: '<optional>',
  },
  'Lambda:invokeAsync': {
    FunctionName: 'required',
    InvokeArgs: ['required1', 'required2'],
  },
  'Lambda:listAliases': {
    FunctionName: 'required',
    FunctionVersion: '<optional>',
  },
  'Lambda:listEventSourceMappings': {
    EventSourceArn: '<optional>',
    FunctionName: '<optional>',
  },
  'Lambda:listFunctionEventInvokeConfigs': {
    FunctionName: 'required',
  },
  'Lambda:listFunctions': {
    MasterRegion: '<optional>',
    FunctionVersion: '<optional>',
  },
  'Lambda:listLayerVersions': {
    LayerName: 'required',
    CompatibleRuntime: '<optional>',
  },
  'Lambda:listLayers': {
    CompatibleRuntime: '<optional>',
  },
  'Lambda:listProvisionedConcurrencyConfigs': {
    FunctionName: 'required',
  },
  'Lambda:listTags': {
    Resource: 'required',
  },
  'Lambda:listVersionsByFunction': {
    FunctionName: 'required',
  },
  'Lambda:publishLayerVersion': {
    LayerName: 'required',
    Content: {
      S3Bucket: '<optional>',
      S3Key: '<optional>',
      S3ObjectVersion: '<optional>',
      ZipFile: '<optional>',
    },
    Description: '<optional>',
    CompatibleRuntimes: ['<optional1>', '<optional2>'],
    LicenseInfo: '<optional>',
  },
  'Lambda:publishVersion': {
    FunctionName: 'required',
    CodeSha256: '<optional>',
    Description: '<optional>',
    RevisionId: '<optional>',
  },
  'Lambda:putFunctionConcurrency': {
    FunctionName: 'required',
    ReservedConcurrentExecutions: ['required1', 'required2'],
  },
  'Lambda:putFunctionEventInvokeConfig': {
    FunctionName: 'required',
    Qualifier: '<optional>',
    MaximumRetryAttempts: ['<optional1>', '<optional2>'],
    MaximumEventAgeInSeconds: ['<optional1>', '<optional2>'],
    DestinationConfig: {
      OnSuccess: {
        Destination: '<optional>',
      },
      OnFailure: {
        Destination: '<optional>',
      },
    },
  },
  'Lambda:putProvisionedConcurrencyConfig': {
    FunctionName: 'required',
    Qualifier: 'required',
    ProvisionedConcurrentExecutions: ['required1', 'required2'],
  },
  'Lambda:removeLayerVersionPermission': {
    LayerName: 'required',
    VersionNumber: 'required',
    StatementId: 'required',
    RevisionId: '<optional>',
  },
  'Lambda:removePermission': {
    FunctionName: 'required',
    StatementId: 'required',
    Qualifier: '<optional>',
    RevisionId: '<optional>',
  },
  'Lambda:tagResource': {
    Resource: 'required',
    Tags: ['required1', 'required2'],
  },
  'Lambda:untagResource': {
    Resource: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Lambda:updateAlias': {
    FunctionName: 'required',
    Name: 'required',
    FunctionVersion: '<optional>',
    Description: '<optional>',
    RoutingConfig: {
      AdditionalVersionWeights: ['<optional1>', '<optional2>'],
    },
    RevisionId: '<optional>',
  },
  'Lambda:updateEventSourceMapping': {
    UUID: 'required',
    FunctionName: '<optional>',
    Enabled: '<optional>',
    BatchSize: '<optional>',
  },
  'Lambda:updateFunctionCode': {
    FunctionName: 'required',
    ZipFile: '<optional>',
    S3Bucket: '<optional>',
    S3Key: '<optional>',
    S3ObjectVersion: '<optional>',
    Publish: '<optional>',
    RevisionId: '<optional>',
  },
  'Lambda:updateFunctionConfiguration': {
    FunctionName: 'required',
    Role: '<optional>',
    Handler: '<optional>',
    Description: '<optional>',
    Timeout: '<optional>',
    MemorySize: '<optional>',
    VpcConfig: {
      SubnetIds: ['<optional1>', '<optional2>'],
      SecurityGroupIds: ['<optional1>', '<optional2>'],
    },
    Environment: {
      Variables: ['<optional1>', '<optional2>'],
    },
    Runtime: '<optional>',
    DeadLetterConfig: {
      TargetArn: '<optional>',
    },
    KMSKeyArn: '<optional>',
    TracingConfig: {
      Mode: '<optional>',
    },
    RevisionId: '<optional>',
    Layers: ['<optional1>', '<optional2>'],
  },
  'Lambda:updateFunctionEventInvokeConfig': {
    FunctionName: 'required',
    Qualifier: '<optional>',
    MaximumRetryAttempts: ['<optional1>', '<optional2>'],
    MaximumEventAgeInSeconds: ['<optional1>', '<optional2>'],
    DestinationConfig: {
      OnSuccess: {
        Destination: '<optional>',
      },
      OnFailure: {
        Destination: '<optional>',
      },
    },
  },
  'LexModelBuildingService:createBotVersion': {
    name: 'required',
    checksum: '<optional>',
  },
  'LexModelBuildingService:createIntentVersion': {
    name: 'required',
    checksum: '<optional>',
  },
  'LexModelBuildingService:createSlotTypeVersion': {
    name: 'required',
    checksum: '<optional>',
  },
  'LexModelBuildingService:deleteBot': {
    name: 'required',
  },
  'LexModelBuildingService:deleteBotAlias': {
    name: 'required',
    botName: 'required',
  },
  'LexModelBuildingService:deleteBotChannelAssociation': {
    name: 'required',
    botName: 'required',
    botAlias: 'required',
  },
  'LexModelBuildingService:deleteBotVersion': {
    name: 'required',
    version: 'required',
  },
  'LexModelBuildingService:deleteIntent': {
    name: 'required',
  },
  'LexModelBuildingService:deleteIntentVersion': {
    name: 'required',
    version: 'required',
  },
  'LexModelBuildingService:deleteSlotType': {
    name: 'required',
  },
  'LexModelBuildingService:deleteSlotTypeVersion': {
    name: 'required',
    version: 'required',
  },
  'LexModelBuildingService:deleteUtterances': {
    botName: 'required',
    userId: 'required',
  },
  'LexModelBuildingService:getBot': {
    name: 'required',
    versionOrAlias: 'required',
  },
  'LexModelBuildingService:getBotAlias': {
    name: 'required',
    botName: 'required',
  },
  'LexModelBuildingService:getBotAliases': {
    botName: 'required',
    nameContains: ['<optional1>', '<optional2>'],
  },
  'LexModelBuildingService:getBotChannelAssociation': {
    name: 'required',
    botName: 'required',
    botAlias: 'required',
  },
  'LexModelBuildingService:getBotChannelAssociations': {
    botName: 'required',
    botAlias: 'required',
    nameContains: ['<optional1>', '<optional2>'],
  },
  'LexModelBuildingService:getBotVersions': {
    name: 'required',
  },
  'LexModelBuildingService:getBots': {
    nameContains: ['<optional1>', '<optional2>'],
  },
  'LexModelBuildingService:getBuiltinIntent': {
    signature: 'required',
  },
  'LexModelBuildingService:getBuiltinIntents': {
    locale: '<optional>',
    signatureContains: ['<optional1>', '<optional2>'],
  },
  'LexModelBuildingService:getBuiltinSlotTypes': {
    locale: '<optional>',
    signatureContains: ['<optional1>', '<optional2>'],
  },
  'LexModelBuildingService:getExport': {
    name: 'required',
    version: 'required',
    resourceType: 'required',
    exportType: 'required',
  },
  'LexModelBuildingService:getImport': {
    importId: 'required',
  },
  'LexModelBuildingService:getIntent': {
    name: 'required',
    version: 'required',
  },
  'LexModelBuildingService:getIntentVersions': {
    name: 'required',
  },
  'LexModelBuildingService:getIntents': {
    nameContains: ['<optional1>', '<optional2>'],
  },
  'LexModelBuildingService:getSlotType': {
    name: 'required',
    version: 'required',
  },
  'LexModelBuildingService:getSlotTypeVersions': {
    name: 'required',
  },
  'LexModelBuildingService:getSlotTypes': {
    nameContains: ['<optional1>', '<optional2>'],
  },
  'LexModelBuildingService:getUtterancesView': {
    botName: 'required',
    botVersions: ['required1', 'required2'],
    statusType: 'required',
  },
  'LexModelBuildingService:putBot': {
    name: 'required',
    locale: 'required',
    childDirected: 'required',
    intents: ['<optional1>', '<optional2>'],
    clarificationPrompt: {
      responseCard: '<optional>',
      messages: [
        {
          groupNumber: '<optional>',
        },
      ],
    },
    abortStatement: {
      responseCard: '<optional>',
      messages: [
        {
          groupNumber: '<optional>',
        },
      ],
    },
    description: '<optional>',
    idleSessionTTLInSeconds: ['<optional1>', '<optional2>'],
    voiceId: '<optional>',
    checksum: '<optional>',
    processBehavior: '<optional>',
    createVersion: '<optional>',
  },
  'LexModelBuildingService:putBotAlias': {
    name: 'required',
    botVersion: 'required',
    botName: 'required',
    description: '<optional>',
    checksum: '<optional>',
  },
  'LexModelBuildingService:putIntent': {
    name: 'required',
    slots: [
      {
        description: '<optional>',
        slotType: '<optional>',
        slotTypeVersion: '<optional>',
        valueElicitationPrompt: {
          responseCard: '<optional>',
          messages: [
            {
              groupNumber: '<optional>',
            },
          ],
        },
        priority: '<optional>',
        sampleUtterances: ['<optional1>', '<optional2>'],
        responseCard: '<optional>',
      },
    ],
    confirmationPrompt: {
      responseCard: '<optional>',
      messages: [
        {
          groupNumber: '<optional>',
        },
      ],
    },
    rejectionStatement: {
      responseCard: '<optional>',
      messages: [
        {
          groupNumber: '<optional>',
        },
      ],
    },
    followUpPrompt: {
      prompt: {
        responseCard: '<optional>',
        messages: [
          {
            groupNumber: '<optional>',
          },
        ],
      },
      rejectionStatement: {
        responseCard: '<optional>',
        messages: [
          {
            groupNumber: '<optional>',
          },
        ],
      },
    },
    conclusionStatement: {
      responseCard: '<optional>',
      messages: [
        {
          groupNumber: '<optional>',
        },
      ],
    },
    dialogCodeHook: '<optional>',
    fulfillmentActivity: {
      codeHook: '<optional>',
    },
    description: '<optional>',
    sampleUtterances: ['<optional1>', '<optional2>'],
    parentIntentSignature: '<optional>',
    checksum: '<optional>',
    createVersion: '<optional>',
  },
  'LexModelBuildingService:putSlotType': {
    name: 'required',
    enumerationValues: [
      {
        synonyms: ['<optional1>', '<optional2>'],
      },
    ],
    description: '<optional>',
    checksum: '<optional>',
    valueSelectionStrategy: '<optional>',
    createVersion: '<optional>',
  },
  'LexModelBuildingService:startImport': {
    payload: 'required',
    resourceType: 'required',
    mergeStrategy: 'required',
  },
  'LexRuntime:deleteSession': {
    botName: 'required',
    botAlias: 'required',
    userId: 'required',
  },
  'LexRuntime:getSession': {
    botName: 'required',
    botAlias: 'required',
    userId: 'required',
  },
  'LexRuntime:postContent': {
    botName: 'required',
    botAlias: 'required',
    userId: 'required',
    contentType: 'required',
    inputStream: 'required',
    sessionAttributes: ['<optional1>', '<optional2>'],
    requestAttributes: ['<optional1>', '<optional2>'],
    accept: '<optional>',
  },
  'LexRuntime:postText': {
    botName: 'required',
    botAlias: 'required',
    userId: 'required',
    inputText: 'required',
    sessionAttributes: ['<optional1>', '<optional2>'],
    requestAttributes: ['<optional1>', '<optional2>'],
  },
  'LexRuntime:putSession': {
    botName: 'required',
    botAlias: 'required',
    userId: 'required',
    dialogAction: {
      intentName: '<optional>',
      slots: ['<optional1>', '<optional2>'],
      slotToElicit: '<optional>',
      fulfillmentState: '<optional>',
      message: '<optional>',
      messageFormat: '<optional>',
    },
    sessionAttributes: ['<optional1>', '<optional2>'],
    accept: '<optional>',
  },
  'LicenseManager:createLicenseConfiguration': {
    Name: 'required',
    LicenseCountingType: 'required',
    Description: '<optional>',
    LicenseCount: '<optional>',
    LicenseCountHardLimit: '<optional>',
    LicenseRules: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'LicenseManager:deleteLicenseConfiguration': {
    LicenseConfigurationArn: 'required',
  },
  'LicenseManager:getLicenseConfiguration': {
    LicenseConfigurationArn: 'required',
  },
  'LicenseManager:getServiceSettings': {},
  'LicenseManager:listAssociationsForLicenseConfiguration': {
    LicenseConfigurationArn: 'required',
  },
  'LicenseManager:listFailuresForLicenseConfigurationOperations': {
    LicenseConfigurationArn: 'required',
  },
  'LicenseManager:listLicenseConfigurations': {
    LicenseConfigurationArns: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'LicenseManager:listLicenseSpecificationsForResource': {
    ResourceArn: 'required',
  },
  'LicenseManager:listResourceInventory': {
    Filters: [
      {
        Value: '<optional>',
        Name: '<optional>',
        Condition: '<optional>',
      },
    ],
  },
  'LicenseManager:listTagsForResource': {
    ResourceArn: 'required',
  },
  'LicenseManager:listUsageForLicenseConfiguration': {
    LicenseConfigurationArn: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'LicenseManager:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'LicenseManager:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'LicenseManager:updateLicenseConfiguration': {
    LicenseConfigurationArn: 'required',
    LicenseConfigurationStatus: '<optional>',
    LicenseRules: ['<optional1>', '<optional2>'],
    LicenseCount: '<optional>',
    LicenseCountHardLimit: '<optional>',
    Name: '<optional>',
    Description: '<optional>',
  },
  'LicenseManager:updateLicenseSpecificationsForResource': {
    ResourceArn: 'required',
    AddLicenseSpecifications: ['<optional1>', '<optional2>'],
    RemoveLicenseSpecifications: ['<optional1>', '<optional2>'],
  },
  'LicenseManager:updateServiceSettings': {
    OrganizationConfiguration: '<optional>',
    S3BucketArn: '<optional>',
    SnsTopicArn: '<optional>',
    EnableCrossAccountsDiscovery: '<optional>',
  },
  'Lightsail:allocateStaticIp': {
    staticIpName: 'required',
  },
  'Lightsail:attachDisk': {
    diskName: 'required',
    instanceName: 'required',
    diskPath: 'required',
  },
  'Lightsail:attachInstancesToLoadBalancer': {
    loadBalancerName: 'required',
    instanceNames: ['required1', 'required2'],
  },
  'Lightsail:attachLoadBalancerTlsCertificate': {
    loadBalancerName: 'required',
    certificateName: 'required',
  },
  'Lightsail:attachStaticIp': {
    staticIpName: 'required',
    instanceName: 'required',
  },
  'Lightsail:closeInstancePublicPorts': {
    portInfo: {
      fromPort: '<optional>',
      toPort: '<optional>',
      protocol: '<optional>',
    },
    instanceName: 'required',
  },
  'Lightsail:copySnapshot': {
    sourceSnapshotName: 'required',
    targetSnapshotName: 'required',
    sourceRegion: 'required',
  },
  'Lightsail:createCloudFormationStack': {
    instances: [
      {
        userData: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Lightsail:createDisk': {
    diskName: 'required',
    availabilityZone: 'required',
    sizeInGb: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createDiskFromSnapshot': {
    diskName: 'required',
    diskSnapshotName: 'required',
    availabilityZone: 'required',
    sizeInGb: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createDiskSnapshot': {
    diskSnapshotName: 'required',
    diskName: '<optional>',
    instanceName: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createDomain': {
    domainName: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createDomainEntry': {
    domainName: 'required',
    domainEntry: {
      id: '<optional>',
      name: '<optional>',
      target: '<optional>',
      isAlias: '<optional>',
      type: '<optional>',
      options: ['<optional1>', '<optional2>'],
    },
  },
  'Lightsail:createInstanceSnapshot': {
    instanceSnapshotName: 'required',
    instanceName: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createInstances': {
    instanceNames: ['required1', 'required2'],
    availabilityZone: 'required',
    blueprintId: 'required',
    bundleId: 'required',
    customImageName: '<optional>',
    userData: ['<optional1>', '<optional2>'],
    keyPairName: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createInstancesFromSnapshot': {
    instanceNames: ['required1', 'required2'],
    availabilityZone: 'required',
    instanceSnapshotName: 'required',
    bundleId: 'required',
    attachedDiskMapping: '<optional>',
    userData: ['<optional1>', '<optional2>'],
    keyPairName: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createKeyPair': {
    keyPairName: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createLoadBalancer': {
    loadBalancerName: 'required',
    instancePort: 'required',
    healthCheckPath: '<optional>',
    certificateName: '<optional>',
    certificateDomainName: '<optional>',
    certificateAlternativeNames: ['<optional1>', '<optional2>'],
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createLoadBalancerTlsCertificate': {
    loadBalancerName: 'required',
    certificateName: 'required',
    certificateDomainName: 'required',
    certificateAlternativeNames: ['<optional1>', '<optional2>'],
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createRelationalDatabase': {
    relationalDatabaseName: 'required',
    relationalDatabaseBlueprintId: 'required',
    relationalDatabaseBundleId: 'required',
    masterDatabaseName: 'required',
    masterUsername: 'required',
    availabilityZone: '<optional>',
    masterUserPassword: '<optional>',
    preferredBackupWindow: '<optional>',
    preferredMaintenanceWindow: '<optional>',
    publiclyAccessible: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createRelationalDatabaseFromSnapshot': {
    relationalDatabaseName: 'required',
    availabilityZone: '<optional>',
    publiclyAccessible: '<optional>',
    relationalDatabaseSnapshotName: '<optional>',
    relationalDatabaseBundleId: '<optional>',
    sourceRelationalDatabaseName: '<optional>',
    restoreTime: '<optional>',
    useLatestRestorableTime: '<optional>',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:createRelationalDatabaseSnapshot': {
    relationalDatabaseName: 'required',
    relationalDatabaseSnapshotName: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:deleteAutoSnapshot': {
    resourceName: 'required',
    date: 'required',
  },
  'Lightsail:deleteDisk': {
    diskName: 'required',
  },
  'Lightsail:deleteDiskSnapshot': {
    diskSnapshotName: 'required',
  },
  'Lightsail:deleteDomain': {
    domainName: 'required',
  },
  'Lightsail:deleteDomainEntry': {
    domainName: 'required',
    domainEntry: {
      id: '<optional>',
      name: '<optional>',
      target: '<optional>',
      isAlias: '<optional>',
      type: '<optional>',
      options: ['<optional1>', '<optional2>'],
    },
  },
  'Lightsail:deleteInstance': {
    instanceName: 'required',
  },
  'Lightsail:deleteInstanceSnapshot': {
    instanceSnapshotName: 'required',
  },
  'Lightsail:deleteKeyPair': {
    keyPairName: 'required',
  },
  'Lightsail:deleteKnownHostKeys': {
    instanceName: 'required',
  },
  'Lightsail:deleteLoadBalancer': {
    loadBalancerName: 'required',
  },
  'Lightsail:deleteLoadBalancerTlsCertificate': {
    loadBalancerName: 'required',
    certificateName: 'required',
    force: '<optional>',
  },
  'Lightsail:deleteRelationalDatabase': {
    relationalDatabaseName: 'required',
    skipFinalSnapshot: '<optional>',
    finalRelationalDatabaseSnapshotName: '<optional>',
  },
  'Lightsail:deleteRelationalDatabaseSnapshot': {
    relationalDatabaseSnapshotName: 'required',
  },
  'Lightsail:detachDisk': {
    diskName: 'required',
  },
  'Lightsail:detachInstancesFromLoadBalancer': {
    loadBalancerName: 'required',
    instanceNames: ['required1', 'required2'],
  },
  'Lightsail:detachStaticIp': {
    staticIpName: 'required',
  },
  'Lightsail:disableAddOn': {
    addOnType: 'required',
    resourceName: 'required',
  },
  'Lightsail:downloadDefaultKeyPair': {},
  'Lightsail:enableAddOn': {
    resourceName: 'required',
    addOnRequest: {
      autoSnapshotAddOnRequest: {
        snapshotTimeOfDay: '<optional>',
      },
    },
  },
  'Lightsail:exportSnapshot': {
    sourceSnapshotName: 'required',
  },
  'Lightsail:getActiveNames': {
    pageToken: '<optional>',
  },
  'Lightsail:getAutoSnapshots': {
    resourceName: 'required',
  },
  'Lightsail:getBlueprints': {
    includeInactive: '<optional>',
    pageToken: '<optional>',
  },
  'Lightsail:getBundles': {
    includeInactive: '<optional>',
    pageToken: '<optional>',
  },
  'Lightsail:getCloudFormationStackRecords': {
    pageToken: '<optional>',
  },
  'Lightsail:getDisk': {
    diskName: 'required',
  },
  'Lightsail:getDiskSnapshot': {
    diskSnapshotName: 'required',
  },
  'Lightsail:getDiskSnapshots': {
    pageToken: '<optional>',
  },
  'Lightsail:getDisks': {
    pageToken: '<optional>',
  },
  'Lightsail:getDomain': {
    domainName: 'required',
  },
  'Lightsail:getDomains': {
    pageToken: '<optional>',
  },
  'Lightsail:getExportSnapshotRecords': {
    pageToken: '<optional>',
  },
  'Lightsail:getInstance': {
    instanceName: 'required',
  },
  'Lightsail:getInstanceAccessDetails': {
    instanceName: 'required',
    protocol: '<optional>',
  },
  'Lightsail:getInstanceMetricData': {
    instanceName: 'required',
    metricName: 'required',
    period: 'required',
    startTime: 'required',
    endTime: 'required',
    unit: 'required',
    statistics: ['required1', 'required2'],
  },
  'Lightsail:getInstancePortStates': {
    instanceName: 'required',
  },
  'Lightsail:getInstanceSnapshot': {
    instanceSnapshotName: 'required',
  },
  'Lightsail:getInstanceSnapshots': {
    pageToken: '<optional>',
  },
  'Lightsail:getInstanceState': {
    instanceName: 'required',
  },
  'Lightsail:getInstances': {
    pageToken: '<optional>',
  },
  'Lightsail:getKeyPair': {
    keyPairName: 'required',
  },
  'Lightsail:getKeyPairs': {
    pageToken: '<optional>',
  },
  'Lightsail:getLoadBalancer': {
    loadBalancerName: 'required',
  },
  'Lightsail:getLoadBalancerMetricData': {
    loadBalancerName: 'required',
    metricName: 'required',
    period: 'required',
    startTime: 'required',
    endTime: 'required',
    unit: 'required',
    statistics: ['required1', 'required2'],
  },
  'Lightsail:getLoadBalancerTlsCertificates': {
    loadBalancerName: 'required',
  },
  'Lightsail:getLoadBalancers': {
    pageToken: '<optional>',
  },
  'Lightsail:getOperation': {
    operationId: 'required',
  },
  'Lightsail:getOperations': {
    pageToken: '<optional>',
  },
  'Lightsail:getOperationsForResource': {
    resourceName: 'required',
    pageToken: '<optional>',
  },
  'Lightsail:getRegions': {
    includeAvailabilityZones: ['<optional1>', '<optional2>'],
    includeRelationalDatabaseAvailabilityZones: ['<optional1>', '<optional2>'],
  },
  'Lightsail:getRelationalDatabase': {
    relationalDatabaseName: 'required',
  },
  'Lightsail:getRelationalDatabaseBlueprints': {
    pageToken: '<optional>',
  },
  'Lightsail:getRelationalDatabaseBundles': {
    pageToken: '<optional>',
  },
  'Lightsail:getRelationalDatabaseEvents': {
    relationalDatabaseName: 'required',
    durationInMinutes: ['<optional1>', '<optional2>'],
    pageToken: '<optional>',
  },
  'Lightsail:getRelationalDatabaseLogEvents': {
    relationalDatabaseName: 'required',
    logStreamName: 'required',
    startTime: '<optional>',
    endTime: '<optional>',
    startFromHead: '<optional>',
    pageToken: '<optional>',
  },
  'Lightsail:getRelationalDatabaseLogStreams': {
    relationalDatabaseName: 'required',
  },
  'Lightsail:getRelationalDatabaseMasterUserPassword': {
    relationalDatabaseName: 'required',
    passwordVersion: '<optional>',
  },
  'Lightsail:getRelationalDatabaseMetricData': {
    relationalDatabaseName: 'required',
    metricName: 'required',
    period: 'required',
    startTime: 'required',
    endTime: 'required',
    unit: 'required',
    statistics: ['required1', 'required2'],
  },
  'Lightsail:getRelationalDatabaseParameters': {
    relationalDatabaseName: 'required',
    pageToken: '<optional>',
  },
  'Lightsail:getRelationalDatabaseSnapshot': {
    relationalDatabaseSnapshotName: 'required',
  },
  'Lightsail:getRelationalDatabaseSnapshots': {
    pageToken: '<optional>',
  },
  'Lightsail:getRelationalDatabases': {
    pageToken: '<optional>',
  },
  'Lightsail:getStaticIp': {
    staticIpName: 'required',
  },
  'Lightsail:getStaticIps': {
    pageToken: '<optional>',
  },
  'Lightsail:importKeyPair': {
    keyPairName: 'required',
    publicKeyBase64: 'required',
  },
  'Lightsail:isVpcPeered': {},
  'Lightsail:openInstancePublicPorts': {
    portInfo: {
      fromPort: '<optional>',
      toPort: '<optional>',
      protocol: '<optional>',
    },
    instanceName: 'required',
  },
  'Lightsail:peerVpc': {},
  'Lightsail:putInstancePublicPorts': {
    portInfos: [
      {
        fromPort: '<optional>',
        toPort: '<optional>',
        protocol: '<optional>',
      },
    ],
    instanceName: 'required',
  },
  'Lightsail:rebootInstance': {
    instanceName: 'required',
  },
  'Lightsail:rebootRelationalDatabase': {
    relationalDatabaseName: 'required',
  },
  'Lightsail:releaseStaticIp': {
    staticIpName: 'required',
  },
  'Lightsail:startInstance': {
    instanceName: 'required',
  },
  'Lightsail:startRelationalDatabase': {
    relationalDatabaseName: 'required',
  },
  'Lightsail:stopInstance': {
    instanceName: 'required',
    force: '<optional>',
  },
  'Lightsail:stopRelationalDatabase': {
    relationalDatabaseName: 'required',
    relationalDatabaseSnapshotName: '<optional>',
  },
  'Lightsail:tagResource': {
    resourceName: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'Lightsail:unpeerVpc': {},
  'Lightsail:untagResource': {
    resourceName: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'Lightsail:updateDomainEntry': {
    domainName: 'required',
    domainEntry: {
      id: '<optional>',
      name: '<optional>',
      target: '<optional>',
      isAlias: '<optional>',
      type: '<optional>',
      options: ['<optional1>', '<optional2>'],
    },
  },
  'Lightsail:updateLoadBalancerAttribute': {
    loadBalancerName: 'required',
    attributeName: 'required',
    attributeValue: 'required',
  },
  'Lightsail:updateRelationalDatabase': {
    relationalDatabaseName: 'required',
    masterUserPassword: '<optional>',
    rotateMasterUserPassword: '<optional>',
    preferredBackupWindow: '<optional>',
    preferredMaintenanceWindow: '<optional>',
    enableBackupRetention: '<optional>',
    disableBackupRetention: '<optional>',
    publiclyAccessible: '<optional>',
    applyImmediately: '<optional>',
  },
  'Lightsail:updateRelationalDatabaseParameters': {
    relationalDatabaseName: 'required',
    parameters: [
      {
        allowedValues: ['<optional1>', '<optional2>'],
        applyMethod: '<optional>',
        applyType: '<optional>',
        dataType: '<optional>',
        description: '<optional>',
        isModifiable: '<optional>',
        parameterName: '<optional>',
        parameterValue: '<optional>',
      },
    ],
  },
  'MQ:createBroker': {
    AutoMinorVersionUpgrade: '<optional>',
    BrokerName: '<optional>',
    Configuration: {
      Id: '<optional>',
      Revision: '<optional>',
    },
    CreatorRequestId: '<optional>',
    DeploymentMode: '<optional>',
    EngineType: '<optional>',
    EngineVersion: '<optional>',
    HostInstanceType: '<optional>',
    Logs: [
      {
        Audit: '<optional>',
        General: '<optional>',
      },
    ],
    MaintenanceWindowStartTime: {
      DayOfWeek: '<optional>',
      TimeOfDay: '<optional>',
      TimeZone: '<optional>',
    },
    PubliclyAccessible: '<optional>',
    SecurityGroups: ['<optional1>', '<optional2>'],
    SubnetIds: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    Users: [
      {
        ConsoleAccess: '<optional>',
        Groups: ['<optional1>', '<optional2>'],
        Password: '<optional>',
        Username: '<optional>',
      },
    ],
  },
  'MQ:createConfiguration': {
    EngineType: '<optional>',
    EngineVersion: '<optional>',
    Name: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MQ:createTags': {
    ResourceArn: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MQ:createUser': {
    Username: 'required',
    BrokerId: 'required',
    ConsoleAccess: '<optional>',
    Groups: ['<optional1>', '<optional2>'],
    Password: '<optional>',
  },
  'MQ:deleteBroker': {
    BrokerId: 'required',
  },
  'MQ:deleteTags': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'MQ:deleteUser': {
    Username: 'required',
    BrokerId: 'required',
  },
  'MQ:describeBroker': {
    BrokerId: 'required',
  },
  'MQ:describeBrokerEngineTypes': {
    EngineType: '<optional>',
  },
  'MQ:describeBrokerInstanceOptions': {
    EngineType: '<optional>',
    HostInstanceType: '<optional>',
  },
  'MQ:describeConfiguration': {
    ConfigurationId: 'required',
  },
  'MQ:describeConfigurationRevision': {
    ConfigurationRevision: 'required',
    ConfigurationId: 'required',
  },
  'MQ:describeUser': {
    Username: 'required',
    BrokerId: 'required',
  },
  'MQ:listBrokers': {},
  'MQ:listConfigurationRevisions': {
    ConfigurationId: 'required',
  },
  'MQ:listConfigurations': {},
  'MQ:listTags': {
    ResourceArn: 'required',
  },
  'MQ:listUsers': {
    BrokerId: 'required',
  },
  'MQ:rebootBroker': {
    BrokerId: 'required',
  },
  'MQ:updateBroker': {
    BrokerId: 'required',
    AutoMinorVersionUpgrade: '<optional>',
    Configuration: {
      Id: '<optional>',
      Revision: '<optional>',
    },
    EngineVersion: '<optional>',
    Logs: [
      {
        Audit: '<optional>',
        General: '<optional>',
      },
    ],
  },
  'MQ:updateConfiguration': {
    ConfigurationId: 'required',
    Data: ['<optional1>', '<optional2>'],
    Description: '<optional>',
  },
  'MQ:updateUser': {
    Username: 'required',
    BrokerId: 'required',
    ConsoleAccess: '<optional>',
    Groups: ['<optional1>', '<optional2>'],
    Password: '<optional>',
  },
  'MTurk:acceptQualificationRequest': {
    QualificationRequestId: 'required',
    IntegerValue: '<optional>',
  },
  'MTurk:approveAssignment': {
    AssignmentId: 'required',
    RequesterFeedback: '<optional>',
    OverrideRejection: '<optional>',
  },
  'MTurk:associateQualificationWithWorker': {
    QualificationTypeId: 'required',
    WorkerId: 'required',
    IntegerValue: '<optional>',
    SendNotification: '<optional>',
  },
  'MTurk:createAdditionalAssignmentsForHIT': {
    HITId: 'required',
    NumberOfAdditionalAssignments: ['required1', 'required2'],
    UniqueRequestToken: '<optional>',
  },
  'MTurk:createHIT': {
    LifetimeInSeconds: ['required1', 'required2'],
    AssignmentDurationInSeconds: ['required1', 'required2'],
    Reward: 'required',
    Title: 'required',
    Description: 'required',
    QualificationRequirements: [
      {
        IntegerValues: ['<optional1>', '<optional2>'],
        LocaleValues: [
          {
            Subdivision: '<optional>',
          },
        ],
        RequiredToPreview: '<optional>',
        ActionsGuarded: '<optional>',
      },
    ],
    AssignmentReviewPolicy: {
      Parameters: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
          MapEntries: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
        },
      ],
    },
    HITReviewPolicy: {
      Parameters: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
          MapEntries: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
        },
      ],
    },
    HITLayoutParameters: ['<optional1>', '<optional2>'],
    MaxAssignments: ['<optional1>', '<optional2>'],
    AutoApprovalDelayInSeconds: ['<optional1>', '<optional2>'],
    Keywords: ['<optional1>', '<optional2>'],
    Question: '<optional>',
    RequesterAnnotation: '<optional>',
    UniqueRequestToken: '<optional>',
    HITLayoutId: '<optional>',
  },
  'MTurk:createHITType': {
    AssignmentDurationInSeconds: ['required1', 'required2'],
    Reward: 'required',
    Title: 'required',
    Description: 'required',
    QualificationRequirements: [
      {
        IntegerValues: ['<optional1>', '<optional2>'],
        LocaleValues: [
          {
            Subdivision: '<optional>',
          },
        ],
        RequiredToPreview: '<optional>',
        ActionsGuarded: '<optional>',
      },
    ],
    AutoApprovalDelayInSeconds: ['<optional1>', '<optional2>'],
    Keywords: ['<optional1>', '<optional2>'],
  },
  'MTurk:createHITWithHITType': {
    HITTypeId: 'required',
    LifetimeInSeconds: ['required1', 'required2'],
    AssignmentReviewPolicy: {
      Parameters: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
          MapEntries: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
        },
      ],
    },
    HITReviewPolicy: {
      Parameters: [
        {
          Key: '<optional>',
          Values: ['<optional1>', '<optional2>'],
          MapEntries: [
            {
              Key: '<optional>',
              Values: ['<optional1>', '<optional2>'],
            },
          ],
        },
      ],
    },
    HITLayoutParameters: ['<optional1>', '<optional2>'],
    MaxAssignments: ['<optional1>', '<optional2>'],
    Question: '<optional>',
    RequesterAnnotation: '<optional>',
    UniqueRequestToken: '<optional>',
    HITLayoutId: '<optional>',
  },
  'MTurk:createQualificationType': {
    Name: 'required',
    Description: 'required',
    QualificationTypeStatus: 'required',
    Keywords: ['<optional1>', '<optional2>'],
    RetryDelayInSeconds: ['<optional1>', '<optional2>'],
    Test: '<optional>',
    AnswerKey: '<optional>',
    TestDurationInSeconds: ['<optional1>', '<optional2>'],
    AutoGranted: '<optional>',
    AutoGrantedValue: '<optional>',
  },
  'MTurk:createWorkerBlock': {
    WorkerId: 'required',
    Reason: 'required',
  },
  'MTurk:deleteHIT': {
    HITId: 'required',
  },
  'MTurk:deleteQualificationType': {
    QualificationTypeId: 'required',
  },
  'MTurk:deleteWorkerBlock': {
    WorkerId: 'required',
    Reason: '<optional>',
  },
  'MTurk:disassociateQualificationFromWorker': {
    WorkerId: 'required',
    QualificationTypeId: 'required',
    Reason: '<optional>',
  },
  'MTurk:getAccountBalance': {},
  'MTurk:getAssignment': {
    AssignmentId: 'required',
  },
  'MTurk:getFileUploadURL': {
    AssignmentId: 'required',
    QuestionIdentifier: 'required',
  },
  'MTurk:getHIT': {
    HITId: 'required',
  },
  'MTurk:getQualificationScore': {
    QualificationTypeId: 'required',
    WorkerId: 'required',
  },
  'MTurk:getQualificationType': {
    QualificationTypeId: 'required',
  },
  'MTurk:listAssignmentsForHIT': {
    HITId: 'required',
    AssignmentStatuses: ['<optional1>', '<optional2>'],
  },
  'MTurk:listBonusPayments': {
    HITId: '<optional>',
    AssignmentId: '<optional>',
  },
  'MTurk:listHITs': {},
  'MTurk:listHITsForQualificationType': {
    QualificationTypeId: 'required',
  },
  'MTurk:listQualificationRequests': {
    QualificationTypeId: '<optional>',
  },
  'MTurk:listQualificationTypes': {
    MustBeRequestable: 'required',
    Query: '<optional>',
    MustBeOwnedByCaller: '<optional>',
  },
  'MTurk:listReviewPolicyResultsForHIT': {
    HITId: 'required',
    PolicyLevels: ['<optional1>', '<optional2>'],
    RetrieveActions: ['<optional1>', '<optional2>'],
    RetrieveResults: ['<optional1>', '<optional2>'],
  },
  'MTurk:listReviewableHITs': {
    HITTypeId: '<optional>',
    Status: '<optional>',
  },
  'MTurk:listWorkerBlocks': {},
  'MTurk:listWorkersWithQualificationType': {
    QualificationTypeId: 'required',
    Status: '<optional>',
  },
  'MTurk:notifyWorkers': {
    Subject: 'required',
    MessageText: 'required',
    WorkerIds: ['required1', 'required2'],
  },
  'MTurk:rejectAssignment': {
    AssignmentId: 'required',
    RequesterFeedback: 'required',
  },
  'MTurk:rejectQualificationRequest': {
    QualificationRequestId: 'required',
    Reason: '<optional>',
  },
  'MTurk:sendBonus': {
    WorkerId: 'required',
    BonusAmount: 'required',
    AssignmentId: 'required',
    Reason: 'required',
    UniqueRequestToken: '<optional>',
  },
  'MTurk:sendTestEventNotification': {
    Notification: {
      Destination: 'required',
      Transport: 'required',
      Version: 'required',
      EventTypes: ['required1', 'required2'],
    },
    TestEventType: 'required',
  },
  'MTurk:updateExpirationForHIT': {
    HITId: 'required',
    ExpireAt: 'required',
  },
  'MTurk:updateHITReviewStatus': {
    HITId: 'required',
    Revert: '<optional>',
  },
  'MTurk:updateHITTypeOfHIT': {
    HITId: 'required',
    HITTypeId: 'required',
  },
  'MTurk:updateNotificationSettings': {
    HITTypeId: 'required',
    Notification: '<optional>',
    Active: '<optional>',
  },
  'MTurk:updateQualificationType': {
    QualificationTypeId: 'required',
    Description: '<optional>',
    QualificationTypeStatus: '<optional>',
    Test: '<optional>',
    AnswerKey: '<optional>',
    TestDurationInSeconds: ['<optional1>', '<optional2>'],
    RetryDelayInSeconds: ['<optional1>', '<optional2>'],
    AutoGranted: '<optional>',
    AutoGrantedValue: '<optional>',
  },
  'MachineLearning:addTags': {
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    ResourceId: 'required',
    ResourceType: 'required',
  },
  'MachineLearning:createBatchPrediction': {
    BatchPredictionId: 'required',
    MLModelId: 'required',
    BatchPredictionDataSourceId: 'required',
    OutputUri: 'required',
    BatchPredictionName: '<optional>',
  },
  'MachineLearning:createDataSourceFromRDS': {
    DataSourceId: 'required',
    RDSData: [
      {
        DataRearrangement: '<optional>',
        DataSchema: '<optional>',
        DataSchemaUri: '<optional>',
      },
    ],
    RoleARN: 'required',
    DataSourceName: '<optional>',
    ComputeStatistics: ['<optional1>', '<optional2>'],
  },
  'MachineLearning:createDataSourceFromRedshift': {
    DataSourceId: 'required',
    DataSpec: {
      DataRearrangement: '<optional>',
      DataSchema: '<optional>',
      DataSchemaUri: '<optional>',
    },
    RoleARN: 'required',
    DataSourceName: '<optional>',
    ComputeStatistics: ['<optional1>', '<optional2>'],
  },
  'MachineLearning:createDataSourceFromS3': {
    DataSourceId: 'required',
    DataSpec: {
      DataRearrangement: '<optional>',
      DataSchema: '<optional>',
      DataSchemaLocationS3: '<optional>',
    },
    DataSourceName: '<optional>',
    ComputeStatistics: ['<optional1>', '<optional2>'],
  },
  'MachineLearning:createEvaluation': {
    EvaluationId: 'required',
    MLModelId: 'required',
    EvaluationDataSourceId: 'required',
    EvaluationName: '<optional>',
  },
  'MachineLearning:createMLModel': {
    MLModelId: 'required',
    MLModelType: 'required',
    TrainingDataSourceId: 'required',
    MLModelName: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
    Recipe: '<optional>',
    RecipeUri: '<optional>',
  },
  'MachineLearning:createRealtimeEndpoint': {
    MLModelId: 'required',
  },
  'MachineLearning:deleteBatchPrediction': {
    BatchPredictionId: 'required',
  },
  'MachineLearning:deleteDataSource': {
    DataSourceId: 'required',
  },
  'MachineLearning:deleteEvaluation': {
    EvaluationId: 'required',
  },
  'MachineLearning:deleteMLModel': {
    MLModelId: 'required',
  },
  'MachineLearning:deleteRealtimeEndpoint': {
    MLModelId: 'required',
  },
  'MachineLearning:deleteTags': {
    TagKeys: ['required1', 'required2'],
    ResourceId: 'required',
    ResourceType: 'required',
  },
  'MachineLearning:describeBatchPredictions': {
    FilterVariable: '<optional>',
    EQ: '<optional>',
    GT: '<optional>',
    LT: '<optional>',
    GE: '<optional>',
    LE: '<optional>',
    NE: '<optional>',
    Prefix: '<optional>',
    SortOrder: '<optional>',
    Limit: '<optional>',
  },
  'MachineLearning:describeDataSources': {
    FilterVariable: '<optional>',
    EQ: '<optional>',
    GT: '<optional>',
    LT: '<optional>',
    GE: '<optional>',
    LE: '<optional>',
    NE: '<optional>',
    Prefix: '<optional>',
    SortOrder: '<optional>',
    Limit: '<optional>',
  },
  'MachineLearning:describeEvaluations': {
    FilterVariable: '<optional>',
    EQ: '<optional>',
    GT: '<optional>',
    LT: '<optional>',
    GE: '<optional>',
    LE: '<optional>',
    NE: '<optional>',
    Prefix: '<optional>',
    SortOrder: '<optional>',
    Limit: '<optional>',
  },
  'MachineLearning:describeMLModels': {
    FilterVariable: '<optional>',
    EQ: '<optional>',
    GT: '<optional>',
    LT: '<optional>',
    GE: '<optional>',
    LE: '<optional>',
    NE: '<optional>',
    Prefix: '<optional>',
    SortOrder: '<optional>',
    Limit: '<optional>',
  },
  'MachineLearning:describeTags': {
    ResourceId: 'required',
    ResourceType: 'required',
  },
  'MachineLearning:getBatchPrediction': {
    BatchPredictionId: 'required',
  },
  'MachineLearning:getDataSource': {
    DataSourceId: 'required',
    Verbose: '<optional>',
  },
  'MachineLearning:getEvaluation': {
    EvaluationId: 'required',
  },
  'MachineLearning:getMLModel': {
    MLModelId: 'required',
    Verbose: '<optional>',
  },
  'MachineLearning:predict': {
    MLModelId: 'required',
    Record: 'required',
    PredictEndpoint: 'required',
  },
  'MachineLearning:updateBatchPrediction': {
    BatchPredictionId: 'required',
    BatchPredictionName: 'required',
  },
  'MachineLearning:updateDataSource': {
    DataSourceId: 'required',
    DataSourceName: 'required',
  },
  'MachineLearning:updateEvaluation': {
    EvaluationId: 'required',
    EvaluationName: 'required',
  },
  'MachineLearning:updateMLModel': {
    MLModelId: 'required',
    MLModelName: '<optional>',
    ScoreThreshold: '<optional>',
  },
  'Macie:associateMemberAccount': {
    memberAccountId: 'required',
  },
  'Macie:associateS3Resources': {
    s3Resources: [
      {
        prefix: '<optional>',
      },
    ],
    memberAccountId: '<optional>',
  },
  'Macie:disassociateMemberAccount': {
    memberAccountId: 'required',
  },
  'Macie:disassociateS3Resources': {
    associatedS3Resources: [
      {
        prefix: '<optional>',
      },
    ],
    memberAccountId: '<optional>',
  },
  'Macie:listMemberAccounts': {},
  'Macie:listS3Resources': {
    memberAccountId: '<optional>',
  },
  'Macie:updateS3Resources': {
    s3ResourcesUpdate: {
      prefix: '<optional>',
      classificationTypeUpdate: {
        oneTime: '<optional>',
        continuous: ['<optional1>', '<optional2>'],
      },
    },
    memberAccountId: '<optional>',
  },
  'ManagedBlockchain:createMember': {
    ClientRequestToken: 'required',
    InvitationId: 'required',
    NetworkId: 'required',
    MemberConfiguration: {
      Description: '<optional>',
      FrameworkConfiguration: {
        Fabric: '<optional>',
      },
    },
  },
  'ManagedBlockchain:createNetwork': {
    ClientRequestToken: 'required',
    Name: 'required',
    Framework: 'required',
    FrameworkVersion: 'required',
    VotingPolicy: {
      ApprovalThresholdPolicy: {
        ThresholdPercentage: '<optional>',
        ProposalDurationInHours: ['<optional1>', '<optional2>'],
        ThresholdComparator: '<optional>',
      },
    },
    MemberConfiguration: {
      Description: '<optional>',
      FrameworkConfiguration: {
        Fabric: '<optional>',
      },
    },
    FrameworkConfiguration: {
      Fabric: '<optional>',
    },
    Description: '<optional>',
  },
  'ManagedBlockchain:createNode': {
    ClientRequestToken: 'required',
    NetworkId: 'required',
    MemberId: 'required',
    NodeConfiguration: {
      InstanceType: 'required',
      AvailabilityZone: 'required',
    },
  },
  'ManagedBlockchain:createProposal': {
    ClientRequestToken: 'required',
    NetworkId: 'required',
    MemberId: 'required',
    Actions: [
      {
        Invitations: ['<optional1>', '<optional2>'],
        Removals: ['<optional1>', '<optional2>'],
      },
    ],
    Description: '<optional>',
  },
  'ManagedBlockchain:deleteMember': {
    NetworkId: 'required',
    MemberId: 'required',
  },
  'ManagedBlockchain:deleteNode': {
    NetworkId: 'required',
    MemberId: 'required',
    NodeId: 'required',
  },
  'ManagedBlockchain:getMember': {
    NetworkId: 'required',
    MemberId: 'required',
  },
  'ManagedBlockchain:getNetwork': {
    NetworkId: 'required',
  },
  'ManagedBlockchain:getNode': {
    NetworkId: 'required',
    MemberId: 'required',
    NodeId: 'required',
  },
  'ManagedBlockchain:getProposal': {
    NetworkId: 'required',
    ProposalId: 'required',
  },
  'ManagedBlockchain:listInvitations': {},
  'ManagedBlockchain:listMembers': {
    NetworkId: 'required',
    Name: '<optional>',
    Status: '<optional>',
    IsOwned: '<optional>',
  },
  'ManagedBlockchain:listNetworks': {
    Name: '<optional>',
    Framework: '<optional>',
    Status: '<optional>',
  },
  'ManagedBlockchain:listNodes': {
    NetworkId: 'required',
    MemberId: 'required',
    Status: '<optional>',
  },
  'ManagedBlockchain:listProposalVotes': {
    NetworkId: 'required',
    ProposalId: 'required',
  },
  'ManagedBlockchain:listProposals': {
    NetworkId: 'required',
  },
  'ManagedBlockchain:rejectInvitation': {
    InvitationId: 'required',
  },
  'ManagedBlockchain:voteOnProposal': {
    NetworkId: 'required',
    ProposalId: 'required',
    VoterMemberId: 'required',
    Vote: 'required',
  },
  'MarketplaceCatalog:cancelChangeSet': {
    Catalog: 'required',
    ChangeSetId: 'required',
  },
  'MarketplaceCatalog:describeChangeSet': {
    Catalog: 'required',
    ChangeSetId: 'required',
  },
  'MarketplaceCatalog:describeEntity': {
    Catalog: 'required',
    EntityId: 'required',
  },
  'MarketplaceCatalog:listChangeSets': {
    Catalog: 'required',
    FilterList: {
      Name: '<optional>',
      ValueList: '<optional>',
    },
    Sort: {
      SortBy: '<optional>',
      SortOrder: '<optional>',
    },
  },
  'MarketplaceCatalog:listEntities': {
    Catalog: 'required',
    EntityType: 'required',
    FilterList: {
      Name: '<optional>',
      ValueList: '<optional>',
    },
    Sort: {
      SortBy: '<optional>',
      SortOrder: '<optional>',
    },
  },
  'MarketplaceCatalog:startChangeSet': {
    Catalog: 'required',
    ChangeSet: {
      Entity: {
        Identifier: '<optional>',
      },
    },
    ChangeSetName: '<optional>',
    ClientRequestToken: '<optional>',
  },
  'MarketplaceCommerceAnalytics:generateDataSet': {
    dataSetType: 'required',
    dataSetPublicationDate: 'required',
    roleNameArn: 'required',
    destinationS3BucketName: 'required',
    snsTopicArn: 'required',
    destinationS3Prefix: '<optional>',
    customerDefinedValues: ['<optional1>', '<optional2>'],
  },
  'MarketplaceCommerceAnalytics:startSupportDataExport': {
    dataSetType: 'required',
    fromDate: 'required',
    roleNameArn: 'required',
    destinationS3BucketName: 'required',
    snsTopicArn: 'required',
    destinationS3Prefix: '<optional>',
    customerDefinedValues: ['<optional1>', '<optional2>'],
  },
  'MarketplaceEntitlementService:getEntitlements': {
    ProductCode: 'required',
    Filter: '<optional>',
  },
  'MarketplaceMetering:batchMeterUsage': {
    UsageRecords: [
      {
        Quantity: '<optional>',
      },
    ],
    ProductCode: 'required',
  },
  'MarketplaceMetering:meterUsage': {
    ProductCode: 'required',
    Timestamp: 'required',
    UsageDimension: 'required',
    UsageQuantity: '<optional>',
  },
  'MarketplaceMetering:registerUsage': {
    ProductCode: 'required',
    PublicKeyVersion: 'required',
    Nonce: '<optional>',
  },
  'MarketplaceMetering:resolveCustomer': {
    RegistrationToken: 'required',
  },
  'MediaConnect:addFlowOutputs': {
    FlowArn: 'required',
    Outputs: [
      {
        Description: '<optional>',
        Encryption: {
          ConstantInitializationVector: '<optional>',
          DeviceId: '<optional>',
          KeyType: '<optional>',
          Region: '<optional>',
          ResourceId: '<optional>',
          SecretArn: '<optional>',
          Url: '<optional>',
        },
        MaxLatency: '<optional>',
        Name: '<optional>',
        SmoothingLatency: '<optional>',
        StreamId: '<optional>',
      },
    ],
  },
  'MediaConnect:createFlow': {
    Source: {
      Decryption: {
        ConstantInitializationVector: '<optional>',
        DeviceId: '<optional>',
        KeyType: '<optional>',
        Region: '<optional>',
        ResourceId: '<optional>',
        SecretArn: '<optional>',
        Url: '<optional>',
      },
      Description: '<optional>',
      EntitlementArn: '<optional>',
      IngestPort: '<optional>',
      MaxBitrate: '<optional>',
      MaxLatency: '<optional>',
      Name: '<optional>',
      Protocol: '<optional>',
      StreamId: '<optional>',
      WhitelistCidr: '<optional>',
    },
    Name: 'required',
    Entitlements: [
      {
        Description: '<optional>',
        Encryption: {
          ConstantInitializationVector: '<optional>',
          DeviceId: '<optional>',
          KeyType: '<optional>',
          Region: '<optional>',
          ResourceId: '<optional>',
          SecretArn: '<optional>',
          Url: '<optional>',
        },
        Name: '<optional>',
      },
    ],
    Outputs: [
      {
        Description: '<optional>',
        Encryption: {
          ConstantInitializationVector: '<optional>',
          DeviceId: '<optional>',
          KeyType: '<optional>',
          Region: '<optional>',
          ResourceId: '<optional>',
          SecretArn: '<optional>',
          Url: '<optional>',
        },
        MaxLatency: '<optional>',
        Name: '<optional>',
        SmoothingLatency: '<optional>',
        StreamId: '<optional>',
      },
    ],
    AvailabilityZone: '<optional>',
  },
  'MediaConnect:deleteFlow': {
    FlowArn: 'required',
  },
  'MediaConnect:describeFlow': {
    FlowArn: 'required',
  },
  'MediaConnect:grantFlowEntitlements': {
    FlowArn: 'required',
    Entitlements: [
      {
        Description: '<optional>',
        Encryption: {
          ConstantInitializationVector: '<optional>',
          DeviceId: '<optional>',
          KeyType: '<optional>',
          Region: '<optional>',
          ResourceId: '<optional>',
          SecretArn: '<optional>',
          Url: '<optional>',
        },
        Name: '<optional>',
      },
    ],
  },
  'MediaConnect:listEntitlements': {},
  'MediaConnect:listFlows': {},
  'MediaConnect:listTagsForResource': {
    ResourceArn: 'required',
  },
  'MediaConnect:removeFlowOutput': {
    FlowArn: 'required',
    OutputArn: 'required',
  },
  'MediaConnect:revokeFlowEntitlement': {
    FlowArn: 'required',
    EntitlementArn: 'required',
  },
  'MediaConnect:startFlow': {
    FlowArn: 'required',
  },
  'MediaConnect:stopFlow': {
    FlowArn: 'required',
  },
  'MediaConnect:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'MediaConnect:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'MediaConnect:updateFlowEntitlement': {
    FlowArn: 'required',
    EntitlementArn: 'required',
    Description: '<optional>',
    Encryption: {
      Algorithm: '<optional>',
      ConstantInitializationVector: '<optional>',
      DeviceId: '<optional>',
      KeyType: '<optional>',
      Region: '<optional>',
      ResourceId: '<optional>',
      RoleArn: '<optional>',
      SecretArn: '<optional>',
      Url: '<optional>',
    },
    Subscribers: ['<optional1>', '<optional2>'],
  },
  'MediaConnect:updateFlowOutput': {
    FlowArn: 'required',
    OutputArn: 'required',
    Description: '<optional>',
    Destination: '<optional>',
    Encryption: {
      Algorithm: '<optional>',
      ConstantInitializationVector: '<optional>',
      DeviceId: '<optional>',
      KeyType: '<optional>',
      Region: '<optional>',
      ResourceId: '<optional>',
      RoleArn: '<optional>',
      SecretArn: '<optional>',
      Url: '<optional>',
    },
    MaxLatency: '<optional>',
    Port: '<optional>',
    Protocol: '<optional>',
    SmoothingLatency: '<optional>',
    StreamId: '<optional>',
  },
  'MediaConnect:updateFlowSource': {
    FlowArn: 'required',
    SourceArn: 'required',
    Decryption: {
      Algorithm: '<optional>',
      ConstantInitializationVector: '<optional>',
      DeviceId: '<optional>',
      KeyType: '<optional>',
      Region: '<optional>',
      ResourceId: '<optional>',
      RoleArn: '<optional>',
      SecretArn: '<optional>',
      Url: '<optional>',
    },
    Description: '<optional>',
    EntitlementArn: '<optional>',
    IngestPort: '<optional>',
    MaxBitrate: '<optional>',
    MaxLatency: '<optional>',
    Protocol: '<optional>',
    StreamId: '<optional>',
    WhitelistCidr: '<optional>',
  },
  'MediaConvert:associateCertificate': {
    Arn: 'required',
  },
  'MediaConvert:cancelJob': {
    Id: 'required',
  },
  'MediaConvert:createJob': {
    Role: 'required',
    Settings: [
      {
        AdAvailOffset: '<optional>',
        AvailBlanking: {
          AvailBlankingImage: '<optional>',
        },
        Esam: {
          ManifestConfirmConditionNotification: {
            MccXml: '<optional>',
          },
          ResponseSignalPreroll: '<optional>',
          SignalProcessingNotification: {
            SccXml: '<optional>',
          },
        },
        Inputs: [
          {
            AudioSelectorGroups: ['<optional1>', '<optional2>'],
            AudioSelectors: ['<optional1>', '<optional2>'],
            CaptionSelectors: ['<optional1>', '<optional2>'],
            DeblockFilter: '<optional>',
            DecryptionSettings: [
              {
                DecryptionMode: '<optional>',
                EncryptedDecryptionKey: '<optional>',
                InitializationVector: '<optional>',
                KmsKeyRegion: '<optional>',
              },
            ],
            DenoiseFilter: '<optional>',
            FileInput: '<optional>',
            FilterEnable: '<optional>',
            FilterStrength: '<optional>',
            ImageInserter: {
              InsertableImages: [
                {
                  Duration: '<optional>',
                  FadeIn: '<optional>',
                  FadeOut: '<optional>',
                  Height: '<optional>',
                  ImageInserterInput: '<optional>',
                  ImageX: '<optional>',
                  ImageY: '<optional>',
                  Layer: '<optional>',
                  Opacity: '<optional>',
                  StartTime: '<optional>',
                  Width: '<optional>',
                },
              ],
            },
            InputClippings: [
              {
                EndTimecode: '<optional>',
                StartTimecode: '<optional>',
              },
            ],
            ProgramNumber: '<optional>',
            PsiControl: '<optional>',
            SupplementalImps: ['<optional1>', '<optional2>'],
            TimecodeSource: '<optional>',
            VideoSelector: {
              ColorSpace: '<optional>',
              ColorSpaceUsage: '<optional>',
              Hdr10Metadata: [
                {
                  BluePrimaryX: '<optional>',
                  BluePrimaryY: '<optional>',
                  GreenPrimaryX: '<optional>',
                  GreenPrimaryY: '<optional>',
                  MaxContentLightLevel: '<optional>',
                  MaxFrameAverageLightLevel: '<optional>',
                  MaxLuminance: '<optional>',
                  MinLuminance: '<optional>',
                  RedPrimaryX: '<optional>',
                  RedPrimaryY: '<optional>',
                  WhitePointX: '<optional>',
                  WhitePointY: '<optional>',
                },
              ],
              Pid: '<optional>',
              ProgramNumber: '<optional>',
              Rotate: '<optional>',
            },
          },
        ],
        MotionImageInserter: {
          Framerate: {
            FramerateDenominator: '<optional>',
            FramerateNumerator: '<optional>',
          },
          Input: '<optional>',
          InsertionMode: '<optional>',
          Offset: {
            ImageX: '<optional>',
            ImageY: '<optional>',
          },
          Playback: '<optional>',
          StartTime: '<optional>',
        },
        NielsenConfiguration: {
          BreakoutCode: '<optional>',
          DistributorId: '<optional>',
        },
        OutputGroups: [
          {
            CustomName: '<optional>',
            Name: '<optional>',
            OutputGroupSettings: [
              {
                CmafGroupSettings: [
                  {
                    BaseUrl: '<optional>',
                    ClientCache: '<optional>',
                    CodecSpecification: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      ConstantInitializationVector: '<optional>',
                      EncryptionMethod: '<optional>',
                      InitializationVectorInManifest: '<optional>',
                      StaticKeyProvider: {
                        KeyFormat: '<optional>',
                        KeyFormatVersions: ['<optional1>', '<optional2>'],
                        StaticKeyValue: '<optional>',
                        Url: '<optional>',
                      },
                      Type: '<optional>',
                    },
                    FragmentLength: '<optional>',
                    ManifestCompression: '<optional>',
                    ManifestDurationFormat: '<optional>',
                    MinBufferTime: '<optional>',
                    MinFinalSegmentLength: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    StreamInfResolution: '<optional>',
                    WriteDashManifest: '<optional>',
                    WriteHlsManifest: '<optional>',
                  },
                ],
                DashIsoGroupSettings: [
                  {
                    BaseUrl: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      PlaybackDeviceCompatibility: '<optional>',
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                    },
                    FragmentLength: '<optional>',
                    HbbtvCompliance: '<optional>',
                    MinBufferTime: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    WriteSegmentTimelineInRepresentation: '<optional>',
                  },
                ],
                FileGroupSettings: [
                  {
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
                HlsGroupSettings: [
                  {
                    AdMarkers: ['<optional1>', '<optional2>'],
                    BaseUrl: '<optional>',
                    CaptionLanguageMappings: [
                      {
                        CaptionChannel: '<optional>',
                        CustomLanguageCode: '<optional>',
                        LanguageCode: '<optional>',
                        LanguageDescription: '<optional>',
                      },
                    ],
                    CaptionLanguageSetting: '<optional>',
                    ClientCache: '<optional>',
                    CodecSpecification: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    DirectoryStructure: '<optional>',
                    Encryption: {
                      ConstantInitializationVector: '<optional>',
                      EncryptionMethod: '<optional>',
                      InitializationVectorInManifest: '<optional>',
                      OfflineEncrypted: '<optional>',
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                      StaticKeyProvider: {
                        KeyFormat: '<optional>',
                        KeyFormatVersions: ['<optional1>', '<optional2>'],
                        StaticKeyValue: '<optional>',
                        Url: '<optional>',
                      },
                      Type: '<optional>',
                    },
                    ManifestCompression: '<optional>',
                    ManifestDurationFormat: '<optional>',
                    MinFinalSegmentLength: '<optional>',
                    MinSegmentLength: '<optional>',
                    OutputSelection: '<optional>',
                    ProgramDateTime: '<optional>',
                    ProgramDateTimePeriod: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    SegmentsPerSubdirectory: '<optional>',
                    StreamInfResolution: '<optional>',
                    TimedMetadataId3Frame: '<optional>',
                    TimedMetadataId3Period: '<optional>',
                    TimestampDeltaMilliseconds: ['<optional1>', '<optional2>'],
                  },
                ],
                MsSmoothGroupSettings: [
                  {
                    AudioDeduplication: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                    },
                    FragmentLength: '<optional>',
                    ManifestEncoding: '<optional>',
                  },
                ],
                Type: '<optional>',
              },
            ],
            Outputs: [
              {
                AudioDescriptions: [
                  {
                    AudioNormalizationSettings: [
                      {
                        Algorithm: '<optional>',
                        AlgorithmControl: '<optional>',
                        CorrectionGateLevel: '<optional>',
                        LoudnessLogging: '<optional>',
                        PeakCalculation: '<optional>',
                        TargetLkfs: ['<optional1>', '<optional2>'],
                      },
                    ],
                    AudioSourceName: '<optional>',
                    AudioType: '<optional>',
                    AudioTypeControl: '<optional>',
                    CodecSettings: [
                      {
                        AacSettings: [
                          {
                            AudioDescriptionBroadcasterMix: '<optional>',
                            Bitrate: '<optional>',
                            CodecProfile: '<optional>',
                            CodingMode: '<optional>',
                            RateControlMode: '<optional>',
                            RawFormat: '<optional>',
                            SampleRate: '<optional>',
                            Specification: '<optional>',
                            VbrQuality: '<optional>',
                          },
                        ],
                        Ac3Settings: [
                          {
                            Bitrate: '<optional>',
                            BitstreamMode: '<optional>',
                            CodingMode: '<optional>',
                            Dialnorm: '<optional>',
                            DynamicRangeCompressionProfile: '<optional>',
                            LfeFilter: '<optional>',
                            MetadataControl: '<optional>',
                            SampleRate: '<optional>',
                          },
                        ],
                        AiffSettings: [
                          {
                            BitDepth: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            SampleRate: '<optional>',
                          },
                        ],
                        Codec: '<optional>',
                        Eac3Settings: [
                          {
                            AttenuationControl: '<optional>',
                            Bitrate: '<optional>',
                            BitstreamMode: '<optional>',
                            CodingMode: '<optional>',
                            DcFilter: '<optional>',
                            Dialnorm: '<optional>',
                            DynamicRangeCompressionLine: '<optional>',
                            DynamicRangeCompressionRf: '<optional>',
                            LfeControl: '<optional>',
                            LfeFilter: '<optional>',
                            LoRoCenterMixLevel: '<optional>',
                            LoRoSurroundMixLevel: '<optional>',
                            LtRtCenterMixLevel: '<optional>',
                            LtRtSurroundMixLevel: '<optional>',
                            MetadataControl: '<optional>',
                            PassthroughControl: '<optional>',
                            PhaseControl: '<optional>',
                            SampleRate: '<optional>',
                            StereoDownmix: '<optional>',
                            SurroundExMode: '<optional>',
                            SurroundMode: '<optional>',
                          },
                        ],
                        Mp2Settings: [
                          {
                            Bitrate: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            SampleRate: '<optional>',
                          },
                        ],
                        WavSettings: [
                          {
                            BitDepth: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            Format: '<optional>',
                            SampleRate: '<optional>',
                          },
                        ],
                      },
                    ],
                    CustomLanguageCode: '<optional>',
                    LanguageCode: '<optional>',
                    LanguageCodeControl: '<optional>',
                    RemixSettings: [
                      {
                        ChannelMapping: {
                          OutputChannels: [
                            {
                              InputChannels: ['<optional1>', '<optional2>'],
                            },
                          ],
                        },
                        ChannelsIn: '<optional>',
                        ChannelsOut: '<optional>',
                      },
                    ],
                    StreamName: '<optional>',
                  },
                ],
                CaptionDescriptions: [
                  {
                    CaptionSelectorName: '<optional>',
                    CustomLanguageCode: '<optional>',
                    DestinationSettings: [
                      {
                        BurninDestinationSettings: [
                          {
                            Alignment: '<optional>',
                            BackgroundColor: '<optional>',
                            BackgroundOpacity: '<optional>',
                            FontColor: '<optional>',
                            FontOpacity: '<optional>',
                            FontResolution: '<optional>',
                            FontScript: '<optional>',
                            FontSize: '<optional>',
                            OutlineColor: '<optional>',
                            OutlineSize: '<optional>',
                            ShadowColor: '<optional>',
                            ShadowOpacity: '<optional>',
                            ShadowXOffset: '<optional>',
                            ShadowYOffset: '<optional>',
                            TeletextSpacing: '<optional>',
                            XPosition: '<optional>',
                            YPosition: '<optional>',
                          },
                        ],
                        DestinationType: '<optional>',
                        DvbSubDestinationSettings: [
                          {
                            Alignment: '<optional>',
                            BackgroundColor: '<optional>',
                            BackgroundOpacity: '<optional>',
                            FontColor: '<optional>',
                            FontOpacity: '<optional>',
                            FontResolution: '<optional>',
                            FontScript: '<optional>',
                            FontSize: '<optional>',
                            OutlineColor: '<optional>',
                            OutlineSize: '<optional>',
                            ShadowColor: '<optional>',
                            ShadowOpacity: '<optional>',
                            ShadowXOffset: '<optional>',
                            ShadowYOffset: '<optional>',
                            TeletextSpacing: '<optional>',
                            XPosition: '<optional>',
                            YPosition: '<optional>',
                          },
                        ],
                        EmbeddedDestinationSettings: [
                          {
                            Destination608ChannelNumber: '<optional>',
                          },
                        ],
                        SccDestinationSettings: [
                          {
                            Framerate: '<optional>',
                          },
                        ],
                        TeletextDestinationSettings: [
                          {
                            PageNumber: '<optional>',
                          },
                        ],
                        TtmlDestinationSettings: [
                          {
                            StylePassthrough: '<optional>',
                          },
                        ],
                      },
                    ],
                    LanguageCode: '<optional>',
                    LanguageDescription: '<optional>',
                  },
                ],
                ContainerSettings: [
                  {
                    Container: '<optional>',
                    F4vSettings: [
                      {
                        MoovPlacement: '<optional>',
                      },
                    ],
                    M2tsSettings: [
                      {
                        AudioBufferModel: '<optional>',
                        AudioFramesPerPes: ['<optional1>', '<optional2>'],
                        AudioPids: ['<optional1>', '<optional2>'],
                        Bitrate: '<optional>',
                        BufferModel: '<optional>',
                        DvbNitSettings: [
                          {
                            NetworkId: '<optional>',
                            NetworkName: '<optional>',
                            NitInterval: '<optional>',
                          },
                        ],
                        DvbSdtSettings: [
                          {
                            OutputSdt: '<optional>',
                            SdtInterval: '<optional>',
                            ServiceName: '<optional>',
                            ServiceProviderName: '<optional>',
                          },
                        ],
                        DvbSubPids: ['<optional1>', '<optional2>'],
                        DvbTdtSettings: [
                          {
                            TdtInterval: '<optional>',
                          },
                        ],
                        DvbTeletextPid: '<optional>',
                        EbpAudioInterval: '<optional>',
                        EbpPlacement: '<optional>',
                        EsRateInPes: ['<optional1>', '<optional2>'],
                        ForceTsVideoEbpOrder: '<optional>',
                        FragmentTime: '<optional>',
                        MaxPcrInterval: '<optional>',
                        MinEbpInterval: '<optional>',
                        NielsenId3: '<optional>',
                        NullPacketBitrate: '<optional>',
                        PatInterval: '<optional>',
                        PcrControl: '<optional>',
                        PcrPid: '<optional>',
                        PmtInterval: '<optional>',
                        PmtPid: '<optional>',
                        PrivateMetadataPid: '<optional>',
                        ProgramNumber: '<optional>',
                        RateMode: '<optional>',
                        Scte35Esam: {
                          Scte35EsamPid: '<optional>',
                        },
                        Scte35Pid: '<optional>',
                        Scte35Source: '<optional>',
                        SegmentationMarkers: ['<optional1>', '<optional2>'],
                        SegmentationStyle: '<optional>',
                        SegmentationTime: '<optional>',
                        TimedMetadataPid: '<optional>',
                        TransportStreamId: '<optional>',
                        VideoPid: '<optional>',
                      },
                    ],
                    M3u8Settings: [
                      {
                        AudioFramesPerPes: ['<optional1>', '<optional2>'],
                        AudioPids: ['<optional1>', '<optional2>'],
                        NielsenId3: '<optional>',
                        PatInterval: '<optional>',
                        PcrControl: '<optional>',
                        PcrPid: '<optional>',
                        PmtInterval: '<optional>',
                        PmtPid: '<optional>',
                        PrivateMetadataPid: '<optional>',
                        ProgramNumber: '<optional>',
                        Scte35Pid: '<optional>',
                        Scte35Source: '<optional>',
                        TimedMetadata: ['<optional1>', '<optional2>'],
                        TimedMetadataPid: '<optional>',
                        TransportStreamId: '<optional>',
                        VideoPid: '<optional>',
                      },
                    ],
                    MovSettings: [
                      {
                        ClapAtom: '<optional>',
                        CslgAtom: '<optional>',
                        Mpeg2FourCCControl: '<optional>',
                        PaddingControl: '<optional>',
                        Reference: '<optional>',
                      },
                    ],
                    Mp4Settings: [
                      {
                        CslgAtom: '<optional>',
                        FreeSpaceBox: '<optional>',
                        MoovPlacement: '<optional>',
                        Mp4MajorBrand: '<optional>',
                      },
                    ],
                  },
                ],
                Extension: '<optional>',
                NameModifier: '<optional>',
                OutputSettings: [
                  {
                    HlsSettings: [
                      {
                        AudioGroupId: '<optional>',
                        AudioRenditionSets: ['<optional1>', '<optional2>'],
                        AudioTrackType: '<optional>',
                        IFrameOnlyManifest: '<optional>',
                        SegmentModifier: '<optional>',
                      },
                    ],
                  },
                ],
                Preset: '<optional>',
                VideoDescription: {
                  AfdSignaling: '<optional>',
                  AntiAlias: '<optional>',
                  CodecSettings: [
                    {
                      Codec: '<optional>',
                      FrameCaptureSettings: [
                        {
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          MaxCaptures: ['<optional1>', '<optional2>'],
                          Quality: '<optional>',
                        },
                      ],
                      H264Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          EntropyEncoding: '<optional>',
                          FieldEncoding: '<optional>',
                          FlickerAdaptiveQuantization: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopBReference: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          NumberReferenceFrames: ['<optional1>', '<optional2>'],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          QvbrSettings: [
                            {
                              MaxAverageBitrate: '<optional>',
                              QvbrQualityLevel: '<optional>',
                            },
                          ],
                          RateControlMode: '<optional>',
                          RepeatPps: ['<optional1>', '<optional2>'],
                          SceneChangeDetect: '<optional>',
                          Slices: ['<optional1>', '<optional2>'],
                          SlowPal: '<optional>',
                          Softness: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Syntax: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                          UnregisteredSeiTimecode: '<optional>',
                        },
                      ],
                      H265Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          AlternateTransferFunctionSei: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          FlickerAdaptiveQuantization: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopBReference: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          NumberReferenceFrames: ['<optional1>', '<optional2>'],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          QvbrSettings: [
                            {
                              MaxAverageBitrate: '<optional>',
                              QvbrQualityLevel: '<optional>',
                            },
                          ],
                          RateControlMode: '<optional>',
                          SampleAdaptiveOffsetFilterMode: '<optional>',
                          SceneChangeDetect: '<optional>',
                          Slices: ['<optional1>', '<optional2>'],
                          SlowPal: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                          TemporalIds: ['<optional1>', '<optional2>'],
                          Tiles: ['<optional1>', '<optional2>'],
                          UnregisteredSeiTimecode: '<optional>',
                          WriteMp4PackagingType: '<optional>',
                        },
                      ],
                      Mpeg2Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          IntraDcPrecision: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          RateControlMode: '<optional>',
                          SceneChangeDetect: '<optional>',
                          SlowPal: '<optional>',
                          Softness: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Syntax: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                        },
                      ],
                      ProresSettings: [
                        {
                          CodecProfile: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          InterlaceMode: '<optional>',
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          SlowPal: '<optional>',
                          Telecine: '<optional>',
                        },
                      ],
                    },
                  ],
                  ColorMetadata: ['<optional1>', '<optional2>'],
                  Crop: {
                    Height: '<optional>',
                    Width: '<optional>',
                    X: '<optional>',
                    Y: '<optional>',
                  },
                  DropFrameTimecode: '<optional>',
                  FixedAfd: '<optional>',
                  Height: '<optional>',
                  Position: {
                    Height: '<optional>',
                    Width: '<optional>',
                    X: '<optional>',
                    Y: '<optional>',
                  },
                  RespondToAfd: '<optional>',
                  ScalingBehavior: '<optional>',
                  Sharpness: '<optional>',
                  TimecodeInsertion: '<optional>',
                  VideoPreprocessors: [
                    {
                      ColorCorrector: {
                        Brightness: '<optional>',
                        ColorSpaceConversion: '<optional>',
                        Contrast: '<optional>',
                        Hdr10Metadata: [
                          {
                            BluePrimaryX: '<optional>',
                            BluePrimaryY: '<optional>',
                            GreenPrimaryX: '<optional>',
                            GreenPrimaryY: '<optional>',
                            MaxContentLightLevel: '<optional>',
                            MaxFrameAverageLightLevel: '<optional>',
                            MaxLuminance: '<optional>',
                            MinLuminance: '<optional>',
                            RedPrimaryX: '<optional>',
                            RedPrimaryY: '<optional>',
                            WhitePointX: '<optional>',
                            WhitePointY: '<optional>',
                          },
                        ],
                        Hue: '<optional>',
                        Saturation: '<optional>',
                      },
                      Deinterlacer: {
                        Algorithm: '<optional>',
                        Control: '<optional>',
                        Mode: '<optional>',
                      },
                      ImageInserter: {
                        InsertableImages: [
                          {
                            Duration: '<optional>',
                            FadeIn: '<optional>',
                            FadeOut: '<optional>',
                            Height: '<optional>',
                            ImageInserterInput: '<optional>',
                            ImageX: '<optional>',
                            ImageY: '<optional>',
                            Layer: '<optional>',
                            Opacity: '<optional>',
                            StartTime: '<optional>',
                            Width: '<optional>',
                          },
                        ],
                      },
                      NoiseReducer: {
                        Filter: '<optional>',
                        FilterSettings: [
                          {
                            Strength: '<optional>',
                          },
                        ],
                        SpatialFilterSettings: [
                          {
                            PostFilterSharpenStrength: '<optional>',
                            Speed: '<optional>',
                            Strength: '<optional>',
                          },
                        ],
                      },
                      TimecodeBurnin: {
                        FontSize: '<optional>',
                        Position: '<optional>',
                        Prefix: '<optional>',
                      },
                    },
                  ],
                  Width: '<optional>',
                },
              },
            ],
          },
        ],
        TimecodeConfig: {
          Anchor: '<optional>',
          Source: '<optional>',
          Start: '<optional>',
          TimestampOffset: '<optional>',
        },
        TimedMetadataInsertion: {
          Id3Insertions: [
            {
              Id3: '<optional>',
              Timecode: '<optional>',
            },
          ],
        },
      },
    ],
    AccelerationSettings: ['<optional1>', '<optional2>'],
    BillingTagsSource: '<optional>',
    ClientRequestToken: '<optional>',
    JobTemplate: '<optional>',
    Queue: '<optional>',
    StatusUpdateInterval: '<optional>',
    UserMetadata: ['<optional1>', '<optional2>'],
  },
  'MediaConvert:createJobTemplate': {
    Settings: [
      {
        AdAvailOffset: '<optional>',
        AvailBlanking: {
          AvailBlankingImage: '<optional>',
        },
        Esam: {
          ManifestConfirmConditionNotification: {
            MccXml: '<optional>',
          },
          ResponseSignalPreroll: '<optional>',
          SignalProcessingNotification: {
            SccXml: '<optional>',
          },
        },
        Inputs: [
          {
            AudioSelectorGroups: ['<optional1>', '<optional2>'],
            AudioSelectors: ['<optional1>', '<optional2>'],
            CaptionSelectors: ['<optional1>', '<optional2>'],
            DeblockFilter: '<optional>',
            DenoiseFilter: '<optional>',
            FilterEnable: '<optional>',
            FilterStrength: '<optional>',
            ImageInserter: {
              InsertableImages: [
                {
                  Duration: '<optional>',
                  FadeIn: '<optional>',
                  FadeOut: '<optional>',
                  Height: '<optional>',
                  ImageInserterInput: '<optional>',
                  ImageX: '<optional>',
                  ImageY: '<optional>',
                  Layer: '<optional>',
                  Opacity: '<optional>',
                  StartTime: '<optional>',
                  Width: '<optional>',
                },
              ],
            },
            InputClippings: [
              {
                EndTimecode: '<optional>',
                StartTimecode: '<optional>',
              },
            ],
            ProgramNumber: '<optional>',
            PsiControl: '<optional>',
            TimecodeSource: '<optional>',
            VideoSelector: {
              ColorSpace: '<optional>',
              ColorSpaceUsage: '<optional>',
              Hdr10Metadata: [
                {
                  BluePrimaryX: '<optional>',
                  BluePrimaryY: '<optional>',
                  GreenPrimaryX: '<optional>',
                  GreenPrimaryY: '<optional>',
                  MaxContentLightLevel: '<optional>',
                  MaxFrameAverageLightLevel: '<optional>',
                  MaxLuminance: '<optional>',
                  MinLuminance: '<optional>',
                  RedPrimaryX: '<optional>',
                  RedPrimaryY: '<optional>',
                  WhitePointX: '<optional>',
                  WhitePointY: '<optional>',
                },
              ],
              Pid: '<optional>',
              ProgramNumber: '<optional>',
              Rotate: '<optional>',
            },
          },
        ],
        MotionImageInserter: {
          Framerate: {
            FramerateDenominator: '<optional>',
            FramerateNumerator: '<optional>',
          },
          Input: '<optional>',
          InsertionMode: '<optional>',
          Offset: {
            ImageX: '<optional>',
            ImageY: '<optional>',
          },
          Playback: '<optional>',
          StartTime: '<optional>',
        },
        NielsenConfiguration: {
          BreakoutCode: '<optional>',
          DistributorId: '<optional>',
        },
        OutputGroups: [
          {
            CustomName: '<optional>',
            Name: '<optional>',
            OutputGroupSettings: [
              {
                CmafGroupSettings: [
                  {
                    BaseUrl: '<optional>',
                    ClientCache: '<optional>',
                    CodecSpecification: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      ConstantInitializationVector: '<optional>',
                      EncryptionMethod: '<optional>',
                      InitializationVectorInManifest: '<optional>',
                      StaticKeyProvider: {
                        KeyFormat: '<optional>',
                        KeyFormatVersions: ['<optional1>', '<optional2>'],
                        StaticKeyValue: '<optional>',
                        Url: '<optional>',
                      },
                      Type: '<optional>',
                    },
                    FragmentLength: '<optional>',
                    ManifestCompression: '<optional>',
                    ManifestDurationFormat: '<optional>',
                    MinBufferTime: '<optional>',
                    MinFinalSegmentLength: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    StreamInfResolution: '<optional>',
                    WriteDashManifest: '<optional>',
                    WriteHlsManifest: '<optional>',
                  },
                ],
                DashIsoGroupSettings: [
                  {
                    BaseUrl: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      PlaybackDeviceCompatibility: '<optional>',
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                    },
                    FragmentLength: '<optional>',
                    HbbtvCompliance: '<optional>',
                    MinBufferTime: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    WriteSegmentTimelineInRepresentation: '<optional>',
                  },
                ],
                FileGroupSettings: [
                  {
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
                HlsGroupSettings: [
                  {
                    AdMarkers: ['<optional1>', '<optional2>'],
                    BaseUrl: '<optional>',
                    CaptionLanguageMappings: [
                      {
                        CaptionChannel: '<optional>',
                        CustomLanguageCode: '<optional>',
                        LanguageCode: '<optional>',
                        LanguageDescription: '<optional>',
                      },
                    ],
                    CaptionLanguageSetting: '<optional>',
                    ClientCache: '<optional>',
                    CodecSpecification: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    DirectoryStructure: '<optional>',
                    Encryption: {
                      ConstantInitializationVector: '<optional>',
                      EncryptionMethod: '<optional>',
                      InitializationVectorInManifest: '<optional>',
                      OfflineEncrypted: '<optional>',
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                      StaticKeyProvider: {
                        KeyFormat: '<optional>',
                        KeyFormatVersions: ['<optional1>', '<optional2>'],
                        StaticKeyValue: '<optional>',
                        Url: '<optional>',
                      },
                      Type: '<optional>',
                    },
                    ManifestCompression: '<optional>',
                    ManifestDurationFormat: '<optional>',
                    MinFinalSegmentLength: '<optional>',
                    MinSegmentLength: '<optional>',
                    OutputSelection: '<optional>',
                    ProgramDateTime: '<optional>',
                    ProgramDateTimePeriod: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    SegmentsPerSubdirectory: '<optional>',
                    StreamInfResolution: '<optional>',
                    TimedMetadataId3Frame: '<optional>',
                    TimedMetadataId3Period: '<optional>',
                    TimestampDeltaMilliseconds: ['<optional1>', '<optional2>'],
                  },
                ],
                MsSmoothGroupSettings: [
                  {
                    AudioDeduplication: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                    },
                    FragmentLength: '<optional>',
                    ManifestEncoding: '<optional>',
                  },
                ],
                Type: '<optional>',
              },
            ],
            Outputs: [
              {
                AudioDescriptions: [
                  {
                    AudioNormalizationSettings: [
                      {
                        Algorithm: '<optional>',
                        AlgorithmControl: '<optional>',
                        CorrectionGateLevel: '<optional>',
                        LoudnessLogging: '<optional>',
                        PeakCalculation: '<optional>',
                        TargetLkfs: ['<optional1>', '<optional2>'],
                      },
                    ],
                    AudioSourceName: '<optional>',
                    AudioType: '<optional>',
                    AudioTypeControl: '<optional>',
                    CodecSettings: [
                      {
                        AacSettings: [
                          {
                            AudioDescriptionBroadcasterMix: '<optional>',
                            Bitrate: '<optional>',
                            CodecProfile: '<optional>',
                            CodingMode: '<optional>',
                            RateControlMode: '<optional>',
                            RawFormat: '<optional>',
                            SampleRate: '<optional>',
                            Specification: '<optional>',
                            VbrQuality: '<optional>',
                          },
                        ],
                        Ac3Settings: [
                          {
                            Bitrate: '<optional>',
                            BitstreamMode: '<optional>',
                            CodingMode: '<optional>',
                            Dialnorm: '<optional>',
                            DynamicRangeCompressionProfile: '<optional>',
                            LfeFilter: '<optional>',
                            MetadataControl: '<optional>',
                            SampleRate: '<optional>',
                          },
                        ],
                        AiffSettings: [
                          {
                            BitDepth: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            SampleRate: '<optional>',
                          },
                        ],
                        Codec: '<optional>',
                        Eac3Settings: [
                          {
                            AttenuationControl: '<optional>',
                            Bitrate: '<optional>',
                            BitstreamMode: '<optional>',
                            CodingMode: '<optional>',
                            DcFilter: '<optional>',
                            Dialnorm: '<optional>',
                            DynamicRangeCompressionLine: '<optional>',
                            DynamicRangeCompressionRf: '<optional>',
                            LfeControl: '<optional>',
                            LfeFilter: '<optional>',
                            LoRoCenterMixLevel: '<optional>',
                            LoRoSurroundMixLevel: '<optional>',
                            LtRtCenterMixLevel: '<optional>',
                            LtRtSurroundMixLevel: '<optional>',
                            MetadataControl: '<optional>',
                            PassthroughControl: '<optional>',
                            PhaseControl: '<optional>',
                            SampleRate: '<optional>',
                            StereoDownmix: '<optional>',
                            SurroundExMode: '<optional>',
                            SurroundMode: '<optional>',
                          },
                        ],
                        Mp2Settings: [
                          {
                            Bitrate: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            SampleRate: '<optional>',
                          },
                        ],
                        WavSettings: [
                          {
                            BitDepth: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            Format: '<optional>',
                            SampleRate: '<optional>',
                          },
                        ],
                      },
                    ],
                    CustomLanguageCode: '<optional>',
                    LanguageCode: '<optional>',
                    LanguageCodeControl: '<optional>',
                    RemixSettings: [
                      {
                        ChannelMapping: {
                          OutputChannels: [
                            {
                              InputChannels: ['<optional1>', '<optional2>'],
                            },
                          ],
                        },
                        ChannelsIn: '<optional>',
                        ChannelsOut: '<optional>',
                      },
                    ],
                    StreamName: '<optional>',
                  },
                ],
                CaptionDescriptions: [
                  {
                    CaptionSelectorName: '<optional>',
                    CustomLanguageCode: '<optional>',
                    DestinationSettings: [
                      {
                        BurninDestinationSettings: [
                          {
                            Alignment: '<optional>',
                            BackgroundColor: '<optional>',
                            BackgroundOpacity: '<optional>',
                            FontColor: '<optional>',
                            FontOpacity: '<optional>',
                            FontResolution: '<optional>',
                            FontScript: '<optional>',
                            FontSize: '<optional>',
                            OutlineColor: '<optional>',
                            OutlineSize: '<optional>',
                            ShadowColor: '<optional>',
                            ShadowOpacity: '<optional>',
                            ShadowXOffset: '<optional>',
                            ShadowYOffset: '<optional>',
                            TeletextSpacing: '<optional>',
                            XPosition: '<optional>',
                            YPosition: '<optional>',
                          },
                        ],
                        DestinationType: '<optional>',
                        DvbSubDestinationSettings: [
                          {
                            Alignment: '<optional>',
                            BackgroundColor: '<optional>',
                            BackgroundOpacity: '<optional>',
                            FontColor: '<optional>',
                            FontOpacity: '<optional>',
                            FontResolution: '<optional>',
                            FontScript: '<optional>',
                            FontSize: '<optional>',
                            OutlineColor: '<optional>',
                            OutlineSize: '<optional>',
                            ShadowColor: '<optional>',
                            ShadowOpacity: '<optional>',
                            ShadowXOffset: '<optional>',
                            ShadowYOffset: '<optional>',
                            TeletextSpacing: '<optional>',
                            XPosition: '<optional>',
                            YPosition: '<optional>',
                          },
                        ],
                        EmbeddedDestinationSettings: [
                          {
                            Destination608ChannelNumber: '<optional>',
                          },
                        ],
                        SccDestinationSettings: [
                          {
                            Framerate: '<optional>',
                          },
                        ],
                        TeletextDestinationSettings: [
                          {
                            PageNumber: '<optional>',
                          },
                        ],
                        TtmlDestinationSettings: [
                          {
                            StylePassthrough: '<optional>',
                          },
                        ],
                      },
                    ],
                    LanguageCode: '<optional>',
                    LanguageDescription: '<optional>',
                  },
                ],
                ContainerSettings: [
                  {
                    Container: '<optional>',
                    F4vSettings: [
                      {
                        MoovPlacement: '<optional>',
                      },
                    ],
                    M2tsSettings: [
                      {
                        AudioBufferModel: '<optional>',
                        AudioFramesPerPes: ['<optional1>', '<optional2>'],
                        AudioPids: ['<optional1>', '<optional2>'],
                        Bitrate: '<optional>',
                        BufferModel: '<optional>',
                        DvbNitSettings: [
                          {
                            NetworkId: '<optional>',
                            NetworkName: '<optional>',
                            NitInterval: '<optional>',
                          },
                        ],
                        DvbSdtSettings: [
                          {
                            OutputSdt: '<optional>',
                            SdtInterval: '<optional>',
                            ServiceName: '<optional>',
                            ServiceProviderName: '<optional>',
                          },
                        ],
                        DvbSubPids: ['<optional1>', '<optional2>'],
                        DvbTdtSettings: [
                          {
                            TdtInterval: '<optional>',
                          },
                        ],
                        DvbTeletextPid: '<optional>',
                        EbpAudioInterval: '<optional>',
                        EbpPlacement: '<optional>',
                        EsRateInPes: ['<optional1>', '<optional2>'],
                        ForceTsVideoEbpOrder: '<optional>',
                        FragmentTime: '<optional>',
                        MaxPcrInterval: '<optional>',
                        MinEbpInterval: '<optional>',
                        NielsenId3: '<optional>',
                        NullPacketBitrate: '<optional>',
                        PatInterval: '<optional>',
                        PcrControl: '<optional>',
                        PcrPid: '<optional>',
                        PmtInterval: '<optional>',
                        PmtPid: '<optional>',
                        PrivateMetadataPid: '<optional>',
                        ProgramNumber: '<optional>',
                        RateMode: '<optional>',
                        Scte35Esam: {
                          Scte35EsamPid: '<optional>',
                        },
                        Scte35Pid: '<optional>',
                        Scte35Source: '<optional>',
                        SegmentationMarkers: ['<optional1>', '<optional2>'],
                        SegmentationStyle: '<optional>',
                        SegmentationTime: '<optional>',
                        TimedMetadataPid: '<optional>',
                        TransportStreamId: '<optional>',
                        VideoPid: '<optional>',
                      },
                    ],
                    M3u8Settings: [
                      {
                        AudioFramesPerPes: ['<optional1>', '<optional2>'],
                        AudioPids: ['<optional1>', '<optional2>'],
                        NielsenId3: '<optional>',
                        PatInterval: '<optional>',
                        PcrControl: '<optional>',
                        PcrPid: '<optional>',
                        PmtInterval: '<optional>',
                        PmtPid: '<optional>',
                        PrivateMetadataPid: '<optional>',
                        ProgramNumber: '<optional>',
                        Scte35Pid: '<optional>',
                        Scte35Source: '<optional>',
                        TimedMetadata: ['<optional1>', '<optional2>'],
                        TimedMetadataPid: '<optional>',
                        TransportStreamId: '<optional>',
                        VideoPid: '<optional>',
                      },
                    ],
                    MovSettings: [
                      {
                        ClapAtom: '<optional>',
                        CslgAtom: '<optional>',
                        Mpeg2FourCCControl: '<optional>',
                        PaddingControl: '<optional>',
                        Reference: '<optional>',
                      },
                    ],
                    Mp4Settings: [
                      {
                        CslgAtom: '<optional>',
                        FreeSpaceBox: '<optional>',
                        MoovPlacement: '<optional>',
                        Mp4MajorBrand: '<optional>',
                      },
                    ],
                  },
                ],
                Extension: '<optional>',
                NameModifier: '<optional>',
                OutputSettings: [
                  {
                    HlsSettings: [
                      {
                        AudioGroupId: '<optional>',
                        AudioRenditionSets: ['<optional1>', '<optional2>'],
                        AudioTrackType: '<optional>',
                        IFrameOnlyManifest: '<optional>',
                        SegmentModifier: '<optional>',
                      },
                    ],
                  },
                ],
                Preset: '<optional>',
                VideoDescription: {
                  AfdSignaling: '<optional>',
                  AntiAlias: '<optional>',
                  CodecSettings: [
                    {
                      Codec: '<optional>',
                      FrameCaptureSettings: [
                        {
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          MaxCaptures: ['<optional1>', '<optional2>'],
                          Quality: '<optional>',
                        },
                      ],
                      H264Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          EntropyEncoding: '<optional>',
                          FieldEncoding: '<optional>',
                          FlickerAdaptiveQuantization: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopBReference: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          NumberReferenceFrames: ['<optional1>', '<optional2>'],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          QvbrSettings: [
                            {
                              MaxAverageBitrate: '<optional>',
                              QvbrQualityLevel: '<optional>',
                            },
                          ],
                          RateControlMode: '<optional>',
                          RepeatPps: ['<optional1>', '<optional2>'],
                          SceneChangeDetect: '<optional>',
                          Slices: ['<optional1>', '<optional2>'],
                          SlowPal: '<optional>',
                          Softness: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Syntax: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                          UnregisteredSeiTimecode: '<optional>',
                        },
                      ],
                      H265Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          AlternateTransferFunctionSei: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          FlickerAdaptiveQuantization: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopBReference: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          NumberReferenceFrames: ['<optional1>', '<optional2>'],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          QvbrSettings: [
                            {
                              MaxAverageBitrate: '<optional>',
                              QvbrQualityLevel: '<optional>',
                            },
                          ],
                          RateControlMode: '<optional>',
                          SampleAdaptiveOffsetFilterMode: '<optional>',
                          SceneChangeDetect: '<optional>',
                          Slices: ['<optional1>', '<optional2>'],
                          SlowPal: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                          TemporalIds: ['<optional1>', '<optional2>'],
                          Tiles: ['<optional1>', '<optional2>'],
                          UnregisteredSeiTimecode: '<optional>',
                          WriteMp4PackagingType: '<optional>',
                        },
                      ],
                      Mpeg2Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          IntraDcPrecision: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          RateControlMode: '<optional>',
                          SceneChangeDetect: '<optional>',
                          SlowPal: '<optional>',
                          Softness: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Syntax: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                        },
                      ],
                      ProresSettings: [
                        {
                          CodecProfile: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          InterlaceMode: '<optional>',
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          SlowPal: '<optional>',
                          Telecine: '<optional>',
                        },
                      ],
                    },
                  ],
                  ColorMetadata: ['<optional1>', '<optional2>'],
                  Crop: {
                    Height: '<optional>',
                    Width: '<optional>',
                    X: '<optional>',
                    Y: '<optional>',
                  },
                  DropFrameTimecode: '<optional>',
                  FixedAfd: '<optional>',
                  Height: '<optional>',
                  Position: {
                    Height: '<optional>',
                    Width: '<optional>',
                    X: '<optional>',
                    Y: '<optional>',
                  },
                  RespondToAfd: '<optional>',
                  ScalingBehavior: '<optional>',
                  Sharpness: '<optional>',
                  TimecodeInsertion: '<optional>',
                  VideoPreprocessors: [
                    {
                      ColorCorrector: {
                        Brightness: '<optional>',
                        ColorSpaceConversion: '<optional>',
                        Contrast: '<optional>',
                        Hdr10Metadata: [
                          {
                            BluePrimaryX: '<optional>',
                            BluePrimaryY: '<optional>',
                            GreenPrimaryX: '<optional>',
                            GreenPrimaryY: '<optional>',
                            MaxContentLightLevel: '<optional>',
                            MaxFrameAverageLightLevel: '<optional>',
                            MaxLuminance: '<optional>',
                            MinLuminance: '<optional>',
                            RedPrimaryX: '<optional>',
                            RedPrimaryY: '<optional>',
                            WhitePointX: '<optional>',
                            WhitePointY: '<optional>',
                          },
                        ],
                        Hue: '<optional>',
                        Saturation: '<optional>',
                      },
                      Deinterlacer: {
                        Algorithm: '<optional>',
                        Control: '<optional>',
                        Mode: '<optional>',
                      },
                      ImageInserter: {
                        InsertableImages: [
                          {
                            Duration: '<optional>',
                            FadeIn: '<optional>',
                            FadeOut: '<optional>',
                            Height: '<optional>',
                            ImageInserterInput: '<optional>',
                            ImageX: '<optional>',
                            ImageY: '<optional>',
                            Layer: '<optional>',
                            Opacity: '<optional>',
                            StartTime: '<optional>',
                            Width: '<optional>',
                          },
                        ],
                      },
                      NoiseReducer: {
                        Filter: '<optional>',
                        FilterSettings: [
                          {
                            Strength: '<optional>',
                          },
                        ],
                        SpatialFilterSettings: [
                          {
                            PostFilterSharpenStrength: '<optional>',
                            Speed: '<optional>',
                            Strength: '<optional>',
                          },
                        ],
                      },
                      TimecodeBurnin: {
                        FontSize: '<optional>',
                        Position: '<optional>',
                        Prefix: '<optional>',
                      },
                    },
                  ],
                  Width: '<optional>',
                },
              },
            ],
          },
        ],
        TimecodeConfig: {
          Anchor: '<optional>',
          Source: '<optional>',
          Start: '<optional>',
          TimestampOffset: '<optional>',
        },
        TimedMetadataInsertion: {
          Id3Insertions: [
            {
              Id3: '<optional>',
              Timecode: '<optional>',
            },
          ],
        },
      },
    ],
    Name: 'required',
    AccelerationSettings: ['<optional1>', '<optional2>'],
    Category: '<optional>',
    Description: '<optional>',
    Queue: '<optional>',
    StatusUpdateInterval: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MediaConvert:createPreset': {
    Settings: [
      {
        AudioDescriptions: [
          {
            AudioNormalizationSettings: [
              {
                Algorithm: '<optional>',
                AlgorithmControl: '<optional>',
                CorrectionGateLevel: '<optional>',
                LoudnessLogging: '<optional>',
                PeakCalculation: '<optional>',
                TargetLkfs: ['<optional1>', '<optional2>'],
              },
            ],
            AudioSourceName: '<optional>',
            AudioType: '<optional>',
            AudioTypeControl: '<optional>',
            CodecSettings: [
              {
                AacSettings: [
                  {
                    AudioDescriptionBroadcasterMix: '<optional>',
                    Bitrate: '<optional>',
                    CodecProfile: '<optional>',
                    CodingMode: '<optional>',
                    RateControlMode: '<optional>',
                    RawFormat: '<optional>',
                    SampleRate: '<optional>',
                    Specification: '<optional>',
                    VbrQuality: '<optional>',
                  },
                ],
                Ac3Settings: [
                  {
                    Bitrate: '<optional>',
                    BitstreamMode: '<optional>',
                    CodingMode: '<optional>',
                    Dialnorm: '<optional>',
                    DynamicRangeCompressionProfile: '<optional>',
                    LfeFilter: '<optional>',
                    MetadataControl: '<optional>',
                    SampleRate: '<optional>',
                  },
                ],
                AiffSettings: [
                  {
                    BitDepth: '<optional>',
                    Channels: ['<optional1>', '<optional2>'],
                    SampleRate: '<optional>',
                  },
                ],
                Codec: '<optional>',
                Eac3Settings: [
                  {
                    AttenuationControl: '<optional>',
                    Bitrate: '<optional>',
                    BitstreamMode: '<optional>',
                    CodingMode: '<optional>',
                    DcFilter: '<optional>',
                    Dialnorm: '<optional>',
                    DynamicRangeCompressionLine: '<optional>',
                    DynamicRangeCompressionRf: '<optional>',
                    LfeControl: '<optional>',
                    LfeFilter: '<optional>',
                    LoRoCenterMixLevel: '<optional>',
                    LoRoSurroundMixLevel: '<optional>',
                    LtRtCenterMixLevel: '<optional>',
                    LtRtSurroundMixLevel: '<optional>',
                    MetadataControl: '<optional>',
                    PassthroughControl: '<optional>',
                    PhaseControl: '<optional>',
                    SampleRate: '<optional>',
                    StereoDownmix: '<optional>',
                    SurroundExMode: '<optional>',
                    SurroundMode: '<optional>',
                  },
                ],
                Mp2Settings: [
                  {
                    Bitrate: '<optional>',
                    Channels: ['<optional1>', '<optional2>'],
                    SampleRate: '<optional>',
                  },
                ],
                WavSettings: [
                  {
                    BitDepth: '<optional>',
                    Channels: ['<optional1>', '<optional2>'],
                    Format: '<optional>',
                    SampleRate: '<optional>',
                  },
                ],
              },
            ],
            CustomLanguageCode: '<optional>',
            LanguageCode: '<optional>',
            LanguageCodeControl: '<optional>',
            RemixSettings: [
              {
                ChannelMapping: {
                  OutputChannels: [
                    {
                      InputChannels: ['<optional1>', '<optional2>'],
                    },
                  ],
                },
                ChannelsIn: '<optional>',
                ChannelsOut: '<optional>',
              },
            ],
            StreamName: '<optional>',
          },
        ],
        CaptionDescriptions: [
          {
            CustomLanguageCode: '<optional>',
            DestinationSettings: [
              {
                BurninDestinationSettings: [
                  {
                    Alignment: '<optional>',
                    BackgroundColor: '<optional>',
                    BackgroundOpacity: '<optional>',
                    FontColor: '<optional>',
                    FontOpacity: '<optional>',
                    FontResolution: '<optional>',
                    FontScript: '<optional>',
                    FontSize: '<optional>',
                    OutlineColor: '<optional>',
                    OutlineSize: '<optional>',
                    ShadowColor: '<optional>',
                    ShadowOpacity: '<optional>',
                    ShadowXOffset: '<optional>',
                    ShadowYOffset: '<optional>',
                    TeletextSpacing: '<optional>',
                    XPosition: '<optional>',
                    YPosition: '<optional>',
                  },
                ],
                DestinationType: '<optional>',
                DvbSubDestinationSettings: [
                  {
                    Alignment: '<optional>',
                    BackgroundColor: '<optional>',
                    BackgroundOpacity: '<optional>',
                    FontColor: '<optional>',
                    FontOpacity: '<optional>',
                    FontResolution: '<optional>',
                    FontScript: '<optional>',
                    FontSize: '<optional>',
                    OutlineColor: '<optional>',
                    OutlineSize: '<optional>',
                    ShadowColor: '<optional>',
                    ShadowOpacity: '<optional>',
                    ShadowXOffset: '<optional>',
                    ShadowYOffset: '<optional>',
                    TeletextSpacing: '<optional>',
                    XPosition: '<optional>',
                    YPosition: '<optional>',
                  },
                ],
                EmbeddedDestinationSettings: [
                  {
                    Destination608ChannelNumber: '<optional>',
                  },
                ],
                SccDestinationSettings: [
                  {
                    Framerate: '<optional>',
                  },
                ],
                TeletextDestinationSettings: [
                  {
                    PageNumber: '<optional>',
                  },
                ],
                TtmlDestinationSettings: [
                  {
                    StylePassthrough: '<optional>',
                  },
                ],
              },
            ],
            LanguageCode: '<optional>',
            LanguageDescription: '<optional>',
          },
        ],
        ContainerSettings: [
          {
            Container: '<optional>',
            F4vSettings: [
              {
                MoovPlacement: '<optional>',
              },
            ],
            M2tsSettings: [
              {
                AudioBufferModel: '<optional>',
                AudioFramesPerPes: ['<optional1>', '<optional2>'],
                AudioPids: ['<optional1>', '<optional2>'],
                Bitrate: '<optional>',
                BufferModel: '<optional>',
                DvbNitSettings: [
                  {
                    NetworkId: '<optional>',
                    NetworkName: '<optional>',
                    NitInterval: '<optional>',
                  },
                ],
                DvbSdtSettings: [
                  {
                    OutputSdt: '<optional>',
                    SdtInterval: '<optional>',
                    ServiceName: '<optional>',
                    ServiceProviderName: '<optional>',
                  },
                ],
                DvbSubPids: ['<optional1>', '<optional2>'],
                DvbTdtSettings: [
                  {
                    TdtInterval: '<optional>',
                  },
                ],
                DvbTeletextPid: '<optional>',
                EbpAudioInterval: '<optional>',
                EbpPlacement: '<optional>',
                EsRateInPes: ['<optional1>', '<optional2>'],
                ForceTsVideoEbpOrder: '<optional>',
                FragmentTime: '<optional>',
                MaxPcrInterval: '<optional>',
                MinEbpInterval: '<optional>',
                NielsenId3: '<optional>',
                NullPacketBitrate: '<optional>',
                PatInterval: '<optional>',
                PcrControl: '<optional>',
                PcrPid: '<optional>',
                PmtInterval: '<optional>',
                PmtPid: '<optional>',
                PrivateMetadataPid: '<optional>',
                ProgramNumber: '<optional>',
                RateMode: '<optional>',
                Scte35Esam: {
                  Scte35EsamPid: '<optional>',
                },
                Scte35Pid: '<optional>',
                Scte35Source: '<optional>',
                SegmentationMarkers: ['<optional1>', '<optional2>'],
                SegmentationStyle: '<optional>',
                SegmentationTime: '<optional>',
                TimedMetadataPid: '<optional>',
                TransportStreamId: '<optional>',
                VideoPid: '<optional>',
              },
            ],
            M3u8Settings: [
              {
                AudioFramesPerPes: ['<optional1>', '<optional2>'],
                AudioPids: ['<optional1>', '<optional2>'],
                NielsenId3: '<optional>',
                PatInterval: '<optional>',
                PcrControl: '<optional>',
                PcrPid: '<optional>',
                PmtInterval: '<optional>',
                PmtPid: '<optional>',
                PrivateMetadataPid: '<optional>',
                ProgramNumber: '<optional>',
                Scte35Pid: '<optional>',
                Scte35Source: '<optional>',
                TimedMetadata: ['<optional1>', '<optional2>'],
                TimedMetadataPid: '<optional>',
                TransportStreamId: '<optional>',
                VideoPid: '<optional>',
              },
            ],
            MovSettings: [
              {
                ClapAtom: '<optional>',
                CslgAtom: '<optional>',
                Mpeg2FourCCControl: '<optional>',
                PaddingControl: '<optional>',
                Reference: '<optional>',
              },
            ],
            Mp4Settings: [
              {
                CslgAtom: '<optional>',
                FreeSpaceBox: '<optional>',
                MoovPlacement: '<optional>',
                Mp4MajorBrand: '<optional>',
              },
            ],
          },
        ],
        VideoDescription: {
          AfdSignaling: '<optional>',
          AntiAlias: '<optional>',
          CodecSettings: [
            {
              Codec: '<optional>',
              FrameCaptureSettings: [
                {
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  MaxCaptures: ['<optional1>', '<optional2>'],
                  Quality: '<optional>',
                },
              ],
              H264Settings: [
                {
                  AdaptiveQuantization: '<optional>',
                  Bitrate: '<optional>',
                  CodecLevel: '<optional>',
                  CodecProfile: '<optional>',
                  DynamicSubGop: '<optional>',
                  EntropyEncoding: '<optional>',
                  FieldEncoding: '<optional>',
                  FlickerAdaptiveQuantization: '<optional>',
                  FramerateControl: '<optional>',
                  FramerateConversionAlgorithm: '<optional>',
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  GopBReference: '<optional>',
                  GopClosedCadence: '<optional>',
                  GopSize: '<optional>',
                  GopSizeUnits: ['<optional1>', '<optional2>'],
                  HrdBufferInitialFillPercentage: '<optional>',
                  HrdBufferSize: '<optional>',
                  InterlaceMode: '<optional>',
                  MaxBitrate: '<optional>',
                  MinIInterval: '<optional>',
                  NumberBFramesBetweenReferenceFrames: [
                    '<optional1>',
                    '<optional2>',
                  ],
                  NumberReferenceFrames: ['<optional1>', '<optional2>'],
                  ParControl: '<optional>',
                  ParDenominator: '<optional>',
                  ParNumerator: '<optional>',
                  QualityTuningLevel: '<optional>',
                  QvbrSettings: [
                    {
                      MaxAverageBitrate: '<optional>',
                      QvbrQualityLevel: '<optional>',
                    },
                  ],
                  RateControlMode: '<optional>',
                  RepeatPps: ['<optional1>', '<optional2>'],
                  SceneChangeDetect: '<optional>',
                  Slices: ['<optional1>', '<optional2>'],
                  SlowPal: '<optional>',
                  Softness: '<optional>',
                  SpatialAdaptiveQuantization: '<optional>',
                  Syntax: '<optional>',
                  Telecine: '<optional>',
                  TemporalAdaptiveQuantization: '<optional>',
                  UnregisteredSeiTimecode: '<optional>',
                },
              ],
              H265Settings: [
                {
                  AdaptiveQuantization: '<optional>',
                  AlternateTransferFunctionSei: '<optional>',
                  Bitrate: '<optional>',
                  CodecLevel: '<optional>',
                  CodecProfile: '<optional>',
                  DynamicSubGop: '<optional>',
                  FlickerAdaptiveQuantization: '<optional>',
                  FramerateControl: '<optional>',
                  FramerateConversionAlgorithm: '<optional>',
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  GopBReference: '<optional>',
                  GopClosedCadence: '<optional>',
                  GopSize: '<optional>',
                  GopSizeUnits: ['<optional1>', '<optional2>'],
                  HrdBufferInitialFillPercentage: '<optional>',
                  HrdBufferSize: '<optional>',
                  InterlaceMode: '<optional>',
                  MaxBitrate: '<optional>',
                  MinIInterval: '<optional>',
                  NumberBFramesBetweenReferenceFrames: [
                    '<optional1>',
                    '<optional2>',
                  ],
                  NumberReferenceFrames: ['<optional1>', '<optional2>'],
                  ParControl: '<optional>',
                  ParDenominator: '<optional>',
                  ParNumerator: '<optional>',
                  QualityTuningLevel: '<optional>',
                  QvbrSettings: [
                    {
                      MaxAverageBitrate: '<optional>',
                      QvbrQualityLevel: '<optional>',
                    },
                  ],
                  RateControlMode: '<optional>',
                  SampleAdaptiveOffsetFilterMode: '<optional>',
                  SceneChangeDetect: '<optional>',
                  Slices: ['<optional1>', '<optional2>'],
                  SlowPal: '<optional>',
                  SpatialAdaptiveQuantization: '<optional>',
                  Telecine: '<optional>',
                  TemporalAdaptiveQuantization: '<optional>',
                  TemporalIds: ['<optional1>', '<optional2>'],
                  Tiles: ['<optional1>', '<optional2>'],
                  UnregisteredSeiTimecode: '<optional>',
                  WriteMp4PackagingType: '<optional>',
                },
              ],
              Mpeg2Settings: [
                {
                  AdaptiveQuantization: '<optional>',
                  Bitrate: '<optional>',
                  CodecLevel: '<optional>',
                  CodecProfile: '<optional>',
                  DynamicSubGop: '<optional>',
                  FramerateControl: '<optional>',
                  FramerateConversionAlgorithm: '<optional>',
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  GopClosedCadence: '<optional>',
                  GopSize: '<optional>',
                  GopSizeUnits: ['<optional1>', '<optional2>'],
                  HrdBufferInitialFillPercentage: '<optional>',
                  HrdBufferSize: '<optional>',
                  InterlaceMode: '<optional>',
                  IntraDcPrecision: '<optional>',
                  MaxBitrate: '<optional>',
                  MinIInterval: '<optional>',
                  NumberBFramesBetweenReferenceFrames: [
                    '<optional1>',
                    '<optional2>',
                  ],
                  ParControl: '<optional>',
                  ParDenominator: '<optional>',
                  ParNumerator: '<optional>',
                  QualityTuningLevel: '<optional>',
                  RateControlMode: '<optional>',
                  SceneChangeDetect: '<optional>',
                  SlowPal: '<optional>',
                  Softness: '<optional>',
                  SpatialAdaptiveQuantization: '<optional>',
                  Syntax: '<optional>',
                  Telecine: '<optional>',
                  TemporalAdaptiveQuantization: '<optional>',
                },
              ],
              ProresSettings: [
                {
                  CodecProfile: '<optional>',
                  FramerateControl: '<optional>',
                  FramerateConversionAlgorithm: '<optional>',
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  InterlaceMode: '<optional>',
                  ParControl: '<optional>',
                  ParDenominator: '<optional>',
                  ParNumerator: '<optional>',
                  SlowPal: '<optional>',
                  Telecine: '<optional>',
                },
              ],
            },
          ],
          ColorMetadata: ['<optional1>', '<optional2>'],
          Crop: {
            Height: '<optional>',
            Width: '<optional>',
            X: '<optional>',
            Y: '<optional>',
          },
          DropFrameTimecode: '<optional>',
          FixedAfd: '<optional>',
          Height: '<optional>',
          Position: {
            Height: '<optional>',
            Width: '<optional>',
            X: '<optional>',
            Y: '<optional>',
          },
          RespondToAfd: '<optional>',
          ScalingBehavior: '<optional>',
          Sharpness: '<optional>',
          TimecodeInsertion: '<optional>',
          VideoPreprocessors: [
            {
              ColorCorrector: {
                Brightness: '<optional>',
                ColorSpaceConversion: '<optional>',
                Contrast: '<optional>',
                Hdr10Metadata: [
                  {
                    BluePrimaryX: '<optional>',
                    BluePrimaryY: '<optional>',
                    GreenPrimaryX: '<optional>',
                    GreenPrimaryY: '<optional>',
                    MaxContentLightLevel: '<optional>',
                    MaxFrameAverageLightLevel: '<optional>',
                    MaxLuminance: '<optional>',
                    MinLuminance: '<optional>',
                    RedPrimaryX: '<optional>',
                    RedPrimaryY: '<optional>',
                    WhitePointX: '<optional>',
                    WhitePointY: '<optional>',
                  },
                ],
                Hue: '<optional>',
                Saturation: '<optional>',
              },
              Deinterlacer: {
                Algorithm: '<optional>',
                Control: '<optional>',
                Mode: '<optional>',
              },
              ImageInserter: {
                InsertableImages: [
                  {
                    Duration: '<optional>',
                    FadeIn: '<optional>',
                    FadeOut: '<optional>',
                    Height: '<optional>',
                    ImageInserterInput: '<optional>',
                    ImageX: '<optional>',
                    ImageY: '<optional>',
                    Layer: '<optional>',
                    Opacity: '<optional>',
                    StartTime: '<optional>',
                    Width: '<optional>',
                  },
                ],
              },
              NoiseReducer: {
                Filter: '<optional>',
                FilterSettings: [
                  {
                    Strength: '<optional>',
                  },
                ],
                SpatialFilterSettings: [
                  {
                    PostFilterSharpenStrength: '<optional>',
                    Speed: '<optional>',
                    Strength: '<optional>',
                  },
                ],
              },
              TimecodeBurnin: {
                FontSize: '<optional>',
                Position: '<optional>',
                Prefix: '<optional>',
              },
            },
          ],
          Width: '<optional>',
        },
      },
    ],
    Name: 'required',
    Category: '<optional>',
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MediaConvert:createQueue': {
    Name: 'required',
    ReservationPlanSettings: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    PricingPlan: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MediaConvert:deleteJobTemplate': {
    Name: 'required',
  },
  'MediaConvert:deletePreset': {
    Name: 'required',
  },
  'MediaConvert:deleteQueue': {
    Name: 'required',
  },
  'MediaConvert:describeEndpoints': {
    Mode: '<optional>',
  },
  'MediaConvert:disassociateCertificate': {
    Arn: 'required',
  },
  'MediaConvert:getJob': {
    Id: 'required',
  },
  'MediaConvert:getJobTemplate': {
    Name: 'required',
  },
  'MediaConvert:getPreset': {
    Name: 'required',
  },
  'MediaConvert:getQueue': {
    Name: 'required',
  },
  'MediaConvert:listJobTemplates': {
    Category: '<optional>',
    ListBy: '<optional>',
    Order: '<optional>',
  },
  'MediaConvert:listJobs': {
    Order: '<optional>',
    Queue: '<optional>',
    Status: '<optional>',
  },
  'MediaConvert:listPresets': {
    Category: '<optional>',
    ListBy: '<optional>',
    Order: '<optional>',
  },
  'MediaConvert:listQueues': {
    ListBy: '<optional>',
    Order: '<optional>',
  },
  'MediaConvert:listTagsForResource': {
    Arn: 'required',
  },
  'MediaConvert:tagResource': {
    Arn: 'required',
    Tags: ['required1', 'required2'],
  },
  'MediaConvert:untagResource': {
    Arn: 'required',
    TagKeys: ['<optional1>', '<optional2>'],
  },
  'MediaConvert:updateJobTemplate': {
    Name: 'required',
    AccelerationSettings: ['<optional1>', '<optional2>'],
    Category: '<optional>',
    Description: '<optional>',
    Queue: '<optional>',
    Settings: [
      {
        AdAvailOffset: '<optional>',
        AvailBlanking: {
          AvailBlankingImage: '<optional>',
        },
        Esam: {
          ManifestConfirmConditionNotification: {
            MccXml: '<optional>',
          },
          ResponseSignalPreroll: '<optional>',
          SignalProcessingNotification: {
            SccXml: '<optional>',
          },
        },
        Inputs: [
          {
            AudioSelectorGroups: ['<optional1>', '<optional2>'],
            AudioSelectors: ['<optional1>', '<optional2>'],
            CaptionSelectors: ['<optional1>', '<optional2>'],
            DeblockFilter: '<optional>',
            DenoiseFilter: '<optional>',
            FilterEnable: '<optional>',
            FilterStrength: '<optional>',
            ImageInserter: {
              InsertableImages: [
                {
                  Duration: '<optional>',
                  FadeIn: '<optional>',
                  FadeOut: '<optional>',
                  Height: '<optional>',
                  ImageInserterInput: '<optional>',
                  ImageX: '<optional>',
                  ImageY: '<optional>',
                  Layer: '<optional>',
                  Opacity: '<optional>',
                  StartTime: '<optional>',
                  Width: '<optional>',
                },
              ],
            },
            InputClippings: [
              {
                EndTimecode: '<optional>',
                StartTimecode: '<optional>',
              },
            ],
            ProgramNumber: '<optional>',
            PsiControl: '<optional>',
            TimecodeSource: '<optional>',
            VideoSelector: {
              ColorSpace: '<optional>',
              ColorSpaceUsage: '<optional>',
              Hdr10Metadata: [
                {
                  BluePrimaryX: '<optional>',
                  BluePrimaryY: '<optional>',
                  GreenPrimaryX: '<optional>',
                  GreenPrimaryY: '<optional>',
                  MaxContentLightLevel: '<optional>',
                  MaxFrameAverageLightLevel: '<optional>',
                  MaxLuminance: '<optional>',
                  MinLuminance: '<optional>',
                  RedPrimaryX: '<optional>',
                  RedPrimaryY: '<optional>',
                  WhitePointX: '<optional>',
                  WhitePointY: '<optional>',
                },
              ],
              Pid: '<optional>',
              ProgramNumber: '<optional>',
              Rotate: '<optional>',
            },
          },
        ],
        MotionImageInserter: {
          Framerate: {
            FramerateDenominator: '<optional>',
            FramerateNumerator: '<optional>',
          },
          Input: '<optional>',
          InsertionMode: '<optional>',
          Offset: {
            ImageX: '<optional>',
            ImageY: '<optional>',
          },
          Playback: '<optional>',
          StartTime: '<optional>',
        },
        NielsenConfiguration: {
          BreakoutCode: '<optional>',
          DistributorId: '<optional>',
        },
        OutputGroups: [
          {
            CustomName: '<optional>',
            Name: '<optional>',
            OutputGroupSettings: [
              {
                CmafGroupSettings: [
                  {
                    BaseUrl: '<optional>',
                    ClientCache: '<optional>',
                    CodecSpecification: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      ConstantInitializationVector: '<optional>',
                      EncryptionMethod: '<optional>',
                      InitializationVectorInManifest: '<optional>',
                      StaticKeyProvider: {
                        KeyFormat: '<optional>',
                        KeyFormatVersions: ['<optional1>', '<optional2>'],
                        StaticKeyValue: '<optional>',
                        Url: '<optional>',
                      },
                      Type: '<optional>',
                    },
                    FragmentLength: '<optional>',
                    ManifestCompression: '<optional>',
                    ManifestDurationFormat: '<optional>',
                    MinBufferTime: '<optional>',
                    MinFinalSegmentLength: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    StreamInfResolution: '<optional>',
                    WriteDashManifest: '<optional>',
                    WriteHlsManifest: '<optional>',
                  },
                ],
                DashIsoGroupSettings: [
                  {
                    BaseUrl: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      PlaybackDeviceCompatibility: '<optional>',
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                    },
                    FragmentLength: '<optional>',
                    HbbtvCompliance: '<optional>',
                    MinBufferTime: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    WriteSegmentTimelineInRepresentation: '<optional>',
                  },
                ],
                FileGroupSettings: [
                  {
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
                HlsGroupSettings: [
                  {
                    AdMarkers: ['<optional1>', '<optional2>'],
                    BaseUrl: '<optional>',
                    CaptionLanguageMappings: [
                      {
                        CaptionChannel: '<optional>',
                        CustomLanguageCode: '<optional>',
                        LanguageCode: '<optional>',
                        LanguageDescription: '<optional>',
                      },
                    ],
                    CaptionLanguageSetting: '<optional>',
                    ClientCache: '<optional>',
                    CodecSpecification: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    DirectoryStructure: '<optional>',
                    Encryption: {
                      ConstantInitializationVector: '<optional>',
                      EncryptionMethod: '<optional>',
                      InitializationVectorInManifest: '<optional>',
                      OfflineEncrypted: '<optional>',
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                      StaticKeyProvider: {
                        KeyFormat: '<optional>',
                        KeyFormatVersions: ['<optional1>', '<optional2>'],
                        StaticKeyValue: '<optional>',
                        Url: '<optional>',
                      },
                      Type: '<optional>',
                    },
                    ManifestCompression: '<optional>',
                    ManifestDurationFormat: '<optional>',
                    MinFinalSegmentLength: '<optional>',
                    MinSegmentLength: '<optional>',
                    OutputSelection: '<optional>',
                    ProgramDateTime: '<optional>',
                    ProgramDateTimePeriod: '<optional>',
                    SegmentControl: '<optional>',
                    SegmentLength: '<optional>',
                    SegmentsPerSubdirectory: '<optional>',
                    StreamInfResolution: '<optional>',
                    TimedMetadataId3Frame: '<optional>',
                    TimedMetadataId3Period: '<optional>',
                    TimestampDeltaMilliseconds: ['<optional1>', '<optional2>'],
                  },
                ],
                MsSmoothGroupSettings: [
                  {
                    AudioDeduplication: '<optional>',
                    Destination: '<optional>',
                    DestinationSettings: [
                      {
                        S3Settings: [
                          {
                            Encryption: {
                              EncryptionType: '<optional>',
                              KmsKeyArn: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    Encryption: {
                      SpekeKeyProvider: {
                        CertificateArn: '<optional>',
                        ResourceId: '<optional>',
                        SystemIds: ['<optional1>', '<optional2>'],
                        Url: '<optional>',
                      },
                    },
                    FragmentLength: '<optional>',
                    ManifestEncoding: '<optional>',
                  },
                ],
                Type: '<optional>',
              },
            ],
            Outputs: [
              {
                AudioDescriptions: [
                  {
                    AudioNormalizationSettings: [
                      {
                        Algorithm: '<optional>',
                        AlgorithmControl: '<optional>',
                        CorrectionGateLevel: '<optional>',
                        LoudnessLogging: '<optional>',
                        PeakCalculation: '<optional>',
                        TargetLkfs: ['<optional1>', '<optional2>'],
                      },
                    ],
                    AudioSourceName: '<optional>',
                    AudioType: '<optional>',
                    AudioTypeControl: '<optional>',
                    CodecSettings: [
                      {
                        AacSettings: [
                          {
                            AudioDescriptionBroadcasterMix: '<optional>',
                            Bitrate: '<optional>',
                            CodecProfile: '<optional>',
                            CodingMode: '<optional>',
                            RateControlMode: '<optional>',
                            RawFormat: '<optional>',
                            SampleRate: '<optional>',
                            Specification: '<optional>',
                            VbrQuality: '<optional>',
                          },
                        ],
                        Ac3Settings: [
                          {
                            Bitrate: '<optional>',
                            BitstreamMode: '<optional>',
                            CodingMode: '<optional>',
                            Dialnorm: '<optional>',
                            DynamicRangeCompressionProfile: '<optional>',
                            LfeFilter: '<optional>',
                            MetadataControl: '<optional>',
                            SampleRate: '<optional>',
                          },
                        ],
                        AiffSettings: [
                          {
                            BitDepth: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            SampleRate: '<optional>',
                          },
                        ],
                        Codec: '<optional>',
                        Eac3Settings: [
                          {
                            AttenuationControl: '<optional>',
                            Bitrate: '<optional>',
                            BitstreamMode: '<optional>',
                            CodingMode: '<optional>',
                            DcFilter: '<optional>',
                            Dialnorm: '<optional>',
                            DynamicRangeCompressionLine: '<optional>',
                            DynamicRangeCompressionRf: '<optional>',
                            LfeControl: '<optional>',
                            LfeFilter: '<optional>',
                            LoRoCenterMixLevel: '<optional>',
                            LoRoSurroundMixLevel: '<optional>',
                            LtRtCenterMixLevel: '<optional>',
                            LtRtSurroundMixLevel: '<optional>',
                            MetadataControl: '<optional>',
                            PassthroughControl: '<optional>',
                            PhaseControl: '<optional>',
                            SampleRate: '<optional>',
                            StereoDownmix: '<optional>',
                            SurroundExMode: '<optional>',
                            SurroundMode: '<optional>',
                          },
                        ],
                        Mp2Settings: [
                          {
                            Bitrate: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            SampleRate: '<optional>',
                          },
                        ],
                        WavSettings: [
                          {
                            BitDepth: '<optional>',
                            Channels: ['<optional1>', '<optional2>'],
                            Format: '<optional>',
                            SampleRate: '<optional>',
                          },
                        ],
                      },
                    ],
                    CustomLanguageCode: '<optional>',
                    LanguageCode: '<optional>',
                    LanguageCodeControl: '<optional>',
                    RemixSettings: [
                      {
                        ChannelMapping: {
                          OutputChannels: [
                            {
                              InputChannels: ['<optional1>', '<optional2>'],
                            },
                          ],
                        },
                        ChannelsIn: '<optional>',
                        ChannelsOut: '<optional>',
                      },
                    ],
                    StreamName: '<optional>',
                  },
                ],
                CaptionDescriptions: [
                  {
                    CaptionSelectorName: '<optional>',
                    CustomLanguageCode: '<optional>',
                    DestinationSettings: [
                      {
                        BurninDestinationSettings: [
                          {
                            Alignment: '<optional>',
                            BackgroundColor: '<optional>',
                            BackgroundOpacity: '<optional>',
                            FontColor: '<optional>',
                            FontOpacity: '<optional>',
                            FontResolution: '<optional>',
                            FontScript: '<optional>',
                            FontSize: '<optional>',
                            OutlineColor: '<optional>',
                            OutlineSize: '<optional>',
                            ShadowColor: '<optional>',
                            ShadowOpacity: '<optional>',
                            ShadowXOffset: '<optional>',
                            ShadowYOffset: '<optional>',
                            TeletextSpacing: '<optional>',
                            XPosition: '<optional>',
                            YPosition: '<optional>',
                          },
                        ],
                        DestinationType: '<optional>',
                        DvbSubDestinationSettings: [
                          {
                            Alignment: '<optional>',
                            BackgroundColor: '<optional>',
                            BackgroundOpacity: '<optional>',
                            FontColor: '<optional>',
                            FontOpacity: '<optional>',
                            FontResolution: '<optional>',
                            FontScript: '<optional>',
                            FontSize: '<optional>',
                            OutlineColor: '<optional>',
                            OutlineSize: '<optional>',
                            ShadowColor: '<optional>',
                            ShadowOpacity: '<optional>',
                            ShadowXOffset: '<optional>',
                            ShadowYOffset: '<optional>',
                            TeletextSpacing: '<optional>',
                            XPosition: '<optional>',
                            YPosition: '<optional>',
                          },
                        ],
                        EmbeddedDestinationSettings: [
                          {
                            Destination608ChannelNumber: '<optional>',
                          },
                        ],
                        SccDestinationSettings: [
                          {
                            Framerate: '<optional>',
                          },
                        ],
                        TeletextDestinationSettings: [
                          {
                            PageNumber: '<optional>',
                          },
                        ],
                        TtmlDestinationSettings: [
                          {
                            StylePassthrough: '<optional>',
                          },
                        ],
                      },
                    ],
                    LanguageCode: '<optional>',
                    LanguageDescription: '<optional>',
                  },
                ],
                ContainerSettings: [
                  {
                    Container: '<optional>',
                    F4vSettings: [
                      {
                        MoovPlacement: '<optional>',
                      },
                    ],
                    M2tsSettings: [
                      {
                        AudioBufferModel: '<optional>',
                        AudioFramesPerPes: ['<optional1>', '<optional2>'],
                        AudioPids: ['<optional1>', '<optional2>'],
                        Bitrate: '<optional>',
                        BufferModel: '<optional>',
                        DvbNitSettings: [
                          {
                            NetworkId: '<optional>',
                            NetworkName: '<optional>',
                            NitInterval: '<optional>',
                          },
                        ],
                        DvbSdtSettings: [
                          {
                            OutputSdt: '<optional>',
                            SdtInterval: '<optional>',
                            ServiceName: '<optional>',
                            ServiceProviderName: '<optional>',
                          },
                        ],
                        DvbSubPids: ['<optional1>', '<optional2>'],
                        DvbTdtSettings: [
                          {
                            TdtInterval: '<optional>',
                          },
                        ],
                        DvbTeletextPid: '<optional>',
                        EbpAudioInterval: '<optional>',
                        EbpPlacement: '<optional>',
                        EsRateInPes: ['<optional1>', '<optional2>'],
                        ForceTsVideoEbpOrder: '<optional>',
                        FragmentTime: '<optional>',
                        MaxPcrInterval: '<optional>',
                        MinEbpInterval: '<optional>',
                        NielsenId3: '<optional>',
                        NullPacketBitrate: '<optional>',
                        PatInterval: '<optional>',
                        PcrControl: '<optional>',
                        PcrPid: '<optional>',
                        PmtInterval: '<optional>',
                        PmtPid: '<optional>',
                        PrivateMetadataPid: '<optional>',
                        ProgramNumber: '<optional>',
                        RateMode: '<optional>',
                        Scte35Esam: {
                          Scte35EsamPid: '<optional>',
                        },
                        Scte35Pid: '<optional>',
                        Scte35Source: '<optional>',
                        SegmentationMarkers: ['<optional1>', '<optional2>'],
                        SegmentationStyle: '<optional>',
                        SegmentationTime: '<optional>',
                        TimedMetadataPid: '<optional>',
                        TransportStreamId: '<optional>',
                        VideoPid: '<optional>',
                      },
                    ],
                    M3u8Settings: [
                      {
                        AudioFramesPerPes: ['<optional1>', '<optional2>'],
                        AudioPids: ['<optional1>', '<optional2>'],
                        NielsenId3: '<optional>',
                        PatInterval: '<optional>',
                        PcrControl: '<optional>',
                        PcrPid: '<optional>',
                        PmtInterval: '<optional>',
                        PmtPid: '<optional>',
                        PrivateMetadataPid: '<optional>',
                        ProgramNumber: '<optional>',
                        Scte35Pid: '<optional>',
                        Scte35Source: '<optional>',
                        TimedMetadata: ['<optional1>', '<optional2>'],
                        TimedMetadataPid: '<optional>',
                        TransportStreamId: '<optional>',
                        VideoPid: '<optional>',
                      },
                    ],
                    MovSettings: [
                      {
                        ClapAtom: '<optional>',
                        CslgAtom: '<optional>',
                        Mpeg2FourCCControl: '<optional>',
                        PaddingControl: '<optional>',
                        Reference: '<optional>',
                      },
                    ],
                    Mp4Settings: [
                      {
                        CslgAtom: '<optional>',
                        FreeSpaceBox: '<optional>',
                        MoovPlacement: '<optional>',
                        Mp4MajorBrand: '<optional>',
                      },
                    ],
                  },
                ],
                Extension: '<optional>',
                NameModifier: '<optional>',
                OutputSettings: [
                  {
                    HlsSettings: [
                      {
                        AudioGroupId: '<optional>',
                        AudioRenditionSets: ['<optional1>', '<optional2>'],
                        AudioTrackType: '<optional>',
                        IFrameOnlyManifest: '<optional>',
                        SegmentModifier: '<optional>',
                      },
                    ],
                  },
                ],
                Preset: '<optional>',
                VideoDescription: {
                  AfdSignaling: '<optional>',
                  AntiAlias: '<optional>',
                  CodecSettings: [
                    {
                      Codec: '<optional>',
                      FrameCaptureSettings: [
                        {
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          MaxCaptures: ['<optional1>', '<optional2>'],
                          Quality: '<optional>',
                        },
                      ],
                      H264Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          EntropyEncoding: '<optional>',
                          FieldEncoding: '<optional>',
                          FlickerAdaptiveQuantization: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopBReference: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          NumberReferenceFrames: ['<optional1>', '<optional2>'],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          QvbrSettings: [
                            {
                              MaxAverageBitrate: '<optional>',
                              QvbrQualityLevel: '<optional>',
                            },
                          ],
                          RateControlMode: '<optional>',
                          RepeatPps: ['<optional1>', '<optional2>'],
                          SceneChangeDetect: '<optional>',
                          Slices: ['<optional1>', '<optional2>'],
                          SlowPal: '<optional>',
                          Softness: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Syntax: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                          UnregisteredSeiTimecode: '<optional>',
                        },
                      ],
                      H265Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          AlternateTransferFunctionSei: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          FlickerAdaptiveQuantization: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopBReference: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          NumberReferenceFrames: ['<optional1>', '<optional2>'],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          QvbrSettings: [
                            {
                              MaxAverageBitrate: '<optional>',
                              QvbrQualityLevel: '<optional>',
                            },
                          ],
                          RateControlMode: '<optional>',
                          SampleAdaptiveOffsetFilterMode: '<optional>',
                          SceneChangeDetect: '<optional>',
                          Slices: ['<optional1>', '<optional2>'],
                          SlowPal: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                          TemporalIds: ['<optional1>', '<optional2>'],
                          Tiles: ['<optional1>', '<optional2>'],
                          UnregisteredSeiTimecode: '<optional>',
                          WriteMp4PackagingType: '<optional>',
                        },
                      ],
                      Mpeg2Settings: [
                        {
                          AdaptiveQuantization: '<optional>',
                          Bitrate: '<optional>',
                          CodecLevel: '<optional>',
                          CodecProfile: '<optional>',
                          DynamicSubGop: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          GopClosedCadence: '<optional>',
                          GopSize: '<optional>',
                          GopSizeUnits: ['<optional1>', '<optional2>'],
                          HrdBufferInitialFillPercentage: '<optional>',
                          HrdBufferSize: '<optional>',
                          InterlaceMode: '<optional>',
                          IntraDcPrecision: '<optional>',
                          MaxBitrate: '<optional>',
                          MinIInterval: '<optional>',
                          NumberBFramesBetweenReferenceFrames: [
                            '<optional1>',
                            '<optional2>',
                          ],
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          QualityTuningLevel: '<optional>',
                          RateControlMode: '<optional>',
                          SceneChangeDetect: '<optional>',
                          SlowPal: '<optional>',
                          Softness: '<optional>',
                          SpatialAdaptiveQuantization: '<optional>',
                          Syntax: '<optional>',
                          Telecine: '<optional>',
                          TemporalAdaptiveQuantization: '<optional>',
                        },
                      ],
                      ProresSettings: [
                        {
                          CodecProfile: '<optional>',
                          FramerateControl: '<optional>',
                          FramerateConversionAlgorithm: '<optional>',
                          FramerateDenominator: '<optional>',
                          FramerateNumerator: '<optional>',
                          InterlaceMode: '<optional>',
                          ParControl: '<optional>',
                          ParDenominator: '<optional>',
                          ParNumerator: '<optional>',
                          SlowPal: '<optional>',
                          Telecine: '<optional>',
                        },
                      ],
                    },
                  ],
                  ColorMetadata: ['<optional1>', '<optional2>'],
                  Crop: {
                    Height: '<optional>',
                    Width: '<optional>',
                    X: '<optional>',
                    Y: '<optional>',
                  },
                  DropFrameTimecode: '<optional>',
                  FixedAfd: '<optional>',
                  Height: '<optional>',
                  Position: {
                    Height: '<optional>',
                    Width: '<optional>',
                    X: '<optional>',
                    Y: '<optional>',
                  },
                  RespondToAfd: '<optional>',
                  ScalingBehavior: '<optional>',
                  Sharpness: '<optional>',
                  TimecodeInsertion: '<optional>',
                  VideoPreprocessors: [
                    {
                      ColorCorrector: {
                        Brightness: '<optional>',
                        ColorSpaceConversion: '<optional>',
                        Contrast: '<optional>',
                        Hdr10Metadata: [
                          {
                            BluePrimaryX: '<optional>',
                            BluePrimaryY: '<optional>',
                            GreenPrimaryX: '<optional>',
                            GreenPrimaryY: '<optional>',
                            MaxContentLightLevel: '<optional>',
                            MaxFrameAverageLightLevel: '<optional>',
                            MaxLuminance: '<optional>',
                            MinLuminance: '<optional>',
                            RedPrimaryX: '<optional>',
                            RedPrimaryY: '<optional>',
                            WhitePointX: '<optional>',
                            WhitePointY: '<optional>',
                          },
                        ],
                        Hue: '<optional>',
                        Saturation: '<optional>',
                      },
                      Deinterlacer: {
                        Algorithm: '<optional>',
                        Control: '<optional>',
                        Mode: '<optional>',
                      },
                      ImageInserter: {
                        InsertableImages: [
                          {
                            Duration: '<optional>',
                            FadeIn: '<optional>',
                            FadeOut: '<optional>',
                            Height: '<optional>',
                            ImageInserterInput: '<optional>',
                            ImageX: '<optional>',
                            ImageY: '<optional>',
                            Layer: '<optional>',
                            Opacity: '<optional>',
                            StartTime: '<optional>',
                            Width: '<optional>',
                          },
                        ],
                      },
                      NoiseReducer: {
                        Filter: '<optional>',
                        FilterSettings: [
                          {
                            Strength: '<optional>',
                          },
                        ],
                        SpatialFilterSettings: [
                          {
                            PostFilterSharpenStrength: '<optional>',
                            Speed: '<optional>',
                            Strength: '<optional>',
                          },
                        ],
                      },
                      TimecodeBurnin: {
                        FontSize: '<optional>',
                        Position: '<optional>',
                        Prefix: '<optional>',
                      },
                    },
                  ],
                  Width: '<optional>',
                },
              },
            ],
          },
        ],
        TimecodeConfig: {
          Anchor: '<optional>',
          Source: '<optional>',
          Start: '<optional>',
          TimestampOffset: '<optional>',
        },
        TimedMetadataInsertion: {
          Id3Insertions: [
            {
              Id3: '<optional>',
              Timecode: '<optional>',
            },
          ],
        },
      },
    ],
    StatusUpdateInterval: '<optional>',
  },
  'MediaConvert:updatePreset': {
    Name: 'required',
    Category: '<optional>',
    Description: '<optional>',
    Settings: [
      {
        AudioDescriptions: [
          {
            AudioNormalizationSettings: [
              {
                Algorithm: '<optional>',
                AlgorithmControl: '<optional>',
                CorrectionGateLevel: '<optional>',
                LoudnessLogging: '<optional>',
                PeakCalculation: '<optional>',
                TargetLkfs: ['<optional1>', '<optional2>'],
              },
            ],
            AudioSourceName: '<optional>',
            AudioType: '<optional>',
            AudioTypeControl: '<optional>',
            CodecSettings: [
              {
                AacSettings: [
                  {
                    AudioDescriptionBroadcasterMix: '<optional>',
                    Bitrate: '<optional>',
                    CodecProfile: '<optional>',
                    CodingMode: '<optional>',
                    RateControlMode: '<optional>',
                    RawFormat: '<optional>',
                    SampleRate: '<optional>',
                    Specification: '<optional>',
                    VbrQuality: '<optional>',
                  },
                ],
                Ac3Settings: [
                  {
                    Bitrate: '<optional>',
                    BitstreamMode: '<optional>',
                    CodingMode: '<optional>',
                    Dialnorm: '<optional>',
                    DynamicRangeCompressionProfile: '<optional>',
                    LfeFilter: '<optional>',
                    MetadataControl: '<optional>',
                    SampleRate: '<optional>',
                  },
                ],
                AiffSettings: [
                  {
                    BitDepth: '<optional>',
                    Channels: ['<optional1>', '<optional2>'],
                    SampleRate: '<optional>',
                  },
                ],
                Codec: '<optional>',
                Eac3Settings: [
                  {
                    AttenuationControl: '<optional>',
                    Bitrate: '<optional>',
                    BitstreamMode: '<optional>',
                    CodingMode: '<optional>',
                    DcFilter: '<optional>',
                    Dialnorm: '<optional>',
                    DynamicRangeCompressionLine: '<optional>',
                    DynamicRangeCompressionRf: '<optional>',
                    LfeControl: '<optional>',
                    LfeFilter: '<optional>',
                    LoRoCenterMixLevel: '<optional>',
                    LoRoSurroundMixLevel: '<optional>',
                    LtRtCenterMixLevel: '<optional>',
                    LtRtSurroundMixLevel: '<optional>',
                    MetadataControl: '<optional>',
                    PassthroughControl: '<optional>',
                    PhaseControl: '<optional>',
                    SampleRate: '<optional>',
                    StereoDownmix: '<optional>',
                    SurroundExMode: '<optional>',
                    SurroundMode: '<optional>',
                  },
                ],
                Mp2Settings: [
                  {
                    Bitrate: '<optional>',
                    Channels: ['<optional1>', '<optional2>'],
                    SampleRate: '<optional>',
                  },
                ],
                WavSettings: [
                  {
                    BitDepth: '<optional>',
                    Channels: ['<optional1>', '<optional2>'],
                    Format: '<optional>',
                    SampleRate: '<optional>',
                  },
                ],
              },
            ],
            CustomLanguageCode: '<optional>',
            LanguageCode: '<optional>',
            LanguageCodeControl: '<optional>',
            RemixSettings: [
              {
                ChannelMapping: {
                  OutputChannels: [
                    {
                      InputChannels: ['<optional1>', '<optional2>'],
                    },
                  ],
                },
                ChannelsIn: '<optional>',
                ChannelsOut: '<optional>',
              },
            ],
            StreamName: '<optional>',
          },
        ],
        CaptionDescriptions: [
          {
            CustomLanguageCode: '<optional>',
            DestinationSettings: [
              {
                BurninDestinationSettings: [
                  {
                    Alignment: '<optional>',
                    BackgroundColor: '<optional>',
                    BackgroundOpacity: '<optional>',
                    FontColor: '<optional>',
                    FontOpacity: '<optional>',
                    FontResolution: '<optional>',
                    FontScript: '<optional>',
                    FontSize: '<optional>',
                    OutlineColor: '<optional>',
                    OutlineSize: '<optional>',
                    ShadowColor: '<optional>',
                    ShadowOpacity: '<optional>',
                    ShadowXOffset: '<optional>',
                    ShadowYOffset: '<optional>',
                    TeletextSpacing: '<optional>',
                    XPosition: '<optional>',
                    YPosition: '<optional>',
                  },
                ],
                DestinationType: '<optional>',
                DvbSubDestinationSettings: [
                  {
                    Alignment: '<optional>',
                    BackgroundColor: '<optional>',
                    BackgroundOpacity: '<optional>',
                    FontColor: '<optional>',
                    FontOpacity: '<optional>',
                    FontResolution: '<optional>',
                    FontScript: '<optional>',
                    FontSize: '<optional>',
                    OutlineColor: '<optional>',
                    OutlineSize: '<optional>',
                    ShadowColor: '<optional>',
                    ShadowOpacity: '<optional>',
                    ShadowXOffset: '<optional>',
                    ShadowYOffset: '<optional>',
                    TeletextSpacing: '<optional>',
                    XPosition: '<optional>',
                    YPosition: '<optional>',
                  },
                ],
                EmbeddedDestinationSettings: [
                  {
                    Destination608ChannelNumber: '<optional>',
                  },
                ],
                SccDestinationSettings: [
                  {
                    Framerate: '<optional>',
                  },
                ],
                TeletextDestinationSettings: [
                  {
                    PageNumber: '<optional>',
                  },
                ],
                TtmlDestinationSettings: [
                  {
                    StylePassthrough: '<optional>',
                  },
                ],
              },
            ],
            LanguageCode: '<optional>',
            LanguageDescription: '<optional>',
          },
        ],
        ContainerSettings: [
          {
            Container: '<optional>',
            F4vSettings: [
              {
                MoovPlacement: '<optional>',
              },
            ],
            M2tsSettings: [
              {
                AudioBufferModel: '<optional>',
                AudioFramesPerPes: ['<optional1>', '<optional2>'],
                AudioPids: ['<optional1>', '<optional2>'],
                Bitrate: '<optional>',
                BufferModel: '<optional>',
                DvbNitSettings: [
                  {
                    NetworkId: '<optional>',
                    NetworkName: '<optional>',
                    NitInterval: '<optional>',
                  },
                ],
                DvbSdtSettings: [
                  {
                    OutputSdt: '<optional>',
                    SdtInterval: '<optional>',
                    ServiceName: '<optional>',
                    ServiceProviderName: '<optional>',
                  },
                ],
                DvbSubPids: ['<optional1>', '<optional2>'],
                DvbTdtSettings: [
                  {
                    TdtInterval: '<optional>',
                  },
                ],
                DvbTeletextPid: '<optional>',
                EbpAudioInterval: '<optional>',
                EbpPlacement: '<optional>',
                EsRateInPes: ['<optional1>', '<optional2>'],
                ForceTsVideoEbpOrder: '<optional>',
                FragmentTime: '<optional>',
                MaxPcrInterval: '<optional>',
                MinEbpInterval: '<optional>',
                NielsenId3: '<optional>',
                NullPacketBitrate: '<optional>',
                PatInterval: '<optional>',
                PcrControl: '<optional>',
                PcrPid: '<optional>',
                PmtInterval: '<optional>',
                PmtPid: '<optional>',
                PrivateMetadataPid: '<optional>',
                ProgramNumber: '<optional>',
                RateMode: '<optional>',
                Scte35Esam: {
                  Scte35EsamPid: '<optional>',
                },
                Scte35Pid: '<optional>',
                Scte35Source: '<optional>',
                SegmentationMarkers: ['<optional1>', '<optional2>'],
                SegmentationStyle: '<optional>',
                SegmentationTime: '<optional>',
                TimedMetadataPid: '<optional>',
                TransportStreamId: '<optional>',
                VideoPid: '<optional>',
              },
            ],
            M3u8Settings: [
              {
                AudioFramesPerPes: ['<optional1>', '<optional2>'],
                AudioPids: ['<optional1>', '<optional2>'],
                NielsenId3: '<optional>',
                PatInterval: '<optional>',
                PcrControl: '<optional>',
                PcrPid: '<optional>',
                PmtInterval: '<optional>',
                PmtPid: '<optional>',
                PrivateMetadataPid: '<optional>',
                ProgramNumber: '<optional>',
                Scte35Pid: '<optional>',
                Scte35Source: '<optional>',
                TimedMetadata: ['<optional1>', '<optional2>'],
                TimedMetadataPid: '<optional>',
                TransportStreamId: '<optional>',
                VideoPid: '<optional>',
              },
            ],
            MovSettings: [
              {
                ClapAtom: '<optional>',
                CslgAtom: '<optional>',
                Mpeg2FourCCControl: '<optional>',
                PaddingControl: '<optional>',
                Reference: '<optional>',
              },
            ],
            Mp4Settings: [
              {
                CslgAtom: '<optional>',
                FreeSpaceBox: '<optional>',
                MoovPlacement: '<optional>',
                Mp4MajorBrand: '<optional>',
              },
            ],
          },
        ],
        VideoDescription: {
          AfdSignaling: '<optional>',
          AntiAlias: '<optional>',
          CodecSettings: [
            {
              Codec: '<optional>',
              FrameCaptureSettings: [
                {
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  MaxCaptures: ['<optional1>', '<optional2>'],
                  Quality: '<optional>',
                },
              ],
              H264Settings: [
                {
                  AdaptiveQuantization: '<optional>',
                  Bitrate: '<optional>',
                  CodecLevel: '<optional>',
                  CodecProfile: '<optional>',
                  DynamicSubGop: '<optional>',
                  EntropyEncoding: '<optional>',
                  FieldEncoding: '<optional>',
                  FlickerAdaptiveQuantization: '<optional>',
                  FramerateControl: '<optional>',
                  FramerateConversionAlgorithm: '<optional>',
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  GopBReference: '<optional>',
                  GopClosedCadence: '<optional>',
                  GopSize: '<optional>',
                  GopSizeUnits: ['<optional1>', '<optional2>'],
                  HrdBufferInitialFillPercentage: '<optional>',
                  HrdBufferSize: '<optional>',
                  InterlaceMode: '<optional>',
                  MaxBitrate: '<optional>',
                  MinIInterval: '<optional>',
                  NumberBFramesBetweenReferenceFrames: [
                    '<optional1>',
                    '<optional2>',
                  ],
                  NumberReferenceFrames: ['<optional1>', '<optional2>'],
                  ParControl: '<optional>',
                  ParDenominator: '<optional>',
                  ParNumerator: '<optional>',
                  QualityTuningLevel: '<optional>',
                  QvbrSettings: [
                    {
                      MaxAverageBitrate: '<optional>',
                      QvbrQualityLevel: '<optional>',
                    },
                  ],
                  RateControlMode: '<optional>',
                  RepeatPps: ['<optional1>', '<optional2>'],
                  SceneChangeDetect: '<optional>',
                  Slices: ['<optional1>', '<optional2>'],
                  SlowPal: '<optional>',
                  Softness: '<optional>',
                  SpatialAdaptiveQuantization: '<optional>',
                  Syntax: '<optional>',
                  Telecine: '<optional>',
                  TemporalAdaptiveQuantization: '<optional>',
                  UnregisteredSeiTimecode: '<optional>',
                },
              ],
              H265Settings: [
                {
                  AdaptiveQuantization: '<optional>',
                  AlternateTransferFunctionSei: '<optional>',
                  Bitrate: '<optional>',
                  CodecLevel: '<optional>',
                  CodecProfile: '<optional>',
                  DynamicSubGop: '<optional>',
                  FlickerAdaptiveQuantization: '<optional>',
                  FramerateControl: '<optional>',
                  FramerateConversionAlgorithm: '<optional>',
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  GopBReference: '<optional>',
                  GopClosedCadence: '<optional>',
                  GopSize: '<optional>',
                  GopSizeUnits: ['<optional1>', '<optional2>'],
                  HrdBufferInitialFillPercentage: '<optional>',
                  HrdBufferSize: '<optional>',
                  InterlaceMode: '<optional>',
                  MaxBitrate: '<optional>',
                  MinIInterval: '<optional>',
                  NumberBFramesBetweenReferenceFrames: [
                    '<optional1>',
                    '<optional2>',
                  ],
                  NumberReferenceFrames: ['<optional1>', '<optional2>'],
                  ParControl: '<optional>',
                  ParDenominator: '<optional>',
                  ParNumerator: '<optional>',
                  QualityTuningLevel: '<optional>',
                  QvbrSettings: [
                    {
                      MaxAverageBitrate: '<optional>',
                      QvbrQualityLevel: '<optional>',
                    },
                  ],
                  RateControlMode: '<optional>',
                  SampleAdaptiveOffsetFilterMode: '<optional>',
                  SceneChangeDetect: '<optional>',
                  Slices: ['<optional1>', '<optional2>'],
                  SlowPal: '<optional>',
                  SpatialAdaptiveQuantization: '<optional>',
                  Telecine: '<optional>',
                  TemporalAdaptiveQuantization: '<optional>',
                  TemporalIds: ['<optional1>', '<optional2>'],
                  Tiles: ['<optional1>', '<optional2>'],
                  UnregisteredSeiTimecode: '<optional>',
                  WriteMp4PackagingType: '<optional>',
                },
              ],
              Mpeg2Settings: [
                {
                  AdaptiveQuantization: '<optional>',
                  Bitrate: '<optional>',
                  CodecLevel: '<optional>',
                  CodecProfile: '<optional>',
                  DynamicSubGop: '<optional>',
                  FramerateControl: '<optional>',
                  FramerateConversionAlgorithm: '<optional>',
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  GopClosedCadence: '<optional>',
                  GopSize: '<optional>',
                  GopSizeUnits: ['<optional1>', '<optional2>'],
                  HrdBufferInitialFillPercentage: '<optional>',
                  HrdBufferSize: '<optional>',
                  InterlaceMode: '<optional>',
                  IntraDcPrecision: '<optional>',
                  MaxBitrate: '<optional>',
                  MinIInterval: '<optional>',
                  NumberBFramesBetweenReferenceFrames: [
                    '<optional1>',
                    '<optional2>',
                  ],
                  ParControl: '<optional>',
                  ParDenominator: '<optional>',
                  ParNumerator: '<optional>',
                  QualityTuningLevel: '<optional>',
                  RateControlMode: '<optional>',
                  SceneChangeDetect: '<optional>',
                  SlowPal: '<optional>',
                  Softness: '<optional>',
                  SpatialAdaptiveQuantization: '<optional>',
                  Syntax: '<optional>',
                  Telecine: '<optional>',
                  TemporalAdaptiveQuantization: '<optional>',
                },
              ],
              ProresSettings: [
                {
                  CodecProfile: '<optional>',
                  FramerateControl: '<optional>',
                  FramerateConversionAlgorithm: '<optional>',
                  FramerateDenominator: '<optional>',
                  FramerateNumerator: '<optional>',
                  InterlaceMode: '<optional>',
                  ParControl: '<optional>',
                  ParDenominator: '<optional>',
                  ParNumerator: '<optional>',
                  SlowPal: '<optional>',
                  Telecine: '<optional>',
                },
              ],
            },
          ],
          ColorMetadata: ['<optional1>', '<optional2>'],
          Crop: {
            Height: '<optional>',
            Width: '<optional>',
            X: '<optional>',
            Y: '<optional>',
          },
          DropFrameTimecode: '<optional>',
          FixedAfd: '<optional>',
          Height: '<optional>',
          Position: {
            Height: '<optional>',
            Width: '<optional>',
            X: '<optional>',
            Y: '<optional>',
          },
          RespondToAfd: '<optional>',
          ScalingBehavior: '<optional>',
          Sharpness: '<optional>',
          TimecodeInsertion: '<optional>',
          VideoPreprocessors: [
            {
              ColorCorrector: {
                Brightness: '<optional>',
                ColorSpaceConversion: '<optional>',
                Contrast: '<optional>',
                Hdr10Metadata: [
                  {
                    BluePrimaryX: '<optional>',
                    BluePrimaryY: '<optional>',
                    GreenPrimaryX: '<optional>',
                    GreenPrimaryY: '<optional>',
                    MaxContentLightLevel: '<optional>',
                    MaxFrameAverageLightLevel: '<optional>',
                    MaxLuminance: '<optional>',
                    MinLuminance: '<optional>',
                    RedPrimaryX: '<optional>',
                    RedPrimaryY: '<optional>',
                    WhitePointX: '<optional>',
                    WhitePointY: '<optional>',
                  },
                ],
                Hue: '<optional>',
                Saturation: '<optional>',
              },
              Deinterlacer: {
                Algorithm: '<optional>',
                Control: '<optional>',
                Mode: '<optional>',
              },
              ImageInserter: {
                InsertableImages: [
                  {
                    Duration: '<optional>',
                    FadeIn: '<optional>',
                    FadeOut: '<optional>',
                    Height: '<optional>',
                    ImageInserterInput: '<optional>',
                    ImageX: '<optional>',
                    ImageY: '<optional>',
                    Layer: '<optional>',
                    Opacity: '<optional>',
                    StartTime: '<optional>',
                    Width: '<optional>',
                  },
                ],
              },
              NoiseReducer: {
                Filter: '<optional>',
                FilterSettings: [
                  {
                    Strength: '<optional>',
                  },
                ],
                SpatialFilterSettings: [
                  {
                    PostFilterSharpenStrength: '<optional>',
                    Speed: '<optional>',
                    Strength: '<optional>',
                  },
                ],
              },
              TimecodeBurnin: {
                FontSize: '<optional>',
                Position: '<optional>',
                Prefix: '<optional>',
              },
            },
          ],
          Width: '<optional>',
        },
      },
    ],
  },
  'MediaConvert:updateQueue': {
    Name: 'required',
    ReservationPlanSettings: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    Status: '<optional>',
  },
  'MediaLive:batchUpdateSchedule': {
    ChannelId: 'required',
    Creates: [
      {
        ScheduleActions: [
          {
            ScheduleActionSettings: [
              {
                HlsTimedMetadataSettings: ['<optional1>', '<optional2>'],
                InputSwitchSettings: ['<optional1>', '<optional2>'],
                PauseStateSettings: [
                  {
                    Pipelines: ['<optional1>', '<optional2>'],
                  },
                ],
                Scte35ReturnToNetworkSettings: ['<optional1>', '<optional2>'],
                Scte35SpliceInsertSettings: [
                  {
                    Duration: '<optional>',
                  },
                ],
                Scte35TimeSignalSettings: [
                  {
                    Scte35Descriptors: [
                      {
                        Scte35DescriptorSettings: [
                          {
                            SegmentationDescriptorScte35DescriptorSettings: [
                              {
                                DeliveryRestrictions: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                SegmentNum: '<optional>',
                                SegmentationDuration: '<optional>',
                                SegmentationTypeId: '<optional>',
                                SegmentationUpid: '<optional>',
                                SegmentationUpidType: '<optional>',
                                SegmentsExpected: '<optional>',
                                SubSegmentNum: '<optional>',
                                SubSegmentsExpected: '<optional>',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
                StaticImageActivateSettings: [
                  {
                    Duration: '<optional>',
                    FadeIn: '<optional>',
                    FadeOut: '<optional>',
                    Height: '<optional>',
                    ImageX: '<optional>',
                    ImageY: '<optional>',
                    Layer: '<optional>',
                    Opacity: '<optional>',
                    Width: '<optional>',
                    Image: {
                      PasswordParam: '<optional>',
                      Username: '<optional>',
                    },
                  },
                ],
                StaticImageDeactivateSettings: [
                  {
                    FadeOut: '<optional>',
                    Layer: '<optional>',
                  },
                ],
              },
            ],
            ScheduleActionStartSettings: [
              {
                FixedModeScheduleActionStartSettings: [
                  '<optional1>',
                  '<optional2>',
                ],
                FollowModeScheduleActionStartSettings: [
                  '<optional1>',
                  '<optional2>',
                ],
              },
            ],
          },
        ],
      },
    ],
    Deletes: ['<optional1>', '<optional2>'],
  },
  'MediaLive:createChannel': {
    EncoderSettings: [
      {
        AvailBlanking: {
          AvailBlankingImage: {
            PasswordParam: '<optional>',
            Username: '<optional>',
          },
          State: '<optional>',
        },
        AvailConfiguration: {
          AvailSettings: [
            {
              Scte35SpliceInsert: {
                AdAvailOffset: '<optional>',
                NoRegionalBlackoutFlag: '<optional>',
                WebDeliveryAllowedFlag: '<optional>',
              },
              Scte35TimeSignalApos: [
                {
                  AdAvailOffset: '<optional>',
                  NoRegionalBlackoutFlag: '<optional>',
                  WebDeliveryAllowedFlag: '<optional>',
                },
              ],
            },
          ],
        },
        BlackoutSlate: {
          BlackoutSlateImage: {
            PasswordParam: '<optional>',
            Username: '<optional>',
          },
          NetworkEndBlackout: '<optional>',
          NetworkEndBlackoutImage: {
            PasswordParam: '<optional>',
            Username: '<optional>',
          },
          NetworkId: '<optional>',
          State: '<optional>',
        },
        CaptionDescriptions: [
          {
            DestinationSettings: [
              {
                AribDestinationSettings: ['<optional1>', '<optional2>'],
                BurnInDestinationSettings: [
                  {
                    Alignment: '<optional>',
                    BackgroundColor: '<optional>',
                    BackgroundOpacity: '<optional>',
                    Font: {
                      PasswordParam: '<optional>',
                      Username: '<optional>',
                    },
                    FontColor: '<optional>',
                    FontOpacity: '<optional>',
                    FontResolution: '<optional>',
                    FontSize: '<optional>',
                    OutlineColor: '<optional>',
                    OutlineSize: '<optional>',
                    ShadowColor: '<optional>',
                    ShadowOpacity: '<optional>',
                    ShadowXOffset: '<optional>',
                    ShadowYOffset: '<optional>',
                    TeletextGridControl: '<optional>',
                    XPosition: '<optional>',
                    YPosition: '<optional>',
                  },
                ],
                DvbSubDestinationSettings: [
                  {
                    Alignment: '<optional>',
                    BackgroundColor: '<optional>',
                    BackgroundOpacity: '<optional>',
                    Font: {
                      PasswordParam: '<optional>',
                      Username: '<optional>',
                    },
                    FontColor: '<optional>',
                    FontOpacity: '<optional>',
                    FontResolution: '<optional>',
                    FontSize: '<optional>',
                    OutlineColor: '<optional>',
                    OutlineSize: '<optional>',
                    ShadowColor: '<optional>',
                    ShadowOpacity: '<optional>',
                    ShadowXOffset: '<optional>',
                    ShadowYOffset: '<optional>',
                    TeletextGridControl: '<optional>',
                    XPosition: '<optional>',
                    YPosition: '<optional>',
                  },
                ],
                EmbeddedDestinationSettings: ['<optional1>', '<optional2>'],
                EmbeddedPlusScte20DestinationSettings: [
                  '<optional1>',
                  '<optional2>',
                ],
                RtmpCaptionInfoDestinationSettings: [
                  '<optional1>',
                  '<optional2>',
                ],
                Scte20PlusEmbeddedDestinationSettings: [
                  '<optional1>',
                  '<optional2>',
                ],
                Scte27DestinationSettings: ['<optional1>', '<optional2>'],
                SmpteTtDestinationSettings: ['<optional1>', '<optional2>'],
                TeletextDestinationSettings: ['<optional1>', '<optional2>'],
                TtmlDestinationSettings: [
                  {
                    StyleControl: '<optional>',
                  },
                ],
                WebvttDestinationSettings: ['<optional1>', '<optional2>'],
              },
            ],
            LanguageCode: '<optional>',
            LanguageDescription: '<optional>',
          },
        ],
        GlobalConfiguration: {
          InitialAudioGain: '<optional>',
          InputEndAction: '<optional>',
          InputLossBehavior: {
            BlackFrameMsec: '<optional>',
            InputLossImageColor: '<optional>',
            InputLossImageSlate: {
              PasswordParam: '<optional>',
              Username: '<optional>',
            },
            InputLossImageType: '<optional>',
            RepeatFrameMsec: '<optional>',
          },
          OutputLockingMode: '<optional>',
          OutputTimingSource: '<optional>',
          SupportLowFramerateInputs: ['<optional1>', '<optional2>'],
        },
        AudioDescriptions: [
          {
            AudioNormalizationSettings: [
              {
                Algorithm: '<optional>',
                AlgorithmControl: '<optional>',
                TargetLkfs: ['<optional1>', '<optional2>'],
              },
            ],
            AudioType: '<optional>',
            AudioTypeControl: '<optional>',
            CodecSettings: [
              {
                AacSettings: [
                  {
                    Bitrate: '<optional>',
                    CodingMode: '<optional>',
                    InputType: '<optional>',
                    Profile: '<optional>',
                    RateControlMode: '<optional>',
                    RawFormat: '<optional>',
                    SampleRate: '<optional>',
                    Spec: '<optional>',
                    VbrQuality: '<optional>',
                  },
                ],
                Ac3Settings: [
                  {
                    Bitrate: '<optional>',
                    BitstreamMode: '<optional>',
                    CodingMode: '<optional>',
                    Dialnorm: '<optional>',
                    DrcProfile: '<optional>',
                    LfeFilter: '<optional>',
                    MetadataControl: '<optional>',
                  },
                ],
                Eac3Settings: [
                  {
                    AttenuationControl: '<optional>',
                    Bitrate: '<optional>',
                    BitstreamMode: '<optional>',
                    CodingMode: '<optional>',
                    DcFilter: '<optional>',
                    Dialnorm: '<optional>',
                    DrcLine: '<optional>',
                    DrcRf: '<optional>',
                    LfeControl: '<optional>',
                    LfeFilter: '<optional>',
                    LoRoCenterMixLevel: '<optional>',
                    LoRoSurroundMixLevel: '<optional>',
                    LtRtCenterMixLevel: '<optional>',
                    LtRtSurroundMixLevel: '<optional>',
                    MetadataControl: '<optional>',
                    PassthroughControl: '<optional>',
                    PhaseControl: '<optional>',
                    StereoDownmix: '<optional>',
                    SurroundExMode: '<optional>',
                    SurroundMode: '<optional>',
                  },
                ],
                Mp2Settings: [
                  {
                    Bitrate: '<optional>',
                    CodingMode: '<optional>',
                    SampleRate: '<optional>',
                  },
                ],
                PassThroughSettings: ['<optional1>', '<optional2>'],
              },
            ],
            LanguageCode: '<optional>',
            LanguageCodeControl: '<optional>',
            RemixSettings: [
              {
                ChannelsIn: '<optional>',
                ChannelsOut: '<optional>',
              },
            ],
            StreamName: '<optional>',
          },
        ],
        OutputGroups: [
          {
            Name: '<optional>',
            OutputGroupSettings: [
              {
                ArchiveGroupSettings: [
                  {
                    RolloverInterval: '<optional>',
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                FrameCaptureGroupSettings: [
                  {
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                HlsGroupSettings: [
                  {
                    AdMarkers: ['<optional1>', '<optional2>'],
                    BaseUrlContent: '<optional>',
                    BaseUrlManifest: '<optional>',
                    CaptionLanguageMappings: ['<optional1>', '<optional2>'],
                    CaptionLanguageSetting: '<optional>',
                    ClientCache: '<optional>',
                    CodecSpecification: '<optional>',
                    ConstantIv: '<optional>',
                    DirectoryStructure: '<optional>',
                    EncryptionType: '<optional>',
                    HlsCdnSettings: [
                      {
                        HlsAkamaiSettings: [
                          {
                            ConnectionRetryInterval: '<optional>',
                            FilecacheDuration: '<optional>',
                            HttpTransferMode: '<optional>',
                            NumRetries: ['<optional1>', '<optional2>'],
                            RestartDelay: '<optional>',
                            Salt: '<optional>',
                            Token: '<optional>',
                          },
                        ],
                        HlsBasicPutSettings: [
                          {
                            ConnectionRetryInterval: '<optional>',
                            FilecacheDuration: '<optional>',
                            NumRetries: ['<optional1>', '<optional2>'],
                            RestartDelay: '<optional>',
                          },
                        ],
                        HlsMediaStoreSettings: [
                          {
                            ConnectionRetryInterval: '<optional>',
                            FilecacheDuration: '<optional>',
                            MediaStoreStorageClass: '<optional>',
                            NumRetries: ['<optional1>', '<optional2>'],
                            RestartDelay: '<optional>',
                          },
                        ],
                        HlsWebdavSettings: [
                          {
                            ConnectionRetryInterval: '<optional>',
                            FilecacheDuration: '<optional>',
                            HttpTransferMode: '<optional>',
                            NumRetries: ['<optional1>', '<optional2>'],
                            RestartDelay: '<optional>',
                          },
                        ],
                      },
                    ],
                    IFrameOnlyPlaylists: ['<optional1>', '<optional2>'],
                    IndexNSegments: ['<optional1>', '<optional2>'],
                    InputLossAction: '<optional>',
                    IvInManifest: '<optional>',
                    IvSource: '<optional>',
                    KeepSegments: ['<optional1>', '<optional2>'],
                    KeyFormat: '<optional>',
                    KeyFormatVersions: ['<optional1>', '<optional2>'],
                    KeyProviderSettings: [
                      {
                        StaticKeySettings: [
                          {
                            KeyProviderServer: {
                              PasswordParam: '<optional>',
                              Username: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    ManifestCompression: '<optional>',
                    ManifestDurationFormat: '<optional>',
                    MinSegmentLength: '<optional>',
                    Mode: '<optional>',
                    OutputSelection: '<optional>',
                    ProgramDateTime: '<optional>',
                    ProgramDateTimePeriod: '<optional>',
                    RedundantManifest: '<optional>',
                    SegmentLength: '<optional>',
                    SegmentationMode: '<optional>',
                    SegmentsPerSubdirectory: '<optional>',
                    StreamInfResolution: '<optional>',
                    TimedMetadataId3Frame: '<optional>',
                    TimedMetadataId3Period: '<optional>',
                    TimestampDeltaMilliseconds: ['<optional1>', '<optional2>'],
                    TsFileMode: '<optional>',
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                MediaPackageGroupSettings: [
                  {
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                MsSmoothGroupSettings: [
                  {
                    AcquisitionPointId: '<optional>',
                    AudioOnlyTimecodeControl: '<optional>',
                    CertificateMode: '<optional>',
                    ConnectionRetryInterval: '<optional>',
                    EventId: '<optional>',
                    EventIdMode: '<optional>',
                    EventStopBehavior: '<optional>',
                    FilecacheDuration: '<optional>',
                    FragmentLength: '<optional>',
                    InputLossAction: '<optional>',
                    NumRetries: ['<optional1>', '<optional2>'],
                    RestartDelay: '<optional>',
                    SegmentationMode: '<optional>',
                    SendDelayMs: ['<optional1>', '<optional2>'],
                    SparseTrackType: '<optional>',
                    StreamManifestBehavior: '<optional>',
                    TimestampOffset: '<optional>',
                    TimestampOffsetMode: '<optional>',
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                RtmpGroupSettings: [
                  {
                    AuthenticationScheme: '<optional>',
                    CacheFullBehavior: '<optional>',
                    CacheLength: '<optional>',
                    CaptionData: ['<optional1>', '<optional2>'],
                    InputLossAction: '<optional>',
                    RestartDelay: '<optional>',
                  },
                ],
                UdpGroupSettings: [
                  {
                    InputLossAction: '<optional>',
                    TimedMetadataId3Frame: '<optional>',
                    TimedMetadataId3Period: '<optional>',
                  },
                ],
              },
            ],
            Outputs: [
              {
                AudioDescriptionNames: ['<optional1>', '<optional2>'],
                CaptionDescriptionNames: ['<optional1>', '<optional2>'],
                OutputName: '<optional>',
                VideoDescriptionName: '<optional>',
                OutputSettings: [
                  {
                    ArchiveOutputSettings: [
                      {
                        Extension: '<optional>',
                        NameModifier: '<optional>',
                        ContainerSettings: [
                          {
                            M2tsSettings: [
                              {
                                AbsentInputAudioBehavior: '<optional>',
                                Arib: '<optional>',
                                AribCaptionsPid: '<optional>',
                                AribCaptionsPidControl: '<optional>',
                                AudioBufferModel: '<optional>',
                                AudioFramesPerPes: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                AudioPids: ['<optional1>', '<optional2>'],
                                AudioStreamType: '<optional>',
                                Bitrate: '<optional>',
                                BufferModel: '<optional>',
                                CcDescriptor: '<optional>',
                                DvbNitSettings: [
                                  {
                                    RepInterval: '<optional>',
                                  },
                                ],
                                DvbSdtSettings: [
                                  {
                                    OutputSdt: '<optional>',
                                    RepInterval: '<optional>',
                                    ServiceName: '<optional>',
                                    ServiceProviderName: '<optional>',
                                  },
                                ],
                                DvbSubPids: ['<optional1>', '<optional2>'],
                                DvbTdtSettings: [
                                  {
                                    RepInterval: '<optional>',
                                  },
                                ],
                                DvbTeletextPid: '<optional>',
                                Ebif: '<optional>',
                                EbpAudioInterval: '<optional>',
                                EbpLookaheadMs: ['<optional1>', '<optional2>'],
                                EbpPlacement: '<optional>',
                                EcmPid: '<optional>',
                                EsRateInPes: ['<optional1>', '<optional2>'],
                                EtvPlatformPid: '<optional>',
                                EtvSignalPid: '<optional>',
                                FragmentTime: '<optional>',
                                Klv: '<optional>',
                                KlvDataPids: ['<optional1>', '<optional2>'],
                                NullPacketBitrate: '<optional>',
                                PatInterval: '<optional>',
                                PcrControl: '<optional>',
                                PcrPeriod: '<optional>',
                                PcrPid: '<optional>',
                                PmtInterval: '<optional>',
                                PmtPid: '<optional>',
                                ProgramNum: '<optional>',
                                RateMode: '<optional>',
                                Scte27Pids: ['<optional1>', '<optional2>'],
                                Scte35Control: '<optional>',
                                Scte35Pid: '<optional>',
                                SegmentationMarkers: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                SegmentationStyle: '<optional>',
                                SegmentationTime: '<optional>',
                                TimedMetadataBehavior: '<optional>',
                                TimedMetadataPid: '<optional>',
                                TransportStreamId: '<optional>',
                                VideoPid: '<optional>',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    FrameCaptureOutputSettings: [
                      {
                        NameModifier: '<optional>',
                      },
                    ],
                    HlsOutputSettings: [
                      {
                        NameModifier: '<optional>',
                        SegmentModifier: '<optional>',
                        HlsSettings: [
                          {
                            AudioOnlyHlsSettings: [
                              {
                                AudioGroupId: '<optional>',
                                AudioOnlyImage: {
                                  PasswordParam: '<optional>',
                                  Username: '<optional>',
                                },
                                AudioTrackType: '<optional>',
                              },
                            ],
                            StandardHlsSettings: [
                              {
                                AudioRenditionSets: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                M3u8Settings: [
                                  {
                                    AudioFramesPerPes: [
                                      '<optional1>',
                                      '<optional2>',
                                    ],
                                    AudioPids: ['<optional1>', '<optional2>'],
                                    EcmPid: '<optional>',
                                    PatInterval: '<optional>',
                                    PcrControl: '<optional>',
                                    PcrPeriod: '<optional>',
                                    PcrPid: '<optional>',
                                    PmtInterval: '<optional>',
                                    PmtPid: '<optional>',
                                    ProgramNum: '<optional>',
                                    Scte35Behavior: '<optional>',
                                    Scte35Pid: '<optional>',
                                    TimedMetadataBehavior: '<optional>',
                                    TimedMetadataPid: '<optional>',
                                    TransportStreamId: '<optional>',
                                    VideoPid: '<optional>',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    MediaPackageOutputSettings: ['<optional1>', '<optional2>'],
                    MsSmoothOutputSettings: [
                      {
                        NameModifier: '<optional>',
                      },
                    ],
                    RtmpOutputSettings: [
                      {
                        CertificateMode: '<optional>',
                        ConnectionRetryInterval: '<optional>',
                        NumRetries: ['<optional1>', '<optional2>'],
                        Destination: {
                          DestinationRefId: '<optional>',
                        },
                      },
                    ],
                    UdpOutputSettings: [
                      {
                        BufferMsec: '<optional>',
                        FecOutputSettings: [
                          {
                            ColumnDepth: '<optional>',
                            IncludeFec: '<optional>',
                            RowLength: '<optional>',
                          },
                        ],
                        ContainerSettings: [
                          {
                            M2tsSettings: [
                              {
                                AbsentInputAudioBehavior: '<optional>',
                                Arib: '<optional>',
                                AribCaptionsPid: '<optional>',
                                AribCaptionsPidControl: '<optional>',
                                AudioBufferModel: '<optional>',
                                AudioFramesPerPes: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                AudioPids: ['<optional1>', '<optional2>'],
                                AudioStreamType: '<optional>',
                                Bitrate: '<optional>',
                                BufferModel: '<optional>',
                                CcDescriptor: '<optional>',
                                DvbNitSettings: [
                                  {
                                    RepInterval: '<optional>',
                                  },
                                ],
                                DvbSdtSettings: [
                                  {
                                    OutputSdt: '<optional>',
                                    RepInterval: '<optional>',
                                    ServiceName: '<optional>',
                                    ServiceProviderName: '<optional>',
                                  },
                                ],
                                DvbSubPids: ['<optional1>', '<optional2>'],
                                DvbTdtSettings: [
                                  {
                                    RepInterval: '<optional>',
                                  },
                                ],
                                DvbTeletextPid: '<optional>',
                                Ebif: '<optional>',
                                EbpAudioInterval: '<optional>',
                                EbpLookaheadMs: ['<optional1>', '<optional2>'],
                                EbpPlacement: '<optional>',
                                EcmPid: '<optional>',
                                EsRateInPes: ['<optional1>', '<optional2>'],
                                EtvPlatformPid: '<optional>',
                                EtvSignalPid: '<optional>',
                                FragmentTime: '<optional>',
                                Klv: '<optional>',
                                KlvDataPids: ['<optional1>', '<optional2>'],
                                NullPacketBitrate: '<optional>',
                                PatInterval: '<optional>',
                                PcrControl: '<optional>',
                                PcrPeriod: '<optional>',
                                PcrPid: '<optional>',
                                PmtInterval: '<optional>',
                                PmtPid: '<optional>',
                                ProgramNum: '<optional>',
                                RateMode: '<optional>',
                                Scte27Pids: ['<optional1>', '<optional2>'],
                                Scte35Control: '<optional>',
                                Scte35Pid: '<optional>',
                                SegmentationMarkers: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                SegmentationStyle: '<optional>',
                                SegmentationTime: '<optional>',
                                TimedMetadataBehavior: '<optional>',
                                TimedMetadataPid: '<optional>',
                                TransportStreamId: '<optional>',
                                VideoPid: '<optional>',
                              },
                            ],
                          },
                        ],
                        Destination: {
                          DestinationRefId: '<optional>',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        TimecodeConfig: {
          SyncThreshold: '<optional>',
        },
        VideoDescriptions: [
          {
            CodecSettings: [
              {
                FrameCaptureSettings: ['<optional1>', '<optional2>'],
                H264Settings: [
                  {
                    AdaptiveQuantization: '<optional>',
                    AfdSignaling: '<optional>',
                    Bitrate: '<optional>',
                    BufFillPct: '<optional>',
                    BufSize: '<optional>',
                    ColorMetadata: ['<optional1>', '<optional2>'],
                    EntropyEncoding: '<optional>',
                    FixedAfd: '<optional>',
                    FlickerAq: '<optional>',
                    FramerateControl: '<optional>',
                    FramerateDenominator: '<optional>',
                    FramerateNumerator: '<optional>',
                    GopBReference: '<optional>',
                    GopClosedCadence: '<optional>',
                    GopNumBFrames: ['<optional1>', '<optional2>'],
                    GopSize: '<optional>',
                    GopSizeUnits: ['<optional1>', '<optional2>'],
                    Level: '<optional>',
                    LookAheadRateControl: '<optional>',
                    MaxBitrate: '<optional>',
                    MinIInterval: '<optional>',
                    NumRefFrames: ['<optional1>', '<optional2>'],
                    ParControl: '<optional>',
                    ParDenominator: '<optional>',
                    ParNumerator: '<optional>',
                    Profile: '<optional>',
                    QvbrQualityLevel: '<optional>',
                    RateControlMode: '<optional>',
                    ScanType: '<optional>',
                    SceneChangeDetect: '<optional>',
                    Slices: ['<optional1>', '<optional2>'],
                    Softness: '<optional>',
                    SpatialAq: '<optional>',
                    SubgopLength: '<optional>',
                    Syntax: '<optional>',
                    TemporalAq: '<optional>',
                    TimecodeInsertion: '<optional>',
                  },
                ],
              },
            ],
            Height: '<optional>',
            RespondToAfd: '<optional>',
            ScalingBehavior: '<optional>',
            Sharpness: '<optional>',
            Width: '<optional>',
          },
        ],
      },
    ],
    InputAttachments: [
      {
        InputAttachmentName: '<optional>',
        InputId: '<optional>',
        InputSettings: [
          {
            AudioSelectors: [
              {
                SelectorSettings: [
                  {
                    AudioLanguageSelection: {
                      LanguageSelectionPolicy: '<optional>',
                    },
                    AudioPidSelection: '<optional>',
                  },
                ],
              },
            ],
            CaptionSelectors: [
              {
                LanguageCode: '<optional>',
                SelectorSettings: [
                  {
                    AribSourceSettings: ['<optional1>', '<optional2>'],
                    DvbSubSourceSettings: [
                      {
                        Pid: '<optional>',
                      },
                    ],
                    EmbeddedSourceSettings: [
                      {
                        Convert608To708: '<optional>',
                        Scte20Detection: '<optional>',
                        Source608ChannelNumber: '<optional>',
                        Source608TrackNumber: '<optional>',
                      },
                    ],
                    Scte20SourceSettings: [
                      {
                        Convert608To708: '<optional>',
                        Source608ChannelNumber: '<optional>',
                      },
                    ],
                    Scte27SourceSettings: [
                      {
                        Pid: '<optional>',
                      },
                    ],
                    TeletextSourceSettings: [
                      {
                        PageNumber: '<optional>',
                      },
                    ],
                  },
                ],
              },
            ],
            DeblockFilter: '<optional>',
            DenoiseFilter: '<optional>',
            FilterStrength: '<optional>',
            InputFilter: '<optional>',
            NetworkInputSettings: [
              {
                HlsInputSettings: [
                  {
                    Bandwidth: '<optional>',
                    BufferSegments: ['<optional1>', '<optional2>'],
                    Retries: ['<optional1>', '<optional2>'],
                    RetryInterval: '<optional>',
                  },
                ],
                ServerValidation: '<optional>',
              },
            ],
            SourceEndBehavior: '<optional>',
            VideoSelector: {
              ColorSpace: '<optional>',
              ColorSpaceUsage: '<optional>',
              SelectorSettings: [
                {
                  VideoSelectorPid: {
                    Pid: '<optional>',
                  },
                  VideoSelectorProgramId: {
                    ProgramId: '<optional>',
                  },
                },
              ],
            },
          },
        ],
      },
    ],
    ChannelClass: '<optional>',
    Destinations: [
      {
        Id: '<optional>',
        MediaPackageSettings: [
          {
            ChannelId: '<optional>',
          },
        ],
        Settings: [
          {
            PasswordParam: '<optional>',
            StreamName: '<optional>',
            Url: '<optional>',
            Username: '<optional>',
          },
        ],
      },
    ],
    InputSpecification: {
      Codec: '<optional>',
      MaximumBitrate: '<optional>',
      Resolution: '<optional>',
    },
    LogLevel: '<optional>',
    Name: '<optional>',
    RequestId: '<optional>',
    Reserved: '<optional>',
    RoleArn: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MediaLive:createInput': {
    Vpc: {
      SecurityGroupIds: ['<optional1>', '<optional2>'],
    },
    Destinations: [
      {
        StreamName: '<optional>',
      },
    ],
    InputSecurityGroups: ['<optional1>', '<optional2>'],
    MediaConnectFlows: [
      {
        FlowArn: '<optional>',
      },
    ],
    Name: '<optional>',
    RequestId: '<optional>',
    RoleArn: '<optional>',
    Sources: [
      {
        PasswordParam: '<optional>',
        Url: '<optional>',
        Username: '<optional>',
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
    Type: '<optional>',
  },
  'MediaLive:createInputSecurityGroup': {
    Tags: ['<optional1>', '<optional2>'],
    WhitelistRules: [
      {
        Cidr: '<optional>',
      },
    ],
  },
  'MediaLive:createMultiplex': {
    RequestId: 'required',
    MultiplexSettings: [
      {
        MaximumVideoBufferDelayMilliseconds: ['<optional1>', '<optional2>'],
        TransportStreamReservedBitrate: '<optional>',
      },
    ],
    AvailabilityZones: ['required1', 'required2'],
    Name: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MediaLive:createMultiplexProgram': {
    MultiplexId: 'required',
    RequestId: 'required',
    MultiplexProgramSettings: [
      {
        ServiceDescriptor: '<optional>',
        VideoSettings: [
          {
            ConstantBitrate: '<optional>',
            StatmuxSettings: [
              {
                MaximumBitrate: '<optional>',
                MinimumBitrate: '<optional>',
              },
            ],
          },
        ],
      },
    ],
    ProgramName: 'required',
  },
  'MediaLive:createTags': {
    ResourceArn: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MediaLive:deleteChannel': {
    ChannelId: 'required',
  },
  'MediaLive:deleteInput': {
    InputId: 'required',
  },
  'MediaLive:deleteInputSecurityGroup': {
    InputSecurityGroupId: 'required',
  },
  'MediaLive:deleteMultiplex': {
    MultiplexId: 'required',
  },
  'MediaLive:deleteMultiplexProgram': {
    MultiplexId: 'required',
    ProgramName: 'required',
  },
  'MediaLive:deleteReservation': {
    ReservationId: 'required',
  },
  'MediaLive:deleteSchedule': {
    ChannelId: 'required',
  },
  'MediaLive:deleteTags': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'MediaLive:describeChannel': {
    ChannelId: 'required',
  },
  'MediaLive:describeInput': {
    InputId: 'required',
  },
  'MediaLive:describeInputSecurityGroup': {
    InputSecurityGroupId: 'required',
  },
  'MediaLive:describeMultiplex': {
    MultiplexId: 'required',
  },
  'MediaLive:describeMultiplexProgram': {
    MultiplexId: 'required',
    ProgramName: 'required',
  },
  'MediaLive:describeOffering': {
    OfferingId: 'required',
  },
  'MediaLive:describeReservation': {
    ReservationId: 'required',
  },
  'MediaLive:describeSchedule': {
    ChannelId: 'required',
  },
  'MediaLive:listChannels': {},
  'MediaLive:listInputSecurityGroups': {},
  'MediaLive:listInputs': {},
  'MediaLive:listMultiplexPrograms': {
    MultiplexId: 'required',
  },
  'MediaLive:listMultiplexes': {},
  'MediaLive:listOfferings': {
    ChannelClass: '<optional>',
    ChannelConfiguration: '<optional>',
    Codec: '<optional>',
    MaximumBitrate: '<optional>',
    MaximumFramerate: '<optional>',
    Resolution: '<optional>',
    ResourceType: '<optional>',
    SpecialFeature: '<optional>',
    VideoQuality: '<optional>',
  },
  'MediaLive:listReservations': {
    ChannelClass: '<optional>',
    Codec: '<optional>',
    MaximumBitrate: '<optional>',
    MaximumFramerate: '<optional>',
    Resolution: '<optional>',
    ResourceType: '<optional>',
    SpecialFeature: '<optional>',
    VideoQuality: '<optional>',
  },
  'MediaLive:listTagsForResource': {
    ResourceArn: 'required',
  },
  'MediaLive:purchaseOffering': {
    OfferingId: 'required',
    Count: 'required',
    Name: '<optional>',
    RequestId: '<optional>',
    Start: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MediaLive:startChannel': {
    ChannelId: 'required',
  },
  'MediaLive:startMultiplex': {
    MultiplexId: 'required',
  },
  'MediaLive:stopChannel': {
    ChannelId: 'required',
  },
  'MediaLive:stopMultiplex': {
    MultiplexId: 'required',
  },
  'MediaLive:updateChannel': {
    ChannelId: 'required',
    EncoderSettings: [
      {
        AvailBlanking: {
          AvailBlankingImage: {
            PasswordParam: '<optional>',
            Username: '<optional>',
          },
          State: '<optional>',
        },
        AvailConfiguration: {
          AvailSettings: [
            {
              Scte35SpliceInsert: {
                AdAvailOffset: '<optional>',
                NoRegionalBlackoutFlag: '<optional>',
                WebDeliveryAllowedFlag: '<optional>',
              },
              Scte35TimeSignalApos: [
                {
                  AdAvailOffset: '<optional>',
                  NoRegionalBlackoutFlag: '<optional>',
                  WebDeliveryAllowedFlag: '<optional>',
                },
              ],
            },
          ],
        },
        BlackoutSlate: {
          BlackoutSlateImage: {
            PasswordParam: '<optional>',
            Username: '<optional>',
          },
          NetworkEndBlackout: '<optional>',
          NetworkEndBlackoutImage: {
            PasswordParam: '<optional>',
            Username: '<optional>',
          },
          NetworkId: '<optional>',
          State: '<optional>',
        },
        CaptionDescriptions: [
          {
            DestinationSettings: [
              {
                AribDestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
                BurnInDestinationSettings: [
                  {
                    Alignment: '<optional>',
                    BackgroundColor: '<optional>',
                    BackgroundOpacity: '<optional>',
                    Font: {
                      PasswordParam: '<optional>',
                      Username: '<optional>',
                    },
                    FontColor: '<optional>',
                    FontOpacity: '<optional>',
                    FontResolution: '<optional>',
                    FontSize: '<optional>',
                    OutlineColor: '<optional>',
                    OutlineSize: '<optional>',
                    ShadowColor: '<optional>',
                    ShadowOpacity: '<optional>',
                    ShadowXOffset: '<optional>',
                    ShadowYOffset: '<optional>',
                    TeletextGridControl: '<optional>',
                    XPosition: '<optional>',
                    YPosition: '<optional>',
                  },
                ],
                DvbSubDestinationSettings: [
                  {
                    Alignment: '<optional>',
                    BackgroundColor: '<optional>',
                    BackgroundOpacity: '<optional>',
                    Font: {
                      PasswordParam: '<optional>',
                      Username: '<optional>',
                    },
                    FontColor: '<optional>',
                    FontOpacity: '<optional>',
                    FontResolution: '<optional>',
                    FontSize: '<optional>',
                    OutlineColor: '<optional>',
                    OutlineSize: '<optional>',
                    ShadowColor: '<optional>',
                    ShadowOpacity: '<optional>',
                    ShadowXOffset: '<optional>',
                    ShadowYOffset: '<optional>',
                    TeletextGridControl: '<optional>',
                    XPosition: '<optional>',
                    YPosition: '<optional>',
                  },
                ],
                EmbeddedDestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
                EmbeddedPlusScte20DestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
                RtmpCaptionInfoDestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
                Scte20PlusEmbeddedDestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
                Scte27DestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
                SmpteTtDestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
                TeletextDestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
                TtmlDestinationSettings: [
                  {
                    StyleControl: '<optional>',
                  },
                ],
                WebvttDestinationSettings: [
                  {
                    type: '<optional>',
                  },
                ],
              },
            ],
            LanguageCode: '<optional>',
            LanguageDescription: '<optional>',
          },
        ],
        GlobalConfiguration: {
          InitialAudioGain: '<optional>',
          InputEndAction: '<optional>',
          InputLossBehavior: {
            BlackFrameMsec: '<optional>',
            InputLossImageColor: '<optional>',
            InputLossImageSlate: {
              PasswordParam: '<optional>',
              Username: '<optional>',
            },
            InputLossImageType: '<optional>',
            RepeatFrameMsec: '<optional>',
          },
          OutputLockingMode: '<optional>',
          OutputTimingSource: '<optional>',
          SupportLowFramerateInputs: ['<optional1>', '<optional2>'],
        },
        AudioDescriptions: [
          {
            AudioNormalizationSettings: [
              {
                Algorithm: '<optional>',
                AlgorithmControl: '<optional>',
                TargetLkfs: ['<optional1>', '<optional2>'],
              },
            ],
            AudioType: '<optional>',
            AudioTypeControl: '<optional>',
            CodecSettings: [
              {
                AacSettings: [
                  {
                    Bitrate: '<optional>',
                    CodingMode: '<optional>',
                    InputType: '<optional>',
                    Profile: '<optional>',
                    RateControlMode: '<optional>',
                    RawFormat: '<optional>',
                    SampleRate: '<optional>',
                    Spec: '<optional>',
                    VbrQuality: '<optional>',
                  },
                ],
                Ac3Settings: [
                  {
                    Bitrate: '<optional>',
                    BitstreamMode: '<optional>',
                    CodingMode: '<optional>',
                    Dialnorm: '<optional>',
                    DrcProfile: '<optional>',
                    LfeFilter: '<optional>',
                    MetadataControl: '<optional>',
                  },
                ],
                Eac3Settings: [
                  {
                    AttenuationControl: '<optional>',
                    Bitrate: '<optional>',
                    BitstreamMode: '<optional>',
                    CodingMode: '<optional>',
                    DcFilter: '<optional>',
                    Dialnorm: '<optional>',
                    DrcLine: '<optional>',
                    DrcRf: '<optional>',
                    LfeControl: '<optional>',
                    LfeFilter: '<optional>',
                    LoRoCenterMixLevel: '<optional>',
                    LoRoSurroundMixLevel: '<optional>',
                    LtRtCenterMixLevel: '<optional>',
                    LtRtSurroundMixLevel: '<optional>',
                    MetadataControl: '<optional>',
                    PassthroughControl: '<optional>',
                    PhaseControl: '<optional>',
                    StereoDownmix: '<optional>',
                    SurroundExMode: '<optional>',
                    SurroundMode: '<optional>',
                  },
                ],
                Mp2Settings: [
                  {
                    Bitrate: '<optional>',
                    CodingMode: '<optional>',
                    SampleRate: '<optional>',
                  },
                ],
                PassThroughSettings: [
                  {
                    type: '<optional>',
                  },
                ],
              },
            ],
            LanguageCode: '<optional>',
            LanguageCodeControl: '<optional>',
            RemixSettings: [
              {
                ChannelsIn: '<optional>',
                ChannelsOut: '<optional>',
              },
            ],
            StreamName: '<optional>',
          },
        ],
        OutputGroups: [
          {
            Name: '<optional>',
            OutputGroupSettings: [
              {
                ArchiveGroupSettings: [
                  {
                    RolloverInterval: '<optional>',
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                FrameCaptureGroupSettings: [
                  {
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                HlsGroupSettings: [
                  {
                    AdMarkers: ['<optional1>', '<optional2>'],
                    BaseUrlContent: '<optional>',
                    BaseUrlManifest: '<optional>',
                    CaptionLanguageMappings: ['<optional1>', '<optional2>'],
                    CaptionLanguageSetting: '<optional>',
                    ClientCache: '<optional>',
                    CodecSpecification: '<optional>',
                    ConstantIv: '<optional>',
                    DirectoryStructure: '<optional>',
                    EncryptionType: '<optional>',
                    HlsCdnSettings: [
                      {
                        HlsAkamaiSettings: [
                          {
                            ConnectionRetryInterval: '<optional>',
                            FilecacheDuration: '<optional>',
                            HttpTransferMode: '<optional>',
                            NumRetries: ['<optional1>', '<optional2>'],
                            RestartDelay: '<optional>',
                            Salt: '<optional>',
                            Token: '<optional>',
                          },
                        ],
                        HlsBasicPutSettings: [
                          {
                            ConnectionRetryInterval: '<optional>',
                            FilecacheDuration: '<optional>',
                            NumRetries: ['<optional1>', '<optional2>'],
                            RestartDelay: '<optional>',
                          },
                        ],
                        HlsMediaStoreSettings: [
                          {
                            ConnectionRetryInterval: '<optional>',
                            FilecacheDuration: '<optional>',
                            MediaStoreStorageClass: '<optional>',
                            NumRetries: ['<optional1>', '<optional2>'],
                            RestartDelay: '<optional>',
                          },
                        ],
                        HlsWebdavSettings: [
                          {
                            ConnectionRetryInterval: '<optional>',
                            FilecacheDuration: '<optional>',
                            HttpTransferMode: '<optional>',
                            NumRetries: ['<optional1>', '<optional2>'],
                            RestartDelay: '<optional>',
                          },
                        ],
                      },
                    ],
                    IFrameOnlyPlaylists: ['<optional1>', '<optional2>'],
                    IndexNSegments: ['<optional1>', '<optional2>'],
                    InputLossAction: '<optional>',
                    IvInManifest: '<optional>',
                    IvSource: '<optional>',
                    KeepSegments: ['<optional1>', '<optional2>'],
                    KeyFormat: '<optional>',
                    KeyFormatVersions: ['<optional1>', '<optional2>'],
                    KeyProviderSettings: [
                      {
                        StaticKeySettings: [
                          {
                            KeyProviderServer: {
                              PasswordParam: '<optional>',
                              Username: '<optional>',
                            },
                          },
                        ],
                      },
                    ],
                    ManifestCompression: '<optional>',
                    ManifestDurationFormat: '<optional>',
                    MinSegmentLength: '<optional>',
                    Mode: '<optional>',
                    OutputSelection: '<optional>',
                    ProgramDateTime: '<optional>',
                    ProgramDateTimePeriod: '<optional>',
                    RedundantManifest: '<optional>',
                    SegmentLength: '<optional>',
                    SegmentationMode: '<optional>',
                    SegmentsPerSubdirectory: '<optional>',
                    StreamInfResolution: '<optional>',
                    TimedMetadataId3Frame: '<optional>',
                    TimedMetadataId3Period: '<optional>',
                    TimestampDeltaMilliseconds: ['<optional1>', '<optional2>'],
                    TsFileMode: '<optional>',
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                MediaPackageGroupSettings: [
                  {
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                MsSmoothGroupSettings: [
                  {
                    AcquisitionPointId: '<optional>',
                    AudioOnlyTimecodeControl: '<optional>',
                    CertificateMode: '<optional>',
                    ConnectionRetryInterval: '<optional>',
                    EventId: '<optional>',
                    EventIdMode: '<optional>',
                    EventStopBehavior: '<optional>',
                    FilecacheDuration: '<optional>',
                    FragmentLength: '<optional>',
                    InputLossAction: '<optional>',
                    NumRetries: ['<optional1>', '<optional2>'],
                    RestartDelay: '<optional>',
                    SegmentationMode: '<optional>',
                    SendDelayMs: ['<optional1>', '<optional2>'],
                    SparseTrackType: '<optional>',
                    StreamManifestBehavior: '<optional>',
                    TimestampOffset: '<optional>',
                    TimestampOffsetMode: '<optional>',
                    Destination: {
                      DestinationRefId: '<optional>',
                    },
                  },
                ],
                RtmpGroupSettings: [
                  {
                    AuthenticationScheme: '<optional>',
                    CacheFullBehavior: '<optional>',
                    CacheLength: '<optional>',
                    CaptionData: ['<optional1>', '<optional2>'],
                    InputLossAction: '<optional>',
                    RestartDelay: '<optional>',
                  },
                ],
                UdpGroupSettings: [
                  {
                    InputLossAction: '<optional>',
                    TimedMetadataId3Frame: '<optional>',
                    TimedMetadataId3Period: '<optional>',
                  },
                ],
              },
            ],
            Outputs: [
              {
                AudioDescriptionNames: ['<optional1>', '<optional2>'],
                CaptionDescriptionNames: ['<optional1>', '<optional2>'],
                OutputName: '<optional>',
                VideoDescriptionName: '<optional>',
                OutputSettings: [
                  {
                    ArchiveOutputSettings: [
                      {
                        Extension: '<optional>',
                        NameModifier: '<optional>',
                        ContainerSettings: [
                          {
                            M2tsSettings: [
                              {
                                AbsentInputAudioBehavior: '<optional>',
                                Arib: '<optional>',
                                AribCaptionsPid: '<optional>',
                                AribCaptionsPidControl: '<optional>',
                                AudioBufferModel: '<optional>',
                                AudioFramesPerPes: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                AudioPids: ['<optional1>', '<optional2>'],
                                AudioStreamType: '<optional>',
                                Bitrate: '<optional>',
                                BufferModel: '<optional>',
                                CcDescriptor: '<optional>',
                                DvbNitSettings: [
                                  {
                                    RepInterval: '<optional>',
                                  },
                                ],
                                DvbSdtSettings: [
                                  {
                                    OutputSdt: '<optional>',
                                    RepInterval: '<optional>',
                                    ServiceName: '<optional>',
                                    ServiceProviderName: '<optional>',
                                  },
                                ],
                                DvbSubPids: ['<optional1>', '<optional2>'],
                                DvbTdtSettings: [
                                  {
                                    RepInterval: '<optional>',
                                  },
                                ],
                                DvbTeletextPid: '<optional>',
                                Ebif: '<optional>',
                                EbpAudioInterval: '<optional>',
                                EbpLookaheadMs: ['<optional1>', '<optional2>'],
                                EbpPlacement: '<optional>',
                                EcmPid: '<optional>',
                                EsRateInPes: ['<optional1>', '<optional2>'],
                                EtvPlatformPid: '<optional>',
                                EtvSignalPid: '<optional>',
                                FragmentTime: '<optional>',
                                Klv: '<optional>',
                                KlvDataPids: ['<optional1>', '<optional2>'],
                                NullPacketBitrate: '<optional>',
                                PatInterval: '<optional>',
                                PcrControl: '<optional>',
                                PcrPeriod: '<optional>',
                                PcrPid: '<optional>',
                                PmtInterval: '<optional>',
                                PmtPid: '<optional>',
                                ProgramNum: '<optional>',
                                RateMode: '<optional>',
                                Scte27Pids: ['<optional1>', '<optional2>'],
                                Scte35Control: '<optional>',
                                Scte35Pid: '<optional>',
                                SegmentationMarkers: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                SegmentationStyle: '<optional>',
                                SegmentationTime: '<optional>',
                                TimedMetadataBehavior: '<optional>',
                                TimedMetadataPid: '<optional>',
                                TransportStreamId: '<optional>',
                                VideoPid: '<optional>',
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    FrameCaptureOutputSettings: [
                      {
                        NameModifier: '<optional>',
                      },
                    ],
                    HlsOutputSettings: [
                      {
                        NameModifier: '<optional>',
                        SegmentModifier: '<optional>',
                        HlsSettings: [
                          {
                            AudioOnlyHlsSettings: [
                              {
                                AudioGroupId: '<optional>',
                                AudioOnlyImage: {
                                  PasswordParam: '<optional>',
                                  Username: '<optional>',
                                },
                                AudioTrackType: '<optional>',
                              },
                            ],
                            StandardHlsSettings: [
                              {
                                AudioRenditionSets: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                M3u8Settings: [
                                  {
                                    AudioFramesPerPes: [
                                      '<optional1>',
                                      '<optional2>',
                                    ],
                                    AudioPids: ['<optional1>', '<optional2>'],
                                    EcmPid: '<optional>',
                                    PatInterval: '<optional>',
                                    PcrControl: '<optional>',
                                    PcrPeriod: '<optional>',
                                    PcrPid: '<optional>',
                                    PmtInterval: '<optional>',
                                    PmtPid: '<optional>',
                                    ProgramNum: '<optional>',
                                    Scte35Behavior: '<optional>',
                                    Scte35Pid: '<optional>',
                                    TimedMetadataBehavior: '<optional>',
                                    TimedMetadataPid: '<optional>',
                                    TransportStreamId: '<optional>',
                                    VideoPid: '<optional>',
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    MediaPackageOutputSettings: [
                      {
                        type: '<optional>',
                      },
                    ],
                    MsSmoothOutputSettings: [
                      {
                        NameModifier: '<optional>',
                      },
                    ],
                    RtmpOutputSettings: [
                      {
                        CertificateMode: '<optional>',
                        ConnectionRetryInterval: '<optional>',
                        NumRetries: ['<optional1>', '<optional2>'],
                        Destination: {
                          DestinationRefId: '<optional>',
                        },
                      },
                    ],
                    UdpOutputSettings: [
                      {
                        BufferMsec: '<optional>',
                        FecOutputSettings: [
                          {
                            ColumnDepth: '<optional>',
                            IncludeFec: '<optional>',
                            RowLength: '<optional>',
                          },
                        ],
                        ContainerSettings: [
                          {
                            M2tsSettings: [
                              {
                                AbsentInputAudioBehavior: '<optional>',
                                Arib: '<optional>',
                                AribCaptionsPid: '<optional>',
                                AribCaptionsPidControl: '<optional>',
                                AudioBufferModel: '<optional>',
                                AudioFramesPerPes: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                AudioPids: ['<optional1>', '<optional2>'],
                                AudioStreamType: '<optional>',
                                Bitrate: '<optional>',
                                BufferModel: '<optional>',
                                CcDescriptor: '<optional>',
                                DvbNitSettings: [
                                  {
                                    RepInterval: '<optional>',
                                  },
                                ],
                                DvbSdtSettings: [
                                  {
                                    OutputSdt: '<optional>',
                                    RepInterval: '<optional>',
                                    ServiceName: '<optional>',
                                    ServiceProviderName: '<optional>',
                                  },
                                ],
                                DvbSubPids: ['<optional1>', '<optional2>'],
                                DvbTdtSettings: [
                                  {
                                    RepInterval: '<optional>',
                                  },
                                ],
                                DvbTeletextPid: '<optional>',
                                Ebif: '<optional>',
                                EbpAudioInterval: '<optional>',
                                EbpLookaheadMs: ['<optional1>', '<optional2>'],
                                EbpPlacement: '<optional>',
                                EcmPid: '<optional>',
                                EsRateInPes: ['<optional1>', '<optional2>'],
                                EtvPlatformPid: '<optional>',
                                EtvSignalPid: '<optional>',
                                FragmentTime: '<optional>',
                                Klv: '<optional>',
                                KlvDataPids: ['<optional1>', '<optional2>'],
                                NullPacketBitrate: '<optional>',
                                PatInterval: '<optional>',
                                PcrControl: '<optional>',
                                PcrPeriod: '<optional>',
                                PcrPid: '<optional>',
                                PmtInterval: '<optional>',
                                PmtPid: '<optional>',
                                ProgramNum: '<optional>',
                                RateMode: '<optional>',
                                Scte27Pids: ['<optional1>', '<optional2>'],
                                Scte35Control: '<optional>',
                                Scte35Pid: '<optional>',
                                SegmentationMarkers: [
                                  '<optional1>',
                                  '<optional2>',
                                ],
                                SegmentationStyle: '<optional>',
                                SegmentationTime: '<optional>',
                                TimedMetadataBehavior: '<optional>',
                                TimedMetadataPid: '<optional>',
                                TransportStreamId: '<optional>',
                                VideoPid: '<optional>',
                              },
                            ],
                          },
                        ],
                        Destination: {
                          DestinationRefId: '<optional>',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        TimecodeConfig: {
          SyncThreshold: '<optional>',
        },
        VideoDescriptions: [
          {
            CodecSettings: [
              {
                FrameCaptureSettings: ['<optional1>', '<optional2>'],
                H264Settings: [
                  {
                    AdaptiveQuantization: '<optional>',
                    AfdSignaling: '<optional>',
                    Bitrate: '<optional>',
                    BufFillPct: '<optional>',
                    BufSize: '<optional>',
                    ColorMetadata: ['<optional1>', '<optional2>'],
                    EntropyEncoding: '<optional>',
                    FixedAfd: '<optional>',
                    FlickerAq: '<optional>',
                    FramerateControl: '<optional>',
                    FramerateDenominator: '<optional>',
                    FramerateNumerator: '<optional>',
                    GopBReference: '<optional>',
                    GopClosedCadence: '<optional>',
                    GopNumBFrames: ['<optional1>', '<optional2>'],
                    GopSize: '<optional>',
                    GopSizeUnits: ['<optional1>', '<optional2>'],
                    Level: '<optional>',
                    LookAheadRateControl: '<optional>',
                    MaxBitrate: '<optional>',
                    MinIInterval: '<optional>',
                    NumRefFrames: ['<optional1>', '<optional2>'],
                    ParControl: '<optional>',
                    ParDenominator: '<optional>',
                    ParNumerator: '<optional>',
                    Profile: '<optional>',
                    QvbrQualityLevel: '<optional>',
                    RateControlMode: '<optional>',
                    ScanType: '<optional>',
                    SceneChangeDetect: '<optional>',
                    Slices: ['<optional1>', '<optional2>'],
                    Softness: '<optional>',
                    SpatialAq: '<optional>',
                    SubgopLength: '<optional>',
                    Syntax: '<optional>',
                    TemporalAq: '<optional>',
                    TimecodeInsertion: '<optional>',
                  },
                ],
              },
            ],
            Height: '<optional>',
            RespondToAfd: '<optional>',
            ScalingBehavior: '<optional>',
            Sharpness: '<optional>',
            Width: '<optional>',
          },
        ],
      },
    ],
    InputAttachments: [
      {
        InputAttachmentName: '<optional>',
        InputId: '<optional>',
        InputSettings: [
          {
            AudioSelectors: [
              {
                SelectorSettings: [
                  {
                    AudioLanguageSelection: {
                      LanguageSelectionPolicy: '<optional>',
                    },
                    AudioPidSelection: '<optional>',
                  },
                ],
              },
            ],
            CaptionSelectors: [
              {
                LanguageCode: '<optional>',
                SelectorSettings: [
                  {
                    AribSourceSettings: [
                      {
                        type: '<optional>',
                      },
                    ],
                    DvbSubSourceSettings: [
                      {
                        Pid: '<optional>',
                      },
                    ],
                    EmbeddedSourceSettings: [
                      {
                        Convert608To708: '<optional>',
                        Scte20Detection: '<optional>',
                        Source608ChannelNumber: '<optional>',
                        Source608TrackNumber: '<optional>',
                      },
                    ],
                    Scte20SourceSettings: [
                      {
                        Convert608To708: '<optional>',
                        Source608ChannelNumber: '<optional>',
                      },
                    ],
                    Scte27SourceSettings: [
                      {
                        Pid: '<optional>',
                      },
                    ],
                    TeletextSourceSettings: [
                      {
                        PageNumber: '<optional>',
                      },
                    ],
                  },
                ],
              },
            ],
            DeblockFilter: '<optional>',
            DenoiseFilter: '<optional>',
            FilterStrength: '<optional>',
            InputFilter: '<optional>',
            NetworkInputSettings: [
              {
                HlsInputSettings: [
                  {
                    Bandwidth: '<optional>',
                    BufferSegments: ['<optional1>', '<optional2>'],
                    Retries: ['<optional1>', '<optional2>'],
                    RetryInterval: '<optional>',
                  },
                ],
                ServerValidation: '<optional>',
              },
            ],
            SourceEndBehavior: '<optional>',
            VideoSelector: {
              ColorSpace: '<optional>',
              ColorSpaceUsage: '<optional>',
              SelectorSettings: [
                {
                  VideoSelectorPid: {
                    Pid: '<optional>',
                  },
                  VideoSelectorProgramId: {
                    ProgramId: '<optional>',
                  },
                },
              ],
            },
          },
        ],
      },
    ],
    Destinations: [
      {
        Id: '<optional>',
        MediaPackageSettings: [
          {
            ChannelId: '<optional>',
          },
        ],
        Settings: [
          {
            PasswordParam: '<optional>',
            StreamName: '<optional>',
            Url: '<optional>',
            Username: '<optional>',
          },
        ],
      },
    ],
    InputSpecification: {
      Codec: '<optional>',
      MaximumBitrate: '<optional>',
      Resolution: '<optional>',
    },
    LogLevel: '<optional>',
    Name: '<optional>',
    RoleArn: '<optional>',
  },
  'MediaLive:updateChannelClass': {
    ChannelId: 'required',
    ChannelClass: 'required',
    Destinations: [
      {
        Id: '<optional>',
        MediaPackageSettings: [
          {
            ChannelId: '<optional>',
          },
        ],
        Settings: [
          {
            PasswordParam: '<optional>',
            StreamName: '<optional>',
            Url: '<optional>',
            Username: '<optional>',
          },
        ],
      },
    ],
  },
  'MediaLive:updateInput': {
    InputId: 'required',
    Destinations: [
      {
        StreamName: '<optional>',
      },
    ],
    InputSecurityGroups: ['<optional1>', '<optional2>'],
    MediaConnectFlows: [
      {
        FlowArn: '<optional>',
      },
    ],
    Name: '<optional>',
    RoleArn: '<optional>',
    Sources: [
      {
        PasswordParam: '<optional>',
        Url: '<optional>',
        Username: '<optional>',
      },
    ],
  },
  'MediaLive:updateInputSecurityGroup': {
    InputSecurityGroupId: 'required',
    Tags: ['<optional1>', '<optional2>'],
    WhitelistRules: [
      {
        Cidr: '<optional>',
      },
    ],
  },
  'MediaLive:updateMultiplex': {
    MultiplexId: 'required',
    MultiplexSettings: [
      {
        MaximumVideoBufferDelayMilliseconds: ['<optional1>', '<optional2>'],
        TransportStreamReservedBitrate: '<optional>',
      },
    ],
    Name: '<optional>',
  },
  'MediaLive:updateMultiplexProgram': {
    MultiplexId: 'required',
    ProgramName: 'required',
    MultiplexProgramSettings: [
      {
        ServiceDescriptor: '<optional>',
        VideoSettings: [
          {
            ConstantBitrate: '<optional>',
            StatmuxSettings: [
              {
                MaximumBitrate: '<optional>',
                MinimumBitrate: '<optional>',
              },
            ],
          },
        ],
      },
    ],
  },
  'MediaLive:updateReservation': {
    ReservationId: 'required',
    Name: '<optional>',
  },
  'MediaPackage:createChannel': {
    Id: 'required',
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'MediaPackage:createHarvestJob': {
    S3Destination: {
      ManifestKey: 'required',
      BucketName: 'required',
      RoleArn: 'required',
    },
    EndTime: 'required',
    OriginEndpointId: 'required',
    StartTime: 'required',
    Id: 'required',
  },
  'MediaPackage:createOriginEndpoint': {
    ChannelId: 'required',
    Id: 'required',
    CmafPackage: {
      Encryption: {
        KeyRotationIntervalSeconds: ['<optional1>', '<optional2>'],
        SpekeKeyProvider: {
          CertificateArn: '<optional>',
        },
      },
      HlsManifests: [
        {
          AdMarkers: ['<optional1>', '<optional2>'],
          AdTriggers: ['<optional1>', '<optional2>'],
          AdsOnDeliveryRestrictions: ['<optional1>', '<optional2>'],
          IncludeIframeOnlyStream: '<optional>',
          ManifestName: '<optional>',
          PlaylistType: '<optional>',
          PlaylistWindowSeconds: ['<optional1>', '<optional2>'],
          ProgramDateTimeIntervalSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      SegmentPrefix: '<optional>',
      StreamSelection: {
        MaxVideoBitsPerSecond: '<optional>',
        MinVideoBitsPerSecond: '<optional>',
        StreamOrder: '<optional>',
      },
    },
    DashPackage: {
      AdTriggers: ['<optional1>', '<optional2>'],
      AdsOnDeliveryRestrictions: ['<optional1>', '<optional2>'],
      Encryption: {
        KeyRotationIntervalSeconds: ['<optional1>', '<optional2>'],
        SpekeKeyProvider: {
          CertificateArn: '<optional>',
        },
      },
      ManifestLayout: '<optional>',
      ManifestWindowSeconds: ['<optional1>', '<optional2>'],
      MinBufferTimeSeconds: ['<optional1>', '<optional2>'],
      MinUpdatePeriodSeconds: ['<optional1>', '<optional2>'],
      PeriodTriggers: ['<optional1>', '<optional2>'],
      Profile: '<optional>',
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      SegmentTemplateFormat: '<optional>',
      StreamSelection: {
        MaxVideoBitsPerSecond: '<optional>',
        MinVideoBitsPerSecond: '<optional>',
        StreamOrder: '<optional>',
      },
      SuggestedPresentationDelaySeconds: ['<optional1>', '<optional2>'],
    },
    HlsPackage: {
      AdMarkers: ['<optional1>', '<optional2>'],
      AdTriggers: ['<optional1>', '<optional2>'],
      AdsOnDeliveryRestrictions: ['<optional1>', '<optional2>'],
      Encryption: {
        ConstantInitializationVector: '<optional>',
        EncryptionMethod: '<optional>',
        KeyRotationIntervalSeconds: ['<optional1>', '<optional2>'],
        RepeatExtXKey: '<optional>',
        SpekeKeyProvider: {
          CertificateArn: '<optional>',
        },
      },
      IncludeIframeOnlyStream: '<optional>',
      PlaylistType: '<optional>',
      PlaylistWindowSeconds: ['<optional1>', '<optional2>'],
      ProgramDateTimeIntervalSeconds: ['<optional1>', '<optional2>'],
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      StreamSelection: {
        MaxVideoBitsPerSecond: '<optional>',
        MinVideoBitsPerSecond: '<optional>',
        StreamOrder: '<optional>',
      },
      UseAudioRenditionGroup: '<optional>',
    },
    MssPackage: {
      Encryption: {
        SpekeKeyProvider: {
          CertificateArn: '<optional>',
        },
      },
      ManifestWindowSeconds: ['<optional1>', '<optional2>'],
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      StreamSelection: {
        MaxVideoBitsPerSecond: '<optional>',
        MinVideoBitsPerSecond: '<optional>',
        StreamOrder: '<optional>',
      },
    },
    Description: '<optional>',
    ManifestName: '<optional>',
    StartoverWindowSeconds: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    TimeDelaySeconds: ['<optional1>', '<optional2>'],
    Whitelist: '<optional>',
  },
  'MediaPackage:deleteChannel': {
    Id: 'required',
  },
  'MediaPackage:deleteOriginEndpoint': {
    Id: 'required',
  },
  'MediaPackage:describeChannel': {
    Id: 'required',
  },
  'MediaPackage:describeHarvestJob': {
    Id: 'required',
  },
  'MediaPackage:describeOriginEndpoint': {
    Id: 'required',
  },
  'MediaPackage:listChannels': {},
  'MediaPackage:listHarvestJobs': {
    IncludeChannelId: '<optional>',
    IncludeStatus: '<optional>',
  },
  'MediaPackage:listOriginEndpoints': {
    ChannelId: '<optional>',
  },
  'MediaPackage:listTagsForResource': {
    ResourceArn: 'required',
  },
  'MediaPackage:rotateChannelCredentials': {
    Id: 'required',
  },
  'MediaPackage:rotateIngestEndpointCredentials': {
    IngestEndpointId: 'required',
    Id: 'required',
  },
  'MediaPackage:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'MediaPackage:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'MediaPackage:updateChannel': {
    Id: 'required',
    Description: '<optional>',
  },
  'MediaPackage:updateOriginEndpoint': {
    Id: 'required',
    CmafPackage: {
      Encryption: {
        KeyRotationIntervalSeconds: ['<optional1>', '<optional2>'],
        SpekeKeyProvider: {
          CertificateArn: '<optional>',
        },
      },
      HlsManifests: [
        {
          AdMarkers: ['<optional1>', '<optional2>'],
          AdTriggers: ['<optional1>', '<optional2>'],
          AdsOnDeliveryRestrictions: ['<optional1>', '<optional2>'],
          IncludeIframeOnlyStream: '<optional>',
          ManifestName: '<optional>',
          PlaylistType: '<optional>',
          PlaylistWindowSeconds: ['<optional1>', '<optional2>'],
          ProgramDateTimeIntervalSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      SegmentPrefix: '<optional>',
      StreamSelection: {
        MaxVideoBitsPerSecond: '<optional>',
        MinVideoBitsPerSecond: '<optional>',
        StreamOrder: '<optional>',
      },
    },
    DashPackage: {
      AdTriggers: ['<optional1>', '<optional2>'],
      AdsOnDeliveryRestrictions: ['<optional1>', '<optional2>'],
      Encryption: {
        KeyRotationIntervalSeconds: ['<optional1>', '<optional2>'],
        SpekeKeyProvider: {
          CertificateArn: '<optional>',
        },
      },
      ManifestLayout: '<optional>',
      ManifestWindowSeconds: ['<optional1>', '<optional2>'],
      MinBufferTimeSeconds: ['<optional1>', '<optional2>'],
      MinUpdatePeriodSeconds: ['<optional1>', '<optional2>'],
      PeriodTriggers: ['<optional1>', '<optional2>'],
      Profile: '<optional>',
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      SegmentTemplateFormat: '<optional>',
      StreamSelection: {
        MaxVideoBitsPerSecond: '<optional>',
        MinVideoBitsPerSecond: '<optional>',
        StreamOrder: '<optional>',
      },
      SuggestedPresentationDelaySeconds: ['<optional1>', '<optional2>'],
    },
    HlsPackage: {
      AdMarkers: ['<optional1>', '<optional2>'],
      AdTriggers: ['<optional1>', '<optional2>'],
      AdsOnDeliveryRestrictions: ['<optional1>', '<optional2>'],
      Encryption: {
        ConstantInitializationVector: '<optional>',
        EncryptionMethod: '<optional>',
        KeyRotationIntervalSeconds: ['<optional1>', '<optional2>'],
        RepeatExtXKey: '<optional>',
        SpekeKeyProvider: {
          CertificateArn: '<optional>',
        },
      },
      IncludeIframeOnlyStream: '<optional>',
      PlaylistType: '<optional>',
      PlaylistWindowSeconds: ['<optional1>', '<optional2>'],
      ProgramDateTimeIntervalSeconds: ['<optional1>', '<optional2>'],
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      StreamSelection: {
        MaxVideoBitsPerSecond: '<optional>',
        MinVideoBitsPerSecond: '<optional>',
        StreamOrder: '<optional>',
      },
      UseAudioRenditionGroup: '<optional>',
    },
    MssPackage: {
      Encryption: {
        SpekeKeyProvider: {
          CertificateArn: '<optional>',
        },
      },
      ManifestWindowSeconds: ['<optional1>', '<optional2>'],
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      StreamSelection: {
        MaxVideoBitsPerSecond: '<optional>',
        MinVideoBitsPerSecond: '<optional>',
        StreamOrder: '<optional>',
      },
    },
    Description: '<optional>',
    ManifestName: '<optional>',
    StartoverWindowSeconds: ['<optional1>', '<optional2>'],
    TimeDelaySeconds: ['<optional1>', '<optional2>'],
    Whitelist: '<optional>',
  },
  'MediaPackageVod:createAsset': {
    SourceArn: 'required',
    Id: 'required',
    PackagingGroupId: 'required',
    SourceRoleArn: 'required',
    ResourceId: '<optional>',
  },
  'MediaPackageVod:createPackagingConfiguration': {
    Id: 'required',
    PackagingGroupId: 'required',
    CmafPackage: {
      Encryption: '<optional>',
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      HlsManifests: [
        {
          AdMarkers: ['<optional1>', '<optional2>'],
          IncludeIframeOnlyStream: '<optional>',
          ManifestName: '<optional>',
          ProgramDateTimeIntervalSeconds: ['<optional1>', '<optional2>'],
          RepeatExtXKey: '<optional>',
          StreamSelection: {
            MaxVideoBitsPerSecond: '<optional>',
            MinVideoBitsPerSecond: '<optional>',
            StreamOrder: '<optional>',
          },
        },
      ],
    },
    DashPackage: {
      Encryption: '<optional>',
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      DashManifests: [
        {
          ManifestName: '<optional>',
          MinBufferTimeSeconds: ['<optional1>', '<optional2>'],
          Profile: '<optional>',
          StreamSelection: {
            MaxVideoBitsPerSecond: '<optional>',
            MinVideoBitsPerSecond: '<optional>',
            StreamOrder: '<optional>',
          },
        },
      ],
    },
    HlsPackage: {
      Encryption: {
        ConstantInitializationVector: '<optional>',
        EncryptionMethod: '<optional>',
      },
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      UseAudioRenditionGroup: '<optional>',
      HlsManifests: [
        {
          AdMarkers: ['<optional1>', '<optional2>'],
          IncludeIframeOnlyStream: '<optional>',
          ManifestName: '<optional>',
          ProgramDateTimeIntervalSeconds: ['<optional1>', '<optional2>'],
          RepeatExtXKey: '<optional>',
          StreamSelection: {
            MaxVideoBitsPerSecond: '<optional>',
            MinVideoBitsPerSecond: '<optional>',
            StreamOrder: '<optional>',
          },
        },
      ],
    },
    MssPackage: {
      Encryption: '<optional>',
      SegmentDurationSeconds: ['<optional1>', '<optional2>'],
      MssManifests: [
        {
          ManifestName: '<optional>',
          StreamSelection: {
            MaxVideoBitsPerSecond: '<optional>',
            MinVideoBitsPerSecond: '<optional>',
            StreamOrder: '<optional>',
          },
        },
      ],
    },
  },
  'MediaPackageVod:createPackagingGroup': {
    Id: 'required',
  },
  'MediaPackageVod:deleteAsset': {
    Id: 'required',
  },
  'MediaPackageVod:deletePackagingConfiguration': {
    Id: 'required',
  },
  'MediaPackageVod:deletePackagingGroup': {
    Id: 'required',
  },
  'MediaPackageVod:describeAsset': {
    Id: 'required',
  },
  'MediaPackageVod:describePackagingConfiguration': {
    Id: 'required',
  },
  'MediaPackageVod:describePackagingGroup': {
    Id: 'required',
  },
  'MediaPackageVod:listAssets': {
    PackagingGroupId: '<optional>',
  },
  'MediaPackageVod:listPackagingConfigurations': {
    PackagingGroupId: '<optional>',
  },
  'MediaPackageVod:listPackagingGroups': {},
  'MediaStore:createContainer': {
    ContainerName: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'MediaStore:deleteContainer': {
    ContainerName: 'required',
  },
  'MediaStore:deleteContainerPolicy': {
    ContainerName: 'required',
  },
  'MediaStore:deleteCorsPolicy': {
    ContainerName: 'required',
  },
  'MediaStore:deleteLifecyclePolicy': {
    ContainerName: 'required',
  },
  'MediaStore:describeContainer': {
    ContainerName: '<optional>',
  },
  'MediaStore:getContainerPolicy': {
    ContainerName: 'required',
  },
  'MediaStore:getCorsPolicy': {
    ContainerName: 'required',
  },
  'MediaStore:getLifecyclePolicy': {
    ContainerName: 'required',
  },
  'MediaStore:listContainers': {},
  'MediaStore:listTagsForResource': {
    Resource: 'required',
  },
  'MediaStore:putContainerPolicy': {
    ContainerName: 'required',
    Policy: 'required',
  },
  'MediaStore:putCorsPolicy': {
    ContainerName: 'required',
    CorsPolicy: {
      AllowedMethods: ['<optional1>', '<optional2>'],
      MaxAgeSeconds: ['<optional1>', '<optional2>'],
      ExposeHeaders: ['<optional1>', '<optional2>'],
    },
  },
  'MediaStore:putLifecyclePolicy': {
    ContainerName: 'required',
    LifecyclePolicy: 'required',
  },
  'MediaStore:startAccessLogging': {
    ContainerName: 'required',
  },
  'MediaStore:stopAccessLogging': {
    ContainerName: 'required',
  },
  'MediaStore:tagResource': {
    Resource: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'MediaStore:untagResource': {
    Resource: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'MediaStoreData:deleteObject': {
    Path: 'required',
  },
  'MediaStoreData:describeObject': {
    Path: 'required',
  },
  'MediaStoreData:getObject': {
    Path: 'required',
    Range: '<optional>',
  },
  'MediaStoreData:listItems': {
    Path: '<optional>',
  },
  'MediaStoreData:putObject': {
    Body: 'required',
    Path: 'required',
    ContentType: '<optional>',
    CacheControl: '<optional>',
    StorageClass: '<optional>',
    UploadAvailability: '<optional>',
  },
  'MediaTailor:deletePlaybackConfiguration': {
    Name: 'required',
  },
  'MediaTailor:getPlaybackConfiguration': {
    Name: 'required',
  },
  'MediaTailor:listPlaybackConfigurations': {},
  'MediaTailor:listTagsForResource': {
    ResourceArn: 'required',
  },
  'MediaTailor:putPlaybackConfiguration': {
    AdDecisionServerUrl: '<optional>',
    CdnConfiguration: {
      AdSegmentUrlPrefix: '<optional>',
      ContentSegmentUrlPrefix: '<optional>',
    },
    DashConfiguration: {
      MpdLocation: '<optional>',
      OriginManifestType: '<optional>',
    },
    Name: '<optional>',
    SlateAdUrl: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
    TranscodeProfileName: '<optional>',
    VideoContentSourceUrl: '<optional>',
  },
  'MediaTailor:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'MediaTailor:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'MigrationHub:associateCreatedArtifact': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
    CreatedArtifact: {
      Description: '<optional>',
    },
  },
  'MigrationHub:associateDiscoveredResource': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
    DiscoveredResource: {
      Description: '<optional>',
    },
  },
  'MigrationHub:createProgressUpdateStream': {
    ProgressUpdateStreamName: 'required',
  },
  'MigrationHub:deleteProgressUpdateStream': {
    ProgressUpdateStreamName: 'required',
  },
  'MigrationHub:describeApplicationState': {
    ApplicationId: 'required',
  },
  'MigrationHub:describeMigrationTask': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
  },
  'MigrationHub:disassociateCreatedArtifact': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
    CreatedArtifactName: 'required',
  },
  'MigrationHub:disassociateDiscoveredResource': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
    ConfigurationId: 'required',
  },
  'MigrationHub:importMigrationTask': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
  },
  'MigrationHub:listCreatedArtifacts': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
  },
  'MigrationHub:listDiscoveredResources': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
  },
  'MigrationHub:listMigrationTasks': {
    ResourceName: '<optional>',
  },
  'MigrationHub:listProgressUpdateStreams': {},
  'MigrationHub:notifyApplicationState': {
    ApplicationId: 'required',
    Status: 'required',
  },
  'MigrationHub:notifyMigrationTaskState': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
    Task: {
      StatusDetail: '<optional>',
      ProgressPercent: '<optional>',
    },
    UpdateDateTime: 'required',
    NextUpdateSeconds: ['required1', 'required2'],
  },
  'MigrationHub:putResourceAttributes': {
    ProgressUpdateStream: 'required',
    MigrationTaskName: 'required',
    ResourceAttributeList: {
      Type: 'required',
      Value: 'required',
    },
  },
  'MigrationHubConfig:createHomeRegionControl': {
    HomeRegion: 'required',
    Target: {
      Id: '<optional>',
    },
  },
  'MigrationHubConfig:describeHomeRegionControls': {
    Target: {
      Id: '<optional>',
    },
    ControlId: '<optional>',
    HomeRegion: '<optional>',
  },
  'MigrationHubConfig:getHomeRegion': {},
  'Mobile:createProject': {
    name: '<optional>',
    region: '<optional>',
    contents: ['<optional1>', '<optional2>'],
    snapshotId: '<optional>',
  },
  'Mobile:deleteProject': {
    projectId: 'required',
  },
  'Mobile:describeBundle': {
    bundleId: 'required',
  },
  'Mobile:describeProject': {
    projectId: 'required',
    syncFromResources: ['<optional1>', '<optional2>'],
  },
  'Mobile:exportBundle': {
    bundleId: 'required',
    projectId: '<optional>',
    platform: '<optional>',
  },
  'Mobile:exportProject': {
    projectId: 'required',
  },
  'Mobile:listBundles': {},
  'Mobile:listProjects': {},
  'Mobile:updateProject': {
    projectId: 'required',
    contents: ['<optional1>', '<optional2>'],
  },
  'MobileAnalytics:putEvents': {
    eventIds: [
      {
        session: {
          id: '<optional>',
          duration: '<optional>',
          startTimestamp: '<optional>',
          stopTimestamp: '<optional>',
        },
        version: '<optional>',
        attributes: ['<optional1>', '<optional2>'],
        metrics: ['<optional1>', '<optional2>'],
      },
    ],
    clientContext: 'required',
    clientContextEncoding: '<optional>',
  },
  'Neptune:addRoleToDBCluster': {
    DBClusterIdentifier: 'required',
    RoleArn: 'required',
  },
  'Neptune:addSourceIdentifierToSubscription': {
    SubscriptionName: 'required',
    SourceIdentifier: 'required',
  },
  'Neptune:addTagsToResource': {
    ResourceName: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:applyPendingMaintenanceAction': {
    ResourceIdentifier: 'required',
    ApplyAction: 'required',
    OptInType: 'required',
  },
  'Neptune:copyDBClusterParameterGroup': {
    SourceDBClusterParameterGroupIdentifier: 'required',
    TargetDBClusterParameterGroupIdentifier: 'required',
    TargetDBClusterParameterGroupDescription: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:copyDBClusterSnapshot': {
    SourceDBClusterSnapshotIdentifier: 'required',
    TargetDBClusterSnapshotIdentifier: 'required',
    KmsKeyId: '<optional>',
    PreSignedUrl: '<optional>',
    CopyTags: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:copyDBParameterGroup': {
    SourceDBParameterGroupIdentifier: 'required',
    TargetDBParameterGroupIdentifier: 'required',
    TargetDBParameterGroupDescription: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:createDBCluster': {
    DBClusterIdentifier: 'required',
    Engine: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    BackupRetentionPeriod: '<optional>',
    CharacterSetName: '<optional>',
    DatabaseName: '<optional>',
    DBClusterParameterGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    DBSubnetGroupName: '<optional>',
    EngineVersion: '<optional>',
    Port: '<optional>',
    MasterUsername: '<optional>',
    MasterUserPassword: '<optional>',
    OptionGroupName: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    ReplicationSourceIdentifier: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    StorageEncrypted: '<optional>',
    KmsKeyId: '<optional>',
    PreSignedUrl: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
  },
  'Neptune:createDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    DBParameterGroupFamily: 'required',
    Description: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:createDBClusterSnapshot': {
    DBClusterSnapshotIdentifier: 'required',
    DBClusterIdentifier: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:createDBInstance': {
    DBInstanceIdentifier: 'required',
    DBInstanceClass: 'required',
    Engine: 'required',
    DBName: '<optional>',
    AllocatedStorage: '<optional>',
    MasterUsername: '<optional>',
    MasterUserPassword: '<optional>',
    DBSecurityGroups: ['<optional1>', '<optional2>'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    AvailabilityZone: '<optional>',
    DBSubnetGroupName: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    DBParameterGroupName: '<optional>',
    BackupRetentionPeriod: '<optional>',
    PreferredBackupWindow: '<optional>',
    Port: '<optional>',
    MultiAZ: '<optional>',
    EngineVersion: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    LicenseModel: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    OptionGroupName: '<optional>',
    CharacterSetName: '<optional>',
    PubliclyAccessible: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    DBClusterIdentifier: '<optional>',
    StorageType: '<optional>',
    TdeCredentialArn: '<optional>',
    TdeCredentialPassword: '<optional>',
    StorageEncrypted: '<optional>',
    KmsKeyId: '<optional>',
    Domain: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    MonitoringInterval: '<optional>',
    MonitoringRoleArn: '<optional>',
    DomainIAMRoleName: '<optional>',
    PromotionTier: '<optional>',
    Timezone: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnablePerformanceInsights: ['<optional1>', '<optional2>'],
    PerformanceInsightsKMSKeyId: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
  },
  'Neptune:createDBParameterGroup': {
    DBParameterGroupName: 'required',
    DBParameterGroupFamily: 'required',
    Description: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:createDBSubnetGroup': {
    DBSubnetGroupName: 'required',
    DBSubnetGroupDescription: 'required',
    SubnetIds: ['required1', 'required2'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:createEventSubscription': {
    SubscriptionName: 'required',
    SnsTopicArn: 'required',
    SourceType: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    SourceIds: ['<optional1>', '<optional2>'],
    Enabled: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Neptune:deleteDBCluster': {
    DBClusterIdentifier: 'required',
    SkipFinalSnapshot: '<optional>',
    FinalDBSnapshotIdentifier: '<optional>',
  },
  'Neptune:deleteDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
  },
  'Neptune:deleteDBClusterSnapshot': {
    DBClusterSnapshotIdentifier: 'required',
  },
  'Neptune:deleteDBInstance': {
    DBInstanceIdentifier: 'required',
    SkipFinalSnapshot: '<optional>',
    FinalDBSnapshotIdentifier: '<optional>',
  },
  'Neptune:deleteDBParameterGroup': {
    DBParameterGroupName: 'required',
  },
  'Neptune:deleteDBSubnetGroup': {
    DBSubnetGroupName: 'required',
  },
  'Neptune:deleteEventSubscription': {
    SubscriptionName: 'required',
  },
  'Neptune:describeDBClusterParameterGroups': {
    DBClusterParameterGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeDBClusterParameters': {
    DBClusterParameterGroupName: 'required',
    Source: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeDBClusterSnapshotAttributes': {
    DBClusterSnapshotIdentifier: 'required',
  },
  'Neptune:describeDBClusterSnapshots': {
    DBClusterIdentifier: '<optional>',
    DBClusterSnapshotIdentifier: '<optional>',
    SnapshotType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    IncludeShared: '<optional>',
    IncludePublic: '<optional>',
  },
  'Neptune:describeDBClusters': {
    DBClusterIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeDBEngineVersions': {
    Engine: '<optional>',
    EngineVersion: '<optional>',
    DBParameterGroupFamily: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    DefaultOnly: '<optional>',
    ListSupportedCharacterSets: ['<optional1>', '<optional2>'],
    ListSupportedTimezones: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeDBInstances': {
    DBInstanceIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeDBParameterGroups': {
    DBParameterGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeDBParameters': {
    DBParameterGroupName: 'required',
    Source: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeDBSubnetGroups': {
    DBSubnetGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeEngineDefaultClusterParameters': {
    DBParameterGroupFamily: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeEngineDefaultParameters': {
    DBParameterGroupFamily: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeEventCategories': {
    SourceType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Neptune:describeEventSubscriptions': {
    SubscriptionName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeEvents': {
    SourceIdentifier: '<optional>',
    SourceType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Duration: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeOrderableDBInstanceOptions': {
    Engine: 'required',
    EngineVersion: '<optional>',
    DBInstanceClass: '<optional>',
    LicenseModel: '<optional>',
    Vpc: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describePendingMaintenanceActions': {
    ResourceIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Neptune:describeValidDBInstanceModifications': {
    DBInstanceIdentifier: 'required',
  },
  'Neptune:failoverDBCluster': {
    DBClusterIdentifier: '<optional>',
    TargetDBInstanceIdentifier: '<optional>',
  },
  'Neptune:listTagsForResource': {
    ResourceName: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Neptune:modifyDBCluster': {
    DBClusterIdentifier: 'required',
    NewDBClusterIdentifier: '<optional>',
    ApplyImmediately: '<optional>',
    BackupRetentionPeriod: '<optional>',
    DBClusterParameterGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Port: '<optional>',
    MasterUserPassword: '<optional>',
    OptionGroupName: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    CloudwatchLogsExportConfiguration: {
      EnableLogTypes: ['<optional1>', '<optional2>'],
      DisableLogTypes: ['<optional1>', '<optional2>'],
    },
    EngineVersion: '<optional>',
  },
  'Neptune:modifyDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
      },
    ],
  },
  'Neptune:modifyDBClusterSnapshotAttribute': {
    DBClusterSnapshotIdentifier: 'required',
    AttributeName: 'required',
    ValuesToAdd: '<optional>',
    ValuesToRemove: '<optional>',
  },
  'Neptune:modifyDBInstance': {
    DBInstanceIdentifier: 'required',
    AllocatedStorage: '<optional>',
    DBInstanceClass: '<optional>',
    DBSubnetGroupName: '<optional>',
    DBSecurityGroups: ['<optional1>', '<optional2>'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    ApplyImmediately: '<optional>',
    MasterUserPassword: '<optional>',
    DBParameterGroupName: '<optional>',
    BackupRetentionPeriod: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    MultiAZ: '<optional>',
    EngineVersion: '<optional>',
    AllowMajorVersionUpgrade: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    LicenseModel: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    OptionGroupName: '<optional>',
    NewDBInstanceIdentifier: '<optional>',
    StorageType: '<optional>',
    TdeCredentialArn: '<optional>',
    TdeCredentialPassword: '<optional>',
    CACertificateIdentifier: '<optional>',
    Domain: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    MonitoringInterval: '<optional>',
    DBPortNumber: '<optional>',
    PubliclyAccessible: '<optional>',
    MonitoringRoleArn: '<optional>',
    DomainIAMRoleName: '<optional>',
    PromotionTier: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnablePerformanceInsights: ['<optional1>', '<optional2>'],
    PerformanceInsightsKMSKeyId: '<optional>',
    CloudwatchLogsExportConfiguration: {
      EnableLogTypes: ['<optional1>', '<optional2>'],
      DisableLogTypes: ['<optional1>', '<optional2>'],
    },
  },
  'Neptune:modifyDBParameterGroup': {
    DBParameterGroupName: 'required',
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
      },
    ],
  },
  'Neptune:modifyDBSubnetGroup': {
    DBSubnetGroupName: 'required',
    SubnetIds: ['required1', 'required2'],
    DBSubnetGroupDescription: '<optional>',
  },
  'Neptune:modifyEventSubscription': {
    SubscriptionName: 'required',
    SnsTopicArn: '<optional>',
    SourceType: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    Enabled: '<optional>',
  },
  'Neptune:promoteReadReplicaDBCluster': {
    DBClusterIdentifier: 'required',
  },
  'Neptune:rebootDBInstance': {
    DBInstanceIdentifier: 'required',
    ForceFailover: '<optional>',
  },
  'Neptune:removeRoleFromDBCluster': {
    DBClusterIdentifier: 'required',
    RoleArn: 'required',
  },
  'Neptune:removeSourceIdentifierFromSubscription': {
    SubscriptionName: 'required',
    SourceIdentifier: 'required',
  },
  'Neptune:removeTagsFromResource': {
    ResourceName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Neptune:resetDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    ResetAllParameters: ['<optional1>', '<optional2>'],
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
      },
    ],
  },
  'Neptune:resetDBParameterGroup': {
    DBParameterGroupName: 'required',
    ResetAllParameters: ['<optional1>', '<optional2>'],
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
      },
    ],
  },
  'Neptune:restoreDBClusterFromSnapshot': {
    DBClusterIdentifier: 'required',
    SnapshotIdentifier: 'required',
    Engine: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    EngineVersion: '<optional>',
    Port: '<optional>',
    DBSubnetGroupName: '<optional>',
    DatabaseName: '<optional>',
    OptionGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    DBClusterParameterGroupName: '<optional>',
  },
  'Neptune:restoreDBClusterToPointInTime': {
    DBClusterIdentifier: 'required',
    SourceDBClusterIdentifier: 'required',
    RestoreType: '<optional>',
    RestoreToTime: '<optional>',
    UseLatestRestorableTime: '<optional>',
    Port: '<optional>',
    DBSubnetGroupName: '<optional>',
    OptionGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    DBClusterParameterGroupName: '<optional>',
  },
  'NetworkManager:associateCustomerGateway': {
    CustomerGatewayArn: 'required',
    GlobalNetworkId: 'required',
    DeviceId: 'required',
    LinkId: '<optional>',
  },
  'NetworkManager:associateLink': {
    GlobalNetworkId: 'required',
    DeviceId: 'required',
    LinkId: 'required',
  },
  'NetworkManager:createDevice': {
    GlobalNetworkId: 'required',
    Description: '<optional>',
    Type: '<optional>',
    Vendor: '<optional>',
    Model: '<optional>',
    SerialNumber: '<optional>',
    Location: {
      Address: '<optional>',
      Latitude: '<optional>',
      Longitude: '<optional>',
    },
    SiteId: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'NetworkManager:createGlobalNetwork': {
    Description: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'NetworkManager:createLink': {
    GlobalNetworkId: 'required',
    Bandwidth: {
      UploadSpeed: '<optional>',
      DownloadSpeed: '<optional>',
    },
    SiteId: 'required',
    Description: '<optional>',
    Type: '<optional>',
    Provider: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'NetworkManager:createSite': {
    GlobalNetworkId: 'required',
    Description: '<optional>',
    Location: {
      Address: '<optional>',
      Latitude: '<optional>',
      Longitude: '<optional>',
    },
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'NetworkManager:deleteDevice': {
    GlobalNetworkId: 'required',
    DeviceId: 'required',
  },
  'NetworkManager:deleteGlobalNetwork': {
    GlobalNetworkId: 'required',
  },
  'NetworkManager:deleteLink': {
    GlobalNetworkId: 'required',
    LinkId: 'required',
  },
  'NetworkManager:deleteSite': {
    GlobalNetworkId: 'required',
    SiteId: 'required',
  },
  'NetworkManager:deregisterTransitGateway': {
    GlobalNetworkId: 'required',
    TransitGatewayArn: 'required',
  },
  'NetworkManager:describeGlobalNetworks': {
    GlobalNetworkIds: ['<optional1>', '<optional2>'],
  },
  'NetworkManager:disassociateCustomerGateway': {
    GlobalNetworkId: 'required',
    CustomerGatewayArn: 'required',
  },
  'NetworkManager:disassociateLink': {
    GlobalNetworkId: 'required',
    DeviceId: 'required',
    LinkId: 'required',
  },
  'NetworkManager:getCustomerGatewayAssociations': {
    GlobalNetworkId: 'required',
    CustomerGatewayArns: ['<optional1>', '<optional2>'],
  },
  'NetworkManager:getDevices': {
    GlobalNetworkId: 'required',
    DeviceIds: ['<optional1>', '<optional2>'],
    SiteId: '<optional>',
  },
  'NetworkManager:getLinkAssociations': {
    GlobalNetworkId: 'required',
    DeviceId: '<optional>',
    LinkId: '<optional>',
  },
  'NetworkManager:getLinks': {
    GlobalNetworkId: 'required',
    LinkIds: ['<optional1>', '<optional2>'],
    SiteId: '<optional>',
    Type: '<optional>',
    Provider: '<optional>',
  },
  'NetworkManager:getSites': {
    GlobalNetworkId: 'required',
    SiteIds: ['<optional1>', '<optional2>'],
  },
  'NetworkManager:getTransitGatewayRegistrations': {
    GlobalNetworkId: 'required',
    TransitGatewayArns: ['<optional1>', '<optional2>'],
  },
  'NetworkManager:listTagsForResource': {
    ResourceArn: 'required',
  },
  'NetworkManager:registerTransitGateway': {
    GlobalNetworkId: 'required',
    TransitGatewayArn: 'required',
  },
  'NetworkManager:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'NetworkManager:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'NetworkManager:updateDevice': {
    GlobalNetworkId: 'required',
    DeviceId: 'required',
    Description: '<optional>',
    Type: '<optional>',
    Vendor: '<optional>',
    Model: '<optional>',
    SerialNumber: '<optional>',
    Location: {
      Address: '<optional>',
      Latitude: '<optional>',
      Longitude: '<optional>',
    },
    SiteId: '<optional>',
  },
  'NetworkManager:updateGlobalNetwork': {
    GlobalNetworkId: 'required',
    Description: '<optional>',
  },
  'NetworkManager:updateLink': {
    GlobalNetworkId: 'required',
    LinkId: 'required',
    Description: '<optional>',
    Type: '<optional>',
    Bandwidth: {
      UploadSpeed: '<optional>',
      DownloadSpeed: '<optional>',
    },
    Provider: '<optional>',
  },
  'NetworkManager:updateSite': {
    GlobalNetworkId: 'required',
    SiteId: 'required',
    Description: '<optional>',
    Location: {
      Address: '<optional>',
      Latitude: '<optional>',
      Longitude: '<optional>',
    },
  },
  'OpsWorks:assignInstance': {
    InstanceId: 'required',
    LayerIds: ['required1', 'required2'],
  },
  'OpsWorks:assignVolume': {
    VolumeId: 'required',
    InstanceId: '<optional>',
  },
  'OpsWorks:associateElasticIp': {
    ElasticIp: 'required',
    InstanceId: '<optional>',
  },
  'OpsWorks:attachElasticLoadBalancer': {
    ElasticLoadBalancerName: 'required',
    LayerId: 'required',
  },
  'OpsWorks:cloneStack': {
    SourceStackId: 'required',
    ServiceRoleArn: 'required',
    Name: '<optional>',
    Region: '<optional>',
    VpcId: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
    DefaultInstanceProfileArn: '<optional>',
    DefaultOs: ['<optional1>', '<optional2>'],
    HostnameTheme: '<optional>',
    DefaultAvailabilityZone: '<optional>',
    DefaultSubnetId: '<optional>',
    CustomJson: '<optional>',
    ConfigurationManager: {
      Name: '<optional>',
      Version: '<optional>',
    },
    ChefConfiguration: {
      ManageBerkshelf: '<optional>',
      BerkshelfVersion: '<optional>',
    },
    UseCustomCookbooks: ['<optional1>', '<optional2>'],
    UseOpsworksSecurityGroups: ['<optional1>', '<optional2>'],
    CustomCookbooksSource: {
      Type: '<optional>',
      Url: '<optional>',
      Username: '<optional>',
      Password: '<optional>',
      SshKey: '<optional>',
      Revision: '<optional>',
    },
    DefaultSshKeyName: '<optional>',
    ClonePermissions: ['<optional1>', '<optional2>'],
    CloneAppIds: ['<optional1>', '<optional2>'],
    DefaultRootDeviceType: '<optional>',
    AgentVersion: '<optional>',
  },
  'OpsWorks:createApp': {
    StackId: 'required',
    Name: 'required',
    Type: 'required',
    SslConfiguration: {
      Chain: '<optional>',
    },
    Environment: {
      Secure: '<optional>',
    },
    Shortname: '<optional>',
    Description: '<optional>',
    DataSources: [
      {
        Type: '<optional>',
        Arn: '<optional>',
        DatabaseName: '<optional>',
      },
    ],
    AppSource: {
      Type: '<optional>',
      Url: '<optional>',
      Username: '<optional>',
      Password: '<optional>',
      SshKey: '<optional>',
      Revision: '<optional>',
    },
    Domains: ['<optional1>', '<optional2>'],
    EnableSsl: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:createDeployment': {
    StackId: 'required',
    Command: {
      Args: ['<optional1>', '<optional2>'],
    },
    AppId: '<optional>',
    InstanceIds: ['<optional1>', '<optional2>'],
    LayerIds: ['<optional1>', '<optional2>'],
    Comment: '<optional>',
    CustomJson: '<optional>',
  },
  'OpsWorks:createInstance': {
    StackId: 'required',
    LayerIds: ['required1', 'required2'],
    InstanceType: 'required',
    AutoScalingType: '<optional>',
    Hostname: '<optional>',
    Os: ['<optional1>', '<optional2>'],
    AmiId: '<optional>',
    SshKeyName: '<optional>',
    AvailabilityZone: '<optional>',
    VirtualizationType: '<optional>',
    SubnetId: '<optional>',
    Architecture: '<optional>',
    RootDeviceType: '<optional>',
    BlockDeviceMappings: [
      {
        DeviceName: '<optional>',
        NoDevice: '<optional>',
        VirtualName: '<optional>',
        Ebs: [
          {
            SnapshotId: '<optional>',
            Iops: ['<optional1>', '<optional2>'],
            VolumeSize: '<optional>',
            VolumeType: '<optional>',
            DeleteOnTermination: '<optional>',
          },
        ],
      },
    ],
    InstallUpdatesOnBoot: '<optional>',
    EbsOptimized: '<optional>',
    AgentVersion: '<optional>',
    Tenancy: '<optional>',
  },
  'OpsWorks:createLayer': {
    StackId: 'required',
    Type: 'required',
    Name: 'required',
    Shortname: 'required',
    VolumeConfigurations: [
      {
        RaidLevel: '<optional>',
        VolumeType: '<optional>',
        Iops: ['<optional1>', '<optional2>'],
        Encrypted: '<optional>',
      },
    ],
    Attributes: ['<optional1>', '<optional2>'],
    CloudWatchLogsConfiguration: {
      Enabled: '<optional>',
      LogStreams: [
        {
          LogGroupName: '<optional>',
          DatetimeFormat: '<optional>',
          TimeZone: '<optional>',
          File: '<optional>',
          FileFingerprintLines: ['<optional1>', '<optional2>'],
          MultiLineStartPattern: '<optional>',
          InitialPosition: '<optional>',
          Encoding: '<optional>',
          BufferDuration: '<optional>',
          BatchCount: '<optional>',
          BatchSize: '<optional>',
        },
      ],
    },
    CustomInstanceProfileArn: '<optional>',
    CustomJson: '<optional>',
    CustomSecurityGroupIds: ['<optional1>', '<optional2>'],
    Packages: ['<optional1>', '<optional2>'],
    EnableAutoHealing: '<optional>',
    AutoAssignElasticIps: ['<optional1>', '<optional2>'],
    AutoAssignPublicIps: ['<optional1>', '<optional2>'],
    CustomRecipes: [
      {
        Setup: '<optional>',
        Configure: '<optional>',
        Deploy: '<optional>',
        Undeploy: '<optional>',
        Shutdown: '<optional>',
      },
    ],
    InstallUpdatesOnBoot: '<optional>',
    UseEbsOptimizedInstances: ['<optional1>', '<optional2>'],
    LifecycleEventConfiguration: {
      Shutdown: {
        ExecutionTimeout: '<optional>',
        DelayUntilElbConnectionsDrained: '<optional>',
      },
    },
  },
  'OpsWorks:createStack': {
    Name: 'required',
    Region: 'required',
    ServiceRoleArn: 'required',
    DefaultInstanceProfileArn: 'required',
    VpcId: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
    DefaultOs: ['<optional1>', '<optional2>'],
    HostnameTheme: '<optional>',
    DefaultAvailabilityZone: '<optional>',
    DefaultSubnetId: '<optional>',
    CustomJson: '<optional>',
    ConfigurationManager: {
      Name: '<optional>',
      Version: '<optional>',
    },
    ChefConfiguration: {
      ManageBerkshelf: '<optional>',
      BerkshelfVersion: '<optional>',
    },
    UseCustomCookbooks: ['<optional1>', '<optional2>'],
    UseOpsworksSecurityGroups: ['<optional1>', '<optional2>'],
    CustomCookbooksSource: {
      Type: '<optional>',
      Url: '<optional>',
      Username: '<optional>',
      Password: '<optional>',
      SshKey: '<optional>',
      Revision: '<optional>',
    },
    DefaultSshKeyName: '<optional>',
    DefaultRootDeviceType: '<optional>',
    AgentVersion: '<optional>',
  },
  'OpsWorks:createUserProfile': {
    IamUserArn: 'required',
    SshUsername: '<optional>',
    SshPublicKey: '<optional>',
    AllowSelfManagement: '<optional>',
  },
  'OpsWorks:deleteApp': {
    AppId: 'required',
  },
  'OpsWorks:deleteInstance': {
    InstanceId: 'required',
    DeleteElasticIp: '<optional>',
    DeleteVolumes: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:deleteLayer': {
    LayerId: 'required',
  },
  'OpsWorks:deleteStack': {
    StackId: 'required',
  },
  'OpsWorks:deleteUserProfile': {
    IamUserArn: 'required',
  },
  'OpsWorks:deregisterEcsCluster': {
    EcsClusterArn: 'required',
  },
  'OpsWorks:deregisterElasticIp': {
    ElasticIp: 'required',
  },
  'OpsWorks:deregisterInstance': {
    InstanceId: 'required',
  },
  'OpsWorks:deregisterRdsDbInstance': {
    RdsDbInstanceArn: 'required',
  },
  'OpsWorks:deregisterVolume': {
    VolumeId: 'required',
  },
  'OpsWorks:describeAgentVersions': {
    StackId: '<optional>',
    ConfigurationManager: {
      Name: '<optional>',
      Version: '<optional>',
    },
  },
  'OpsWorks:describeApps': {
    StackId: '<optional>',
    AppIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeCommands': {
    DeploymentId: '<optional>',
    InstanceId: '<optional>',
    CommandIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeDeployments': {
    StackId: '<optional>',
    AppId: '<optional>',
    DeploymentIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeEcsClusters': {
    EcsClusterArns: ['<optional1>', '<optional2>'],
    StackId: '<optional>',
  },
  'OpsWorks:describeElasticIps': {
    InstanceId: '<optional>',
    StackId: '<optional>',
    Ips: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeElasticLoadBalancers': {
    StackId: '<optional>',
    LayerIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeInstances': {
    StackId: '<optional>',
    LayerId: '<optional>',
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeLayers': {
    StackId: '<optional>',
    LayerIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeLoadBasedAutoScaling': {
    LayerIds: ['required1', 'required2'],
  },
  'OpsWorks:describeMyUserProfile': {},
  'OpsWorks:describeOperatingSystems': {},
  'OpsWorks:describePermissions': {
    IamUserArn: '<optional>',
    StackId: '<optional>',
  },
  'OpsWorks:describeRaidArrays': {
    InstanceId: '<optional>',
    StackId: '<optional>',
    RaidArrayIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeRdsDbInstances': {
    StackId: 'required',
    RdsDbInstanceArns: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeServiceErrors': {
    StackId: '<optional>',
    InstanceId: '<optional>',
    ServiceErrorIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeStackProvisioningParameters': {
    StackId: 'required',
  },
  'OpsWorks:describeStackSummary': {
    StackId: 'required',
  },
  'OpsWorks:describeStacks': {
    StackIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeTimeBasedAutoScaling': {
    InstanceIds: ['required1', 'required2'],
  },
  'OpsWorks:describeUserProfiles': {
    IamUserArns: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:describeVolumes': {
    InstanceId: '<optional>',
    StackId: '<optional>',
    RaidArrayId: '<optional>',
    VolumeIds: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:detachElasticLoadBalancer': {
    ElasticLoadBalancerName: 'required',
    LayerId: 'required',
  },
  'OpsWorks:disassociateElasticIp': {
    ElasticIp: 'required',
  },
  'OpsWorks:getHostnameSuggestion': {
    LayerId: 'required',
  },
  'OpsWorks:grantAccess': {
    InstanceId: 'required',
    ValidForInMinutes: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:listTags': {
    ResourceArn: 'required',
  },
  'OpsWorks:rebootInstance': {
    InstanceId: 'required',
  },
  'OpsWorks:registerEcsCluster': {
    EcsClusterArn: 'required',
    StackId: 'required',
  },
  'OpsWorks:registerElasticIp': {
    ElasticIp: 'required',
    StackId: 'required',
  },
  'OpsWorks:registerInstance': {
    StackId: 'required',
    Hostname: '<optional>',
    PublicIp: '<optional>',
    PrivateIp: '<optional>',
    RsaPublicKey: '<optional>',
    RsaPublicKeyFingerprint: '<optional>',
    InstanceIdentity: {
      Document: '<optional>',
      Signature: '<optional>',
    },
  },
  'OpsWorks:registerRdsDbInstance': {
    StackId: 'required',
    RdsDbInstanceArn: 'required',
    DbUser: 'required',
    DbPassword: 'required',
  },
  'OpsWorks:registerVolume': {
    StackId: 'required',
    Ec2VolumeId: '<optional>',
  },
  'OpsWorks:setLoadBasedAutoScaling': {
    LayerId: 'required',
    Enable: '<optional>',
    UpScaling: {
      InstanceCount: '<optional>',
      ThresholdsWaitTime: '<optional>',
      IgnoreMetricsTime: '<optional>',
      CpuThreshold: '<optional>',
      MemoryThreshold: '<optional>',
      LoadThreshold: '<optional>',
      Alarms: ['<optional1>', '<optional2>'],
    },
    DownScaling: {
      InstanceCount: '<optional>',
      ThresholdsWaitTime: '<optional>',
      IgnoreMetricsTime: '<optional>',
      CpuThreshold: '<optional>',
      MemoryThreshold: '<optional>',
      LoadThreshold: '<optional>',
      Alarms: ['<optional1>', '<optional2>'],
    },
  },
  'OpsWorks:setPermission': {
    StackId: 'required',
    IamUserArn: 'required',
    AllowSsh: '<optional>',
    AllowSudo: '<optional>',
    Level: '<optional>',
  },
  'OpsWorks:setTimeBasedAutoScaling': {
    InstanceId: 'required',
    AutoScalingSchedule: {
      Monday: '<optional>',
      Tuesday: '<optional>',
      Wednesday: '<optional>',
      Thursday: '<optional>',
      Friday: '<optional>',
      Saturday: '<optional>',
      Sunday: '<optional>',
    },
  },
  'OpsWorks:startInstance': {
    InstanceId: 'required',
  },
  'OpsWorks:startStack': {
    StackId: 'required',
  },
  'OpsWorks:stopInstance': {
    InstanceId: 'required',
    Force: '<optional>',
  },
  'OpsWorks:stopStack': {
    StackId: 'required',
  },
  'OpsWorks:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'OpsWorks:unassignInstance': {
    InstanceId: 'required',
  },
  'OpsWorks:unassignVolume': {
    VolumeId: 'required',
  },
  'OpsWorks:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'OpsWorks:updateApp': {
    AppId: 'required',
    SslConfiguration: {
      Chain: '<optional>',
    },
    Environment: {
      Secure: '<optional>',
    },
    Name: '<optional>',
    Description: '<optional>',
    DataSources: [
      {
        Type: '<optional>',
        Arn: '<optional>',
        DatabaseName: '<optional>',
      },
    ],
    Type: '<optional>',
    AppSource: {
      Type: '<optional>',
      Url: '<optional>',
      Username: '<optional>',
      Password: '<optional>',
      SshKey: '<optional>',
      Revision: '<optional>',
    },
    Domains: ['<optional1>', '<optional2>'],
    EnableSsl: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
  },
  'OpsWorks:updateElasticIp': {
    ElasticIp: 'required',
    Name: '<optional>',
  },
  'OpsWorks:updateInstance': {
    InstanceId: 'required',
    LayerIds: ['<optional1>', '<optional2>'],
    InstanceType: '<optional>',
    AutoScalingType: '<optional>',
    Hostname: '<optional>',
    Os: ['<optional1>', '<optional2>'],
    AmiId: '<optional>',
    SshKeyName: '<optional>',
    Architecture: '<optional>',
    InstallUpdatesOnBoot: '<optional>',
    EbsOptimized: '<optional>',
    AgentVersion: '<optional>',
  },
  'OpsWorks:updateLayer': {
    LayerId: 'required',
    VolumeConfigurations: [
      {
        RaidLevel: '<optional>',
        VolumeType: '<optional>',
        Iops: ['<optional1>', '<optional2>'],
        Encrypted: '<optional>',
      },
    ],
    Name: '<optional>',
    Shortname: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
    CloudWatchLogsConfiguration: {
      Enabled: '<optional>',
      LogStreams: [
        {
          LogGroupName: '<optional>',
          DatetimeFormat: '<optional>',
          TimeZone: '<optional>',
          File: '<optional>',
          FileFingerprintLines: ['<optional1>', '<optional2>'],
          MultiLineStartPattern: '<optional>',
          InitialPosition: '<optional>',
          Encoding: '<optional>',
          BufferDuration: '<optional>',
          BatchCount: '<optional>',
          BatchSize: '<optional>',
        },
      ],
    },
    CustomInstanceProfileArn: '<optional>',
    CustomJson: '<optional>',
    CustomSecurityGroupIds: ['<optional1>', '<optional2>'],
    Packages: ['<optional1>', '<optional2>'],
    EnableAutoHealing: '<optional>',
    AutoAssignElasticIps: ['<optional1>', '<optional2>'],
    AutoAssignPublicIps: ['<optional1>', '<optional2>'],
    CustomRecipes: [
      {
        Setup: '<optional>',
        Configure: '<optional>',
        Deploy: '<optional>',
        Undeploy: '<optional>',
        Shutdown: '<optional>',
      },
    ],
    InstallUpdatesOnBoot: '<optional>',
    UseEbsOptimizedInstances: ['<optional1>', '<optional2>'],
    LifecycleEventConfiguration: {
      Shutdown: {
        ExecutionTimeout: '<optional>',
        DelayUntilElbConnectionsDrained: '<optional>',
      },
    },
  },
  'OpsWorks:updateMyUserProfile': {
    SshPublicKey: '<optional>',
  },
  'OpsWorks:updateRdsDbInstance': {
    RdsDbInstanceArn: 'required',
    DbUser: '<optional>',
    DbPassword: '<optional>',
  },
  'OpsWorks:updateStack': {
    StackId: 'required',
    Name: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
    ServiceRoleArn: '<optional>',
    DefaultInstanceProfileArn: '<optional>',
    DefaultOs: ['<optional1>', '<optional2>'],
    HostnameTheme: '<optional>',
    DefaultAvailabilityZone: '<optional>',
    DefaultSubnetId: '<optional>',
    CustomJson: '<optional>',
    ConfigurationManager: {
      Name: '<optional>',
      Version: '<optional>',
    },
    ChefConfiguration: {
      ManageBerkshelf: '<optional>',
      BerkshelfVersion: '<optional>',
    },
    UseCustomCookbooks: ['<optional1>', '<optional2>'],
    CustomCookbooksSource: {
      Type: '<optional>',
      Url: '<optional>',
      Username: '<optional>',
      Password: '<optional>',
      SshKey: '<optional>',
      Revision: '<optional>',
    },
    DefaultSshKeyName: '<optional>',
    DefaultRootDeviceType: '<optional>',
    UseOpsworksSecurityGroups: ['<optional1>', '<optional2>'],
    AgentVersion: '<optional>',
  },
  'OpsWorks:updateUserProfile': {
    IamUserArn: 'required',
    SshUsername: '<optional>',
    SshPublicKey: '<optional>',
    AllowSelfManagement: '<optional>',
  },
  'OpsWorks:updateVolume': {
    VolumeId: 'required',
    Name: '<optional>',
    MountPoint: '<optional>',
  },
  'OpsWorksCM:associateNode': {
    ServerName: 'required',
    NodeName: 'required',
    EngineAttributes: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'OpsWorksCM:createBackup': {
    ServerName: 'required',
    Description: '<optional>',
  },
  'OpsWorksCM:createServer': {
    ServerName: 'required',
    InstanceProfileArn: 'required',
    InstanceType: 'required',
    ServiceRoleArn: 'required',
    AssociatePublicIpAddress: '<optional>',
    DisableAutomatedBackup: '<optional>',
    Engine: '<optional>',
    EngineModel: '<optional>',
    EngineVersion: '<optional>',
    EngineAttributes: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
    BackupRetentionCount: '<optional>',
    KeyPair: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    PreferredBackupWindow: '<optional>',
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    SubnetIds: ['<optional1>', '<optional2>'],
    BackupId: '<optional>',
  },
  'OpsWorksCM:deleteBackup': {
    BackupId: 'required',
  },
  'OpsWorksCM:deleteServer': {
    ServerName: 'required',
  },
  'OpsWorksCM:describeAccountAttributes': {},
  'OpsWorksCM:describeBackups': {
    BackupId: '<optional>',
    ServerName: '<optional>',
  },
  'OpsWorksCM:describeEvents': {
    ServerName: 'required',
  },
  'OpsWorksCM:describeNodeAssociationStatus': {
    NodeAssociationStatusToken: 'required',
    ServerName: 'required',
  },
  'OpsWorksCM:describeServers': {
    ServerName: '<optional>',
  },
  'OpsWorksCM:disassociateNode': {
    ServerName: 'required',
    NodeName: 'required',
    EngineAttributes: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'OpsWorksCM:exportServerEngineAttribute': {
    ExportAttributeName: 'required',
    ServerName: 'required',
    InputAttributes: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'OpsWorksCM:listTagsForResource': {
    ResourceArn: 'required',
  },
  'OpsWorksCM:restoreServer': {
    BackupId: 'required',
    ServerName: 'required',
    InstanceType: '<optional>',
    KeyPair: '<optional>',
  },
  'OpsWorksCM:startMaintenance': {
    ServerName: 'required',
    EngineAttributes: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'OpsWorksCM:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'OpsWorksCM:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'OpsWorksCM:updateServer': {
    ServerName: 'required',
    DisableAutomatedBackup: '<optional>',
    BackupRetentionCount: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    PreferredBackupWindow: '<optional>',
  },
  'OpsWorksCM:updateServerEngineAttributes': {
    ServerName: 'required',
    AttributeName: 'required',
    AttributeValue: '<optional>',
  },
  'Organizations:acceptHandshake': {
    HandshakeId: 'required',
  },
  'Organizations:attachPolicy': {
    PolicyId: 'required',
    TargetId: 'required',
  },
  'Organizations:cancelHandshake': {
    HandshakeId: 'required',
  },
  'Organizations:createAccount': {
    Email: 'required',
    AccountName: 'required',
    RoleName: '<optional>',
    IamUserAccessToBilling: '<optional>',
  },
  'Organizations:createGovCloudAccount': {
    Email: 'required',
    AccountName: 'required',
    RoleName: '<optional>',
    IamUserAccessToBilling: '<optional>',
  },
  'Organizations:createOrganization': {
    FeatureSet: '<optional>',
  },
  'Organizations:createOrganizationalUnit': {
    ParentId: 'required',
    Name: 'required',
  },
  'Organizations:createPolicy': {
    Content: 'required',
    Description: 'required',
    Name: 'required',
    Type: 'required',
  },
  'Organizations:declineHandshake': {
    HandshakeId: 'required',
  },
  'Organizations:deleteOrganization': {},
  'Organizations:deleteOrganizationalUnit': {
    OrganizationalUnitId: 'required',
  },
  'Organizations:deletePolicy': {
    PolicyId: 'required',
  },
  'Organizations:describeAccount': {
    AccountId: 'required',
  },
  'Organizations:describeCreateAccountStatus': {
    CreateAccountRequestId: 'required',
  },
  'Organizations:describeEffectivePolicy': {
    PolicyType: 'required',
    TargetId: '<optional>',
  },
  'Organizations:describeHandshake': {
    HandshakeId: 'required',
  },
  'Organizations:describeOrganization': {},
  'Organizations:describeOrganizationalUnit': {
    OrganizationalUnitId: 'required',
  },
  'Organizations:describePolicy': {
    PolicyId: 'required',
  },
  'Organizations:detachPolicy': {
    PolicyId: 'required',
    TargetId: 'required',
  },
  'Organizations:disableAWSServiceAccess': {
    ServicePrincipal: 'required',
  },
  'Organizations:disablePolicyType': {
    RootId: 'required',
    PolicyType: 'required',
  },
  'Organizations:enableAWSServiceAccess': {
    ServicePrincipal: 'required',
  },
  'Organizations:enableAllFeatures': {},
  'Organizations:enablePolicyType': {
    RootId: 'required',
    PolicyType: 'required',
  },
  'Organizations:inviteAccountToOrganization': {
    Target: {
      Id: 'required',
      Type: 'required',
    },
    Notes: ['<optional1>', '<optional2>'],
  },
  'Organizations:leaveOrganization': {},
  'Organizations:listAWSServiceAccessForOrganization': {},
  'Organizations:listAccounts': {},
  'Organizations:listAccountsForParent': {
    ParentId: 'required',
  },
  'Organizations:listChildren': {
    ParentId: 'required',
    ChildType: 'required',
  },
  'Organizations:listCreateAccountStatus': {
    States: ['<optional1>', '<optional2>'],
  },
  'Organizations:listHandshakesForAccount': {
    Filter: {
      ActionType: '<optional>',
      ParentHandshakeId: '<optional>',
    },
  },
  'Organizations:listHandshakesForOrganization': {
    Filter: {
      ActionType: '<optional>',
      ParentHandshakeId: '<optional>',
    },
  },
  'Organizations:listOrganizationalUnitsForParent': {
    ParentId: 'required',
  },
  'Organizations:listParents': {
    ChildId: 'required',
  },
  'Organizations:listPolicies': {
    Filter: '<optional>',
  },
  'Organizations:listPoliciesForTarget': {
    TargetId: 'required',
    Filter: '<optional>',
  },
  'Organizations:listRoots': {},
  'Organizations:listTagsForResource': {
    ResourceId: 'required',
  },
  'Organizations:listTargetsForPolicy': {
    PolicyId: 'required',
  },
  'Organizations:moveAccount': {
    AccountId: 'required',
    SourceParentId: 'required',
    DestinationParentId: 'required',
  },
  'Organizations:removeAccountFromOrganization': {
    AccountId: 'required',
  },
  'Organizations:tagResource': {
    ResourceId: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'Organizations:untagResource': {
    ResourceId: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Organizations:updateOrganizationalUnit': {
    OrganizationalUnitId: 'required',
    Name: '<optional>',
  },
  'Organizations:updatePolicy': {
    PolicyId: 'required',
    Name: '<optional>',
    Description: '<optional>',
    Content: '<optional>',
  },
  'Outposts:createOutpost': {
    SiteId: 'required',
    Name: '<optional>',
    Description: '<optional>',
    AvailabilityZone: '<optional>',
    AvailabilityZoneId: '<optional>',
  },
  'Outposts:getOutpost': {
    OutpostId: 'required',
  },
  'Outposts:getOutpostInstanceTypes': {
    OutpostId: 'required',
  },
  'Outposts:listOutposts': {},
  'Outposts:listSites': {},
  'PI:describeDimensionKeys': {
    ServiceType: 'required',
    Identifier: 'required',
    StartTime: 'required',
    EndTime: 'required',
    Metric: 'required',
    GroupBy: {
      Dimensions: ['<optional1>', '<optional2>'],
      Limit: '<optional>',
    },
    PartitionBy: {
      Dimensions: ['<optional1>', '<optional2>'],
      Limit: '<optional>',
    },
    PeriodInSeconds: ['<optional1>', '<optional2>'],
    Filter: '<optional>',
  },
  'PI:getResourceMetrics': {
    ServiceType: 'required',
    Identifier: 'required',
    MetricQueries: [
      {
        GroupBy: {
          Dimensions: ['<optional1>', '<optional2>'],
          Limit: '<optional>',
        },
        Filter: '<optional>',
      },
    ],
    StartTime: 'required',
    EndTime: 'required',
    PeriodInSeconds: ['<optional1>', '<optional2>'],
  },
  'Personalize:createBatchInferenceJob': {
    jobName: 'required',
    solutionVersionArn: 'required',
    jobInput: {
      s3DataSource: {
        kmsKeyArn: '<optional>',
      },
    },
    jobOutput: {
      s3DataDestination: {
        kmsKeyArn: '<optional>',
      },
    },
    roleArn: 'required',
    numResults: ['<optional1>', '<optional2>'],
  },
  'Personalize:createCampaign': {
    name: 'required',
    solutionVersionArn: 'required',
    minProvisionedTPS: ['required1', 'required2'],
  },
  'Personalize:createDataset': {
    name: 'required',
    schemaArn: 'required',
    datasetGroupArn: 'required',
    datasetType: 'required',
  },
  'Personalize:createDatasetGroup': {
    name: 'required',
    roleArn: '<optional>',
    kmsKeyArn: '<optional>',
  },
  'Personalize:createDatasetImportJob': {
    jobName: 'required',
    datasetArn: 'required',
    dataSource: {
      dataLocation: '<optional>',
    },
    roleArn: 'required',
  },
  'Personalize:createEventTracker': {
    name: 'required',
    datasetGroupArn: 'required',
  },
  'Personalize:createSchema': {
    name: 'required',
    schema: 'required',
  },
  'Personalize:createSolution': {
    name: 'required',
    datasetGroupArn: 'required',
    performHPO: '<optional>',
    performAutoML: '<optional>',
    recipeArn: '<optional>',
    eventType: '<optional>',
    solutionConfig: {
      eventValueThreshold: '<optional>',
      hpoConfig: {
        hpoObjective: {
          type: '<optional>',
          metricName: '<optional>',
          metricRegex: '<optional>',
        },
        hpoResourceConfig: {
          maxNumberOfTrainingJobs: ['<optional1>', '<optional2>'],
          maxParallelTrainingJobs: ['<optional1>', '<optional2>'],
        },
        algorithmHyperParameterRanges: [
          {
            integerHyperParameterRanges: [
              {
                name: '<optional>',
                minValue: '<optional>',
                maxValue: '<optional>',
              },
            ],
            continuousHyperParameterRanges: [
              {
                name: '<optional>',
                minValue: '<optional>',
                maxValue: '<optional>',
              },
            ],
            categoricalHyperParameterRanges: [
              {
                name: '<optional>',
                values: ['<optional1>', '<optional2>'],
              },
            ],
          },
        ],
      },
      algorithmHyperParameters: ['<optional1>', '<optional2>'],
      featureTransformationParameters: ['<optional1>', '<optional2>'],
      autoMLConfig: {
        metricName: '<optional>',
        recipeList: '<optional>',
      },
    },
  },
  'Personalize:createSolutionVersion': {
    solutionArn: 'required',
    trainingMode: '<optional>',
  },
  'Personalize:deleteCampaign': {
    campaignArn: 'required',
  },
  'Personalize:deleteDataset': {
    datasetArn: 'required',
  },
  'Personalize:deleteDatasetGroup': {
    datasetGroupArn: 'required',
  },
  'Personalize:deleteEventTracker': {
    eventTrackerArn: 'required',
  },
  'Personalize:deleteSchema': {
    schemaArn: 'required',
  },
  'Personalize:deleteSolution': {
    solutionArn: 'required',
  },
  'Personalize:describeAlgorithm': {
    algorithmArn: 'required',
  },
  'Personalize:describeBatchInferenceJob': {
    batchInferenceJobArn: 'required',
  },
  'Personalize:describeCampaign': {
    campaignArn: 'required',
  },
  'Personalize:describeDataset': {
    datasetArn: 'required',
  },
  'Personalize:describeDatasetGroup': {
    datasetGroupArn: 'required',
  },
  'Personalize:describeDatasetImportJob': {
    datasetImportJobArn: 'required',
  },
  'Personalize:describeEventTracker': {
    eventTrackerArn: 'required',
  },
  'Personalize:describeFeatureTransformation': {
    featureTransformationArn: 'required',
  },
  'Personalize:describeRecipe': {
    recipeArn: 'required',
  },
  'Personalize:describeSchema': {
    schemaArn: 'required',
  },
  'Personalize:describeSolution': {
    solutionArn: 'required',
  },
  'Personalize:describeSolutionVersion': {
    solutionVersionArn: 'required',
  },
  'Personalize:getSolutionMetrics': {
    solutionVersionArn: 'required',
  },
  'Personalize:listBatchInferenceJobs': {
    solutionVersionArn: '<optional>',
  },
  'Personalize:listCampaigns': {
    solutionArn: '<optional>',
  },
  'Personalize:listDatasetGroups': {},
  'Personalize:listDatasetImportJobs': {
    datasetArn: '<optional>',
  },
  'Personalize:listDatasets': {
    datasetGroupArn: '<optional>',
  },
  'Personalize:listEventTrackers': {
    datasetGroupArn: '<optional>',
  },
  'Personalize:listRecipes': {
    recipeProvider: '<optional>',
  },
  'Personalize:listSchemas': {},
  'Personalize:listSolutionVersions': {
    solutionArn: '<optional>',
  },
  'Personalize:listSolutions': {
    datasetGroupArn: '<optional>',
  },
  'Personalize:updateCampaign': {
    campaignArn: 'required',
    solutionVersionArn: '<optional>',
    minProvisionedTPS: ['<optional1>', '<optional2>'],
  },
  'PersonalizeEvents:putEvents': {
    trackingId: 'required',
    sessionId: 'required',
    eventList: {
      eventId: '<optional>',
    },
    userId: '<optional>',
  },
  'PersonalizeRuntime:getPersonalizedRanking': {
    campaignArn: 'required',
    inputList: 'required',
    userId: 'required',
    context: '<optional>',
  },
  'PersonalizeRuntime:getRecommendations': {
    campaignArn: 'required',
    itemId: '<optional>',
    userId: '<optional>',
    numResults: ['<optional1>', '<optional2>'],
    context: '<optional>',
  },
  'Pinpoint:createApp': {
    CreateApplicationRequest: {
      tags: ['<optional1>', '<optional2>'],
    },
  },
  'Pinpoint:createCampaign': {
    ApplicationId: 'required',
    WriteCampaignRequest: {
      AdditionalTreatments: [
        {
          MessageConfiguration: {
            ADMMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            APNSMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            BaiduMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            DefaultMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            EmailMessage: {
              Body: '<optional>',
              FromAddress: '<optional>',
              HtmlBody: '<optional>',
            },
            GCMMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            SMSMessage: {
              Body: '<optional>',
              MessageType: '<optional>',
              SenderId: '<optional>',
            },
          },
          Schedule: {
            EndTime: '<optional>',
            EventFilter: {
              Dimensions: [
                {
                  Attributes: ['<optional1>', '<optional2>'],
                  EventType: {
                    DimensionType: '<optional>',
                  },
                  Metrics: ['<optional1>', '<optional2>'],
                },
              ],
            },
            Frequency: '<optional>',
            IsLocalTime: '<optional>',
            QuietTime: {
              End: '<optional>',
              Start: '<optional>',
            },
            Timezone: '<optional>',
          },
          TreatmentDescription: '<optional>',
          TreatmentName: '<optional>',
        },
      ],
      Description: '<optional>',
      HoldoutPercent: '<optional>',
      Hook: {
        LambdaFunctionName: '<optional>',
        Mode: '<optional>',
        WebUrl: '<optional>',
      },
      IsPaused: '<optional>',
      Limits: [
        {
          Daily: '<optional>',
          MaximumDuration: '<optional>',
          MessagesPerSecond: '<optional>',
          Total: '<optional>',
        },
      ],
      MessageConfiguration: {
        ADMMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        APNSMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        BaiduMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        DefaultMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        EmailMessage: {
          Body: '<optional>',
          FromAddress: '<optional>',
          HtmlBody: '<optional>',
        },
        GCMMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        SMSMessage: {
          Body: '<optional>',
          MessageType: '<optional>',
          SenderId: '<optional>',
        },
      },
      Name: '<optional>',
      Schedule: {
        EndTime: '<optional>',
        EventFilter: {
          Dimensions: [
            {
              Attributes: ['<optional1>', '<optional2>'],
              EventType: {
                DimensionType: '<optional>',
              },
              Metrics: ['<optional1>', '<optional2>'],
            },
          ],
        },
        Frequency: '<optional>',
        IsLocalTime: '<optional>',
        QuietTime: {
          End: '<optional>',
          Start: '<optional>',
        },
        Timezone: '<optional>',
      },
      SegmentId: '<optional>',
      SegmentVersion: '<optional>',
      tags: ['<optional1>', '<optional2>'],
      TreatmentDescription: '<optional>',
      TreatmentName: '<optional>',
    },
  },
  'Pinpoint:createEmailTemplate': {
    TemplateName: 'required',
    EmailTemplateRequest: {
      DefaultSubstitutions: ['<optional1>', '<optional2>'],
      HtmlPart: '<optional>',
      Subject: '<optional>',
      tags: ['<optional1>', '<optional2>'],
      TemplateDescription: '<optional>',
      TextPart: '<optional>',
    },
  },
  'Pinpoint:createExportJob': {
    ApplicationId: 'required',
    ExportJobRequest: {
      SegmentId: '<optional>',
      SegmentVersion: '<optional>',
    },
  },
  'Pinpoint:createImportJob': {
    ApplicationId: 'required',
    ImportJobRequest: {
      DefineSegment: '<optional>',
      ExternalId: '<optional>',
      RegisterEndpoints: ['<optional1>', '<optional2>'],
      SegmentId: '<optional>',
      SegmentName: '<optional>',
    },
  },
  'Pinpoint:createJourney': {
    ApplicationId: 'required',
    WriteJourneyRequest: {
      Activities: ['<optional1>', '<optional2>'],
      CreationDate: '<optional>',
      LastModifiedDate: '<optional>',
      Limits: [
        {
          DailyCap: '<optional>',
          EndpointReentryCap: '<optional>',
          MessagesPerSecond: '<optional>',
        },
      ],
      LocalTime: '<optional>',
      QuietTime: {
        End: '<optional>',
        Start: '<optional>',
      },
      RefreshFrequency: '<optional>',
      Schedule: {
        EndTime: '<optional>',
        StartTime: '<optional>',
        Timezone: '<optional>',
      },
      StartActivity: '<optional>',
      StartCondition: {
        Description: '<optional>',
        SegmentStartCondition: '<optional>',
      },
      State: '<optional>',
    },
  },
  'Pinpoint:createPushTemplate': {
    TemplateName: 'required',
    PushNotificationTemplateRequest: {
      ADM: {
        Action: '<optional>',
        Body: '<optional>',
        ImageIconUrl: '<optional>',
        ImageUrl: '<optional>',
        RawContent: '<optional>',
        SmallImageIconUrl: '<optional>',
        Sound: '<optional>',
        Title: '<optional>',
        Url: '<optional>',
      },
      APNS: [
        {
          Action: '<optional>',
          Body: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          Sound: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
      ],
      Baidu: {
        Action: '<optional>',
        Body: '<optional>',
        ImageIconUrl: '<optional>',
        ImageUrl: '<optional>',
        RawContent: '<optional>',
        SmallImageIconUrl: '<optional>',
        Sound: '<optional>',
        Title: '<optional>',
        Url: '<optional>',
      },
      Default: {
        Action: '<optional>',
        Body: '<optional>',
        Sound: '<optional>',
        Title: '<optional>',
        Url: '<optional>',
      },
      DefaultSubstitutions: ['<optional1>', '<optional2>'],
      GCM: {
        Action: '<optional>',
        Body: '<optional>',
        ImageIconUrl: '<optional>',
        ImageUrl: '<optional>',
        RawContent: '<optional>',
        SmallImageIconUrl: '<optional>',
        Sound: '<optional>',
        Title: '<optional>',
        Url: '<optional>',
      },
      tags: ['<optional1>', '<optional2>'],
      TemplateDescription: '<optional>',
    },
  },
  'Pinpoint:createSegment': {
    ApplicationId: 'required',
    WriteSegmentRequest: {
      Dimensions: [
        {
          Attributes: ['<optional1>', '<optional2>'],
          Behavior: {
            Recency: '<optional>',
          },
          Demographic: {
            AppVersion: {
              DimensionType: '<optional>',
            },
            Channel: {
              DimensionType: '<optional>',
            },
            DeviceType: {
              DimensionType: '<optional>',
            },
            Make: {
              DimensionType: '<optional>',
            },
            Model: {
              DimensionType: '<optional>',
            },
            Platform: {
              DimensionType: '<optional>',
            },
          },
          Location: {
            Country: {
              DimensionType: '<optional>',
            },
            GPSPoint: {
              RangeInKilometers: ['<optional1>', '<optional2>'],
            },
          },
          Metrics: ['<optional1>', '<optional2>'],
          UserAttributes: ['<optional1>', '<optional2>'],
        },
      ],
      Name: '<optional>',
      SegmentGroups: [
        {
          Groups: [
            {
              Dimensions: [
                {
                  Attributes: ['<optional1>', '<optional2>'],
                  Behavior: {
                    Recency: '<optional>',
                  },
                  Demographic: {
                    AppVersion: {
                      DimensionType: '<optional>',
                    },
                    Channel: {
                      DimensionType: '<optional>',
                    },
                    DeviceType: {
                      DimensionType: '<optional>',
                    },
                    Make: {
                      DimensionType: '<optional>',
                    },
                    Model: {
                      DimensionType: '<optional>',
                    },
                    Platform: {
                      DimensionType: '<optional>',
                    },
                  },
                  Location: {
                    Country: {
                      DimensionType: '<optional>',
                    },
                    GPSPoint: {
                      RangeInKilometers: ['<optional1>', '<optional2>'],
                    },
                  },
                  Metrics: ['<optional1>', '<optional2>'],
                  UserAttributes: ['<optional1>', '<optional2>'],
                },
              ],
              SourceSegments: [
                {
                  Version: '<optional>',
                },
              ],
              SourceType: '<optional>',
              Type: '<optional>',
            },
          ],
          Include: '<optional>',
        },
      ],
      tags: ['<optional1>', '<optional2>'],
    },
  },
  'Pinpoint:createSmsTemplate': {
    TemplateName: 'required',
    SMSTemplateRequest: {
      Body: '<optional>',
      DefaultSubstitutions: ['<optional1>', '<optional2>'],
      tags: ['<optional1>', '<optional2>'],
      TemplateDescription: '<optional>',
    },
  },
  'Pinpoint:createVoiceTemplate': {
    TemplateName: 'required',
    VoiceTemplateRequest: {
      Body: '<optional>',
      DefaultSubstitutions: ['<optional1>', '<optional2>'],
      LanguageCode: '<optional>',
      tags: ['<optional1>', '<optional2>'],
      TemplateDescription: '<optional>',
      VoiceId: '<optional>',
    },
  },
  'Pinpoint:deleteAdmChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteApnsChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteApnsSandboxChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteApnsVoipChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteApnsVoipSandboxChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteApp': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteBaiduChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteCampaign': {
    CampaignId: 'required',
    ApplicationId: 'required',
  },
  'Pinpoint:deleteEmailChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteEmailTemplate': {
    TemplateName: 'required',
    Version: '<optional>',
  },
  'Pinpoint:deleteEndpoint': {
    ApplicationId: 'required',
    EndpointId: 'required',
  },
  'Pinpoint:deleteEventStream': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteGcmChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteJourney': {
    JourneyId: 'required',
    ApplicationId: 'required',
  },
  'Pinpoint:deletePushTemplate': {
    TemplateName: 'required',
    Version: '<optional>',
  },
  'Pinpoint:deleteSegment': {
    SegmentId: 'required',
    ApplicationId: 'required',
  },
  'Pinpoint:deleteSmsChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteSmsTemplate': {
    TemplateName: 'required',
    Version: '<optional>',
  },
  'Pinpoint:deleteUserEndpoints': {
    ApplicationId: 'required',
    UserId: 'required',
  },
  'Pinpoint:deleteVoiceChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:deleteVoiceTemplate': {
    TemplateName: 'required',
    Version: '<optional>',
  },
  'Pinpoint:getAdmChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getApnsChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getApnsSandboxChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getApnsVoipChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getApnsVoipSandboxChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getApp': {
    ApplicationId: 'required',
  },
  'Pinpoint:getApplicationDateRangeKpi': {
    ApplicationId: 'required',
    KpiName: 'required',
    EndTime: '<optional>',
    PageSize: '<optional>',
    StartTime: '<optional>',
  },
  'Pinpoint:getApplicationSettings': {
    ApplicationId: 'required',
  },
  'Pinpoint:getApps': {
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getBaiduChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getCampaign': {
    CampaignId: 'required',
    ApplicationId: 'required',
  },
  'Pinpoint:getCampaignActivities': {
    ApplicationId: 'required',
    CampaignId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getCampaignDateRangeKpi': {
    ApplicationId: 'required',
    KpiName: 'required',
    CampaignId: 'required',
    EndTime: '<optional>',
    PageSize: '<optional>',
    StartTime: '<optional>',
  },
  'Pinpoint:getCampaignVersion': {
    Version: 'required',
    ApplicationId: 'required',
    CampaignId: 'required',
  },
  'Pinpoint:getCampaignVersions': {
    ApplicationId: 'required',
    CampaignId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getCampaigns': {
    ApplicationId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getChannels': {
    ApplicationId: 'required',
  },
  'Pinpoint:getEmailChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getEmailTemplate': {
    TemplateName: 'required',
    Version: '<optional>',
  },
  'Pinpoint:getEndpoint': {
    ApplicationId: 'required',
    EndpointId: 'required',
  },
  'Pinpoint:getEventStream': {
    ApplicationId: 'required',
  },
  'Pinpoint:getExportJob': {
    ApplicationId: 'required',
    JobId: 'required',
  },
  'Pinpoint:getExportJobs': {
    ApplicationId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getGcmChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getImportJob': {
    ApplicationId: 'required',
    JobId: 'required',
  },
  'Pinpoint:getImportJobs': {
    ApplicationId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getJourney': {
    JourneyId: 'required',
    ApplicationId: 'required',
  },
  'Pinpoint:getJourneyDateRangeKpi': {
    JourneyId: 'required',
    ApplicationId: 'required',
    KpiName: 'required',
    EndTime: '<optional>',
    PageSize: '<optional>',
    StartTime: '<optional>',
  },
  'Pinpoint:getJourneyExecutionActivityMetrics': {
    JourneyActivityId: 'required',
    ApplicationId: 'required',
    JourneyId: 'required',
    PageSize: '<optional>',
  },
  'Pinpoint:getJourneyExecutionMetrics': {
    ApplicationId: 'required',
    JourneyId: 'required',
    PageSize: '<optional>',
  },
  'Pinpoint:getPushTemplate': {
    TemplateName: 'required',
    Version: '<optional>',
  },
  'Pinpoint:getSegment': {
    SegmentId: 'required',
    ApplicationId: 'required',
  },
  'Pinpoint:getSegmentExportJobs': {
    SegmentId: 'required',
    ApplicationId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getSegmentImportJobs': {
    SegmentId: 'required',
    ApplicationId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getSegmentVersion': {
    SegmentId: 'required',
    Version: 'required',
    ApplicationId: 'required',
  },
  'Pinpoint:getSegmentVersions': {
    SegmentId: 'required',
    ApplicationId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getSegments': {
    ApplicationId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:getSmsChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getSmsTemplate': {
    TemplateName: 'required',
    Version: '<optional>',
  },
  'Pinpoint:getUserEndpoints': {
    ApplicationId: 'required',
    UserId: 'required',
  },
  'Pinpoint:getVoiceChannel': {
    ApplicationId: 'required',
  },
  'Pinpoint:getVoiceTemplate': {
    TemplateName: 'required',
    Version: '<optional>',
  },
  'Pinpoint:listJourneys': {
    ApplicationId: 'required',
    PageSize: '<optional>',
    Token: '<optional>',
  },
  'Pinpoint:listTagsForResource': {
    ResourceArn: 'required',
  },
  'Pinpoint:listTemplateVersions': {
    TemplateName: 'required',
    TemplateType: 'required',
    PageSize: '<optional>',
  },
  'Pinpoint:listTemplates': {
    PageSize: '<optional>',
    Prefix: '<optional>',
    TemplateType: '<optional>',
  },
  'Pinpoint:phoneNumberValidate': {
    NumberValidateRequest: {
      IsoCountryCode: '<optional>',
      PhoneNumber: '<optional>',
    },
  },
  'Pinpoint:putEventStream': {
    ApplicationId: 'required',
    WriteEventStream: {
      RoleArn: 'required',
      DestinationStreamArn: 'required',
    },
  },
  'Pinpoint:putEvents': {
    ApplicationId: 'required',
    EventsRequest: {
      BatchItem: 'required',
    },
  },
  'Pinpoint:removeAttributes': {
    AttributeType: 'required',
    ApplicationId: 'required',
    UpdateAttributesRequest: {
      Blacklist: '<optional>',
    },
  },
  'Pinpoint:sendMessages': {
    ApplicationId: 'required',
    MessageRequest: {
      Addresses: ['<optional1>', '<optional2>'],
      Context: '<optional>',
      Endpoints: ['<optional1>', '<optional2>'],
      TraceId: '<optional>',
      MessageConfiguration: {
        ADMMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ConsolidationKey: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          ExpiresAfter: '<optional>',
          IconReference: '<optional>',
          ImageIconUrl: '<optional>',
          ImageUrl: '<optional>',
          MD5: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          SmallImageIconUrl: '<optional>',
          Sound: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          Title: '<optional>',
          Url: '<optional>',
        },
        APNSMessage: {
          Action: '<optional>',
          Badge: '<optional>',
          Body: '<optional>',
          Category: '<optional>',
          CollapseId: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          MediaUrl: '<optional>',
          PreferredAuthenticationMethod: '<optional>',
          Priority: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          Sound: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          ThreadId: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        BaiduMessage: {
          Action: '<optional>',
          Body: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          IconReference: '<optional>',
          ImageIconUrl: '<optional>',
          ImageUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          SmallImageIconUrl: '<optional>',
          Sound: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        DefaultMessage: {
          Body: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
        },
        DefaultPushNotificationMessage: {
          Action: '<optional>',
          Body: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          SilentPush: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          Title: '<optional>',
          Url: '<optional>',
        },
        EmailMessage: {
          Body: '<optional>',
          FeedbackForwardingAddress: '<optional>',
          FromAddress: '<optional>',
          RawEmail: {
            Data: ['<optional1>', '<optional2>'],
          },
          ReplyToAddresses: ['<optional1>', '<optional2>'],
          SimpleEmail: {
            HtmlPart: {
              Charset: '<optional>',
              Data: ['<optional1>', '<optional2>'],
            },
            Subject: {
              Charset: '<optional>',
              Data: ['<optional1>', '<optional2>'],
            },
            TextPart: {
              Charset: '<optional>',
              Data: ['<optional1>', '<optional2>'],
            },
          },
          Substitutions: ['<optional1>', '<optional2>'],
        },
        GCMMessage: {
          Action: '<optional>',
          Body: '<optional>',
          CollapseKey: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          IconReference: '<optional>',
          ImageIconUrl: '<optional>',
          ImageUrl: '<optional>',
          Priority: '<optional>',
          RawContent: '<optional>',
          RestrictedPackageName: '<optional>',
          SilentPush: '<optional>',
          SmallImageIconUrl: '<optional>',
          Sound: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        SMSMessage: {
          Body: '<optional>',
          Keyword: '<optional>',
          MessageType: '<optional>',
          OriginationNumber: '<optional>',
          SenderId: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
        },
        VoiceMessage: {
          Body: '<optional>',
          LanguageCode: '<optional>',
          OriginationNumber: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          VoiceId: '<optional>',
        },
      },
    },
  },
  'Pinpoint:sendUsersMessages': {
    ApplicationId: 'required',
    SendUsersMessageRequest: {
      Context: '<optional>',
      TraceId: '<optional>',
      MessageConfiguration: {
        ADMMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ConsolidationKey: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          ExpiresAfter: '<optional>',
          IconReference: '<optional>',
          ImageIconUrl: '<optional>',
          ImageUrl: '<optional>',
          MD5: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          SmallImageIconUrl: '<optional>',
          Sound: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          Title: '<optional>',
          Url: '<optional>',
        },
        APNSMessage: {
          Action: '<optional>',
          Badge: '<optional>',
          Body: '<optional>',
          Category: '<optional>',
          CollapseId: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          MediaUrl: '<optional>',
          PreferredAuthenticationMethod: '<optional>',
          Priority: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          Sound: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          ThreadId: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        BaiduMessage: {
          Action: '<optional>',
          Body: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          IconReference: '<optional>',
          ImageIconUrl: '<optional>',
          ImageUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          SmallImageIconUrl: '<optional>',
          Sound: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        DefaultMessage: {
          Body: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
        },
        DefaultPushNotificationMessage: {
          Action: '<optional>',
          Body: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          SilentPush: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          Title: '<optional>',
          Url: '<optional>',
        },
        EmailMessage: {
          Body: '<optional>',
          FeedbackForwardingAddress: '<optional>',
          FromAddress: '<optional>',
          RawEmail: {
            Data: ['<optional1>', '<optional2>'],
          },
          ReplyToAddresses: ['<optional1>', '<optional2>'],
          SimpleEmail: {
            HtmlPart: {
              Charset: '<optional>',
              Data: ['<optional1>', '<optional2>'],
            },
            Subject: {
              Charset: '<optional>',
              Data: ['<optional1>', '<optional2>'],
            },
            TextPart: {
              Charset: '<optional>',
              Data: ['<optional1>', '<optional2>'],
            },
          },
          Substitutions: ['<optional1>', '<optional2>'],
        },
        GCMMessage: {
          Action: '<optional>',
          Body: '<optional>',
          CollapseKey: '<optional>',
          Data: ['<optional1>', '<optional2>'],
          IconReference: '<optional>',
          ImageIconUrl: '<optional>',
          ImageUrl: '<optional>',
          Priority: '<optional>',
          RawContent: '<optional>',
          RestrictedPackageName: '<optional>',
          SilentPush: '<optional>',
          SmallImageIconUrl: '<optional>',
          Sound: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        SMSMessage: {
          Body: '<optional>',
          Keyword: '<optional>',
          MessageType: '<optional>',
          OriginationNumber: '<optional>',
          SenderId: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
        },
        VoiceMessage: {
          Body: '<optional>',
          LanguageCode: '<optional>',
          OriginationNumber: '<optional>',
          Substitutions: ['<optional1>', '<optional2>'],
          VoiceId: '<optional>',
        },
      },
    },
  },
  'Pinpoint:tagResource': {
    ResourceArn: 'required',
    TagsModel: {
      tags: ['required1', 'required2'],
    },
  },
  'Pinpoint:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'Pinpoint:updateAdmChannel': {
    ApplicationId: 'required',
    ADMChannelRequest: {
      Enabled: '<optional>',
    },
  },
  'Pinpoint:updateApnsChannel': {
    ApplicationId: 'required',
    APNSChannelRequest: {
      BundleId: '<optional>',
      Certificate: '<optional>',
      DefaultAuthenticationMethod: '<optional>',
      Enabled: '<optional>',
      PrivateKey: '<optional>',
      TeamId: '<optional>',
      TokenKey: '<optional>',
      TokenKeyId: '<optional>',
    },
  },
  'Pinpoint:updateApnsSandboxChannel': {
    ApplicationId: 'required',
    APNSSandboxChannelRequest: {
      BundleId: '<optional>',
      Certificate: '<optional>',
      DefaultAuthenticationMethod: '<optional>',
      Enabled: '<optional>',
      PrivateKey: '<optional>',
      TeamId: '<optional>',
      TokenKey: '<optional>',
      TokenKeyId: '<optional>',
    },
  },
  'Pinpoint:updateApnsVoipChannel': {
    ApplicationId: 'required',
    APNSVoipChannelRequest: {
      BundleId: '<optional>',
      Certificate: '<optional>',
      DefaultAuthenticationMethod: '<optional>',
      Enabled: '<optional>',
      PrivateKey: '<optional>',
      TeamId: '<optional>',
      TokenKey: '<optional>',
      TokenKeyId: '<optional>',
    },
  },
  'Pinpoint:updateApnsVoipSandboxChannel': {
    ApplicationId: 'required',
    APNSVoipSandboxChannelRequest: {
      BundleId: '<optional>',
      Certificate: '<optional>',
      DefaultAuthenticationMethod: '<optional>',
      Enabled: '<optional>',
      PrivateKey: '<optional>',
      TeamId: '<optional>',
      TokenKey: '<optional>',
      TokenKeyId: '<optional>',
    },
  },
  'Pinpoint:updateApplicationSettings': {
    ApplicationId: 'required',
    WriteApplicationSettingsRequest: {
      CampaignHook: {
        LambdaFunctionName: '<optional>',
        Mode: '<optional>',
        WebUrl: '<optional>',
      },
      CloudWatchMetricsEnabled: '<optional>',
      Limits: [
        {
          Daily: '<optional>',
          MaximumDuration: '<optional>',
          MessagesPerSecond: '<optional>',
          Total: '<optional>',
        },
      ],
      QuietTime: {
        End: '<optional>',
        Start: '<optional>',
      },
    },
  },
  'Pinpoint:updateBaiduChannel': {
    ApplicationId: 'required',
    BaiduChannelRequest: {
      Enabled: '<optional>',
    },
  },
  'Pinpoint:updateCampaign': {
    CampaignId: 'required',
    ApplicationId: 'required',
    WriteCampaignRequest: {
      AdditionalTreatments: [
        {
          MessageConfiguration: {
            ADMMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            APNSMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            BaiduMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            DefaultMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            EmailMessage: {
              Body: '<optional>',
              FromAddress: '<optional>',
              HtmlBody: '<optional>',
            },
            GCMMessage: {
              Action: '<optional>',
              Body: '<optional>',
              ImageIconUrl: '<optional>',
              ImageSmallIconUrl: '<optional>',
              ImageUrl: '<optional>',
              JsonBody: '<optional>',
              MediaUrl: '<optional>',
              RawContent: '<optional>',
              SilentPush: '<optional>',
              TimeToLive: '<optional>',
              Title: '<optional>',
              Url: '<optional>',
            },
            SMSMessage: {
              Body: '<optional>',
              MessageType: '<optional>',
              SenderId: '<optional>',
            },
          },
          Schedule: {
            EndTime: '<optional>',
            EventFilter: {
              Dimensions: [
                {
                  Attributes: ['<optional1>', '<optional2>'],
                  EventType: {
                    DimensionType: '<optional>',
                  },
                  Metrics: ['<optional1>', '<optional2>'],
                },
              ],
            },
            Frequency: '<optional>',
            IsLocalTime: '<optional>',
            QuietTime: {
              End: '<optional>',
              Start: '<optional>',
            },
            Timezone: '<optional>',
          },
          TreatmentDescription: '<optional>',
          TreatmentName: '<optional>',
        },
      ],
      Description: '<optional>',
      HoldoutPercent: '<optional>',
      Hook: {
        LambdaFunctionName: '<optional>',
        Mode: '<optional>',
        WebUrl: '<optional>',
      },
      IsPaused: '<optional>',
      Limits: [
        {
          Daily: '<optional>',
          MaximumDuration: '<optional>',
          MessagesPerSecond: '<optional>',
          Total: '<optional>',
        },
      ],
      MessageConfiguration: {
        ADMMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        APNSMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        BaiduMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        DefaultMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        EmailMessage: {
          Body: '<optional>',
          FromAddress: '<optional>',
          HtmlBody: '<optional>',
        },
        GCMMessage: {
          Action: '<optional>',
          Body: '<optional>',
          ImageIconUrl: '<optional>',
          ImageSmallIconUrl: '<optional>',
          ImageUrl: '<optional>',
          JsonBody: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          SilentPush: '<optional>',
          TimeToLive: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
        SMSMessage: {
          Body: '<optional>',
          MessageType: '<optional>',
          SenderId: '<optional>',
        },
      },
      Name: '<optional>',
      Schedule: {
        EndTime: '<optional>',
        EventFilter: {
          Dimensions: [
            {
              Attributes: ['<optional1>', '<optional2>'],
              EventType: {
                DimensionType: '<optional>',
              },
              Metrics: ['<optional1>', '<optional2>'],
            },
          ],
        },
        Frequency: '<optional>',
        IsLocalTime: '<optional>',
        QuietTime: {
          End: '<optional>',
          Start: '<optional>',
        },
        Timezone: '<optional>',
      },
      SegmentId: '<optional>',
      SegmentVersion: '<optional>',
      tags: ['<optional1>', '<optional2>'],
      TreatmentDescription: '<optional>',
      TreatmentName: '<optional>',
    },
  },
  'Pinpoint:updateEmailChannel': {
    ApplicationId: 'required',
    EmailChannelRequest: {
      ConfigurationSet: '<optional>',
      Enabled: '<optional>',
      RoleArn: '<optional>',
    },
  },
  'Pinpoint:updateEmailTemplate': {
    TemplateName: 'required',
    EmailTemplateRequest: {
      DefaultSubstitutions: ['<optional1>', '<optional2>'],
      HtmlPart: '<optional>',
      Subject: '<optional>',
      tags: ['<optional1>', '<optional2>'],
      TemplateDescription: '<optional>',
      TextPart: '<optional>',
    },
    CreateNewVersion: '<optional>',
    Version: '<optional>',
  },
  'Pinpoint:updateEndpoint': {
    ApplicationId: 'required',
    EndpointId: 'required',
    EndpointRequest: {
      Address: '<optional>',
      Attributes: ['<optional1>', '<optional2>'],
      ChannelType: '<optional>',
      Demographic: {
        AppVersion: '<optional>',
        Locale: '<optional>',
        Make: '<optional>',
        Model: '<optional>',
        ModelVersion: '<optional>',
        Platform: '<optional>',
        PlatformVersion: '<optional>',
        Timezone: '<optional>',
      },
      EffectiveDate: '<optional>',
      EndpointStatus: '<optional>',
      Location: {
        City: '<optional>',
        Country: '<optional>',
        Latitude: '<optional>',
        Longitude: '<optional>',
        PostalCode: '<optional>',
        Region: '<optional>',
      },
      Metrics: ['<optional1>', '<optional2>'],
      OptOut: '<optional>',
      RequestId: '<optional>',
      User: {
        UserAttributes: ['<optional1>', '<optional2>'],
        UserId: '<optional>',
      },
    },
  },
  'Pinpoint:updateEndpointsBatch': {
    ApplicationId: 'required',
    EndpointBatchRequest: {
      Item: {
        Address: '<optional>',
        Attributes: ['<optional1>', '<optional2>'],
        ChannelType: '<optional>',
        Demographic: {
          AppVersion: '<optional>',
          Locale: '<optional>',
          Make: '<optional>',
          Model: '<optional>',
          ModelVersion: '<optional>',
          Platform: '<optional>',
          PlatformVersion: '<optional>',
          Timezone: '<optional>',
        },
        EffectiveDate: '<optional>',
        EndpointStatus: '<optional>',
        Id: '<optional>',
        Location: {
          City: '<optional>',
          Country: '<optional>',
          Latitude: '<optional>',
          Longitude: '<optional>',
          PostalCode: '<optional>',
          Region: '<optional>',
        },
        Metrics: ['<optional1>', '<optional2>'],
        OptOut: '<optional>',
        RequestId: '<optional>',
        User: {
          UserAttributes: ['<optional1>', '<optional2>'],
          UserId: '<optional>',
        },
      },
    },
  },
  'Pinpoint:updateGcmChannel': {
    ApplicationId: 'required',
    GCMChannelRequest: {
      Enabled: '<optional>',
    },
  },
  'Pinpoint:updateJourney': {
    JourneyId: 'required',
    ApplicationId: 'required',
    WriteJourneyRequest: {
      Activities: ['<optional1>', '<optional2>'],
      CreationDate: '<optional>',
      LastModifiedDate: '<optional>',
      Limits: [
        {
          DailyCap: '<optional>',
          EndpointReentryCap: '<optional>',
          MessagesPerSecond: '<optional>',
        },
      ],
      LocalTime: '<optional>',
      QuietTime: {
        End: '<optional>',
        Start: '<optional>',
      },
      RefreshFrequency: '<optional>',
      Schedule: {
        EndTime: '<optional>',
        StartTime: '<optional>',
        Timezone: '<optional>',
      },
      StartActivity: '<optional>',
      StartCondition: {
        Description: '<optional>',
        SegmentStartCondition: '<optional>',
      },
      State: '<optional>',
    },
  },
  'Pinpoint:updateJourneyState': {
    JourneyId: 'required',
    ApplicationId: 'required',
    JourneyStateRequest: {
      State: '<optional>',
    },
  },
  'Pinpoint:updatePushTemplate': {
    TemplateName: 'required',
    PushNotificationTemplateRequest: {
      ADM: {
        Action: '<optional>',
        Body: '<optional>',
        ImageIconUrl: '<optional>',
        ImageUrl: '<optional>',
        RawContent: '<optional>',
        SmallImageIconUrl: '<optional>',
        Sound: '<optional>',
        Title: '<optional>',
        Url: '<optional>',
      },
      APNS: [
        {
          Action: '<optional>',
          Body: '<optional>',
          MediaUrl: '<optional>',
          RawContent: '<optional>',
          Sound: '<optional>',
          Title: '<optional>',
          Url: '<optional>',
        },
      ],
      Baidu: {
        Action: '<optional>',
        Body: '<optional>',
        ImageIconUrl: '<optional>',
        ImageUrl: '<optional>',
        RawContent: '<optional>',
        SmallImageIconUrl: '<optional>',
        Sound: '<optional>',
        Title: '<optional>',
        Url: '<optional>',
      },
      Default: {
        Action: '<optional>',
        Body: '<optional>',
        Sound: '<optional>',
        Title: '<optional>',
        Url: '<optional>',
      },
      DefaultSubstitutions: ['<optional1>', '<optional2>'],
      GCM: {
        Action: '<optional>',
        Body: '<optional>',
        ImageIconUrl: '<optional>',
        ImageUrl: '<optional>',
        RawContent: '<optional>',
        SmallImageIconUrl: '<optional>',
        Sound: '<optional>',
        Title: '<optional>',
        Url: '<optional>',
      },
      tags: ['<optional1>', '<optional2>'],
      TemplateDescription: '<optional>',
    },
    CreateNewVersion: '<optional>',
    Version: '<optional>',
  },
  'Pinpoint:updateSegment': {
    SegmentId: 'required',
    ApplicationId: 'required',
    WriteSegmentRequest: {
      Dimensions: [
        {
          Attributes: ['<optional1>', '<optional2>'],
          Behavior: {
            Recency: '<optional>',
          },
          Demographic: {
            AppVersion: {
              DimensionType: '<optional>',
            },
            Channel: {
              DimensionType: '<optional>',
            },
            DeviceType: {
              DimensionType: '<optional>',
            },
            Make: {
              DimensionType: '<optional>',
            },
            Model: {
              DimensionType: '<optional>',
            },
            Platform: {
              DimensionType: '<optional>',
            },
          },
          Location: {
            Country: {
              DimensionType: '<optional>',
            },
            GPSPoint: {
              RangeInKilometers: ['<optional1>', '<optional2>'],
            },
          },
          Metrics: ['<optional1>', '<optional2>'],
          UserAttributes: ['<optional1>', '<optional2>'],
        },
      ],
      Name: '<optional>',
      SegmentGroups: [
        {
          Groups: [
            {
              Dimensions: [
                {
                  Attributes: ['<optional1>', '<optional2>'],
                  Behavior: {
                    Recency: '<optional>',
                  },
                  Demographic: {
                    AppVersion: {
                      DimensionType: '<optional>',
                    },
                    Channel: {
                      DimensionType: '<optional>',
                    },
                    DeviceType: {
                      DimensionType: '<optional>',
                    },
                    Make: {
                      DimensionType: '<optional>',
                    },
                    Model: {
                      DimensionType: '<optional>',
                    },
                    Platform: {
                      DimensionType: '<optional>',
                    },
                  },
                  Location: {
                    Country: {
                      DimensionType: '<optional>',
                    },
                    GPSPoint: {
                      RangeInKilometers: ['<optional1>', '<optional2>'],
                    },
                  },
                  Metrics: ['<optional1>', '<optional2>'],
                  UserAttributes: ['<optional1>', '<optional2>'],
                },
              ],
              SourceSegments: [
                {
                  Version: '<optional>',
                },
              ],
              SourceType: '<optional>',
              Type: '<optional>',
            },
          ],
          Include: '<optional>',
        },
      ],
      tags: ['<optional1>', '<optional2>'],
    },
  },
  'Pinpoint:updateSmsChannel': {
    ApplicationId: 'required',
    SMSChannelRequest: {
      Enabled: '<optional>',
      SenderId: '<optional>',
      ShortCode: '<optional>',
    },
  },
  'Pinpoint:updateSmsTemplate': {
    TemplateName: 'required',
    SMSTemplateRequest: {
      Body: '<optional>',
      DefaultSubstitutions: ['<optional1>', '<optional2>'],
      tags: ['<optional1>', '<optional2>'],
      TemplateDescription: '<optional>',
    },
    CreateNewVersion: '<optional>',
    Version: '<optional>',
  },
  'Pinpoint:updateTemplateActiveVersion': {
    TemplateName: 'required',
    TemplateType: 'required',
    TemplateActiveVersionRequest: {
      Version: '<optional>',
    },
  },
  'Pinpoint:updateVoiceChannel': {
    ApplicationId: 'required',
    VoiceChannelRequest: {
      Enabled: '<optional>',
    },
  },
  'Pinpoint:updateVoiceTemplate': {
    TemplateName: 'required',
    VoiceTemplateRequest: {
      Body: '<optional>',
      DefaultSubstitutions: ['<optional1>', '<optional2>'],
      LanguageCode: '<optional>',
      tags: ['<optional1>', '<optional2>'],
      TemplateDescription: '<optional>',
      VoiceId: '<optional>',
    },
    CreateNewVersion: '<optional>',
    Version: '<optional>',
  },
  'PinpointEmail:createConfigurationSet': {
    ConfigurationSetName: 'required',
    TrackingOptions: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    DeliveryOptions: [
      {
        TlsPolicy: '<optional>',
        SendingPoolName: '<optional>',
      },
    ],
    ReputationOptions: [
      {
        ReputationMetricsEnabled: '<optional>',
        LastFreshStart: '<optional>',
      },
    ],
    SendingOptions: [
      {
        SendingEnabled: '<optional>',
      },
    ],
  },
  'PinpointEmail:createConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestinationName: 'required',
    EventDestination: {
      Enabled: '<optional>',
      MatchingEventTypes: ['<optional1>', '<optional2>'],
      KinesisFirehoseDestination: '<optional>',
      CloudWatchDestination: '<optional>',
      SnsDestination: '<optional>',
      PinpointDestination: {
        ApplicationArn: '<optional>',
      },
    },
  },
  'PinpointEmail:createDedicatedIpPool': {
    PoolName: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'PinpointEmail:createDeliverabilityTestReport': {
    FromEmailAddress: 'required',
    Content: {
      Simple: {
        Subject: {
          Charset: '<optional>',
        },
        Body: {
          Text: {
            Charset: '<optional>',
          },
          Html: {
            Charset: '<optional>',
          },
        },
      },
      Raw: '<optional>',
    },
    Tags: ['<optional1>', '<optional2>'],
    ReportName: '<optional>',
  },
  'PinpointEmail:createEmailIdentity': {
    EmailIdentity: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'PinpointEmail:deleteConfigurationSet': {
    ConfigurationSetName: 'required',
  },
  'PinpointEmail:deleteConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestinationName: 'required',
  },
  'PinpointEmail:deleteDedicatedIpPool': {
    PoolName: 'required',
  },
  'PinpointEmail:deleteEmailIdentity': {
    EmailIdentity: 'required',
  },
  'PinpointEmail:getAccount': {},
  'PinpointEmail:getBlacklistReports': {
    BlacklistItemNames: ['required1', 'required2'],
  },
  'PinpointEmail:getConfigurationSet': {
    ConfigurationSetName: 'required',
  },
  'PinpointEmail:getConfigurationSetEventDestinations': {
    ConfigurationSetName: 'required',
  },
  'PinpointEmail:getDedicatedIp': {
    Ip: 'required',
  },
  'PinpointEmail:getDedicatedIps': {
    PoolName: '<optional>',
    PageSize: '<optional>',
  },
  'PinpointEmail:getDeliverabilityDashboardOptions': {},
  'PinpointEmail:getDeliverabilityTestReport': {
    ReportId: 'required',
  },
  'PinpointEmail:getDomainDeliverabilityCampaign': {
    CampaignId: 'required',
  },
  'PinpointEmail:getDomainStatisticsReport': {
    Domain: 'required',
    StartDate: 'required',
    EndDate: 'required',
  },
  'PinpointEmail:getEmailIdentity': {
    EmailIdentity: 'required',
  },
  'PinpointEmail:listConfigurationSets': {
    PageSize: '<optional>',
  },
  'PinpointEmail:listDedicatedIpPools': {
    PageSize: '<optional>',
  },
  'PinpointEmail:listDeliverabilityTestReports': {
    PageSize: '<optional>',
  },
  'PinpointEmail:listDomainDeliverabilityCampaigns': {
    StartDate: 'required',
    EndDate: 'required',
    SubscribedDomain: 'required',
    PageSize: '<optional>',
  },
  'PinpointEmail:listEmailIdentities': {
    PageSize: '<optional>',
  },
  'PinpointEmail:listTagsForResource': {
    ResourceArn: 'required',
  },
  'PinpointEmail:putAccountDedicatedIpWarmupAttributes': {
    AutoWarmupEnabled: '<optional>',
  },
  'PinpointEmail:putAccountSendingAttributes': {
    SendingEnabled: '<optional>',
  },
  'PinpointEmail:putConfigurationSetDeliveryOptions': {
    ConfigurationSetName: 'required',
    TlsPolicy: '<optional>',
    SendingPoolName: '<optional>',
  },
  'PinpointEmail:putConfigurationSetReputationOptions': {
    ConfigurationSetName: 'required',
    ReputationMetricsEnabled: '<optional>',
  },
  'PinpointEmail:putConfigurationSetSendingOptions': {
    ConfigurationSetName: 'required',
    SendingEnabled: '<optional>',
  },
  'PinpointEmail:putConfigurationSetTrackingOptions': {
    ConfigurationSetName: 'required',
    CustomRedirectDomain: '<optional>',
  },
  'PinpointEmail:putDedicatedIpInPool': {
    Ip: 'required',
    DestinationPoolName: 'required',
  },
  'PinpointEmail:putDedicatedIpWarmupAttributes': {
    Ip: 'required',
    WarmupPercentage: 'required',
  },
  'PinpointEmail:putDeliverabilityDashboardOption': {
    DashboardEnabled: 'required',
    SubscribedDomains: [
      {
        Domain: '<optional>',
        SubscriptionStartDate: '<optional>',
        InboxPlacementTrackingOption: {
          Global: '<optional>',
          TrackedIsps: ['<optional1>', '<optional2>'],
        },
      },
    ],
  },
  'PinpointEmail:putEmailIdentityDkimAttributes': {
    EmailIdentity: 'required',
    SigningEnabled: '<optional>',
  },
  'PinpointEmail:putEmailIdentityFeedbackAttributes': {
    EmailIdentity: 'required',
    EmailForwardingEnabled: '<optional>',
  },
  'PinpointEmail:putEmailIdentityMailFromAttributes': {
    EmailIdentity: 'required',
    MailFromDomain: '<optional>',
    BehaviorOnMxFailure: '<optional>',
  },
  'PinpointEmail:sendEmail': {
    Destination: {
      ToAddresses: ['<optional1>', '<optional2>'],
      CcAddresses: ['<optional1>', '<optional2>'],
      BccAddresses: ['<optional1>', '<optional2>'],
    },
    Content: {
      Simple: {
        Subject: {
          Charset: '<optional>',
        },
        Body: {
          Text: {
            Charset: '<optional>',
          },
          Html: {
            Charset: '<optional>',
          },
        },
      },
      Raw: '<optional>',
    },
    EmailTags: ['<optional1>', '<optional2>'],
    FromEmailAddress: '<optional>',
    ReplyToAddresses: ['<optional1>', '<optional2>'],
    FeedbackForwardingEmailAddress: '<optional>',
    ConfigurationSetName: '<optional>',
  },
  'PinpointEmail:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'PinpointEmail:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'PinpointEmail:updateConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestinationName: 'required',
    EventDestination: {
      Enabled: '<optional>',
      MatchingEventTypes: ['<optional1>', '<optional2>'],
      KinesisFirehoseDestination: '<optional>',
      CloudWatchDestination: '<optional>',
      SnsDestination: '<optional>',
      PinpointDestination: {
        ApplicationArn: '<optional>',
      },
    },
  },
  'PinpointSMSVoice:createConfigurationSet': {
    ConfigurationSetName: '<optional>',
  },
  'PinpointSMSVoice:createConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestination: {
      CloudWatchLogsDestination: {
        IamRoleArn: '<optional>',
        LogGroupArn: '<optional>',
      },
      Enabled: '<optional>',
      KinesisFirehoseDestination: {
        DeliveryStreamArn: '<optional>',
        IamRoleArn: '<optional>',
      },
      MatchingEventTypes: ['<optional1>', '<optional2>'],
      SnsDestination: {
        TopicArn: '<optional>',
      },
    },
    EventDestinationName: '<optional>',
  },
  'PinpointSMSVoice:deleteConfigurationSet': {
    ConfigurationSetName: 'required',
  },
  'PinpointSMSVoice:deleteConfigurationSetEventDestination': {
    EventDestinationName: 'required',
    ConfigurationSetName: 'required',
  },
  'PinpointSMSVoice:getConfigurationSetEventDestinations': {
    ConfigurationSetName: 'required',
  },
  'PinpointSMSVoice:listConfigurationSets': {
    PageSize: '<optional>',
  },
  'PinpointSMSVoice:sendVoiceMessage': {
    CallerId: '<optional>',
    ConfigurationSetName: '<optional>',
    Content: {
      CallInstructionsMessage: {
        Text: '<optional>',
      },
      PlainTextMessage: {
        LanguageCode: '<optional>',
        Text: '<optional>',
        VoiceId: '<optional>',
      },
      SSMLMessage: {
        LanguageCode: '<optional>',
        Text: '<optional>',
        VoiceId: '<optional>',
      },
    },
    DestinationPhoneNumber: '<optional>',
    OriginationPhoneNumber: '<optional>',
  },
  'PinpointSMSVoice:updateConfigurationSetEventDestination': {
    EventDestinationName: 'required',
    ConfigurationSetName: 'required',
    EventDestination: {
      CloudWatchLogsDestination: {
        IamRoleArn: '<optional>',
        LogGroupArn: '<optional>',
      },
      Enabled: '<optional>',
      KinesisFirehoseDestination: {
        DeliveryStreamArn: '<optional>',
        IamRoleArn: '<optional>',
      },
      MatchingEventTypes: ['<optional1>', '<optional2>'],
      SnsDestination: {
        TopicArn: '<optional>',
      },
    },
  },
  'Polly:deleteLexicon': {
    Name: 'required',
  },
  'Polly:describeVoices': {
    LanguageCode: '<optional>',
    IncludeAdditionalLanguageCodes: ['<optional1>', '<optional2>'],
  },
  'Polly:getLexicon': {
    Name: 'required',
  },
  'Polly:getSpeechSynthesisTask': {
    TaskId: 'required',
  },
  'Polly:listLexicons': {},
  'Polly:listSpeechSynthesisTasks': {
    Status: '<optional>',
  },
  'Polly:putLexicon': {
    Name: 'required',
    Content: 'required',
  },
  'Polly:startSpeechSynthesisTask': {
    OutputFormat: 'required',
    OutputS3BucketName: 'required',
    Text: 'required',
    VoiceId: 'required',
    LexiconNames: ['<optional1>', '<optional2>'],
    OutputS3KeyPrefix: '<optional>',
    SampleRate: '<optional>',
    SnsTopicArn: '<optional>',
    SpeechMarkTypes: ['<optional1>', '<optional2>'],
    TextType: '<optional>',
    LanguageCode: '<optional>',
  },
  'Polly:synthesizeSpeech': {
    OutputFormat: 'required',
    Text: 'required',
    VoiceId: 'required',
    LexiconNames: ['<optional1>', '<optional2>'],
    SampleRate: '<optional>',
    SpeechMarkTypes: ['<optional1>', '<optional2>'],
    TextType: '<optional>',
    LanguageCode: '<optional>',
  },
  'Pricing:describeServices': {
    ServiceCode: '<optional>',
    FormatVersion: '<optional>',
  },
  'Pricing:getAttributeValues': {
    ServiceCode: 'required',
    AttributeName: 'required',
  },
  'Pricing:getProducts': {
    ServiceCode: '<optional>',
    Filters: [
      {
        Type: '<optional>',
        Field: '<optional>',
        Value: '<optional>',
      },
    ],
    FormatVersion: '<optional>',
  },
  'QLDB:createLedger': {
    Name: 'required',
    PermissionsMode: 'required',
    Tags: ['<optional1>', '<optional2>'],
    DeletionProtection: '<optional>',
  },
  'QLDB:deleteLedger': {
    Name: 'required',
  },
  'QLDB:describeJournalS3Export': {
    Name: 'required',
    ExportId: 'required',
  },
  'QLDB:describeLedger': {
    Name: 'required',
  },
  'QLDB:exportJournalToS3': {
    Name: 'required',
    InclusiveStartTime: 'required',
    ExclusiveEndTime: 'required',
    S3ExportConfiguration: {
      EncryptionConfiguration: {
        KmsKeyArn: '<optional>',
      },
    },
    RoleArn: 'required',
  },
  'QLDB:getBlock': {
    Name: 'required',
    BlockAddress: {
      IonText: '<optional>',
    },
    DigestTipAddress: {
      IonText: '<optional>',
    },
  },
  'QLDB:getDigest': {
    Name: 'required',
  },
  'QLDB:getRevision': {
    Name: 'required',
    BlockAddress: {
      IonText: '<optional>',
    },
    DocumentId: 'required',
    DigestTipAddress: {
      IonText: '<optional>',
    },
  },
  'QLDB:listJournalS3Exports': {},
  'QLDB:listJournalS3ExportsForLedger': {
    Name: 'required',
  },
  'QLDB:listLedgers': {},
  'QLDB:listTagsForResource': {
    ResourceArn: 'required',
  },
  'QLDB:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'QLDB:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'QLDB:updateLedger': {
    Name: 'required',
    DeletionProtection: '<optional>',
  },
  'QLDBSession:sendCommand': {
    StartSession: '<optional>',
    CommitTransaction: '<optional>',
    ExecuteStatement: {
      Parameters: [
        {
          IonBinary: '<optional>',
          IonText: '<optional>',
        },
      ],
    },
    FetchPage: '<optional>',
    SessionToken: '<optional>',
    StartTransaction: '<optional>',
    EndSession: '<optional>',
    AbortTransaction: '<optional>',
  },
  'QuickSight:cancelIngestion': {
    AwsAccountId: 'required',
    DataSetId: 'required',
    IngestionId: 'required',
  },
  'QuickSight:createDashboard': {
    AwsAccountId: 'required',
    DashboardId: 'required',
    Name: 'required',
    SourceEntity: {
      SourceTemplate: '<optional>',
    },
    Parameters: [
      {
        StringParameters: ['<optional1>', '<optional2>'],
        IntegerParameters: ['<optional1>', '<optional2>'],
        DecimalParameters: ['<optional1>', '<optional2>'],
        DateTimeParameters: ['<optional1>', '<optional2>'],
      },
    ],
    Permissions: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    VersionDescription: '<optional>',
    DashboardPublishOptions: [
      {
        AdHocFilteringOption: {
          AvailabilityStatus: '<optional>',
        },
        ExportToCSVOption: {
          AvailabilityStatus: '<optional>',
        },
        SheetControlsOption: {
          VisibilityState: '<optional>',
        },
      },
    ],
  },
  'QuickSight:createDataSet': {
    AwsAccountId: 'required',
    DataSetId: 'required',
    Name: 'required',
    PhysicalTableMap: 'required',
    ImportMode: 'required',
    ColumnGroups: [
      {
        GeoSpatialColumnGroup: '<optional>',
      },
    ],
    Permissions: ['<optional1>', '<optional2>'],
    RowLevelPermissionDataSet: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
    LogicalTableMap: '<optional>',
  },
  'QuickSight:createDataSource': {
    AwsAccountId: 'required',
    DataSourceId: 'required',
    Name: 'required',
    Type: 'required',
    DataSourceParameters: [
      {
        AmazonElasticsearchParameters: ['<optional1>', '<optional2>'],
        AthenaParameters: [
          {
            WorkGroup: '<optional>',
          },
        ],
        AuroraParameters: ['<optional1>', '<optional2>'],
        AuroraPostgreSqlParameters: ['<optional1>', '<optional2>'],
        AwsIotAnalyticsParameters: ['<optional1>', '<optional2>'],
        JiraParameters: ['<optional1>', '<optional2>'],
        MariaDbParameters: ['<optional1>', '<optional2>'],
        MySqlParameters: ['<optional1>', '<optional2>'],
        PostgreSqlParameters: ['<optional1>', '<optional2>'],
        PrestoParameters: ['<optional1>', '<optional2>'],
        RdsParameters: ['<optional1>', '<optional2>'],
        RedshiftParameters: [
          {
            Host: '<optional>',
            Port: '<optional>',
            ClusterId: '<optional>',
          },
        ],
        S3Parameters: ['<optional1>', '<optional2>'],
        ServiceNowParameters: ['<optional1>', '<optional2>'],
        SnowflakeParameters: ['<optional1>', '<optional2>'],
        SparkParameters: ['<optional1>', '<optional2>'],
        SqlServerParameters: ['<optional1>', '<optional2>'],
        TeradataParameters: ['<optional1>', '<optional2>'],
        TwitterParameters: ['<optional1>', '<optional2>'],
      },
    ],
    Credentials: [
      {
        CredentialPair: '<optional>',
      },
    ],
    Permissions: ['<optional1>', '<optional2>'],
    VpcConnectionProperties: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    SslProperties: [
      {
        DisableSsl: '<optional>',
      },
    ],
  },
  'QuickSight:createGroup': {
    GroupName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
    Description: '<optional>',
  },
  'QuickSight:createGroupMembership': {
    MemberName: 'required',
    GroupName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:createIAMPolicyAssignment': {
    AwsAccountId: 'required',
    AssignmentName: 'required',
    AssignmentStatus: 'required',
    Namespace: 'required',
    PolicyArn: '<optional>',
    Identities: ['<optional1>', '<optional2>'],
  },
  'QuickSight:createIngestion': {
    DataSetId: 'required',
    IngestionId: 'required',
    AwsAccountId: 'required',
  },
  'QuickSight:createTemplate': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    SourceEntity: {
      SourceAnalysis: '<optional>',
      SourceTemplate: '<optional>',
    },
    Permissions: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    Name: '<optional>',
    VersionDescription: '<optional>',
  },
  'QuickSight:createTemplateAlias': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    AliasName: 'required',
    TemplateVersionNumber: 'required',
  },
  'QuickSight:deleteDashboard': {
    AwsAccountId: 'required',
    DashboardId: 'required',
    VersionNumber: '<optional>',
  },
  'QuickSight:deleteDataSet': {
    AwsAccountId: 'required',
    DataSetId: 'required',
  },
  'QuickSight:deleteDataSource': {
    AwsAccountId: 'required',
    DataSourceId: 'required',
  },
  'QuickSight:deleteGroup': {
    GroupName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:deleteGroupMembership': {
    MemberName: 'required',
    GroupName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:deleteIAMPolicyAssignment': {
    AwsAccountId: 'required',
    AssignmentName: 'required',
    Namespace: 'required',
  },
  'QuickSight:deleteTemplate': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    VersionNumber: '<optional>',
  },
  'QuickSight:deleteTemplateAlias': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    AliasName: 'required',
  },
  'QuickSight:deleteUser': {
    UserName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:deleteUserByPrincipalId': {
    PrincipalId: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:describeDashboard': {
    AwsAccountId: 'required',
    DashboardId: 'required',
    VersionNumber: '<optional>',
    AliasName: '<optional>',
  },
  'QuickSight:describeDashboardPermissions': {
    AwsAccountId: 'required',
    DashboardId: 'required',
  },
  'QuickSight:describeDataSet': {
    AwsAccountId: 'required',
    DataSetId: 'required',
  },
  'QuickSight:describeDataSetPermissions': {
    AwsAccountId: 'required',
    DataSetId: 'required',
  },
  'QuickSight:describeDataSource': {
    AwsAccountId: 'required',
    DataSourceId: 'required',
  },
  'QuickSight:describeDataSourcePermissions': {
    AwsAccountId: 'required',
    DataSourceId: 'required',
  },
  'QuickSight:describeGroup': {
    GroupName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:describeIAMPolicyAssignment': {
    AwsAccountId: 'required',
    AssignmentName: 'required',
    Namespace: 'required',
  },
  'QuickSight:describeIngestion': {
    AwsAccountId: 'required',
    DataSetId: 'required',
    IngestionId: 'required',
  },
  'QuickSight:describeTemplate': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    VersionNumber: '<optional>',
    AliasName: '<optional>',
  },
  'QuickSight:describeTemplateAlias': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    AliasName: 'required',
  },
  'QuickSight:describeTemplatePermissions': {
    AwsAccountId: 'required',
    TemplateId: 'required',
  },
  'QuickSight:describeUser': {
    UserName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:getDashboardEmbedUrl': {
    AwsAccountId: 'required',
    DashboardId: 'required',
    IdentityType: 'required',
    SessionLifetimeInMinutes: ['<optional1>', '<optional2>'],
    UndoRedoDisabled: '<optional>',
    ResetDisabled: '<optional>',
    UserArn: '<optional>',
  },
  'QuickSight:listDashboardVersions': {
    AwsAccountId: 'required',
    DashboardId: 'required',
  },
  'QuickSight:listDashboards': {
    AwsAccountId: 'required',
  },
  'QuickSight:listDataSets': {
    AwsAccountId: 'required',
  },
  'QuickSight:listDataSources': {
    AwsAccountId: 'required',
  },
  'QuickSight:listGroupMemberships': {
    GroupName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:listGroups': {
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:listIAMPolicyAssignments': {
    AwsAccountId: 'required',
    Namespace: 'required',
    AssignmentStatus: '<optional>',
  },
  'QuickSight:listIAMPolicyAssignmentsForUser': {
    AwsAccountId: 'required',
    UserName: 'required',
    Namespace: 'required',
  },
  'QuickSight:listIngestions': {
    DataSetId: 'required',
    AwsAccountId: 'required',
  },
  'QuickSight:listTagsForResource': {
    ResourceArn: 'required',
  },
  'QuickSight:listTemplateAliases': {
    AwsAccountId: 'required',
    TemplateId: 'required',
  },
  'QuickSight:listTemplateVersions': {
    AwsAccountId: 'required',
    TemplateId: 'required',
  },
  'QuickSight:listTemplates': {
    AwsAccountId: 'required',
  },
  'QuickSight:listUserGroups': {
    UserName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:listUsers': {
    AwsAccountId: 'required',
    Namespace: 'required',
  },
  'QuickSight:registerUser': {
    IdentityType: 'required',
    Email: 'required',
    UserRole: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
    IamArn: '<optional>',
    SessionName: '<optional>',
    UserName: '<optional>',
  },
  'QuickSight:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'QuickSight:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'QuickSight:updateDashboard': {
    AwsAccountId: 'required',
    DashboardId: 'required',
    Name: 'required',
    SourceEntity: {
      SourceTemplate: '<optional>',
    },
    Parameters: [
      {
        StringParameters: ['<optional1>', '<optional2>'],
        IntegerParameters: ['<optional1>', '<optional2>'],
        DecimalParameters: ['<optional1>', '<optional2>'],
        DateTimeParameters: ['<optional1>', '<optional2>'],
      },
    ],
    VersionDescription: '<optional>',
    DashboardPublishOptions: [
      {
        AdHocFilteringOption: {
          AvailabilityStatus: '<optional>',
        },
        ExportToCSVOption: {
          AvailabilityStatus: '<optional>',
        },
        SheetControlsOption: {
          VisibilityState: '<optional>',
        },
      },
    ],
  },
  'QuickSight:updateDashboardPermissions': {
    AwsAccountId: 'required',
    DashboardId: 'required',
    GrantPermissions: ['<optional1>', '<optional2>'],
    RevokePermissions: ['<optional1>', '<optional2>'],
  },
  'QuickSight:updateDashboardPublishedVersion': {
    AwsAccountId: 'required',
    DashboardId: 'required',
    VersionNumber: 'required',
  },
  'QuickSight:updateDataSet': {
    AwsAccountId: 'required',
    DataSetId: 'required',
    Name: 'required',
    PhysicalTableMap: 'required',
    ImportMode: 'required',
    ColumnGroups: [
      {
        GeoSpatialColumnGroup: '<optional>',
      },
    ],
    RowLevelPermissionDataSet: '<optional>',
    LogicalTableMap: '<optional>',
  },
  'QuickSight:updateDataSetPermissions': {
    AwsAccountId: 'required',
    DataSetId: 'required',
    GrantPermissions: ['<optional1>', '<optional2>'],
    RevokePermissions: ['<optional1>', '<optional2>'],
  },
  'QuickSight:updateDataSource': {
    AwsAccountId: 'required',
    DataSourceId: 'required',
    Name: 'required',
    DataSourceParameters: [
      {
        AmazonElasticsearchParameters: ['<optional1>', '<optional2>'],
        AthenaParameters: [
          {
            WorkGroup: '<optional>',
          },
        ],
        AuroraParameters: ['<optional1>', '<optional2>'],
        AuroraPostgreSqlParameters: ['<optional1>', '<optional2>'],
        AwsIotAnalyticsParameters: ['<optional1>', '<optional2>'],
        JiraParameters: ['<optional1>', '<optional2>'],
        MariaDbParameters: ['<optional1>', '<optional2>'],
        MySqlParameters: ['<optional1>', '<optional2>'],
        PostgreSqlParameters: ['<optional1>', '<optional2>'],
        PrestoParameters: ['<optional1>', '<optional2>'],
        RdsParameters: ['<optional1>', '<optional2>'],
        RedshiftParameters: [
          {
            Host: '<optional>',
            Port: '<optional>',
            ClusterId: '<optional>',
          },
        ],
        S3Parameters: ['<optional1>', '<optional2>'],
        ServiceNowParameters: ['<optional1>', '<optional2>'],
        SnowflakeParameters: ['<optional1>', '<optional2>'],
        SparkParameters: ['<optional1>', '<optional2>'],
        SqlServerParameters: ['<optional1>', '<optional2>'],
        TeradataParameters: ['<optional1>', '<optional2>'],
        TwitterParameters: ['<optional1>', '<optional2>'],
      },
    ],
    Credentials: [
      {
        CredentialPair: '<optional>',
      },
    ],
    VpcConnectionProperties: ['<optional1>', '<optional2>'],
    SslProperties: [
      {
        DisableSsl: '<optional>',
      },
    ],
  },
  'QuickSight:updateDataSourcePermissions': {
    AwsAccountId: 'required',
    DataSourceId: 'required',
    GrantPermissions: ['<optional1>', '<optional2>'],
    RevokePermissions: ['<optional1>', '<optional2>'],
  },
  'QuickSight:updateGroup': {
    GroupName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
    Description: '<optional>',
  },
  'QuickSight:updateIAMPolicyAssignment': {
    AwsAccountId: 'required',
    AssignmentName: 'required',
    Namespace: 'required',
    AssignmentStatus: '<optional>',
    PolicyArn: '<optional>',
    Identities: ['<optional1>', '<optional2>'],
  },
  'QuickSight:updateTemplate': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    SourceEntity: {
      SourceAnalysis: '<optional>',
      SourceTemplate: '<optional>',
    },
    VersionDescription: '<optional>',
    Name: '<optional>',
  },
  'QuickSight:updateTemplateAlias': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    AliasName: 'required',
    TemplateVersionNumber: 'required',
  },
  'QuickSight:updateTemplatePermissions': {
    AwsAccountId: 'required',
    TemplateId: 'required',
    GrantPermissions: ['<optional1>', '<optional2>'],
    RevokePermissions: ['<optional1>', '<optional2>'],
  },
  'QuickSight:updateUser': {
    UserName: 'required',
    AwsAccountId: 'required',
    Namespace: 'required',
    Email: 'required',
    Role: 'required',
  },
  'RAM:acceptResourceShareInvitation': {
    resourceShareInvitationArn: 'required',
    clientToken: '<optional>',
  },
  'RAM:associateResourceShare': {
    resourceShareArn: 'required',
    resourceArns: ['<optional1>', '<optional2>'],
    principals: ['<optional1>', '<optional2>'],
    clientToken: '<optional>',
  },
  'RAM:associateResourceSharePermission': {
    resourceShareArn: 'required',
    permissionArn: 'required',
    replace: '<optional>',
    clientToken: '<optional>',
  },
  'RAM:createResourceShare': {
    name: 'required',
    resourceArns: ['<optional1>', '<optional2>'],
    principals: ['<optional1>', '<optional2>'],
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    allowExternalPrincipals: ['<optional1>', '<optional2>'],
    clientToken: '<optional>',
  },
  'RAM:deleteResourceShare': {
    resourceShareArn: 'required',
    clientToken: '<optional>',
  },
  'RAM:disassociateResourceShare': {
    resourceShareArn: 'required',
    resourceArns: ['<optional1>', '<optional2>'],
    principals: ['<optional1>', '<optional2>'],
    clientToken: '<optional>',
  },
  'RAM:disassociateResourceSharePermission': {
    resourceShareArn: 'required',
    permissionArn: 'required',
    clientToken: '<optional>',
  },
  'RAM:enableSharingWithAwsOrganization': {},
  'RAM:getPermission': {
    permissionArn: 'required',
    permissionVersion: '<optional>',
  },
  'RAM:getResourcePolicies': {
    resourceArns: ['required1', 'required2'],
    principal: '<optional>',
  },
  'RAM:getResourceShareAssociations': {
    associationType: 'required',
    resourceShareArns: ['<optional1>', '<optional2>'],
    resourceArn: '<optional>',
    principal: '<optional>',
    associationStatus: '<optional>',
  },
  'RAM:getResourceShareInvitations': {
    resourceShareInvitationArns: ['<optional1>', '<optional2>'],
    resourceShareArns: ['<optional1>', '<optional2>'],
  },
  'RAM:getResourceShares': {
    resourceOwner: 'required',
    resourceShareArns: ['<optional1>', '<optional2>'],
    resourceShareStatus: '<optional>',
    name: '<optional>',
    tagFilters: [
      {
        tagKey: '<optional>',
        tagValues: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RAM:listPendingInvitationResources': {
    resourceShareInvitationArn: 'required',
  },
  'RAM:listPermissions': {
    resourceType: '<optional>',
  },
  'RAM:listPrincipals': {
    resourceOwner: 'required',
    resourceArn: '<optional>',
    principals: ['<optional1>', '<optional2>'],
    resourceType: '<optional>',
    resourceShareArns: ['<optional1>', '<optional2>'],
  },
  'RAM:listResourceSharePermissions': {
    resourceShareArn: 'required',
  },
  'RAM:listResources': {
    resourceOwner: 'required',
    principal: '<optional>',
    resourceType: '<optional>',
    resourceArns: ['<optional1>', '<optional2>'],
    resourceShareArns: ['<optional1>', '<optional2>'],
  },
  'RAM:promoteResourceShareCreatedFromPolicy': {
    resourceShareArn: 'required',
  },
  'RAM:rejectResourceShareInvitation': {
    resourceShareInvitationArn: 'required',
    clientToken: '<optional>',
  },
  'RAM:tagResource': {
    resourceShareArn: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'RAM:untagResource': {
    resourceShareArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'RAM:updateResourceShare': {
    resourceShareArn: 'required',
    name: '<optional>',
    allowExternalPrincipals: ['<optional1>', '<optional2>'],
    clientToken: '<optional>',
  },
  'RDS:addRoleToDBCluster': {
    DBClusterIdentifier: 'required',
    RoleArn: 'required',
    FeatureName: '<optional>',
  },
  'RDS:addRoleToDBInstance': {
    DBInstanceIdentifier: 'required',
    RoleArn: 'required',
    FeatureName: 'required',
  },
  'RDS:addSourceIdentifierToSubscription': {
    SubscriptionName: 'required',
    SourceIdentifier: 'required',
  },
  'RDS:addTagsToResource': {
    ResourceName: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:applyPendingMaintenanceAction': {
    ResourceIdentifier: 'required',
    ApplyAction: 'required',
    OptInType: 'required',
  },
  'RDS:authorizeDBSecurityGroupIngress': {
    DBSecurityGroupName: 'required',
    CIDRIP: '<optional>',
    EC2SecurityGroupName: '<optional>',
    EC2SecurityGroupId: '<optional>',
    EC2SecurityGroupOwnerId: '<optional>',
  },
  'RDS:backtrackDBCluster': {
    DBClusterIdentifier: 'required',
    BacktrackTo: 'required',
    Force: '<optional>',
    UseEarliestTimeOnPointInTimeUnavailable: '<optional>',
  },
  'RDS:copyDBClusterParameterGroup': {
    SourceDBClusterParameterGroupIdentifier: 'required',
    TargetDBClusterParameterGroupIdentifier: 'required',
    TargetDBClusterParameterGroupDescription: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:copyDBClusterSnapshot': {
    SourceDBClusterSnapshotIdentifier: 'required',
    TargetDBClusterSnapshotIdentifier: 'required',
    KmsKeyId: '<optional>',
    PreSignedUrl: '<optional>',
    CopyTags: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    SourceRegion: '<optional>',
  },
  'RDS:copyDBParameterGroup': {
    SourceDBParameterGroupIdentifier: 'required',
    TargetDBParameterGroupIdentifier: 'required',
    TargetDBParameterGroupDescription: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:copyDBSnapshot': {
    SourceDBSnapshotIdentifier: 'required',
    TargetDBSnapshotIdentifier: 'required',
    KmsKeyId: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    CopyTags: ['<optional1>', '<optional2>'],
    PreSignedUrl: '<optional>',
    OptionGroupName: '<optional>',
    SourceRegion: '<optional>',
  },
  'RDS:copyOptionGroup': {
    SourceOptionGroupIdentifier: 'required',
    TargetOptionGroupIdentifier: 'required',
    TargetOptionGroupDescription: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createCustomAvailabilityZone': {
    CustomAvailabilityZoneName: 'required',
    ExistingVpnId: '<optional>',
    NewVpnTunnelName: '<optional>',
    VpnTunnelOriginatorIP: '<optional>',
  },
  'RDS:createDBCluster': {
    DBClusterIdentifier: 'required',
    Engine: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    BackupRetentionPeriod: '<optional>',
    CharacterSetName: '<optional>',
    DatabaseName: '<optional>',
    DBClusterParameterGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    DBSubnetGroupName: '<optional>',
    EngineVersion: '<optional>',
    Port: '<optional>',
    MasterUsername: '<optional>',
    MasterUserPassword: '<optional>',
    OptionGroupName: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    ReplicationSourceIdentifier: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    StorageEncrypted: '<optional>',
    KmsKeyId: '<optional>',
    PreSignedUrl: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    BacktrackWindow: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    EngineMode: '<optional>',
    ScalingConfiguration: {
      MinCapacity: '<optional>',
      MaxCapacity: '<optional>',
      AutoPause: '<optional>',
      SecondsUntilAutoPause: '<optional>',
      TimeoutAction: '<optional>',
    },
    DeletionProtection: '<optional>',
    GlobalClusterIdentifier: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    SourceRegion: '<optional>',
  },
  'RDS:createDBClusterEndpoint': {
    DBClusterIdentifier: 'required',
    DBClusterEndpointIdentifier: 'required',
    EndpointType: 'required',
    StaticMembers: ['<optional1>', '<optional2>'],
    ExcludedMembers: ['<optional1>', '<optional2>'],
  },
  'RDS:createDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    DBParameterGroupFamily: 'required',
    Description: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createDBClusterSnapshot': {
    DBClusterSnapshotIdentifier: 'required',
    DBClusterIdentifier: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createDBInstance': {
    DBInstanceIdentifier: 'required',
    DBInstanceClass: 'required',
    Engine: 'required',
    DBName: '<optional>',
    AllocatedStorage: '<optional>',
    MasterUsername: '<optional>',
    MasterUserPassword: '<optional>',
    DBSecurityGroups: ['<optional1>', '<optional2>'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    AvailabilityZone: '<optional>',
    DBSubnetGroupName: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    DBParameterGroupName: '<optional>',
    BackupRetentionPeriod: '<optional>',
    PreferredBackupWindow: '<optional>',
    Port: '<optional>',
    MultiAZ: '<optional>',
    EngineVersion: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    LicenseModel: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    OptionGroupName: '<optional>',
    CharacterSetName: '<optional>',
    PubliclyAccessible: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    DBClusterIdentifier: '<optional>',
    StorageType: '<optional>',
    TdeCredentialArn: '<optional>',
    TdeCredentialPassword: '<optional>',
    StorageEncrypted: '<optional>',
    KmsKeyId: '<optional>',
    Domain: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    MonitoringInterval: '<optional>',
    MonitoringRoleArn: '<optional>',
    DomainIAMRoleName: '<optional>',
    PromotionTier: '<optional>',
    Timezone: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnablePerformanceInsights: ['<optional1>', '<optional2>'],
    PerformanceInsightsKMSKeyId: '<optional>',
    PerformanceInsightsRetentionPeriod: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    ProcessorFeatures: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
    DeletionProtection: '<optional>',
    MaxAllocatedStorage: '<optional>',
  },
  'RDS:createDBInstanceReadReplica': {
    DBInstanceIdentifier: 'required',
    SourceDBInstanceIdentifier: 'required',
    DBInstanceClass: '<optional>',
    AvailabilityZone: '<optional>',
    Port: '<optional>',
    MultiAZ: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    OptionGroupName: '<optional>',
    PubliclyAccessible: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    DBSubnetGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    StorageType: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    MonitoringInterval: '<optional>',
    MonitoringRoleArn: '<optional>',
    KmsKeyId: '<optional>',
    PreSignedUrl: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnablePerformanceInsights: ['<optional1>', '<optional2>'],
    PerformanceInsightsKMSKeyId: '<optional>',
    PerformanceInsightsRetentionPeriod: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    ProcessorFeatures: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
    UseDefaultProcessorFeatures: ['<optional1>', '<optional2>'],
    DeletionProtection: '<optional>',
    SourceRegion: '<optional>',
  },
  'RDS:createDBParameterGroup': {
    DBParameterGroupName: 'required',
    DBParameterGroupFamily: 'required',
    Description: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createDBProxy': {
    DBProxyName: 'required',
    EngineFamily: 'required',
    Auth: {
      Description: '<optional>',
      UserName: '<optional>',
      AuthScheme: '<optional>',
      SecretArn: '<optional>',
      IAMAuth: '<optional>',
    },
    RoleArn: 'required',
    VpcSubnetIds: ['required1', 'required2'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    RequireTLS: ['<optional1>', '<optional2>'],
    IdleClientTimeout: '<optional>',
    DebugLogging: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createDBSecurityGroup': {
    DBSecurityGroupName: 'required',
    DBSecurityGroupDescription: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createDBSnapshot': {
    DBSnapshotIdentifier: 'required',
    DBInstanceIdentifier: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createDBSubnetGroup': {
    DBSubnetGroupName: 'required',
    DBSubnetGroupDescription: 'required',
    SubnetIds: ['required1', 'required2'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createEventSubscription': {
    SubscriptionName: 'required',
    SnsTopicArn: 'required',
    SourceType: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    SourceIds: ['<optional1>', '<optional2>'],
    Enabled: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:createGlobalCluster': {
    GlobalClusterIdentifier: '<optional>',
    SourceDBClusterIdentifier: '<optional>',
    Engine: '<optional>',
    EngineVersion: '<optional>',
    DeletionProtection: '<optional>',
    DatabaseName: '<optional>',
    StorageEncrypted: '<optional>',
  },
  'RDS:createOptionGroup': {
    OptionGroupName: 'required',
    EngineName: 'required',
    MajorEngineVersion: 'required',
    OptionGroupDescription: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:deleteCustomAvailabilityZone': {
    CustomAvailabilityZoneId: 'required',
  },
  'RDS:deleteDBCluster': {
    DBClusterIdentifier: 'required',
    SkipFinalSnapshot: '<optional>',
    FinalDBSnapshotIdentifier: '<optional>',
  },
  'RDS:deleteDBClusterEndpoint': {
    DBClusterEndpointIdentifier: 'required',
  },
  'RDS:deleteDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
  },
  'RDS:deleteDBClusterSnapshot': {
    DBClusterSnapshotIdentifier: 'required',
  },
  'RDS:deleteDBInstance': {
    DBInstanceIdentifier: 'required',
    SkipFinalSnapshot: '<optional>',
    FinalDBSnapshotIdentifier: '<optional>',
    DeleteAutomatedBackups: ['<optional1>', '<optional2>'],
  },
  'RDS:deleteDBInstanceAutomatedBackup': {
    DbiResourceId: 'required',
  },
  'RDS:deleteDBParameterGroup': {
    DBParameterGroupName: 'required',
  },
  'RDS:deleteDBProxy': {
    DBProxyName: 'required',
  },
  'RDS:deleteDBSecurityGroup': {
    DBSecurityGroupName: 'required',
  },
  'RDS:deleteDBSnapshot': {
    DBSnapshotIdentifier: 'required',
  },
  'RDS:deleteDBSubnetGroup': {
    DBSubnetGroupName: 'required',
  },
  'RDS:deleteEventSubscription': {
    SubscriptionName: 'required',
  },
  'RDS:deleteGlobalCluster': {
    GlobalClusterIdentifier: 'required',
  },
  'RDS:deleteInstallationMedia': {
    InstallationMediaId: 'required',
  },
  'RDS:deleteOptionGroup': {
    OptionGroupName: 'required',
  },
  'RDS:deregisterDBProxyTargets': {
    DBProxyName: 'required',
    TargetGroupName: '<optional>',
    DBInstanceIdentifiers: ['<optional1>', '<optional2>'],
    DBClusterIdentifiers: ['<optional1>', '<optional2>'],
  },
  'RDS:describeAccountAttributes': {},
  'RDS:describeCertificates': {
    CertificateIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeCustomAvailabilityZones': {
    Filters: ['<optional1>', '<optional2>'],
    CustomAvailabilityZoneId: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBClusterBacktracks': {
    DBClusterIdentifier: 'required',
    BacktrackIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBClusterEndpoints': {
    DBClusterIdentifier: '<optional>',
    DBClusterEndpointIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBClusterParameterGroups': {
    DBClusterParameterGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBClusterParameters': {
    DBClusterParameterGroupName: 'required',
    Source: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBClusterSnapshotAttributes': {
    DBClusterSnapshotIdentifier: 'required',
  },
  'RDS:describeDBClusterSnapshots': {
    DBClusterIdentifier: '<optional>',
    DBClusterSnapshotIdentifier: '<optional>',
    SnapshotType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    IncludeShared: '<optional>',
    IncludePublic: '<optional>',
  },
  'RDS:describeDBClusters': {
    DBClusterIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    IncludeShared: '<optional>',
  },
  'RDS:describeDBEngineVersions': {
    Engine: '<optional>',
    EngineVersion: '<optional>',
    DBParameterGroupFamily: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    DefaultOnly: '<optional>',
    ListSupportedCharacterSets: ['<optional1>', '<optional2>'],
    ListSupportedTimezones: ['<optional1>', '<optional2>'],
    IncludeAll: '<optional>',
  },
  'RDS:describeDBInstanceAutomatedBackups': {
    DbiResourceId: '<optional>',
    DBInstanceIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBInstances': {
    DBInstanceIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBLogFiles': {
    DBInstanceIdentifier: 'required',
    FilenameContains: ['<optional1>', '<optional2>'],
    FileLastWritten: '<optional>',
    FileSize: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBParameterGroups': {
    DBParameterGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBParameters': {
    DBParameterGroupName: 'required',
    Source: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBProxies': {
    Filters: ['<optional1>', '<optional2>'],
    DBProxyName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBProxyTargetGroups': {
    DBProxyName: 'required',
    Filters: ['<optional1>', '<optional2>'],
    TargetGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBProxyTargets': {
    DBProxyName: 'required',
    Filters: ['<optional1>', '<optional2>'],
    TargetGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBSecurityGroups': {
    DBSecurityGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeDBSnapshotAttributes': {
    DBSnapshotIdentifier: 'required',
  },
  'RDS:describeDBSnapshots': {
    DBInstanceIdentifier: '<optional>',
    DBSnapshotIdentifier: '<optional>',
    SnapshotType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    IncludeShared: '<optional>',
    IncludePublic: '<optional>',
    DbiResourceId: '<optional>',
  },
  'RDS:describeDBSubnetGroups': {
    DBSubnetGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeEngineDefaultClusterParameters': {
    DBParameterGroupFamily: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeEngineDefaultParameters': {
    DBParameterGroupFamily: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeEventCategories': {
    SourceType: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RDS:describeEventSubscriptions': {
    SubscriptionName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeEvents': {
    SourceIdentifier: '<optional>',
    SourceType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Duration: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeGlobalClusters': {
    GlobalClusterIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeInstallationMedia': {
    Filters: ['<optional1>', '<optional2>'],
    InstallationMediaId: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeOptionGroupOptions': {
    EngineName: 'required',
    MajorEngineVersion: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeOptionGroups': {
    OptionGroupName: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
    EngineName: '<optional>',
    MajorEngineVersion: '<optional>',
  },
  'RDS:describeOrderableDBInstanceOptions': {
    Engine: 'required',
    EngineVersion: '<optional>',
    DBInstanceClass: '<optional>',
    LicenseModel: '<optional>',
    Vpc: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describePendingMaintenanceActions': {
    ResourceIdentifier: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeReservedDBInstances': {
    ReservedDBInstanceId: '<optional>',
    ReservedDBInstancesOfferingId: '<optional>',
    DBInstanceClass: '<optional>',
    Duration: '<optional>',
    ProductDescription: '<optional>',
    OfferingType: '<optional>',
    MultiAZ: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeReservedDBInstancesOfferings': {
    ReservedDBInstancesOfferingId: '<optional>',
    DBInstanceClass: '<optional>',
    Duration: '<optional>',
    ProductDescription: '<optional>',
    OfferingType: '<optional>',
    MultiAZ: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'RDS:describeSourceRegions': {
    RegionName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RDS:describeValidDBInstanceModifications': {
    DBInstanceIdentifier: 'required',
  },
  'RDS:downloadDBLogFilePortion': {
    DBInstanceIdentifier: 'required',
    LogFileName: 'required',
    NumberOfLines: ['<optional1>', '<optional2>'],
  },
  'RDS:failoverDBCluster': {
    DBClusterIdentifier: 'required',
    TargetDBInstanceIdentifier: '<optional>',
  },
  'RDS:importInstallationMedia': {
    CustomAvailabilityZoneId: 'required',
    Engine: 'required',
    EngineVersion: 'required',
    EngineInstallationMediaPath: 'required',
    OSInstallationMediaPath: 'required',
  },
  'RDS:listTagsForResource': {
    ResourceName: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RDS:modifyCurrentDBClusterCapacity': {
    DBClusterIdentifier: 'required',
    Capacity: '<optional>',
    SecondsBeforeTimeout: '<optional>',
    TimeoutAction: '<optional>',
  },
  'RDS:modifyDBCluster': {
    DBClusterIdentifier: 'required',
    NewDBClusterIdentifier: '<optional>',
    ApplyImmediately: '<optional>',
    BackupRetentionPeriod: '<optional>',
    DBClusterParameterGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Port: '<optional>',
    MasterUserPassword: '<optional>',
    OptionGroupName: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    BacktrackWindow: '<optional>',
    CloudwatchLogsExportConfiguration: {
      EnableLogTypes: ['<optional1>', '<optional2>'],
      DisableLogTypes: ['<optional1>', '<optional2>'],
    },
    EngineVersion: '<optional>',
    AllowMajorVersionUpgrade: '<optional>',
    DBInstanceParameterGroupName: '<optional>',
    ScalingConfiguration: {
      MinCapacity: '<optional>',
      MaxCapacity: '<optional>',
      AutoPause: '<optional>',
      SecondsUntilAutoPause: '<optional>',
      TimeoutAction: '<optional>',
    },
    DeletionProtection: '<optional>',
    EnableHttpEndpoint: '<optional>',
    CopyTagsToSnapshot: '<optional>',
  },
  'RDS:modifyDBClusterEndpoint': {
    DBClusterEndpointIdentifier: 'required',
    EndpointType: '<optional>',
    StaticMembers: ['<optional1>', '<optional2>'],
    ExcludedMembers: ['<optional1>', '<optional2>'],
  },
  'RDS:modifyDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
        SupportedEngineModes: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RDS:modifyDBClusterSnapshotAttribute': {
    DBClusterSnapshotIdentifier: 'required',
    AttributeName: 'required',
    ValuesToAdd: '<optional>',
    ValuesToRemove: '<optional>',
  },
  'RDS:modifyDBInstance': {
    DBInstanceIdentifier: 'required',
    AllocatedStorage: '<optional>',
    DBInstanceClass: '<optional>',
    DBSubnetGroupName: '<optional>',
    DBSecurityGroups: ['<optional1>', '<optional2>'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    ApplyImmediately: '<optional>',
    MasterUserPassword: '<optional>',
    DBParameterGroupName: '<optional>',
    BackupRetentionPeriod: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    MultiAZ: '<optional>',
    EngineVersion: '<optional>',
    AllowMajorVersionUpgrade: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    LicenseModel: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    OptionGroupName: '<optional>',
    NewDBInstanceIdentifier: '<optional>',
    StorageType: '<optional>',
    TdeCredentialArn: '<optional>',
    TdeCredentialPassword: '<optional>',
    CACertificateIdentifier: '<optional>',
    Domain: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    MonitoringInterval: '<optional>',
    DBPortNumber: '<optional>',
    PubliclyAccessible: '<optional>',
    MonitoringRoleArn: '<optional>',
    DomainIAMRoleName: '<optional>',
    PromotionTier: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnablePerformanceInsights: ['<optional1>', '<optional2>'],
    PerformanceInsightsKMSKeyId: '<optional>',
    PerformanceInsightsRetentionPeriod: '<optional>',
    CloudwatchLogsExportConfiguration: {
      EnableLogTypes: ['<optional1>', '<optional2>'],
      DisableLogTypes: ['<optional1>', '<optional2>'],
    },
    ProcessorFeatures: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
    UseDefaultProcessorFeatures: ['<optional1>', '<optional2>'],
    DeletionProtection: '<optional>',
    MaxAllocatedStorage: '<optional>',
  },
  'RDS:modifyDBParameterGroup': {
    DBParameterGroupName: 'required',
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
        SupportedEngineModes: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RDS:modifyDBProxy': {
    DBProxyName: 'required',
    NewDBProxyName: '<optional>',
    Auth: {
      Description: '<optional>',
      UserName: '<optional>',
      AuthScheme: '<optional>',
      SecretArn: '<optional>',
      IAMAuth: '<optional>',
    },
    RequireTLS: ['<optional1>', '<optional2>'],
    IdleClientTimeout: '<optional>',
    DebugLogging: '<optional>',
    RoleArn: '<optional>',
    SecurityGroups: ['<optional1>', '<optional2>'],
  },
  'RDS:modifyDBProxyTargetGroup': {
    TargetGroupName: 'required',
    DBProxyName: 'required',
    ConnectionPoolConfig: {
      MaxConnectionsPercent: '<optional>',
      MaxIdleConnectionsPercent: '<optional>',
      ConnectionBorrowTimeout: '<optional>',
      SessionPinningFilters: ['<optional1>', '<optional2>'],
      InitQuery: '<optional>',
    },
    NewName: '<optional>',
  },
  'RDS:modifyDBSnapshot': {
    DBSnapshotIdentifier: 'required',
    EngineVersion: '<optional>',
    OptionGroupName: '<optional>',
  },
  'RDS:modifyDBSnapshotAttribute': {
    DBSnapshotIdentifier: 'required',
    AttributeName: 'required',
    ValuesToAdd: '<optional>',
    ValuesToRemove: '<optional>',
  },
  'RDS:modifyDBSubnetGroup': {
    DBSubnetGroupName: 'required',
    SubnetIds: ['required1', 'required2'],
    DBSubnetGroupDescription: '<optional>',
  },
  'RDS:modifyEventSubscription': {
    SubscriptionName: 'required',
    SnsTopicArn: '<optional>',
    SourceType: '<optional>',
    EventCategories: ['<optional1>', '<optional2>'],
    Enabled: '<optional>',
  },
  'RDS:modifyGlobalCluster': {
    GlobalClusterIdentifier: '<optional>',
    NewGlobalClusterIdentifier: '<optional>',
    DeletionProtection: '<optional>',
  },
  'RDS:modifyOptionGroup': {
    OptionGroupName: 'required',
    OptionsToInclude: {
      Port: '<optional>',
      OptionVersion: '<optional>',
      DBSecurityGroupMemberships: ['<optional1>', '<optional2>'],
      VpcSecurityGroupMemberships: ['<optional1>', '<optional2>'],
      OptionSettings: [
        {
          Name: '<optional>',
          Value: '<optional>',
          DefaultValue: '<optional>',
          Description: '<optional>',
          ApplyType: '<optional>',
          DataType: '<optional>',
          AllowedValues: ['<optional1>', '<optional2>'],
          IsModifiable: '<optional>',
          IsCollection: '<optional>',
        },
      ],
    },
    OptionsToRemove: '<optional>',
    ApplyImmediately: '<optional>',
  },
  'RDS:promoteReadReplica': {
    DBInstanceIdentifier: 'required',
    BackupRetentionPeriod: '<optional>',
    PreferredBackupWindow: '<optional>',
  },
  'RDS:promoteReadReplicaDBCluster': {
    DBClusterIdentifier: 'required',
  },
  'RDS:purchaseReservedDBInstancesOffering': {
    ReservedDBInstancesOfferingId: 'required',
    ReservedDBInstanceId: '<optional>',
    DBInstanceCount: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'RDS:rebootDBInstance': {
    DBInstanceIdentifier: 'required',
    ForceFailover: '<optional>',
  },
  'RDS:registerDBProxyTargets': {
    DBProxyName: 'required',
    TargetGroupName: '<optional>',
    DBInstanceIdentifiers: ['<optional1>', '<optional2>'],
    DBClusterIdentifiers: ['<optional1>', '<optional2>'],
  },
  'RDS:removeFromGlobalCluster': {
    GlobalClusterIdentifier: '<optional>',
    DbClusterIdentifier: '<optional>',
  },
  'RDS:removeRoleFromDBCluster': {
    DBClusterIdentifier: 'required',
    RoleArn: 'required',
    FeatureName: '<optional>',
  },
  'RDS:removeRoleFromDBInstance': {
    DBInstanceIdentifier: 'required',
    RoleArn: 'required',
    FeatureName: 'required',
  },
  'RDS:removeSourceIdentifierFromSubscription': {
    SubscriptionName: 'required',
    SourceIdentifier: 'required',
  },
  'RDS:removeTagsFromResource': {
    ResourceName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'RDS:resetDBClusterParameterGroup': {
    DBClusterParameterGroupName: 'required',
    ResetAllParameters: ['<optional1>', '<optional2>'],
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
        SupportedEngineModes: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RDS:resetDBParameterGroup': {
    DBParameterGroupName: 'required',
    ResetAllParameters: ['<optional1>', '<optional2>'],
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        ApplyType: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
        ApplyMethod: '<optional>',
        SupportedEngineModes: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RDS:restoreDBClusterFromS3': {
    DBClusterIdentifier: 'required',
    Engine: 'required',
    MasterUsername: 'required',
    MasterUserPassword: 'required',
    SourceEngine: 'required',
    SourceEngineVersion: 'required',
    S3BucketName: 'required',
    S3IngestionRoleArn: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    BackupRetentionPeriod: '<optional>',
    CharacterSetName: '<optional>',
    DatabaseName: '<optional>',
    DBClusterParameterGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    DBSubnetGroupName: '<optional>',
    EngineVersion: '<optional>',
    Port: '<optional>',
    OptionGroupName: '<optional>',
    PreferredBackupWindow: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    StorageEncrypted: '<optional>',
    KmsKeyId: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    S3Prefix: '<optional>',
    BacktrackWindow: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    DeletionProtection: '<optional>',
    CopyTagsToSnapshot: '<optional>',
  },
  'RDS:restoreDBClusterFromSnapshot': {
    DBClusterIdentifier: 'required',
    SnapshotIdentifier: 'required',
    Engine: 'required',
    AvailabilityZones: ['<optional1>', '<optional2>'],
    EngineVersion: '<optional>',
    Port: '<optional>',
    DBSubnetGroupName: '<optional>',
    DatabaseName: '<optional>',
    OptionGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    BacktrackWindow: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    EngineMode: '<optional>',
    ScalingConfiguration: {
      MinCapacity: '<optional>',
      MaxCapacity: '<optional>',
      AutoPause: '<optional>',
      SecondsUntilAutoPause: '<optional>',
      TimeoutAction: '<optional>',
    },
    DBClusterParameterGroupName: '<optional>',
    DeletionProtection: '<optional>',
    CopyTagsToSnapshot: '<optional>',
  },
  'RDS:restoreDBClusterToPointInTime': {
    DBClusterIdentifier: 'required',
    SourceDBClusterIdentifier: 'required',
    RestoreType: '<optional>',
    RestoreToTime: '<optional>',
    UseLatestRestorableTime: '<optional>',
    Port: '<optional>',
    DBSubnetGroupName: '<optional>',
    OptionGroupName: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    BacktrackWindow: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    DBClusterParameterGroupName: '<optional>',
    DeletionProtection: '<optional>',
    CopyTagsToSnapshot: '<optional>',
  },
  'RDS:restoreDBInstanceFromDBSnapshot': {
    DBInstanceIdentifier: 'required',
    DBSnapshotIdentifier: 'required',
    DBInstanceClass: '<optional>',
    Port: '<optional>',
    AvailabilityZone: '<optional>',
    DBSubnetGroupName: '<optional>',
    MultiAZ: '<optional>',
    PubliclyAccessible: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    LicenseModel: '<optional>',
    DBName: '<optional>',
    Engine: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    OptionGroupName: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    StorageType: '<optional>',
    TdeCredentialArn: '<optional>',
    TdeCredentialPassword: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Domain: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    DomainIAMRoleName: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    ProcessorFeatures: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
    UseDefaultProcessorFeatures: ['<optional1>', '<optional2>'],
    DBParameterGroupName: '<optional>',
    DeletionProtection: '<optional>',
  },
  'RDS:restoreDBInstanceFromS3': {
    DBInstanceIdentifier: 'required',
    DBInstanceClass: 'required',
    Engine: 'required',
    SourceEngine: 'required',
    SourceEngineVersion: 'required',
    S3BucketName: 'required',
    S3IngestionRoleArn: 'required',
    DBName: '<optional>',
    AllocatedStorage: '<optional>',
    MasterUsername: '<optional>',
    MasterUserPassword: '<optional>',
    DBSecurityGroups: ['<optional1>', '<optional2>'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    AvailabilityZone: '<optional>',
    DBSubnetGroupName: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    DBParameterGroupName: '<optional>',
    BackupRetentionPeriod: '<optional>',
    PreferredBackupWindow: '<optional>',
    Port: '<optional>',
    MultiAZ: '<optional>',
    EngineVersion: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    LicenseModel: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    OptionGroupName: '<optional>',
    PubliclyAccessible: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    StorageType: '<optional>',
    StorageEncrypted: '<optional>',
    KmsKeyId: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    MonitoringInterval: '<optional>',
    MonitoringRoleArn: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    S3Prefix: '<optional>',
    EnablePerformanceInsights: ['<optional1>', '<optional2>'],
    PerformanceInsightsKMSKeyId: '<optional>',
    PerformanceInsightsRetentionPeriod: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    ProcessorFeatures: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
    UseDefaultProcessorFeatures: ['<optional1>', '<optional2>'],
    DeletionProtection: '<optional>',
  },
  'RDS:restoreDBInstanceToPointInTime': {
    TargetDBInstanceIdentifier: 'required',
    SourceDBInstanceIdentifier: '<optional>',
    RestoreTime: '<optional>',
    UseLatestRestorableTime: '<optional>',
    DBInstanceClass: '<optional>',
    Port: '<optional>',
    AvailabilityZone: '<optional>',
    DBSubnetGroupName: '<optional>',
    MultiAZ: '<optional>',
    PubliclyAccessible: '<optional>',
    AutoMinorVersionUpgrade: '<optional>',
    LicenseModel: '<optional>',
    DBName: '<optional>',
    Engine: '<optional>',
    Iops: ['<optional1>', '<optional2>'],
    OptionGroupName: '<optional>',
    CopyTagsToSnapshot: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    StorageType: '<optional>',
    TdeCredentialArn: '<optional>',
    TdeCredentialPassword: '<optional>',
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    Domain: '<optional>',
    DomainIAMRoleName: '<optional>',
    EnableIAMDatabaseAuthentication: '<optional>',
    EnableCloudwatchLogsExports: ['<optional1>', '<optional2>'],
    ProcessorFeatures: [
      {
        Name: '<optional>',
        Value: '<optional>',
      },
    ],
    UseDefaultProcessorFeatures: ['<optional1>', '<optional2>'],
    DBParameterGroupName: '<optional>',
    DeletionProtection: '<optional>',
    SourceDbiResourceId: '<optional>',
  },
  'RDS:revokeDBSecurityGroupIngress': {
    DBSecurityGroupName: 'required',
    CIDRIP: '<optional>',
    EC2SecurityGroupName: '<optional>',
    EC2SecurityGroupId: '<optional>',
    EC2SecurityGroupOwnerId: '<optional>',
  },
  'RDS:startActivityStream': {
    ResourceArn: 'required',
    Mode: 'required',
    KmsKeyId: 'required',
    ApplyImmediately: '<optional>',
  },
  'RDS:startDBCluster': {
    DBClusterIdentifier: 'required',
  },
  'RDS:startDBInstance': {
    DBInstanceIdentifier: 'required',
  },
  'RDS:stopActivityStream': {
    ResourceArn: 'required',
    ApplyImmediately: '<optional>',
  },
  'RDS:stopDBCluster': {
    DBClusterIdentifier: 'required',
  },
  'RDS:stopDBInstance': {
    DBInstanceIdentifier: 'required',
    DBSnapshotIdentifier: '<optional>',
  },
  'RDSDataService:batchExecuteStatement': {
    resourceArn: 'required',
    secretArn: 'required',
    sql: 'required',
    database: '<optional>',
    parameterSets: [
      {
        name: '<optional>',
        value: {
          blobValue: '<optional>',
          booleanValue: '<optional>',
          doubleValue: '<optional>',
          isNull: '<optional>',
          longValue: '<optional>',
          stringValue: '<optional>',
        },
      },
    ],
    schema: '<optional>',
    transactionId: '<optional>',
  },
  'RDSDataService:beginTransaction': {
    resourceArn: 'required',
    secretArn: 'required',
    database: '<optional>',
    schema: '<optional>',
  },
  'RDSDataService:commitTransaction': {
    resourceArn: 'required',
    secretArn: 'required',
    transactionId: 'required',
  },
  'RDSDataService:executeSql': {
    awsSecretStoreArn: 'required',
    dbClusterOrInstanceArn: 'required',
    sqlStatements: ['required1', 'required2'],
    database: '<optional>',
    schema: '<optional>',
  },
  'RDSDataService:executeStatement': {
    resourceArn: 'required',
    secretArn: 'required',
    sql: 'required',
    continueAfterTimeout: '<optional>',
    database: '<optional>',
    includeResultMetadata: ['<optional1>', '<optional2>'],
    parameters: [
      {
        name: '<optional>',
        value: {
          blobValue: '<optional>',
          booleanValue: '<optional>',
          doubleValue: '<optional>',
          isNull: '<optional>',
          longValue: '<optional>',
          stringValue: '<optional>',
        },
      },
    ],
    schema: '<optional>',
    transactionId: '<optional>',
  },
  'RDSDataService:rollbackTransaction': {
    resourceArn: 'required',
    secretArn: 'required',
    transactionId: 'required',
  },
  'Redshift:acceptReservedNodeExchange': {
    ReservedNodeId: 'required',
    TargetReservedNodeOfferingId: 'required',
  },
  'Redshift:authorizeClusterSecurityGroupIngress': {
    ClusterSecurityGroupName: 'required',
    CIDRIP: '<optional>',
    EC2SecurityGroupName: '<optional>',
    EC2SecurityGroupOwnerId: '<optional>',
  },
  'Redshift:authorizeSnapshotAccess': {
    SnapshotIdentifier: 'required',
    AccountWithRestoreAccess: 'required',
    SnapshotClusterIdentifier: '<optional>',
  },
  'Redshift:batchDeleteClusterSnapshots': {
    Identifiers: [
      {
        SnapshotClusterIdentifier: '<optional>',
      },
    ],
  },
  'Redshift:batchModifyClusterSnapshots': {
    SnapshotIdentifierList: 'required',
    ManualSnapshotRetentionPeriod: '<optional>',
    Force: '<optional>',
  },
  'Redshift:cancelResize': {
    ClusterIdentifier: 'required',
  },
  'Redshift:copyClusterSnapshot': {
    SourceSnapshotIdentifier: 'required',
    TargetSnapshotIdentifier: 'required',
    SourceSnapshotClusterIdentifier: '<optional>',
    ManualSnapshotRetentionPeriod: '<optional>',
  },
  'Redshift:createCluster': {
    ClusterIdentifier: 'required',
    NodeType: 'required',
    MasterUsername: 'required',
    MasterUserPassword: 'required',
    DBName: '<optional>',
    ClusterType: '<optional>',
    ClusterSecurityGroups: ['<optional1>', '<optional2>'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    ClusterSubnetGroupName: '<optional>',
    AvailabilityZone: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    ClusterParameterGroupName: '<optional>',
    AutomatedSnapshotRetentionPeriod: '<optional>',
    ManualSnapshotRetentionPeriod: '<optional>',
    Port: '<optional>',
    ClusterVersion: '<optional>',
    AllowVersionUpgrade: '<optional>',
    NumberOfNodes: ['<optional1>', '<optional2>'],
    PubliclyAccessible: '<optional>',
    Encrypted: '<optional>',
    HsmClientCertificateIdentifier: '<optional>',
    HsmConfigurationIdentifier: '<optional>',
    ElasticIp: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    KmsKeyId: '<optional>',
    EnhancedVpcRouting: '<optional>',
    AdditionalInfo: '<optional>',
    IamRoles: ['<optional1>', '<optional2>'],
    MaintenanceTrackName: '<optional>',
    SnapshotScheduleIdentifier: '<optional>',
  },
  'Redshift:createClusterParameterGroup': {
    ParameterGroupName: 'required',
    ParameterGroupFamily: 'required',
    Description: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:createClusterSecurityGroup': {
    ClusterSecurityGroupName: 'required',
    Description: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:createClusterSnapshot': {
    SnapshotIdentifier: 'required',
    ClusterIdentifier: 'required',
    ManualSnapshotRetentionPeriod: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:createClusterSubnetGroup': {
    ClusterSubnetGroupName: 'required',
    Description: 'required',
    SubnetIds: ['required1', 'required2'],
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:createEventSubscription': {
    SubscriptionName: 'required',
    SnsTopicArn: 'required',
    SourceType: '<optional>',
    SourceIds: ['<optional1>', '<optional2>'],
    EventCategories: ['<optional1>', '<optional2>'],
    Severity: '<optional>',
    Enabled: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:createHsmClientCertificate': {
    HsmClientCertificateIdentifier: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:createHsmConfiguration': {
    HsmConfigurationIdentifier: 'required',
    Description: 'required',
    HsmIpAddress: 'required',
    HsmPartitionName: 'required',
    HsmPartitionPassword: 'required',
    HsmServerPublicCertificate: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:createScheduledAction': {
    ScheduledActionName: 'required',
    TargetAction: {
      ResizeCluster: {
        ClusterType: '<optional>',
        NodeType: '<optional>',
        Classic: '<optional>',
      },
    },
    Schedule: 'required',
    IamRole: 'required',
    ScheduledActionDescription: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Enable: '<optional>',
  },
  'Redshift:createSnapshotCopyGrant': {
    SnapshotCopyGrantName: 'required',
    KmsKeyId: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:createSnapshotSchedule': {
    ScheduleDefinitions: ['<optional1>', '<optional2>'],
    ScheduleIdentifier: '<optional>',
    ScheduleDescription: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    NextInvocations: ['<optional1>', '<optional2>'],
  },
  'Redshift:createTags': {
    ResourceName: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Redshift:deleteCluster': {
    ClusterIdentifier: 'required',
    SkipFinalClusterSnapshot: '<optional>',
    FinalClusterSnapshotIdentifier: '<optional>',
    FinalClusterSnapshotRetentionPeriod: '<optional>',
  },
  'Redshift:deleteClusterParameterGroup': {
    ParameterGroupName: 'required',
  },
  'Redshift:deleteClusterSecurityGroup': {
    ClusterSecurityGroupName: 'required',
  },
  'Redshift:deleteClusterSnapshot': {
    SnapshotIdentifier: 'required',
    SnapshotClusterIdentifier: '<optional>',
  },
  'Redshift:deleteClusterSubnetGroup': {
    ClusterSubnetGroupName: 'required',
  },
  'Redshift:deleteEventSubscription': {
    SubscriptionName: 'required',
  },
  'Redshift:deleteHsmClientCertificate': {
    HsmClientCertificateIdentifier: 'required',
  },
  'Redshift:deleteHsmConfiguration': {
    HsmConfigurationIdentifier: 'required',
  },
  'Redshift:deleteScheduledAction': {
    ScheduledActionName: 'required',
  },
  'Redshift:deleteSnapshotCopyGrant': {
    SnapshotCopyGrantName: 'required',
  },
  'Redshift:deleteSnapshotSchedule': {
    ScheduleIdentifier: 'required',
  },
  'Redshift:deleteTags': {
    ResourceName: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Redshift:describeAccountAttributes': {
    AttributeNames: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusterDbRevisions': {
    ClusterIdentifier: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusterParameterGroups': {
    ParameterGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusterParameters': {
    ParameterGroupName: 'required',
    Source: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusterSecurityGroups': {
    ClusterSecurityGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusterSnapshots': {
    SortingEntities: [
      {
        SortOrder: '<optional>',
      },
    ],
    ClusterIdentifier: '<optional>',
    SnapshotIdentifier: '<optional>',
    SnapshotType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    OwnerAccount: '<optional>',
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
    ClusterExists: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusterSubnetGroups': {
    ClusterSubnetGroupName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusterTracks': {
    MaintenanceTrackName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusterVersions': {
    ClusterVersion: '<optional>',
    ClusterParameterGroupFamily: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeClusters': {
    ClusterIdentifier: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeDefaultClusterParameters': {
    ParameterGroupFamily: 'required',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeEventCategories': {
    SourceType: '<optional>',
  },
  'Redshift:describeEventSubscriptions': {
    SubscriptionName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeEvents': {
    SourceIdentifier: '<optional>',
    SourceType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Duration: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeHsmClientCertificates': {
    HsmClientCertificateIdentifier: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeHsmConfigurations': {
    HsmConfigurationIdentifier: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeLoggingStatus': {
    ClusterIdentifier: 'required',
  },
  'Redshift:describeNodeConfigurationOptions': {
    ActionType: 'required',
    ClusterIdentifier: '<optional>',
    SnapshotIdentifier: '<optional>',
    OwnerAccount: '<optional>',
    Filters: [
      {
        Name: '<optional>',
        Operator: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeOrderableClusterOptions': {
    ClusterVersion: '<optional>',
    NodeType: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeReservedNodeOfferings': {
    ReservedNodeOfferingId: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeReservedNodes': {
    ReservedNodeId: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeResize': {
    ClusterIdentifier: 'required',
  },
  'Redshift:describeScheduledActions': {
    Filters: ['<optional1>', '<optional2>'],
    ScheduledActionName: '<optional>',
    TargetActionType: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Active: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeSnapshotCopyGrants': {
    SnapshotCopyGrantName: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeSnapshotSchedules': {
    ClusterIdentifier: '<optional>',
    ScheduleIdentifier: '<optional>',
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeStorage': {},
  'Redshift:describeTableRestoreStatus': {
    ClusterIdentifier: '<optional>',
    TableRestoreRequestId: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:describeTags': {
    ResourceName: '<optional>',
    ResourceType: '<optional>',
    MaxRecords: ['<optional1>', '<optional2>'],
    TagKeys: ['<optional1>', '<optional2>'],
    TagValues: ['<optional1>', '<optional2>'],
  },
  'Redshift:disableLogging': {
    ClusterIdentifier: 'required',
  },
  'Redshift:disableSnapshotCopy': {
    ClusterIdentifier: 'required',
  },
  'Redshift:enableLogging': {
    ClusterIdentifier: 'required',
    BucketName: 'required',
    S3KeyPrefix: '<optional>',
  },
  'Redshift:enableSnapshotCopy': {
    ClusterIdentifier: 'required',
    DestinationRegion: 'required',
    RetentionPeriod: '<optional>',
    SnapshotCopyGrantName: '<optional>',
    ManualSnapshotRetentionPeriod: '<optional>',
  },
  'Redshift:getClusterCredentials': {
    DbUser: 'required',
    ClusterIdentifier: 'required',
    DbName: '<optional>',
    DurationSeconds: ['<optional1>', '<optional2>'],
    AutoCreate: '<optional>',
    DbGroups: ['<optional1>', '<optional2>'],
  },
  'Redshift:getReservedNodeExchangeOfferings': {
    ReservedNodeId: 'required',
    MaxRecords: ['<optional1>', '<optional2>'],
  },
  'Redshift:modifyCluster': {
    ClusterIdentifier: 'required',
    ClusterType: '<optional>',
    NodeType: '<optional>',
    NumberOfNodes: ['<optional1>', '<optional2>'],
    ClusterSecurityGroups: ['<optional1>', '<optional2>'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    MasterUserPassword: '<optional>',
    ClusterParameterGroupName: '<optional>',
    AutomatedSnapshotRetentionPeriod: '<optional>',
    ManualSnapshotRetentionPeriod: '<optional>',
    PreferredMaintenanceWindow: '<optional>',
    ClusterVersion: '<optional>',
    AllowVersionUpgrade: '<optional>',
    HsmClientCertificateIdentifier: '<optional>',
    HsmConfigurationIdentifier: '<optional>',
    NewClusterIdentifier: '<optional>',
    PubliclyAccessible: '<optional>',
    ElasticIp: '<optional>',
    EnhancedVpcRouting: '<optional>',
    MaintenanceTrackName: '<optional>',
    Encrypted: '<optional>',
    KmsKeyId: '<optional>',
  },
  'Redshift:modifyClusterDbRevision': {
    ClusterIdentifier: 'required',
    RevisionTarget: 'required',
  },
  'Redshift:modifyClusterIamRoles': {
    ClusterIdentifier: 'required',
    AddIamRoles: ['<optional1>', '<optional2>'],
    RemoveIamRoles: ['<optional1>', '<optional2>'],
  },
  'Redshift:modifyClusterMaintenance': {
    ClusterIdentifier: 'required',
    DeferMaintenance: '<optional>',
    DeferMaintenanceIdentifier: '<optional>',
    DeferMaintenanceStartTime: '<optional>',
    DeferMaintenanceEndTime: '<optional>',
    DeferMaintenanceDuration: '<optional>',
  },
  'Redshift:modifyClusterParameterGroup': {
    ParameterGroupName: 'required',
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        ApplyType: '<optional>',
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
      },
    ],
  },
  'Redshift:modifyClusterSnapshot': {
    SnapshotIdentifier: 'required',
    ManualSnapshotRetentionPeriod: '<optional>',
    Force: '<optional>',
  },
  'Redshift:modifyClusterSnapshotSchedule': {
    ClusterIdentifier: 'required',
    ScheduleIdentifier: '<optional>',
    DisassociateSchedule: '<optional>',
  },
  'Redshift:modifyClusterSubnetGroup': {
    ClusterSubnetGroupName: 'required',
    SubnetIds: ['required1', 'required2'],
    Description: '<optional>',
  },
  'Redshift:modifyEventSubscription': {
    SubscriptionName: 'required',
    SnsTopicArn: '<optional>',
    SourceType: '<optional>',
    SourceIds: ['<optional1>', '<optional2>'],
    EventCategories: ['<optional1>', '<optional2>'],
    Severity: '<optional>',
    Enabled: '<optional>',
  },
  'Redshift:modifyScheduledAction': {
    ScheduledActionName: 'required',
    TargetAction: {
      ResizeCluster: {
        ClusterType: '<optional>',
        NodeType: '<optional>',
        Classic: '<optional>',
      },
    },
    Schedule: '<optional>',
    IamRole: '<optional>',
    ScheduledActionDescription: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    Enable: '<optional>',
  },
  'Redshift:modifySnapshotCopyRetentionPeriod': {
    ClusterIdentifier: 'required',
    RetentionPeriod: 'required',
    Manual: '<optional>',
  },
  'Redshift:modifySnapshotSchedule': {
    ScheduleIdentifier: 'required',
    ScheduleDefinitions: ['required1', 'required2'],
  },
  'Redshift:purchaseReservedNodeOffering': {
    ReservedNodeOfferingId: 'required',
    NodeCount: '<optional>',
  },
  'Redshift:rebootCluster': {
    ClusterIdentifier: 'required',
  },
  'Redshift:resetClusterParameterGroup': {
    ParameterGroupName: 'required',
    ResetAllParameters: ['<optional1>', '<optional2>'],
    Parameters: [
      {
        ParameterName: '<optional>',
        ParameterValue: '<optional>',
        Description: '<optional>',
        Source: '<optional>',
        DataType: '<optional>',
        AllowedValues: ['<optional1>', '<optional2>'],
        ApplyType: '<optional>',
        IsModifiable: '<optional>',
        MinimumEngineVersion: '<optional>',
      },
    ],
  },
  'Redshift:resizeCluster': {
    ClusterIdentifier: 'required',
    NumberOfNodes: ['required1', 'required2'],
    ClusterType: '<optional>',
    NodeType: '<optional>',
    Classic: '<optional>',
  },
  'Redshift:restoreFromClusterSnapshot': {
    ClusterIdentifier: 'required',
    SnapshotIdentifier: 'required',
    SnapshotClusterIdentifier: '<optional>',
    Port: '<optional>',
    AvailabilityZone: '<optional>',
    AllowVersionUpgrade: '<optional>',
    ClusterSubnetGroupName: '<optional>',
    PubliclyAccessible: '<optional>',
    OwnerAccount: '<optional>',
    HsmClientCertificateIdentifier: '<optional>',
    HsmConfigurationIdentifier: '<optional>',
    ElasticIp: '<optional>',
    ClusterParameterGroupName: '<optional>',
    ClusterSecurityGroups: ['<optional1>', '<optional2>'],
    VpcSecurityGroupIds: ['<optional1>', '<optional2>'],
    PreferredMaintenanceWindow: '<optional>',
    AutomatedSnapshotRetentionPeriod: '<optional>',
    ManualSnapshotRetentionPeriod: '<optional>',
    KmsKeyId: '<optional>',
    NodeType: '<optional>',
    EnhancedVpcRouting: '<optional>',
    AdditionalInfo: '<optional>',
    IamRoles: ['<optional1>', '<optional2>'],
    MaintenanceTrackName: '<optional>',
    SnapshotScheduleIdentifier: '<optional>',
  },
  'Redshift:restoreTableFromClusterSnapshot': {
    ClusterIdentifier: 'required',
    SnapshotIdentifier: 'required',
    SourceDatabaseName: 'required',
    SourceTableName: 'required',
    NewTableName: 'required',
    SourceSchemaName: '<optional>',
    TargetDatabaseName: '<optional>',
    TargetSchemaName: '<optional>',
  },
  'Redshift:revokeClusterSecurityGroupIngress': {
    ClusterSecurityGroupName: 'required',
    CIDRIP: '<optional>',
    EC2SecurityGroupName: '<optional>',
    EC2SecurityGroupOwnerId: '<optional>',
  },
  'Redshift:revokeSnapshotAccess': {
    SnapshotIdentifier: 'required',
    AccountWithRestoreAccess: 'required',
    SnapshotClusterIdentifier: '<optional>',
  },
  'Redshift:rotateEncryptionKey': {
    ClusterIdentifier: 'required',
  },
  'Rekognition:compareFaces': {
    SourceImage: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    TargetImage: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    SimilarityThreshold: '<optional>',
  },
  'Rekognition:createCollection': {
    CollectionId: 'required',
  },
  'Rekognition:createProject': {
    ProjectName: 'required',
  },
  'Rekognition:createProjectVersion': {
    ProjectArn: 'required',
    VersionName: 'required',
    OutputConfig: {
      S3Bucket: '<optional>',
      S3KeyPrefix: '<optional>',
    },
    TrainingData: [
      {
        Assets: [
          {
            GroundTruthManifest: {
              S3Object: {
                Bucket: '<optional>',
                Name: '<optional>',
                Version: '<optional>',
              },
            },
          },
        ],
      },
    ],
    TestingData: [
      {
        Assets: [
          {
            GroundTruthManifest: {
              S3Object: {
                Bucket: '<optional>',
                Name: '<optional>',
                Version: '<optional>',
              },
            },
          },
        ],
        AutoCreate: '<optional>',
      },
    ],
  },
  'Rekognition:createStreamProcessor': {
    Input: {
      KinesisVideoStream: {
        Arn: '<optional>',
      },
    },
    Output: {
      KinesisDataStream: {
        Arn: '<optional>',
      },
    },
    Name: 'required',
    Settings: [
      {
        FaceSearch: {
          CollectionId: '<optional>',
          FaceMatchThreshold: '<optional>',
        },
      },
    ],
    RoleArn: 'required',
  },
  'Rekognition:deleteCollection': {
    CollectionId: 'required',
  },
  'Rekognition:deleteFaces': {
    CollectionId: 'required',
    FaceIds: ['required1', 'required2'],
  },
  'Rekognition:deleteStreamProcessor': {
    Name: 'required',
  },
  'Rekognition:describeCollection': {
    CollectionId: 'required',
  },
  'Rekognition:describeProjectVersions': {
    ProjectArn: 'required',
    VersionNames: ['<optional1>', '<optional2>'],
  },
  'Rekognition:describeProjects': {},
  'Rekognition:describeStreamProcessor': {
    Name: 'required',
  },
  'Rekognition:detectCustomLabels': {
    ProjectVersionArn: 'required',
    Image: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    MinConfidence: '<optional>',
  },
  'Rekognition:detectFaces': {
    Image: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    Attributes: ['<optional1>', '<optional2>'],
  },
  'Rekognition:detectLabels': {
    Image: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    MaxLabels: ['<optional1>', '<optional2>'],
    MinConfidence: '<optional>',
  },
  'Rekognition:detectModerationLabels': {
    Image: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    MinConfidence: '<optional>',
  },
  'Rekognition:detectText': {
    Image: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
  },
  'Rekognition:getCelebrityInfo': {
    Id: 'required',
  },
  'Rekognition:getCelebrityRecognition': {
    JobId: 'required',
    SortBy: '<optional>',
  },
  'Rekognition:getContentModeration': {
    JobId: 'required',
    SortBy: '<optional>',
  },
  'Rekognition:getFaceDetection': {
    JobId: 'required',
  },
  'Rekognition:getFaceSearch': {
    JobId: 'required',
    SortBy: '<optional>',
  },
  'Rekognition:getLabelDetection': {
    JobId: 'required',
    SortBy: '<optional>',
  },
  'Rekognition:getPersonTracking': {
    JobId: 'required',
    SortBy: '<optional>',
  },
  'Rekognition:indexFaces': {
    CollectionId: 'required',
    Image: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    ExternalImageId: '<optional>',
    DetectionAttributes: ['<optional1>', '<optional2>'],
    MaxFaces: ['<optional1>', '<optional2>'],
    QualityFilter: '<optional>',
  },
  'Rekognition:listCollections': {},
  'Rekognition:listFaces': {
    CollectionId: 'required',
  },
  'Rekognition:listStreamProcessors': {},
  'Rekognition:recognizeCelebrities': {
    Image: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
  },
  'Rekognition:searchFaces': {
    CollectionId: 'required',
    FaceId: 'required',
    MaxFaces: ['<optional1>', '<optional2>'],
    FaceMatchThreshold: '<optional>',
  },
  'Rekognition:searchFacesByImage': {
    CollectionId: 'required',
    Image: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    MaxFaces: ['<optional1>', '<optional2>'],
    FaceMatchThreshold: '<optional>',
  },
  'Rekognition:startCelebrityRecognition': {
    Video: {
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    NotificationChannel: '<optional>',
    ClientRequestToken: '<optional>',
    JobTag: '<optional>',
  },
  'Rekognition:startContentModeration': {
    Video: {
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    NotificationChannel: '<optional>',
    MinConfidence: '<optional>',
    ClientRequestToken: '<optional>',
    JobTag: '<optional>',
  },
  'Rekognition:startFaceDetection': {
    Video: {
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    NotificationChannel: '<optional>',
    ClientRequestToken: '<optional>',
    FaceAttributes: ['<optional1>', '<optional2>'],
    JobTag: '<optional>',
  },
  'Rekognition:startFaceSearch': {
    Video: {
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    CollectionId: 'required',
    NotificationChannel: '<optional>',
    ClientRequestToken: '<optional>',
    FaceMatchThreshold: '<optional>',
    JobTag: '<optional>',
  },
  'Rekognition:startLabelDetection': {
    Video: {
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    NotificationChannel: '<optional>',
    ClientRequestToken: '<optional>',
    MinConfidence: '<optional>',
    JobTag: '<optional>',
  },
  'Rekognition:startPersonTracking': {
    Video: {
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    NotificationChannel: '<optional>',
    ClientRequestToken: '<optional>',
    JobTag: '<optional>',
  },
  'Rekognition:startProjectVersion': {
    ProjectVersionArn: 'required',
    MinInferenceUnits: ['required1', 'required2'],
  },
  'Rekognition:startStreamProcessor': {
    Name: 'required',
  },
  'Rekognition:stopProjectVersion': {
    ProjectVersionArn: 'required',
  },
  'Rekognition:stopStreamProcessor': {
    Name: 'required',
  },
  'ResourceGroups:createGroup': {
    Name: 'required',
    ResourceQuery: {
      Type: 'required',
      Query: 'required',
    },
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'ResourceGroups:deleteGroup': {
    GroupName: 'required',
  },
  'ResourceGroups:getGroup': {
    GroupName: 'required',
  },
  'ResourceGroups:getGroupQuery': {
    GroupName: 'required',
  },
  'ResourceGroups:getTags': {
    Arn: 'required',
  },
  'ResourceGroups:listGroupResources': {
    GroupName: 'required',
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'ResourceGroups:listGroups': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'ResourceGroups:searchResources': {
    ResourceQuery: {
      Type: 'required',
      Query: 'required',
    },
  },
  'ResourceGroups:tag': {
    Arn: 'required',
    Tags: ['required1', 'required2'],
  },
  'ResourceGroups:untag': {
    Arn: 'required',
    Keys: ['required1', 'required2'],
  },
  'ResourceGroups:updateGroup': {
    GroupName: 'required',
    Description: '<optional>',
  },
  'ResourceGroups:updateGroupQuery': {
    GroupName: 'required',
    ResourceQuery: {
      Type: 'required',
      Query: 'required',
    },
  },
  'ResourceGroupsTaggingAPI:describeReportCreation': {},
  'ResourceGroupsTaggingAPI:getComplianceSummary': {
    TargetIdFilters: ['<optional1>', '<optional2>'],
    RegionFilters: ['<optional1>', '<optional2>'],
    ResourceTypeFilters: ['<optional1>', '<optional2>'],
    TagKeyFilters: ['<optional1>', '<optional2>'],
    GroupBy: '<optional>',
    PaginationToken: '<optional>',
  },
  'ResourceGroupsTaggingAPI:getResources': {
    PaginationToken: '<optional>',
    TagFilters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ResourcesPerPage: '<optional>',
    TagsPerPage: '<optional>',
    ResourceTypeFilters: ['<optional1>', '<optional2>'],
  },
  'ResourceGroupsTaggingAPI:getTagKeys': {
    PaginationToken: '<optional>',
  },
  'ResourceGroupsTaggingAPI:getTagValues': {
    Key: 'required',
    PaginationToken: '<optional>',
  },
  'ResourceGroupsTaggingAPI:startReportCreation': {
    S3Bucket: 'required',
  },
  'ResourceGroupsTaggingAPI:tagResources': {
    ResourceARNList: 'required',
    Tags: ['required1', 'required2'],
  },
  'ResourceGroupsTaggingAPI:untagResources': {
    ResourceARNList: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'RoboMaker:batchDescribeSimulationJob': {
    jobs: ['required1', 'required2'],
  },
  'RoboMaker:cancelDeploymentJob': {
    job: 'required',
  },
  'RoboMaker:cancelSimulationJob': {
    job: 'required',
  },
  'RoboMaker:createDeploymentJob': {
    clientRequestToken: 'required',
    fleet: 'required',
    deploymentApplicationConfigs: [
      {
        launchConfig: {
          preLaunchFile: '<optional>',
          postLaunchFile: '<optional>',
          environmentVariables: ['<optional1>', '<optional2>'],
        },
      },
    ],
    deploymentConfig: {
      concurrentDeploymentPercentage: '<optional>',
      failureThresholdPercentage: '<optional>',
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'RoboMaker:createFleet': {
    name: 'required',
    tags: ['<optional1>', '<optional2>'],
  },
  'RoboMaker:createRobot': {
    name: 'required',
    architecture: 'required',
    greengrassGroupId: 'required',
    tags: ['<optional1>', '<optional2>'],
  },
  'RoboMaker:createRobotApplication': {
    name: 'required',
    sources: [
      {
        s3Bucket: '<optional>',
        s3Key: '<optional>',
        architecture: '<optional>',
      },
    ],
    robotSoftwareSuite: {
      name: '<optional>',
      version: '<optional>',
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'RoboMaker:createRobotApplicationVersion': {
    application: 'required',
    currentRevisionId: '<optional>',
  },
  'RoboMaker:createSimulationApplication': {
    name: 'required',
    sources: [
      {
        s3Bucket: '<optional>',
        s3Key: '<optional>',
        architecture: '<optional>',
      },
    ],
    simulationSoftwareSuite: {
      name: '<optional>',
      version: '<optional>',
    },
    robotSoftwareSuite: {
      name: '<optional>',
      version: '<optional>',
    },
    renderingEngine: {
      name: '<optional>',
      version: '<optional>',
    },
    tags: ['<optional1>', '<optional2>'],
  },
  'RoboMaker:createSimulationApplicationVersion': {
    application: 'required',
    currentRevisionId: '<optional>',
  },
  'RoboMaker:createSimulationJob': {
    maxJobDurationInSeconds: ['required1', 'required2'],
    iamRole: 'required',
    robotApplications: [
      {
        applicationVersion: '<optional>',
        launchConfig: {
          environmentVariables: ['<optional1>', '<optional2>'],
        },
      },
    ],
    simulationApplications: [
      {
        applicationVersion: '<optional>',
        launchConfig: {
          environmentVariables: ['<optional1>', '<optional2>'],
        },
      },
    ],
    vpcConfig: {
      securityGroups: ['<optional1>', '<optional2>'],
      assignPublicIp: '<optional>',
    },
    clientRequestToken: '<optional>',
    outputLocation: {
      s3Bucket: '<optional>',
      s3Prefix: '<optional>',
    },
    failureBehavior: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'RoboMaker:deleteFleet': {
    fleet: 'required',
  },
  'RoboMaker:deleteRobot': {
    robot: 'required',
  },
  'RoboMaker:deleteRobotApplication': {
    application: 'required',
    applicationVersion: '<optional>',
  },
  'RoboMaker:deleteSimulationApplication': {
    application: 'required',
    applicationVersion: '<optional>',
  },
  'RoboMaker:deregisterRobot': {
    fleet: 'required',
    robot: 'required',
  },
  'RoboMaker:describeDeploymentJob': {
    job: 'required',
  },
  'RoboMaker:describeFleet': {
    fleet: 'required',
  },
  'RoboMaker:describeRobot': {
    robot: 'required',
  },
  'RoboMaker:describeRobotApplication': {
    application: 'required',
    applicationVersion: '<optional>',
  },
  'RoboMaker:describeSimulationApplication': {
    application: 'required',
    applicationVersion: '<optional>',
  },
  'RoboMaker:describeSimulationJob': {
    job: 'required',
  },
  'RoboMaker:listDeploymentJobs': {
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RoboMaker:listFleets': {
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RoboMaker:listRobotApplications': {
    versionQualifier: '<optional>',
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RoboMaker:listRobots': {
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RoboMaker:listSimulationApplications': {
    versionQualifier: '<optional>',
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RoboMaker:listSimulationJobs': {
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'RoboMaker:listTagsForResource': {
    resourceArn: 'required',
  },
  'RoboMaker:registerRobot': {
    fleet: 'required',
    robot: 'required',
  },
  'RoboMaker:restartSimulationJob': {
    job: 'required',
  },
  'RoboMaker:syncDeploymentJob': {
    clientRequestToken: 'required',
    fleet: 'required',
  },
  'RoboMaker:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'RoboMaker:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'RoboMaker:updateRobotApplication': {
    application: 'required',
    sources: [
      {
        s3Bucket: '<optional>',
        s3Key: '<optional>',
        architecture: '<optional>',
      },
    ],
    robotSoftwareSuite: {
      name: '<optional>',
      version: '<optional>',
    },
    currentRevisionId: '<optional>',
  },
  'RoboMaker:updateSimulationApplication': {
    application: 'required',
    sources: [
      {
        s3Bucket: '<optional>',
        s3Key: '<optional>',
        architecture: '<optional>',
      },
    ],
    simulationSoftwareSuite: {
      name: '<optional>',
      version: '<optional>',
    },
    robotSoftwareSuite: {
      name: '<optional>',
      version: '<optional>',
    },
    renderingEngine: {
      name: '<optional>',
      version: '<optional>',
    },
    currentRevisionId: '<optional>',
  },
  'Route53:associateVPCWithHostedZone': {
    HostedZoneId: 'required',
    VPC: {
      VPCRegion: '<optional>',
      VPCId: '<optional>',
    },
    Comment: '<optional>',
  },
  'Route53:changeResourceRecordSets': {
    HostedZoneId: 'required',
    ChangeBatch: {
      Comment: '<optional>',
      Changes: [
        {
          ResourceRecordSet: {
            SetIdentifier: '<optional>',
            Weight: '<optional>',
            Region: '<optional>',
            GeoLocation: {
              ContinentCode: '<optional>',
              CountryCode: '<optional>',
              SubdivisionCode: '<optional>',
            },
            Failover: '<optional>',
            MultiValueAnswer: '<optional>',
            TTL: '<optional>',
            ResourceRecords: ['<optional1>', '<optional2>'],
            AliasTarget: '<optional>',
            HealthCheckId: '<optional>',
            TrafficPolicyInstanceId: '<optional>',
          },
        },
      ],
    },
  },
  'Route53:changeTagsForResource': {
    ResourceType: 'required',
    ResourceId: 'required',
    AddTags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    RemoveTagKeys: ['<optional1>', '<optional2>'],
  },
  'Route53:createHealthCheck': {
    CallerReference: 'required',
    HealthCheckConfig: {
      IPAddress: '<optional>',
      Port: '<optional>',
      ResourcePath: '<optional>',
      FullyQualifiedDomainName: '<optional>',
      SearchString: '<optional>',
      RequestInterval: '<optional>',
      FailureThreshold: '<optional>',
      MeasureLatency: '<optional>',
      Inverted: '<optional>',
      Disabled: '<optional>',
      HealthThreshold: '<optional>',
      ChildHealthChecks: ['<optional1>', '<optional2>'],
      EnableSNI: '<optional>',
      Regions: ['<optional1>', '<optional2>'],
      AlarmIdentifier: '<optional>',
      InsufficientDataHealthStatus: '<optional>',
    },
  },
  'Route53:createHostedZone': {
    Name: 'required',
    CallerReference: 'required',
    VPC: {
      VPCRegion: '<optional>',
      VPCId: '<optional>',
    },
    HostedZoneConfig: {
      Comment: '<optional>',
      PrivateZone: '<optional>',
    },
    DelegationSetId: '<optional>',
  },
  'Route53:createQueryLoggingConfig': {
    HostedZoneId: 'required',
    CloudWatchLogsLogGroupArn: 'required',
  },
  'Route53:createReusableDelegationSet': {
    CallerReference: 'required',
    HostedZoneId: '<optional>',
  },
  'Route53:createTrafficPolicy': {
    Name: 'required',
    Document: 'required',
    Comment: '<optional>',
  },
  'Route53:createTrafficPolicyInstance': {
    HostedZoneId: 'required',
    Name: 'required',
    TTL: 'required',
    TrafficPolicyId: 'required',
    TrafficPolicyVersion: 'required',
  },
  'Route53:createTrafficPolicyVersion': {
    Id: 'required',
    Document: 'required',
    Comment: '<optional>',
  },
  'Route53:createVPCAssociationAuthorization': {
    HostedZoneId: 'required',
    VPC: {
      VPCRegion: '<optional>',
      VPCId: '<optional>',
    },
  },
  'Route53:deleteHealthCheck': {
    HealthCheckId: 'required',
  },
  'Route53:deleteHostedZone': {
    Id: 'required',
  },
  'Route53:deleteQueryLoggingConfig': {
    Id: 'required',
  },
  'Route53:deleteReusableDelegationSet': {
    Id: 'required',
  },
  'Route53:deleteTrafficPolicy': {
    Id: 'required',
    Version: 'required',
  },
  'Route53:deleteTrafficPolicyInstance': {
    Id: 'required',
  },
  'Route53:deleteVPCAssociationAuthorization': {
    HostedZoneId: 'required',
    VPC: {
      VPCRegion: '<optional>',
      VPCId: '<optional>',
    },
  },
  'Route53:disassociateVPCFromHostedZone': {
    HostedZoneId: 'required',
    VPC: {
      VPCRegion: '<optional>',
      VPCId: '<optional>',
    },
    Comment: '<optional>',
  },
  'Route53:getAccountLimit': {
    Type: 'required',
  },
  'Route53:getChange': {
    Id: 'required',
  },
  'Route53:getCheckerIpRanges': {},
  'Route53:getGeoLocation': {
    ContinentCode: '<optional>',
    CountryCode: '<optional>',
    SubdivisionCode: '<optional>',
  },
  'Route53:getHealthCheck': {
    HealthCheckId: 'required',
  },
  'Route53:getHealthCheckCount': {},
  'Route53:getHealthCheckLastFailureReason': {
    HealthCheckId: 'required',
  },
  'Route53:getHealthCheckStatus': {
    HealthCheckId: 'required',
  },
  'Route53:getHostedZone': {
    Id: 'required',
  },
  'Route53:getHostedZoneCount': {},
  'Route53:getHostedZoneLimit': {
    Type: 'required',
    HostedZoneId: 'required',
  },
  'Route53:getQueryLoggingConfig': {
    Id: 'required',
  },
  'Route53:getReusableDelegationSet': {
    Id: 'required',
  },
  'Route53:getReusableDelegationSetLimit': {
    Type: 'required',
    DelegationSetId: 'required',
  },
  'Route53:getTrafficPolicy': {
    Id: 'required',
    Version: 'required',
  },
  'Route53:getTrafficPolicyInstance': {
    Id: 'required',
  },
  'Route53:getTrafficPolicyInstanceCount': {},
  'Route53:listGeoLocations': {
    StartContinentCode: '<optional>',
    StartCountryCode: '<optional>',
    StartSubdivisionCode: '<optional>',
  },
  'Route53:listHealthChecks': {},
  'Route53:listHostedZones': {
    DelegationSetId: '<optional>',
  },
  'Route53:listHostedZonesByName': {
    DNSName: '<optional>',
    HostedZoneId: '<optional>',
  },
  'Route53:listQueryLoggingConfigs': {
    HostedZoneId: '<optional>',
  },
  'Route53:listResourceRecordSets': {
    HostedZoneId: 'required',
    StartRecordName: '<optional>',
    StartRecordType: '<optional>',
    StartRecordIdentifier: '<optional>',
  },
  'Route53:listReusableDelegationSets': {},
  'Route53:listTagsForResource': {
    ResourceType: 'required',
    ResourceId: 'required',
  },
  'Route53:listTagsForResources': {
    ResourceType: 'required',
    ResourceIds: ['required1', 'required2'],
  },
  'Route53:listTrafficPolicies': {
    TrafficPolicyIdMarker: '<optional>',
  },
  'Route53:listTrafficPolicyInstances': {
    HostedZoneIdMarker: '<optional>',
    TrafficPolicyInstanceNameMarker: '<optional>',
    TrafficPolicyInstanceTypeMarker: '<optional>',
  },
  'Route53:listTrafficPolicyInstancesByHostedZone': {
    HostedZoneId: 'required',
    TrafficPolicyInstanceNameMarker: '<optional>',
    TrafficPolicyInstanceTypeMarker: '<optional>',
  },
  'Route53:listTrafficPolicyInstancesByPolicy': {
    TrafficPolicyId: 'required',
    TrafficPolicyVersion: 'required',
    HostedZoneIdMarker: '<optional>',
    TrafficPolicyInstanceNameMarker: '<optional>',
    TrafficPolicyInstanceTypeMarker: '<optional>',
  },
  'Route53:listTrafficPolicyVersions': {
    Id: 'required',
    TrafficPolicyVersionMarker: '<optional>',
  },
  'Route53:listVPCAssociationAuthorizations': {
    HostedZoneId: 'required',
  },
  'Route53:testDNSAnswer': {
    HostedZoneId: 'required',
    RecordName: 'required',
    RecordType: 'required',
    ResolverIP: '<optional>',
    EDNS0ClientSubnetIP: '<optional>',
    EDNS0ClientSubnetMask: '<optional>',
  },
  'Route53:updateHealthCheck': {
    HealthCheckId: 'required',
    AlarmIdentifier: '<optional>',
    HealthCheckVersion: '<optional>',
    IPAddress: '<optional>',
    Port: '<optional>',
    ResourcePath: '<optional>',
    FullyQualifiedDomainName: '<optional>',
    SearchString: '<optional>',
    FailureThreshold: '<optional>',
    Inverted: '<optional>',
    Disabled: '<optional>',
    HealthThreshold: '<optional>',
    ChildHealthChecks: ['<optional1>', '<optional2>'],
    EnableSNI: '<optional>',
    Regions: ['<optional1>', '<optional2>'],
    InsufficientDataHealthStatus: '<optional>',
    ResetElements: ['<optional1>', '<optional2>'],
  },
  'Route53:updateHostedZoneComment': {
    Id: 'required',
    Comment: '<optional>',
  },
  'Route53:updateTrafficPolicyComment': {
    Id: 'required',
    Version: 'required',
    Comment: 'required',
  },
  'Route53:updateTrafficPolicyInstance': {
    Id: 'required',
    TTL: 'required',
    TrafficPolicyId: 'required',
    TrafficPolicyVersion: 'required',
  },
  'Route53Domains:checkDomainAvailability': {
    DomainName: 'required',
    IdnLangCode: '<optional>',
  },
  'Route53Domains:checkDomainTransferability': {
    DomainName: 'required',
    AuthCode: '<optional>',
  },
  'Route53Domains:deleteTagsForDomain': {
    DomainName: 'required',
    TagsToDelete: 'required',
  },
  'Route53Domains:disableDomainAutoRenew': {
    DomainName: 'required',
  },
  'Route53Domains:disableDomainTransferLock': {
    DomainName: 'required',
  },
  'Route53Domains:enableDomainAutoRenew': {
    DomainName: 'required',
  },
  'Route53Domains:enableDomainTransferLock': {
    DomainName: 'required',
  },
  'Route53Domains:getContactReachabilityStatus': {
    domainName: '<optional>',
  },
  'Route53Domains:getDomainDetail': {
    DomainName: 'required',
  },
  'Route53Domains:getDomainSuggestions': {
    DomainName: 'required',
    SuggestionCount: 'required',
    OnlyAvailable: 'required',
  },
  'Route53Domains:getOperationDetail': {
    OperationId: 'required',
  },
  'Route53Domains:listDomains': {},
  'Route53Domains:listOperations': {
    SubmittedSince: '<optional>',
  },
  'Route53Domains:listTagsForDomain': {
    DomainName: 'required',
  },
  'Route53Domains:registerDomain': {
    DomainName: 'required',
    DurationInYears: ['required1', 'required2'],
    AdminContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
    RegistrantContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
    TechContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
    IdnLangCode: '<optional>',
    AutoRenew: '<optional>',
    PrivacyProtectAdminContact: '<optional>',
    PrivacyProtectRegistrantContact: '<optional>',
    PrivacyProtectTechContact: '<optional>',
  },
  'Route53Domains:renewDomain': {
    DomainName: 'required',
    CurrentExpiryYear: 'required',
    DurationInYears: ['<optional1>', '<optional2>'],
  },
  'Route53Domains:resendContactReachabilityEmail': {
    domainName: '<optional>',
  },
  'Route53Domains:retrieveDomainAuthCode': {
    DomainName: 'required',
  },
  'Route53Domains:transferDomain': {
    DomainName: 'required',
    DurationInYears: ['required1', 'required2'],
    AdminContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
    RegistrantContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
    TechContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
    Nameservers: [
      {
        GlueIps: ['<optional1>', '<optional2>'],
      },
    ],
    IdnLangCode: '<optional>',
    AuthCode: '<optional>',
    AutoRenew: '<optional>',
    PrivacyProtectAdminContact: '<optional>',
    PrivacyProtectRegistrantContact: '<optional>',
    PrivacyProtectTechContact: '<optional>',
  },
  'Route53Domains:updateDomainContact': {
    DomainName: 'required',
    AdminContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
    RegistrantContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
    TechContact: {
      FirstName: '<optional>',
      LastName: '<optional>',
      ContactType: '<optional>',
      OrganizationName: '<optional>',
      AddressLine1: '<optional>',
      AddressLine2: '<optional>',
      City: '<optional>',
      State: '<optional>',
      CountryCode: '<optional>',
      ZipCode: '<optional>',
      PhoneNumber: '<optional>',
      Email: '<optional>',
      Fax: '<optional>',
      ExtraParams: ['<optional1>', '<optional2>'],
    },
  },
  'Route53Domains:updateDomainContactPrivacy': {
    DomainName: 'required',
    AdminPrivacy: '<optional>',
    RegistrantPrivacy: '<optional>',
    TechPrivacy: '<optional>',
  },
  'Route53Domains:updateDomainNameservers': {
    DomainName: 'required',
    Nameservers: [
      {
        GlueIps: ['<optional1>', '<optional2>'],
      },
    ],
    FIAuthKey: '<optional>',
  },
  'Route53Domains:updateTagsForDomain': {
    DomainName: 'required',
    TagsToUpdate: {
      Key: '<optional>',
      Value: '<optional>',
    },
  },
  'Route53Domains:viewBilling': {
    Start: '<optional>',
    End: '<optional>',
  },
  'Route53Resolver:associateResolverEndpointIpAddress': {
    ResolverEndpointId: 'required',
    IpAddress: {
      IpId: '<optional>',
      SubnetId: '<optional>',
      Ip: '<optional>',
    },
  },
  'Route53Resolver:associateResolverRule': {
    ResolverRuleId: 'required',
    VPCId: 'required',
    Name: '<optional>',
  },
  'Route53Resolver:createResolverEndpoint': {
    CreatorRequestId: 'required',
    SecurityGroupIds: ['required1', 'required2'],
    Direction: 'required',
    IpAddresses: [
      {
        Ip: '<optional>',
      },
    ],
    Name: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Route53Resolver:createResolverRule': {
    CreatorRequestId: 'required',
    RuleType: 'required',
    DomainName: 'required',
    TargetIps: [
      {
        Port: '<optional>',
      },
    ],
    Name: '<optional>',
    ResolverEndpointId: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Route53Resolver:deleteResolverEndpoint': {
    ResolverEndpointId: 'required',
  },
  'Route53Resolver:deleteResolverRule': {
    ResolverRuleId: 'required',
  },
  'Route53Resolver:disassociateResolverEndpointIpAddress': {
    ResolverEndpointId: 'required',
    IpAddress: {
      IpId: '<optional>',
      SubnetId: '<optional>',
      Ip: '<optional>',
    },
  },
  'Route53Resolver:disassociateResolverRule': {
    VPCId: 'required',
    ResolverRuleId: 'required',
  },
  'Route53Resolver:getResolverEndpoint': {
    ResolverEndpointId: 'required',
  },
  'Route53Resolver:getResolverRule': {
    ResolverRuleId: 'required',
  },
  'Route53Resolver:getResolverRuleAssociation': {
    ResolverRuleAssociationId: 'required',
  },
  'Route53Resolver:getResolverRulePolicy': {
    Arn: 'required',
  },
  'Route53Resolver:listResolverEndpointIpAddresses': {
    ResolverEndpointId: 'required',
  },
  'Route53Resolver:listResolverEndpoints': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Route53Resolver:listResolverRuleAssociations': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Route53Resolver:listResolverRules': {
    Filters: [
      {
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'Route53Resolver:listTagsForResource': {
    ResourceArn: 'required',
  },
  'Route53Resolver:putResolverRulePolicy': {
    Arn: 'required',
    ResolverRulePolicy: 'required',
  },
  'Route53Resolver:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'Route53Resolver:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Route53Resolver:updateResolverEndpoint': {
    ResolverEndpointId: 'required',
    Name: '<optional>',
  },
  'Route53Resolver:updateResolverRule': {
    ResolverRuleId: 'required',
    Config: {
      Name: '<optional>',
      TargetIps: [
        {
          Port: '<optional>',
        },
      ],
      ResolverEndpointId: '<optional>',
    },
  },
  'S3:abortMultipartUpload': {
    Bucket: 'required',
    Key: 'required',
    UploadId: 'required',
    RequestPayer: '<optional>',
  },
  'S3:completeMultipartUpload': {
    Bucket: 'required',
    Key: 'required',
    UploadId: 'required',
    MultipartUpload: {
      Parts: [
        {
          ETag: '<optional>',
          PartNumber: '<optional>',
        },
      ],
    },
    RequestPayer: '<optional>',
  },
  'S3:copyObject': {
    Bucket: 'required',
    CopySource: 'required',
    Key: 'required',
    ACL: '<optional>',
    CacheControl: '<optional>',
    ContentDisposition: '<optional>',
    ContentEncoding: '<optional>',
    ContentLanguage: '<optional>',
    ContentType: '<optional>',
    CopySourceIfMatch: '<optional>',
    CopySourceIfModifiedSince: '<optional>',
    CopySourceIfNoneMatch: '<optional>',
    CopySourceIfUnmodifiedSince: '<optional>',
    Expires: ['<optional1>', '<optional2>'],
    GrantFullControl: '<optional>',
    GrantRead: '<optional>',
    GrantReadACP: '<optional>',
    GrantWriteACP: '<optional>',
    Metadata: ['<optional1>', '<optional2>'],
    MetadataDirective: '<optional>',
    TaggingDirective: '<optional>',
    ServerSideEncryption: '<optional>',
    StorageClass: '<optional>',
    WebsiteRedirectLocation: '<optional>',
    SSECustomerAlgorithm: '<optional>',
    SSECustomerKey: '<optional>',
    SSECustomerKeyMD5: '<optional>',
    SSEKMSKeyId: '<optional>',
    SSEKMSEncryptionContext: '<optional>',
    CopySourceSSECustomerAlgorithm: '<optional>',
    CopySourceSSECustomerKey: '<optional>',
    CopySourceSSECustomerKeyMD5: '<optional>',
    RequestPayer: '<optional>',
    Tagging: '<optional>',
    ObjectLockMode: '<optional>',
    ObjectLockRetainUntilDate: '<optional>',
    ObjectLockLegalHoldStatus: '<optional>',
  },
  'S3:createBucket': {
    Bucket: 'required',
    ACL: '<optional>',
    CreateBucketConfiguration: {
      LocationConstraint: '<optional>',
    },
    GrantFullControl: '<optional>',
    GrantRead: '<optional>',
    GrantReadACP: '<optional>',
    GrantWrite: '<optional>',
    GrantWriteACP: '<optional>',
    ObjectLockEnabledForBucket: '<optional>',
  },
  'S3:createMultipartUpload': {
    Bucket: 'required',
    Key: 'required',
    ACL: '<optional>',
    CacheControl: '<optional>',
    ContentDisposition: '<optional>',
    ContentEncoding: '<optional>',
    ContentLanguage: '<optional>',
    ContentType: '<optional>',
    Expires: ['<optional1>', '<optional2>'],
    GrantFullControl: '<optional>',
    GrantRead: '<optional>',
    GrantReadACP: '<optional>',
    GrantWriteACP: '<optional>',
    Metadata: ['<optional1>', '<optional2>'],
    ServerSideEncryption: '<optional>',
    StorageClass: '<optional>',
    WebsiteRedirectLocation: '<optional>',
    SSECustomerAlgorithm: '<optional>',
    SSECustomerKey: '<optional>',
    SSECustomerKeyMD5: '<optional>',
    SSEKMSKeyId: '<optional>',
    SSEKMSEncryptionContext: '<optional>',
    RequestPayer: '<optional>',
    Tagging: '<optional>',
    ObjectLockMode: '<optional>',
    ObjectLockRetainUntilDate: '<optional>',
    ObjectLockLegalHoldStatus: '<optional>',
  },
  'S3:deleteBucket': {
    Bucket: 'required',
  },
  'S3:deleteBucketAnalyticsConfiguration': {
    Bucket: 'required',
    Id: 'required',
  },
  'S3:deleteBucketCors': {
    Bucket: 'required',
  },
  'S3:deleteBucketEncryption': {
    Bucket: 'required',
  },
  'S3:deleteBucketInventoryConfiguration': {
    Bucket: 'required',
    Id: 'required',
  },
  'S3:deleteBucketLifecycle': {
    Bucket: 'required',
  },
  'S3:deleteBucketMetricsConfiguration': {
    Bucket: 'required',
    Id: 'required',
  },
  'S3:deleteBucketPolicy': {
    Bucket: 'required',
  },
  'S3:deleteBucketReplication': {
    Bucket: 'required',
  },
  'S3:deleteBucketTagging': {
    Bucket: 'required',
  },
  'S3:deleteBucketWebsite': {
    Bucket: 'required',
  },
  'S3:deleteObject': {
    Bucket: 'required',
    Key: 'required',
    MFA: '<optional>',
    VersionId: '<optional>',
    RequestPayer: '<optional>',
    BypassGovernanceRetention: '<optional>',
  },
  'S3:deleteObjectTagging': {
    Bucket: 'required',
    Key: 'required',
    VersionId: '<optional>',
  },
  'S3:deleteObjects': {
    Bucket: 'required',
    Delete: {
      Quiet: '<optional>',
      Objects: [
        {
          VersionId: '<optional>',
        },
      ],
    },
    MFA: '<optional>',
    RequestPayer: '<optional>',
    BypassGovernanceRetention: '<optional>',
  },
  'S3:deletePublicAccessBlock': {
    Bucket: 'required',
  },
  'S3:getBucketAccelerateConfiguration': {
    Bucket: 'required',
  },
  'S3:getBucketAcl': {
    Bucket: 'required',
  },
  'S3:getBucketAnalyticsConfiguration': {
    Bucket: 'required',
    Id: 'required',
  },
  'S3:getBucketCors': {
    Bucket: 'required',
  },
  'S3:getBucketEncryption': {
    Bucket: 'required',
  },
  'S3:getBucketInventoryConfiguration': {
    Bucket: 'required',
    Id: 'required',
  },
  'S3:getBucketLifecycle': {
    Bucket: 'required',
  },
  'S3:getBucketLifecycleConfiguration': {
    Bucket: 'required',
  },
  'S3:getBucketLocation': {
    Bucket: 'required',
  },
  'S3:getBucketLogging': {
    Bucket: 'required',
  },
  'S3:getBucketMetricsConfiguration': {
    Bucket: 'required',
    Id: 'required',
  },
  'S3:getBucketNotification': {
    Bucket: 'required',
  },
  'S3:getBucketNotificationConfiguration': {
    Bucket: 'required',
  },
  'S3:getBucketPolicy': {
    Bucket: 'required',
  },
  'S3:getBucketPolicyStatus': {
    Bucket: 'required',
  },
  'S3:getBucketReplication': {
    Bucket: 'required',
  },
  'S3:getBucketRequestPayment': {
    Bucket: 'required',
  },
  'S3:getBucketTagging': {
    Bucket: 'required',
  },
  'S3:getBucketVersioning': {
    Bucket: 'required',
  },
  'S3:getBucketWebsite': {
    Bucket: 'required',
  },
  'S3:getObject': {
    Bucket: 'required',
    Key: 'required',
    IfMatch: '<optional>',
    IfModifiedSince: '<optional>',
    IfNoneMatch: '<optional>',
    IfUnmodifiedSince: '<optional>',
    Range: '<optional>',
    ResponseCacheControl: '<optional>',
    ResponseContentDisposition: '<optional>',
    ResponseContentEncoding: '<optional>',
    ResponseContentLanguage: '<optional>',
    ResponseContentType: '<optional>',
    ResponseExpires: ['<optional1>', '<optional2>'],
    VersionId: '<optional>',
    SSECustomerAlgorithm: '<optional>',
    SSECustomerKey: '<optional>',
    SSECustomerKeyMD5: '<optional>',
    RequestPayer: '<optional>',
    PartNumber: '<optional>',
  },
  'S3:getObjectAcl': {
    Bucket: 'required',
    Key: 'required',
    VersionId: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3:getObjectLegalHold': {
    Bucket: 'required',
    Key: 'required',
    VersionId: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3:getObjectLockConfiguration': {
    Bucket: 'required',
  },
  'S3:getObjectRetention': {
    Bucket: 'required',
    Key: 'required',
    VersionId: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3:getObjectTagging': {
    Bucket: 'required',
    Key: 'required',
    VersionId: '<optional>',
  },
  'S3:getObjectTorrent': {
    Bucket: 'required',
    Key: 'required',
    RequestPayer: '<optional>',
  },
  'S3:getPublicAccessBlock': {
    Bucket: 'required',
  },
  'S3:headBucket': {
    Bucket: 'required',
  },
  'S3:headObject': {
    Bucket: 'required',
    Key: 'required',
    IfMatch: '<optional>',
    IfModifiedSince: '<optional>',
    IfNoneMatch: '<optional>',
    IfUnmodifiedSince: '<optional>',
    Range: '<optional>',
    VersionId: '<optional>',
    SSECustomerAlgorithm: '<optional>',
    SSECustomerKey: '<optional>',
    SSECustomerKeyMD5: '<optional>',
    RequestPayer: '<optional>',
    PartNumber: '<optional>',
  },
  'S3:listBucketAnalyticsConfigurations': {
    Bucket: 'required',
    ContinuationToken: '<optional>',
  },
  'S3:listBucketInventoryConfigurations': {
    Bucket: 'required',
    ContinuationToken: '<optional>',
  },
  'S3:listBucketMetricsConfigurations': {
    Bucket: 'required',
    ContinuationToken: '<optional>',
  },
  'S3:listBuckets': {},
  'S3:listMultipartUploads': {
    Bucket: 'required',
    Delimiter: '<optional>',
    EncodingType: '<optional>',
    KeyMarker: '<optional>',
    MaxUploads: ['<optional1>', '<optional2>'],
    Prefix: '<optional>',
    UploadIdMarker: '<optional>',
  },
  'S3:listObjectVersions': {
    Bucket: 'required',
    Delimiter: '<optional>',
    EncodingType: '<optional>',
    KeyMarker: '<optional>',
    MaxKeys: ['<optional1>', '<optional2>'],
    Prefix: '<optional>',
    VersionIdMarker: '<optional>',
  },
  'S3:listObjects': {
    Bucket: 'required',
    Delimiter: '<optional>',
    EncodingType: '<optional>',
    MaxKeys: ['<optional1>', '<optional2>'],
    Prefix: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3:listObjectsV2': {
    Bucket: 'required',
    Delimiter: '<optional>',
    EncodingType: '<optional>',
    MaxKeys: ['<optional1>', '<optional2>'],
    Prefix: '<optional>',
    ContinuationToken: '<optional>',
    FetchOwner: '<optional>',
    StartAfter: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3:listParts': {
    Bucket: 'required',
    Key: 'required',
    UploadId: 'required',
    MaxParts: ['<optional1>', '<optional2>'],
    PartNumberMarker: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3:putBucketAccelerateConfiguration': {
    Bucket: 'required',
    AccelerateConfiguration: {
      Status: '<optional>',
    },
  },
  'S3:putBucketAcl': {
    Bucket: 'required',
    AccessControlPolicy: {
      Grants: [
        {
          Grantee: {
            DisplayName: '<optional>',
            EmailAddress: '<optional>',
            ID: '<optional>',
            URI: '<optional>',
          },
          Permission: '<optional>',
        },
      ],
      Owner: {
        DisplayName: '<optional>',
        ID: '<optional>',
      },
    },
    ACL: '<optional>',
    ContentMD5: '<optional>',
    GrantFullControl: '<optional>',
    GrantRead: '<optional>',
    GrantReadACP: '<optional>',
    GrantWrite: '<optional>',
    GrantWriteACP: '<optional>',
  },
  'S3:putBucketAnalyticsConfiguration': {
    Bucket: 'required',
    Id: 'required',
    AnalyticsConfiguration: {
      Filter: {
        Prefix: '<optional>',
        Tag: '<optional>',
        And: {
          Prefix: '<optional>',
          Tags: ['<optional1>', '<optional2>'],
        },
      },
      StorageClassAnalysis: {
        DataExport: {
          Destination: {
            S3BucketDestination: {
              BucketAccountId: '<optional>',
              Prefix: '<optional>',
            },
          },
        },
      },
    },
  },
  'S3:putBucketCors': {
    Bucket: 'required',
    CORSConfiguration: {
      CORSRules: [
        {
          AllowedHeaders: ['<optional1>', '<optional2>'],
          ExposeHeaders: ['<optional1>', '<optional2>'],
          MaxAgeSeconds: ['<optional1>', '<optional2>'],
        },
      ],
    },
    ContentMD5: '<optional>',
  },
  'S3:putBucketEncryption': {
    Bucket: 'required',
    ServerSideEncryptionConfiguration: {
      Rules: [
        {
          ApplyServerSideEncryptionByDefault: {
            KMSMasterKeyID: '<optional>',
          },
        },
      ],
    },
    ContentMD5: '<optional>',
  },
  'S3:putBucketInventoryConfiguration': {
    Bucket: 'required',
    Id: 'required',
    InventoryConfiguration: {
      Filter: '<optional>',
      OptionalFields: ['<optional1>', '<optional2>'],
      Destination: {
        S3BucketDestination: {
          AccountId: '<optional>',
          Prefix: '<optional>',
          Encryption: {
            SSES3: {
              type: '<optional>',
            },
            SSEKMS: ['<optional1>', '<optional2>'],
          },
        },
      },
    },
  },
  'S3:putBucketLifecycle': {
    Bucket: 'required',
    LifecycleConfiguration: {
      Rules: [
        {
          Expiration: {
            Date: '<optional>',
            Days: ['<optional1>', '<optional2>'],
            ExpiredObjectDeleteMarker: '<optional>',
          },
          ID: '<optional>',
          Transition: {
            Date: '<optional>',
            Days: ['<optional1>', '<optional2>'],
            StorageClass: '<optional>',
          },
          NoncurrentVersionTransition: {
            NoncurrentDays: ['<optional1>', '<optional2>'],
            StorageClass: '<optional>',
          },
          NoncurrentVersionExpiration: {
            NoncurrentDays: ['<optional1>', '<optional2>'],
          },
          AbortIncompleteMultipartUpload: {
            DaysAfterInitiation: '<optional>',
          },
        },
      ],
    },
    ContentMD5: '<optional>',
  },
  'S3:putBucketLifecycleConfiguration': {
    Bucket: 'required',
    LifecycleConfiguration: {
      Rules: [
        {
          Expiration: {
            Date: '<optional>',
            Days: ['<optional1>', '<optional2>'],
            ExpiredObjectDeleteMarker: '<optional>',
          },
          ID: '<optional>',
          Prefix: '<optional>',
          Filter: {
            Prefix: '<optional>',
            Tag: '<optional>',
            And: {
              Prefix: '<optional>',
              Tags: ['<optional1>', '<optional2>'],
            },
          },
          Transitions: [
            {
              Date: '<optional>',
              Days: ['<optional1>', '<optional2>'],
              StorageClass: '<optional>',
            },
          ],
          NoncurrentVersionTransitions: [
            {
              NoncurrentDays: ['<optional1>', '<optional2>'],
              StorageClass: '<optional>',
            },
          ],
          NoncurrentVersionExpiration: {
            NoncurrentDays: ['<optional1>', '<optional2>'],
          },
          AbortIncompleteMultipartUpload: {
            DaysAfterInitiation: '<optional>',
          },
        },
      ],
    },
  },
  'S3:putBucketLogging': {
    Bucket: 'required',
    BucketLoggingStatus: {
      LoggingEnabled: {
        TargetGrants: [
          {
            Grantee: {
              DisplayName: '<optional>',
              EmailAddress: '<optional>',
              ID: '<optional>',
              URI: '<optional>',
            },
            Permission: '<optional>',
          },
        ],
      },
    },
    ContentMD5: '<optional>',
  },
  'S3:putBucketMetricsConfiguration': {
    Bucket: 'required',
    Id: 'required',
    MetricsConfiguration: {
      Filter: {
        Prefix: '<optional>',
        Tag: '<optional>',
        And: {
          Prefix: '<optional>',
          Tags: ['<optional1>', '<optional2>'],
        },
      },
    },
  },
  'S3:putBucketNotification': {
    Bucket: 'required',
    NotificationConfiguration: {
      TopicConfiguration: {
        Id: '<optional>',
        Events: ['<optional1>', '<optional2>'],
        Event: '<optional>',
        Topic: '<optional>',
      },
      QueueConfiguration: {
        Id: '<optional>',
        Event: '<optional>',
        Events: ['<optional1>', '<optional2>'],
        Queue: '<optional>',
      },
      CloudFunctionConfiguration: {
        Id: '<optional>',
        Event: '<optional>',
        Events: ['<optional1>', '<optional2>'],
        CloudFunction: '<optional>',
        InvocationRole: '<optional>',
      },
    },
    ContentMD5: '<optional>',
  },
  'S3:putBucketNotificationConfiguration': {
    Bucket: 'required',
    NotificationConfiguration: {
      TopicConfigurations: [
        {
          Id: '<optional>',
          Filter: {
            Key: {
              FilterRules: [
                {
                  Name: '<optional>',
                  Value: '<optional>',
                },
              ],
            },
          },
        },
      ],
      QueueConfigurations: [
        {
          Id: '<optional>',
          Filter: {
            Key: {
              FilterRules: [
                {
                  Name: '<optional>',
                  Value: '<optional>',
                },
              ],
            },
          },
        },
      ],
      LambdaFunctionConfigurations: [
        {
          Id: '<optional>',
          Filter: {
            Key: {
              FilterRules: [
                {
                  Name: '<optional>',
                  Value: '<optional>',
                },
              ],
            },
          },
        },
      ],
    },
  },
  'S3:putBucketPolicy': {
    Bucket: 'required',
    Policy: 'required',
    ContentMD5: '<optional>',
    ConfirmRemoveSelfBucketAccess: '<optional>',
  },
  'S3:putBucketReplication': {
    Bucket: 'required',
    ReplicationConfiguration: {
      Rules: [
        {
          ID: '<optional>',
          Priority: '<optional>',
          Prefix: '<optional>',
          Filter: {
            Prefix: '<optional>',
            Tag: '<optional>',
            And: {
              Prefix: '<optional>',
              Tags: ['<optional1>', '<optional2>'],
            },
          },
          SourceSelectionCriteria: [
            {
              SseKmsEncryptedObjects: ['<optional1>', '<optional2>'],
            },
          ],
          DeleteMarkerReplication: {
            Status: '<optional>',
          },
          Destination: {
            Account: '<optional>',
            StorageClass: '<optional>',
            AccessControlTranslation: '<optional>',
            EncryptionConfiguration: {
              ReplicaKmsKeyID: '<optional>',
            },
          },
        },
      ],
    },
    ContentMD5: '<optional>',
    Token: '<optional>',
  },
  'S3:putBucketRequestPayment': {
    Bucket: 'required',
    RequestPaymentConfiguration: {
      Payer: 'required',
    },
    ContentMD5: '<optional>',
  },
  'S3:putBucketTagging': {
    Bucket: 'required',
    Tagging: {
      TagSet: {
        Key: 'required',
        Value: 'required',
      },
    },
    ContentMD5: '<optional>',
  },
  'S3:putBucketVersioning': {
    Bucket: 'required',
    VersioningConfiguration: {
      MFADelete: '<optional>',
      Status: '<optional>',
    },
    ContentMD5: '<optional>',
    MFA: '<optional>',
  },
  'S3:putBucketWebsite': {
    Bucket: 'required',
    WebsiteConfiguration: {
      ErrorDocument: '<optional>',
      IndexDocument: '<optional>',
      RedirectAllRequestsTo: {
        Protocol: '<optional>',
      },
      RoutingRules: [
        {
          Condition: {
            HttpErrorCodeReturnedEquals: ['<optional1>', '<optional2>'],
            KeyPrefixEquals: ['<optional1>', '<optional2>'],
          },
          Redirect: {
            HostName: '<optional>',
            HttpRedirectCode: '<optional>',
            Protocol: '<optional>',
            ReplaceKeyPrefixWith: '<optional>',
            ReplaceKeyWith: '<optional>',
          },
        },
      ],
    },
    ContentMD5: '<optional>',
  },
  'S3:putObject': {
    Bucket: 'required',
    Key: 'required',
    ACL: '<optional>',
    Body: '<optional>',
    CacheControl: '<optional>',
    ContentDisposition: '<optional>',
    ContentEncoding: '<optional>',
    ContentLanguage: '<optional>',
    ContentLength: '<optional>',
    ContentMD5: '<optional>',
    ContentType: '<optional>',
    Expires: ['<optional1>', '<optional2>'],
    GrantFullControl: '<optional>',
    GrantRead: '<optional>',
    GrantReadACP: '<optional>',
    GrantWriteACP: '<optional>',
    Metadata: ['<optional1>', '<optional2>'],
    ServerSideEncryption: '<optional>',
    StorageClass: '<optional>',
    WebsiteRedirectLocation: '<optional>',
    SSECustomerAlgorithm: '<optional>',
    SSECustomerKey: '<optional>',
    SSECustomerKeyMD5: '<optional>',
    SSEKMSKeyId: '<optional>',
    SSEKMSEncryptionContext: '<optional>',
    RequestPayer: '<optional>',
    Tagging: '<optional>',
    ObjectLockMode: '<optional>',
    ObjectLockRetainUntilDate: '<optional>',
    ObjectLockLegalHoldStatus: '<optional>',
  },
  'S3:putObjectAcl': {
    Bucket: 'required',
    Key: 'required',
    AccessControlPolicy: {
      Grants: [
        {
          Grantee: {
            DisplayName: '<optional>',
            EmailAddress: '<optional>',
            ID: '<optional>',
            URI: '<optional>',
          },
          Permission: '<optional>',
        },
      ],
      Owner: {
        DisplayName: '<optional>',
        ID: '<optional>',
      },
    },
    ACL: '<optional>',
    ContentMD5: '<optional>',
    GrantFullControl: '<optional>',
    GrantRead: '<optional>',
    GrantReadACP: '<optional>',
    GrantWrite: '<optional>',
    GrantWriteACP: '<optional>',
    RequestPayer: '<optional>',
    VersionId: '<optional>',
  },
  'S3:putObjectLegalHold': {
    Bucket: 'required',
    Key: 'required',
    LegalHold: {
      Status: '<optional>',
    },
    RequestPayer: '<optional>',
    VersionId: '<optional>',
    ContentMD5: '<optional>',
  },
  'S3:putObjectLockConfiguration': {
    Bucket: 'required',
    ObjectLockConfiguration: {
      ObjectLockEnabled: '<optional>',
      Rule: {
        DefaultRetention: {
          Mode: '<optional>',
          Days: ['<optional1>', '<optional2>'],
          Years: ['<optional1>', '<optional2>'],
        },
      },
    },
    RequestPayer: '<optional>',
    Token: '<optional>',
    ContentMD5: '<optional>',
  },
  'S3:putObjectRetention': {
    Bucket: 'required',
    Key: 'required',
    Retention: {
      Mode: '<optional>',
      RetainUntilDate: '<optional>',
    },
    RequestPayer: '<optional>',
    VersionId: '<optional>',
    BypassGovernanceRetention: '<optional>',
    ContentMD5: '<optional>',
  },
  'S3:putObjectTagging': {
    Bucket: 'required',
    Key: 'required',
    Tagging: {
      TagSet: {
        Key: 'required',
        Value: 'required',
      },
    },
    VersionId: '<optional>',
    ContentMD5: '<optional>',
  },
  'S3:putPublicAccessBlock': {
    Bucket: 'required',
    PublicAccessBlockConfiguration: {
      BlockPublicAcls: ['<optional1>', '<optional2>'],
      IgnorePublicAcls: ['<optional1>', '<optional2>'],
      BlockPublicPolicy: '<optional>',
      RestrictPublicBuckets: ['<optional1>', '<optional2>'],
    },
    ContentMD5: '<optional>',
  },
  'S3:restoreObject': {
    Bucket: 'required',
    Key: 'required',
    RestoreRequest: {
      Days: ['<optional1>', '<optional2>'],
      GlacierJobParameters: ['<optional1>', '<optional2>'],
      Type: '<optional>',
      Tier: '<optional>',
      Description: '<optional>',
      SelectParameters: [
        {
          InputSerialization: {
            CSV: {
              FileHeaderInfo: '<optional>',
              Comments: ['<optional1>', '<optional2>'],
              QuoteEscapeCharacter: '<optional>',
              RecordDelimiter: '<optional>',
              FieldDelimiter: '<optional>',
              QuoteCharacter: '<optional>',
              AllowQuotedRecordDelimiter: '<optional>',
            },
            CompressionType: '<optional>',
            JSON: {
              Type: '<optional>',
            },
            Parquet: '<optional>',
          },
          OutputSerialization: {
            CSV: {
              QuoteFields: ['<optional1>', '<optional2>'],
              QuoteEscapeCharacter: '<optional>',
              RecordDelimiter: '<optional>',
              FieldDelimiter: '<optional>',
              QuoteCharacter: '<optional>',
            },
            JSON: {
              RecordDelimiter: '<optional>',
            },
          },
        },
      ],
      OutputLocation: {
        S3: {
          Encryption: {
            KMSKeyId: '<optional>',
            KMSContext: '<optional>',
          },
          CannedACL: '<optional>',
          AccessControlList: {
            Grantee: {
              DisplayName: '<optional>',
              EmailAddress: '<optional>',
              ID: '<optional>',
              URI: '<optional>',
            },
            Permission: '<optional>',
          },
          Tagging: '<optional>',
          UserMetadata: [
            {
              Name: '<optional>',
              Value: '<optional>',
            },
          ],
          StorageClass: '<optional>',
        },
      },
    },
    VersionId: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3:selectObjectContent': {
    Bucket: 'required',
    Key: 'required',
    Expression: 'required',
    ExpressionType: 'required',
    InputSerialization: {
      CSV: {
        FileHeaderInfo: '<optional>',
        Comments: ['<optional1>', '<optional2>'],
        QuoteEscapeCharacter: '<optional>',
        RecordDelimiter: '<optional>',
        FieldDelimiter: '<optional>',
        QuoteCharacter: '<optional>',
        AllowQuotedRecordDelimiter: '<optional>',
      },
      CompressionType: '<optional>',
      JSON: {
        Type: '<optional>',
      },
      Parquet: {
        type: '<optional>',
      },
    },
    OutputSerialization: {
      CSV: {
        QuoteFields: ['<optional1>', '<optional2>'],
        QuoteEscapeCharacter: '<optional>',
        RecordDelimiter: '<optional>',
        FieldDelimiter: '<optional>',
        QuoteCharacter: '<optional>',
      },
      JSON: {
        RecordDelimiter: '<optional>',
      },
    },
    SSECustomerAlgorithm: '<optional>',
    SSECustomerKey: '<optional>',
    SSECustomerKeyMD5: '<optional>',
    RequestProgress: {
      Enabled: '<optional>',
    },
  },
  'S3:uploadPart': {
    Bucket: 'required',
    Key: 'required',
    PartNumber: 'required',
    UploadId: 'required',
    Body: '<optional>',
    ContentLength: '<optional>',
    ContentMD5: '<optional>',
    SSECustomerAlgorithm: '<optional>',
    SSECustomerKey: '<optional>',
    SSECustomerKeyMD5: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3:uploadPartCopy': {
    Bucket: 'required',
    CopySource: 'required',
    Key: 'required',
    PartNumber: 'required',
    UploadId: 'required',
    CopySourceIfMatch: '<optional>',
    CopySourceIfModifiedSince: '<optional>',
    CopySourceIfNoneMatch: '<optional>',
    CopySourceIfUnmodifiedSince: '<optional>',
    CopySourceRange: '<optional>',
    SSECustomerAlgorithm: '<optional>',
    SSECustomerKey: '<optional>',
    SSECustomerKeyMD5: '<optional>',
    CopySourceSSECustomerAlgorithm: '<optional>',
    CopySourceSSECustomerKey: '<optional>',
    CopySourceSSECustomerKeyMD5: '<optional>',
    RequestPayer: '<optional>',
  },
  'S3Control:createAccessPoint': {
    AccountId: 'required',
    Name: 'required',
    Bucket: 'required',
    VpcConfiguration: '<optional>',
    PublicAccessBlockConfiguration: {
      BlockPublicAcls: ['<optional1>', '<optional2>'],
      IgnorePublicAcls: ['<optional1>', '<optional2>'],
      BlockPublicPolicy: '<optional>',
      RestrictPublicBuckets: ['<optional1>', '<optional2>'],
    },
  },
  'S3Control:createJob': {
    AccountId: 'required',
    Operation: {
      LambdaInvoke: {
        FunctionArn: '<optional>',
      },
      S3PutObjectCopy: {
        TargetResource: '<optional>',
        CannedAccessControlList: '<optional>',
        AccessControlGrants: [
          {
            Grantee: {
              TypeIdentifier: '<optional>',
              Identifier: '<optional>',
              DisplayName: '<optional>',
            },
            Permission: '<optional>',
          },
        ],
        MetadataDirective: '<optional>',
        ModifiedSinceConstraint: '<optional>',
        NewObjectMetadata: [
          {
            CacheControl: '<optional>',
            ContentDisposition: '<optional>',
            ContentEncoding: '<optional>',
            ContentLanguage: '<optional>',
            UserMetadata: ['<optional1>', '<optional2>'],
            ContentLength: '<optional>',
            ContentMD5: '<optional>',
            ContentType: '<optional>',
            HttpExpiresDate: '<optional>',
            RequesterCharged: '<optional>',
            SSEAlgorithm: '<optional>',
          },
        ],
        NewObjectTagging: '<optional>',
        RedirectLocation: '<optional>',
        RequesterPays: ['<optional1>', '<optional2>'],
        StorageClass: '<optional>',
        UnModifiedSinceConstraint: '<optional>',
        SSEAwsKmsKeyId: '<optional>',
        TargetKeyPrefix: '<optional>',
        ObjectLockLegalHoldStatus: '<optional>',
        ObjectLockMode: '<optional>',
        ObjectLockRetainUntilDate: '<optional>',
      },
      S3PutObjectAcl: {
        AccessControlPolicy: {
          AccessControlList: {
            Grants: [
              {
                Grantee: {
                  TypeIdentifier: '<optional>',
                  Identifier: '<optional>',
                  DisplayName: '<optional>',
                },
                Permission: '<optional>',
              },
            ],
            Owner: {
              ID: '<optional>',
              DisplayName: '<optional>',
            },
          },
          CannedAccessControlList: '<optional>',
        },
      },
      S3PutObjectTagging: {
        TagSet: '<optional>',
      },
      S3InitiateRestoreObject: {
        ExpirationInDays: ['<optional1>', '<optional2>'],
        GlacierJobTier: '<optional>',
      },
    },
    Report: {
      Bucket: '<optional>',
      Format: '<optional>',
      Prefix: '<optional>',
      ReportScope: '<optional>',
    },
    ClientRequestToken: 'required',
    Manifest: {
      Spec: {
        Fields: ['<optional1>', '<optional2>'],
      },
      Location: {
        ObjectVersionId: '<optional>',
      },
    },
    Priority: 'required',
    RoleArn: 'required',
    ConfirmationRequired: '<optional>',
    Description: '<optional>',
  },
  'S3Control:deleteAccessPoint': {
    AccountId: 'required',
    Name: 'required',
  },
  'S3Control:deleteAccessPointPolicy': {
    AccountId: 'required',
    Name: 'required',
  },
  'S3Control:deletePublicAccessBlock': {
    AccountId: 'required',
  },
  'S3Control:describeJob': {
    AccountId: 'required',
    JobId: 'required',
  },
  'S3Control:getAccessPoint': {
    AccountId: 'required',
    Name: 'required',
  },
  'S3Control:getAccessPointPolicy': {
    AccountId: 'required',
    Name: 'required',
  },
  'S3Control:getAccessPointPolicyStatus': {
    AccountId: 'required',
    Name: 'required',
  },
  'S3Control:getPublicAccessBlock': {
    AccountId: 'required',
  },
  'S3Control:listAccessPoints': {
    AccountId: 'required',
    Bucket: '<optional>',
  },
  'S3Control:listJobs': {
    AccountId: 'required',
    JobStatuses: ['<optional1>', '<optional2>'],
  },
  'S3Control:putAccessPointPolicy': {
    AccountId: 'required',
    Name: 'required',
    Policy: 'required',
  },
  'S3Control:putPublicAccessBlock': {
    PublicAccessBlockConfiguration: {
      BlockPublicAcls: ['<optional1>', '<optional2>'],
      IgnorePublicAcls: ['<optional1>', '<optional2>'],
      BlockPublicPolicy: '<optional>',
      RestrictPublicBuckets: ['<optional1>', '<optional2>'],
    },
    AccountId: 'required',
  },
  'S3Control:updateJobPriority': {
    AccountId: 'required',
    JobId: 'required',
    Priority: 'required',
  },
  'S3Control:updateJobStatus': {
    AccountId: 'required',
    JobId: 'required',
    RequestedJobStatus: 'required',
    StatusUpdateReason: '<optional>',
  },
  'SES:cloneReceiptRuleSet': {
    RuleSetName: 'required',
    OriginalRuleSetName: 'required',
  },
  'SES:createConfigurationSet': {
    ConfigurationSet: {
      Name: 'required',
    },
  },
  'SES:createConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestination: {
      Enabled: '<optional>',
      KinesisFirehoseDestination: '<optional>',
      CloudWatchDestination: '<optional>',
      SNSDestination: '<optional>',
    },
  },
  'SES:createConfigurationSetTrackingOptions': {
    ConfigurationSetName: 'required',
    TrackingOptions: [
      {
        CustomRedirectDomain: '<optional>',
      },
    ],
  },
  'SES:createCustomVerificationEmailTemplate': {
    TemplateName: 'required',
    FromEmailAddress: 'required',
    TemplateSubject: 'required',
    TemplateContent: 'required',
    SuccessRedirectionURL: 'required',
    FailureRedirectionURL: 'required',
  },
  'SES:createReceiptFilter': {
    Filter: {
      Name: '<optional>',
      IpFilter: {
        Policy: '<optional>',
        Cidr: '<optional>',
      },
    },
  },
  'SES:createReceiptRule': {
    RuleSetName: 'required',
    Rule: {
      Enabled: '<optional>',
      TlsPolicy: '<optional>',
      Recipients: ['<optional1>', '<optional2>'],
      Actions: [
        {
          S3Action: {
            TopicArn: '<optional>',
            ObjectKeyPrefix: '<optional>',
            KmsKeyArn: '<optional>',
          },
          BounceAction: {
            TopicArn: '<optional>',
            StatusCode: '<optional>',
          },
          WorkmailAction: {
            TopicArn: '<optional>',
          },
          LambdaAction: {
            TopicArn: '<optional>',
            InvocationType: '<optional>',
          },
          StopAction: {
            TopicArn: '<optional>',
          },
          AddHeaderAction: '<optional>',
          SNSAction: {
            Encoding: '<optional>',
          },
        },
      ],
      ScanEnabled: '<optional>',
    },
    After: '<optional>',
  },
  'SES:createReceiptRuleSet': {
    RuleSetName: 'required',
  },
  'SES:createTemplate': {
    Template: {
      SubjectPart: '<optional>',
      TextPart: '<optional>',
      HtmlPart: '<optional>',
    },
  },
  'SES:deleteConfigurationSet': {
    ConfigurationSetName: 'required',
  },
  'SES:deleteConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestinationName: 'required',
  },
  'SES:deleteConfigurationSetTrackingOptions': {
    ConfigurationSetName: 'required',
  },
  'SES:deleteCustomVerificationEmailTemplate': {
    TemplateName: 'required',
  },
  'SES:deleteIdentity': {
    Identity: 'required',
  },
  'SES:deleteIdentityPolicy': {
    Identity: 'required',
    PolicyName: 'required',
  },
  'SES:deleteReceiptFilter': {
    FilterName: '<optional>',
  },
  'SES:deleteReceiptRule': {
    RuleSetName: 'required',
    RuleName: 'required',
  },
  'SES:deleteReceiptRuleSet': {
    RuleSetName: 'required',
  },
  'SES:deleteTemplate': {
    TemplateName: 'required',
  },
  'SES:deleteVerifiedEmailAddress': {
    EmailAddress: 'required',
  },
  'SES:describeActiveReceiptRuleSet': {},
  'SES:describeConfigurationSet': {
    ConfigurationSetName: 'required',
    ConfigurationSetAttributeNames: ['<optional1>', '<optional2>'],
  },
  'SES:describeReceiptRule': {
    RuleSetName: 'required',
    RuleName: 'required',
  },
  'SES:describeReceiptRuleSet': {
    RuleSetName: 'required',
  },
  'SES:getAccountSendingEnabled': {},
  'SES:getCustomVerificationEmailTemplate': {
    TemplateName: 'required',
  },
  'SES:getIdentityDkimAttributes': {
    Identities: ['required1', 'required2'],
  },
  'SES:getIdentityMailFromDomainAttributes': {
    Identities: ['required1', 'required2'],
  },
  'SES:getIdentityNotificationAttributes': {
    Identities: ['required1', 'required2'],
  },
  'SES:getIdentityPolicies': {
    Identity: 'required',
    PolicyNames: ['required1', 'required2'],
  },
  'SES:getIdentityVerificationAttributes': {
    Identities: ['required1', 'required2'],
  },
  'SES:getSendQuota': {},
  'SES:getSendStatistics': {},
  'SES:getTemplate': {
    TemplateName: 'required',
  },
  'SES:listConfigurationSets': {},
  'SES:listCustomVerificationEmailTemplates': {},
  'SES:listIdentities': {
    IdentityType: '<optional>',
  },
  'SES:listIdentityPolicies': {
    Identity: 'required',
  },
  'SES:listReceiptFilters': {},
  'SES:listReceiptRuleSets': {},
  'SES:listTemplates': {},
  'SES:listVerifiedEmailAddresses': {},
  'SES:putConfigurationSetDeliveryOptions': {
    ConfigurationSetName: 'required',
    DeliveryOptions: [
      {
        TlsPolicy: '<optional>',
      },
    ],
  },
  'SES:putIdentityPolicy': {
    Identity: 'required',
    PolicyName: 'required',
    Policy: 'required',
  },
  'SES:reorderReceiptRuleSet': {
    RuleSetName: 'required',
    RuleNames: ['required1', 'required2'],
  },
  'SES:sendBounce': {
    OriginalMessageId: 'required',
    BounceSender: 'required',
    BouncedRecipientInfoList: {
      RecipientArn: '<optional>',
      BounceType: '<optional>',
      RecipientDsnFields: [
        {
          FinalRecipient: '<optional>',
          RemoteMta: '<optional>',
          DiagnosticCode: '<optional>',
          LastAttemptDate: '<optional>',
          ExtensionFields: ['<optional1>', '<optional2>'],
        },
      ],
    },
    MessageDsn: {
      ArrivalDate: '<optional>',
      ExtensionFields: ['<optional1>', '<optional2>'],
    },
    Explanation: '<optional>',
    BounceSenderArn: '<optional>',
  },
  'SES:sendBulkTemplatedEmail': {
    Source: 'required',
    Template: 'required',
    Destinations: [
      {
        ReplacementTags: ['<optional1>', '<optional2>'],
        ReplacementTemplateData: ['<optional1>', '<optional2>'],
        Destination: {
          ToAddresses: ['<optional1>', '<optional2>'],
          CcAddresses: ['<optional1>', '<optional2>'],
          BccAddresses: ['<optional1>', '<optional2>'],
        },
      },
    ],
    DefaultTags: ['<optional1>', '<optional2>'],
    SourceArn: '<optional>',
    ReplyToAddresses: ['<optional1>', '<optional2>'],
    ReturnPath: '<optional>',
    ReturnPathArn: '<optional>',
    ConfigurationSetName: '<optional>',
    TemplateArn: '<optional>',
    DefaultTemplateData: ['<optional1>', '<optional2>'],
  },
  'SES:sendCustomVerificationEmail': {
    EmailAddress: 'required',
    TemplateName: 'required',
    ConfigurationSetName: '<optional>',
  },
  'SES:sendEmail': {
    Source: 'required',
    Destination: {
      ToAddresses: ['<optional1>', '<optional2>'],
      CcAddresses: ['<optional1>', '<optional2>'],
      BccAddresses: ['<optional1>', '<optional2>'],
    },
    Message: {
      Subject: {
        Charset: '<optional>',
      },
      Body: {
        Text: {
          Charset: '<optional>',
        },
        Html: {
          Charset: '<optional>',
        },
      },
    },
    Tags: ['<optional1>', '<optional2>'],
    ReplyToAddresses: ['<optional1>', '<optional2>'],
    ReturnPath: '<optional>',
    SourceArn: '<optional>',
    ReturnPathArn: '<optional>',
    ConfigurationSetName: '<optional>',
  },
  'SES:sendRawEmail': {
    RawMessage: {
      Data: ['required1', 'required2'],
    },
    Tags: ['<optional1>', '<optional2>'],
    Source: '<optional>',
    Destinations: ['<optional1>', '<optional2>'],
    FromArn: '<optional>',
    SourceArn: '<optional>',
    ReturnPathArn: '<optional>',
    ConfigurationSetName: '<optional>',
  },
  'SES:sendTemplatedEmail': {
    Source: 'required',
    Destination: {
      ToAddresses: ['<optional1>', '<optional2>'],
      CcAddresses: ['<optional1>', '<optional2>'],
      BccAddresses: ['<optional1>', '<optional2>'],
    },
    Template: 'required',
    TemplateData: ['required1', 'required2'],
    Tags: ['<optional1>', '<optional2>'],
    ReplyToAddresses: ['<optional1>', '<optional2>'],
    ReturnPath: '<optional>',
    SourceArn: '<optional>',
    ReturnPathArn: '<optional>',
    ConfigurationSetName: '<optional>',
    TemplateArn: '<optional>',
  },
  'SES:setActiveReceiptRuleSet': {
    RuleSetName: '<optional>',
  },
  'SES:setIdentityDkimEnabled': {
    Identity: 'required',
    DkimEnabled: 'required',
  },
  'SES:setIdentityFeedbackForwardingEnabled': {
    Identity: 'required',
    ForwardingEnabled: 'required',
  },
  'SES:setIdentityHeadersInNotificationsEnabled': {
    Identity: 'required',
    NotificationType: 'required',
    Enabled: 'required',
  },
  'SES:setIdentityMailFromDomain': {
    Identity: 'required',
    MailFromDomain: '<optional>',
    BehaviorOnMXFailure: '<optional>',
  },
  'SES:setIdentityNotificationTopic': {
    Identity: 'required',
    NotificationType: 'required',
    SnsTopic: '<optional>',
  },
  'SES:setReceiptRulePosition': {
    RuleSetName: 'required',
    RuleName: 'required',
    After: '<optional>',
  },
  'SES:testRenderTemplate': {
    TemplateName: 'required',
    TemplateData: ['required1', 'required2'],
  },
  'SES:updateAccountSendingEnabled': {
    Enabled: '<optional>',
  },
  'SES:updateConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestination: {
      Enabled: '<optional>',
      KinesisFirehoseDestination: '<optional>',
      CloudWatchDestination: '<optional>',
      SNSDestination: '<optional>',
    },
  },
  'SES:updateConfigurationSetReputationMetricsEnabled': {
    ConfigurationSetName: 'required',
    Enabled: 'required',
  },
  'SES:updateConfigurationSetSendingEnabled': {
    ConfigurationSetName: 'required',
    Enabled: 'required',
  },
  'SES:updateConfigurationSetTrackingOptions': {
    ConfigurationSetName: 'required',
    TrackingOptions: [
      {
        CustomRedirectDomain: '<optional>',
      },
    ],
  },
  'SES:updateCustomVerificationEmailTemplate': {
    TemplateName: 'required',
    FromEmailAddress: '<optional>',
    TemplateSubject: '<optional>',
    TemplateContent: '<optional>',
    SuccessRedirectionURL: '<optional>',
    FailureRedirectionURL: '<optional>',
  },
  'SES:updateReceiptRule': {
    RuleSetName: 'required',
    Rule: {
      Enabled: '<optional>',
      TlsPolicy: '<optional>',
      Recipients: ['<optional1>', '<optional2>'],
      Actions: [
        {
          S3Action: {
            TopicArn: '<optional>',
            ObjectKeyPrefix: '<optional>',
            KmsKeyArn: '<optional>',
          },
          BounceAction: {
            TopicArn: '<optional>',
            StatusCode: '<optional>',
          },
          WorkmailAction: {
            TopicArn: '<optional>',
          },
          LambdaAction: {
            TopicArn: '<optional>',
            InvocationType: '<optional>',
          },
          StopAction: {
            TopicArn: '<optional>',
          },
          AddHeaderAction: '<optional>',
          SNSAction: {
            Encoding: '<optional>',
          },
        },
      ],
      ScanEnabled: '<optional>',
    },
  },
  'SES:updateTemplate': {
    Template: {
      SubjectPart: '<optional>',
      TextPart: '<optional>',
      HtmlPart: '<optional>',
    },
  },
  'SES:verifyDomainDkim': {
    Domain: 'required',
  },
  'SES:verifyDomainIdentity': {
    Domain: 'required',
  },
  'SES:verifyEmailAddress': {
    EmailAddress: 'required',
  },
  'SES:verifyEmailIdentity': {
    EmailAddress: 'required',
  },
  'SESV2:createConfigurationSet': {
    ConfigurationSetName: 'required',
    TrackingOptions: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    DeliveryOptions: [
      {
        TlsPolicy: '<optional>',
        SendingPoolName: '<optional>',
      },
    ],
    ReputationOptions: [
      {
        ReputationMetricsEnabled: '<optional>',
        LastFreshStart: '<optional>',
      },
    ],
    SendingOptions: [
      {
        SendingEnabled: '<optional>',
      },
    ],
    SuppressionOptions: [
      {
        SuppressedReasons: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SESV2:createConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestinationName: 'required',
    EventDestination: {
      Enabled: '<optional>',
      MatchingEventTypes: ['<optional1>', '<optional2>'],
      KinesisFirehoseDestination: '<optional>',
      CloudWatchDestination: '<optional>',
      SnsDestination: '<optional>',
      PinpointDestination: {
        ApplicationArn: '<optional>',
      },
    },
  },
  'SESV2:createDedicatedIpPool': {
    PoolName: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'SESV2:createDeliverabilityTestReport': {
    FromEmailAddress: 'required',
    Content: {
      Simple: {
        Subject: {
          Charset: '<optional>',
        },
        Body: {
          Text: {
            Charset: '<optional>',
          },
          Html: {
            Charset: '<optional>',
          },
        },
      },
      Raw: '<optional>',
      Template: {
        TemplateArn: '<optional>',
        TemplateData: ['<optional1>', '<optional2>'],
      },
    },
    Tags: ['<optional1>', '<optional2>'],
    ReportName: '<optional>',
  },
  'SESV2:createEmailIdentity': {
    EmailIdentity: 'required',
    Tags: ['<optional1>', '<optional2>'],
    DkimSigningAttributes: ['<optional1>', '<optional2>'],
  },
  'SESV2:deleteConfigurationSet': {
    ConfigurationSetName: 'required',
  },
  'SESV2:deleteConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestinationName: 'required',
  },
  'SESV2:deleteDedicatedIpPool': {
    PoolName: 'required',
  },
  'SESV2:deleteEmailIdentity': {
    EmailIdentity: 'required',
  },
  'SESV2:deleteSuppressedDestination': {
    EmailAddress: 'required',
  },
  'SESV2:getAccount': {},
  'SESV2:getBlacklistReports': {
    BlacklistItemNames: ['required1', 'required2'],
  },
  'SESV2:getConfigurationSet': {
    ConfigurationSetName: 'required',
  },
  'SESV2:getConfigurationSetEventDestinations': {
    ConfigurationSetName: 'required',
  },
  'SESV2:getDedicatedIp': {
    Ip: 'required',
  },
  'SESV2:getDedicatedIps': {
    PoolName: '<optional>',
    PageSize: '<optional>',
  },
  'SESV2:getDeliverabilityDashboardOptions': {},
  'SESV2:getDeliverabilityTestReport': {
    ReportId: 'required',
  },
  'SESV2:getDomainDeliverabilityCampaign': {
    CampaignId: 'required',
  },
  'SESV2:getDomainStatisticsReport': {
    Domain: 'required',
    StartDate: 'required',
    EndDate: 'required',
  },
  'SESV2:getEmailIdentity': {
    EmailIdentity: 'required',
  },
  'SESV2:getSuppressedDestination': {
    EmailAddress: 'required',
  },
  'SESV2:listConfigurationSets': {
    PageSize: '<optional>',
  },
  'SESV2:listDedicatedIpPools': {
    PageSize: '<optional>',
  },
  'SESV2:listDeliverabilityTestReports': {
    PageSize: '<optional>',
  },
  'SESV2:listDomainDeliverabilityCampaigns': {
    StartDate: 'required',
    EndDate: 'required',
    SubscribedDomain: 'required',
    PageSize: '<optional>',
  },
  'SESV2:listEmailIdentities': {
    PageSize: '<optional>',
  },
  'SESV2:listSuppressedDestinations': {
    Reasons: ['<optional1>', '<optional2>'],
    StartDate: '<optional>',
    EndDate: '<optional>',
    PageSize: '<optional>',
  },
  'SESV2:listTagsForResource': {
    ResourceArn: 'required',
  },
  'SESV2:putAccountDedicatedIpWarmupAttributes': {
    AutoWarmupEnabled: '<optional>',
  },
  'SESV2:putAccountSendingAttributes': {
    SendingEnabled: '<optional>',
  },
  'SESV2:putAccountSuppressionAttributes': {
    SuppressedReasons: ['<optional1>', '<optional2>'],
  },
  'SESV2:putConfigurationSetDeliveryOptions': {
    ConfigurationSetName: 'required',
    TlsPolicy: '<optional>',
    SendingPoolName: '<optional>',
  },
  'SESV2:putConfigurationSetReputationOptions': {
    ConfigurationSetName: 'required',
    ReputationMetricsEnabled: '<optional>',
  },
  'SESV2:putConfigurationSetSendingOptions': {
    ConfigurationSetName: 'required',
    SendingEnabled: '<optional>',
  },
  'SESV2:putConfigurationSetSuppressionOptions': {
    ConfigurationSetName: 'required',
    SuppressedReasons: ['<optional1>', '<optional2>'],
  },
  'SESV2:putConfigurationSetTrackingOptions': {
    ConfigurationSetName: 'required',
    CustomRedirectDomain: '<optional>',
  },
  'SESV2:putDedicatedIpInPool': {
    Ip: 'required',
    DestinationPoolName: 'required',
  },
  'SESV2:putDedicatedIpWarmupAttributes': {
    Ip: 'required',
    WarmupPercentage: 'required',
  },
  'SESV2:putDeliverabilityDashboardOption': {
    DashboardEnabled: 'required',
    SubscribedDomains: [
      {
        Domain: '<optional>',
        SubscriptionStartDate: '<optional>',
        InboxPlacementTrackingOption: {
          Global: '<optional>',
          TrackedIsps: ['<optional1>', '<optional2>'],
        },
      },
    ],
  },
  'SESV2:putEmailIdentityDkimAttributes': {
    EmailIdentity: 'required',
    SigningEnabled: '<optional>',
  },
  'SESV2:putEmailIdentityDkimSigningAttributes': {
    EmailIdentity: 'required',
    SigningAttributesOrigin: 'required',
    SigningAttributes: ['<optional1>', '<optional2>'],
  },
  'SESV2:putEmailIdentityFeedbackAttributes': {
    EmailIdentity: 'required',
    EmailForwardingEnabled: '<optional>',
  },
  'SESV2:putEmailIdentityMailFromAttributes': {
    EmailIdentity: 'required',
    MailFromDomain: '<optional>',
    BehaviorOnMxFailure: '<optional>',
  },
  'SESV2:putSuppressedDestination': {
    EmailAddress: 'required',
    Reason: 'required',
  },
  'SESV2:sendEmail': {
    Destination: {
      ToAddresses: ['<optional1>', '<optional2>'],
      CcAddresses: ['<optional1>', '<optional2>'],
      BccAddresses: ['<optional1>', '<optional2>'],
    },
    Content: {
      Simple: {
        Subject: {
          Charset: '<optional>',
        },
        Body: {
          Text: {
            Charset: '<optional>',
          },
          Html: {
            Charset: '<optional>',
          },
        },
      },
      Raw: '<optional>',
      Template: {
        TemplateArn: '<optional>',
        TemplateData: ['<optional1>', '<optional2>'],
      },
    },
    EmailTags: ['<optional1>', '<optional2>'],
    FromEmailAddress: '<optional>',
    ReplyToAddresses: ['<optional1>', '<optional2>'],
    FeedbackForwardingEmailAddress: '<optional>',
    ConfigurationSetName: '<optional>',
  },
  'SESV2:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'SESV2:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'SESV2:updateConfigurationSetEventDestination': {
    ConfigurationSetName: 'required',
    EventDestinationName: 'required',
    EventDestination: {
      Enabled: '<optional>',
      MatchingEventTypes: ['<optional1>', '<optional2>'],
      KinesisFirehoseDestination: '<optional>',
      CloudWatchDestination: '<optional>',
      SnsDestination: '<optional>',
      PinpointDestination: {
        ApplicationArn: '<optional>',
      },
    },
  },
  'SMS:createApp': {
    name: '<optional>',
    description: '<optional>',
    roleName: '<optional>',
    clientToken: '<optional>',
    serverGroups: [
      {
        serverGroupId: '<optional>',
        name: '<optional>',
        serverList: {
          serverId: '<optional>',
          serverType: '<optional>',
          vmServer: {
            vmServerAddress: {
              vmManagerId: '<optional>',
              vmId: '<optional>',
            },
            vmName: '<optional>',
            vmManagerName: '<optional>',
            vmManagerType: '<optional>',
            vmPath: '<optional>',
          },
          replicationJobId: '<optional>',
          replicationJobTerminated: '<optional>',
        },
      },
    ],
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'SMS:createReplicationJob': {
    serverId: 'required',
    seedReplicationTime: 'required',
    frequency: '<optional>',
    runOnce: '<optional>',
    licenseType: '<optional>',
    roleName: '<optional>',
    description: '<optional>',
    numberOfRecentAmisToKeep: '<optional>',
    encrypted: '<optional>',
    kmsKeyId: '<optional>',
  },
  'SMS:deleteApp': {
    appId: '<optional>',
    forceStopAppReplication: '<optional>',
    forceTerminateApp: '<optional>',
  },
  'SMS:deleteAppLaunchConfiguration': {
    appId: '<optional>',
  },
  'SMS:deleteAppReplicationConfiguration': {
    appId: '<optional>',
  },
  'SMS:deleteReplicationJob': {
    replicationJobId: 'required',
  },
  'SMS:deleteServerCatalog': {},
  'SMS:disassociateConnector': {
    connectorId: 'required',
  },
  'SMS:generateChangeSet': {
    appId: '<optional>',
    changesetFormat: '<optional>',
  },
  'SMS:generateTemplate': {
    appId: '<optional>',
    templateFormat: '<optional>',
  },
  'SMS:getApp': {
    appId: '<optional>',
  },
  'SMS:getAppLaunchConfiguration': {
    appId: '<optional>',
  },
  'SMS:getAppReplicationConfiguration': {
    appId: '<optional>',
  },
  'SMS:getConnectors': {},
  'SMS:getReplicationJobs': {
    replicationJobId: '<optional>',
  },
  'SMS:getReplicationRuns': {
    replicationJobId: 'required',
  },
  'SMS:getServers': {
    vmServerAddressList: {
      vmManagerId: '<optional>',
      vmId: '<optional>',
    },
  },
  'SMS:importServerCatalog': {},
  'SMS:launchApp': {
    appId: '<optional>',
  },
  'SMS:listApps': {
    appIds: ['<optional1>', '<optional2>'],
  },
  'SMS:putAppLaunchConfiguration': {
    appId: '<optional>',
    roleName: '<optional>',
    serverGroupLaunchConfigurations: [
      {
        serverGroupId: '<optional>',
        launchOrder: '<optional>',
        serverLaunchConfigurations: [
          {
            server: {
              serverId: '<optional>',
              serverType: '<optional>',
              vmServer: {
                vmServerAddress: {
                  vmManagerId: '<optional>',
                  vmId: '<optional>',
                },
                vmName: '<optional>',
                vmManagerName: '<optional>',
                vmManagerType: '<optional>',
                vmPath: '<optional>',
              },
              replicationJobId: '<optional>',
              replicationJobTerminated: '<optional>',
            },
            logicalId: '<optional>',
            vpc: '<optional>',
            subnet: '<optional>',
            securityGroup: '<optional>',
            ec2KeyName: '<optional>',
            userData: [
              {
                s3Location: {
                  bucket: '<optional>',
                  key: '<optional>',
                },
              },
            ],
            instanceType: '<optional>',
            associatePublicIpAddress: '<optional>',
          },
        ],
      },
    ],
  },
  'SMS:putAppReplicationConfiguration': {
    appId: '<optional>',
    serverGroupReplicationConfigurations: [
      {
        serverGroupId: '<optional>',
        serverReplicationConfigurations: [
          {
            server: {
              serverId: '<optional>',
              serverType: '<optional>',
              vmServer: {
                vmServerAddress: {
                  vmManagerId: '<optional>',
                  vmId: '<optional>',
                },
                vmName: '<optional>',
                vmManagerName: '<optional>',
                vmManagerType: '<optional>',
                vmPath: '<optional>',
              },
              replicationJobId: '<optional>',
              replicationJobTerminated: '<optional>',
            },
            serverReplicationParameters: [
              {
                seedTime: '<optional>',
                frequency: '<optional>',
                runOnce: '<optional>',
                licenseType: '<optional>',
                numberOfRecentAmisToKeep: '<optional>',
                encrypted: '<optional>',
                kmsKeyId: '<optional>',
              },
            ],
          },
        ],
      },
    ],
  },
  'SMS:startAppReplication': {
    appId: '<optional>',
  },
  'SMS:startOnDemandReplicationRun': {
    replicationJobId: 'required',
    description: '<optional>',
  },
  'SMS:stopAppReplication': {
    appId: '<optional>',
  },
  'SMS:terminateApp': {
    appId: '<optional>',
  },
  'SMS:updateApp': {
    appId: '<optional>',
    name: '<optional>',
    description: '<optional>',
    roleName: '<optional>',
    serverGroups: [
      {
        serverGroupId: '<optional>',
        name: '<optional>',
        serverList: {
          serverId: '<optional>',
          serverType: '<optional>',
          vmServer: {
            vmServerAddress: {
              vmManagerId: '<optional>',
              vmId: '<optional>',
            },
            vmName: '<optional>',
            vmManagerName: '<optional>',
            vmManagerType: '<optional>',
            vmPath: '<optional>',
          },
          replicationJobId: '<optional>',
          replicationJobTerminated: '<optional>',
        },
      },
    ],
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'SMS:updateReplicationJob': {
    replicationJobId: 'required',
    frequency: '<optional>',
    nextReplicationRunStartTime: '<optional>',
    licenseType: '<optional>',
    roleName: '<optional>',
    description: '<optional>',
    numberOfRecentAmisToKeep: '<optional>',
    encrypted: '<optional>',
    kmsKeyId: '<optional>',
  },
  'SNS:addPermission': {
    TopicArn: 'required',
    Label: 'required',
    AWSAccountId: 'required',
    ActionName: 'required',
  },
  'SNS:checkIfPhoneNumberIsOptedOut': {
    phoneNumber: 'required',
  },
  'SNS:confirmSubscription': {
    TopicArn: 'required',
    Token: 'required',
    AuthenticateOnUnsubscribe: '<optional>',
  },
  'SNS:createPlatformApplication': {
    Name: 'required',
    Platform: 'required',
    Attributes: ['required1', 'required2'],
  },
  'SNS:createPlatformEndpoint': {
    PlatformApplicationArn: 'required',
    Token: 'required',
    CustomUserData: ['<optional1>', '<optional2>'],
    Attributes: ['<optional1>', '<optional2>'],
  },
  'SNS:createTopic': {
    Name: 'required',
    Tags: ['<optional1>', '<optional2>'],
    Attributes: ['<optional1>', '<optional2>'],
  },
  'SNS:deleteEndpoint': {
    EndpointArn: 'required',
  },
  'SNS:deletePlatformApplication': {
    PlatformApplicationArn: 'required',
  },
  'SNS:deleteTopic': {
    TopicArn: 'required',
  },
  'SNS:getEndpointAttributes': {
    EndpointArn: 'required',
  },
  'SNS:getPlatformApplicationAttributes': {
    PlatformApplicationArn: 'required',
  },
  'SNS:getSMSAttributes': {
    attributes: ['<optional1>', '<optional2>'],
  },
  'SNS:getSubscriptionAttributes': {
    SubscriptionArn: 'required',
  },
  'SNS:getTopicAttributes': {
    TopicArn: 'required',
  },
  'SNS:listEndpointsByPlatformApplication': {
    PlatformApplicationArn: 'required',
  },
  'SNS:listPhoneNumbersOptedOut': {},
  'SNS:listPlatformApplications': {},
  'SNS:listSubscriptions': {},
  'SNS:listSubscriptionsByTopic': {
    TopicArn: 'required',
  },
  'SNS:listTagsForResource': {
    ResourceArn: 'required',
  },
  'SNS:listTopics': {},
  'SNS:optInPhoneNumber': {
    phoneNumber: 'required',
  },
  'SNS:publish': {
    Message: 'required',
    TopicArn: '<optional>',
    TargetArn: '<optional>',
    PhoneNumber: '<optional>',
    Subject: '<optional>',
    MessageStructure: '<optional>',
    MessageAttributes: ['<optional1>', '<optional2>'],
  },
  'SNS:removePermission': {
    TopicArn: 'required',
    Label: 'required',
  },
  'SNS:setEndpointAttributes': {
    EndpointArn: 'required',
    Attributes: ['required1', 'required2'],
  },
  'SNS:setPlatformApplicationAttributes': {
    PlatformApplicationArn: 'required',
    Attributes: ['required1', 'required2'],
  },
  'SNS:setSMSAttributes': {
    attributes: ['required1', 'required2'],
  },
  'SNS:setSubscriptionAttributes': {
    SubscriptionArn: 'required',
    AttributeName: 'required',
    AttributeValue: '<optional>',
  },
  'SNS:setTopicAttributes': {
    TopicArn: 'required',
    AttributeName: 'required',
    AttributeValue: '<optional>',
  },
  'SNS:subscribe': {
    TopicArn: 'required',
    Protocol: 'required',
    Endpoint: '<optional>',
    Attributes: ['<optional1>', '<optional2>'],
    ReturnSubscriptionArn: '<optional>',
  },
  'SNS:tagResource': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'SNS:unsubscribe': {
    SubscriptionArn: 'required',
  },
  'SNS:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'SQS:addPermission': {
    QueueUrl: 'required',
    Label: 'required',
    AWSAccountIds: ['required1', 'required2'],
    Actions: ['required1', 'required2'],
  },
  'SQS:changeMessageVisibility': {
    QueueUrl: 'required',
    ReceiptHandle: 'required',
    VisibilityTimeout: 'required',
  },
  'SQS:changeMessageVisibilityBatch': {
    QueueUrl: 'required',
    Entries: [
      {
        VisibilityTimeout: '<optional>',
      },
    ],
  },
  'SQS:createQueue': {
    QueueName: 'required',
    Attributes: ['<optional1>', '<optional2>'],
  },
  'SQS:deleteMessage': {
    QueueUrl: 'required',
    ReceiptHandle: 'required',
  },
  'SQS:deleteMessageBatch': {
    QueueUrl: 'required',
    Entries: [
      {
        Id: 'required',
        ReceiptHandle: 'required',
      },
    ],
  },
  'SQS:deleteQueue': {
    QueueUrl: 'required',
  },
  'SQS:getQueueAttributes': {
    QueueUrl: 'required',
    AttributeNames: ['<optional1>', '<optional2>'],
  },
  'SQS:getQueueUrl': {
    QueueName: 'required',
    QueueOwnerAWSAccountId: '<optional>',
  },
  'SQS:listDeadLetterSourceQueues': {
    QueueUrl: 'required',
  },
  'SQS:listQueueTags': {
    QueueUrl: 'required',
  },
  'SQS:listQueues': {
    QueueNamePrefix: '<optional>',
  },
  'SQS:purgeQueue': {
    QueueUrl: 'required',
  },
  'SQS:receiveMessage': {
    QueueUrl: 'required',
    AttributeNames: ['<optional1>', '<optional2>'],
    MessageAttributeNames: ['<optional1>', '<optional2>'],
    MaxNumberOfMessages: ['<optional1>', '<optional2>'],
    VisibilityTimeout: '<optional>',
    WaitTimeSeconds: ['<optional1>', '<optional2>'],
    ReceiveRequestAttemptId: '<optional>',
  },
  'SQS:removePermission': {
    QueueUrl: 'required',
    Label: 'required',
  },
  'SQS:sendMessage': {
    QueueUrl: 'required',
    MessageBody: 'required',
    DelaySeconds: ['<optional1>', '<optional2>'],
    MessageAttributes: ['<optional1>', '<optional2>'],
    MessageDeduplicationId: '<optional>',
    MessageGroupId: '<optional>',
  },
  'SQS:sendMessageBatch': {
    QueueUrl: 'required',
    Entries: [
      {
        DelaySeconds: ['<optional1>', '<optional2>'],
        MessageAttributes: ['<optional1>', '<optional2>'],
        MessageDeduplicationId: '<optional>',
        MessageGroupId: '<optional>',
      },
    ],
  },
  'SQS:setQueueAttributes': {
    QueueUrl: 'required',
    Attributes: ['required1', 'required2'],
  },
  'SQS:tagQueue': {
    QueueUrl: 'required',
    Tags: ['required1', 'required2'],
  },
  'SQS:untagQueue': {
    QueueUrl: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'SSM:addTagsToResource': {
    ResourceType: 'required',
    ResourceId: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'SSM:cancelCommand': {
    CommandId: 'required',
    InstanceIds: ['<optional1>', '<optional2>'],
  },
  'SSM:cancelMaintenanceWindowExecution': {
    WindowExecutionId: 'required',
  },
  'SSM:createActivation': {
    IamRole: 'required',
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    DefaultInstanceName: '<optional>',
    RegistrationLimit: '<optional>',
    ExpirationDate: '<optional>',
  },
  'SSM:createAssociation': {
    Name: 'required',
    DocumentVersion: '<optional>',
    InstanceId: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ScheduleExpression: '<optional>',
    OutputLocation: {
      S3Location: {
        OutputS3Region: '<optional>',
        OutputS3BucketName: '<optional>',
        OutputS3KeyPrefix: '<optional>',
      },
    },
    AssociationName: '<optional>',
    AutomationTargetParameterName: '<optional>',
    MaxErrors: ['<optional1>', '<optional2>'],
    MaxConcurrency: '<optional>',
    ComplianceSeverity: '<optional>',
  },
  'SSM:createAssociationBatch': {
    Entries: [
      {
        InstanceId: '<optional>',
        Parameters: ['<optional1>', '<optional2>'],
        AutomationTargetParameterName: '<optional>',
        DocumentVersion: '<optional>',
        Targets: [
          {
            Key: '<optional>',
            Values: ['<optional1>', '<optional2>'],
          },
        ],
        ScheduleExpression: '<optional>',
        OutputLocation: {
          S3Location: {
            OutputS3Region: '<optional>',
            OutputS3BucketName: '<optional>',
            OutputS3KeyPrefix: '<optional>',
          },
        },
        AssociationName: '<optional>',
        MaxErrors: ['<optional1>', '<optional2>'],
        MaxConcurrency: '<optional>',
        ComplianceSeverity: '<optional>',
      },
    ],
  },
  'SSM:createDocument': {
    Content: 'required',
    Name: 'required',
    Tags: ['<optional1>', '<optional2>'],
    Attachments: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VersionName: '<optional>',
    DocumentType: '<optional>',
    DocumentFormat: '<optional>',
    TargetType: '<optional>',
  },
  'SSM:createMaintenanceWindow': {
    Name: 'required',
    Schedule: 'required',
    Duration: 'required',
    Cutoff: 'required',
    AllowUnassociatedTargets: ['required1', 'required2'],
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    StartDate: '<optional>',
    EndDate: '<optional>',
    ScheduleTimezone: '<optional>',
    ClientToken: '<optional>',
  },
  'SSM:createOpsItem': {
    Description: 'required',
    Source: 'required',
    Title: 'required',
    RelatedOpsItems: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    OperationalData: ['<optional1>', '<optional2>'],
    Notifications: [
      {
        Arn: '<optional>',
      },
    ],
    Priority: '<optional>',
  },
  'SSM:createPatchBaseline': {
    Name: 'required',
    GlobalFilters: ['<optional1>', '<optional2>'],
    ApprovalRules: [
      {
        PatchRules: [
          {
            ComplianceLevel: '<optional>',
            EnableNonSecurity: '<optional>',
          },
        ],
      },
    ],
    Sources: ['<optional1>', '<optional2>'],
    Tags: ['<optional1>', '<optional2>'],
    OperatingSystem: '<optional>',
    ApprovedPatches: ['<optional1>', '<optional2>'],
    ApprovedPatchesComplianceLevel: '<optional>',
    ApprovedPatchesEnableNonSecurity: '<optional>',
    RejectedPatches: ['<optional1>', '<optional2>'],
    RejectedPatchesAction: '<optional>',
    Description: '<optional>',
    ClientToken: '<optional>',
  },
  'SSM:createResourceDataSync': {
    SyncName: 'required',
    S3Destination: {
      Prefix: '<optional>',
      AWSKMSKeyARN: '<optional>',
    },
  },
  'SSM:deleteActivation': {
    ActivationId: 'required',
  },
  'SSM:deleteAssociation': {
    Name: '<optional>',
    InstanceId: '<optional>',
    AssociationId: '<optional>',
  },
  'SSM:deleteDocument': {
    Name: 'required',
    DocumentVersion: '<optional>',
    VersionName: '<optional>',
  },
  'SSM:deleteInventory': {
    TypeName: 'required',
    SchemaDeleteOption: '<optional>',
    ClientToken: '<optional>',
  },
  'SSM:deleteMaintenanceWindow': {
    WindowId: 'required',
  },
  'SSM:deleteParameter': {
    Name: 'required',
  },
  'SSM:deleteParameters': {
    Names: ['required1', 'required2'],
  },
  'SSM:deletePatchBaseline': {
    BaselineId: 'required',
  },
  'SSM:deleteResourceDataSync': {
    SyncName: 'required',
  },
  'SSM:deregisterManagedInstance': {
    InstanceId: 'required',
  },
  'SSM:deregisterPatchBaselineForPatchGroup': {
    BaselineId: 'required',
    PatchGroup: 'required',
  },
  'SSM:deregisterTargetFromMaintenanceWindow': {
    WindowId: 'required',
    WindowTargetId: 'required',
    Safe: '<optional>',
  },
  'SSM:deregisterTaskFromMaintenanceWindow': {
    WindowId: 'required',
    WindowTaskId: 'required',
  },
  'SSM:describeActivations': {
    Filters: [
      {
        FilterKey: '<optional>',
        FilterValues: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeAssociation': {
    Name: '<optional>',
    InstanceId: '<optional>',
    AssociationId: '<optional>',
    AssociationVersion: '<optional>',
  },
  'SSM:describeAssociationExecutionTargets': {
    AssociationId: 'required',
    ExecutionId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'SSM:describeAssociationExecutions': {
    AssociationId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        Type: '<optional>',
      },
    ],
  },
  'SSM:describeAutomationExecutions': {
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeAutomationStepExecutions': {
    AutomationExecutionId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ReverseOrder: '<optional>',
  },
  'SSM:describeAvailablePatches': {
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeDocument': {
    Name: 'required',
    DocumentVersion: '<optional>',
    VersionName: '<optional>',
  },
  'SSM:describeDocumentPermission': {
    Name: 'required',
    PermissionType: 'required',
  },
  'SSM:describeEffectiveInstanceAssociations': {
    InstanceId: 'required',
  },
  'SSM:describeEffectivePatchesForPatchBaseline': {
    BaselineId: 'required',
  },
  'SSM:describeInstanceAssociationsStatus': {
    InstanceId: 'required',
  },
  'SSM:describeInstanceInformation': {
    InstanceInformationFilterList: '<optional>',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeInstancePatchStates': {
    InstanceIds: ['required1', 'required2'],
  },
  'SSM:describeInstancePatchStatesForPatchGroup': {
    PatchGroup: 'required',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
        Type: '<optional>',
      },
    ],
  },
  'SSM:describeInstancePatches': {
    InstanceId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeInventoryDeletions': {
    DeletionId: '<optional>',
  },
  'SSM:describeMaintenanceWindowExecutionTaskInvocations': {
    WindowExecutionId: 'required',
    TaskId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeMaintenanceWindowExecutionTasks': {
    WindowExecutionId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeMaintenanceWindowExecutions': {
    WindowId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeMaintenanceWindowSchedule': {
    WindowId: '<optional>',
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ResourceType: '<optional>',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeMaintenanceWindowTargets': {
    WindowId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeMaintenanceWindowTasks': {
    WindowId: 'required',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeMaintenanceWindows': {
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describeMaintenanceWindowsForTarget': {
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    ResourceType: 'required',
  },
  'SSM:describeOpsItems': {
    OpsItemFilters: ['<optional1>', '<optional2>'],
  },
  'SSM:describeParameters': {
    ParameterFilters: [
      {
        Key: '<optional>',
        Option: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describePatchBaselines': {
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describePatchGroupState': {
    PatchGroup: 'required',
  },
  'SSM:describePatchGroups': {
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:describePatchProperties': {
    OperatingSystem: 'required',
    Property: 'required',
    PatchSet: '<optional>',
  },
  'SSM:describeSessions': {
    State: 'required',
    Filters: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'SSM:getAutomationExecution': {
    AutomationExecutionId: 'required',
  },
  'SSM:getCalendarState': {
    CalendarNames: ['required1', 'required2'],
    AtTime: '<optional>',
  },
  'SSM:getCommandInvocation': {
    CommandId: 'required',
    InstanceId: 'required',
    PluginName: '<optional>',
  },
  'SSM:getConnectionStatus': {
    Target: 'required',
  },
  'SSM:getDefaultPatchBaseline': {
    OperatingSystem: '<optional>',
  },
  'SSM:getDeployablePatchSnapshotForInstance': {
    InstanceId: 'required',
    SnapshotId: 'required',
  },
  'SSM:getDocument': {
    Name: 'required',
    VersionName: '<optional>',
    DocumentVersion: '<optional>',
    DocumentFormat: '<optional>',
  },
  'SSM:getInventory': {
    Aggregators: [
      {
        Expression: '<optional>',
        Aggregators: ['<optional1>', '<optional2>'],
        Groups: [
          {
            Filters: [
              {
                Type: '<optional>',
              },
            ],
          },
        ],
      },
    ],
    ResultAttributes: ['<optional1>', '<optional2>'],
    Filters: [
      {
        Type: '<optional>',
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:getInventorySchema': {
    TypeName: '<optional>',
    Aggregator: '<optional>',
    SubType: '<optional>',
  },
  'SSM:getMaintenanceWindow': {
    WindowId: 'required',
  },
  'SSM:getMaintenanceWindowExecution': {
    WindowExecutionId: 'required',
  },
  'SSM:getMaintenanceWindowExecutionTask': {
    WindowExecutionId: 'required',
    TaskId: 'required',
  },
  'SSM:getMaintenanceWindowExecutionTaskInvocation': {
    WindowExecutionId: 'required',
    TaskId: 'required',
    InvocationId: 'required',
  },
  'SSM:getMaintenanceWindowTask': {
    WindowId: 'required',
    WindowTaskId: 'required',
  },
  'SSM:getOpsItem': {
    OpsItemId: 'required',
  },
  'SSM:getOpsSummary': {
    Aggregators: [
      {
        AggregatorType: '<optional>',
        TypeName: '<optional>',
        AttributeName: '<optional>',
        Values: ['<optional1>', '<optional2>'],
        Filters: [
          {
            Type: '<optional>',
          },
        ],
        Aggregators: ['<optional1>', '<optional2>'],
      },
    ],
    Filters: [
      {
        Type: '<optional>',
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:getParameter': {
    Name: 'required',
    WithDecryption: '<optional>',
  },
  'SSM:getParameterHistory': {
    Name: 'required',
    WithDecryption: '<optional>',
  },
  'SSM:getParameters': {
    Names: ['required1', 'required2'],
    WithDecryption: '<optional>',
  },
  'SSM:getParametersByPath': {
    Path: 'required',
    ParameterFilters: [
      {
        Option: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    Recursive: '<optional>',
    WithDecryption: '<optional>',
  },
  'SSM:getPatchBaseline': {
    BaselineId: 'required',
  },
  'SSM:getPatchBaselineForPatchGroup': {
    PatchGroup: 'required',
    OperatingSystem: '<optional>',
  },
  'SSM:getServiceSetting': {
    SettingId: 'required',
  },
  'SSM:labelParameterVersion': {
    Name: 'required',
    Labels: ['required1', 'required2'],
    ParameterVersion: '<optional>',
  },
  'SSM:listAssociationVersions': {
    AssociationId: 'required',
  },
  'SSM:listAssociations': {
    AssociationFilterList: '<optional>',
  },
  'SSM:listCommandInvocations': {
    CommandId: '<optional>',
    InstanceId: '<optional>',
    Filters: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
    Details: ['<optional1>', '<optional2>'],
  },
  'SSM:listCommands': {
    CommandId: '<optional>',
    InstanceId: '<optional>',
    Filters: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'SSM:listComplianceItems': {
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
        Type: '<optional>',
      },
    ],
    ResourceIds: ['<optional1>', '<optional2>'],
    ResourceTypes: ['<optional1>', '<optional2>'],
  },
  'SSM:listComplianceSummaries': {
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
        Type: '<optional>',
      },
    ],
  },
  'SSM:listDocumentVersions': {
    Name: 'required',
  },
  'SSM:listDocuments': {
    DocumentFilterList: '<optional>',
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:listInventoryEntries': {
    InstanceId: 'required',
    TypeName: 'required',
    Filters: [
      {
        Type: '<optional>',
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SSM:listResourceComplianceSummaries': {
    Filters: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
        Type: '<optional>',
      },
    ],
  },
  'SSM:listResourceDataSync': {},
  'SSM:listTagsForResource': {
    ResourceType: 'required',
    ResourceId: 'required',
  },
  'SSM:modifyDocumentPermission': {
    Name: 'required',
    PermissionType: 'required',
    AccountIdsToAdd: '<optional>',
    AccountIdsToRemove: '<optional>',
  },
  'SSM:putComplianceItems': {
    ResourceId: 'required',
    ResourceType: 'required',
    ComplianceType: 'required',
    ExecutionSummary: {
      ExecutionId: '<optional>',
      ExecutionType: '<optional>',
    },
    Items: [
      {
        Id: '<optional>',
        Title: '<optional>',
        Details: ['<optional1>', '<optional2>'],
      },
    ],
    ItemContentHash: '<optional>',
  },
  'SSM:putInventory': {
    InstanceId: 'required',
    Items: [
      {
        ContentHash: '<optional>',
        Content: '<optional>',
        Context: '<optional>',
      },
    ],
  },
  'SSM:putParameter': {
    Name: 'required',
    Value: 'required',
    Type: 'required',
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    KeyId: '<optional>',
    Overwrite: '<optional>',
    AllowedPattern: '<optional>',
    Tier: '<optional>',
    Policies: ['<optional1>', '<optional2>'],
  },
  'SSM:registerDefaultPatchBaseline': {
    BaselineId: 'required',
  },
  'SSM:registerPatchBaselineForPatchGroup': {
    BaselineId: 'required',
    PatchGroup: 'required',
  },
  'SSM:registerTargetWithMaintenanceWindow': {
    WindowId: 'required',
    ResourceType: 'required',
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    OwnerInformation: '<optional>',
    Name: '<optional>',
    Description: '<optional>',
    ClientToken: '<optional>',
  },
  'SSM:registerTaskWithMaintenanceWindow': {
    WindowId: 'required',
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    TaskArn: 'required',
    TaskType: 'required',
    MaxConcurrency: 'required',
    MaxErrors: ['required1', 'required2'],
    LoggingInfo: {
      S3KeyPrefix: '<optional>',
    },
    ServiceRoleArn: '<optional>',
    TaskParameters: ['<optional1>', '<optional2>'],
    TaskInvocationParameters: [
      {
        RunCommand: {
          Comment: '<optional>',
          DocumentHash: '<optional>',
          DocumentHashType: '<optional>',
          NotificationConfig: {
            NotificationArn: '<optional>',
            NotificationEvents: ['<optional1>', '<optional2>'],
            NotificationType: '<optional>',
          },
          OutputS3BucketName: '<optional>',
          OutputS3KeyPrefix: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
          ServiceRoleArn: '<optional>',
          TimeoutSeconds: ['<optional1>', '<optional2>'],
        },
        Automation: {
          DocumentVersion: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
        },
        StepFunctions: [
          {
            Input: '<optional>',
            Name: '<optional>',
          },
        ],
        Lambda: {
          ClientContext: '<optional>',
          Qualifier: '<optional>',
          Payload: '<optional>',
        },
      },
    ],
    Priority: '<optional>',
    Name: '<optional>',
    Description: '<optional>',
    ClientToken: '<optional>',
  },
  'SSM:removeTagsFromResource': {
    ResourceType: 'required',
    ResourceId: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'SSM:resetServiceSetting': {
    SettingId: 'required',
  },
  'SSM:resumeSession': {
    SessionId: 'required',
  },
  'SSM:sendAutomationSignal': {
    AutomationExecutionId: 'required',
    SignalType: 'required',
    Payload: '<optional>',
  },
  'SSM:sendCommand': {
    DocumentName: 'required',
    InstanceIds: ['<optional1>', '<optional2>'],
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    DocumentVersion: '<optional>',
    DocumentHash: '<optional>',
    DocumentHashType: '<optional>',
    TimeoutSeconds: ['<optional1>', '<optional2>'],
    Comment: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
    OutputS3Region: '<optional>',
    OutputS3BucketName: '<optional>',
    OutputS3KeyPrefix: '<optional>',
    MaxConcurrency: '<optional>',
    MaxErrors: ['<optional1>', '<optional2>'],
    ServiceRoleArn: '<optional>',
    NotificationConfig: {
      NotificationArn: '<optional>',
      NotificationEvents: ['<optional1>', '<optional2>'],
      NotificationType: '<optional>',
    },
    CloudWatchOutputConfig: {
      CloudWatchLogGroupName: '<optional>',
      CloudWatchOutputEnabled: '<optional>',
    },
  },
  'SSM:startAssociationsOnce': {
    AssociationIds: ['required1', 'required2'],
  },
  'SSM:startAutomationExecution': {
    DocumentName: 'required',
    DocumentVersion: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
    ClientToken: '<optional>',
    Mode: '<optional>',
    TargetParameterName: '<optional>',
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    TargetMaps: ['<optional1>', '<optional2>'],
    MaxConcurrency: '<optional>',
    MaxErrors: ['<optional1>', '<optional2>'],
    TargetLocations: [
      {
        Accounts: ['<optional1>', '<optional2>'],
        Regions: ['<optional1>', '<optional2>'],
        TargetLocationMaxConcurrency: '<optional>',
        TargetLocationMaxErrors: ['<optional1>', '<optional2>'],
        ExecutionRoleName: '<optional>',
      },
    ],
  },
  'SSM:startSession': {
    Target: 'required',
    DocumentName: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
  },
  'SSM:stopAutomationExecution': {
    AutomationExecutionId: 'required',
    Type: '<optional>',
  },
  'SSM:terminateSession': {
    SessionId: 'required',
  },
  'SSM:updateAssociation': {
    AssociationId: 'required',
    Parameters: ['<optional1>', '<optional2>'],
    DocumentVersion: '<optional>',
    ScheduleExpression: '<optional>',
    OutputLocation: {
      S3Location: {
        OutputS3Region: '<optional>',
        OutputS3BucketName: '<optional>',
        OutputS3KeyPrefix: '<optional>',
      },
    },
    Name: '<optional>',
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    AssociationName: '<optional>',
    AssociationVersion: '<optional>',
    AutomationTargetParameterName: '<optional>',
    MaxErrors: ['<optional1>', '<optional2>'],
    MaxConcurrency: '<optional>',
    ComplianceSeverity: '<optional>',
  },
  'SSM:updateAssociationStatus': {
    Name: 'required',
    InstanceId: 'required',
    AssociationStatus: {
      AdditionalInfo: '<optional>',
    },
  },
  'SSM:updateDocument': {
    Content: 'required',
    Name: 'required',
    Attachments: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    VersionName: '<optional>',
    DocumentVersion: '<optional>',
    DocumentFormat: '<optional>',
    TargetType: '<optional>',
  },
  'SSM:updateDocumentDefaultVersion': {
    Name: 'required',
    DocumentVersion: 'required',
  },
  'SSM:updateMaintenanceWindow': {
    WindowId: 'required',
    Name: '<optional>',
    Description: '<optional>',
    StartDate: '<optional>',
    EndDate: '<optional>',
    Schedule: '<optional>',
    ScheduleTimezone: '<optional>',
    Duration: '<optional>',
    Cutoff: '<optional>',
    AllowUnassociatedTargets: ['<optional1>', '<optional2>'],
    Enabled: '<optional>',
    Replace: '<optional>',
  },
  'SSM:updateMaintenanceWindowTarget': {
    WindowId: 'required',
    WindowTargetId: 'required',
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    OwnerInformation: '<optional>',
    Name: '<optional>',
    Description: '<optional>',
    Replace: '<optional>',
  },
  'SSM:updateMaintenanceWindowTask': {
    WindowId: 'required',
    WindowTaskId: 'required',
    LoggingInfo: {
      S3KeyPrefix: '<optional>',
    },
    Targets: [
      {
        Key: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
    TaskArn: '<optional>',
    ServiceRoleArn: '<optional>',
    TaskParameters: ['<optional1>', '<optional2>'],
    TaskInvocationParameters: [
      {
        RunCommand: {
          Comment: '<optional>',
          DocumentHash: '<optional>',
          DocumentHashType: '<optional>',
          NotificationConfig: {
            NotificationArn: '<optional>',
            NotificationEvents: ['<optional1>', '<optional2>'],
            NotificationType: '<optional>',
          },
          OutputS3BucketName: '<optional>',
          OutputS3KeyPrefix: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
          ServiceRoleArn: '<optional>',
          TimeoutSeconds: ['<optional1>', '<optional2>'],
        },
        Automation: {
          DocumentVersion: '<optional>',
          Parameters: ['<optional1>', '<optional2>'],
        },
        StepFunctions: [
          {
            Input: '<optional>',
            Name: '<optional>',
          },
        ],
        Lambda: {
          ClientContext: '<optional>',
          Qualifier: '<optional>',
          Payload: '<optional>',
        },
      },
    ],
    Priority: '<optional>',
    MaxConcurrency: '<optional>',
    MaxErrors: ['<optional1>', '<optional2>'],
    Name: '<optional>',
    Description: '<optional>',
    Replace: '<optional>',
  },
  'SSM:updateManagedInstanceRole': {
    InstanceId: 'required',
    IamRole: 'required',
  },
  'SSM:updateOpsItem': {
    OpsItemId: 'required',
    RelatedOpsItems: ['<optional1>', '<optional2>'],
    Description: '<optional>',
    OperationalData: ['<optional1>', '<optional2>'],
    OperationalDataToDelete: '<optional>',
    Notifications: [
      {
        Arn: '<optional>',
      },
    ],
    Priority: '<optional>',
    Status: '<optional>',
    Title: '<optional>',
  },
  'SSM:updatePatchBaseline': {
    BaselineId: 'required',
    GlobalFilters: ['<optional1>', '<optional2>'],
    ApprovalRules: [
      {
        PatchRules: [
          {
            ComplianceLevel: '<optional>',
            EnableNonSecurity: '<optional>',
          },
        ],
      },
    ],
    Sources: ['<optional1>', '<optional2>'],
    Name: '<optional>',
    ApprovedPatches: ['<optional1>', '<optional2>'],
    ApprovedPatchesComplianceLevel: '<optional>',
    ApprovedPatchesEnableNonSecurity: '<optional>',
    RejectedPatches: ['<optional1>', '<optional2>'],
    RejectedPatchesAction: '<optional>',
    Description: '<optional>',
    Replace: '<optional>',
  },
  'SSM:updateResourceDataSync': {
    SyncName: 'required',
    SyncType: 'required',
    SyncSource: {
      AwsOrganizationsSource: {
        OrganizationalUnits: [
          {
            OrganizationalUnitId: '<optional>',
          },
        ],
      },
      IncludeFutureRegions: ['<optional1>', '<optional2>'],
    },
  },
  'SSM:updateServiceSetting': {
    SettingId: 'required',
    SettingValue: 'required',
  },
  'SSO:getRoleCredentials': {
    roleName: 'required',
    accountId: 'required',
    accessToken: 'required',
  },
  'SSO:listAccountRoles': {
    accessToken: 'required',
    accountId: 'required',
  },
  'SSO:listAccounts': {
    accessToken: 'required',
  },
  'SSO:logout': {
    accessToken: 'required',
  },
  'SSOOIDC:createToken': {
    clientId: 'required',
    clientSecret: 'required',
    grantType: 'required',
    deviceCode: 'required',
    code: '<optional>',
    refreshToken: '<optional>',
    scope: '<optional>',
    redirectUri: '<optional>',
  },
  'SSOOIDC:registerClient': {
    clientName: 'required',
    clientType: 'required',
    scopes: ['<optional1>', '<optional2>'],
  },
  'SSOOIDC:startDeviceAuthorization': {
    clientId: 'required',
    clientSecret: 'required',
    startUrl: 'required',
  },
  'STS:assumeRole': {
    RoleArn: 'required',
    RoleSessionName: 'required',
    PolicyArns: [
      {
        arn: '<optional>',
      },
    ],
    Policy: '<optional>',
    DurationSeconds: ['<optional1>', '<optional2>'],
    ExternalId: '<optional>',
    SerialNumber: '<optional>',
    TokenCode: '<optional>',
  },
  'STS:assumeRoleWithSAML': {
    RoleArn: 'required',
    PrincipalArn: 'required',
    SAMLAssertion: 'required',
    PolicyArns: [
      {
        arn: '<optional>',
      },
    ],
    Policy: '<optional>',
    DurationSeconds: ['<optional1>', '<optional2>'],
  },
  'STS:assumeRoleWithWebIdentity': {
    RoleArn: 'required',
    RoleSessionName: 'required',
    WebIdentityToken: 'required',
    ProviderId: '<optional>',
    PolicyArns: [
      {
        arn: '<optional>',
      },
    ],
    Policy: '<optional>',
    DurationSeconds: ['<optional1>', '<optional2>'],
  },
  'STS:decodeAuthorizationMessage': {
    EncodedMessage: 'required',
  },
  'STS:getAccessKeyInfo': {
    AccessKeyId: 'required',
  },
  'STS:getCallerIdentity': {},
  'STS:getFederationToken': {
    Name: 'required',
    Policy: '<optional>',
    PolicyArns: [
      {
        arn: '<optional>',
      },
    ],
    DurationSeconds: ['<optional1>', '<optional2>'],
  },
  'STS:getSessionToken': {
    DurationSeconds: ['<optional1>', '<optional2>'],
    SerialNumber: '<optional>',
    TokenCode: '<optional>',
  },
  'SWF:countClosedWorkflowExecutions': {
    domain: 'required',
    startTimeFilter: {
      latestDate: '<optional>',
    },
    closeTimeFilter: {
      latestDate: '<optional>',
    },
    executionFilter: '<optional>',
    typeFilter: {
      version: '<optional>',
    },
    tagFilter: '<optional>',
    closeStatusFilter: '<optional>',
  },
  'SWF:countOpenWorkflowExecutions': {
    domain: 'required',
    startTimeFilter: {
      latestDate: '<optional>',
    },
    typeFilter: {
      version: '<optional>',
    },
    tagFilter: '<optional>',
    executionFilter: '<optional>',
  },
  'SWF:countPendingActivityTasks': {
    domain: 'required',
    taskList: {
      name: 'required',
    },
  },
  'SWF:countPendingDecisionTasks': {
    domain: 'required',
    taskList: {
      name: 'required',
    },
  },
  'SWF:deprecateActivityType': {
    domain: 'required',
    activityType: {
      name: 'required',
      version: 'required',
    },
  },
  'SWF:deprecateDomain': {
    name: 'required',
  },
  'SWF:deprecateWorkflowType': {
    domain: 'required',
    workflowType: {
      name: 'required',
      version: 'required',
    },
  },
  'SWF:describeActivityType': {
    domain: 'required',
    activityType: {
      name: 'required',
      version: 'required',
    },
  },
  'SWF:describeDomain': {
    name: 'required',
  },
  'SWF:describeWorkflowExecution': {
    domain: 'required',
    execution: {
      workflowId: 'required',
      runId: 'required',
    },
  },
  'SWF:describeWorkflowType': {
    domain: 'required',
    workflowType: {
      name: 'required',
      version: 'required',
    },
  },
  'SWF:getWorkflowExecutionHistory': {
    domain: 'required',
    execution: {
      workflowId: 'required',
      runId: 'required',
    },
    nextPageToken: '<optional>',
    maximumPageSize: '<optional>',
    reverseOrder: '<optional>',
  },
  'SWF:listActivityTypes': {
    domain: 'required',
    registrationStatus: 'required',
    name: '<optional>',
    nextPageToken: '<optional>',
    maximumPageSize: '<optional>',
    reverseOrder: '<optional>',
  },
  'SWF:listClosedWorkflowExecutions': {
    domain: 'required',
    startTimeFilter: {
      latestDate: '<optional>',
    },
    closeTimeFilter: {
      latestDate: '<optional>',
    },
    executionFilter: '<optional>',
    closeStatusFilter: '<optional>',
    typeFilter: {
      version: '<optional>',
    },
    tagFilter: '<optional>',
    nextPageToken: '<optional>',
    maximumPageSize: '<optional>',
    reverseOrder: '<optional>',
  },
  'SWF:listDomains': {
    registrationStatus: 'required',
    nextPageToken: '<optional>',
    maximumPageSize: '<optional>',
    reverseOrder: '<optional>',
  },
  'SWF:listOpenWorkflowExecutions': {
    domain: 'required',
    startTimeFilter: {
      latestDate: '<optional>',
    },
    typeFilter: {
      version: '<optional>',
    },
    tagFilter: '<optional>',
    executionFilter: '<optional>',
    nextPageToken: '<optional>',
    maximumPageSize: '<optional>',
    reverseOrder: '<optional>',
  },
  'SWF:listTagsForResource': {
    resourceArn: 'required',
  },
  'SWF:listWorkflowTypes': {
    domain: 'required',
    registrationStatus: 'required',
    name: '<optional>',
    nextPageToken: '<optional>',
    maximumPageSize: '<optional>',
    reverseOrder: '<optional>',
  },
  'SWF:pollForActivityTask': {
    domain: 'required',
    taskList: {
      name: 'required',
    },
    identity: '<optional>',
  },
  'SWF:pollForDecisionTask': {
    domain: 'required',
    taskList: {
      name: 'required',
    },
    identity: '<optional>',
    nextPageToken: '<optional>',
    maximumPageSize: '<optional>',
    reverseOrder: '<optional>',
  },
  'SWF:recordActivityTaskHeartbeat': {
    taskToken: 'required',
    details: ['<optional1>', '<optional2>'],
  },
  'SWF:registerActivityType': {
    domain: 'required',
    name: 'required',
    version: 'required',
    defaultTaskList: '<optional>',
    description: '<optional>',
    defaultTaskStartToCloseTimeout: '<optional>',
    defaultTaskHeartbeatTimeout: '<optional>',
    defaultTaskPriority: '<optional>',
    defaultTaskScheduleToStartTimeout: '<optional>',
    defaultTaskScheduleToCloseTimeout: '<optional>',
  },
  'SWF:registerDomain': {
    name: 'required',
    workflowExecutionRetentionPeriodInDays: ['required1', 'required2'],
    tags: [
      {
        value: '<optional>',
      },
    ],
    description: '<optional>',
  },
  'SWF:registerWorkflowType': {
    domain: 'required',
    name: 'required',
    version: 'required',
    defaultTaskList: '<optional>',
    description: '<optional>',
    defaultTaskStartToCloseTimeout: '<optional>',
    defaultExecutionStartToCloseTimeout: '<optional>',
    defaultTaskPriority: '<optional>',
    defaultChildPolicy: '<optional>',
    defaultLambdaRole: '<optional>',
  },
  'SWF:requestCancelWorkflowExecution': {
    domain: 'required',
    workflowId: 'required',
    runId: '<optional>',
  },
  'SWF:respondActivityTaskCanceled': {
    taskToken: 'required',
    details: ['<optional1>', '<optional2>'],
  },
  'SWF:respondActivityTaskCompleted': {
    taskToken: 'required',
    result: '<optional>',
  },
  'SWF:respondActivityTaskFailed': {
    taskToken: 'required',
    reason: '<optional>',
    details: ['<optional1>', '<optional2>'],
  },
  'SWF:respondDecisionTaskCompleted': {
    taskToken: 'required',
    decisions: [
      {
        scheduleActivityTaskDecisionAttributes: [
          {
            control: '<optional>',
            input: '<optional>',
            scheduleToCloseTimeout: '<optional>',
            taskList: '<optional>',
            taskPriority: '<optional>',
            scheduleToStartTimeout: '<optional>',
            startToCloseTimeout: '<optional>',
            heartbeatTimeout: '<optional>',
          },
        ],
        requestCancelActivityTaskDecisionAttributes: [
          '<optional1>',
          '<optional2>',
        ],
        completeWorkflowExecutionDecisionAttributes: [
          {
            result: '<optional>',
          },
        ],
        failWorkflowExecutionDecisionAttributes: [
          {
            reason: '<optional>',
            details: ['<optional1>', '<optional2>'],
          },
        ],
        cancelWorkflowExecutionDecisionAttributes: [
          {
            details: ['<optional1>', '<optional2>'],
          },
        ],
        continueAsNewWorkflowExecutionDecisionAttributes: [
          {
            input: '<optional>',
            executionStartToCloseTimeout: '<optional>',
            taskList: '<optional>',
            taskPriority: '<optional>',
            taskStartToCloseTimeout: '<optional>',
            childPolicy: '<optional>',
            tagList: '<optional>',
            workflowTypeVersion: '<optional>',
            lambdaRole: '<optional>',
          },
        ],
        recordMarkerDecisionAttributes: [
          {
            details: ['<optional1>', '<optional2>'],
          },
        ],
        startTimerDecisionAttributes: [
          {
            control: '<optional>',
          },
        ],
        cancelTimerDecisionAttributes: ['<optional1>', '<optional2>'],
        signalExternalWorkflowExecutionDecisionAttributes: [
          {
            runId: '<optional>',
            input: '<optional>',
            control: '<optional>',
          },
        ],
        requestCancelExternalWorkflowExecutionDecisionAttributes: [
          {
            runId: '<optional>',
            control: '<optional>',
          },
        ],
        startChildWorkflowExecutionDecisionAttributes: [
          {
            control: '<optional>',
            input: '<optional>',
            executionStartToCloseTimeout: '<optional>',
            taskList: '<optional>',
            taskPriority: '<optional>',
            taskStartToCloseTimeout: '<optional>',
            childPolicy: '<optional>',
            tagList: '<optional>',
            lambdaRole: '<optional>',
          },
        ],
        scheduleLambdaFunctionDecisionAttributes: [
          {
            control: '<optional>',
            input: '<optional>',
            startToCloseTimeout: '<optional>',
          },
        ],
      },
    ],
    executionContext: '<optional>',
  },
  'SWF:signalWorkflowExecution': {
    domain: 'required',
    workflowId: 'required',
    signalName: 'required',
    runId: '<optional>',
    input: '<optional>',
  },
  'SWF:startWorkflowExecution': {
    domain: 'required',
    workflowId: 'required',
    workflowType: {
      name: 'required',
      version: 'required',
    },
    taskList: '<optional>',
    taskPriority: '<optional>',
    input: '<optional>',
    executionStartToCloseTimeout: '<optional>',
    tagList: '<optional>',
    taskStartToCloseTimeout: '<optional>',
    childPolicy: '<optional>',
    lambdaRole: '<optional>',
  },
  'SWF:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        value: '<optional>',
      },
    ],
  },
  'SWF:terminateWorkflowExecution': {
    domain: 'required',
    workflowId: 'required',
    runId: '<optional>',
    reason: '<optional>',
    details: ['<optional1>', '<optional2>'],
    childPolicy: '<optional>',
  },
  'SWF:undeprecateActivityType': {
    domain: 'required',
    activityType: {
      name: 'required',
      version: 'required',
    },
  },
  'SWF:undeprecateDomain': {
    name: 'required',
  },
  'SWF:undeprecateWorkflowType': {
    domain: 'required',
    workflowType: {
      name: 'required',
      version: 'required',
    },
  },
  'SWF:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'SageMaker:addTags': {
    ResourceArn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'SageMaker:associateTrialComponent': {
    TrialComponentName: 'required',
    TrialName: 'required',
  },
  'SageMaker:createAlgorithm': {
    AlgorithmName: 'required',
    TrainingSpecification: {
      TrainingImageDigest: '<optional>',
      SupportedHyperParameters: [
        {
          Description: '<optional>',
          Range: {
            IntegerParameterRangeSpecification: '<optional>',
            ContinuousParameterRangeSpecification: '<optional>',
            CategoricalParameterRangeSpecification: '<optional>',
          },
          IsTunable: '<optional>',
          IsRequired: '<optional>',
          DefaultValue: '<optional>',
        },
      ],
      SupportsDistributedTraining: '<optional>',
      MetricDefinitions: ['<optional1>', '<optional2>'],
      SupportedTuningJobObjectiveMetrics: ['<optional1>', '<optional2>'],
      TrainingChannels: [
        {
          Description: '<optional>',
          IsRequired: '<optional>',
          SupportedCompressionTypes: ['<optional1>', '<optional2>'],
        },
      ],
    },
    InferenceSpecification: {
      Containers: [
        {
          ContainerHostname: '<optional>',
          ImageDigest: '<optional>',
          ModelDataUrl: '<optional>',
          ProductId: '<optional>',
        },
      ],
    },
    ValidationSpecification: {
      ValidationProfiles: [
        {
          TransformJobDefinition: {
            MaxConcurrentTransforms: ['<optional1>', '<optional2>'],
            MaxPayloadInMB: '<optional>',
            BatchStrategy: '<optional>',
            Environment: '<optional>',
            TransformInput: {
              ContentType: '<optional>',
              CompressionType: '<optional>',
              SplitType: '<optional>',
            },
            TransformOutput: {
              Accept: '<optional>',
              AssembleWith: '<optional>',
              KmsKeyId: '<optional>',
            },
            TransformResources: [
              {
                VolumeKmsKeyId: '<optional>',
              },
            ],
          },
          TrainingJobDefinition: {
            HyperParameters: ['<optional1>', '<optional2>'],
            InputDataConfig: {
              ContentType: '<optional>',
              CompressionType: '<optional>',
              RecordWrapperType: '<optional>',
              InputMode: '<optional>',
              ShuffleConfig: '<optional>',
              DataSource: {
                S3DataSource: {
                  S3DataDistributionType: '<optional>',
                  AttributeNames: ['<optional1>', '<optional2>'],
                },
              },
            },
            OutputDataConfig: {
              KmsKeyId: '<optional>',
            },
            ResourceConfig: {
              VolumeKmsKeyId: '<optional>',
            },
            StoppingCondition: {
              MaxRuntimeInSeconds: ['<optional1>', '<optional2>'],
            },
          },
        },
      ],
    },
    AlgorithmDescription: '<optional>',
    CertifyForMarketplace: '<optional>',
  },
  'SageMaker:createApp': {
    DomainId: 'required',
    UserProfileName: 'required',
    AppType: 'required',
    AppName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    ResourceSpec: {
      EnvironmentArn: '<optional>',
      InstanceType: '<optional>',
    },
  },
  'SageMaker:createAutoMLJob': {
    AutoMLJobName: 'required',
    InputDataConfig: {
      CompressionType: '<optional>',
    },
    OutputDataConfig: {
      KmsKeyId: '<optional>',
    },
    RoleArn: 'required',
    AutoMLJobObjective: '<optional>',
    AutoMLJobConfig: {
      CompletionCriteria: [
        {
          MaxCandidates: ['<optional1>', '<optional2>'],
          MaxRuntimePerTrainingJobInSeconds: ['<optional1>', '<optional2>'],
          MaxAutoMLJobRuntimeInSeconds: ['<optional1>', '<optional2>'],
        },
      ],
      SecurityConfig: {
        VolumeKmsKeyId: '<optional>',
        EnableInterContainerTrafficEncryption: '<optional>',
        VpcConfig: '<optional>',
      },
    },
    Tags: ['<optional1>', '<optional2>'],
    ProblemType: '<optional>',
    GenerateCandidateDefinitionsOnly: '<optional>',
  },
  'SageMaker:createCodeRepository': {
    CodeRepositoryName: 'required',
    GitConfig: {
      Branch: '<optional>',
      SecretArn: '<optional>',
    },
  },
  'SageMaker:createCompilationJob': {
    CompilationJobName: 'required',
    RoleArn: 'required',
    InputConfig: {
      S3Uri: 'required',
      DataInputConfig: 'required',
      Framework: 'required',
    },
    OutputConfig: {
      S3OutputLocation: 'required',
      TargetDevice: 'required',
    },
    StoppingCondition: {
      MaxRuntimeInSeconds: ['<optional1>', '<optional2>'],
    },
  },
  'SageMaker:createDomain': {
    DomainName: 'required',
    AuthMode: 'required',
    DefaultUserSettings: [
      {
        ExecutionRole: '<optional>',
        SecurityGroups: ['<optional1>', '<optional2>'],
        SharingSettings: [
          {
            NotebookOutputOption: '<optional>',
            S3OutputPath: '<optional>',
            S3KmsKeyId: '<optional>',
          },
        ],
        JupyterServerAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
        KernelGatewayAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
        TensorBoardAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
      },
    ],
    SubnetIds: ['required1', 'required2'],
    VpcId: 'required',
    Tags: ['<optional1>', '<optional2>'],
    HomeEfsFileSystemKmsKeyId: '<optional>',
  },
  'SageMaker:createEndpoint': {
    EndpointName: 'required',
    EndpointConfigName: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'SageMaker:createEndpointConfig': {
    EndpointConfigName: 'required',
    ProductionVariants: [
      {
        InitialVariantWeight: '<optional>',
        AcceleratorType: '<optional>',
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
    KmsKeyId: '<optional>',
  },
  'SageMaker:createExperiment': {
    ExperimentName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    DisplayName: '<optional>',
    Description: '<optional>',
  },
  'SageMaker:createFlowDefinition': {
    FlowDefinitionName: 'required',
    HumanLoopConfig: {
      TaskAvailabilityLifetimeInSeconds: ['<optional1>', '<optional2>'],
      TaskTimeLimitInSeconds: ['<optional1>', '<optional2>'],
      TaskKeywords: ['<optional1>', '<optional2>'],
      PublicWorkforceTaskPrice: {
        AmountInUsd: {
          Dollars: ['<optional1>', '<optional2>'],
          Cents: ['<optional1>', '<optional2>'],
          TenthFractionsOfACent: '<optional>',
        },
      },
    },
    OutputConfig: {
      KmsKeyId: '<optional>',
    },
    RoleArn: 'required',
    HumanLoopActivationConfig: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'SageMaker:createHumanTaskUi': {
    HumanTaskUiName: 'required',
    UiTemplate: {
      Content: 'required',
    },
    Tags: ['<optional1>', '<optional2>'],
  },
  'SageMaker:createHyperParameterTuningJob': {
    HyperParameterTuningJobName: 'required',
    HyperParameterTuningJobConfig: {
      HyperParameterTuningJobObjective: '<optional>',
      ParameterRanges: [
        {
          IntegerParameterRanges: [
            {
              ScalingType: '<optional>',
            },
          ],
          ContinuousParameterRanges: [
            {
              ScalingType: '<optional>',
            },
          ],
          CategoricalParameterRanges: ['<optional1>', '<optional2>'],
        },
      ],
      TrainingJobEarlyStoppingType: '<optional>',
    },
    TrainingJobDefinition: {
      StaticHyperParameters: ['<optional1>', '<optional2>'],
      InputDataConfig: {
        ContentType: '<optional>',
        CompressionType: '<optional>',
        RecordWrapperType: '<optional>',
        InputMode: '<optional>',
        ShuffleConfig: '<optional>',
        DataSource: {
          S3DataSource: {
            S3DataDistributionType: '<optional>',
            AttributeNames: ['<optional1>', '<optional2>'],
          },
        },
      },
      VpcConfig: '<optional>',
      EnableNetworkIsolation: '<optional>',
      EnableInterContainerTrafficEncryption: '<optional>',
      AlgorithmSpecification: {
        TrainingImage: '<optional>',
        AlgorithmName: '<optional>',
        MetricDefinitions: ['<optional1>', '<optional2>'],
      },
      OutputDataConfig: {
        KmsKeyId: '<optional>',
      },
      ResourceConfig: {
        VolumeKmsKeyId: '<optional>',
      },
      StoppingCondition: {
        MaxRuntimeInSeconds: ['<optional1>', '<optional2>'],
      },
    },
    WarmStartConfig: {
      ParentHyperParameterTuningJobs: [
        {
          HyperParameterTuningJobName: '<optional>',
        },
      ],
    },
    Tags: ['<optional1>', '<optional2>'],
  },
  'SageMaker:createLabelingJob': {
    LabelingJobName: 'required',
    LabelAttributeName: 'required',
    InputConfig: {
      DataAttributes: [
        {
          ContentClassifiers: ['<optional1>', '<optional2>'],
        },
      ],
    },
    OutputConfig: {
      KmsKeyId: '<optional>',
    },
    RoleArn: 'required',
    HumanTaskConfig: {
      TaskKeywords: ['<optional1>', '<optional2>'],
      TaskAvailabilityLifetimeInSeconds: ['<optional1>', '<optional2>'],
      MaxConcurrentTaskCount: '<optional>',
      PublicWorkforceTaskPrice: {
        AmountInUsd: {
          Dollars: ['<optional1>', '<optional2>'],
          Cents: ['<optional1>', '<optional2>'],
          TenthFractionsOfACent: '<optional>',
        },
      },
    },
    LabelingJobAlgorithmsConfig: {
      InitialActiveLearningModelArn: '<optional>',
      LabelingJobResourceConfig: {
        VolumeKmsKeyId: '<optional>',
      },
    },
    Tags: ['<optional1>', '<optional2>'],
    LabelCategoryConfigS3Uri: '<optional>',
    StoppingConditions: [
      {
        MaxHumanLabeledObjectCount: '<optional>',
        MaxPercentageOfInputDatasetLabeled: '<optional>',
      },
    ],
  },
  'SageMaker:createModel': {
    ModelName: 'required',
    ExecutionRoleArn: 'required',
    Tags: ['<optional1>', '<optional2>'],
    VpcConfig: '<optional>',
    PrimaryContainer: {
      ContainerHostname: '<optional>',
      Image: '<optional>',
      ModelDataUrl: '<optional>',
      Environment: '<optional>',
      ModelPackageName: '<optional>',
    },
    Containers: [
      {
        ContainerHostname: '<optional>',
        Image: '<optional>',
        ModelDataUrl: '<optional>',
        Environment: '<optional>',
        ModelPackageName: '<optional>',
      },
    ],
    EnableNetworkIsolation: '<optional>',
  },
  'SageMaker:createModelPackage': {
    ModelPackageName: 'required',
    InferenceSpecification: {
      Containers: [
        {
          ContainerHostname: '<optional>',
          ImageDigest: '<optional>',
          ModelDataUrl: '<optional>',
          ProductId: '<optional>',
        },
      ],
    },
    ValidationSpecification: {
      ValidationProfiles: [
        {
          TransformJobDefinition: {
            MaxConcurrentTransforms: ['<optional1>', '<optional2>'],
            MaxPayloadInMB: '<optional>',
            BatchStrategy: '<optional>',
            Environment: '<optional>',
            TransformInput: {
              ContentType: '<optional>',
              CompressionType: '<optional>',
              SplitType: '<optional>',
            },
            TransformOutput: {
              Accept: '<optional>',
              AssembleWith: '<optional>',
              KmsKeyId: '<optional>',
            },
            TransformResources: [
              {
                VolumeKmsKeyId: '<optional>',
              },
            ],
          },
        },
      ],
    },
    SourceAlgorithmSpecification: {
      SourceAlgorithms: [
        {
          ModelDataUrl: '<optional>',
        },
      ],
    },
    ModelPackageDescription: '<optional>',
    CertifyForMarketplace: '<optional>',
  },
  'SageMaker:createMonitoringSchedule': {
    MonitoringScheduleName: 'required',
    MonitoringScheduleConfig: {
      ScheduleConfig: '<optional>',
      MonitoringJobDefinition: {
        BaselineConfig: {
          ConstraintsResource: {
            S3Uri: '<optional>',
          },
          StatisticsResource: {
            S3Uri: '<optional>',
          },
        },
        StoppingCondition: '<optional>',
        Environment: '<optional>',
        NetworkConfig: {
          EnableNetworkIsolation: '<optional>',
          VpcConfig: '<optional>',
        },
        MonitoringInputs: [
          {
            EndpointInput: {
              S3InputMode: '<optional>',
              S3DataDistributionType: '<optional>',
            },
          },
        ],
        MonitoringOutputConfig: {
          KmsKeyId: '<optional>',
          MonitoringOutputs: [
            {
              S3Output: {
                S3UploadMode: '<optional>',
              },
            },
          ],
        },
        MonitoringResources: [
          {
            ClusterConfig: {
              VolumeKmsKeyId: '<optional>',
            },
          },
        ],
        MonitoringAppSpecification: {
          ContainerEntrypoint: '<optional>',
          ContainerArguments: ['<optional1>', '<optional2>'],
          RecordPreprocessorSourceUri: '<optional>',
          PostAnalyticsProcessorSourceUri: '<optional>',
        },
      },
    },
    Tags: ['<optional1>', '<optional2>'],
  },
  'SageMaker:createNotebookInstance': {
    NotebookInstanceName: 'required',
    InstanceType: 'required',
    RoleArn: 'required',
    Tags: ['<optional1>', '<optional2>'],
    SubnetId: '<optional>',
    SecurityGroupIds: ['<optional1>', '<optional2>'],
    KmsKeyId: '<optional>',
    LifecycleConfigName: '<optional>',
    DirectInternetAccess: '<optional>',
    VolumeSizeInGB: '<optional>',
    AcceleratorTypes: ['<optional1>', '<optional2>'],
    DefaultCodeRepository: '<optional>',
    AdditionalCodeRepositories: ['<optional1>', '<optional2>'],
    RootAccess: '<optional>',
  },
  'SageMaker:createNotebookInstanceLifecycleConfig': {
    NotebookInstanceLifecycleConfigName: 'required',
    OnCreate: {
      Content: '<optional>',
    },
    OnStart: {
      Content: '<optional>',
    },
  },
  'SageMaker:createPresignedDomainUrl': {
    DomainId: 'required',
    UserProfileName: 'required',
    SessionExpirationDurationInSeconds: ['<optional1>', '<optional2>'],
  },
  'SageMaker:createPresignedNotebookInstanceUrl': {
    NotebookInstanceName: 'required',
    SessionExpirationDurationInSeconds: ['<optional1>', '<optional2>'],
  },
  'SageMaker:createProcessingJob': {
    ProcessingJobName: 'required',
    ProcessingResources: [
      {
        ClusterConfig: {
          VolumeKmsKeyId: '<optional>',
        },
      },
    ],
    AppSpecification: {
      ContainerEntrypoint: '<optional>',
      ContainerArguments: ['<optional1>', '<optional2>'],
    },
    RoleArn: 'required',
    ProcessingInputs: [
      {
        S3Input: {
          S3DataDistributionType: '<optional>',
          S3CompressionType: '<optional>',
        },
      },
    ],
    ProcessingOutputConfig: {
      KmsKeyId: '<optional>',
    },
    StoppingCondition: '<optional>',
    NetworkConfig: {
      EnableNetworkIsolation: '<optional>',
      VpcConfig: '<optional>',
    },
    Tags: ['<optional1>', '<optional2>'],
    Environment: '<optional>',
    ExperimentConfig: {
      ExperimentName: '<optional>',
      TrialName: '<optional>',
      TrialComponentDisplayName: '<optional>',
    },
  },
  'SageMaker:createTrainingJob': {
    TrainingJobName: 'required',
    AlgorithmSpecification: {
      TrainingImage: '<optional>',
      AlgorithmName: '<optional>',
      MetricDefinitions: ['<optional1>', '<optional2>'],
    },
    RoleArn: 'required',
    OutputDataConfig: {
      KmsKeyId: '<optional>',
    },
    ResourceConfig: {
      VolumeKmsKeyId: '<optional>',
    },
    StoppingCondition: {
      MaxRuntimeInSeconds: ['<optional1>', '<optional2>'],
    },
    InputDataConfig: {
      ContentType: '<optional>',
      CompressionType: '<optional>',
      RecordWrapperType: '<optional>',
      InputMode: '<optional>',
      ShuffleConfig: '<optional>',
      DataSource: {
        S3DataSource: {
          S3DataDistributionType: '<optional>',
          AttributeNames: ['<optional1>', '<optional2>'],
        },
      },
    },
    VpcConfig: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
    HyperParameters: ['<optional1>', '<optional2>'],
    EnableNetworkIsolation: '<optional>',
    EnableInterContainerTrafficEncryption: '<optional>',
  },
  'SageMaker:createTransformJob': {
    TransformJobName: 'required',
    ModelName: 'required',
    TransformInput: {
      ContentType: '<optional>',
      CompressionType: '<optional>',
      SplitType: '<optional>',
    },
    TransformOutput: {
      Accept: '<optional>',
      AssembleWith: '<optional>',
      KmsKeyId: '<optional>',
    },
    TransformResources: [
      {
        VolumeKmsKeyId: '<optional>',
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
    MaxConcurrentTransforms: ['<optional1>', '<optional2>'],
    MaxPayloadInMB: '<optional>',
    BatchStrategy: '<optional>',
    Environment: '<optional>',
    DataProcessing: {
      InputFilter: '<optional>',
      OutputFilter: '<optional>',
      JoinSource: '<optional>',
    },
  },
  'SageMaker:createTrial': {
    TrialName: 'required',
    ExperimentName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    DisplayName: '<optional>',
  },
  'SageMaker:createTrialComponent': {
    TrialComponentName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    DisplayName: '<optional>',
    Status: {
      PrimaryStatus: '<optional>',
      Message: '<optional>',
    },
    StartTime: '<optional>',
    EndTime: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
    InputArtifacts: ['<optional1>', '<optional2>'],
    OutputArtifacts: ['<optional1>', '<optional2>'],
  },
  'SageMaker:createUserProfile': {
    DomainId: 'required',
    UserProfileName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    SingleSignOnUserIdentifier: '<optional>',
    SingleSignOnUserValue: '<optional>',
    UserSettings: [
      {
        ExecutionRole: '<optional>',
        SecurityGroups: ['<optional1>', '<optional2>'],
        SharingSettings: [
          {
            NotebookOutputOption: '<optional>',
            S3OutputPath: '<optional>',
            S3KmsKeyId: '<optional>',
          },
        ],
        JupyterServerAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
        KernelGatewayAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
        TensorBoardAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
      },
    ],
  },
  'SageMaker:createWorkteam': {
    WorkteamName: 'required',
    MemberDefinitions: [
      {
        CognitoMemberDefinition: '<optional>',
      },
    ],
    Description: 'required',
    Tags: ['<optional1>', '<optional2>'],
    NotificationConfiguration: {
      NotificationTopicArn: '<optional>',
    },
  },
  'SageMaker:deleteAlgorithm': {
    AlgorithmName: 'required',
  },
  'SageMaker:deleteApp': {
    DomainId: 'required',
    UserProfileName: 'required',
    AppType: 'required',
    AppName: 'required',
  },
  'SageMaker:deleteCodeRepository': {
    CodeRepositoryName: 'required',
  },
  'SageMaker:deleteDomain': {
    DomainId: 'required',
    RetentionPolicy: {
      HomeEfsFileSystem: '<optional>',
    },
  },
  'SageMaker:deleteEndpoint': {
    EndpointName: 'required',
  },
  'SageMaker:deleteEndpointConfig': {
    EndpointConfigName: 'required',
  },
  'SageMaker:deleteExperiment': {
    ExperimentName: 'required',
  },
  'SageMaker:deleteFlowDefinition': {
    FlowDefinitionName: 'required',
  },
  'SageMaker:deleteModel': {
    ModelName: 'required',
  },
  'SageMaker:deleteModelPackage': {
    ModelPackageName: 'required',
  },
  'SageMaker:deleteMonitoringSchedule': {
    MonitoringScheduleName: 'required',
  },
  'SageMaker:deleteNotebookInstance': {
    NotebookInstanceName: 'required',
  },
  'SageMaker:deleteNotebookInstanceLifecycleConfig': {
    NotebookInstanceLifecycleConfigName: 'required',
  },
  'SageMaker:deleteTags': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'SageMaker:deleteTrial': {
    TrialName: 'required',
  },
  'SageMaker:deleteTrialComponent': {
    TrialComponentName: 'required',
  },
  'SageMaker:deleteUserProfile': {
    DomainId: 'required',
    UserProfileName: 'required',
  },
  'SageMaker:deleteWorkteam': {
    WorkteamName: 'required',
  },
  'SageMaker:describeAlgorithm': {
    AlgorithmName: 'required',
  },
  'SageMaker:describeApp': {
    DomainId: 'required',
    UserProfileName: 'required',
    AppType: 'required',
    AppName: 'required',
  },
  'SageMaker:describeAutoMLJob': {
    AutoMLJobName: 'required',
  },
  'SageMaker:describeCodeRepository': {
    CodeRepositoryName: 'required',
  },
  'SageMaker:describeCompilationJob': {
    CompilationJobName: 'required',
  },
  'SageMaker:describeDomain': {
    DomainId: 'required',
  },
  'SageMaker:describeEndpoint': {
    EndpointName: 'required',
  },
  'SageMaker:describeEndpointConfig': {
    EndpointConfigName: 'required',
  },
  'SageMaker:describeExperiment': {
    ExperimentName: 'required',
  },
  'SageMaker:describeFlowDefinition': {
    FlowDefinitionName: 'required',
  },
  'SageMaker:describeHumanTaskUi': {
    HumanTaskUiName: 'required',
  },
  'SageMaker:describeHyperParameterTuningJob': {
    HyperParameterTuningJobName: 'required',
  },
  'SageMaker:describeLabelingJob': {
    LabelingJobName: 'required',
  },
  'SageMaker:describeModel': {
    ModelName: 'required',
  },
  'SageMaker:describeModelPackage': {
    ModelPackageName: 'required',
  },
  'SageMaker:describeMonitoringSchedule': {
    MonitoringScheduleName: 'required',
  },
  'SageMaker:describeNotebookInstance': {
    NotebookInstanceName: 'required',
  },
  'SageMaker:describeNotebookInstanceLifecycleConfig': {
    NotebookInstanceLifecycleConfigName: 'required',
  },
  'SageMaker:describeProcessingJob': {
    ProcessingJobName: 'required',
  },
  'SageMaker:describeSubscribedWorkteam': {
    WorkteamArn: 'required',
  },
  'SageMaker:describeTrainingJob': {
    TrainingJobName: 'required',
  },
  'SageMaker:describeTransformJob': {
    TransformJobName: 'required',
  },
  'SageMaker:describeTrial': {
    TrialName: 'required',
  },
  'SageMaker:describeTrialComponent': {
    TrialComponentName: 'required',
  },
  'SageMaker:describeUserProfile': {
    DomainId: 'required',
    UserProfileName: 'required',
  },
  'SageMaker:describeWorkteam': {
    WorkteamName: 'required',
  },
  'SageMaker:disassociateTrialComponent': {
    TrialComponentName: 'required',
    TrialName: 'required',
  },
  'SageMaker:getSearchSuggestions': {
    Resource: 'required',
    SuggestionQuery: {
      PropertyNameQuery: '<optional>',
    },
  },
  'SageMaker:listAlgorithms': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listApps': {
    SortOrder: '<optional>',
    SortBy: '<optional>',
    DomainIdEquals: ['<optional1>', '<optional2>'],
    UserProfileNameEquals: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listAutoMLJobs': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    StatusEquals: ['<optional1>', '<optional2>'],
    SortOrder: '<optional>',
    SortBy: '<optional>',
  },
  'SageMaker:listCandidatesForAutoMLJob': {
    AutoMLJobName: 'required',
    StatusEquals: ['<optional1>', '<optional2>'],
    CandidateNameEquals: ['<optional1>', '<optional2>'],
    SortOrder: '<optional>',
    SortBy: '<optional>',
  },
  'SageMaker:listCodeRepositories': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listCompilationJobs': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    StatusEquals: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listDomains': {},
  'SageMaker:listEndpointConfigs': {
    SortBy: '<optional>',
    SortOrder: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    CreationTimeBefore: '<optional>',
    CreationTimeAfter: '<optional>',
  },
  'SageMaker:listEndpoints': {
    SortBy: '<optional>',
    SortOrder: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    CreationTimeBefore: '<optional>',
    CreationTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    StatusEquals: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listExperiments': {
    CreatedAfter: '<optional>',
    CreatedBefore: '<optional>',
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listFlowDefinitions': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listHumanTaskUis': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listHyperParameterTuningJobs': {
    SortBy: '<optional>',
    SortOrder: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    StatusEquals: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listLabelingJobs': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
    StatusEquals: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listLabelingJobsForWorkteam': {
    WorkteamArn: 'required',
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    JobReferenceCodeContains: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listModelPackages': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listModels': {
    SortBy: '<optional>',
    SortOrder: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    CreationTimeBefore: '<optional>',
    CreationTimeAfter: '<optional>',
  },
  'SageMaker:listMonitoringExecutions': {
    MonitoringScheduleName: '<optional>',
    EndpointName: '<optional>',
    SortBy: '<optional>',
    SortOrder: '<optional>',
    ScheduledTimeBefore: '<optional>',
    ScheduledTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    CreationTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    StatusEquals: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listMonitoringSchedules': {
    EndpointName: '<optional>',
    SortBy: '<optional>',
    SortOrder: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    CreationTimeBefore: '<optional>',
    CreationTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    StatusEquals: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listNotebookInstanceLifecycleConfigs': {
    SortBy: '<optional>',
    SortOrder: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    CreationTimeBefore: '<optional>',
    CreationTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
  },
  'SageMaker:listNotebookInstances': {
    SortBy: '<optional>',
    SortOrder: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    CreationTimeBefore: '<optional>',
    CreationTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    StatusEquals: ['<optional1>', '<optional2>'],
    NotebookInstanceLifecycleConfigNameContains: ['<optional1>', '<optional2>'],
    DefaultCodeRepositoryContains: ['<optional1>', '<optional2>'],
    AdditionalCodeRepositoryEquals: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listProcessingJobs': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    StatusEquals: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listSubscribedWorkteams': {
    NameContains: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listTags': {
    ResourceArn: 'required',
  },
  'SageMaker:listTrainingJobs': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    StatusEquals: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listTrainingJobsForHyperParameterTuningJob': {
    HyperParameterTuningJobName: 'required',
    StatusEquals: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listTransformJobs': {
    CreationTimeAfter: '<optional>',
    CreationTimeBefore: '<optional>',
    LastModifiedTimeAfter: '<optional>',
    LastModifiedTimeBefore: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
    StatusEquals: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listTrialComponents': {
    SourceArn: '<optional>',
    CreatedAfter: '<optional>',
    CreatedBefore: '<optional>',
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listTrials': {
    ExperimentName: '<optional>',
    CreatedAfter: '<optional>',
    CreatedBefore: '<optional>',
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:listUserProfiles': {
    SortOrder: '<optional>',
    SortBy: '<optional>',
    DomainIdEquals: ['<optional1>', '<optional2>'],
    UserProfileNameContains: ['<optional1>', '<optional2>'],
  },
  'SageMaker:listWorkteams': {
    SortBy: '<optional>',
    SortOrder: '<optional>',
    NameContains: ['<optional1>', '<optional2>'],
  },
  'SageMaker:renderUiTemplate': {
    UiTemplate: {
      Content: 'required',
    },
    Task: {
      Input: 'required',
    },
    RoleArn: 'required',
  },
  'SageMaker:search': {
    Resource: 'required',
    SearchExpression: {
      Filters: [
        {
          Operator: '<optional>',
          Value: '<optional>',
        },
      ],
      NestedFilters: [
        {
          Filters: [
            {
              Operator: '<optional>',
              Value: '<optional>',
            },
          ],
        },
      ],
      SubExpressions: ['<optional1>', '<optional2>'],
      Operator: '<optional>',
    },
    SortBy: '<optional>',
    SortOrder: '<optional>',
  },
  'SageMaker:startMonitoringSchedule': {
    MonitoringScheduleName: 'required',
  },
  'SageMaker:startNotebookInstance': {
    NotebookInstanceName: 'required',
  },
  'SageMaker:stopAutoMLJob': {
    AutoMLJobName: 'required',
  },
  'SageMaker:stopCompilationJob': {
    CompilationJobName: 'required',
  },
  'SageMaker:stopHyperParameterTuningJob': {
    HyperParameterTuningJobName: 'required',
  },
  'SageMaker:stopLabelingJob': {
    LabelingJobName: 'required',
  },
  'SageMaker:stopMonitoringSchedule': {
    MonitoringScheduleName: 'required',
  },
  'SageMaker:stopNotebookInstance': {
    NotebookInstanceName: 'required',
  },
  'SageMaker:stopProcessingJob': {
    ProcessingJobName: 'required',
  },
  'SageMaker:stopTrainingJob': {
    TrainingJobName: 'required',
  },
  'SageMaker:stopTransformJob': {
    TransformJobName: 'required',
  },
  'SageMaker:updateCodeRepository': {
    CodeRepositoryName: 'required',
    GitConfig: {
      SecretArn: '<optional>',
    },
  },
  'SageMaker:updateDomain': {
    DomainId: 'required',
    DefaultUserSettings: [
      {
        ExecutionRole: '<optional>',
        SecurityGroups: ['<optional1>', '<optional2>'],
        SharingSettings: [
          {
            NotebookOutputOption: '<optional>',
            S3OutputPath: '<optional>',
            S3KmsKeyId: '<optional>',
          },
        ],
        JupyterServerAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
        KernelGatewayAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
        TensorBoardAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
      },
    ],
  },
  'SageMaker:updateEndpoint': {
    EndpointName: 'required',
    EndpointConfigName: 'required',
  },
  'SageMaker:updateEndpointWeightsAndCapacities': {
    EndpointName: 'required',
    DesiredWeightsAndCapacities: [
      {
        DesiredWeight: '<optional>',
        DesiredInstanceCount: '<optional>',
      },
    ],
  },
  'SageMaker:updateExperiment': {
    ExperimentName: 'required',
    DisplayName: '<optional>',
    Description: '<optional>',
  },
  'SageMaker:updateMonitoringSchedule': {
    MonitoringScheduleName: 'required',
    MonitoringScheduleConfig: {
      ScheduleConfig: '<optional>',
      MonitoringJobDefinition: {
        BaselineConfig: {
          ConstraintsResource: {
            S3Uri: '<optional>',
          },
          StatisticsResource: {
            S3Uri: '<optional>',
          },
        },
        StoppingCondition: '<optional>',
        Environment: '<optional>',
        NetworkConfig: {
          EnableNetworkIsolation: '<optional>',
          VpcConfig: '<optional>',
        },
        MonitoringInputs: [
          {
            EndpointInput: {
              S3InputMode: '<optional>',
              S3DataDistributionType: '<optional>',
            },
          },
        ],
        MonitoringOutputConfig: {
          KmsKeyId: '<optional>',
          MonitoringOutputs: [
            {
              S3Output: {
                S3UploadMode: '<optional>',
              },
            },
          ],
        },
        MonitoringResources: [
          {
            ClusterConfig: {
              VolumeKmsKeyId: '<optional>',
            },
          },
        ],
        MonitoringAppSpecification: {
          ContainerEntrypoint: '<optional>',
          ContainerArguments: ['<optional1>', '<optional2>'],
          RecordPreprocessorSourceUri: '<optional>',
          PostAnalyticsProcessorSourceUri: '<optional>',
        },
      },
    },
  },
  'SageMaker:updateNotebookInstance': {
    NotebookInstanceName: 'required',
    InstanceType: '<optional>',
    RoleArn: '<optional>',
    LifecycleConfigName: '<optional>',
    DisassociateLifecycleConfig: '<optional>',
    VolumeSizeInGB: '<optional>',
    DefaultCodeRepository: '<optional>',
    AdditionalCodeRepositories: ['<optional1>', '<optional2>'],
    AcceleratorTypes: ['<optional1>', '<optional2>'],
    DisassociateAcceleratorTypes: ['<optional1>', '<optional2>'],
    DisassociateDefaultCodeRepository: '<optional>',
    DisassociateAdditionalCodeRepositories: ['<optional1>', '<optional2>'],
    RootAccess: '<optional>',
  },
  'SageMaker:updateNotebookInstanceLifecycleConfig': {
    NotebookInstanceLifecycleConfigName: 'required',
    OnCreate: {
      Content: '<optional>',
    },
    OnStart: {
      Content: '<optional>',
    },
  },
  'SageMaker:updateTrial': {
    TrialName: 'required',
    DisplayName: '<optional>',
  },
  'SageMaker:updateTrialComponent': {
    TrialComponentName: 'required',
    DisplayName: '<optional>',
    Status: {
      PrimaryStatus: '<optional>',
      Message: '<optional>',
    },
    StartTime: '<optional>',
    EndTime: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
    ParametersToRemove: '<optional>',
    InputArtifacts: ['<optional1>', '<optional2>'],
    InputArtifactsToRemove: '<optional>',
    OutputArtifacts: ['<optional1>', '<optional2>'],
    OutputArtifactsToRemove: '<optional>',
  },
  'SageMaker:updateUserProfile': {
    DomainId: 'required',
    UserProfileName: 'required',
    UserSettings: [
      {
        ExecutionRole: '<optional>',
        SecurityGroups: ['<optional1>', '<optional2>'],
        SharingSettings: [
          {
            NotebookOutputOption: '<optional>',
            S3OutputPath: '<optional>',
            S3KmsKeyId: '<optional>',
          },
        ],
        JupyterServerAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
        KernelGatewayAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
        TensorBoardAppSettings: [
          {
            DefaultResourceSpec: {
              EnvironmentArn: '<optional>',
              InstanceType: '<optional>',
            },
          },
        ],
      },
    ],
  },
  'SageMaker:updateWorkteam': {
    WorkteamName: 'required',
    MemberDefinitions: [
      {
        CognitoMemberDefinition: '<optional>',
      },
    ],
    Description: '<optional>',
    NotificationConfiguration: {
      NotificationTopicArn: '<optional>',
    },
  },
  'SageMakerRuntime:invokeEndpoint': {
    EndpointName: 'required',
    Body: 'required',
    ContentType: '<optional>',
    Accept: '<optional>',
    CustomAttributes: ['<optional1>', '<optional2>'],
  },
  'SavingsPlans:createSavingsPlan': {
    savingsPlanOfferingId: 'required',
    commitment: 'required',
    upfrontPaymentAmount: '<optional>',
    clientToken: '<optional>',
    tags: ['<optional1>', '<optional2>'],
  },
  'SavingsPlans:describeSavingsPlanRates': {
    savingsPlanId: 'required',
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SavingsPlans:describeSavingsPlans': {
    savingsPlanArns: ['<optional1>', '<optional2>'],
    savingsPlanIds: ['<optional1>', '<optional2>'],
    states: ['<optional1>', '<optional2>'],
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SavingsPlans:describeSavingsPlansOfferingRates': {
    savingsPlanOfferingIds: ['<optional1>', '<optional2>'],
    savingsPlanPaymentOptions: ['<optional1>', '<optional2>'],
    savingsPlanTypes: ['<optional1>', '<optional2>'],
    products: ['<optional1>', '<optional2>'],
    serviceCodes: ['<optional1>', '<optional2>'],
    usageTypes: ['<optional1>', '<optional2>'],
    operations: ['<optional1>', '<optional2>'],
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SavingsPlans:describeSavingsPlansOfferings': {
    offeringIds: ['<optional1>', '<optional2>'],
    paymentOptions: ['<optional1>', '<optional2>'],
    productType: '<optional>',
    planTypes: ['<optional1>', '<optional2>'],
    durations: ['<optional1>', '<optional2>'],
    currencies: ['<optional1>', '<optional2>'],
    descriptions: ['<optional1>', '<optional2>'],
    serviceCodes: ['<optional1>', '<optional2>'],
    usageTypes: ['<optional1>', '<optional2>'],
    operations: ['<optional1>', '<optional2>'],
    filters: [
      {
        name: '<optional>',
        values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SavingsPlans:listTagsForResource': {
    resourceArn: 'required',
  },
  'SavingsPlans:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'SavingsPlans:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'Schemas:createDiscoverer': {
    SourceArn: 'required',
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'Schemas:createRegistry': {
    RegistryName: 'required',
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'Schemas:createSchema': {
    RegistryName: 'required',
    SchemaName: 'required',
    Type: 'required',
    Content: 'required',
    Description: '<optional>',
    Tags: ['<optional1>', '<optional2>'],
  },
  'Schemas:deleteDiscoverer': {
    DiscovererId: 'required',
  },
  'Schemas:deleteRegistry': {
    RegistryName: 'required',
  },
  'Schemas:deleteSchema': {
    RegistryName: 'required',
    SchemaName: 'required',
  },
  'Schemas:deleteSchemaVersion': {
    SchemaVersion: 'required',
    RegistryName: 'required',
    SchemaName: 'required',
  },
  'Schemas:describeCodeBinding': {
    RegistryName: 'required',
    SchemaName: 'required',
    Language: 'required',
    SchemaVersion: '<optional>',
  },
  'Schemas:describeDiscoverer': {
    DiscovererId: 'required',
  },
  'Schemas:describeRegistry': {
    RegistryName: 'required',
  },
  'Schemas:describeSchema': {
    RegistryName: 'required',
    SchemaName: 'required',
    SchemaVersion: '<optional>',
  },
  'Schemas:getCodeBindingSource': {
    RegistryName: 'required',
    SchemaName: 'required',
    Language: 'required',
    SchemaVersion: '<optional>',
  },
  'Schemas:getDiscoveredSchema': {
    Type: 'required',
    Events: ['required1', 'required2'],
  },
  'Schemas:listDiscoverers': {
    DiscovererIdPrefix: '<optional>',
    Limit: '<optional>',
    SourceArnPrefix: '<optional>',
  },
  'Schemas:listRegistries': {
    Limit: '<optional>',
    RegistryNamePrefix: '<optional>',
    Scope: '<optional>',
  },
  'Schemas:listSchemaVersions': {
    RegistryName: 'required',
    SchemaName: 'required',
    Limit: '<optional>',
  },
  'Schemas:listSchemas': {
    RegistryName: 'required',
    Limit: '<optional>',
    SchemaNamePrefix: '<optional>',
  },
  'Schemas:listTagsForResource': {
    ResourceArn: 'required',
  },
  'Schemas:lockServiceLinkedRole': {
    Timeout: 'required',
    RoleArn: 'required',
  },
  'Schemas:putCodeBinding': {
    RegistryName: 'required',
    SchemaName: 'required',
    Language: 'required',
    SchemaVersion: '<optional>',
  },
  'Schemas:searchSchemas': {
    RegistryName: 'required',
    Keywords: ['required1', 'required2'],
    Limit: '<optional>',
  },
  'Schemas:startDiscoverer': {
    DiscovererId: 'required',
  },
  'Schemas:stopDiscoverer': {
    DiscovererId: 'required',
  },
  'Schemas:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'Schemas:unlockServiceLinkedRole': {
    RoleArn: 'required',
  },
  'Schemas:untagResource': {
    TagKeys: ['required1', 'required2'],
    ResourceArn: 'required',
  },
  'Schemas:updateDiscoverer': {
    DiscovererId: 'required',
    Description: '<optional>',
  },
  'Schemas:updateRegistry': {
    RegistryName: 'required',
    Description: '<optional>',
  },
  'Schemas:updateSchema': {
    RegistryName: 'required',
    SchemaName: 'required',
    ClientTokenId: '<optional>',
    Content: '<optional>',
    Description: '<optional>',
    Type: '<optional>',
  },
  'SecretsManager:cancelRotateSecret': {
    SecretId: 'required',
  },
  'SecretsManager:createSecret': {
    Name: 'required',
    ClientRequestToken: '<optional>',
    Description: '<optional>',
    KmsKeyId: '<optional>',
    SecretBinary: '<optional>',
    SecretString: '<optional>',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'SecretsManager:deleteResourcePolicy': {
    SecretId: 'required',
  },
  'SecretsManager:deleteSecret': {
    SecretId: 'required',
    RecoveryWindowInDays: ['<optional1>', '<optional2>'],
    ForceDeleteWithoutRecovery: '<optional>',
  },
  'SecretsManager:describeSecret': {
    SecretId: 'required',
  },
  'SecretsManager:getRandomPassword': {
    PasswordLength: '<optional>',
    ExcludeCharacters: ['<optional1>', '<optional2>'],
    ExcludeNumbers: ['<optional1>', '<optional2>'],
    ExcludePunctuation: '<optional>',
    ExcludeUppercase: '<optional>',
    ExcludeLowercase: '<optional>',
    IncludeSpace: '<optional>',
    RequireEachIncludedType: '<optional>',
  },
  'SecretsManager:getResourcePolicy': {
    SecretId: 'required',
  },
  'SecretsManager:getSecretValue': {
    SecretId: 'required',
    VersionId: '<optional>',
    VersionStage: '<optional>',
  },
  'SecretsManager:listSecretVersionIds': {
    SecretId: 'required',
    IncludeDeprecated: '<optional>',
  },
  'SecretsManager:listSecrets': {},
  'SecretsManager:putResourcePolicy': {
    SecretId: 'required',
    ResourcePolicy: 'required',
  },
  'SecretsManager:putSecretValue': {
    SecretId: 'required',
    ClientRequestToken: '<optional>',
    SecretBinary: '<optional>',
    SecretString: '<optional>',
    VersionStages: ['<optional1>', '<optional2>'],
  },
  'SecretsManager:restoreSecret': {
    SecretId: 'required',
  },
  'SecretsManager:rotateSecret': {
    SecretId: 'required',
    ClientRequestToken: '<optional>',
    RotationLambdaARN: '<optional>',
    RotationRules: [
      {
        AutomaticallyAfterDays: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'SecretsManager:tagResource': {
    SecretId: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'SecretsManager:untagResource': {
    SecretId: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'SecretsManager:updateSecret': {
    SecretId: 'required',
    ClientRequestToken: '<optional>',
    Description: '<optional>',
    KmsKeyId: '<optional>',
    SecretBinary: '<optional>',
    SecretString: '<optional>',
  },
  'SecretsManager:updateSecretVersionStage': {
    SecretId: 'required',
    VersionStage: 'required',
    RemoveFromVersionId: '<optional>',
    MoveToVersionId: '<optional>',
  },
  'SecurityHub:acceptInvitation': {
    MasterId: '<optional>',
    InvitationId: '<optional>',
  },
  'SecurityHub:batchDisableStandards': {
    StandardsSubscriptionArns: ['required1', 'required2'],
  },
  'SecurityHub:batchEnableStandards': {
    StandardsSubscriptionRequests: [
      {
        StandardsInput: '<optional>',
      },
    ],
  },
  'SecurityHub:batchImportFindings': {
    Findings: [
      {
        FirstObservedAt: '<optional>',
        LastObservedAt: '<optional>',
        Confidence: '<optional>',
        Criticality: '<optional>',
        Remediation: {
          Recommendation: {
            Text: '<optional>',
            Url: '<optional>',
          },
        },
        SourceUrl: '<optional>',
        ProductFields: ['<optional1>', '<optional2>'],
        UserDefinedFields: ['<optional1>', '<optional2>'],
        Malware: {
          Type: '<optional>',
          Path: '<optional>',
          State: '<optional>',
        },
        Network: {
          Direction: '<optional>',
          Protocol: '<optional>',
          SourceIpV4: '<optional>',
          SourceIpV6: '<optional>',
          SourcePort: '<optional>',
          SourceDomain: '<optional>',
          SourceMac: '<optional>',
          DestinationIpV4: '<optional>',
          DestinationIpV6: '<optional>',
          DestinationPort: '<optional>',
          DestinationDomain: '<optional>',
        },
        Process: {
          Name: '<optional>',
          Path: '<optional>',
          Pid: '<optional>',
          ParentPid: '<optional>',
          LaunchedAt: '<optional>',
          TerminatedAt: '<optional>',
        },
        ThreatIntelIndicators: [
          {
            Type: '<optional>',
            Value: '<optional>',
            Category: '<optional>',
            LastObservedAt: '<optional>',
            Source: '<optional>',
            SourceUrl: '<optional>',
          },
        ],
        Compliance: {
          Status: '<optional>',
        },
        VerificationState: '<optional>',
        WorkflowState: '<optional>',
        RecordState: '<optional>',
        RelatedFindings: ['<optional1>', '<optional2>'],
        Note: '<optional>',
        Severity: {
          Product: '<optional>',
        },
        Resources: [
          {
            Partition: '<optional>',
            Region: '<optional>',
            Tags: ['<optional1>', '<optional2>'],
            Details: [
              {
                AwsEc2Instance: {
                  Type: '<optional>',
                  ImageId: '<optional>',
                  IpV4Addresses: ['<optional1>', '<optional2>'],
                  IpV6Addresses: ['<optional1>', '<optional2>'],
                  KeyName: '<optional>',
                  IamInstanceProfileArn: '<optional>',
                  VpcId: '<optional>',
                  SubnetId: '<optional>',
                  LaunchedAt: '<optional>',
                },
                AwsS3Bucket: {
                  OwnerId: '<optional>',
                  OwnerName: '<optional>',
                },
                AwsIamAccessKey: {
                  UserName: '<optional>',
                  Status: '<optional>',
                  CreatedAt: '<optional>',
                },
                Container: {
                  Name: '<optional>',
                  ImageId: '<optional>',
                  ImageName: '<optional>',
                  LaunchedAt: '<optional>',
                },
                Other: '<optional>',
              },
            ],
          },
        ],
      },
    ],
  },
  'SecurityHub:createActionTarget': {
    Name: 'required',
    Description: 'required',
    Id: 'required',
  },
  'SecurityHub:createInsight': {
    Name: 'required',
    GroupByAttribute: 'required',
    Filters: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:createMembers': {
    AccountDetails: [
      {
        AccountId: '<optional>',
        Email: '<optional>',
      },
    ],
  },
  'SecurityHub:declineInvitations': {
    AccountIds: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:deleteActionTarget': {
    ActionTargetArn: 'required',
  },
  'SecurityHub:deleteInsight': {
    InsightArn: 'required',
  },
  'SecurityHub:deleteInvitations': {
    AccountIds: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:deleteMembers': {
    AccountIds: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:describeActionTargets': {
    ActionTargetArns: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:describeHub': {
    HubArn: '<optional>',
  },
  'SecurityHub:describeProducts': {},
  'SecurityHub:disableImportFindingsForProduct': {
    ProductSubscriptionArn: 'required',
  },
  'SecurityHub:disableSecurityHub': {},
  'SecurityHub:disassociateFromMasterAccount': {},
  'SecurityHub:disassociateMembers': {
    AccountIds: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:enableImportFindingsForProduct': {
    ProductArn: 'required',
  },
  'SecurityHub:enableSecurityHub': {
    Tags: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:getEnabledStandards': {
    StandardsSubscriptionArns: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:getFindings': {
    Filters: [
      {
        ProductArn: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        AwsAccountId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Id: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        GeneratorId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Type: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        FirstObservedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        LastObservedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        CreatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        UpdatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        SeverityProduct: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        SeverityNormalized: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        SeverityLabel: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Confidence: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        Criticality: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        Title: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Description: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        RecommendationText: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        SourceUrl: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ProductFields: [
          {
            Key: '<optional>',
            Value: '<optional>',
            Comparison: '<optional>',
          },
        ],
        ProductName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        CompanyName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        UserDefinedFields: [
          {
            Key: '<optional>',
            Value: '<optional>',
            Comparison: '<optional>',
          },
        ],
        MalwareName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        MalwareType: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        MalwarePath: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        MalwareState: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkDirection: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkProtocol: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkSourceIpV4: {
          Cidr: '<optional>',
        },
        NetworkSourceIpV6: {
          Cidr: '<optional>',
        },
        NetworkSourcePort: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        NetworkSourceDomain: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkSourceMac: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkDestinationIpV4: {
          Cidr: '<optional>',
        },
        NetworkDestinationIpV6: {
          Cidr: '<optional>',
        },
        NetworkDestinationPort: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        NetworkDestinationDomain: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ProcessName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ProcessPath: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ProcessPid: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        ProcessParentPid: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        ProcessLaunchedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ProcessTerminatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ThreatIntelIndicatorType: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ThreatIntelIndicatorValue: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ThreatIntelIndicatorCategory: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ThreatIntelIndicatorLastObservedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ThreatIntelIndicatorSource: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ThreatIntelIndicatorSourceUrl: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceType: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourcePartition: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceRegion: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceTags: [
          {
            Key: '<optional>',
            Value: '<optional>',
            Comparison: '<optional>',
          },
        ],
        ResourceAwsEc2InstanceType: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceImageId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceIpV4Addresses: [
          {
            Cidr: '<optional>',
          },
        ],
        ResourceAwsEc2InstanceIpV6Addresses: [
          {
            Cidr: '<optional>',
          },
        ],
        ResourceAwsEc2InstanceKeyName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceIamInstanceProfileArn: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceVpcId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceSubnetId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceLaunchedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ResourceAwsS3BucketOwnerId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsS3BucketOwnerName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsIamAccessKeyUserName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsIamAccessKeyStatus: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsIamAccessKeyCreatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ResourceContainerName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceContainerImageId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceContainerImageName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceContainerLaunchedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ResourceDetailsOther: {
          Key: '<optional>',
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ComplianceStatus: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        VerificationState: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        WorkflowState: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        RecordState: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        RelatedFindingsProductArn: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        RelatedFindingsId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NoteText: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NoteUpdatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        NoteUpdatedBy: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Keyword: {
          Value: '<optional>',
        },
      },
    ],
    SortCriteria: [
      {
        Field: '<optional>',
        SortOrder: '<optional>',
      },
    ],
  },
  'SecurityHub:getInsightResults': {
    InsightArn: 'required',
  },
  'SecurityHub:getInsights': {
    InsightArns: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:getInvitationsCount': {},
  'SecurityHub:getMasterAccount': {},
  'SecurityHub:getMembers': {
    AccountIds: ['required1', 'required2'],
  },
  'SecurityHub:inviteMembers': {
    AccountIds: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:listEnabledProductsForImport': {},
  'SecurityHub:listInvitations': {},
  'SecurityHub:listMembers': {
    OnlyAssociated: '<optional>',
  },
  'SecurityHub:listTagsForResource': {
    ResourceArn: 'required',
  },
  'SecurityHub:tagResource': {
    ResourceArn: 'required',
    Tags: ['required1', 'required2'],
  },
  'SecurityHub:untagResource': {
    ResourceArn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'SecurityHub:updateActionTarget': {
    ActionTargetArn: 'required',
    Name: '<optional>',
    Description: '<optional>',
  },
  'SecurityHub:updateFindings': {
    Note: '<optional>',
    RecordState: '<optional>',
    Filters: ['<optional1>', '<optional2>'],
  },
  'SecurityHub:updateInsight': {
    InsightArn: 'required',
    Name: '<optional>',
    Filters: [
      {
        ProductArn: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        AwsAccountId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Id: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        GeneratorId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Type: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        FirstObservedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        LastObservedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        CreatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        UpdatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        SeverityProduct: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        SeverityNormalized: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        SeverityLabel: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Confidence: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        Criticality: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        Title: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Description: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        RecommendationText: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        SourceUrl: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ProductFields: [
          {
            Key: '<optional>',
            Value: '<optional>',
            Comparison: '<optional>',
          },
        ],
        ProductName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        CompanyName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        UserDefinedFields: [
          {
            Key: '<optional>',
            Value: '<optional>',
            Comparison: '<optional>',
          },
        ],
        MalwareName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        MalwareType: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        MalwarePath: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        MalwareState: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkDirection: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkProtocol: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkSourceIpV4: {
          Cidr: '<optional>',
        },
        NetworkSourceIpV6: {
          Cidr: '<optional>',
        },
        NetworkSourcePort: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        NetworkSourceDomain: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkSourceMac: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NetworkDestinationIpV4: {
          Cidr: '<optional>',
        },
        NetworkDestinationIpV6: {
          Cidr: '<optional>',
        },
        NetworkDestinationPort: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        NetworkDestinationDomain: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ProcessName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ProcessPath: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ProcessPid: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        ProcessParentPid: {
          Gte: '<optional>',
          Lte: '<optional>',
          Eq: '<optional>',
        },
        ProcessLaunchedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ProcessTerminatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ThreatIntelIndicatorType: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ThreatIntelIndicatorValue: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ThreatIntelIndicatorCategory: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ThreatIntelIndicatorLastObservedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ThreatIntelIndicatorSource: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ThreatIntelIndicatorSourceUrl: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceType: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourcePartition: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceRegion: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceTags: [
          {
            Key: '<optional>',
            Value: '<optional>',
            Comparison: '<optional>',
          },
        ],
        ResourceAwsEc2InstanceType: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceImageId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceIpV4Addresses: [
          {
            Cidr: '<optional>',
          },
        ],
        ResourceAwsEc2InstanceIpV6Addresses: [
          {
            Cidr: '<optional>',
          },
        ],
        ResourceAwsEc2InstanceKeyName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceIamInstanceProfileArn: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceVpcId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceSubnetId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsEc2InstanceLaunchedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ResourceAwsS3BucketOwnerId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsS3BucketOwnerName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsIamAccessKeyUserName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsIamAccessKeyStatus: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceAwsIamAccessKeyCreatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ResourceContainerName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceContainerImageId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceContainerImageName: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ResourceContainerLaunchedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        ResourceDetailsOther: {
          Key: '<optional>',
          Value: '<optional>',
          Comparison: '<optional>',
        },
        ComplianceStatus: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        VerificationState: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        WorkflowState: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        RecordState: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        RelatedFindingsProductArn: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        RelatedFindingsId: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NoteText: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        NoteUpdatedAt: {
          Start: '<optional>',
          End: '<optional>',
          DateRange: {
            Value: '<optional>',
            Unit: '<optional>',
          },
        },
        NoteUpdatedBy: {
          Value: '<optional>',
          Comparison: '<optional>',
        },
        Keyword: {
          Value: '<optional>',
        },
      },
    ],
    GroupByAttribute: '<optional>',
  },
  'ServerlessApplicationRepository:createApplication': {
    Description: 'required',
    Name: 'required',
    Author: 'required',
    HomePageUrl: '<optional>',
    Labels: ['<optional1>', '<optional2>'],
    LicenseBody: '<optional>',
    LicenseUrl: '<optional>',
    ReadmeBody: '<optional>',
    ReadmeUrl: '<optional>',
    SemanticVersion: '<optional>',
    SourceCodeArchiveUrl: '<optional>',
    SourceCodeUrl: '<optional>',
    SpdxLicenseId: '<optional>',
    TemplateBody: '<optional>',
    TemplateUrl: '<optional>',
  },
  'ServerlessApplicationRepository:createApplicationVersion': {
    ApplicationId: 'required',
    SemanticVersion: 'required',
    SourceCodeArchiveUrl: '<optional>',
    SourceCodeUrl: '<optional>',
    TemplateBody: '<optional>',
    TemplateUrl: '<optional>',
  },
  'ServerlessApplicationRepository:createCloudFormationChangeSet': {
    ApplicationId: 'required',
    StackName: 'required',
    ParameterOverrides: ['<optional1>', '<optional2>'],
    RollbackConfiguration: {
      MonitoringTimeInMinutes: ['<optional1>', '<optional2>'],
      RollbackTriggers: ['<optional1>', '<optional2>'],
    },
    Tags: ['<optional1>', '<optional2>'],
    Capabilities: ['<optional1>', '<optional2>'],
    ChangeSetName: '<optional>',
    ClientToken: '<optional>',
    Description: '<optional>',
    NotificationArns: ['<optional1>', '<optional2>'],
    ResourceTypes: ['<optional1>', '<optional2>'],
    SemanticVersion: '<optional>',
    TemplateId: '<optional>',
  },
  'ServerlessApplicationRepository:createCloudFormationTemplate': {
    ApplicationId: 'required',
    SemanticVersion: '<optional>',
  },
  'ServerlessApplicationRepository:deleteApplication': {
    ApplicationId: 'required',
  },
  'ServerlessApplicationRepository:getApplication': {
    ApplicationId: 'required',
    SemanticVersion: '<optional>',
  },
  'ServerlessApplicationRepository:getApplicationPolicy': {
    ApplicationId: 'required',
  },
  'ServerlessApplicationRepository:getCloudFormationTemplate': {
    ApplicationId: 'required',
    TemplateId: 'required',
  },
  'ServerlessApplicationRepository:listApplicationDependencies': {
    ApplicationId: 'required',
    SemanticVersion: '<optional>',
  },
  'ServerlessApplicationRepository:listApplicationVersions': {
    ApplicationId: 'required',
  },
  'ServerlessApplicationRepository:listApplications': {},
  'ServerlessApplicationRepository:putApplicationPolicy': {
    ApplicationId: 'required',
    Statements: [
      {
        StatementId: '<optional>',
      },
    ],
  },
  'ServerlessApplicationRepository:updateApplication': {
    ApplicationId: 'required',
    Author: '<optional>',
    Description: '<optional>',
    HomePageUrl: '<optional>',
    Labels: ['<optional1>', '<optional2>'],
    ReadmeBody: '<optional>',
    ReadmeUrl: '<optional>',
  },
  'ServiceCatalog:acceptPortfolioShare': {
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
    PortfolioShareType: '<optional>',
  },
  'ServiceCatalog:associateBudgetWithResource': {
    BudgetName: 'required',
    ResourceId: 'required',
  },
  'ServiceCatalog:associatePrincipalWithPortfolio': {
    PortfolioId: 'required',
    PrincipalARN: 'required',
    PrincipalType: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:associateProductWithPortfolio': {
    ProductId: 'required',
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
    SourcePortfolioId: '<optional>',
  },
  'ServiceCatalog:associateServiceActionWithProvisioningArtifact': {
    ProductId: 'required',
    ProvisioningArtifactId: 'required',
    ServiceActionId: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:associateTagOptionWithResource': {
    ResourceId: 'required',
    TagOptionId: 'required',
  },
  'ServiceCatalog:batchAssociateServiceActionWithProvisioningArtifact': {
    ServiceActionAssociations: [
      {
        ServiceActionId: 'required',
        ProductId: 'required',
        ProvisioningArtifactId: 'required',
      },
    ],
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:batchDisassociateServiceActionFromProvisioningArtifact': {
    ServiceActionAssociations: [
      {
        ServiceActionId: 'required',
        ProductId: 'required',
        ProvisioningArtifactId: 'required',
      },
    ],
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:copyProduct': {
    SourceProductArn: 'required',
    IdempotencyToken: 'required',
    AcceptLanguage: '<optional>',
    TargetProductId: '<optional>',
    TargetProductName: '<optional>',
    SourceProvisioningArtifactIdentifiers: ['<optional1>', '<optional2>'],
    CopyOptions: ['<optional1>', '<optional2>'],
  },
  'ServiceCatalog:createConstraint': {
    PortfolioId: 'required',
    ProductId: 'required',
    Parameters: ['required1', 'required2'],
    Type: 'required',
    IdempotencyToken: 'required',
    AcceptLanguage: '<optional>',
    Description: '<optional>',
  },
  'ServiceCatalog:createPortfolio': {
    DisplayName: 'required',
    ProviderName: 'required',
    IdempotencyToken: 'required',
    Tags: ['<optional1>', '<optional2>'],
    AcceptLanguage: '<optional>',
    Description: '<optional>',
  },
  'ServiceCatalog:createPortfolioShare': {
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
    AccountId: '<optional>',
    OrganizationNode: {
      Type: '<optional>',
      Value: '<optional>',
    },
  },
  'ServiceCatalog:createProduct': {
    Name: 'required',
    Owner: 'required',
    ProductType: 'required',
    ProvisioningArtifactParameters: [
      {
        Name: '<optional>',
        Description: '<optional>',
        Type: '<optional>',
        DisableTemplateValidation: '<optional>',
      },
    ],
    IdempotencyToken: 'required',
    Tags: ['<optional1>', '<optional2>'],
    AcceptLanguage: '<optional>',
    Description: '<optional>',
    Distributor: '<optional>',
    SupportDescription: '<optional>',
    SupportEmail: '<optional>',
    SupportUrl: '<optional>',
  },
  'ServiceCatalog:createProvisionedProductPlan': {
    PlanName: 'required',
    PlanType: 'required',
    ProductId: 'required',
    ProvisionedProductName: 'required',
    ProvisioningArtifactId: 'required',
    IdempotencyToken: 'required',
    Tags: ['<optional1>', '<optional2>'],
    AcceptLanguage: '<optional>',
    NotificationArns: ['<optional1>', '<optional2>'],
    PathId: '<optional>',
    ProvisioningParameters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        UsePreviousValue: '<optional>',
      },
    ],
  },
  'ServiceCatalog:createProvisioningArtifact': {
    ProductId: 'required',
    Parameters: [
      {
        Name: '<optional>',
        Description: '<optional>',
        Type: '<optional>',
        DisableTemplateValidation: '<optional>',
      },
    ],
    IdempotencyToken: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:createServiceAction': {
    Name: 'required',
    DefinitionType: 'required',
    Definition: 'required',
    IdempotencyToken: 'required',
    Description: '<optional>',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:createTagOption': {
    Key: 'required',
    Value: 'required',
  },
  'ServiceCatalog:deleteConstraint': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:deletePortfolio': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:deletePortfolioShare': {
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
    AccountId: '<optional>',
    OrganizationNode: {
      Type: '<optional>',
      Value: '<optional>',
    },
  },
  'ServiceCatalog:deleteProduct': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:deleteProvisionedProductPlan': {
    PlanId: 'required',
    AcceptLanguage: '<optional>',
    IgnoreErrors: ['<optional1>', '<optional2>'],
  },
  'ServiceCatalog:deleteProvisioningArtifact': {
    ProductId: 'required',
    ProvisioningArtifactId: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:deleteServiceAction': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:deleteTagOption': {
    Id: 'required',
  },
  'ServiceCatalog:describeConstraint': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describeCopyProductStatus': {
    CopyProductToken: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describePortfolio': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describePortfolioShareStatus': {
    PortfolioShareToken: 'required',
  },
  'ServiceCatalog:describeProduct': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describeProductAsAdmin': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describeProductView': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describeProvisionedProduct': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describeProvisionedProductPlan': {
    PlanId: 'required',
    AcceptLanguage: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:describeProvisioningArtifact': {
    ProvisioningArtifactId: 'required',
    ProductId: 'required',
    AcceptLanguage: '<optional>',
    Verbose: '<optional>',
  },
  'ServiceCatalog:describeProvisioningParameters': {
    ProductId: 'required',
    ProvisioningArtifactId: 'required',
    AcceptLanguage: '<optional>',
    PathId: '<optional>',
  },
  'ServiceCatalog:describeRecord': {
    Id: 'required',
    AcceptLanguage: '<optional>',
    PageToken: '<optional>',
    PageSize: '<optional>',
  },
  'ServiceCatalog:describeServiceAction': {
    Id: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describeServiceActionExecutionParameters': {
    ProvisionedProductId: 'required',
    ServiceActionId: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:describeTagOption': {
    Id: 'required',
  },
  'ServiceCatalog:disableAWSOrganizationsAccess': {},
  'ServiceCatalog:disassociateBudgetFromResource': {
    BudgetName: 'required',
    ResourceId: 'required',
  },
  'ServiceCatalog:disassociatePrincipalFromPortfolio': {
    PortfolioId: 'required',
    PrincipalARN: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:disassociateProductFromPortfolio': {
    ProductId: 'required',
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:disassociateServiceActionFromProvisioningArtifact': {
    ProductId: 'required',
    ProvisioningArtifactId: 'required',
    ServiceActionId: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:disassociateTagOptionFromResource': {
    ResourceId: 'required',
    TagOptionId: 'required',
  },
  'ServiceCatalog:enableAWSOrganizationsAccess': {},
  'ServiceCatalog:executeProvisionedProductPlan': {
    PlanId: 'required',
    IdempotencyToken: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:executeProvisionedProductServiceAction': {
    ProvisionedProductId: 'required',
    ServiceActionId: 'required',
    ExecuteToken: 'required',
    AcceptLanguage: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
  },
  'ServiceCatalog:getAWSOrganizationsAccessStatus': {},
  'ServiceCatalog:listAcceptedPortfolioShares': {
    AcceptLanguage: '<optional>',
    PageToken: '<optional>',
    PageSize: '<optional>',
    PortfolioShareType: '<optional>',
  },
  'ServiceCatalog:listBudgetsForResource': {
    ResourceId: 'required',
    AcceptLanguage: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:listConstraintsForPortfolio': {
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
    ProductId: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:listLaunchPaths': {
    ProductId: 'required',
    AcceptLanguage: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:listOrganizationPortfolioAccess': {
    PortfolioId: 'required',
    OrganizationNodeType: 'required',
    AcceptLanguage: '<optional>',
    PageToken: '<optional>',
    PageSize: '<optional>',
  },
  'ServiceCatalog:listPortfolioAccess': {
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:listPortfolios': {
    AcceptLanguage: '<optional>',
    PageToken: '<optional>',
    PageSize: '<optional>',
  },
  'ServiceCatalog:listPortfoliosForProduct': {
    ProductId: 'required',
    AcceptLanguage: '<optional>',
    PageToken: '<optional>',
    PageSize: '<optional>',
  },
  'ServiceCatalog:listPrincipalsForPortfolio': {
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:listProvisionedProductPlans': {
    AcceptLanguage: '<optional>',
    ProvisionProductId: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
    AccessLevelFilter: {
      Key: '<optional>',
      Value: '<optional>',
    },
  },
  'ServiceCatalog:listProvisioningArtifacts': {
    ProductId: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:listProvisioningArtifactsForServiceAction': {
    ServiceActionId: 'required',
    PageSize: '<optional>',
    PageToken: '<optional>',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:listRecordHistory': {
    AcceptLanguage: '<optional>',
    AccessLevelFilter: {
      Key: '<optional>',
      Value: '<optional>',
    },
    SearchFilter: {
      Key: '<optional>',
      Value: '<optional>',
    },
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:listResourcesForTagOption': {
    TagOptionId: 'required',
    ResourceType: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:listServiceActions': {
    AcceptLanguage: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:listServiceActionsForProvisioningArtifact': {
    ProductId: 'required',
    ProvisioningArtifactId: 'required',
    PageSize: '<optional>',
    PageToken: '<optional>',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:listStackInstancesForProvisionedProduct': {
    ProvisionedProductId: 'required',
    AcceptLanguage: '<optional>',
    PageToken: '<optional>',
    PageSize: '<optional>',
  },
  'ServiceCatalog:listTagOptions': {
    Filters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        Active: '<optional>',
      },
    ],
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:provisionProduct': {
    ProductId: 'required',
    ProvisioningArtifactId: 'required',
    ProvisionedProductName: 'required',
    ProvisionToken: 'required',
    Tags: ['<optional1>', '<optional2>'],
    AcceptLanguage: '<optional>',
    PathId: '<optional>',
    ProvisioningParameters: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
    ProvisioningPreferences: [
      {
        StackSetAccounts: ['<optional1>', '<optional2>'],
        StackSetRegions: ['<optional1>', '<optional2>'],
        StackSetFailureToleranceCount: '<optional>',
        StackSetFailureTolerancePercentage: '<optional>',
        StackSetMaxConcurrencyCount: '<optional>',
        StackSetMaxConcurrencyPercentage: '<optional>',
      },
    ],
    NotificationArns: ['<optional1>', '<optional2>'],
  },
  'ServiceCatalog:rejectPortfolioShare': {
    PortfolioId: 'required',
    AcceptLanguage: '<optional>',
    PortfolioShareType: '<optional>',
  },
  'ServiceCatalog:scanProvisionedProducts': {
    AcceptLanguage: '<optional>',
    AccessLevelFilter: {
      Key: '<optional>',
      Value: '<optional>',
    },
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:searchProducts': {
    AcceptLanguage: '<optional>',
    Filters: ['<optional1>', '<optional2>'],
    PageSize: '<optional>',
    SortBy: '<optional>',
    SortOrder: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:searchProductsAsAdmin': {
    AcceptLanguage: '<optional>',
    PortfolioId: '<optional>',
    Filters: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
    PageToken: '<optional>',
    PageSize: '<optional>',
    ProductSource: '<optional>',
  },
  'ServiceCatalog:searchProvisionedProducts': {
    AcceptLanguage: '<optional>',
    AccessLevelFilter: {
      Key: '<optional>',
      Value: '<optional>',
    },
    Filters: ['<optional1>', '<optional2>'],
    SortBy: '<optional>',
    SortOrder: '<optional>',
    PageSize: '<optional>',
    PageToken: '<optional>',
  },
  'ServiceCatalog:terminateProvisionedProduct': {
    TerminateToken: 'required',
    ProvisionedProductName: '<optional>',
    ProvisionedProductId: '<optional>',
    IgnoreErrors: ['<optional1>', '<optional2>'],
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:updateConstraint': {
    Id: 'required',
    AcceptLanguage: '<optional>',
    Description: '<optional>',
    Parameters: ['<optional1>', '<optional2>'],
  },
  'ServiceCatalog:updatePortfolio': {
    Id: 'required',
    AddTags: ['<optional1>', '<optional2>'],
    AcceptLanguage: '<optional>',
    DisplayName: '<optional>',
    Description: '<optional>',
    ProviderName: '<optional>',
    RemoveTags: ['<optional1>', '<optional2>'],
  },
  'ServiceCatalog:updateProduct': {
    Id: 'required',
    AddTags: ['<optional1>', '<optional2>'],
    AcceptLanguage: '<optional>',
    Name: '<optional>',
    Owner: '<optional>',
    Description: '<optional>',
    Distributor: '<optional>',
    SupportDescription: '<optional>',
    SupportEmail: '<optional>',
    SupportUrl: '<optional>',
    RemoveTags: ['<optional1>', '<optional2>'],
  },
  'ServiceCatalog:updateProvisionedProduct': {
    UpdateToken: 'required',
    Tags: ['<optional1>', '<optional2>'],
    AcceptLanguage: '<optional>',
    ProvisionedProductName: '<optional>',
    ProvisionedProductId: '<optional>',
    ProductId: '<optional>',
    ProvisioningArtifactId: '<optional>',
    PathId: '<optional>',
    ProvisioningParameters: [
      {
        Key: '<optional>',
        Value: '<optional>',
        UsePreviousValue: '<optional>',
      },
    ],
    ProvisioningPreferences: [
      {
        StackSetAccounts: ['<optional1>', '<optional2>'],
        StackSetRegions: ['<optional1>', '<optional2>'],
        StackSetFailureToleranceCount: '<optional>',
        StackSetFailureTolerancePercentage: '<optional>',
        StackSetMaxConcurrencyCount: '<optional>',
        StackSetMaxConcurrencyPercentage: '<optional>',
        StackSetOperationType: '<optional>',
      },
    ],
  },
  'ServiceCatalog:updateProvisionedProductProperties': {
    ProvisionedProductId: 'required',
    ProvisionedProductProperties: ['required1', 'required2'],
    IdempotencyToken: 'required',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:updateProvisioningArtifact': {
    ProductId: 'required',
    ProvisioningArtifactId: 'required',
    AcceptLanguage: '<optional>',
    Name: '<optional>',
    Description: '<optional>',
    Active: '<optional>',
    Guidance: '<optional>',
  },
  'ServiceCatalog:updateServiceAction': {
    Id: 'required',
    Name: '<optional>',
    Definition: '<optional>',
    Description: '<optional>',
    AcceptLanguage: '<optional>',
  },
  'ServiceCatalog:updateTagOption': {
    Id: 'required',
    Value: '<optional>',
    Active: '<optional>',
  },
  'ServiceDiscovery:createHttpNamespace': {
    Name: 'required',
    CreatorRequestId: '<optional>',
    Description: '<optional>',
  },
  'ServiceDiscovery:createPrivateDnsNamespace': {
    Name: 'required',
    Vpc: 'required',
    CreatorRequestId: '<optional>',
    Description: '<optional>',
  },
  'ServiceDiscovery:createPublicDnsNamespace': {
    Name: 'required',
    CreatorRequestId: '<optional>',
    Description: '<optional>',
  },
  'ServiceDiscovery:createService': {
    Name: 'required',
    DnsConfig: {
      NamespaceId: '<optional>',
      RoutingPolicy: '<optional>',
    },
    HealthCheckConfig: {
      ResourcePath: '<optional>',
      FailureThreshold: '<optional>',
    },
    NamespaceId: '<optional>',
    CreatorRequestId: '<optional>',
    Description: '<optional>',
    HealthCheckCustomConfig: {
      FailureThreshold: '<optional>',
    },
  },
  'ServiceDiscovery:deleteNamespace': {
    Id: 'required',
  },
  'ServiceDiscovery:deleteService': {
    Id: 'required',
  },
  'ServiceDiscovery:deregisterInstance': {
    ServiceId: 'required',
    InstanceId: 'required',
  },
  'ServiceDiscovery:discoverInstances': {
    NamespaceName: 'required',
    ServiceName: 'required',
    QueryParameters: ['<optional1>', '<optional2>'],
    HealthStatus: '<optional>',
  },
  'ServiceDiscovery:getInstance': {
    ServiceId: 'required',
    InstanceId: 'required',
  },
  'ServiceDiscovery:getInstancesHealthStatus': {
    ServiceId: 'required',
    Instances: ['<optional1>', '<optional2>'],
  },
  'ServiceDiscovery:getNamespace': {
    Id: 'required',
  },
  'ServiceDiscovery:getOperation': {
    OperationId: 'required',
  },
  'ServiceDiscovery:getService': {
    Id: 'required',
  },
  'ServiceDiscovery:listInstances': {
    ServiceId: 'required',
  },
  'ServiceDiscovery:listNamespaces': {
    Filters: [
      {
        Condition: '<optional>',
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'ServiceDiscovery:listOperations': {
    Filters: [
      {
        Condition: '<optional>',
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'ServiceDiscovery:listServices': {
    Filters: [
      {
        Condition: '<optional>',
        Name: '<optional>',
        Values: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'ServiceDiscovery:registerInstance': {
    ServiceId: 'required',
    InstanceId: 'required',
    Attributes: ['required1', 'required2'],
    CreatorRequestId: '<optional>',
  },
  'ServiceDiscovery:updateInstanceCustomHealthStatus': {
    ServiceId: 'required',
    InstanceId: 'required',
    Status: 'required',
  },
  'ServiceDiscovery:updateService': {
    Id: 'required',
    Service: {
      Description: '<optional>',
      HealthCheckConfig: {
        ResourcePath: '<optional>',
        FailureThreshold: '<optional>',
      },
    },
  },
  'ServiceQuotas:associateServiceQuotaTemplate': {},
  'ServiceQuotas:deleteServiceQuotaIncreaseRequestFromTemplate': {
    ServiceCode: 'required',
    QuotaCode: 'required',
    AwsRegion: 'required',
  },
  'ServiceQuotas:disassociateServiceQuotaTemplate': {},
  'ServiceQuotas:getAWSDefaultServiceQuota': {
    ServiceCode: 'required',
    QuotaCode: 'required',
  },
  'ServiceQuotas:getAssociationForServiceQuotaTemplate': {},
  'ServiceQuotas:getRequestedServiceQuotaChange': {
    RequestId: 'required',
  },
  'ServiceQuotas:getServiceQuota': {
    ServiceCode: 'required',
    QuotaCode: 'required',
  },
  'ServiceQuotas:getServiceQuotaIncreaseRequestFromTemplate': {
    ServiceCode: 'required',
    QuotaCode: 'required',
    AwsRegion: 'required',
  },
  'ServiceQuotas:listAWSDefaultServiceQuotas': {
    ServiceCode: 'required',
  },
  'ServiceQuotas:listRequestedServiceQuotaChangeHistory': {
    ServiceCode: '<optional>',
    Status: '<optional>',
  },
  'ServiceQuotas:listRequestedServiceQuotaChangeHistoryByQuota': {
    ServiceCode: 'required',
    QuotaCode: 'required',
    Status: '<optional>',
  },
  'ServiceQuotas:listServiceQuotaIncreaseRequestsInTemplate': {
    ServiceCode: '<optional>',
    AwsRegion: '<optional>',
  },
  'ServiceQuotas:listServiceQuotas': {
    ServiceCode: 'required',
  },
  'ServiceQuotas:listServices': {},
  'ServiceQuotas:putServiceQuotaIncreaseRequestIntoTemplate': {
    QuotaCode: 'required',
    ServiceCode: 'required',
    AwsRegion: 'required',
    DesiredValue: 'required',
  },
  'ServiceQuotas:requestServiceQuotaIncrease': {
    ServiceCode: 'required',
    QuotaCode: 'required',
    DesiredValue: 'required',
  },
  'Shield:associateDRTLogBucket': {
    LogBucket: 'required',
  },
  'Shield:associateDRTRole': {
    RoleArn: 'required',
  },
  'Shield:createProtection': {
    Name: 'required',
    ResourceArn: 'required',
  },
  'Shield:createSubscription': {},
  'Shield:deleteProtection': {
    ProtectionId: 'required',
  },
  'Shield:deleteSubscription': {},
  'Shield:describeAttack': {
    AttackId: 'required',
  },
  'Shield:describeDRTAccess': {},
  'Shield:describeEmergencyContactSettings': {},
  'Shield:describeProtection': {
    ProtectionId: '<optional>',
    ResourceArn: '<optional>',
  },
  'Shield:describeSubscription': {},
  'Shield:disassociateDRTLogBucket': {
    LogBucket: 'required',
  },
  'Shield:disassociateDRTRole': {},
  'Shield:getSubscriptionState': {},
  'Shield:listAttacks': {
    ResourceArns: ['<optional1>', '<optional2>'],
    StartTime: {
      FromInclusive: '<optional>',
      ToExclusive: '<optional>',
    },
    EndTime: {
      FromInclusive: '<optional>',
      ToExclusive: '<optional>',
    },
  },
  'Shield:listProtections': {},
  'Shield:updateEmergencyContactSettings': {
    EmergencyContactList: '<optional>',
  },
  'Shield:updateSubscription': {
    AutoRenew: '<optional>',
  },
  'Signer:cancelSigningProfile': {
    profileName: 'required',
  },
  'Signer:describeSigningJob': {
    jobId: 'required',
  },
  'Signer:getSigningPlatform': {
    platformId: 'required',
  },
  'Signer:getSigningProfile': {
    profileName: 'required',
  },
  'Signer:listSigningJobs': {
    status: '<optional>',
    platformId: '<optional>',
    requestedBy: '<optional>',
  },
  'Signer:listSigningPlatforms': {
    category: '<optional>',
    partner: '<optional>',
    target: '<optional>',
  },
  'Signer:listSigningProfiles': {
    includeCanceled: '<optional>',
  },
  'Signer:listTagsForResource': {
    resourceArn: 'required',
  },
  'Signer:putSigningProfile': {
    profileName: 'required',
    signingMaterial: {
      certificateArn: 'required',
    },
    platformId: 'required',
    overrides: [
      {
        signingConfiguration: {
          encryptionAlgorithm: '<optional>',
          hashAlgorithm: '<optional>',
        },
      },
    ],
    signingParameters: ['<optional1>', '<optional2>'],
  },
  'Signer:startSigningJob': {
    source: {
      s3: '<optional>',
    },
    destination: {
      s3: {
        bucketName: '<optional>',
        prefix: '<optional>',
      },
    },
    clientRequestToken: 'required',
    profileName: '<optional>',
  },
  'Signer:tagResource': {
    resourceArn: 'required',
    tags: ['required1', 'required2'],
  },
  'Signer:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'SimpleDB:batchDeleteAttributes': {
    DomainName: 'required',
    Items: [
      {
        Attributes: [
          {
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'SimpleDB:batchPutAttributes': {
    DomainName: 'required',
    Items: [
      {
        Attributes: [
          {
            Replace: '<optional>',
          },
        ],
      },
    ],
  },
  'SimpleDB:createDomain': {
    DomainName: 'required',
  },
  'SimpleDB:deleteAttributes': {
    DomainName: 'required',
    ItemName: 'required',
    Attributes: [
      {
        Value: '<optional>',
      },
    ],
    Expected: {
      Name: '<optional>',
      Value: '<optional>',
      Exists: ['<optional1>', '<optional2>'],
    },
  },
  'SimpleDB:deleteDomain': {
    DomainName: 'required',
  },
  'SimpleDB:domainMetadata': {
    DomainName: 'required',
  },
  'SimpleDB:getAttributes': {
    DomainName: 'required',
    ItemName: 'required',
    AttributeNames: ['<optional1>', '<optional2>'],
    ConsistentRead: '<optional>',
  },
  'SimpleDB:listDomains': {
    MaxNumberOfDomains: ['<optional1>', '<optional2>'],
  },
  'SimpleDB:putAttributes': {
    DomainName: 'required',
    ItemName: 'required',
    Attributes: [
      {
        Replace: '<optional>',
      },
    ],
    Expected: {
      Name: '<optional>',
      Value: '<optional>',
      Exists: ['<optional1>', '<optional2>'],
    },
  },
  'SimpleDB:select': {
    SelectExpression: 'required',
    ConsistentRead: '<optional>',
  },
  'Snowball:cancelCluster': {
    ClusterId: 'required',
  },
  'Snowball:cancelJob': {
    JobId: 'required',
  },
  'Snowball:createAddress': {
    Address: {
      AddressId: '<optional>',
      Name: '<optional>',
      Company: '<optional>',
      Street1: '<optional>',
      Street2: '<optional>',
      Street3: '<optional>',
      City: '<optional>',
      StateOrProvince: '<optional>',
      PrefectureOrDistrict: '<optional>',
      Landmark: '<optional>',
      Country: '<optional>',
      PostalCode: '<optional>',
      PhoneNumber: '<optional>',
      IsRestricted: '<optional>',
    },
  },
  'Snowball:createCluster': {
    JobType: 'required',
    Resources: [
      {
        S3Resources: [
          {
            BucketArn: '<optional>',
            KeyRange: {
              BeginMarker: '<optional>',
              EndMarker: '<optional>',
            },
          },
        ],
        LambdaResources: [
          {
            LambdaArn: '<optional>',
            EventTriggers: [
              {
                EventResourceARN: '<optional>',
              },
            ],
          },
        ],
        Ec2AmiResources: [
          {
            SnowballAmiId: '<optional>',
          },
        ],
      },
    ],
    AddressId: 'required',
    RoleARN: 'required',
    ShippingOption: 'required',
    Description: '<optional>',
    KmsKeyARN: '<optional>',
    SnowballType: '<optional>',
    Notification: {
      SnsTopicARN: '<optional>',
      JobStatesToNotify: '<optional>',
      NotifyAll: '<optional>',
    },
    ForwardingAddressId: '<optional>',
  },
  'Snowball:createJob': {
    Resources: [
      {
        S3Resources: [
          {
            BucketArn: '<optional>',
            KeyRange: {
              BeginMarker: '<optional>',
              EndMarker: '<optional>',
            },
          },
        ],
        LambdaResources: [
          {
            LambdaArn: '<optional>',
            EventTriggers: [
              {
                EventResourceARN: '<optional>',
              },
            ],
          },
        ],
        Ec2AmiResources: [
          {
            SnowballAmiId: '<optional>',
          },
        ],
      },
    ],
    JobType: '<optional>',
    Description: '<optional>',
    AddressId: '<optional>',
    KmsKeyARN: '<optional>',
    RoleARN: '<optional>',
    SnowballCapacityPreference: '<optional>',
    ShippingOption: '<optional>',
    Notification: {
      SnsTopicARN: '<optional>',
      JobStatesToNotify: '<optional>',
      NotifyAll: '<optional>',
    },
    ClusterId: '<optional>',
    SnowballType: '<optional>',
    ForwardingAddressId: '<optional>',
  },
  'Snowball:describeAddress': {
    AddressId: 'required',
  },
  'Snowball:describeAddresses': {},
  'Snowball:describeCluster': {
    ClusterId: 'required',
  },
  'Snowball:describeJob': {
    JobId: 'required',
  },
  'Snowball:getJobManifest': {
    JobId: 'required',
  },
  'Snowball:getJobUnlockCode': {
    JobId: 'required',
  },
  'Snowball:getSnowballUsage': {},
  'Snowball:getSoftwareUpdates': {
    JobId: 'required',
  },
  'Snowball:listClusterJobs': {
    ClusterId: 'required',
  },
  'Snowball:listClusters': {},
  'Snowball:listCompatibleImages': {},
  'Snowball:listJobs': {},
  'Snowball:updateCluster': {
    ClusterId: 'required',
    Resources: [
      {
        S3Resources: [
          {
            BucketArn: '<optional>',
            KeyRange: {
              BeginMarker: '<optional>',
              EndMarker: '<optional>',
            },
          },
        ],
        LambdaResources: [
          {
            LambdaArn: '<optional>',
            EventTriggers: [
              {
                EventResourceARN: '<optional>',
              },
            ],
          },
        ],
        Ec2AmiResources: [
          {
            SnowballAmiId: '<optional>',
          },
        ],
      },
    ],
    RoleARN: '<optional>',
    Description: '<optional>',
    AddressId: '<optional>',
    ShippingOption: '<optional>',
    Notification: {
      SnsTopicARN: '<optional>',
      JobStatesToNotify: '<optional>',
      NotifyAll: '<optional>',
    },
    ForwardingAddressId: '<optional>',
  },
  'Snowball:updateJob': {
    JobId: 'required',
    Resources: [
      {
        S3Resources: [
          {
            BucketArn: '<optional>',
            KeyRange: {
              BeginMarker: '<optional>',
              EndMarker: '<optional>',
            },
          },
        ],
        LambdaResources: [
          {
            LambdaArn: '<optional>',
            EventTriggers: [
              {
                EventResourceARN: '<optional>',
              },
            ],
          },
        ],
        Ec2AmiResources: [
          {
            SnowballAmiId: '<optional>',
          },
        ],
      },
    ],
    RoleARN: '<optional>',
    Notification: {
      SnsTopicARN: '<optional>',
      JobStatesToNotify: '<optional>',
      NotifyAll: '<optional>',
    },
    AddressId: '<optional>',
    ShippingOption: '<optional>',
    Description: '<optional>',
    SnowballCapacityPreference: '<optional>',
    ForwardingAddressId: '<optional>',
  },
  'StepFunctions:createActivity': {
    name: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'StepFunctions:createStateMachine': {
    name: 'required',
    definition: 'required',
    roleArn: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'StepFunctions:deleteActivity': {
    activityArn: 'required',
  },
  'StepFunctions:deleteStateMachine': {
    stateMachineArn: 'required',
  },
  'StepFunctions:describeActivity': {
    activityArn: 'required',
  },
  'StepFunctions:describeExecution': {
    executionArn: 'required',
  },
  'StepFunctions:describeStateMachine': {
    stateMachineArn: 'required',
  },
  'StepFunctions:describeStateMachineForExecution': {
    executionArn: 'required',
  },
  'StepFunctions:getActivityTask': {
    activityArn: 'required',
    workerName: '<optional>',
  },
  'StepFunctions:getExecutionHistory': {
    executionArn: 'required',
    reverseOrder: '<optional>',
  },
  'StepFunctions:listActivities': {},
  'StepFunctions:listExecutions': {
    stateMachineArn: 'required',
    statusFilter: '<optional>',
  },
  'StepFunctions:listStateMachines': {},
  'StepFunctions:listTagsForResource': {
    resourceArn: 'required',
  },
  'StepFunctions:sendTaskFailure': {
    taskToken: 'required',
    error: '<optional>',
    cause: '<optional>',
  },
  'StepFunctions:sendTaskHeartbeat': {
    taskToken: 'required',
  },
  'StepFunctions:sendTaskSuccess': {
    taskToken: 'required',
    output: 'required',
  },
  'StepFunctions:startExecution': {
    stateMachineArn: 'required',
    name: '<optional>',
    input: '<optional>',
  },
  'StepFunctions:stopExecution': {
    executionArn: 'required',
    error: '<optional>',
    cause: '<optional>',
  },
  'StepFunctions:tagResource': {
    resourceArn: 'required',
    tags: [
      {
        key: '<optional>',
        value: '<optional>',
      },
    ],
  },
  'StepFunctions:untagResource': {
    resourceArn: 'required',
    tagKeys: ['required1', 'required2'],
  },
  'StepFunctions:updateStateMachine': {
    stateMachineArn: 'required',
    definition: '<optional>',
    roleArn: '<optional>',
  },
  'StorageGateway:activateGateway': {
    ActivationKey: 'required',
    GatewayName: 'required',
    GatewayTimezone: 'required',
    GatewayRegion: 'required',
    Tags: ['<optional1>', '<optional2>'],
    GatewayType: '<optional>',
    TapeDriveType: '<optional>',
    MediumChangerType: '<optional>',
  },
  'StorageGateway:addCache': {
    GatewayARN: 'required',
    DiskIds: ['required1', 'required2'],
  },
  'StorageGateway:addTagsToResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'StorageGateway:addUploadBuffer': {
    GatewayARN: 'required',
    DiskIds: ['required1', 'required2'],
  },
  'StorageGateway:addWorkingStorage': {
    GatewayARN: 'required',
    DiskIds: ['required1', 'required2'],
  },
  'StorageGateway:assignTapePool': {
    TapeARN: 'required',
    PoolId: 'required',
  },
  'StorageGateway:attachVolume': {
    GatewayARN: 'required',
    VolumeARN: 'required',
    NetworkInterfaceId: 'required',
    TargetName: '<optional>',
    DiskId: '<optional>',
  },
  'StorageGateway:cancelArchival': {
    GatewayARN: 'required',
    TapeARN: 'required',
  },
  'StorageGateway:cancelRetrieval': {
    GatewayARN: 'required',
    TapeARN: 'required',
  },
  'StorageGateway:createCachediSCSIVolume': {
    GatewayARN: 'required',
    VolumeSizeInBytes: ['required1', 'required2'],
    TargetName: 'required',
    NetworkInterfaceId: 'required',
    ClientToken: 'required',
    Tags: ['<optional1>', '<optional2>'],
    SnapshotId: '<optional>',
    SourceVolumeARN: '<optional>',
    KMSEncrypted: '<optional>',
    KMSKey: '<optional>',
  },
  'StorageGateway:createNFSFileShare': {
    ClientToken: 'required',
    GatewayARN: 'required',
    Role: 'required',
    LocationARN: 'required',
    Tags: ['<optional1>', '<optional2>'],
    NFSFileShareDefaults: [
      {
        FileMode: '<optional>',
        DirectoryMode: '<optional>',
        GroupId: '<optional>',
        OwnerId: '<optional>',
      },
    ],
    KMSEncrypted: '<optional>',
    KMSKey: '<optional>',
    DefaultStorageClass: '<optional>',
    ObjectACL: '<optional>',
    ClientList: '<optional>',
    Squash: '<optional>',
    ReadOnly: '<optional>',
    GuessMIMETypeEnabled: '<optional>',
    RequesterPays: ['<optional1>', '<optional2>'],
  },
  'StorageGateway:createSMBFileShare': {
    ClientToken: 'required',
    GatewayARN: 'required',
    Role: 'required',
    LocationARN: 'required',
    Tags: ['<optional1>', '<optional2>'],
    KMSEncrypted: '<optional>',
    KMSKey: '<optional>',
    DefaultStorageClass: '<optional>',
    ObjectACL: '<optional>',
    ReadOnly: '<optional>',
    GuessMIMETypeEnabled: '<optional>',
    RequesterPays: ['<optional1>', '<optional2>'],
    SMBACLEnabled: '<optional>',
    AdminUserList: '<optional>',
    ValidUserList: '<optional>',
    InvalidUserList: '<optional>',
    Authentication: '<optional>',
  },
  'StorageGateway:createSnapshot': {
    VolumeARN: 'required',
    SnapshotDescription: 'required',
    Tags: ['<optional1>', '<optional2>'],
  },
  'StorageGateway:createSnapshotFromVolumeRecoveryPoint': {
    VolumeARN: 'required',
    SnapshotDescription: 'required',
  },
  'StorageGateway:createStorediSCSIVolume': {
    GatewayARN: 'required',
    DiskId: 'required',
    PreserveExistingData: ['required1', 'required2'],
    TargetName: 'required',
    NetworkInterfaceId: 'required',
    Tags: ['<optional1>', '<optional2>'],
    SnapshotId: '<optional>',
    KMSEncrypted: '<optional>',
    KMSKey: '<optional>',
  },
  'StorageGateway:createTapeWithBarcode': {
    GatewayARN: 'required',
    TapeSizeInBytes: ['required1', 'required2'],
    TapeBarcode: 'required',
    Tags: ['<optional1>', '<optional2>'],
    KMSEncrypted: '<optional>',
    KMSKey: '<optional>',
    PoolId: '<optional>',
  },
  'StorageGateway:createTapes': {
    GatewayARN: 'required',
    TapeSizeInBytes: ['required1', 'required2'],
    ClientToken: 'required',
    NumTapesToCreate: 'required',
    TapeBarcodePrefix: 'required',
    Tags: ['<optional1>', '<optional2>'],
    KMSEncrypted: '<optional>',
    KMSKey: '<optional>',
    PoolId: '<optional>',
  },
  'StorageGateway:deleteBandwidthRateLimit': {
    GatewayARN: 'required',
    BandwidthType: 'required',
  },
  'StorageGateway:deleteChapCredentials': {
    TargetARN: 'required',
    InitiatorName: 'required',
  },
  'StorageGateway:deleteFileShare': {
    FileShareARN: 'required',
    ForceDelete: '<optional>',
  },
  'StorageGateway:deleteGateway': {
    GatewayARN: 'required',
  },
  'StorageGateway:deleteSnapshotSchedule': {
    VolumeARN: 'required',
  },
  'StorageGateway:deleteTape': {
    GatewayARN: 'required',
    TapeARN: 'required',
  },
  'StorageGateway:deleteTapeArchive': {
    TapeARN: 'required',
  },
  'StorageGateway:deleteVolume': {
    VolumeARN: 'required',
  },
  'StorageGateway:describeAvailabilityMonitorTest': {
    GatewayARN: 'required',
  },
  'StorageGateway:describeBandwidthRateLimit': {
    GatewayARN: 'required',
  },
  'StorageGateway:describeCache': {
    GatewayARN: 'required',
  },
  'StorageGateway:describeCachediSCSIVolumes': {
    VolumeARNs: ['required1', 'required2'],
  },
  'StorageGateway:describeChapCredentials': {
    TargetARN: 'required',
  },
  'StorageGateway:describeGatewayInformation': {
    GatewayARN: 'required',
  },
  'StorageGateway:describeMaintenanceStartTime': {
    GatewayARN: 'required',
  },
  'StorageGateway:describeNFSFileShares': {
    FileShareARNList: 'required',
  },
  'StorageGateway:describeSMBFileShares': {
    FileShareARNList: 'required',
  },
  'StorageGateway:describeSMBSettings': {
    GatewayARN: 'required',
  },
  'StorageGateway:describeSnapshotSchedule': {
    VolumeARN: 'required',
  },
  'StorageGateway:describeStorediSCSIVolumes': {
    VolumeARNs: ['required1', 'required2'],
  },
  'StorageGateway:describeTapeArchives': {
    TapeARNs: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'StorageGateway:describeTapeRecoveryPoints': {
    GatewayARN: 'required',
    Limit: '<optional>',
  },
  'StorageGateway:describeTapes': {
    GatewayARN: 'required',
    TapeARNs: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'StorageGateway:describeUploadBuffer': {
    GatewayARN: 'required',
  },
  'StorageGateway:describeVTLDevices': {
    GatewayARN: 'required',
    VTLDeviceARNs: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'StorageGateway:describeWorkingStorage': {
    GatewayARN: 'required',
  },
  'StorageGateway:detachVolume': {
    VolumeARN: 'required',
    ForceDetach: '<optional>',
  },
  'StorageGateway:disableGateway': {
    GatewayARN: 'required',
  },
  'StorageGateway:joinDomain': {
    GatewayARN: 'required',
    DomainName: 'required',
    UserName: 'required',
    Password: 'required',
    OrganizationalUnit: '<optional>',
    DomainControllers: ['<optional1>', '<optional2>'],
  },
  'StorageGateway:listFileShares': {
    GatewayARN: '<optional>',
    Limit: '<optional>',
  },
  'StorageGateway:listGateways': {
    Limit: '<optional>',
  },
  'StorageGateway:listLocalDisks': {
    GatewayARN: 'required',
  },
  'StorageGateway:listTagsForFileShares': {
    ResourceARN: 'required',
    Limit: '<optional>',
  },
  'StorageGateway:listTagsForGateways': {
    ResourceARN: 'required',
    Limit: '<optional>',
  },
  'StorageGateway:listTagsForResource': {
    ResourceARN: 'required',
    Limit: '<optional>',
  },
  'StorageGateway:listTagsForTapeArchives': {
    ResourceARN: 'required',
    Limit: '<optional>',
  },
  'StorageGateway:listTagsForTapes': {
    ResourceARN: 'required',
    Limit: '<optional>',
  },
  'StorageGateway:listTagsForVolumes': {
    ResourceARN: 'required',
    Limit: '<optional>',
  },
  'StorageGateway:listTapes': {
    TapeARNs: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'StorageGateway:listVolumeInitiators': {
    VolumeARN: 'required',
  },
  'StorageGateway:listVolumeRecoveryPoints': {
    GatewayARN: 'required',
  },
  'StorageGateway:listVolumes': {
    GatewayARN: '<optional>',
    Limit: '<optional>',
  },
  'StorageGateway:notifyWhenUploaded': {
    FileShareARN: 'required',
  },
  'StorageGateway:refreshCache': {
    FileShareARN: 'required',
    FolderList: '<optional>',
    Recursive: '<optional>',
  },
  'StorageGateway:removeTagsFromResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'StorageGateway:resetCache': {
    GatewayARN: 'required',
  },
  'StorageGateway:retrieveTapeArchive': {
    TapeARN: 'required',
    GatewayARN: 'required',
  },
  'StorageGateway:retrieveTapeRecoveryPoint': {
    TapeARN: 'required',
    GatewayARN: 'required',
  },
  'StorageGateway:setLocalConsolePassword': {
    GatewayARN: 'required',
    LocalConsolePassword: 'required',
  },
  'StorageGateway:setSMBGuestPassword': {
    GatewayARN: 'required',
    Password: 'required',
  },
  'StorageGateway:shutdownGateway': {
    GatewayARN: 'required',
  },
  'StorageGateway:startAvailabilityMonitorTest': {
    GatewayARN: 'required',
  },
  'StorageGateway:startGateway': {
    GatewayARN: 'required',
  },
  'StorageGateway:updateBandwidthRateLimit': {
    GatewayARN: 'required',
    AverageUploadRateLimitInBitsPerSec: '<optional>',
    AverageDownloadRateLimitInBitsPerSec: '<optional>',
  },
  'StorageGateway:updateChapCredentials': {
    TargetARN: 'required',
    SecretToAuthenticateInitiator: 'required',
    InitiatorName: 'required',
    SecretToAuthenticateTarget: '<optional>',
  },
  'StorageGateway:updateGatewayInformation': {
    GatewayARN: 'required',
    GatewayName: '<optional>',
    GatewayTimezone: '<optional>',
  },
  'StorageGateway:updateGatewaySoftwareNow': {
    GatewayARN: 'required',
  },
  'StorageGateway:updateMaintenanceStartTime': {
    GatewayARN: 'required',
    HourOfDay: 'required',
    MinuteOfHour: 'required',
    DayOfWeek: '<optional>',
    DayOfMonth: '<optional>',
  },
  'StorageGateway:updateNFSFileShare': {
    FileShareARN: 'required',
    KMSEncrypted: '<optional>',
    KMSKey: '<optional>',
    NFSFileShareDefaults: [
      {
        FileMode: '<optional>',
        DirectoryMode: '<optional>',
        GroupId: '<optional>',
        OwnerId: '<optional>',
      },
    ],
    DefaultStorageClass: '<optional>',
    ObjectACL: '<optional>',
    ClientList: '<optional>',
    Squash: '<optional>',
    ReadOnly: '<optional>',
    GuessMIMETypeEnabled: '<optional>',
    RequesterPays: ['<optional1>', '<optional2>'],
  },
  'StorageGateway:updateSMBFileShare': {
    FileShareARN: 'required',
    KMSEncrypted: '<optional>',
    KMSKey: '<optional>',
    DefaultStorageClass: '<optional>',
    ObjectACL: '<optional>',
    ReadOnly: '<optional>',
    GuessMIMETypeEnabled: '<optional>',
    RequesterPays: ['<optional1>', '<optional2>'],
    SMBACLEnabled: '<optional>',
    AdminUserList: '<optional>',
    ValidUserList: '<optional>',
    InvalidUserList: '<optional>',
  },
  'StorageGateway:updateSMBSecurityStrategy': {
    GatewayARN: 'required',
    SMBSecurityStrategy: 'required',
  },
  'StorageGateway:updateSnapshotSchedule': {
    VolumeARN: 'required',
    StartAt: 'required',
    RecurrenceInHours: ['required1', 'required2'],
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
  },
  'StorageGateway:updateVTLDeviceType': {
    VTLDeviceARN: 'required',
    DeviceType: 'required',
  },
  'Support:addAttachmentsToSet': {
    attachments: [
      {
        fileName: '<optional>',
        data: ['<optional1>', '<optional2>'],
      },
    ],
    attachmentSetId: '<optional>',
  },
  'Support:addCommunicationToCase': {
    communicationBody: 'required',
    caseId: '<optional>',
    ccEmailAddresses: ['<optional1>', '<optional2>'],
    attachmentSetId: '<optional>',
  },
  'Support:createCase': {
    subject: 'required',
    communicationBody: 'required',
    serviceCode: '<optional>',
    severityCode: '<optional>',
    categoryCode: '<optional>',
    ccEmailAddresses: ['<optional1>', '<optional2>'],
    language: '<optional>',
    issueType: '<optional>',
    attachmentSetId: '<optional>',
  },
  'Support:describeAttachment': {
    attachmentId: 'required',
  },
  'Support:describeCases': {
    caseIdList: '<optional>',
    displayId: '<optional>',
    afterTime: '<optional>',
    beforeTime: '<optional>',
    includeResolvedCases: ['<optional1>', '<optional2>'],
    language: '<optional>',
    includeCommunications: ['<optional1>', '<optional2>'],
  },
  'Support:describeCommunications': {
    caseId: 'required',
    beforeTime: '<optional>',
    afterTime: '<optional>',
  },
  'Support:describeServices': {
    serviceCodeList: '<optional>',
    language: '<optional>',
  },
  'Support:describeSeverityLevels': {
    language: '<optional>',
  },
  'Support:describeTrustedAdvisorCheckRefreshStatuses': {
    checkIds: ['required1', 'required2'],
  },
  'Support:describeTrustedAdvisorCheckResult': {
    checkId: 'required',
    language: '<optional>',
  },
  'Support:describeTrustedAdvisorCheckSummaries': {
    checkIds: ['required1', 'required2'],
  },
  'Support:describeTrustedAdvisorChecks': {
    language: 'required',
  },
  'Support:refreshTrustedAdvisorCheck': {
    checkId: 'required',
  },
  'Support:resolveCase': {
    caseId: '<optional>',
  },
  'Textract:analyzeDocument': {
    Document: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    FeatureTypes: ['required1', 'required2'],
  },
  'Textract:detectDocumentText': {
    Document: {
      Bytes: ['<optional1>', '<optional2>'],
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
  },
  'Textract:getDocumentAnalysis': {
    JobId: 'required',
  },
  'Textract:getDocumentTextDetection': {
    JobId: 'required',
  },
  'Textract:startDocumentAnalysis': {
    DocumentLocation: {
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    FeatureTypes: ['required1', 'required2'],
    NotificationChannel: '<optional>',
    ClientRequestToken: '<optional>',
    JobTag: '<optional>',
  },
  'Textract:startDocumentTextDetection': {
    DocumentLocation: {
      S3Object: {
        Bucket: '<optional>',
        Name: '<optional>',
        Version: '<optional>',
      },
    },
    NotificationChannel: '<optional>',
    ClientRequestToken: '<optional>',
    JobTag: '<optional>',
  },
  'TranscribeService:createVocabulary': {
    VocabularyName: 'required',
    LanguageCode: 'required',
    Phrases: ['<optional1>', '<optional2>'],
    VocabularyFileUri: '<optional>',
  },
  'TranscribeService:createVocabularyFilter': {
    VocabularyFilterName: 'required',
    LanguageCode: 'required',
    Words: ['<optional1>', '<optional2>'],
    VocabularyFilterFileUri: '<optional>',
  },
  'TranscribeService:deleteTranscriptionJob': {
    TranscriptionJobName: 'required',
  },
  'TranscribeService:deleteVocabulary': {
    VocabularyName: 'required',
  },
  'TranscribeService:deleteVocabularyFilter': {
    VocabularyFilterName: 'required',
  },
  'TranscribeService:getTranscriptionJob': {
    TranscriptionJobName: 'required',
  },
  'TranscribeService:getVocabulary': {
    VocabularyName: 'required',
  },
  'TranscribeService:getVocabularyFilter': {
    VocabularyFilterName: 'required',
  },
  'TranscribeService:listTranscriptionJobs': {
    Status: '<optional>',
    JobNameContains: ['<optional1>', '<optional2>'],
  },
  'TranscribeService:listVocabularies': {
    StateEquals: ['<optional1>', '<optional2>'],
    NameContains: ['<optional1>', '<optional2>'],
  },
  'TranscribeService:listVocabularyFilters': {
    NameContains: ['<optional1>', '<optional2>'],
  },
  'TranscribeService:startTranscriptionJob': {
    TranscriptionJobName: 'required',
    LanguageCode: 'required',
    MediaFormat: 'required',
    Media: [
      {
        MediaFileUri: '<optional>',
      },
    ],
    MediaSampleRateHertz: '<optional>',
    OutputBucketName: '<optional>',
    Settings: [
      {
        VocabularyName: '<optional>',
        ShowSpeakerLabels: ['<optional1>', '<optional2>'],
        MaxSpeakerLabels: ['<optional1>', '<optional2>'],
        ChannelIdentification: '<optional>',
      },
    ],
  },
  'TranscribeService:updateVocabulary': {
    VocabularyName: 'required',
    LanguageCode: 'required',
    Phrases: ['<optional1>', '<optional2>'],
    VocabularyFileUri: '<optional>',
  },
  'TranscribeService:updateVocabularyFilter': {
    VocabularyFilterName: 'required',
    Words: ['<optional1>', '<optional2>'],
    VocabularyFilterFileUri: '<optional>',
  },
  'Transfer:createServer': {
    Tags: ['<optional1>', '<optional2>'],
    EndpointDetails: [
      {
        VpcEndpointId: '<optional>',
      },
    ],
    EndpointType: '<optional>',
    HostKey: '<optional>',
    IdentityProviderDetails: [
      {
        Url: '<optional>',
        InvocationRole: '<optional>',
      },
    ],
    IdentityProviderType: '<optional>',
    LoggingRole: '<optional>',
  },
  'Transfer:createUser': {
    Role: 'required',
    ServerId: 'required',
    UserName: 'required',
    Tags: ['<optional1>', '<optional2>'],
    HomeDirectory: '<optional>',
    Policy: '<optional>',
    SshPublicKeyBody: '<optional>',
  },
  'Transfer:deleteServer': {
    ServerId: 'required',
  },
  'Transfer:deleteSshPublicKey': {
    ServerId: 'required',
    SshPublicKeyId: 'required',
    UserName: 'required',
  },
  'Transfer:deleteUser': {
    ServerId: 'required',
    UserName: 'required',
  },
  'Transfer:describeServer': {
    ServerId: 'required',
  },
  'Transfer:describeUser': {
    ServerId: 'required',
    UserName: 'required',
  },
  'Transfer:importSshPublicKey': {
    ServerId: 'required',
    SshPublicKeyBody: 'required',
    UserName: 'required',
  },
  'Transfer:listServers': {},
  'Transfer:listTagsForResource': {
    Arn: 'required',
  },
  'Transfer:listUsers': {
    ServerId: 'required',
  },
  'Transfer:startServer': {
    ServerId: 'required',
  },
  'Transfer:stopServer': {
    ServerId: 'required',
  },
  'Transfer:tagResource': {
    Arn: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'Transfer:testIdentityProvider': {
    ServerId: 'required',
    UserName: 'required',
    UserPassword: '<optional>',
  },
  'Transfer:untagResource': {
    Arn: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'Transfer:updateServer': {
    ServerId: 'required',
    EndpointDetails: [
      {
        VpcEndpointId: '<optional>',
      },
    ],
    EndpointType: '<optional>',
    HostKey: '<optional>',
    IdentityProviderDetails: [
      {
        Url: '<optional>',
        InvocationRole: '<optional>',
      },
    ],
    LoggingRole: '<optional>',
  },
  'Transfer:updateUser': {
    ServerId: 'required',
    UserName: 'required',
    HomeDirectory: '<optional>',
    Policy: '<optional>',
    Role: '<optional>',
  },
  'Translate:deleteTerminology': {
    Name: 'required',
  },
  'Translate:getTerminology': {
    Name: 'required',
    TerminologyDataFormat: 'required',
  },
  'Translate:importTerminology': {
    Name: 'required',
    MergeStrategy: 'required',
    TerminologyData: [
      {
        File: 'required',
        Format: 'required',
      },
    ],
    EncryptionKey: '<optional>',
    Description: '<optional>',
  },
  'Translate:listTerminologies': {},
  'Translate:translateText': {
    Text: 'required',
    SourceLanguageCode: 'required',
    TargetLanguageCode: 'required',
    TerminologyNames: ['<optional1>', '<optional2>'],
  },
  'WAF:createByteMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAF:createGeoMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAF:createIPSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAF:createRateBasedRule': {
    Name: 'required',
    MetricName: 'required',
    RateKey: 'required',
    RateLimit: 'required',
    ChangeToken: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAF:createRegexMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAF:createRegexPatternSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAF:createRule': {
    Name: 'required',
    MetricName: 'required',
    ChangeToken: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAF:createRuleGroup': {
    Name: 'required',
    MetricName: 'required',
    ChangeToken: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAF:createSizeConstraintSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAF:createSqlInjectionMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAF:createWebACL': {
    Name: 'required',
    MetricName: 'required',
    DefaultAction: {
      Type: 'required',
    },
    ChangeToken: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAF:createXssMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteByteMatchSet': {
    ByteMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteGeoMatchSet': {
    GeoMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteIPSet': {
    IPSetId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteLoggingConfiguration': {
    ResourceArn: 'required',
  },
  'WAF:deletePermissionPolicy': {
    ResourceArn: 'required',
  },
  'WAF:deleteRateBasedRule': {
    RuleId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteRegexMatchSet': {
    RegexMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteRegexPatternSet': {
    RegexPatternSetId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteRule': {
    RuleId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteRuleGroup': {
    RuleGroupId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteSizeConstraintSet': {
    SizeConstraintSetId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteSqlInjectionMatchSet': {
    SqlInjectionMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteWebACL': {
    WebACLId: 'required',
    ChangeToken: 'required',
  },
  'WAF:deleteXssMatchSet': {
    XssMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAF:getByteMatchSet': {
    ByteMatchSetId: 'required',
  },
  'WAF:getChangeToken': {},
  'WAF:getChangeTokenStatus': {
    ChangeToken: 'required',
  },
  'WAF:getGeoMatchSet': {
    GeoMatchSetId: 'required',
  },
  'WAF:getIPSet': {
    IPSetId: 'required',
  },
  'WAF:getLoggingConfiguration': {
    ResourceArn: 'required',
  },
  'WAF:getPermissionPolicy': {
    ResourceArn: 'required',
  },
  'WAF:getRateBasedRule': {
    RuleId: 'required',
  },
  'WAF:getRateBasedRuleManagedKeys': {
    RuleId: 'required',
    NextMarker: '<optional>',
  },
  'WAF:getRegexMatchSet': {
    RegexMatchSetId: 'required',
  },
  'WAF:getRegexPatternSet': {
    RegexPatternSetId: 'required',
  },
  'WAF:getRule': {
    RuleId: 'required',
  },
  'WAF:getRuleGroup': {
    RuleGroupId: 'required',
  },
  'WAF:getSampledRequests': {
    WebAclId: 'required',
    RuleId: 'required',
    TimeWindow: {
      StartTime: 'required',
      EndTime: 'required',
    },
  },
  'WAF:getSizeConstraintSet': {
    SizeConstraintSetId: 'required',
  },
  'WAF:getSqlInjectionMatchSet': {
    SqlInjectionMatchSetId: 'required',
  },
  'WAF:getWebACL': {
    WebACLId: 'required',
  },
  'WAF:getXssMatchSet': {
    XssMatchSetId: 'required',
  },
  'WAF:listActivatedRulesInRuleGroup': {
    RuleGroupId: '<optional>',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listByteMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listGeoMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listIPSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listLoggingConfigurations': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listRateBasedRules': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listRegexMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listRegexPatternSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listRuleGroups': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listRules': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listSizeConstraintSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listSqlInjectionMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listSubscribedRuleGroups': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listTagsForResource': {
    ResourceARN: 'required',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listWebACLs': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:listXssMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAF:putLoggingConfiguration': {
    LoggingConfiguration: {
      RedactedFields: [
        {
          Data: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'WAF:putPermissionPolicy': {
    ResourceArn: 'required',
    Policy: 'required',
  },
  'WAF:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAF:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'WAF:updateByteMatchSet': {
    ByteMatchSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        ByteMatchTuple: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAF:updateGeoMatchSet': {
    GeoMatchSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        Action: 'required',
        GeoMatchConstraint: {
          Type: 'required',
          Value: 'required',
        },
      },
    ],
  },
  'WAF:updateIPSet': {
    IPSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        Action: 'required',
        IPSetDescriptor: {
          Type: 'required',
          Value: 'required',
        },
      },
    ],
  },
  'WAF:updateRateBasedRule': {
    RuleId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        Action: 'required',
        Predicate: {
          Negated: 'required',
          Type: 'required',
          DataId: 'required',
        },
      },
    ],
    RateLimit: 'required',
  },
  'WAF:updateRegexMatchSet': {
    RegexMatchSetId: 'required',
    Updates: [
      {
        RegexMatchTuple: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
    ChangeToken: 'required',
  },
  'WAF:updateRegexPatternSet': {
    RegexPatternSetId: 'required',
    Updates: [
      {
        Action: 'required',
        RegexPatternString: 'required',
      },
    ],
    ChangeToken: 'required',
  },
  'WAF:updateRule': {
    RuleId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        Action: 'required',
        Predicate: {
          Negated: 'required',
          Type: 'required',
          DataId: 'required',
        },
      },
    ],
  },
  'WAF:updateRuleGroup': {
    RuleGroupId: 'required',
    Updates: [
      {
        ActivatedRule: {
          Action: '<optional>',
          OverrideAction: '<optional>',
          Type: '<optional>',
          ExcludedRules: ['<optional1>', '<optional2>'],
        },
      },
    ],
    ChangeToken: 'required',
  },
  'WAF:updateSizeConstraintSet': {
    SizeConstraintSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        SizeConstraint: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAF:updateSqlInjectionMatchSet': {
    SqlInjectionMatchSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        SqlInjectionMatchTuple: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAF:updateWebACL': {
    WebACLId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        ActivatedRule: {
          Action: '<optional>',
          OverrideAction: '<optional>',
          Type: '<optional>',
          ExcludedRules: ['<optional1>', '<optional2>'],
        },
      },
    ],
    DefaultAction: '<optional>',
  },
  'WAF:updateXssMatchSet': {
    XssMatchSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        XssMatchTuple: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAFRegional:associateWebACL': {
    WebACLId: 'required',
    ResourceArn: 'required',
  },
  'WAFRegional:createByteMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:createGeoMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:createIPSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:createRateBasedRule': {
    Name: 'required',
    MetricName: 'required',
    RateKey: 'required',
    RateLimit: 'required',
    ChangeToken: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAFRegional:createRegexMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:createRegexPatternSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:createRule': {
    Name: 'required',
    MetricName: 'required',
    ChangeToken: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAFRegional:createRuleGroup': {
    Name: 'required',
    MetricName: 'required',
    ChangeToken: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAFRegional:createSizeConstraintSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:createSqlInjectionMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:createWebACL': {
    Name: 'required',
    MetricName: 'required',
    DefaultAction: {
      Type: 'required',
    },
    ChangeToken: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAFRegional:createXssMatchSet': {
    Name: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteByteMatchSet': {
    ByteMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteGeoMatchSet': {
    GeoMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteIPSet': {
    IPSetId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteLoggingConfiguration': {
    ResourceArn: 'required',
  },
  'WAFRegional:deletePermissionPolicy': {
    ResourceArn: 'required',
  },
  'WAFRegional:deleteRateBasedRule': {
    RuleId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteRegexMatchSet': {
    RegexMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteRegexPatternSet': {
    RegexPatternSetId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteRule': {
    RuleId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteRuleGroup': {
    RuleGroupId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteSizeConstraintSet': {
    SizeConstraintSetId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteSqlInjectionMatchSet': {
    SqlInjectionMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteWebACL': {
    WebACLId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:deleteXssMatchSet': {
    XssMatchSetId: 'required',
    ChangeToken: 'required',
  },
  'WAFRegional:disassociateWebACL': {
    ResourceArn: 'required',
  },
  'WAFRegional:getByteMatchSet': {
    ByteMatchSetId: 'required',
  },
  'WAFRegional:getChangeToken': {},
  'WAFRegional:getChangeTokenStatus': {
    ChangeToken: 'required',
  },
  'WAFRegional:getGeoMatchSet': {
    GeoMatchSetId: 'required',
  },
  'WAFRegional:getIPSet': {
    IPSetId: 'required',
  },
  'WAFRegional:getLoggingConfiguration': {
    ResourceArn: 'required',
  },
  'WAFRegional:getPermissionPolicy': {
    ResourceArn: 'required',
  },
  'WAFRegional:getRateBasedRule': {
    RuleId: 'required',
  },
  'WAFRegional:getRateBasedRuleManagedKeys': {
    RuleId: 'required',
    NextMarker: '<optional>',
  },
  'WAFRegional:getRegexMatchSet': {
    RegexMatchSetId: 'required',
  },
  'WAFRegional:getRegexPatternSet': {
    RegexPatternSetId: 'required',
  },
  'WAFRegional:getRule': {
    RuleId: 'required',
  },
  'WAFRegional:getRuleGroup': {
    RuleGroupId: 'required',
  },
  'WAFRegional:getSampledRequests': {
    WebAclId: 'required',
    RuleId: 'required',
    TimeWindow: {
      StartTime: 'required',
      EndTime: 'required',
    },
  },
  'WAFRegional:getSizeConstraintSet': {
    SizeConstraintSetId: 'required',
  },
  'WAFRegional:getSqlInjectionMatchSet': {
    SqlInjectionMatchSetId: 'required',
  },
  'WAFRegional:getWebACL': {
    WebACLId: 'required',
  },
  'WAFRegional:getWebACLForResource': {
    ResourceArn: 'required',
  },
  'WAFRegional:getXssMatchSet': {
    XssMatchSetId: 'required',
  },
  'WAFRegional:listActivatedRulesInRuleGroup': {
    RuleGroupId: '<optional>',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listByteMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listGeoMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listIPSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listLoggingConfigurations': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listRateBasedRules': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listRegexMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listRegexPatternSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listResourcesForWebACL': {
    WebACLId: 'required',
    ResourceType: '<optional>',
  },
  'WAFRegional:listRuleGroups': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listRules': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listSizeConstraintSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listSqlInjectionMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listSubscribedRuleGroups': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listTagsForResource': {
    ResourceARN: 'required',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listWebACLs': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:listXssMatchSets': {
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFRegional:putLoggingConfiguration': {
    LoggingConfiguration: {
      RedactedFields: [
        {
          Data: ['<optional1>', '<optional2>'],
        },
      ],
    },
  },
  'WAFRegional:putPermissionPolicy': {
    ResourceArn: 'required',
    Policy: 'required',
  },
  'WAFRegional:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: '<optional>',
        Value: '<optional>',
      },
    ],
  },
  'WAFRegional:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'WAFRegional:updateByteMatchSet': {
    ByteMatchSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        ByteMatchTuple: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAFRegional:updateGeoMatchSet': {
    GeoMatchSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        Action: 'required',
        GeoMatchConstraint: {
          Type: 'required',
          Value: 'required',
        },
      },
    ],
  },
  'WAFRegional:updateIPSet': {
    IPSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        Action: 'required',
        IPSetDescriptor: {
          Type: 'required',
          Value: 'required',
        },
      },
    ],
  },
  'WAFRegional:updateRateBasedRule': {
    RuleId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        Action: 'required',
        Predicate: {
          Negated: 'required',
          Type: 'required',
          DataId: 'required',
        },
      },
    ],
    RateLimit: 'required',
  },
  'WAFRegional:updateRegexMatchSet': {
    RegexMatchSetId: 'required',
    Updates: [
      {
        RegexMatchTuple: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
    ChangeToken: 'required',
  },
  'WAFRegional:updateRegexPatternSet': {
    RegexPatternSetId: 'required',
    Updates: [
      {
        Action: 'required',
        RegexPatternString: 'required',
      },
    ],
    ChangeToken: 'required',
  },
  'WAFRegional:updateRule': {
    RuleId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        Action: 'required',
        Predicate: {
          Negated: 'required',
          Type: 'required',
          DataId: 'required',
        },
      },
    ],
  },
  'WAFRegional:updateRuleGroup': {
    RuleGroupId: 'required',
    Updates: [
      {
        ActivatedRule: {
          Action: '<optional>',
          OverrideAction: '<optional>',
          Type: '<optional>',
          ExcludedRules: ['<optional1>', '<optional2>'],
        },
      },
    ],
    ChangeToken: 'required',
  },
  'WAFRegional:updateSizeConstraintSet': {
    SizeConstraintSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        SizeConstraint: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAFRegional:updateSqlInjectionMatchSet': {
    SqlInjectionMatchSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        SqlInjectionMatchTuple: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAFRegional:updateWebACL': {
    WebACLId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        ActivatedRule: {
          Action: '<optional>',
          OverrideAction: '<optional>',
          Type: '<optional>',
          ExcludedRules: ['<optional1>', '<optional2>'],
        },
      },
    ],
    DefaultAction: '<optional>',
  },
  'WAFRegional:updateXssMatchSet': {
    XssMatchSetId: 'required',
    ChangeToken: 'required',
    Updates: [
      {
        XssMatchTuple: {
          FieldToMatch: {
            Data: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAFV2:associateWebACL': {
    WebACLArn: 'required',
    ResourceArn: 'required',
  },
  'WAFV2:checkCapacity': {
    Scope: 'required',
    Rules: [
      {
        Action: {
          Block: '<optional>',
          Allow: '<optional>',
          Count: '<optional>',
        },
        OverrideAction: {
          Count: '<optional>',
          None: '<optional>',
        },
        Statement: {
          ByteMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: ['<optional1>', '<optional2>'],
              UriPath: '<optional>',
              QueryString: '<optional>',
              Body: '<optional>',
              Method: '<optional>',
            },
          },
          SqliMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: ['<optional1>', '<optional2>'],
              UriPath: '<optional>',
              QueryString: '<optional>',
              Body: '<optional>',
              Method: '<optional>',
            },
          },
          XssMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: ['<optional1>', '<optional2>'],
              UriPath: '<optional>',
              QueryString: '<optional>',
              Body: '<optional>',
              Method: '<optional>',
            },
          },
          SizeConstraintStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: ['<optional1>', '<optional2>'],
              UriPath: '<optional>',
              QueryString: '<optional>',
              Body: '<optional>',
              Method: '<optional>',
            },
          },
          GeoMatchStatement: {
            CountryCodes: ['<optional1>', '<optional2>'],
          },
          RuleGroupReferenceStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
          IPSetReferenceStatement: '<optional>',
          RegexPatternSetReferenceStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: ['<optional1>', '<optional2>'],
              UriPath: '<optional>',
              QueryString: '<optional>',
              Body: '<optional>',
              Method: '<optional>',
            },
          },
          RateBasedStatement: {
            ScopeDownStatement: '<optional>',
          },
          AndStatement: '<optional>',
          OrStatement: '<optional>',
          NotStatement: '<optional>',
          ManagedRuleGroupStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
  },
  'WAFV2:createIPSet': {
    Name: 'required',
    Scope: 'required',
    IPAddressVersion: 'required',
    Addresses: ['required1', 'required2'],
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
  },
  'WAFV2:createRegexPatternSet': {
    Name: 'required',
    Scope: 'required',
    RegularExpressionList: {
      RegexString: '<optional>',
    },
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
  },
  'WAFV2:createRuleGroup': {
    Name: 'required',
    Scope: 'required',
    Capacity: 'required',
    VisibilityConfig: {
      SampledRequestsEnabled: 'required',
      CloudWatchMetricsEnabled: 'required',
      MetricName: 'required',
    },
    Rules: [
      {
        Action: {
          Block: {
            type: '<optional>',
          },
          Allow: {
            type: '<optional>',
          },
          Count: {
            type: '<optional>',
          },
        },
        OverrideAction: {
          Count: {
            type: '<optional>',
          },
          None: {
            type: '<optional>',
          },
        },
        Statement: {
          ByteMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          SqliMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          XssMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          SizeConstraintStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          GeoMatchStatement: {
            CountryCodes: ['<optional1>', '<optional2>'],
          },
          RuleGroupReferenceStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
          IPSetReferenceStatement: '<optional>',
          RegexPatternSetReferenceStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          RateBasedStatement: {
            ScopeDownStatement: '<optional>',
          },
          AndStatement: '<optional>',
          OrStatement: '<optional>',
          NotStatement: '<optional>',
          ManagedRuleGroupStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
  },
  'WAFV2:createWebACL': {
    Name: 'required',
    Scope: 'required',
    DefaultAction: {
      Block: {
        type: '<optional>',
      },
      Allow: {
        type: '<optional>',
      },
    },
    VisibilityConfig: {
      SampledRequestsEnabled: 'required',
      CloudWatchMetricsEnabled: 'required',
      MetricName: 'required',
    },
    Rules: [
      {
        Action: {
          Block: {
            type: '<optional>',
          },
          Allow: {
            type: '<optional>',
          },
          Count: {
            type: '<optional>',
          },
        },
        OverrideAction: {
          Count: {
            type: '<optional>',
          },
          None: {
            type: '<optional>',
          },
        },
        Statement: {
          ByteMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          SqliMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          XssMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          SizeConstraintStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          GeoMatchStatement: {
            CountryCodes: ['<optional1>', '<optional2>'],
          },
          RuleGroupReferenceStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
          IPSetReferenceStatement: '<optional>',
          RegexPatternSetReferenceStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          RateBasedStatement: {
            ScopeDownStatement: '<optional>',
          },
          AndStatement: '<optional>',
          OrStatement: '<optional>',
          NotStatement: '<optional>',
          ManagedRuleGroupStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
    Tags: ['<optional1>', '<optional2>'],
    Description: '<optional>',
  },
  'WAFV2:deleteIPSet': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
    LockToken: 'required',
  },
  'WAFV2:deleteLoggingConfiguration': {
    ResourceArn: 'required',
  },
  'WAFV2:deleteRegexPatternSet': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
    LockToken: 'required',
  },
  'WAFV2:deleteRuleGroup': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
    LockToken: 'required',
  },
  'WAFV2:deleteWebACL': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
    LockToken: 'required',
  },
  'WAFV2:describeManagedRuleGroup': {
    VendorName: 'required',
    Name: 'required',
    Scope: 'required',
  },
  'WAFV2:disassociateWebACL': {
    ResourceArn: 'required',
  },
  'WAFV2:getIPSet': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
  },
  'WAFV2:getLoggingConfiguration': {
    ResourceArn: 'required',
  },
  'WAFV2:getRateBasedStatementManagedKeys': {
    Scope: 'required',
    WebACLName: 'required',
    WebACLId: 'required',
    RuleName: 'required',
  },
  'WAFV2:getRegexPatternSet': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
  },
  'WAFV2:getRuleGroup': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
  },
  'WAFV2:getSampledRequests': {
    WebAclArn: 'required',
    RuleMetricName: 'required',
    Scope: 'required',
    TimeWindow: {
      StartTime: 'required',
      EndTime: 'required',
    },
  },
  'WAFV2:getWebACL': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
  },
  'WAFV2:getWebACLForResource': {
    ResourceArn: 'required',
  },
  'WAFV2:listAvailableManagedRuleGroups': {
    Scope: 'required',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFV2:listIPSets': {
    Scope: 'required',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFV2:listLoggingConfigurations': {
    Scope: '<optional>',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFV2:listRegexPatternSets': {
    Scope: 'required',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFV2:listResourcesForWebACL': {
    WebACLArn: 'required',
    ResourceType: '<optional>',
  },
  'WAFV2:listRuleGroups': {
    Scope: 'required',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFV2:listTagsForResource': {
    ResourceARN: 'required',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFV2:listWebACLs': {
    Scope: 'required',
    NextMarker: '<optional>',
    Limit: '<optional>',
  },
  'WAFV2:putLoggingConfiguration': {
    LoggingConfiguration: {
      RedactedFields: [
        {
          SingleHeader: '<optional>',
          SingleQueryArgument: '<optional>',
          AllQueryArguments: [
            {
              type: '<optional>',
            },
          ],
          UriPath: {
            type: '<optional>',
          },
          QueryString: {
            type: '<optional>',
          },
          Body: {
            type: '<optional>',
          },
          Method: {
            type: '<optional>',
          },
        },
      ],
    },
  },
  'WAFV2:tagResource': {
    ResourceARN: 'required',
    Tags: [
      {
        Key: 'required',
        Value: 'required',
      },
    ],
  },
  'WAFV2:untagResource': {
    ResourceARN: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'WAFV2:updateIPSet': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
    Addresses: ['required1', 'required2'],
    LockToken: 'required',
    Description: '<optional>',
  },
  'WAFV2:updateRegexPatternSet': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
    RegularExpressionList: {
      RegexString: '<optional>',
    },
    LockToken: 'required',
    Description: '<optional>',
  },
  'WAFV2:updateRuleGroup': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
    VisibilityConfig: {
      SampledRequestsEnabled: 'required',
      CloudWatchMetricsEnabled: 'required',
      MetricName: 'required',
    },
    LockToken: 'required',
    Rules: [
      {
        Action: {
          Block: {
            type: '<optional>',
          },
          Allow: {
            type: '<optional>',
          },
          Count: {
            type: '<optional>',
          },
        },
        OverrideAction: {
          Count: {
            type: '<optional>',
          },
          None: {
            type: '<optional>',
          },
        },
        Statement: {
          ByteMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          SqliMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          XssMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          SizeConstraintStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          GeoMatchStatement: {
            CountryCodes: ['<optional1>', '<optional2>'],
          },
          RuleGroupReferenceStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
          IPSetReferenceStatement: '<optional>',
          RegexPatternSetReferenceStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          RateBasedStatement: {
            ScopeDownStatement: '<optional>',
          },
          AndStatement: '<optional>',
          OrStatement: '<optional>',
          NotStatement: '<optional>',
          ManagedRuleGroupStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
    Description: '<optional>',
  },
  'WAFV2:updateWebACL': {
    Name: 'required',
    Scope: 'required',
    Id: 'required',
    DefaultAction: {
      Block: {
        type: '<optional>',
      },
      Allow: {
        type: '<optional>',
      },
    },
    VisibilityConfig: {
      SampledRequestsEnabled: 'required',
      CloudWatchMetricsEnabled: 'required',
      MetricName: 'required',
    },
    LockToken: 'required',
    Rules: [
      {
        Action: {
          Block: {
            type: '<optional>',
          },
          Allow: {
            type: '<optional>',
          },
          Count: {
            type: '<optional>',
          },
        },
        OverrideAction: {
          Count: {
            type: '<optional>',
          },
          None: {
            type: '<optional>',
          },
        },
        Statement: {
          ByteMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          SqliMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          XssMatchStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          SizeConstraintStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          GeoMatchStatement: {
            CountryCodes: ['<optional1>', '<optional2>'],
          },
          RuleGroupReferenceStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
          IPSetReferenceStatement: '<optional>',
          RegexPatternSetReferenceStatement: {
            FieldToMatch: {
              SingleHeader: '<optional>',
              SingleQueryArgument: '<optional>',
              AllQueryArguments: [
                {
                  type: '<optional>',
                },
              ],
              UriPath: {
                type: '<optional>',
              },
              QueryString: {
                type: '<optional>',
              },
              Body: {
                type: '<optional>',
              },
              Method: {
                type: '<optional>',
              },
            },
          },
          RateBasedStatement: {
            ScopeDownStatement: '<optional>',
          },
          AndStatement: '<optional>',
          OrStatement: '<optional>',
          NotStatement: '<optional>',
          ManagedRuleGroupStatement: {
            ExcludedRules: ['<optional1>', '<optional2>'],
          },
        },
      },
    ],
    Description: '<optional>',
  },
  'WorkDocs:abortDocumentVersionUpload': {
    DocumentId: 'required',
    VersionId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:activateUser': {
    UserId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:addResourcePermissions': {
    ResourceId: 'required',
    Principals: [
      {
        Id: 'required',
        Type: 'required',
        Role: 'required',
      },
    ],
    AuthenticationToken: '<optional>',
    NotificationOptions: [
      {
        SendEmail: '<optional>',
        EmailMessage: '<optional>',
      },
    ],
  },
  'WorkDocs:createComment': {
    DocumentId: 'required',
    VersionId: 'required',
    Text: 'required',
    AuthenticationToken: '<optional>',
    ParentId: '<optional>',
    ThreadId: '<optional>',
    Visibility: '<optional>',
    NotifyCollaborators: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:createCustomMetadata': {
    ResourceId: 'required',
    CustomMetadata: ['required1', 'required2'],
    AuthenticationToken: '<optional>',
    VersionId: '<optional>',
  },
  'WorkDocs:createFolder': {
    ParentFolderId: 'required',
    AuthenticationToken: '<optional>',
    Name: '<optional>',
  },
  'WorkDocs:createLabels': {
    ResourceId: 'required',
    Labels: ['required1', 'required2'],
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:createNotificationSubscription': {
    OrganizationId: 'required',
    Endpoint: 'required',
    Protocol: 'required',
    SubscriptionType: 'required',
  },
  'WorkDocs:createUser': {
    Username: 'required',
    GivenName: 'required',
    Surname: 'required',
    Password: 'required',
    OrganizationId: '<optional>',
    EmailAddress: '<optional>',
    TimeZoneId: '<optional>',
    StorageRule: {
      StorageAllocatedInBytes: ['<optional1>', '<optional2>'],
      StorageType: '<optional>',
    },
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:deactivateUser': {
    UserId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:deleteComment': {
    DocumentId: 'required',
    VersionId: 'required',
    CommentId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:deleteCustomMetadata': {
    ResourceId: 'required',
    AuthenticationToken: '<optional>',
    VersionId: '<optional>',
    Keys: ['<optional1>', '<optional2>'],
    DeleteAll: '<optional>',
  },
  'WorkDocs:deleteDocument': {
    DocumentId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:deleteFolder': {
    FolderId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:deleteFolderContents': {
    FolderId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:deleteLabels': {
    ResourceId: 'required',
    AuthenticationToken: '<optional>',
    Labels: ['<optional1>', '<optional2>'],
    DeleteAll: '<optional>',
  },
  'WorkDocs:deleteNotificationSubscription': {
    SubscriptionId: 'required',
    OrganizationId: 'required',
  },
  'WorkDocs:deleteUser': {
    UserId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:describeActivities': {
    AuthenticationToken: '<optional>',
    StartTime: '<optional>',
    EndTime: '<optional>',
    OrganizationId: '<optional>',
    ActivityTypes: ['<optional1>', '<optional2>'],
    ResourceId: '<optional>',
    UserId: '<optional>',
    IncludeIndirectActivities: ['<optional1>', '<optional2>'],
    Limit: '<optional>',
  },
  'WorkDocs:describeComments': {
    DocumentId: 'required',
    VersionId: 'required',
    AuthenticationToken: '<optional>',
    Limit: '<optional>',
  },
  'WorkDocs:describeDocumentVersions': {
    DocumentId: 'required',
    AuthenticationToken: '<optional>',
    Limit: '<optional>',
    Include: '<optional>',
    Fields: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:describeFolderContents': {
    FolderId: 'required',
    AuthenticationToken: '<optional>',
    Sort: '<optional>',
    Order: '<optional>',
    Limit: '<optional>',
    Type: '<optional>',
    Include: '<optional>',
  },
  'WorkDocs:describeGroups': {
    SearchQuery: 'required',
    AuthenticationToken: '<optional>',
    OrganizationId: '<optional>',
    Limit: '<optional>',
  },
  'WorkDocs:describeNotificationSubscriptions': {
    OrganizationId: 'required',
    Limit: '<optional>',
  },
  'WorkDocs:describeResourcePermissions': {
    ResourceId: 'required',
    AuthenticationToken: '<optional>',
    PrincipalId: '<optional>',
    Limit: '<optional>',
  },
  'WorkDocs:describeRootFolders': {
    AuthenticationToken: 'required',
    Limit: '<optional>',
  },
  'WorkDocs:describeUsers': {
    AuthenticationToken: '<optional>',
    OrganizationId: '<optional>',
    UserIds: ['<optional1>', '<optional2>'],
    Query: '<optional>',
    Include: '<optional>',
    Order: '<optional>',
    Sort: '<optional>',
    Limit: '<optional>',
    Fields: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:getCurrentUser': {
    AuthenticationToken: 'required',
  },
  'WorkDocs:getDocument': {
    DocumentId: 'required',
    AuthenticationToken: '<optional>',
    IncludeCustomMetadata: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:getDocumentPath': {
    DocumentId: 'required',
    AuthenticationToken: '<optional>',
    Limit: '<optional>',
    Fields: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:getDocumentVersion': {
    DocumentId: 'required',
    VersionId: 'required',
    AuthenticationToken: '<optional>',
    Fields: ['<optional1>', '<optional2>'],
    IncludeCustomMetadata: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:getFolder': {
    FolderId: 'required',
    AuthenticationToken: '<optional>',
    IncludeCustomMetadata: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:getFolderPath': {
    FolderId: 'required',
    AuthenticationToken: '<optional>',
    Limit: '<optional>',
    Fields: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:getResources': {
    AuthenticationToken: '<optional>',
    UserId: '<optional>',
    CollectionType: '<optional>',
    Limit: '<optional>',
  },
  'WorkDocs:initiateDocumentVersionUpload': {
    ParentFolderId: 'required',
    AuthenticationToken: '<optional>',
    Id: '<optional>',
    Name: '<optional>',
    ContentCreatedTimestamp: '<optional>',
    ContentModifiedTimestamp: '<optional>',
    ContentType: '<optional>',
    DocumentSizeInBytes: ['<optional1>', '<optional2>'],
  },
  'WorkDocs:removeAllResourcePermissions': {
    ResourceId: 'required',
    AuthenticationToken: '<optional>',
  },
  'WorkDocs:removeResourcePermission': {
    ResourceId: 'required',
    PrincipalId: 'required',
    AuthenticationToken: '<optional>',
    PrincipalType: '<optional>',
  },
  'WorkDocs:updateDocument': {
    DocumentId: 'required',
    AuthenticationToken: '<optional>',
    Name: '<optional>',
    ParentFolderId: '<optional>',
    ResourceState: '<optional>',
  },
  'WorkDocs:updateDocumentVersion': {
    DocumentId: 'required',
    VersionId: 'required',
    AuthenticationToken: '<optional>',
    VersionStatus: '<optional>',
  },
  'WorkDocs:updateFolder': {
    FolderId: 'required',
    AuthenticationToken: '<optional>',
    Name: '<optional>',
    ParentFolderId: '<optional>',
    ResourceState: '<optional>',
  },
  'WorkDocs:updateUser': {
    UserId: 'required',
    AuthenticationToken: '<optional>',
    GivenName: '<optional>',
    Surname: '<optional>',
    Type: '<optional>',
    StorageRule: {
      StorageAllocatedInBytes: ['<optional1>', '<optional2>'],
      StorageType: '<optional>',
    },
    TimeZoneId: '<optional>',
    Locale: '<optional>',
    GrantPoweruserPrivileges: ['<optional1>', '<optional2>'],
  },
  'WorkLink:associateDomain': {
    FleetArn: 'required',
    DomainName: 'required',
    AcmCertificateArn: 'required',
    DisplayName: '<optional>',
  },
  'WorkLink:associateWebsiteAuthorizationProvider': {
    FleetArn: 'required',
    AuthorizationProviderType: 'required',
    DomainName: '<optional>',
  },
  'WorkLink:associateWebsiteCertificateAuthority': {
    FleetArn: 'required',
    Certificate: 'required',
    DisplayName: '<optional>',
  },
  'WorkLink:createFleet': {
    FleetName: 'required',
    DisplayName: '<optional>',
    OptimizeForEndUserLocation: '<optional>',
  },
  'WorkLink:deleteFleet': {
    FleetArn: 'required',
  },
  'WorkLink:describeAuditStreamConfiguration': {
    FleetArn: 'required',
  },
  'WorkLink:describeCompanyNetworkConfiguration': {
    FleetArn: 'required',
  },
  'WorkLink:describeDevice': {
    FleetArn: 'required',
    DeviceId: 'required',
  },
  'WorkLink:describeDevicePolicyConfiguration': {
    FleetArn: 'required',
  },
  'WorkLink:describeDomain': {
    FleetArn: 'required',
    DomainName: 'required',
  },
  'WorkLink:describeFleetMetadata': {
    FleetArn: 'required',
  },
  'WorkLink:describeIdentityProviderConfiguration': {
    FleetArn: 'required',
  },
  'WorkLink:describeWebsiteCertificateAuthority': {
    FleetArn: 'required',
    WebsiteCaId: 'required',
  },
  'WorkLink:disassociateDomain': {
    FleetArn: 'required',
    DomainName: 'required',
  },
  'WorkLink:disassociateWebsiteAuthorizationProvider': {
    FleetArn: 'required',
    AuthorizationProviderId: 'required',
  },
  'WorkLink:disassociateWebsiteCertificateAuthority': {
    FleetArn: 'required',
    WebsiteCaId: 'required',
  },
  'WorkLink:listDevices': {
    FleetArn: 'required',
  },
  'WorkLink:listDomains': {
    FleetArn: 'required',
  },
  'WorkLink:listFleets': {},
  'WorkLink:listWebsiteAuthorizationProviders': {
    FleetArn: 'required',
  },
  'WorkLink:listWebsiteCertificateAuthorities': {
    FleetArn: 'required',
  },
  'WorkLink:restoreDomainAccess': {
    FleetArn: 'required',
    DomainName: 'required',
  },
  'WorkLink:revokeDomainAccess': {
    FleetArn: 'required',
    DomainName: 'required',
  },
  'WorkLink:signOutUser': {
    FleetArn: 'required',
    Username: 'required',
  },
  'WorkLink:updateAuditStreamConfiguration': {
    FleetArn: 'required',
    AuditStreamArn: '<optional>',
  },
  'WorkLink:updateCompanyNetworkConfiguration': {
    FleetArn: 'required',
    VpcId: 'required',
    SubnetIds: ['required1', 'required2'],
    SecurityGroupIds: ['required1', 'required2'],
  },
  'WorkLink:updateDevicePolicyConfiguration': {
    FleetArn: 'required',
    DeviceCaCertificate: '<optional>',
  },
  'WorkLink:updateDomainMetadata': {
    FleetArn: 'required',
    DomainName: 'required',
    DisplayName: '<optional>',
  },
  'WorkLink:updateFleetMetadata': {
    FleetArn: 'required',
    DisplayName: '<optional>',
    OptimizeForEndUserLocation: '<optional>',
  },
  'WorkLink:updateIdentityProviderConfiguration': {
    FleetArn: 'required',
    IdentityProviderType: 'required',
    IdentityProviderSamlMetadata: ['<optional1>', '<optional2>'],
  },
  'WorkMail:associateDelegateToResource': {
    OrganizationId: 'required',
    ResourceId: 'required',
    EntityId: 'required',
  },
  'WorkMail:associateMemberToGroup': {
    OrganizationId: 'required',
    GroupId: 'required',
    MemberId: 'required',
  },
  'WorkMail:createAlias': {
    OrganizationId: 'required',
    EntityId: 'required',
    Alias: 'required',
  },
  'WorkMail:createGroup': {
    OrganizationId: 'required',
    Name: 'required',
  },
  'WorkMail:createResource': {
    OrganizationId: 'required',
    Name: 'required',
    Type: 'required',
  },
  'WorkMail:createUser': {
    OrganizationId: 'required',
    Name: 'required',
    DisplayName: 'required',
    Password: 'required',
  },
  'WorkMail:deleteAlias': {
    OrganizationId: 'required',
    EntityId: 'required',
    Alias: 'required',
  },
  'WorkMail:deleteGroup': {
    OrganizationId: 'required',
    GroupId: 'required',
  },
  'WorkMail:deleteMailboxPermissions': {
    OrganizationId: 'required',
    EntityId: 'required',
    GranteeId: 'required',
  },
  'WorkMail:deleteResource': {
    OrganizationId: 'required',
    ResourceId: 'required',
  },
  'WorkMail:deleteUser': {
    OrganizationId: 'required',
    UserId: 'required',
  },
  'WorkMail:deregisterFromWorkMail': {
    OrganizationId: 'required',
    EntityId: 'required',
  },
  'WorkMail:describeGroup': {
    OrganizationId: 'required',
    GroupId: 'required',
  },
  'WorkMail:describeOrganization': {
    OrganizationId: 'required',
  },
  'WorkMail:describeResource': {
    OrganizationId: 'required',
    ResourceId: 'required',
  },
  'WorkMail:describeUser': {
    OrganizationId: 'required',
    UserId: 'required',
  },
  'WorkMail:disassociateDelegateFromResource': {
    OrganizationId: 'required',
    ResourceId: 'required',
    EntityId: 'required',
  },
  'WorkMail:disassociateMemberFromGroup': {
    OrganizationId: 'required',
    GroupId: 'required',
    MemberId: 'required',
  },
  'WorkMail:getMailboxDetails': {
    OrganizationId: 'required',
    UserId: 'required',
  },
  'WorkMail:listAliases': {
    OrganizationId: 'required',
    EntityId: 'required',
  },
  'WorkMail:listGroupMembers': {
    OrganizationId: 'required',
    GroupId: 'required',
  },
  'WorkMail:listGroups': {
    OrganizationId: 'required',
  },
  'WorkMail:listMailboxPermissions': {
    OrganizationId: 'required',
    EntityId: 'required',
  },
  'WorkMail:listOrganizations': {},
  'WorkMail:listResourceDelegates': {
    OrganizationId: 'required',
    ResourceId: 'required',
  },
  'WorkMail:listResources': {
    OrganizationId: 'required',
  },
  'WorkMail:listUsers': {
    OrganizationId: 'required',
  },
  'WorkMail:putMailboxPermissions': {
    OrganizationId: 'required',
    EntityId: 'required',
    GranteeId: 'required',
    PermissionValues: ['required1', 'required2'],
  },
  'WorkMail:registerToWorkMail': {
    OrganizationId: 'required',
    EntityId: 'required',
    Email: 'required',
  },
  'WorkMail:resetPassword': {
    OrganizationId: 'required',
    UserId: 'required',
    Password: 'required',
  },
  'WorkMail:updateMailboxQuota': {
    OrganizationId: 'required',
    UserId: 'required',
    MailboxQuota: 'required',
  },
  'WorkMail:updatePrimaryEmailAddress': {
    OrganizationId: 'required',
    EntityId: 'required',
    Email: 'required',
  },
  'WorkMail:updateResource': {
    OrganizationId: 'required',
    ResourceId: 'required',
    Name: '<optional>',
    BookingOptions: [
      {
        AutoAcceptRequests: ['<optional1>', '<optional2>'],
        AutoDeclineRecurringRequests: ['<optional1>', '<optional2>'],
        AutoDeclineConflictingRequests: ['<optional1>', '<optional2>'],
      },
    ],
  },
  'WorkMailMessageFlow:getRawMessageContent': {
    messageId: 'required',
  },
  'WorkSpaces:associateIpGroups': {
    DirectoryId: 'required',
    GroupIds: ['required1', 'required2'],
  },
  'WorkSpaces:authorizeIpRules': {
    GroupId: 'required',
    UserRules: [
      {
        ipRule: '<optional>',
        ruleDesc: '<optional>',
      },
    ],
  },
  'WorkSpaces:copyWorkspaceImage': {
    Name: 'required',
    SourceImageId: 'required',
    SourceRegion: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    Description: '<optional>',
  },
  'WorkSpaces:createIpGroup': {
    GroupName: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    GroupDesc: '<optional>',
    UserRules: [
      {
        ipRule: '<optional>',
        ruleDesc: '<optional>',
      },
    ],
  },
  'WorkSpaces:createTags': {
    ResourceId: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'WorkSpaces:createWorkspaces': {
    Workspaces: [
      {
        VolumeEncryptionKey: '<optional>',
        UserVolumeEncryptionEnabled: '<optional>',
        RootVolumeEncryptionEnabled: '<optional>',
        WorkspaceProperties: [
          {
            RunningMode: '<optional>',
            RunningModeAutoStopTimeoutInMinutes: ['<optional1>', '<optional2>'],
            RootVolumeSizeGib: '<optional>',
            UserVolumeSizeGib: '<optional>',
            ComputeTypeName: '<optional>',
          },
        ],
        Tags: [
          {
            Value: '<optional>',
          },
        ],
      },
    ],
  },
  'WorkSpaces:deleteIpGroup': {
    GroupId: 'required',
  },
  'WorkSpaces:deleteTags': {
    ResourceId: 'required',
    TagKeys: ['required1', 'required2'],
  },
  'WorkSpaces:deleteWorkspaceImage': {
    ImageId: 'required',
  },
  'WorkSpaces:deregisterWorkspaceDirectory': {
    DirectoryId: 'required',
  },
  'WorkSpaces:describeAccount': {},
  'WorkSpaces:describeAccountModifications': {},
  'WorkSpaces:describeClientProperties': {
    ResourceIds: ['required1', 'required2'],
  },
  'WorkSpaces:describeIpGroups': {
    GroupIds: ['<optional1>', '<optional2>'],
  },
  'WorkSpaces:describeTags': {
    ResourceId: 'required',
  },
  'WorkSpaces:describeWorkspaceBundles': {
    BundleIds: ['<optional1>', '<optional2>'],
    Owner: '<optional>',
  },
  'WorkSpaces:describeWorkspaceDirectories': {
    DirectoryIds: ['<optional1>', '<optional2>'],
  },
  'WorkSpaces:describeWorkspaceImages': {
    ImageIds: ['<optional1>', '<optional2>'],
  },
  'WorkSpaces:describeWorkspaceSnapshots': {
    WorkspaceId: 'required',
  },
  'WorkSpaces:describeWorkspaces': {
    WorkspaceIds: ['<optional1>', '<optional2>'],
    DirectoryId: '<optional>',
    UserName: '<optional>',
    BundleId: '<optional>',
    Limit: '<optional>',
  },
  'WorkSpaces:describeWorkspacesConnectionStatus': {
    WorkspaceIds: ['<optional1>', '<optional2>'],
  },
  'WorkSpaces:disassociateIpGroups': {
    DirectoryId: 'required',
    GroupIds: ['required1', 'required2'],
  },
  'WorkSpaces:importWorkspaceImage': {
    Ec2ImageId: 'required',
    IngestionProcess: 'required',
    ImageName: 'required',
    ImageDescription: 'required',
    Tags: [
      {
        Value: '<optional>',
      },
    ],
  },
  'WorkSpaces:listAvailableManagementCidrRanges': {
    ManagementCidrRangeConstraint: 'required',
  },
  'WorkSpaces:modifyAccount': {
    DedicatedTenancySupport: '<optional>',
    DedicatedTenancyManagementCidrRange: '<optional>',
  },
  'WorkSpaces:modifyClientProperties': {
    ResourceId: 'required',
    ClientProperties: [
      {
        ReconnectEnabled: '<optional>',
      },
    ],
  },
  'WorkSpaces:modifySelfservicePermissions': {
    ResourceId: 'required',
    SelfservicePermissions: [
      {
        RestartWorkspace: '<optional>',
        IncreaseVolumeSize: '<optional>',
        ChangeComputeType: '<optional>',
        SwitchRunningMode: '<optional>',
        RebuildWorkspace: '<optional>',
      },
    ],
  },
  'WorkSpaces:modifyWorkspaceAccessProperties': {
    ResourceId: 'required',
    WorkspaceAccessProperties: [
      {
        DeviceTypeWindows: ['<optional1>', '<optional2>'],
        DeviceTypeOsx: '<optional>',
        DeviceTypeWeb: '<optional>',
        DeviceTypeIos: ['<optional1>', '<optional2>'],
        DeviceTypeAndroid: '<optional>',
        DeviceTypeChromeOs: ['<optional1>', '<optional2>'],
        DeviceTypeZeroClient: '<optional>',
      },
    ],
  },
  'WorkSpaces:modifyWorkspaceCreationProperties': {
    ResourceId: 'required',
    WorkspaceCreationProperties: [
      {
        EnableInternetAccess: '<optional>',
        DefaultOu: '<optional>',
        CustomSecurityGroupId: '<optional>',
        UserEnabledAsLocalAdministrator: '<optional>',
        EnableMaintenanceMode: '<optional>',
      },
    ],
  },
  'WorkSpaces:modifyWorkspaceProperties': {
    WorkspaceId: 'required',
    WorkspaceProperties: [
      {
        RunningMode: '<optional>',
        RunningModeAutoStopTimeoutInMinutes: ['<optional1>', '<optional2>'],
        RootVolumeSizeGib: '<optional>',
        UserVolumeSizeGib: '<optional>',
        ComputeTypeName: '<optional>',
      },
    ],
  },
  'WorkSpaces:modifyWorkspaceState': {
    WorkspaceId: 'required',
    WorkspaceState: 'required',
  },
  'WorkSpaces:rebootWorkspaces': {
    RebootWorkspaceRequests: [
      {
        WorkspaceId: 'required',
      },
    ],
  },
  'WorkSpaces:rebuildWorkspaces': {
    RebuildWorkspaceRequests: [
      {
        WorkspaceId: 'required',
      },
    ],
  },
  'WorkSpaces:registerWorkspaceDirectory': {
    DirectoryId: 'required',
    EnableWorkDocs: ['required1', 'required2'],
    Tags: [
      {
        Value: '<optional>',
      },
    ],
    SubnetIds: ['<optional1>', '<optional2>'],
    EnableSelfService: '<optional>',
    Tenancy: '<optional>',
  },
  'WorkSpaces:restoreWorkspace': {
    WorkspaceId: 'required',
  },
  'WorkSpaces:revokeIpRules': {
    GroupId: 'required',
    UserRules: ['required1', 'required2'],
  },
  'WorkSpaces:startWorkspaces': {
    StartWorkspaceRequests: [
      {
        WorkspaceId: '<optional>',
      },
    ],
  },
  'WorkSpaces:stopWorkspaces': {
    StopWorkspaceRequests: [
      {
        WorkspaceId: '<optional>',
      },
    ],
  },
  'WorkSpaces:terminateWorkspaces': {
    TerminateWorkspaceRequests: [
      {
        WorkspaceId: 'required',
      },
    ],
  },
  'WorkSpaces:updateRulesOfIpGroup': {
    GroupId: 'required',
    UserRules: [
      {
        ipRule: '<optional>',
        ruleDesc: '<optional>',
      },
    ],
  },
  'XRay:batchGetTraces': {
    TraceIds: ['required1', 'required2'],
  },
  'XRay:createGroup': {
    GroupName: 'required',
    FilterExpression: '<optional>',
  },
  'XRay:createSamplingRule': {
    SamplingRule: {
      RuleName: '<optional>',
      RuleARN: '<optional>',
      Attributes: ['<optional1>', '<optional2>'],
    },
  },
  'XRay:deleteGroup': {
    GroupName: '<optional>',
    GroupARN: '<optional>',
  },
  'XRay:deleteSamplingRule': {
    RuleName: '<optional>',
    RuleARN: '<optional>',
  },
  'XRay:getEncryptionConfig': {},
  'XRay:getGroup': {
    GroupName: '<optional>',
    GroupARN: '<optional>',
  },
  'XRay:getGroups': {},
  'XRay:getSamplingRules': {},
  'XRay:getSamplingStatisticSummaries': {},
  'XRay:getSamplingTargets': {
    SamplingStatisticsDocuments: [
      {
        BorrowCount: '<optional>',
      },
    ],
  },
  'XRay:getServiceGraph': {
    StartTime: 'required',
    EndTime: 'required',
    GroupName: '<optional>',
    GroupARN: '<optional>',
  },
  'XRay:getTimeSeriesServiceStatistics': {
    StartTime: 'required',
    EndTime: 'required',
    GroupName: '<optional>',
    GroupARN: '<optional>',
    EntitySelectorExpression: '<optional>',
    Period: '<optional>',
  },
  'XRay:getTraceGraph': {
    TraceIds: ['required1', 'required2'],
  },
  'XRay:getTraceSummaries': {
    StartTime: 'required',
    EndTime: 'required',
    TimeRangeType: '<optional>',
    Sampling: '<optional>',
    SamplingStrategy: {
      Name: '<optional>',
      Value: '<optional>',
    },
    FilterExpression: '<optional>',
  },
  'XRay:putEncryptionConfig': {
    Type: 'required',
    KeyId: '<optional>',
  },
  'XRay:putTelemetryRecords': {
    TelemetryRecords: [
      {
        SegmentsReceivedCount: '<optional>',
        SegmentsSentCount: '<optional>',
        SegmentsSpilloverCount: '<optional>',
        SegmentsRejectedCount: '<optional>',
        BackendConnectionErrors: [
          {
            TimeoutCount: '<optional>',
            ConnectionRefusedCount: '<optional>',
            HTTPCode4XXCount: '<optional>',
            HTTPCode5XXCount: '<optional>',
            UnknownHostCount: '<optional>',
            OtherCount: '<optional>',
          },
        ],
      },
    ],
    EC2InstanceId: '<optional>',
    Hostname: '<optional>',
    ResourceARN: '<optional>',
  },
  'XRay:putTraceSegments': {
    TraceSegmentDocuments: ['required1', 'required2'],
  },
  'XRay:updateGroup': {
    GroupName: '<optional>',
    GroupARN: '<optional>',
    FilterExpression: '<optional>',
  },
  'XRay:updateSamplingRule': {
    SamplingRuleUpdate: {
      RuleName: '<optional>',
      RuleARN: '<optional>',
      ResourceARN: '<optional>',
      Priority: '<optional>',
      FixedRate: '<optional>',
      ReservoirSize: '<optional>',
      Host: '<optional>',
      ServiceName: '<optional>',
      ServiceType: '<optional>',
      HTTPMethod: '<optional>',
      URLPath: '<optional>',
      Attributes: ['<optional1>', '<optional2>'],
    },
  },
};
