import * as React from 'react';
import { FormikProps } from 'formik';
import { ActionSchema } from './schema';
import { ModalWrapper } from '../../common/ModalWrapper';
import {
  getActionOptionsForService,
  getMappedParamsForService,
} from '../../../utils/EditorUtils';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { actionHelpText } from './help';
import { ActionModelProps } from './ActionNodeModal';

import pluralize from 'pluralize';
import AdvancedFiltersView from '../../common/AdvancedFiltersView';
import InputSelect from '../../../../../formInputs/select-option.component';
import { PageLoader } from '../../../common/Loaders';
import { OutputKey, SelectedResourceType } from 'workflow-model/dist';
import { ReactNode } from 'react';
import { getModalTitle } from '../../common/nodeModalHelper';
import { getAutoCompleteList } from '../helpers/Utils';
import { getVariablesJson } from '../../editorHeader/GlobalVariables';

import api from '../../../../../../api';
import { SchemaValidationError } from '../../../../../formInputs/json-editor';
import { Dispatch } from 'redux';
import { fetchSGCDefinition } from '../../../../../store/sgc/actions';
import CloudProvider from 'workflow-model/dist/types/cloudProvider';
import { AppState } from '../../../../../store';
import { connect } from 'react-redux';

type StateProps = {
  paramsOption: any;
  properties: any;
  isFetchingParams: boolean;
};

type DispatchProps = {
  fetchSGCData: (key: string) => void;
};

interface OwnProps extends FormikProps<ActionSchema>, ActionModelProps {}

type ActionFormProps = OwnProps & DispatchProps & StateProps;

interface ActionFormState {
  lastNodeOutputKeys: OutputKey[];
  actionOptions: { label: string; value: string }[];
  isFetching: boolean;
}

const makeKey = (u: OwnProps) => {
  let serviceObj = u.actions.find((x) => x.service === u.values.service);
  if (!serviceObj) return;
  let resourceTypeObj = serviceObj.actions.find(
    (x) => x.method === u.values.method,
  );
  if (
    resourceTypeObj &&
    resourceTypeObj.resourceType &&
    u.values.method &&
    u.values.service
  ) {
    return `${resourceTypeObj.resourceType}/${u.values.service}/${u.values.method}`;
  }
};

export class ActionFormInner extends React.Component<
  ActionFormProps,
  ActionFormState
> {
  state = {
    isFetching: false,
    lastNodeOutputKeys: [] as OutputKey[],
    actionOptions: getActionOptionsForService(
      this.props.values.service,
      this.props.actions,
    ),
  };

  eventHandlers = {
    onChange: this.props.handleChange,
    onBlur: this.props.handleBlur,
  };

  getFieldValues = (field: keyof ActionSchema) => {
    let value = this.props.values[field];
    let defaultFieldValue: any = '';
    let error = this.props.errors[field] || defaultFieldValue;
    // @ts-ignore
    if (field === 'selectedInputData' && error) {
      error = 'Please select a resource';
    }
    return {
      name: field,
      value: value || defaultFieldValue,
      error: error.toString(),
      touched: this.props.submitCount > 0 || this.props.touched[field] || false,
      placeholder: field,
    };
  };

  fetchSgcData = () => {
    const paramsKey = `${makeKey(this.props)}/params.json`;
    this.props.fetchSGCData(paramsKey);
    const properties = `${makeKey(this.props)}/properties.json`;
    this.props.fetchSGCData(properties);
  };

  componentDidMount(): void {
    if (makeKey(this.props)) {
      this.fetchSgcData();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<ActionFormProps>,
    prevState: Readonly<{}>,
    snapshot?: any,
  ): void {
    // fetch addons and params for select service and method
    if (makeKey(this.props) && makeKey(prevProps) !== makeKey(this.props)) {
      this.fetchSgcData();
    }

    // set default params of not already set.
    if (
      makeKey(this.props) &&
      !this.props.values.params &&
      this.props.paramsOption &&
      !this.state.isFetching
    ) {
      // this.props.handleChange({ target: { name: 'params', value: {paramsValue :this.props.paramsOption  }}});
      this.mapParams();
    }
  }

  setActionOptions(service) {
    // console.log('getting for service', service);
    this.setState({
      actionOptions: getActionOptionsForService(service, this.props.actions),
    });
  }

  onChangeOfAvailableResourcesList(event) {
    let { value, name } = event.target;
    this.props.handleChange(event);
    this.fetchOutputProperties(
      Object.assign({}, this.props.values, { [name]: value }),
    );
  }

  getIndexOfFirstUpperCaseLetter(str) {
    for (let i = 0; i < str.length; i++) {
      let currentChar = str[i];
      if (currentChar.toUpperCase() === currentChar) return i;
    }
    return -1;
  }
  getSelectedResourceName = () => {
    let { selectedResource } = this.props.values;
    if (!selectedResource || selectedResource === 'NONE') return;
    let selectedResourceName = selectedResource.substring(
      selectedResource.lastIndexOf('.') + 1,
    );
    // if 1st letter is lowercase remove the first word.
    if (
      selectedResourceName.charAt(0).toLowerCase() ===
      selectedResourceName.charAt(0)
    ) {
      let index = this.getIndexOfFirstUpperCaseLetter(selectedResourceName);
      if (index !== -1) {
        selectedResourceName = selectedResourceName.substring(index);
      }
    }
    return selectedResourceName;
  };

  /*
      reset value of params, action  and waitForAction on change of service
  */
  onServiceChange(event) {
    this.setActionOptions(event.target.value);
    this.props.handleChange(event);
    this.props.handleChange({ target: { name: 'params', value: null } });
    this.props.handleChange({ target: { name: 'waitForAction', value: '' } });
    this.props.handleChange({ target: { name: 'method', value: '' } });
  }

  /*
      reset params and waitForAction on change of action
  */

  onActionChange(event) {
    this.props.handleChange(event);
    this.props.handleChange({ target: { name: 'waitForAction', value: '' } });
    this.props.handleChange({ target: { name: 'params', value: null } });
  }

  renderWaitForAction(): JSX.Element | null {
    const { service, method, waitForAction } = this.props.values;
    if (
      !method ||
      !service ||
      !this.props.properties ||
      !this.props.properties.waiters ||
      !Object.keys(this.props.properties.waiters).length
    ) {
      return null;
    }

    const waiter: string = Object.keys(this.props.properties.waiters || {})[0];

    const onSelectionModified = (event) => {
      let { name } = event.target;
      const modified = waitForAction === waiter ? '' : waiter;
      this.props.handleChange({ target: { name, value: modified || '' } });
    };

    return (
      <div>
        <div id={'wait_for_input'}>
          <input
            type={'checkbox'}
            {...this.eventHandlers}
            {...this.getFieldValues('waitForAction')}
            onChange={(e) => onSelectionModified(e)}
            checked={!!waitForAction}
          />
          {`Wait for ${method} to finish.`}
        </div>
        <UncontrolledTooltip
          delay={0}
          placement="top"
          target={'wait_for_input'}
        >
          If selected, next node will {`wait for ${method} to finish.`}
        </UncontrolledTooltip>
      </div>
    );
  }
  //"Expected params.InstanceCount to be a number
  //* MissingRequiredParameter: Missing required key 'InstancePlatform' in params

  onSchemaValidation = async (params): Promise<SchemaValidationError[]> => {
    let variables = getVariablesJson(this.props.workflowModel);

    try {
      let { service, method } = this.props.values;
      if (params.error) return [];
      let { isValid, message } = await api.validateParams({
        service,
        method,
        params,
        variables,
      });
      if (isValid) return [];
      let errors = message
        .split('\n')
        .map(getErrorObj)
        .filter((x) => !!x);
      return errors;
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  renderAdvancedFilters = (paramsMappingLabel: string) => {
    let { params } = this.props.values;

    if (this.props.isFetchingParams || !params || this.state.isFetching)
      return <PageLoader />;
    return (
      <AdvancedFiltersView
        {...this.eventHandlers}
        {...this.getFieldValues('params')}
        autoCompletionList={getAutoCompleteList({
          ...this.props,
          lastNodeOutputKeys: this.state.lastNodeOutputKeys,
        })}
        setError={this.props.setError}
        error={
          this.props.errors &&
          this.props.errors.params &&
          this.props.errors.params.error
        }
        value={params}
        h2={paramsMappingLabel}
        onSchemaValidation={this.onSchemaValidation}
      />
    );
  };

  async fetchOutputProperties(data: ActionSchema) {
    let { selectedResource } = data;
    if (!selectedResource) return [];
    try {
      let selectedResourceObj: SelectedResourceType = this.props.availableResources.find(
        (x) => x.label === selectedResource,
      );
      let lastNodeOutputKeys: Array<OutputKey> = await this.props.workflowModel.getOutputKeysOfSelectedResource(
        selectedResourceObj,
      );
      this.setState({ lastNodeOutputKeys });
      if (makeKey(this.props) && this.props.values.params) {
        await this.mapParams();
      }
    } catch (error) {
      this.setState({ lastNodeOutputKeys: [] });
      console.error(error);
    }
  }

  mapParams = async () => {
    this.setState({ isFetching: true });
    const paramsForService = await getMappedParamsForService({
      service: this.props.values.service,
      method: this.props.values.method,
      inputJson: this.props.values.params
        ? this.props.values.params.paramsValue
        : {},
      outputKeys: this.state.lastNodeOutputKeys.map((x) => x.name),
      paramsOptions: this.props.paramsOption,
    });
    this.props.handleChange({
      target: {
        name: 'params',
        value: { paramsValue: paramsForService, error: false },
      },
    });
    this.setState({ isFetching: false });
  };

  render(): React.ReactNode {
    const services = this.props.actions.map((x) => ({
      label: x.displayService,
      value: x.service,
    }));

    const { method, service, params, selectedResource } = this.props.values;
    const { availableResources } = this.props;
    let selectedResourceName = this.getSelectedResourceName();
    let paramsMappingLabel = 'Params Mapping ';
    if (selectedResourceName)
      paramsMappingLabel +=
        '(Each ' +
        pluralize.singular(selectedResourceName) +
        ' is referred as obj )';
    let modalTitle: ReactNode = getModalTitle(this.props.selectedNode);
    return (
      <ModalWrapper
        title={modalTitle}
        onCancel={this.props.onClose}
        onSubmit={this.props.submitForm}
        helpText={actionHelpText}
        jsonData={{
          workflow: this.props.workflowModel,
          selectedNode: this.props.selectedNode,
          data: JSON.parse(JSON.stringify(this.props.values)),
        }}
      >
        <Row className={'my-1'}>
          <Col md={7}>
            <InputSelect
              {...this.eventHandlers}
              {...this.getFieldValues('selectedResource')}
              onChange={this.onChangeOfAvailableResourcesList.bind(this)}
              label={'Select Resource to perform action on '}
              options={availableResources.map(({ label }) => ({
                label,
                value: label,
              }))}
            />
          </Col>
        </Row>

        {selectedResourceName && (
          <Row className={'mt-1'}>
            <Col md={8} className={'text-dark'}>
              For Each of {selectedResourceName}
            </Col>
          </Row>
        )}
        <Row className={'mt-1 pl-3'}>
          <Col className={'border px-4 pt-1'}>
            <Row>
              <Col md={4}>
                <InputSelect
                  {...this.eventHandlers}
                  {...this.getFieldValues('service')}
                  value={service}
                  label={'Select a service'}
                  onChange={this.onServiceChange.bind(this)}
                  options={services}
                />
              </Col>
              <Col md={4}>
                <InputSelect
                  {...this.eventHandlers}
                  {...this.getFieldValues('method')}
                  value={method}
                  label={'Select an action'}
                  onChange={this.onActionChange.bind(this)}
                  options={this.state.actionOptions}
                />
              </Col>
              <Col md={4} className={'pt-4'}>
                {/*wait for resource*/}
                {this.renderWaitForAction()}
              </Col>
            </Row>
            {method ? (
              <div>
                <Row className={'mt-1'}>
                  <Col>{this.renderAdvancedFilters(paramsMappingLabel)}</Col>
                </Row>
              </div>
            ) : (
              <Row className={'mt-3'}>
                <Col>Select an action for more options</Col>
              </Row>
            )}
          </Col>
        </Row>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const paramsKey = `${CloudProvider.AWS}/${makeKey(ownProps)}/params.json`;
  const paramsData = state.sgcTypes.sgcType[paramsKey];
  const propertiesKey = `${CloudProvider.AWS}/${makeKey(
    ownProps,
  )}/properties.json`;
  const propertiesData = state.sgcTypes.sgcType[propertiesKey];
  return {
    isFetchingParams: paramsData && paramsData.fetching,
    paramsOption: paramsData && paramsData.data,
    properties: (propertiesData && propertiesData.data) || {},
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchSGCData: (key: string) => {
      return fetchSGCDefinition({
        dispatch,
        path: key,
        cloudType: CloudProvider.AWS,
      });
    },
  };
}

export const ActionForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActionFormInner);

export const getErrorObj = (errorMessage): SchemaValidationError => {
  let key, message;
  if (errorMessage.includes('params.')) {
    key = errorMessage.split(' ').find((m) => m.startsWith('params.'));
    message = errorMessage.replace(
      key,
      key.substring(key.lastIndexOf('.') + 1),
    );
  } else if (errorMessage.includes('params')) {
    key = errorMessage.split(' ').find((m) => m.startsWith('params'));
    if (!key) return;
    let pathKey = errorMessage.substring(
      errorMessage.indexOf("'") + 1,
      errorMessage.lastIndexOf("'"),
    );
    let errorCodeIndex = errorMessage.indexOf(':');
    message = errorMessage.substring(
      errorCodeIndex + 1,
      errorMessage.lastIndexOf("'"),
    );

    key = key + '.' + pathKey;
  } else {
    let errorCodeIndex = errorMessage.indexOf(':');
    message = errorMessage.substring(
      errorCodeIndex + 1,
      errorMessage.lastIndexOf("'"),
    );
  }
  if (!key) return;
  let path = key
    .substring(key.indexOf('.') + 1)
    .replace(/\[/g, '.')
    .replace(/\]/g, '')
    .split('.');
  return { path, message };
};
