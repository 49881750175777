import * as moment from 'moment';
import {
  SCREEN_BREAKPOINTS,
  DEVICE_KEY,
  DEVICE_TYPE,
} from '../../context/deviceContext';

export const getUtcTime = (dateTime: number | string | void): string => {
  if (!dateTime) return '--';
  let time = moment.utc(dateTime);
  // return time.format('Do MMM');
  return time.format('MMM Do YYYY, HH:mm:ss UTC');
};

export const getUtcDate = (dateTime: number | string | void): string => {
  if (!dateTime) return '--';
  let time = moment.utc(dateTime);
  return time.format('Do MMM');
  // return time.format('MMM Do YYYY, HH:mm:ss UTC');
};

export function getDate(dateString: string) {
  let time = moment.utc(dateString);
  return time.format('DD MMMM YYYY');
}

export function getDateTime(dateString: string) {
  let time = moment.utc(dateString);
  return time.format('MMM Do YYYY, HH:mm:ss UTC');
}

export const determineDevice = () => {
  if (window.innerWidth < SCREEN_BREAKPOINTS.small) {
    return DEVICE_KEY[DEVICE_TYPE.SMALL];
  } else if (
    window.innerWidth >= SCREEN_BREAKPOINTS.small &&
    window.innerWidth <= SCREEN_BREAKPOINTS.medium
  ) {
    return DEVICE_KEY[DEVICE_TYPE.MEDIUM];
  } else if (
    window.innerWidth > SCREEN_BREAKPOINTS.medium &&
    window.innerWidth <= SCREEN_BREAKPOINTS.xlarge
  ) {
    return DEVICE_KEY[DEVICE_TYPE.LARGE];
  } else {
    if (window.innerWidth > SCREEN_BREAKPOINTS.xlarge) {
      return DEVICE_KEY[DEVICE_TYPE.XLARGE];
    }
  }
};

export const deviceWidthUpdate = () => {
  const device = determineDevice();
  if (device === 'mobile') {
    alert(
      `The app is not optimised for the device that you’re using. For the best experience access it from a higher resolution screen.`,
    );
  }
};
