import { Button, Col, FormGroup, Row } from 'reactstrap';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import deleteReasons from './delete-account-questions';

interface DeleteAccountInnerProps {
  /**
   * Function prop to call when delete button is pressed and prompt is confirmed.
   */
  onDeleteAccount: (reasonText: string) => void;
}

/**
 *
 * Delete AccountInner component without regionProps and no top-bar
 */
export const DeleteAccount = ({ onDeleteAccount }: DeleteAccountInnerProps) => {
  const [reasonText, setReasonText] = useState<string | null>(null);
  const [reasonKey, setReasonKey] = useState<number | null>(null);
  const handleReasonChange = (key: number) => {
    setReasonKey(key);
    key !== 5
      ? setReasonText(deleteReasons.find((x) => x.key === key).value)
      : setReasonText(null);
  };

  const handleReasonTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setReasonText(e.target.value);
  };

  const deleteAccount = () => {
    Swal.fire({
      title: 'Confirm Delete account',
      text:
        'Your account will be permanently deleted and you will not be able to login again',
      type: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if (result.dismiss) {
      } else if (result.value) {
        onDeleteAccount(reasonText);
      }
    });
  };

  return (
    <div className={'dashboard-content custom-container py-4 px-4'}>
      <Row>
        <Col sm={12}>
          <h3 className={'font-weight-bold'}>Delete my account</h3>
          <p className={'w-75 mt-3 text-muted'}>
            We’re sorry you didn't love TotalCloud. We’d like to understand why
            you want to leave us, so that we can prevent it in the future.
          </p>
        </Col>

        <Col sm={12} className={'mt-5'}>
          <h5 className={'font-weight-bold'}>
            Help us understand your concern, what made you to leave?
          </h5>
          <FormGroup className={''}>
            <div className={''}>
              {deleteReasons.map(({ label, value, key }, reasonIndex) => (
                <div className={'mb-3'} key={key}>
                  <input
                    className={'mr-2 cursor-pointer'}
                    type="radio"
                    name={'delete-reason'}
                    value={value}
                    checked={key === reasonKey}
                    id={`delete-reason`}
                    onChange={() => handleReasonChange(key)}
                  />
                  <span>{label}</span>
                </div>
              ))}
            </div>
          </FormGroup>
          {reasonKey === 5 && (
            <FormGroup>
              <textarea
                placeholder={'Please explain your reason to leave.'}
                onChange={handleReasonTextChange}
                value={reasonText}
                rows={4}
                style={{ width: '400px' }}
              />
            </FormGroup>
          )}

          <FormGroup className={'mt-3'}>
            <Button
              disabled={!Boolean(reasonText)}
              color={'danger'}
              onClick={deleteAccount}
            >
              Delete my Account
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
