import { TableItem, TemplateColNames } from '../cols';
import {
  StatusIndicator,
  StyledCustomDropDown,
} from '../../../workflows/style';
import * as React from 'react';
import { timeAgo } from './util';
import { Link } from 'react-router-dom';

export const TestedOnDisplay = (testedOn: string, item: TableItem) => {
  if (!testedOn || !item.templateItem || !item.templateItem.id) return '';
  return (
    <>
      {timeAgo(new Date(testedOn))}
      <Link
        to={`/workflow/${item.templateTestStatus.workflowId}/details?execution=${item.templateTestStatus.executionId}`}
        target={'_blank'}
      >
        <i className={'fa fa fa-external-link ml-1'} aria-hidden="true" />
      </Link>
    </>
  );
};

export const TestStatusDisplay = (status: string, item: TableItem) => {
  if (!status || !item.templateItem || !item.templateItem.id) return '';
  return <StatusIndicator type={status} />;
};
