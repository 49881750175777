import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

interface Props {
  data: { name: string; route: string; onClick?: Function }[];
}

export const CustomBreadCrumb = (props: Props) => {
  return (
    <div className="font-18">
      <Breadcrumb className="breadcrumb" data-test="breadCrumb">
        {props.data.map((datum, index) => {
          return (
            <BreadcrumbItem
              key={`${datum.name}_${index}`}
              active={index === props.data.length - 1}
              data-test="breadcrumb-item"
            >
              {datum.route ? (
                <Link
                  onClick={() => {
                    datum.onClick && datum.onClick();
                  }}
                  to={datum.route}
                  className="text-capitalize"
                >
                  {datum.name}
                </Link>
              ) : (
                datum.name
              )}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};
