import { Reducer } from 'redux';
import {
  TemplateAdoptionRequest,
  TemplatesAction,
  TemplateState,
} from './types';

const initialState: TemplateState = {
  fetching: false,
  data: [],
  selected: null,
  adopting: false,
};

export const templatesReducer: Reducer<TemplateState, TemplatesAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'TemplateAdoptionRequest':
      return { ...state, adopting: true };
    case 'TemplateAdoptionResponse':
      return { ...state, adopting: false };
    case 'FetchTemplates':
      return { ...state, fetching: true };
    case 'TemplateFetchSuccess':
      return { ...state, fetching: false, data: action.data || state.data };
    case 'SelectTemplateFetchSuccess':
      return { ...state, fetching: false, selected: action.selected || null };
    default:
      return state;
  }
};
