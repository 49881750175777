import React, { useEffect, useState } from 'react';
import Chart, { ChartData, ChartOptions, ChartType } from 'chart.js';
import styled from 'styled-components';
import _ from 'lodash';
import { convertData } from './options';
import { ComponentOverlayLoader } from '../loaders';

const StyledChartContainer = styled.div`
  position: relative;
  canvas {
    height: 100%;
    width: 100%;
  }

  .insight-chart-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
  }
`;

interface ChartCanvasProps {
  data: ChartData;
  options: ChartOptions;
  type: ChartType;
  isXTime?: boolean;
  height?: React.Key;
}

interface ChartComponentProps extends ChartCanvasProps {
  isLoading: boolean;
}

export const ChartComponent = (props: ChartComponentProps) => {
  const { data, height, options, type, isXTime = false, isLoading } = props;

  return (
    <StyledChartContainer className={'insight-chart-container'}>
      {isLoading && (
        <div className={'insight-chart-loader'}>
          <ComponentOverlayLoader />
        </div>
      )}
      <ChartCanvas
        data={data}
        options={options}
        type={type}
        height={height}
        isXTime={isXTime}
      />
    </StyledChartContainer>
  );
};

const ChartCanvas = React.memo(
  (props: ChartCanvasProps) => {
    const { data, options, type, isXTime = false, height } = props;
    const insightCharRef = React.createRef<HTMLCanvasElement>();
    const [chart, setChart] = useState(null);

    useEffect(() => {
      let chartRef = insightCharRef.current.getContext('2d');
      let c = new Chart(chartRef, {
        type,
        data: data || {},
        options,
      });
      setChart(c);
    }, []);

    useEffect(() => {
      if (chart) {
        chart.options = options;
        chart.data = convertData(data, isXTime);
        chart.update();
      }
    }, [data, options]);

    return (
      <canvas
        id={'insight-chart'}
        ref={insightCharRef}
        height={height || 300}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      _.isEqual(prevProps.data?.datasets, nextProps.data?.datasets) &&
      _.isEqual(prevProps.options, nextProps.options)
    );
  },
);
