import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../api';
import { WorkflowProperties } from '../../../lib/store/workflows/types';
import { Badge } from '../../common/BadgeComponent';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  fetchWorkflowExecutionInBatch,
  fetchWorkflowExecutionInBatchWithId,
} from '../../../lib/store/executions/action';
import { connect } from 'react-redux';
import {
  ExecutionDataType,
  ExecutionsMapType,
} from '../../../lib/store/executions/types';

interface OwnProps {
  allWorkflows: {
    workflow: string;
    name: string;
    properties: WorkflowProperties;
  }[];
  viewData: any;
}

interface StateProps {
  executions: ExecutionDataType[];
  fetching: boolean;
}

interface DispatchProps {
  fetchBatchExecutions: (workflows: string[]) => void;
}

type IProps = OwnProps & DispatchProps & StateProps;

const InventoryActionProgressIndicator = ({
  allWorkflows,
  viewData,
  fetchBatchExecutions,
  executions,
  fetching,
}: IProps) => {
  const [actionStatus, setActionStatus] = useState(undefined);
  const [workflows, setWorkflows] = useState([]);

  useEffect(() => {
    let actionWorkflows = [];
    actionWorkflows =
      allWorkflows.length && viewData && viewData.workflow
        ? allWorkflows.filter((workflowItem: any) => {
            return Boolean(
              workflowItem &&
                workflowItem.properties &&
                workflowItem.properties.categories &&
                workflowItem.properties.categories.find(
                  (category) =>
                    category.category === 'Parent-Workflow' &&
                    category.subCategories[0] === viewData.workflow,
                ),
            );
          })
        : [];

    let ids: string[] = actionWorkflows.map((w) => w.workflow);
    if (ids.length) {
      setWorkflows(ids);
    }
  }, [allWorkflows.length, viewData]);

  useEffect(() => {
    workflows.length && fetchBatchExecutions(workflows);
  }, [workflows]);

  useEffect(() => {
    let filteredExecutions = executions
      .filter((c) => Boolean(c.exes))
      .map((c) => c.exes[0])
      .filter((execution) => Boolean(execution))
      .filter(({ workflow }) => workflows.includes(workflow));
    filteredExecutions = filteredExecutions.sort(
      (x, y) => y.startDate - x.startDate,
    );

    if (filteredExecutions.length) {
      setActionStatus(filteredExecutions[0]);
    }
  }, [executions, fetching, workflows]);

  useEffect(() => {
    if (
      actionStatus &&
      (actionStatus.status === 'Queued' || actionStatus.status === 'Running')
    ) {
      setTimeout(() => {
        fetchBatchExecutions(workflows);
      }, 5000);
    }
  }, [actionStatus]);

  if (fetching) {
    return (
      <div className={'text-dark'} data-test={'inventory-action-progress'}>
        <span className={'mx-3'}>
          <i className="fa fa-spinner fa-spin"></i>
        </span>
        Fetching Details
      </div>
    );
  }

  if (actionStatus) {
    let { status, timestamp, workflow } = actionStatus;
    let executionUrl = `/workflow/${workflow}/details`;

    return (
      <div
        className={'text-dark d-flex justify-content-end'}
        data-test={'inventory-action-progress'}
      >
        <div className={'mr-1'} data-test={'execution-time'}>
          Last executed <span className={'font-weight-bold'}>{timestamp}</span>,
        </div>
        <div className={'mx-1'}>
          Status: <Badge component={status} type={status} />
        </div>
        <Link to={executionUrl} target={'_blank'} className={'ml-1'}>
          Execution Details
        </Link>
      </div>
    );
  } else {
    return (
      <div className={'text-dark'} data-test={'inventory-action-progress'}>
        Action details not found
      </div>
    );
  }
};

const mapStateToProps = (state: AppState, props: OwnProps): StateProps => {
  let ex = state.execution.workflowExecutions;
  let customActionExecution: ExecutionDataType[] = Object.entries(ex).map(
    ([, value]) => value,
  );
  return {
    executions: customActionExecution,
    fetching: Boolean(customActionExecution.find((x) => x.fetching === true)),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
): DispatchProps => {
  return {
    fetchBatchExecutions: (workflows) =>
      fetchWorkflowExecutionInBatchWithId({ dispatch, workflows }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryActionProgressIndicator);
