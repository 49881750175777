import * as React from 'react';
import { Redirect } from 'react-router';
import withAuth from '@okta/okta-react/dist/withAuth';

interface IProps {
  auth: {
    getAccessToken: () => Promise<string>;
    getUser: () => any;
    logout: (s: string) => void;
    handleAuthentication: () => Promise<any>;
  };
}

interface IState {
  authenticated: boolean | null;
  error: any;
}

class ImplicitCallback extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      authenticated: null,
      error: null,
    };
  }

  componentDidMount() {
    this.props.auth
      .handleAuthentication()
      .then(() => this.setState({ authenticated: true }))
      .catch((err) =>
        this.setState({ authenticated: false, error: err.toString() }),
      );
  }

  render() {
    if (this.state.authenticated === null) {
      return null;
    }

    const referrerKey = 'secureRouterReferrerPath';
    const location = JSON.parse(
      localStorage.getItem(referrerKey) || '{ "pathname": "/" }',
    );
    localStorage.removeItem(referrerKey);

    if (!this.state.authenticated) {
      // log error
      console.error(this.state.error);
      if (
        this.state.error ===
        'AuthSdkError: Unable to retrieve OAuth redirect params cookie'
      ) {
        return <Redirect to={'/'} />;
      }
      return <p>{this.state.error}</p>;
    }

    return <Redirect to={location} />;
  }
}

export const ImplicitCallbackHandler = withAuth(ImplicitCallback);
