import {
  ChildWorkflow,
  GroupWorkflowSerializedType,
  IGroupWorkflowModel,
  WORKFLOW_ACTION,
} from 'workflow-model/dist/types/IGroupWorkflowModel';
import {
  FilterStep,
  ParkedActionStep,
  ResourceStep,
  ScheduleStep,
  SmartScheduleStep,
  STEP_TYPE,
  STEPS_CONFIG,
  StepType,
} from './types';
import {
  getInvalidResponse,
  getValidResponse,
  INode,
  IWorkflow,
  NODE_TYPES,
  SerializedFilterNodeData,
  SerializedMonitoringNodeData,
} from 'workflow-model/dist';
import {
  FilterNode,
  MonitoringNode,
  TriggerNode,
} from 'workflow-model/dist/nodes';
import { SerializedScheduleTrigger } from 'workflow-model/dist/types/SerializedTypes';
import { CustomScheduleType } from './components/CustomScheduleForm';
import { ValidationResponseType } from 'workflow-model/dist/workflowModel';
import {
  areAllSchedulesValid,
  expressionParser,
  pairSchedules,
} from './helper';
import {
  functionFilterNodeId,
  getThresholdFromFunctionValue,
} from './defaults/smartScheduleWorkflow';
import { getWorkflows } from './handlers/addNode';
import { WFValidationOptions } from 'workflow-model/dist/workflowModel/index';

function getUNParkedActionsStep(unParkedActions: INode[]): ParkedActionStep {
  let type = STEP_TYPE.UN_PARK_ACTION;
  let nodes = unParkedActions.map((x) => {
    return {
      nodeId: x.getNodeId(),
      summary: x.getSummary(),
      isValid: x.validate(),
    };
  });
  return {
    type,
    isValid: nodes.filter((x) => !x.isValid).length === 0,
    action: WORKFLOW_ACTION.START,
    title: STEPS_CONFIG[type].title,
    description: STEPS_CONFIG[type].description,
    nodes,
    summary: Promise.all(nodes.map((x) => x.summary)).then((x) => x.join(', ')),
  };
}

function getParkedActionsStep(parkedActions: INode[]): ParkedActionStep {
  let type = STEP_TYPE.PARK_ACTION;
  let nodes = parkedActions.map((x) => {
    return {
      nodeId: x.getNodeId(),
      summary: x.getSummary(),
      isValid: x.validate(),
    };
  });
  return {
    type,
    isValid: nodes.filter((x) => !x.isValid).length === 0,
    action: WORKFLOW_ACTION.STOP,
    title: STEPS_CONFIG[type].title,
    description: STEPS_CONFIG[type].description,
    nodes,
    summary: Promise.all(nodes.map((x) => x.summary)).then((x) => x.join(', ')),
  };
}

function getFilterStep(filterNodes: INode[]): FilterStep {
  let type = STEP_TYPE.FILTER;
  let nodes = filterNodes.map((x) => {
    return {
      nodeId: x.getNodeId(),
      summary: x.getSummary(),
      isValid: x.validate(),
    };
  });
  return {
    type,
    isValid: nodes.filter((x) => !x.isValid).length === 0,
    action: WORKFLOW_ACTION.STOP,
    title: STEPS_CONFIG[type].title,
    description: STEPS_CONFIG[type].description,
    nodes,
    summary: Promise.all(nodes.map((x) => x.summary)).then((x) => x.join(', ')),
  };
}

function getResourceStep(resourceNode: INode): ResourceStep {
  let type = STEP_TYPE.RESOURCE;
  return {
    type,
    isValid: resourceNode.validate(),
    action: WORKFLOW_ACTION.STOP,
    title: STEPS_CONFIG[type].title,
    description: STEPS_CONFIG[type].description,
    node: {
      nodeId: resourceNode.getNodeId(),
      summary: resourceNode.getSummary(),
      isValid: resourceNode.validate(),
    },
    summary: resourceNode.getSummary(),
  };
}

function getSmartScheduleStep(
  smartScheduleWorkflow: IWorkflow,
): SmartScheduleStep {
  let monitoringNode = smartScheduleWorkflow.getNodesByType(
    NODE_TYPES.MONITORING,
  )[0] as MonitoringNode;
  let monitoringNodeData: SerializedMonitoringNodeData = monitoringNode.getNodeData();

  let namespace = monitoringNodeData.Queries[0].Namespace;
  let dimension = monitoringNodeData.Queries[0].Dimensions[0].Name;
  let metrics = monitoringNodeData.Queries[0].MetricName;
  let stat = monitoringNodeData.Queries[0].Stat;

  let threshold = getThresholdFromFunctionValue();
  if (smartScheduleWorkflow) {
    let functionFilterNode = smartScheduleWorkflow.getNodeById(
      functionFilterNodeId,
    ) as FilterNode;
    let data: SerializedFilterNodeData =
      functionFilterNode && functionFilterNode.getNodeData();
    if (data && data.conditions.length && data.conditions[0].value)
      threshold = getThresholdFromFunctionValue(data.conditions[0].value);
  }
  let type = STEP_TYPE.SMART_SCHEDULE;
  let summary = smartScheduleWorkflow.isActive()
    ? 'Stop Filtered resources if CPU utilisation is less than ' + threshold
    : 'Disabled';
  return {
    type,
    isValid: !!threshold,
    threshold,
    dimension,
    metrics,
    stat,
    namespace,
    action: WORKFLOW_ACTION.STOP,
    title: STEPS_CONFIG[type].title,
    description: STEPS_CONFIG[type].description,
    isActive: smartScheduleWorkflow.isActive(),
    summary: Promise.resolve(summary),
  };
}

export const getSchedules = (
  unParkedTrigger: TriggerNode,
  parkedTrigger: TriggerNode,
): ScheduleStep => {
  let parkedSchedule: SerializedScheduleTrigger = parkedTrigger.getNodeData()
    .schedule;
  let unParkedSchedule: SerializedScheduleTrigger = unParkedTrigger.getNodeData()
    .schedule;

  let startSchedules = unParkedSchedule.value.map((x) => x.value);
  let endSchedules = parkedSchedule.value.map((x) => x.value);

  let paired: { start: string; end: string }[] = pairSchedules({
    startSchedules,
    endSchedules,
  });
  let values: CustomScheduleType[] = paired.map((x) =>
    expressionParser(x.start, x.end),
  );
  let type = STEP_TYPE.SCHEDULE;
  return {
    summary: getScheduleSummary(values),
    isValid: areAllSchedulesValid(values),
    type,
    action: '',
    title: STEPS_CONFIG[type].title,
    description: STEPS_CONFIG[type].description,
    schedules: values,
  };
};
export async function getScheduleSummary(
  values: CustomScheduleType[],
): Promise<string> {
  let summary = 'Running only from:';
  let schedules = values.map((x) => {
    if (x.days !== '0') return x.days + ' ' + x.startTime + ' To ' + x.endTime;
    else return x.customDays.join(',') + ' ' + x.startTime + ' To ' + x.endTime;
  });

  return summary + ' ' + schedules.join(', ');
}

export const convertGroupModelToSteps = (
  groupWorkflow: IGroupWorkflowModel,
): Array<StepType> => {
  let workflows: ChildWorkflow[] = groupWorkflow.getWorkflows();

  let steps: StepType[] = [];

  if (!workflows || workflows.length < 2) return steps;

  let {
    parkedWorkflow,
    unParkedWorkflow,
    smartScheduleWorkflow,
  } = getWorkflows({ groupWorkflow });

  if (!parkedWorkflow || !unParkedWorkflow) return steps;

  let resourceNode: INode = parkedWorkflow.getNodesByType(
    NODE_TYPES.RESOURCE,
  )[0];
  let filterNodes: INode[] = parkedWorkflow.getNodesByType(NODE_TYPES.FILTER);
  let parkedActions: INode[] = parkedWorkflow.getNodesByType(NODE_TYPES.ACTION);

  let unParkedActions: INode[] = unParkedWorkflow.getNodesByType(
    NODE_TYPES.ACTION,
  );

  if (
    !resourceNode ||
    !filterNodes ||
    !filterNodes.length ||
    !parkedActions ||
    !parkedActions.length ||
    !unParkedActions ||
    !unParkedActions.length
  )
    return steps;

  steps.push(getResourceStep(resourceNode));
  steps.push(getFilterStep(filterNodes));
  steps.push(getParkedActionsStep(parkedActions));
  steps.push(getUNParkedActionsStep(unParkedActions));
  steps.push(getSmartScheduleStep(smartScheduleWorkflow));
  return steps;
};

export const validateGroupWorkflow = (params: {
  steps: StepType[];
  groupWorkflow: IGroupWorkflowModel;
  validationOptions?: WFValidationOptions;
}): ValidationResponseType => {
  let { steps, groupWorkflow, validationOptions } = params;

  // validate group workflow
  let validationResult: ValidationResponseType = groupWorkflow.validate(
    validationOptions,
  );

  // validate if both schedules are valid
  let invalidSteps = steps && steps.filter((x) => !x.isValid);

  if (validationResult.isValid && invalidSteps && invalidSteps.length === 0) {
    return getValidResponse();
  } else
    return getInvalidResponse(
      !validationResult.isValid ? validationResult.message : 'Invalid Schedule',
    );
};

export const getSerializedGroupModel = (params: {
  steps: StepType[];
  groupWorkflow: IGroupWorkflowModel;
  skipValidation?: boolean;
  validationOptions?: WFValidationOptions;
}): GroupWorkflowSerializedType => {
  let { steps, groupWorkflow, skipValidation, validationOptions } = params;

  let {
    parkedWorkflow,
    unParkedWorkflow,
    smartScheduleWorkflow,
  } = getWorkflows({ groupWorkflow });

  // set variables for unparked workflow
  unParkedWorkflow.setCredentials(parkedWorkflow.getCredentials());
  unParkedWorkflow.setRegion(parkedWorkflow.getRegion());

  smartScheduleWorkflow.setCredentials(parkedWorkflow.getCredentials());
  smartScheduleWorkflow.setRegion(parkedWorkflow.getRegion());

  let validationResult: ValidationResponseType = validateGroupWorkflow({
    steps,
    groupWorkflow,
    validationOptions,
  });
  if (validationResult.isValid || skipValidation) {
    return groupWorkflow.serialize(validationOptions);
  } else {
    throw new Error(validationResult.message);
  }
};
