import * as React from 'react';

export function ButtonText(
  props: React.PropsWithChildren<{ className?: string }>,
) {
  return (
    <small>
      <b className={props.className}>{props.children}</b>
    </small>
  );
}
