export const Fonts = {
  family: {
    // heading: '"Lato", sans-serif',
    // content: '"Lato", sans-serif'
    // heading: '"proxima-nova", sans-serif',
    // content: '"proxima-nova", sans-serif',
    heading: '"Montserrat", sans-serif',
    content: '"Montserrat", sans-serif',
    logo: 'Arimo',
  },
  weight: {
    heading: {
      normal: 500,
      bold: 700,
    },
    content: {
      normal: 300,
      bold: 700,
    },
  },
  size: {
    heading: {
      large: '42px',
      medium: '32px',
      regular: '16px',
      small: '22px',
    },
    content: {
      large: '20px',
      medium: '1.125em',
      regular: '16px',
      small: '14px',
    },
  },
};
