import React, { useEffect } from 'react';
import { WorkflowReportWrapper } from '../../workflows/common/WorkflowReportWrapper';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { CustomReportWorkflow } from '../../workflows/common/workflowReportConverter';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  runReportingWorkflow,
  saveReportingWorkflow,
} from '../../../lib/store/workflows/action';
import { superApiCall } from '../../../lib/store/super-table/action';
import { ApiOperation } from '../../../lib/store/super-table/types';
import { connect } from 'react-redux';
import { Workflow } from '../../../lib/store/workflows/types';
import { IWorkflow } from 'workflow-model';
import { DummyField } from '../../common/DummyField';

interface StateProps {
  reportWorkflow: Workflow;
  isFetching: boolean;
  reportingAction: boolean;
}

interface DispatchProps {
  saveReportingWorkflow: (workflow: IWorkflow) => void;
  sendReportNow: (workflow: IWorkflow) => void;
  fetchReportingWorkflow: (options: {}) => void;
}

interface OwnProps {
  tabName: TAB_NAME;
  category: string;
  subCategory?: string;
  workflowCategory?: string;
  workflowSubCategory?: string; //
  user: string;
}

type ShareOptionsProps = StateProps & DispatchProps & OwnProps;

export const ShareOptionsInner = (props: ShareOptionsProps) => {
  const {
    tabName,
    isFetching,
    fetchReportingWorkflow,
    reportWorkflow,
    saveReportingWorkflow,
    sendReportNow,
    reportingAction,
    workflowCategory,
    subCategory,
    category,
    workflowSubCategory,
    user,
  } = props;

  useEffect(() => {
    if (!reportWorkflow) {
      fetchReportingWorkflow({});
    }
  }, []);

  const customWorkflow = new CustomReportWorkflow({
    category: workflowCategory,
    subCategory: workflowSubCategory,
    tabName,
    user,
    ...reportWorkflow,
  });

  if (isFetching) {
    return <DummyField height={'40px'} />;
  }

  return (
    <WorkflowReportWrapper
      tabName={tabName}
      customWorkflow={customWorkflow}
      isFetching={isFetching}
      reportingAction={reportingAction}
      sendReportNow={sendReportNow}
      saveReportingWorkflow={saveReportingWorkflow}
    />
  );
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const { category, subCategory } = ownProps;
  const reportData =
    state.superTable[category] && state.superTable[category][subCategory]; //
  return {
    isFetching: reportData?.loading,
    reportingAction: state.workflows.reportingAction,
    reportWorkflow: reportData?.data?.workflow, //
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<DispatchProps, AppState, AnyAction>,
  ownProps: OwnProps,
): DispatchProps => {
  const { category, subCategory } = ownProps;

  const fetchReportingWorkflow = (options: any = {}) =>
    superApiCall({
      category,
      subCategory,
      operation: ApiOperation.GET,
      options,
      dispatch,
    });

  return {
    fetchReportingWorkflow,
    saveReportingWorkflow: (workflow: IWorkflow) =>
      saveReportingWorkflow({
        dispatch,
        workflow,
        reFetch: fetchReportingWorkflow,
      }),
    sendReportNow: (workflow) => runReportingWorkflow({ dispatch, workflow }),
  };
};

export const ShareOptions = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ShareOptionsInner);
