import React, { useEffect, useState } from 'react';
import { withAuth } from '@okta/okta-react';

export const WithUserInner = (props: {
  auth: any;
  otherProps: any;
  Component: React.ComponentType<any>;
}) => {
  const { auth, Component, otherProps } = props;
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!auth) return;
    auth.getUser().then((user) => {
      setUser(user);
    });
  }, [auth]);

  return <Component user={user} {...otherProps} />;
};

const withUser = (Component: React.ComponentType<any>) =>
  withAuth((authProps) => {
    return (
      <WithUserInner
        auth={authProps.auth}
        otherProps={authProps}
        Component={Component}
      />
    );
  });
export default withUser;
