import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Description from './Description';
// import { page } from '../../../analytics';
import {
  MSG_NO_INTERNET,
  getForgotPasswordErrorMessage,
  MSG_PASSWORD_RESET_LINK_SENT,
} from './ErrorHandler';
import { track, identify } from '../../../analytics/analytics';
import { events } from '../../../analytics/events';
import { observe } from '../../../analytics/eventCapturer';
import { OktaConfig } from '../../../config/okta';
import { loadOrgUser } from '../common/loadOrgUser';

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
);

const style = {
  // minHeight: `600px`,
};
const rowStyle = {
  width: '80%',
  margin: 'auto',
};

interface Props {}

interface State {
  email?: string;
  emailValidate?: boolean;
  clickedSendEmail?: boolean;
  firstEmailRequest?: boolean;
  forgotSubmissionFailed: boolean;
  defaultFeedback: boolean;
  feedbackMessage: string;
}

class ForgotForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      emailValidate: true,
      clickedSendEmail: false,
      firstEmailRequest: false,
      defaultFeedback: false,
      forgotSubmissionFailed: false,
      feedbackMessage: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  // componentDidMount() {
  //   page('forgotpassword');
  // }

  handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    e.preventDefault();
    const { email } = this.state;
    this.setState({ clickedSendEmail: true, feedbackMessage: '' });

    if (email) {
      this.setState({ firstEmailRequest: true });
      loadOrgUser(email)
        .then(() =>
          observe(
            fetch(`${OktaConfig.REACT_APP_FORGOT_PASSWORD_URL}`, {
              method: 'post',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                username: email,
                factorType: 'EMAIL',
              }),
            }),
            'FORGET_PASSWORD_REQUEST',
          ),
        )
        .then((res) => res.json())
        .then((resp) => {
          // this.setState({ forgot: false });
          // console.log(resp);

          if (resp.errorCauses) {
            this.setState({
              forgotSubmissionFailed: true,
              defaultFeedback: false,
              feedbackMessage: getForgotPasswordErrorMessage(resp),
            });
          } else {
            this.setState({
              forgotSubmissionFailed: false,
              defaultFeedback: true,
              feedbackMessage: MSG_PASSWORD_RESET_LINK_SENT,
            });
          }
        })
        .catch((err) => {
          this.setState({
            forgotSubmissionFailed: true,
            defaultFeedback: false,
            feedbackMessage: MSG_NO_INTERNET,
          });
        });
    }
  }

  handleEmailChange(e: React.FormEvent<HTMLInputElement>): void {
    this.setState({
      email: e.currentTarget.value,
      emailValidate: emailRegex.test(e.currentTarget.value),
      forgotSubmissionFailed: false,
      defaultFeedback: false,
    });
  }

  render() {
    const {
      emailValidate,
      clickedSendEmail,
      email,
      firstEmailRequest,
      defaultFeedback,
      forgotSubmissionFailed,
      feedbackMessage,
    } = this.state;

    let large = window.innerWidth > 500;

    return (
      <div className="container-fluid login d-flex align-items-center ">
        <div
          className="row"
          style={large ? rowStyle : { fontSize: '1rem', margin: 0 }}
        >
          <div className="d-none d-md-block col-lg-6 col-xs-12">
            <Description />
          </div>
          <div className={large ? 'col-lg-5 offset-lg-1 col-xs-12' : 'col p-0'}>
            <div
              className={`row login-form test-reset-form ${
                large ? '' : ' m-0'
              }`}
            >
              <form onSubmit={this.handleSubmit} style={style}>
                <div className="form-group">
                  <div className="form-row title-row">
                    <h3>Forgot Password</h3>
                  </div>
                  <div className="form-row my-4 email">
                    <input
                      type="email"
                      className="form-control context-input test-forgot-email"
                      placeholder="Enter Email"
                      value={this.state.email}
                      onChange={this.handleEmailChange}
                    />
                    {!emailValidate && <small>Email is not valid</small>}
                    {clickedSendEmail && !email && (
                      <small>Email is required</small>
                    )}
                  </div>
                  <div className="form-row mb-5">
                    <button
                      type="submit"
                      className="btn btn-primary mb-2 login-button test-reset-button"
                    >
                      {!firstEmailRequest
                        ? `send reset password link`
                        : `Resend`}
                    </button>
                  </div>

                  {forgotSubmissionFailed && (
                    <div
                      className={`form-row failed-message`}
                      style={{ width: '100%' }}
                    >
                      <p>{feedbackMessage}</p>
                    </div>
                  )}

                  {defaultFeedback && (
                    <div
                      className={`form-row default-message`}
                      style={{ width: '100%' }}
                    >
                      <p>{feedbackMessage}</p>
                    </div>
                  )}
                </div>
              </form>
              <div className="form-row have-account go-to-login-page">
                <h5>
                  Go back to login page.&nbsp;
                  <Link to="/login" className="register-link">
                    Login
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotForm;
