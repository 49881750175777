import { OrgGroup, OrgUser, Tenant } from '../../lib/store/tenants/types';
import React from 'react';
import { AppState } from '../../lib/store';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  addUser,
  toggleUserStatus,
  fetchGroups,
  fetchUsers,
  reAssignGroupsToUser,
} from '../../lib/store/tenants/action';
import LineLoader from '../common/LineLoader';
import { Badge, Button, Col } from 'reactstrap';
import CustomTable, { ICustomTableCol } from '../common/table';
import { AddUser } from './AddUser';
import { ModifyGroups } from './ModifyGroupAssignment';
import { confirm } from './util';
import { Config } from '../../lib/store/config/types';
import { ORDER } from '../workflows/common/workflowTable';
import classNames from 'classnames';

type OwnProps = {
  user: string;
};

type StateProps = {
  isFetchingUsers: boolean;
  isFetchingGroups: boolean;
  isAddingUser: boolean;
  isTogglingUserActive: boolean;
  isFetchingConfig: boolean;
  isModifyingUserGroups: boolean;
  tenant: Tenant;
  config: Config[];
  users: OrgUser[];
  groups: OrgGroup[];
};

type DispatchProps = {
  fetchUsers: () => void;
  fetchGroups: () => void;
  addUser: (user: Partial<OrgUser>, groups: OrgGroup[]) => void;
  toggleUserStatus: (user: OrgUser) => void;
  reassignGroups: (user: OrgUser, groups: OrgGroup[]) => void;
};

type IProps = OwnProps & DispatchProps & StateProps;

class OrgUsersInner extends React.Component<IProps> {
  getUser = () => {
    return this.props.users.map((u) => {
      return {
        ...u,
        toggleUser: () =>
          confirm("The user won't be able to log in").then(
            (proceed) => proceed && this.props.toggleUserStatus(u),
          ),
      };
    });
  };

  componentDidMount(): void {
    if (!this.props.users.length) {
      this.props.fetchUsers();
    }
    if (!this.props.users.length) {
      this.props.fetchGroups();
    }
  }

  groupFormatter = (groups: OrgGroup[], user: OrgUser) => {
    return (
      <>
        {groups.map((group, key) => {
          return (
            <Badge key={key} color={'light'} className="text-capitalize">
              {group.name}
            </Badge>
          );
        })}
        <ModifyGroups
          groups={this.props.groups}
          user={user}
          reassignGroups={this.props.reassignGroups}
          config={this.props.config}
        />
      </>
    );
  };

  actionFormatter = (cell: any, user: OrgUser) => {
    let color: string = 'default';
    let disabled =
      user.email === this.props.tenant.owner || user.email === this.props.user;
    let text = 'Disable';
    if (user.status === 'ACTIVE') {
      color = 'danger';
    } else if (user.status === 'SUSPENDED') {
      color = 'info';
      text = 'Enable';
    } else {
      disabled = true;
    }
    return (
      <Button
        color={color}
        size={'sm'}
        onClick={user['toggleUser']}
        disabled={disabled}
      >
        {text} <i className="fa fa-minus-circle ml-3" />
      </Button>
    );
  };

  getColumns(): ICustomTableCol[] {
    return [
      {
        colName: 'Username',
        dataField: 'email',
        text: 'Username',
        sort: true,
        classes: 'text-truncate',
      },
      {
        colName: 'Groups',
        dataField: 'groups',
        text: 'Groups',
        sort: true,
        formatter: this.groupFormatter,
      },
      {
        colName: 'Status',
        dataField: 'status',
        text: 'Status',
        sort: true,
      },
      {
        colName: 'Actions',
        dataField: '',
        text: '',
        formatter: this.actionFormatter,
        classes: 'text-right',
      },
    ];
  }

  render() {
    const isBusy =
      this.props.isFetchingUsers ||
      this.props.isFetchingGroups ||
      this.props.isAddingUser ||
      this.props.isTogglingUserActive ||
      this.props.isModifyingUserGroups;
    return (
      <>
        {isBusy && <LineLoader />}

        <CustomTable
          sortField={'email'}
          order={ORDER.ASCENDING}
          options={
            <Col md={8} className="d-flex justify-content-end">
              <Button
                color={'primary'}
                outline={true}
                onClick={this.props.fetchUsers}
              >
                <i
                  className={classNames({
                    'fa fa-refresh': true,
                    'fa-spin': this.props.isFetchingUsers,
                  })}
                />
              </Button>
              <AddUser
                addUser={this.props.addUser}
                existingUsers={this.props.users}
                groups={this.props.groups}
                config={this.props.config}
                tenant={this.props.tenant}
              />
            </Col>
          }
          emptyText={
            isBusy
              ? 'Fetching users in this organisation ....'
              : 'Invite users into your organisation'
          }
          data={this.getUser()}
          columns={this.getColumns()}
          divId={'org-users'}
          responsive={false}
        />
      </>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetchingUsers: state.tenantConfig.isFetchingUsers,
    isFetchingGroups: state.tenantConfig.isFetchingGroups,
    isFetchingConfig: state.config.fetching,
    config: state.config.data || [],
    isAddingUser: state.tenantConfig.isAddingUser,
    isTogglingUserActive: state.tenantConfig.isTogglingUserActive,
    isModifyingUserGroups: state.tenantConfig.isModifyingUserGroups,
    tenant: state.tenantConfig.tenant,
    users: state.tenantConfig.orgUsers,
    groups: state.tenantConfig.orgGroups,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchUsers: () => fetchUsers({ dispatch }),
    fetchGroups: () => fetchGroups({ dispatch }),
    addUser: (user, groups) => addUser({ dispatch, user, groups }),
    toggleUserStatus: (user) => toggleUserStatus({ dispatch, user }),
    reassignGroups: (user, groups) =>
      reAssignGroupsToUser({ dispatch, user, groups }),
  };
}

export const OrgUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgUsersInner);
