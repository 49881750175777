import * as React from 'react';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from 'reactstrap';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { StyledCustomDropDown, SwitchStatus } from '../../style';
import SwitchContainer from '../../../../lib/switch/SwitchContainer';
import tableify from 'tableify';
import ShareLinkWorkflowModal from '../ShareLinkWorkflowModal';
import { TAB_NAME } from 'webapp-genric/dist';
import classnames from 'classnames';
export type OptionsColDataType = {
  templateId: string;
  active: boolean;
  classNames: string;
  onChangeOption: (option: string) => void;
  onAdoptRequest: (templateId: string, option: string) => void;
  toggleActive: Function;
};

export type options = {
  label: JSX.Element;
  value: string;
  disabled?: boolean;
}[];

export function getExtraOptions(data, url): options {
  return [
    {
      label: (
        <>
          <i className={'fa fa-line-chart mr-1'} />{' '}
          <span className="">Resource Details</span>
        </>
      ),
      value: 'resource-details',
      disabled: !data,
    },
    {
      label: (
        <>
          <i className={'fa fa-external-link mr-1'} />{' '}
          <span className="">Open Report</span>
        </>
      ),
      value: 'open-report',
      disabled: !url,
    },
  ];
}

const options = [
  {
    label: (
      <>
        <i className={'fa fa-edit mr-1'} />{' '}
        <span className="test-edit-button">Edit</span>
      </>
    ),
    value: 'edit',
  },
  {
    label: (
      <>
        <i className={'fa fa-clone mr-1'} />{' '}
        <span className="test-clone-button">Clone</span>
      </>
    ),
    value: 'clone',
  },
  {
    label: (
      <>
        <i className={'fa fa-info-circle mr-1'} />{' '}
        <span className="test-exec-details">Execution Details</span>
      </>
    ),
    value: 'execution-details',
  },
  {
    label: (
      <>
        <i className={'fa fa-file mr-1'} />{' '}
        <span className="test-view-policy">View Policy</span>
      </>
    ),
    value: 'view-policy',
  },
  {
    label: (
      <>
        <i className={'fa fa-play mr-1'} />{' '}
        <span className="test-options-run-now">Run Now</span>
      </>
    ),
    value: 'run-now',
  },
  {
    label: (
      <>
        <i className={'fa fa-trash mr-1'} />{' '}
        <span className="test-delete-button">Delete</span>
      </>
    ),
    value: 'delete',
  },
  {
    label: (
      <>
        <i className={'fa fa-share mr-1'} />{' '}
        <span className="test-delete-button">Share</span>
      </>
    ),
    value: 'share',
  },
];

export const getDropdownOptions = (row) => {
  let dropDownOptions: options = options;
  if (row.tabName === TAB_NAME.UTILISATION) {
    const extraOptions = getExtraOptions(row.metricUtilisationMap, row.url);
    dropDownOptions = extraOptions.concat(dropDownOptions);
  } else if (row.tabName === TAB_NAME.INVENTORY) {
    const extraOptions = getExtraOptions(row.url, row.url);
    dropDownOptions = extraOptions.concat(dropDownOptions);
  }
  return dropDownOptions;
};

const OptionDispay = (props: {
  col: any;
  row: any;
  customDropdownFooter: any;
}) => {
  const { col, row, customDropdownFooter } = props;
  const [isShowShareLinkModal, toggleShareLinkModal] = React.useState(false);
  const [previousShareToken, updateShareToken] = React.useState(null);

  let dropDownOptions = getDropdownOptions(row);

  return (
    <>
      <StyledCustomDropDown
        className="test-options-component d-flex justify-content-end"
        name="sort-workflow"
        value={''}
        options={dropDownOptions}
        title={<i className={col.classNames} />}
        onChange={(value) => {
          if (value === 'share') {
            toggleShareLinkModal(!isShowShareLinkModal);
          } else {
            col.onChangeOption(value);
          }
        }}
        right
        customDropDownFooter={customDropdownFooter}
      />
      {isShowShareLinkModal && (
        <ShareLinkWorkflowModal
          toggleShareLinkModal={() =>
            toggleShareLinkModal(!isShowShareLinkModal)
          }
          workflow={row.workflow}
          tabName={row.tabName}
          updateShareToken={updateShareToken}
          previousShareToken={previousShareToken}
        />
      )}
    </>
  );
};

export const getTemplateOptionsFormatter = (colContent: {
  templateId: string;
  onAdoptRequest: (templateId: string, option: string) => void;
}) => {
  return (
    <div className={'d-flex justify-content-end'}>
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          outline={true}
          color={'primary'}
          size={'sm'}
          className={'test-adopt-template-button px-2'}
        >
          Adopt <i className="fa fa-caret-down" />
        </DropdownToggle>
        <DropdownMenu className="test-adopt-menu overflow-hidden" right>
          <DropdownItem
            className="test-adopt-directly"
            onClick={() =>
              colContent.onAdoptRequest(colContent.templateId, 'AdoptDirectly')
            }
          >
            Adopt Directly
          </DropdownItem>
          <DropdownItem
            className="test-adopt-customize"
            onClick={() =>
              colContent.onAdoptRequest(colContent.templateId, 'Customize')
            }
          >
            Customise
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

const DetailsModal = function(props: { data: any } = { data: {} }) {
  const [isOpen, setOpen] = React.useState(false);
  const toggle = () => setOpen(!isOpen);
  let table = tableify(props.data);
  table = table.replace('<table>', '<table class="table table-striped">');
  const htmlToReactParser = new HtmlToReactParser();
  const tableNode = htmlToReactParser.parse(table);
  return (
    <>
      <Col className={'text-right'}>
        <Button size={'sm'} color={'link'} onClick={toggle}>
          Details
        </Button>
        <Modal isOpen={isOpen} toggle={toggle} size={'xl'} centered={true}>
          <ModalHeader toggle={toggle}>Event Details</ModalHeader>
          <ModalBody>{tableNode}</ModalBody>
        </Modal>
      </Col>
    </>
  );
};

export const optionsColDisplayFormatter = (
  colContent: OptionsColDataType,
  row,
): React.ReactNode => {
  if (row.isTemplateItem) {
    return getTemplateOptionsFormatter(colContent);
  }

  if (row.isEventHistory) {
    return <DetailsModal data={row._raw} />;
  }

  let customDropDownFooter;
  if (row.isFetchingWorkflow) {
    customDropDownFooter = (
      <div className="w-100 d-flex justify-content-between align-items-center text-uppercase">
        <i className={'fa fa-spin fa-spinner'} />
      </div>
    );
  } else {
    const active = colContent.active;
    customDropDownFooter = (
      <div className="d-flex justify-content-between align-items-center text-uppercase">
        <SwitchStatus>Stage</SwitchStatus>
        <div className="px-2">
          <SwitchContainer
            data-test="data-switch"
            value={active}
            onChange={() => colContent.toggleActive(!active)}
          />
        </div>
        <SwitchStatus>Deploy</SwitchStatus>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-end">
      <OptionDispay
        col={colContent}
        row={row}
        customDropdownFooter={customDropDownFooter}
      />
    </div>
  );
};
