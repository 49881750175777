import { Reducer } from 'redux';
import {
  ListUserCreatedScriptCompleted,
  ListUserCreatedScriptStarted,
  UserCreatedScriptAction,
  UserCreatedScriptState,
} from './types';

const initialState: UserCreatedScriptState = {
  fetching: false,
  data: [],
};

export const userCreatedScriptReducer: Reducer<
  UserCreatedScriptState,
  UserCreatedScriptAction
> = (state = initialState, action) => {
  switch (action.type) {
    case ListUserCreatedScriptStarted:
      return { ...state, fetching: true };
    case ListUserCreatedScriptCompleted:
      return { ...state, fetching: false, data: action.data || state.data };
    case 'ModifyUserCreatedScriptStarted':
      return { ...state, fetching: true };
    case 'ModifyUserCreatedScriptCompleted':
      return { ...state, fetching: false };
    default:
      return state;
  }
};
