import {
  GlobalPolicyDetailsType,
  GlobalPolicyType,
} from '../../lib/store/policy/types';
import React from 'react';
import { Config } from '../../lib/store/config/types';
import CustomTable, { ICustomTableCol } from './table';
import { PolicyStatus } from '../../lib/editor/util/policyValidator';

type IProps = {
  globalPolicy: GlobalPolicyType;
  configs: Config[];
  account: string[];
  showPolicyDocument: (props?: {
    actions: string[];
    invalidActions?: string[];
  }) => void;
};

type TabDataType = {
  tabName: string;
  status: PolicyStatus;
  actions: string[];
  invalidActions: string[];
  isAuthError: boolean;
  functions: {
    showPolicyDocument: (props: {
      actions: string[];
      invalidActions: string[];
    }) => void;
  };
};
type DataType = {
  _id: number;
  account: string;
  status: PolicyStatus;
  actions: string[];
  invalidActions: string[];
  isAuthError: boolean;
  tabs: TabDataType[];
  functions: {
    showPolicyDocument: (props: {
      actions: string[];
      invalidActions: string[];
    }) => void;
  };
};

const getStatusFormatter = (cell: PolicyStatus, row) => {
  switch (cell) {
    case PolicyStatus.WARNING:
      return (
        <i
          title={row.isAuthError ? 'Missing IAM permissions' : 'Invalid policy'}
          className={
            ' mr-1 fa fa-exclamation-triangle text-warning cursor-pointer'
          }
        />
      );
    case PolicyStatus.VALID:
      return (
        <i
          className={'mr-2 fa fa-check-circle-o text-success cursor-pointer'}
        />
      );
    case PolicyStatus.INVALID:
      return (
        <i
          title={'Invalid policy'}
          className={' mr-1 fa fa-times-circle text-danger cursor-pointer'}
        />
      );
    default:
      return (
        <i
          title={'Invalid policy'}
          className={' mr-1 fa master-tc-continuous text-danger cursor-pointer'}
        />
      );
  }
};
const getChildTableCols = () => {
  return [
    {
      dataField: 'tabName',
      colName: 'tabName',
      text: 'Tab',
      headerStyle: { display: 'none' },
      style: { width: '50%' },
    },
    {
      dataField: 'status',
      colName: 'status',
      text: '',
      headerStyle: { display: 'none' },
      formatter: getStatusFormatter,
    },
    {
      dataField: 'policy',
      colName: 'account',
      isDummyField: true,
      headerStyle: { display: 'none' },
      text: '',
      formatter: (col, row: DataType | TabDataType) => {
        let invalidActions =
          row.invalidActions && row.invalidActions.length
            ? row.invalidActions
            : undefined;
        return (
          <>
            <i
              title={'Policy document'}
              onClick={() =>
                row.functions.showPolicyDocument({
                  actions: row.actions,
                  invalidActions,
                })
              }
              data-test="policy-icon"
              className={
                'ml-2 new_icon-4list fa-2x  text-primary cursor-pointer'
              }
              id="tooltip-note"
            />
          </>
        );
      },
    },
  ];
};

const getcols = (): ICustomTableCol[] => {
  return [
    {
      dataField: 'account',
      colName: 'account',
      text: 'Account',
      headerStyle: { width: '50%' },
    },
    {
      dataField: 'status',
      colName: 'status',
      text: 'Status',
      formatter: getStatusFormatter,
    },
    {
      dataField: 'policy',
      colName: 'account',
      isDummyField: true,
      text: 'Policy',
      formatter: (col, row: DataType) => {
        let invalidActions =
          row.invalidActions && row.invalidActions.length
            ? row.invalidActions
            : undefined;
        return (
          <>
            <i
              title={'Policy document'}
              onClick={() =>
                row.functions.showPolicyDocument({
                  actions: row.actions,
                  invalidActions,
                })
              }
              data-test="policy-icon"
              className={
                'ml-2 new_icon-4list fa-2x  text-primary cursor-pointer'
              }
              id="tooltip-note"
            />
          </>
        );
      },
    },
  ];
};

const getStatus = ({ invalidActions, isAuthError }) => {
  if (isAuthError) return PolicyStatus.WARNING;
  return invalidActions.length == 0 ? PolicyStatus.VALID : PolicyStatus.INVALID;
};

const getTabsData = (
  props: { [tabName: string]: GlobalPolicyDetailsType },
  showPolicyDocument,
): TabDataType[] => {
  let tabs = Object.keys(props);
  return tabs.map((tab) => {
    let tabDetails: GlobalPolicyDetailsType = props[tab];
    let actions = Object.keys(tabDetails.details).reduce((A, e) => {
      A = Object.assign(A, tabDetails.details[e]);
      return A;
    }, {});
    let invalidActions = Object.keys(actions).filter((x) => !actions[x]);
    let status = getStatus({
      invalidActions,
      isAuthError: tabDetails.isAuthError,
    });
    return {
      tabName: tab,
      status,
      actions: Object.keys(actions),
      invalidActions,
      isAuthError: tabDetails.isAuthError,
      functions: {
        showPolicyDocument,
      },
    };
  });
};

const getAccountStatus = (tabData: TabDataType[]): PolicyStatus => {
  let isError = tabData.find((x) => x.status === PolicyStatus.INVALID);
  if (isError) return PolicyStatus.INVALID;
  let isWarning = tabData.find((x) => x.status === PolicyStatus.WARNING);
  if (isWarning) return PolicyStatus.WARNING;
  return PolicyStatus.VALID;
};

const getData = (props: IProps): DataType[] => {
  let tabSummary = props.globalPolicy.tabSummary;
  let accountIds = Object.keys(tabSummary);
  if (props.account && props.account.length === 1) {
    accountIds = accountIds.sort((a, b) => {
      if (a === props.account[0]) return -1;
      if (b === props.account[0]) return 1;
      return 0;
    });
  }
  return accountIds.map((accountId, _id) => {
    let accountConfig = props.configs.find((x) => x.id == accountId);
    let accountName = accountConfig ? accountConfig.name : 'Unknown';
    let tabs = getTabsData(tabSummary[accountId], props.showPolicyDocument);
    let isAuthError = tabs.filter((x) => x.isAuthError).length > 0;
    let actions = tabs.reduce((A, e) => {
      return A.concat(e.actions);
    }, []);
    let invalidActions = tabs.reduce((A, e) => {
      return A.concat(e.invalidActions);
    }, []);
    let status = getAccountStatus(tabs);

    return {
      _id,
      account: accountName,
      status,
      tabs,
      isAuthError,
      invalidActions,
      actions,
      functions: {
        showPolicyDocument: props.showPolicyDocument,
      },
    };
  });
};

export const GlobalPolicyStatus = (props: IProps) => {
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row: DataType) => (
      <div className={'borderless heading-none tc-table-hide-hover ml-2'}>
        <CustomTable
          disableFixedLayout={true}
          options={null}
          data={row.tabs}
          columns={getChildTableCols()}
          hideSearch={true}
          striped={false}
          pagination={false}
        />
      </div>
    ),
    expanded: props.account && props.account.length === 1 ? [0] : undefined,
  };
  return (
    <div style={{ height: '500px', overflowX: 'hidden', overflowY: 'scroll' }}>
      <CustomTable
        disableFixedLayout={true}
        hideSearch={true}
        options={null}
        data={getData(props)}
        columns={getcols()}
        expand={expandRow}
        pagination={false}
      />
    </div>
  );
};
