import * as React from 'react';
import { shouldShowTimeStamp } from '../../../../config/WorkflowCategories';
import { Col, UncontrolledTooltip } from 'reactstrap';
import { RowDataType } from '../dataFormatter/FormatWorkflowsData';

export type NameColDataType = {
  style: { maxWidth: string };
  name: string;
  description: string;
  timestamp: string;
};

export const nameColDisplayFormatter = (
  colContent: NameColDataType,
  row: RowDataType,
) => {
  return (
    <div className={''}>
      <div
        id={`workflow-name-${row._id}`}
        data-test="workflow-list-item"
        className={
          'align-self-center text-dark text-truncate test-workflow-list-item font-weight-400'
        }
        style={row.Name.style}
      >
        {colContent}
      </div>
      <UncontrolledTooltip
        delay={0}
        placement="right"
        target={`workflow-name-${row._id}`}
      >
        {row.Name.description}
      </UncontrolledTooltip>

      <div
        data-test="last-runon"
        className={'align-self-center text-muted small test-last-runon pt-1'}
      >
        {row.Name.timestamp ? `Last executed ${row.Name.timestamp}` : ''}
      </div>
    </div>
  );
};

export const nameColDataFormatter = (data): NameColDataType => {
  let shouldShow = shouldShowTimeStamp(data.tabName);
  return {
    name: data.name || '',
    style: { maxWidth: shouldShow ? '80%' : '100%' },
    description: data.description || data.name || '',
    timestamp:
      shouldShow && data.executions && data.executions[0]
        ? `${data.executions[0].timestamp}`
        : '',
  };
};
