import moment from 'moment';
import React from 'react';

export const TIME_OPTIONS = [
  { value: '1d', label: 'Last day' },
  { value: '3d', label: 'Last 3 days' },
  { value: '7d', label: 'Last 7 days' },
  { value: '14d', label: 'Last 14 days' },
];

export const isUnderFilteredTime = (timestamp, selectedTimeStamp) => {
  let currentDate = new Date();
  let milliSecond = 0;
  let lastChar = selectedTimeStamp[selectedTimeStamp.length - 1];
  selectedTimeStamp = selectedTimeStamp.slice(0, -1);
  if (lastChar === 'd') {
    milliSecond = Number(selectedTimeStamp) * 24 * 60 * 60 * 1000;
  } else if (lastChar === 'h') {
    milliSecond = Number(selectedTimeStamp) * 60 * 60 * 1000;
  } else if (lastChar === 'm') {
    milliSecond = Number(selectedTimeStamp) * 60 * 1000;
  }
  if (
    moment(new Date(timestamp)) > moment(currentDate.getTime() - milliSecond)
  ) {
    return true;
  }
  return false;
};

export const getRequiredStringFromDate = (date, duration) => {
  if (duration === '1d') {
    return String(date.getUTCHours());
  } else {
    return `${date.getUTCDate()}/${date.getUTCMonth() + 1}`;
  }
};

export const filterAndGroupDataByDate = (metricData, duration) => {
  let xCoordinate: string[] = [];
  let value: number[] = [];
  let sum = 0;
  let count = 0;
  metricData.Timestamps.forEach((timestamp, i) => {
    if (isUnderFilteredTime(timestamp, duration)) {
      let date = new Date(timestamp);
      let requiredXCoordinate = getRequiredStringFromDate(date, duration);
      if (xCoordinate.length === 0) {
        xCoordinate.push(requiredXCoordinate);
        sum += metricData.Values[i];
        count = 1;
      } else if (xCoordinate.includes(requiredXCoordinate)) {
        sum += metricData.Values[i];
        count += 1;
      } else {
        xCoordinate.push(requiredXCoordinate);
        let avg = sum / count;
        value.push(avg);
        sum = metricData.Values[i];
        count = 1;
      }
    }
  });
  if (!isNaN(sum / count)) {
    value.push(sum / count);
  }
  return { xCoordinate, value };
};

export const labelFormatter = (colContent, row) => {
  return (
    <div>
      <strong>{row.label}</strong>
    </div>
  );
};

export const getOverAllUtilisationFormatter = (colContent, row) => {
  return (
    <div>
      <strong>{row.overAllUtilisation}</strong>
    </div>
  );
};
export const getUtilisationResourceSummaryColumns = () => {
  return [
    {
      dataField: 'label',
      colName: 'Label',
      text: 'Label',
      formatter: labelFormatter,
    },
    {
      dataField: 'overAllUtilisation',
      colName: 'Overall Utilisation',
      text: 'Overall Utilisation',
      formatter: getOverAllUtilisationFormatter,
    },
  ];
};
