import React from 'react';
import queryString from 'query-string';
import { set as setProperty } from 'lodash';
import { Button, Col, Label, Row } from 'reactstrap';
import InputSelect from './select-option.component';
import InputText from './input-text.component';

type IProps = {
  name: string;
  label: string;
  value: string;
  onChange: (event: any) => void;
  error?: string;
  keyOptions?: { label: string; value: string }[];
};

export class KeyValuePairEditor extends React.Component<IProps> {
  handleChange = ({ target: { name, value } }) => {
    const dimensions = this.getValue();
    setProperty(dimensions, name, value);
    this.setValue(dimensions);
  };

  handleAdd = () => {
    const dimensions = this.getValue();
    dimensions.push({ dId: 'name', dVal: 'value' });
    this.setValue(dimensions);
  };

  handleRemove = () => {
    const dimensions = this.getValue();
    if (dimensions.length) {
      this.setValue([...dimensions.slice(0, dimensions.length - 1)]);
    }
  };

  private setValue(dimensions) {
    const reduced = {};
    for (const dimension of dimensions) {
      reduced[dimension.dId] = dimension.dVal;
    }
    const value = queryString.stringify(reduced);
    /// change this later
    this.props.onChange({ target: { name: this.props.name, value: value } });
  }

  private getValue() {
    ///object to dimensionId, dimensionValue array
    return Object.entries(
      queryString.parse(this.props.value),
    ).map(([dId, dVal]) => ({ dId, dVal }));
  }

  render() {
    return (
      <>
        {this.props.label && <Label>{this.props.label}</Label>}
        {this.getValue().map((u, i) => (
          <Row key={i}>
            <Col md={6}>
              {this.props.keyOptions ? (
                <InputSelect
                  onChange={this.handleChange}
                  value={u.dId}
                  options={this.props.keyOptions}
                  name={`[${i}].dId`}
                  label={'Name'}
                />
              ) : (
                <InputText
                  onChange={this.handleChange}
                  value={u.dId}
                  name={`[${i}].dId`}
                />
              )}
            </Col>
            <Col md={6}>
              <InputText
                onChange={this.handleChange}
                value={u.dVal}
                name={`[${i}].dVal`}
                label={'Value'}
              />
            </Col>
          </Row>
        ))}
        <Row className="px-3">
          <Button onClick={this.handleAdd} color={'primary'} size={'sm'}>
            Add
          </Button>
          <Button
            onClick={this.handleRemove}
            color={'primary'}
            outline={true}
            className={'ml-2'}
            size={'sm'}
          >
            Remove
          </Button>
        </Row>
        {this.props.error && <Row className="text-red">{this.props.error}</Row>}
      </>
    );
  }
}
