export function getLoginErrorMessage(err: any): string {
  switch (err.errorCode) {
    case 'E0000004':
      return 'Invalid username and/or password';
    case undefined:
      return MSG_NO_INTERNET;
    default:
      return 'Error logging in. Please try again.';
  }
}

export function getSignUpErrorMessage(err: any): string {
  return err.errorCauses[0].errorSummary;
}

export function getForgotPasswordErrorMessage(err: any): string {
  return err.errorCauses[0].errorSummary;
}

export const MSG_NO_INTERNET: string = 'Please check your internet connection';

export const MSG_PASSWORD_RESET_LINK_SENT: string =
  'A password reset message has been sent to your email address. ' +
  'Please click on the link in that message to reset your password. ' +
  "If you don't receive the message within a few moments, please check your spam folder or other filtering tools " +
  'or resend the message.';
