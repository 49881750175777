const { saveSync } = require('save-file');
const jsonExport = require('jsonexport/dist');

function jsonToCsv(data) {
  return new Promise((resolve, reject) => {
    jsonExport(data, function(err, data) {
      if (err) return reject(err);
      return resolve(data);
    });
  });
}

/**
 * @param jsObj
 */
export async function downloadAsCSV(jsObj: any) {
  if (jsObj.data) jsObj = jsObj.data;
  if (!Array.isArray(jsObj)) jsObj = [jsObj];
  let csv = await jsonToCsv(jsObj);
  await saveSync(csv, 'report.csv');
}

export default downloadAsCSV;
