import * as React from 'react';
import { Button, Col, Row } from 'reactstrap';
import classnames from 'classnames';
import HelpSection from './HelpSection';
import { INode, IWorkflow, NodeDataType } from 'workflow-model/dist';
import { ReactNode } from 'react';
import { captureEvent } from '../../../../../analytics/eventCapturer';
import SplitPane from 'react-split-pane';

export type JsonSectionDataType = {
  selectedNode: INode;
  data: NodeDataType;
  workflow: IWorkflow;
};

interface IProps {
  title: string | ReactNode;
  onCancel: () => void | boolean;
  onSubmit: (data?: any) => void;
  helpText?: React.ReactNode;
  jsonData?: JsonSectionDataType;
  uiData?: any;
  submitDisabled?: boolean;
  skipSaveButton?: boolean;
  text?: {
    save?: string;
    cancel?: string;
  };
}

export class ModalWrapper extends React.Component<IProps> {
  constructor(props) {
    super(props);
  }

  renderModalBody = () => {
    const nodeName =
      this.props.jsonData &&
      this.props.jsonData.selectedNode.getNodeName().toUpperCase();
    const cancelText = (this.props.text && this.props.text.cancel) || 'Cancel';
    const saveText = (this.props.text && this.props.text.save) || 'Save';
    return (
      <div className={'p-4'}>
        {/*header*/}
        <Row>
          <Col>
            <h4>{this.props.title}</h4>
          </Col>
        </Row>

        {/*body*/}
        <Row className={classnames('border-top', 'border-bottom')}>
          <Col className={classnames('node-ui-modal-body pt-3')}>
            {this.props.children}
          </Col>
        </Row>

        {/*footer*/}
        <Row className={classnames('d-flex', 'justify-content-start pt-2')}>
          {!this.props.skipSaveButton && (
            <Col md={4}>
              <Button
                data-testid={'node-modal-wrapper-save-button'}
                color="primary"
                block={true}
                disabled={this.props.submitDisabled}
                onClick={captureEvent(this.props.onSubmit, `${nodeName}_SAVE`)}
              >
                {saveText}
              </Button>
            </Col>
          )}
          <Col md={4}>
            <Button
              data-testid="node-modal-wrapper-cancel-button"
              color="primary"
              outline={true}
              block={true}
              onClick={captureEvent(this.props.onCancel, `${nodeName}_CANCEL`)}
              disabled={!this.props.onCancel}
            >
              {cancelText}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  renderModalHelp = () => {
    return (
      <div
        className={classnames(
          'px-4',
          'pt-4',
          'bg-help',
          'text-dark',
          'help-section',
        )}
      >
        {/*help header*/}
        <HelpSection
          helpText={this.props.helpText}
          jsonData={this.props.jsonData}
        />
      </div>
    );
  };
  render(): React.ReactNode {
    // console.log('name ', this.props.jsonData.selectedNode.getNodeName());

    return (
      <Row noGutters={true}>
        <Col md={12}>
          {this.props.helpText ? (
            <SplitPane
              split="vertical"
              defaultSize={'30%'}
              style={{ position: 'relative' }}
              primary="second"
            >
              {this.renderModalBody()}
              {/*helpBar*/}
              {this.renderModalHelp()}
            </SplitPane>
          ) : (
            this.renderModalBody()
          )}
        </Col>
      </Row>
    );
  }
}
