import * as React from 'react';
import { LastExecutionsStatus } from './LastExecutionsStatus';
import { ICustomTableCol } from '../../common/table';
import {
  fetchingMetricCol,
  formatAccountLevelHighUtilisationCol,
  formatAccountLevelInRangeUtilisationCol,
  formatAccountLevelLowUtilisationCol,
  formatAccountLevelTotalResourcesUtilisationCol,
  formatHighUtilisationCol,
  formatLowUtilisationCol,
  formatOverallUtilisationCol,
  formatTotalResourceCol,
} from './formatter/UtilisationPageFormatters';

import { nameColDisplayFormatter } from './formatter/NameColFormatter';
import { ServiceColDataType } from './formatter/ServiceColFormatter';
import { resultColDisplayFormatter } from './formatter/ResultColFormatter';
import { optionsColDisplayFormatter } from './formatter/OptionsColFormatter';
import {
  FixColDataType,
  fixColDisplayFormatter,
} from './formatter/FixColFormatter';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { RowDataType } from './dataFormatter/FormatWorkflowsData';
import { Badge } from 'reactstrap';
import {
  formatAccountCol,
  formatAccountLabelCol,
  formatSummaryAccountLabelCol,
} from './formatter/accountColFormatter';
import { formatResourceCol } from './formatter/ResourceColFormatter';
import {
  formatAllInsight,
  formatSuccessInsight,
  formatFailureInsight,
  formatUnknownInsight,
  formatFailureInsightWithHighSeverity,
  formatFailureInsightWithMediumSeverity,
  formatFailureInsightWithLowSeverity,
} from './formatter/InsightColFormatter';
import {
  regionFilterValue,
  regionDisplayFormatter,
} from './formatter/RegionColFormatter';

export enum COL_NAMES {
  'FIX' = 'fix',
}

export const getPastExecutionsFormatter = (
  colContent,
  row,
): React.ReactNode => {
  if (row.isTemplateItem) return '';
  return (
    <LastExecutionsStatus
      isFetching={row.isFetchingExecution}
      statusData={colContent}
    />
  );
};

export const getColsForTabs = (tabName: TAB_NAME) => {
  switch (tabName) {
    case TAB_NAME.INSIGHTS:
      return [
        'Name',
        'Account_Label',
        'Region',
        'Service',
        'Severity',
        'Result',
        'Priority',
        COL_NAMES.FIX,
      ];
    case TAB_NAME.AUTO_REMEDIATION:
      return ['Name', 'Account_Label', 'Region', 'Result', 'Executions'];
    case TAB_NAME.WORKFLOWS:
      return ['Name', 'Account_Label', 'Region', 'Result', 'Executions'];
    case TAB_NAME.UTILISATION:
      return [
        'Resource',
        'Account_Label',
        'Region',
        'Total Resources',
        'Metric Name',
        'Overall Utilisation',
        'High Utilisation',
        'Low Utilisation',
      ];
    case TAB_NAME.EVENTS:
      return [
        'Name',
        'Account_Label',
        'Region',
        'Severity',
        'Service',
        'Result',
      ];
    case TAB_NAME.EVENTS_SEARCH:
      return [
        'EventName',
        'Account_Label',
        'Region',
        'EventTime',
        'IamUser',
        'EventSource',
      ];
    case TAB_NAME.INVENTORY:
      return [
        'Resource',
        'Service',
        'Account_Label',
        'Region',
        'Total Resources',
        'Untagged',
      ];
    default:
      return ['Name'];
  }
};

export const getSummaryColsForTabs = (tabName: TAB_NAME) => {
  switch (tabName) {
    case TAB_NAME.UTILISATION:
      return [
        'Account',
        'Account Level Total Resources',
        'Account Level In-range Utilisation',
        'Account Level Low Utilisation',
        'Account Level High Utilisation',
      ];
    case TAB_NAME.INVENTORY:
      return ['Account', 'Total Resources', 'Untagged'];
    case TAB_NAME.INSIGHTS:
      return [
        'AccountLabel',
        'ServiceLabel',
        'All',
        'Passing',
        'Failing',
        'High',
        'Medium',
        'Low',
        'Unknown',
      ];
    default:
      return ['Name'];
  }
};

export const TabCols: ICustomTableCol[] = [
  {
    colName: 'workflowId',
    dataField: 'workflow',
    text: 'workflow',
    hidden: true,
  },
  {
    colName: 'templateId',
    dataField: 'id',
    text: 'id',
    hidden: true,
  },
  {
    colName: 'Name',
    dataField: 'Name.name',
    text: 'Name',
    sort: true,
    headerStyle: { width: '30%' },
    formatter: nameColDisplayFormatter,
  },
  {
    colName: 'Region',
    dataField: 'regionName',
    text: 'Region/ResourceGroup',
    sort: true,
    headerStyle: { width: '15%' },
    filterValue: regionFilterValue,

    formatter: regionDisplayFormatter,
  },
  {
    colName: 'Account_Label',
    dataField: 'accountName',
    text: 'Account',
    sort: true,
    headerStyle: { width: '10%' },
    formatter: formatAccountLabelCol,
  },
  {
    colName: 'Result',
    dataField: 'Result.status',
    text: 'Result',
    sort: true,
    headerStyle: { minWidth: '25%', width: '20%' },
    formatter: resultColDisplayFormatter,

    sortValue: (col: string, row: RowDataType) =>
      row.Result ? row.Result.sortValue : undefined,
  },
  {
    colName: 'Service',
    dataField: 'service',
    text: 'Service',
    sort: true,
    align: 'center',
    headerStyle: { width: '10%', minWidth: '5%', textAlign: 'center' },
  },
  {
    colName: 'Severity',
    dataField: 'severity',
    text: 'Severity',
    sort: true,
    headerStyle: { width: '12%', textAlign: 'center' },
    align: 'center',
    classes: 'font-weight-bold',
    sortValue: (col: ServiceColDataType) => {
      return col === 'HIGH' ? 3 : col === 'MEDIUM' ? 2 : 1;
    },
  },
  {
    colName: COL_NAMES.FIX,
    dataField: 'Fix',
    text: 'Fix',
    headerStyle: { width: '9%' },
    sort: true,
    sortValue: (col: FixColDataType) =>
      col.linkedTemplate ? 1 : col.linkedWorkflow ? 0 : -1,
    formatter: fixColDisplayFormatter,
  },

  {
    colName: 'Options',
    headerStyle: { width: '8%' },
    dataField: 'options',
    text: '',
    formatter: optionsColDisplayFormatter,
    // hidden: true,
  },
  {
    colName: 'Executions',
    dataField: 'executions',
    text: 'Past Executions',
    formatter: getPastExecutionsFormatter,
    headerStyle: { width: '10%' },
  },
  {
    colName: 'Priority',
    // headerStyle: { width: '10%' },
    dataField: 'properties.priority',
    text: 'Priority',
    sort: true,
    // formatter : getPriorityFormatter
    hidden: true,
  },

  //  utilisationTable
  {
    colName: 'Resource',
    dataField: 'resource',
    text: 'Resource',
    sort: true,
    headerStyle: { width: '20%' },
    formatter: formatResourceCol,
  },

  {
    colName: 'Total Resources',
    headerStyle: { width: '10%', textAlign: 'center' },
    dataField: 'totalResources',
    text: 'Total',
    sort: true,
    align: 'center',
    formatter: formatTotalResourceCol,
  },
  {
    colName: 'Untagged',
    headerStyle: { width: '10%', textAlign: 'center' },
    dataField: 'unTagged',
    text: 'Untagged',
    sort: true,
    align: 'center',
    formatter: formatTotalResourceCol,
  },
  {
    colName: 'Metric Name',
    dataField: 'metricName',
    headerStyle: { textAlign: 'center' },
    text: 'Metric Name',
    sort: true,
    align: 'center',
    formatter: fetchingMetricCol,
  },
  {
    colName: 'Overall Utilisation',
    dataField: 'overAllUtilisation',
    headerStyle: { textAlign: 'center' },
    text: 'OverAll Utilisation',
    sort: true,
    align: 'center',
    formatter: formatOverallUtilisationCol,
  },
  {
    colName: 'High Utilisation',
    dataField: 'highUtilisation',
    headerStyle: { textAlign: 'center' },
    text: 'High Utilisation',
    sort: true,
    align: 'center',
    formatter: formatHighUtilisationCol,
  },
  {
    colName: 'Low Utilisation',
    dataField: 'lowUtilisation',
    headerStyle: { textAlign: 'center' },
    text: 'Low Utilisation',
    sort: true,
    align: 'center',
    formatter: formatLowUtilisationCol,
  },
  {
    colName: 'Account',
    dataField: 'accountName',
    text: 'Account',
    sort: true,
    headerStyle: { width: '20%' },
    formatter: formatAccountCol,
  },
  {
    colName: 'AccountLabel',
    dataField: 'accountLabel',
    text: 'Account/Group',
    sort: true,
    headerStyle: { width: '20%' },
    formatter: formatSummaryAccountLabelCol,
  },
  {
    colName: 'ServiceLabel',
    dataField: 'serviceLabel',
    text: 'Service',
    sort: true,
    headerStyle: { width: '20%' },
  },
  {
    colName: 'Account Level Total Resources',
    dataField: 'totalResources',
    headerStyle: { textAlign: 'center' },
    text: 'Total Resources',
    sort: true,
    align: 'center',
    formatter: formatAccountLevelTotalResourcesUtilisationCol,
  },
  {
    colName: 'Account Level Low Utilisation',
    dataField: 'lowUtilisation',
    headerStyle: { textAlign: 'center' },
    text: 'Low Utilisation',
    sort: true,
    align: 'center',
    formatter: formatAccountLevelLowUtilisationCol,
  },
  {
    colName: 'Account Level High Utilisation',
    dataField: 'highUtilisation',
    headerStyle: { textAlign: 'center' },
    text: 'High Utilisation',
    sort: true,
    align: 'center',
    formatter: formatAccountLevelHighUtilisationCol,
  },
  {
    colName: 'Account Level In-range Utilisation',
    dataField: 'InRangeUtilisation',
    headerStyle: { textAlign: 'center' },
    text: 'In-Range Utilisation',
    sort: true,
    align: 'center',
    formatter: formatAccountLevelInRangeUtilisationCol,
  },
  {
    colName: 'All',
    dataField: 'all',
    headerStyle: { textAlign: 'center' },
    text: 'All',
    sort: true,
    align: 'center',
    formatter: formatAllInsight,
  },
  {
    colName: 'Passing',
    dataField: 'passing',
    headerStyle: { textAlign: 'center' },
    text: 'Passing',
    sort: true,
    align: 'center',
    formatter: formatSuccessInsight,
  },
  {
    colName: 'Failing',
    dataField: 'failing',
    headerStyle: { textAlign: 'center' },
    text: 'Failing',
    sort: true,
    align: 'center',
    formatter: formatFailureInsight,
  },
  {
    colName: 'High',
    dataField: 'high',
    headerStyle: { textAlign: 'center', color: 'red' },
    text: 'High',
    sort: true,
    align: 'center',
    formatter: formatFailureInsightWithHighSeverity,
  },
  {
    colName: 'Medium',
    dataField: 'medium',
    headerStyle: { textAlign: 'center', color: 'red' },
    text: 'Medium',
    sort: true,
    align: 'center',
    formatter: formatFailureInsightWithMediumSeverity,
  },
  {
    colName: 'Low',
    dataField: 'low',
    headerStyle: { textAlign: 'center', color: 'red' },
    text: 'Low',
    sort: true,
    align: 'center',
    formatter: formatFailureInsightWithLowSeverity,
  },
  {
    colName: 'Unknown',
    dataField: 'unknown',
    headerStyle: { textAlign: 'center' },
    text: 'Unknown',
    sort: true,
    align: 'center',
    formatter: formatUnknownInsight,
  },
  {
    colName: 'EventName',
    dataField: 'EventName',
    text: 'Event Name',
    sort: true,
  },
  {
    colName: 'EventTime',
    dataField: 'EventTime',
    text: 'Time',
    sort: true,
  },
  {
    colName: 'IamUser',
    dataField: 'Username',
    text: 'User',
    sort: true,
  },
  {
    colName: 'EventSource',
    dataField: 'EventSource',
    text: 'EventSource',
    sort: true,
  },
];

// name and options should alwayes be present
export const getCols = (
  fields: string[],
  showOption: boolean = true,
  extraCols?: ICustomTableCol[],
): ICustomTableCol[] => {
  if (!fields || fields.length === 0) return TabCols;

  let resultCols: ICustomTableCol[] = [];

  // resultCols.push(cols.find((x) => x.colName === 'Name'));

  // do not add duplicates as name and option are default
  fields = fields.filter((x) => x !== 'Options');

  for (let f of fields) {
    resultCols.push(TabCols.find((x) => x.colName === f));
  }
  if (extraCols) {
    resultCols = resultCols.concat(extraCols);
  }
  if (showOption) {
    resultCols.push(TabCols.find((x) => x.colName === 'Options'));
  }
  resultCols.push(TabCols.find((x) => x.colName === 'templateId'));
  resultCols.push(TabCols.find((x) => x.colName === 'workflowId'));
  return resultCols.filter((x) => !!x);
};
