import * as React from 'react';
import { FormikProps } from 'formik';
import { Button, Col, Modal, Row } from 'reactstrap';
import { MultiReportingFormSchema } from './reportingFormSchema';
import CustomRadioButtons, {
  ORIENTATION,
} from '../../../lib/formInputs/radio-button.component';
import { SlackInputField } from '../../../lib/editor/components/ui/nodeUI/common/SlackInputSelect';
import InputText from '../../../lib/formInputs/input-text.component';
import { NotificationType, ScheduleType, ViewType } from 'workflow-model/dist';
import { SectionLoader } from '../../common/SectionLoader';
import { ModalWrapper } from '../../../lib/editor/components/ui/common/ModalWrapper';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { MultiScheduleForm } from '../../common/MultiScheduleForm/MultiScheduleForm';
import { MultiScheduleSchema } from '../../common/MultiScheduleForm/schema';
import SwitchContainer from '../../../lib/switch/SwitchContainer';
import { defaultSchedule } from '../../../lib/editor/components/ui/nodeUI/trigger/TriggerForm';

export enum viewType {
  'NOW' = 'now',
  'LATER' = 'later',
}

interface FormProps extends FormikProps<MultiReportingFormSchema> {
  onClose: () => void;
  isFetching: boolean;
  tabName: TAB_NAME;
  reportingAction: boolean;
}

const helpText = (props: FormProps) => (
  <div>
    <p>
      You can configure these settings to periodically trigger {props.tabName}.
    </p>
    <p>
      All the Schedule/Cron time will be evaluated in UTC format. Time selected
      or entered should correspond to their UTC time.
    </p>
  </div>
);

export class WorkflowReportForm extends React.Component<FormProps> {
  eventHandlers = {
    onChange: this.props.handleChange,
    onBlur: this.props.handleBlur,
  };

  getFieldValues = (field: keyof MultiReportingFormSchema) => {
    return {
      name: field,
      value: this.props.values[field] || '',
      error: this.props.errors[field]
        ? this.props.errors[field].toString()
        : '',
      touched: this.props.submitCount > 0 || this.props.touched[field] || false,
      placeholder: field,
    };
  };

  /**
   * Renders the toggle active button to enable disable the trigger workflow
   * This trigger set and unset the trigger value
   *
   */
  renderActiveToggleButton = () => {
    const { handleChange, setFieldValue, values } = this.props;
    return (
      <Row className={''}>
        <Col className={'py-3'}>
          Enable Trigger
          <SwitchContainer
            size={'small'}
            value={values.schedule.active}
            onChange={(value) => {
              if (value) {
                setFieldValue('schedule.value', [defaultSchedule]);
              } else {
                setFieldValue('schedule.value', []);
              }
              setFieldValue('schedule.active', value);
            }}
          />
        </Col>
      </Row>
    );
  };

  renderNotificationType = () => {
    let { type, slackConfig, notifyEmail } = this.props.values;
    return (
      <>
        <Row className={'my-2'}>
          <Col>
            <label className={'text-dark'}>
              Select a notification application
            </label>
          </Col>
        </Row>

        <Row className={'my-2'}>
          <Col md={3}>
            <Button
              name={'slackButton'}
              color={'primary'}
              outline
              active={type === NotificationType.SLACK}
              onClick={() =>
                this.props.handleChange({
                  target: { name: 'type', value: NotificationType.SLACK },
                })
              }
            >
              Slack
            </Button>
          </Col>
          <Col md={3}>
            <Button
              name={'emailButton'}
              color={'primary'}
              outline
              active={type === NotificationType.EMAIL}
              onClick={() =>
                this.props.handleChange({
                  target: { name: 'type', value: NotificationType.EMAIL },
                })
              }
            >
              Email
            </Button>
          </Col>
        </Row>

        <Row>
          <Col md={11}>
            {/*section:slack*/}
            {type === 'slack' && (
              <SlackInputField
                {...this.eventHandlers}
                value={slackConfig}
                name={'slackConfig'}
                error={this.props.errors.slackConfig}
              />
            )}

            {/*section:email*/}
            {type === 'email' && (
              <Row className={'mt-4'}>
                <Col>
                  <InputText
                    {...this.eventHandlers}
                    {...this.getFieldValues('notifyEmail')}
                    label={"Provide receiver's email"}
                    placeholder={"Enter receiver's email"}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </>
    );
  };
  renderView = () => {
    let { group, schedule } = this.props.values;
    if (group === viewType.NOW) {
      return <>{this.renderNotificationType()}</>;
    } else {
      return (
        <>
          {this.renderNotificationType()}
          {this.renderActiveToggleButton()}
          {schedule.active && (
            <MultiScheduleForm<MultiScheduleSchema> {...this.props} />
          )}
        </>
      );
    }
  };

  getSaveButtonText = (): string => {
    let { group } = this.props.values;
    if (group === viewType.NOW)
      return this.props.reportingAction ? 'Sending..' : 'Send Report';
    return this.props.reportingAction ? 'Saving..' : 'Save';
  };

  changeViewType = (e) => {
    this.props.handleChange({
      target: { name: 'group', value: e.target.value },
    });
    e.target.value === viewType.NOW &&
      this.props.setFieldValue('schedule.active', false);
  };

  render(): React.ReactNode {
    return (
      <Modal
        size={'xl'}
        isOpen={true}
        toggle={this.props.onClose}
        backdrop={'static'}
      >
        <ModalWrapper
          submitDisabled={this.props.reportingAction}
          text={{ save: this.getSaveButtonText() }}
          title={`Send Report Of All Active ${this.props.tabName}`}
          onCancel={this.props.onClose}
          onSubmit={this.props.handleSubmit}
          helpText={helpText(this.props)}
        >
          {this.props.isFetching && !this.props.reportingAction ? (
            <SectionLoader />
          ) : (
            <>
              <Row className={'text-center '}>
                <Col>
                  <CustomRadioButtons
                    {...this.eventHandlers}
                    {...this.getFieldValues('group')}
                    onChange={this.changeViewType}
                    options={[
                      { label: 'Send Later', value: viewType.LATER },
                      { label: 'Send Now', value: viewType.NOW },
                    ]}
                    name={'group'}
                    orientation={ORIENTATION.horizontal}
                  />
                </Col>
              </Row>
              {this.renderView()}
            </>
          )}
        </ModalWrapper>
      </Modal>
    );
  }
}
