import InferType from './aggregations/inferType';
import MetricHandler from './transforms/metricHandler';
import { TcPropsExclusion } from './transforms/tcProps';
import DotKeysHandler from './transforms/dotKeysHandler';

const pipelines = [
  new MetricHandler(),
  new TcPropsExclusion(),
  new DotKeysHandler(),
];

function isPrimitive(value: any): boolean {
  return typeof value !== 'object';
}

function ensureArray(data: any): Array<any> {
  if (!data) return [];
  if (Array.isArray(data)) {
    if (!data.length) return [];
    if (isPrimitive(data[0])) {
      return data.map((e, i) => ({ key: i, value: e }));
    }
    return data;
  }
  return [];
}

export const fixStructure = (data) => {
  let array = ensureArray(data);

  for (const pipeline of pipelines) {
    for (let i = 0; i < array.length; i++) {
      array[i] = pipeline.apply(array[i]);
    }
    array = array.reduce((a, e) => a.concat(e), []);
  }

  return array;
};

export const insertTypeInformation = (data: any[]) => {
  let inferTypes = new InferType();
  for (const datum of data) {
    inferTypes.digest(datum);
  }
  return [inferTypes.reduce()].concat(data);
};
