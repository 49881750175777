import { FormatDataProps, getTableData } from './tableData';
import { getTemplateStatus } from '../cols/TemplateStatus';
import { TableItem } from '../cols';
import { Workflow } from '../../../../lib/store/workflows/types';
import CustomTable from '../../../common/table';
import React from 'react';
import {
  filterItemsByMultipleCategories,
  getFilterCategoriesByTabName,
} from 'webapp-genric/dist/filters';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { Template } from '../../../../lib/store/templates/types';
import { CONFIG_TYPES } from 'webapp-genric/dist';

type SummaryChildTableRowType = {
  itemType: string;
  count: number;
  color?: string;
};

type SummaryTableRowType = {
  itemType: string;
  count: number;
  childTableData: SummaryChildTableRowType[];
};

const formatWorkflowChildTableData = (
  workflowTableData: TableItem[],
  workflows: Workflow[],
): SummaryChildTableRowType[] => {
  let result: SummaryChildTableRowType[] = [];
  let templateExists = 0;
  let activeWorkflows = 0;

  for (let row of workflowTableData) {
    let { TE, NV } = getTemplateStatus(row);
    if (TE) templateExists = templateExists + 1;
    if (
      row.workflowItem &&
      row.workflowItem.properties &&
      row.workflowItem.properties.active
    )
      activeWorkflows = activeWorkflows + 1;
  }

  result.push({ itemType: 'Workflows With Template', count: templateExists });
  result.push({
    itemType: 'Workflows WithOut Templates',
    count: workflows.length - templateExists,
  });
  result.push({ itemType: 'Active Workflows', count: activeWorkflows });
  result.push({
    itemType: 'Inactive Workflows',
    count: workflows.length - activeWorkflows,
  });

  return result;
};

const formatTemplateChildTableData = (
  allTemplatesData: TableItem[],
  orphanTemplatesData: TableItem[],
): SummaryChildTableRowType[] => {
  let result: SummaryChildTableRowType[] = [];
  let newVersionAvailable = 0;
  let publishPending = 0;
  let activeTemplates = 0;
  let cloudTypesCountMap = {};
  for (let row of allTemplatesData) {
    let { NV, PP } = getTemplateStatus(row);
    if (NV) newVersionAvailable = newVersionAvailable + 1;
    if (PP) publishPending = publishPending + 1;
    if (
      row.templateItem &&
      row.templateItem.properties &&
      row.templateItem.properties.active
    ) {
      const key = `${row.cloudTypes.toString()} (Active)`;
      activeTemplates = activeTemplates + 1;
      cloudTypesCountMap[key] = cloudTypesCountMap[key] || 0;
      cloudTypesCountMap[key] = cloudTypesCountMap[key] + 1;
    }
  }

  result.push({ itemType: 'Active Templates', count: activeTemplates });
  result.push({
    itemType: 'Inactive Templates',
    count: allTemplatesData.length - activeTemplates,
  });

  result = result.concat(
    Object.keys(cloudTypesCountMap).map((x) => ({
      itemType: x,
      count: cloudTypesCountMap[x],
    })),
  );

  result.push({
    itemType: 'New Version Available',
    count: newVersionAvailable,
    color: '#FF7A00',
  });
  result.push({
    itemType: 'Publish Pending',
    count: publishPending,
    color: '#FF0000',
  });
  result.push({
    itemType: 'Orphan Templates',
    count: orphanTemplatesData.length,
    color: '#FF0000',
  });

  return result;
};

const formatInsightsChildTableData = (
  insightsTemplates: Template[],
): SummaryChildTableRowType[] => {
  let result: SummaryChildTableRowType[] = [];
  let insightsWthFixes = insightsTemplates.filter(
    (x) =>
      x.properties &&
      x.properties.linkedTemplates &&
      x.properties.linkedTemplates.length,
  );

  result.push({
    itemType: 'Insights with fixes',
    count: insightsWthFixes.length,
    color: '#078505',
  });
  result.push({
    itemType: 'Insights without fixes',
    count: insightsTemplates.length - insightsWthFixes.length,
    color: '#FF0000',
  });
  return result;
};

const formatTemplateTestChildTableData = (
  templatesWithTestSettings: TableItem[],
): SummaryChildTableRowType[] => {
  let result: SummaryChildTableRowType[] = [];
  let templatesTested = 0;
  let templatesSuccess = 0;
  let templatesFailed = 0;

  for (let temp of templatesWithTestSettings) {
    if (temp.templateTestStatus) {
      templatesTested = templatesTested + 1;
      temp.templateTestStatus.status === 'Success'
        ? (templatesSuccess = templatesSuccess + 1)
        : (templatesFailed = templatesFailed + 1);
    }
  }
  result.push({ itemType: 'Templates tested', count: templatesTested });
  result.push({
    itemType: 'Test Status Success',
    count: templatesSuccess,
    color: '#078505',
  });
  result.push({
    itemType: 'Test Status Error',
    count: templatesFailed,
    color: '#FF0000',
  });
  return result;
};

export const getSummaryTableData = (
  props: FormatDataProps,
): SummaryTableRowType[] => {
  let result: SummaryTableRowType[] = [];
  let { workflows, webflowRecords } = props;
  let { data, allTemplatesData, orphanTemplatesData } = getTableData(props);

  let templateItems = allTemplatesData.map((x) => x.templateItem);
  let insightsTemplates = filterItemsByMultipleCategories(
    templateItems,
    getFilterCategoriesByTabName(TAB_NAME.INSIGHTS),
  );
  let utilisationTemplates = filterItemsByMultipleCategories(
    templateItems,
    getFilterCategoriesByTabName(TAB_NAME.UTILISATION),
  );
  let autoRemediationTemplates = filterItemsByMultipleCategories(
    templateItems,
    getFilterCategoriesByTabName(TAB_NAME.AUTO_REMEDIATION),
  );

  let templatesWithTestSettings = allTemplatesData.filter(
    (x) =>
      x.templateItem.properties && x.templateItem.properties['testSettings'],
  );

  result.push({
    itemType: 'Templates',
    count: allTemplatesData.length,
    childTableData: formatTemplateChildTableData(
      allTemplatesData,
      orphanTemplatesData,
    ),
  });

  result.push({
    itemType: 'Templates With Test Settings',
    count: templatesWithTestSettings.length,
    childTableData: formatTemplateTestChildTableData(templatesWithTestSettings),
  });

  result.push({
    itemType: 'Workflows',
    count: workflows.length,
    childTableData: formatWorkflowChildTableData(data, workflows),
  });

  result.push({
    itemType: 'Insights Templates',
    count: insightsTemplates.length,
    childTableData: formatInsightsChildTableData(insightsTemplates),
  });

  result.push({
    itemType: 'Auto Remediation Templates',
    count: autoRemediationTemplates.length,
    childTableData: [],
  });

  result.push({
    itemType: 'Utilisation Templates',
    count: utilisationTemplates.length,
    childTableData: [],
  });
  result.push({
    itemType: 'Orphan Webflow Items',
    count: webflowRecords ? webflowRecords.length : 0,
    childTableData: [],
  });

  return result.map((x, i) => ({ _id: i, ...x }));
};
