export const RequestType = 'RequestType';
export const RequestTypeSuccess = 'RequestTypeSuccess';

interface RequestType {
  type: typeof RequestType;
  path: string;
}

interface RequestTypeSuccess {
  type: typeof RequestTypeSuccess;
  path: string;
  data: any;
}

export type SGCTypeAction = RequestType | RequestTypeSuccess;

export type SgcResultType<T = any> = {
  fetching: boolean;
  data: T;
};
export type SGCTypeState = {
  sgcType: {
    [typePath: string]: SgcResultType;
  };
};

export type SGCResourceApi = {
  service: string;
  displayService: string;
  resources: {
    method: string;
    resourceType: string;
    displayResourceType?: string;
    displayName: string;
    active: boolean;
  }[];
};

export type SGCActionApi = {
  service: string;
  displayService: string;
  actions: {
    method: string;
    resourceType: string;
    displayResourceType?: string;
    displayName: string;
    active?: boolean;
  }[];
};

export type SGCAddon = {
  service: string;
  displayService: string;
  displayResourceType?: string;
  method: string;
  resourceType: string;
  displayName: string;
  active: boolean;
  params: any;
};

export type SGCProviders = SGCAddon;
