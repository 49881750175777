import { Reducer } from 'redux';
import { ExecutionAction, ExecutionState } from './types';

const initialState: ExecutionState = {
  batchFetching: false,
  fetching: false,
  data: {},
  workflowExecutions: {},
};

export const executionReducer: Reducer<ExecutionState, ExecutionAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'ResetExecutionMap':
      return { ...state, workflowExecutions: {} };
    case 'FetchExecution':
      return { ...state, fetching: true };
    case 'FetchBatchExecution':
      return {
        ...state,
        batchFetching: true,
        fetchingUtilisationSummary: true,
      };
    case 'FetchBatchExecutionSuccess':
      return { ...state, batchFetching: false };
    case 'FetchExecutionSuccess':
      return {
        ...state,
        fetching: false,
        data: {
          ...state.data,
          [action.duration]: action.data || state.data[action.duration],
        },
      };
    case 'FetchWorkflowExecution':
      return {
        ...state,
        workflowExecutions: {
          ...state.workflowExecutions,
          [action.workflow]: {
            ...state.workflowExecutions[action.workflow],
            fetching: true,
          },
        },
      };
    case 'FetchBatchWorkflowExecution': {
      let workflowExecutions = state.workflowExecutions;
      for (let wf of action.workflows) {
        workflowExecutions[wf] = {
          ...workflowExecutions[wf],
          fetching: true,
        };
      }
      return { ...state, workflowExecutions };
    }

    case 'FetchBatchWorkflowExecutionSuccess': {
      let workflowExecutions = state.workflowExecutions;
      for (let wf of action.workflows) {
        workflowExecutions[wf.workflow] = {
          ...workflowExecutions[wf.workflow],
          fetching: false,
          exes:
            wf.executions ||
            (state.workflowExecutions[wf.workflow] &&
              state.workflowExecutions[wf.workflow].exes),
          output:
            wf.output ||
            (state.workflowExecutions[wf.workflow] &&
              state.workflowExecutions[wf.workflow].output),
        };
      }
      return { ...state, workflowExecutions };
    }
    case 'FetchWorkflowExecutionSuccess':
      return {
        ...state,
        fetching: false,
        workflowExecutions: {
          ...state.workflowExecutions,
          [action.workflow]: {
            ...state.workflowExecutions[action.workflow],
            fetching: false,
            exes:
              action.data ||
              (state.workflowExecutions[action.workflow] &&
                state.workflowExecutions[action.workflow].exes),
            output:
              action.output ||
              (state.workflowExecutions[action.workflow] &&
                state.workflowExecutions[action.workflow].output),
          },
        },
      };
    default:
      return state;
  }
};
