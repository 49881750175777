import React from 'react';
import { FormikProps } from 'formik';
import { TriggerSchema } from '../schema';
import Switch from '../../../../../../switch/SwitchContainer';
import { Colors } from '../../../../../Colors';

type IProps = FormikProps<TriggerSchema> & {
  targetType: 'alarm' | 'schedule' | 'http' | 'manual' | 'event';
};

export function ToggleActiveState(props: IProps) {
  const target = props.values[props.targetType];
  const toggleActive = () => {
    props.setFieldValue(`${props.targetType}.active`, !target.active);
  };

  return <ToggleButton isActive={target.active} toggleActive={toggleActive} />;
}

export const ToggleButton = (props: {
  isActive: boolean;
  toggleActive: () => void;
  disabled?: boolean;
}) => (
  <div style={{ display: 'flex', margin: '1em 0' }}>
    <div>Inactive</div>
    <Switch
      value={props.isActive}
      onChange={() => props.toggleActive()}
      disabled={props.disabled}
    />
    <div>Active</div>
  </div>
);
