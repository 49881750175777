declare const analytics: any;

export const load = () => {
  window &&
    // @ts-ignore
    window['analytics'] &&
    analytics.load('CfLekLE83Dgkskz7TLu8u4wBvvHRA99s');
};

export const page = (name?: string) => {
  // @ts-ignore
  window && window['analytics'] && analytics.page(name);
};

export const track = (name: string, properties: any) => {
  // @ts-ignore
  try {
    window && window['analytics'] && analytics.track(name, properties);
  } catch (err) {
    console.error(err);
  }
};

export const identify = (
  userId: string,
  traits?: any,
  options?: any,
  callback?: Function,
) => {
  let analyticStream =
    window &&
    // @ts-ignore
    window['analytics'] &&
    analytics;
  if (!analyticStream)
    analyticStream = { identify: console.log.bind('[identify]') };
  analyticStream.identify(userId, traits, options, callback);
};
