import api from '../../../api';
import { notify } from '../../../components/common/toaster';
import {
  Execution,
  ExecutionAction,
  FetchBatchExecution,
  FetchBatchExecutionSuccess,
  FetchBatchWorkflowExecution,
  FetchBatchWorkflowExecutionSuccess,
  FetchExecution,
  FetchExecutionSuccess,
  FetchWorkflowExecution,
  FetchWorkflowExecutionSuccess,
  ResetExecutionMap,
  WorkflowExecutionOutput,
} from './types';
import { WorkflowResponse } from '../workflows/action';
import { Workflow } from '../workflows/types';

export async function fetchFromUrl(url: string) {
  const response = await fetch(url);
  return response.json();
}

export const ResetExecutionMapRequest = (): ExecutionAction => {
  return {
    type: ResetExecutionMap,
  };
};
export const SendBatchExecutionRequest = (): ExecutionAction => {
  return {
    type: FetchBatchExecution,
  };
};
export const SendBatchExecutionResponse = (): ExecutionAction => {
  return {
    type: FetchBatchExecutionSuccess,
  };
};
export const SendExecutionRequest = function(): ExecutionAction {
  return {
    type: FetchExecution,
  };
};

export const ExecutionResponse = function(
  duration: string,
  data: Execution[] | null,
): ExecutionAction {
  return {
    type: FetchExecutionSuccess,
    duration: duration,
    data: data as Execution[],
  };
};

export const SendBatchWorkflowExecutionResponse = function(
  workflows: {
    workflow: string;
    executions: Execution[] | null;
    output: WorkflowExecutionOutput | null;
  }[],
): ExecutionAction {
  return {
    type: FetchBatchWorkflowExecutionSuccess,
    workflows: workflows,
  };
};

export const SendBatchWorkflowExecutionRequest = function(
  workflows: string[],
): ExecutionAction {
  return {
    type: FetchBatchWorkflowExecution,
    workflows: workflows,
  };
};

export const SendWorkflowExecutionRequest = function(
  workflow: string,
): ExecutionAction {
  return {
    type: FetchWorkflowExecution,
    workflow: workflow,
  };
};

export const WorkflowExecutionResponse = function(
  workflow: string,
  data: Execution[] | null,
  output: WorkflowExecutionOutput | null,
): ExecutionAction {
  return {
    type: FetchWorkflowExecutionSuccess,
    workflow: workflow,
    data: data as Execution[],
    output: output,
  };
};

function isPermissionsError(errorType) {
  return (
    errorType === 'AccessDenied' ||
    errorType === 'UnauthorizedAccess' ||
    errorType === 'AllAccessDisabled' ||
    errorType === 'AuthFailure' ||
    errorType === 'AccessDeniedException' ||
    errorType === 'TCAccessDeniedException' ||
    errorType === 'UnauthorizedOperation' ||
    errorType === 'UnrecognizedClientException' ||
    errorType === 'InvalidClientTokenId'
  );
}

function getUtilisationData(history) {
  if (!history || !history.length) return;
  let customJavascript = history.filter(
    (x) => x.nodeType === 'customJavascript',
  );
  if (customJavascript.length === 0) return;

  let utilisationScriptNode = customJavascript.find((x) => {
    return (
      x.output &&
      x.output.data &&
      x.output.data[0] &&
      x.output.data[0].hasOwnProperty('utilisation')
    );
  });

  if (!utilisationScriptNode) return;

  return utilisationScriptNode.output.data[0];
}

async function getEventLookupResult(
  history,
): Promise<{ result: any[]; initialOptions: any }> {
  if (!history || !history.length) return;
  const trigger = history.find((n) => n.nodeType === 'trigger');
  if (!trigger) return;
  const initialOptions = trigger.output.data;
  const result = history.find((n) => n.nodeType === 'awsapi');
  let output = result && result.output && result.output.data;
  if (!output) return { result: [], initialOptions };
  if (result.output.partial) {
    output = (await fetchFromUrl(result.output.url)).collection;
  }
  if (output && output[0] && output[0].Events) {
    return { result: output[0].Events, initialOptions };
  } else return { result: [], initialOptions };
}

export const getExecutionOutputInner = async (
  executionId: string,
): Promise<WorkflowExecutionOutput> => {
  let { history, status = '' } = await api.getExecutionSteps(executionId);

  if (!['Success', 'Error'].includes(status)) {
    return { state: status.toLowerCase() };
  }

  let utilisationReport = getUtilisationData(history);

  let eventLookupResult = await getEventLookupResult(history);

  // console.log({ utilisationReport });
  let result: WorkflowExecutionOutput = {
    state: undefined,
    utilisationReport,
    eventLookupResult,
  };
  if (status === 'Error') {
    for (const step of history) {
      let errorType = step.cause && step.cause.errorType;
      if (isPermissionsError(errorType)) {
        result.state = 'permission';
        return result;
      }
    }
    result.state = 'error';
    return result;
  }

  let lastStep = history[history.length - 1];

  if (
    lastStep &&
    lastStep.output &&
    lastStep.output.meta &&
    lastStep.output.meta.shouldShowStepMessage
  ) {
    let url = lastStep.output.meta.output && lastStep.output.meta.output.url;
    result.state = 'custom';
    result.message = lastStep.output.meta.step.message;
    result.url = url;
    return result;
  }

  for (const step of history) {
    if (step.output && step.output.meta && step.output.meta.output) {
      return { ...step.output.meta.output, ...result };
    }
  }

  result.state = 'success';
  return result;
};

async function getExecutionOutput(
  execution: Execution,
): Promise<WorkflowExecutionOutput> {
  const { status } = execution;

  if (!['Success', 'Error'].includes(status)) {
    return { state: status.toLowerCase() };
  }

  return getExecutionOutputInner(execution.execution);
}

export const fetchWorkflowExecutionInBatch = (props: {
  dispatch: (e: any) => void;
  workflows: Workflow[];
}) => {
  let { dispatch, workflows } = props;
  dispatch(SendBatchExecutionRequest());
  fetchWorkflowExecutionInBatchInner({ dispatch, workflows })
    .then(() => {
      dispatch(SendBatchExecutionResponse());
    })
    .catch((e) => {
      console.error(e);
      dispatch(SendBatchExecutionResponse());
      notify({ type: 'error', message: e.message || e });
    });
};

const getLinkedWorkflowsIds = (workflows: Workflow[]) => {
  return workflows
    .map((x) => x.properties && x.properties.linkedWorkflows)
    .filter((x) => !!x)
    .reduce((A, e) => A.concat(e), []);
};

const fetchWorkflowExecutionInBatchInner = async (props: {
  dispatch: (e: any) => void;
  workflows: Workflow[];
}) => {
  let { dispatch, workflows } = props;
  let ids = workflows.map((x) => x.workflow);
  ids = ids.concat(getLinkedWorkflowsIds(workflows));
  dispatch(SendBatchWorkflowExecutionRequest(ids));
  const batchResult = (await api.batchGetExecutionStatus({
    workflows: ids,
  })) as {
    workflow: string;
    executions: Execution[] | null;
    execution: Execution | null;
    output: WorkflowExecutionOutput | null;
  }[];
  dispatch(
    SendBatchWorkflowExecutionResponse(
      batchResult.map((u) => {
        return {
          workflow: u.workflow,
          executions: u.executions ? [...u.executions] : [],
          output: u.output,
        };
      }),
    ),
  );
};

async function fetchWorkflowExecutionInner(
  dispatch: (e: any) => void,
  workflow: string,
): Promise<{
  workflow: string;
  executions: Execution[];
  output: WorkflowExecutionOutput | null;
}> {
  let executions = await api.getWorkflowExecutionHistory({
    workflow,
    limit: 3,
  });
  // get execution step for latest only
  let output = null;
  if (executions && executions[0]) {
    output = await getExecutionOutput(executions[0]);
  }
  return { workflow, executions, output };
  // ;
}

export const fetchWorkflowExecution = (props: {
  dispatch: (e: any) => void;
  workflow: string;
}) => {
  let { dispatch, workflow } = props;
  dispatch(SendWorkflowExecutionRequest(workflow));

  fetchWorkflowExecutionInner(dispatch, workflow)
    .then(({ workflow, executions, output }) => {
      dispatch(WorkflowExecutionResponse(workflow, executions, output));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowExecutionResponse(workflow, null, null));
    });
};
//props : { dispatch: (e:any) => void, workflow : string }
export const fetchExecution = (props: {
  dispatch: (e: any) => void;
  duration?: string;
  detailed?: boolean;
}) => {
  let { dispatch, duration = '1d', detailed = false } = props;
  dispatch(SendExecutionRequest());

  let p = api.getExecutionsList(`timeperiod=${duration}`);

  if (detailed) {
    //// handle each get separately
    function getStep(exe: any) {
      return api //// handle each get separately
        .getExecutionSteps(exe.execution)
        .catch((e) => {
          notify({ type: 'error', message: e.message || e });
        });
    }

    p = p
      .then((exes) => Promise.all(exes.map(getStep)))
      .then((x) => x.filter((y) => y)); /// filter failed responses
  }

  function onSuccess(data: Execution[]) {
    return dispatch(ExecutionResponse(duration, data));
  }

  function onError(e: any) {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
    return dispatch(ExecutionResponse(duration, null));
  }

  p.then(onSuccess).catch(onError);
};

export const runBulkWorkflowAction = async (props: {
  dispatch: (e: any) => void;
  workflows: string[];
  payload?: any;
}) => {
  let { dispatch, workflows, payload } = props;
  await Promise.all(
    workflows.map((x) => {
      return api.executeWorkflowInstantRun(x, payload);
    }),
  )
    .then(() => {
      notify({ type: 'success', message: 'Workflows ran successfully' });
      return fetchExecution({ dispatch });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

export const runWorkflow = (props: {
  dispatch: (e: any) => void;
  workflow: string;
  payload?: any;
}) => {
  let { dispatch, workflow, payload } = props;
  dispatch(SendExecutionRequest());
  dispatch(SendWorkflowExecutionRequest(workflow));
  return api
    .executeWorkflowInstantRun(workflow, payload)
    .then(() => {
      notify({ type: 'success', message: 'Workflow ran successfully' });
      return fetchWorkflowExecution({ dispatch, workflow });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const waitForExecution = async (executionId, retryCount = 0) => {
  if (retryCount === 20) return null;
  if (!executionId) return null;
  await wait(5000);
  let executionHistory = await api.getExecutionSteps(executionId);
  if (executionHistory && executionHistory.status === 'Success') {
    return executionHistory;
  }

  if (executionHistory && executionHistory.status === 'Error') {
    throw new Error('Execution failed');
  }
  return waitForExecution(executionId, (retryCount = retryCount + 1));
};

export const fetchWorkflowExecutionInBatchWithId = (props: {
  dispatch: (e: any) => void;
  workflows: string[];
}) => {
  let { dispatch, workflows } = props;
  if (!workflows.length) return null;
  dispatch(SendBatchWorkflowExecutionRequest(workflows));
  api
    .batchGetExecutionStatus({ workflows })
    .then((res) => {
      dispatch(
        SendBatchWorkflowExecutionResponse(
          res.map((u) => {
            return {
              workflow: u.workflow,
              executions: u.executions ? [...u.executions] : [],
              output: u.output,
            };
          }),
        ),
      );
    })
    .catch((e) => {
      notify({ type: 'error', message: e.message || e });
      dispatch(SendBatchExecutionResponse());
    });
};
