import * as React from 'react';
import { Workflow } from '../../lib/store/workflows/types';
import { Template } from '../../lib/store/templates/types';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import {
  fetchGlobalPolicyAction,
  fetchPolicyDoc,
} from '../../lib/store/policy/action';
import { GlobalPolicyType, IPolicyDoc } from '../../lib/store/policy/types';
import { shouldAdoptTemplates } from '../../config/policyStatusConfig';
import { GroupWorkflowListResult } from '../../lib/store/parking/type';
import { AutoAdoption } from './AutoAdoptTemplates';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { REGIONS } from '../../lib/editor/components/model/config/Regions';
import { getActionsForAccount } from './policyHelper';
import { Config } from '../../lib/store/config/types';
import { getAccountConfig } from './withRegionAndCredentials';

interface OwnProps {
  text: string;
  hideRegionSelector?: () => void;
  renderRegionSelector?: boolean;
  tabName: TAB_NAME;
  isFetchingWorkflows: boolean;
  isFetchingSchedules: boolean;
  workflows: Workflow[];
  templates: Template[];
  credentials: string[];
  regions: string[];
  schedules: GroupWorkflowListResult[];
  triggerWorkflow?: Workflow;
}

interface StateProps {
  isFetchingPolicy: boolean;
  isFetchingPolicyDoc: boolean;
  policyDoc: IPolicyDoc;
  availableCredentials: string[];
  globalPolicyMap: GlobalPolicyType;
  configs: Config[];
}

interface DispatchProps {
  fetchGlobalPolicy: () => void;
  fetchPolicyDocument: (params: {
    actions: string[];
    invalidActions?: string[];
  }) => void;
}

type IProps = OwnProps & StateProps & DispatchProps;

type IState = {
  showPolicyModal: boolean;
};

class PolicyStatusInner extends React.Component<IProps, IState> {
  state: IState = {
    showPolicyModal: false,
  };

  get credentials() {
    return this.props.credentials || this.props.availableCredentials;
  }

  shouldRenderAdoption = () => {
    if (!this.props.credentials || !this.props.credentials.length) {
      return false;
    }

    if (this.props.renderRegionSelector) return true;

    if (!this.props.globalPolicyMap) return false;

    return !(this.props.isFetchingWorkflows || this.props.isFetchingSchedules);
  };

  // templates that are validated successfully
  getValidatedTemplateIdsToAdopt = (): string[] => {
    let templates: Template[] = [];
    for (const credential of this.credentials) {
      templates = [
        ...templates,
        ...this.props.templates.filter((x) => {
          let { actions } = getActionsForAccount({
            workflows: [],
            accounts: [credential],
            templates: [x.id],
            globalPolicyMap: this.props.globalPolicyMap,
          });
          if (!actions) return false;
          let invalidActions = Object.keys(actions).filter((x) => !actions[x]);
          return invalidActions.length === 0;
        }),
      ];
    }
    return templates
      .filter((e, i, a) => a.findIndex((f) => f.id === e.id) === i)
      .map((temp) => temp.id);
  };

  render = () => {
    return (
      <>
        {this.shouldRenderAdoption() &&
          shouldAdoptTemplates(this.props.tabName) && (
            <AutoAdoption
              alreadyAdopted={{ accounts: [], resourceGroups: [], regions: [] }}
              fetchAdoptionData={() => {}}
              configs={this.props.configs}
              hideRegionSelector={this.props.hideRegionSelector}
              showRegionSelector={this.props.renderRegionSelector}
              showNotification={!this.props.renderRegionSelector}
              tabName={this.props.tabName}
              isFetching={this.props.isFetchingWorkflows}
              workflows={this.props.workflows}
              validatedTemplates={this.getValidatedTemplateIdsToAdopt()}
              account={this.credentials}
              region={this.props.regions || REGIONS.map((x) => x.value)}
              shouldAdopt={this.shouldRenderAdoption()}
              triggerWorkflow={this.props.triggerWorkflow}
            />
          )}
      </>
    );
  };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const awsConfigs = getAccountConfig(state.config.data);
  const availableCredentials = awsConfigs.map((c) => c.id);
  return {
    isFetchingPolicy: state.policy.fetchingGlobalPolicy,
    globalPolicyMap: state.policy.globalPolicy,
    isFetchingPolicyDoc: state.policy.fetchingPolicyDoc,
    policyDoc: state.policy.policyDoc,
    availableCredentials: availableCredentials,
    configs: awsConfigs,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchGlobalPolicy: () => fetchGlobalPolicyAction({ dispatch }),
    fetchPolicyDocument: ({ actions, invalidActions }) =>
      fetchPolicyDoc({ dispatch, actions, invalidActions }),
  };
}

export const PolicyStatus = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PolicyStatusInner);
