import React from 'react';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { superApiCall } from '../../../lib/store/super-table/action';
import { ApiOperation } from '../../../lib/store/super-table/types';
import { connect } from 'react-redux';
import { DummyField } from '../../common/DummyField';

interface OwnProps {
  category: string;
  subCategory: string;
}

interface StateProps {
  executionStatus: any;
  isFetching: boolean;
}

interface DispatchProps {
  fetchExecution: (options?: any) => void;
}

type ExecutionStatusProps = OwnProps & StateProps & DispatchProps;

export const ExecutionStatusInner = ({
  isFetching,
  executionStatus,
  fetchExecution,
}: ExecutionStatusProps) => {
  React.useEffect(() => {
    if (!isFetching && !executionStatus) {
      fetchExecution();
    }
  }, []);

  if (isFetching) return <DummyField width={'200px'} />;
  return <div className={'px-3 text-primary text-12'}>{executionStatus}</div>;
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const { category, subCategory } = ownProps;
  const execState =
    state.superTable[category] && state.superTable[category][subCategory];
  return {
    executionStatus: execState?.data?.executionStatus,
    isFetching: execState?.loading,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<DispatchProps, AppState, AnyAction>,
  ownProps: OwnProps,
): DispatchProps => {
  const { category, subCategory } = ownProps;

  return {
    fetchExecution: (options = {}) =>
      superApiCall({
        operation: ApiOperation.GET,
        category,
        subCategory,
        dispatch,
        options,
      }),
  };
};

export const ExecutionStatus = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ExecutionStatusInner);
