import { TableItem } from '../cols';

import * as React from 'react';

export const getCFCreateStackWfNames = (cell: string[], row: TableItem) => {
  if (!cell) return '';
  return row.functions
    .getCustomWorkflows()
    .filter((x) => cell.indexOf(x.workflow) !== -1)
    .map((x) => x.name)
    .toString();
};

export const CFNameDisplay = (cell: string[], row: TableItem) => {
  let name = getCFCreateStackWfNames(cell, row);
  return (
    <span className={'text-truncate d-flex'} title={name}>
      {name}{' '}
    </span>
  );
};
