import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  Form,
} from 'reactstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Permissions } from 'webapp-genric/dist/constants';
import CheckBox from '../../../lib/formInputs/checkbox.component';
import { copyToClipboard } from '../../../util/commonUtils';
import { generateShareWorkflowUrl } from '../../../lib/store/workflows/action';

interface OwnProps {
  workflow: string;
  tabName: string;
  toggleShareLinkModal: () => void;
  updateShareToken: (token) => void;
  previousShareToken: string | null;
}

interface OwnState {
  generatedToken: string;
  isFetchingToken: boolean;
}

type IProps = OwnProps;

type IState = OwnState;

const schema = Yup.object({
  permissions: Yup.array().test(
    'permission',
    'Should Provide atleast 1 Permission',
    (permissions) => {
      return permissions.length > 0;
    },
  ),
});

export const checkBoxOptions = Object.keys(Permissions)
  .filter(
    (permission) => Permissions[permission] !== Permissions.DETAILED_OUTPUT,
  )
  .map((permission) => {
    return { label: permission, value: permission };
  });

export default class ShareLinkWorkflowModal extends React.Component<
  IProps,
  IState
> {
  state = {
    generatedToken: '',
    isFetchingToken: false,
  };
  componentDidMount() {
    this.setState({
      generatedToken: this.props.previousShareToken,
    });
  }

  handlePermission = (formProps, value) => {
    const set = new Set(formProps.values.permissions);
    if (set.has(value)) {
      set.delete(value);
    } else {
      set.add(value);
    }
    formProps.setFieldValue('permissions', Array.from(set));
  };

  generateShareToken = (values) => {
    this.setState(
      {
        isFetchingToken: true,
      },
      async () => {
        try {
          let response = await generateShareWorkflowUrl({
            workflow: this.props.workflow,
            tabName: this.props.tabName,
            permissions: values.permissions.map(
              (permission) => Permissions[permission],
            ),
          });
          if (response) {
            this.setState(
              {
                generatedToken: response.token,
                isFetchingToken: false,
              },
              () => {
                this.props.updateShareToken(response.token);
              },
            );
          }
        } catch (e) {
          this.setState({
            isFetchingToken: false,
          });
        }
      },
    );
  };

  render() {
    let generatedUrl = this.state.generatedToken
      ? `${window.location.origin}/workflow-share/${this.state.generatedToken}`
      : null;
    return (
      <Modal
        centered={true}
        size={'md'}
        isOpen={true}
        toggle={this.props.toggleShareLinkModal}
        backdrop={'static'}
      >
        <ModalHeader toggle={this.props.toggleShareLinkModal}>
          <strong>Share Link Modal</strong>
        </ModalHeader>
        <Formik
          initialValues={{
            permissions: [],
          }}
          data-test="share-link-workflow-form"
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            this.generateShareToken({ ...values });
          }}
          validationSchema={schema}
          render={(formProps) => {
            return (
              <Form>
                <ModalBody>
                  <Row>
                    <Col md={12}>
                      <strong>Permission</strong>
                    </Col>
                    <Col md={12}>
                      <Field
                        name="permissions"
                        data-test="permissions"
                        value={formProps.values.permissions}
                        render={({ field }) => {
                          return (
                            <CheckBox
                              {...field}
                              options={checkBoxOptions}
                              CheckedValues={formProps.values.permissions}
                              hideSelectAll
                              onChange={(value) =>
                                this.handlePermission(formProps, value)
                              }
                              border={false}
                            />
                          );
                        }}
                      />
                      <div className="text-red font-12">
                        <ErrorMessage name="permissions" />
                      </div>
                    </Col>
                  </Row>
                  {generatedUrl && (
                    <Row className={'my-2'} data-test={'generated-url'}>
                      <Col md={12}>
                        <strong>
                          Generated Url{' '}
                          <i
                            className="fa fa-clipboard cursor-pointer"
                            onClick={() => copyToClipboard(generatedUrl)}
                          />
                        </strong>
                        <div className="border rounded p-2">
                          <code>{generatedUrl}</code>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {this.state.isFetchingToken && (
                    <Row className="my-2">
                      <Col md={12} className="d-flex justify-content-center">
                        {' '}
                        <i className="fa fa-spinner fa-2x fa-spin"></i>
                      </Col>
                    </Row>
                  )}
                  <Row className="my-5">
                    <Col md={12}>
                      <Button
                        color="primary"
                        data-test={'generate-share-workflow-button'}
                        onClick={() => formProps.handleSubmit()}
                        disabled={formProps.values.permissions.length < 1}
                      >
                        Generate
                      </Button>
                      <Button
                        color="primary"
                        onClick={this.props.toggleShareLinkModal}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}
