import * as React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {
  Col,
  Row,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { CSSProperties } from 'react';
import classnames from 'classnames';
import { ORDER } from '../workflows/common/workflowTable';
const { SearchBar } = Search;

export interface ICustomTableCol {
  dataField: string;
  text: string;
  sort?: boolean;
  editable?: ((content, row, rowIndex, columnIndex) => void) | boolean;
  hidden?: boolean;
  filterValue?: (cellContent, row) => void;
  formatter?: (
    cellContent,
    row,
    rowIndex?: number,
  ) => React.ReactNode | JSX.Element | string | null;
  align?: string;
  headerFormatter?: (cellContent, colIndex?, elements?) => void;
  headerStyle?: any;
  isDummyField?: boolean;
  sortValue?: (cellContent, row) => void;
  colName: string;
  sortFunc?: (a, b, order, dataField) => number;
  classes?: string;
  headerClasses?: string;
  validator?: (newValue, row, column) => void;
  editCellStyle?: Function;
  attrs?: Function | any;
  filter?: Function | any;
  editor?: Function | any;
  editorRenderer?: (
    editorProps,
    value,
    row,
    column,
    rowIndex,
    columnIndex,
  ) => void;
}

export interface SelectRow {
  mode: string;
  clickToSelect: boolean;
  hideSelectAll?: boolean;
  clickToEdit?: boolean;
  selected?: string[];
  onSelect?: (row, isSelect?) => void;
  onSelectAll?: (isSelect?: boolean, rows?) => void;
}
interface IProps {
  options: React.ReactNode;
  emptyText?: string;
  data: { [s: string]: any }[];
  columns: ICustomTableCol[];
  selectRow?: SelectRow;
  hideSearch?: boolean;
  maxHeight?: string;
  sortField?: string;
  order?: ORDER;
  striped?: boolean;
  cellEditFactory?: any;
  divId?: string;
  disableContainerScroll?: boolean;
  responsive?: boolean;
  expand?: React.ReactNode;
  pagination?: boolean;
  disableFixedLayout?: boolean;
}

interface IState {}

const PaginationTotalRenderer = (from, to, size) => {
  return (
    <span
      data-test="react-table-pagination-total"
      className="react-bootstrap-table-pagination-total ml-5"
    >
      {from}-{to} of {size}
    </span>
  );
};

const PageListRenderer = ({ pages, onPageChange }) => {
  let activePage = pages.find((x) => x.active);
  const pageWithIndication = pages.filter(
    (p) => p.title === 'previous page' || p.title === 'next page',
  );
  return (
    <>
      <Col md={3}>
        <div className="d-flex justify-content-end align-items-center">
          {pageWithIndication.map((p) => (
            <div
              className={classnames({
                'cursor-pointer font-weight-bold px-5': true,
              })}
              onClick={() => onPageChange(p.page)}
            >
              <i
                className={classnames({
                  'fa fa-angle-left font-20': p.title === 'previous page',
                  'fa fa-angle-right font-20': p.title === 'next page',
                  'cursor-pointer font-weight-bold': true,
                })}
                data-test={
                  p.title === 'previous page'
                    ? 'pagination-previous-page'
                    : 'pagination-next-page'
                }
              />
            </div>
          ))}
        </div>
      </Col>
    </>
  );
};

const SizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <span>
    <span>Rows per page: </span>
    <UncontrolledDropdown className="d-inline-block" direction="left">
      {currSizePerPage}
      <DropdownToggle tag="span">
        <i
          id={'menuitem'}
          className="fa fa-caret-down ml-2"
          aria-hidden="true"
        />
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option, key) => {
          return (
            <DropdownItem
              key={key}
              data-test={`pagination-${option.text}-list`}
              onClick={(e) => {
                e.preventDefault();
                onSizePerPageChange(option.page);
              }}
            >
              <a
                href="#"
                tabIndex={-1}
                role="menuitem"
                data-page={option.value}
              >
                {option.text}
              </a>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  </span>
);

export default class CustomTable extends React.Component<IProps, IState> {
  containerStyle: CSSProperties = {};

  render() {
    const pageOptions = {
      showTotal: true,
      sizePerPageRenderer: SizePerPageRenderer,
      pageListRenderer: PageListRenderer,
      paginationTotalRenderer: PaginationTotalRenderer,
      sizePerPageList: [
        {
          text: '10',
          value: 10,
        },
        {
          text: '25',
          value: 25,
        },
        {
          text: '30',
          value: 30,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: 'All',
          value: this.props.data ? this.props.data.length : 0,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };
    const {
      hideSearch,
      maxHeight = '500px',
      striped = true,
      pagination = true,
    } = this.props;

    // console.log(this.props.data);
    if (!this.props.disableContainerScroll) {
      this.containerStyle = {
        // height: maxHeight,
        // overflowX: 'hidden',
        // overflowY: 'scroll',
        // position: 'relative',
      };
    }

    let sort = this.props.sortField
      ? { dataField: this.props.sortField, order: this.props.order }
      : {
          dataField: null,
          order: null,
        };

    const { responsive, disableFixedLayout } = this.props;
    let tableClasses = `${(responsive && 'table-responsive') ||
      ''} ${(disableFixedLayout && 'table-no-fixed-layout') || ''}`;

    return (
      <ToolkitProvider
        keyField="_id"
        data={this.props.data || []}
        columns={this.props.columns}
        search
      >
        {(props) => (
          <div>
            {!hideSearch && (
              <Row>
                <Col md={4}>
                  <SearchBar
                    {...props.searchProps}
                    style={{ width: '200px' }}
                  />
                </Col>
                {this.props.options}
              </Row>
            )}

            <Row style={this.containerStyle}>
              <Col>
                <BootstrapTable
                  noDataIndication={
                    this.props.emptyText
                      ? this.props.emptyText
                      : 'Table is Empty'
                  }
                  cellEdit={cellEditFactory({
                    ...this.props.cellEditFactory,
                  })}
                  {...props.baseProps}
                  bordered={false}
                  striped={this.props.striped}
                  hover
                  bootstrap4
                  keyField="_id"
                  selectRow={this.props.selectRow}
                  pagination={
                    pagination ? paginationFactory(pageOptions) : undefined
                  }
                  sort={sort}
                  id={this.props.divId}
                  classes={tableClasses}
                  expandRow={this.props.expand}
                  filter={filterFactory()}
                  filterPosition="top"
                />
              </Col>
            </Row>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}
