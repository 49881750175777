import React from 'react';
import { StyledCustomDropDown, StyledOptionCol } from '../../workflows/style';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { Workflow } from '../../../lib/store/workflows/types';
import { ExecutionsMapType } from '../../../lib/store/executions/types';
import { TemplateMap } from '../../../lib/store/templates-manage/types';

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Have template', value: 'template' },
  { label: 'Without template', value: 'not_template' },
];

type IProps = {
  fetchWorkflows: () => void;
  fetchTemplates: () => void;
  selectedFilter: string;
  handleFilter: (value) => void;
  deleteSelected: () => void;
  deleteDisabled: boolean;
};

export const workflowFiltersOptions = (props: IProps) => {
  return (
    <>
      <StyledOptionCol
        className={
          'px-3 d-flex align-self-center justify-content-around text-right text-primary'
        }
        md={4}
      >
        <Button
          title={'Delete selected workflows'}
          color={'link'}
          className={'p-0 m-0'}
          onClick={props.deleteSelected}
          disabled={props.deleteDisabled}
        >
          <i className={'fa fa-trash'} />
        </Button>

        <Button
          color={'link'}
          className={'p-0 m-0'}
          onClick={() => {
            props.fetchWorkflows();
            props.fetchTemplates();
          }}
        >
          {' '}
          <i className={'fa master-tc-continuous text-primary'}> </i>{' '}
        </Button>
        <StyledCustomDropDown
          name="filter-workflow"
          value={props.selectedFilter}
          options={filterOptions}
          icon={
            props.selectedFilter === 'all'
              ? 'fa master-tc-filter test-filter-button'
              : 'navIcon-filter-applied test-filter-selected'
          }
          onChange={props.handleFilter}
          right
        />
      </StyledOptionCol>
    </>
  );
};

type IWProps = {
  fetchWorkflows: () => void;
  selectedFilter: string;
  handleFilter: (value) => void;
  onCreateStack: () => void;
  onDestroyStack: () => void;
  disabledDestroy: boolean;
  disabledCreate: boolean;
};

const stackWfFilterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const stackWorkflowOptions = (props: IWProps) => {
  return (
    <>
      <StyledOptionCol
        className={
          'px-3 d-flex align-self-center justify-content-around text-right text-primary'
        }
        md={4}
      >
        <UncontrolledDropdown>
          <DropdownToggle color={'primary'} caret size={'sm'} outline={true}>
            Options
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={props.onCreateStack}
              disabled={props.disabledCreate}
            >
              Create Stack
            </DropdownItem>
            <DropdownItem
              onClick={props.onDestroyStack}
              disabled={props.disabledDestroy}
            >
              Destroy Stack
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <Button
          color={'link'}
          className={'p-0 m-0'}
          onClick={() => {
            props.fetchWorkflows();
          }}
        >
          {' '}
          <i className={'fa master-tc-continuous text-primary'}> </i>{' '}
        </Button>
        <StyledCustomDropDown
          className={'mt-2'}
          name="filter-workflow"
          value={props.selectedFilter}
          options={stackWfFilterOptions}
          icon={
            props.selectedFilter === 'all'
              ? 'fa master-tc-filter test-filter-button'
              : 'navIcon-filter-applied test-filter-selected'
          }
          onChange={props.handleFilter}
          right
        />
      </StyledOptionCol>
    </>
  );
};

interface FilterProps {
  workflows: Workflow[];
  templateMap: TemplateMap;
  selectedFilter: string;
}

export function FilterWorkflows(props: FilterProps) {
  let { workflows, templateMap, selectedFilter } = props;
  let workflowIds = Object.keys(templateMap).map(
    (x) => templateMap[x].properties.originWorkflow,
  );
  switch (selectedFilter) {
    case 'all':
      return workflows;
    case 'active':
      return workflows.filter((w) => !!w.properties.active);
    case 'inactive':
      return workflows.filter((w) => !w.properties.active);
    case 'template':
      return workflows.filter((x) => workflowIds.indexOf(x.workflow) !== -1);
    case 'not_template':
      return workflows.filter((x) => workflowIds.indexOf(x.workflow) === -1);
    default:
      return workflows;
  }
}
