import { TAB_NAME } from 'webapp-genric/dist/constants';
import { parseExecutionStatus, SymbolMap } from '../ExecutionStatus';
import * as React from 'react';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { RowDataType } from '../dataFormatter/FormatWorkflowsData';

const ResultSortValue = [
  'None',
  'Never Executed',
  'Loading latest Result',
  'Execution Error',
  'Insufficient Permissions',
  'In Progress',
  'Successfully Executed',
  'Execution completed',
];

export type ResultColDataType = {
  status: string;
  sortValue: number;
  style: {
    marginRight?: string;
    color?: string;
    backgroundColor?: string;
  };
  url: string;
  executionUrl: string;
};
//todo define tableRow type
export const resultColDataFormatter = (tableRow): ResultColDataType => {
  let status, color, backgroundColor, marginRight;
  if (!tableRow.result) tableRow.result = {};

  let url = tableRow.result.url;
  let executionUrl = tableRow.result.executionUrl;

  if (url && url.startsWith('http')) {
    url = new URL(url).pathname;
  }
  if (executionUrl && executionUrl.startsWith('http')) {
    executionUrl = new URL(executionUrl).pathname;
  }

  if (tableRow.tabName === TAB_NAME.INSIGHTS) {
    let statusObj = parseExecutionStatus(tableRow.result.workflowOutput);
    marginRight = '1px';
    backgroundColor = statusObj.isSuccess
      ? '#228b22'
      : SymbolMap[statusObj.status]
      ? SymbolMap[statusObj.status].color
      : '#FF0004';
    status = statusObj.isSuccess
      ? 'Success'
      : statusObj.isFailure
      ? 'Failed: ' + statusObj.status
      : statusObj.status;
    color = '#FFFFFF';
    executionUrl = tableRow.isWorkflowReport ? executionUrl : undefined;
  } else {
    status = tableRow.result.workflowOutput || '';
    color = SymbolMap[status] ? SymbolMap[status].color : '#118009';
  }

  return {
    sortValue: getResultSortValue(tableRow),
    status,
    style: {
      marginRight,
      color,
      backgroundColor,
    },
    executionUrl,
    url,
  };
};

export const getResultSortValue = (row) => {
  if (row.isTemplateItem) {
    return;
  }
  if (!row.result) row.result = {};
  if (row.result.workflowOutput) {
    let number = row.result.workflowOutput.substring(
      0,
      row.result.workflowOutput.indexOf(' '),
    );
    if (number === '' || isNaN(number)) {
      return ResultSortValue.indexOf(row.result.workflowOutput);
    } else {
      number = Number(number);
      if (row.tabName === TAB_NAME.INSIGHTS && number === 0) {
        return number;
      }
      return number + ResultSortValue.length + 1;
    }
  }
};

export const resultColDisplayFormatter = (status, row: RowDataType) => {
  if (row.isTemplateItem) {
    return (
      <>
        <span id={`sample-output-${row._id}`}>
          <Button size={'sm'} color={'link'} disabled={true}>
            View Sample
          </Button>
        </span>
        <UncontrolledTooltip target={`sample-output-${row._id}`}>
          coming soon
        </UncontrolledTooltip>
      </>
    );
  }
  let reportId;
  if (row.Result && row.Result.url) {
    let tempArray = row.Result.url.split('/');
    let index = tempArray.indexOf('report');
    reportId = tempArray[index + 1];
  }
  return (
    <Row style={row.Result ? row.Result.style : {}}>
      <Col className={'d-flex p-2'} style={row.Result ? row.Result.style : {}}>
        <div title={status} className={'text-truncate'}>
          <span data-test={'result-text'}>{status}</span>
        </div>
        <div className={'align-items-right'}>
          {row.Result && row.Result.url && (
            <>
              <Link
                to={`/report/${reportId}`}
                // to={row.Result.url}
                // target={'_blank'}
                id={`report-link-${row._id}`}
              >
                <i className={'fa fa fa-newspaper-o ml-1'} aria-hidden="true" />
              </Link>
              <UncontrolledTooltip
                target={`report-link-${row._id}`}
                placement={'bottom'}
              >
                Report link
              </UncontrolledTooltip>
            </>
          )}
          {row.Result && row.Result.executionUrl && (
            <Link
              to={row.Result.executionUrl}
              target={'_blank'}
              id={'execution-detail-url'}
            >
              <i className={'fa fa fa-external-link ml-1'} aria-hidden="true" />
            </Link>
          )}
        </div>
      </Col>
    </Row>
  );
};
