import * as React from 'react';
import { Col, Row, Button, Form, UncontrolledTooltip } from 'reactstrap';
import {
  IExternalDataDependent,
  withExternalData,
} from '../../common/externalDependency';
import {
  StyledHeader,
  LogoWrapper,
  DateWrapper,
} from '../../report/styledElements';
import { getDate } from '../../common/utils';
import moment from 'moment';
import { WORKFLOW_ACTION } from 'workflow-model/dist/types/IGroupWorkflowModel';
import { notify } from '../../common/toaster';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { getResourceName } from 'webapp-genric/dist/filters';
import { BASE_URL } from '../../../config/api';
import { GroupHistoryAction } from 'workflow-model';
import { getTenantName } from '../../../util/commonUtils';

interface IProps extends IExternalDataDependent {
  token: string;
  data: {
    url: string;
    name: string;
    workflows: { action: WORKFLOW_ACTION; hookUrl: string }[];
    details: {
      action: WORKFLOW_ACTION;
      actions: GroupHistoryAction[];
      timestamp: string;
      status: string;
    };
  };
}
type IState = {
  hours: number;
};

export const getInstanceAffected = (action) => {
  const { modified } = action;
  if (modified.length === 1) {
    if (!Object.values(modified[0]).some((x) => x !== null && x !== '')) {
      return 0;
    }
  }
  return modified.length;
};

export const getActionArray = (actions) => {
  if (!actions || (actions && actions.length === 0)) {
    return [{ resourceName: '', instanceAffected: '' }];
  }
  return actions.map((action) => {
    return {
      resourceName: getResourceName(action.actionApiName),
      instanceAffected: getInstanceAffected(action),
    };
  });
};

export const getLinkedAction = (workflows) => {
  let linkedAction = {
    start: [],
    stop: [],
  };
  if (!workflows) {
    return linkedAction;
  }
  workflows.forEach((workflow) => {
    linkedAction[workflow.action] = workflow.awsActions;
  });
  return linkedAction;
};

export class TriggerActionExternally extends React.Component<IProps, IState> {
  handleAction = (action, hours?: number) => {
    const workflow = this.props.data.workflows.filter(
      (workflow) => workflow.action === action,
    );
    triggerAction(workflow[0].hookUrl, hours);
  };
  render(): React.ReactNode {
    const { data } = this.props;
    const scheduleName = data.name;
    const lastAction = getActionArray(data.details.actions);
    const lastExecuted = data.details.timestamp || 'Not Executed';
    const actionName = data.details.action;
    const linkedAction = getLinkedAction(data.workflows);
    const tenantName = getTenantName();
    return (
      <div>
        <StyledHeader data-test="trigger-action-header">
          <LogoWrapper lg={6} data-test="trigger-action-logo">
            <h2 className="font-weight-bold font-24 mb-0 text-uppercase">
              {tenantName}
            </h2>
            <div className="font-italic font-16">
              Share and execute Schedule actions, without logging into{' '}
              {tenantName}
            </div>
          </LogoWrapper>
          <DateWrapper
            lg={{ size: 2, offset: 4 }}
            data-test="trigger-action-date"
          >
            {moment().format('DD-MM-YYYY')}
          </DateWrapper>
        </StyledHeader>
        <div
          className="mx-5 my-3 px-5 font-18 trigger-action-data"
          data-test="trigger-action-details"
        >
          <Row className="py-4" data-test="trigger-action-schedule-name">
            <Col md={6}>
              <span>Schedule Name</span>
            </Col>
            <Col md={6}>
              <span>{scheduleName}</span>
            </Col>
          </Row>
          <Row className="py-4" data-test="trigger-action-instance-affected">
            <Col md={6}>
              <span>Last Action</span>
            </Col>
            <Col md={6}>
              {actionName ? (
                <span>{actionName}</span>
              ) : (
                <div className="text-center">-</div>
              )}
            </Col>
          </Row>
          <Row className="py-4" data-test="trigger-action-instance-affected">
            <Col md={6}>
              <span>Last Execution</span>
            </Col>
            <Col md={6}>
              <span>{lastExecuted}</span>
            </Col>
          </Row>
          <Row className="py-4" data-test="trigger-action-last-action">
            <Col md={6}>
              <span>Resource(s) Affected</span>
            </Col>
            <Col md={6}>
              {lastAction[0].instanceAffected || lastAction[0].resourceName ? (
                <span>
                  {`${lastAction[0].instanceAffected} ${lastAction[0].resourceName} `}
                  <span
                    className="text-primary"
                    id={`action-more-external`}
                  >{`${
                    lastAction.length > 1
                      ? `+${lastAction.length - 1} more`
                      : ''
                  }`}</span>
                  {lastAction.length > 1 && (
                    <UncontrolledTooltip
                      placement="right"
                      target={`action-more-external`}
                      data-test={'last-execution-tooltip-data'}
                    >
                      <div className="px-1">
                        {lastAction.slice(1).map((action, index) => (
                          <div key={index}>
                            {`${action.instanceAffected} ${action.resourceName} `}
                          </div>
                        ))}
                      </div>
                    </UncontrolledTooltip>
                  )}
                </span>
              ) : (
                <div className="text-center">-</div>
              )}
            </Col>
          </Row>
          <Row className="py-4">
            <Col md={6}>
              <div className="custom-trigger-button">
                <Button
                  color={'danger'}
                  onClick={(e) => this.handleAction(WORKFLOW_ACTION.STOP)}
                  className="btn-block text-align-center px-2"
                  data-test="trigger-park-action"
                >
                  Park
                </Button>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <span>
                {linkedAction && linkedAction.stop[0]}{' '}
                <span
                  className="text-primary"
                  id={`linked-stop-action-more-external`}
                >{`${
                  linkedAction.stop.length > 1
                    ? `+${linkedAction.stop.length - 1} more`
                    : ''
                }`}</span>
                {linkedAction.stop.length > 1 && (
                  <UncontrolledTooltip
                    placement="right"
                    target={`linked-stop-action-more-external`}
                    data-test={'linked-stop-action-tooltip-data'}
                  >
                    <div className="px-1">
                      {linkedAction.stop.slice(1).map((action, index) => (
                        <div key={index}>{`${action}`}</div>
                      ))}
                    </div>
                  </UncontrolledTooltip>
                )}
              </span>
            </Col>
          </Row>
          <Row className="py-4">
            <Col md={6}>
              <div className="custom-trigger-button">
                <Button
                  color={'primary'}
                  onClick={(e) => this.handleAction(WORKFLOW_ACTION.START)}
                  className="btn-block text-align-center px-2"
                  data-test="trigger-unpark-action"
                >
                  Unpark
                </Button>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <span>
                {linkedAction && linkedAction.start[0]}{' '}
                <span
                  className="text-primary"
                  id={`linked-start-action-more-external`}
                >{`${
                  linkedAction.start.length > 1
                    ? `+${linkedAction.stop.length - 1} more`
                    : ''
                }`}</span>
                {linkedAction.start.length > 1 && (
                  <UncontrolledTooltip
                    placement="right"
                    target={`linked-start-action-more-external`}
                    data-test={'linked-start-action-tooltip-data'}
                  >
                    <div className="px-1">
                      {linkedAction.start.slice(1).map((action, index) => (
                        <div key={index}>{`${action}`}</div>
                      ))}
                    </div>
                  </UncontrolledTooltip>
                )}
              </span>
            </Col>
          </Row>
          <Row className="py-4">
            <Col md={12}>
              <Formik
                initialValues={{
                  hours: 0,
                }}
                data-test="trigger-skip-action-form"
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values) => {
                  this.handleAction(WORKFLOW_ACTION.SKIP, values.hours);
                }}
                validationSchema={Yup.object({
                  hours: Yup.number()
                    .required('Hours must be greater than 0')
                    .min(1),
                })}
                render={(formProps) => {
                  return (
                    <Form className="row">
                      <Col md={6}>
                        <div className="custom-trigger-button">
                          <Button
                            color={'warning'}
                            onClick={() => formProps.handleSubmit()}
                            className="btn-block text-align-center px-2"
                            data-test="trigger-skip-action"
                          >
                            Skip
                          </Button>
                        </div>
                      </Col>
                      <Col md={6} className="d-flex align-items-center">
                        <div>
                          <Field
                            name="hours"
                            value={formProps.values.hours}
                            data-test="trigger-skip-action"
                            render={({ field }) => {
                              return (
                                <input
                                  type="number"
                                  {...field}
                                  className="form-control w-50 border-bottom mx-2 d-inline-block bg-white"
                                />
                              );
                            }}
                          />
                          Hours
                        </div>
                        <div className="text-red font-12">
                          <ErrorMessage name="hours" />
                        </div>
                      </Col>
                    </Form>
                  );
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const fetchGroupData = async (props) => {
  const { token } = props as IProps;
  let url = `${BASE_URL}/grouphooks/${token}`;
  let response = await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
  });
  let data = await response.json();
  data = data.response.data;
  data.url = url;
  return data;
};

const triggerAction = async (url: string, hours?: number) => {
  let tempUrl = url;
  if (hours) {
    let tempArray = tempUrl.split('/');
    let index = tempArray.indexOf('dev');
    tempArray[index + 1] = 'skiphooks';
    tempUrl = `${tempArray.join('/')}/${hours}`;
  }
  try {
    let response = await fetch(tempUrl, {
      method: 'post',
      body: JSON.stringify({}),
    });
    let data = await response.json();
    if (data.response.error) {
      notify({ type: 'error', message: data.response.error.message });
    }
    notify({ type: 'success', message: 'Trigger Successfully' });
  } catch (error) {
    notify({ type: 'error', message: error.response });
  }
};

export default withExternalData(TriggerActionExternally, fetchGroupData);
