import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/totalcloud.png';
import Group from '../assets/images/automation.png';
import { Row, Col } from 'reactstrap';

const Description: React.FC = () => (
  <div className="row description text-center text-black">
    <Row>
      <Col md={12}>
        <div className="font-48 font-weight-900 login-heading-description">
          Evolve the way you manage your cloud.
        </div>
      </Col>
      <Col md={12} className="d-flex justify-content-center">
        <div className="font-weight-300 font-18 login-subheading-description text-uppercase">
          15-day free trial. no credit card.
        </div>
      </Col>
      <Col md={12} className="text-center mb-5 mt-5">
        <div>
          <img src={Group} alt="Group" width={'20%'} height={'20%'} />
        </div>
      </Col>
      <Col md={12} className="d-flex justify-content-center">
        <div className="font-weight-500 font-24 login-subheading-description">
          Achieve any cloud management use case through accelerated workflows.
        </div>
      </Col>
    </Row>
    {/* <Link to="/">
      <img src={Logo} alt="Logo" />
    </Link>
    <ul>
      {[
        `Save 95% on engineering time and eliminate tedious coding  `,
        `Easily schedule resources, get security & cost insights and auto-remediate any cloud issue`,
        `Achieve complex & custom cloud management use cases through workflows`,
        `Integrate TotalCloud into your existing tech stack with ease`,
      ].map((item, key) => (
        <li key={key}>
          <span>{item}</span>
        </li>
      ))}
    </ul>*/}
  </div>
);

export default Description;
