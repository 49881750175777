import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { fetchConfig } from '../../lib/store/config/action';
import { Config } from '../../lib/store/config/types';
import {
  RegionChildProps,
  withRegionAndCredentials,
} from '../common/withRegionAndCredentials';
import { isEqual } from 'lodash';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { SectionLoader } from '../common/SectionLoader';
import { ITableDataDef } from 'webapp-genric/dist/super-table/model/table';
import { modalRenderer } from '../modals';
import { superApiCall } from '../../lib/store/super-table/action';
import { CategorySelector } from '../workflows/common/CategorySelector';
import api from '../../api';
import { SummaryAndDataTableWrapper } from './SummaryAndDataTableWrapper';
import { TableSubCategories } from './index';
import { ApiOperation } from '../../lib/store/super-table/types';
import { PageHeaderComponent } from '../page-header';
import TableContext, { TableContextType } from './Context';
import { shouldShowTriggerTimeStamp } from '../../config/WorkflowCategories';
import { withUser } from '../../api/withUser';

interface StateProps {
  isFetching: boolean;
  configs: Config[];
  tableData: ITableDataDef;
  isFetchingSummary: boolean;
  isFetchingCategory: boolean;
  isFetchingData: boolean;
  summaryData: ITableDataDef;
  categoryData: { category: string; subCategories: string[] }[];
}

interface DispatchProps {
  superApi: (operation: any, options?: any) => void;
  fetchConfig: () => void;
}

type OwnProps = {
  user: string;
  tabName?: TAB_NAME;
  category: string;
  subCategories: string[];
  regions: string[];
  credentials: string[];
  hideCategory?: boolean;
  hideSubCategory?: boolean;
};

type IProps = RegionChildProps & OwnProps & DispatchProps & StateProps;

interface IState {
  categoryFilters: { category: string; subCategory?: string };
}

export class TableWrapperInner extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      categoryFilters: {
        category: 'All',
        subCategory: 'All',
      },
    };
  }

  callSuperApi = (
    category: string,
    subCategory: TableSubCategories,
    options?: any,
  ) => {
    this.props.subCategories.includes(subCategory) &&
      this.props.superApi(ApiOperation.GET, {
        category,
        subCategory,
        ...options,
      });
  };

  componentDidMount(): void {
    const { category, categoryData, summaryData, tableData } = this.props;

    if (!this.props.configs) {
      this.props.fetchConfig();
    }
    if (!categoryData) {
      this.callSuperApi(category, TableSubCategories.CATEGORY_DATA);
    }
    if (!summaryData) {
      this.callSuperApi(category, TableSubCategories.SUMMARY_DATA);
    }
    if (!tableData) {
      this.callSuperApi(category, TableSubCategories.TABLE_DATA);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
  ): void {
    const { category } = this.props;
    if (
      prevProps.category !== this.props.category ||
      !isEqual(prevProps.credentials, this.props.credentials) ||
      !isEqual(this.props.regions, prevProps.regions)
    ) {
      if (!this.props.credentials.length) return;
      this.callSuperApi(category, TableSubCategories.CATEGORY_DATA);
      this.callSuperApi(category, TableSubCategories.SUMMARY_DATA);
      this.callSuperApi(category, TableSubCategories.TABLE_DATA);
    }
    if (!isEqual(prevState.categoryFilters, this.state.categoryFilters)) {
      this.callSuperApi(category, TableSubCategories.TABLE_DATA, {
        categoryFilters: this.state.categoryFilters,
      });
    }
  }

  onSelectCategory = (filterCategory: string, filterSubCategory?: string) => {
    this.setState({
      categoryFilters: {
        category: filterCategory,
        subCategory: filterSubCategory,
      },
    });
  };

  onFilterChange = (filters: any) => {
    this.props.superApi(ApiOperation.GET, { filters });
  };

  onSearchChange = (searchValues: any) => {
    this.props.superApi(ApiOperation.GET, { searchValues });
  };

  refreshTableComponents = () => {
    const { category, subCategories } = this.props;

    subCategories.forEach((sub) => {
      this.callSuperApi(category, sub as TableSubCategories);
    });
  };

  render(): React.ReactNode {
    if (!this.props.user) {
      return <SectionLoader />;
    }

    const {
      tableData,
      isFetchingCategory,
      isFetchingData,
      isFetchingSummary,
      superApi,
      categoryData,
      tabName,
      regions,
      credentials,
      category,
      subCategories,
      user,
      updateConfigAndRegion,
      summaryData,
      hideCategory,
      hideSubCategory,
    } = this.props;

    const { categoryFilters } = this.state;

    const dataCount = `(${tableData?.data?.length || 0})`;

    const contextValue: TableContextType = {
      refreshTableComponents: this.refreshTableComponents,
    };

    return (
      <TableContext.Provider value={contextValue}>
        <Row className={'dashboard-content position-relative'}>
          <Col md={12}>
            <PageHeaderComponent
              showHeading={true}
              showExecutionTimeStamp={shouldShowTriggerTimeStamp(tabName)}
              showCreateButton={true}
              showOptions={true}
              showShareOptions={true}
              category={category}
              tabName={tabName}
              user={user}
              subCategories={subCategories}
              regions={regions}
              credentials={credentials}
              workflowSubCategory={categoryFilters.subCategory}
              workflowCategory={categoryFilters.category}
            />
            {subCategories.includes(TableSubCategories.CATEGORY_DATA) && (
              <CategorySelector
                selected={categoryFilters}
                hideCategory={hideCategory}
                hideSubCategory={hideSubCategory}
                categories={categoryData}
                onSelectCategory={this.onSelectCategory}
                isLoading={isFetchingCategory}
              />
            )}
            <SummaryAndDataTableWrapper
              onSearchChange={this.onSearchChange}
              category={category}
              subCategories={subCategories}
              superApi={superApi}
              tableData={tableData}
              summaryData={summaryData}
              onFilterChange={this.onFilterChange}
              tcApi={api}
              modalRenderer={modalRenderer}
              isFetchingSummary={isFetchingSummary}
              isFetchingData={isFetchingData}
              regions={regions}
              credentials={credentials}
              updateConfigAndRegion={updateConfigAndRegion}
            />
          </Col>
        </Row>
      </TableContext.Provider>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const { category, subCategories } = ownProps;
  return {
    isFetching: state.workflows?.fetching,
    configs: state.config.data,
    isFetchingCategory:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.CATEGORY_DATA]?.loading,
    isFetchingData:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.TABLE_DATA]?.loading,
    isFetchingSummary:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.SUMMARY_DATA]?.loading,
    tableData:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.TABLE_DATA]?.data,
    categoryData:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.CATEGORY_DATA]?.data,
    summaryData:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.SUMMARY_DATA]?.data,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchConfig: () => fetchConfig({ dispatch }),
    superApi: (operation, options) => {
      const { category, subCategories, regions, credentials } = ownProps;
      const cat = options.cateogry || category;
      const subCat = options.subCategory || TableSubCategories.TABLE_DATA; // Summary
      const filteredOptions = apiCallOptionFilter({
        ...options,
        sortBy: options.sortOption?.value,
        filters: {
          ...options.filters,
          ...options.categoryFilters,
          regions: regions,
          accounts: credentials,
        },
      });
      return superApiCall({
        dispatch,
        category: cat,
        subCategory: subCat,
        operation,
        options: filteredOptions,
      });
    },
  };
}

const TableWrapperWithoutRegion = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TableWrapperInner);

const apiCallOptionFilter = (options: any) => {
  const filters = [
    'category',
    'subCategory',
    'pointer',
    'categoryFilters',
    'sortOption',
  ];
  return Object.keys(options)
    .filter((key) => !filters.includes(key))
    .reduce(
      (newOptions, key) => ({
        ...newOptions,
        [key]: options[key],
      }),
      {},
    );
};

export const TableWrapper = withUser(TableWrapperWithoutRegion);
export const TableWrapperWithRegion = withRegionAndCredentials(
  withUser(TableWrapperWithoutRegion),
);
