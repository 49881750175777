import React, { ReactNode } from 'react';
import { NodeModalProps } from '../NodeUIView';
import { triggerSchema, TriggerSchema } from './schema';
import {
  Alarm,
  CronSchedule,
  Nodes,
  ParsedCronSchedule,
  RateSchedule,
  ScheduleType,
  SerializedTriggerNodeData,
  TriggerNodeData,
  ViewType,
} from 'workflow-model/dist';
import { Formik, FormikProps } from 'formik';
import { ModalWrapper } from '../../common/ModalWrapper';
import { TypeSelector } from './components/TypeSelector';
import { ScheduleForm } from './components/ScheduleForm';
import { TabPane } from 'reactstrap';
import { HttpForm } from './components/HttpForm';
import { AlarmForm } from './components/AlarmForm';
import { getModalTitle } from '../../common/nodeModalHelper';
import { defaultSchema } from './components/SchemaComponent';
import { ManualForm } from './components/ManualForm';
import { globalHelpText } from '../../common/commonHelp';
import { EventsForm } from './components/EventsForm';
import {
  convertCombinedToParsed,
  convertParsedToCombinedCon,
} from '../../../../../../components/workflows/common/cornTypeConverter';

const helpText: JSX.Element = (
  <>
    <h4>
      <strong>Trigger Node</strong>
    </h4>
    <p>
      Trigger node defines ‘when’ the workflow should be executed. It is always
      the first node in any workflow and cannot be deleted.
    </p>
    <p>The following are the different types of triggers;</p>
    <ul>
      <li>
        <p>
          <strong>Schedule:</strong> Allows you to trigger the workflow at
          specific times.
        </p>
        <ul>
          <li>
            <p>
              <strong>Schedule:</strong> Triggers the workflow on a specific day
              and time.
            </p>
            <ul>
              <li>
                <strong>Example:</strong> Schedule the workflow for every Monday
                at 7.00 PM
              </li>
            </ul>
          </li>
          <li>
            <p>
              <strong>Recurrent:</strong> Triggers the workflow periodically for
              every hour, every day, or every seven days.
            </p>
          </li>
          <li>
            <p>
              <strong>Cron:</strong> Write a Cron expression to specify the date
              and time of execution. It is possible to add more than one cron
              expression separated by a : , if you need to schedule the workflow
              to run at different times. Note that the Cron expression used here
              should conform with AWS Cron requirements by omitting the
              'seconds' part. Read more about this{' '}
              <a
                href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html"
                target="_blank"
              >
                here
              </a>
              .
            </p>
          </li>
        </ul>
      </li>
      <li>
        <p>
          <strong>HTTP:</strong> Triggers the workflow using an HTTP endpoint
          URL that can be used with webhooks or in scripts. The URL to trigger
          the workflow will be available after the workflow is saved. Read more
          about HTTP triggers here.
        </p>
      </li>
      <li>
        <p>
          <strong>Alarms:</strong> Triggers the workflow when an AWS Cloudwatch
          Alarm goes into the 'ALARM' state. Configure the Alarm Trigger by
          setting the Metric Namespace, Metric Names (select multiple if
          needed), Statistics (optional), and Dimensions (optional). Read more
          about the Alarm Trigger and it's parameters{' '}
          <a
            href="https://docs.totalcloud.io/workflows/nodes/trigger-node/types-of-trigger-node"
            target="_blank"
          >
            here
          </a>
          .
        </p>
      </li>
      <li>
        <p>
          <strong>Events:</strong> Triggers the workflow in response to an AWS
          event. Configure the event trigger to listen to an event by setting
          the service, resource, event type, and the specific event to listen
          to. It is possible to listen to all events of a particular service by
          setting the Resource and Events parameters to 'All Events'. Note that
          AWS CloudTrail Events can be listened to by setting 'Api' as Resource
          and 'Cloud Trail Event' as Event Type. The JSON pattern on the right
          lets you enter more specific configuration information. Read more
          about the Event Trigger and it's parameters{' '}
          <a
            href="https://docs.totalcloud.io/workflows/nodes/trigger-node/types-of-trigger-node"
            target="_blank"
          >
            here
          </a>
          .
          <p>
            <strong>Caution:</strong> Avoid using Event Triggers with workflows
            that perform AWS actions that can generate the same event that the
            workflow is listening to. This can create an event-workflow loop.
            Read more about this{' '}
            <a
              href="https://docs.totalcloud.io/workflows/nodes/trigger-node/types-of-trigger-node"
              target="_blank"
            >
              here
            </a>
            .
          </p>
        </p>
      </li>
      <li>
        <p>
          <strong>Manual:</strong> The manual trigger is the default trigger. It
          is set to active all the time and you can run the workflow by clicking
          on the ‘Run Now’ button.
        </p>
      </li>
    </ul>
    <p>
      Read more about the Trigger Node{' '}
      <a
        href="https://docs.totalcloud.io/workflows/nodes/trigger-node"
        target="_blank"
      >
        here
      </a>
    </p>
    <br />
    {globalHelpText}
    <br />
    <p>
      For any further queries, chat or mail at{' '}
      <a href="mailto:support@totalcloud.io">support@totalcloud.io</a>
    </p>
    {/* <p>
      <a
        href="https://docs.totalcloud.io/workflows/nodes/trigger-node/types-of-trigger-node"
        target="_blank"
      >
        Click here to know more
      </a>
    </p> */}
  </>
);

export const defaultAlarm: Alarm = {
  namespace: '',
  statistics: [],
  dimensions: '',
  metricNames: [],
};

export const defaultSchedule: RateSchedule = {
  type: ScheduleType.RATE,
  value: '',
  userSchema: defaultSchema,
};

export const defaultHttp = {
  url: '',
  userSchema: defaultSchema,
};

export const defaultEvent = {
  pattern: {},
  eventIds: [],
};

export const defaultTriggerNodeData: TriggerNodeData = {
  alarm: { active: false, value: defaultAlarm },
  schedule: { active: false, value: [] },
  http: { active: false, value: defaultHttp },
  event: { active: false, value: defaultEvent },
  view: ViewType.SCHEDULE,
};

export class TriggerForm extends React.Component<NodeModalProps> {
  getInitialValues(): TriggerSchema {
    const current = this.props.selectedNode as Nodes.TriggerNode;
    let data = current.deserialize();
    if (data === null) {
      return defaultTriggerNodeData;
    } else if (data.schedule.value.length === 0) {
      data.schedule.value.push(defaultSchedule);
    }
    return convertParsedToCombinedCon(data) as TriggerSchema;
  }
  submit = (values: TriggerSchema) => {
    if (!values.alarm.active) {
      values.alarm.value = defaultAlarm;
    }
    if (!values.schedule.active) {
      values.schedule.value = [];
    }

    if (values.schedule.active) {
      const parsedCronValue = convertCombinedToParsed(values) as TriggerSchema;
      values = { ...parsedCronValue };
    }

    const serialized = this.props.selectedNode.serialize(
      JSON.parse(JSON.stringify(values)),
    ) as SerializedTriggerNodeData;
    (this.props.selectedNode as Nodes.TriggerNode).setNodeData(serialized);
    // track(events.TRIGGER_CONFIGURED,null);
    this.props.onClose(true);
  };

  renderForms = (props: FormikProps<TriggerSchema>) => {
    let modalTitle: ReactNode = getModalTitle(this.props.selectedNode);
    return (
      <ModalWrapper
        title={modalTitle}
        onCancel={this.props.onClose}
        onSubmit={props.submitForm}
        helpText={helpText}
        jsonData={{
          workflow: this.props.workflowModel,
          selectedNode: this.props.selectedNode,
          data: props.values,
        }}
      >
        <TypeSelector {...props}>
          <TabPane tabId="schedule" className={'pl-2'}>
            <ScheduleForm {...props} />
          </TabPane>
          <TabPane tabId="http" className={'pl-2'}>
            <HttpForm {...props} />
          </TabPane>
          <TabPane tabId="alarm" className={'pl-2'}>
            <AlarmForm {...props} workflowModel={this.props.workflowModel} />
          </TabPane>
          <TabPane tabId="events" className={'pl-2'}>
            <EventsForm {...props} workflowModel={this.props.workflowModel} />
          </TabPane>
          <TabPane tabId="manual" className={'pl-2'}>
            <ManualForm {...props} workflowModel={this.props.workflowModel} />
          </TabPane>
        </TypeSelector>
        {/*<pre>{JSON.stringify(props.errors)}</pre>*/}
        {props.errors['undefined'] && (
          <small className="text-danger">{props.errors['undefined']}</small>
        )}
      </ModalWrapper>
    );
  };

  render() {
    return (
      <Formik<TriggerSchema>
        initialValues={this.getInitialValues()}
        onSubmit={this.submit}
        render={this.renderForms}
        validationSchema={triggerSchema}
        validateOnChange={false}
        validateOnBlur={true}
      />
    );
  }
}
