import * as React from 'react';
import { AppContainer } from './style';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const FallBack = () => {
  return (
    <AppContainer fluid>
      <Row className={'vh-100'}>
        <Col
          sm={12}
          className={'d-flex align-items-center justify-content-center'}
        >
          <div className={'text-center'}>
            <h2 className={'display-2'}>Oops!</h2>
            <h5>The page you are looking for does not exists.</h5>

            <Button tag={Link} to={'/dashboard'} color={'primary'}>
              Go to Dashboard
            </Button>
          </div>
        </Col>
      </Row>
    </AppContainer>
  );
};

export default FallBack;
