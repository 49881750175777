import * as React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  TestTemplateFormSchema,
  testTemplateSchema,
} from './testTemplateFormSchema';
import CheckBox from '../../../lib/formInputs/checkbox.component';
import { RegionAndCredsComponent } from '../../common/RegionAndCredsComponent';
import { Config } from '../../../lib/store/config/types';
import { ResourceGroupsFactory } from '../../common/resourceGroupsFactory';

interface IProps {
  configs: Config[];
  allAWSConfigs: { label: string; value: string }[];
  templateIds: string[];
  onSave: (params: {
    skipNodeIds: string[];
    templateIds: string[];
    region: string;
    resourceGroup: string;
    account: string;
  }) => void;
  onClose: () => void;
}

export class TestTemplateModal extends React.Component<IProps> {
  onSubmit = (values: TestTemplateFormSchema) => {
    let { account, region, skipNodeIds, resourceGroup } = values;
    this.props.onSave({
      skipNodeIds,
      templateIds: this.props.templateIds,
      account,
      resourceGroup,
      region,
    });
  };

  renderModal = (props: FormikProps<TestTemplateFormSchema>) => {
    const eventHandlers = {
      onChange: props.handleChange,
      onBlur: props.handleBlur,
    };

    const getFieldValues = (field: keyof TestTemplateFormSchema) => {
      return {
        name: field,
        value: props.values[field] || '',
        error: props.errors[field] ? props.errors[field].toString() : '',
        touched: props.submitCount > 0 || props.touched[field] || false,
        placeholder: field,
      };
    };

    const onCheckBoxChange = (value) => {
      let skipNodeIds = props.values.skipNodeIds;
      if (skipNodeIds.indexOf(value) === -1) {
        skipNodeIds.push(value);
      } else {
        skipNodeIds = skipNodeIds.filter((x) => x !== value);
      }

      props.handleChange({
        target: { name: 'skipNodeIds', value: skipNodeIds },
      });
    };

    const onChange = (
      key: 'resourceGroup' | 'account' | 'region',
      value: any,
    ) => {
      if (key === 'account') {
      }
      if (key === 'region') {
      }

      if (key === 'resourceGroup') {
      }
    };

    return (
      <Modal backdrop={'static'} size={'lg'} centered={true} isOpen={true}>
        <ModalHeader
          toggle={this.props.onClose}
          tag={'h5'}
          className={'font-weight-bold'}
        >
          You have selected {this.props.templateIds.length} templates to test
        </ModalHeader>
        <ModalBody>
          <Row className={'my-2'}>
            <Col>
              Select an account and region in which you want to test these
              templates
            </Col>
          </Row>
          <Row>
            <Col md={12} className={'d-flex mb-2 justify-content-around'}>
              <ResourceGroupsFactory configId={props.values.account}>
                {({ availableResourceGroups }) => (
                  <RegionAndCredsComponent
                    availableResourceGroups={availableResourceGroups}
                    configs={this.props.configs}
                    selectedAccount={props.values.account}
                    selectedRegion={props.values.region}
                    onAccountChange={(value) =>
                      props.handleChange({ target: { name: 'account', value } })
                    }
                    onRegionChange={(value) =>
                      props.handleChange({ target: { name: 'region', value } })
                    }
                    showResourceGroup={true}
                    selectedResourceGroup={props.values.resourceGroup}
                    onResourceGroupChange={(value) =>
                      props.handleChange({
                        target: { name: 'resourceGroup', value },
                      })
                    }
                  />
                )}
              </ResourceGroupsFactory>
            </Col>
          </Row>
          <Row>
            <Col>
              <CheckBox
                border={false}
                onChange={onCheckBoxChange}
                hideSelectAll={true}
                options={[
                  {
                    label: 'Skip Create Stack',
                    value: 'node-82084d39-workflow',
                  },
                  {
                    label: 'Skip Destroy Stack',
                    value: 'node-569469a7-workflow',
                  },
                ]}
                CheckedValues={props.values.skipNodeIds}
                name={'skip-node-ids'}
              />
            </Col>
          </Row>
          <Row className={'my-4'}>
            <Col md={5}>
              <Button
                className="test-clone-button"
                color={'primary'}
                block={true}
                disabled={!props.values['account']}
                onClick={props.submitForm}
              >
                Test
              </Button>
            </Col>
            <Col md={5}>
              <Button
                color={'primary'}
                block={true}
                onClick={this.props.onClose}
              >
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  render() {
    return (
      <>
        <Formik
          initialValues={{ region: 'us-east-1', account: '', skipNodeIds: [] }}
          onSubmit={(
            values: TestTemplateFormSchema,
            actions: FormikActions<TestTemplateFormSchema>,
          ) => {
            this.onSubmit(values);
            actions.setSubmitting(false);
          }}
          render={this.renderModal}
          validateOnChange={true}
          validationSchema={testTemplateSchema}
        />
      </>
    );
  }
}
