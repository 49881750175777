import api, { CloneWorkflowParams } from '../../../api';
import { notify } from '../../../components/common/toaster';
import {
  CloneWorkflow,
  CloneWorkflowSuccess,
  CreateWorkflow,
  CreateWorkflowSuccess,
  EditorWorkflowResponse,
  FetchReportingWorkflow,
  FetchWorkflowPolicy,
  FetchWorkflows,
  GroupWfRequest,
  GroupWfResponse,
  ReceivedWorkflowPolicy,
  ReportingWfActionRequest,
  ReportingWfActionResponse,
  ReportWorkflowSuccess,
  SetDefaultCloneResponse,
  UpdateItemRequest,
  UpdateItemResponse,
  Workflow,
  WorkflowAction,
  WorkflowFetchSuccess,
  WorkflowUpdateSuccess,
} from './types';

import { store } from '../index';
import * as WorkflowModal from 'workflow-model/dist';
import { NODE_TYPES, Workflow as WorkflowModel } from 'workflow-model/dist';
import { WorkflowSerializedType } from 'workflow-model/dist/types/IWorkflow';
import shortHash from 'short-hash';
import uuid from 'uuid';
import {
  getExecutionOutputInner,
  ResetExecutionMapRequest,
  waitForExecution,
  WorkflowExecutionResponse,
} from '../executions/action';

import {
  getSerializedWorkflowFromTemplate,
  getWorkflowsAdoptedFormTemplate,
} from '../templates/action';
import { COL_NAMES } from '../../../components/workflows/common/workflowTableHelper';
import { TriggerNode } from 'workflow-model/dist/nodes';

const SendReportingWorkflowActionRequest = function(): WorkflowAction {
  return {
    type: ReportingWfActionRequest,
  };
};

const SendReportingWorkflowActionResponse = function(): WorkflowAction {
  return {
    type: ReportingWfActionResponse,
  };
};

const SendReportingWorkflowRequest = function(): WorkflowAction {
  return {
    type: FetchReportingWorkflow,
  };
};

const ReportingWorkflowResponse = function(
  data: Workflow | null,
): WorkflowAction {
  return {
    type: ReportWorkflowSuccess,
    reportingWorkflow: data as Workflow,
  };
};

export const SendWorkflowRequest = function(): WorkflowAction {
  return {
    type: FetchWorkflows,
  };
};

export const SendEditorWorkflowResponse = function(
  wf: Workflow,
): WorkflowAction {
  return {
    type: EditorWorkflowResponse,
    data: wf,
  };
};
export const SetDefaultResponse = function(): WorkflowAction {
  return {
    type: SetDefaultCloneResponse,
  };
};

export const SendCloneRequest = function(): WorkflowAction {
  return {
    type: CloneWorkflow,
  };
};

export const CloneWorkflowResponse = function({
  success = false,
  error = false,
}): WorkflowAction {
  return {
    type: CloneWorkflowSuccess,
    response: { success, error },
  };
};

export const CreateWorkflowRequest = function(): WorkflowAction {
  return {
    type: CreateWorkflow,
  };
};

export const CreateWorkflowResponse = function(data: any): WorkflowAction {
  return {
    type: CreateWorkflowSuccess,
    data: data,
  };
};

export const WorkflowResponse = function(
  data: Workflow[] | null,
): WorkflowAction {
  return {
    type: WorkflowFetchSuccess,
    data: data as Workflow[],
  };
};

export const workflowUpdateSuccess = function(): WorkflowAction {
  return {
    type: WorkflowUpdateSuccess,
  };
};

export const fetchWorkflowPolicy = function(workflow): WorkflowAction {
  return {
    type: FetchWorkflowPolicy,
    workflow,
  };
};

export const receivedWorkflowPolicy = function(
  workflow: string,
  data: Workflow[] | null,
): WorkflowAction {
  return {
    type: ReceivedWorkflowPolicy,
    workflow,
    data,
  };
};

export const SendGroupWorkflowRequest = function(
  group: string,
): WorkflowAction {
  return {
    type: GroupWfRequest,
    group: group,
  };
};

export const GroupWorkflowResponse = function(
  data: Workflow[] | null,
  group: string,
): WorkflowAction {
  return {
    type: GroupWfResponse,
    data: data as Workflow[],
    group: group,
  };
};

const SendItemUpdateRequest = function(params: {
  id: string;
  col: string;
}): WorkflowAction {
  return {
    type: UpdateItemRequest,
    id: params.id,
    col: params.col,
  };
};

const SendItemUpdateResponse = function(params: {
  id: string;
}): WorkflowAction {
  return {
    type: UpdateItemResponse,
    id: params.id,
  };
};

export const fetchWorkflow = (props: {
  dispatch: (e: any) => void;
  skipResetExecutionRequest?: boolean;
}) => {
  let { dispatch, skipResetExecutionRequest } = props;
  dispatch(SendWorkflowRequest());
  if (!props.skipResetExecutionRequest) {
    dispatch(ResetExecutionMapRequest());
  }

  api
    .getWorkflows()
    .then((data) => {
      return dispatch(WorkflowResponse(data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

export const getGroupWorkflowDetails = function(props: {
  dispatch: (e: any) => void;
  workflow: string;
  group: string;
}) {
  let { dispatch, workflow, group } = props;
  dispatch(SendGroupWorkflowRequest(group));
  api
    .getWorkflowDetails(workflow)
    .then(async (data) => {
      let newStore = store.getState();
      let workflowData = newStore.workflows.groups[group].data || [];
      let dataIndex = workflowData.findIndex(
        (w) => w.workflow == data.workflow,
      );
      if (dataIndex >= 0) workflowData[dataIndex] = data;
      else workflowData.push(data);
      return dispatch(GroupWorkflowResponse(workflowData, group));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(GroupWorkflowResponse(null, group));
    });
};

export const fetchGroupWorkflow = (props: {
  dispatch: (e: any) => void;
  group: string;
}) => {
  let { dispatch, group } = props;
  dispatch(SendGroupWorkflowRequest(group));
  return api
    .getWorkflows(`group=${group}`)
    .then(async (data) => {
      // when fetching workflows also reset executionMap
      return dispatch(GroupWorkflowResponse(data, group));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(GroupWorkflowResponse(null, group));
    });
};

export const fetchPolicy = (props: {
  dispatch: (e: any) => void;
  workflow: string;
}) => {
  let { dispatch, workflow } = props;
  dispatch(fetchWorkflowPolicy(workflow));
  api
    .verifyWorkflowPolicy({ workflow })
    .then((data) => {
      dispatch(receivedWorkflowPolicy(workflow, data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(receivedWorkflowPolicy(workflow, null));
    });
};

export const toggleWorkflowActive = function(props: {
  dispatch: (e: any) => void;
  workflow: Workflow;
}) {
  let { dispatch, workflow } = props;
  let target = {
    workflow: workflow.workflow,
    properties: { active: !workflow.properties.active },
  };
  // console.log('before', workflow, 'after', target);
  dispatch(SendWorkflowRequest());
  return api
    .editWorkflow(target)
    .then(() => fetchWorkflow({ dispatch }))
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

export const createWorkflow = function(props: {
  dispatch: (e: any) => void;
  name: string;
  definition: string;
  properties: any;
  group?: string;
}) {
  let {
    dispatch,
    name,
    definition,
    properties: { active, categories },
    group,
  } = props;

  let target = {
    name,
    definition,
    properties: { active, categories },
    group,
  };

  dispatch(CreateWorkflowRequest());
  return api
    .createWorkflow(target)
    .then(() => fetchWorkflow({ dispatch }))
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

export const updateWorkflow = function(props: {
  dispatch: (e: any) => void;
  workflow: string;
  definition?: string;
  properties?: any;
}) {
  let { dispatch, workflow, definition, properties } = props;
  let target = {
    workflow,
    definition,
    properties,
  };

  dispatch(CreateWorkflowRequest());
  return api
    .updateWorkflow(target)
    .then(() => {
      return dispatch(workflowUpdateSuccess());
    })
    .then(() => fetchWorkflow({ dispatch }))
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

export const fetchWorkflowByName = (props: {
  name: string;
  dispatch: (e: any) => void;
}) => {
  return fetchWorkflowByNameInner(props).catch((e) => {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
    return props.dispatch(ReportingWorkflowResponse(null));
  });
};

export const fetchWorkflowByNameInner = async (props: {
  name: string;
  dispatch: (e: any) => void;
}) => {
  let { dispatch, name } = props;
  dispatch(SendReportingWorkflowRequest());
  let data = await api.getWorkflowByName(name);
  dispatch(ReportingWorkflowResponse(data));
};

export const getEditorWorkflow = function(props: {
  dispatch: (e: any) => void;
  workflow: string;
}) {
  let { dispatch, workflow } = props;
  dispatch(SendWorkflowRequest());
  api
    .getWorkflowDetails(workflow)
    .then(async (data) => {
      dispatch(SendEditorWorkflowResponse(data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

export const getWorkflowDetails = function(props: {
  dispatch: (e: any) => void;
  workflow: string;
}) {
  let { dispatch, workflow } = props;
  dispatch(SendWorkflowRequest());
  api
    .getWorkflowDetails(workflow)
    .then(async (data) => {
      let newStore = store.getState();
      let workflowData = newStore.workflows.data || [];
      let dataIndex = workflowData.findIndex(
        (w) => w.workflow == data.workflow,
      );
      if (dataIndex >= 0) workflowData[dataIndex] = data;
      else workflowData.push(data);
      return dispatch(WorkflowResponse(workflowData));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

export const destroyWorkflow = function(props: {
  dispatch: (e: any) => void;
  workflow: string;
}) {
  let { dispatch, workflow } = props;
  dispatch(SendWorkflowRequest());
  return api
    .destroyWorkflow(workflow)
    .then(async () => {
      let data = store.getState().workflows.data || [];
      return dispatch(
        WorkflowResponse(data.filter((i) => i.workflow != workflow)),
      );
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(WorkflowResponse(null));
    });
};

export const cloneWorkflow = function(
  dispatch: (e: any) => void,
  params: CloneWorkflowParams,
) {
  dispatch(SendCloneRequest());
  api
    .cloneWorkflow(params)
    .then(async (data) => {
      return dispatch(CloneWorkflowResponse({ success: true }));
    })
    .catch((e) => {
      console.error(e);
      return dispatch(CloneWorkflowResponse({ error: true }));
    });
};
export const setDefaultResponse = function(props: {
  dispatch: (e: any) => void;
}) {
  let { dispatch } = props;
  dispatch(SetDefaultResponse());
};

export const waitForReportingUrl = async (props: {
  workflow: WorkflowModal.IWorkflow;
}) => {
  try {
    let wf: WorkflowSerializedType = props.workflow.serialize({
      skipCredsAndRegionValidation: true,
    });
    if (!wf.workflow) wf.workflow = shortHash(uuid());
    let { executionId } = await api.executeWorkflowDryRun({
      ...wf,
      input: { skipReport: true },
    });
    let execution = await waitForExecution(executionId);
    if (!execution || !execution.history || !execution.history.length)
      throw new Error('Error in generating reporting url');
    let reportNode = execution.history.find(
      (x) => x.nodeType === 'workflowReport',
    );
    if (
      !reportNode ||
      !reportNode.output ||
      !reportNode.output.data ||
      !reportNode.output.data.length
    )
      throw new Error('Error in generating reporting url');
    return reportNode.output.data[0].reportUrl;
  } catch (e) {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
  }
};

const getLiveUrlFromHttpHook = (httpHookUrl, reportUrl) => {
  let liveUrl = reportUrl.replace(
    'workflows-report-v2',
    'workflows-report-live',
  );
  let tempArray = httpHookUrl.split('/');
  let index = tempArray.indexOf('webhooks');
  let token = tempArray[index + 1];
  return `${liveUrl}/${token}`;
};

export const generateShareWorkflowUrl = async (props: {
  workflow: string;
  tabName: string;
  permissions: string[];
}) => {
  try {
    const { workflow, tabName, permissions } = props;
    if (permissions.includes('run')) {
      let workflowDetails = await api.getWorkflowDetails(workflow);
      let model = new WorkflowModel({
        isModelDirty: false,
        ...workflowDetails,
        user: workflowDetails['user'],
      });
      let triggerNode: TriggerNode = model.getNodesByType(
        NODE_TYPES.TRIGGER,
      )[0] as TriggerNode;
      let triggerNodeData = triggerNode.getNodeData();
      let isActive = triggerNodeData.http.active;
      if (!isActive) {
        triggerNodeData.http.active = true;
        triggerNode.setNodeData(triggerNodeData);
        let response = await api.updateWorkflow(model.serialize());
      }
    }
    let generateUrl = await api.getShareWorkflowUrl({
      workflow,
      tabName,
      permissions,
    });
    return generateUrl;
  } catch (e) {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
  }
};

export const waitForLiveReportingUrl = async (props: {
  workflow: WorkflowModal.IWorkflow;
}) => {
  try {
    let { workflow } = props;
    if (!workflow.getId()) {
      let triggerNode: TriggerNode = workflow.getNodeById(
        'trigger',
      ) as TriggerNode;
      let triggerNodeData = triggerNode.getNodeData();
      triggerNodeData.schedule.active = false;
      triggerNode.setNodeData(triggerNodeData);
      let newWorkflow = await api.createWorkflow(
        workflow.serialize({ skipCredsAndRegionValidation: true }),
      );
      workflow = new WorkflowModal.Workflow({
        isModelDirty: true,
        ...newWorkflow,
      });
    }

    let reportUrl = await waitForReportingUrl({ workflow });
    let triggerNode: TriggerNode = workflow.getNodeById(
      'trigger',
    ) as TriggerNode;
    if (!triggerNode) throw new Error('Error in generating live reporting url');
    let triggerNodeData = triggerNode.getNodeData();
    if (
      !triggerNodeData ||
      !triggerNodeData.http ||
      !triggerNodeData.http.value ||
      !triggerNodeData.http.value.url
    )
      throw new Error('Error in generating live reporting url');
    return getLiveUrlFromHttpHook(triggerNodeData.http.value.url, reportUrl);
  } catch (e) {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
  }
};

export const runTriggerWorkflow = function(props: {
  dispatch: (e: any) => void;
  workflow: string;
}) {
  let { dispatch, workflow } = props;
  dispatch(SendReportingWorkflowActionRequest());
  api
    .executeWorkflowInstantRun(workflow)
    .then(async (data) => {
      notify({ type: 'success', message: 'Workflow ran successfully' });
      return dispatch(SendReportingWorkflowActionResponse());
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendReportingWorkflowActionResponse());
    });
};

export const runReportingWorkflow = function(props: {
  dispatch: (e: any) => void;
  workflow: WorkflowModal.IWorkflow;
}) {
  let { dispatch, workflow } = props;
  dispatch(SendReportingWorkflowActionRequest());
  let wf: WorkflowSerializedType = workflow.serialize({
    skipCredsAndRegionValidation: true,
  });
  if (!wf.workflow) wf.workflow = shortHash(uuid());
  api
    .executeWorkflowDryRun(wf)
    .then(async (data) => {
      notify({ type: 'success', message: 'Workflow ran successfully' });
      return dispatch(SendReportingWorkflowActionResponse());
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendReportingWorkflowActionResponse());
    });
};

export const saveReportingWorkflow = function(props: {
  dispatch: (e: any) => void;
  workflow: WorkflowModal.IWorkflow;
  reFetch?: () => void;
}) {
  let { dispatch, workflow, reFetch } = props;
  dispatch(SendReportingWorkflowActionRequest());
  let wf: WorkflowSerializedType = workflow.serialize({
    skipCredsAndRegionValidation: true,
  });
  let promise;
  if (wf.workflow) {
    promise = api.updateWorkflow(wf);
    // update
  } else {
    // create
    promise = api.createWorkflow(wf);
  }

  promise
    .then(async (data) => {
      await fetchWorkflowByName({ dispatch, name: workflow.getWorkflowName() });
      dispatch(SendReportingWorkflowActionResponse());
      notify({ type: 'success', message: 'Successfully saved' });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendReportingWorkflowActionResponse());
    })
    .finally(() => reFetch && reFetch());
};

export const saveGroupWorkflow = function(props: {
  dispatch: (e: any) => void;
  workflow: WorkflowModal.IWorkflow;
  group: string;
  skipCredsAndRegionValidation?: boolean;
  reFetch?: (options: any) => void;
}) {
  let {
    dispatch,
    workflow,
    group,
    skipCredsAndRegionValidation,
    reFetch,
  } = props;
  dispatch(SendReportingWorkflowActionRequest());
  let wf: WorkflowSerializedType = workflow.serialize({
    skipCredsAndRegionValidation,
  });
  let promise;
  if (wf.workflow) {
    promise = api.updateWorkflow(wf);
    // update
  } else {
    // create
    promise = api.createWorkflow(wf);
  }

  promise
    .then(async (data) => {
      let newStore = store.getState();
      let workflowData = newStore.workflows.groups[group]?.data || [];
      let dataIndex = workflowData.findIndex(
        (w) => w.workflow == data.workflow,
      );
      if (dataIndex >= 0) workflowData[dataIndex] = data;
      else workflowData.push(data);
      dispatch(GroupWorkflowResponse(workflowData, group));
      dispatch(SendReportingWorkflowActionResponse());
      notify({ type: 'success', message: 'Successfully saved' });
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: e.message || e });
      return dispatch(SendReportingWorkflowActionResponse());
    })
    .finally(() => reFetch && reFetch({}));
};

export const updateAutoRunOfLinkAction = async (props: {
  dispatch: (e: any) => void;
  parentWorkflowId: string;
  status: boolean;
}) => {
  props.dispatch(
    SendItemUpdateRequest({ id: props.parentWorkflowId, col: COL_NAMES.FIX }),
  );
  try {
    await api.updateWorkflow({
      workflow: props.parentWorkflowId,
      properties: { autoRunLinkedWorkflows: props.status },
    });
    fetchWorkflow({
      dispatch: props.dispatch,
      skipResetExecutionRequest: true,
    });
    notify({ type: 'success', message: 'Settings save successfully' });
  } catch (e) {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
  }
  props.dispatch(SendItemUpdateResponse({ id: props.parentWorkflowId }));
};

export const updateLinkedWorkflowAction = async (props: {
  dispatch: (e: any) => void;
  parentWorkflowId: string;
  workflow: string;
}) => {
  props.dispatch(
    SendItemUpdateRequest({ id: props.parentWorkflowId, col: COL_NAMES.FIX }),
  );
  try {
    if (props.workflow) {
      await api.updateWorkflow({
        workflow: props.parentWorkflowId,
        properties: {
          linkedWorkflows: [props.workflow],
          autoRunLinkedWorkflows: true,
        },
      });
    } else {
      await api.updateWorkflow({
        workflow: props.parentWorkflowId,
        properties: { linkedWorkflows: [], autoRunLinkedWorkflows: false },
      });
    }

    fetchWorkflow({
      dispatch: props.dispatch,
      skipResetExecutionRequest: true,
    });
    notify({ type: 'success', message: 'Fix updated successfully' });
  } catch (e) {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
  }
  props.dispatch(SendItemUpdateResponse({ id: props.parentWorkflowId }));
};

export const runLinkedWorkflowAction = async (props: {
  dispatch: (e: any) => void;
  parentWorkflowId: string;
  workflow: string;
}) => {
  let { dispatch, workflow, parentWorkflowId } = props;
  dispatch(SendItemUpdateRequest({ id: parentWorkflowId, col: COL_NAMES.FIX }));

  try {
    let { executionId } = await api.executeWorkflowInstantRun(workflow);
    notify({ type: 'success', message: 'Fix ran successfully' });
    let output = await getExecutionOutputInner(executionId);
    dispatch(WorkflowExecutionResponse(workflow, [], output));
  } catch (e) {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
  }
  dispatch(SendItemUpdateResponse({ id: parentWorkflowId }));
};

/*
  checks if workflow for given template id already exists for user.
  if it does update parent workflow and run linked workflow
  if not create that workflow using template id, update parent workflow and run linked workflow
 */

export const adoptAndRunFix = async (props: {
  dispatch: (e: any) => void;
  parentWorkflowId: string;
  templateId: string;
  regions: string[];
  accounts: string[];
  runFix?: boolean;
}) => {
  let {
    dispatch,
    parentWorkflowId,
    templateId,
    regions,
    accounts,
    runFix,
  } = props;
  dispatch(SendItemUpdateRequest({ id: parentWorkflowId, col: COL_NAMES.FIX }));
  try {
    let res = await api.linkAndAdoptFix({
      parentWorkflowId,
      templateId,
      regions,
      accounts,
      runFix,
    });
    fetchWorkflow({
      dispatch: props.dispatch,
      skipResetExecutionRequest: true,
    });
    notify({ type: 'success', message: 'Fix linked successfully' });
  } catch (e) {
    console.error(e);
    notify({ type: 'error', message: e.message || e });
  }
  props.dispatch(SendItemUpdateResponse({ id: props.parentWorkflowId }));
};
