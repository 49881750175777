import * as React from 'react';
// import { page } from '../analytics';
import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { Provider } from 'react-redux';
import { store } from '../lib/store';
import { OnBoardingIntercept } from '../components/common/onBoardingIntercept';
import { SidebarWrapper } from '../components/common/sideBarWrapper';
import { AppContainer } from './style';
import { Dashboard as DashboardInner } from '../components/dashboard';
import { NavBarWrapper } from '../components/common/navbarWrapper';

function getName(user: any) {
  if (!user) return '';
  if (user.firstName) return user.firstName;
  else return user.email.split('@')[0];
}

class Dashboard extends React.Component<SecureRouteProps, { user: string }> {
  componentDidMount(): void {
    this.props.auth.getUser().then((user: {}) => {
      this.setState({ user: getName(user) });
    });
  }
  renderInner = () => (
    <AppContainer fluid={true}>
      {/*<NavBarWrapper {...this.props}>*/}
      <SidebarWrapper {...this.props}>
        <DashboardInner />
      </SidebarWrapper>
      {/*</NavBarWrapper>*/}
    </AppContainer>
  );

  render(): React.ReactNode {
    return (
      <Provider store={store}>
        <OnBoardingIntercept render={this.renderInner} />
      </Provider>
    );
  }
}

// const

export default withTcApi(Dashboard);
