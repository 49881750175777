import styled from 'styled-components';
import { Button, Row, Input } from 'reactstrap';

export const NavButton = styled(Button)`
  text-transform: none !important;
`;

export const SecondaryRow = styled(Row)`
  background: #f5f6fa;
`;

export const WFNameInput = styled(Input)`
  border: 0 !important;
  border-bottom: 0.0625em solid #333 !important;
  border-radius: 0 !important;
  text-align: center;
  max-height: 2em;
`;
