export const events = {
  POSITION_IN_COMPANY: 'position-in-company',
  ROLE_IN_COMPANY: 'role-in-company',
  CREATE_NEW_WORKFLOW_CLICKED: 'create_new_workflow_clicked',
  CHOSEN_TO_USE_TEMPLATES: 'chosen_to_use_templates',
  INITIAL_CHOICE_COMPLETED: 'initial_choice_completed',
  CHOSEN_TO_CREATE_FROM_SCRATCH: 'chosen_to_create_from_scratch',
  NODE_DATA_SAVED_SUCCESSFULLY: 'node_data_saved_successfully',
  SAVE_WORKFLOW_CLICKED: 'save_workflow_clicked',
  WORKFLOW_CREATED: 'workflow_created',
  WORKFLOW_UPDATED: 'workflow_updated',
  API_CALL_SUCCESSFUL: 'api_call_successful',
  API_CALL_FAILED: 'api_call_failed',
  WORKFLOW_TOGGLE_CLICKED: 'workflow_toggle_clicked',
  ENTERED_NODE_EDIT_MODE: 'entered-node-edit-mode',
  DELETE_NODE_CLICKED: 'delete_node_clicked',
  DELETE_NODE_SUCCESS: 'delete_node_successfully',
  DELETE_NODE_CANCEL: 'node_deletion_cancel',
  ADDED_NODE: 'added_node',
  TRIGGER_SAVE_CLICKED: 'trigger_save_clicked',
  TRIGGER_CANCEL_CLICKED: 'trigger_cancel_clicked',
  RESOURCE_SAVE_CLICKED: 'resource_save_clicked',
  RESOURCE_CANCEL_CLICKED: 'resource_cancel_clicked',
  FILTER_SAVE_CLICKED: 'filter_save_clicked',
  FILTER_CANCEL_CLICKED: 'filter_cancel_clicked',
  ACTION_SAVE_CLICKED: 'action_save_clicked',
  ACTION_CANCEL_CLICKED: 'action_cancel_clicked',
  APPROVAL_SAVE_CLICKED: 'approval_save_clicked',
  APPROVAL_CANCEL_CLICKED: 'approval_cancel_clicked',
  NOTIFICATION_SAVE_CLICKED: 'notification_save_clicked',
  NOTIFICATION_CANCEL_CLICKED: 'notification_cancel_clicked',
  REPORT_SAVE_CLICKED: 'report_save_clicked',
  REPORT_CANCEL_CLICKED: 'report_cancel_clicked',
  WORKFLOW_ACTIVATED: 'workflow_activated',
  WORKFLOW_DEACTIVATED: 'workflow_deactivated',
  SYNC_VALIDATION: 'sync_validation',
  SAVE_CLOUD_BILL: 'save_bill_cloud',
  IMPROVE_SAFETY_RELIABILITY: 'improve_safety_reliability',
  SAVE_TIME: 'save_time',
  CURRENTLY_MANAGE_CLOUD: 'currently_manage_cloud_usage',
  DEFINE_SUCCESS: 'define_success',
  MADE_YOU_TRY: 'what_made_you_try',
  CHECKED_KEEP_ME_LOGIN: 'checked_keep_me_login',
  UNCHECKED_KEEP_ME_LOGIN: 'Unchecked_keep_me_login',
  SIGN_UP_FAILURE: 'failure_in_sign_up',
  SIGN_UP_SUCCESS: 'successfully_sign_up',
  LOGIN_SUCCESS: 'successfully_login',
  LOGIN_FAILURE: 'failure_in_login',
  RESET_ACCOUNT_FAILURE: 'failure_account_resetting',
  RESET_ACCOUNT_SUCCESS: 'success_account_resetting',
  FORGET_PASSWORD_REQUEST_FAILURE: 'forgot_password_request_failed',
  FORGET_PASSWORD_REQUEST_SUCCESS: 'forgot_password_request_success',
  ACTIVATION_LINK_SENT_SUCCESSFULLY: 'activation_link_sent_successfully',
  ACTIVATION_LINK_SENT_FAILED: 'activation_link_sending_failed',

  HEAR_FROM: 'hear_from_about_totalcloud',
  CURRENT_CHALLENGE_IN_CLOUD_MANAGEMENT:
    'current_challenge_in_cloud_management',
  HOW_YOU_DO_CURRENTLY: 'how_you_do_currently',
  CURRENT_CLOUD_PROVIDER: 'current_cloud_provider',
  INTEND_TO_GET_FROM_TC: 'intend_to_get_from_tc',
};
