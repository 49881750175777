import { SEVERITY } from 'workflow-model/dist';
import * as React from 'react';

export type SeverityColDataType = string;

export const getSeverityString = (
  severity: SEVERITY = SEVERITY.LOW,
): string => {
  switch (severity) {
    case SEVERITY.LOW:
      return 'LOW';
    case SEVERITY.HIGH:
      return 'HIGH';
    case SEVERITY.MEDIUM:
      return 'MEDIUM';
    default:
      return 'LOW';
  }
};

export const severityColDataFormatter = (data) => {
  return getSeverityString(
    (data.properties && data.properties.severity) || SEVERITY.LOW,
  );
};
