import { Execution } from '../../../lib/store/executions/types';
import * as React from 'react';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { OverviewDuration } from '../../../lib/store/overview/types';
import { fetchExecution } from '../../../lib/store/executions/action';
import ExecutionList from './executionsList';

interface StateProps {
  executions: Execution[];
  isFetching: boolean;
}

interface DispatchProps {
  getExecution: () => void;
}

interface OwnProps {
  duration: OverviewDuration;
}

type IProps = StateProps & DispatchProps & OwnProps;

export class ExecutionInterceptInner extends React.Component<IProps> {
  componentDidMount(): void {
    this.props.getExecution();
  }

  render(): React.ReactNode {
    if (!this.props.executions && !this.props.isFetching) {
      this.props.getExecution();
    }

    let loading = this.props.isFetching && !this.props.executions;
    if (loading) {
      return null;
    }

    return <ExecutionList executionData={this.props.executions} />;
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isFetching: state.execution.fetching,
    executions: state.execution.data[ownProps.duration],
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    getExecution: () =>
      fetchExecution({
        dispatch,
        duration: ownProps.duration,
        detailed: false,
      }),
  };
}

export const ExecutionIntercept = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ExecutionInterceptInner);
