import { Workflow } from '../workflows/types';
import {
  Execution,
  ExecutionDataType,
  WorkflowExecutionOutput,
} from '../executions/types';

export const RequestWorkflowList = 'RequestWorkflowList';
export const ResponseWorkflowList = 'ResponseWorkflowList';
export const RequestBatchExecution = 'RequestBatchExecution';
export const ResponseBatchExecution = 'ResponseBatchExecution';
export const RequestWorkflowReport = 'RequestWorkflowReport';
export const ResponseWorkflowReport = 'ResponseWorkflowReport';

interface RequestWorkflowReport {
  type: typeof RequestWorkflowReport;
  data: string[];
}

interface ResponseWorkflowReport {
  type: typeof ResponseWorkflowReport;
  data: { workflow: string; data: any }[];
}
interface RequestBatchExecution {
  type: typeof RequestBatchExecution;
  data: string[];
}

interface ResponseBatchExecution {
  type: typeof ResponseBatchExecution;
  data: ExecutionStatusApiResult[];
}

interface RequestWorkflowList {
  type: typeof RequestWorkflowList;
}

interface ResponseWorkflowList {
  type: typeof ResponseWorkflowList;
  data: Workflow[];
}

export type ManageAction =
  | RequestWorkflowList
  | ResponseWorkflowList
  | RequestBatchExecution
  | ResponseBatchExecution
  | RequestWorkflowReport
  | ResponseWorkflowReport;

export type ManageStateWorkflow = {
  workflow: Workflow;
  executions: ExecutionDataType;
  report: {
    fetching: boolean;
    data: any;
  };
};

export interface ManageState {
  fetchingWorkflow: boolean;
  data: {
    [workflowId: string]: ManageStateWorkflow;
  };
}

export interface ExecutionStatusApiResult {
  workflow: string;
  executions: Execution[] | null;
  execution: Execution | null;
  output: WorkflowExecutionOutput | null;
}
