import { GlobalPolicyType } from '../../lib/store/policy/types';

type IProps = {
  workflows: string[];
  templates: string[];
  accounts: string[];
  globalPolicyMap: GlobalPolicyType;
};

export const getActionsForAccount = (
  props: IProps,
): { actions: { [action: string]: boolean }; isAuthError: boolean } => {
  let result = { actions: {}, isAuthError: false };
  let { workflows, templates, accounts, globalPolicyMap } = props;
  if (!globalPolicyMap) return result;
  for (let account of accounts) {
    let accountPolicy = globalPolicyMap.workflows[account];
    // console.log({accountPolicy});
    if (!accountPolicy) {
      result.isAuthError = true;
      continue;
    }

    if (accountPolicy.isAuthError) result.isAuthError = true;
    // console.log("account keys",Object.keys(accountPolicy.details));
    // console.log("workflows",JSON.stringify(workflows));
    let accountWorkflows = Object.keys(accountPolicy.details).filter(
      (x) => workflows.indexOf(x) !== -1 || templates.indexOf(x) !== -1,
    );
    // console.log({accountWorkflows});

    let accountActions: { [action: string]: boolean } = accountWorkflows.reduce(
      (A, e) => {
        A = Object.assign(A, accountPolicy.details[e]);
        return A;
      },
      {},
    );
    result.actions = Object.assign(result.actions, accountActions);
  }
  return result;
};
