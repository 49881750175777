import * as React from 'react';
import { SupportIndex } from '../components/support';
import { Provider } from 'react-redux';
import { store } from '../lib/store';
import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { AppContainer } from './style';
import { OnBoardingIntercept } from '../components/common/onBoardingIntercept';
import { SidebarWrapper } from '../components/common/sideBarWrapper';
// import { page } from '../analytics/analytics';

class SupportPage extends React.Component<SecureRouteProps> {
  // componentDidMount() {
  //   page();
  // }

  renderInner = () => (
    <Provider store={store}>
      <AppContainer fluid={true}>
        <SidebarWrapper {...this.props}>
          <SupportIndex {...this.props} />
        </SidebarWrapper>
      </AppContainer>
    </Provider>
  );

  render(): React.ReactNode {
    return (
      <Provider store={store}>
        <OnBoardingIntercept render={this.renderInner} />
      </Provider>
    );
  }
}

export default withTcApi(SupportPage);
