import React from 'react';
import { Formik, FormikProps } from 'formik';
import {
  Template,
  TemplateTestSettings,
} from '../../../lib/store/templates/types';
import { Workflow } from '../../../lib/store/workflows/types';
import {
  testSettingsFormSchema,
  TestSettingsFormSchema,
} from './testSettingsFormSchema';

import { TestSettingsForm } from './TestSettingsForm';

interface TestSettingModalProps {
  testSettings: TemplateTestSettings;
  template: Template;
  onSave: (data: TestSettingsFormSchema, template: Template) => void;
  getCustomWorkflows: () => Workflow[];
  getTemplateTesterWorkflows: () => {name:string, workflow:string}[]
}

interface IState {
  showModal: boolean;
}
export default class TestSettingModal extends React.Component<
  TestSettingModalProps,
  IState
> {
  state = {
    showModal: false,
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  getInitialValues = () => {
    let { testSettings } = this.props;
    if (!testSettings)
      return {
        createStackWorkflows: [],
        destroyStackWorkflows: [],
        expectedOutput: [],
        testerWorkflows: []
      };

    return {
      createStackWorkflows: testSettings.createStackWorkflows || [],
      destroyStackWorkflows: testSettings.destroyStackWorkflows || [],
      expectedOutput: testSettings.expectedOutput || {},
      testerWorkflows: testSettings.testerWorkflows || []
    };
  };

  render() {
    let initialValues = this.getInitialValues();
    const renderForm = (props: FormikProps<TestSettingsFormSchema>) => {
      return (
        <TestSettingsForm
          {...this.props}
          {...props}
          hideTestSettingsModal={this.toggleModal}
        />
      );
    };

    return (
      <>
        <a className={'text-decoration-none'} onClick={this.toggleModal}>
          {initialValues.createStackWorkflows &&
          initialValues.createStackWorkflows.length
            ? 'Edit'
            : 'Create'}
        </a>
        {this.state.showModal && (
          <Formik<TestSettingsFormSchema>
            initialValues={initialValues}
            data-test="skip-action-form"
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(values: TestSettingsFormSchema) => {
              this.props.onSave({ ...values }, this.props.template);
            }}
            validationSchema={testSettingsFormSchema}
            render={renderForm}
          />
        )}
      </>
    );
  }
}
