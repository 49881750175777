import html2canvas from 'html2canvas';
import JsPdf from 'jspdf';
const { saveSync } = require('save-file');

async function getCanvas(rootContainerId: string) {
  // bug fix: capture body
  return html2canvas(document.querySelector(`#${rootContainerId}`));
}

function convertToImage(canvas) {
  return canvas.toDataURL('image/png');
}

function convertImageToPdf(image, height, width) {
  const pdf = new JsPdf(width > height ? 'l' : 'p', 'pt', [width, height]);
  pdf.addImage(image, 'PNG', 0, 0, width, height);
  pdf.save('report.pdf');
}

export const pdfConvert = async function(rootContainerId: string) {
  let canvas = await getCanvas(rootContainerId);
  let image = await convertToImage(canvas);
  const { height, width } = await getImageDimensions(image);
  convertImageToPdf(image, height, width);
};

export const exportAsPng = async function(rootContainerId: string) {
  let canvas = await getCanvas(rootContainerId);
  let image = await convertToImage(canvas);
  saveSync(image, 'report.png');
};

function getImageDimensions(file): Promise<{ width; height }> {
  return new Promise(function(resolved, rejected) {
    let i = new Image();
    i.onload = function() {
      resolved({ width: i.width, height: i.height });
    };
    i.src = file;
  });
}
