import * as React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { ExternalLinks } from '../index.component';
import { loader } from '../../../components/workflows/common/formatter/UtilisationPageFormatters';

interface TooltipProps {
  links: ExternalLinks[];
  className?: string;
}

const ExternalLink = (props: TooltipProps) => {
  const { links } = props;

  let { className } = props;
  className = className ? className : '';

  return (
    <Nav className={`external-links ${className}`}>
      {links.map((link: ExternalLinks, key: number) => {
        if (link.loading)
          return <i key={key} className={'fa fa-spin fa-spinner'} />;
        else
          return (
            <NavItem key={key}>
              <NavLink
                disabled={link.disabled}
                onClick={link.onClick}
                style={{ textDecoration: 'none' }}
                className={`padding-0  ${
                  link.disabled ? 'text-muted' : 'text-info'
                }`}
              >
                {link.label}
              </NavLink>
            </NavItem>
          );
      })}
    </Nav>
  );
};

export default ExternalLink;
