import React from 'react';
import { StyledCustomDropDown } from '../../../workflows/style';

interface ChartFilterProps<T> {
  filters: T[];
  selected: T;
  onChange: (value: any) => void;
}

export const ChartFilter = ({
  filters,
  onChange,
  selected,
}: ChartFilterProps<{
  label: string;
  value: { [key: string]: any };
}>) => {
  return (
    <StyledCustomDropDown
      icon={
        selected.label === 'all' || 'All'
          ? 'fa master-tc-filter test-filter-button'
          : 'fa navIcon-filter-applied test-filter-selected'
      }
      onChange={onChange}
      className={'mx-2'}
      disabled={!Boolean(filters.length)}
      value={selected?.value}
      options={filters}
      name={'table-filters'}
      right
    />
  );
};
