import { TableSubCategories } from '../../tables';
import React, { useEffect, useMemo, useState } from 'react';
import { AppState, store } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { superApiCall } from '../../../lib/store/super-table/action';
import { ApiOperation } from '../../../lib/store/super-table/types';
import { connect } from 'react-redux';
import { ChartComponent } from './index';
import { options } from './options';
import { Period, periodMap } from './constants';
import { PeriodDropDown } from './components/PeriodDropdown';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { DurationSelectorComponent } from './components/DurationSelectorComponent';
import { CategorySelector } from '../../workflows/common/CategorySelector';
import { ChartFilter } from './components/ChartFilter';
import _ from 'lodash';

export const getFromTime = (fromTime: number) =>
  fromTime === 0
    ? 1546281000000 // corresponds to this time =>  new Date('01/01/2019').getTime()
    : Date.now() - fromTime * 1000;

const filters = [
  { label: 'All', value: { active: undefined } },
  { label: 'Active', value: { active: true } },
  { label: 'Inactive', value: { active: false } },
];
interface OwnProps {
  category: string;
  subCategories: TableSubCategories[];
  views?: string[];
  height?: React.Key;
  title?: string;
  extraOptions?: any;
}

interface StateProps {
  data: any;
  loading: boolean;
  categoryData?: { category: string; subCategories: string[] }[];
  loadingCategories: boolean;
}

interface DispatchProps {
  getData: (category: string, subCategory: string, options) => void;
}

type ChartWrapperProps = OwnProps & DispatchProps & StateProps;
export const ChartWrapperInner = ({
  data,
  subCategories,
  category,
  loading,
  views,
  height,
  getData,
  categoryData,
  loadingCategories,
  title,
  extraOptions,
}: ChartWrapperProps) => {
  const [period, setPeriod] = useState<Period>(Period.WEEK);
  const [fromTime, setFromTime] = useState<number>(Period.QUARTER);
  const [toTime, setToTime] = useState<number>(0);
  const [view, setView] = useState(views[0]);
  const [chartFilters, setChartFilters] = useState(filters[0]);
  const [filterCategory, setFilterCategory] = useState<{
    category: string;
    subCategory: string;
  }>({ category: 'All', subCategory: 'All' });

  const getChartData = () => {
    const _params = {
      period: period,
      fromTime: getFromTime(fromTime),
      toTime: Date.now() - toTime * 1000,
    };
    getData(category, TableSubCategories.SUMMARY_DATA, {
      ..._params,
      view,
      ...filterCategory,
      ...chartFilters.value,
      ...extraOptions,
    });
  };

  useEffect(() => {
    if (
      !categoryData &&
      subCategories.includes(TableSubCategories.CATEGORY_DATA)
    ) {
      getData(category, TableSubCategories.CATEGORY_DATA, {});
    }
  }, []);

  useEffect(() => {
    getChartData();
  }, [
    period,
    fromTime,
    toTime,
    view,
    filterCategory,
    chartFilters,
    extraOptions,
  ]);

  const onPeriodChange = (option: { key: string; value: number }) => {
    setPeriod(option.value);
  };

  const onDurationChange = (duration: { fromTime: number; toTime: number }) => {
    setFromTime(duration.fromTime);
    setToTime(duration.toTime);
  };

  const chartOptions = useMemo(
    () =>
      options(period, {
        fromTime: getFromTime(fromTime),
        toTime: Date.now() - toTime * 1000,
      }),
    [period, fromTime, toTime],
  );

  const onSelectCategory = (category: string, subCategory?: string) => {
    setFilterCategory({ category, subCategory: subCategory || 'All' });
  };

  const onChangeFilters = (value) => {
    const find = filters.find((f) => _.isEqual(f.value, value));
    setChartFilters(find);
  };

  return (
    <Row className={'custom-container py-3'}>
      <Col md={12}>
        <CategorySelector
          categories={categoryData}
          onSelectCategory={onSelectCategory}
          selected={filterCategory}
          isLoading={loadingCategories}
        />
      </Col>
      <Col
        md={6}
        className={'mb-3 d-flex  justify-content-start align-items-center'}
      >
        <div>{title || 'Chart'}</div>
        {views?.length && (
          <UncontrolledDropdown>
            <DropdownToggle size={'sm'} className={'ml-2'}>
              {view ? `${view}` : 'Change View'}
            </DropdownToggle>
            <DropdownMenu>
              {views.map((v) => (
                <DropdownItem
                  key={v}
                  data-testid={`chart-view-${v}`}
                  active={v === view}
                  onClick={() => setView(v)}
                >
                  {v}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </Col>
      <Col
        md={6}
        className={'mb-3 d-flex justify-content-end align-items-center'}
      >
        <DurationSelectorComponent
          onChangeDuration={onDurationChange}
          selectedDuration={{
            fromTime: fromTime,
            toTime: toTime,
          }}
        />
        <PeriodDropDown
          onChange={onPeriodChange}
          selected={{ key: periodMap[period], value: period }}
        />

        <ChartFilter
          filters={filters}
          selected={chartFilters}
          onChange={onChangeFilters}
        />

        <span
          data-testid={'chart-refresh-control'}
          className={'mx-3'}
          onClick={() => getChartData()}
        >
          <i
            className={'fa master-tc-continuous text-primary cursor-pointer'}
          />
        </span>
      </Col>
      <Col md={12}>
        <ChartComponent
          isLoading={loading}
          isXTime={true}
          height={height || 300}
          data={data}
          options={chartOptions}
          type={'line'}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => {
  const { category } = ownProps;
  return {
    data:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.SUMMARY_DATA]?.data,
    loading:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.SUMMARY_DATA]?.loading,
    categoryData:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.CATEGORY_DATA]?.data,
    loadingCategories:
      state.superTable[category] &&
      state.superTable[category][TableSubCategories.CATEGORY_DATA]?.loading,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps => {
  return {
    getData: (category: string, subCategory: string, options) =>
      superApiCall({
        category,
        options,
        dispatch,
        subCategory,
        operation: ApiOperation.GET,
      }),
  };
};

const ChartWrapper = connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(ChartWrapperInner);

export default ChartWrapper;
