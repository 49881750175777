import { JSONAction, RequestJSON, RequestJSONSuccess } from './types';
import { JsonSectionDataType } from '../../editor/components/ui/common/ModalWrapper';
import { Workflow } from 'workflow-model/dist';
import { DataResolver } from '../../editor/components/api/DataResolver';
import api from '../../../api';
import { de } from 'cronstrue/dist/i18n/locales/de';
export const SendJSONRequest = function(): JSONAction {
  return {
    type: RequestJSON,
  };
};

export const JSONVValueResult = function(data: string): JSONAction {
  // console.log({data})
  return {
    type: RequestJSONSuccess,
    data: data,
  };
};

export function fetchJSONValue(props: {
  dispatch: (e: any) => void;
  uiData: JsonSectionDataType;
}) {
  const { dispatch, uiData } = props;
  dispatch(SendJSONRequest());
  // api.
  getJSONValue({ uiData })
    .then((value: string) => {
      return dispatch(JSONVValueResult(value));
    })
    .catch((e) => {
      console.error(e);
    });
}

async function getJSONValue(props: {
  uiData: JsonSectionDataType;
}): Promise<string> {
  let { uiData } = props;
  let { selectedNode, data, workflow } = uiData;
  let serialized = workflow.serialize({ skipFullValidation: true });
  let tempWorkflow = new Workflow({
    isModelDirty: true,
    ...serialized,
    definition: JSON.stringify(serialized.definition),
    dataResolver: new DataResolver({
      apiInterface: api,
      onError: () => {},
    }),
  });

  let currentNode = tempWorkflow.getNodeById(selectedNode.getNodeId());
  currentNode.setNodeData(
    selectedNode.serialize(JSON.parse(JSON.stringify(data))),
  );
  let allAvailableResources = await tempWorkflow.getAllAvailableResources(
    currentNode,
  );
  currentNode.setSelectedResource(
    allAvailableResources.find((x) => x.label === data['selectedResource']),
  );

  let outputKeysSchema = await tempWorkflow.getAllOutputKeys();

  let nodes = tempWorkflow.getNodes();
  let currentComponentIndex = nodes.findIndex(
    (x) => x.getNodeId() === selectedNode.getNodeId(),
  );
  let { definition } = tempWorkflow.serialize({ skipFullValidation: true });
  let definitionToShow: any = definition;

  definitionToShow.components = definitionToShow.components.map((x, i) => {
    x['output'] = outputKeysSchema[x.id];
    x['selectedResource'] = x.selectedResource
      ? x.selectedResource.label
      : undefined;
    delete x.target;
    return x;
  });

  definition.components = definition.components.filter((x, i) => {
    return i <= currentComponentIndex;
  });

  return JSON.stringify(definition, null, 2);
}
