import * as yup from 'yup';
import { OrgGroup, OrgUser, Tenant } from '../../lib/store/tenants/types';
import React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import InputText from '../../lib/formInputs/input-text.component';
import MultiSelect from '../../lib/formInputs/multi-select.component';
import { Config } from '../../lib/store/config/types';

type IProps = {
  addUser: (user: Partial<OrgUser>, groups: OrgGroup[]) => void;
  existingUsers: OrgUser[];
  groups: OrgGroup[];
  config: Config[];
  tenant: Tenant;
};

type OrgUserForm = {
  firstName: string;
  lastName: string;
  email: string;
  groups: string[];
};

export function AddUser(props: IProps) {
  const domainRegex = new RegExp(
    '(.)+@' + props.tenant.domain.replace(/\./g, '\\.'),
  );
  const schema = yup.object().shape<OrgUserForm>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup
      .string()
      .email()
      .matches(
        domainRegex,
        `Users belonging to domain ${props.tenant.domain} can only be invited.`,
      )
      .required()
      .notOneOf(
        props.existingUsers.map((u) => u.email),
        'User Already exist',
      ),
    groups: yup.array().of(yup.string()),
  });

  const [isModelVisible, setModalVisible] = React.useState(false);
  const toggleModal = () => setModalVisible(!isModelVisible);
  const getInitialValue = (): OrgUserForm => {
    return {
      firstName: '',
      lastName: '',
      groups: [],
      email: '',
    };
  };

  const resetForm = (
    value: OrgUserForm,
    action: FormikActions<OrgUserForm>,
  ) => {
    action.resetForm(getInitialValue());
    toggleModal();
  };

  const submit = (value: OrgUserForm, action: FormikActions<OrgUserForm>) => {
    action.setSubmitting(true);
    const groups = props.groups.filter((g) => value.groups.includes(g.id));
    props.addUser(
      {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
      },
      groups,
    );
    resetForm(value, action);
  };

  const groupOptions = props.groups.map((u) => ({
    label: u.name,
    value: u.id,
  }));

  return (
    <>
      <Button color={'primary'} outline={true} onClick={toggleModal}>
        Invite User <i className="fa fa-plus ml-3" />
      </Button>
      <Formik<OrgUserForm>
        initialValues={getInitialValue()}
        onSubmit={submit}
        onReset={resetForm}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps: FormikProps<OrgUser>) => {
          const handleOptionUpdate = (values: { value: string }[]) => {
            formikProps.handleChange({
              target: { name: 'groups', value: values.map((u) => u.value) },
            });
          };
          return (
            <Modal size={'lg'} toggle={toggleModal} isOpen={isModelVisible}>
              <ModalHeader toggle={toggleModal}>Invite new user</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={8}>
                    <InputText
                      name={'email'}
                      value={formikProps.values.email}
                      error={formikProps.errors.email}
                      onChange={formikProps.handleChange}
                      label={'User Email'}
                    />
                  </Col>
                  <Col md={8}>
                    <InputText
                      name={'firstName'}
                      value={formikProps.values.firstName}
                      error={formikProps.errors.firstName}
                      onChange={formikProps.handleChange}
                      label={'First Name'}
                    />
                  </Col>
                  <Col md={8}>
                    <InputText
                      name={'lastName'}
                      value={formikProps.values.lastName}
                      error={formikProps.errors.lastName}
                      onChange={formikProps.handleChange}
                      label={'Last Name'}
                    />
                  </Col>
                  <Col md={8} className={'mt-3 mb-2'}>
                    <MultiSelect
                      label={'Add to existing groups'}
                      value={[]}
                      options={groupOptions}
                      name={'groups'}
                      onChange={handleOptionUpdate}
                      error={
                        formikProps.errors.groups &&
                        formikProps.errors.groups.join('   ')
                      }
                    />
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-start">
                <Button
                  color={'primary'}
                  onClick={() => formikProps.handleSubmit()}
                >
                  Submit
                </Button>
                <Button
                  color={'primary'}
                  className="ml-3"
                  outline={true}
                  onClick={formikProps.handleReset}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}
