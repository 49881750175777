import * as React from 'react';

import Description from '../components/Description';
import { Link } from 'react-router-dom';
import { observe } from '../../../analytics/eventCapturer';
import { OktaConfig } from '../../../config/okta';

const style = {
  width: '80%',
  margin: 'auto',
};

const ACTIVATION_SUCCESS_MESSAGE: string =
  'Your account has been activated. Enjoy using TotalCloud.';
const ACTIVATION_ERROR_MESSAGE: string =
  'Error actvating your account. Please check your internet connection.';
const ACTIVATION_PROGRESS: string = 'Activating your account';

interface IProps {
  activationToken: string;
}

interface IState {
  activationMessage: string;
}

export default class ActivationPage extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activationMessage: ACTIVATION_PROGRESS,
    };
  }

  componentDidMount() {
    if (!this.props.activationToken) {
      this.setState({ activationMessage: ACTIVATION_ERROR_MESSAGE });
      return;
    }
    const headers: HeadersInit = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    observe(
      fetch(
        `${OktaConfig.REACT_APP_BASE_URL}/tokens/${this.props.activationToken}/verify`,
        {
          method: 'get',
          mode: 'no-cors',
          headers,
        },
      ),
      'ACTIVATION_LINK_SENT',
    )
      .then((resp) => {
        this.setState({
          activationMessage: ACTIVATION_SUCCESS_MESSAGE,
        });
      })
      .catch((err) => {
        this.setState({
          activationMessage: ACTIVATION_ERROR_MESSAGE,
        });
      });
  }
  render() {
    const { activationMessage } = this.state;
    return (
      <div className="container-fluid login d-flex align-items-center ">
        <div className="row" style={style}>
          <div className="col-lg-5 offset-lg-1 col-xs-12">
            <Description />
          </div>
          <div className="col-lg-4 offset-lg-1 col-xs-12">
            <div className="row login-form">
              <div className="form-group verification">
                <div className="form-row title-row text-center">
                  <h3 className="title">Activation</h3>
                </div>
                <div className="form-row text-center content">
                  <h5>
                    {/* The page you are looking for might have been removed, had its
                                        name changed, or is temporarily unavailable. */}
                    {activationMessage}
                  </h5>
                </div>
              </div>
              <div className="form-row have-account text-center">
                <h6>
                  Go to
                  <Link to="/login">Login</Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
