import { Formik, FormikProps } from 'formik';
import { EventEditor, eventExplorerSchema, TriggerSchema } from '../schema';
import { ToggleActiveState } from './ToggleActiveState';
import React, { Component } from 'react';
import { IWorkflow } from 'workflow-model/dist';
import { Alert, Button, Col, Label, Row } from 'reactstrap';
import { EventExplorer } from './events/EventExplorer';
import EventPatterns from './events/patterns.json';
import { getSelectedFromId } from './events/util';
import { Config } from '../../../../../../store/config/types';
import { connect } from 'react-redux';
import { AppState } from '../../../../../../store';
import { CONFIG_TYPES } from 'webapp-genric/dist';
import { Dispatch } from 'redux';
import { fetchConfig } from '../../../../../../store/config/action';
import { SectionLoader } from '../../../../../../../components/common/SectionLoader';

type StateProps = {
  isConfigSelected: boolean;
  isConfigAvailable: boolean;
  isFetching: boolean;
};

type DispatchProps = {
  fetchConfig: () => void;
};

interface OwnProps extends FormikProps<TriggerSchema> {
  workflowModel: IWorkflow;
}

type IProps = StateProps & DispatchProps & OwnProps;

class EventsFormInner extends Component<IProps> {
  getInitialExplorerValue = (): EventEditor => {
    const { value = {} } = this.props.values.event;
    const { eventIds = [], pattern = {} } = value as any;
    return {
      selectedDepth:
        eventIds && eventIds.length ? getSelectedFromId(eventIds[0]) : [],
      selectedEvents:
        eventIds && eventIds.map((id) => ({ id, ...EventPatterns[id] })),
      pattern: {
        data: JSON.stringify(pattern, null, 2),
        error: false,
      },
    };
  };

  savePattern = (value: EventEditor) => {
    this.props.handleChange({
      target: {
        value: value.selectedEvents.map((u: any) => u.id),
        name: 'event.value.eventIds',
      },
    });
    this.props.handleChange({
      target: {
        value: value.pattern.data && EventsFormInner.parseString(value),
        name: 'event.value.pattern',
      },
    });
  };

  private static parseString(value: EventEditor) {
    try {
      return JSON.parse(value.pattern.data);
    } catch (e) {
      console.error('error while parsing');
    }
    return {};
  }

  render() {
    const errors = [];
    const addErrors = (e: any) => {
      errors.push(e !== 'string' ? JSON.stringify(e) : e);
    };
    if (this.props.errors.event && this.props.errors.event.value) {
      if (this.props.errors.event.value.eventIds)
        addErrors(this.props.errors.event.value.eventIds);
      if (this.props.errors.event.value.pattern)
        addErrors(this.props.errors.event.value.pattern);
    }
    if (this.props.isFetching) {
      return <SectionLoader />;
    }
    if (!this.props.isConfigSelected) {
      return (
        <Row className={'mt-4'}>
          <Col md={8}>
            <Alert color={'danger'}>
              <i className="fa fa-warning mr-2" /> Please select an aws
              credential and region in editor.
            </Alert>
          </Col>
        </Row>
      );
    }
    if (!this.props.isConfigAvailable) {
      return (
        <Row className={'mt-4'}>
          <Col md={8}>
            <Alert color={'danger'}>
              <i className="fa fa-warning mr-2" /> Please enable Events for
              selected region.
            </Alert>
          </Col>
          <Col md={6}>
            <a href={'/accounts/events'} target="_blank">
              <Button color={'primary'}>
                <i className="fa fa-external-link-square mr-1" /> Accounts{' '}
              </Button>
            </a>
            <Button
              color={'primary'}
              outline={true}
              onClick={this.props.fetchConfig}
            >
              <i className="fa fa-refresh mr-1" /> Refresh{' '}
            </Button>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col>
          <ToggleActiveState {...this.props} targetType={'event'} />
          <Row className={'px-3'}>
            <h4 className="text-muted">
              Select Events for which this workflow should run
            </h4>
          </Row>
          <Formik<EventEditor>
            initialValues={this.getInitialExplorerValue()}
            onSubmit={this.savePattern}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={eventExplorerSchema}
          >
            {(props) => <EventExplorer {...props} srcErrors={errors} />}
          </Formik>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  const currentConfigId = ownProps.workflowModel.getCredentials();
  const region = ownProps.workflowModel.getRegion();
  let isConfigAvailable = false;
  const configs = state.config.data;
  if (currentConfigId && configs) {
    const currentConfig = configs.find((u) => u.id === currentConfigId);
    for (const config of configs) {
      if (config.type === CONFIG_TYPES.Events) {
        const parent = configs.find((u) => u.id === config.parentId);
        if (
          parent &&
          currentConfig &&
          parent.accountId === currentConfig.accountId &&
          config.value.regions &&
          config.value.regions.includes(region)
        ) {
          isConfigAvailable = true;
          break;
        }
      }
    }
  }
  return {
    isConfigSelected: !!(currentConfigId && region),
    isConfigAvailable: isConfigAvailable,
    isFetching: state.config.fetching,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    fetchConfig: () => fetchConfig({ dispatch }),
  };
}

export const EventsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventsFormInner);
