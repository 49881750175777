import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import fonts from '../../design/fonts';
import colors from '../../design/colors';

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  // height: 110px;
  // height: 88px;
  height: 10%;
  justify-content: space-between;
  min-height: 88px;
  max-height: 110px;
`;

export const LogoSection = styled.div`
  cursor: pointer;
  // margin-right: 5rem;
  img {
    // width: 145px;
    // width: auto;
    width: 105px;
  }
`;

export const LinksSection = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 85%;
  margin: 0 auto;
  margin-right: 2em;
`;

export const StyledLink = styled(Link)`
  font-family: ${fonts.family.heading};
  font-weight: ${fonts.weight.content.medium};
  font-size: ${fonts.size.content.regular};
  color: ${colors.palette.white};
  text-decoration: none;
  white-space: nowrap;
  text-transform: uppercase;
  opacity: ${(props: any) => (props.opacity ? props.opacity : '0.7')};
  :first-child {
    padding-left: 2.875em;
  }
  :last-child {
    padding-right: 2.875em;
  }
  &.active {
    opacity: 1;
    font-weight: ${fonts.weight.content.medium};
  }
  &:hover {
    opacity: 1;
  }
  &:not(:last-child) {
    // margin-right: 3em;
  }
`;

export const SettingsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    padding-left: 0.5rem;
  }
  // width: 100%;
`;

// export const HelpSection = styled.div`
//   align-items: center;
//   justify-content: flex-end;
// `;

export const StyledSpan = styled.span`
font-family: ${fonts.family.content};
  font-weight: ${fonts.weight.content.normal};
  font-size: ${fonts.size.content.large};
  color: ${colors.palette.white};
  line-height: 35px;
  cursor: pointer;
  &:hover {
    color: ${colors.link.active} !important;
`;

export const SideBarLabel = styled.span`
  font-size: 0.5em !important;
`;

export const SidebarCol = styled.div<{ isOpen: boolean }>`
  height: calc(100vh - 45px);
  top: auto;
  font-size: ${window.screen.availHeight < 800
    ? 1.3
    : window.screen.availHeight < 950
    ? 1.4
    : 1.5}em;
  max-width: ${(props) => (props.isOpen ? '14vw' : '3.5em')} !important;
  transition: all 100ms ease-in;
  left: 0px;
  bottom: 0;

  &::before {
    display: none;
  }

  .side-bar-toggle {
    display: none;
    left: 100%;
    transform: translateX(-50%);
    top: 35px;
    font-size: 0.8rem;
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    font-weight: light;
    transition: all 0.15s ease-out;
  }

  :hover .side-bar-toggle {
    display: flex;
  }
`;

export const SidebarContent = styled(Col)`
  padding-left: ${(props) => (props.isOpen ? '16vw' : '7em')};
  height: 100vh;
  // overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.15s ease-out;
  // margin-top: 50px;
`;

export const SearchInput = styled.div`
  position: relative;
  font-size: 0.95vw;

  i {
    z-index: 99;
    position: relative;
    font-size: 1.8em;
    // -webkit-text-stroke: 0.08em #ffffff;
    cursor: pointer;
  }

  input[type='text'].search {
    color: #000;
    box-sizing: border-box;
    outline: none;
    font-size: 0.9em;
    padding: 0.4em;
    font-weight: 400;
    border: solid 1px #ccc;
    border-radius: 10em;
    position: absolute;
    top: -0.3em;
    left: auto;
    width: 0;
    opacity: 0;
    z-index: 2;
  }
  .active {
    width: 12em !important;
    opacity: 1 !important;
  }

  .right {
    left: -35%!;
    padding-left: 2.7em !important;

    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  input[type='text'].left {
    left: auto;
  }
  input[type='text'].right {
    right: auto;
  }
  .left {
    right: -35%;
    padding-right: 2.5em !important;

    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
`;
