import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IResolver } from 'workflow-model/dist';
import InputSelect from '../../../../../formInputs/select-option.component';
import { Colors } from '../../../../Colors';
import { URLs } from '../../../common/Globals';
import { AppState } from '../../../../../store';
import { fetchConfig } from '../../../../../store/config/action';

interface StateProps {
  isFetching: boolean;
  slackConfigs: { label: string; value: string }[];
}

interface DispatchProps {
  fetchSlackConfig: () => any;
}

interface OwnProps {
  name: string;
  value: string;
  error: string;
  onChange: (e) => void;
}

export type SlackProps = OwnProps & StateProps & DispatchProps;

class SlackInputSelect extends React.Component<SlackProps> {
  componentDidMount(): void {
    this.props.fetchSlackConfig();
  }

  render() {
    let selectedSlackConfig = this.props.slackConfigs.find(
      (x) => x.value === this.props.value || '@' + x.label === this.props.value,
    );
    return (
      <>
        <Row className={'mt-4'}>
          <Col>
            <InputSelect
              label={'Select Slack Config'}
              {...this.props}
              options={this.props.slackConfigs}
              value={selectedSlackConfig ? selectedSlackConfig.value : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col md={1}>
            {this.props.isFetching ? (
              <span
                style={{
                  color: Colors.link.active,
                }}
                className={'fa fa-refresh fa-spin fa-2x'}
              />
            ) : (
              <span
                style={{
                  textDecoration: 'underline',
                  color: Colors.link.active,
                  cursor: 'pointer',
                }}
                onClick={this.props.fetchSlackConfig}
              >
                Refresh
              </span>
            )}
          </Col>
          <Col md={5}>
            <a
              href={URLs.SLACK_NEW_ACCOUNT}
              target="_blank"
              style={{
                textDecoration: 'underline',
                marginLeft: '1rem',
              }}
            >
              Add Slack Account
            </a>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state: AppState): StateProps {
  return {
    isFetching: state.config.fetching,
    slackConfigs: state.config.data
      .filter((s) => s.type === 'slack')
      .map((s) => ({ label: s.name, value: `@${s.id}` })),
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchSlackConfig: () => fetchConfig({ dispatch }),
  };
}

export const SlackInputField = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps,
)(SlackInputSelect);
