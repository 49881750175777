import * as React from 'react';
import { Onboading } from '../components/onboarding/onboading';
import { store } from '../lib/store';
import { Provider } from 'react-redux';
import { SecureRouteProps, withTcApi } from '../api/tcApiHelper';
import { AppContainer } from './style';
import { SidebarWrapper } from '../components/common/sideBarWrapper';
import { OnBoardingV2 } from '../components/onboarding/views/v2';

export function OnboardingPage(props: SecureRouteProps) {
  return (
    <Provider store={store}>
      <AppContainer useColor={true} fluid={true}>
        <SidebarWrapper {...props}>
          <OnBoardingV2 {...props} />
        </SidebarWrapper>
      </AppContainer>
    </Provider>
  );
}

export default withTcApi(OnboardingPage);
