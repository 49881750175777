import { Workflow } from '../../lib/store/workflows/types';
import { filterItemsByMultipleCategories } from 'webapp-genric/dist/filters';
import { Recommendation } from '../../lib/store/recommendation/types';

export type SubCategory = {
  label: string;
  value: string;
};

export type Category = SubCategory & {
  subcategories?: SubCategory[];
};

interface C {
  categories: {
    category: string;
    subCategories: string[];
  }[];
}

type IOptions = {
  excludeAllSelector?: boolean;
};

export function extractCategories<T extends C>(
  items: T[],
  options: IOptions = {},
): Category[] {
  let cat = options.excludeAllSelector ? [] : ['All'];
  for (const template of items) {
    if (template.categories)
      cat = cat.concat(template.categories.map((c) => c.category));
  }

  // exclude duplicates
  cat = cat.filter((e, i, a) => a.indexOf(e) === i);

  return cat.map((c) => {
    let originals = items.filter(
      (t) => t.categories && t.categories.map((o) => o.category).includes(c),
    );
    if (c === 'All') {
      originals = items;
    }
    let sub = options.excludeAllSelector ? [] : ['All'];
    for (const original of originals) {
      if (!original || !original.categories) continue;
      const s = original.categories
        .filter((a) => a.category === c)
        .map((s) => s.subCategories)
        .reduce((a, e) => a.concat(e), []);
      sub = sub.concat(s);
    }
    // unique sub only
    sub = sub.filter((e, i, a) => a.indexOf(e) === i);
    let subCategories = sub.map((s) => ({ label: s, value: s }));
    return {
      label: c,
      value: c,
      subcategories: subCategories,
    };
  });
}

export function filterRegionByProperty<
  T extends { region: string; credentials: string }
>(items: T[], credentials: string[], region: string[]): T[] {
  return items.filter((r) => {
    let cf = false,
      rf = false;
    if (!credentials) cf = true;
    else {
      const c = r.credentials.replace(/^@/, '');
      cf = credentials.includes(c);
    }
    if (!region) rf = true;
    else rf = region.includes(r.region);
    return rf && cf;
  });
}

export function filterRecommendationByResourceType(
  items: Recommendation[],
  resourceType: string,
) {
  if (!items) {
    return items;
  }
  if (resourceType === 'All') {
    return items;
  }
  return items.filter((item) => item.rType === resourceType);
}

export function findWorkflowWithRegionAndAccount(
  workflows: Workflow[],
  regions: string[],
  credentials: string[],
) {
  const leveled = workflows.map((wf) => ({ ...wf, ...wf.properties }));
  return filterItemsByMultipleCategories(leveled, [
    { category: 'Region', subCategories: regions },
    { category: 'Account', subCategories: credentials },
  ]);
}
