import React from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import { loader } from './UtilisationPageFormatters';
import classNames from 'classnames';

const filterFunction = ({ row, account, service, result, severity }) => {
  if (!row.isLeaf && !row.serviceLabel) return;
  row.onChangeResultFilter(result);
  if (row.accountLevelData) {
    row.onChangeServiceFilter(service);
    row.onChangeAccountFilter('');
  } else {
    row.onChangeServiceFilter('');
    row.onChangeAccountFilter(account);
  }
  row.onChangeSeverityFilter(severity);
};

export const formatAllInsight = (col, row) => {
  if (row.isFetching) return loader();
  return (
    <>
      <span
        id={'all-insights' + row._id}
        className={classNames({
          'cursor-pointer': row.isLeaf || row.serviceLabel,
        })}
        onClick={() =>
          filterFunction({
            severity: '',
            result: '',
            account: row.accountLabel,
            service: row.serviceLabel,
            row,
          })
        }
      >
        {col}
      </span>
      {row.accountLevelData &&
        getToolTip('all-insights' + row._id, row.accountLevelData, 'all')}
    </>
  );
};

export const formatSuccessInsight = (col, row) => {
  if (row.isFetching) return loader();
  return (
    <>
      <span
        id={'success-insights' + row._id}
        style={{ color: '#228b22' }}
        className={classNames({
          'cursor-pointer': row.isLeaf || row.serviceLabel,
        })}
        onClick={() =>
          filterFunction({
            severity: '',
            result: 'Success',
            account: row.accountLabel,
            service: row.serviceLabel,
            row,
          })
        }
      >
        {col}%
      </span>
      {row.accountLevelData &&
        getToolTip(
          'success-insights' + row._id,
          row.accountLevelData,
          'passing',
        )}
    </>
  );
};

export const formatFailureInsight = (col, row) => {
  if (row.isFetching) return loader();
  return (
    <>
      <span
        id={'failed-insights' + row._id}
        className={classNames({
          'cursor-pointer': row.isLeaf || row.serviceLabel,
          'text-red': true,
        })}
        onClick={() =>
          filterFunction({
            severity: '',
            result: 'Failed',
            account: row.accountLabel,
            service: row.serviceLabel,
            row,
          })
        }
      >
        {col}%
      </span>
      {row.accountLevelData &&
        getToolTip(
          'failed-insights' + row._id,
          row.accountLevelData,
          'failing',
        )}
    </>
  );
};

export const formatFailureInsightWithHighSeverity = (col, row) => {
  if (row.isFetching) return loader();
  return (
    <>
      <span
        id={'failed-high-insights' + row._id}
        style={{ backgroundColor: 'red' }}
        className={classNames({
          'cursor-pointer': row.isLeaf || row.serviceLabel,
          'text-white px-2': true,
        })}
        onClick={() =>
          filterFunction({
            severity: 'HIGH',
            result: 'Failed',
            account: row.accountLabel,
            service: row.serviceLabel,
            row,
          })
        }
      >
        {col}%
      </span>
      {row.accountLevelData &&
        getToolTip(
          'failed-high-insights' + row._id,
          row.accountLevelData,
          'high',
        )}
    </>
  );
};

export const formatFailureInsightWithMediumSeverity = (col, row) => {
  if (row.isFetching) return loader();
  return (
    <>
      <span
        id={'failed-medium-insights' + row._id}
        style={{ backgroundColor: 'red' }}
        className={classNames({
          'cursor-pointer': row.isLeaf || row.serviceLabel,
          'text-white px-2': true,
        })}
        onClick={() =>
          filterFunction({
            severity: 'MEDIUM',
            result: 'Failed',
            account: row.accountLabel,
            service: row.serviceLabel,
            row,
          })
        }
      >
        {col}%
      </span>
      {row.accountLevelData &&
        getToolTip(
          'failed-medium-insights' + row._id,
          row.accountLevelData,
          'medium',
        )}
    </>
  );
};

export const formatFailureInsightWithLowSeverity = (col, row) => {
  if (row.isFetching) return loader();
  return (
    <>
      <span
        id={'failed-low-insights' + row._id}
        style={{ backgroundColor: 'red' }}
        className={classNames({
          'cursor-pointer': row.isLeaf || row.serviceLabel,
          'text-white px-2': true,
        })}
        onClick={() =>
          filterFunction({
            severity: 'LOW',
            result: 'Failed',
            account: row.accountLabel,
            service: row.serviceLabel,
            row,
          })
        }
      >
        {col}%
      </span>
      {row.accountLevelData &&
        getToolTip(
          'failed-low-insights' + row._id,
          row.accountLevelData,
          'low',
        )}
    </>
  );
};

const getToolTip = (target, accountLevelData, key) => {
  return (
    <UncontrolledTooltip target={target} style={{ width: '250px' }}>
      {accountLevelData.map((x) => {
        return (
          <Row>
            <Col md={8} className={'text-left'}>
              <span>{x.accountLabel}:</span>
            </Col>
            <Col md={4} className={'text-right'}>
              <span>{x[key]}%</span>
            </Col>
          </Row>
        );
      })}
    </UncontrolledTooltip>
  );
};

export const formatUnknownInsight = (col, row) => {
  if (row.isFetching) return loader();

  return (
    <>
      <span id={`insight-unknown-${row.accountId}`}>{col}%</span>
      <UncontrolledTooltip
        placement="right"
        target={`insight-unknown-${row.accountId}`}
      >
        <div>Not executed/lack of permission</div>
      </UncontrolledTooltip>
    </>
  );
};
