import React from 'react';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
const { RangePicker } = DatePicker;

function onChange(value, dateString) {}

interface DateRangePickerParams {
  name: string;
  value?: { start: string; end: string };
  onChange: (a: any) => void;
  disabled: boolean;
  size?: 'small' | 'default' | 'large';
}

function disabledDate(current: moment.Moment) {
  return current && current.isAfter(moment.now());
}

export function DateRangePicker(props: DateRangePickerParams) {
  const { name, value, disabled, size = 'default' } = props;
  const initial: [moment.Moment, moment.Moment] =
    value && value.start && value.end
      ? [moment.utc(value.start), moment.utc(value.end)]
      : [undefined, undefined];
  const changeEventHandler = ([start, end]) => {
    const event = {
      target: {
        name,
        value: {
          start: start && (start as moment.Moment).toISOString(),
          end: end && (end as moment.Moment).toISOString(),
        },
      },
    };
    props.onChange(event);
  };
  return (
    <RangePicker
      disabled={disabled}
      value={initial}
      showTime={{ format: 'HH:mm' }}
      format="YYYY-MM-DD HH:mm"
      placeholder={['Start Time', 'End Time']}
      onChange={changeEventHandler}
      onOk={changeEventHandler}
      disabledDate={disabledDate}
      size={size}
      style={{ width: '100%' }}
    />
  );
}
