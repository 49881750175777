import styled from 'styled-components';
import { Colors } from './Colors';
import { Fonts } from './Fonts';
import {
  CardSubtitle,
  CardText,
  Col,
  Input,
  InputGroup,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { boolean } from 'yup';
import { ElementType, ReactElement } from 'react';

export const NodesWrapper = styled.div`
  background: ${Colors.palette.bgPrimary};
  border: solid 1px ${Colors.palette.bgThird};
  margin: 1.25em;
  max-height: calc(100% - 2.5em);
  height: fit-content;
  overflow-y: auto;
  width: calc(15% - 1.25em);
`;

export const NodesToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${Colors.button.primary.background};
  color: ${Colors.palette.bgSecondary};
  font-weight: ${Fonts.weight.heading.bold};
  padding: 12px;
  cursor: pointer;
  span {
    padding: 0 8px;
  }
`;
export const NodesBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  height: 100%;
`;
// font-family: sans-serif;
export const NodesInnerBox = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.palette.white};
  align-items: center;
  margin: 1em auto;
  cursor: pointer;
  > div:first-child {
    margin-bottom: 0.2em;
    font-weight: 400;
    img {
      filter: brightness(0) invert(1);
    }
  }
  > div:last-child {
    font-size: 0.9em;
    text-align: center;
  }
`;

export const NameInput = styled.input`
  background: transparent;
  min-width: 20em;
  border-radius: 4px;
  font-size: 1.2em;
  color: ${Colors.text.secondary};
  border: none;
  border-bottom: solid 1px ${Colors.input.placeholderDark};
  outline: none;
  ::placeholder {
    color: ${Colors.input.placeholderDark};
  }
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .tc-editor-wf-toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: ${Colors.palette.white};
    width: 13em;
    padding: 0 1em;
  }
`;

export const EditorActionButton = styled.button`
  border: none;
  background-color: ${Colors.palette.transparent};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 1em;
  color: ${Colors.text.primary};
  font-size: 1.2em;
`;

export const VerificationConfirmationWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .verification-confirmation-header {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${Fonts.size.heading.medium};
    color: ${Colors.text.secondary};
  }

  .verification-confirmation-message {
    height: 60%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${Fonts.size.heading.regular};
    color: ${Colors.text.secondary};
  }
`;

export const ModalFooterButtonsWrapper = styled.div`
  // position: absolute;
  bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  button {
    margin-right: 2rem;
  }

  .test-run-footer-button {
    margin-left: 1em;
    margin-right: 1em;
  }
`;

export const ModalCloseButton = styled.div`
  top: 12px;
  right: 24px;
  color: ${Colors.text.secondary};
  cursor: pointer;
  z-index: 2;
  position: absolute;
  font-size: 48px;
  font-weight: 700;
`;

export const FancyButton = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  display: block;
  outline: none;
  cursor: pointer;
  padding: 1.25rem 0;
  box-sizing: border-box;
  font-family: ${Fonts.family.heading};
  font-size: 1.25rem;
  background-color: transparent;
  font-weight: normal;
  color: ${Colors.text.secondary};
  line-height: 1.25;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 0.3rem;
  border-right: ${(props: any) =>
    props.selected
      ? `5px solid ${Colors.text.secondary}`
      : `2px solid ${Colors.text.tertiary}`};
  opacity: ${(props: any) => (props.disabled ? '0.6' : '1')};

  .underText {
    height: 14px;
    width: 62px;
    opacity: 0.6;
    color: ${Colors.text.tertiary};
    font-family: ${Fonts.family.heading};
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0.44px;
    line-height: 14px;
  }
`;

export const HelpCard = styled(Col)`
  border-radius: 5px;
  background-color: ${Colors.palette.bgThird};
  padding: 27px;
  margin: -2px;
`;

export const HelpCardTitle = styled.span`
  color: #0a2140;
  font-family: ${Fonts.family.heading};
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`;

export const HelpCardSubtitle = styled(CardSubtitle)``;

export const HelpCardText = styled(CardText)`
  color: #001d44;
  font-family: ${Fonts.family.heading};
  font-size: 16px;
  font-style: italic;
  line-height: 18px;
  text-align: justify;
`;

export const ReportHeader = styled.span`
  color: ${Colors.text.secondary};
  font-family: ${Fonts.family.heading};
  font-size: 28.5px;
  line-height: 31.5px;
`;

export const ReportSubHeader = styled.span`
  color: ${Colors.text.secondary};
  font-family: ${Fonts.family.heading};
  font-size: 15px;
  line-height: 16.5px;
  margin-top: 20px;
`;

export const ReportOptions = styled(InputGroup)`
  margin-top: 15px;
  color: ${Colors.text.tertiary};
  font-family: ${Fonts.family.heading};
  font-size: 15px;
  letter-spacing: 0.4725px;
  line-height: 16.5px;
`;

export const ReportOptionElement = styled.span`
  margin-right: 22.5px;
`;

export const ReportNodeFooter = styled(ModalFooterButtonsWrapper)`
  margin-top: 54px;
`;

export const PrimaryButton = styled.button`
  background: ${Colors.button.primary.background};
  color: ${Colors.button.primary.color};
  border-radius: 0.3125em;
  padding: ${(props: any) => (props.padding ? props.padding : '0.6em 0.5em')};
  font-size: ${Fonts.size.content.medium};
  cursor: pointer;
  min-width: 8.5em;
  border: none;
`;

export const SecondaryButton = styled.button`
  background: transparent;
  color: ${Colors.button.secondary.color};
  border-radius: 0.3125em;
  padding: ${(props: any) => (props.padding ? props.padding : '1em')};
  font-size: ${Fonts.size.content.medium};
  border: solid 0.125em ${Colors.button.secondary.color};
  cursor: pointer;
  min-width: 12.5em;
`;

export const FormItem = styled.div`
  margin-bottom: 2rem;
  .label {
    font-size: ${Fonts.size.heading.regular};
    margin-bottom: 0.75rem;
    color: ${Colors.text.secondary};
  }
  .condition-label {
    font-size: ${Fonts.size.content.regular};
    font-style: italic;
  }
`;

export const StyledInput = styled.input<{ hasError?: boolean }>`
  width: 540px;
  height: 46px;
  line-height: 46px;
  padding-left: 1rem;
  border-radius: 4px;
  background: transparent;
  color: ${Colors.text.secondary};
  outline: none;
  border: solid 1px;
  font-size: ${Fonts.size.content.medium};
  ${({ hasError }) => (hasError ? 'border: 1px solid #d04d4d' : '')}
`;

export const ActiveButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${Colors.palette.white};
  padding: 1rem 0 2rem 0;
  .innerElement-left {
    margin: 1rem 1rem 1rem 0;
    color: ${Colors.text.tertiary};
    font-size: 20px;
    letter-spacing: 0.63px;
    line-height: 14px;
  }
  .innerElement-right {
    margin: 1rem 0 1rem 1rem;
    color: ${Colors.text.tertiary};
    font-size: 20px;
    letter-spacing: 0.63px;
    line-height: 14px;
  }
  .innerElement {
    border: 1px solid ${Colors.text.primary};
  }
`;

export const FormError = styled.div`
  margin: 0.5rem 0;
  color: ${Colors.palette.errorMessageColor};
`;

export const StyledReactSelect = styled(Select)`
  width: ${(props: any) => (props.width ? props.width : '40%')};
  min-height: ${(props: any) => (props.height ? props.height : '46px')};
  outline: none !important;
  border-radius: 4px;

  .react-select__control {
    background: transparent;
    color: ${Colors.text.secondary};
    min-height: ${(props: any) => (props.height ? props.height : '46px')};
    outline: none;
    border: ${(props: any) =>
      props.hasError ? '1px solid #d04d4d' : 'solid 1px'};
  }

  .react-select__menu,
  .react-select__option {
    color: ${Colors.text.tertiary};
    data-container: body;
  }
  .react-select__option--is-focused {
    background-color: ${Colors.palette.bgThird} !important;
    color: ${Colors.text.tertiary};
    cursor: pointer;
  }
  .react-select__option--is-selected {
    background-color: ${Colors.palette.bgThird} !important;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__dropdown-indicator > svg {
    width: 30px;
    height: 30px;
  }

  .react-select__single-value {
    color: ${Colors.text.tertiary};
    font-size: ${Fonts.size.content.medium};
  }
`;

export const StyledReactSelectNav = styled(Select)`
  width: ${(props: any) => props.width || '40%'};
  min-height: ${(props: any) => props.height || '46px'};
  outline: none !important;
  border-radius: 0px;
  border: 0 !important;

  .react-select__control {
    background: transparent;
    min-height: ${(props: any) => props.height || '46px'};
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
  .react-select__control:hover {
    outline: none !important;
    border: 0 !important;
    box-shadow: none !important;
  }
  .react-select__menu {
    width: calc(100% + 1em);
    left: -0.5rem;
  }
  .react-select__indicator-separator {
    display: none !important;
  }
  .react-select__value-container {
    padding-left: 0 !important;
    font-size: 1.125em;
  }
  .react-select__option--is-selected {
    background-color: #5e72e4 !important;
  }
  .options-form-item {
    font-size: 14px;
  }
`;

export const TcAccountSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TriggerTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 2rem;
    margin-right: 4rem;
    cursor: pointer;
    font-size: ${Fonts.size.content.large};
    user-select: none;
    color: ${Colors.text.tertiary};
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    border: 1px solid ${Colors.border.normal};
    border-radius: 50%;
  }
  .checkbox-container:hover input ~ .checkmark {
    border: 1px solid ${Colors.text.primary};
  }
  .checkbox-container input:checked ~ .checkmark {
    background-color: ${Colors.text.primary};
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  .checkbox-container .checkmark:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: white;
  }
`;

export const HourMinWrapper = styled.div`
  display: flex;
  width: 40%;
  height: 46px;
  justify-content: space-between;
  border: solid 1px white;
  border-radius: 4px;
`;
export const HourSelect = styled.select`
  color: white;
  margin: 0 1rem;
  height: 46px;
  background: transparent;
  border: none;
  font-size: 24px;
  outline: none;
  color: ${Colors.text.tertiary};

  .hour-option {
    color: ${'black'};
  }
`;

export const MinuteSelect = styled.select`
  color: white;
  margin: 0 1rem;
  height: 46px;
  background: transparent;
  border: none;
  font-size: 24px;
  outline: none;
  color: ${Colors.text.tertiary};

  .minute-option {
    color: ${'black'};
  }
`;

export const ErrorPortion = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

export const StyledTextArea = styled.textarea<{ width?: string }>`
  // width: 540px;
  width: ${(props: any) => (props.width ? props.width : '540px')};
  font-size: ${Fonts.size.content.medium};
  background: transparent;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  border: solid 1px ${Colors.text.secondary};
  outline: none;
  color: ${Colors.text.secondary};
`;

export const NodesSelectorDiv = styled.div`
  display: flex;
  align-items: baseline;
  padding: 1em 1em 0;
  flex-wrap: wrap;
  background: #fff;
  border: none;
  .line {
    width: 2.5em;
    height: 0.125em;
    background: #000;
  }
`;

export const NodeItem = styled.div`
  border: solid 1px;
  color: #000;
  padding: 0.5em;
  cursor: pointer;
  margin: 0 0 1em 0;
  border-radius: 0.25em;
  &.active {
    background: ${Colors.palette.bgFourth};
    color: white;
  }
`;

export const ResultScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
  font-size: ${Fonts.size.content.medium};
`;

export const CRow = styled.div`
  display: flex;
  align-items: ${(props: any) => (props.align ? props.align : 'center')};
  justify-content: ${(props: any) =>
    props.justify ? props.justify : 'center'};
  margin-bottom: ${(props: any) =>
    props.marginBottom ? props.marginBottom : '0'};
  padding: ${(props: any) => (props.padding ? props.padding : '1rem')};
  flex-wrap: wrap;
`;

export const ModalContentTitle = styled.div`
  font-size: ${Fonts.size.heading.medium};
  color: ${Colors.text.secondary};
  font-style: italic;
  margin-bottom: 1.5rem;
`;

export const NotificationOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  button + button {
    margin-left: 20px;
  }
`;

export const NodeModalContentContainer = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .info{
    width: 35%;
    align-self: center;
    max-height: 80%;
    padding: 20px;
    background-color: ${Colors.palette.bgThird};
  }

  .info-header{
    font-size: ${Fonts.size.heading.small}
    // text-align: right;
    margin-bottom: 1rem;
    font-style: italic;
    color: ${Colors.text.secondary};
  }

  .info-text{
    font-size: ${Fonts.size.content.medium};
    // text-align: right;
    color: ${Colors.text.secondary};
    font-style: italic;
  }
`;
// font-family: 'Open Sans', sans-serif;
export const StyledCheckBox = styled.label`
  .text {
    font-size: 20px;
    line-height: 22px;
    color: ${Colors.text.tertiary};
  }
`;

export const StyledCheckBoxElement = styled.input`
  height: 18px;
  width: 18px;
  border-radius: 2px;
  background-color: rgb(11, 33, 63);
  margin: 17px 10px 17px 0;
`;

export const ConditionOuterWrapper = styled.div`
  border: ${(props: any) =>
    `solid 1px ${props.error ? Colors.palette.error : Colors.border.normal}`};
  border-radius: 5px;
  box-sizing: border-box;
  // height: calc(100vh*.47);
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  padding-top: 1rem;
  padding-bottom: 1rem;

  // .condition-main-left{
  //   background-color: mediumseagreen;
  //   width: 60%;
  //   height: 100%;
  // }

  // .condition-main-right{
  //   background-color: slateblue;
  //   width: 40%;
  //   height: 100%;
  // }

  .condition-unit-header {
    width: 100%;
    height: 10%;
    // background-color: limegreen;
  }
`;

export const ConditionInnerWrapper = styled.div`
  // background: ${Colors.text.primary};
  border: none;
  // border-radius: 4px;
  // color: ${Colors.palette.bgPrimary} !important;
  // margin-bottom: 1rem;
  height: 90%;
  // background-color: red;
  display: flex;
  flex-direction: row;

  margin-left: 1rem;
  margin-right: 1rem;

  // .condition-inner-left{
  //   width: 60%;
  //   background-color: darkblue;
  //   // margin-left: 1rem;
  // }

  // .condition-inner-right{
  //   width: 40%;
  //   background-color: dodgerblue;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // }
`;

export const ConditionHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  // background-color: yellow;

  .condition-header-left {
    // background-color: tomato;
    width: 80%;
    height: 100%;
  }

  .condition-header-right {
    // background-color: dodgerblue;
    width: 20%;
    height: 100%;
    display: grid;
    justify-content: right;
  }
`;

export const ConditionsBreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  // background-color: orange;
  flex-wrap: wrap;

  .condition-breadcrumb-item{
    display: flex;
    flex-direction: row;
    font-style: italic;
    font-size: ${Fonts.size.content.regular}
    
    .condition-breadcrumb-label{
      cursor: pointer;
    }

    .condition-breadcrumb-operator-display{
      color: ${Colors.text.primary}
      font-style: normal;
      text-transform: uppercase;
    }
  }
`;

export const ConditionBreadcrumbLabel = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  color: ${(props: any) =>
    props.selected ? Colors.text.secondary : Colors.text.tertiary};
`;

export const FilterMainConditionLabel = styled.div``;

export const StyledSpan = styled.span`
  font-family: ${Fonts.family.content};
  font-weight: ${Fonts.weight.content.normal};
  font-size: ${Fonts.size.content.large};
  color: ${Colors.text.tertiary};
  line-height: 35px;
  cursor: pointer;
  padding: 0 1.5rem;
  &:hover {
    color: ${Colors.text.secondary} !important;
`;

export const StyledListWrapper = styled(Row)`
  // max-height: 220px;
  margin-bottom: 10px;
  // overflow-y: scroll;
  .short {
    max-height: 153.75px;
  }
`;

export const BorderedSegment = styled(Col)`
  border-radius: 5px;
  border: 1px solid ${Colors.border.normal};
  margin-top: 22.5px;
`;
export const SectorHeading = styled.span`
  vertical-align: middle;
  opacity: 0.5;
  color: ${Colors.text.tertiary};
  font-family: ${Fonts.family.heading};
  font-size: 12px;
  font-style: italic;
  line-height: 13.5px;
`;

export const StyledListObject = styled(Col)`
  // padding: 5.6px 22.5px 5.6px 0;
  overflow: hidden;
  color: ${Colors.text.tertiary};
  // :hover {
  //   overflow-x: scroll;
  // }
  .clickable {
    cursor: pointer;
    font-weight: normal;
    color: #0275d8;
    text-align: center;
    font-size: 1rem;
    text-decoration: underline;
  }
  .clickable:hover {
    opacity: 0.5;
  }
`;

export const LargeRadioButton = styled(Input)`
  height: 20px;
  width: 20px;
  margin-right: 3.75px;
  background: transparent;
`;

export const ZoomContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  // bottom: -10%;
  // top: 12%;
  top: 20vh;
  right: 2%;
  // left: 15%;
  // z-index: 999999;
  z-index: 5;
`;

export const ViewOutputCheckBox = styled.div`
  right: 1.5em;
  z-index: 1;

  label {
    cursor: pointer;
    user-select: none;
  }
`;
