export interface UserCreatedScript {
  id: string;
  version: number;
  name: string;
  value: string;
  node?: { name: string; scriptId: string; id: string };
}

// events
export const ListUserCreatedScriptStarted = 'ListUserCreatedScriptStarted';
export const ListUserCreatedScriptCompleted = 'ListUserCreatedScriptCompleted';
export const ModifyUserCreatedScriptStarted = 'ModifyUserCreatedScriptStarted';
export const ModifyUserCreatedScriptCompleted =
  'ModifyUserCreatedScriptCompleted';

interface ListUserCreatedScriptStarted {
  type: typeof ListUserCreatedScriptStarted;
}

interface ListUserCreatedScriptCompleted {
  type: typeof ListUserCreatedScriptCompleted;
  data: UserCreatedScript[] | null;
}

interface ModifyUserCreatedScriptStarted {
  type: typeof ModifyUserCreatedScriptStarted;
}

interface ModifyUserCreatedScriptCompleted {
  type: typeof ModifyUserCreatedScriptCompleted;
}

export type UserCreatedScriptAction =
  | ListUserCreatedScriptStarted
  | ListUserCreatedScriptCompleted
  | ModifyUserCreatedScriptStarted
  | ModifyUserCreatedScriptCompleted;

export interface UserCreatedScriptState {
  fetching: boolean;
  data: UserCreatedScript[];
}
