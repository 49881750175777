import { GlobalPolicyType } from '../../../lib/store/policy/types';
import { TAB_NAME } from 'webapp-genric/dist/constants';

type IProps = {
  accounts: string[];
  globalPolicyMap?: GlobalPolicyType;
  tabName: TAB_NAME;
};

export const getActionsForAccount = (
  props: IProps,
): { actions: { [action: string]: boolean }; isAuthError: boolean } => {
  let result = { actions: {}, isAuthError: false };
  let { globalPolicyMap, accounts, tabName } = props;
  if (!globalPolicyMap) return result;
  for (let account of accounts) {
    let accountPolicy =
      globalPolicyMap.tabSummary[account] &&
      globalPolicyMap.tabSummary[account][tabName];
    if (!accountPolicy) {
      result.isAuthError = true;
      continue;
    }

    if (accountPolicy.isAuthError) result.isAuthError = true;

    let accountWorkflows = Object.keys(accountPolicy.details);

    let accountActions: { [action: string]: boolean } = accountWorkflows.reduce(
      (A, e) => {
        A = Object.assign(A, accountPolicy.details[e]);
        return A;
      },
      {},
    );
    result.actions = Object.assign(result.actions, accountActions);
  }
  return result;
};
