import { SuperTableAction } from './types';

export interface SuperTableSubData {
  data: any;
  loading: boolean;
}

export interface SuperTableState {
  [p: string]: {
    [x: string]: SuperTableSubData;
  };
}

const initialState: SuperTableState = {
  insights: {
    tableData: {
      data: undefined,
      loading: false,
    },
    summaryData: {
      data: undefined,
      loading: false,
    },
    categoryData: {
      data: undefined,
      loading: false,
    },
    triggerSettingData: {
      data: {},
      loading: false,
    },
  },
};

export default (
  state: SuperTableState = initialState,
  action: SuperTableAction,
): SuperTableState => {
  const { category, subCategory } = action.payload || {};

  /**
   * Current value stored in category-subCategory Pair
   */
  let stateData = state[category] && state[category][subCategory];

  switch (action.type) {
    case 'GET_SUPER_TABLE_DATA':
      return {
        ...state,
        [category]: {
          ...state[category],
          [subCategory]: { ...stateData, loading: true },
        },
      };
    case 'GET_SUPER_TABLE_DATA_SUCCESS':
      const meta = state[category] && state[category][subCategory]?.data?.meta;
      if (meta) {
        return {
          ...state,
          [category]: {
            ...state[category],
            [subCategory]: {
              ...stateData,
              data: {
                ...action.payload.data,
                meta: { ...meta, ...action.payload.data?.meta },
              },
              loading: false,
            },
          },
        };
      }

      return {
        ...state,
        [category]: {
          ...state[category],
          [subCategory]: {
            ...stateData,
            data: action.payload.data,
            loading: false,
          },
        },
      };
    case 'GET_SUPER_TABLE_DATA_FAILED':
      return {
        ...state,
        [category]: {
          ...state[category],
          [subCategory]: { ...stateData, loading: false },
        },
      };
    default:
      return state;
  }
};
