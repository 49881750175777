import React from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';

import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  fetchWorkflowByName,
  runReportingWorkflow,
  saveGroupWorkflow,
  saveReportingWorkflow,
} from '../../../lib/store/workflows/action';
import { connect } from 'react-redux';
import {
  CustomReportWorkflow,
  getReportingWorkflowName,
} from './workflowReportConverter';
import { IWorkflow } from 'workflow-model/dist';
import { Workflow } from '../../../lib/store/workflows/types';
import { ButtonText } from '../../onboarding/common/buttonText';
import { Link } from 'react-router-dom';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { TriggerWorkflowOptions } from './WorkflowsTriggerOptionsModal';

import { PolicyStatusRenderer } from './policyStatusRenderer';
import { Template } from '../../../lib/store/templates/types';

import { WorkflowReportWrapper } from './WorkflowReportWrapper';
import ReactDOM from 'react-dom';
import { ExecutionDataType } from '../../../lib/store/executions/types';
import { fetchWorkflowExecutionInBatch } from '../../../lib/store/executions/action';
import { shouldRenderTriggerOptions } from '../../../config/policyStatusConfig';
import { getTriggerWorkflowConfigByTabName } from 'webapp-genric/dist/genricWorkflowsConfig';
import { AdoptionSetting } from '../../page-header/AdoptionSetting';
import Categories from '../../templates-editor/components/TemplateModalContentCategoriesUi';
import { TableSubCategories } from '../../tables';

export const WorkflowTriggerTimeStampDivId = 'workflow_trigger-timestamp-div';

interface StateProps {
  triggerExecution: ExecutionDataType;
  triggerWorkflow: Workflow;
  workflow: Workflow;
  isFetching: boolean;
  reportingAction: boolean;
  isFetchingExecution: boolean;
}

interface DispatchProps {
  fetchReportWf: () => void;
  sendReportNow: (workflow: IWorkflow) => void;
  fetchTriggerWorkflowExecution: (workflow: Workflow) => void;
  saveReportingWorkflow: (workflow: IWorkflow) => void;
  saveTriggerWorkflow: (params: { workflow: IWorkflow; group: string }) => void;
}

interface OwnProps {
  user: string;
  tabName: TAB_NAME;
  category?: string;
  subCategory?: string;
  credentials: string[];
  workflows: Workflow[];
  templates: Template[];
  loading: boolean;
  regions: string[];
}

type IProps = OwnProps & DispatchProps & StateProps;

interface IState {
  showModal: number;
  renderRegionSelector: boolean;
}

class WorkflowReportInner extends React.Component<IProps, IState> {
  state = {
    renderRegionSelector: false,
    showModal: 0, // 1 : report modal, 2 :  trigger control modal 3. region selector for adoption
  };

  componentDidMount(): void {
    this.props.fetchReportWf();
    this.fetchTriggerExecution();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ): void {
    this.fetchTriggerExecution();
  }

  fetchTriggerExecution = () => {
    if (
      !this.props.triggerExecution &&
      !this.props.isFetchingExecution &&
      this.props.triggerWorkflow &&
      this.props.triggerWorkflow.workflow
    )
      this.props.fetchTriggerWorkflowExecution(this.props.triggerWorkflow);
  };
  openModal = (view) => {
    this.setState({ showModal: view });
  };

  closeModal = () => {
    this.setState({ showModal: 0 });
  };

  renderTriggerOptions = () => {
    return (
      <TriggerWorkflowOptions closeModal={this.closeModal} {...this.props} />
    );
  };

  renderTriggerTimeStamp = () => {
    let { triggerExecution, tabName } = this.props;
    if (!triggerExecution || !shouldRenderTriggerOptions(tabName)) return '';

    const divId = WorkflowTriggerTimeStampDivId;
    const container = document.getElementById(divId);

    if (!container) return '';

    if (
      !triggerExecution.exes ||
      !triggerExecution.exes.length ||
      !triggerExecution.exes[0].timestamp
    )
      return '';

    return ReactDOM.createPortal(
      <small>Last refreshed: {triggerExecution.exes[0].timestamp}</small>,
      container,
    );
  };

  onShowRegionSelector = () => {
    this.setState({ renderRegionSelector: true });
  };
  hideRegionSelector = () => {
    this.setState({ renderRegionSelector: false });
  };

  renderPolicyValidation = (workflows, templates, loading) => {
    return (
      <PolicyStatusRenderer
        hideRegionSelector={this.hideRegionSelector}
        renderRegionSelector={this.state.renderRegionSelector}
        loading={loading}
        workflows={workflows}
        templates={templates}
        credentials={this.props.credentials}
        regions={this.props.regions}
        tabName={this.props.tabName}
        triggerWorkflow={this.props.triggerWorkflow}
      />
    );
  };

  renderAdoption = () => {
    const { regions, tabName, credentials } = this.props;

    return (
      <AdoptionSetting
        regions={regions}
        credentials={credentials}
        tabName={tabName}
        category={tabName.toLowerCase()}
        subCategory={TableSubCategories.ADOPTION_SETTINGS_DATA}
        renderRegionSelector={this.state.renderRegionSelector}
        hideRegionSelector={this.hideRegionSelector}
      />
    );
  };

  renderShareOptions = () => {
    return (
      <WorkflowReportWrapper
        customWorkflow={
          new CustomReportWorkflow({
            category: this.props.category,
            subCategory: this.props.subCategory,
            tabName: this.props.tabName,
            user: this.props.user,
            ...this.props.workflow,
          })
        }
        {...this.props}
      />
    );
  };

  render() {
    let { workflows, templates, loading } = this.props;
    return (
      <>
        {this.renderTriggerTimeStamp()}
        {this.renderAdoption()}
        {this.state.showModal === 2 && this.renderTriggerOptions()}
        <div className="d-flex justify-content-end">
          {this.renderShareOptions()}
          {shouldRenderTriggerOptions(this.props.tabName) && (
            <UncontrolledDropdown>
              <DropdownToggle
                color={'primary'}
                caret
                outline={true}
                className="test-new-account d-inline-block text-center w-auto overflow-visible ml-2"
              >
                Options
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.openModal(2)}
                  disabled={this.props.isFetching}
                >
                  Trigger Settings
                  <div className="float-right">
                    <i className="fa fa-info" id={'trigger-save'} />
                  </div>
                  <UncontrolledTooltip target={'trigger-save'}>
                    {`Configure when ${this.props.tabName} will run`}
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    this.onShowRegionSelector && this.onShowRegionSelector()
                  }
                  disabled={
                    !this.props.credentials || !this.props.credentials.length
                  }
                >
                  Region Setting
                  <div className="float-right">
                    <i className="fa fa-info" id={'region-setting'} />
                  </div>
                  <UncontrolledTooltip target={'region-setting'}>
                    {`Configure regions in which ${this.props.tabName} should be adopted`}
                  </UncontrolledTooltip>
                </DropdownItem>
                {this.props.tabName === TAB_NAME.INVENTORY && (
                  <DropdownItem
                    onClick={() =>
                      window.open(`${window.location.origin}/actions/history`)
                    }
                  >
                    Actions History
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}

          <Link to={'/editor'}>
            <Button
              className="test-new-account d-inline-block text-center w-auto overflow-visible ml-2"
              color={'primary'}
              block={true}
            >
              Create {this.props.tabName ? this.props.tabName : 'Workflow'}
            </Button>
          </Link>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  let triggerConfig = getTriggerWorkflowConfigByTabName(ownProps.tabName);
  let triggerWorkflow;
  if (triggerConfig) {
    let groupWfs = state.workflows.groups[triggerConfig.group];
    triggerWorkflow =
      groupWfs &&
      groupWfs.data &&
      groupWfs.data.find((x) => x.name === triggerConfig.name);
  }
  let triggerExecution =
    triggerWorkflow &&
    triggerWorkflow.workflow &&
    state.execution.workflowExecutions[triggerWorkflow.workflow];

  return {
    isFetching: state.workflows.fetchingReportWf,
    reportingAction: state.workflows.reportingAction,
    workflow: state.workflows.reportingWorkflow,
    isFetchingExecution: state.execution.batchFetching,
    triggerWorkflow,
    triggerExecution,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchReportWf: () =>
      fetchWorkflowByName({
        dispatch,
        name: getReportingWorkflowName(ownProps.tabName),
      }),
    sendReportNow: (workflow: IWorkflow) =>
      runReportingWorkflow({ dispatch, workflow }),
    saveTriggerWorkflow: ({ workflow, group }) =>
      saveGroupWorkflow({
        dispatch,
        workflow,
        group,
        skipCredsAndRegionValidation: true,
      }),
    fetchTriggerWorkflowExecution: (workflow: Workflow) =>
      fetchWorkflowExecutionInBatch({ dispatch, workflows: [workflow] }),
    saveReportingWorkflow: (workflow: IWorkflow) =>
      saveReportingWorkflow({ dispatch, workflow }),
  };
}

export const WorkflowReport = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(WorkflowReportInner);
