import * as React from 'react';
import classNames from 'classnames';

export const Group = (props) => {
  const {
    children,
    className,
    cx,
    getStyles,
    label,
    setValue,
    getValue,
    data,
  } = props;
  const expanded = data.expanded;
  const getSelectedCount = () => {
    let selectedOptions = getValue() || [];
    return selectedOptions.filter((x) =>
      data.options.find((f) => {
        if (f.options) return f.label === x.label;
        return f.value === x.value;
      }),
    ).length;
  };

  const arePartialChildrenSelected = () => {
    let selectedValues = getValue() || [];
    let groupOptions = props.data.options;

    let selectedGroupOptions = selectedValues.filter((x) => {
      return x.options
        ? !!groupOptions.find((g) => g.label === x.label)
        : !!groupOptions.find((g) => g.value === x.value);
    });

    if (selectedGroupOptions.length === 0) return false; // not at all selected
    if (groupOptions.length !== selectedGroupOptions.length) return true; // partial

    // if not partial check if any nested group is partial.

    let nestedGroups = groupOptions.filter((x) => x.options);
    if (nestedGroups.length === 0) return false; // if no nested group available for this group

    let partialSelectedNestedGroup = groupOptions.find((x) => {
      return (
        x.options.length !==
        selectedGroupOptions.find((g) => g.label === x.label).options.length
      );
    });

    return !!partialSelectedNestedGroup;
  };
  const isGroupSelected = () => {
    let selectedValues = getValue() || [];
    let groupOptions = props.data.options;
    for (let g of groupOptions) {
      let isOptionSelected = g.options
        ? !!selectedValues.find((x) => x.label === g.label)
        : !!selectedValues.find((x) => x.value === g.value);
      if (isOptionSelected) return true;
    }
    return false;
  };

  const onSelectGroup = () => {
    let selectedValues = getValue() || [];
    let groupOptions = props.data.options;
    // filter all options of current group
    selectedValues = selectedValues.filter((x) => {
      return x.options
        ? !groupOptions.find((g) => g.label === x.label)
        : !groupOptions.find((g) => g.value === x.value);
    });
    if (!isGroupSelected()) {
      // unselect all
      selectedValues.push(groupOptions);
    }
    return setValue(selectedValues);
  };

  return (
    <div>
      <div
        style={getStyles('group', props)}
        className={cx({ group: true }, className)}
      >
        <div style={getStyles('groupHeading', props)} className={'d-flex'}>
          <div className={'pl-3'} onClick={() => props.onExpand(props.data)}>
            <i
              className={classNames({
                'fa fa-caret-down': props.data.expanded,
                'fa fa-caret-right': !props.data.expanded,
              })}
            />
          </div>
          <div
            className={'d-flex'}
            onClick={() =>
              props.isMulti && !props.singleSelectWithinGroup && onSelectGroup()
            }
          >
            {props.isMulti && !props.singleSelectWithinGroup && (
              <input
                type="checkbox"
                checked={isGroupSelected() && !arePartialChildrenSelected()}
                onChange={() => null}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = arePartialChildrenSelected();
                  }
                }}
              />
            )}
            <div
              style={{ maxWidth: '150px' }}
              title={label}
              className={'text-truncate ml-2'}
            >
              <span>{label}</span>
            </div>
          </div>
          {props.isMulti && getSelectedCount() !== 0 && (
            <small className={'ml-2 text-lowercase text-muted'}>
              {`(${getSelectedCount()} selected)`}
            </small>
          )}
        </div>
      </div>

      <div style={{ display: expanded ? 'block' : 'none' }}>{children}</div>
    </div>
  );
};
