import Swal from 'sweetalert2';

export async function confirm(message = "You won't be able to revert this!") {
  return Swal.fire({
    title: 'Are you sure?',
    text: message,
    type: 'error',
    width: '20em',
    showCancelButton: true,
    confirmButtonColor: '#ad1321',
    cancelButtonColor: '#646464',
    confirmButtonText: 'Proceed',
    confirmButtonClass: 'test-delete-confirm',
    cancelButtonClass: 'test-delete-cancel',
  }).then((result) => {
    return result.value;
  });
}
