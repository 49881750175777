import * as React from 'react';
import { Col, Row, Button, Form, UncontrolledTooltip } from 'reactstrap';
import {
  IExternalDataDependent,
  withExternalData,
} from '../../common/externalDependency';
import {
  StyledHeader,
  LogoWrapper,
  DateWrapper,
} from '../../report/styledElements';
import moment from 'moment';
import { notify } from '../../common/toaster';
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { BASE_URL } from '../../../config/api';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import CustomTable from '../../common/table';
import { getCols } from './workflowTableHelper';
import { nameColDataFormatter } from './formatter/NameColFormatter';
import { resultColDataFormatter } from './formatter/ResultColFormatter';
import {
  WorkflowExecutionOutput,
  ExecutionHistory,
} from '../../../lib/store/executions/types';
import { getUtilisationReport } from './dataFormatter/utilisationTab';
import { getLogo_dark } from '../../../util/commonUtils';

interface IProps extends IExternalDataDependent {
  token: string;
  data: {
    url: string;
    name: string;
    tabName: TAB_NAME;
    execution: {
      timestamp: string;
      status: string;
      history: ExecutionHistory[];
    };
    output: WorkflowExecutionOutput;
    hookUrl: string;
  };
}

const getStatus = (data) => {
  if (data && data.execution && data.execution.status) {
    return data.execution.status;
  }
  return;
};

const getLastExecuted = (data) => {
  if (data && data.execution && data.execution.timestamp) {
    return data.execution.timestamp;
  }
  return;
};

export const FormatUtilisationData = (data) => {
  let result: {
    name: string;
    resource: string;
    totalResources: number | string;
    highUtilisation: number | string;
    lowUtilisation: number | string;
    overAllUtilisation: number | string;
    metricName: string;
  }[] = data
    .map((x) => {
      let result = [];
      let output: WorkflowExecutionOutput = x ? x.output : null;
      if (output && output.utilisationReport) {
        let url = output.url;

        let {
          totalResources,
          utilisation,
          metricUtilisationMap,
          Unit,
        } = output.utilisationReport;

        let report = getUtilisationReport(utilisation);
        let re = report.map((r) => {
          return {
            ...x,
            tabName: x.tabName,
            resource: x.name,
            Unit,
            url,
            metricUtilisationMap,
            totalResources,
            ...r,
          };
        });
        result = result.concat(re);
      } else {
        return [
          {
            ...x,
            tabName: x.tabName,
            resource: x.name,
          },
        ];
      }
      return result;
    })
    .reduce((A, e) => A.concat(e), []);
  return result;
};

const dataFormatter = (data) => {
  let rows = data.map((x, i) => {
    return {
      ...x,
      _id: i,
      Name: nameColDataFormatter(x),
      Result: resultColDataFormatter(x),
      options: {
        templateId: '',
        toggleActive: '',
        onAdoptRequest: '',
        onChangeOption: '',
        active: x.properties ? x.properties.active : false,
      },
    };
  });
  return rows;
};

const getTableData = (data) => {
  let formattedData: any = [
    {
      tabName: data.tabName,
      name: data.name,
      result: { workflowOutput: data.output.message },
      output: data.output,
    },
  ];

  if (data.tabName === TAB_NAME.UTILISATION) {
    formattedData = FormatUtilisationData(formattedData);
  }
  return dataFormatter(formattedData);
};

const getColumns = (data) => {
  if (data.tabName === TAB_NAME.UTILISATION) {
    return getCols([
      'Name',
      'Resource',
      'Total Resources',
      'Metric Name',
      'Overall Utilisation',
      'High Utilisation',
      'Low Utilisation',
    ]);
  }
  return getCols(['Name', 'Result']);
};

export class ShareWorkflowExternally extends React.Component<IProps> {
  render(): React.ReactNode {
    const { data } = this.props;
    const status = getStatus(data);
    const lastExecuted = getLastExecuted(data);
    const tableData = data.output && getTableData(data);
    return (
      <div>
        <StyledHeader data-test="share-workflow-header">
          <LogoWrapper lg={2} data-test="share-workflow-page-logo">
            <img src={getLogo_dark()} alt={'logo'} />
          </LogoWrapper>
          <DateWrapper
            lg={{ size: 2, offset: 8 }}
            data-test="share-workflow-page-date"
          >
            {moment().format('DD-MM-YYYY')}
          </DateWrapper>
        </StyledHeader>
        <div
          className="mx-5 my-3 px-5 font-18 share-workflow-data"
          data-test="share-workflow-details"
        >
          <Row className="py-4" data-test="share-workflow-name">
            <Col md={6}>
              <span>Workflow Name</span>
            </Col>
            <Col md={6}>
              <span>{data.name}</span>
            </Col>
          </Row>
          {lastExecuted && (
            <Row className="py-4" data-test="share-workflow-last-executed">
              <Col md={6}>
                <span>Last Execution</span>
              </Col>
              <Col md={6}>
                <span>{lastExecuted}</span>
              </Col>
            </Row>
          )}
          {status && (
            <Row className="py-4" data-test="trigger-action-last-action">
              <Col md={6}>
                <span>Status</span>
              </Col>
              <Col md={6}>{status}</Col>
            </Row>
          )}
          {data.hookUrl && (
            <Row className="py-4">
              <Col md={3}>
                <div className="custom-trigger-button">
                  <Button
                    color={'primary'}
                    onClick={(e) => triggerAction(data.hookUrl)}
                    className="btn-block text-align-center px-2"
                    data-test="trigger-unpark-action"
                  >
                    Run Now
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          {tableData && (
            <Row className="my-3">
              <Col md={12}>
                <div className="font-weight-bold my-2">Workflow Report</div>

                <CustomTable
                  options={''}
                  emptyText={'No Data found'}
                  data={tableData}
                  columns={getColumns(data)}
                  hideSearch
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

const fetchGroupData = async (props) => {
  const { token } = props as IProps;
  let url = `${BASE_URL}/workflow-details-hook/${token}`;
  let response = await fetch(url, {
    method: 'post',
    body: JSON.stringify({}),
  });
  let data = await response.json();
  data = data.response.data;
  data.url = url;
  return data;
};

const triggerAction = async (url: string) => {
  try {
    let response = await fetch(url, {
      method: 'post',
      body: JSON.stringify({}),
    });
    let data = await response.json();
    if (data.response.error) {
      notify({ type: 'error', message: data.response.error.message });
    }
    notify({ type: 'success', message: 'Run Successfully' });
  } catch (error) {
    notify({ type: 'error', message: error.response });
  }
};

export default withExternalData(ShareWorkflowExternally, fetchGroupData);
