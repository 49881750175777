import { TAB_NAME } from 'webapp-genric/dist/constants';
import {
  getGroupedWorkflows,
  getSummaryBreadCrumbs,
} from './dataFormatter/summaryDataFormatter';
import { getAccountConfig } from '../../common/withRegionAndCredentials';
import { groupAccountConfigsOptions } from '../../common/RegionAndCredsComponent';
import { VIEW } from './workflowTable';

export type BreadCrumb = {
  name: string;
  route: string;
  onClick?: Function;
}[];

export const getBreadCrumbs = ({
  view = VIEW.TABLE,
  workflows = [],
  configs = [],
  tabName,
  account,
  updateConfigAndRegion,
  resourceName = '',
  changeToTableView = undefined,
  reportId = undefined,
}) => {
  switch (tabName) {
    case TAB_NAME.UTILISATION: {
      let breadCrumbs: BreadCrumb = [
        {
          name: 'All Accounts',
          route: '/utilisation',
          onClick: () => {
            if (resourceName) {
              changeToTableView();
            }
            updateConfigAndRegion('account', ['all']);
          },
        },
      ];
      if (account && account.length === 1 && account[0].name) {
        breadCrumbs.push({
          name: account[0].name,
          route: '/utilisation',
          onClick: changeToTableView,
        });
      }
      if (resourceName) breadCrumbs.push({ name: resourceName, route: '' });
      if (reportId) breadCrumbs.push({ name: 'Report', route: '' });
      return breadCrumbs;
    }
    case TAB_NAME.INSIGHTS: {
      let breadCrumbs: BreadCrumb = [
        {
          name: 'All Accounts',
          route: '/insights',
          onClick: () => {
            updateConfigAndRegion(
              'account',
              configs.map((x) => x.id),
            );
          },
        },
      ];
      if (account && account.length > 0 && account[0].name) {
        let groupObj = getGroupedWorkflows({ workflows, configs });
        breadCrumbs = breadCrumbs.concat(
          getSummaryBreadCrumbs(
            groupObj,
            groupAccountConfigsOptions(configs),
            updateConfigAndRegion,
            changeToTableView,
          ),
        );
      }
      if (view === VIEW.SERVICE) {
        breadCrumbs.push({ name: account[0].name, route: '' });
      }
      if (reportId) breadCrumbs.push({ name: 'Report', route: '' });
      return breadCrumbs;
    }
    case TAB_NAME.INVENTORY: {
      let breadCrumbs: BreadCrumb = [
        {
          name: 'All Accounts',
          route: '/inventory',
          onClick: () => {
            if (resourceName) {
              changeToTableView();
            }
            updateConfigAndRegion(
              'account',
              configs.map((x) => x.id),
            );
          },
        },
      ];
      if (account && account.length === 1 && account[0].name) {
        breadCrumbs.push({
          name: account[0].name,
          route: '/inventory',
          onClick: changeToTableView,
        });
      }
      if (resourceName) breadCrumbs.push({ name: resourceName, route: '' });
      if (reportId) breadCrumbs.push({ name: 'Report', route: '' });
      return breadCrumbs;
    }
    case TAB_NAME.WORKFLOWS: {
      let breadCrumbs: BreadCrumb = [{ name: 'Workflow', route: '/workflows' }];
      if (reportId) breadCrumbs.push({ name: 'Report', route: '' });
      return breadCrumbs;
    }
  }
};
