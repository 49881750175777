import * as React from 'react';
import { TemplatePage } from './templatePage';
import { AppState } from '../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { fetchConfig, fetchResourceGroup } from '../../lib/store/config/action';
import { connect } from 'react-redux';
import { Config, ConfigState } from '../../lib/store/config/types';
import { NoConfigError } from './NoConfigError';
import { SecureRouteProps } from '../../api/tcApiHelper';
import { PageLoader } from '../common/loaders';
import {
  withRegionAndCredentials,
  RegionChildProps,
  getAccountConfig,
} from '../common/withRegionAndCredentials';
import { AdoptionV2 } from './Adoption.v2';
import withUser from '../common/withUser';
import { WithUser } from '../../api/withUser';

interface IState {
  isAdopting: boolean;
  selectedTemplate: string;
}

type OwnProps = SecureRouteProps & {};

interface DispatchProps {
  fetchConfig: () => void;
  fetchResourceGroup: (configId: string) => void;
}

interface StateProps {
  isFetching: boolean;
  configs: Config[];
  resourceGroupData: ConfigState['resourceGroupData'];
}

type IProps = RegionChildProps & OwnProps & StateProps & DispatchProps;

class TemplateIndexInner extends React.Component<WithUser<IProps>, IState> {
  state: IState = {
    isAdopting: false,
    selectedTemplate: '',
  };

  onAdoptionCompleted = () => {
    this.setState({ isAdopting: false });
  };

  onAdoptTemplate = (templateId: string, option) => {
    if (option == 'Customize') {
      window.open(`${window.location.origin}/editor?template=${templateId}`);
    } else if (option === 'AdoptDirectly') {
      this.setState({ isAdopting: true, selectedTemplate: templateId });
    }
  };

  renderAdoptionDialog() {
    let credentials = this.props.configs.filter(
      (c) => c.type === 'aws-role' || c.type === 'aws-key',
    );

    // console.log(credentials);

    if (!credentials.length) {
      return <NoConfigError onClose={this.onAdoptionCompleted} />;
    }

    return (
      <AdoptionV2
        selectedRegion={this.props.regions && this.props.regions[0]}
        selectedAccountId={this.props.credentials && this.props.credentials[0]}
        templates={[this.state.selectedTemplate]}
        onCompleted={this.onAdoptionCompleted}
        onCancel={this.onAdoptionCompleted}
      />
    );
  }

  componentDidMount(): void {
    this.props.fetchConfig();
  }

  render(): React.ReactNode {
    let isFetching = this.props.isFetching && !this.props.configs.length;
    if (isFetching) {
      return <PageLoader />;
    }
    return (
      <>
        {this.state.isAdopting && this.renderAdoptionDialog()}
        <TemplatePage adoptTemplates={this.onAdoptTemplate} {...this.props} />
      </>
    );
  }
}

const RegionWrappedComponent = withRegionAndCredentials(TemplateIndexInner);

function mapStateToProps(state: AppState): StateProps {
  return {
    isFetching: state.config.fetching,
    configs: getAccountConfig(state.config.data),

    resourceGroupData: state.config.resourceGroupData,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  return {
    fetchConfig: () => fetchConfig({ dispatch }),
    fetchResourceGroup: (configId) => fetchResourceGroup(dispatch, configId),
  };
}

export const TemplateIndex = withUser(
  connect<StateProps, DispatchProps, OwnProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(RegionWrappedComponent),
);
