import * as React from 'react';

import {
  Button,
  Card,
  CardBody,
  CardColumns,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';

import { getSummaryTableData } from '../components/formatter/summaryTableData';
import {
  ItemUpdateStateMap,
  MissingWebflowItem,
  TemplateMap,
} from '../../../lib/store/templates-manage/types';
import { Workflow } from '../../../lib/store/workflows/types';
import { Template } from '../../../lib/store/templates/types';
import { ExecutionsMapType } from '../../../lib/store/executions/types';
import { InLineLoader } from '../../common/loaders';
import ChartWrapper from '../../common/Chart/ChartWrapper';
import { TableSubCategories } from '../../tables';

type IProps = {
  fetchingWorkflows: boolean;
  fetchingTemplates: boolean;
  templatesMap: TemplateMap;
  workflows: Workflow[];
  wfToTemplateMap: { [s: string]: Template };
  itemUpdateStateMap: ItemUpdateStateMap;
  executionsMap: ExecutionsMapType;
  webflowRecords: MissingWebflowItem[];
  fetchingWebflow: boolean;
  isSyncingTemplates: boolean;
  syncTemplatesFromProd: () => void;
};

export class TemplatesSummary extends React.Component<IProps> {
  render() {
    let data = getSummaryTableData({
      templatesMap: this.props.templatesMap,
      workflows: this.props.workflows,
      wfToTemplateMap: this.props.wfToTemplateMap,
      itemUpdateStateMap: this.props.itemUpdateStateMap,
      executionsMap: this.props.executionsMap,
      webflowRecords: this.props.webflowRecords,
    });
    return (
      <Container fluid>
        <Row className={'custom-container py-3'}>
          <Col md={2}>
            {this.props.isSyncingTemplates ? (
              <InLineLoader />
            ) : (
              <Button
                onClick={this.props.syncTemplatesFromProd}
                color={'primary'}
                size={'sm'}
              >
                Sync Dev
              </Button>
            )}
          </Col>
        </Row>
        <ChartWrapper
          title={'Templates Summary'}
          category={'manageTemplates'}
          views={['cumulative', 'daily']}
          subCategories={[
            TableSubCategories.SUMMARY_DATA,
            TableSubCategories.CATEGORY_DATA,
          ]}
        />
        <Row>
          <CardColumns>
            {data.map((x) => {
              return (
                <Card
                  className={'p-3 mr-4 align-items-center  custom-container'}
                >
                  <CardBody>
                    <CardTitle>
                      <h5
                        className={
                          'd-flex align-items-center text-primary dashboard-card-title font-18'
                        }
                      >
                        {`${x.itemType} (${x.count})`}
                        {(this.props.fetchingTemplates ||
                          this.props.fetchingWorkflows ||
                          this.props.fetchingWebflow) && (
                          <i className={'fa fa-spin fa-spinner ml-1'} />
                        )}
                      </h5>
                    </CardTitle>
                    <CardText>
                      {x.childTableData.map((c) => {
                        return (
                          <Row
                            style={{ color: c.color || '#000000' }}
                            className={'p-1'}
                          >
                            <Col md={9}>{c.itemType}</Col>
                            <Col md={3}>{c.count}</Col>
                          </Row>
                        );
                      })}
                    </CardText>
                  </CardBody>
                </Card>
              );
            })}
          </CardColumns>
        </Row>
      </Container>
    );
  }
}
