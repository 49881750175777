import { Reducer } from 'redux';
import { TfcTypeState, TfcTypeAction } from './types';

const initialState: TfcTypeState = {
  types: {},
};

export const tfcTypeReducer: Reducer<TfcTypeState, TfcTypeAction> = (
  state = initialState,
  action,
): TfcTypeState => {
  switch (action.type) {
    case 'RequestType':
      return {
        ...state,
        types: {
          ...state.types,
          [action.path]: {
            fetching: true,
            data: state.types[action.path]
              ? state.types[action.path].data
              : null,
          },
        },
      };
    case 'RequestTypeSuccess':
      return {
        ...state,
        types: {
          ...state.types,
          [action.path]: {
            fetching: false,
            data: action.data
              ? action.data
              : state.types[action.path]
              ? state.types[action.path].data
              : null,
          },
        },
      };
    default:
      return state;
  }
};
