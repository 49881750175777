import {
  GlobalPolicyRequest,
  GlobalPolicyResponse,
  IPolicyDoc,
  MultiPolicyMap,
  MultiPolicyRequest,
  MultiPolicyResponse,
  PolicyAction,
  PolicyDocRequest,
  PolicyDocResponse,
} from './types';
import api from '../../../api';
import { notify } from '../../../components/common/toaster';
import { store } from '../index';

const SendGlobalPolicyRequest = function(): PolicyAction {
  return {
    type: GlobalPolicyRequest,
  };
};

const SendGlobalPolicyResponse = function(data): PolicyAction {
  return {
    type: GlobalPolicyResponse,
    data,
  };
};
export const SendPolicyDocRequest = function(): PolicyAction {
  return {
    type: PolicyDocRequest,
  };
};

export const SendPolicyDocResponse = function(data: any): PolicyAction {
  return {
    type: PolicyDocResponse,
    data: data as IPolicyDoc,
  };
};

export const SendMultiPolicyRequest = function(): PolicyAction {
  return {
    type: MultiPolicyRequest,
  };
};

export const SendMultiPolicyResponse = function(
  data: any,
  account: string,
): PolicyAction {
  return {
    type: MultiPolicyResponse,
    data: data as MultiPolicyMap,
    account: account,
  };
};

export function fetchPolicyDoc(props: {
  dispatch: any;
  actions: string[];
  invalidActions?: string[];
}) {
  props.dispatch(SendPolicyDocRequest());
  let { actions, invalidActions } = props;
  api
    .getPolicyDoc({ actions, invalidActions })
    .then((document) => {
      return props.dispatch(SendPolicyDocResponse(document));
    })
    .catch((e) => {
      notify({ type: 'error', message: 'Can not fetch policy document' });
      console.error(e);
      props.dispatch(SendPolicyDocResponse(null));
    });
}

export function fetchMultiPolicy(props: {
  dispatch: any;
  groups: string[];
  account: string;
  workflows: string[];
  templates: string[];
}) {
  props.dispatch(SendMultiPolicyRequest());
  let { workflows, templates, groups } = props;
  api
    .getMultiPolicy({ account: props.account, workflows, templates, groups })
    .then((policy) => {
      return props.dispatch(SendMultiPolicyResponse(policy, props.account));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: 'Policy validation failed' });
      props.dispatch(
        SendMultiPolicyResponse(
          { error: 'Policy validation failed' },
          props.account,
        ),
      );
    });
}

export function fetchGlobalPolicyAction(props: { dispatch: any }) {
  props.dispatch(SendGlobalPolicyRequest());
  api
    .getGlobalPolicy()
    .then((data) => {
      return props.dispatch(SendGlobalPolicyResponse(data));
    })
    .catch((e) => {
      console.error(e);
      notify({ type: 'error', message: 'Policy validation failed' });
      props.dispatch(SendGlobalPolicyResponse(null));
    });
}

// fetchGlobalPolicyAction({ dispatch: store.dispatch });

export function mergePolicyChecks(policies: MultiPolicyMap[]): MultiPolicyMap {
  const result: MultiPolicyMap = { details: {}, error: '' };
  for (const policy of policies) {
    if (!policy) continue;
    if (policy.error) {
      result.error = policy.error;
      return result;
    }
    // iter over wf id
    for (const key of Object.keys(policy.details)) {
      // iter over action
      for (const inner of Object.keys(policy.details[key])) {
        if (!result.details[key]) result.details[key] = {};
        result.details[key][inner] = result.details[key][inner]
          ? result.details[key][inner] && policy.details[key][inner]
          : policy.details[key][inner];
      }
    }
  }
  return result;
}
