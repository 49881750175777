import { CustomScheduleType } from '../schedules/components/CustomScheduleForm';

export enum STEP_TYPE {
  RESOURCE = 'resource',
  FILTER = 'filter',
  PARK_ACTION = 'park_action',
  UN_PARK_ACTION = 'un_park_action',
  SCHEDULE = 'schedule',
  SMART_SCHEDULE = 'smart_schedule',
}

export const STEPS_CONFIG = {
  [STEP_TYPE.RESOURCE]: {
    title: 'Configure Service',
    description: 'Configure your AWS service.',
  },
  [STEP_TYPE.FILTER]: {
    title: 'Configure Filters',
    description: 'Add filters to enhance.',
  },
  [STEP_TYPE.PARK_ACTION]: {
    title: 'Parking Actions',
    description: 'Customize action before parking',
  },
  [STEP_TYPE.UN_PARK_ACTION]: {
    title: 'Un-parking Actions',
    description: 'Customize action before un-parking',
  },
  [STEP_TYPE.SCHEDULE]: {
    title: 'Custom Schedule',
    description: 'Customise when your resources should run',
  },
  [STEP_TYPE.SMART_SCHEDULE]: {
    title: 'Smart Schedule',
    description: 'Add smart scheduling to your resource',
  },
};
export type StepNode = {
  nodeId: string;
  summary: Promise<string>;
  isValid: boolean;
};

export type ResourceStep = {
  title: string;
  description: string;
  type: string;
  node: StepNode;
  action: string;
  isValid: boolean;
  summary: Promise<string>;
};

export type FilterStep = {
  title: string;
  description: string;
  type: string;
  nodes: Array<StepNode>;
  action: string;
  isValid: boolean;
  summary: Promise<string>;
};

export type ParkedActionStep = {
  title: string;
  description: string;
  type: string;
  nodes: Array<StepNode>;
  action: string;
  isValid: boolean;
  summary: Promise<string>;
};

export type UnParkedActionStep = ParkedActionStep;

export type ScheduleStep = {
  title: string;
  description: string;
  type: string;
  action: string;
  schedules: CustomScheduleType[];
  isValid: boolean;
  summary: Promise<string>;
};

export type SmartScheduleStep = {
  title: string;
  description: string;
  type: string;
  action: string;
  threshold: string;
  namespace: string;
  metrics: string;
  dimension: string;
  stat: string;
  isValid: boolean;
  isActive: boolean;
  summary: Promise<string>;
};

export type StepType =
  | ResourceStep
  | FilterStep
  | ParkedActionStep
  | UnParkedActionStep
  | ScheduleStep
  | SmartScheduleStep;
