import * as React from 'react';
import { Workflow } from '../../../lib/store/workflows/types';
import { AppState } from '../../../lib/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { adoptTemplates } from '../../../lib/store/templates/action';
import { TAB_NAME } from 'webapp-genric/dist/constants';
import { runWorkflow } from '../../../lib/store/executions/action';
import { AdoptionSelectorModal } from '../../workflows/common/adoptionSelector';
import { Config, ConfigState } from '../../../lib/store/config/types';
import { AdoptionSelectorSchema } from '../../workflows/common/adoptionSelectorSchema';
import { fetchResourceGroup } from '../../../lib/store/config/action';
import _ from 'lodash';
import api from '../../../api';
import { notify } from '../toaster';
import { Template } from '../../../lib/store/templates/types';
import { AlreadyAdopted } from '../../page-header/AdoptionSetting';

export interface TemplatesToAdopt {
  region: string[];
  account: string[];
  templatesIds: string[];
  resourceGroup?: string[];
}

interface OwnProps {
  refreshComponents: () => void;
  configs: Config[];
  tabName: TAB_NAME;
  templatesToAdopt: TemplatesToAdopt[];
  isFetching: boolean;
  account: string[];
  region: string[];
  resourceGroups?: string[];
  shouldAdopt: boolean;
  autoAdopt: boolean;
  showNotification: boolean;
  showRegionSelector?: boolean;
  hideRegionSelector?: () => void;
  triggerWorkflow?: Workflow;
  category: string;
  subCategory: string;
  alreadyAdopted: AlreadyAdopted;
  fetchAdoptionData: (options: any) => void;
}

interface StateProps {
  isAdopting: boolean;
  resourceGroupData: ConfigState['resourceGroupData'];
}

interface DispatchProps {
  adoptTemplatesRequest: (params: {
    templatesObj?: TemplatesToAdopt[];
    templateIds?: Template[];
    account?: string[];
    region?: string[];
    resourceGroups?: string[];
  }) => void;
  fetchResourceGroups: (configIds: string[]) => void;
}

type IProps = OwnProps & StateProps & DispatchProps;

type IState = {
  showRegionSelector: boolean;
  regionsToAdopt: string[];
  isFetching: boolean;
};

class AutoAdoptionInnerV2 extends React.Component<IProps, IState> {
  state: IState = {
    showRegionSelector: this.props.showRegionSelector,
    regionsToAdopt: [],
    isFetching: false,
  };

  /**
   * Run the auto adoption task if autoAdopt is true and templates are present to adopts
   */
  autoAdoption = () => {
    const {
      autoAdopt,
      templatesToAdopt,
      isAdopting,
      account,
      region,
      resourceGroups,
    } = this.props;
    if (autoAdopt && !isAdopting && templatesToAdopt.length) {
      this.props.adoptTemplatesRequest({
        templatesObj: templatesToAdopt,
      });
    }
  };

  showRegionSelector = () => {
    this.setState({ showRegionSelector: true });
  };

  hideRegionSelector = () => {
    this.setState({ showRegionSelector: false });
    this.props.hideRegionSelector && this.props.hideRegionSelector();
  };

  adopt = (
    regions?: string[],
    templatesIds?: Template[],
    accounts?: string[],
    resourceGroups?: string[],
    templatesObj?: TemplatesToAdopt[],
  ) => {
    this.hideRegionSelector();
    if (this.props.isAdopting) return;
    this.props.adoptTemplatesRequest({
      account: accounts,
      templateIds: templatesIds,
      region: regions,
      resourceGroups,
      templatesObj,
    });
  };

  onSubmitAdoptionForm = (values: AdoptionSelectorSchema) => {
    let { regions, accounts, resourceGroups } = values;
    const { category, subCategory } = this.props;
    const options = {
      category,
      subCategory,
      params: { filters: { regions, accounts, resourceGroups } },
    };
    this.hideRegionSelector();

    this.setState({ isFetching: true });

    api
      .getSuperTableData(options)
      .then((data) => {
        if (data.templatesToAdopt?.length) {
          return this.props.adoptTemplatesRequest({
            templatesObj: data.templatesToAdopt,
          });
        } else {
          notify({
            type: 'info',
            message: 'No templates to adopt for selected configs',
          });
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: 'Unable to fetch Adoption Templates',
        });
      })
      .finally(() => this.setState({ isFetching: false }));
  };

  componentDidMount(): void {
    this.autoAdoption();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any,
  ) {
    if (!_.isEqual(prevProps.templatesToAdopt, this.props.templatesToAdopt)) {
      this.autoAdoption();
    }
  }

  onRegionChange = (regionsToAdopt = []) => {
    this.setState({ regionsToAdopt });
  };

  renderRegionSelector = () => {
    return (
      <AdoptionSelectorModal
        alreadyAdopted={this.props.alreadyAdopted}
        configs={this.props.configs}
        tabName={this.props.tabName}
        selectedAccounts={this.props.account}
        selectedRegions={this.props.region}
        onSubmit={this.onSubmitAdoptionForm}
        toggle={this.hideRegionSelector}
        fetchResourceGroups={this.props.fetchResourceGroups}
        resourceGroupData={this.props.resourceGroupData}
      />
    );
  };

  render = () => {
    const {
      isAdopting,
      isFetching,
      showNotification,
      tabName,
      showRegionSelector,
      refreshComponents,
    } = this.props;

    if (isAdopting || isFetching) {
      return (
        <NotificationBar
          message={'Adopting templates... '}
          show={this.props.isAdopting}
        />
      );
    } else
      return (
        <>
          {showNotification && (
            <NotificationBar
              message={
                'No ' +
                tabName +
                ' available for this account and region.You want to adopt? '
              }
              show={!isFetching}
              onConfirm={() => this.showRegionSelector()}
              onCancel={() => {}}
            />
          )}

          {(this.state.showRegionSelector || showRegionSelector) &&
            this.renderRegionSelector()}
        </>
      );
  };
}

function mapStateToProps(state: AppState, ownProps: OwnProps): StateProps {
  return {
    isAdopting: state.templates.adopting,
    resourceGroupData: state.config.resourceGroupData,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, DispatchProps, AnyAction>,
  ownProps: OwnProps,
): DispatchProps {
  const {
    category,
    subCategory,
    region,
    account,
    resourceGroups = [],
    refreshComponents,
    fetchAdoptionData,
    hideRegionSelector,
  } = ownProps;
  return {
    adoptTemplatesRequest: async (data) => {
      const rsGrp = data.resourceGroups?.filter((x) => !!x);
      await adoptTemplates({
        dispatch,
        ...data,
        category,
        subCategory,
        resourceGroup: rsGrp,
        templatesObj: data.templatesObj,
        options: {
          filters: {
            accounts: account,
            regions: region,
            resourceGroups,
          },
        },
      });

      fetchAdoptionData({ filters: { regions: region, accounts: account } });

      if (ownProps.triggerWorkflow && ownProps.triggerWorkflow.workflow) {
        await runWorkflow({
          dispatch,
          workflow: ownProps.triggerWorkflow.workflow,
        });

        // refreshComponents();
      } else {
        notify({ type: 'error', message: 'Workflow not found' });
      }
    },
    fetchResourceGroups: (configIds) =>
      configIds.forEach((id) => fetchResourceGroup(dispatch, id)),
  };
}

interface INProps {
  show: boolean;
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

interface INState {
  showNotificationBar: boolean;
}

class NotificationBar extends React.Component<INProps, INState> {
  state = {
    showNotificationBar: this.props.show,
  };

  componentDidUpdate(
    prevProps: Readonly<INProps>,
    prevState: Readonly<INState>,
    snapshot?: any,
  ): void {
    if (this.props !== prevProps)
      this.setState({ showNotificationBar: this.props.show });
  }

  render = () => {
    if (this.state.showNotificationBar)
      return (
        <div className={'notification-bar'}>
          <Alert color="primary">
            {this.props.message}
            {this.props.onConfirm && (
              <>
                <a
                  onClick={() => {
                    this.props.onConfirm();
                    this.setState({ showNotificationBar: false });
                  }}
                  className="alert-link ml-3"
                >
                  Yes{' '}
                </a>
                <a
                  onClick={() => this.setState({ showNotificationBar: false })}
                  className="alert-link ml-2"
                >
                  No{' '}
                </a>
              </>
            )}
          </Alert>
        </div>
      );
    return <div />;
  };
}

export const AutoAdoptionV2 = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AutoAdoptionInnerV2);
